import { FormProvider, useForm } from 'react-hook-form';
import { get, set } from 'lodash-es';
import { PropsWithChildren, useMemo } from 'react';
import { BasicFormConfig, field2ToFormField, IField } from '../../data-models/field2.data-model';
import { RendererType } from '../../data-models/field.data-model';
import { FormVariant } from '../../view-models/form.view-model';
import { useListenToFormChanges } from './FormFactory';
import { FormFieldAndLabelFactory } from './FormFieldAndLabelFactory';

export interface ICustomFieldsFormSectionProps {
  data: Record<string, unknown>;
  customFields: IField<unknown, BasicFormConfig>[];
  disableEdit?: boolean;
  variant?: FormVariant;
  handleChange: (data: Record<string, unknown>) => void;
}
export function CustomFieldsFormSection(props: ICustomFieldsFormSectionProps) {
  const { data, customFields, disableEdit, handleChange, variant } = props;
  const initialData = addInitialValues(data, customFields);
  const formMethods = useForm({
    values: initialData,
    mode: 'onBlur',
  });

  const formFields = useMemo(() => {
    return (
      customFields?.map((field) => {
        return field2ToFormField(field, {
          disabled: disableEdit,
          variant: variant ?? 'form-inline',
        });
      }) ?? []
    );
  }, [customFields, disableEdit, variant]);

  if (formFields.length === 0) {
    return null;
  }

  const inputs = formFields.map((formField) => {
    return <FormFieldAndLabelFactory formField={formField} key={formField.key} />;
  });

  return (
    <FormProvider {...formMethods}>
      <ChangeHandlerWrapper handleChange={handleChange}>{inputs}</ChangeHandlerWrapper>
    </FormProvider>
  );
}

interface IChangeHandlerWrapperProps extends PropsWithChildren {
  handleChange: (data: Record<string, unknown>) => void;
}
function ChangeHandlerWrapper({ children, handleChange }: IChangeHandlerWrapperProps) {
  useListenToFormChanges(handleChange);
  return <>{children}</>;
}

function addInitialValues(data: Record<string, unknown>, fields: IField<unknown>[]) {
  fields.forEach((field) => {
    const fieldId = field.id.toString();

    if (get(data, fieldId) === undefined) {
      if (field.formMeta?.renderer?.type === RendererType.multiSelect) {
        set(data, fieldId, []);
      } else if (field.formMeta?.renderer?.type === RendererType.singleSelect) {
        set(data, fieldId, '');
      }
    }
  });

  return data;
}
