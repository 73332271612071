import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { ColumnStage } from '../../data-models/columnStage.data-model';

export const getCurrentDealPosition = (
  dealboardState: ColumnStage[],
  currentDeal: IDealDataModel
): { columnIndex: number; dealIndex: number } => {
  const currColumnIndex = dealboardState.findIndex((col) => col.stage.id === currentDeal.stageId);
  const currDealIndex = dealboardState[currColumnIndex].deals.findIndex((deal) => deal.id === currentDeal.id);
  return { columnIndex: currColumnIndex, dealIndex: currDealIndex };
};

export const getPrevDeal = (dealboardState: ColumnStage[], currentDeal: IDealDataModel | null) => {
  if (!currentDeal) return null;

  const { columnIndex: currColumnIndex, dealIndex: currDealIndex } = getCurrentDealPosition(
    dealboardState,
    currentDeal
  );

  let prevColIndex = currDealIndex !== 0 ? currColumnIndex : currColumnIndex - 1;
  while (prevColIndex >= 0 && dealboardState[prevColIndex].deals.length === 0) prevColIndex--; // -1 will signify no previous deal
  if (prevColIndex === -1) return null;

  const prevDealIndex =
    prevColIndex === currColumnIndex ? currDealIndex - 1 : dealboardState[prevColIndex].deals.length - 1;

  return dealboardState[prevColIndex].deals[prevDealIndex];
};

export const getNextDeal = (dealboardState: ColumnStage[], currentDeal: IDealDataModel | null) => {
  if (!currentDeal) return null;

  const { columnIndex: currColumnIndex, dealIndex: currDealIndex } = getCurrentDealPosition(
    dealboardState,
    currentDeal
  );
  let nextColIndex =
    currDealIndex === dealboardState[currColumnIndex].deals.length - 1
      ? currColumnIndex + 1
      : currColumnIndex;

  while (nextColIndex < dealboardState.length && dealboardState[nextColIndex].deals.length === 0)
    nextColIndex++;
  if (nextColIndex >= dealboardState.length) return null;

  const nextDealIndex = currColumnIndex === nextColIndex ? currDealIndex + 1 : 0;

  return dealboardState[nextColIndex].deals[nextDealIndex];
};
