import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import {
  fundReservesHistoryState,
  selectedFundIdStateFP,
  selectedFundReservesState,
  selectedFundStateFP,
} from '../state/FPState';
import { usePortCos } from '../../Finance2/hooks/usePortCos';
import { FundReservesSummary } from './FundReservesSummary';
import { FundReservesGrid } from './FundReservesGrid';
import { ReservesModals } from './ReservesModals';

export function FundReserves() {
  const { dataReady } = usePortCos();
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const fund = useRecoilValue(selectedFundStateFP);
  const reserves = useRecoilValue(selectedFundReservesState(fundId ?? -1));
  useRecoilValue(fundReservesHistoryState(fundId));

  if (!reserves || !fund || !dataReady) return null;
  return (
    <>
      <ReservesModals />
      <Box>
        <FundReservesSummary reserves={reserves} />
        <FundReservesGrid fund={fund} reserves={reserves} />
      </Box>
    </>
  );
}
