import { useRecoilValue } from 'recoil';
import { fundDataStateFP, selectedFundStateFP } from '../state/FPState';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { FundMetricsSummary } from './FundMetricsSummary';
import { WaterfallModelling } from './WaterfallModelling';

export function FPWaterfall() {
  const fund = useRecoilValue(selectedFundStateFP);
  const fundMetrics = useFundMetricsFP();
  useRecoilValue(fundDataStateFP(fund?.id ?? -1));
  if (!fund || !fundMetrics) return null;
  return (
    <>
      <FundMetricsSummary fundMetrics={fundMetrics.metrics} />
      <WaterfallModelling />
    </>
  );
}
