import { useMemo } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import { SchemaDescription } from 'yup';
import { FundMetrics } from '../../../schemas/FundMetrics.schema';
import {
  StyledTableHeaderRow,
  StyledTableCell,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { FMT } from '../../../util/formatter-service';
import { WaterfallGridData, getWaterfallTotals, waterfallGridDataSchema } from './FPWaterfallData';
import { LpGpPortions } from './DistributionBar';

export function WaterfallModellingSummaryRow({ fundMetrics }: { fundMetrics: FundMetrics }) {
  const { colors } = useTheme();
  const totals = useMemo(() => {
    if (!fundMetrics) return null;
    return getWaterfallTotals(fundMetrics);
  }, [fundMetrics]);

  const fieldDescriptionsMap = useMemo(() => {
    return new Map(
      Object.entries(waterfallGridDataSchema().pick(['lpDistributed', 'gpDistributed']).fields).map(
        ([fieldKey, value]) => [fieldKey as keyof WaterfallGridData, value.describe() as SchemaDescription]
      )
    );
  }, []);

  if (!fundMetrics) return null;
  return (
    <>
      <StyledTableHeaderRow style={{ marginTop: '1rem' }}>
        <StyledTableCell style={{ gridColumn: 5 }} type={'numeric'} header>
          LP NAV
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 6 }} type='numeric' header>
          GP Incentive (Carry)
        </StyledTableCell>
      </StyledTableHeaderRow>

      <StyledTableRow style={{ background: colors.primary[10] }}>
        <StyledTableCell style={{ gridColumn: 5 }} type={'numeric'}>
          {totals?.lpDistributed != null ? (
            <Tooltip
              title={<LpGpPortions data={totals} />}
              slotProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
              leaveDelay={200}
              placement='top'
            >
              <span>
                {FMT.format(
                  fieldDescriptionsMap.get('lpDistributed')!.meta!.formatter!,
                  totals.lpDistributed
                )}
              </span>
            </Tooltip>
          ) : (
            '-'
          )}
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 6 }} type={'numeric'}>
          {totals?.gpDistributed != null
            ? FMT.format(fieldDescriptionsMap.get('gpDistributed')!.meta!.formatter!, totals.gpDistributed)
            : '-'}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
