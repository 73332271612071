import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';

const EditButton = css`
  transform: translate(-75%, 20%);
`;

const Container = css`
  display: grid;
  grid-template-columns: 1fr min-content;
`;

const EditButtonContainer = css`
  width: 0;
  overflow: visible;
`;

interface IEditableComponentProps extends PropsWithChildren {
  onEdit: () => void;
}

export function EditableComponent({ onEdit, children }: IEditableComponentProps) {
  return (
    <div css={Container}>
      <div>{children}</div>
      <div css={EditButtonContainer}>
        <IconButton css={EditButton} onClick={onEdit} title='edit'>
          <EditOutlinedIcon fontSize='small' color='secondary' />
        </IconButton>
      </div>
    </div>
  );
}
