import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import { INewsDataModel } from '../../../../data-models/news.data-model';
import { newsByCompanyIdState } from '../../../../services/state/NewsState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { NoDataMessage2 } from '../../components/Messages/HelpMessage';
import { NewsRow } from './NewsRow';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NewsSection: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const news = useRecoilValue(newsByCompanyIdState(companyId))
    ?.filter((n) => Boolean(n.date))
    .toSorted((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  if (!news || !news.length) {
    return <NoDataMessage2 />;
  }

  return (
    <Wrapper id='News'>
      {news.map((n) => (
        <NewsRow key={n.id} news={n as INewsDataModel} />
      ))}
    </Wrapper>
  );
};
