import { useCallback } from 'react';
import { atom, selector, useResetRecoilState } from 'recoil';
import { AssignTemplateFieldTypes } from '../../../../../schemas/template-assignment-schema';
import { selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { companyState } from '../../../../../services/state/CompanyState';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { validateCompanyData } from './template-assignment-utils';

export const templateAssignmentFormState = atom<Partial<AssignTemplateFieldTypes> | null>({
  key: 'templateAssignmentFormState',
  default: null,
});

export const companiesWithMissingDataState = selector<Set<number>>({
  key: 'companiesWithMissingDataState',
  get: ({ get }) => {
    const selectedCompaniesIds = get(selectedKpiCompaniesStateDC).map((company) => company.id);
    const currentlyInvalid = selectedCompaniesIds.filter((id) => {
      return !validateCompanyData(get(companyState(id))!);
    });
    return new Set(currentlyInvalid.map((id) => id));
  },
});

export const currentFormCompanyDataState = selector<Map<number, ICompanyDataModel>>({
  key: 'currentFormCompanyData',
  get: ({ get }) => {
    return get(selectedKpiCompaniesStateDC).reduce(
      (map, company) => map.set(company.id, get(companyState(company.id))!),
      new Map<number, ICompanyDataModel>()
    );
  },
});

export const currentStepStateTA = atom<number>({
  key: 'currentStepStateTA',
  default: 0,
});

export function useResetTemplateAssignmentFormState() {
  const resetFormState = useResetRecoilState(templateAssignmentFormState);
  const resetCurrentStep = useResetRecoilState(currentStepStateTA);

  return useCallback(() => {
    resetFormState();
    resetCurrentStep();
  }, [resetFormState, resetCurrentStep]);
}
