import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';

export function miscWriteOffFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
  };
}

export function miscWriteOffFormSchema() {
  return yup.object().shape(miscWriteOffFields());
}
