import { Stack, Typography, useTheme } from '@mui/material';
import { flatten, get, isEmpty, noop } from 'lodash-es';
import { useFormContext, useFormState } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IField } from '../../../../../../data-models/field2.data-model';
import { MetricNameCell } from '../../../KPIMetricsNameCell';
import { FormTable } from '../../../../../../components/Form/FormGrid';
import { FormTextField } from '../../../../../../components/FormField/FormTextField';
import { formatDateYYYY } from '../../../../../../util/formatters/DateFormatters';
import { MaggieFeatureFlags } from '../../../../../../util/feature-flags';
import { IKPITableDataModel } from '../../../../../../data-models/kpi-template.data-model';
import { KPITableEditableMetrics } from './KPITableEditableMetrics';
import { KPITableHeader } from './KPITableHeader';
import { useKPITableMetricsInputs } from './useKPITableMetricsInputs';
import { KPITableEditableMetricsErrors } from './KPITableEditableMetricsErrors';

export type KPITableErrorsType = {
  numberFormatValue: {
    ref: HTMLInputElement;
  };
};

export interface IKPITableProps {
  metrics: IField<unknown>[];
  sectionFieldRef: string;
  editMode?: boolean;
  sectionRef: string;
  showErrors?: boolean;
}

export function KPITable(props: { metrics: IField<unknown>[]; sectionMeta: IKPITableDataModel }) {
  const { metrics } = props;

  const rows = useMemo(
    () =>
      metrics.map((metric: IField<unknown>) => {
        return (
          <tr key={metric.id}>
            <td>
              <MetricNameCell metric={metric} />
            </td>
            <td>
              <FormTextField className={'transparent'} fullWidth onValueChanged={noop} />
            </td>
          </tr>
        );
      }),
    [metrics]
  );

  return (
    <Stack
      gap='.5rem'
      sx={{
        overflowX: 'scroll',
      }}
    >
      <FormTable
        sx={{
          width: '100%',
          overflowX: 'scroll',
          '& td': {
            width: '160px',
          },
        }}
      >
        <thead>
          <tr>
            <th>
              <Typography variant={'body2'}>{`Metrics`}</Typography>
            </th>
            <th>
              <Typography variant={'body2'}>Period</Typography>
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </FormTable>
    </Stack>
  );
}

export function KPITableEditable(props: IKPITableProps) {
  const { colors } = useTheme();
  const { metrics, sectionRef, sectionFieldRef } = props;
  const { watch } = useFormContext();
  const { errors } = useFormState();
  const showErrors = props.showErrors ?? true;

  const kpiSection = watch(`${sectionRef}.meta.section`);

  const kpiRequestPeriodDate = watch(`kpiRequest.period`);
  // obtain year from period date for the sake of column names
  const periodDateYear = useMemo(() => formatDateYYYY(kpiRequestPeriodDate), [kpiRequestPeriodDate]);

  const { metricsInputColNames } = useKPITableMetricsInputs(
    metrics,
    kpiRequestPeriodDate,
    sectionFieldRef,
    kpiSection
  );

  const lastFocusedMetric = watch('lastFocusedMetric');

  const { metricParentRowIdx, metricCeilColIdx } = lastFocusedMetric;

  const lastActiveMetricCeilRef = `${sectionFieldRef}.${metricParentRowIdx}.${metricCeilColIdx}`;
  const isValidFocusedMetricCeil = !get(errors, lastActiveMetricCeilRef);

  const { showKpiCurrencySelector } = useFlags<MaggieFeatureFlags>();

  const getNextOrPrevPosition = useCallback(
    (action: 'next' | 'prev') => {
      // duplicate because hook form library isolation re-rendering...
      const isValidFocusedMetricCeil = !get(errors, lastActiveMetricCeilRef);
      // If the current focused metric ceil is not valid, then we should not navigate to the next or prev metric ceil
      if (!isValidFocusedMetricCeil) {
        const currentFocusedCeil = get(errors, lastActiveMetricCeilRef) as KPITableErrorsType;

        return currentFocusedCeil?.numberFormatValue.ref.focus();
      }

      // increase if next or decrease if prev
      let operator = -1;
      switch (action) {
        case 'next':
          operator = 1;
          break;
      }

      const newMetricRowtIdx = metricParentRowIdx;
      const newMetricColIdx = metricCeilColIdx;

      const colsLength = metricsInputColNames.length;
      const rowsLength = metrics.length;

      let newRow = newMetricRowtIdx + operator;
      let newCol = newMetricColIdx;

      const isSameMetricCeil = newRow == newMetricRowtIdx && newCol == newMetricColIdx;

      // vertical navigation throughout the metrics ceils
      while (!isSameMetricCeil) {
        // circular navigation throughout the metrics ceils
        if (action === 'next') {
          if (newRow === rowsLength) {
            newRow = 0;
            newCol += operator;
          }
          if (newCol === colsLength) {
            newCol = 0;
          }
        } else {
          if (newRow === -1) {
            newCol--;
            newRow = rowsLength - 1;
          }
          if (newCol === -1) {
            newCol = colsLength - 1;
            newRow = rowsLength - 1;
          }
        }

        const metricCeilRef = `${sectionFieldRef}.${newRow}.${newCol}`;

        const hasMetricCeil = get(errors, metricCeilRef) as KPITableErrorsType;

        newRow += operator;
        if (hasMetricCeil) {
          hasMetricCeil.numberFormatValue.ref.focus();
          break;
        }
      }
    },
    [
      errors,
      lastActiveMetricCeilRef,
      metricCeilColIdx,
      metricParentRowIdx,
      metrics.length,
      metricsInputColNames.length,
      sectionFieldRef,
    ]
  );

  let kpiTableErrors = flatten((get(errors, `kpiData.values`) as unknown as []) ?? []);
  kpiTableErrors = kpiTableErrors.filter((object) => !isEmpty(object)) as [];
  const allKpiTableErrors = kpiTableErrors.length;

  return (
    <Stack direction='row'>
      <Stack
        gap='.5rem'
        sx={{
          width: '100%',
          overflowX: 'scroll',
        }}
      >
        {showKpiCurrencySelector && <KPITableHeader />}
        <FormTable
          sx={{
            borderCollapse: 'separate',
            borderSpacing: 0,
            width: '100%',
            '& td': {
              width: '160px',
              borderBottom: 'transparent',
            },
            '& th': {
              width: '160px',
              borderBottom: 'transparent',
            },
            '&:last-child tr td': {
              borderBottom: `1px solid ${colors.neutral[20]}`,
            },
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: '220px',
                }}
              >
                <Typography variant={'body2'}>{`Metrics`}</Typography>
              </th>
              {metricsInputColNames.length ? (
                metricsInputColNames.map((colName, index) => (
                  <th key={index}>
                    <Typography variant={'body2'}>{colName}</Typography>
                  </th>
                ))
              ) : (
                <th>
                  <Typography variant={'body2'}>{periodDateYear}</Typography>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <KPITableEditableMetrics
              metrics={metrics}
              sectionFieldRef={sectionFieldRef}
              sectionRef={sectionRef}
            />
          </tbody>
        </FormTable>
      </Stack>
      {showErrors && (
        <KPITableEditableMetricsErrors
          isValidFocusedMetricCeil={isValidFocusedMetricCeil && lastFocusedMetric.focused}
          onNextErrorMetric={() => getNextOrPrevPosition('next')}
          onPrevErrorMetric={() => getNextOrPrevPosition('prev')}
          totalErrors={allKpiTableErrors}
        />
      )}
    </Stack>
  );
}
