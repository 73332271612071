import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { atom, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { CompetitiveIntelligenceItem } from '../../../data-models/competitive-intelligence.data-model';
import { LoadingStatus } from '../../../types';
import { currentCompaniesCI } from '../state/CompaniesState';
import { selectedFieldsCI } from '../state/DashboardsState';
import { getLatestValue } from '../../../services/queries/CompetitiveIntelligenceSocketQueries';
import { usePreviousValue } from '../../../hooks/usePreviousValue';

export interface CompetitiveIntelligenceMetricsDataModel {
  data: CompetitiveIntelligenceItem[];
  status: LoadingStatus;
}

export interface CompetitiveIntelligenceMetricsModel extends CompetitiveIntelligenceMetricsDataModel {
  partialData: CompetitiveIntelligenceItem[] | null;
}

export const competitiveIntelligenceMetrics = atom<CompetitiveIntelligenceMetricsModel>({
  key: 'competitiveIntelligenceMetrics2',
  default: {
    data: [],
    status: LoadingStatus.idle,
    partialData: null,
  },
});

export function useConnectCompetitiveIntelligenceMetrics() {
  const companies = useRecoilValueLoadable(currentCompaniesCI).valueMaybe();
  const selectedFields = useRecoilValueLoadable(selectedFieldsCI).valueMaybe();
  const previousSelectedFields = usePreviousValue(selectedFields);
  const socketSubscription = useRef<Subscription>();

  const ciMetricsLoadable = useRecoilValueLoadable(competitiveIntelligenceMetrics);
  const setCIMetrics = useSetRecoilState(competitiveIntelligenceMetrics);

  useEffect(() => {
    if (!selectedFields || !companies) return;
    const isDeselect = previousSelectedFields ? selectedFields.length < previousSelectedFields.length : false;
    if (isDeselect) {
      return;
    }

    if (selectedFields.length === 0 || companies.length === 0) {
      setCIMetrics({
        data: [],
        status: LoadingStatus.success,
        partialData: null,
      });

      return;
    }

    setCIMetrics((current) => {
      return {
        data: current?.data ?? [],
        status: LoadingStatus.loading,
        partialData: null, // current.data might contain data from previous queries
      };
    });

    const fieldsId = selectedFields.map((field) => field.id);
    const companiesId = companies.map((company) => company.id);
    socketSubscription.current = getLatestValue(companiesId, fieldsId).subscribe({
      next: (response) => {
        setCIMetrics((current) => {
          return {
            data: (current?.data ?? []).concat(response.data),
            status: LoadingStatus.loading,
            partialData: response.data || null,
          };
        });
      },
      complete: () => {
        setCIMetrics((current) => {
          return {
            data: current?.data ?? [],
            status: LoadingStatus.success,
            partialData: null,
          };
        });
      },
    });
  }, [companies, previousSelectedFields, selectedFields, setCIMetrics]);

  return ciMetricsLoadable.valueMaybe();
}
