import { Collapse, Link, Stack, Typography, TypographyProps } from '@mui/material';
import React, { CSSProperties, FC, PropsWithChildren, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { CollapseButton } from './CollapseButton';

type SubSectionProps = PropsWithChildren & {
  title: string;
  actionComponent?: React.JSX.Element;
  link?: string | React.JSX.Element;
  path?: string;
  style?: CSSProperties;
  noCard?: boolean;
  cardPadding?: string;
  collapseProps?: {
    collapsible?: boolean;
    defaultExpanded?: boolean;
  };
  childrenContainerStyle?: CSSProperties;
};

const Wrapper = styled('div')`
  margin: 2rem 0;
  max-width: 100%;
`;
const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const TitleAndAction = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

interface CardProps {
  noShadow?: boolean;
  padding?: string;
}
const CardWrapper = styled('div')<CardProps>`
  ${({ noShadow }) =>
    !noShadow &&
    `border: 2px solid #ffffff;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;`};
  padding: ${({ padding }) => (padding ? padding : '12px 16px')};
  ${({ noShadow }) => noShadow && `padding: 1rem 0`};
  margin-top: ${({ noShadow }) => (noShadow ? '0' : '8px')};
  background: ${({ noShadow }) =>
    !noShadow &&
    `radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  )`};
`;

export const SubSectionTitle: FC<Omit<TypographyProps, 'component' | 'ref'>> = ({
  children,
  sx,
  ...props
}) => (
  <Typography
    component='h4'
    variant='body1'
    color='text.primary'
    sx={{ fontWeight: '500', ...sx }}
    {...props}
  >
    {children}
  </Typography>
);

export function SubSection(props: SubSectionProps) {
  const { children, title, actionComponent, link, path, style, noCard, cardPadding, childrenContainerStyle } =
    props;
  const collapseProps = {
    collapsible: false,
    defaultExpanded: true,
    ...props.collapseProps,
  };
  const navigate = useNavigate();
  const { collapsible, defaultExpanded } = collapseProps;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const onLinkClick = useCallback(() => {
    if (path) navigate(path);
  }, [navigate, path]);

  return (
    <Wrapper style={{ ...style }}>
      <HeaderWrapper>
        <TitleAndAction>
          <Stack direction='row' gap='0.25rem' alignItems={'center'}>
            {collapsible && <CollapseButton expanded={expanded} setExpanded={setExpanded} />}
            <Typography variant='body1' sx={{ fontWeight: '500' }} component={'h4'}>
              {title}
            </Typography>
          </Stack>
          {actionComponent && actionComponent}
        </TitleAndAction>
        {link && (
          <Link
            color={'secondary'}
            component='button'
            underline='hover'
            onClick={onLinkClick}
            sx={{ '&.LinkButtonWithIcon:hover': { borderBottom: 'none' }, whiteSpace: 'nowrap' }}
          >
            {link}
          </Link>
        )}
      </HeaderWrapper>
      <Collapse in={expanded}>
        <CardWrapper noShadow={noCard} padding={cardPadding} style={childrenContainerStyle}>
          {children}
        </CardWrapper>
      </Collapse>
    </Wrapper>
  );
}
