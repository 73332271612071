import { Chip, Tooltip, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { peopleByCompanyState } from '../../CompanyProfiles/state/PeopleByCompanyState';
import { FMT } from '../../../util/formatter-service';
import { compareByCreatedAt, getEngagementSummary, IEngagementSummary } from '../utils/getPersonActivityData';
import { IContactActivityItemDataModel } from '../../../data-models/person.data-model';
import { ActivityDetails } from './ActivityDetails';

interface ILastContactedProps {
  companyId: number;
}

export function LastContacted(props: ILastContactedProps) {
  const people = useRecoilValue(peopleByCompanyState(props.companyId));
  const lastContact = useMemo(() => {
    return (people ?? []).reduce((res, person) => {
      const activity = getEngagementSummary(person.activity);
      if (!activity.lastEmail && !activity.lastMeeting) {
        return res;
      }
      let latest;
      if (activity.lastEmail && activity.lastMeeting) {
        latest =
          compareByCreatedAt(activity.lastEmail, activity.lastMeeting) > 0
            ? activity.lastEmail
            : activity.lastMeeting;
      } else if (activity.lastEmail) {
        latest = activity.lastEmail;
      } else {
        latest = activity.lastMeeting;
      }
      return {
        lastContact: latest,
        contactType: latest === activity.lastEmail ? 'email' : 'meeting',
        activity,
      } as ILastEmailDetailsProps;
    }, null as ILastEmailDetailsProps | null);
  }, [people]);

  return lastContact ? <LastContactDetails {...lastContact} /> : <div />;
}

interface ILastEmailDetailsProps {
  lastContact: IContactActivityItemDataModel | null;
  contactType: 'email' | 'meeting';
  activity: IEngagementSummary;
}
function LastContactDetails(props: ILastEmailDetailsProps) {
  const theme = useTheme();
  if (!props.lastContact) return null;
  const { lastContact, contactType, activity } = props;
  const contactTypeText = contactType === 'email' ? 'emailed' : 'met';
  const message = `Last ${contactTypeText} on ${FMT.format('dateShort', lastContact.createdAt!)}`;

  return (
    <Tooltip
      placement='bottom-end'
      componentsProps={{
        tooltip: { sx: { minWidth: '28rem' } },
      }}
      leaveDelay={200}
      title={<ActivityDetails engagementSummary={activity} style={{ padding: '1rem' }} />}
    >
      <Chip
        aria-label={'last contact'}
        size={'medium'}
        label={message}
        variant='outlined'
        sx={{ '&:hover': { background: theme.palette.action.hover } }}
      />
    </Tooltip>
  );
}
