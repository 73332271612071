import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';

import { CPWrapper } from '../components/CompanyProfilesWrapper';

export const Wrapper = styled('div')`
  margin-top: 24px;
`;

export const CompanyProfilesCompetitors = () => {
  const { primary } = useTheme().colors;

  return (
    <CPWrapper>
      <Wrapper>
        <Typography variant='h5' color={primary[90]}>
          Company Profile Competitors
        </Typography>
      </Wrapper>
    </CPWrapper>
  );
};
