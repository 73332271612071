import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { getWarningMessage } from '../../../helpers/checklistHelpers';
import { dealBoardConfigState } from '../../../state/DealboardDataState';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { GeneralInfo2 } from './GeneralInfo2';
import { FinancialInfo2 } from './FinancialInfo2';
import { AdditionalInfo2 } from './AdditionalInfo2';

export const DATA_SECTION_WIDTH = '28.125rem';

const Container = styled('div')`
  display: grid;
  width: ${DATA_SECTION_WIDTH};
  gap: 0.75rem;
`;
const Section = styled('section')`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral[2]};
  padding: 1rem;
`;

const DrawerSection = styled(Section)`
  display: grid;
  grid-template-columns: 6.875rem 1fr;
  gap: 0.5rem;
  align-items: center;
  & label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const WarningMessageWrapper = styled('div')`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.warning[10]};
  padding: 8px 16px;
`;

interface IDataSectionProps {
  deal: IDealDataModel;
  company: ICompanyDataModel;
}

export const DataSection = ({ deal, company }: IDataSectionProps) => {
  const dealBoardConfig = useRecoilValue(dealBoardConfigState);
  const { colors } = useTheme();
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const warningMessage = useMemo(() => {
    if (!dealBoardConfig) return null;
    return getWarningMessage({ dealBoardConfig, deal });
  }, [deal, dealBoardConfig]);

  return (
    <Container key={deal.id}>
      {warningMessage && (
        <WarningMessageWrapper>
          <Typography component='span' variant='caption' color={colors.warning[60]}>
            {warningMessage}
          </Typography>
        </WarningMessageWrapper>
      )}
      <DrawerSection>
        <GeneralInfo2 deal={deal} company={company} disableEdit={!canEditDeal} />
      </DrawerSection>
      <DrawerSection>
        <FinancialInfo2 deal={deal} disableEdit={!canEditDeal} />
      </DrawerSection>
      <DrawerSection>
        <AdditionalInfo2 deal={deal} />
      </DrawerSection>
    </Container>
  );
};
