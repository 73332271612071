import { atom, selector } from 'recoil';
import { ANALYSIS_TYPE } from '../../../types';
import { ICompanySearchResponseDataModel } from '../../../data-models/company-search-result.data-model';
import { primaryCompanyCI, primaryCompanyIdCI } from '../../CompetitiveIntelligence/state/DashboardsState';
import { companyDataModelToSearchResultDataModel, companyToSearchResult } from '../../../util/companyUtils';
import { competitorsIdsCI } from '../../CompetitiveIntelligence/state/CompaniesState';
import { companyState } from '../../../services/state/CompanyState';

export const selectedPrimaryCompanyCCState = selector<ICompanySearchResponseDataModel | undefined>({
  key: 'selectedPrimaryCompanyStateCC',
  get: async ({ get }) => {
    const primaryId = get(primaryCompanyIdCI);

    if (primaryId === undefined) {
      return undefined;
    }

    if (companySearchResultCache.has(primaryId)) {
      return companySearchResultCache.get(primaryId);
    } else {
      return companyDataModelToSearchResultDataModel(get(primaryCompanyCI));
    }
  },
});

export const selectedCompetitorsCCState = selector<ICompanySearchResponseDataModel[]>({
  key: 'selectedCompetitorsCCState',
  get: async ({ get }) => {
    const competitorsIds = get(competitorsIdsCI) ?? [];
    const allInCache = competitorsIds.every((companyId) => companySearchResultCache.has(companyId));

    if (allInCache) {
      return competitorsIds.map((companyId) => companySearchResultCache.get(companyId)!);
    }

    const competitors = competitorsIds.map((companyId) => get(companyState(companyId)));
    return competitors.map((company) => companyToSearchResult(company));
  },
});

export const selectedAnalysisTypeState = atom<string>({
  key: 'selectedAnalysisType',
  default: ANALYSIS_TYPE.GROWTH_ANALYSIS,
});

export const selectedStepState = atom<number>({
  key: 'selectedStepState',
  default: 1,
});

/* 
We rely on calls to `company/:id` to get company data at load, if we were to rely
on it in all the components in this page, that is useRecoilValue(primaryCompanyCI)
instead of  useRecoilValue(selectedPrimaryCompanyCCState) then there will always
be an unwanted async call triggered AFTER a company was selected which would trigger suspense.
Hence, by checking against the cache below first we can avoid that.

Will try to think of a simpler approach...
*/
export const companySearchResultCache = new Map<number, ICompanySearchResponseDataModel>();
export function addAllToCompanyResultCache(res: ICompanySearchResponseDataModel[]) {
  res.forEach((company) => {
    companySearchResultCache.set(Number(company.id), company);
  });
}
