import { ColGroupDef } from 'ag-grid-community';
import { capitalize, groupBy, set } from 'lodash-es';
import {
  ShareClass,
  getInvestmentKey,
  getInvestmentNameAndType,
} from '../../../view-models/captable.view-model';
import { getAgColDefFromGridField } from '../../../components/AgTable/cell-editors/AgColDefFactory';
import { RendererType } from '../../../data-models/field.data-model';
import { ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { createGridField } from '../../../view-models/grid.view-model';
import { FDMap } from '../../../util/data-structure/FDMap';
import { getInputLabelByInvestmentType } from './OtherInvestmentsDefs';

export function getGroupedInvestmentsColDefs(shareClasses: ShareClass[]): ColGroupDef[] {
  const shareClassesByType = shareClasses.reduce((map, shareClass) => {
    return map.setOrUpdate(shareClass.type, [shareClass], (currentValue) => {
      return [...currentValue, shareClass];
    });
  }, new FDMap<ViewModelInvestmentType, ShareClass[]>());

  const investmentDefs: ColGroupDef[] = [];
  shareClassesByType.forEach((shareClasses, type) => {
    const shareClassesColDefs = shareClasses.map((shareClass) => {
      return {
        ...getAgColDefFromGridField(
          createGridField({
            key: getInvestmentKey(shareClass),
            renderer: RendererType.number,
            formatter: 'integer',
            editable: true,
            label: `${shareClass.name} ${getInputLabelByInvestmentType(shareClass.type)}`,
          })
        ),
        filter: 'agNumberColumnFilter',
        headerClass: 'investment-header',
      };
    });

    investmentDefs.push({
      headerName: capitalize(type),
      headerClass: 'type-header',
      children: shareClassesColDefs,
    });
  });
  return investmentDefs;
}
export function updateInvestmentRows<T extends object>(
  rows: T[],
  includedShareClasses: ShareClass[],
  mainFieldKey: keyof T
) {
  const shareClassesByGroupByName = includedShareClasses.reduce((map, shareClass) => {
    return map.setOrUpdate(
      shareClass.type,
      new FDMap<string, ShareClass>([[shareClass.name, shareClass]]),
      (currentValue) => {
        return currentValue.set(shareClass.name, shareClass);
      }
    );
  }, new FDMap<ViewModelInvestmentType, FDMap<string, ShareClass>>());

  const result = rows.reduce((acc, row) => {
    if (row[mainFieldKey] === null || row[mainFieldKey] === undefined) return acc;

    const updatedRow = { ...row };

    Object.keys(row).forEach((key) => {
      const { name: shareClassName, type: shareClassType } = getInvestmentNameAndType(key);
      if (
        !shareClassesByGroupByName.get(shareClassType as ViewModelInvestmentType)?.has(shareClassName) &&
        key !== mainFieldKey
      ) {
        delete updatedRow[key as keyof typeof updatedRow];
      }
    });

    shareClassesByGroupByName.forEach((shareClassesByName) => {
      shareClassesByName.forEach((shareClass) => {
        const key = getInvestmentKey(shareClass);
        set(updatedRow, key, row[key as keyof typeof row] ?? null);
      });
    });

    acc.push(updatedRow);
    return acc;
  }, [] as T[]);

  return result;
}
const sortOrderByInvestmentType: Record<ViewModelInvestmentType, number> = {
  [ViewModelInvestmentType.preferred]: 0,
  [ViewModelInvestmentType.notes]: 1,
  [ViewModelInvestmentType.common]: 2,
  [ViewModelInvestmentType.options]: 3,
  [ViewModelInvestmentType.warrants]: 4,
};

export interface ISortableCaptableInvestment extends Pick<ShareClass, 'type' | 'investmentDate' | 'name'> {}
function sortInvestmentsOfType<T extends ISortableCaptableInvestment>(investments: T[]): T[] {
  if (investments.every((sc) => sc.investmentDate)) {
    return [...investments].sort((a, b) => {
      const res = a.investmentDate!.getTime() - b.investmentDate!.getTime();
      if (res === 0) {
        return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
      } else {
        return res;
      }
    });
  } else {
    return [...investments].sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
    });
  }
}

export function sortShareClasses<T extends ISortableCaptableInvestment>(shareClasses: T[]): T[] {
  const sortedByType = [...shareClasses].sort((a, b) => {
    return sortOrderByInvestmentType[a.type] - sortOrderByInvestmentType[b.type];
  });
  const result = new Map<ViewModelInvestmentType, T[]>();
  FDMap.fromObject(groupBy(sortedByType, (sc) => sc.type)).forEach((value, key) => {
    result.set(key as ViewModelInvestmentType, sortInvestmentsOfType(value));
  });
  return Array.from(result.values()).flat();
}
