import { AxiosRequestConfig } from 'axios';
import { IProviderConfigDataModel } from '../../data-models/provider-config.data-model';
import { IProviderFieldsDataModel } from '../../data-models/provider-fields.data-model';
import { MaggieHttp } from '../MaggieHttp';

export async function getProviderConfig() {
  const { data } = await MaggieHttp.maggie.get<IProviderConfigDataModel<unknown>[]>(`/providerConfig`);

  return data;
}

export async function getProviderFields<T>(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<IProviderFieldsDataModel<T>[]>(
    `/company/${companyId}/providers`
  );

  return data;
}

export async function putProviderFields<T>(
  fieldData: Partial<IProviderFieldsDataModel<T>>,
  params?: AxiosRequestConfig
) {
  const payload = preparePutPayload(fieldData);
  const { data } = await MaggieHttp.maggie.put<
    Partial<IProviderFieldsDataModel<T>>,
    IProviderFieldsDataModel<T>
  >(`/company/providers/${fieldData.id}`, payload, params);

  return data;
}

function preparePutPayload<T>(payload: Partial<IProviderFieldsDataModel<T>>) {
  const result = { ...payload };
  delete result.createdAt;
  delete result.updatedAt;
  delete result.id;
  delete result.companyId;
  delete result.sources;

  return result;
}
