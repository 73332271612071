import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import {
  investmentTypeField,
  shareClassNameField,
  ppsField10dp,
  ViewModelInvestmentType,
} from './common-schema-defs';
import { abstractShareClassSchema } from './AbstractShareClass.schema';

export const OptionsSchema = {
  create() {
    return abstractShareClassSchema().shape({
      name: shareClassNameField().default('Options'),
      type: investmentTypeField().default(ViewModelInvestmentType.options),
      strikePrice: ppsField10dp().required().label('Strike Price').default(0.01),
      authorizedShares: yup.number().nullable().optional().label('Authorized Shares').default(null).meta({
        renderer: RendererType.integer,
      }),
      totalOptionPool: yup
        .number()
        .nullable()
        .required()
        .label('Number of Unissued Options')
        .default(0)
        .meta({
          renderer: RendererType.integer,
        }),
      investmentDate: yup.date().nullable().optional().label('Investment Date').default(null).meta({
        renderer: RendererType.date,
      }),
    });
  },
};

export type Option = yup.InferType<ReturnType<typeof OptionsSchema.create>>;
export function createOption(overrides: Partial<Option> = {}): Option {
  return OptionsSchema.create().cast(overrides);
}
