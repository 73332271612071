export const UploadStatus = {
  uploading: 'Uploading',
  success: 'Success',
  error: 'Error',
};
export type UploadStatus = (typeof UploadStatus)[keyof typeof UploadStatus];

export const BulkUploadStatusEvent = 'processBulkUpload';

export interface IBulkUploadStatusMessageDataModel {
  fileName: string;
  uploadId: number;
  note?: string; // currently only comes back from BE for failed status
  status: UploadStatus;
}
