import { Alert, AlertProps, AlertTitle, styled, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useMemo } from 'react';

export interface IAlertMUIProps extends AlertProps {
  title?: string;
  children?: React.ReactNode | null;
}

const StyledAlert = styled(Alert)`
  &.MuiAlert-standardInfo {
    background-color: ${({ theme }) => theme.colors.secondary[5]};
  }
`;

export default function AlertMUI({ title, severity, children = null, ...rest }: IAlertMUIProps) {
  const { colors } = useTheme();

  const iconMapping = useMemo(
    () => ({
      info: <InfoIcon style={{ color: colors.neutral[60] }} />,
    }),
    [colors.neutral]
  );

  return (
    <StyledAlert severity={severity} iconMapping={iconMapping} {...rest}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  );
}
