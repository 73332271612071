import { Typography, styled } from '@mui/material';

export const MinRowHeight = '3rem';

export const Summary = styled('div')`
  display: grid;
  padding-top: 0.5rem;
  align-items: start;
  align-content: start;
  gap: 0.75rem;

  & .highlight {
    background-color: ${({ theme }) => theme.colors.secondary[5]};
  }
`;

export const SummarySection = styled('section')`
  border: thin solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 4px;
  min-height: ${MinRowHeight};
  width: 100%;
`;

export const SummaryRow = styled('div')`
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: 15rem 10rem;
  min-height: ${MinRowHeight};
  align-items: center;
  &:not(:last-of-type) {
    border-bottom: thin solid ${({ theme }) => theme.colors.neutral[20]};
  }
`;

export const InvestmentSummary = styled(SummaryRow)`
  grid-template-columns: max-content auto;
  column-gap: 2rem;
`;

export const SummaryRowValue = styled(Typography)`
  justify-self: end;
`;

export const FundName = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
