import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { MUIAvatar } from '../Avatar/MUIAvatar';

interface IProps {
  createdAt: Date;
  authorFirstName: string;
  authorLastName: string;
  isYou?: boolean;
  isLoading?: boolean;
}

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`;

export function CommentHeader({
  authorFirstName,
  authorLastName,
  createdAt,
  isYou = false,
  isLoading = false,
}: IProps) {
  const { neutral, primary } = useTheme().colors;
  const dateFormatted = useMemo(() => format(createdAt, 'LLL dd, hh:mm a'), [createdAt]);

  return (
    <Header data-testid={'comment-header'}>
      <MUIAvatar nameParts={[authorFirstName, authorLastName]} src={null} />
      <Typography variant={'caption'} color={isLoading ? neutral['40'] : primary[100]}>
        {authorFirstName} {authorLastName} {isYou && '(You)'}
      </Typography>
      <Typography variant={'caption'} color={neutral[60]}>
        {dateFormatted}
      </Typography>
    </Header>
  );
}
