import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import {
  formatCategoryName,
  getIdsAndSources,
  IMappingsRowDataModel,
  isPrimaryWebsite,
  isSecondaryWebsite,
} from '../mappingDataUtils';

import { MappingsDataRenderer } from '../MappingsDataRenderer';
// eslint-disable-next-line import/order
import { selectedMappingsCompanies } from '../../state/MappingsState';

const commonCompanyColDefs = {
  filter: 'agTextColumnFilter',
};

export function useMappingGridColDefs() {
  const selectedCompanies = useRecoilValue(selectedMappingsCompanies);

  const companyColDefs: ColDef<IMappingsRowDataModel>[] = useMemo(
    () =>
      selectedCompanies.map((company) => {
        return {
          headerName: company.fields.name,
          colId: String(company.id),
          ...commonCompanyColDefs,
          valueGetter: (params: ValueGetterParams<IMappingsRowDataModel>) => {
            const { data } = params;
            if (!data) return null;
            const companyId = Number(company.id);
            if (isPrimaryWebsite(data)) return data[companyId]?.primary;
            else if (isSecondaryWebsite(data)) return data[companyId]?.secondary;
            else return getIdsAndSources(data[companyId]);
          },
          cellRenderer: MappingsDataRenderer,
          sortable: false,
        };
      }),
    [selectedCompanies]
  );

  const colDefs: ColDef<IMappingsRowDataModel>[] = useMemo(
    () => [
      {
        headerName: 'Provider',
        field: 'displayName',
        sortable: false,
      },
      {
        headerName: 'Category',
        field: 'category',
        rowGroup: true,
        valueFormatter: (params) => (!params?.value ? '' : formatCategoryName(params.value)),
        hide: true,
      },
      ...companyColDefs,
    ],
    [companyColDefs]
  );

  return colDefs;
}
