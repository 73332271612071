import { MaggieHttp } from '../MaggieHttp';
import { NoteComment } from '../../data-models/note.data-model';
import {
  NoteCommentCreationPayload,
  NoteCommentUpdatePayload,
} from '../../data-models/note-comment.data-model';

export async function fetchCommentsByNoteId(noteId: number) {
  const { data } = await MaggieHttp.maggie.get<NoteComment[]>(`noteComment?noteId=${noteId}`);

  return data;
}

export const createNoteComment = async (commentPayload: NoteCommentCreationPayload) => {
  const { data } = await MaggieHttp.maggie.post<NoteCommentCreationPayload, NoteComment>(
    '/noteComment',
    commentPayload
  );

  return data;
};

export const updateNoteComment = async ({ noteId, comment, commentId }: NoteCommentUpdatePayload) => {
  const { data } = await MaggieHttp.maggie.put<NoteCommentCreationPayload, NoteComment>(
    `/noteComment/${commentId}`,
    {
      noteId,
      comment,
    }
  );

  return data;
};

export const deleteNoteComment = (commentId: number) => {
  return MaggieHttp.maggie.delete<NoteComment>(`/noteComment/${commentId}`);
};
