import * as yup from 'yup';
import {
  requiredMsg,
  urlMsg,
} from '../../../Finance/components/TransactionModal/Forms/utils/validationMessages';
import { VALID_URL_REGEX } from '../../../../util/regex';

export const CreateListItemSchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')),
  website: yup.string().optional().nullable().matches(VALID_URL_REGEX, urlMsg('Website')),
  sectorId: yup.number().required(requiredMsg('Sector')).nullable(),
  assignee: yup.number().optional().nullable(),
});
