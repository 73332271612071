import { FC } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { plainDateToZonedDateTime } from '../../../util/date-utilts';
import { calendarShortcutToValueMap, shortcutsSx } from '../../DatePicker/Calendar';
import { IDateMeta } from '../../../data-models/field3.data-model';
import { formTextFieldInlineClassNames } from '../../../theme/component-styles';
import { IBaseFieldProps } from './types';

/**
 * The MUI date picker has a limit of 100 years in the past / future by default, see:
 * https://github.com/mui/mui-x/issues/7072
 */
export const FieldDate: FC<IBaseFieldProps<IDateMeta>> = ({ formField, formProps }) => {
  const { ref, onChange, onBlur, value } = formProps;
  const { disabled = false, fullWidth = false, variant } = formField ?? {};
  const { minimum, maximum, presets } = formField.rendererMeta ?? {};
  const className = variant === 'form-inline' ? `${formTextFieldInlineClassNames} form-date-picker` : '';
  const shortcutItems = presets?.map((preset) => ({
    label: preset,
    getValue: calendarShortcutToValueMap[preset],
  }));

  let dateValue = value;
  if (typeof value === 'string') {
    dateValue = plainDateToZonedDateTime(value);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        defaultValue={dateValue}
        disabled={disabled}
        maxDate={maximum ? new Date(maximum) : undefined}
        minDate={minimum ? new Date(minimum) : undefined}
        onAccept={onChange}
        onChange={onChange}
        onClose={onBlur}
        views={['year', 'month', 'day']}
        slotProps={{
          shortcuts: {
            items: shortcutItems,
            sx: shortcutsSx,
          },
          textField: {
            className,
            onBlur,
            ref,
            placeholder: variant === 'form-inline' ? '' : undefined,
            fullWidth,
          },
        }}
      />
    </LocalizationProvider>
  );
};
