import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, useTheme, Typography } from '@mui/material';
import { HourglassBottomTwoTone } from '@mui/icons-material';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useUpdateDeal } from '../../../../services/queries-hooks/MaggieDealQueriesHooks';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { queryClient } from '../../../../services/MaggieQueryClient';
import { DEALS } from '../../../../services/queries/MaggieDealQueries';
import { MuiMultiselect } from '../../../../components/MuiMultiselect/MuiMultiselect';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { selectedDealState, selectedFieldState } from '../../state/DealboardUIState';
import { formatDate, formatDateShort, getDaysBetween } from '../../../../util/formatters/DateFormatters';
import {
  dealBoardConfigState,
  dealStagesByNameMapState,
  dealStagesIdsState,
} from '../../state/DealboardDataState';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { EditableField } from './DataSection/EditableField';

const WarningWrapper = styled('div')`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary[5]};
  padding: 16px;
`;
const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;
const MainInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const SnoozeDateWrapper = styled('div')`
  margin-top: 8px;
`;
const InputWrapper = styled('div')`
  width: 300px;
`;
const IconWrapper = styled('div')`
  margin-right: 10px;
  margin-bottom: -3px;
`;
const ReasonWrapper = styled('div')`
  padding-top: 5px;
  padding-right: 6px;
`;

export const StageStatusBar: FC = () => {
  const selectedDeal = useRecoilValue(selectedDealState);
  const stagesMap = useRecoilValue(dealStagesByNameMapState);
  const [activeField, setActiveField] = useRecoilState(selectedFieldState);
  const stageIds = useRecoilValue(dealStagesIdsState);
  const dealBoardConfig = useRecoilValue(dealBoardConfigState);
  const { passReasons } = dealBoardConfig;
  const [stageComments, setStageComments] = useState(
    selectedDeal?.stageComments ?? selectedDeal?.trackComments ?? ''
  );
  const [passComments, setPassComments] = useState(selectedDeal?.passComments ?? '');
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);
  const [isLoadingStageCommentsField, setIsLoadingStageCommentsField] = useState(false);
  const [isLoadingPassCommentsField, setIsLoadingPassCommentsField] = useState(false);
  const { pushErrorToast } = useToastMessageState();
  const { mutateAsync: updateDealAsync } = useUpdateDeal();
  const { colors } = useTheme();
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const isTrackedDeal = useMemo(() => {
    return selectedDeal?.stageId === stagesMap.get('Track')!.id;
  }, [selectedDeal?.stageId, stagesMap]);

  const isPassedDeal = useMemo(() => {
    return selectedDeal?.stageId === stagesMap.get('Pass')!.id;
  }, [selectedDeal?.stageId, stagesMap]);

  const isMissedDeal = useMemo(() => {
    return selectedDeal?.stageId === stagesMap.get('Missed')!.id;
  }, [selectedDeal?.stageId, stagesMap]);

  const daysBetweenLabel = useMemo(() => {
    if (!selectedDeal || !isTrackedDeal) return '';
    const days = getDaysBetween(selectedDeal?.snoozeDate as string, new Date(Date.now()).toISOString());
    if (days < 0) return '';
    if (days === 0) return 'today';
    if (days === 1) return `tomorrow`;
    return `in ${days} days`;
  }, [isTrackedDeal, selectedDeal]);

  const mainTitle = useMemo(() => {
    if (isTrackedDeal) return 'This deal was tracked.';
    if (isPassedDeal) return 'This deal was passed.';
    if (isMissedDeal) return 'This deal was missed.';
    return '';
  }, [isMissedDeal, isPassedDeal, isTrackedDeal]);

  const onUpdateDealStageComments = async () => {
    if (!selectedDeal) return;
    if (!stageComments) {
      setStageComments(selectedDeal.stageComments as string);
      return;
    }
    setActiveField(null);
    setIsLoadingStageCommentsField(true);
    const updatedDeal: IDealDataModel = { ...selectedDeal, stageComments };
    await updateDealAsync(
      { payload: updatedDeal, stageIds },
      {
        onError: () => {
          pushErrorToast({ message: `An error occurred while trying to update a deal` });
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [DEALS, { stageIds }] });
        },
        onSettled: () => {
          setIsLoadingStageCommentsField(false);
        },
      }
    );
  };

  const onUpdateDealPassComments = async () => {
    if (!selectedDeal) return;
    setActiveField(null);
    setIsLoadingPassCommentsField(true);
    const updatedDeal: IDealDataModel = { ...selectedDeal, passComments };
    await updateDealAsync(
      { payload: updatedDeal, stageIds },
      {
        onError: () => {
          pushErrorToast({ message: `An error occurred while trying to update a deal` });
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [DEALS, { stageIds }] });
        },
        onSettled: () => {
          setIsLoadingPassCommentsField(false);
        },
      }
    );
  };

  const handleOnInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, field: string) => {
    e.stopPropagation();
    if (e.code !== 'Enter') return;
    if (field === 'stageComments') onUpdateDealStageComments();
    if (field === 'passComments') onUpdateDealPassComments();
  };

  const items = useMemo(() => {
    if (isMissedDeal && dealBoardConfig)
      return dealBoardConfig.missedReasons.map(({ id, reason: value }) => ({ id, value }));
    if (isPassedDeal) return passReasons.map(({ id, reason: value }) => ({ id, value }));
    return [];
  }, [dealBoardConfig, isMissedDeal, isPassedDeal, passReasons]);

  const onOptionsChange = (e: SyntheticEvent, newValues: Option[] | undefined) => {
    setSelectedItems(newValues ?? []);
    const newStageComments = newValues?.map(({ value }) => value).join(',');
    setStageComments(newStageComments as string);
  };

  useEffect(() => {
    if (!isPassedDeal && !isMissedDeal) return;
    const preselectedOptions: Option[] =
      selectedDeal?.stageComments?.split(',').map((reason) => {
        return { id: reason, value: reason };
      }) ?? [];

    setSelectedItems(preselectedOptions);
  }, [dealBoardConfig?.missedReasons, isMissedDeal, isPassedDeal, passReasons, selectedDeal?.stageComments]);

  const stageCommentsFormatted = useMemo(() => {
    if (isTrackedDeal) return stageComments;
    return stageComments.split(',').join(', ');
  }, [stageComments, isTrackedDeal]);

  if (!isTrackedDeal && !isPassedDeal && !isMissedDeal) return null;
  if (!selectedDeal) return null;

  return (
    <WarningWrapper>
      <MainInfoWrapper>
        <TitleWrapper>
          <IconWrapper>
            {isTrackedDeal && <HourglassBottomTwoTone fontSize='small' style={{ marginTop: '-3px' }} />}
            {isMissedDeal && <CallMissedOutgoingIcon fontSize='small' style={{ marginTop: '-3px' }} />}
            {isPassedDeal && <DangerousOutlinedIcon fontSize='small' style={{ marginTop: '-3px' }} />}
          </IconWrapper>
          <Typography component='span' variant='subtitle2' color={colors.primary[100]}>
            {mainTitle}
          </Typography>
        </TitleWrapper>
        <Typography component='span' variant='caption' color={colors.neutral[60]}>
          {formatDateShort(selectedDeal.stageUpdateDate)}
        </Typography>
      </MainInfoWrapper>
      <EditableField
        disabled={!canEditDeal}
        width='fit-content'
        isActive={activeField === 'stageComments'}
        activeField={activeField}
        setActiveField={setActiveField}
        name={'stageComments'}
        onUpdate={onUpdateDealStageComments}
        isLoading={isLoadingStageCommentsField}
        inputComponent={
          <InputWrapper>
            {isTrackedDeal && (
              <TextField
                fullWidth
                placeholder='Write the Reason'
                value={stageComments}
                onChange={(e) => setStageComments(e.target.value)}
                onKeyDown={(e) => handleOnInputKeyDown(e, 'stageComments')}
                variant='outlined'
                autoFocus
              />
            )}
            {(isPassedDeal || isMissedDeal) && (
              <MuiMultiselect
                fieldPlaceholder='Select the Reason'
                options={items}
                value={selectedItems}
                onChange={onOptionsChange}
                onFocus={(e) => e.stopPropagation()}
                autofocus
                openOnFocus
              />
            )}
          </InputWrapper>
        }
      >
        <ReasonWrapper>
          <Typography
            variant='body2'
            color={stageCommentsFormatted ? colors.primary[100] : colors.neutral[30]}
          >
            {stageCommentsFormatted ? stageCommentsFormatted : 'Select the Reason'}
          </Typography>
        </ReasonWrapper>
      </EditableField>
      {(isPassedDeal || isMissedDeal) && (
        <EditableField
          disabled={!canEditDeal}
          width='fit-content'
          isActive={activeField === 'passComments'}
          activeField={activeField}
          setActiveField={setActiveField}
          name={'passComments'}
          onUpdate={onUpdateDealPassComments}
          isLoading={isLoadingPassCommentsField}
          inputComponent={
            <InputWrapper>
              <TextField
                fullWidth
                placeholder={canEditDeal ? 'Add a comment' : ''}
                value={passComments}
                onChange={(e) => setPassComments(e.target.value)}
                onKeyDown={(e) => handleOnInputKeyDown(e, 'passComments')}
                variant='outlined'
                autoFocus
              />
            </InputWrapper>
          }
        >
          <ReasonWrapper>
            <Typography variant='body2' color={passComments ? colors.primary[100] : colors.neutral[30]}>
              {passComments ? passComments : canEditDeal ? 'Add a comment' : ''}
            </Typography>
          </ReasonWrapper>
        </EditableField>
      )}

      {isTrackedDeal && (
        <SnoozeDateWrapper>
          <Typography variant='body2' color={colors.primary[100]}>
            {daysBetweenLabel &&
              `Move back to Current ${daysBetweenLabel} (${formatDate(selectedDeal.snoozeDate as string)})`}
          </Typography>
        </SnoozeDateWrapper>
      )}
    </WarningWrapper>
  );
};
