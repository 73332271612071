import { useCallback } from 'react';
import { useFundIdField, useGetCurrencyIdField } from '../../../data-fields/CommonFields';
import { transactionDateField } from '../../../data-fields/TransactionFields';

// most transaction forms have these three fields as the first subsection
export function useFundDateCurrencyFields() {
  const fundIdField = useFundIdField();
  const getCurrencyIdField = useGetCurrencyIdField();

  return useCallback(() => {
    return [fundIdField, transactionDateField(), getCurrencyIdField('currencyid', 'Currency', true)];
  }, [fundIdField, getCurrencyIdField]);
}
