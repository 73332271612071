import {
  IFundLimitDataModel,
  IMetricsConfigDataModel,
} from '../../../../data-models/metrics-config.data-model';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';

const GC_COMPANY_OTHER = new Set([54117, 475]);

export function customLabelsCalculator(
  config: IMetricsConfigDataModel,
  fundsInMetric: Set<number>,
  metric: IMetricsDataModel
): string {
  let label;
  let ruleMatched = false;

  if (GC_COMPANY_OTHER.has(metric.companyId)) {
    return 'Other';
  }

  const limitsByFundId = config.limitRules.reduce((res, limit) => {
    return res.set(limit.fundId, limit);
  }, new Map<number, IFundLimitDataModel>());

  if (config.limitRules.length > 0) {
    // Try all the rules
    limitsByFundId.forEach((limitRule) => {
      if (fundsInMetric.has(limitRule.fundId)) {
        ruleMatched = true;
        const amountInvestedValue = metric.fundData.find((f) => {
          return f.fundId === limitRule.fundId;
        })?.amountInvested;
        if (amountInvestedValue && amountInvestedValue > limitRule.value) {
          label = limitRule.overLabel;
        }
      }
    });
  }

  // Only try the default rule is no other were matched.
  if (!ruleMatched && config.defaultRule) {
    const { overLabel, value } = config.defaultRule;
    label = metric.fundData.some((f) => {
      return f.amountInvested && f.amountInvested > value;
    })
      ? overLabel
      : undefined;
  }

  return label ?? config.defaultLabel ?? '';
}
