import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';
import { AnyObject, AnySchema, ObjectSchema } from 'yup';
import * as yup from 'yup';
import { transactionTypesMapState } from '../../services/state/AppConfigState';
import { useSchemaToGrid } from '../../util/schema-utils';
import { TRANSACTION_SUB_TYPES } from '../../types';
import { escrowGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Escrow/EscrowSchema';
import { escrowWriteOffGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Escrow/EscrowWriteOffSchema';
import { eqBuyGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';
import { eqSellGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Equity/EquitySellSchema';
import { eqShareExchangeGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Equity/EquityShareExchangeSchema';
import { eqSecondaryPurchaseGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Equity/EquitySecondaryPurchaseSchema';
import { capitalCallGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Fund/CapitalCallSchema';
import { noteIssuanceGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Note/NoteIssuanceSchema';
import { noteConversionGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Note/NoteConversionSchema';
import { noteSafeConversionGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Note/NoteSafeConversionSchema';
import { noteSafeIssuanceGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Note/NoteSafeIssuanceSchema';
import { optionIssuanceGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Option/OptionIssuanceSchema';
import { optionExerciseGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Option/OptionExerciseSchema';
import { tokenSaftGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Token/TokenSaftSchema';
import { tokenBuyGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Token/TokenBuySchema';
import { tokenSaftConversionGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Token/TokenSaftConversionSchema';
import { miscInternalValuationFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Misc/MiscInternalValuationSchema';
import { stockIssuanceFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Misc/StockIssuanceSchema';
import { miscPaymentFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Misc/MiscPaymentSchema';
import { ownershipChangeFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Misc/OwnershipChangeSchema';
import { miscWriteOffFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Misc/MiscWriteOffSchema';
import { warrantExpirationFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantExpirationSchema';
import { warrantExerciseFormSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantExerciseSchema';
import { WarrantsType } from '../../view-models/transaction-form.view-model';
import { warrantIssuanceGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Warrant/WarrantIssuanceSchema';
import { noteExpirationGridSchema } from '../../pages/Finance/components/TransactionModal/Forms/schemas/Note/NoteExpirationSchema';
import { ITransactionDataModel } from '../../data-models/transaction.data-model';
import { withOverrides } from '../../util/ag-grid-utils';
import { getMenuColDef } from '../../pages/Finance2/Grids/useTransactionColDefs';
import { TransactionsCommonSchemas } from './TransactionsCommon.schema';
getMenuColDef;

const colDefOverrides: Record<string, ColDef<ITransactionDataModel>> = {
  transactionDate: {
    sortable: true,
    initialSort: 'desc',
    sortIndex: 0,
  },
  id: {
    sortable: true,
    hide: true,
    sort: 'asc',
    sortIndex: 1,
  },
};

const fieldsSortOrder = new Map([
  ['transactionDate', 0],
  ['fundId', 1],
  ['transactionTypeId', 2],
  ['investmentAmount', Number.MAX_SAFE_INTEGER - 1],
  ['stage', 4],
  ['status', 6],
  ['investmentRoundId', 5],
  ['notes', Number.MAX_SAFE_INTEGER],
]);

const commonExcludedFields: (keyof ITransactionDataModel)[] = ['currencyid', 'valuationImpact'];

function getExcludedFields(type?: TRANSACTION_SUB_TYPES): (keyof ITransactionDataModel)[] {
  if (type === TRANSACTION_SUB_TYPES.MISC_OWNERSHIP_CHANGE) {
    return commonExcludedFields;
  } else {
    return [...commonExcludedFields, 'fullyDilutedShares'];
  }
}

export function getTransactionGridSchemas(): Record<TRANSACTION_SUB_TYPES, AnySchema> {
  return {
    [TRANSACTION_SUB_TYPES.BUY]: eqBuyGridSchema(),
    [TRANSACTION_SUB_TYPES.CAPITAL_CALL]: capitalCallGridSchema(),
    [TRANSACTION_SUB_TYPES.ESCROW_RECEIVED]: escrowGridSchema(),
    [TRANSACTION_SUB_TYPES.ESCROW_WRITE_OFF]: escrowWriteOffGridSchema(),
    [TRANSACTION_SUB_TYPES.INTERNAL_VALUATION]: miscInternalValuationFormSchema(),
    [TRANSACTION_SUB_TYPES.MISC_OWNERSHIP_CHANGE]: ownershipChangeFormSchema(),
    [TRANSACTION_SUB_TYPES.MISC_PAYMENT]: miscPaymentFormSchema(),
    [TRANSACTION_SUB_TYPES.MISC_WRITE_OFF]: miscWriteOffFormSchema(),
    [TRANSACTION_SUB_TYPES.NOTE_CONVERSION]: noteConversionGridSchema(),
    [TRANSACTION_SUB_TYPES.NOTE_EXPIRATION]: noteExpirationGridSchema(),
    [TRANSACTION_SUB_TYPES.NOTE_ISSUANCE]: noteIssuanceGridSchema(),
    [TRANSACTION_SUB_TYPES.OPTION_EXERCISE]: optionExerciseGridSchema(),
    [TRANSACTION_SUB_TYPES.OPTION_ISSUANCE]: optionIssuanceGridSchema(),
    [TRANSACTION_SUB_TYPES.SAFE_CONVERSION]: noteSafeConversionGridSchema(),
    [TRANSACTION_SUB_TYPES.SAFE_EXPIRATION]: noteExpirationGridSchema(),
    [TRANSACTION_SUB_TYPES.SAFE_ISSUANCE]: noteSafeIssuanceGridSchema(),
    [TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE]: eqSecondaryPurchaseGridSchema(),
    [TRANSACTION_SUB_TYPES.SELL]: eqSellGridSchema(),
    [TRANSACTION_SUB_TYPES.SHARE_EXCHANGE]: eqShareExchangeGridSchema(),
    [TRANSACTION_SUB_TYPES.STOCK_ISSUANCE]: stockIssuanceFormSchema(),
    [TRANSACTION_SUB_TYPES.TOKEN_BUY]: tokenBuyGridSchema(),
    [TRANSACTION_SUB_TYPES.TOKEN_SAFT]: tokenSaftGridSchema(),
    [TRANSACTION_SUB_TYPES.TOKEN_SAFT_CONVERSION]: tokenSaftConversionGridSchema(),
    [TRANSACTION_SUB_TYPES.WARRANT_EXERCISE]: warrantExerciseFormSchema(),
    [TRANSACTION_SUB_TYPES.WARRANT_EXPIRATION]: warrantExpirationFormSchema(),
    [TRANSACTION_SUB_TYPES.WARRANT_ISSUANCE]: warrantIssuanceGridSchema(),
  };
}

function allTransactionsSchema() {
  return yup.object(TransactionsCommonSchemas.allTransactionsCommonFields());
}

function getFieldSortIndex(fieldName: string | undefined) {
  return fieldsSortOrder.get(fieldName ?? '') ?? Number.MAX_SAFE_INTEGER - 2;
}

export function useGetTransactionGridColdDefsForTypeId() {
  const transactionTypes = useRecoilValue(transactionTypesMapState);
  const schemaToGrid = useSchemaToGrid();

  return useCallback(
    (transactionTypeId: number) => {
      const { name } = transactionTypes.get(transactionTypeId) ?? {};
      const gridSchemas = getTransactionGridSchemas();
      const schema = gridSchemas[name!] ?? allTransactionsSchema();
      const commonFields = TransactionsCommonSchemas.allTransactionsCommonFields();

      const withCommonFields = (schema as ObjectSchema<AnyObject>)
        .shape(commonFields)
        .omit(getExcludedFields(name));

      const defs = schemaToGrid(withCommonFields);
      defs.unshift(getMenuColDef());
      defs.forEach((def) => {
        def.minWidth = 150;
        def.flex = 1;
      });
      defs.sort((def1, def2) => {
        return getFieldSortIndex(def1.field) - getFieldSortIndex(def2.field);
      });

      return withOverrides(defs, colDefOverrides);
    },
    [schemaToGrid, transactionTypes]
  );
}
export function validateRequiredForNoOfWarrants(
  value: number | null | undefined,
  context: yup.TestContext<yup.AnyObject>
) {
  if (context.parent?._viewModel?.warrantsType === WarrantsType.numberOfWarrants) {
    return value != null;
  } else {
    return true;
  }
}
