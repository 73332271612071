import { FORMAT_TEXT_COMMAND, LexicalEditor } from 'lexical';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';
import { Paper, IconButton as IconButtonMUI, styled } from '@mui/material';
import { useTextFormatFloatingToolbar } from './hooks/useTextFormatFloatingToolbar';

const CustomPaper = styled(Paper)`
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
`;

export const IconButton = styled(IconButtonMUI, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.neutral[10] : 'white')};
  border-radius: 0;
`;

export function TextFormatFloatingToolbar({
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  isBold: boolean;
  isCode: boolean;
  isItalic: boolean;
  isLink: boolean;
  isStrikethrough: boolean;
  isUnderline: boolean;
}) {
  const { insertLink, popupCharStylesEditorRef } = useTextFormatFloatingToolbar({
    editor,
    anchorElem,
    isLink,
  });

  return (
    <CustomPaper
      ref={popupCharStylesEditorRef}
      sx={{
        height: '50px',
        padding: 0,
      }}
    >
      {editor.isEditable() && (
        <>
          <IconButton
            isActive={isBold}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            aria-label='Format text as bold'
          >
            <FormatBoldIcon />
          </IconButton>
          <IconButton
            isActive={isItalic}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            aria-label='Format text as italics'
          >
            <FormatItalicIcon />
          </IconButton>
          <IconButton
            isActive={isUnderline}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            aria-label='Format text to underlined'
          >
            <FormatUnderlinedIcon />
          </IconButton>
          <IconButton
            isActive={isStrikethrough}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            aria-label='Format text with a strikethrough'
          >
            <FormatStrikethroughIcon />
          </IconButton>
          <IconButton
            isActive={isCode}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
            }}
            aria-label='Insert code block'
          >
            <CodeIcon />
          </IconButton>
          <IconButton isActive={isLink} onClick={insertLink} aria-label='Insert link'>
            <LinkIcon />
          </IconButton>
        </>
      )}
    </CustomPaper>
  );
}
