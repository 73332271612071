import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';

type Props = {
  name: string;
  logoUrl: string;
};

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const LogoWraper = styled('div')`
  margin-right: 10px;
  margin-top: 2px;
  place-items: center;
  min-height: 24px;
  min-width: 24px;
`;

const LogoImg = styled('img')`
  height: 24px;
  width: 24px;
`;

export const CompanyAndLogo = ({ name, logoUrl }: Props) => {
  const theme = useTheme();
  return (
    <Container>
      <LogoWraper>{logoUrl.length > 0 ? <LogoImg src={logoUrl} title='company_logo' /> : ''}</LogoWraper>
      <Typography variant='caption' color={theme.colors.primary[100]} noWrap>
        {name}
      </Typography>
    </Container>
  );
};
