import { TextField, useTheme, TextFieldProps } from '@mui/material';

export const DisabledTextField = ({ value, InputProps, ...rest }: TextFieldProps) => {
  const { colors } = useTheme();

  return (
    <TextField
      name='primary'
      disabled
      value={value}
      InputProps={{
        sx: {
          background: colors.neutral[10],
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.neutral[10]} !important`,
          },
        },
        ...InputProps,
      }}
      {...rest}
    />
  );
};
