import { atomFamily, selectorFamily } from 'recoil';
import { ICaptableDataModel } from '../../../data-models/captable2.data-model';
import { getCapTablesForCompany } from '../../../services/queries/MaggieCapTableQueries';

export const captablesByCompanyState = atomFamily<ICaptableDataModel[], number>({
  key: 'capTableState',
  default: selectorFamily({
    key: 'capTableState/Default',
    get: (companyId) => () => {
      return getCapTablesForCompany(companyId);
    },
  }),
});

export const primaryCaptableState = selectorFamily<ICaptableDataModel | null, number>({
  key: 'primaryCaptableState',
  get:
    (companyId) =>
    ({ get }) => {
      const captables = get(captablesByCompanyState(companyId));
      return captables.find((c) => c.primary) ?? null;
    },
});

export const primaryOrDefaultCaptableState = selectorFamily<ICaptableDataModel | null, number>({
  key: 'primaryOrDefaultCaptableState',
  get:
    (companyId) =>
    ({ get }) => {
      const captables = get(captablesByCompanyState(companyId));
      return get(primaryCaptableState(companyId)) ?? captables.at(0) ?? null;
    },
});

/** Includes only current captables for company, with the limit of one captable per source;
 * Stale captables can only be fetched by id
 */
export const companyCaptablesByIdState = selectorFamily<Map<number, ICaptableDataModel>, number>({
  key: 'companyCaptablesByIdState',
  get:
    (companyId) =>
    ({ get }) => {
      const captables = get(captablesByCompanyState(companyId));
      return new Map(captables.map((c) => [c.id, c]));
    },
});

export const selectedCaptableIdState = atomFamily<number | null, number>({
  key: 'selectedCaptableIdState',
  default: selectorFamily({
    key: 'selectedCaptableIdState/Default',
    get:
      (companyId) =>
      ({ get }) => {
        return get(primaryOrDefaultCaptableState(companyId))?.id ?? null;
      },
  }),
});

export const selectedCaptableState = selectorFamily<ICaptableDataModel | null, number>({
  key: 'selectedCaptableState',
  get:
    (companyId) =>
    ({ get }) => {
      const selectedCaptableId = get(selectedCaptableIdState(companyId));
      const captablesById = get(companyCaptablesByIdState(companyId));
      return captablesById.get(selectedCaptableId ?? -1) ?? null;
    },
});
