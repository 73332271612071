import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { updateGoogleDriveFolder } from '../../../services/queries/MaggieCompanyQueries';
import { companyState } from '../../../services/state/CompanyState';
import { getErrorMessage } from '../../../services/queryHelpers';

export function useUpdateGoogleDriveFolder() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ set }) =>
      async (companyId: number, folderId: string) => {
        try {
          updateGoogleDriveFolder(companyId, folderId);
          set(companyState(companyId), (current) => {
            if (!current) return current;
            return { ...current, googleDriveFolder: folderId };
          });
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Error updating Dropbox folder') });
        }
      },
    []
  );
}
