import { IDealDataModel } from '../../../../../data-models/deal.data-model';

export const dealInitialValues = {
  roundId: null,
  dealTypeId: null,
  fundIds: [],
  dealLeadId: null,
  dealTeamIds: [],
  labels: [],
  raiseAmount: null,
  gcAmountMin: null,
  gcAmountMax: null,
  totalPic: null,
  todoList: [],
} as unknown as Partial<IDealDataModel>;
