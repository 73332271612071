import { FC } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { IKeyResultDataModel } from '../../../../../data-models/key-result.data-model';
import { OkrForm } from './OkrForm';

interface OkrModalProps {
  open: boolean;
  onClose: () => void;
  initialValues?: IKeyResultDataModel;
}

export const OkrModal: FC<OkrModalProps> = ({ open, onClose }) => {
  return (
    <ModalWrapper title='Add Objective' open={open} onClose={onClose}>
      <OkrForm onClose={onClose} />
    </ModalWrapper>
  );
};
