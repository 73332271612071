import { atom } from 'jotai';
import { atomFamily, unwrap } from 'jotai/utils';
import { IField, isUniversalKPIField } from '../../../data-models/field2.data-model';
import {
  PanelPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../data-models/preferences-v3.data-model';

import {
  kpiConfigAtom,
  kpiConfigByKeyMapAtom,
  universalAndSystemKPIAtom,
} from '../../../services/state/KPIConfigState';
import { SelectItem } from '../../../types';
import { preferenceAtom } from '../../../util/preferences-service-v3';

export const availableKPIChartsAtom = atom((get) => {
  const kpiConfig = get(unwrap(kpiConfigAtom));
  return (
    kpiConfig?.filter(
      (kpi) =>
        kpi.type === 'number' &&
        kpi.formMeta?.renderer?.type !== 'date' &&
        kpi.formMeta?.formatter?.type !== 'date'
    ) ?? []
  );
});

const defaultKPIChartsAtom = atom((get) => {
  return get(availableKPIChartsAtom).filter(isUniversalKPIField);
});

export interface IKpiSelectItem extends SelectItem {
  id: number;
}

const selectedStatisticsByCompanyPrefAtom = atomFamily((companyId: number) => {
  return preferenceAtom<PanelPreferences>([
    {
      preferenceKey: `${PreferenceKey.companyPerformanceSelectedKPIs}.$${companyId}`,
      scope: PreferenceScope.ORGANIZATION,
    },
    {
      preferenceKey: `${PreferenceKey.companyPerformanceSelectedKPIs}.$default`,
      scope: PreferenceScope.ORGANIZATION,
    },
  ]);
});

export const selectedKPIMenuItemsAtom = atomFamily((companyId: number) =>
  atom(
    (get) => {
      const defaultValue = get(universalAndSystemKPIAtom).map((kpi) => kpi.entityField);
      const preference = get(selectedStatisticsByCompanyPrefAtom(companyId));
      const kpisByEntityField = get(unwrap(kpiConfigByKeyMapAtom)) ?? new Map();

      return kpiKeysToSelectItem(preference?.selectedFields ?? defaultValue, kpisByEntityField);
    },
    async (get, set, newValue: IKpiSelectItem[]) => {
      const kpiFields = await get(kpiConfigAtom);
      const kpiIdToEntityMap = kpiFields.reduce((acc, curr) => {
        acc.set(curr.id, curr.entityField);
        return acc;
      }, new Map<number, string>());

      const newSelectedFields = newValue
        .map(({ id }) => kpiIdToEntityMap.get(id) ?? null)
        .filter((v) => v !== null);

      set(selectedStatisticsByCompanyPrefAtom(companyId), { selectedFields: newSelectedFields });
    }
  )
);

const selectedKPIChartsByCompanyPrefAtom = atomFamily((companyId: number) =>
  preferenceAtom<PanelPreferences>([
    {
      preferenceKey: `${PreferenceKey.companyPerformanceSelectedKPICharts}.$${companyId}`,
      scope: PreferenceScope.ORGANIZATION,
    },
    {
      preferenceKey: `${PreferenceKey.companyPerformanceSelectedKPICharts}.$default`,
      scope: PreferenceScope.ORGANIZATION,
    },
  ])
);

export const selectedKPIChartsAtom = atomFamily((companyId: number) =>
  atom(
    (get) => {
      const defaultValue = get(defaultKPIChartsAtom);
      const kpisByKey = get(unwrap(kpiConfigByKeyMapAtom)) ?? new Map();
      const preference = get(selectedKPIChartsByCompanyPrefAtom(companyId));

      return preference?.selectedFields
        ? kpiKeysToFields(preference.selectedFields, kpisByKey)
        : defaultValue;
    },
    async (_get, set, newValue: IField<unknown>[]) => {
      const newSelectedFields = newValue.map(({ entityField }) => entityField);

      set(selectedKPIChartsByCompanyPrefAtom(companyId), { selectedFields: newSelectedFields });
    }
  )
);

function kpiKeysToSelectItem(fields: string[], kpisById: Map<string, IField<unknown>>) {
  return fields.reduce((curr, id) => {
    const field = kpisById.get(id);
    if (!field) return curr;
    return [
      ...curr,
      {
        id: field.id,
        value: field.displayName,
        group: field.gridMeta?.group,
      },
    ];
  }, [] as IKpiSelectItem[]);
}

function kpiKeysToFields(fields: string[], kpisByKey: Map<string, IField<unknown>>) {
  return fields.reduce((curr, id) => {
    const field = kpisByKey.get(id);
    if (!field) return curr;
    return [...curr, field];
  }, [] as IField<unknown>[]);
}
