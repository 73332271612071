import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../services/queryHelpers';
import { companyState } from '../../../services/state/CompanyState';
import { updateDropboxFolder } from '../../../services/queries/MaggieCompanyQueries';

export function useUpdateDropboxFolder() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ set }) =>
      async (companyId: number, folderName: string) => {
        try {
          const { dropboxUrl } = await updateDropboxFolder(companyId, { folder: folderName });
          set(companyState(companyId), (current) => {
            if (!current) return current;
            return { ...current, dropboxFolder: dropboxUrl };
          });
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Error updating Dropbox folder') });
        }
      },
    []
  );
}
