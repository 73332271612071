import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LoadingButton } from '@mui/lab';
import { Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LexicalEditor } from 'lexical';
import { useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { FieldDate } from '../../../../../components/Form/Display/FieldDate';
import ToolbarPlugin from '../../../../../components/LexicalEditor/plugins/ToolbarPlugin';
import '../../../../../components/LexicalEditor/editorStyles.css';
import DefaultTheme from '../../../../../components/LexicalEditor/themes/DefaultTheme';
import { dateField } from '../../../../../data-fields/CommonFields';
import { Commentary, NewCommentaryId } from '../../../../../schemas/Commentary.schema';
import { LoadingStatus } from '../../../../../types';
import { dateToPlainDateString, plainDateToZonedDateTime } from '../../../../../util/date-utilts';
import { isDrawerOpenState, selectedCommentaryState } from '../../../state/UIState';
import { ActivityHeader } from '../ActivityHeader';
import { useCreateOrUpdateCommentary } from './useCommentaryActions';

const EditorAndHeaderWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const ContentWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  padding: 1rem 2rem;
  height: 100%;
  overflow: hidden;
`;

export interface ICommentaryEditorProps {
  companyId: number;
  commentary: Commentary;
}

export function CommentaryEditor({ companyId, commentary }: ICommentaryEditorProps) {
  const editorRef = useRef<LexicalEditor>(null);
  const [selectedDate, setSelectedDate] = useState(plainDateToZonedDateTime(commentary.date));
  const [saveState, setSaveState] = useState(LoadingStatus.idle);
  const updateCommentary = useCreateOrUpdateCommentary();

  const dateFormField = dateField({
    key: 'asOfDate',
    label: 'As of',
    required: true,
  });
  dateFormField.disabled = commentary.id !== NewCommentaryId;
  dateFormField.fullWidth = true;

  const formProps = {
    onChange: (value) => {
      setSelectedDate(value);
    },
    value: selectedDate,
  } as ControllerRenderProps;

  const initialConfig: InitialConfigType = {
    editorState: commentary.text ?? undefined,
    editable: true,
    namespace: 'Commentary',
    onError: (error) => {
      console.error(error);
    },
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode],
    theme: DefaultTheme,
  };

  const saveChanges = () => {
    if (!editorRef.current) {
      return;
    }

    const newData = JSON.stringify(editorRef.current.getEditorState());

    setSaveState(LoadingStatus.loading);
    updateCommentary(companyId, {
      ...commentary,
      date: dateToPlainDateString(selectedDate),
      text: newData,
    }).then(() => {
      setSaveState(LoadingStatus.idle);
    });
  };

  return (
    <EditorAndHeaderWrapper>
      <CommentaryEditorHeader />
      <Divider />

      <ContentWrapper>
        <div>
          <FieldDate formField={dateFormField} formProps={formProps} />
          <Divider sx={{ marginTop: '1rem' }} />
        </div>

        <LexicalComposer initialConfig={initialConfig}>
          <EditorRefPlugin editorRef={editorRef} />
          <div className='commentary-editor-container'>
            <ToolbarPlugin />
            <div className='editor-inner'>
              <RichTextPlugin
                contentEditable={<ContentEditable className='editor-input' />}
                placeholder={<div className='editor-placeholder'>Enter commentary...</div>}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <AutoFocusPlugin />
            </div>
          </div>
        </LexicalComposer>

        <LoadingButton
          color={'secondary'}
          loading={saveState === LoadingStatus.loading}
          loadingPosition='end'
          onClick={saveChanges}
          variant='contained'
        >
          Save
        </LoadingButton>
      </ContentWrapper>
    </EditorAndHeaderWrapper>
  );
}

function CommentaryEditorHeader() {
  const setIsOpenDrawer = useSetRecoilState(isDrawerOpenState);
  const setSelectedCommentary = useSetRecoilState(selectedCommentaryState);
  const onClick = () => setSelectedCommentary(undefined);
  const onCloseClick = () => {
    setSelectedCommentary(undefined);
    setIsOpenDrawer(false);
  };

  return (
    <ActivityHeader
      onBackClick={onClick}
      onCloseClick={onCloseClick}
      title={
        <Typography variant='h6' fontSize={'16px'}>
          Performance Commentary
        </Typography>
      }
    />
  );
}
