import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useInvestmentAmount as useInvestmentAmountBySector } from '../../hooks/useInvestmentAmountBySector';
import { CHART_COMPONENTS } from '../../../../types';
import { PieChart } from '../../../../components/Charts/Piechart/PieChart';
import { sectorsColourState } from '../../../../services/state/SectorState';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const InvestmentAmountBySectorChart: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const { data, total } = useInvestmentAmountBySector(props.filteredMetrics);
  const sectorColours = useRecoilValue(sectorsColourState);

  return (
    <PieChart
      chartColors={sectorColours}
      data={data}
      id={CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_SECTOR}
      title={'Investment By Sector'}
      total={total}
    />
  );
};
