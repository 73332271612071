import { useRecoilCallback } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import { LoadingId, ToastProps } from '../../types';
import {
  createListItem,
  createListItemComment,
  deleteListItemComment,
  fetchListItems,
  requestAddListItemToPipeline,
  updateListItemComment,
} from '../queries/MaggieListQueries';
import {
  IListItemDataModel,
  ListActionType,
  ListItemsResponse,
} from '../../data-models/list-item.data-model';
import { companyListState, listsState } from '../../pages/CompanyProfiles/state/ListsState';
import { useLoadingBarState } from '../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { IListDataModel } from '../../data-models/list.data-model';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { getErrorMessage } from '../queryHelpers';
import { companyState } from '../state/CompanyState';
import { CompanySearchResponseWithRealId } from '../../schemas/CompanySearchResponse.schema';
import { useCurrentUser } from './useCurrentUser';

export function useAddListItemToPipeline() {
  const { actions } = useLoadingBarState();
  const navigate = useNavigate();
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const currentUser = useCurrentUser();

  return useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (listItem: IListItemDataModel, company: ICompanyDataModel) => {
        actions.startLoading(LoadingId.updateListItem);

        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(
            companyListState(listItem.listId),
            (current: Omit<ListItemsResponse, 'companies'> | null) => {
              if (current) {
                const newItems =
                  current.items?.map((item) => {
                    if (item.id !== listItem.id) {
                      return item;
                    }
                    return {
                      ...item,
                      actionType: 'pipeline' as ListActionType,
                      updatedAt: new Date().toISOString(),
                    };
                  }) ?? [];

                return {
                  items: newItems,
                  versions: current.versions,
                };
              }

              return current;
            }
          );
          mutable.set(companyState(listItem.companyId), (current) => {
            if (!current) return null;
            return { ...current, assignee: currentUser.id };
          });
          const allLists = mutable.getLoadable(listsState).valueMaybe();

          const pipelineListId = allLists?.find((list) => list.name === 'Pipeline')?.id;
          if (pipelineListId) {
            mutable.set(companyListState(pipelineListId), (current) => {
              if (!current) return null;
              return {
                ...current,
                items: [...(current.items ?? []), listItem],
                versions: current.versions,
              };
            });
          }
        });

        gotoSnapshot(updatedSnapshot);

        try {
          await requestAddListItemToPipeline(listItem.id);

          const toastConfig: ToastProps = {
            message: `Deal for ${company.name} successfully added to pipeline!`,
            buttonLabel: 'Go to Board',
            buttonAction: () => navigate('/deal-flow'),
          };
          pushInfoToast(toastConfig);
        } catch (err) {
          gotoSnapshot(snapshot);
          pushErrorToast({ message: 'An error occurred while trying to create a deal.' });
        } finally {
          actions.stopLoading(LoadingId.updateListItem);
        }
      },
    [actions, navigate, pushErrorToast, pushInfoToast]
  );
}

export function useListCommentActions() {
  const { pushErrorToast } = useToastMessageState();
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<number | null>(null);

  const createItemComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (listItem: IListItemDataModel, comment: string) => {
        if (!comment) return;
        setLoading(true);
        try {
          const { data } = await createListItemComment(listItem.companyId, comment);

          const updatedSnapshot = snapshot.map((mutable) => {
            const allLists = mutable.getLoadable(listsState).valueMaybe();
            const listIds = allLists?.map((list) => list.id) ?? [];
            listIds.forEach((listId) => {
              if (snapshot.getInfo_UNSTABLE(companyListState(listId)).isSet) {
                mutable.set(companyListState(listId), (current) => {
                  if (!current) return current;

                  const updatedItems = current!.items.map((item) => {
                    if (item.companyId === listItem.companyId) {
                      return {
                        ...item,
                        comments: [...(item.comments ?? []), data],
                      };
                    } else {
                      return item;
                    }
                  });
                  return { ...current, items: updatedItems };
                });
              }
            });
          });
          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to add comment');
          pushErrorToast({ message });
        } finally {
          setLoading(false);
        }
      }
  );

  const updateItemComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (listItem: IListItemDataModel, commentId: number, comment: string) => {
        if (!comment) return;
        setLoadingId(commentId);
        try {
          const { data } = await updateListItemComment(commentId, listItem.companyId, comment);

          const updatedSnapshot = snapshot.map((mutable) => {
            const allLists = mutable.getLoadable(listsState).valueMaybe();
            const listIds = allLists?.map((list) => list.id) ?? [];
            listIds.forEach((listId) => {
              if (snapshot.getInfo_UNSTABLE(companyListState(listId)).isSet) {
                mutable.set(companyListState(listId), (current) => {
                  if (!current) return current;
                  const updatedItems = current!.items.map((item) => {
                    if (item.companyId === listItem.companyId) {
                      return {
                        ...item,
                        comments: item.comments?.map((comment) => {
                          if (comment.id === data.id) {
                            return data;
                          } else {
                            return comment;
                          }
                        }),
                      };
                    } else {
                      return item;
                    }
                  });
                  return { ...current, items: updatedItems };
                });
              }
            });
          });
          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to update comment');
          pushErrorToast({ message });
        } finally {
          setLoadingId(null);
        }
      }
  );

  const deleteItemComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (listItem: IListItemDataModel, commentId: number) => {
        setLoadingId(commentId);
        try {
          await deleteListItemComment(commentId);

          const updatedSnapshot = snapshot.map((mutable) => {
            const allLists = mutable.getLoadable(listsState).valueMaybe();
            const listIds = allLists?.map((list) => list.id) ?? [];
            listIds.forEach((listId) => {
              if (snapshot.getInfo_UNSTABLE(companyListState(listId)).isSet) {
                mutable.set(companyListState(listId), (current) => {
                  if (!current) return current;
                  const updatedItems = current!.items.map((item) => {
                    if (item.companyId === listItem.companyId) {
                      return {
                        ...item,
                        comments: item.comments?.filter((comment) => comment.id !== commentId),
                      };
                    } else {
                      return item;
                    }
                  });
                  return { ...current, items: updatedItems };
                });
              }
            });
          });
          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Failed to delete comment');
          pushErrorToast({ message });
        } finally {
          setLoadingId(null);
        }
      }
  );

  return { createItemComment, updateItemComment, deleteItemComment, loading, loadingId };
}

export function useListUploadActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const refreshListData = useRecoilCallback(({ set }) => async (activeList: IListDataModel) => {
    try {
      actions.startLoading(LoadingId.addListItem);
      const items = await fetchListItems(activeList.id);
      set(companyListState(activeList.id), items);
      pushSuccessToast({ message: `Company list ${activeList?.name} uploaded successfully` });
    } catch (error) {
      console.error(error);
      const message = getErrorMessage(error, 'An error occurred while fetching companies');
      pushErrorToast({ message });
    } finally {
      actions.stopLoading(LoadingId.addListItem);
    }
  });

  const addCompanyToList = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (activeList: IListDataModel, selectedCompany: CompanySearchResponseWithRealId) => {
        try {
          actions.startLoading(LoadingId.addListItem);

          const companyId = Number(selectedCompany.id);
          const createdItem = await createListItem(activeList.id, companyId);
          const allItemsUpdated = await fetchListItems(activeList.id); // need to refetch all items to ensure we have all versions for grouping
          const newSnapshot = snapshot.map((mutable) => {
            mutable.set(companyListState(activeList.id), allItemsUpdated);
            mutable.set(listsState, (currentLists) => {
              if (!currentLists) return null;
              return currentLists.map((list) => {
                if (list.id === createdItem.listId) {
                  return {
                    ...cloneDeep(list),
                    numberOfItems: list.numberOfItems + 1,
                  };
                } else {
                  return list;
                }
              });
            });
          });
          gotoSnapshot(newSnapshot);

          pushSuccessToast({
            message: `Company ${selectedCompany.fields.name} added to list ${activeList?.name}`,
          });
        } catch (error) {
          gotoSnapshot(snapshot);
          const message = getErrorMessage(error, 'An error occurred while adding the company to the list');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.addListItem);
        }
      },
    [pushErrorToast]
  );

  return { refreshListData, addCompanyToList };
}
