import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/system';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { DealAnalyticsHeader } from './DealAnalyticsHeader';

const DAContainer = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  padding: 1rem 2.25rem;
  width: 100%;
`;

const ContentsWrapper = styled('div')`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const DealAnalytics: FC = () => {
  return (
    <DAContainer>
      <DealAnalyticsHeader />
      <ContentsWrapper>
        <GenericFallbacksWrapper>
          <Outlet />
        </GenericFallbacksWrapper>
      </ContentsWrapper>
    </DAContainer>
  );
};
