import { useCallback } from 'react';
import { KpiPeriod, KpiSection } from '../../../../../data-models/company-financials.data-model';
import { IFinancialsRowData2, useFinancialsGridData } from '../hooks/useFinancialsGridData';
import { IField } from '../../../../../data-models/field2.data-model';
import { useFinancialsGridSettings } from '../components/FinancialsSettingsState';

export interface IFinancialsExportData extends IField<unknown>, IFinancialsRowData2 {
  section: KpiSection;
}

export function useFinancialsExportData() {
  const getAllSectionData = useFinancialsGridData();
  const { displaySettings } = useFinancialsGridSettings();

  return useCallback(
    (period: KpiPeriod): IFinancialsExportData[] => {
      const rowData: IFinancialsExportData[] = [];
      const allSectionData = getAllSectionData(period, {
        showBudget: true,
        reportedOnly: displaySettings.reportedOnly,
      });

      allSectionData.forEach((kpiDataByDate) => {
        for (const section of Object.values(KpiSection)) {
          rowData.push({
            ...kpiDataByDate,
            section,
          });
        }
      });
      return rowData;
    },
    [displaySettings.reportedOnly, getAllSectionData]
  );
}
