import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Event } from '../../../types';
import { useNotificationConfig } from '../hooks/useNotificationConfig';

const Wrapper = styled('div')`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  & > * {
    &:not(:first-child) {
      text-align: center;
    }
  }
`;

export function NotificationHeader() {
  const {
    data: {
      deal: { types },
    },
  } = useNotificationConfig();
  const [sortedTypes, setSortedTypes] = useState<Event[] | null>();
  const { primary } = useTheme().colors;

  useEffect(() => {
    if (!types) return;
    setSortedTypes([...types].sort(({ sortOrder: a }, { sortOrder: b }) => a - b));
  }, [types]);

  return (
    <Wrapper data-testid={'notification-preferences-header'}>
      <Typography variant={'subtitle2'} color={primary['100']}>
        Events
      </Typography>
      {sortedTypes?.map(({ displayName, id }) => (
        <Typography variant={'subtitle2'} color={primary['100']} key={id}>
          {displayName}
        </Typography>
      ))}
    </Wrapper>
  );
}
