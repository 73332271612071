import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useSetRecoilState } from 'recoil';
import { useScenarioActions } from '../hooks/useScenarioActions';
import { currentScenarioState } from '../../state/ScenariosState';
import { prepareScenarioPayload } from './form/scenarioFormUtils';

export const RunScenarioAction: FC = () => {
  const { handleValidateAndRun } = useValidateAndRun();

  return (
    <Button
      type='button'
      onClick={handleValidateAndRun}
      variant='contained'
      size='medium'
      color='secondary'
      startIcon={<PlayArrowIcon />}
    >
      Run Scenario
    </Button>
  );
};

export function useValidateAndRun() {
  const formContext = useFormContext();
  const setCurrentScenario = useSetRecoilState(currentScenarioState);
  const { handleRunScenario } = useScenarioActions();

  const handleValidateAndRun = useCallback(async () => {
    if (!formContext) {
      return handleRunScenario();
    }
    const { getValues, trigger } = formContext;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    const payload = prepareScenarioPayload(values);
    setCurrentScenario(payload);
    handleRunScenario();
  }, [formContext, handleRunScenario, setCurrentScenario]);

  return { handleValidateAndRun };
}
