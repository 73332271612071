import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { SelectItem } from '../../../../types';
import { OriginViewDataModel, VIEW_TYPE } from '../../../../data-models/view-config.data-model';
import { selectedViewPF, viewsPF } from '../../state/ViewState';
import { pageConfigPF } from '../../state/PageState';
import { useReorderViews } from '../../hooks/usePageActions';
import { ROUTES } from '../../../../constants/RouteNameMapping';

interface ViewNavigationInterface {
  items: SelectItem[];
  onReorder: (items: SelectItem[]) => void;
  onSelect?: (item: SelectItem) => void;
  selectedItem: SelectItem | null;
  isLoading: boolean;
  viewsMap: Record<string, OriginViewDataModel>;
  setIsItemDropdownOpen: Dispatch<SetStateAction<boolean>>;
  isItemDropdownOpen: boolean;
}

const ViewNavigationContext = createContext<ViewNavigationInterface | undefined>(undefined);

export const ViewNavigationProvider = ({ children }: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const views = useRecoilValueLoadable(viewsPF);
  const pageConfig = useRecoilValueLoadable(pageConfigPF);
  const selectedView = useRecoilValue(selectedViewPF);
  const isLoadingViews = views.state === 'loading';
  const isLoadingPageConfig = pageConfig.state === 'loading';
  const reorderViews = useReorderViews();

  const [selectedItem, setSelectedItem] = useState<SelectItem | null>(null);
  const [isItemDropdownOpen, setIsItemDropdownOpen] = useState(false);
  const [items, setItems] = useState<SelectItem[]>([]);

  const viewsMap = useMemo(
    () =>
      views
        .valueMaybe()
        ?.reduce(
          (map, view) => ({ ...map, [view.viewId]: view }),
          {} as Record<string, OriginViewDataModel>
        ) ?? {},
    [views]
  );

  const onSelect = useCallback(
    (item: SelectItem) => {
      setSelectedItem(item);

      const selectedViewFromItem = views.getValue().find((view) => view.viewId === item.id);

      navigate(`/${ROUTES.PORTFOLIO}/${selectedViewFromItem!.id}`);
    },
    [navigate, views]
  );

  const onReorder = useCallback(
    async (items: SelectItem[]) => {
      setItems(items);
      return await reorderViews(items.map((item) => item.id as string));
    },
    [reorderViews]
  );

  useEffect(() => {
    if (isLoadingPageConfig || !pageConfig.valueMaybe()?.order || !viewsMap) return;

    const viewOptions =
      pageConfig
        .valueMaybe()
        ?.order.filter((viewId) => viewsMap[viewId])
        .map((viewId) => ({
          value: viewsMap[viewId].label,
          id: viewId,
          viewType: viewsMap[viewId].viewType,
          isCompany: viewsMap[viewId].type === VIEW_TYPE.COMPANY,
        })) ?? [];

    setItems(viewOptions);
  }, [pageConfig, isLoadingPageConfig, viewsMap, selectedItem]);

  useEffect(() => {
    if (selectedItem || !items?.length || !selectedView) return;

    const selectedItemFromView = items.find((item) => item.id === selectedView.viewId);
    setSelectedItem(selectedItemFromView ?? null);
  }, [items, selectedItem, selectedView]);

  const value = useMemo(
    () => ({
      isLoading: isLoadingPageConfig || isLoadingViews,
      selectedItem,
      items,
      onReorder,
      onSelect,
      viewsMap,
      setIsItemDropdownOpen,
      isItemDropdownOpen,
    }),
    [
      isLoadingPageConfig,
      isLoadingViews,
      selectedItem,
      items,
      onReorder,
      onSelect,
      viewsMap,
      isItemDropdownOpen,
    ]
  );

  return <ViewNavigationContext.Provider value={value}>{children}</ViewNavigationContext.Provider>;
};

export const useViewNavigation = (): ViewNavigationInterface => {
  const context = useContext(ViewNavigationContext);

  if (context === undefined) {
    throw new Error('useViewNavigation cannot be used outside ViewNavigationProvider');
  }

  return context;
};
