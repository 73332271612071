/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { InputHTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { getTextWidth } from '../../util/getTextWidth';

interface EditableTypographyProps extends InputHTMLAttributes<HTMLInputElement> {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'button'
    | 'overline'
    | 'caption';
  color: string;
  content: string;
  onEdit?: (text: string) => void;
  handleSubmit: (newName: string) => void;
  editable: boolean;
  width?: number;
  submitOnBlur?: boolean;
  inputColor?: string;
}

const EditableInput = styled('input')<{ inputColor?: string }>`
  all: unset;
  padding: 0 0 0 4px;
  outline: none;
  border: ${({ theme }) => `1px solid ${theme.colors.primary[60]}`};
  border-radius: 3px;
  color: ${({ theme, inputColor }) => (inputColor ? inputColor : theme.colors.neutral[60])};
  &:disabled,
  &[disabled] {
    border: 1px solid transparent;
    color: inherit;
    background-color: transparent;
    pointer-events: none;
  }
  width: ${({ width }) => (width ? width + 'px' : '')};
  max-width: ${({ width }) => (width ? width + 'px' : '')};
  text-overflow: ellipsis;
`;

export const EditableTypography = ({
  variant,
  color,
  content,
  handleSubmit,
  editable,
  placeholder,
  submitOnBlur = true,
  inputColor,
  ...rest
}: EditableTypographyProps): JSX.Element => {
  const [text, setText] = useState(content);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleBlur = () => {
    if (!text) return;
    submitOnBlur && handleSubmit(text);
    inputRef.current?.blur();
  };
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!text) return;
    handleSubmit(text);
  };

  useEffect(() => {
    if (editable) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [editable]);

  useEffect(() => {
    setText(content);
  }, [content]);

  return (
    <form onSubmit={onSubmit}>
      <Typography variant={variant} color={color}>
        <EditableInput
          ref={inputRef}
          disabled={!editable}
          type='text'
          value={text}
          onChange={handleInputChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          inputColor={inputColor}
          width={
            editable
              ? !text && placeholder
                ? Math.max(getTextWidth(placeholder, '16px Wotfard-Regular'), 50)
                : Math.max(getTextWidth(text, '16px Wotfard-Regular'), 50)
              : getTextWidth(text)
          }
          {...rest}
        />
      </Typography>
    </form>
  );
};
