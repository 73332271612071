import { CompetitiveIntelligenceItem } from '../../../../../data-models/competitive-intelligence.data-model';
import { getFieldsMap } from '../Columns/getFieldsMap';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { getCellValue } from './getCellValue';

export const prepRowData = (
  companies: ICompanyDataModel[],
  fields: FieldDataModel[],
  responseData: CompetitiveIntelligenceItem[]
) => {
  const fieldsMap = getFieldsMap(fields);

  //map of all entries for a single company (one row)
  // first map companyIds to all field-value pairs for respective company constituting a row
  const rowDataMap: Record<number, Record<string, unknown>> | null =
    !fieldsMap || !companies?.length || !responseData
      ? null
      : responseData.reduce((map, curr) => {
          if (fieldsMap[curr.fieldId]) {
            map[curr.companyId] = map[curr.companyId] || {};
            // add row ids for efficient grid data updates:
            map[curr.companyId].id = curr.companyId;
            map[curr.companyId][fieldsMap[curr.fieldId].name] = getCellValue(curr);
            map[curr.companyId].company = companies.find((c) => c.id === curr.companyId);
          }
          return map;
        }, {} as Record<number, Record<string, unknown>>);

  return !rowDataMap ? [] : Object.values(rowDataMap);
};
