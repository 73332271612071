import { atom, selector } from 'recoil';
import { DASHBOARD_TYPES, Dashboard, DashboardConfigObject } from '../../../types';
import {
  fetchDashboards,
  fetchFields,
  fetchPackages,
} from '../../../services/queries/CompetitiveIntelligenceDashboardQueries';
import { IPackageDataModel } from '../../../data-models/package.data-model';
import { fetchCompanyRaw } from '../../../services/queries/MaggieCompanyQueries';
import { FieldDataModel } from '../../../data-models/field.data-model';
import { IGroupOrderDataModel } from '../../../data-models/group-order-data.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';

export const selectedDashboardCI = selector<Dashboard>({
  key: 'selectedDashboardCI',
  get: ({ get }) => {
    const activeDashboardId = get(selectedDashboardIdCI);
    const dashboards = get(dashboardsCI);

    return dashboards.find((dashboard) => dashboard.id === activeDashboardId) ?? dashboards[0]; // TODO: see comment in selectedDashboardIdCI
  },
});

export const selectedDashboardIdCI = atom<number>({
  key: 'selectedDashboardIdCI',
  default: -1,
});

export const selectedDashboardConfigCI = selector<DashboardConfigObject>({
  key: 'selectedDashboardConfigCI',
  get: ({ get }) => {
    return get(selectedDashboardCI).config;
  },
});

export const dashboardsCI = atom<Dashboard[]>({
  key: 'dashboardsCI',
  default: [],
  effects: [
    ({ getInfo_UNSTABLE, setSelf }) => {
      if (!getInfo_UNSTABLE(dashboardsCI).isSet) {
        setSelf(fetchDashboards());
      }
    },
  ],
});

export const competitiveIntelligenceDashboards = selector<Dashboard[]>({
  key: 'competitiveIntelligenceDashboards',
  get: ({ get }) => {
    const allDashboards = get(dashboardsCI);
    return allDashboards.filter((d) => d.type !== DASHBOARD_TYPES.CompanyProfile);
  },
});

export const companyProfileDashboard = selector<Dashboard | null>({
  key: 'companyProfileDashboard',
  get: ({ get }) => {
    const allDashboards = get(dashboardsCI);
    return allDashboards.find((d) => d.type === DASHBOARD_TYPES.CompanyProfile) ?? null;
  },
});

export const fieldsCI = atom<FieldDataModel[]>({
  key: 'fieldsCI',
  effects: [
    ({ setSelf }) => {
      setSelf(fetchFields());
    },
  ],
});

export const fieldsMapCI = selector<Map<number, FieldDataModel>>({
  key: 'fieldsMapCI',
  get: ({ get }) => {
    return get(fieldsCI).reduce((res, field) => {
      return res.set(field.id, field);
    }, new Map<number, FieldDataModel>());
  },
});

export const packagesCI = atom<IPackageDataModel[]>({
  key: 'packagesCI',
  effects: [
    ({ setSelf }) => {
      setSelf(fetchPackages());
    },
  ],
});

export const primaryCompanyCI = selector<ICompanyDataModel | undefined>({
  key: 'primaryCompanyCI',
  get: async ({ get }) => {
    const primaryId = get(primaryCompanyIdCI);

    if (primaryId === undefined) {
      return undefined;
    }
    return await fetchCompanyRaw(primaryId);
  },
});

export const primaryCompanyIdCI = atom<number | undefined>({
  key: 'primaryCompanyIdCI',
  default: undefined,
});

export const selectedFieldsCI = selector<FieldDataModel[]>({
  key: 'selectedFieldsCI',
  get: ({ get }) => {
    const packages = get(packagesCI);
    const fieldsMap = get(fieldsMapCI);
    const dashboard = get(selectedDashboardCI);
    const selectedCategoriesMap = dashboard.config.selectedCategories.reduce(
      (res, cat) => res.set(cat, true),
      new Map<string, boolean>()
    );

    const newSelectedFields = packages.reduce((res, pack) => {
      pack.categories.forEach((category) => {
        if (selectedCategoriesMap.has(category.id)) {
          category.fields.forEach((fieldId) => {
            if (fieldsMap.has(fieldId)) {
              res.add(fieldsMap.get(fieldId)!);
            }
          });
        }
      });
      return res;
    }, new Set<FieldDataModel>());

    return Array.from(newSelectedFields).sort((f1, f2) => f1.id - f2.id);
  },
});

export const groupOrderMapCI = selector<Map<string, IGroupOrderDataModel>>({
  key: 'groupOrderMap',
  get: ({ get }) => {
    const packages = get(packagesCI);
    const fieldsMap = get(fieldsMapCI);

    return packages.reduce((res, pack) => {
      pack.categories.forEach((cat) => {
        cat.fields.forEach((fieldId) => {
          const field = fieldsMap.get(fieldId);
          if (!field || !field.groupName) return res;
          res.set(field.groupName, {
            groupPriority: field.groupPriority ?? null,
            packagePriority: pack.priority ?? null,
          });
        });
      });
      return res;
    }, new Map<string, IGroupOrderDataModel>());
  },
});

export const timeRange = atom<number>({
  key: 'timeRange',
  default: 24,
});
