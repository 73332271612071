import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { CSSProperties } from 'react';

import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';

const Wrapper = styled('div')`
  background-color: #f4f5f6;
  padding: 24px 20px 24px 44px;
  margin-top: 10px;
  position: relative;
`;

const CompanyInfo = styled('div')`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.secondary[60]};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  margin-top: 1rem;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: start;
  gap: 12px;
  margin-top: 1rem;
`;

interface IProps {
  company: ICompanyDataModel;
  onCancel?: () => void;
  onSelect?: () => void;
  style?: CSSProperties;
}

export const CompanySuggestion = ({ company, onCancel, onSelect, style }: IProps) => {
  return (
    <Wrapper style={style}>
      <DuplicateCompanyInfo
        company={company}
        errorMessage={
          onSelect
            ? undefined
            : `The company with the website name “${company.website}” has a duplicate in our database.
Please cancel and select it from search to avoid duplicates.`
        }
        additionalInfo={
          onSelect
            ? 'You can select the existing company and edit the auto-filled data if necessary'
            : undefined
        }
      />
      {Boolean(onSelect) && (
        <ButtonWrapper>
          <Button
            variant={'contained'}
            color='secondary'
            data-testid={'company-form-submit'}
            onClick={onSelect}
          >
            Select
          </Button>
          <Button variant='outlined' color={'secondary'} onClick={onCancel}>
            Cancel
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export function DuplicateCompanyInfo({
  company,
  additionalInfo,
  errorMessage,
}: {
  company: ICompanyDataModel;
  errorMessage?: string;
  additionalInfo?: string;
}) {
  return (
    <>
      <Typography variant='subtitle2' display={'flex'} gap={'0.25rem'} whiteSpace={'pre'}>
        <ErrorOutlineOutlinedIcon fontSize='small' style={{ marginLeft: '-1.5rem' }} />
        {errorMessage
          ? errorMessage
          : `The company with the website name “${company.website}” has a duplicate in our database`}
      </Typography>
      {additionalInfo ? (
        <Typography color='text.secondary' variant='body2' lineHeight={'1.5em'}>
          You can select the existing company and edit the auto-filled data if necessary
        </Typography>
      ) : null}
      <CompanyInfo>
        <Typography variant='subtitle1' display='flex' gap='0.5rem'>
          <MUIAvatar src={company.logoUrl ?? ''} nameParts={company.name?.split(' ')} />
          {company.name}
        </Typography>
        <Typography color='secondary' variant='body2'>
          {company.website}
        </Typography>
      </CompanyInfo>
    </>
  );
}
