import { FieldDataModel } from '../../../../../data-models/field.data-model';

export const getFieldsMap = (fields: FieldDataModel[]) => {
  const fieldsMap: Record<number, FieldDataModel> = fields.reduce((map, field) => {
    map[field.id] = field;
    return map;
  }, {} as Record<number, FieldDataModel>);

  return fieldsMap;
};
