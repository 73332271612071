import { FieldEntity } from '../../../data-models/field2.data-model';
import { createField3DataModel, ITextMeta } from '../../../data-models/field3.data-model';

export function getKPIGroupFields() {
  return [
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: FieldEntity.dummy,
      entityKey: 'name',
      displayName: 'Name',
      meta: {
        maxLength: -1,
      },
    }),
  ];
}
