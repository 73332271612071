import { Chip, ChipProps } from '@mui/material';
import { ChipStack } from '../pages/CompanyProfiles/Scenarios/components/commonStyledComponents';
import { ISimpleChoice } from '../data-models/field2.data-model';

export interface IChipSelectorProps<T> {
  options: ISimpleChoice<T>[];
  value: T | null;
  onChange: (val: T) => void;
  chipProps?: ChipProps;
}

export function ChipSelector<T>({ options, value, onChange, chipProps }: IChipSelectorProps<T>) {
  return (
    <ChipStack style={{ paddingBottom: '0.5rem', width: 'calc(100% - 3rem)' }}>
      <UnstyledChipSelector options={options} value={value} onChange={onChange} chipProps={chipProps} />
    </ChipStack>
  );
}

export function UnstyledChipSelector<T>({ options, value, onChange, chipProps }: IChipSelectorProps<T>) {
  return (
    <>
      {options.map((opt) => {
        const isSelected = value === opt.value;
        const label = opt.displayName ?? String(opt.value);
        return (
          <Chip
            key={label}
            label={label}
            onClick={() => onChange(opt.value)}
            variant={isSelected ? 'filled' : 'outlined'}
            color={isSelected ? 'primary' : 'default'}
            size='medium'
            aria-pressed={isSelected}
            {...chipProps}
          />
        );
      })}
    </>
  );
}
