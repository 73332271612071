import { useMemo } from 'react';
import { css } from '@emotion/react';
import { FundReserves, fundReservesSummarySchema } from '../../../schemas/FundReserves.schema';
import { fieldDisplayValuesFromSchema } from '../../../util/schema-utils';
import { LabeledField, LabeledFieldContainer } from '../../../components/LabeledField';
import { SubSection } from '../../CompanyProfiles/Summary/components/SubSection';

export function FundReservesSummary({ reserves }: { reserves: FundReserves }) {
  const schema = fundReservesSummarySchema();

  const fields = useMemo(() => {
    const fieldMap = fieldDisplayValuesFromSchema({
      schema,
      paths: [
        'reservesAllocation',
        'reservesInvested',
        'reservesAllocationRemaining',
        'assignedReserves',
        'unassignedReserves',
        'projectedReservesBalance',
      ],
      values: reserves.summary,
    });
    return Array.from(fieldMap.values()).map((f) => (
      <LabeledField key={f.label} label={f.label ?? ''} value={f.formattedValue} />
    ));
  }, [reserves.summary, schema]);

  return (
    <SubSection title='Fund Reserves Summary' style={{ marginTop: '0.5rem' }}>
      <ul
        css={css`
          ${LabeledFieldContainer};
        `}
      >
        {fields}
      </ul>
    </SubSection>
  );
}
