import { ICellRendererParams } from 'ag-grid-community';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { memo, useCallback, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IAssignedRowData } from '../../AssignedCompaniesData';
import { useAllKpiCompaniesActions } from '../../AllCompaniesForms/useAllKpiCompaniesActions';
import { MenuButton } from '../../../../../Finance2/common-grid-defs/MenuButton';
import { templateAssignmentFormState } from '../../AllCompaniesForms/AssignTemplateFormState';
import {
  DefaultReminderThreshold,
  ReminderThresholds,
} from '../../../../../../data-models/template.data-model';
import { kpiTemplatesMapByUuidState } from '../../../../../../services/state/KPI/KPITemplatesState';
import { MaggieFeatureFlags } from '../../../../../../util/feature-flags';
import { KPIRequestFrequency } from '../../../../../../data-models/kpi-requests.data-model';

export const AssignedActionsRenderer = memo(function AssignedActionsRenderer(
  params: ICellRendererParams<IAssignedRowData>
) {
  const { company, id, templateUuid, frequency, reminderThreshold } = params.data ?? {};
  const templatesByUuid = useRecoilValue(kpiTemplatesMapByUuidState);
  const setTemplateFormState = useSetRecoilState(templateAssignmentFormState);
  const { showCreateRequest } = useFlags<MaggieFeatureFlags>();

  const { handleCreateRequest, handleSelectAction, handleSelectDeleteAssignment } =
    useAllKpiCompaniesActions();

  const handleEdit = useCallback(() => {
    if (!company || !id) return;
    setTemplateFormState({
      id,
      templateUuid: templatesByUuid.get(templateUuid ?? '') ? templateUuid : '',
      frequency: frequency as KPIRequestFrequency,
      reminderThreshold: ReminderThresholds.get(reminderThreshold ?? -1)
        ? reminderThreshold
        : DefaultReminderThreshold,
    });
    handleSelectAction([company], 'editAssignment');
  }, [
    company,
    frequency,
    handleSelectAction,
    id,
    reminderThreshold,
    setTemplateFormState,
    templateUuid,
    templatesByUuid,
  ]);

  const handleDelete = useCallback(() => {
    if (!id) return;
    handleSelectDeleteAssignment(id);
  }, [handleSelectDeleteAssignment, id]);

  const handleSelectCreateRequest = useCallback(() => {
    if (!params.data) return;
    handleCreateRequest(params.data);
  }, [handleCreateRequest, params.data]);

  const menuItems = useMemo(() => {
    const company = params.data;
    if (!company) return [];
    const menu = [
      {
        name: 'Edit',
        icon: <EditOutlinedIcon />,
        onClick: handleEdit,
      },
      {
        name: 'Delete',
        icon: <DeleteOutlineOutlinedIcon />,
        onClick: handleDelete,
      },
    ];

    if (showCreateRequest) {
      menu.unshift({
        name: 'Create Request',
        icon: <NoteAddOutlinedIcon />,
        onClick: handleSelectCreateRequest,
      });
    }
    return menu;
  }, [params.data, handleEdit, handleDelete, showCreateRequest, handleSelectCreateRequest]);

  if (!company) return null;
  return <MenuButton menuItems={menuItems} />;
});
