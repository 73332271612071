import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Typography } from '@mui/material';
import { contributionsDistributionsState } from '../state/FPState';
import { MixedChart } from '../../../components/Charts/MixedChart/MixedChart';
import { cardStyles } from '../../../theme/component-styles';
import { fundTransactionsFields } from '../../../schemas/FundContributions.schema';
import { formatFieldValue } from '../../../util/schema-utils';
import { getContributionsDistributionsSeries, getTimeCategories } from './contributionsDistributionsUtils';

export function ContributionsDistributions() {
  const contributionsDistributions = useRecoilValue(contributionsDistributionsState);
  const xData = useMemo(() => getTimeCategories(), []);
  const yData = useMemo(
    () => getContributionsDistributionsSeries(contributionsDistributions?.transactions ?? []),
    [contributionsDistributions?.transactions]
  );

  return (
    <div style={cardStyles}>
      <Typography variant={'body1'} my={'1rem'} component={'h6'}>
        Contributions/Distributions
      </Typography>
      <Box width={'100%'} height={'25rem'}>
        <MixedChart
          xCategoryData={xData}
          yValueData={yData}
          yAxis={{
            type: 'value',
            axisLabel: {
              formatter: (value: number | null) =>
                formatFieldValue(fundTransactionsFields().amount, value, '-'),
            },
          }}
          staticOptions={{}}
        />
      </Box>
    </div>
  );
}
