import { Divider, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { DEAL_TYPE_PARAM } from '../constants/dealsSearchParams';
import { dealBoardConfigState } from '../state/DealboardDataState';
import { selectedDealCategoryState, selectedDealTypeState } from '../state/DealboardUIState';

const Container = styled('section')`
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem 0 3rem;
  margin-bottom: -1px;

  .customSvgColor {
    stroke: ${({ theme }) => theme.colors.primary[60]};
    * {
      stroke: ${({ theme }) => theme.colors.primary[60]};
    }
  }
`;

export const DealFlowNavigation: FC = () => {
  const navigate = useNavigate();
  const { dealTypes } = useRecoilValue(dealBoardConfigState);
  const selectedDealType = useRecoilValue(selectedDealTypeState);
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `/${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/${selectedDealCategory.id}?${DEAL_TYPE_PARAM}=${newValue}`
    );
  };

  return (
    <>
      <Container>
        <Tabs value={selectedDealType} onChange={handleChange}>
          {dealTypes.map((item) => (
            <Tab key={item.id} value={item.id} label={item.displayName} className={`mui-custom-tab`} />
          ))}
        </Tabs>
      </Container>
      <Divider />
    </>
  );
};
