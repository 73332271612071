import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Dialog,
  DialogActions as DialogActionsMUI,
  DialogContent,
  DialogTitle as DialogTitleMUI,
  IconButton,
  SxProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ZINDEX } from '../../../constants/styles';
import { FooterButtons, FooterButtonsProps } from './FooterButtons';

const Title = styled('span')`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;
  justify-content: start;
`;

export enum ConfirmDialogTitleOptions {
  Warning = 'warning',
  Default = 'default',
}

const DialogTitle = styled(DialogTitleMUI)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DialogActions = styled(DialogActionsMUI)`
  display: flex;
  justify-content: flex-start;
`;

interface ITitleWithIconsProps {
  type: ConfirmDialogTitleOptions;
  children: string | ReactNode;
}

const TitleWithIcon = ({ type, children }: ITitleWithIconsProps) => {
  const { colors } = useTheme();
  let IconType: ReactNode;

  switch (type) {
    case ConfirmDialogTitleOptions.Warning:
      IconType = <ReportProblemIcon sx={{ color: colors.critical[50] }} />;
      break;
    default:
      IconType = null;
  }

  return (
    <Title>
      {IconType}
      {children}
    </Title>
  );
};

export interface IConfirmDialogProps {
  open: boolean;
  children: ReactNode | string;
  title?: string | ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  actionButtons?: ReactNode;
  loading?: boolean;
  type?: ConfirmDialogTitleOptions;
  footerButtonsProps?: FooterButtonsProps;
  sx?: SxProps;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  loading = false,
  children,
  type = ConfirmDialogTitleOptions.Default,
  title,
  actionButtons = false,
  footerButtonsProps = null,
  sx = {},
}) => {
  return (
    <Dialog open={open} maxWidth={false} sx={{ zIndex: ZINDEX.MODAL, ...sx }}>
      <DialogHeader type={type} title={title} onClose={onClose} />
      <DialogContent style={{ minWidth: '43.5rem' }}>{children}</DialogContent>
      <DialogActions sx={{ padding: '1.5rem 1rem 2rem 1.5rem', pt: 0 }}>
        {actionButtons ? (
          actionButtons
        ) : (
          <FooterButtons loading={loading} onConfirm={onConfirm} onCancel={onClose} {...footerButtonsProps} />
        )}
      </DialogActions>
    </Dialog>
  );
};

export type IDialogHeaderProps = Pick<IConfirmDialogProps, 'type' | 'title' | 'onClose'>;

export function DialogHeader(props: IDialogHeaderProps) {
  const { type = ConfirmDialogTitleOptions.Default, title, onClose } = props;
  const { colors } = useTheme();

  return (
    <DialogTitle>
      <TitleWithIcon type={type}>{title}</TitleWithIcon>
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            color: colors.neutral[40],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
