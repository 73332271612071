import { atom, atomFamily, selector } from 'recoil';
import { endOfDay } from 'date-fns';
import { fetchPageConfig } from '../../../services/queries/MaggieViewQueries';
import { IPageConfigDataModel } from '../../../data-models/page-config.data-model';
import { selectedViewIdPF } from './ViewState';

export const pageConfigPF = atom<IPageConfigDataModel>({
  key: 'pageConfigPF',
  effects: [
    ({ setSelf }) => {
      setSelf(fetchPageConfig());
    },
  ],
});

export const selectedDateByViewPF = atomFamily<Date, number>({
  key: 'selectedDateByViewPF',
  default: defaultViewDate(),
});

export const selectedViewDatePF = selector<Date>({
  key: 'selectedViewDatePF',
  get: ({ get }) => {
    const selectedViewId = get(selectedViewIdPF);

    return get(selectedDateByViewPF(selectedViewId));
  },
});

export function defaultViewDate() {
  return endOfDay(new Date());
}
