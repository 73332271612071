import { ECBasicOption } from 'echarts/types/dist/shared';
import { theme } from '../../theme';

const { primary } = theme.colors;
export const BAR_COLORS = [primary['60'], primary['20']];

export const AxisTickTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 11,
};

export const ToolTipTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 14,
};

export const CommonChartOptions: ECBasicOption = {
  legend: {
    orient: 'horizontal',
    bottom: 0,
  },
  grid: {
    containLabel: true,
    top: 20,
    bottom: 30,
    left: 0,
    right: 0,
  },
};
