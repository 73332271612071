import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { upperFirst } from 'lodash-es';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';

export function ToggleFrequencyButtons({
  frequency,
  setFrequency,
}: {
  frequency: KpiPeriod;
  setFrequency: (value: KpiPeriod) => void;
}) {
  const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newFrequency: KpiPeriod | null) => {
    // if selected option was unselected do nothing
    if (newFrequency === null) return;
    setFrequency(newFrequency);
  };

  return (
    <ToggleButtonGroup
      onChange={handlePeriodChange}
      value={frequency}
      aria-label='select frequency'
      color={'secondary'}
      exclusive
    >
      {Object.values(KpiPeriod).map((frequency) => (
        <ToggleButton key={frequency} value={frequency}>
          {upperFirst(frequency)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
