import { FieldFormatting } from '../data-models/field.data-model';
import {
  formatCurrencyArray,
  formatNumberArray,
  formatPercentArray,
  formatStringArray,
} from './formatters/ArrayFormattersConfig';
import { formatBooleanTrueFalse, formatBooleanYesNo } from './formatters/BooleanFormatters';
import { formatDateNumeric, formatDateYYYY, formatTime } from './formatters/DateFormatters';
import {
  formatMultiplier,
  formatNumber2dp,
  formatPercent,
  formatPercentRoundAsIs,
  formatUSD,
} from './formatters/NumericFormatters';

export type GetterFormatType =
  | 'array'
  | 'boolean'
  | 'date'
  | 'number'
  | 'numberArray'
  | 'percent'
  | 'string'
  | 'stringArray';
export type GetterFormatting =
  | 'YYYY'
  | 'boardMembers'
  | 'currency'
  | 'hh:mm:ss'
  | 'image'
  | 'integer'
  | 'longText'
  | 'multiplier'
  | 'percent'
  | 'percentFromDecimal'
  | 'url'
  | 'yesNo';

/** @deprecated */
export type DeprecatedGetterFormatType = 'number' | 'string' | 'link' | 'date' | 'array';

/** @deprecated */
export type DeprecatedGetterFormatting =
  | 'text'
  | 'number'
  | 'percent'
  | 'multiplier'
  | 'image'
  | 'link'
  | 'url'
  | 'date'
  | 'currency';

/** @deprecated */
const handleDeprecatedCases = (formatting?: string | null) => {
  if (formatting === 'percent') return formatPercentRoundAsIs;
  if (formatting === 'date') return formatDateNumeric;
};

export interface GetFormatterProps {
  type: GetterFormatType | DeprecatedGetterFormatType | null;
  formatting?: FieldFormatting;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFormatter({ type, formatting }: GetFormatterProps): ((value: any) => string) | null {
  switch (type) {
    case 'number': {
      if (formatting === 'percent') return formatPercentRoundAsIs;
      if (formatting === 'currency') return formatUSD;
      if (formatting === 'multiplier') return formatMultiplier;
      if (formatting === 'percentFromDecimal') return formatPercent;

      return formatNumber2dp;
    }
    case 'date': {
      if (formatting === 'YYYY') return formatDateYYYY;
      if (formatting === 'hh:mm:ss') return formatTime;

      return formatDateNumeric;
    }
    case 'array': {
      return formatStringArray;
    }
    case 'numberArray': {
      if (formatting === 'currency') return formatCurrencyArray;
      if (formatting === 'percent') return formatPercentArray;
      return formatNumberArray;
    }
    case 'boolean': {
      if (formatting === 'yesNo') return formatBooleanYesNo;
      return formatBooleanTrueFalse;
    }
    case 'string': {
      return (value: string) => value;
    }
    default: {
      const formatter = handleDeprecatedCases(formatting);
      return formatter || null;
    }
  }
}
