import { useMemo } from 'react';
import { FieldPath } from 'react-hook-form';
import { SchemaDescription } from 'yup';
import { css } from '@emotion/react';
import { FundMetrics, fundMetricsFormSchema } from '../../../schemas/FundMetrics.schema';
import { LabeledField, LabeledFieldContainer } from '../../../components/LabeledField';
import { FMT } from '../../../util/formatter-service';
import { SubSection } from '../../CompanyProfiles/Summary/components/SubSection';

export const FundMetricsSummaryFields: FieldPath<FundMetrics>[] = [
  'fmv',
  'escrowReceivable',
  'contributions',
  'lpContributions',
  'gpContributions',
  'distributions',
  'lpDistributions',
  'gpDistributions',
];

interface IFundMetricsSummaryProps {
  fundMetrics: FundMetrics;
}

export function FundMetricsSummary({ fundMetrics }: IFundMetricsSummaryProps) {
  const schema = fundMetricsFormSchema().pick(FundMetricsSummaryFields);

  const metrics = useMemo(() => {
    return FundMetricsSummaryFields.map((field) => {
      const desc = schema.fields[field].describe() as SchemaDescription;
      const label = desc.label ?? '';
      const formatterId = desc.meta?.formatter ?? 'string';
      const value = fundMetrics?.[field];
      const formattedValue = value != null ? FMT.format(formatterId, value) : '-';
      return <LabeledField key={field} label={label ?? ''} value={formattedValue} />;
    });
  }, [fundMetrics, schema.fields]);

  return (
    <SubSection title='Fund Metrics Summary' style={{ marginTop: '0.5rem' }}>
      <ul
        css={css`
          ${LabeledFieldContainer};
        `}
      >
        {metrics}
      </ul>
    </SubSection>
  );
}
