import { Chip, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FC, useCallback, useState } from 'react';
import { FormikHelpers } from 'formik';
import { appConfigState } from '../../../../../services/state/AppConfigState';
import { formatDate } from '../../../../../util/formatters/DateFormatters';
import { IObjectiveDataModel } from '../../../../../data-models/objective.data-model';
import { StatusComponent } from '../StatusComponent/StatusComponent';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { EditActions } from '../EditActions/EditActions';
import { useObjectiveActions } from '../../../hooks/useObjectiveActions';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { ObjectiveModal } from '../OkrForms/ObjectiveModal';
import { ObjectiveFormValues } from '../OkrForms/ObjectiveForm';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { ConfirmDeletionDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { KeyResults } from './KeyResults';
import { UserCell } from './UserCell';
import { TEMPLATE_COLS } from './ObjectivesConstants';

const RowContainer = styled('div')`
  border: 2px solid #ffffff;

  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  margin: 0.3rem 0;
`;

interface ObjectiveRowProps {
  expanded?: boolean;
}
export const ObjectiveRow = styled('div')<ObjectiveRowProps>`
  display: grid;
  grid-template-columns: ${TEMPLATE_COLS};
  grid-auto-rows: 3.25rem;

  border-radius: 4px;
  align-content: center;
  ${({ expanded }) => expanded && `background: #F0F0FF;`}
`;

interface CellProps {
  hasDivider?: boolean;
  indent?: boolean;
  hasGap?: boolean;
  numeric?: boolean;
}
export const CenterAlignedCell = styled('div')<CellProps>`
  display: flex;
  align-items: center;
  padding-right: 0.5rem;

  & > * {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ hasGap }) => hasGap && `gap: 0.5rem`};
  ${({ hasDivider }) =>
    hasDivider ? `border-bottom: thin solid rgba(0, 0, 0, 0.12)` : `border-bottom: thin solid transparent`};

  ${({ indent }) => indent && `padding-left: 2rem;`}
  &.monospace {
    font-size: 0.8rem;
  }
  ${({ numeric }) => numeric && `justify-content: flex-end; padding-right: 3rem`};
`;

interface ObjectiveProps {
  objective: IObjectiveDataModel;
}

export const ObjectiveComponent: FC<ObjectiveProps> = ({ objective }) => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { colors } = useTheme();
  const objectiveCategories = useRecoilValue(appConfigState).objectiveCategory;
  const { id, name, status, updatedAt, keyResults, ownerId, categoryId } = objective;

  const [expanded, setExpanded] = useState(false);

  const { pushSuccessToast, pushErrorToast } = useToastMessageState();
  const { handleDeleteObjective, handleEditObjective } = useObjectiveActions();
  const [showObjectiveModal, setShowObjectiveModal] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const submitHandler = useCallback(
    async (values: ObjectiveFormValues, formikHelpers: FormikHelpers<ObjectiveFormValues>) => {
      formikHelpers.setSubmitting(true);
      const { name, categoryId, ownerId } = values;
      const payload = { id: objective.id, name, categoryId, ownerId, companyId };

      if (name === objective.name && categoryId === objective.categoryId && ownerId === objective.ownerId) {
        setShowObjectiveModal(false);
        return;
      }

      try {
        await handleEditObjective(payload);
        pushSuccessToast({ message: 'Objective updated successfully' });
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Failed to update objective.') });
      } finally {
        formikHelpers.setSubmitting(false);
        setShowObjectiveModal(false);
      }
    },
    [
      companyId,
      handleEditObjective,
      objective.categoryId,
      objective.id,
      objective.name,
      objective.ownerId,
      pushErrorToast,
      pushSuccessToast,
    ]
  );

  const handleDelete = useCallback(
    async (objective: IObjectiveDataModel) => {
      try {
        await handleDeleteObjective(objective.companyId, objective.id);
        pushSuccessToast({ message: 'Objective deleted successfully' });
      } catch (err) {
        console.error(err);
        pushErrorToast({ message: getErrorMessage(err, 'Error deleting objective') });
      } finally {
        setShowConfirmDelete(false);
      }
    },
    [handleDeleteObjective, pushErrorToast, pushSuccessToast]
  );

  return (
    <>
      <RowContainer key={id}>
        <ObjectiveRow expanded={expanded} className='componentWithActionOnHover'>
          <CenterAlignedCell hasDivider={expanded}>
            <IconButton onClick={() => setExpanded((prev) => !prev)} aria-label='expand objective'>
              <ChevronRightIcon
                sx={{
                  transition: 'transform .2s',
                  transform: `${expanded ? 'rotate(90deg)' : ''}`,
                }}
              />
            </IconButton>
            <StyledTooltip title={name} enterDelay={500} placement='top-start'>
              <Typography variant='body2'>{name}</Typography>
            </StyledTooltip>
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={expanded}>
            <StatusComponent status={status} />
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={expanded}></CenterAlignedCell>
          <CenterAlignedCell hasDivider={expanded}>
            <Chip
              label={objectiveCategories.find((c) => c.id === categoryId)?.displayName ?? ''}
              sx={{
                borderRadius: '4px',
                backgroundColor: colors.primary[10],
                color: colors.primary[60],
              }}
            />
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={expanded}>
            <Typography variant='body2'>{updatedAt && formatDate(updatedAt)}</Typography>
          </CenterAlignedCell>
          <CenterAlignedCell hasDivider={expanded}>
            {typeof ownerId === 'number' ? <UserCell userId={ownerId} /> : <></>}
          </CenterAlignedCell>
          <EditActions
            onDelete={() => setShowConfirmDelete(true)}
            onEdit={() => setShowObjectiveModal(true)}
          />
        </ObjectiveRow>
        <Collapse in={expanded}>
          <KeyResults keyResults={keyResults} objective={objective} />
        </Collapse>
      </RowContainer>
      <ConfirmDeletionDialog
        title='Delete this Objective?'
        open={showConfirmDelete}
        onConfirm={() => handleDelete(objective)}
        onClose={() => setShowConfirmDelete(false)}
      >
        <Typography variant='body2' color={colors.neutral[60]}>
          Are you sure you want to permanently delete this objective?
        </Typography>
      </ConfirmDeletionDialog>
      <ObjectiveModal
        onClose={() => setShowObjectiveModal(false)}
        open={showObjectiveModal}
        initialValues={{
          name: objective.name,
          categoryId: objective.categoryId,
          ownerId: objective.ownerId,
        }}
        onSubmit={submitHandler}
      />
    </>
  );
};
