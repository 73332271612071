import { get } from 'lodash-es';
import { ReactElement, useMemo } from 'react';
import { FormatterService } from '../../../../../util/formatter-service';
import { IScenarioRunDataModel } from '../../../../../data-models/scenario-run.data-model';
import { DataCard } from '../../../../../components/Card/DataCard';
import { LabeledFieldContainer } from '../../../../../components/LabeledField';
import { IFormField } from '../../../../../view-models/form.view-model';

interface IScenarioKPIsProps {
  fields: IFormField<unknown>[];
  lastRoundRunData: IScenarioRunDataModel;
}
export function ScenarioKPIs({ fields, lastRoundRunData }: IScenarioKPIsProps) {
  const cards = useMemo(() => {
    return fields
      .map((field) => {
        const value = get(lastRoundRunData, field.key);

        if (value !== undefined) {
          const formatter = FormatterService.get().getFormatterForFormField(field);
          const formattedValue = formatter(value);

          return (
            <DataCard key={field.label} title={field.label ?? field.key} formattedValue={formattedValue} />
          );
        }
        return null;
      })
      .filter((elem) => elem !== null) as unknown as ReactElement;
  }, [fields, lastRoundRunData]);

  return (
    <ul css={LabeledFieldContainer} style={{ gridTemplateColumns: 'repeat(auto-fit, 12.5fr)' }}>
      {cards}
    </ul>
  );
}
