import { IFinanceRoundDataModel } from '../../data-models/finance-round.data-model';
import { IRoundDataModel } from '../../data-models/round.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

interface IStageFormatterInitData {
  investmentMap: Map<number, IFinanceRoundDataModel>;
  stageMap: Map<number, IRoundDataModel>;
}

export class StageFormatter implements IInitializedFormatter<IStageFormatterInitData, number | number[]> {
  #investmentRounds: Map<number, IFinanceRoundDataModel> = new Map();
  #stages: Map<number, IRoundDataModel> = new Map();

  init({ investmentMap, stageMap }: IStageFormatterInitData) {
    this.#investmentRounds = investmentMap;
    this.#stages = stageMap;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    const roundId = this.#investmentRounds.get(value)?.roundId;
    return this.#stages.get(roundId ?? -1)?.displayName ?? 'Unknown stage';
  };
}
