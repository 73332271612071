import { format } from '../kpi-formatter';
import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../../types';

export class TotalInvestmentConfigRoundTracker implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT_RT as const;
  private title = 'Total Investment';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const total =
      data?.filteredData?.reduce((sum, metric) => {
        return sum + (metric.gcAmount ?? 0);
      }, 0) ?? 0;

    return {
      ...this.getMeta(),
      value: total,
      formatted: format(total, this.type),
    };
  }
}
