import { FC } from 'react';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { IAirTableFieldDataModel } from '../../../../data-models/airtable-base.data-model';

interface IFieldSelectorProps {
  value: IAirTableFieldDataModel | null;
  onChange: (value: IAirTableFieldDataModel | null) => void;
  options: IAirTableFieldDataModel[];
  sx?: SxProps;
}

export const FieldSelector: FC<IFieldSelectorProps> = ({ value, onChange, options, sx }) => {
  return (
    <Autocomplete
      value={value}
      onChange={(e, newValue) => onChange(newValue)}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => <TextField {...params} />}
      style={{ marginTop: '-0.35rem' }}
      sx={sx}
      openOnFocus
      blurOnSelect
    />
  );
};
