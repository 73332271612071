import { Typography, useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import { IWorkExperienceDataModel } from '../../../../data-models/person.data-model';
import { getPeriod } from '../../utils/workHistoryUtils';
import { StyledTable } from './StyledHistoryTable';
const Container = styled('div')`
  width: 30rem;
  background: white;
  padding: 0.5rem;
`;

const ScrollableBody = styled('div')`
  max-height: 10rem;
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    background: var(--thumbBG);
    border-radius: 8px;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
`;

interface WorkHistoryTableProps {
  workHistory: IWorkExperienceDataModel[];
  style?: CSSProperties;
  showTitle?: boolean;
  tableBodyStyle?: CSSProperties;
}

export const WorkHistoryTable: FC<WorkHistoryTableProps> = ({
  workHistory,
  style,
  tableBodyStyle,
  showTitle = true,
}) => {
  const { colors } = useTheme();

  if (!workHistory?.length)
    return (
      <Typography variant='body2' color={colors.neutral[60]}>
        No data
      </Typography>
    );
  return (
    <Container style={style}>
      {showTitle && (
        <Typography
          variant='body2'
          sx={{ marginBottom: '.5rem' }}
          color='text.primary'
        >{`All Work History (${workHistory.length})`}</Typography>
      )}
      <StyledTable>
        <WorkHistoryColGroup />
        <thead>
          <tr>
            <th>Company</th>
            <th>Title</th>
            <th>Period</th>
          </tr>
        </thead>
      </StyledTable>
      <ScrollableBody style={tableBodyStyle}>
        <StyledTable>
          <WorkHistoryColGroup />

          <tbody>
            {workHistory.map((wh, i) => (
              <tr key={i}>
                <td>
                  <Typography variant='caption' color='text.primary'>
                    {wh.orgName}
                  </Typography>
                </td>
                <td>
                  {' '}
                  <Typography variant='caption' color='text.primary'>
                    {wh.title}
                  </Typography>
                </td>
                <td>{getPeriod(wh)}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </ScrollableBody>
    </Container>
  );
};

function WorkHistoryColGroup() {
  return (
    <colgroup>
      <col style={{ width: '30%' }} />
      <col style={{ width: '40%' }} />
      <col style={{ width: '30%' }} />
    </colgroup>
  );
}
