import { createHeadlessEditor } from '@lexical/headless';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isAfter } from 'date-fns';
import { $getRoot } from 'lexical';
import { noop } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UserAvatarList } from '../../../../../components/AvatarListTooltip/UserAvatarList';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { usePreviousValue } from '../../../../../hooks/usePreviousValue';
import { Commentary, NewCommentaryId } from '../../../../../schemas/Commentary.schema';
import { commentariesByCompanyIdState } from '../../../../../services/state/CommentariesState';
import { cardStyles } from '../../../../../theme/component-styles';
import { plainDateToZonedDateTime } from '../../../../../util/date-utilts';
import { FMT } from '../../../../../util/formatter-service';
import {
  PaneContents,
  PanelContainer,
  PanelPagination,
  PanelTitle,
} from '../../../../CompanyProfiles2/Summary/Panel';
import { isDrawerOpenState, selectedCommentaryState } from '../../../state/UIState';
import { NoDataMessage2 } from '../../Messages/HelpMessage';
import { useGetTodaysCommentary } from './useCommentaryActions';

const SUMMARY_MAX_CHAR = 250;

const CommentariesPanelHeader = styled('div')`
  align-items: center;
  column-gap: 1rem;
  display: grid;
  grid-template-areas: 'title fund-selector spacer pages';
  grid-template-columns: max-content minmax(12.5rem, 20rem) auto max-content;
`;

const CommentarySummaryCardWrapper = styled('div')`
  display: grid;
  height: 100%;
  row-gap: 0.5rem;
  width: 100%;
  :hover {
    cursor: pointer;
  }
`;

const CommentaryTextWrapper = styled('div')`
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.75rem;
  position: relative;
  border: thin solid transparent;
  transition: border-color 0.2s;
  :hover {
    border-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ActionButtonsWrapper = styled('div')`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
`;

export function RecentCommentariesPanel(props: { companyId: number }) {
  const { companyId } = props;
  const setSelectedCommentary = useSetRecoilState(selectedCommentaryState);
  const setOpenDrawer = useSetRecoilState(isDrawerOpenState);
  const commentaries = useRecoilValue(commentariesByCompanyIdState(companyId));
  const [selectedSummary, setSelectedSummary] = useState(commentaries?.at(0));

  const sortedCommentaries = useMemo(() => {
    return getSortedActivities(commentaries ?? []);
  }, [commentaries]);
  const todaysCommentary = useGetTodaysCommentary(companyId);
  const previousCommentaries = usePreviousValue(sortedCommentaries);

  const onPageChange = useCallback(
    (pageId: number) => {
      setSelectedSummary(sortedCommentaries[pageId - 1]);
    },
    [sortedCommentaries, setSelectedSummary]
  );

  const onEditClick = useCallback(() => {
    setSelectedCommentary(selectedSummary);
    setOpenDrawer(true);
  }, [selectedSummary, setOpenDrawer, setSelectedCommentary]);

  const onCreateClick = useCallback(() => {
    setSelectedCommentary(todaysCommentary);
    setOpenDrawer(true);
  }, [setOpenDrawer, setSelectedCommentary, todaysCommentary]);

  useEffect(
    function onDataUpdate() {
      if (previousCommentaries?.at(0)?.id !== sortedCommentaries.at(0)?.id) {
        const newlyAddedCommentary = sortedCommentaries[0];
        setSelectedSummary(newlyAddedCommentary);
      } else {
        const selectedIndex = sortedCommentaries.findIndex((com) => com.id === selectedSummary?.id);
        const updatedCommentary = sortedCommentaries[selectedIndex];
        setSelectedSummary(updatedCommentary);
      }
    },
    [previousCommentaries, selectedSummary?.id, sortedCommentaries]
  );

  return (
    <GenericFallbacksWrapper>
      <PanelContainer style={{ ...cardStyles }}>
        <CommentariesPanelHeader>
          <PanelTitle key={'title'} title={'Recent Performance Commentaries'} />
          <PanelPagination
            key={`panel-${sortedCommentaries.at(0)?.id ?? -1}`}
            pageCount={Math.min(3, sortedCommentaries.length)}
            onChange={onPageChange}
          />
        </CommentariesPanelHeader>
        <PaneContents showOverflow={false}>
          {selectedSummary ? (
            <CommentarySummary
              key={`preview-${selectedSummary.updatedAt}`}
              commentary={selectedSummary}
              onEditClick={onEditClick}
            />
          ) : (
            <NoDataMessage2 />
          )}
        </PaneContents>
        <ActionButtonsWrapper>
          {todaysCommentary.id === NewCommentaryId ? (
            <Button color={'secondary'} variant={'outlined'} onClick={onCreateClick} endIcon={<AddIcon />}>
              Add Performance Commentary
            </Button>
          ) : (
            <Button color={'secondary'} variant={'outlined'} onClick={onCreateClick}>
              {`Edit Today's Commentary`}
            </Button>
          )}
        </ActionButtonsWrapper>
      </PanelContainer>
    </GenericFallbacksWrapper>
  );
}

function getSortedActivities(commentaries: Commentary[]) {
  return [...commentaries].sort((com1, com2) => {
    return isAfter(plainDateToZonedDateTime(com1.date), plainDateToZonedDateTime(com2.date)) ? -1 : 1;
  });
}

interface ICommentarySummaryProps {
  commentary: Commentary;
  onEditClick: () => void;
}

function CommentarySummary(props: ICommentarySummaryProps) {
  const { commentary, onEditClick } = props;
  const commentaryText = getCommentaryText(commentary);

  return (
    <CommentarySummaryCardWrapper>
      <Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'} gap={'0.5rem'}>
            <UserAvatarList users={[]} />
            <Typography variant={'caption'} color={'text.secondary'}>{`${FMT.format(
              'date',
              commentary.createdAt
            )} at ${FMT.format('time12hr', commentary.createdAt)}`}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <CommentaryTextWrapper onClick={onEditClick}>
        <Typography variant={'body2'}>{commentaryText}</Typography>
      </CommentaryTextWrapper>
    </CommentarySummaryCardWrapper>
  );
}

function getCommentaryText(commentary: Commentary) {
  const editor = createHeadlessEditor({
    nodes: [],
    onError: noop,
  });
  const parsedEditorState = editor.parseEditorState(commentary.text);
  const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());
  const textMaybeEllipsized =
    editorStateTextString.length > SUMMARY_MAX_CHAR
      ? `${editorStateTextString.slice(0, SUMMARY_MAX_CHAR)}...`
      : editorStateTextString;

  return textMaybeEllipsized;
}
