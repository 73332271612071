import { useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';
import { getDefaultStore } from 'jotai';
import { LDSingleKindContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { allInvestmentRoundsMapState } from '../pages/Finance2/state/FinanceState';
import { useToken } from '../services/hooks/useToken';
import { MaggieHttp } from '../services/MaggieHttp';
import { fetchAppConfig } from '../services/queries/MaggieConfigQueries';
import { getAllPreferences } from '../services/queries/MaggiePreferencesQueries';
import {
  appConfigState,
  fundsByIdMapState,
  roundsMapState,
  sectorsMapState,
  transactionTypesMapState,
  usersByIdMapState,
} from '../services/state/AppConfigState';
import { initConfigsWithMeta } from '../services/state/AppConfigStateJ';
import { authenticatedUserState, sessionExpiredState } from '../services/state/AuthState';
import { basic_formatters } from '../test-utils/test-scenarios/app-config.scenario';
import { LoadingStatus } from '../types';
import { AgFormatterService } from '../util/ag-formatter-service';
import { ColourSchemeGenerator } from '../util/ColourSchemeGenerator';
import { getEnvironment, isLocal } from '../util/environment';
import { FormatterService } from '../util/formatter-service';
import { FundFormatter } from '../util/formatters/FundFormatter';
import { InvestmentRoundFormatter } from '../util/formatters/InvestmentRoundFormatter';
import { RoundFormatter } from '../util/formatters/RoundFormatter';
import { SectorFormatter } from '../util/formatters/SectorFormatter';
import { StageFormatter } from '../util/formatters/StageFormatter';
import { TransactionTypeFormatter } from '../util/formatters/TransactionTypeFormatter';
import { UserFormatter } from '../util/formatters/UserFormatter';
import { PreferencesServiceV3 } from '../util/preferences-service-v3';
import { transactionTypeServiceState } from '../util/transaction-type-service';
import { userServiceState } from '../util/user-service';
import { useCleanLocalStorageShortcut } from './useCleanLocalStorageShortcut';
import { useCopyTokenToConsole } from './useCopyTokenToConsole';
import { useInitSocket } from './useInitSocket';

const emailDomainRegex = /(.*)@(.*)(.com)/;

export function useInitApp() {
  const colourGenStatus = useInitColourGenerator();
  const connectionStatus = useInitConnections();
  const appConfigStatus = useInitAppConfig(connectionStatus);
  const launchDarklyStatus = useInitLaunchDarkly();
  const preferencesStatus = useInitPreferencesServiceV3();

  useInitSentry();
  useInitPendo();
  // FIXME Restore after MAGGIE-3617
  // useInitSocket();
  useCopyTokenToConsole();
  useCleanLocalStorageShortcut();
  useInitSocket();

  return useMemo(() => {
    const appOverallStatus = getAggregateStatus([
      appConfigStatus,
      colourGenStatus,
      connectionStatus,
      launchDarklyStatus,
      preferencesStatus,
    ]);

    return {
      appConfig: appConfigStatus,
      colorGenerator: colourGenStatus,
      connectionStatus: connectionStatus,
      overallStatus: appOverallStatus,
    };
  }, [appConfigStatus, colourGenStatus, connectionStatus, launchDarklyStatus, preferencesStatus]);
}

function useInitColourGenerator() {
  const theme = useTheme();
  ColourSchemeGenerator.init(theme.colors);

  return LoadingStatus.success;
}

function useInitSentry() {
  const user = useRecoilValue(authenticatedUserState);

  useEffect(() => {
    if (isLocal() || !import.meta.env.VITE_SENTRY_DSN || !user?.email) {
      return;
    }

    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: getEnvironment(),
      integrations: [browserTracingIntegration()],
      release: import.meta.env.VITE_DEPLOY_VERSION,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
    });

    Sentry.setUser({
      email: user?.email,
    });
  }, [user]);
}

function useInitConnections() {
  const user = useRecoilValue(authenticatedUserState);
  const getToken = useToken();
  const setSessionExpired = useSetRecoilState(sessionExpiredState);
  const [status, setStatus] = useState(LoadingStatus.loading);

  useEffect(() => {
    if (user === undefined || status !== LoadingStatus.loading) return;

    MaggieHttp.init({
      getToken,
      on401Callback: () => setSessionExpired(true),
    });

    setStatus(LoadingStatus.success);
  }, [getToken, setSessionExpired, status, user]);

  return status;
}

function useInitAppConfig(connectionStatus: LoadingStatus) {
  const [status, setStatus] = useState(LoadingStatus.idle);
  const setAppConfigState = useSetRecoilState(appConfigState);
  const initFormatters = useInitFormatters();
  const initServices = useInitServices();

  useEffect(() => {
    if (status === LoadingStatus.idle && connectionStatus === LoadingStatus.success) {
      setStatus(LoadingStatus.loading);

      fetchAppConfig()
        .then((configMeta) => {
          try {
            setAppConfigState(configMeta);
            initConfigsWithMeta(getDefaultStore(), cloneDeep(configMeta));

            FormatterService.initService([...basic_formatters(), ...configMeta.formatters]);
            AgFormatterService.initService();

            return Promise.all([initFormatters(), initServices()]);
          } catch (err) {
            console.error('Failed to initialize formatters, using defaults:\n', err);
            Sentry.captureException(err);
            FormatterService.initService(basic_formatters());
          }
        })
        .then(() => {
          setStatus(LoadingStatus.success);
        })
        .catch(() => {
          setStatus(LoadingStatus.error);
        });
    }
  }, [connectionStatus, initFormatters, initServices, setAppConfigState, status]);

  return status;
}

function useInitPendo() {
  const user = useRecoilValue(authenticatedUserState);

  useEffect(() => {
    const email = user?.email ?? '';

    if (isLocal() || !email) {
      return;
    }

    const domain = email.match(emailDomainRegex)?.[2] ?? email;
    window.pendo.initialize({
      visitor: {
        id: email,
        environment: getEnvironment(),
      },
      account: {
        id: domain,
      },
    });
  }, [user?.email]);
}

function useInitLaunchDarkly() {
  const [status, setStatus] = useState(LoadingStatus.loading);
  const user = useRecoilValue(authenticatedUserState);
  const ldClient = useLDClient();

  useEffect(() => {
    if (!user) {
      return;
    }

    const email = user.email;
    const ldUser: LDSingleKindContext = {
      kind: 'user',
      key: email,
      email,
    };

    ldClient?.identify(ldUser).then(() => {
      setStatus(LoadingStatus.success);
    });
  }, [ldClient, user]);

  return status;
}

function getAggregateStatus(statuses: LoadingStatus[]) {
  for (const status of statuses) {
    if (status === LoadingStatus.error) {
      return LoadingStatus.error;
    }
    if (status === LoadingStatus.loading) {
      return LoadingStatus.loading;
    }
  }

  return LoadingStatus.success;
}

export function useInitFormatters() {
  return useRecoilCallback(({ snapshot }) => async () => {
    const fundsById = await snapshot.getPromise(fundsByIdMapState);
    const roundsById = await snapshot.getPromise(roundsMapState);
    const sectorsById = await snapshot.getPromise(sectorsMapState);
    const transactionTypesById = await snapshot.getPromise(transactionTypesMapState);
    const usersById = await snapshot.getPromise(usersByIdMapState);
    const investmentRounds = await snapshot.getPromise(allInvestmentRoundsMapState);
    const transTypeFormatter = new TransactionTypeFormatter().init(transactionTypesById);
    const service = FormatterService.get();

    service.setFormatterForId('fund', new FundFormatter().init(fundsById).format);
    service.setFormatterForId('round', new RoundFormatter().init(roundsById).format);
    service.setFormatterForId('sector', new SectorFormatter().init(sectorsById).format);
    service.setFormatterForId('transactionType', transTypeFormatter.format);
    service.setFormatterForId('transactionCategory', transTypeFormatter.formatCategory);
    service.setFormatterForId('transactionSubType', transTypeFormatter.formatSubType);
    service.setFormatterForId('user', new UserFormatter().init(usersById).format);
    service.setFormatterForId('userByEmail', new UserFormatter().init(usersById).formatByEmail);
    service.setFormatterForId(
      'investmentRound',
      new InvestmentRoundFormatter().init(investmentRounds).format
    );
    service.setFormatterForId(
      'stage',
      new StageFormatter().init({ investmentMap: investmentRounds, stageMap: roundsById }).format
    );
  });
}

export function useInitPreferencesServiceV3() {
  const [status, setStatus] = useState(LoadingStatus.loading);
  const initPrefs = useCallback(async () => {
    try {
      const preferences = await getAllPreferences();
      PreferencesServiceV3.initService(preferences);
      setStatus(LoadingStatus.success);
    } catch (error) {
      PreferencesServiceV3.initService([]);
      console.error('Failed to initialize preferences v3');
      setStatus(LoadingStatus.success);
    }
  }, []);

  useEffect(() => {
    initPrefs();
  }, [initPrefs]);

  return status;
}

export function useInitServices() {
  return useRecoilCallback(({ snapshot }) => async () => {
    await snapshot.getPromise(transactionTypeServiceState);
    await snapshot.getPromise(userServiceState);
  });
}
