import * as yup from 'yup';
import {
  requiredMsg,
  urlMsg,
} from '../../../../Finance/components/TransactionModal/Forms/utils/validationMessages';
import { VALID_URL_REGEX } from '../../../../../util/regex';

export const CompanySchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')),
  website: yup.string().nullable().matches(VALID_URL_REGEX, urlMsg('Website')),
  sectorId: yup.number(),
  city: yup.string(),
  state: yup.string().optional().nullable(),
  country: yup.string(),
  ceoName: yup.string().nullable(),
  shortDescription: yup.string().optional().nullable(),
  sourceType: yup.string(),
  internalSourceId: yup.number().optional().nullable(),
});
