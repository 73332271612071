import { ButtonBase, Typography } from '@mui/material';
import { ReactElement, ReactNode, useCallback } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import { theme } from '../../../theme';
import { isNavigationBarStateOpen } from '../state/NavigationState';

const { primary, tertiary, secondary } = theme.colors;

interface Props {
  icon: ReactNode;
  text: string;
  path: string;
}

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 6px;
  padding-top: 10px;
`;

const IconWrapper = styled('span')<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ isActive }) => (isActive ? primary['40'] : 'transparent')};
  border-radius: 4px;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  color: ${({ isActive }) => (isActive ? tertiary['20'] : primary['0'])};

  &:last-child {
    margin-top: auto;
  }
`;
const Icon = styled('div')`
  margin-bottom: -3px;
`;

const TypographyWrapper = styled('span')`
  position: relative;
  top: 2px;
`;

export function NavItem({ icon, text, path }: Props): ReactElement {
  const navigate = useNavigate();
  const setIsOpen = useSetRecoilState(isNavigationBarStateOpen);

  const onClick = useCallback(() => {
    setIsOpen(true);
    navigate(path);
  }, [navigate, path, setIsOpen]);

  const [, parentRoutePath] = useResolvedPath(path).pathname.split('/');
  const isActive = useMatch({
    path: `/${parentRoutePath}` + '/*',
  });

  return (
    <ButtonBase TouchRippleProps={{ color: 'white' }} style={{ width: '100%' }} onClick={onClick}>
      <Wrapper data-testid={'nav-item-2'}>
        <IconWrapper isActive={!!isActive}>
          <Icon>{icon}</Icon>
        </IconWrapper>
        <TypographyWrapper>
          <Typography variant={'caption2'} color={secondary['10']}>
            {text}
          </Typography>
        </TypographyWrapper>
      </Wrapper>
    </ButtonBase>
  );
}
