import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface ICollapseButtonProps {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  buttonProps?: IconButtonProps;
  iconProps?: SvgIconProps;
}
export function CollapseButton({ expanded, setExpanded, buttonProps, iconProps }: ICollapseButtonProps) {
  const { sx: buttonSx = {}, ...restButtonProps } = buttonProps ?? {};
  const { sx: iconSx = {}, ...restIconProps } = iconProps ?? {};
  return (
    <IconButton
      onClick={() => setExpanded((prev) => !prev)}
      aria-label='expand content'
      size='small'
      sx={{ p: '0.1rem', ...buttonSx }}
      {...restButtonProps}
    >
      <ChevronRightIcon
        sx={{
          transition: 'transform .1s',
          transform: `${expanded ? 'rotate(90deg)' : ''}`,
          ...iconSx,
        }}
        {...restIconProps}
      />
    </IconButton>
  );
}
