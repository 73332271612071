import { Typography, useTheme } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { NavigationWrapper } from '../NavigationWrapper';
import { AnalyticsSectionsList } from './AnalyticsSectionsList';
import { DealCategoryList } from './DealCategoryList';

// FIXME: Reuse SubSectionNavigation component: https://foresightdata.atlassian.net/jira/software/projects/MAGGIE/boards/7?assignee=63bda5b08a7d2f693bf6c73f&selectedIssue=MAGGIE-4279
export const DealflowNavigation = () => {
  const { secondary } = useTheme().colors;
  const match = useMatch(`/${ROUTES.DEAL_FLOW}/*`);

  if (match === null) return null;

  return (
    <NavigationWrapper title={'Pipeline'}>
      <Typography variant='subtitle2' color={secondary[20]}>
        DEALS
      </Typography>
      <DealCategoryList />

      <Typography variant='subtitle2' color={secondary[20]} style={{ marginTop: '1.5rem' }}>
        ANALYTICS
      </Typography>
      <AnalyticsSectionsList />
    </NavigationWrapper>
  );
};
