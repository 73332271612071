import { useCallback, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, styled, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { capitalizeString } from '../../../util/formatters/StringFormatters';
import { ILexicalNoteFormValues } from '../../../data-models/note.data-model';
import { noteCommentsState } from '../../../pages/CompanyProfiles/state/NoteCommentsState';
import { useLexicalContext } from '../provider/LexicalProvider';
import { LexicalEditorAttendeesTab } from './LexicalEditorAttendeesTab';
import { LexicalEditorCommentsTab } from './LexicalEditorCommentsTab';
import { LexicalEditorTabPage } from './LexicalEditorTabPage';

export const FooterTabsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const CustomBottomNavigationAction = styled(BottomNavigationAction)(({ theme: { colors } }) => ({
  color: colors.neutral[40],
  '&.Mui-selected': {
    color: colors.neutral[60],
  },
  '&.MuiBottomNavigationAction-root': {
    display: 'flex',
    flexDirection: 'row',
    gap: '.25rem',
    '&.MuiBottomNavigationAction-label': {
      fontSize: '1.5rem',
      fontWeight: '700',
    },
  },
}));

export enum LexicalFooterTabsTypes {
  Attendees = 'attendees',
  Mentions = 'mentions',
  Tags = 'tags',
  Comments = 'comments',
  Attachments = 'attachments',
}

const variants = {
  open: { display: 'block', y: 0 },
  closed: { display: 'none', y: '100%' },
};

export const LexicalFooter = () => {
  const { noteData } = useLexicalContext();
  const { watch } = useFormContext<ILexicalNoteFormValues>();
  const notesComments = useRecoilValue(noteCommentsState(noteData?.id ?? 0)) ?? [];
  const { colors } = useTheme();

  const selectedAttendees = watch('body.attendees');
  const [selectedSection, setSelectedSection] = useState<LexicalFooterTabsTypes | string>('');

  const onCloseSection = useCallback(() => {
    setSelectedSection('');
  }, []);

  return (
    <FooterTabsWrapper
      sx={{
        height: selectedSection ? '65%' : '100px',
      }}
    >
      <motion.div
        initial={{
          y: '100%',
        }}
        animate={selectedSection ? 'open' : 'closed'}
        variants={variants}
        style={{ position: 'relative', height: '100%', width: '100%' }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          <LexicalFooterSection onClose={onCloseSection} selectedSection={selectedSection} />
        </Box>
      </motion.div>

      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%', zIndex: 5 }}>
        <BottomNavigation
          showLabels
          value={selectedSection}
          onChange={(_event, newValue) => {
            setSelectedSection(newValue);
          }}
          sx={{
            backgroundColor: colors.neutral[5],
          }}
        >
          <CustomBottomNavigationAction
            label={selectedAttendees.length}
            value={LexicalFooterTabsTypes.Attendees}
            icon={<AccountCircleOutlinedIcon />}
          />
          <CustomBottomNavigationAction
            label={notesComments.length}
            value={LexicalFooterTabsTypes.Comments}
            icon={<ForumOutlinedIcon />}
          />
        </BottomNavigation>
      </Box>
    </FooterTabsWrapper>
  );
};

type LexicalFooterSectionProps = {
  selectedSection: string;
  onClose: () => void;
};

function LexicalFooterSection({ selectedSection, onClose }: LexicalFooterSectionProps) {
  let selectedTabComponent = null;
  switch (selectedSection) {
    case LexicalFooterTabsTypes.Attendees:
      selectedTabComponent = <LexicalEditorAttendeesTab />;
      break;
    case LexicalFooterTabsTypes.Comments:
      selectedTabComponent = <LexicalEditorCommentsTab />;
      break;
    default:
      return null;
  }

  const selectedTabTitle = capitalizeString(selectedSection);

  return (
    <LexicalEditorTabPage tabTitle={selectedTabTitle} onClose={onClose}>
      {selectedTabComponent}
    </LexicalEditorTabPage>
  );
}
