import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class CapitalLossRatioConfig implements KpiConfig {
  private id = KPI_COMPONENTS.CAPITAL_LOSS_RATIO as const;
  private title = 'Capital Loss Ratio';
  private type = 'percentage';
  private description =
    'The percentage of capital in companies realized below cost, net of any proceeds, divided by the total invested capital';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      description: this.description,
    };
  }

  calculate(params: KpiCalculationParams): Kpi {
    let totalAmountInvested = 0;
    let totalInvestmentProceedsDiff = 0;

    params.data?.rawCompanies.forEach((metrics) => {
      totalAmountInvested += metrics.amountInvested;
      if (countsAsLoss(metrics)) {
        totalInvestmentProceedsDiff += metrics.amountInvested - metrics.distributions;
      }
    });

    const value = totalAmountInvested === 0 ? 0 : (totalInvestmentProceedsDiff / totalAmountInvested) * 100;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}

function countsAsLoss(metrics: IMetricsDataModel) {
  return metrics.activeStatus === 'Realized' && metrics.totalValue < metrics.amountInvested;
}
