import AddIcon from '@mui/icons-material/Add';
import { Button, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { FC, useState } from 'react';
import { useUpdateMappings } from '../../../services/hooks/useMappingsActions';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { MappingsEventType } from '../MappingsGrid/mappingsGridEvents';
import { FieldActionButtons } from './components/FieldActionButtons';
import { FormFooterButtons } from './components/FormFooterButtons';
import {
  FieldAndButtonContainer,
  FieldContainer,
  FormContainer,
  Label,
  LabelAndButtonContainer,
} from './components/FormUI';
import { DisabledTextField } from './DisabledTextField';
import { secondaryWebsitesInitialValues } from './schemas/secondaryWebsitesInitialValues';
import { secondaryWebsitesSchema } from './schemas/secondaryWebsitesSchema';
import { IMappingsFormProps } from './IMappingsFormProps';

const FieldAndButtonsContainer = styled('div')`
  display: flex;
  width: 100%;
  gap: 0.25rem;
`;

export const SecondaryWebsitesForm: FC<IMappingsFormProps> = ({ data, onCancel }) => {
  const { colors } = useTheme();
  const updateMappings = useUpdateMappings();
  const [selectedSecondaryWebsite, setSelectedSecondaryWebsite] = useState('');
  const [selectedPrimaryWebsite, setSelectedPrimaryWebsite] = useState<string>('');
  const [showConfirmationWebsiteDeletion, setShowConfirmationWebsiteDeletion] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      ...secondaryWebsitesInitialValues,
      ...data,
    },
    validateOnChange: true,
    validationSchema: secondaryWebsitesSchema,
    onSubmit: async (values) => {
      await updateMappings({ ...data, ...values }, MappingsEventType.editSecondary);
      onCancel?.();
    },
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting } =
    formik;

  const textFieldProps = { margin: 0, width: '100%' };

  const openConfirmDeletionModal = (website: string, removeItem: (() => void) | undefined) => {
    if (!website) {
      return removeItem!();
    }

    setShowConfirmationWebsiteDeletion(true);
    setSelectedSecondaryWebsite(website);
  };

  const handleOnDeleteWebsite = () => {
    if (selectedSecondaryWebsite) {
      const updatedWebsites = values.secondary?.filter((website) => website !== selectedSecondaryWebsite);

      setFieldValue('secondary', updatedWebsites);
      setSelectedSecondaryWebsite('');
    } else if (selectedPrimaryWebsite) {
      setFieldValue('primary', '');
      setSelectedPrimaryWebsite('');
    }
    setShowConfirmationWebsiteDeletion(false);
  };

  return (
    <div>
      <ConfirmDeletionDialog
        open={showConfirmationWebsiteDeletion}
        onClose={() => {
          setShowConfirmationWebsiteDeletion(false);
          setSelectedPrimaryWebsite('');
          setSelectedSecondaryWebsite('');
        }}
        onConfirm={handleOnDeleteWebsite}
        title={`Delete ${selectedSecondaryWebsite ? 'Secondary' : 'Primary'} Website "${
          selectedSecondaryWebsite || selectedPrimaryWebsite
        }"?`}
      >
        <>
          <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem' }}>
            All data vendor links to {selectedSecondaryWebsite || selectedPrimaryWebsite} will be removed
          </Typography>
        </>
      </ConfirmDeletionDialog>
      <FormikProvider value={formik}>
        <FormContainer>
          <FieldContainer>
            <Label htmlFor='primary'>Current Primary Website</Label>
            <FieldAndButtonContainer>
              <DisabledTextField sx={textFieldProps} value={values.primary ?? ''} />
              {Boolean(values.primary) && (
                <IconButton
                  aria-label='Delete Primary Website'
                  onClick={() => {
                    setSelectedPrimaryWebsite(values.primary as string);
                    setShowConfirmationWebsiteDeletion(true);
                  }}
                  sx={{
                    color: colors.neutral[60],
                  }}
                >
                  <DeleteOutlineIcon fontSize='small' />
                </IconButton>
              )}
            </FieldAndButtonContainer>
          </FieldContainer>

          <FieldContainer>
            <FieldArray
              name='secondary'
              render={(arrayHelpers) => (
                <>
                  <LabelAndButtonContainer>
                    <Label>Secondary Websites</Label>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      sx={{ fontSize: '.75rem' }}
                      onClick={() => arrayHelpers.push('')}
                      startIcon={<AddIcon />}
                    >
                      Add New
                    </Button>
                  </LabelAndButtonContainer>
                  {values.secondary && values.secondary.length > 0 ? (
                    values.secondary.map((_, index: number) => {
                      const isTouched =
                        (touched.secondary as unknown as []) && (touched.secondary as unknown as [])[index];
                      const value = values.secondary?.[index];
                      const hasErrors = errors.secondary?.[index];

                      return (
                        <FieldAndButtonsContainer key={index}>
                          <TextField
                            sx={{ ...textFieldProps, width: '100%' }}
                            value={value}
                            name={`secondary.${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Enter new address'
                            error={!!isTouched && !!hasErrors}
                            helperText={(isTouched && hasErrors) || ''}
                          />
                          <FieldActionButtons
                            markAsPrimaryDisabled={!value || !!hasErrors}
                            onRemove={() =>
                              openConfirmDeletionModal(value as string, () => arrayHelpers.remove(index))
                            }
                            onMakeAsPrimary={() => {
                              arrayHelpers.remove(index);
                              setFieldValue('primary', value);
                              arrayHelpers.insert(index, values.primary);
                            }}
                          />
                        </FieldAndButtonsContainer>
                      );
                    })
                  ) : (
                    <Typography>There are no secondary websites</Typography>
                  )}
                </>
              )}
            />
          </FieldContainer>
          <FormFooterButtons
            isSubmitting={isSubmitting}
            disabled={Object.keys(errors).length !== 0 || isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onClose={onCancel}
          />
        </FormContainer>
      </FormikProvider>
    </div>
  );
};
