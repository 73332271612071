import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { cloneDeep } from 'lodash-es';
import {
  assignedKPIRequestsStateByCompany,
  kpisTemplateByIdState,
} from '../../../../../services/state/KPI/KPITemplatesState';
import { companyState } from '../../../../../services/state/CompanyState';
import { FormatterService } from '../../../../../util/formatter-service';
import {
  getMetricsSectionData,
  kpiDataValuesToFormStructure,
  prepareKpiResponsePayload,
  sortTemplateSections,
} from '../../../utils';
import {
  periodFromGranularityAndFrequency,
  IKPIResponseForm,
  IKPIResponseFormSectionData,
  KPIRequestFrequency,
  KPIRequestStatus,
} from '../../../../../data-models/kpi-requests.data-model';
import { Loader } from '../../../../../components/Loader/Loader';
import { IKPITemplate, isKpiTableDataModel } from '../../../../../data-models/kpi-template.data-model';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { kpisRequestResponseByIdState } from '../../../../../services/state/KPI/KPIRequestsState';
import { KPISubmissionHeader } from './KPIRecipientView2';
import { KPIRecipientViewHeader } from './KPIRecipientViewHeader';
import { KPIRecipientViewSections } from './KPIRecipientViewSections';
import { useKPIRequestResponseActions } from './hooks/useKPIRequestResponseActions';
import { KPINoPendingRequest } from './KPINoPendingRequest';

const MinPxWidth = 900;

const MainWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
`;

const Footer = styled('div')`
  background: white;
  display: flex;
  gap: 1rem;
  justify-content: end;
  padding: 1rem;
`;

const ContentWrapper = styled('div')`
  align-items: center;
  background: ${({ theme }) => theme.gradients.primary};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1rem 0;
`;

const FormItemsWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 1rem;
  width: 100%;
`;

const FormItemsInnerWrapper = styled('div')`
  width: ${MinPxWidth}px;
`;

export function KPIRecipientView() {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const {
    kpisForCompany,
    complete,
    currentRequest,
    currentRequestIndex,
    currentResponse,
    isSubmitting,
    skipRequest,
    submitRequest,
  } = useManageAssignedRequests(Number(companyId));
  const company = useRecoilValue(companyState(currentRequest?.companyId ?? -1));
  const kpiTemplate = useRecoilValueLoadable(kpisTemplateByIdState(currentRequest?.templateId ?? -1));
  const kpiTemplateSections = useMemo(() => {
    return kpiTemplate.state === 'hasValue' ? sortTemplateSections(kpiTemplate.getValue())!.sections : [];
  }, [kpiTemplate]);
  const instruction = `Please submit the requested information by the stated submission date`;

  const methods = useForm<IKPIResponseForm>({
    mode: 'onChange',
    values: {
      lastFocusedMetric: {
        metricParentRowIdx: 0,
        metricCeilColIdx: 0,
        focused: false,
      },
      sections: kpiTemplateSections ?? [],
      kpiData: {
        currencyId: 1,
        period: periodFromGranularityAndFrequency(
          currentRequest?.granularity,
          currentRequest?.frequency ?? KPIRequestFrequency.Monthly
        ),
        values: [],
      },
      sectionData: [],
      kpiRequest: currentRequest,
    },
  });

  const requestId = currentRequest?.id;
  useEffect(() => {
    if (kpiTemplate.state !== 'hasValue') {
      return;
    }

    const _kpiTemplate = kpiTemplate.getValue()!;
    const sectionsBySortOrder = cloneDeep(_kpiTemplate.sections ?? []).sort(
      (a, b) => (a.meta.sortOrder ?? 0) - (b.meta.sortOrder ?? 0)
    );

    if (!currentResponse?.id) {
      methods.setValue(
        'sectionData',
        sectionsBySortOrder.map((section) => {
          return {
            sectionId: section.id,
            value: isKpiTableDataModel(section.meta) ? [] : null,
          } as IKPIResponseFormSectionData;
        })
      );
      return;
    }

    let metricsSectionData: Record<number, IKPIResponseFormSectionData> = {};
    if (currentResponse?.kpiData?.values && kpiTemplate) {
      metricsSectionData = getMetricsSectionData(currentResponse.kpiData.values, _kpiTemplate);
    }
    const sectionData = currentResponse.sectionData.map((section) => {
      return {
        ...section,
        value: metricsSectionData[section.sectionId]?.value ?? section.value,
      };
    });

    const sectionDataSet = new Map(sectionData.map((section) => [section.sectionId, section]));

    const completeSectionData: IKPIResponseFormSectionData[] = [];
    sectionsBySortOrder.forEach((section) => {
      completeSectionData.push(
        sectionDataSet.get(section.id ?? -1) ?? {
          sectionId: section.id as number,
          value: isKpiTableDataModel(section.meta) ? [] : undefined,
        }
      );
    });

    const kpiResponseWithNumberFormat = {
      ...currentResponse,
      sectionData: completeSectionData,
      kpiData: {
        ...currentResponse.kpiData,
        values: currentResponse.kpiData.values.map((v) => ({
          ...v,
          numberFormatValue: `${v.value}`,
        })),
      },
    };

    const kpiDataWithFormStructure = {
      ...kpiResponseWithNumberFormat.kpiData,
      values: kpiDataValuesToFormStructure(kpiResponseWithNumberFormat.kpiData.values),
    };

    // using setValue instead of reset to avoid redundant re-validation trigger and unexpected behavior.
    methods.setValue('kpiData', { ...kpiDataWithFormStructure });
    methods.setValue('sectionData', [...kpiResponseWithNumberFormat.sectionData]);
  }, [currentResponse, kpiTemplate, methods, requestId]);

  useEffect(() => {
    if (complete) {
      navigate(`/${ROUTES.KPI_THANK_YOU_SCREEN}`);
    }
  }, [complete, navigate]);

  if (!company || !currentRequest || kpisForCompany.length === 0) {
    return <KPINoPendingRequest />;
  }

  const steps = kpisForCompany.map((kpiRequest, index) => {
    const { id } = kpiRequest;
    const kpiPeriod = FormatterService.formatKPIPeriod(kpiRequest.period, {
      frequency: kpiRequest.frequency,
      showYear: true,
      yearFormat: 'yyyy',
    });

    return (
      <Step key={id} active={index === currentRequestIndex} completed={index < currentRequestIndex}>
        <StepLabel>{kpiPeriod}</StepLabel>
      </Step>
    );
  });

  return (
    <FormProvider {...methods} key={currentRequest.id}>
      <MainWrapper>
        <KPISubmissionHeader>
          <KPIRecipientViewHeader kpiRequest={currentRequest} company={company} />
        </KPISubmissionHeader>
        <ContentWrapper>
          {steps.length > 1 ? <Stepper sx={{ width: `${MinPxWidth}px` }}>{steps}</Stepper> : <div />}
          <Typography variant={'body1'} sx={{ margin: '2rem 0 1rem 0', width: `${MinPxWidth}px` }}>
            {instruction}
          </Typography>
          <FormItemsWrapper>
            <FormItemsInnerWrapper>
              {kpiTemplate.state === 'hasValue' ? (
                <KPIRecipientViewSections kpiTemplateSections={kpiTemplateSections} readOnly={false} />
              ) : (
                <Loader height={'100%'} />
              )}
            </FormItemsInnerWrapper>
          </FormItemsWrapper>
        </ContentWrapper>
        <Footer>
          <LoadingButton
            loading={isSubmitting}
            onClick={skipRequest}
            size='medium'
            variant='outlined'
            color='secondary'
          >
            Skip
          </LoadingButton>
          <LoadingButton
            loading={isSubmitting}
            onClick={() => {
              submitRequest(methods.getValues(), kpiTemplate.getValue()!);
            }}
            size='medium'
            variant='contained'
            color='secondary'
          >
            Submit & Continue
          </LoadingButton>
        </Footer>
      </MainWrapper>
    </FormProvider>
  );
}

export function useManageAssignedRequests(companyId: number) {
  const kpisByCompany = useRecoilValue(assignedKPIRequestsStateByCompany);
  const kpisForCompany = useMemo(() => {
    return (
      kpisByCompany
        .get(companyId)
        ?.filter((request) => {
          return request.status === KPIRequestStatus.Rejected || request.status === KPIRequestStatus.Sent;
        })
        .sort((requestA, requestB) => {
          return new Date(requestA.period).getTime() - new Date(requestB.period).getTime();
        }) ?? []
    );
  }, [companyId, kpisByCompany]);
  const [currentRequestIndex, setCurrentRequestIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentRequest = useMemo(() => {
    return kpisForCompany.at(currentRequestIndex);
  }, [currentRequestIndex, kpisForCompany]);
  const [complete, setComplete] = useState(false);
  const { createRequestResponse, updateRequestResponse } = useKPIRequestResponseActions();
  const currentResponse = useRecoilValue(kpisRequestResponseByIdState(currentRequest?.id ?? -1));

  const skipRequest = useCallback(() => {
    if (currentRequestIndex === kpisForCompany.length - 1) {
      setComplete(true);
    } else {
      setCurrentRequestIndex((value) => value + 1);
    }
  }, [currentRequestIndex, kpisForCompany.length]);

  const submitRequest = useCallback(
    async (data: IKPIResponseForm, kpiTemplate: IKPITemplate) => {
      setIsSubmitting(true);
      const payload = prepareKpiResponsePayload(kpiTemplate!, data, currentRequest!.id.toString());

      if (currentRequest!.status === KPIRequestStatus.Rejected) {
        await updateRequestResponse(payload);
      } else {
        await createRequestResponse(payload);
      }

      if (currentRequestIndex === kpisForCompany.length - 1) {
        setComplete(true);
      } else {
        setCurrentRequestIndex((value) => value + 1);
      }
      setIsSubmitting(false);
    },
    [createRequestResponse, currentRequest, currentRequestIndex, kpisForCompany.length, updateRequestResponse]
  );

  return {
    complete,
    currentRequest,
    currentRequestIndex,
    currentResponse,
    isSubmitting,
    kpisForCompany,
    skipRequest,
    submitRequest,
  };
}
