import * as yup from 'yup';
import { captableInvestmentFormSchema } from '../schemas/CaptableInvestment.schema';
import { StakeholderInvestmentSchemas } from '../schemas/StakeholderInvestment.schema';

export enum CaptableType {
  system = 'system',
  user = 'user',
}

export enum CaptableSource {
  aumni = 'aumni',
  carta = 'carta',
  diligentEquity = 'diligent-equity',
  none = 'none',
}

export interface ICaptableDataModel {
  captableInvestments: ICaptableInvestmentDataModel[] | null;
  cashRaised: number | null;
  companyId: number;
  createdAt: string;
  createdBy?: string;
  currencyId: number;
  date: string;
  fullyDilutedShares: number | null;
  id: number;
  name: string | null;
  outstandingShares: number | null;
  primary: boolean | null;
  published: boolean | null;
  source: CaptableSource;
  stakeholdersTotals?: IStakeholderTotals[] | null; // available only on GET
  type: CaptableType;
  updatedAt?: string;
  uuid: string;
}

export type IStakeholderInvestmentDataModel = yup.InferType<
  ReturnType<typeof StakeholderInvestmentSchemas.create>
>;

export type ICaptableInvestmentDataModel = yup.InferType<ReturnType<typeof captableInvestmentFormSchema>> & {
  id: number;
};

export function createCaptableDataModel(overrides: Partial<ICaptableDataModel> = {}): ICaptableDataModel {
  return {
    captableInvestments: [],
    cashRaised: null,
    companyId: 0,
    createdAt: '',
    currencyId: 0,
    date: '',
    fullyDilutedShares: 0,
    id: -1,
    name: '',
    outstandingShares: null,
    primary: false,
    published: false,
    source: CaptableSource.none,
    type: CaptableType.system,
    uuid: '',
    ...overrides,
  };
}

export function createCaptableInvestment(
  overrides: Partial<ICaptableInvestmentDataModel> = {}
): ICaptableInvestmentDataModel {
  try {
    return {
      // cast will throw if any required fields are missing
      ...captableInvestmentFormSchema().cast({
        captableId: 0,
        currencyId: 1,
        id: 0,
        name: '',
      }),
      ...overrides,
    } as ICaptableInvestmentDataModel;
  } catch (e) {
    console.error(e);
    return {
      ...captableInvestmentFormSchema().getDefault(),
      captableId: 0,
      currencyId: 1,
      id: 0,
      name: '',
      ...overrides,
    };
  }
}

export function createStakeholderInvestment(
  overrides: Partial<IStakeholderInvestmentDataModel> = {}
): IStakeholderInvestmentDataModel {
  try {
    return {
      ...StakeholderInvestmentSchemas.create().cast({
        currencyId: 1,
        stakeholderName: '',
      }),
      ...overrides,
    };
  } catch (e) {
    console.error(e);
    return {
      ...StakeholderInvestmentSchemas.create().getDefault(),
      currencyId: 1,
      stakeholderName: '',
      ...overrides,
    };
  }
}

export interface IStakeholderTotals {
  fundId?: number;
  stakeholderName?: string;
  cashRaised?: number | null;
  fullyDilutedShares?: number | null;
  ownershipPercentage?: number | null;
}
