import { atom, createStore, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import {
  IAppConfigDataModel,
  IGenericSortable,
  sortGenericSortable,
} from '../../data-models/app-config.data-model';
import { ICurrencyDataModel } from '../../data-models/currency.data-model';
import { IFundDataModel } from '../../data-models/fund.data-model';
import { IMetricsConfigDataModel } from '../../data-models/metrics-config.data-model';
import { IRoundDataModel } from '../../data-models/round.data-model';
import { TransactionCategory, TransactionType } from '../../data-models/transaction-type.data-model';
import { IUserDataModel } from '../../data-models/user.data-model';
import { Fund } from '../../schemas/Fund.schema';
import { fetchAppConfig } from '../queries/MaggieConfigQueries';

export const currenciesAtom = atom<ICurrencyDataModel[]>([]);
export const fundsAtom = atom<Fund[]>([]);
export const roundsAtom = atom<IRoundDataModel[]>([]);
export const metricsConfigAtom = atom<IMetricsConfigDataModel>({} as IMetricsConfigDataModel);
export const sectorsAtom = atom<IGenericSortable[]>([]);
export const teamsAtom = atom<IGenericSortable[]>([]);
export const transactionTypesAtom = atom<TransactionType[]>([]);
export const usersAtom = atom<IUserDataModel[]>([]);

export async function initConfigs(store: ReturnType<typeof createStore>) {
  const config = await fetchAppConfig();
  initConfigsWithMeta(store, config);
}

export function initConfigsWithMeta(store: ReturnType<typeof createStore>, config: IAppConfigDataModel) {
  store.set(currenciesAtom, config.currencies);
  store.set(fundsAtom, config.funds.sort(sortGenericSortable));
  store.set(roundsAtom, config.rounds.sort(sortGenericSortable));
  store.set(metricsConfigAtom, config.metricsConfig);
  store.set(sectorsAtom, config.sectors.sort(sortGenericSortable));
  store.set(teamsAtom, config.teams.sort(sortGenericSortable));
  store.set(transactionTypesAtom, config.transactionTypes);
  store.set(
    usersAtom,
    config.users.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
  );
}

export const fundsOrderedAlphabeticallyAtom = atom((get) => {
  const funds = get(fundsAtom);

  return [...funds].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
});

export const fundsByIdMapAtom = atom((get) => {
  return get(fundsAtom).reduce((res, fund) => {
    return res.set(fund.id, fund);
  }, new Map<number, IFundDataModel>());
});

export const fundTypeListAtom = atom((get) => {
  const funds = get(fundsAtom);
  return new Set(funds.map((fund) => fund.fundType));
});

export const roundsMapAtom = atom((get) => {
  return get(roundsAtom).reduce((res, round) => {
    return res.set(round.id, round);
  }, new Map<number, IRoundDataModel>());
});

export const roundsMapByNameAtom = atom((get) => {
  return get(roundsAtom).reduce((res, round) => {
    return res.set(round.displayName, round);
  }, new Map<string, IRoundDataModel>());
});

export const sectorsMapAtom = atom((get) => {
  return get(sectorsAtom).reduce((res, sector) => {
    return res.set(sector.id, sector);
  }, new Map<number, IGenericSortable>());
});

export const teamsMapAtom = atom((get) => {
  return get(teamsAtom).reduce((res, team) => {
    return res.set(team.id, team);
  }, new Map<number, IGenericSortable>());
});

export const transactionTypesAndSubTypeAtom = atom((get) => {
  return get(transactionTypesAtom).reduce((res, transactionType) => {
    if (!res.has(transactionType.catergory)) {
      res.set(transactionType.catergory, new Map());
    }
    res.get(transactionType.catergory)!.set(transactionType.name, transactionType);

    return res;
  }, new Map<string, Map<string, TransactionType>>());
});

export const transactionTypesMapAtom = atom((get) => {
  return get(transactionTypesAtom).reduce((res, transactionType) => {
    return res.set(transactionType.id, transactionType);
  }, new Map<number, TransactionType>());
});

export const activeUsersAtom = atom((get) => {
  const allUsers = get(usersAtom);

  return allUsers.filter((user) => user.activeStatus === true);
});

export const activeUserOptionsAtom = atom((get) => {
  const activeUsers = get(activeUsersAtom);

  return activeUsers.map((user) => ({
    id: user.id,
    value: user.name,
    label: user.name,
  }));
});

export const allUserOptionsStateAtom = atom((get) => {
  const allUsers = get(usersAtom);

  return allUsers.map((user) => ({
    id: user.id,
    value: user.name,
    label: user.name,
  }));
});

export const usersByIdMapAtom = atom((get) => {
  const map = new Map<number, IUserDataModel>();
  get(usersAtom).forEach((user) => {
    map.set(user.id, user);
  });

  return map;
});

export const usersByEmailMapAtom = atom((get) => {
  const map = new Map<string, IUserDataModel>();
  get(usersAtom).forEach((user) => {
    map.set(user.email, user);
  });

  return map;
});

export const currencyMapByIdAtom = atom((get) => {
  const currencies = get(currenciesAtom);

  return new Map(currencies.map((c) => [c.id, c]));
});

export const currencyMapByCodeAtom = atom((get) => {
  const currencies = get(currenciesAtom);

  return new Map(currencies.map((c) => [c.code, c]));
});

export function useGetTransactionCategoryAndSubtype() {
  const transactionTypesById = useAtomValue(transactionTypesMapAtom);

  return useCallback(
    (transactionTypeId: number) => {
      const transactionType = transactionTypesById.get(transactionTypeId);
      const transactionCategory = (transactionType?.catergory as TransactionCategory) ?? null;
      const transactionSubType = transactionType?.name ?? null;

      return {
        transactionCategory,
        transactionSubType,
      };
    },
    [transactionTypesById]
  );
}
