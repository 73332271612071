import { merge } from 'lodash-es';
import * as yup from 'yup';

export function generalLedgerUploadDataFields() {
  return {
    fileName: yup.string().required(),
    fileFormat: yup.string().required(),
    fileSize: yup.string().required(), // TODO: check once BE is ready
    createdAt: yup.date().required(),
    createdBy: yup.string().required().customMeta({
      formatter: 'userByEmail',
    }),
    startDate: yup.date().required(),
  };
}

export function generalLedgerUploadDataSchema() {
  return yup.object().shape(generalLedgerUploadDataFields());
}

export type GeneralLedgerUploadData = yup.InferType<ReturnType<typeof generalLedgerUploadDataSchema>>;

export function createGeneralLedgerUploadData(
  overrides: Partial<GeneralLedgerUploadData> = {}
): GeneralLedgerUploadData {
  return merge({}, generalLedgerUploadDataSchema().getDefault(), {
    fileName: 'test',
    fileFormat: 'test',
    fileSize: 'test',
    createdBy: 'test',
    ...overrides,
  });
}
