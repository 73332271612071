import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ToastMessageType, ToastProps } from '../../types';
import { IBulkUploadStatusMessageDataModel } from '../../data-models/bulk-upload-status.data-model';
import { BulkUploadStatus } from '../../pages/AdminPanel/CustomFields/BulkUploadStatus';
import { ToastMessage } from './ToastMessage';

// FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5992
export interface IToastStore {
  pushSuccessToast: (config: ToastProps) => void;
  pushWarningToast: (config: ToastProps) => void;
  pushErrorToast: (config: ToastProps) => void;
  pushInfoToast: (config: ToastProps) => void;
  pushStatusToast: ({ message, onViewReport }: IUploadStatusConfig) => void;
}

interface IUploadStatusConfig {
  message: IBulkUploadStatusMessageDataModel;
  onViewReport?: () => void;
}

interface IGeneralToastConfig extends ToastProps {
  type: ToastMessageType;
}

type TToastConfig = IGeneralToastConfig | IUploadStatusConfig;

const ToastMessageContext = createContext<IToastStore | undefined>(undefined);

export const useToastMessageState = (): IToastStore => {
  const context = useContext(ToastMessageContext);

  if (typeof context === 'undefined') {
    throw new Error('useToastMessageState cannot be used outside ToastMessageProvider');
  }

  return context;
};

export const ToastMessageProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState<TToastConfig | null>(null);

  const onClose = () => {
    setIsOpen(false);
    setConfig(null);
  };

  const pushToast = (config: TToastConfig): void => {
    setConfig(config);
    setIsOpen(true);
  };

  const _onViewReport = useCallback(() => {
    if (isGeneralToastConfig(config) || !config?.onViewReport) return;

    config.onViewReport();
    setTimeout(() => {
      onClose();
    }, 300);
  }, [config]);

  const pushStatusToast = useCallback((config: IUploadStatusConfig) => {
    pushToast(config);
  }, []);

  const pushSuccessToast = useCallback((config: ToastProps): void => {
    pushToast({ ...config, type: ToastMessageType.success });
  }, []);
  const pushWarningToast = useCallback((config: ToastProps): void => {
    pushToast({ ...config, type: ToastMessageType.warning });
  }, []);
  const pushErrorToast = useCallback((config: ToastProps): void => {
    pushToast({ ...config, type: ToastMessageType.error });
  }, []);
  const pushInfoToast = useCallback((config: ToastProps): void => {
    pushToast({ ...config, type: ToastMessageType.info });
  }, []);

  const value = useMemo(() => {
    return {
      pushSuccessToast,
      pushWarningToast,
      pushErrorToast,
      pushInfoToast,
      pushStatusToast,
    };
  }, [pushErrorToast, pushInfoToast, pushStatusToast, pushSuccessToast, pushWarningToast]);

  return (
    <ToastMessageContext.Provider value={value}>
      {isGeneralToastConfig(config) ? (
        <ToastMessage {...config} isOpen={isOpen} handleClose={onClose} />
      ) : config ? (
        <BulkUploadStatus onClose={onClose} {...config.message} open={isOpen} onViewReport={_onViewReport} />
      ) : null}
      {children}
    </ToastMessageContext.Provider>
  );
};

function isGeneralToastConfig(config: TToastConfig | null): config is IGeneralToastConfig {
  return Boolean(config) && 'type' in config!;
}
