import { IFinanceRoundDataModel } from '../../data-models/finance-round.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class InvestmentRoundFormatter
  implements IInitializedFormatter<Map<number, IFinanceRoundDataModel>, number | number[]>
{
  #investmentRounds: Map<number, IFinanceRoundDataModel> = new Map();

  init(investmentRounds: Map<number, IFinanceRoundDataModel>) {
    this.#investmentRounds = investmentRounds;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#investmentRounds.get(value)?.name ?? value.toString();
  };
}
