import { atom, atomFamily, selectorFamily } from 'recoil';
import { fetchScenariosForCompany } from '../../../services/queries/MaggieScenarioQueries';
import { IScenarioDataModel } from '../../../data-models/scenario.data-model';

export const scenariosByCompanyIdState = atomFamily<IScenarioDataModel[], number>({
  key: 'ScenariosByCompanyIdState',
  default: selectorFamily({
    key: 'ScenariosByCompanyIdState/Default',
    get: (companyId) => () => {
      return fetchScenariosForCompany(companyId);
    },
  }),
});

export const ScenariosByIdMapState = selectorFamily<Map<number, IScenarioDataModel>, number>({
  key: 'ScenariosByIdMapState',
  get:
    (companyId: number) =>
    ({ get }) => {
      const scenarios = get(scenariosByCompanyIdState(companyId));
      const scenarioMap = new Map<number, IScenarioDataModel>();
      scenarios.forEach((scenario) => {
        scenarioMap.set(scenario.id, scenario);
      });
      return scenarioMap;
    },
});

// scenario that is currently being edited / viewed / created
// if there is a scenarioId  search param, will initially be set to that
export const currentScenarioState = atom<Partial<IScenarioDataModel> | null>({
  key: 'currentScenarioState',
  default: null,
});
