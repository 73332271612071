import { TransactionType } from '../../data-models/transaction-type.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class TransactionTypeFormatter
  implements IInitializedFormatter<Map<number, TransactionType>, number | number[]>
{
  #transactionTypes: Map<number, TransactionType> = new Map();

  init(transactionTypes: Map<number, TransactionType>) {
    this.#transactionTypes = transactionTypes;
    return this;
  }

  format = (value: number | number[]) => {
    return Array.isArray(value) ? value.map(this.#format).join(', ') : this.#format(value);
  };

  #format = (value: number) => {
    const transactionType = this.#transactionTypes.get(value);
    return transactionType
      ? `${transactionType.catergory} ${transactionType.name.replace(
          /(Warrant|Misc|Escrow|Note|Option|Token|Fund)/,
          ''
        )}`
      : `Unknown Type ${value}`;
  };

  formatCategory = (value: number | number[]) => {
    return Array.isArray(value) ? value.map(this.#formatCategory).join(', ') : this.#formatCategory(value);
  };

  #formatCategory = (value: number) => {
    return this.#transactionTypes.get(value)?.catergory ?? `Unknown Category ${value}`;
  };

  formatSubType = (value: number | number[]) => {
    return Array.isArray(value) ? value.map(this.#formatSubType).join(', ') : this.#formatSubType(value);
  };

  #formatSubType = (value: number) => {
    return this.#transactionTypes.get(value)?.name ?? `Unknown Sub-Type ${value}`;
  };
}
