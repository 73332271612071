import { useRecoilValue } from 'recoil';
import { get } from 'lodash-es';
import { Alert } from '@mui/material';
import { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { createField3DataModel, IField3 } from '../../../../../data-models/field3.data-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import { FormatterService } from '../../../../../util/formatter-service';
import { LabeledFieldContainer, LabeledField, LabeledFieldBox } from '../../../../../components/LabeledField';
import { CapTableDataFields } from '../../../../../data-models/captable.data-model';
import { SubSection } from '../../../Summary/components/SubSection';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { companyState } from '../../../../../services/state/CompanyState';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { Label } from '../../../../../components/Form/FormComponents';
import { CaptableField } from './CaptableField';
import { PostMoneyValuationField } from './PostMoneyValuationField';

export interface ICaptableDetailsProps {
  captable: ICaptableDataModel | null;
  setCaptable: Dispatch<SetStateAction<ICaptableDataModel | null>>;
}

export const CapTableDetails = memo(function CapTableDetails({
  captable,
  setCaptable,
}: ICaptableDetailsProps) {
  const getCapDataFields = useGetCapDataFields();
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const capTableFields = getCapDataFields(captable);

  const company = useRecoilValue(companyState(companyId));

  const elements = capTableFields
    .map((field) => {
      const value = get(captable, field.entityKey);
      return value ? <CapTableDetailItem key={field.entityKey} field={field} value={value} /> : undefined;
    })
    .filter((item) => item !== undefined);

  if (captable)
    elements.unshift(<CaptableField key='captable' captable={captable} setCaptable={setCaptable} />);
  if (company) elements.unshift(<CompanyNameField key='companyName' company={company} />);
  elements.push(<PostMoneyValuationField key='postMoneyValuation' />);

  return (
    <SubSection title='Cap Table Summary' style={{ marginTop: '0' }}>
      {captable ? (
        <ul css={LabeledFieldContainer}>{elements}</ul>
      ) : (
        <Alert severity='warning' style={{ marginBottom: '1rem' }}>
          No cap table data available
        </Alert>
      )}
    </SubSection>
  );
});

interface ICapTableDetailItemProps {
  field: IField3<unknown>;
  value: unknown;
}
export function CapTableDetailItem(props: ICapTableDetailItemProps) {
  const { field, value } = props;
  const { displayName } = field;
  const formatter = FormatterService.get().getFormatterForFieldV3(field);
  const formattedValue = formatter(castFieldValue(field, value));

  return <LabeledField label={displayName} value={formattedValue} />;
}

function castFieldValue(field: IField3<unknown>, value: unknown) {
  switch (field.format) {
    case RendererType.number:
    case RendererType.percent:
    case RendererType.integer:
    case RendererType.currency:
      return Number(value);
    case RendererType.date:
      return new Date(value as string | number);
    default:
      return value;
  }
}

export function useGetCapDataFields() {
  return useCallback((capTableDetails: ICaptableDataModel | null) => {
    if (!capTableDetails) {
      return [];
    }

    const asOfDate = createField3DataModel(CapTableDataFields.asOfDate);
    const currencyName = 'USD';
    const cashRaisedField = createField3DataModel(CapTableDataFields.cashRaised);
    cashRaisedField.meta!.defaultCurrency = currencyName;

    const result = [
      asOfDate,
      cashRaisedField,
      CapTableDataFields.fullyDilutedShares,
      CapTableDataFields.outstandingShares,
    ];

    return result;
  }, []);
}

export const CompanyNameField: FC<{ company: ICompanyDataModel }> = ({ company }) => {
  return (
    <LabeledFieldBox>
      <Label>Company</Label>
      <CompanyLogoAndName
        name={company.name}
        logoUrl={company.logoUrl ?? ''}
        typographyProps={{ color: 'text.primary' }}
        avatarProps={{ size: 'small' }}
        style={{ columnGap: '0.25rem' }}
      />
    </LabeledFieldBox>
  );
};
