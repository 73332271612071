import * as yup from 'yup';
import { minZeroMsg, requiredMsg } from '../../utils/validationMessages';
import { ProceedsTypes } from '../../../../../../../data-models/transaction.data-model';
import { CompanySearchResponseSchema } from '../../../../../../../schemas/CompanySearchResponse.schema';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import {
  transactionDateSchema,
  proceedsTypeSchema,
} from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { integerField, usdField } from '../../../../../../../schemas/common-schema-defs';
import { eqBuyGridSchema } from './EquityBuySchema';

export function eqSellFields() {
  return {
    acquirer: CompanySearchResponseSchema.formSchema().nullable(),
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().required(requiredMsg('Transaction Date')),
    valuationImpact: yup
      .boolean()
      .required(requiredMsg('Valuation Impact'))
      .transform((_, val) => (val ? val : false)),
    escrowAmount: usdField()
      .moreThan(-Number.MIN_VALUE, minZeroMsg('Escrow Amount'))
      .nullable()
      .when('proceedsType', {
        is: (val: ProceedsTypes[]) => val?.includes(ProceedsTypes.Cash),
        then: (schema) => schema.required(requiredMsg('Escrow Amount')),
      }),
    noOfShares: integerField()
      .nullable()
      .required(requiredMsg('Shares Sold'))
      .min(0, minZeroMsg('Shares Sold')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    currencyid: transactionCurrencyIdField(),
    proceedsType: proceedsTypeSchema(),
    sharesReceived: integerField()
      .optional()
      .nullable()
      .when('proceedsType', {
        is: (val: ProceedsTypes[]) => val?.includes(ProceedsTypes.StockProceeds),
        then: (schema) => schema.required(requiredMsg('Shares Received from Acquirer')),
      }),
    ppsSharesReceived: usdField()
      .optional()
      .nullable()
      .label('PPS of Shares Received')
      .when('proceedsType', {
        is: (val: ProceedsTypes[]) => val?.includes(ProceedsTypes.StockProceeds),
        then: (schema) => schema.required(),
      }),
    receivedAmount: usdField()
      .optional()
      .nullable()
      .when('proceedsType', {
        is: (val: ProceedsTypes[]) => val?.includes(ProceedsTypes.Cash),
        then: (schema) => schema.nonNullable().required(requiredMsg('Cash Received')),
      }),
    fmvBasis: yup.string().optional().nullable(),
  };
}

export function eqSellFormSchema() {
  return yup.object().shape(eqSellFields());
}

export function eqSellGridSchema() {
  const base = eqSellFields();
  base.acquirer = CompanySearchResponseSchema.gridSchema();
  return yup
    .object(base)
    .concat(eqBuyGridSchema())
    .omit(['fmvBasis', 'fullyDilutedShares', 'noOfShares', 'pricePerShare']);
}
