import { useMemo } from 'react';
import { createCompanyDataModel } from '../../../data-models/company.data-model';
import { IDashboardTransactionDataModel } from '../../../data-models/dashboard-transaction.data-model';
import { MostRecentChartData } from './useMostRecentRounds';

export const useMostRecentRealizations = (data: IDashboardTransactionDataModel[]): MostRecentChartData[] => {
  return useMemo(() => {
    if (!data) return [];
    return data.reduce((acc, d) => {
      if (!d.date) return acc;
      acc.push({
        company: createCompanyDataModel({ name: d.companyName, logoUrl: d.companyLogoUrl ?? '' }),
        date: new Date(d.date!),
        amount: d.value,
      });
      return acc;
    }, [] as MostRecentChartData[]);
  }, [data]);
};
