import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useSaveCompanyData } from '../../../services/hooks/useSaveCompanyData';
import { fetchAllPortCos } from '../../../services/queries/MaggieCompanyQueries';
import { portCoIdsState } from '../../../services/state/PortCosState';

// saves portfolio company data in state and sets portCoIdsState
export function usePortCos() {
  const { batchSaveCompanyData } = useSaveCompanyData();
  const setPortCoIds = useSetRecoilState(portCoIdsState);
  const [loading, setLoading] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [error, setError] = useState(null);

  const fetchAndSavePortCos = useFetchAndSavePortCos();

  useEffect(() => {
    if (dataReady || loading) return;
    setLoading(true);
    fetchAndSavePortCos()
      .then(() => {
        setDataReady(true);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [batchSaveCompanyData, dataReady, fetchAndSavePortCos, loading, setPortCoIds]);

  return { loading, dataReady, error, fetchAndSavePortCos };
}

export function useFetchAndSavePortCos() {
  const { batchSaveCompanyData } = useSaveCompanyData();
  const setPortCoIds = useSetRecoilState(portCoIdsState);

  return useCallback(async () => {
    const data = await fetchAllPortCos();
    batchSaveCompanyData(data);
    setPortCoIds(data.map((company) => company.id));
  }, [batchSaveCompanyData, setPortCoIds]);
}
