import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import { createFormField, IFormField, IFormFieldSelectMeta } from '../view-models/form.view-model';
import { IDateMeta, ISelectMeta, ITextMeta } from '../data-models/field3.data-model';
import { ISimpleChoice } from '../data-models/field2.data-model';
import { RendererType } from '../data-models/field.data-model';
import {
  roundsMapState,
  transactionTypesAndSubTypeState,
  transactionTypesState,
} from '../services/state/AppConfigState';
import { CompoundingPeriod, CouponType } from '../data-models/transaction.data-model';
import { roundsByCompanyIdState } from '../pages/Finance2/state/FinanceState';
import { IFinanceRoundDataModel } from '../data-models/finance-round.data-model';
import { IRoundDataModel } from '../data-models/round.data-model';
import { formatDate } from '../util/formatters/DateFormatters';
import { WarrantsType } from '../view-models/transaction-form.view-model';
import { FMT } from '../util/formatter-service';
import { FieldSettings, futureDateField } from './CommonFields';

export function useTransactionCategoryField() {
  const transactionTypes = useRecoilValue(transactionTypesAndSubTypeState);
  const values: ISimpleChoice<string>[] = Array.from(transactionTypes.keys()).map((categoryName) => {
    return {
      value: categoryName,
      displayName: categoryName,
    };
  });

  return createFormField<ISelectMeta<string>>({
    key: '_viewModel.transactionCategory',
    label: 'Transaction Type',
    required: true,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      multi: false,
      values,
    },
  });
}

// Transactions only have a "type" which maps to combination of category
export function useGetTransactionSubTypeField() {
  const transactionTypes = useRecoilValue(transactionTypesAndSubTypeState);

  return useCallback(
    (transactionType: string | null) => {
      const transactionSubTypes = transactionType
        ? (transactionTypes.get(transactionType)?.values() ?? [])
        : [];
      const values: ISimpleChoice<number>[] = Array.from(transactionSubTypes).map((transactionSubType) => {
        return {
          value: transactionSubType.id,
          displayName: transactionSubType.name,
        };
      });

      return createFormField<ISelectMeta<number>>({
        key: 'transactionTypeId',
        label: 'Transaction Sub-Type',
        required: true,
        renderer: RendererType.singleSelect,
        rendererMeta: {
          multi: false,
          values,
        },
      });
    },
    [transactionTypes]
  );
}

export function useGetTransactionTypeField() {
  const getTransactionTypes = useGetTransactionTypesSelectItems();

  return useCallback(() => {
    const values = getTransactionTypes();

    return createFormField<ISelectMeta<number>>({
      key: 'transactionTypeId',
      label: 'Transaction Type',
      required: true,
      renderer: RendererType.singleSelect,
      rendererMeta: {
        multi: false,
        values,
      },
    });
  }, [getTransactionTypes]);
}

export function useGetTransactionTypesSelectItems() {
  const transactionTypes = useRecoilValue(transactionTypesState);

  return useCallback(() => {
    const values: ISimpleChoice<number>[] = transactionTypes
      .map((transactionType) => {
        return {
          value: transactionType.id,
          displayName: FMT.format('transactionType', transactionType.id),
        };
      })
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

    return values;
  }, [transactionTypes]);
}

export function transactionDateField() {
  return createFormField<IDateMeta>({
    key: 'transactionDate',
    label: 'Transaction Date',
    required: true,
    renderer: RendererType.date,
  });
}

export function securityField() {
  return createFormField<ITextMeta>({
    key: 'security',
    label: 'Security',
    renderer: RendererType.text,
  });
}

export function maturityDateField() {
  return futureDateField({
    key: 'maturityDate',
    label: 'Maturity Date',
    required: false,
  });
}

export function couponTypeField() {
  return createFormField<ISelectMeta<string>>({
    key: 'couponType',
    label: 'Interest Type',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(CouponType).map((type) => ({ displayName: type, value: type })),
    },
  });
}

export function compoundingPeriodField({ key, label = key, required = false }: FieldSettings) {
  return createFormField<ISelectMeta<string>>({
    key,
    label,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(CompoundingPeriod).map((period) => ({ displayName: period, value: period })),
    },
    required,
  });
}

export function getInvestmentLabel(
  investmentRound: IFinanceRoundDataModel,
  roundsById: Map<number, IRoundDataModel>
): string {
  const roundName = roundsById.get(investmentRound.roundId ?? -1)?.name ?? '';

  if (investmentRound.name) {
    return `${investmentRound.name}${roundName ? ` (${roundName})` : ''}`;
  } else if (investmentRound.roundDate) {
    return `${roundName ? `${roundName} ` : ''}(${formatDate(investmentRound.roundDate)})`;
  } else {
    return roundName || '-';
  }
}

export function useInvestmentAssignmentField(
  companyId: number,
  onCreateInvestment: (name?: string) => void
): IFormField<IFormFieldSelectMeta<number>> {
  const investmentRoundsByCompany = useRecoilValue(roundsByCompanyIdState(companyId));
  const roundsById = useRecoilValue(roundsMapState);

  const options: ISimpleChoice<number>[] = useMemo(() => {
    if (!investmentRoundsByCompany) return [];
    return investmentRoundsByCompany
      .map((r) => ({
        value: r.id,
        displayName: getInvestmentLabel(r, roundsById),
      }))
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [investmentRoundsByCompany, roundsById]);

  return createFormField({
    dataType: 'number',
    key: 'id',
    label: 'Round',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: options,
      multi: false,
      actionMeta: {
        onAction: onCreateInvestment,
        actionLabel: 'Add New Round',
        helperText: 'Select an existing round or add a new one',
      },
    },
    disableClearable: true,
  });
}

export function getValuationImpactField({
  key = 'valuationImpact',
  label = 'Valuation Impact',
  required = false,
}: Partial<FieldSettings> = {}) {
  return createFormField<unknown>({
    key,
    label,
    required,
    renderer: RendererType.boolean,
    description:
      'By toggling on, all securities held in the company will be marked to the PPS of this transaction',
  });
}

export function warrantsTypeField() {
  return createFormField<ISelectMeta<string>>({
    key: '_viewModel.warrantsType',
    label: 'Warrants Type',
    required: true,
    renderer: RendererType.singleSelect,
    disableClearable: false,
    rendererMeta: {
      multi: false,
      values: Object.values(WarrantsType).map((type) => ({ displayName: type, value: type })),
    },
  });
}
