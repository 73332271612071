import * as yup from 'yup';
import { capitalize } from 'lodash-es';
import { RendererType } from '../data-models/field.data-model';
import { ParticipationType } from '../data-models/scenario.data-model';
import {
  investmentDateField,
  investmentTypeField,
  multiplierField,
  ppsField10dp,
  shareClassNameField,
  usdField,
  ViewModelInvestmentType,
} from './common-schema-defs';
import { abstractShareClassSchema } from './AbstractShareClass.schema';

export const PreferredShareClassSchema = {
  create() {
    return abstractShareClassSchema().shape({
      type: investmentTypeField().default(ViewModelInvestmentType.preferred),
      name: shareClassNameField().default('Series'),
      pricePerShare: ppsField10dp().required().label('Original Issue Price').default(0),
      seniority: yup.number().nullable().required().label('Seniority').default(1).meta({
        renderer: RendererType.integer,
        description: '1 is the highest seniority',
      }),
      pariPassu: yup.boolean().nullable().optional().label('Pari-Passu').default(true),
      participationType: yup
        .string()
        .nullable()
        .required()
        .label('Participating Preference')
        .default(ParticipationType.none)
        .meta({
          renderer: RendererType.singleSelect,
          rendererMeta: {
            values: Object.values(ParticipationType).map((participationType) => ({
              value: participationType,
              displayName: capitalize(participationType),
            })),
          },
          disableClearable: true,
        }),
      participationCap: yup
        .number()
        .nullable()
        .optional()
        .label('Participation Cap')
        .when('participationType', {
          is: ParticipationType.capped,
          then: (schema) => schema.nullable().required().positive(),
        })
        .meta({
          renderer: RendererType.multiplier,
        }),
      liquidationMultiplier: multiplierField(4, 0).required().label('Liquidation Multiple').default(1),
      conversionRatio: multiplierField(4, 0).required().label('Conversion Ratio').default(1),
      investmentDate: investmentDateField(),
      postMoneyValuation: usdField().nullable().label('Post-Money Valuation').default(null),
    });
  },
};

export type PreferredShareClass = yup.InferType<ReturnType<typeof PreferredShareClassSchema.create>>;
export function createPreferred(overrides: Partial<PreferredShareClass> = {}): PreferredShareClass {
  return PreferredShareClassSchema.create().cast(overrides, { assert: false });
}
