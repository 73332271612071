import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { pricePerShareField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function miscInternalValuationFields() {
  return {
    transactionDate: transactionDateSchema(),
    currencyid: transactionCurrencyIdField(),
    valuationImpact: yup.boolean(),
    pricePerShare: pricePerShareField()
      .nullable()
      .when('valuationImpact', {
        is: true,
        then: (schema) =>
          schema.required(requiredMsg('Price Per Share')).min(0, 'Price Per Share must be greater than 0'),
      }),
    valuationAmount: usdField().nullable(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function miscInternalValuationFormSchema() {
  return yup.object().shape(miscInternalValuationFields()).omit(['valuationImpact', 'valuationAmount']);
}
