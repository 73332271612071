import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Chip, Typography, ButtonBase } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { capitalize } from 'lodash-es';
import { css } from '@emotion/react';
import { selectedCompanyIdProfile, selectedRequestIdState } from '../../../state/UIState';
import { kpiRequestsByCompanyIdState } from '../../../../../services/state/KPI/KPIRequestsState';
import { FMT } from '../../../../../util/formatter-service';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { MUIAvatarGroup } from '../../../../../components/Avatar/MUIAvatarGroup';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { cardStyles } from '../../../../../theme/component-styles';
import { groupRequestsByMonth } from './request-utils';

const StyledRequestList = css`
  margin: 2rem 1rem;
  display: grid;
  row-gap: 0.5rem;
  align-items: start;
  align-content: start;
`;

const CardGridStyles = css({
  ...cardStyles,
  width: '100%',
  margin: 0,
  display: 'grid',
  rowGap: '0.5rem',
  alignContent: 'space-around',
  justifyContent: 'start',
});

const RequestRow = css`
  display: grid;
  width: 100%;
  column-gap: 0.5rem;
  justify-content: start;
  align-items: center;
`;

const RequestMainInfo = css`
  ${RequestRow}
  grid-template-columns: repeat(3, auto);
`;

const RequestDetails = css`
  ${RequestRow}
  grid-template-columns: repeat(2, auto);
`;

export function RequestList() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const reqs = useRecoilValue(kpiRequestsByCompanyIdState(companyId));
  const groupedReqs = useMemo(() => {
    return groupRequestsByMonth(reqs);
  }, [reqs]);
  const setSelectedRequestId = useSetRecoilState(selectedRequestIdState);

  const handleClick = useCallback(
    (requestId: number) => {
      setSelectedRequestId(requestId);
    },
    [setSelectedRequestId]
  );

  return <GroupedRequestList groupedReqs={groupedReqs} handleClick={handleClick} />;
}

interface IRequestStatusProps {
  status: KPIRequestStatus;
}
export function RequestStatus({ status }: IRequestStatusProps) {
  const color = status === KPIRequestStatus.Rejected ? 'error' : 'text.secondary';
  return (
    <Typography variant='body2' color={color} display='flex' alignItems={'center'}>
      {status === KPIRequestStatus.Rejected ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />}
      {capitalize(status)}
    </Typography>
  );
}

export function GroupedRequestList({
  groupedReqs,
  handleClick,
}: {
  groupedReqs: Record<string, IKPIRequestDataModel[]>;
  handleClick: (req: number) => void;
}) {
  return (
    <ul>
      {Object.entries(groupedReqs).map(([monthYear, requests]) => {
        return (
          <div role='listitem' key={monthYear} css={StyledRequestList}>
            <Typography variant='caption' color='text.secondary'>
              {monthYear}
            </Typography>
            {requests?.map((r) => {
              const period = FMT.formatKPIPeriod(r.period, {
                frequency: r.frequency,
                showYear: true,
                yearFormat: 'yyyy',
              });
              return (
                <ButtonBase css={CardGridStyles} key={r.id} onClick={() => handleClick(r.id)}>
                  <div css={RequestMainInfo}>
                    <Typography>{period}</Typography>
                    <Chip size='small' label={r.frequency} />
                    <RequestStatus status={r.status as KPIRequestStatus} />
                  </div>

                  <div css={RequestDetails}>
                    <MUIAvatarGroup max={4} size='small'>
                      {r.respondent.map((r) => (
                        <MUIAvatar
                          key={r}
                          src={''}
                          nameParts={FMT.format('userByEmail', r ?? '')?.split(' ') ?? []}
                        />
                      ))}
                    </MUIAvatarGroup>
                    <Typography variant='caption' color='text.secondary'>
                      {FMT.format('date', r.kpiRequestResponse?.respondedAt)}
                    </Typography>
                  </div>
                </ButtonBase>
              );
            })}
          </div>
        );
      })}
    </ul>
  );
}
