import { merge } from 'lodash-es';
import { Scenario, ScenarioTransaction } from '../schemas/Scenario.schema';
import { createBaseModelDataModel } from './base-model.data-model';

export enum ScenarioType {
  base = 'Base',
  downside = 'Downside',
  upside = 'Upside',
}
export type IScenarioDataModel = Scenario;

export enum ScenarioTransactionType {
  round = 'round',
  exit = 'exit',
  partialExit = 'partial-exit',
}

export const ScenarioTransactionTypeToLabel = {
  [ScenarioTransactionType.round]: 'Round',
  [ScenarioTransactionType.exit]: 'Exit',
  [ScenarioTransactionType.partialExit]: 'Partial Exit',
};

export const ParticipationType = {
  full: 'full',
  capped: 'capped',
  none: 'none',
} as const;

export type ParticipationType = (typeof ParticipationType)[keyof typeof ParticipationType];

export interface IFundData {
  fundId: number;
  investedAmount: number;
}

export enum ShareClassSeniorityOption {
  Same = 'same',
  Higher = 'higher',
}
export type IScenarioTransactionDataModel = ScenarioTransaction;

export function createScenarioDataModel(overrides: Partial<IScenarioDataModel> = {}): IScenarioDataModel {
  return merge(
    {
      ...createBaseModelDataModel(),
      id: 0,
      name: '',
      tags: [],
      captableId: 0,
      companyId: 0,
      moic: null,
      outdated: false,
      scenarioTransactions: [],
      createdAt: '',
      updatedAt: '',
    },
    overrides
  );
}

export function createScenarioTransactionDataModel(
  overrides: Partial<IScenarioTransactionDataModel> = {}
): IScenarioTransactionDataModel {
  return merge(
    {
      conversionRatio: null,
      createdAt: '',
      exitDate: null,
      exitValuation: 0,
      fundData: null,
      id: 0,
      investedAmount: 0,
      investmentDate: null,
      multiple: 0,
      newSharesReserved: null,
      optionPoolPercentage: 0,
      optionPoolRefresh: false,
      optionStrikePrice: null,
      participation: ParticipationType.none,
      participationCap: null,
      preMoneyValuation: 0,
      roundId: 0,
      roundSize: 0,
      scenarioId: 0,
      seniorityOption: null,
      type: ScenarioTransactionType.exit,
      warrantStrikePrice: null,
    },
    overrides
  );
}

export function createScenarioFundDataModel(overrides: Partial<IFundData> = {}): IFundData {
  return merge(
    {
      fundId: 0,
      investedAmount: 0,
    },
    overrides
  );
}
