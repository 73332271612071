import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { usePeopleData } from '../hooks/usePeopleData';
import { ErrorOccurredMessage, NoDataMessage2 } from '../components/Messages/HelpMessage';
import { Loader } from '../../../components/Loader/Loader';
import { CPWrapper } from '../components/CompanyProfilesWrapper';
import { EmployeeGrids } from './EmployeeGrids';
import { PeopleFilters } from './PeopleFilters';

export const CompanyProfilesPeople = () => {
  const { allPeople, loading, error } = usePeopleData();

  if (error) return <ErrorOccurredMessage />;

  if (loading) return <Loader />;

  if (!allPeople?.length) return <NoDataMessage2 />;

  return (
    <CPWrapper>
      <GenericFallbacksWrapper>
        <PeopleFilters />
        <EmployeeGrids />
      </GenericFallbacksWrapper>
    </CPWrapper>
  );
};
