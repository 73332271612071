import { atom, selectorFamily } from 'recoil';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { getTitlesForCompany } from '../utils/peopleHelpers';
import { allEmployeesByCompanyState, boardMembersByCompanyState } from './PeopleByCompanyState';

export const nameSearchQueryState = atom<string>({
  key: 'nameSearchQueryState',
  default: '',
});

export const filteredEmployeesByCompanyState = selectorFamily<IPersonDataModel[] | null, number>({
  key: 'filteredPeopleByCompanyState',
  get:
    (companyId) =>
    ({ get }) => {
      const nameQuery = get(nameSearchQueryState);
      const allEmployees = get(allEmployeesByCompanyState(companyId));
      const titleQuery = get(titleSearchQueryState);
      return filterByNameAndTitle(allEmployees, nameQuery, titleQuery, companyId);
    },
});

export const filteredBoardMembersByCompanyState = selectorFamily<IPersonDataModel[] | null, number>({
  key: 'filteredBoardMembersByCompanyState',
  get:
    (companyId) =>
    ({ get }) => {
      const nameQuery = get(nameSearchQueryState);
      const boardMembers = get(boardMembersByCompanyState(companyId));
      const titleQuery = get(titleSearchQueryState);
      return filterByNameAndTitle(boardMembers, nameQuery, titleQuery, companyId);
    },
});

export const titleSearchQueryState = atom<string>({
  key: 'titleQueryState',
  default: '',
});

export function filterByNameAndTitle(
  people: IPersonDataModel[] | null,
  nameQuery: string,
  titleQuery: string,
  companyId: number
): IPersonDataModel[] | null {
  return (
    people?.filter((p) => {
      const titles = getTitlesForCompany(p, companyId);
      const hasTitleQuery =
        titleQuery.length === 0 || titles?.toLocaleLowerCase().includes(titleQuery.toLocaleLowerCase());
      const fullName = `${p.firstName} ${p.lastName}`;
      const hasNameQuery =
        nameQuery.length === 0 || fullName.toLocaleLowerCase().includes(nameQuery.toLocaleLowerCase());

      return hasTitleQuery && hasNameQuery;
    }) ?? null
  );
}
