import {
  IAirTableBaseDataModel,
  IAirTableFieldDataModel,
  IAirTableTableDataModel,
} from '../../../../data-models/airtable-base.data-model';
import {
  IUniqueColumnFieldDataModel,
  IAirTableEntityDataModel,
} from '../../../../data-models/airtable-entity.data-model';
import {
  AirtableSyncType,
  IAirTableSyncColumnDataModel,
  IAirtableSyncPayloadDataModel,
  IBaseColumnDataModel,
  createAirTableSyncColumnDataModel,
} from '../../../../data-models/airtable-sync.data-model';
import { IUserDataModel } from '../../../../data-models/user.data-model';

// each option should be uniquely matched with one column
export function getAvailableOptionsForColumn(
  column: IUniqueColumnFieldDataModel,
  options: IAirTableFieldDataModel[],
  matches: Map<string, IAirTableFieldDataModel>
) {
  const availableOptions = options.filter((option) => {
    return (
      matches.get(column.value)?.id === option.id ||
      ![...matches.values()].some((field) => field.id === option.id)
    );
  });
  return availableOptions;
}

interface IGetSyncPayloadParams {
  selectedBase: IAirTableBaseDataModel;
  selectedTable: IAirTableTableDataModel;
  selectedEntity: IAirTableEntityDataModel;
  matches: Map<string, IAirTableFieldDataModel>;
  syncFields: IAirTableSyncColumnDataModel[];
}

// (if multiple columns in `identifierColumn` and `airtableIdentifierColumnId`, join with ",")
export function getSyncPayload({
  selectedBase,
  selectedTable,
  selectedEntity,
  matches,
  syncFields,
}: IGetSyncPayloadParams): IAirtableSyncPayloadDataModel {
  return {
    entity: selectedEntity.entityValue,
    airtableTableId: selectedTable.id,
    airtableTableName: selectedTable.name,
    airtableBaseId: selectedBase.id,
    airtableBaseName: selectedBase.name,
    identifierColumn: getForesightIndexColumns(matches).join(','),
    airtableIdentifierColumnId: getAirtableIndexColumns(matches).join(','),
    syncFields: syncFields,
  };
}

export function getForesightIndexColumns(matches: Map<string, IAirTableFieldDataModel>) {
  return [...matches.keys()];
}

export function getAirtableIndexColumns(matches: Map<string, IAirTableFieldDataModel>) {
  return [...matches.values()].map((field) => field.id);
}

export function filterOutIndexColumns(
  cols: IBaseColumnDataModel[],
  matches: Map<string, IAirTableFieldDataModel>
) {
  const indexCols = getAirtableIndexColumns(matches);
  const result = [...cols].filter((col) => {
    return !indexCols.includes(col.value);
  });
  return result;
}

export function isFDColumnSyncedAsEditable(column: IBaseColumnDataModel | null) {
  return Boolean(column) && column?.isSynced && column.isSynced.syncType === AirtableSyncType.editable;
}

export function isSyncEstablished(
  airtableCol: IBaseColumnDataModel | null,
  selectedTable: IAirTableTableDataModel,
  foresightColumnId: string | null
) {
  return (
    airtableCol?.isSynced &&
    airtableCol.isSynced.airtableSync.airtableTableId === selectedTable.id &&
    Boolean(foresightColumnId) &&
    airtableCol?.isSynced.entityColumn === foresightColumnId
  );
}

export function getInfoMessage({
  column,
  usersByEmailMap,
  isReadOnly = false,
  isIndexColumn = false,
}: {
  column: IBaseColumnDataModel | null;
  usersByEmailMap: Map<string, IUserDataModel>;
  isReadOnly?: boolean;
  isIndexColumn?: boolean;
}) {
  if (isIndexColumn) {
    return `A unique column is not editable. We are using it to match to AirTable`;
  } else if (isReadOnly) {
    const syncData = column?.isSynced;
    const editor = syncData ? usersByEmailMap.get(syncData.createdBy ?? '') : null;
    const tableName = syncData && syncData.airtableSync?.airtableTableName;
    return `${editor?.name ?? 'Unknown User'} is already editing this column in table "${
      tableName ?? 'Unknown Table'
    }"`;
  } else {
    return '';
  }
}

export function getSyncedColumns(
  airtableCols: IBaseColumnDataModel[]
): Record<string, IAirTableSyncColumnDataModel> {
  return airtableCols.reduce((acc, curr) => {
    if (curr.isSynced) {
      acc[curr.value] = createAirTableSyncColumnDataModel({
        syncType: AirtableSyncType.readOnly,
        entityColumn: curr!.isSynced!.entityColumn,
        airtableColumnId: curr.value,
        airtableColumnName: curr.displayName,
      });
    }
    return acc;
  }, {} as Record<string, IAirTableSyncColumnDataModel>);
}

export function getAllIndexColumnsForEntity(allFDIndexColumns: Set<string>, entity: string) {
  return [...allFDIndexColumns].filter((col) => col.split(',')[0] === entity);
}
