import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function noteExpirationFields() {
  return {
    currencyid: transactionCurrencyIdField(),
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    investmentType: yup.string().nullable(),
    principalWriteOffAmount: usdField().nullable().required('Amount Write Off is required'),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    transactionDate: transactionDateSchema(),
  };
}

export function noteExpirationFormSchema() {
  return yup.object().shape(noteExpirationFields());
}

export function noteExpirationGridSchema() {
  return yup.object().shape(noteExpirationFields()).omit(['investmentType']);
}
