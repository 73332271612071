import { Kpi, KpiConfig, KpiConfigMeta, KPI_COMPONENTS, KpiCalculationParams } from '../../../../types';
import { format } from './kpi-formatter';

export class ProjectedMoicKpiConfig implements KpiConfig {
  private id = KPI_COMPONENTS.PROJECTED_MOIC as const;
  private title = 'Projected MOIC';
  private type = 'multiple';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ kpis }: KpiCalculationParams): Kpi {
    const value =
      (kpis?.[KPI_COMPONENTS.TOTAL_PROJECTED_VALUE]?.value ?? 0) /
      (kpis?.[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT]?.value || 1);

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
