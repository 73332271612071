import { ColDef } from 'ag-grid-community';
import { merge } from 'lodash-es';

export function withOverrides<T>(defs: ColDef<T>[], overrides: Record<string, ColDef>) {
  const defMap = new Map<string, ColDef<T>>(defs.map((def) => [(def.field ?? def.colId) as string, def]));

  for (const [field, customDefs] of Object.entries(overrides)) {
    if (defMap.has(field)) {
      defMap.set(field, merge({}, defMap.get(field), customDefs));
    }
  }
  return Array.from(defMap.values());
}
