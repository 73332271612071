import { Chip, Stack } from '@mui/material';
import { FC } from 'react';
import { UseFieldArrayReturn, useWatch } from 'react-hook-form';
import { IScenarioDataModel, ScenarioTransactionType } from '../../../../../data-models/scenario.data-model';
import { BorderedFormCard } from '../../../../Integrations/Airtable/components/CommonStyledComponents';
import { useViewMode } from '../../hooks/useViewMode';
import { transactionNameMap } from '../../constants';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { RoundFields } from './RoundFields';
import { TransactionFieldActions } from './TransactionFieldActions';
import { ExitFields } from './ExitFields';
import { TransactionTypeToggle } from './TransactionTypeToggle';
import { PartialExitFields } from './PartialExitFields';

export interface ITransactionFieldsProps {
  index: number;
  fieldArrayMethods: UseFieldArrayReturn<IScenarioDataModel, 'scenarioTransactions'>;
}

export interface IExtendedTransactionFieldsProps extends ITransactionFieldsProps {
  captable: ICaptableDataModel;
}

export const TransactionFields: FC<IExtendedTransactionFieldsProps> = ({
  index,
  fieldArrayMethods,
  captable,
}) => {
  const transactionType: ScenarioTransactionType = useWatch({ name: `scenarioTransactions.${index}.type` });

  const isViewMode = useViewMode();

  return (
    <BorderedFormCard sx={{ my: '1rem' }}>
      <Stack direction='row' justifyContent={'space-between'}>
        {isViewMode ? (
          <Chip label={transactionNameMap[transactionType]} />
        ) : (
          <>
            <TransactionTypeToggle index={index} />
            <TransactionFieldActions index={index} fieldArrayMethods={fieldArrayMethods} />
          </>
        )}
      </Stack>
      {transactionType === ScenarioTransactionType.round && (
        <RoundFields index={index} fieldArrayMethods={fieldArrayMethods} />
      )}
      {transactionType === ScenarioTransactionType.exit && (
        <ExitFields index={index} fieldArrayMethods={fieldArrayMethods} />
      )}
      {transactionType === ScenarioTransactionType.partialExit && (
        <PartialExitFields index={index} fieldArrayMethods={fieldArrayMethods} captable={captable} />
      )}
    </BorderedFormCard>
  );
};
