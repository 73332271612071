import { HTMLAttributes, SyntheticEvent, useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Button,
  FilterOptionsState,
  IconButton,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { IFormField, IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { formSelectInlineClassNames } from '../../../theme/component-styles';
import { IBaseFieldProps } from './types';

const actionValue = -1;
interface IActionOption {
  value: number;
  inputValue?: string;
}

export function FieldSelect(props: IBaseFieldProps<IFormFieldSelectMeta<SelectType | string>>) {
  const { formField, formProps } = props;
  const { ref, onChange, onBlur, value } = formProps;
  const { disabled = false, variant, disableClearable } = formField ?? {};
  const className = variant === 'form-inline' ? formSelectInlineClassNames : '';
  const { getOptionsLabel, options, getOptionDisabled, renderOption, componentsProps, filterOptions } =
    useFieldSelect(formField);

  const _onChange = useCallback(
    (event: SyntheticEvent, newValue: SelectType | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Autocomplete
      aria-label={formField?.label}
      className={className}
      componentsProps={componentsProps}
      disableClearable={disableClearable}
      disabled={disabled}
      filterOptions={filterOptions}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionsLabel}
      isOptionEqualToValue={(option, value) => option === value}
      onBlur={onBlur}
      onChange={_onChange}
      openOnFocus
      options={options}
      renderInput={(params) => <TextField {...params} ref={ref} placeholder={formField.placeholder} />}
      renderOption={renderOption}
      value={value ?? null}
    />
  );
}

export type SelectType = string | number;

export function useFieldSelect(field: IFormField<IFormFieldSelectMeta<SelectType>>) {
  const actionableProps = field.rendererMeta?.actionMeta;
  const { actionLabel, helperText, onDelete } = actionableProps ?? {};

  const valuesAsMap = useMemo(() => {
    const values = field.rendererMeta?.values ?? [];
    return values.reduce((res, choice) => {
      return res.set(choice.value, choice);
    }, new Map<SelectType, ISimpleChoice<SelectType>>());
  }, [field.rendererMeta?.values]);

  const getOptionsLabel = useCallback(
    (option: SelectType | string) => {
      const choiceItem = valuesAsMap.get(option) ?? { displayName: '', value: '' };
      return choiceItem.displayName ?? String(choiceItem.value);
    },
    [valuesAsMap]
  );

  const getAllLabels = useCallback(
    (option: SelectType) => {
      const choiceItem = valuesAsMap.get(option) ?? { displayName: '', value: '' };
      return {
        label: choiceItem.displayName ?? String(choiceItem.value),
        secondaryLabel: choiceItem.secondaryLabel,
      };
    },
    [valuesAsMap]
  );

  const _filterOptions = createFilterOptions<SelectType | IActionOption>();
  const filterOptions = useCallback(
    (
      options: (SelectType | IActionOption)[],
      optionsState: FilterOptionsState<SelectType | IActionOption>
    ) => {
      const filtered = _filterOptions(options, optionsState);
      if (actionableProps?.onAction) {
        const { inputValue } = optionsState;
        if (filtered[0] && isActionOption(filtered[0])) filtered.shift();
        filtered.unshift({
          value: actionValue,
          inputValue: inputValue || undefined,
        });
      }
      return filtered;
    },
    [_filterOptions, actionableProps?.onAction]
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: SelectType | IActionOption) => {
      if (isActionOption(option)) {
        return (
          <Button
            key={option.value}
            role='option'
            component='li'
            fullWidth
            sx={{ justifyContent: 'start', pl: '1.125em', mt: '-0.5rem' }}
            color='secondary'
            size='medium'
            onClick={() => actionableProps?.onAction(option.inputValue)}
          >
            {`${actionLabel}${option.inputValue ? ` ${option.inputValue}` : ''}`}
          </Button>
        );
      } else {
        const { label, secondaryLabel } = getAllLabels(option);
        return (
          <Stack
            component='li'
            {...props}
            key={option as SelectType}
            direction={'row'}
            justifyContent={'space-between !important'}
          >
            <Typography variant='body2' noWrap>
              {label}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} gap='0.25rem'>
              {secondaryLabel && (
                <Typography variant='caption' color='text.secondary'>
                  {' '}
                  {secondaryLabel}
                </Typography>
              )}
              {onDelete && (
                <IconButton
                  aria-label='delete'
                  color={'secondary'}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(option);
                  }}
                >
                  <DeleteOutlineIcon fontSize={'small'} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        );
      }
    },
    [actionLabel, actionableProps, getAllLabels, onDelete]
  );

  const componentsProps = useMemo(() => {
    if (!actionableProps) return undefined;
    return { paper: getSelectPaperProps(helperText) };
  }, [actionableProps, helperText]);

  return {
    filterOptions: actionableProps?.onAction
      ? (filterOptions as (opts: SelectType[], optState: FilterOptionsState<SelectType>) => SelectType[])
      : undefined,
    getOptionsLabel,
    options: Array.from(valuesAsMap.keys()),
    valuesAsMap,
    getOptionDisabled: (option: SelectType) => valuesAsMap.get(option)?.disabled ?? false,
    actionableProps,
    renderOption,
    componentsProps,
  };
}

export function getSelectPaperProps(helperText?: string) {
  return {
    sx: {
      minWidth: 'max-content',
      paddingTop: helperText ? '0.5rem' : '0.2rem',
      ':before': helperText
        ? {
            content: `'${helperText}'`,
            transform: { translate: '0, 1rem' },
            marginLeft: '1rem',
            fontSize: '0.75rem',
            color: 'text.secondary',
          }
        : undefined,
    },
  };
}

function isActionOption(value: SelectType | IActionOption): value is IActionOption {
  return (value as IActionOption).value === actionValue;
}
