import { Stack, Typography } from '@mui/material';
import { MessageScreen } from '../../../../../components/MessageScreen/MessageScreen';
import ArchiveCheckIconSvg from '../../../../../assets/icons/archive-doc-check.svg?react';

export function KPIThankYouScreen() {
  return (
    <MessageScreen>
      <Stack alignItems={'center'} justifyContent={'flex-end'} gap={'1rem'}>
        <ArchiveCheckIconSvg />
        <Typography variant='h1'>Thank you!</Typography>
        <Typography variant='h6' mt={'1rem'}>
          {`We received your response`}
        </Typography>
      </Stack>
    </MessageScreen>
  );
}
