import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  BarSeriesOption,
  DatasetComponentOption,
  EChartsType,
  GridComponentOption,
  LegendComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { noop } from 'lodash-es';
import { CategoryAxisBaseOption } from 'echarts/types/src/coord/axisCommonTypes';
import { ComposeOption } from 'echarts/core';
import { Typography } from '@mui/material';
import { Chart, ChartSizes, useChartResizeObserver } from '../chart-utils';
import { ChartWrapper } from '../ChartWrapper/ChartWrapper';
import { defaultFormatter } from '../../../util/formatter-service';
import { AxisTickTextStyle, BAR_COLORS, CommonChartOptions, ToolTipTextStyle } from '../chart-constants';

export type EChartsBarOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | TooltipComponentOption
>;

export type XCategoryData = Exclude<CategoryAxisBaseOption['data'], undefined>;

export type IBarChartProps = {
  title: string | ReactNode;
  displayName: string;
  valueFormatter?: (value: number) => string;
  xCategoryData: XCategoryData;
  yValueData: BarSeriesOption[];
  size?: keyof typeof ChartSizes;
};

export function BarChart(props: IBarChartProps) {
  const {
    title,
    displayName,
    valueFormatter = defaultFormatter,
    xCategoryData,
    yValueData,
    size = '1/4 Screen',
  } = props;
  const [widthState, setWidthState] = useState<string | number>(700);

  const chartContainer = useRef<HTMLDivElement | null>(null);
  const barChart = useRef<EChartsType | null>(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useChartResizeObserver(barChart, chartContainer);

  useEffect(() => {
    if (!barChart.current) {
      return;
    }

    const options: EChartsBarOption = {
      color: BAR_COLORS,
      ...CommonChartOptions,
      xAxis: {
        axisLabel: {
          ...AxisTickTextStyle,
        },
        data: xCategoryData,
        type: 'category',
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          ...AxisTickTextStyle,
          formatter: (val: number) => {
            return valueFormatter(Number(val));
          },
        },
      },
      series: yValueData,
      tooltip: {
        ...ToolTipTextStyle,
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter: (value) => {
          if (value !== 0 && !value) {
            return '-';
          }
          return valueFormatter(Number(value));
        },
        trigger: 'axis',
      },
    };

    if (barChart.current) {
      barChart.current.setOption(options, true);
    }
  }, [displayName, title, valueFormatter, xCategoryData, yValueData]);

  return (
    <ChartWrapper id={displayName} onMouseLeave={noop} onMouseEnter={noop} width={widthState}>
      {typeof title === 'string' ? <Typography>{title}</Typography> : title}
      <Chart width={'100%'} ref={chartContainer} />
    </ChartWrapper>
  );
}
