import { useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { AddTransactionForm } from './AddTransactionForm';
import { AddInvestmentForm } from './AddInvestmentForm';

export const ActionLabel = 'Add Transaction or Investment';

export function AddTransactionOrInvestment() {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchor);

  const [isTransactionFormOpen, setIsTransactionFormOpen] = useState(false);
  const [isInvestmentFormOpen, setIsInvestmentFormOpen] = useState(false);

  return (
    <>
      <Button
        variant={'contained'}
        color={'secondary'}
        disableElevation={true}
        onClick={(e) => setAnchor(e.currentTarget)}
        startIcon={<AddIcon />}
        sx={{ px: '1.5rem' }}
        aria-label={ActionLabel}
      >
        Add
      </Button>
      <Menu
        anchorEl={anchor}
        open={isMenuOpen}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchor(null);
            setIsTransactionFormOpen(true);
          }}
        >
          <ListItemIcon>
            <PaidOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{'Add Transaction'}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            setIsInvestmentFormOpen(true);
          }}
        >
          <ListItemIcon>
            <LocalAtmOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{'Add Round'}</ListItemText>
        </MenuItem>
      </Menu>

      <BasicDialog
        open={isTransactionFormOpen}
        title='Add Transaction'
        onClose={() => setIsTransactionFormOpen(false)}
      >
        {isTransactionFormOpen && <AddTransactionForm onClose={() => setIsTransactionFormOpen(false)} />}
      </BasicDialog>

      <BasicDialog
        open={isInvestmentFormOpen}
        title='Add Round'
        onClose={() => setIsInvestmentFormOpen(false)}
      >
        {isInvestmentFormOpen && <AddInvestmentForm onClose={() => setIsInvestmentFormOpen(false)} />}
      </BasicDialog>
    </>
  );
}
