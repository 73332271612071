import { alpha, Divider, Menu, MenuItem, MenuProps, styled } from '@mui/material';
import React, { useCallback, useState } from 'react';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SegmentOutlinedIcon from '@mui/icons-material/SegmentOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PlusIconButton from '../../../components/IconButton/PlusIconButton';
import { RendererType } from '../../../data-models/field.data-model';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 220,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      gap: '.75rem',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.colors.neutral[40],
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export function KPITemplateBuildMenu({
  onAddSectionItem,
  disableKpis = false,
}: {
  onAddSectionItem: (type: RendererType) => void;
  disableKpis?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectQuestion = useCallback(
    (type: RendererType) => () => {
      onAddSectionItem(type);
      handleClose();
    },
    [onAddSectionItem]
  );

  return (
    <>
      <PlusIconButton
        id='kpis-template-build-button'
        aria-controls={open ? 'kpis-template-build-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ maxWidth: 'fit-content', mb: '1rem' }}
        color='secondary'
      >
        Add Question
      </PlusIconButton>
      <StyledMenu
        id='customized-menu'
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        elevation={12}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={onSelectQuestion(RendererType.universalKpiTable)}
          disableRipple
          disabled={disableKpis}
        >
          <StackedLineChartIcon />
          Universal Metrics
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={onSelectQuestion(RendererType.text)} disableRipple>
          <SegmentOutlinedIcon />
          Paragraph Text
        </MenuItem>
        <MenuItem onClick={onSelectQuestion(RendererType.fileUpload)} disableRipple>
          <InsertDriveFileOutlinedIcon />
          File
        </MenuItem>
      </StyledMenu>
    </>
  );
}
