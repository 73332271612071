const stringComparator = (a: string, b: string) => {
  return a.localeCompare(b, 'en', { sensitivity: 'base' });
};

const numberComparator = (a: number, b: number) => {
  return a - b;
};

export const dateComparator = (a: Date, b: Date, timeSensitive = false) => {
  if (timeSensitive) return a.getTime() - b.getTime();
  else return new Date(a.setHours(0, 0, 0)).getTime() - new Date(b.setHours(0, 0, 0)).getTime();
};

export function genericComparator(a: unknown, b: unknown): number {
  if ((a === undefined || a === null) && (b === undefined || b === null)) return 0;

  if (a === undefined || a === null) return 1;
  if (b === undefined || b === null) return -1;

  if (a instanceof Date && b instanceof Date) {
    return dateComparator(a, b);
  } else if (typeof a === 'string' && typeof b === 'string') {
    return stringComparator(a, b);
  } else if (typeof a === 'number' && typeof b === 'number') {
    return numberComparator(a, b);
  } else return 0; // should never happen
}

export function compareBySortOrder(a: { sortOrder?: number | null }, b: { sortOrder?: number | null }) {
  return (a?.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b?.sortOrder ?? Number.MAX_SAFE_INTEGER);
}
