import { CSSProperties, FC } from 'react';
import { useRecoilValue } from 'recoil';
import { useFormContext } from 'react-hook-form';
import { companyState } from '../../../../../services/state/CompanyState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { StyledTextField, ScenarioNameFieldStyles } from '../../components/commonStyledComponents';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';

interface ScenarioNameFieldProps {
  style?: CSSProperties;
}

export const ScenarioNameField: FC<ScenarioNameFieldProps> = ({ style }) => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));

  const {
    register,
    formState: { errors },
  } = useFormContext<Partial<IScenarioDataModel>>();

  if (!company) return null;

  return (
    <StyledTextField
      {...register('name')}
      className='read-write-input transparent'
      inputProps={{
        style: ScenarioNameFieldStyles,
      }}
      InputLabelProps={{ style: { fontSize: 34 } }}
      placeholder='Untitled'
      sx={{ borderBottom: 'none', transition: 'none' }}
      variant='standard'
      autoFocus
      error={!!errors.name}
      helperText={errors.name?.message ?? ' '}
      style={style}
    />
  );
};
