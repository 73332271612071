import { CSSProperties, Dispatch, SetStateAction, useCallback, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { RangeDatePicker } from '../../../components/DatePicker/RangeDatePicker';
import { Icon, IconTypes } from '../../../components/Icon';
import { IRangeShortcut } from '../../../components/DatePicker/RangeShortcuts';

const SelectorContainer = styled('div')`
  margin: 0;
  padding: 0;
  position: relative;
`;

const StyledSelector = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  border-radius: 4px;
  height: 2.5rem;
  min-width: 15rem;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin-top: 0.15rem;
`;

interface IDateRangeSelectorProps {
  setSelectedStartingDate: Dispatch<SetStateAction<Date | null>>;
  setSelectedEndingDate: Dispatch<SetStateAction<Date | null>>;
  textContent: string;
  initialStartDate?: Date;
  initialEndDate?: Date;
  shortcuts?: IRangeShortcut[];
  style?: CSSProperties;
}

export const DateRangeSelector = ({
  setSelectedStartingDate,
  setSelectedEndingDate,
  initialStartDate,
  initialEndDate,
  textContent,
  shortcuts,
  style,
}: IDateRangeSelectorProps) => {
  const { colors } = useTheme();
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate ?? null);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate ?? null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleApply = useCallback(() => {
    if (!startDate && endDate) return;
    const end = endDate ?? new Date();
    setSelectedStartingDate(startDate);
    setSelectedEndingDate(end);
    setAnchorEl(null);
  }, [startDate, endDate, setSelectedStartingDate, setSelectedEndingDate]);

  return (
    <SelectorContainer style={style}>
      <StyledSelector onClick={(e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}>
        <Typography variant='subtitle2' color={colors.neutral[40]}>
          {textContent}
        </Typography>
        <Icon type={IconTypes.CALENDAR} color={colors.primary[60]} />
      </StyledSelector>

      {anchorEl && (
        <RangeDatePicker
          startingDate={startDate}
          endingDate={endDate}
          setStartingDate={setStartDate}
          setEndingDate={setEndDate}
          handleApply={handleApply}
          popoverProps={{
            anchorEl,
            open: Boolean(anchorEl),
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            onClose: () => setAnchorEl(null),
          }}
          shortcuts={shortcuts}
        />
      )}
    </SelectorContainer>
  );
};
