import { useState } from 'react';
import { FundInvestment, FundInvestmentTransaction } from '../../../schemas/FundReserves.schema';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useReservesActions } from './reservesActions';
import { useReservesFormState } from './useReservesFormState';

export function ConfirmDeleteReservesInvestment({ investment }: { investment: FundInvestment }) {
  const [loading, setLoading] = useState(false);
  const { deleteInvestment } = useReservesActions();
  const { resetFormState, selectedCompanyName } = useReservesFormState();

  async function onConfirm() {
    if (!investment.id) return;
    setLoading(true);
    await deleteInvestment(investment.id);
    setLoading(false);
    resetFormState();
  }
  return (
    <ConfirmDeletionDialog
      open
      onClose={resetFormState}
      loading={loading}
      onConfirm={onConfirm}
      title={`Delete ${selectedCompanyName ?? ''}?`}
    >
      Are you sure you want to permanently delete this investment?
    </ConfirmDeletionDialog>
  );
}

export function ConfirmDeleteReservesTransaction({
  transaction,
}: {
  transaction: FundInvestmentTransaction;
}) {
  const [loading, setLoading] = useState(false);
  const { deleteInvestmentTransaction } = useReservesActions();
  const { resetFormState, selectedCompanyName } = useReservesFormState();

  async function onConfirm() {
    if (!transaction.uuid) return;
    setLoading(true);
    await deleteInvestmentTransaction(transaction.uuid);
    setLoading(false);
    resetFormState();
  }
  return (
    <ConfirmDeletionDialog
      open
      onClose={resetFormState}
      loading={loading}
      onConfirm={onConfirm}
      title={`Delete transaction for ${selectedCompanyName ?? ''}?`}
    >
      Are you sure you want to permanently delete this transaction?
    </ConfirmDeletionDialog>
  );
}
