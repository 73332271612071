import { FieldEntity } from '../../../data-models/field2.data-model';
import { createField3DataModel, ITextMeta } from '../../../data-models/field3.data-model';

export function getDealReasonFields(entity: string) {
  return [
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: entity as FieldEntity,
      entityKey: 'reason',
      displayName: 'Name',
      dataType: 'string',
      meta: {
        maxLength: -1,
      },
    }),
  ];
}
