import { Autocomplete, Chip, TextField } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { MoreChipWithTooltip } from '../../../pages/CompanyProfiles/Summary/components/MoreChipWithTooltip/MoreChipWithTooltip';
import { IFormFieldFreeLabelsMeta } from '../../../view-models/form.view-model';
import { formSelectInlineClassNames } from '../../../theme/component-styles';
import { IBaseFieldProps } from './types';

const DefaultMaxTags = 2;

export function FieldFreeLabels(props: IBaseFieldProps<IFormFieldFreeLabelsMeta>) {
  const { formField, formProps } = props;
  const { ref, onChange, onBlur, value } = formProps;
  const { disabled = false, variant } = formField ?? {};
  const className = variant === 'form-inline' ? formSelectInlineClassNames : '';
  const options = (formField.rendererMeta?.values ?? []).map((option) => option.value);

  const _onChange = useCallback(
    (_: SyntheticEvent, newValue: string[]) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      freeSolo
      getLimitTagsText={(more) => {
        const otherLabels = (value as string[]).slice(-more).map((label) => {
          return label;
        });
        return (
          <MoreChipWithTooltip
            title={`Other (${more})`}
            moreChipTotal={more}
            otherItems={otherLabels}
          ></MoreChipWithTooltip>
        );
      }}
      limitTags={formField.rendererMeta?.maxTags ?? DefaultMaxTags}
      multiple
      onBlur={onBlur}
      onChange={_onChange}
      options={options}
      renderInput={(params) => <TextField {...params} ref={ref} placeholder={formField.placeholder} />}
      renderTags={(labels: readonly string[], getTagProps) => {
        return labels.map((label, index) => {
          return <Chip {...getTagProps({ index })} key={label} label={label} size='small' />;
        });
      }}
      value={value ?? []}
    />
  );
}
