import { FC, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Typography, useTheme } from '@mui/material';
import { formatDateShort } from '../../../../../util/formatters/DateFormatters';

import {
  StyledHeadLeftAlign,
  StyledRow,
  Table,
  Td,
  TdLeftAlign,
} from '../../../components/Table/StyledTableElements';
import { milestonesByCompanyState } from '../../../state/MilestonesState';
import { ICompanyMilestoneDataMode } from '../../../../../data-models/company-milestone.data-model';
import { NoDataAddOne } from '../NoDataAddOne';
import { StatusComponent } from '../StatusComponent/StatusComponent';
import { EditActions } from '../EditActions/EditActions';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { useMilestoneActions } from '../../../hooks/useMilestoneActions';
import { MilestoneModal } from '../MilestoneForms/MilestoneModal';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { ConfirmDeletionDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';

export const Milestones: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  if (!companyId) throw new Error('No company id found');
  const milestones: ICompanyMilestoneDataMode[] | null = useRecoilValue(milestonesByCompanyState(companyId));
  const { handleDeleteMilestone } = useMilestoneActions();
  const { pushSuccessToast, pushErrorToast } = useToastMessageState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [milestoneToEdit, setMilestoneToEdit] = useState<number | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { colors } = useTheme();

  const handleDelete = useCallback(() => {
    if (!milestoneToEdit) return;
    try {
      handleDeleteMilestone(companyId, milestoneToEdit);
      pushSuccessToast({ message: 'Milestone deleted successfully' });
    } catch (err) {
      console.error(err);
      pushErrorToast({ message: 'Failed to delete milestone' });
    } finally {
      setShowConfirmDelete(false);
      setMilestoneToEdit(null);
    }
  }, [companyId, milestoneToEdit, handleDeleteMilestone, pushErrorToast, pushSuccessToast]);

  if (!milestones?.length)
    return <NoDataAddOne mainMessage='No milestones set' subMessage='Start by adding one' />;

  return (
    <>
      <Table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col style={{ width: '1%' }} />
        </colgroup>
        <thead>
          <tr>
            <StyledHeadLeftAlign scope='col'>
              <Typography variant='caption' color='inherit'>
                Milestone
              </Typography>
            </StyledHeadLeftAlign>
            <StyledHeadLeftAlign scope='col'>
              <Typography variant='caption' color='inherit'>
                Target Date
              </Typography>
            </StyledHeadLeftAlign>
            <StyledHeadLeftAlign scope='col'>
              <Typography variant='caption' color='inherit'>
                Status
              </Typography>
            </StyledHeadLeftAlign>
            <StyledHeadLeftAlign scope='col'>
              <Typography variant='caption' color='inherit'>
                Last Modified
              </Typography>
            </StyledHeadLeftAlign>
            <th scope='col'>{/* Actions */}</th>
          </tr>
        </thead>
        <tbody>
          {milestones.map((data, i) => (
            <StyledRow key={i} className='componentWithActionOnHover'>
              <Td>
                <Typography variant='body2'>{data.title}</Typography>
              </Td>
              <TdLeftAlign>
                <Typography variant='body2'>{formatDateShort(data.targetDate)}</Typography>
              </TdLeftAlign>
              <TdLeftAlign>
                <StatusComponent status={data.status} />
              </TdLeftAlign>

              <TdLeftAlign>
                <Typography variant='body2'>{formatDateShort(data.updatedAt)}</Typography>
              </TdLeftAlign>
              <Td>
                <EditActions
                  onEdit={() => {
                    setShowEditModal(true);
                    setMilestoneToEdit(data.id);
                  }}
                  onDelete={() => {
                    setShowConfirmDelete(true);
                    setMilestoneToEdit(data.id);
                  }}
                />
              </Td>
            </StyledRow>
          ))}
        </tbody>
      </Table>
      <MilestoneModal
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setMilestoneToEdit(null);
        }}
        initialValues={milestones.find((m) => m.id === milestoneToEdit)}
      />

      <ConfirmDeletionDialog
        open={showConfirmDelete}
        onClose={() => {
          setShowConfirmDelete(false);
          setMilestoneToEdit(null);
        }}
        onConfirm={handleDelete}
        title='Delete this Milestone?'
      >
        <Typography variant='body2' color={colors.neutral[60]}>
          Are you sure you want to delete this milestone permanently?
        </Typography>
      </ConfirmDeletionDialog>
    </>
  );
};
