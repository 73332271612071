import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { CompanyLogoAndName } from '../../../grid-renderers/CompanyCellRenderer';

import { cardStyles } from '../../../../theme/component-styles';
import { MostRecentChartData } from '../../../../pages/PortfolioOverview/hooks/useMostRecentRounds';
import { FMT } from '../../../../util/formatter-service';
import { RoundNameRenderer } from '../../../AgTable/cell-renderers/RoundRenderer';

export const PortfolioChartRow = styled('li')`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: subgrid;

  @supports not (grid-template-columns: subgrid) {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }
`;

export function MostRecentRow({
  company: { name, logoUrl },
  round,
  date: mostRecent,
  amount: postMoneyValuation,
}: MostRecentChartData): ReactElement {
  return (
    <PortfolioChartRow style={{ ...cardStyles, padding: '0.25rem 0.5rem', minWidth: '1rem' }}>
      <CompanyLogoAndName name={name} logoUrl={logoUrl ?? ''} />
      {round ? (
        <RoundNameRenderer
          name={round}
          style={{
            minWidth: '5rem',
            fontSize: '0.8rem',
            margin: 'auto',
            color: 'text.primary',
            paddingInline: '0.2rem',
          }}
        />
      ) : null}
      <Typography variant={'body2'} noWrap sx={{ marginBlock: 'auto' }}>
        {FMT.format('monthYear', mostRecent)}
      </Typography>
      <Typography
        variant={'body2'}
        noWrap
        sx={{ display: 'grid', alignItems: 'center', justifyContent: 'end' }}
      >
        {FMT.format('usdShort', postMoneyValuation)}
      </Typography>
    </PortfolioChartRow>
  );
}
