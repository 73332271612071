import { ICellRendererParams } from 'ag-grid-community';
import { ReactNode } from 'react';
import { StatusRenderer } from '../../../../../../components/AgTable/cell-renderers/StatusRenderer';
import { groupingByField } from '../../../../../../components/AgTable/cell-renderers/GenericIdRenderer';
import { IKpiCompanyData } from '../../AllCompaniesData';

export interface IRequiredDataRendererParams
  extends ICellRendererParams<IKpiCompanyData, unknown | unknown[]> {
  valueRenderer?: (params: ICellRendererParams) => ReactNode;
}

export function RequiredDataRenderer(params: IRequiredDataRendererParams) {
  const { value, valueFormatted, node } = params;
  if (node.group && !groupingByField(params)) {
    return null;
  }
  if (!value || !(value as unknown[])?.length) {
    return <StatusRenderer value={'Missing Data'} status='warning' />;
  }
  if (params.valueRenderer) {
    return params.valueRenderer(params);
  }
  return <>{valueFormatted ?? ''}</>;
}
