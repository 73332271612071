import { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IField } from '../../../../../../data-models/field2.data-model';
import { generateKPITableColHeaderData } from '../../../../utils';
import { KpiSection } from '../../../../../../data-models/company-financials.data-model';
import {
  IKPIDataValue,
  periodFromGranularityAndFrequency,
} from '../../../../../../data-models/kpi-requests.data-model';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'September',
  'October',
  'November',
  'December',
];

export const useKPITableMetricsInputs = (
  metrics: IField<unknown>[],
  kpiRequestPeriodDate: string,
  sectionFieldRef: string,
  kpiSection = KpiSection.actual
) => {
  const { watch, setValue, getValues, clearErrors } = useFormContext();
  const kpiRequestFrequency = watch(`kpiRequest.frequency`);
  const kpiRequestGranularity = watch(`kpiRequest.granularity`);
  const kpiRequestFYE = watch(`kpiRequest.fye`) ?? '';

  const [metricsInputColNames, setMetricsInputColNames] = useState<string[]>([]);

  const applyValuesToMetricInputs = useCallback(() => {
    let inputsDates: {
      title: string;
      date: string;
    }[] = [];

    inputsDates = generateKPITableColHeaderData({
      fyeDateString: kpiRequestFYE,
      periodISODate: kpiRequestPeriodDate,
      selectedFrequency: periodFromGranularityAndFrequency(kpiRequestGranularity, kpiRequestFrequency),
      kpiRequestFrequency,
      kpiSection,
    });

    // Update col names for each metric column
    setMetricsInputColNames(inputsDates.map((date) => date.title));

    metrics.forEach((_, metricIndex) => {
      const metricInputs = [];
      const valuesByDate = new Map<string, IKPIDataValue[]>(
        (getValues(`${sectionFieldRef}.${metricIndex}`) ?? []).map((value: IKPIDataValue) => [
          value.date,
          value,
        ])
      );
      for (let inputPos = 0; inputPos < inputsDates.length; inputPos++) {
        metricInputs.push({
          numberFormatValue: '',
          ...(valuesByDate.get(inputsDates[inputPos].date) ?? {}),
          date: inputsDates[inputPos].date,
        });
      }

      setValue(`${sectionFieldRef}.${metricIndex}`, metricInputs);
    });
  }, [
    getValues,
    kpiRequestFYE,
    kpiRequestFrequency,
    kpiRequestPeriodDate,
    kpiSection,
    metrics,
    sectionFieldRef,
    kpiRequestGranularity,
    setValue,
  ]);

  useEffect(() => {
    // Clear previous errors from kpiData.values
    clearErrors('kpiData.values');
    applyValuesToMetricInputs();
  }, [clearErrors, applyValuesToMetricInputs]);

  return {
    metricsInputColNames,
  };
};
