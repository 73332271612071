import { useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import { rubricConfigGridSchema } from '../../../schemas/Rubric.schema';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import { RubricConfigMenu } from './RubricConfigMenu';

export function useRubricConfigColumnDefs() {
  const gridSchema = useMemo(() => {
    return rubricConfigGridSchema().omit(['description']);
  }, []);
  const schemaToColDefs = useSchemaToGrid();
  return useMemo(() => {
    return schemaToColDefs(gridSchema).concat({
      headerName: '',
      cellRenderer: RubricConfigMenu,
      maxWidth: 70,
      suppressMenu: true,
    });
  }, [gridSchema, schemaToColDefs]);
}

export function defaultRubricConfigColDefs(): ColDef {
  return {
    flex: 1,
  };
}

export function useRubricConfigExportParams() {
  return useExtendedExportSettings(new Set(['createdBy']), 'Rubrics-Config');
}
