import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentScenarioState } from '../../../state/ScenariosState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { companyCaptablesByIdState } from '../../../CapTable/CapTableDataState';
import { getCaptableById } from '../../../../../services/queries/MaggieCapTableQueries';

export function useScenarioCaptableData() {
  const currentScenario = useRecoilValue(currentScenarioState);
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captablesById = useRecoilValue(companyCaptablesByIdState(companyId));
  const [captable, setCaptable] = useState(() => captablesById.get(currentScenario?.captableId ?? 0) ?? null);
  const [loading, setLoading] = useState(!captable);

  useEffect(() => {
    if (captable || !currentScenario) return;
    getCaptableById(currentScenario.captableId!)
      .then((ct) => {
        setCaptable(ct ?? null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [captable, currentScenario]);

  return { captable, setCaptable, loading };
}
