import { useMemo } from 'react';
import { object } from 'yup';
import { ControllerRenderProps } from 'react-hook-form';
import { noop } from 'lodash-es';
import { useTemplateIdField } from '../../../../../../schemas/template-assignment-schema';
import { schemaToFormFields } from '../../../../../../util/schema-utils';
import { FieldFactory } from '../../../../../../components/Form/FieldFactory';
import { IFormField, IFormFieldSelectMeta } from '../../../../../../view-models/form.view-model';
import { RendererType } from '../../../../../../data-models/field.data-model';

export function KPIRequestsTemplatesFilter({
  onChange,
  value,
}: Pick<ControllerRenderProps, 'onChange' | 'value'>) {
  const templateField = useTemplateIdField('templateId');
  const formField: IFormField<IFormFieldSelectMeta<string>> = useMemo(() => {
    const field = schemaToFormFields(object().shape({ templateId: templateField })).at(0);
    return {
      ...field,
      placeholder: 'Filter by template',
      label: '',
      key: 'templateId',
      renderer: RendererType.multiSelect,
      rendererMeta: {
        ...(field?.rendererMeta ?? {}),
        multi: true,
      },
    } as IFormField<IFormFieldSelectMeta<string>>;
  }, [templateField]);

  const formProps = useMemo(() => {
    return {
      name: 'frequency',
      onChange,
      value,
      onBlur: noop,
      ref: noop,
    };
  }, [onChange, value]);
  return <FieldFactory formField={formField} formProps={formProps} />;
}
