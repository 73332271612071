import { Typography, Link, useTheme } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Check } from '@mui/icons-material';
import { Circle } from '../../../../DealFlow2/components/DealModal/DealAddModalContent/Circle';
import { CenterAlignedCell } from '../Objectives/ObjectiveComponent';
import { Status } from '../../../../../data-models/objective.data-model';
import { StatusComponent } from '../StatusComponent/StatusComponent';
import { formatPercent2dpAsIs, formatUSDShort } from '../../../../../util/formatters/NumericFormatters';
import { KeyResultValues } from '../../../hooks/useObjectiveActions';
import { ClosedSubFormContainer, ClosedFieldContainer } from './StyledFormComponents';

const DataContainer = styled('div')`
  display: grid;
  grid-template-columns: 30% 25% 17% 18% 10%;
  border-radius: 4px;
`;

export interface IKeyResultsData extends KeyResultValues {
  onEdit?: () => void;
}

interface IKeyResultsDataProps {
  keyResultsData: IKeyResultsData[];
}

export const KeyResultsData: FC<IKeyResultsDataProps> = ({ keyResultsData }) => {
  const { colors } = useTheme();

  return (
    <ClosedSubFormContainer>
      <Typography component='h6' variant='h6' sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Circle isChecked={true} style={{ background: colors.success[20] }}>
          <Check htmlColor={colors.success[60]} />
        </Circle>
        Key Results
      </Typography>

      {keyResultsData.map(({ name, status, displayType, value, goal, onEdit }, i) => {
        let valueField = '-';
        if (value != null) {
          if (displayType === 'percentage') {
            valueField = formatPercent2dpAsIs(value);
          } else if (displayType === 'value') {
            valueField = formatUSDShort(value);
          } else {
            valueField = String(value);
          }
        }
        return (
          <ClosedFieldContainer key={i}>
            <DataContainer>
              <CenterAlignedCell>
                <Typography variant='body2'>{name}</Typography>
              </CenterAlignedCell>
              <CenterAlignedCell>
                <StatusComponent status={status as Status} />
              </CenterAlignedCell>
              <CenterAlignedCell>
                <Typography variant='body2'>{valueField}</Typography>
              </CenterAlignedCell>
              <CenterAlignedCell>
                <Typography variant='body2'>
                  {displayType === 'percentage' ? formatPercent2dpAsIs(goal) : formatUSDShort(goal)}
                </Typography>
              </CenterAlignedCell>
              <CenterAlignedCell>
                <Link
                  component='button'
                  type='button'
                  onClick={onEdit ?? undefined}
                  underline={'none'}
                  color={!onEdit ? colors.neutral[40] : undefined}
                  disabled={!onEdit}
                >
                  Edit
                </Link>
              </CenterAlignedCell>
            </DataContainer>
          </ClosedFieldContainer>
        );
      })}
    </ClosedSubFormContainer>
  );
};
