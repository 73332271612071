import { useRecoilCallback } from 'recoil';
import { endOfDay } from 'date-fns';
import { getISODate, getFundMetrics } from '../../../services/queries/MaggieMetricsQueries';
import { forceFundMetricsUpdate } from '../../PortfolioOverview/state/MetricsState';
import { selectedDateFPState, selectedFundMetricsStateFP } from '../state/FPState';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../types';

export function useInvalidateFundMetrics() {
  const { actions } = useLoadingBarState();

  const invalidateFundMetrics = useRecoilCallback(
    ({ set, snapshot }) =>
      async (date: string, fundId: number) => {
        try {
          actions.startLoading(LoadingId.updateFundMetrics);
          const selectedDate = snapshot.getLoadable(selectedDateFPState).valueOrThrow();
          const selectedDateIso = getISODate(selectedDate);
          const displayedFundMetrics = await getFundMetrics(selectedDateIso, [fundId]);
          set(selectedFundMetricsStateFP({ date: selectedDate, fundId: fundId }), displayedFundMetrics);

          if (selectedDateIso !== date) {
            getFundMetrics(date, [fundId]).then((fundMetrics) => {
              set(
                selectedFundMetricsStateFP({ date: endOfDay(new Date(date)), fundId: fundId }),
                fundMetrics
              );
            });
          }
          set(forceFundMetricsUpdate, (curr) => curr + 1);
        } catch (err) {
          console.error(err);
        } finally {
          actions.stopLoading(LoadingId.updateFundMetrics);
        }
      },
    []
  );
  return invalidateFundMetrics;
}
