import { atom, selectorFamily } from 'recoil';
import {
  createFundingDataModel,
  ICompanyFundingsDataModel,
  IFundingDataModel,
  Investor,
} from '../../../data-models/company-fundings.data-model';
import { fetchCompanyFundings } from '../../../services/queries/MaggieCompanyQueries';
import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { fundsByDateDescending } from '../Summary/components/FinancingHistory/FinancingHistoryTable';
import { roundsByCompanyIdState } from '../../Finance2/state/FinanceState';
import { roundsMapState } from '../../../services/state/AppConfigState';

export const companyFundingsState = atom<Map<number, ICompanyFundingsDataModel | null>>({
  key: 'companyFundingState',
  default: new Map<number, ICompanyFundingsDataModel | null>(),
});

export const CompanyFundingState = selectorFamily<ICompanyFundingsDataModel | null, number>({
  key: 'companyFunding',
  get:
    (companyId: number) =>
    async ({ get }) => {
      const companyFundings = get(companyFundingsState);

      try {
        return await getOrFetchCompanyFunding(companyFundings, companyId);
      } catch (err) {
        return null;
      }
    },
});

type TFinanceData = Partial<Pick<IInvestmentDataModel, 'fmv' | 'moic' | 'xirr'>> & IFundingDataModel;

// FIXME: Remove when MAGGIE-3559 data hierarchy work is completed.
type ICompanyFinancingHistoryStateKey = { companyId: number; isPortfolioCompany: boolean };
export const CompanyFinancingHistoryState = selectorFamily<TFinanceData[], ICompanyFinancingHistoryStateKey>({
  key: 'companyFunding',
  get:
    ({ companyId, isPortfolioCompany }: ICompanyFinancingHistoryStateKey) =>
    async ({ get }) => {
      if (isPortfolioCompany) {
        const data = get(roundsByCompanyIdState(companyId));
        const roundsById = get(roundsMapState);

        return (
          data?.map((r) => {
            const { name, roundDate, raiseAmount, postMoneyValuation, leadInvestors, coInvestors, roundId } =
              r;
            return {
              ...createFundingDataModel({
                fundingRound: name ?? '',
                fundingRoundCategory: roundsById.get(roundId ?? -1)?.name ?? '',
                dealDate: roundDate,
                dealSizeInMillions: typeof raiseAmount === 'number' ? raiseAmount / 1_000_000 : undefined,
                valuationInMillions:
                  typeof postMoneyValuation === 'number'
                    ? postMoneyValuation / 1_000_000
                    : postMoneyValuation,
                leadInvestors: leadInvestors?.join(', ') ?? '',
                investors: coInvestors?.map((coInvestor) => {
                  return {
                    orgId: -1,
                    orgName: coInvestor,
                  } as Investor;
                }),
              }),
            };
          }) ?? []
        );
      }

      const data = get(CompanyFundingState(companyId));
      return data?.fundings ?? [];
    },
});

async function getOrFetchCompanyFunding(
  companyFunding: Map<number, ICompanyFundingsDataModel | null>,
  companyId: number
): Promise<ICompanyFundingsDataModel | null> {
  if (companyFunding.get(companyId) === undefined) {
    const fundingData = await fetchCompanyFundings(companyId);
    companyFunding.set(companyId, fundingData);

    return fundingData;
  }

  return companyFunding.get(companyId) ?? null;
}

export const latestFundingsByCompanyState = selectorFamily<TFinanceData | null, number>({
  key: 'latestFundingsByCompanyState',
  get:
    (companyId: number) =>
    async ({ get }) => {
      const company = get(companyState(companyId));
      const fundings = get(
        CompanyFinancingHistoryState({ companyId, isPortfolioCompany: company?.companyType === 'portfolio' })
      );
      return (
        [...fundings].sort(fundsByDateDescending).find((funding) => funding.valuationInMillions != null) ??
        null
      );
    },
});
