import { styled } from '@mui/material/styles';

const Centered = styled('span')`
  margin: 0 auto;
  text-align: center;
`;

type SymbolRendererProps = {
  value: string | null | undefined;
};

export const SymbolRenderer = ({ value }: SymbolRendererProps) => {
  return value ? <Centered className='monospace'>{value}</Centered> : null;
};
