import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { editInvestmentSchema } from '../Transactions/AssignToRound/investmentSchemas';
import { IFinanceRoundFormViewModel } from '../../../view-models/finance-round-form.view-model';
import { InvestmentCreateForm } from './InvestmentSelectOrCreateForm';
import { StepperFormButtons } from './StepperFormButtons';
import { useAddInvestmentFormState } from './useAddInvestmentFormState';

interface INewInvestmentFormStepProps {
  defaultValues: Partial<IFinanceRoundDataModel>;
  onSubmit: (data: IFinanceRoundFormViewModel) => Promise<IFinanceRoundDataModel | void>;
  cacheFormData?: (data: Partial<IFinanceRoundDataModel>) => void;
}

export const NewInvestmentFormStep: FC<INewInvestmentFormStepProps> = ({
  defaultValues,
  onSubmit,
  cacheFormData,
}) => {
  const methods = useForm<IFinanceRoundFormViewModel>({
    defaultValues,
    resolver: yupResolver(editInvestmentSchema),
  });

  const { handleGoBack } = useAddInvestmentFormState();

  const { getValues } = methods;
  const handleBack = useCallback(() => {
    cacheFormData?.(getValues());
    handleGoBack();
  }, [cacheFormData, handleGoBack, getValues]);

  const _onSubmit = useCallback(async () => {
    const data = await methods.handleSubmit(onSubmit)();
    return Boolean(data);
  }, [methods, onSubmit]);

  return (
    <FormProvider {...methods}>
      <StepperFormFieldsContainer>
        <InvestmentCreateForm />
      </StepperFormFieldsContainer>
      <StepperFormButtons stepIsValid={_onSubmit} nextButtonLabel={'Create'} handleGoBack={handleBack} />
    </FormProvider>
  );
};
