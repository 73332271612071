import { Autocomplete, TextField } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { sectorsState } from '../../../services/state/AppConfigState';
import { commonMultiselectProps } from './commonMultiselectProps';

export const SectorFilter = ({
  selectedSectors,
  onChange,
}: {
  selectedSectors: number[];
  onChange: (e: SyntheticEvent, options: Option[] | undefined) => void;
}) => {
  const allSectors = useRecoilValue(sectorsState);

  const sectorOptions: Option[] = useMemo(() => {
    return !allSectors ? [] : allSectors?.map((s) => ({ id: s.id, value: s.name }));
  }, [allSectors]);

  const sectorFilterValue = useMemo(() => {
    if (selectedSectors?.length === allSectors.length) return [];
    return sectorOptions.filter((opt) => selectedSectors?.includes(opt.id as number));
  }, [allSectors.length, sectorOptions, selectedSectors]);

  if (!allSectors) return null;

  return (
    <Autocomplete
      multiple
      options={sectorOptions}
      getOptionLabel={(option) => option.value}
      value={sectorFilterValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={sectorFilterValue?.length ? '' : 'Filter by Sector'}
          aria-label='no-label'
          sx={{
            '& .MuiInputBase-root.Mui-focused': {
              background: 'white',
            },
          }}
        />
      )}
      disablePortal
      {...commonMultiselectProps}
    />
  );
};
