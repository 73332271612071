import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactNode, useCallback, useMemo } from 'react';
import { SerializedEditorState } from 'lexical';
import { styled } from '@mui/material';
import { commentariesByCompanyIdState } from '../../../../../services/state/CommentariesState';
import { plainDateToZonedDateTime } from '../../../../../util/date-utilts';
import { formatMonthYearLong } from '../../../../../util/formatters/DateFormatters';
import { NotesForMonth } from '../Notes/components/NotesForMonth';
import { selectedCommentaryState, selectedNoteState } from '../../../state/UIState';
import { notesState } from '../../../../../services/state/NoteState';
import {
  ActivityType,
  createActivityListItem,
  IActivityListItem,
} from '../../../../../view-models/activity-list-item';
import { INoteDataModel } from '../../../../../data-models/note.data-model';
import { HelpMessage } from '../../Messages/HelpMessage';
import { useDeleteNote } from '../../../../../services/hooks/useNoteActions';
import { useDeleteCommentary } from './useCommentaryActions';

const NoDataWrapper = styled('div')`
  margin: 1rem;
`;

export function ActivityItemsList({ companyId }: { companyId: number }) {
  const setSelectedNote = useSetRecoilState(selectedNoteState);
  const setSelectedCommentary = useSetRecoilState(selectedCommentaryState);
  const { items, itemsAsActivities } = useGetAllActivityItems(companyId);
  const deleteCommentary = useDeleteCommentary();
  const deleteNote = useDeleteNote();

  const onSelectItem = useCallback(
    (activity: IActivityListItem) => {
      if (activity.type === 'boardNote') {
        const selectedItem = items.boardNote.find((item) => item.id === activity.id);
        setSelectedNote(selectedItem! as INoteDataModel<SerializedEditorState>);
      }
      if (activity.type === 'commentary') {
        const selectedItem = items.commentary.find((item) => item.id === activity.id);
        setSelectedCommentary(selectedItem);
      }
    },
    [items.boardNote, items.commentary, setSelectedCommentary, setSelectedNote]
  );

  const onDeleteItem = useCallback(
    (activity: IActivityListItem) => {
      if (activity.type === 'boardNote') {
        const selectedItem = items.boardNote.find((item) => item.id === activity.id);
        deleteNote(companyId, selectedItem!.id);
      }
      if (activity.type === 'commentary') {
        const selectedItem = items.commentary.find((item) => item.id === activity.id);
        deleteCommentary(companyId, selectedItem!.id);
      }
    },
    [items.boardNote, items.commentary, deleteNote, companyId, deleteCommentary]
  );

  const commentariesByMonthComps = useMemo(() => {
    const res: ReactNode[] = [];
    const itemsByMonth = groupByMonth(itemsAsActivities, 'createdAt');

    itemsByMonth.forEach((items, label) => {
      res.push(
        <NotesForMonth
          key={label}
          notes={items}
          monthLabel={label}
          onSelectNote={onSelectItem}
          onDelete={onDeleteItem}
        />
      );
    });

    return res;
  }, [itemsAsActivities, onDeleteItem, onSelectItem]);

  return commentariesByMonthComps.length === 0 ? (
    <NoDataWrapper>
      <HelpMessage message={'No notes or commentaries yet!'} severity={'info'} />
    </NoDataWrapper>
  ) : (
    commentariesByMonthComps
  );
}

function useGetAllActivityItems(companyId: number) {
  const notesForCompany = useRecoilValue(notesState(companyId));
  const commentariesForCompany = useRecoilValue(commentariesByCompanyIdState(companyId));

  const notesItems = notesForCompany.map((note) =>
    createActivityListItem({
      createdAt: note.createdAt,
      createdBy: note.createdBy ?? '',
      id: note.id,
      title: note.title,
      type: ActivityType.boardNote,
    })
  );

  const commentaryItems = commentariesForCompany.map((commentary) =>
    createActivityListItem({
      createdAt: plainDateToZonedDateTime(commentary.date).toISOString(),
      createdBy: commentary.createdBy ?? '',
      id: commentary.id,
      title: 'Performance Commentary',
      type: ActivityType.commentary,
    })
  );

  const sortedActivities = [...notesItems, ...commentaryItems].sort((a, b) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  });

  return {
    itemsAsActivities: sortedActivities,
    items: {
      [ActivityType.commentary]: commentariesForCompany,
      [ActivityType.boardNote]: notesForCompany,
    },
  };
}

function groupByMonth<T>(notes: T[], dateStringField: keyof T) {
  return notes.reduce((res, note) => {
    const noteMonth = formatMonthYearLong(note[dateStringField] as string);
    if (!res.has(noteMonth)) {
      res.set(noteMonth, []);
    }
    res.get(noteMonth)!.push(note);

    return res;
  }, new Map<string, T[]>());
}
