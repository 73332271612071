import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Tooltip, Typography, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { selectedCompanyIdProfile } from '../state/UIState';
import { companyState } from '../../../services/state/CompanyState';

import { clientInvestmentsState } from './CapTableFormState';
import { IInvestmentGridRow, useInvestmentGridRowData } from './InvestmentGridDefs';
import { AddInvestmentsGrid } from './AddInvestmentsGrid';
import { useGetMyInvestmentsColDefs } from './useGetMyInvestmentsColDefs';

const ClientInvestmentHelperText = () => {
  const { colors } = useTheme();
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  return (
    <Typography variant='subtitle1'>
      {`Please add your fund and shares purchased details, if any, for ${company?.name}.`}
      <Tooltip title='Notes and Options will be handled in a different step.'>
        <InfoOutlinedIcon htmlColor={colors.neutral[40]} fontSize='small' />
      </Tooltip>
    </Typography>
  );
};

export function AddYourInvestments() {
  const setClientInvestments = useSetRecoilState(clientInvestmentsState);

  const { getInitialRowData } = useInvestmentGridRowData();
  const initialRowData = useMemo(() => getInitialRowData(), [getInitialRowData]);
  const getColDefs = useGetMyInvestmentsColDefs();
  const colDefs = useMemo(() => getColDefs(), [getColDefs]);

  const setStateToRows = useCallback(
    (rows: IInvestmentGridRow[]) => {
      setClientInvestments(rows);
    },
    [setClientInvestments]
  );

  return (
    <AddInvestmentsGrid
      initialRowData={initialRowData}
      colDefs={colDefs}
      setStateToRows={setStateToRows}
      HelpInfo={ClientInvestmentHelperText}
    />
  );
}
