import { compareAsc } from 'date-fns';
import {
  IScenarioDataModel,
  IScenarioTransactionDataModel,
  ScenarioTransactionType,
} from '../../../../../data-models/scenario.data-model';
import {
  createInitialExitValues,
  createInitialRoundValues,
  createInitialPartialExitValues,
} from './scenarioInitialValues';

export function prepareScenarioPayload(formData: Partial<IScenarioDataModel>): Partial<IScenarioDataModel> {
  const { scenarioTransactions: formTransactions, ...scenario } = formData;

  const transactions = formTransactions
    ?.map((t) => {
      const res = { ...t };
      // reset values that don't apply to the transaction type
      let transactionKeys;
      if (t.type === ScenarioTransactionType.round) {
        transactionKeys = Object.keys(createInitialRoundValues());
      } else if (t.type === ScenarioTransactionType.exit) {
        transactionKeys = Object.keys(createInitialExitValues());
      } else {
        transactionKeys = Object.keys(createInitialPartialExitValues());
      }
      const keySet = new Set(transactionKeys);
      Object.keys(t).forEach((key) => {
        if (!keySet.has(key)) {
          delete res[key as keyof IScenarioTransactionDataModel];
        }
      });
      return res;
    })
    .sort(compareScenarioTransactions);

  const tags = scenario.tags ?? [];

  return { ...scenario, scenarioTransactions: transactions, tags };
}

export function compareScenarioTransactions(
  a: Partial<IScenarioTransactionDataModel>,
  b: Partial<IScenarioTransactionDataModel>
) {
  const aDate =
    a.type === ScenarioTransactionType.exit || a.type === ScenarioTransactionType.partialExit
      ? a.exitDate
      : a.investmentDate;
  const bDate =
    b.type === ScenarioTransactionType.exit || b.type === ScenarioTransactionType.partialExit
      ? b.exitDate
      : b.investmentDate;

  if (!aDate && !bDate) return 0;
  if (!aDate) return 1;
  if (!bDate) return -1;

  return compareAsc(new Date(aDate), new Date(bDate));
}

export function shouldDisableExit(
  transactions: Partial<IScenarioTransactionDataModel>[],
  currentIndex: number
) {
  return transactions.some(
    (t, i) =>
      i !== currentIndex &&
      (t.type === ScenarioTransactionType.exit || t.type === ScenarioTransactionType.partialExit)
  );
}

export function shouldDisablePartialExit(
  transactions: Partial<IScenarioTransactionDataModel>[],
  currentIndex: number
) {
  return transactions.some(
    (t, i) =>
      i !== currentIndex &&
      (t.type === ScenarioTransactionType.exit || t.type === ScenarioTransactionType.partialExit)
  );
}

export function shouldDisableAddTransaction(transactions?: Partial<IScenarioTransactionDataModel>[]) {
  return (
    transactions?.some(
      (t) => t?.type === ScenarioTransactionType.exit || t?.type === ScenarioTransactionType.partialExit
    ) ?? false
  );
}
