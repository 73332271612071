import { LoadingButton } from '@mui/lab';
import { Button, Icon } from '@mui/material';

export interface FooterButtonsProps {
  onCancel?: () => void;
  onConfirm?: () => void;
  onConfirmDisabled?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  loading?: boolean;
}

export const FooterButtons = ({
  onConfirm,
  onCancel,
  loading = false,
  onConfirmDisabled = false,
  confirmButtonText,
  cancelButtonText,
}: FooterButtonsProps) => {
  return (
    <>
      <LoadingButton
        disabled={onConfirmDisabled || loading}
        variant='contained'
        color='secondary'
        onClick={onConfirm}
        size='medium'
        loading={loading}
        loadingPosition='start'
        startIcon={loading ? <Icon /> : <></>}
      >
        {confirmButtonText ?? 'Confirm'}
      </LoadingButton>
      <Button variant='outlined' color='secondary' onClick={onCancel} size='medium'>
        {cancelButtonText ?? 'Cancel'}
      </Button>
    </>
  );
};
