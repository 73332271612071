import { styled } from '@mui/material/styles';
import { useState } from 'react';
import TextField from '@mui/material/TextField';

interface ICommentCreationProps {
  onCreate: (comment: string) => void;
  isLoading?: boolean;
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export function CommentCreation({ onCreate, isLoading }: ICommentCreationProps) {
  const [comment, setComment] = useState('');

  const handleCreate = () => {
    setComment('');
    onCreate(comment);
  };

  return (
    <Wrapper>
      <TextField
        id='outlined-basic'
        variant='outlined'
        placeholder={'Add comment'}
        disabled={isLoading}
        maxRows={2}
        fullWidth
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (isLoading) return;
          if (e.key === 'Enter') {
            e.preventDefault();
            handleCreate();
          }
        }}
      />
    </Wrapper>
  );
}
