import { styled } from '@mui/material/styles';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface FormFieldProps {
  additionalLabel?: string;
  alignItems?: string;
  children: ReactNode;
  description?: string;
  error?: string;
  errorMessageStyle?: CSSProperties;
  label: string | ReactNode;
  labelWidth?: string;
  required?: boolean;
  style?: CSSProperties;
}

const FieldWrapper = styled('div')<{ labelWidth?: string; alignItems?: string }>`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  justify-content: flex-start;

  & > * {
    &:first-child {
      width: ${({ labelWidth }) => (labelWidth ? labelWidth : '17rem')};
      padding-right: 15px;
    }

    &:last-child {
      width: 100%;
    }
  }
`;

const ChildrenWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const RequiredMark = styled('span')`
  color: ${({ theme }) => theme.colors.critical[50]};
  margin-left: 4px;
`;

export function FormField({
  additionalLabel,
  alignItems,
  children,
  description,
  error,
  label,
  labelWidth,
  required = false,
  style,
  errorMessageStyle,
}: FormFieldProps) {
  const { primary, critical, neutral } = useTheme().colors;

  return (
    <FieldWrapper data-testid={'form-field'} labelWidth={labelWidth} alignItems={alignItems} style={style}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'min-content min-content',
          columnGap: '.25rem',
        }}
      >
        <Typography component={'label'} variant={'caption'} color={primary[100]} className='Label'>
          {label}
          {required && <RequiredMark data-testid='required-signifier'>*</RequiredMark>}
        </Typography>
        {description && (
          <Tooltip
            title={
              <Typography variant='caption' color='text.secondary'>
                {description}
              </Typography>
            }
            placement='bottom-start'
            aria-label={description}
          >
            <InfoOutlinedIcon fontSize='small' htmlColor={neutral[60]} />
          </Tooltip>
        )}
      </Box>
      {additionalLabel ? (
        <InputWrapper>
          <ChildrenWrapper>
            {children}
            {error && (
              <Typography variant={'caption'} color={critical[40]}>
                {error}
              </Typography>
            )}
          </ChildrenWrapper>
          <Typography variant={'caption'} color={primary[100]}>
            {additionalLabel}
          </Typography>
        </InputWrapper>
      ) : (
        <ChildrenWrapper style={{ position: 'relative' }}>
          {children}
          {error && (
            <Typography variant={'caption'} color={critical[40]} style={errorMessageStyle}>
              {error}
            </Typography>
          )}
        </ChildrenWrapper>
      )}
    </FieldWrapper>
  );
}
