import { atomFamily, selectorFamily } from 'recoil';
import { getCompanySocials } from '../../../services/queries/MaggieCompanyQueries';
import { CompanySocialMedia } from '../../../schemas/CompanySocialMedia.schema';

export const companySocialsState = atomFamily<CompanySocialMedia, number>({
  key: 'companySocialsState',
  default: selectorFamily({
    key: 'companySocialsStateDefault',
    get: (companyId: number) => async () => {
      return getCompanySocials(companyId);
    },
  }),
});
