import { ButtonProps, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FC, MouseEventHandler, CSSProperties } from 'react';

export const AddButton: FC<Omit<ButtonProps, 'startIcon' | 'color'>> = ({ children, ...props }) => {
  return (
    <Button color='secondary' startIcon={<AddIcon />} {...props}>
      {children}
    </Button>
  );
};

export const RemoveButton: FC<{
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: CSSProperties;
}> = ({ style, onClick, disabled }) => {
  return (
    <IconButton style={{ ...style }} onClick={onClick} disabled={disabled}>
      <CloseIcon />
    </IconButton>
  );
};
