import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Stack, Typography } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { useCaptableActions, useMakeOtherPrimaryAndDelete } from '../CapTableActions';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { captablesByCompanyState, selectedCaptableState } from '../CapTableDataState';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { companyState } from '../../../../services/state/CompanyState';
import { FieldSelect } from '../../../../components/Form/Display/FieldSelect';
import { useCaptableSelectorField } from './CaptableSelector';

interface IDeleteCaptableDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function DeleteCaptableDialog({ open, setOpen }: IDeleteCaptableDialogProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const currentCaptable = useRecoilValue(selectedCaptableState(companyId));
  const allCaptables = useRecoilValue(captablesByCompanyState(companyId));

  if (!currentCaptable || !open || allCaptables.length === 0) {
    return null;
  }

  if (allCaptables.length === 1 || !currentCaptable.primary) {
    return <ConfirmDelete open={open} setOpen={setOpen} />;
  }
  return <ConfirmDeleteAndSetNewPrimary open={open} setOpen={setOpen} />;
}

export function ConfirmDelete({ open, setOpen }: IDeleteCaptableDialogProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const currentCaptable = useRecoilValue(selectedCaptableState(companyId));

  const { deleteCaptableHandler } = useCaptableActions();

  const [loading, setLoading] = useState(false);

  const onConfirmDelete = useCallback(async () => {
    if (!currentCaptable) return;
    setLoading(true);
    await deleteCaptableHandler(currentCaptable);
    setLoading(false);
    setOpen(false);
  }, [currentCaptable, deleteCaptableHandler, setOpen]);

  return (
    <ConfirmDeletionDialog
      open={open}
      onClose={() => setOpen(false)}
      onConfirm={onConfirmDelete}
      title={'Are you sure you want to delete this cap table?'}
      loading={loading}
    >
      <Typography variant='body2' color='text.secondary'>
        All related data will be deleted permanently.
      </Typography>
      {currentCaptable?.primary && (
        <Typography variant='body2' color='text.secondary'>
          {`Deleting this primary cap table will leave company ${company?.name} with no cap table.`}
        </Typography>
      )}
    </ConfirmDeletionDialog>
  );
}

export function ConfirmDeleteAndSetNewPrimary({ open, setOpen }: IDeleteCaptableDialogProps) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const currentCaptable = useRecoilValue(selectedCaptableState(companyId));
  const allCaptables = useRecoilValue(captablesByCompanyState(companyId));
  const captableOptions = useMemo(
    () => allCaptables.filter((c) => c.id !== currentCaptable?.id),
    [allCaptables, currentCaptable]
  );
  const [loading, setLoading] = useState(false);
  const [newPrimaryId, setNewPrimaryId] = useState<number>(() => captableOptions[0]?.id);

  const captableField = useCaptableSelectorField();
  const field = useMemo(() => {
    return {
      ...captableField,
      rendererMeta: {
        ...captableField.rendererMeta,
        values: captableField.rendererMeta?.values?.filter((v) => v.value !== currentCaptable?.id) ?? [],
      },
    };
  }, [captableField, currentCaptable?.id]);

  const makeOtherPrimaryAndDelete = useMakeOtherPrimaryAndDelete();

  const onMakeOtherPrimaryAndDelete = useCallback(async () => {
    if (!currentCaptable || !newPrimaryId) return;
    setLoading(true);
    await makeOtherPrimaryAndDelete(newPrimaryId, currentCaptable.id);
    setLoading(false);
    setOpen(false);
  }, [currentCaptable, makeOtherPrimaryAndDelete, newPrimaryId, setOpen]);

  return (
    <ConfirmDeletionDialog
      open={open}
      onClose={() => setOpen(false)}
      onConfirm={onMakeOtherPrimaryAndDelete}
      title={'Are you sure you want to delete this cap table?'}
      loading={loading}
    >
      <Typography variant='body2' color='text.secondary' mt='1rem' component={'div'}>
        <Stack spacing={3}>
          <p>All related data will be deleted permanently.</p>
          <p>Please select new Primary cap table before proceeding</p>
        </Stack>
      </Typography>

      <FieldSelect
        formField={field}
        formProps={{ onChange: (val) => setNewPrimaryId(val), value: newPrimaryId } as ControllerRenderProps}
      />
    </ConfirmDeletionDialog>
  );
}
