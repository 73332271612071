import { useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { useSaveCompanyData } from '../../../services/hooks/useSaveCompanyData';
import { fetchListItems } from '../../../services/queries/MaggieListQueries';
import { companyListState } from '../state/ListsState';

const fetched = new Map<number, { error: boolean }>();

export function usePrepItemsData(listId: number | null) {
  const setCompanyList = useSetRecoilState(companyListState(listId));

  const { batchSaveCompanyData } = useSaveCompanyData();
  const [ready, setReady] = useState(false);
  const [error, setError] = useState('');

  const saveCompanyDataIfNeeded = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        if (!listId) return;

        const listData = snapshot.getInfo_UNSTABLE(companyListState(listId)).loadable?.valueMaybe();
        if (listData) {
          setReady(true);
          return;
        }

        if (fetched.has(listId) && !fetched.get(listId)?.error) {
          setReady(true);
          return;
        }

        try {
          const list = await fetchListItems(listId);
          fetched.set(listId, { error: false });
          setCompanyList(list);
          batchSaveCompanyData(list.companies);
          setReady(true);
        } catch (err) {
          console.error(err);
          fetched.set(listId, { error: true });
          setError("Couldn't fetch list items");
        }
      },
    [listId]
  );

  useEffect(() => {
    setReady(false);
    setError('');
  }, [listId]);

  useEffect(() => {
    saveCompanyDataIfNeeded();
  }, [ready, saveCompanyDataIfNeeded, listId]);

  return { ready, error };
}
