import { FC } from 'react';
import { stringify } from 'qs';
import { useRecoilValue } from 'recoil';
import { Breadcrumbs } from '../../../../components/Breadcrumbs/Breadcrumbs';
import { primaryCompanyIdCI } from '../../state/DashboardsState';
import { competitorsIdsCI } from '../../state/CompaniesState';

export const BreadCrumbs: FC = () => {
  const primaryCompanyId = useRecoilValue(primaryCompanyIdCI);
  const competitorsId = useRecoilValue(competitorsIdsCI);

  const primaryQuery = primaryCompanyId
    ? `&${stringify({
        primary: primaryCompanyId,
      })}`
    : '';

  const competitorsQuery = competitorsId ? `&competitors=${competitorsId.join(',')}` : '';

  const breadcrumbs = [
    {
      label: 'Select Analysis Type',
      url: `/company-comparison?analysisType=competitive_intelligences&step=1${primaryQuery}${competitorsQuery}`,
    },
    {
      label: 'Select Company',
      url: `/company-comparison?analysisType=competitive_intelligences&step=2${primaryQuery}${competitorsQuery}`,
    },
    {
      label: 'Competitive Intelligence',
    },
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
