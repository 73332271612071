import { useCallback } from 'react';
import { transactionDateField } from '../../../data-fields/TransactionFields';
import { amountField, booleanField, simpleMultilineTextField } from '../../../data-fields/CommonFields';

export function useGetStockIssuanceFields() {
  const transactionDate = transactionDateField();

  return useCallback(() => {
    return [
      transactionDate,
      amountField({ key: 'pricePerShare', label: 'Price Per Share', required: true }),
      booleanField({ key: 'valuationImpact', label: 'Valuation Impacting' }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [transactionDate]);
}
