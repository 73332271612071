import { ReactNode, memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, init as echartsInit, use as echartsUse } from 'echarts/core';
import { BarChart, LineChart as EChartsLine, LineSeriesOption } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { ChartTitleWrapper } from '../ChartTitleWrapper';
import { ChartWrapper } from '../ChartWrapper/ChartWrapper';
import { IconTypes } from '../../Icon';
import { getTextWidth } from '../../../util/getTextWidth';
import { generateChartColourScheme } from '../generateChartColourScheme';
import { Chart, ChartSizes } from '../chart-utils';
import { LineChartData } from '../../../types';
import { ItemsColourScheme } from '../../../util/ColourSchemeGenerator';
import { useIsInViewport } from '../../../hooks/useIsInViewport';

export type ECLineOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
>;

// Register the required components
echartsUse([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  EChartsLine,
  GridComponent,
  LabelLayout,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  UniversalTransition,
]);

const axisTickTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 11,
};

const legendTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 12,
};

const tooltipTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 14,
};

export interface LineChartProps {
  caption?: string;
  chartColors?: ItemsColourScheme<string>;
  data: LineChartData[];
  defaultSize?: string;
  gridOptions?: GridComponentOption;
  id: string;
  legendOptions?: LegendComponentOption;
  showLegend?: boolean;
  title: string;
  tooltipValueFormatter?: (val: number) => string;
  width?: number;
  yAxisLabelFormatter: (tick: string | number) => string;
  titleComponent?: ReactNode;
}

export const LineChart = memo(function LineChart({
  caption,
  chartColors,
  data,
  defaultSize = '1/4 Screen',
  gridOptions,
  id,
  legendOptions,
  showLegend = true,
  title,
  tooltipValueFormatter,
  width = 700,
  yAxisLabelFormatter,
  titleComponent,
}: LineChartProps) {
  const [widthState, setWidthState] = useState<string | number>(width);
  const [isHovering, setIsHovering] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [legendShouldScroll, setLegendShouldScroll] = useState(false);
  const [size, setSize] = useState(defaultSize);
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const lineChart = useRef<ECharts | null>(null);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.75,
    },
    chartContainer
  );

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setShowMore(false);
    setIsHovering(false);
  };

  const labels = useMemo(() => data.map((s) => s.id as string), [data]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!lineChart.current) {
      lineChart.current = echartsInit(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          const doLabelsFit = doLabelsFitInTwoRows(labels, entry.contentBoxSize[0].inlineSize);

          if (legendShouldScroll !== !doLabelsFit) {
            setLegendShouldScroll(!doLabelsFit);
          }
          lineChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer, labels, legendShouldScroll]);

  useEffect(() => {
    if (!(lineChart.current && isInViewport)) {
      return;
    }

    const defaultColours = generateChartColourScheme(data.length);
    const isAreaChart = data.length < 3;
    const series: LineSeriesOption[] = data
      .map((line, i) => {
        const areaConfig = isAreaChart
          ? {
              areaStyle: {
                opacity: 0.1,
              },
            }
          : {};

        return {
          data: line.data.map((point) => [point.x, point.y]),
          name: line.id,
          color: chartColors?.get(line.id) ?? defaultColours[i],
          showSymbol: false,
          type: 'line',
          ...areaConfig,
        } as LineSeriesOption;
      })
      .sort((l1, l2) => {
        if (l1.name! > l2.name!) return 1;
        if (l1.name! < l2.name!) return -1;
        return 0;
      });

    const option: ECLineOption = {
      grid: {
        containLabel: true,
        top: 10,
        bottom: !showLegend || legendShouldScroll ? 30 : 55,
        left: 5,
        right: 5,
        ...gridOptions,
      },
      legend: showLegend
        ? {
            type: legendShouldScroll ? 'scroll' : 'plain',
            bottom: 0,
            padding: 5,
            textStyle: legendTextStyle,
            ...legendOptions,
          }
        : undefined,
      series,
      tooltip: {
        trigger: 'axis',
        confine: true,
        backgroundColor: 'rgba(250,252,254,0.7)',
        order: 'seriesAsc',
        valueFormatter: (params) => {
          return (tooltipValueFormatter ?? yAxisLabelFormatter)(params as number);
        },
        textStyle: tooltipTextStyle,
      },
      xAxis: {
        ...axisTickTextStyle,
        type: 'category',
        axisLabel: {},
      },
      yAxis: {
        axisLabel: {
          ...axisTickTextStyle,
          formatter: yAxisLabelFormatter,
        },
        type: 'value',
      },
    };

    if (lineChart.current) {
      lineChart.current.setOption(option, true);
    }
  }, [
    labels,
    legendShouldScroll,
    data,
    yAxisLabelFormatter,
    chartColors,
    gridOptions,
    legendOptions,
    tooltipValueFormatter,
    isInViewport,
    showLegend,
  ]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      width={widthState}
      dataTestid={'line-chart'}
    >
      {titleComponent ? (
        titleComponent
      ) : (
        <ChartTitleWrapper
          isHovering={isHovering}
          title={title}
          showMore={showMore}
          setShowMore={setShowMore}
          id={id}
          caption={caption}
          icon={IconTypes.FINANCE}
          handleSizeChange={setSize}
          size={size}
          refProp={chartContainer}
        />
      )}
      <Chart width={'100%'} ref={chartContainer} />
    </ChartWrapper>
  );
});

function doLabelsFitInTwoRows(labels: string[], chartWidth: number) {
  const legendFont = `${legendTextStyle.fontSize}px ${legendTextStyle.fontFamily}`;

  for (let i = 0, currRowWidth = 0, rowCount = 1; i < labels.length; i++) {
    // We're using 35 for legendIcon width + padding + (labelAndLegend padding)
    const legendItemWidth = 35 + getTextWidth(labels[i], legendFont) + 10;

    if (currRowWidth + legendItemWidth > chartWidth) {
      currRowWidth = legendItemWidth;
      rowCount++;
    } else {
      currRowWidth += legendItemWidth;
    }

    if (rowCount > 2) {
      return false;
    }
  }

  return true;
}
