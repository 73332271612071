import * as yup from 'yup';
import { integerField, usdShortField } from './common-schema-defs';

export enum FundTransactionType {
  lpContributions = 'LP Contributions',
  lpDistributions = 'LP Distributions',
  gpContributions = 'GP Contributions',
  gpDistributions = 'GP Distributions',
}

export function fundTransactionsFields() {
  return {
    date: yup.string().required(), // 'YYYY-MM-DD'
    type: yup.string().required().oneOf(Object.values(FundTransactionType)),
    amount: usdShortField().required(),
  };
}

export function fundTransactionSchema() {
  return yup.object(fundTransactionsFields()).required();
}

export function createFundTransaction(overrides: Partial<FundTransaction> = {}): FundTransaction {
  return {
    date: '',
    type: FundTransactionType.lpContributions,
    amount: 0,
    ...overrides,
  };
}

export function fundContributionsResponseFields() {
  return {
    fundId: integerField().required(),
    transactions: yup.array().of(fundTransactionSchema()).required(),
  };
}

export function fundContributionsResponseSchema() {
  return yup.object(fundContributionsResponseFields()).required();
}

export type FundTransaction = yup.InferType<ReturnType<typeof fundTransactionSchema>>;
export type FundContributionsResponse = yup.InferType<ReturnType<typeof fundContributionsResponseSchema>>;
