import { ICellRendererParams } from 'ag-grid-community';
import { CompanyLinkRenderer } from './CompanyLinkRenderer';

/** ag-grid wrapper for CompanyLinkRenderer */

export const GridCompanyLinkRenderer = (params: ICellRendererParams) => {
  const { value } = params;
  const { website, domain } = value ?? {};
  if (!value) return null;
  return <CompanyLinkRenderer website={website} domain={domain} />;
};
