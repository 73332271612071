import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { DealBoardDealCategory } from '../../../types';
import { selectedDealCategoryState, visibleDealsState } from '../state/DealboardUIState';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { DealGrid } from './DealGrid/DealGrid';
import { GridHeader } from './DealGrid/GridHeader';

const Container = styled('section')`
  padding: 1rem 2rem 1rem 3rem;
`;

export const CategoryDealTable = () => {
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);
  const filteredDeals = useRecoilValue(visibleDealsState);

  let title = '';
  switch (selectedDealCategory.id) {
    case DealBoardDealCategory.PASS:
      title = 'Passed';
      break;
    case DealBoardDealCategory.TRACK:
      title = 'Tracked';
      break;
    case DealBoardDealCategory.CLOSED:
      title = 'Closed';
      break;
    case DealBoardDealCategory.MISSED:
      title = 'Missed';
  }

  return (
    // FIXME: delete type assertion when this is fixed in deal state
    <Container key={selectedDealCategory.id}>
      <GridHeader title={title} deals={filteredDeals as IDealDataModel[]} />
      <DealGrid deals={filteredDeals as IDealDataModel[]} />
    </Container>
  );
};
