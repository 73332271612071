import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { sectorsState } from '../../../../../../services/state/AppConfigState';
import {
  GroupByFilterState,
  dealLeadFilterState,
  dealTypeFilterStateDA,
  fromDateFilterStateDA,
  sectorFilterState,
  toDateFilterStateDA,
} from '../../../../state/DealConversionFilterState';

export const useFiltersParams = () => {
  const selectedToDate = useRecoilValue(toDateFilterStateDA);
  const selectedFromDate = useRecoilValue(fromDateFilterStateDA);
  const selectedDealType = useRecoilValue(dealTypeFilterStateDA);
  const selectedGroupBy = useRecoilValue(GroupByFilterState);

  const allSectors = useRecoilValue(sectorsState);
  const allSectorsIds = useMemo(
    () => allSectors.map((sector) => sector.id).sort((a, b) => a - b),
    [allSectors]
  );

  const selectedSectors = useRecoilValue(sectorFilterState);
  const selectedDealLead = useRecoilValue(dealLeadFilterState) ?? -1;

  const orderedSelectedSectors: number[] = useMemo(
    () => selectedSectors.concat().sort((a, b) => a - b),
    [selectedSectors]
  );

  const sectors = !orderedSelectedSectors.length ? allSectorsIds : orderedSelectedSectors;

  const queryParams = {
    from: selectedFromDate.toISOString(),
    to: selectedToDate.toISOString(),
    dealType: selectedDealType,
    dealLead: selectedDealLead,
    sectors,
  };

  const queryParamsWithGroupBy = {
    ...queryParams,
    groupBy: selectedGroupBy,
  };

  return {
    queryParams,
    queryParamsWithGroupBy,
    selectedDealType,
  };
};
