import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import {
  createOrUpdateRubric,
  getRubrics,
  ICreateOrUpdateRubricPayload,
} from '../../../../../services/queries/MaggieRubricQueries';
import {
  forceRubricRefreshState,
  mostRecentRubricsByCompanyIdState,
} from '../../../../../services/state/RubricsState';
import { EntityType } from '../../../../../data-models/field2.data-model';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';

export function useCreateOrEditRubric() {
  const { pushErrorToast } = useToastMessageState();
  const { actions } = useLoadingBarState();

  return useRecoilCallback(
    ({ set }) =>
      async (payload: ICreateOrUpdateRubricPayload) => {
        try {
          actions.startLoading(LoadingId.createOrEditRubric);
          const data = await createOrUpdateRubric(payload);
          const { entity, entityId, date } = payload;
          const rubrics = await getRubrics({ entity: entity as EntityType, entityId, date });
          set(mostRecentRubricsByCompanyIdState({ companyId: entityId, date }), rubrics);
          set(forceRubricRefreshState, (prev) => prev + 1);
          return data;
        } catch (err) {
          pushErrorToast({ message: getErrorMessage(err, 'Failed to create/update rubric') });
        } finally {
          actions.stopLoading(LoadingId.createOrEditRubric);
        }
      },
    []
  );
}
