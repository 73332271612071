import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ColDef,
  ExpandCollapseAllEvent,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowClickedEvent,
  RowGroupOpenedEvent,
} from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { commonProcessCb } from '../../../../components/AgTable/exportToExcelDefs';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { useCombineCompaniesWithData } from '../../../../hooks/useCombineCompaniesWithData';
import { roundsMapState, usersByEmailMapState } from '../../../../services/state/AppConfigState';
import { GRID_ROW_HEIGHT } from '../../../../theme/gridStyle';
import { DealBoardDealCategory } from '../../../../types';
import { collapsedDealStages } from '../../../PortfolioOverview/state/UIState';
import { IDealStageDataModel } from '../../data-models/dealStage.data-model';
import { getCollapsedStageIds } from '../../helpers/getCollapsedStageIds';
import { getLatestCommentString } from '../../helpers/getLatestCommentString';
import { dealStagesByIdMapState } from '../../state/DealboardDataState';
import {
  dealBoardSettingsState,
  isOpenDrawerState,
  selectedDealCategoryState,
  selectedDealState,
  selectedDealTypeState,
  showCommentsState,
} from '../../state/DealboardUIState';
import { stageComparator } from './Columns/stageComparator';
import { defaultColDef, useDealGridColDefs } from './Columns/useDealGridColDefs';
import { GridHeader } from './GridHeader';

const GridContainer = styled('section')`
  margin: 0;
  margin-bottom: 2rem;
  & .ag-root-wrapper {
    background-color: transparent;
  }
`;

const GridWrapper = styled('div')`
  padding-top: 2rem;
  height: 38rem;
  & .ag-cell {
    display: flex;
    align-items: center;
  }
  & .ag-cell-last-left-pinned {
    padding: 0;
  }
`;
interface DealGridProps {
  stage?: IDealStageDataModel;
  deals: IDealDataModel[];
}

export interface IDealGridData extends IDealDataModel {
  company: ICompanyDataModel;
}

export const DealGrid: FC<DealGridProps> = ({ stage, deals }) => {
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const setIsOpenDrawer = useSetRecoilState(isOpenDrawerState);
  const setSelectedDeal = useSetRecoilState(selectedDealState);
  const showComments = useRecoilValue(showCommentsState);
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);
  const stages = useRecoilValue(dealStagesByIdMapState);
  const dealBoardSettings = useRecoilValue(dealBoardSettingsState);
  const [collapsedStages, setCollapsedStages] = useRecoilState(collapsedDealStages);
  const columnDefs = useDealGridColDefs();
  const activeDealTypeId = useRecoilValue(selectedDealTypeState);
  const roundsMap = useRecoilValue(roundsMapState);
  const usersByEmail = useRecoilValue(usersByEmailMapState);
  const [initialCollapsedStage] = useState(collapsedStages);
  const getDealGridData = useCombineCompaniesWithData();
  const { colors } = useTheme();

  const [rowData, setRowData] = useState<IDealGridData[]>();

  useEffect(() => {
    getDealGridData(deals).then((data) => {
      setRowData(data as IDealGridData[]);
    });
  }, [deals, getDealGridData]);

  const handleToggleGrid = useCallback(
    (stageId: number, collapsed: boolean) => {
      const uniqueStageId = `${activeDealTypeId}-${stageId}`;
      setCollapsedStages((collapsedStages) => ({ ...collapsedStages, [uniqueStageId]: collapsed }));
    },
    [activeDealTypeId, setCollapsedStages]
  );

  const findStageByDisplayName = useCallback(
    (stageDisplayName: string) => {
      return Array.from(stages.values()).find((s) => s.displayName === stageDisplayName);
    },
    [stages]
  );

  const onRowGroupOpened = useCallback(
    (e: RowGroupOpenedEvent) => {
      const stageDisplayName = e.node.key;
      if (!stageDisplayName) return;
      const stageId = findStageByDisplayName(stageDisplayName)?.id;
      if (stageId) handleToggleGrid(stageId, !e.node.expanded);
    },
    [handleToggleGrid, findStageByDisplayName]
  );

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridAPI(event.api);
  }, []);

  const onFirstDataRendered = useCallback(
    (e: FirstDataRenderedEvent) => {
      const collapsedStageIds = getCollapsedStageIds(initialCollapsedStage, activeDealTypeId);

      e.api?.forEachNode((node) => {
        const stageDisplayName = node.key; // name of the stage

        if (!stageDisplayName) return;
        const stageId = findStageByDisplayName(stageDisplayName)?.id;
        if (!stageId) return;
        if (collapsedStageIds.includes(stageId)) {
          node.setExpanded(false);
        } else {
          node.setExpanded(true);
        }
      });
    },
    [activeDealTypeId, initialCollapsedStage, findStageByDisplayName]
  );

  const onExpandOrCollapseAll = useCallback(
    (e: ExpandCollapseAllEvent) => {
      const collapsed = e.source === 'collapseAll'; // e.source is either "expandAll" or "collapseAll"
      const stageIds = dealBoardSettings.map((stageAndDeals) => stageAndDeals.stage.id);
      stageIds.forEach((stageId) => {
        handleToggleGrid(stageId, collapsed);
      });
    },
    [dealBoardSettings, handleToggleGrid]
  );

  const getRowId: GetRowIdFunc = useCallback((params: GetRowIdParams) => {
    return params.data?.id;
  }, []);

  const onRowClicked = useCallback(
    (e: RowClickedEvent) => {
      if (e.node.group) return;
      setSelectedDeal(e.data);
      setIsOpenDrawer(true);
    },
    [setIsOpenDrawer, setSelectedDeal]
  );

  useEffect(() => {
    if (!gridAPI) return;
    if (showComments) {
      gridAPI.setColumnsVisible(['comments'], true);
    } else {
      gridAPI.setColumnsVisible(['comments'], false);
    }
  }, [gridAPI, showComments]);

  const autoGroupColumnDef: ColDef = useMemo(() => {
    return {
      width: 260,
      sortable: true,
      sort: 'asc',
      comparator:
        selectedDealCategory.id === DealBoardDealCategory.CURRENT
          ? (stageNameA: string, stageNameB: string): number => {
              const a = findStageByDisplayName(stageNameA)?.sortOrder ?? -1;
              const b = findStageByDisplayName(stageNameB)?.sortOrder ?? -1;
              return a - b;
            }
          : undefined,
    };
  }, [findStageByDisplayName, selectedDealCategory.id]);

  const exportSettings = useMemo(() => {
    return {
      processCellCallback: (params: ProcessCellForExportParams<IDealGridData>) => {
        if (params.column.getId() === 'company') {
          return params.node?.data?.company.name || '';
        }
        if (params.column.getColId() === 'round') {
          const roundId = params.node?.data?.roundId;
          if (typeof roundId !== 'number') return '';
          return roundsMap.get(roundId)?.displayName || '';
        }
        if (params.column.getColId() === 'comments') {
          return getLatestCommentString(params.node?.data?.comments, usersByEmail);
        }
        if (params.column.getColId() === 'website') {
          return params.node?.data?.company.website || '';
        }
        return commonProcessCb(params);
      },
    };
  }, [roundsMap, usersByEmail]);

  return (
    <GridContainer data-testid='deal-grid'>
      {stage && <GridHeader key={stage.id} title={stage.displayName} deals={deals} />}
      {deals.length > 0 ? (
        <GridWrapper>
          <AgTable
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            defaultCsvExportParams={exportSettings}
            defaultExcelExportParams={exportSettings}
            getRowId={getRowId}
            groupIncludeTotalFooter={false}
            initialGroupOrderComparator={
              selectedDealCategory.id === DealBoardDealCategory.CURRENT ? stageComparator : undefined
            }
            key={activeDealTypeId}
            onExpandOrCollapseAll={onExpandOrCollapseAll}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            onRowClicked={onRowClicked}
            onRowGroupOpened={onRowGroupOpened}
            rowData={rowData}
            rowGroupPanelShow={'never'}
            rowHeight={GRID_ROW_HEIGHT}
          />
        </GridWrapper>
      ) : (
        <Typography variant='h6' color={colors.primary[90]}>
          No deals to show
        </Typography>
      )}
    </GridContainer>
  );
};
