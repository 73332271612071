import { Stack, Typography } from '@mui/material';
import { MessageScreen } from '../../../../../components/MessageScreen/MessageScreen';
import ArchiveCheckIconSvg from '../../../../../assets/icons/archive-doc-check.svg?react';

export function KPINoPendingRequest() {
  return (
    <MessageScreen>
      <Stack alignItems={'center'} justifyContent={'flex-end'} gap={'1rem'}>
        <ArchiveCheckIconSvg />
        <Typography variant='h1'>No pending request found.</Typography>
      </Stack>
    </MessageScreen>
  );
}
