import { FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import { useRecoilState } from 'recoil';
import { togglePreviousDealsState } from '../../state/ListsState';

export function TogglePreviousDealsSelector() {
  const { colors } = useTheme();
  const [showPreviousDeals, setShowPreviousDeals] = useRecoilState(togglePreviousDealsState);

  return (
    <FormControlLabel
      style={{ marginRight: '0' }}
      control={
        <Switch
          size='small'
          checked={showPreviousDeals}
          onChange={() => setShowPreviousDeals((prev: boolean) => !prev)}
          sx={{ color: colors.neutral[30] }}
          style={{ marginLeft: 'auto' }}
        />
      }
      label={<Typography variant='body2'>Show previous deals</Typography>}
    />
  );
}
