import { FC } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { ITrackCompanyPayload } from '../../../data-models/tracking.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useCompanyActions } from '../Summary/hooks/useCompanyActions';
import { TrackCompanyForm } from './TrackCompanyForm';

interface ITrackCompanyModalProps {
  company: ICompanyDataModel;
  onClose: () => void;
  open: boolean;
  initialValues?: Partial<ITrackCompanyPayload>;
  onSubmit: () => void;
  showNotes?: boolean;
}

export const TrackCompanyModal: FC<ITrackCompanyModalProps> = ({
  company,
  onClose,
  open,
  initialValues,
  onSubmit,
  showNotes = true,
}) => {
  const { handleTrackCompany2 } = useCompanyActions();
  const handleTrack = async (data: Partial<ITrackCompanyPayload>) => {
    await handleTrackCompany2(company, data);
    onSubmit();
  };

  return (
    <BasicDialog open={open} title={`Set Reminder for Tracked Company ${company.name}?`} onClose={onClose}>
      <TrackCompanyForm
        initialValues={initialValues}
        onSubmit={handleTrack}
        onClose={onClose}
        showNotes={showNotes}
      />
    </BasicDialog>
  );
};
