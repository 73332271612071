import { cloneDeep, merge } from 'lodash-es';
import { object } from 'yup';
import {
  IScenarioDataModel,
  IScenarioTransactionDataModel,
  ParticipationType,
  ScenarioTransactionType,
  ShareClassSeniorityOption,
} from '../../../../../data-models/scenario.data-model';
import {
  PartialExitBreakdown,
  partialExitBreakdownFields,
  ScenarioTransaction,
} from '../../../../../schemas/Scenario.schema';

export function getInitialScenarioTransactionValues() {
  return {
    ...createInitialExitValues(),
    ...createInitialRoundValues(),
  };
}

export function createInitialRoundValues(overrides: Partial<IScenarioTransactionDataModel> = {}) {
  return {
    conversionRatio: 1,
    fundData: [getInitialFundDataValues()],
    investedAmount: null,
    investmentDate: null,
    multiple: 1,
    // newSharesReserved: 0,
    optionPoolPercentage: null,
    optionPoolRefresh: false,
    pariPassu: true,
    participation: ParticipationType.none,
    participationCap: null,
    preMoneyValuation: null,
    roundId: null,
    roundSize: null,
    seniorityOption: ShareClassSeniorityOption.Same,
    type: ScenarioTransactionType.round,
    ...overrides,
  };
}

export function getClearedRoundValues() {
  return {
    ...createInitialRoundValues(),
  };
}

export function getInitialFundDataValues() {
  return { fundId: undefined, investedAmount: undefined };
}

export function createInitialExitValues(
  overrides: Partial<IScenarioTransactionDataModel> = {}
): Partial<IScenarioTransactionDataModel> {
  return {
    type: ScenarioTransactionType.exit,
    exitDate: null,
    exitValuation: null,
    optionStrikePrice: 0.01,
    warrantStrikePrice: 0.01,
    ...overrides,
  };
}

export function createInitialPartialExitValues(
  overrides: Partial<IScenarioTransactionDataModel> = {}
): Partial<IScenarioTransactionDataModel> {
  return {
    type: ScenarioTransactionType.partialExit,
    exitDate: null,
    exitValuation: null,
    partialExitBreakdown: [createInitialPartialExitBreakdownValues(overrides?.partialExitBreakdown?.[0])],
    ...overrides,
  };
}

export function createInitialPartialExitBreakdownValues(overrides: Partial<PartialExitBreakdown> = {}) {
  return {
    ...(object().shape(partialExitBreakdownFields()).getDefault() as unknown as PartialExitBreakdown),
    ...overrides,
  };
}

export function getClearedExitValues() {
  return {
    ...createInitialExitValues(),
    optionStrikePrice: null,
    warrantStrikePrice: null,
  };
}

export function getInitialScenarioValues(currentScenario: Partial<IScenarioDataModel> | null) {
  const currTransactions = currentScenario?.scenarioTransactions ?? [];
  const scenarioTransactions = currTransactions.map((transaction) => {
    return merge(getInitialScenarioTransactionValues(), transaction);
  });
  return cloneDeep({ ...currentScenario, scenarioTransactions }) ?? initialScenarioFormValues();
}

export function initialScenarioFormValues(
  overrides: Partial<IScenarioDataModel> = {}
): Partial<IScenarioDataModel> {
  const res: Partial<IScenarioDataModel> = merge(
    {
      name: '',
      scenarioTransactions: [getInitialScenarioTransactionValues()],
    },
    overrides
  );
  delete res.id;
  return res;
}

export function prepareScenarioForDuplication(scenario: IScenarioDataModel) {
  const res = removeNonFormFieldsFromScenario(scenario);
  res.name = scenario.name + ' (Copy)';

  return res;
}

export function removeNonFormFieldsFromScenario(
  scenario: Partial<IScenarioDataModel>
): Partial<IScenarioDataModel> {
  const res: Partial<IScenarioDataModel> = { ...scenario };
  res.tags = [];
  delete res.id;
  delete res.createdBy;
  delete res.createdAt;

  res.scenarioTransactions = scenario.scenarioTransactions?.map((transaction) =>
    removeNonFormFieldsFromScenarioTransaction(transaction)
  ) as ScenarioTransaction[];
  return res;
}

function removeNonFormFieldsFromScenarioTransaction(
  transaction: Partial<IScenarioTransactionDataModel>
): Partial<IScenarioTransactionDataModel> {
  const res: Partial<IScenarioTransactionDataModel> = { ...transaction };
  delete res.id;
  delete res.createdAt;
  delete res.updatedAt;

  return res;
}
