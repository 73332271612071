import { useFlags } from 'launchdarkly-react-client-sdk';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { AddDealButton } from '../Navbar/AddModal';
import { UserMenu } from '../Navbar/Navbar';
import { HeaderSearch } from './HeaderSearch';

export function Header2() {
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();

  if (!showElasticSearchResults) {
    return <HeaderSearch />;
  }

  return (
    <>
      <HeaderSearch />
      <AddDealButton />
      <UserMenu />
    </>
  );
}
