import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { selectedCompanyIdProfile } from '../state/UIState';
import { companyState } from '../../../services/state/CompanyState';

import { otherInvestmentsState } from './CapTableFormState';
import { IOtherInvestmentsGridRow, useOtherInvestmentsRowData } from './InvestmentGridDefs';
import { useOtherInvestmentsColDefs } from './OtherInvestmentsDefs';
import { AddInvestmentsGrid } from './AddInvestmentsGrid';

const OtherInvestmentsHelperText = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  return (
    <Typography variant='subtitle1'>
      {`Please add other investments and options for ${company?.name}.`}
    </Typography>
  );
};

export function AddOtherInvestments() {
  const setOtherInvestments = useSetRecoilState(otherInvestmentsState);
  const getColDefs = useOtherInvestmentsColDefs();
  const colDefs = useMemo(() => getColDefs(), [getColDefs]);
  const { getInitialRowData } = useOtherInvestmentsRowData();
  const initialRowData = useMemo(() => getInitialRowData(), [getInitialRowData]);

  const setStateToRows = useCallback(
    (rows: IOtherInvestmentsGridRow[]) => {
      setOtherInvestments(rows);
    },
    [setOtherInvestments]
  );

  return (
    <AddInvestmentsGrid
      initialRowData={initialRowData}
      colDefs={colDefs as ColDef<IOtherInvestmentsGridRow>[]}
      setStateToRows={setStateToRows}
      HelpInfo={OtherInvestmentsHelperText}
    />
  );
}
