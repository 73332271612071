import { IFormFieldTextMeta } from '../../../view-models/form.view-model';
import { CompanyDuplicate } from '../../../pages/Finance2/Forms/CompanyDuplicate';
import { IBaseFieldProps } from './types';
import { FieldText } from './FieldText';

export function FieldCompanyWebsite(props: IBaseFieldProps<IFormFieldTextMeta>) {
  return (
    <>
      <FieldText {...props} />
      <CompanyDuplicate />
    </>
  );
}
