import { FC, useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { useFinanceActions } from '../../hooks/useFinanceActions';
import { ITransactionFormModalProps } from '../AssignToRound/AssignToInvestmentModal';
import { useTransactionTitle } from '../../hooks/useTransactionTitle';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';

export const DeleteTransactionModal: FC<ITransactionFormModalProps> = ({ open, onClose, transaction }) => {
  const { handleDeleteTransaction } = useFinanceActions();
  const [loading, setLoading] = useState(false);

  const transactionTitle = useTransactionTitle(transaction);
  const { colors } = useTheme();

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await handleDeleteTransaction(transaction);
    setLoading(false);
    onClose();
  }, [handleDeleteTransaction, onClose, transaction]);

  return open ? (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title='Are you sure you want to delete this transaction?'
      loading={loading}
    >
      <Typography variant='body2' color={colors.neutral[60]}>
        {`The ${transactionTitle}, will be deleted permanently`}
      </Typography>
    </ConfirmDeletionDialog>
  ) : (
    <div />
  );
};
