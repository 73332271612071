import { useRecoilValue } from 'recoil';
import { dealBoardConfigState } from '../../DealFlow2/state/DealboardDataState';
import { useInitDealFormatters } from '../../DealFlow2/hooks/useInitDealBoard';
import { LoadingStatus } from '../../../types';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { createDealStageDataModel } from '../../DealFlow2/data-models/dealStage.data-model';
import { useGetDealStageFields } from './DealStageFields';

export function ConfigDealStage() {
  const formatterStatus = useInitDealFormatters();
  const { dealStages } = useRecoilValue(dealBoardConfigState);
  const formFields = useGetDealStageFields();

  if (formatterStatus === LoadingStatus.loading) {
    return <div>Loading</div>;
  }

  return (
    <GenericConfigPage
      baseTitle={'Stage'}
      data={dealStages}
      fields={[]}
      formFields={formFields}
      pageTitle={'Deal Stages'}
      queryURL={'/dealStage'}
      transformBeforeSubmit={(data) => {
        return createDealStageDataModel({
          ...data,
          displayName: data.name,
          sortOrder: 0,
        });
      }}
    />
  );
}
