import { useRecoilCallback } from 'recoil';
import { dealLabelsState } from '../state/DealboardDataState';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { createDealLabel } from '../../../services/queries/MaggieDealQueries';
import { ILabelDataModel } from '../../../data-models/deal-label.data-model';

export function useLabelActions() {
  const { pushErrorToast } = useToastMessageState();

  const handleCreateLabel = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (labelName: string) => {
        const labels: ILabelDataModel[] = [];

        if (snapshot.getInfo_UNSTABLE(dealLabelsState).isSet) {
          const dealLabels = snapshot.getLoadable(dealLabelsState).valueMaybe();
          if (dealLabels) labels.push(...dealLabels);
        }
        const updatedLabels = labels.concat({ name: labelName });

        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(dealLabelsState, updatedLabels);
        });

        gotoSnapshot(updatedSnapshot);
        const release = snapshot.retain();

        try {
          const newLabel = await createDealLabel(labelName);
          if (newLabel) {
            return newLabel;
          }
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          pushErrorToast({ message: 'Failed to create label' });
        } finally {
          release();
        }
      },
    []
  );

  return {
    handleCreateLabel,
  };
}
