import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  useTheme,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { HTMLAttributes, useCallback } from 'react';

interface MultiSelectProps<T>
  extends AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined> {
  getOptionLabel: (option: string | T) => string;
}

export const MultiSelect = <T,>(props: MultiSelectProps<T>) => {
  const { colors } = useTheme();
  const renderTags = useCallback(
    (options: T[]) => {
      if (!options?.length) return <></>;

      return (
        <Chip
          size='small'
          label={`${options.length} selected`}
          style={{ background: colors.secondary[10], color: 'text.primary', marginLeft: '0.5rem' }}
        />
      );
    },
    [colors.secondary]
  );

  const { getOptionLabel } = props;

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: T, renderOptionState: AutocompleteRenderOptionState) => {
      const { selected } = renderOptionState;

      return (
        <li {...props} key={getOptionLabel(option)} style={{ fontSize: '0.875rem' }}>
          <Checkbox
            checked={selected}
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
          />
          {getOptionLabel(option)}
        </li>
      );
    },
    [getOptionLabel]
  );

  return (
    <Autocomplete
      multiple
      renderTags={props.renderTags ?? renderTags}
      renderOption={props.renderOption ?? renderOption}
      {...props}
    />
  );
};
