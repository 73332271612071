import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  CircularProgress,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { BaseLocationDataModel } from '../../schemas/Location.schema';
import { getLocations } from '../../services/queries/LocationQueries';
import { formatLocation } from '../../util/location-formatter';
import { IOptionsFromSearchConfig, useAsyncAutoComplete } from '../Form/Display/useAsyncAutomplete';
import { MoreChipWithTooltip } from '../../pages/CompanyProfiles/Summary/components/MoreChipWithTooltip/MoreChipWithTooltip';

export interface ILocationSearchProps {
  className?: string;
  disabled: boolean;
  initialValue?: BaseLocationDataModel | undefined;
  multiple?: boolean;
  onChange: (location: BaseLocationDataModel | BaseLocationDataModel[] | null) => void;
}

// React requires the "value" to be defined for this to be a controlled component,
// undefined will trigger an error and result in unwanted behaviour, hence this dummy.
// See https://github.com/facebook/react/issues/6222.
const dummyLocation: BaseLocationDataModel = { city: '', country: '', state: '' };

export const LocationSearch = forwardRef(function LocationSearch(props: ILocationSearchProps, ref) {
  const { className, disabled = false, initialValue, onChange, multiple = false } = props;
  const autoCompleteConfig = useMemo(() => {
    const conf: IOptionsFromSearchConfig<BaseLocationDataModel, typeof multiple> = {
      initialValue: initialValue ?? (multiple ? [] : dummyLocation),
      searchFn: getLocations,
      onChange,
    };
    return conf;
  }, [initialValue, multiple, onChange]);

  const { isSearching, onClose, onInputChange, onFocus, onValueChange, open, options, value } =
    useAsyncAutoComplete(autoCompleteConfig);

  return (
    <Autocomplete
      autoHighlight
      blurOnSelect
      className={className}
      disablePortal={true}
      disabled={disabled}
      disableClearable={true}
      disableCloseOnSelect={multiple}
      filterOptions={(options) => options}
      fullWidth={true}
      getOptionLabel={formatLocation}
      id='locations-autocomplete'
      isOptionEqualToValue={(option, newValue) => {
        return locationsEqual(option, newValue);
      }}
      multiple={multiple}
      noOptionsText={null}
      onChange={onValueChange}
      onClose={onClose}
      onFocus={onFocus}
      onInputChange={onInputChange}
      open={open}
      options={options}
      popupIcon={null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching ? <CircularProgress color='secondary' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option, renderOptionState: AutocompleteRenderOptionState) => {
        const { selected } = renderOptionState;
        const locationStr = formatLocation(option);

        return (
          <MenuItem {...props} key={locationStr} disabled={disabled}>
            {multiple ? <Checkbox checked={selected} /> : null}
            <ListItemText primary={locationStr} />
          </MenuItem>
        );
      }}
      renderTags={multiple ? tagRenderer : undefined}
      value={value ?? undefined}
      ListboxProps={{
        style: { maxHeight: 190 },
      }}
    />
  );
});

function locationsEqual(location1: BaseLocationDataModel, location2: BaseLocationDataModel) {
  return formatLocation(location1) === formatLocation(location2);
}

function tagRenderer(options: BaseLocationDataModel[]) {
  if (!options?.length) {
    return <></>;
  }

  const [first, ...others] = options.map((option) => {
    return formatLocation(option);
  });

  if (options.length === 1) {
    return <Chip size='small' label={first} />;
  }
  return (
    <>
      <Chip size='small' label={first} />
      <MoreChipWithTooltip
        title={`Other (${others.length})`}
        moreChipTotal={others.length}
        otherItems={others}
      ></MoreChipWithTooltip>
    </>
  );
}
