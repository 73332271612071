import { useRecoilValue } from 'recoil';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { allCompaniesActionState, selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';
import { AssignTemplateForm } from './AssignTemplateForm';

export function AssignModal() {
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const { resetUIState: handleCloseModal } = useAllKpiCompaniesActions();
  const action = useRecoilValue(allCompaniesActionState);
  const open = action === 'assign' || action === 'editAssignment';
  return (
    <BasicDialog
      open={open}
      title={`Assign request to ${
        selectedCompanies.length === 1
          ? selectedCompanies.at(0)?.name
          : `${selectedCompanies.length} companies`
      }`}
      onClose={handleCloseModal}
    >
      {open && <AssignTemplateForm />}
    </BasicDialog>
  );
}
