import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { getErrorMessage } from '../../../services/queryHelpers';

export interface DataWithId {
  id: number;
}

export function useConfigActions<T extends DataWithId>(baseURL: string) {
  const { pushErrorToast } = useToastMessageState();
  async function updateItem(data: T, isEdit: boolean) {
    try {
      if (isEdit) {
        await MaggieHttp.maggie.put<T, unknown>(`${baseURL}/${data.id}`, data);
      } else {
        await MaggieHttp.maggie.post<T, unknown>(baseURL, data);
      }

      window.location.reload();
    } catch (err) {
      const message = getErrorMessage(err, 'Update failed');
      pushErrorToast({ message });
      console.error(err);
    }
  }

  async function deleteItem(data: T) {
    try {
      await MaggieHttp.maggie.delete<unknown>(`${baseURL}/${data.id}`);

      window.location.reload();
    } catch (err) {
      const message = getErrorMessage(err, 'Delete failed');
      pushErrorToast({ message });
      console.error(err);
    }
  }

  async function bulkUpdateItems(data: T[]) {
    try {
      await MaggieHttp.maggie.put(`${baseURL}/bulk`, {
        items: data,
      });

      window.location.reload();
    } catch (err) {
      const message = getErrorMessage(err, 'Update failed');
      pushErrorToast({ message });
      console.error(err);
    }
  }

  return {
    bulkUpdateItems,
    updateItem,
    deleteItem,
  };
}
