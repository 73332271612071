import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { RowWrapper } from './RowWrapper';

interface Props {
  name: string;
  deals: number;
}

const DealsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
`;

export function MostCommonCoInvestorsRow({ name, deals }: Props): ReactElement {
  const { primary, neutral } = useTheme().colors;

  return (
    <RowWrapper name={name}>
      <DealsWrapper>
        <Typography variant={'subtitle2'} color={primary[100]}>
          {deals.toString()}
        </Typography>
        <Typography variant={'body2'} color={neutral[60]}>
          {deals > 1 ? 'Companies' : 'Company'}
        </Typography>
      </DealsWrapper>
    </RowWrapper>
  );
}
