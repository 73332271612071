import { useCallback } from 'react';
import { ColDef, ValueGetterParams, ColDefField } from 'ag-grid-community';
import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
  IStakeholderInvestmentDataModel,
} from '../../../../../data-models/captable2.data-model';
import { CapTableInvestmentType } from '../../../../../schemas/CaptableInvestment.schema';
import { FDMap } from '../../../../../util/data-structure/FDMap';
import { getCurrencyColDefs, getIntegerColDefs } from '../../../../Finance2/common-grid-defs/commonColDefs';

export const fieldToColDef = (): Partial<
  Record<ColDefField<IStakeholderInvestmentDataModel>, ColDef<ICaptableInvestmentDataModel>>
> => {
  return {
    cashRaised: {
      headerName: 'Invested Amount',
      ...getCurrencyColDefs(0),
      aggFunc: 'sum',
    },
    fullyDilutedShares: {
      headerName: 'Shares Held',
      ...getIntegerColDefs(),
      aggFunc: 'sum',
    },
    outstandingWarrants: {
      headerName: 'Outstanding Warrants',
      ...getIntegerColDefs(),
    },
  };
};

export function useStakeholderColumns(captable: ICaptableDataModel) {
  const clientInvestments = fundInvestmentsByTypeAndName(captable);

  return useCallback(
    (type: CapTableInvestmentType, fundId?: number) => {
      switch (type) {
        case 'share-class': {
          return ['cashRaised', 'fullyDilutedShares'].map((field) =>
            getColDefForField(clientInvestments, field as keyof IStakeholderInvestmentDataModel, fundId)
          );
        }
        case 'warrant-block': {
          return ['fullyDilutedShares', 'outstandingWarrants'].map((field) =>
            getColDefForField(clientInvestments, field as keyof IStakeholderInvestmentDataModel, fundId)
          );
        }
        default: {
          return [];
        }
      }
    },
    [clientInvestments]
  );
}

export function clientInvestmentsByFundId(
  captableInvestment: ICaptableInvestmentDataModel,
  filterByOwnership = false
) {
  const map = new Map<number, IStakeholderInvestmentDataModel>();
  captableInvestment?.stakeholderInvestments?.reduce((res, stakeholderInvestment) => {
    if (!stakeholderInvestment.fundId || (filterByOwnership && !stakeholderInvestment.ownershipPercentage)) {
      return res;
    }
    return res.set(stakeholderInvestment.fundId, stakeholderInvestment);
  }, map);
  return map;
}

export function fundInvestmentsByTypeAndName(captable: ICaptableDataModel) {
  const investmentMap = new FDMap<
    CapTableInvestmentType,
    Map<string, Map<number, IStakeholderInvestmentDataModel>>
  >();

  captable.captableInvestments?.forEach((inv) => {
    if (!inv.investmentType || !inv.name) {
      return;
    }
    investmentMap.setOrUpdate(
      inv.investmentType,
      new Map([[inv.name, clientInvestmentsByFundId(inv)]]),
      (map) => {
        return map.set(inv.name, clientInvestmentsByFundId(inv));
      }
    );
  });

  return investmentMap;
}

function sumForField<T>(array: T[], field: keyof T) {
  return array.reduce((res, item) => res + ((item[field] as number) ?? 0), 0);
}

function getColDefForField(
  clientInvestments: FDMap<CapTableInvestmentType, Map<string, Map<number, IStakeholderInvestmentDataModel>>>,
  field: keyof IStakeholderInvestmentDataModel,
  fundId?: number
): ColDef<ICaptableInvestmentDataModel> {
  return {
    valueGetter: (params: ValueGetterParams<ICaptableInvestmentDataModel>) => {
      const { data } = params;
      if (!data?.investmentType || !data?.name) return null;

      if (fundId != null) {
        return clientInvestments.get(data.investmentType)?.get(data.name)?.get(fundId)?.[field] ?? 0;
      } else {
        return sumForField(
          Array.from(clientInvestments.get(data.investmentType)?.get(data.name)?.values() ?? []),
          field
        );
      }
    },
    ...fieldToColDef()[field],
  };
}
