import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  BarSeriesOption,
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { ComposeOption, ECharts, init } from 'echarts/core';
import { FC, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Chart } from '../../../../../components/Charts/chart-utils';
import { ChartContainer } from '../../../../../components/Charts/ChartContainer';
import { ChartTitleWithIcon } from '../../../../../components/Charts/ChartTitleWithIcon';
import { IDealsGroupedDataModel } from '../../../../../data-models/deal-conversion.data-model';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import { getDurationText } from '../../../helpers/chart-utils';
import { useDealsBreakdownData } from '../../../hooks/useDealsBreakdownData';
import { monthsBackState } from '../../../state/DealConversionFilterState';
import { GroupByFilter } from './Filters/GroupByFilter';
import { formatDiff, useChartStyles } from './utilHooks/useChartStyles';

export type ECBreakdownChartOptions = ComposeOption<
  | BarSeriesOption
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | TitleComponentOption
  | TooltipComponentOption
>;

const TitleAndFilterContainer = styled('div')`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  grid-column-gap: 0.5rem;
`;

interface IBreakdownChartProps {
  dealsGrouped: IDealsGroupedDataModel[];
}

export const BreakdownChart: FC<IBreakdownChartProps> = ({ dealsGrouped }) => {
  const colors = useTheme().colors;
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const breakDownChart = useRef<ECharts | null>(null);
  const period = useRecoilValue(monthsBackState);
  const { indicatorRich } = useChartStyles();
  const { data, categories } = useDealsBreakdownData(dealsGrouped);

  const { min, range } = useMemo(() => {
    const max = Math.max(...dealsGrouped.map((item) => item.value));
    const min = Math.min(...dealsGrouped.map((item) => item.value));
    const range = max - min;
    return { max, min, range };
  }, [dealsGrouped]);

  const isInViewport = useIsInViewport(
    {
      threshold: 0.5,
    },
    chartContainer
  );

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) return;

    if (!breakDownChart.current) {
      breakDownChart.current = init(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          breakDownChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(breakDownChart.current && isInViewport)) {
      return;
    }

    const option: ECBreakdownChartOptions = {
      series: {
        data: data,
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          formatter: (params) => {
            return formatDiff(params.data, 'percentageDifference');
          },
          padding: 5,
          rich: indicatorRich,
        },
        itemStyle: {
          color: (params) => {
            const { value } = params;
            const percentage = ((value as number) - min) / range;
            if (percentage < 0.25) return colors.primary[20];
            else if (percentage < 0.5) return colors.primary[30];
            else if (percentage < 0.75) return colors.primary[40];
            else return colors.primary[50];
          },
        },
        emphasis: { disabled: true },
      },
      xAxis: {
        type: 'category',
        data: categories,
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          hideOverlap: true,
        },
      },
      yAxis: {
        type: 'value',
      },
      grid: { top: '10%', right: '30px', left: '30px', bottom: '5%' },
      tooltip: {},
    };

    breakDownChart.current.setOption(option, true);
  }, [
    breakDownChart,
    categories,
    colors.critical,
    colors.neutral,
    colors.primary,
    colors.success,
    data,
    dealsGrouped,
    indicatorRich,
    isInViewport,
    min,
    range,
  ]);

  const subtitle = useMemo(() => getDurationText(period), [period]);

  return (
    <ChartContainer>
      <TitleAndFilterContainer>
        <ChartTitleWithIcon title='Deals Breakdown' />
        <Typography color={colors.neutral[50]} variant={'caption'} sx={{ alignSelf: 'center' }}>
          {subtitle}
        </Typography>
        <div />
        <GroupByFilter />
      </TitleAndFilterContainer>
      {dealsGrouped?.length ? (
        <Chart ref={chartContainer} width={'100%'} height={'400px'} />
      ) : (
        <Typography color={colors.neutral[40]} style={{ marginTop: '2rem' }}>
          No data.
        </Typography>
      )}
    </ChartContainer>
  );
};
