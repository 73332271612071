import { CSSProperties, FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Column } from 'react-table';
import { ICompanyMetricsDataModel } from '../../../data-models/metrics.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { TransactionAudit, TransactionHistoryTableData } from '../../../types';
import { CompanyTransactionsPanel } from './CompanyTransactionsPanel/CompanyTransactionsPanel';
import { HistoryOfUpdatesSection } from './HistoryOfUpdatesSection';
import { MainTableSection } from './MainTableSection';

const Container = styled('section')`
  margin: 0 0 20px 40px;
  max-width: calc(100vw - 500px);
  overflow-y: scroll;
`;

interface IFinanceHistoryPageProps {
  transactions: ITransactionDataModel[] | undefined;
  selectedTransaction: ITransactionDataModel | null;
  colDefs: Column<TransactionHistoryTableData>[];
  companyMetrics: ICompanyMetricsDataModel | null;
  transactionHistory: TransactionAudit[] | undefined;
  breadCrumbsComponent?: ReactNode;
  sidebarStyles?: CSSProperties;
  mainContentStyles?: CSSProperties;
}

export const FinanceHistoryPage: FC<IFinanceHistoryPageProps> = ({
  transactions,
  selectedTransaction,
  colDefs,
  companyMetrics,
  transactionHistory,
  breadCrumbsComponent,
  sidebarStyles,
  mainContentStyles,
}) => {
  const { colors } = useTheme();
  return (
    <>
      {transactions && (
        <>
          <CompanyTransactionsPanel transactions={transactions} style={sidebarStyles} />
          <Container style={mainContentStyles}>
            {breadCrumbsComponent}
            {selectedTransaction ? (
              <>
                <MainTableSection
                  key={selectedTransaction.id}
                  activeTransaction={selectedTransaction}
                  columns={colDefs}
                  companyMetrics={companyMetrics}
                />
                {transactionHistory ? (
                  <HistoryOfUpdatesSection
                    tableColumns={colDefs}
                    activeTransaction={selectedTransaction}
                    transactionHistory={transactionHistory}
                  />
                ) : null}
              </>
            ) : (
              <Typography variant='h4' color={colors.neutral[50]}>{`No data`}</Typography>
            )}
          </Container>
        </>
      )}
    </>
  );
};
