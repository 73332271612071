import { FC, MouseEvent } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';

interface ReportingFrequencyTogglerProps {
  value: KpiPeriod;
  onChange: (event: MouseEvent, value: KpiPeriod) => void;
}

export const ReportingFrequencyToggler: FC<ReportingFrequencyTogglerProps> = ({ value, onChange }) => {
  return (
    <ToggleButtonGroup
      color='secondary'
      value={value}
      exclusive
      onChange={onChange}
      aria-label='Select reporting frequency'
    >
      <ToggleButton value={KpiPeriod.month}>Monthly</ToggleButton>
      <ToggleButton value={KpiPeriod.quarter}>Quarterly</ToggleButton>
      <ToggleButton value={KpiPeriod.year}>Yearly</ToggleButton>
    </ToggleButtonGroup>
  );
};
