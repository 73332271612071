import { useRecoilCallback } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { companyState } from '../../../services/state/CompanyState';

export function useGetCompanyData() {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (companyIds: number[]): Promise<ICompanyDataModel[]> => {
        const companies: ICompanyDataModel[] = [];
        const companyPromises = [] as Promise<ICompanyDataModel | null>[];

        companyIds.forEach((id) => {
          if (
            snapshot.getInfo_UNSTABLE(companyState(id)).isSet &&
            snapshot.getInfo_UNSTABLE(companyState(id)).loadable?.state === 'hasValue'
          ) {
            const company = snapshot.getLoadable(companyState(id)).valueMaybe();
            if (company) companies.push(company);
          } else {
            const companyPromise = snapshot.getPromise(companyState(id));
            companyPromises.push(companyPromise);
          }
        });

        await Promise.all(companyPromises).then((companyData) => {
          return companyData.reduce((acc, company) => {
            if (company) {
              acc.push(company);
              set(companyState(company.id), company);
            }
            return acc;
          }, companies);
        });
        return companies;
      },
    []
  );
}

export function useGetCompanyIfSet() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (id: number) => {
        const companyData = snapshot.getInfo_UNSTABLE(companyState(id)).loadable?.valueMaybe() ?? null;
        return companyData;
      },
    []
  );
}
