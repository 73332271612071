import { ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import AppsIcon from '@mui/icons-material/Apps';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { AdminPortal } from '@frontegg/react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { logout } from '../../services/hooks/useAuthActions';
import { PermissionService } from '../../services/PermissionService';
import { PermissionKey } from '../../services/PermissionAndRolesKeys';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { ROUTES } from '../../constants/RouteNameMapping';

interface IAccountMenuProps {
  onCloseMenu: () => void;
}

export function AccountMenu({ onCloseMenu }: IAccountMenuProps) {
  const canViewUserAdminPortal = PermissionService.get().hasPermission(PermissionKey.canViewUserAdminPanel);
  const ref = useRef(null);
  const flags = useFlags<MaggieFeatureFlags>();
  function showUserAdminPortal() {
    onCloseMenu();
    AdminPortal.show();
  }

  useOutsideClick(ref, () => onCloseMenu());

  return (
    <MenuList>
      {flags.showIntegrations && (
        <Link
          to={`${ROUTES.INTEGRATIONS}/${ROUTES.INTEGRATIONS_APPS}`}
          style={{ textDecoration: 'none', color: 'unset' }}
          onClick={onCloseMenu}
        >
          <MenuItem>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText>Integrations</ListItemText>
          </MenuItem>
        </Link>
      )}
      {canViewUserAdminPortal && (
        <MenuItem onClick={showUserAdminPortal}>
          <ListItemIcon>
            <AdminPanelSettingsIcon />
          </ListItemIcon>
          <ListItemText>User Management</ListItemText>
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Log out</ListItemText>
      </MenuItem>
    </MenuList>
  );
}
