import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { usersByIdMapState } from '../../../../../services/state/AppConfigState';
import { FMT } from '../../../../../util/formatter-service';

export function useMetricsByUserColDefs() {
  const userByIdMap = useRecoilValue(usersByIdMapState);

  const columnDefs: ColDef[] = [
    {
      headerName: 'User',
      field: 'user',
      suppressMovable: true,
      valueGetter: (params: ValueGetterParams) => {
        const user = userByIdMap.get(params.data.userId);
        return user?.name ?? '';
      },
      initialSort: 'asc',
    },
    {
      headerName: 'IRR',
      field: 'irr',
      valueFormatter: (params: ValueFormatterParams) => {
        return FMT.percent(params.value / 100);
      },
      suppressMovable: true,
    },
    {
      headerName: 'MOIC',
      field: 'moic',
      valueFormatter: (params: ValueFormatterParams) => {
        return FMT.format('multiplier', params.value);
      },
      suppressMovable: true,
    },
  ];

  return columnDefs;
}
