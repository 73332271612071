import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { styled } from '@mui/material/styles';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { collapsedDealStages } from '../../../PortfolioOverview/state/UIState';
import { dealBoardSettingsState, isPresentModeState, viewModeState } from '../../state/DealboardUIState';
import { dealStagesByNameMapState } from '../../state/DealboardDataState';
import { DealCard } from '../DealCard/DealCard';
import { DealBoardViewMode } from '../../../../types';
import { FloatingMenu, FloatingMenuSkeleton } from '../FloatingMenu/FloatingMenu';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { useDealActions2, useUpdateStage } from '../../hooks/useDealActions';
import { isNavigationBarStateOpen } from '../../../../components/Layout/state/NavigationState';
import { MENU_WIDTH } from '../../../../components/Layout/Navbar/Navbar';
import { ADDITIONAL_MENU_WIDTH } from '../../../../components/Layout/Navbar/Navigation/NavigationWrapper';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { ColumnHeader } from './ColumnHeader';
import { CloseDroppable } from './CloseDroppable';
import { DealGrids } from './DealGrids';
import { Draggable } from './Draggable';
import { Droppable } from './Droppable';

interface BoardHeaderProps {
  nColumns: number;
}

const BoardHeader = styled('section', { shouldForwardProp: (prop) => prop !== 'nColumns' })<BoardHeaderProps>`
  display: grid;
  grid-template-columns: ${({ nColumns }) => `repeat(${nColumns}, auto)`};
  gap: 0.5rem;
`;

const MainContainer = styled('div')`
  display: flex;
  overflow-x: auto;
  scrollbar-gutter: stable;
  gap: 1rem;
`;

const BoardColumns = styled(BoardHeader)`
  overflow-y: auto;
  margin-top: 16px;
  min-height: calc(100vh - 22.7rem);
  max-height: calc(100vh - 20rem);
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled('section')<{ isAdditionalNavOpen: boolean; isPresentMode: boolean }>`
  padding: 2rem 2rem 0 3rem;
  max-width: ${({ isAdditionalNavOpen, isPresentMode }) =>
    isPresentMode
      ? '100vw'
      : isAdditionalNavOpen
      ? `calc(99vw - ${MENU_WIDTH + ADDITIONAL_MENU_WIDTH}px)`
      : `calc(99vw - ${MENU_WIDTH}px)`};
`;

export const DealBoard = () => {
  const viewMode = useRecoilValue(viewModeState);
  const dealBoardSettings = useRecoilValue(dealBoardSettingsState);
  const stagesMap = useRecoilValue(dealStagesByNameMapState);
  const updateStage = useUpdateStage();
  const { handleClose } = useDealActions2();
  const [activeDeal, setActiveDeal] = useState<IDealDataModel | null>(null);
  const params = useParams();
  const isPresentMode = useRecoilValue(isPresentModeState);
  const [collapsedStages, setCollapsedStages] = useRecoilState(collapsedDealStages);
  const isOpen = useRecoilValue(isNavigationBarStateOpen);

  const activeDealTypeId = useMemo(() => {
    const { dealTypeId } = params;
    return parseInt(dealTypeId as string);
  }, [params]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const toggleCollapsed = useCallback(
    (stageId: number) => {
      const uniqueStageId = `${activeDealTypeId}-${stageId}`;
      let newCollapsedStagesState: Record<string, boolean>;
      if (collapsedStages[uniqueStageId]) {
        newCollapsedStagesState = { ...collapsedStages, [uniqueStageId]: false };
      } else {
        newCollapsedStagesState = { ...collapsedStages, [uniqueStageId]: true };
      }
      setCollapsedStages(newCollapsedStagesState);
    },
    [activeDealTypeId, collapsedStages, setCollapsedStages]
  );

  function handleDragStart(event: DragStartEvent) {
    setActiveDeal(event.active.data.current?.deal);
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { over, active } = event;
    const currentDeal = active.data.current?.deal;
    const newStageId = parseInt(over?.id as string);
    setActiveDeal(null);

    if (!currentDeal || !newStageId) return;

    //if element is dropped on column which is already its parent
    if (currentDeal.stageId === newStageId) return;

    if (newStageId === stagesMap.get('Closed')!.id) {
      handleClose(currentDeal);
      return;
    }

    await updateStage(
      currentDeal!,
      {
        stageId: newStageId,
        stageUpdateDate: new Date().toISOString(),
      },
      false
    );
  }

  return viewMode === DealBoardViewMode.KANBAN ? (
    <Container isAdditionalNavOpen={isOpen} isPresentMode={isPresentMode}>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} sensors={sensors}>
        <MainContainer>
          <div>
            <BoardHeader nColumns={dealBoardSettings.length}>
              {dealBoardSettings.map((el) => (
                <ColumnHeader
                  key={el.stage.id}
                  title={el.stage.displayName}
                  deals={el.deals}
                  isCollapsed={collapsedStages[`${activeDealTypeId}-${el.stage.id}`]}
                  toggleCollapsed={() => toggleCollapsed(el.stage.id)}
                />
              ))}
            </BoardHeader>
            <BoardColumns nColumns={dealBoardSettings.length}>
              {dealBoardSettings.map((el) => (
                <Droppable
                  key={el.stage.id}
                  data={el}
                  isCollapsed={collapsedStages[`${activeDealTypeId}-${el.stage.id}`]}
                >
                  {el.deals.map((deal) => (
                    <Draggable key={deal.id} deal={deal} stageId={el.stage.id} />
                  ))}
                </Droppable>
              ))}
            </BoardColumns>
          </div>
          <CloseDroppable stageId={stagesMap.get('Closed')!.id} />
          <DragOverlay dropAnimation={null}>{activeDeal ? <DealCard deal={activeDeal} /> : null}</DragOverlay>
        </MainContainer>
      </DndContext>
      <GenericFallbacksWrapper suspenseFallback={<FloatingMenuSkeleton />}>
        <FloatingMenu />
      </GenericFallbacksWrapper>
    </Container>
  ) : (
    <DealGrids />
  );
};
