import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DealModal } from '../../../../../../pages/DealFlow2/components/DealModal/DealModal';
import {
  activeCompanyListIdState,
  listsByIdState,
  listsState,
} from '../../../../../../pages/CompanyProfiles/state/ListsState';
import { updateList } from '../../../../../../services/queries/MaggieListQueries';
import { useToastMessageState } from '../../../../../ToastMessage/ToastMessageProvider';
import { useModalState } from '../../../../../Modal/ModalContext';
import { IListDataModel } from '../../../../../../data-models/list.data-model';
import { CompanyListForm } from './CompanyListForm';

export const EditCompanyListForm = () => {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const listsMap = useRecoilValue(listsByIdState);
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { onCloseModal } = useModalState();
  const setLists = useSetRecoilState(listsState);

  const handleSubmit = async (vals: Partial<IListDataModel>) => {
    try {
      const updatedList = await updateList(vals, Number(activeListId));

      setLists((currVal) => {
        if (!currVal) return currVal;
        return currVal.map((item) => (item.id === activeListId ? updatedList : item));
      });

      pushSuccessToast({ message: `List ${activeList?.name} updated successfully` });
      onCloseModal();
    } catch {
      pushErrorToast({ message: 'An error occurred while updating the list' });
    }
  };

  return (
    <DealModal title={`Edit ${activeList?.name}`} width={'814px'} contentPadding={'0px 2rem'}>
      <CompanyListForm onSubmit={handleSubmit} isEdit={true} />
    </DealModal>
  );
};
