import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Icon, IconTypes } from '../../../../components/Icon';
import { currentCompaniesCI, selectedCompaniesCI } from '../../state/CompaniesState';
import { CompanyList } from './CompanyList';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 2rem;
  margin-bottom: 4rem;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
`;

const IconWrapper = styled('div')`
  transform: translateY(0.13rem);
`;

export function CompanyListWrapper() {
  const currentCompanies = useRecoilValue(currentCompaniesCI);
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(selectedCompaniesCI);
  const [showCompanies, setShowCompanies] = useState(true);
  const { primary, neutral } = useTheme().colors;

  return (
    <Wrapper>
      <Header
        role='switch'
        aria-checked={showCompanies}
        onClick={() => setShowCompanies((prevState) => !prevState)}
      >
        <IconWrapper>
          <Icon type={showCompanies ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_RIGHT} color={neutral[60]} />
        </IconWrapper>
        <Typography variant={'h6'} color={primary[100]}>
          Companies
        </Typography>
        <Typography variant={'body2'} color={neutral[60]}>
          ({selectedCompanies.length} of {currentCompanies.length} selected)
        </Typography>
      </Header>
      {showCompanies && <CompanyList companies={currentCompanies} onChange={setSelectedCompanies} />}
    </Wrapper>
  );
}
