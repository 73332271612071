import { useCallback, useState } from 'react';
import { Icon, Stack } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface DetailsConfigActionsProps {
  save: {
    handler: () => Promise<void>;
    disabled?: boolean;
  };
  reset: {
    handler: () => Promise<void>;
    disabled?: boolean;
  };
}

export function DetailsConfigActions({ save, reset }: DetailsConfigActionsProps) {
  const [loading, setLoading] = useState(false);

  const _onSave = useCallback(async () => {
    setLoading(true);
    await save.handler();
    setLoading(false);
  }, [save]);

  const _onReset = useCallback(async () => {
    setLoading(true);
    await reset.handler();
    setLoading(false);
  }, [reset]);

  const commonProps = {
    loading,
    color: 'secondary',
    loadingPosition: 'start',
    startIcon: loading ? <Icon /> : <></>,
    style: { minWidth: 'fit-content' },
    type: 'button',
  } as LoadingButtonProps;

  return (
    <Stack direction={'row'} spacing={2} minWidth={'11rem'}>
      <LoadingButton
        variant='outlined'
        onClick={_onReset}
        disabled={loading || reset.disabled}
        {...commonProps}
      >
        Reset
      </LoadingButton>
      <LoadingButton
        onClick={_onSave}
        disabled={loading || save.disabled}
        variant='contained'
        {...commonProps}
      >
        Save Changes
      </LoadingButton>
    </Stack>
  );
}
