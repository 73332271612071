import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { SentRequestActions } from '../components/SentRequestActions';
import { SentAtRenderer } from '../components/SentAtRenderer';
import { useSchemaToColDef } from '../../../../../util/schema-utils';
import { RendererType } from '../../../../../data-models/field.data-model';
import { ArchivedRequestsActions } from '../components/ArchivedRequestsActions';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useCommonKpiColDefs } from './useCommonKpiColDefs';
import { IKpiRequestGridData } from './useKPIRequestsFilters';

export default function useKPIRequestsSentColDefs(status: KPIRequestStatus.Sent | KPIRequestStatus.Archived) {
  const {
    respondentColDef,
    periodColDef,
    frequencyColDef,
    granularityColDef,
    templateColDef,
    nonSelectableCompanyColDef,
    fyeColDef,
  } = useCommonKpiColDefs();
  const schemaToColDef = useSchemaToColDef();
  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef<IKpiRequestGridData>[] = useMemo(() => {
    const res = [
      nonSelectableCompanyColDef,
      status === KPIRequestStatus.Sent
        ? {
            headerName: 'Sent At',
            cellRenderer: SentAtRenderer,
            minWidth: 180,
            field: 'sentAt',
          }
        : schemaToColDef(
            'archivedAt',
            yup
              .object()
              .shape({ archivedAt: yup.date().nullable() })
              .meta({
                renderer: RendererType.date,
                label: 'Deleted At',
              })
              .describe()
          ),
      templateColDef,
      frequencyColDef,
      fyeColDef,
      periodColDef,
      respondentColDef,
      {
        maxWidth: status === KPIRequestStatus.Sent ? 100 : 60,
        resizable: false,
        sortable: false,
        pinned: 'right',
        cellRenderer: status === KPIRequestStatus.Sent ? SentRequestActions : ArchivedRequestsActions,
      },
    ] as ColDef<IKpiRequestGridData>[];

    if (showGranularitySelector) {
      res.splice(4, 0, granularityColDef as unknown as ColDef<IKpiRequestGridData>);
    }

    return res;
  }, [
    frequencyColDef,
    fyeColDef,
    granularityColDef,
    nonSelectableCompanyColDef,
    periodColDef,
    respondentColDef,
    schemaToColDef,
    showGranularitySelector,
    status,
    templateColDef,
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      menuTabs: [],
      suppressMovable: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
