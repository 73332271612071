import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import {
  configurationStepsState,
  indexColumnMatchesState,
  selectedEntityState,
} from '../../state/ConfigurationState';
import { useResetConfigState } from './useResetConfigState';

export function useConfigurationSteps() {
  const resetConfigState = useResetConfigState();
  const activeStep = useRecoilValue(configurationStepsState);

  const isActionable = useCallback(
    (stepIndex: number) => {
      return stepIndex < activeStep;
    },
    [activeStep]
  );

  const handleStepClick = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (stepIndex: number) => {
        switch (stepIndex) {
          case 0: {
            resetConfigState();
            return;
          }
          case 1: {
            const updatedSnapshot = snapshot.map((mutable) => {
              mutable.set(indexColumnMatchesState, new Map());
              mutable.set(selectedEntityState, null);
              mutable.set(configurationStepsState, stepIndex);
              return mutable;
            });
            gotoSnapshot(updatedSnapshot);
            return;
          }

          default:
            return;
        }
      },
    [resetConfigState]
  );

  return { handleStepClick, isActionable };
}
