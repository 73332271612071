import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled, Typography, useTheme } from '@mui/material';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { CompanySearchMultiSelect } from '../../components/CompanySearch/CompanySearchMultiSelect';
import { useSetSearchParams } from '../../hooks/useSetSearchParams';
import { PageContainer } from '../../components/Layout/PageContainer';
import { CompanySearchResponseWithRealId } from '../../schemas/CompanySearchResponse.schema';
import { MappingsGrid } from './MappingsGrid/MappingsGrid';
import { selectedMappingsCompanies, selectedMappingsCompaniesIds } from './state/MappingsState';
import { MappingsFormDialog } from './MappingsFormDialog';
import {
  ID_DELIMITER,
  MAPPING_COMPANIES_PARAM,
  useSyncMappingsFromPath,
} from './hooks/useSyncMappingsFromPath';

const InfoContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.25rem;
`;

export const Mappings = () => {
  const initialized = useSyncMappingsFromPath();
  const resetSelectedCompanies = useResetRecoilState(selectedMappingsCompaniesIds);

  useEffect(() => {
    return () => {
      resetSelectedCompanies();
    };
  }, [resetSelectedCompanies]);

  return initialized ? <MappingsContent /> : null;
};

export function MappingsContent() {
  const { colors } = useTheme();
  const selectedCompanies = useRecoilValue(selectedMappingsCompanies);
  const setParams = useSetSearchParams();

  const handleOnSelectCompanies = (companies: CompanySearchResponseWithRealId[]) => {
    const companyIds = companies.map((company) => company.id);
    setParams({
      [MAPPING_COMPANIES_PARAM]: companyIds.join(ID_DELIMITER) ?? '',
    });
  };

  return (
    <PageContainer>
      <MappingsFormDialog />
      <Typography variant='h4' color={colors.primary[90]} sx={{ mb: '0.5rem' }} fontWeight={400}>
        Mappings
      </Typography>

      <div style={{ width: '400px' }}>
        <CompanySearchMultiSelect
          autoFocus={true}
          initialValues={selectedCompanies}
          onChange={handleOnSelectCompanies}
          maxSelectable={5}
          placeholder='Search for a company name or website'
        />
      </div>
      <InfoContainer sx={{ color: colors.neutral[60] }}>
        <InfoOutlinedIcon />
        <Typography variant='body2' color={colors.neutral[60]}>
          Here you can match vendor identification with companies and ensure all vendor information is sourced
          correctly
        </Typography>
      </InfoContainer>
      <MappingsGrid />
    </PageContainer>
  );
}
