import { atomFamily } from 'recoil';
import { NoteComment } from '../../../data-models/note.data-model';
import { fetchCommentsByNoteId } from '../../../services/queries/MaggieNoteCommentsQueries';

export const noteCommentsState = atomFamily<NoteComment[] | null, number>({
  key: 'noteCommentsState',
  default: null,
  effects: (noteId) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          if (!noteId) {
            setSelf(null);
          }
          const comments = fetchCommentsByNoteId(noteId);
          setSelf(comments ?? null);
        } catch (err) {
          console.error(err);
          setSelf(null);
        }
      }
      return;
    },
  ],
});
