import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { object } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { companyState } from '../../../services/state/CompanyState';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { schemaToFormFields } from '../../../util/schema-utils';
import { CompanySchema } from '../../../schemas/Company.schema';
import { useSectorIdField } from '../../../data-fields/CommonFields';
import { FDMap } from '../../../util/data-structure/FDMap';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { createForm } from '../../../view-models/form.view-model';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';

export function CompanySectorField() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { sectorId } = CompanySchema.base();
  const sectorSchema = object({
    sectorId: sectorId.customMeta(
      useSectorIdField({
        key: 'sectorId',
        label: '',
      })
    ),
  });
  const sectorField = schemaToFormFields(sectorSchema);
  const company = useRecoilValue(companyState(companyId))!;
  const updateCompany = useUpdateCompanyAndState();

  const methods = useForm({
    values: { sectorId: company.sectorId },
  });

  const onChange = useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      await updateCompany(companyId, data);
    },
    [companyId, updateCompany]
  );

  const form = createForm<ICompanyDataModel>({
    fields: FDMap.fromArray(sectorField, 'key'),
    variant: 'form-inline',
  });

  return (
    <FormProvider {...methods}>
      <FormFactoryNoLayout form={form} onChange={onChange} />
    </FormProvider>
  );
}
