import { FC } from 'react';
import { Tooltip, useTheme } from '@mui/material';

interface TooltipWrapperProps {
  value: string;
}

export const TooltipWrapperComponent: FC<TooltipWrapperProps> = ({ value }) => {
  const { neutral } = useTheme().colors;
  return (
    <span>
      <Tooltip
        role={'tooltip'}
        title={<span style={{ color: neutral[60], backgroundColor: 'white' }}>{value}</span>}
        placement='top-start'
      >
        <span>{value}</span>
      </Tooltip>
    </span>
  );
};
