import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Autocomplete, Button, InputLabel, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Publish } from '@mui/icons-material';
import { NumericInput } from '../../../../../components/Inputs/NumericInput';
import { FieldContainer, FormikOption, FormGrid } from './StyledFormComponents';

import { KeyResultInitialValues, keyResultInitialValues } from './keyResultInitialValues';
import { keyResultSchema } from './keyResultSchema';
import { ButtonContainer } from './ObjectiveForm';
import { noChange } from './formHelpers';

const statusOptions: FormikOption[] = ['Progress', 'At Risk', 'Completed'].map((status) => ({
  id: status,
  value: status,
  label: status === 'Progress' ? 'In Progress' : status,
}));

const displayTypeOptions: FormikOption[] = [
  { id: 'percentage', value: 'percentage', label: 'Percentage' },
  { id: 'value', value: 'value', label: 'Value' },
];

interface KeyResultFormProps {
  onClose: () => void;
  initialValues?: KeyResultInitialValues;
  onSubmit: (
    values: KeyResultInitialValues,
    formikHelpers: FormikHelpers<KeyResultInitialValues>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => void | Promise<any>;
  onCancel?: () => void;
}

export const KeyResultForm: FC<KeyResultFormProps> = ({ onClose, onSubmit, onCancel, initialValues }) => {
  const formik = useFormik<KeyResultInitialValues>({
    initialValues: initialValues ?? keyResultInitialValues,
    validationSchema: keyResultSchema,
    initialTouched: initialValues ? { name: true } : undefined,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
  } = formik;

  const handleStatusChange = useCallback(
    (event: SyntheticEvent, value: FormikOption | null) => {
      if (!value) setFieldValue(`status`, null);
      else setFieldValue(`status`, value.value);
    },
    [setFieldValue]
  );

  const handleDisplayTypeChange = useCallback(
    (event: SyntheticEvent, value: FormikOption | null) => {
      if (!value) setFieldValue(`displayType`, null);
      else setFieldValue(`displayType`, value.value);
    },
    [setFieldValue]
  );

  const submitDisabled = useMemo(() => noChange(initialValues, values), [initialValues, values]);

  return (
    <FieldContainer>
      <FormGrid>
        <InputLabel required htmlFor={`name`}>
          Name
        </InputLabel>
        <TextField
          id={`name`}
          name={`name`}
          error={Boolean(errors.name && touched.name)}
          helperText={errors.name && touched.name ? errors.name : ''}
          onBlur={handleBlur}
          value={values.name}
          onChange={handleChange}
          placeholder='Enter Name'
        />
        <InputLabel required htmlFor={`status`}>
          Status
        </InputLabel>
        <Autocomplete
          options={statusOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`status`}
              id={`status`}
              error={Boolean(errors.status && touched.status)}
              helperText={errors.status && touched.status ? errors.status : ''}
              placeholder='Select Status'
            />
          )}
          onChange={(e, v) => handleStatusChange(e, v)}
          onBlur={handleBlur}
          value={statusOptions.find((option) => option.id === values.status) ?? null}
          selectOnFocus
        />
        <InputLabel required htmlFor={`displayType`}>
          Goal Type
        </InputLabel>
        <Autocomplete
          options={displayTypeOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              id={`displayType`}
              name={`displayType`}
              error={Boolean(errors.displayType && touched.displayType)}
              helperText={errors.displayType && touched.displayType ? errors.displayType : ''}
              placeholder='Select Goal Type'
            />
          )}
          onChange={(e, v) => handleDisplayTypeChange(e, v)}
          onBlur={handleBlur}
          value={displayTypeOptions.find((option) => option.id === values.displayType) ?? null}
          selectOnFocus
          blurOnSelect={Boolean(values?.displayType)}
        />

        <InputLabel htmlFor={`value`}>Current Value</InputLabel>
        <NumericInput
          id={`value`}
          name={`value`}
          error={Boolean(errors.value && touched.value)}
          helperText={errors.value && touched.value ? errors.value : ''}
          onBlur={handleBlur}
          value={values.value ?? ''}
          onChange={(e) => setFieldValue(`value`, e.target.value ? parseFloat(e.target.value) : null)}
          suffix={values.displayType === 'percentage' ? '%' : ''}
          prefix={values.displayType === 'value' ? '$' : ''}
        />

        <InputLabel required htmlFor={`goal`}>
          Goal Value
        </InputLabel>
        <NumericInput
          id={`goal`}
          name={`goal`}
          error={Boolean(errors.goal && touched.goal)}
          helperText={errors.goal && touched.goal ? errors.goal : ''}
          onBlur={handleBlur}
          value={values.goal ?? ''}
          onChange={(e) => setFieldValue(`goal`, parseFloat(e.target.value) ?? null)}
          suffix={values.displayType === 'percentage' ? '%' : ''}
          prefix={values.displayType === 'value' ? '$' : ''}
        />

        <div></div>
        <ButtonContainer>
          <LoadingButton
            variant='contained'
            color={'secondary'}
            size={'large'}
            disabled={
              Object.keys(touched).length === 0 ||
              Object.keys(errors).length !== 0 ||
              isSubmitting ||
              submitDisabled
            }
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            loading={isSubmitting}
            endIcon={<Publish />}
            loadingPosition='end'
          >
            {initialValues ? 'Save Changes' : 'Add Key Result'}
          </LoadingButton>
          <Button
            type='button'
            variant='outlined'
            color='secondary'
            size={'large'}
            onClick={() => {
              resetForm();
              if (onCancel) onCancel();
              else onClose();
            }}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </FormGrid>
    </FieldContainer>
  );
};
