import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { StatusIndicators } from './StatusIndicators';

const Wrapper = styled('div')<{ isStale?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 70%;
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  filter: ${({ isStale }) => (isStale ? 'grayscale(1)' : '')};
`;
const StatusWrapper = styled('div')<{ isStale?: boolean }>`
  margin-bottom: -4px;
`;

interface Props {
  companyName: string;
  companyLogo: string;
  isStale?: boolean;
  warning?: boolean;
  warningMessage?: string | null;
}

export const CompanyNameAndLogo = ({ companyName, companyLogo, warning, warningMessage, isStale }: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper data-testid='deal-card-company-header' isStale={isStale}>
      <MUIAvatar src={companyLogo ?? ''} nameParts={companyName.split(' ')} />
      <Typography component='span' variant='body1' color={colors.primary[100]}>
        {companyName}
      </Typography>
      <StatusWrapper>
        <StatusIndicators warning={warning} warningMessage={warningMessage} />
      </StatusWrapper>
    </Wrapper>
  );
};
