import { useRecoilValue } from 'recoil';
import { Box, Chip, Stack } from '@mui/material';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { ChipStack } from '../../components/commonStyledComponents';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { companyState } from '../../../../../services/state/CompanyState';
import { getSimulationLabels } from '../../utils/scenarioUtils';
import { TypeChip } from '../../AllScenarios/ScenarioTypes';
import { cardStyles } from '../../../../../theme/component-styles';

export function ScenarioExitWarning({ scenario }: { scenario: Partial<IScenarioDataModel> }) {
  const company = useRecoilValue(companyState(scenario.companyId ?? -1));

  return (
    <Stack gap='1rem'>
      <Stack
        style={cardStyles}
        display={'grid'}
        gridAutoFlow={'column'}
        justifyContent={'start'}
        gap={'3rem'}
        alignItems={'center'}
      >
        <CompanyLogoAndName name={company?.name ?? ''} logoUrl={company?.logoUrl ?? ''} />
        {scenario.scenarioTransactions?.length && (
          <ChipStack style={{ justifySelf: 'center' }}>
            {Array.from(getSimulationLabels(scenario as IScenarioDataModel))
              .sort()
              .map((label) => (
                <Chip key={label} label={label} />
              ))}
          </ChipStack>
        )}
        <Box sx={{ justifySelf: 'end' }}>
          {scenario.tags?.at(0) && <TypeChip type={scenario.tags!.at(0)!} />}
        </Box>
      </Stack>
      <InfoBox message={'Inputs for scenarios that are not published will be deleted'} />
    </Stack>
  );
}
