import { useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { ICaptableInvestmentDataModel } from '../../../../data-models/captable2.data-model';
import { ShareClass } from '../../../../view-models/captable.view-model';
import {
  CapTableInvestmentType,
  captableInvestmentGridSchema,
} from '../../../../schemas/CaptableInvestment.schema';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { ColDefOverrides } from './ColDefOverrides';

const noteBlockColumns: (keyof ICaptableInvestmentDataModel)[] = [
  'name',
  'cashRaised',
  'principal',
  'interest',
  'discountRate',
  'interestRate',
  'valuationCap',
  'interestType',
  'compoundingPeriod',
  'maturityDate',
  'investmentDate',
];
const shareClassColumns: (keyof ICaptableInvestmentDataModel)[] = [
  'name',
  'cashRaised',
  'fullyDilutedShares',
  'pricePerShare',
  'seniority',
  'pariPassu',
  'participationCap',
  'liquidationMultiplier',
  'conversionRatio',
  'conversionPrice',
  'investmentDate',
];

const warrantColumns: (keyof ICaptableInvestmentDataModel)[] = [
  'name',
  'fullyDilutedShares',
  'strikePrice',
  'investmentDate',
];

const optionsColumns: (keyof ICaptableInvestmentDataModel)[] = [
  'name',
  'authorizedShares',
  'fullyDilutedShares',
  'outstandingEquityAwardDerivatives',
  'totalOptionPool',
  'outstandingCommittedRestrictedStockAwards',
  'strikePrice',
  'investmentDate',
];

const TypeToColumns: Record<CapTableInvestmentType, (keyof ICaptableInvestmentDataModel)[]> = {
  'share-class': shareClassColumns,
  'warrant-block': warrantColumns,
  'note-block': noteBlockColumns,
  'option-pool': optionsColumns,
};

export function useInvestmentColDefs(type: CapTableInvestmentType): ColDef<ICaptableInvestmentDataModel>[] {
  const schemaToColDef = useSchemaToGrid();

  return useMemo(() => {
    let defs;
    if (type === 'share-class') {
      const participationTypeField: ColDef<ICaptableInvestmentDataModel> = {
        colId: 'participationType',
        field: 'participationType',
        headerName: 'Participation Type',
      };
      const allFields = schemaToColDef(captableInvestmentGridSchema(), TypeToColumns[type]);

      const insertIndex = allFields.findIndex((cd) => cd.field === 'pariPassu');
      defs = [
        ...allFields.slice(0, insertIndex),
        participationTypeField,
        ...allFields.slice(insertIndex),
      ] as ColDef<ShareClass | ICaptableInvestmentDataModel>[];
    } else {
      defs = schemaToColDef(captableInvestmentGridSchema(), TypeToColumns[type]);
    }

    return withOverrides(
      defs as ColDef<ICaptableInvestmentDataModel>[],
      ColDefOverrides
    ) as ColDef<ICaptableInvestmentDataModel>[];
  }, [schemaToColDef, type]);
}

export const DefaultColDefs = {
  flex: 1,
  minWidth: 150,
};
