import { FC, useCallback, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Typography,
  useTheme,
  Button,
  Collapse,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ConnectIcon from '../../../../assets/icons/connect.svg?react';
import { AirtableSyncType, IBaseColumnDataModel } from '../../../../data-models/airtable-sync.data-model';
import { InfoBox } from '../../../Mappings/forms/InfoBox';
import { StyledTooltip } from '../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { StyledColumnBox, StyledSyncGrid } from './CommonStyledComponents';

const editableFieldMessage = 'Changes that you make to the Airtable will reflect in Foresight';
const readonlyFieldMessage = 'This field will not be editable in Airtable';

const ActionContainer = styled('div')`
  display: grid;
  align-items: center;
  column-gap: 0.75rem;
`;

const SecondaryColumnContainer = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 12rem 1.5rem;
  column-gap: 0.75rem;
  grid-auto-flow: dense;
`;

interface IColumnSyncherProps {
  fixedColumnName: string;
  options: IBaseColumnDataModel[];
  value: IBaseColumnDataModel | null;
  onChange: (value: IBaseColumnDataModel | null) => void;
  syncTypeValue: AirtableSyncType | null;
  onSyncTypeChange: (value: AirtableSyncType) => void;
  disabled?: boolean;
  readonly?: boolean;
  isAlreadySynced?: boolean;
  info?: string;
  hasError?: boolean;
}

export const ColumnSyncher: FC<IColumnSyncherProps> = ({
  fixedColumnName,
  options,
  value,
  onChange,
  syncTypeValue,
  onSyncTypeChange,
  disabled,
  readonly,
  isAlreadySynced,
  info,
  hasError,
}) => {
  const [showSelect, setShowSelect] = useState(Boolean(value));
  const navigate = useNavigate();

  const { colors } = useTheme();
  const disableSyncTypeChange = disabled || readonly || !syncTypeValue;
  const iconColor = disableSyncTypeChange ? colors.neutral[40] : colors.primary[60];

  const showInfo = !disabled && Boolean(syncTypeValue) && Boolean(value);

  const handleSyncTypeChange = useCallback(() => {
    if (!syncTypeValue) return;
    if (syncTypeValue === AirtableSyncType.editable) {
      onSyncTypeChange(AirtableSyncType.readOnly);
    } else {
      onSyncTypeChange(AirtableSyncType.editable);
    }
  }, [onSyncTypeChange, syncTypeValue]);

  return (
    <StyledSyncGrid>
      <ActionContainer>
        {showSelect ? (
          <>
            <Autocomplete
              disabled={disabled}
              value={value}
              onChange={(e, newValue) => onChange(newValue)}
              options={options}
              getOptionLabel={(option) => option.displayName}
              renderInput={(params) => (
                <TextField {...params} autoFocus sx={{ margin: 0 }} error={hasError} />
              )}
              openOnFocus
              blurOnSelect
            />
          </>
        ) : (
          <Button color='secondary' onClick={() => setShowSelect(true)} style={{ justifySelf: 'start' }}>
            Select Field
          </Button>
        )}
      </ActionContainer>

      <Tooltip
        placement='top'
        title={
          disableSyncTypeChange
            ? ''
            : syncTypeValue === AirtableSyncType.readOnly
            ? 'Change to pull from Airtable'
            : syncTypeValue === AirtableSyncType.editable
            ? 'Change to pull from Foresight'
            : ''
        }
      >
        <div>
          <IconButton disabled={disableSyncTypeChange} onClick={handleSyncTypeChange}>
            {syncTypeValue === AirtableSyncType.editable ? (
              <ArrowBackIcon htmlColor={iconColor} />
            ) : syncTypeValue === AirtableSyncType.readOnly ? (
              <ArrowForwardIcon htmlColor={iconColor} />
            ) : (
              <ConnectIcon fill={colors.neutral[20]} />
            )}
          </IconButton>
        </div>
      </Tooltip>

      <SecondaryColumnContainer>
        <StyledColumnBox>
          <Typography variant='body2' color={disabled ? colors.neutral[40] : undefined}>
            {fixedColumnName}
          </Typography>
        </StyledColumnBox>

        {isAlreadySynced ? (
          <Link
            style={{ gridColumn: '2 / -1', textAlign: 'left' }}
            underline='hover'
            color='secondary'
            onClick={() => navigate(`/integrations/apps/AirTable?tab=established-sync`)}
            variant='body2'
            component='button'
          >
            More details in Established Sync
          </Link>
        ) : null}

        {info ? (
          <StyledTooltip title={info} placement='top'>
            <InfoOutlinedIcon fontSize='small' htmlColor={colors.neutral[40]} />
          </StyledTooltip>
        ) : null}

        {!disabled && Boolean(value) && (
          <IconButton
            style={{ gridColumn: '3' }}
            onClick={() => {
              if (value) onChange(null);
              setShowSelect(false);
            }}
            aria-label='deselect-field'
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        )}
      </SecondaryColumnContainer>

      <Collapse in={showInfo} style={{ gridColumn: '1/-1', marginBottom: showInfo ? '0.5rem' : 0 }}>
        <InfoBox
          message={syncTypeValue === AirtableSyncType.editable ? editableFieldMessage : readonlyFieldMessage}
        />
      </Collapse>
    </StyledSyncGrid>
  );
};
