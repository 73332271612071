import { FC, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { ButtonBase, Checkbox, IconButton, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { theme } from '../../../../../theme';
import { ICategoryDataModel } from '../../../../../data-models/package.data-model';
import { selectedDashboardConfigCI } from '../../../../../pages/CompetitiveIntelligence/state/DashboardsState';
import { useUpdateDashboardConfig } from '../../../../../pages/CompetitiveIntelligence/hooks/useDashboardActions';
import { Category as CategoryComp } from './Category';

const { secondary } = theme.colors;

const checkboxStyles = {
  color: '#CCBFF3',
  '&.Mui-checked': {
    color: '#161957',
  },
  '&.Mui-checked input': {
    borderRadius: '5px',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#161957',
  },
  '&.MuiCheckbox-indeterminate:after': {
    content: '""',
    height: 15,
    width: 15,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: -1,
  },
  '&.Mui-checked:after': {
    content: '""',
    height: 15,
    width: 15,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: -1,
  },
  padding: 0,
};

interface IProps {
  name: string;
  categories: ICategoryDataModel[];
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 10px;
  border-radius: 4px;
  gap: 0.7rem;
`;

const ExpandWrapper = styled('div')`
  cursor: pointer;
  color: #fff;
`;

export const Package: FC<IProps> = ({ name, categories }) => {
  const dConfig = useRecoilValue(selectedDashboardConfigCI);
  const updateDashboardConfig = useUpdateDashboardConfig();
  const [isOpen, setIsOpen] = useState(true);

  const isCheckedAll = useMemo(
    () => categories?.every(({ id }) => dConfig?.selectedCategories.find((cId) => cId === id)),
    [categories, dConfig?.selectedCategories]
  );

  const isCheckedAny = useMemo(() => {
    return (
      categories?.some(({ id }) => dConfig?.selectedCategories.find((cId) => cId === id)) && !isCheckedAll
    );
  }, [categories, dConfig?.selectedCategories, isCheckedAll]);

  const handleHeaderToggle = () => {
    if (!dConfig) return;
    const { selectedCategories } = dConfig;
    const filteredCategories = selectedCategories.filter(
      (cId) => !categories.some((category) => category.id === cId)
    );
    const updatedCategories = isCheckedAll
      ? filteredCategories
      : [...filteredCategories, ...categories.map((category) => category.id)];

    updateDashboardConfig({ ...dConfig, selectedCategories: updatedCategories });
  };

  if (!categories?.length) return null;

  return (
    <Wrapper data-testid='package'>
      <Header>
        <ButtonBase
          TouchRippleProps={{ color: 'white' }}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'centerj',
            justifyContent: 'start',
            gap: '0.7rem',
          }}
          onClick={handleHeaderToggle}
        >
          <Checkbox
            className={'custom-checkbox'}
            checked={isCheckedAll}
            indeterminate={isCheckedAny}
            onChange={handleHeaderToggle}
            sx={checkboxStyles}
          />
          <Typography variant={'caption'} color={secondary[10]}>
            {name}
          </Typography>
        </ButtonBase>
        <ExpandWrapper onClick={() => setIsOpen((prevState) => !prevState)}>
          <IconButton>
            {!isOpen ? (
              <ChevronRightIcon style={{ color: '#ECE5FF' }} />
            ) : (
              <ExpandMoreIcon style={{ color: '#ECE5FF' }} />
            )}
          </IconButton>
        </ExpandWrapper>
      </Header>
      {isOpen &&
        categories.map(({ displayName, id: categoryId }) => (
          <CategoryComp id={categoryId} name={displayName} key={categoryId} />
        ))}
    </Wrapper>
  );
};
