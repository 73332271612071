import * as yup from 'yup';
import { minZeroMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { integerField, pricePerShareField } from '../../../../../../../schemas/common-schema-defs';

export function eqShareExchangeFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    receivedSharesNo: yup
      .number()
      .label('Received Shares')
      .nullable()
      .required(requiredMsg('Received Shares'))
      .min(0, minZeroMsg('Received Shares'))
      .typeError(typeMsg('Received Shares', 'number')),
    receivedPricePerShare: pricePerShareField()
      .label('Received PPS')
      .nullable()
      .required(requiredMsg('Received PPS'))
      .min(0, minZeroMsg('Received PPS'))
      .typeError(typeMsg('Received PPS', 'number')),
    currencyid: transactionCurrencyIdField(),
    fullyDilutedShares: integerField().nullable().optional(),
    notes: yup
      .string()
      .nullable()
      .optional()
      .transform((_, val) => (val === val ? val : null)),
    postMoneyValuation: yup
      .number()
      .nullable()
      .min(0, minZeroMsg('Post Money Valuation'))
      .typeError(typeMsg('Post Money Valuation', 'number')),
    security: yup.string().label('Received Security').nullable(),
  };
}

export function eqShareExchangeFormSchema() {
  return yup.object(eqShareExchangeFields());
}

export function eqShareExchangeGridSchema() {
  return yup.object(eqShareExchangeFields()).omit(['fullyDilutedShares', 'postMoneyValuation']);
}
