import { Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import OneSchemaImporter from '@oneschema/react';
import { useCallback, useRef, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { CompanySearch } from '../../../../components/CompanySearch/CompanySearch';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { ListType } from '../../../../data-models/list.data-model';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { getEnvVariable, isDevelopment } from '../../../../util/environment';
import { activeCompanyListIdState, activeListState, companyListState } from '../../state/ListsState';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { useListUploadActions } from '../../../../services/hooks/useListActions';
import { ZINDEX } from '../../../../constants/styles';
import { CompanySearchResponseWithRealId } from '../../../../schemas/CompanySearchResponse.schema';
import { CreateListItemForm } from '../CreateListItemForm/CreateListItemForm';

const ButtonAndSearchContainer = styled('div')<{ outlined?: boolean }>`
  display: flex;
  justify-content: end;
  padding: 1rem 0;
  border-radius: 4px;
  min-width: max(23rem, 100%);
  ${({ outlined }) =>
    outlined &&
    'background: white; box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06), 0px 24px 48px rgba(75, 82, 93, 0.04); padding: 1rem;'}
`;

export const ListUploadButtons = () => {
  const oneSchemaClientId = getEnvVariable('VITE_ONESCHEMA_CLIENT_ID');
  const webhookKey = getEnvVariable('VITE_ONESCHEMA_WEBHOOK_KEY');

  const activeListId = useRecoilValue(activeCompanyListIdState);
  const activeList = useRecoilValue(activeListState);

  const companyList = useRecoilValueLoadable(companyListState(activeListId));
  const [showImportModal, setShowImportModal] = useState(false);
  const { pushErrorToast } = useToastMessageState();
  const { refreshListData, addCompanyToList } = useListUploadActions();
  const ref = useRef<HTMLDivElement>(null);
  const [isCompanySearchActive, setIsCompanySearchActive] = useState(false);
  useOutsideClick(ref, () => setIsCompanySearchActive(false));
  const { onOpenModal } = useModalState();

  const list = companyList.valueMaybe();

  const canEditLists = PermissionService.get().hasPermission(PermissionKey.canEditList);

  const handleAdd = useCallback(
    async (selectedCompany: CompanySearchResponseWithRealId) => {
      setIsCompanySearchActive(false);
      if (!activeList) return;
      await addCompanyToList(activeList, selectedCompany);
    },
    [activeList, addCompanyToList]
  );

  const handleSuccessBulkUpload = async () => {
    if (!activeList) return;
    setShowImportModal(false);
    await refreshListData(activeList);
  };

  const handleErrorBulkUpload = () => {
    pushErrorToast({ message: 'An error occurred while uploading the file' });
  };

  const onCreateListItemClick = useCallback(
    (name: string) => {
      // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-4072
      onOpenModal(<CreateListItemForm name={name} />);
    },
    [onOpenModal]
  );

  if (!(list && canEditLists && activeList?.type === ListType.DISCOVERY)) return null;

  return (
    <>
      <ButtonAndSearchContainer ref={ref} outlined={isCompanySearchActive}>
        {isCompanySearchActive ? (
          <CompanySearch
            showCreateOption
            onCompanyCreateClick={(e) => onCreateListItemClick(e)}
            onChange={handleAdd}
            autoFocus={true}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': { backgroundColor: 'white' },
              '& .MuiFormControl-root': { margin: 0 },
            }}
          />
        ) : (
          <Stack direction='row' gap='1rem'>
            <Button
              onClick={() => setShowImportModal(true)}
              variant='outlined'
              color='secondary'
              startIcon={<FileUploadOutlinedIcon />}
              size='medium'
            >
              Bulk Upload
            </Button>

            <Button
              onClick={() => setIsCompanySearchActive(true)}
              variant='contained'
              color='secondary'
              startIcon={<AddIcon />}
              size='medium'
            >
              Add Company
            </Button>
          </Stack>
        )}
      </ButtonAndSearchContainer>
      {showImportModal && (
        <OneSchemaImporter
          isOpen={showImportModal}
          onRequestClose={() => setShowImportModal(false)}
          clientId={oneSchemaClientId}
          userJwt={String(list.token)}
          templateKey='company_lists_upload'
          importConfig={{ type: 'webhook', key: webhookKey }}
          devMode={isDevelopment()}
          className='oneschema-importer'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: ZINDEX.UPLOAD_MODAL,
          }}
          onSuccess={handleSuccessBulkUpload}
          onError={handleErrorBulkUpload}
        />
      )}
    </>
  );
};
