import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { KPI_COMPONENTS, Kpi } from '../../../../types';
import { useKPIs } from '../../../../pages/PortfolioOverview/hooks/useKPIs';
import { ValuationBridgeDataItem } from '../../chart-utils';

const formatValue = (value: number) => Math.round(value);

export const useValuationBridge = (): ValuationBridgeDataItem[] => {
  const { kpis } = useKPIs();
  const { primary, tertiary, secondary } = useTheme().colors;

  return useMemo(() => {
    if (!kpis || !kpis[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT]?.value) {
      return [];
    }

    const totalInvestment = kpis[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT].value;

    return [
      {
        ...getValuationBridgeItem(kpis[KPI_COMPONENTS.TOTAL_REALIZED_VALUE], totalInvestment),
        colour: tertiary[60],
        hidden: 0,
      },
      {
        ...getValuationBridgeItem(kpis[KPI_COMPONENTS.TOTAL_UNREALIZED_VALUE], totalInvestment),
        colour: primary[60],
        hidden: formatValue(kpis[KPI_COMPONENTS.TOTAL_REALIZED_VALUE].value),
      },
      {
        ...getValuationBridgeItem(kpis[KPI_COMPONENTS.POTENTIAL_UPSIDE], totalInvestment),
        colour: secondary[40],
        hidden: formatValue(
          kpis[KPI_COMPONENTS.TOTAL_REALIZED_VALUE].value + kpis[KPI_COMPONENTS.TOTAL_UNREALIZED_VALUE].value
        ),
      },
      {
        ...getValuationBridgeItem(kpis[KPI_COMPONENTS.TOTAL_PROJECTED_VALUE], totalInvestment),
        colour: primary[60],
        hidden: 0,
        isNegative: false,
      },
      {
        amount: kpis[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT].value,
        colour: primary[80],
        hidden: 0,
        isNegative: false,
        moic: formatValue(
          kpis[KPI_COMPONENTS.TOTAL_PROJECTED_VALUE].value -
            kpis[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT].value
        ),
        multiplier: kpis[KPI_COMPONENTS.PROJECTED_MOIC].formatted,
        name: 'Investment Amount',
        value: formatValue(kpis?.[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT].value),
      },
    ];
  }, [kpis, primary, tertiary, secondary]);
};

function getValuationBridgeItem(
  kpi: Kpi,
  totalInvestment: number
): Pick<ValuationBridgeDataItem, 'amount' | 'multiplier' | 'name' | 'value'> {
  return {
    amount: kpi.value,
    multiplier: ((kpi.value ?? 0) / (totalInvestment ?? 1)).toFixed(2),
    name: kpi.title,
    value: formatValue(kpi.value),
  };
}
