import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';
import { Button, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { colors } from '../../../theme/colors';

const overlayContainer = css`
  display: grid;
  gap: 1rem;
`;

const overlayed = css`
  grid-column: 1;
  grid-row: 1;
`;

const editOverlay = css`
  ${overlayContainer};
  margin-block: 1rem;
  padding: 4rem 3rem;
  background: inherit;
  transition: all 0.5s;
  transform-origin: top left;
  transform: scale(0.5);
  width: 200%;
  height: 200%;

  &:hover {
    background: ${colors.primary[10]}88;
  }
  & fieldset {
    ${overlayed};
  }
  &:hover button.edit-display-button {
    opacity: 1;
  }
`;

const editButton = css`
  ${overlayed};
  justify-self: center;
  align-self: center;
  transform: scale(150%);
  opacity: 0;
  transition: all 0.5s;
`;

const sectionTitle = css`
  ${overlayed};
  transform: translate(0, -3rem);
  font-size: 160%;
`;

interface IEditSectionOverlayProps extends PropsWithChildren {
  title: string;
  onEdit: () => void;
}
export function EditSectionOverlay({ children, onEdit, title }: IEditSectionOverlayProps) {
  return (
    <div css={editOverlay}>
      <Typography variant='body2' css={sectionTitle}>
        {title}
      </Typography>
      <UnstyledFieldset disabled>{children}</UnstyledFieldset>
      <Button
        variant='contained'
        color='secondary'
        size='large'
        startIcon={<EditOutlinedIcon />}
        css={editButton}
        onClick={onEdit}
        className='edit-display-button'
      >
        Edit
      </Button>
    </div>
  );
}
