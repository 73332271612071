import { useMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../../constants/RouteNameMapping';
import { NavigationWrapper } from '../NavigationWrapper';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import SubSectionNavItems from '../../SubSectionNavigation/SubSectionNavItems';
import { templateAssignmentsByCompanyIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { kpiRequestsCountState } from '../../../../../services/state/KPI/KPIRequestsState';
import { KPITemplatesSubNavigation } from './KPITemplatesSubNavigation';

export const KPINavigation = () => {
  const match = useMatch(`/${ROUTES.KPI}/*`);
  const nAssigned = useRecoilValue(templateAssignmentsByCompanyIdState)?.size;
  const reqResCount = useRecoilValue(kpiRequestsCountState);
  const notSentCount = reqResCount?.notSent;
  const toReviewCount = reqResCount?.submitted;
  const permissionService = PermissionService.get();

  const sectionsList = useMemo(() => {
    return [
      {
        id: `${ROUTES.KPI_REQUESTS}`,
        value: ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS],
        parentRoute: `${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`,
        badgeIndicator: notSentCount,
      },
      {
        id: `${ROUTES.KPI_RESPONSES}`,
        value: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES],
        parentRoute: `${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`,
        badgeIndicator: toReviewCount,
      },
      {
        id: ROUTES.COMPANIES,
        value: ROUTE_TITLE_MAPPING[ROUTES.COMPANIES],
        badgeIndicator: nAssigned,
      },
    ];
  }, [nAssigned, notSentCount, toReviewCount]);

  if (match === null || !permissionService.hasPermission(PermissionKey.canReadDataCollection)) return null;

  return (
    <NavigationWrapper title='Data Collection'>
      <SubSectionNavigation title='Management'>
        <SubSectionNavItems sectionList={sectionsList} parentRoute='kpis' />
      </SubSectionNavigation>

      <KPITemplatesSubNavigation />
    </NavigationWrapper>
  );
};
