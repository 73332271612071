import { FieldEntity } from '../../../data-models/field2.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { useDealStageField } from '../../../components/Form/StandardFields';
import { createField3DataModel, ITextMeta } from '../../../data-models/field3.data-model';

export function useDealTypeFields() {
  const dealStageField = useDealStageField();

  return [
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: FieldEntity.deal,
      entityKey: 'name',
      displayName: 'Name',
      format: RendererType.text,
    }),
    dealStageField,
  ];
}
