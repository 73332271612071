import { DealBoardDealCategory } from '../../../types';

export function getDealCategory(
  dealStageIdToCategory: Map<DealBoardDealCategory, Set<number>>,
  dealStageId: number
) {
  for (const [categoryName, stageIds] of dealStageIdToCategory) {
    if (stageIds.has(dealStageId)) {
      return categoryName;
    }
  }

  return DealBoardDealCategory.UNKNOWN;
}
