import { useSortable } from '@dnd-kit/sortable';
import { ReactElement, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { CSS } from '@dnd-kit/utilities';

export const StyledChartWrapper = styled('div')<{
  width: string | number;
  padding?: string;
}>`
  display: inline-flex !important;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : '24px')};
  grid-column: ${({ width }) => (width === '1/2' ? 'span 2' : width === '1/4' ? 'span 1' : 'span 4')};

  &:hover {
    & * {
      opacity: 1;
    }
  }
`;

export const InnerChartWrapper = styled('div')`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  width: 100%;
`;

interface ChartWrapperProps {
  children: ReactNode;
  dataTestid?: string;
  id: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  padding?: string;
  width: string | number;
}

export const ChartWrapper = ({
  children,
  dataTestid,
  id,
  onMouseEnter,
  onMouseLeave,
  padding = '1rem',
  width,
}: ChartWrapperProps): ReactElement => {
  const { setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <StyledChartWrapper
      ref={setNodeRef}
      style={style}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      width={width}
      data-testid={dataTestid}
      padding={padding}
    >
      <InnerChartWrapper>{children}</InnerChartWrapper>
    </StyledChartWrapper>
  );
};
