import { useCallback } from 'react';
import { FieldSettings } from '../../../data-fields/CommonFields';
import { transactionDateField } from '../../../data-fields/TransactionFields';
import { INumberMeta } from '../../../data-models/field3.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { IFormFieldSelectMeta, createFormField } from '../../../view-models/form.view-model';
import { OwnershipChangeReason } from '../../../data-models/transaction.data-model';

export function useMiscOwnershipChangeFields() {
  return useCallback(() => {
    return [
      transactionDateField(),
      createFormField<INumberMeta>({
        key: 'fullyDilutedShares',
        label: 'Fully Diluted Shares',
        required: true,
        disabled: false,
        renderer: RendererType.integer,
      }),
      createOwnershipChangeReasonField(),
    ];
  }, []);
}

export function createOwnershipChangeReasonField({
  key = 'reason',
  label = 'Reason',
  required = false,
  disabled = false,
}: Partial<FieldSettings> = {}) {
  return createFormField<IFormFieldSelectMeta<string>>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(OwnershipChangeReason).map((type) => ({
        value: type,
        displayName: type,
      })),
      multi: false,
    },
  });
}
