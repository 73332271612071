import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { useSaveCompanyData } from '../../../services/hooks/useSaveCompanyData';
import { fetchDealsByStageId } from '../../../services/queries/MaggieDealQueries';
import { DealBoardDealCategory, LoadingId, LoadingStatus } from '../../../types';
import {
  allDealsLoadStatusState,
  allDealsState,
  dealBoardConfigState,
  dealStageIdToCategoryState,
  dealStagesByIdMapState,
  dealTypesByIdMapState,
} from '../state/DealboardDataState';
import { FormatterService } from '../../../util/formatter-service';
import { DealStageFormatter } from '../../../util/formatters/DealStageFormatter';
import { DealTypeFormatter } from '../../../util/formatters/DealTypeFormatter';
import { useSyncFromPath } from './useSyncFromPath';

const categories = [
  DealBoardDealCategory.CURRENT,
  DealBoardDealCategory.PASS,
  DealBoardDealCategory.TRACK,
  DealBoardDealCategory.MISSED,
  DealBoardDealCategory.CLOSED,
];

export function useInitDealBoard() {
  const dealBoardConfig = useRecoilValueLoadable(dealBoardConfigState);
  const initialized = useSyncFromPath();
  const fetchAllDeals = useFetchAllDeals();
  useInitDealFormatters();

  useEffect(() => {
    if (dealBoardConfig.state === 'hasValue' && initialized) {
      fetchAllDeals();
    }
  }, [dealBoardConfig.state, fetchAllDeals, initialized]);

  return initialized;
}

export function useFetchAllDeals() {
  const dealStageIdToCategory = useRecoilValue(dealStageIdToCategoryState);
  const { actions } = useLoadingBarState();
  const { batchSaveCompanyData } = useSaveCompanyData();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const { status, lastUpdated } = snapshot.getLoadable(allDealsLoadStatusState).getValue();

        if (status === LoadingStatus.idle) {
          set(allDealsState, []);
        }

        if (status !== LoadingStatus.loading) {
          actions.startLoading(LoadingId.refreshAllDeals);
          set(allDealsLoadStatusState, { status: LoadingStatus.loading, lastUpdated: new Date() });

          const res = categories.map((category) => {
            return fetchDealsForCategory(category, dealStageIdToCategory, lastUpdated).then(
              (updatedDeals) => {
                const updatedDealsId = updatedDeals.deals.reduce(
                  (res, deal) => res.add(deal.id),
                  new Set<number>()
                );
                set(allDealsState, (curr) => {
                  const unchangedDeals = curr.filter((deal) => !updatedDealsId.has(deal.id));
                  return unchangedDeals.concat(updatedDeals.deals);
                });
                batchSaveCompanyData(updatedDeals.companies);
              }
            );
          });

          try {
            await Promise.all(res);
            set(allDealsLoadStatusState, (curr) => {
              return { status: LoadingStatus.success, lastUpdated: curr.lastUpdated };
            });
          } catch (err) {
            set(allDealsLoadStatusState, { status: LoadingStatus.error, lastUpdated: undefined });
          } finally {
            actions.stopLoading(LoadingId.refreshAllDeals);
          }
        }
      },
    []
  );
}

function fetchDealsForCategory(
  categoryId: DealBoardDealCategory,
  dealStageIdToCategory: Map<DealBoardDealCategory, Set<number>>,
  lastUpdated?: Date
) {
  let stageIds: number[] = [];

  if (!lastUpdated) {
    if (categoryId === DealBoardDealCategory.PASS) {
      stageIds = Array.from(dealStageIdToCategory.get(DealBoardDealCategory.PASS) ?? []);
    } else if (categoryId === DealBoardDealCategory.MISSED) {
      stageIds = Array.from(dealStageIdToCategory.get(DealBoardDealCategory.MISSED) ?? []);
    } else {
      stageIds = Array.from(dealStageIdToCategory.get(categoryId) ?? []);
    }
  }

  return fetchDealsByStageId(stageIds, lastUpdated);
}

export function useInitDealFormatters() {
  const [status, setStatus] = useState(LoadingStatus.loading);
  const dealStages = useRecoilValue(dealStagesByIdMapState);
  const dealTypes = useRecoilValue(dealTypesByIdMapState);

  useEffect(() => {
    const formatterService = FormatterService.get();
    formatterService.setFormatterForId('dealStage', new DealStageFormatter().init(dealStages).format);
    formatterService.setFormatterForId('dealType', new DealTypeFormatter().init(dealTypes).format);
    setStatus(LoadingStatus.success);
  }, [dealStages, dealTypes]);

  return status;
}
