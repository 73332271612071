import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dashboard, ViewAction, ViewActionName } from '../../../../../types';
import {
  useCreateDashboard,
  useDeleteDashboard,
  useDuplicateDashboard,
  useRenameDashboard,
} from '../../../../../pages/CompetitiveIntelligence/hooks/useDashboardActions';
import { theme } from '../../../../../theme';
import { Icon, IconTypes } from '../../../../Icon';
import { EditableTypography } from '../../../../EditableTypography/EditableTypography';
import { ConfirmDeletionDialog } from '../../../../Dialog/ConfirmDialog/ConfirmDeletionDialog';

const Wrapper = styled('div')<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 8px 0px 8px 8px;
  ${({ isSelected }) => (isSelected ? 'background-color: #2D3277;' : '')}
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    #dropdown-wrapper {
      display: block;
    }
  }
`;

const IconWrapper = styled('div')`
  position: relative;
  top: 2px;
  margin-left: 4px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

const CloseIconWrapper = styled('div')``;

const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const DropdownWrapper = styled('div')<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const ItemWrapper = styled('div')``;

const EditableTypographyWrapper = styled('div')``;

export interface DashboardMenuItemProps {
  dashboard: Dashboard;
  isSelected: boolean;
  createMode?: boolean;
  setCreateMode: Dispatch<SetStateAction<boolean>>;
  onSelect?: (id: number) => void;
}

export function DashboardMenuItem({
  dashboard,
  isSelected,
  onSelect,
  createMode,
  setCreateMode,
}: DashboardMenuItemProps) {
  const { id, displayName, isDefault } = dashboard;
  const ref = useRef<null | HTMLDivElement>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [editable, setEditable] = useState(false);
  const [options, setOptions] = useState<ViewAction[]>([]);
  const createDashboard = useCreateDashboard();
  const duplicateDashboard = useDuplicateDashboard();
  const deleteDashboard = useDeleteDashboard();
  const renameDashboard = useRenameDashboard();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { primary, secondary, neutral } = theme.colors;

  const defaultOptions: ViewAction[] = useMemo(
    () => [
      {
        value: 'Duplicate Dashboard',
        id: ViewActionName.DUPLICATE,
        icon: <ContentCopyIcon fontSize='small' />,
      },
    ],
    []
  );
  const personalOptions: ViewAction[] = useMemo(
    () => [
      { value: 'Rename', id: ViewActionName.RENAME, icon: <EditIcon fontSize='small' /> },
      {
        value: 'Duplicate Dashboard',
        id: ViewActionName.DUPLICATE,
        icon: <ContentCopyIcon fontSize='small' />,
      },
      {
        value: 'Delete Dashboard',
        id: ViewActionName.DELETE,
        icon: <DeleteOutlineIcon fontSize='small' />,
        hasSeparator: true,
      },
    ],
    []
  );

  const handleConfirmDelete = useCallback(async () => {
    setShowConfirmDelete(false);
    await deleteDashboard(dashboard);
  }, [dashboard, deleteDashboard]);

  const handleCancelCreate = () => {
    setCreateMode(false);
  };

  const handleDoubleClick = () => {
    if (!dashboard.isDefault) {
      setEditable(true);
    }
  };

  const onSaveNewName = useCallback(
    async (newName: string) => {
      if (createMode) {
        return await createDashboard(newName, () => setCreateMode(false));
      }
      await renameDashboard(dashboard, newName);
      setEditable(false);
    },
    [createDashboard, createMode, dashboard, renameDashboard, setCreateMode]
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (createMode) setEditable(true);
  }, [createMode, setEditable]);

  useEffect(() => {
    if (isDefault) {
      setOptions(defaultOptions);
      return;
    }
    setOptions(personalOptions);
  }, [defaultOptions, isDefault, personalOptions]);

  const onClick = useCallback(
    async (e: MouseEvent<HTMLElement>, id: ViewActionName) => {
      e.stopPropagation();
      handleCloseMenu();
      if (id === ViewActionName.DUPLICATE) {
        await duplicateDashboard(dashboard);
      } else if (id === ViewActionName.RENAME) {
        setEditable(true);
      } else if (id === ViewActionName.DELETE) {
        setShowConfirmDelete(true);
      }
    },
    [dashboard, duplicateDashboard]
  );

  return (
    <Wrapper isSelected={isSelected} onClick={() => onSelect && onSelect(id)} ref={ref}>
      <InfoWrapper>
        {!isDefault && (
          <IconWrapper>
            <Icon type={IconTypes.USER_HEART} color={primary[0]} />
          </IconWrapper>
        )}
        <EditableTypographyWrapper onDoubleClick={handleDoubleClick}>
          <EditableTypography
            submitOnBlur={false}
            variant='caption'
            color={secondary[10]}
            content={displayName}
            editable={editable}
            handleSubmit={onSaveNewName}
            placeholder='New Dashboard Name'
            width={185}
            inputColor={secondary[10]}
          />
        </EditableTypographyWrapper>
      </InfoWrapper>
      {createMode && (
        <CloseIconWrapper onClick={handleCancelCreate}>
          <Icon type={IconTypes.CLOSE_MENU_2} color={secondary[10]} />
        </CloseIconWrapper>
      )}
      {isSelected && !createMode && (
        <DropdownWrapper id='dropdown-wrapper' isOpen={open}>
          <IconButton
            data-testid='menu-btn'
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
          >
            <MoreVertIcon style={{ color: '#ECE5FF' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              handleCloseMenu();
            }}
            disablePortal
            PaperProps={{
              style: {
                width: '210px',
                transform: 'translateX(-95px)',
              },
            }}
          >
            {options.map(({ id, hasSeparator, icon, value }) => (
              <ItemWrapper key={id}>
                {hasSeparator && <Divider />}
                <MenuItem onClick={(e) => onClick(e, id)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <Typography variant='body2' color={primary[100]}>
                    {value}
                  </Typography>
                </MenuItem>
              </ItemWrapper>
            ))}
          </Menu>
        </DropdownWrapper>
      )}
      <ConfirmDeletionDialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={handleConfirmDelete}
        title='Are you sure you want to delete this dashboard?'
      >
        <Typography variant='body2' color={neutral[60]}>
          You will not be able to recover it
        </Typography>
      </ConfirmDeletionDialog>
    </Wrapper>
  );
}
