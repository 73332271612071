import { Stack, styled } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { KPIItemFactory } from './FormItem/KPIItemFactory';
import { KPIBuildItemFactory } from './FormPreviewItem/KPIBuildItemFactory';

const SortableItemWrapper = styled('div')`
  width: 100%;
`;

interface ISectionItem {
  sectionRef: string;
  onRemoveSection: () => void;
  sectionId: string;
}

export function SectionItem({ onRemoveSection, sectionRef, sectionId }: ISectionItem) {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id: sectionId });

  const style = {
    // Use CSS.Translate instead of Transform to avoid weird behavior while dragging
    transform: CSS.Translate.toString(transform),
  };

  return (
    <Stack gap='1rem' direction='row' sx={{ marginBottom: '2rem' }}>
      <SortableItemWrapper ref={setNodeRef} style={style}>
        <KPIBuildItemFactory
          attributes={attributes}
          listeners={listeners}
          sectionRef={sectionRef}
          onRemoveSection={onRemoveSection}
        />
      </SortableItemWrapper>
      <KPIItemFactory sectionRef={sectionRef} />
    </Stack>
  );
}
