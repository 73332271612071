import { FocusEvent, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ReadWriteFieldProps = TextFieldProps & {
  onValueChanged: (newValue: string) => void;
  focusOnEnd?: boolean;
};

export function FormTextField(props: ReadWriteFieldProps) {
  const { onValueChanged, className, focusOnEnd, ...rest } = props;
  const triggerChangeIfValueChanged = useCallback(
    (newValue: string) => {
      if (newValue !== props.defaultValue) {
        onValueChanged(newValue);
      }
    },
    [onValueChanged, props.defaultValue]
  );

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      triggerChangeIfValueChanged(event.target.value);
    },
    [triggerChangeIfValueChanged]
  );

  const onEnterPressed = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.onFocus) {
        props.onFocus(e);
      }
      if (focusOnEnd) {
        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
      }
    },
    [focusOnEnd, props]
  );

  return (
    <ReadWriteTextField
      {...rest}
      className={`read-write-input ${className ?? ''}`}
      inputProps={{
        ...props.inputProps,
        onKeyPress: onEnterPressed,
      }}
      onBlur={onBlur}
      onKeyPress={onEnterPressed}
      onFocus={handleFocus}
    />
  );
}

export const ReadWriteInputStyles = {
  '&.read-write-input': {
    margin: 0,
  },
  '&.read-write-input.aligned': {
    marginLeft: '-0.875rem',
  },
  '&.read-write-input .edit-icon': {
    visibility: 'hidden',
  },
  '&.read-write-input:hover .edit-icon': {
    visibility: 'visible',
  },
  '&.read-write-input .MuiInputBase-root': {
    background: 'white',
  },
  '&.read-write-input.transparent .MuiInputBase-root': {
    background: 'transparent',
  },
  '&.read-write-input .MuiInputBase-root .Mui-disabled': {
    WebkitTextFillColor: 'black',
    background: 'transparent',
  },
  '&.read-write-input .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  // Disabled should look plain.
  '&.read-write-input .Mui-disabled fieldset': {
    borderColor: 'transparent',
  },
  '&.read-write-input:hover .Mui-disabled fieldset': {
    borderColor: 'transparent',
  },
};
export const ReadWriteTextField = styled(TextField)({
  ...ReadWriteInputStyles,
});
