import { Typography, styled } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';

interface LabelProps extends PropsWithChildren {
  style?: CSSProperties;
}
export const DrawerLabel: FC<LabelProps> = ({ children, style }) => (
  <Typography variant='body2' component={'label'} style={style}>
    {children}
  </Typography>
);

export const MultiSelectDrawerLabel: FC<LabelProps> = ({ children, style }) => (
  <DrawerLabel style={{ alignSelf: 'start', marginTop: '0.5rem', ...style }}>{children}</DrawerLabel>
);

export const DrawerUneditableContent = styled(Typography)`
  padding-left: 1rem; // account for input padding
`;
