import { noop } from 'lodash-es';

type UndoAction = () => void;

export class AtomUtils {
  private undoActions = new Map<string, UndoAction>();
  static readonly instance = new AtomUtils();

  private constructor() {
    // Do nothing
  }

  getUndo(atomKey: string) {
    return this.undoActions.get(atomKey) ?? noop;
  }

  setUndo(atomKey: string, undoFn: UndoAction) {
    this.undoActions.set(atomKey, undoFn);
  }

  undo(atomKey: string) {
    this.undoActions.get(atomKey)?.();
  }
}
