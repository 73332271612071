import { FC, useMemo, memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { useTransactionModalActions } from '../hooks/useFinanceModalActions';
import { MenuButton } from './MenuButton';

export const MenuName = 'transaction actions';

export const TransactionsMenuCell: FC<ICellRendererParams<ITransactionDataModel>> = memo(
  function TransactionMenuCell({ data }: ICellRendererParams<ITransactionDataModel>) {
    const { handleClickAction, handleClickHistory } = useTransactionModalActions();
    const disableEdit = !data;
    const isTransactionConfirmed = useMemo(() => data && isConfirmed(data), [data]);

    const menuItems = useMemo(() => {
      if (!data) return [];
      return [
        {
          name: isTransactionConfirmed ? 'Edit Details' : 'Edit Transaction',
          icon: <EditOutlinedIcon aria-label='edit transaction' />,
          onClick: () => {
            handleClickAction(data, 'editTransaction');
          },
          hide: disableEdit,
        },
        {
          name: 'Manage Round',
          icon: <SettingsIcon aria-label='manage investment' />,
          onClick: () => {
            handleClickAction(data, 'assignToInvestment');
          },
          divider: true,
        },
        {
          name: 'History',
          icon: <HistoryIcon />,
          onClick: () => handleClickHistory(data),
        },
        {
          name: 'Delete',
          icon: <DeleteOutlinedIcon />,
          onClick: () => {
            handleClickAction(data, 'deleteTransaction');
          },
        },
      ];
    }, [data, disableEdit, handleClickAction, handleClickHistory, isTransactionConfirmed]);

    if (!data) return null;

    return <MenuButton menuItems={menuItems} label={MenuName} />;
  }
);

export function isConfirmed(transaction: ITransactionDataModel) {
  return transaction.status?.toLowerCase() === 'confirmed';
}
