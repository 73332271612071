import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
} from '../../../../data-models/captable2.data-model';
import { CapTableInvestmentType } from '../../../../schemas/CaptableInvestment.schema';
import { ShareClass } from '../../../../view-models/captable.view-model';
import { StakeholderType } from '../SummaryGrid/CaptableSummaryGrid';
import { isClientInvestment } from '../captable-utils';

export function useInvestmentRowData(
  captable: ICaptableDataModel,
  config?: {
    stakeholderFilter?: StakeholderType;
    investmentTypeFilter?: CapTableInvestmentType;
    fundFilter?: number[];
  }
): (ShareClass | ICaptableInvestmentDataModel)[] {
  const { stakeholderFilter, investmentTypeFilter, fundFilter } = config ?? {};
  return (
    captable.captableInvestments?.filter((i) => {
      return (
        passesStakeholderFilter(i, stakeholderFilter) &&
        passesInvestmentTypeFilter(i, investmentTypeFilter) &&
        passesFundFilter(i, fundFilter)
      );
    }) ?? []
  );
}

export function passesInvestmentTypeFilter(
  investment: ICaptableInvestmentDataModel,
  type?: CapTableInvestmentType
) {
  return !type || investment.investmentType === type;
}

export function passesStakeholderFilter(investment: ICaptableInvestmentDataModel, filter?: StakeholderType) {
  if (filter == StakeholderType.client) {
    return investment.stakeholderInvestments?.some((si) => isClientInvestment(si));
  } else if (filter === StakeholderType.external) {
    return investment.stakeholderInvestments?.some((si) => !isClientInvestment(si));
  } else {
    return true;
  }
}

export function passesFundFilter(investment: ICaptableInvestmentDataModel, funds?: number[]) {
  if (!funds || !funds.length) return true;
  return investment.stakeholderInvestments?.some((si) => si.fundId != null && funds.includes(si.fundId));
}

export function getFundsForInvestment(investment: ICaptableInvestmentDataModel) {
  return (
    investment.stakeholderInvestments?.reduce((acc, si) => {
      if (isClientInvestment(si) && typeof si.fundId === 'number') {
        acc.push(si.fundId);
      }
      return acc;
    }, [] as number[]) ?? []
  );
}

export function getFundsForCaptable(captable: ICaptableDataModel) {
  const allFunds: number[] = [];
  captable.captableInvestments?.forEach((i) => {
    allFunds.push(...getFundsForInvestment(i));
  });

  return new Set<number>(allFunds);
}
