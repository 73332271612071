import { List, ListItem, Stack, SxProps, Tooltip, Typography, styled } from '@mui/material';
import { MoreChip } from '../../../Companies/CompanyCard/ListWithMoreChip';

const TooltipContentStack = styled(Stack)`
  min-width: 10rem;
  padding: 0.75rem 1rem 0;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 0;
  font-size: 0.875rem;
`;

interface IMoreChipWithTooltip {
  title: string;
  moreChipTotal: number;
  moreChipSx?: SxProps;
  otherItems: string[];
}

export const MoreChipWithTooltip = ({
  otherItems,
  title,
  moreChipTotal,
  moreChipSx,
}: IMoreChipWithTooltip) => {
  if (moreChipTotal === 0) return null;

  return (
    <Tooltip
      placement='top-start'
      componentsProps={{
        tooltip: { sx: { color: 'black', maxWidth: 'auto' } },
      }}
      title={
        <TooltipContentStack alignItems='flex-start'>
          <Typography component='h6'>{title}</Typography>
          <List>
            {otherItems.map((label) => (
              <StyledListItem key={label}>{label}</StyledListItem>
            ))}
          </List>
        </TooltipContentStack>
      }
    >
      <MoreChip
        data-testid='morechip'
        sx={{ width: 'fit-content', height: 'fit-content', ...moreChipSx }}
      >{`+${moreChipTotal}`}</MoreChip>
    </Tooltip>
  );
};
