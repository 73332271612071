import { LoadingButton } from '@mui/lab';
import { Button, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  gap: 1rem;
  margin-top: 1rem;
`;

interface IFormFooterButtonsProps {
  isSubmitting: boolean;
  disabled: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: (() => void) | undefined;
}

export const FormFooterButtons = ({ isSubmitting, disabled, onClick, onClose }: IFormFooterButtonsProps) => {
  return (
    <ButtonContainer>
      <LoadingButton
        variant='contained'
        color='secondary'
        size='medium'
        loading={isSubmitting}
        loadingPosition='start'
        startIcon={isSubmitting ? <Icon /> : <></>}
        disabled={disabled}
        onClick={onClick}
      >
        {isSubmitting ? 'Processing' : 'Save Changes'}
      </LoadingButton>
      <Button type='button' variant='outlined' color='secondary' size='medium' onClick={onClose}>
        Cancel
      </Button>
    </ButtonContainer>
  );
};
