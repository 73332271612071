import { FC, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useTheme, Switch, Typography } from '@mui/material';
import { TransposedTable } from './TransposedTable';
import { DefaultTable } from './DefaultTable';

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: 1rem;
  gap: 0.8rem;
`;

export const CITable: FC = () => {
  const [transposed, setTransposed] = useState<boolean>(false);
  const { primary } = useTheme().colors;

  return (
    <>
      <SwitchWrapper>
        <Typography variant={'body2'} color={primary['100']}>
          Transpose
        </Typography>
        <Switch value={transposed} onChange={() => setTransposed((prev) => !prev)} />
      </SwitchWrapper>
      {transposed ? <DefaultTable /> : <TransposedTable />}
    </>
  );
};
