import { FC } from 'react';
import { ITransactionDataModel } from '../../../../data-models/transaction.data-model';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { useTransactionTitle } from '../../hooks/useTransactionTitle';
import { AssignToInvestmentForm } from './AssignToInvestmentForm';
import { useAssignToInvestmentSubmitHandler } from './formHelpers';

export interface ITransactionFormModalProps {
  open: boolean;
  onClose: () => void;
  transaction: ITransactionDataModel;
}

export const AssignToInvestmentModal: FC<ITransactionFormModalProps> = ({ open, onClose, transaction }) => {
  const { handleSubmit, loading } = useAssignToInvestmentSubmitHandler(onClose);
  const transactionTitle = useTransactionTitle(transaction);
  const title = `Manage Round for ${transactionTitle}`;

  return (
    <BasicDialog open={open} onClose={onClose} title={title}>
      {open ? (
        <AssignToInvestmentForm
          onClose={onClose}
          transaction={transaction}
          onSubmit={handleSubmit}
          loading={loading}
        />
      ) : (
        <div />
      )}
    </BasicDialog>
  );
};
