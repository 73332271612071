import { atomFamily } from 'recoil';
import { IProviderFieldsDataModel } from '../../data-models/provider-fields.data-model';
import { getProviderFields } from '../queries/MappingsQueries';

export const fieldsMappingByCompanyState = atomFamily<IProviderFieldsDataModel<unknown>[] | null, number>({
  key: 'fieldsMappingByCompanyState',
  default: null,
  effects: (companyId) => [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          const res = getProviderFields<unknown>(companyId);
          setSelf(res);
        } catch (error) {
          console.error(error);
          setSelf(null);
        }
      }
    },
  ],
});
