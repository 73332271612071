import { atom, selector } from 'recoil';
import { ColourSchemeGenerator, ItemsColourScheme } from '../../../util/ColourSchemeGenerator';
import { companyState } from '../../../services/state/CompanyState';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { primaryCompanyCI } from './DashboardsState';

// TODO: Rename file to CompaniesStateCI
export const competitorsCI = selector<ICompanyDataModel[] | undefined>({
  key: 'competitorsCI',
  get: ({ get }) => {
    const competitorsIds = get(competitorsIdsCI) ?? [];

    return competitorsIds
      .map((id) => get(companyState(id)))
      .filter((value) => value !== null) as unknown as ICompanyDataModel[];
  },
});

export const competitorsIdsCI = atom<number[] | undefined>({
  key: 'competitorsIdsCI',
  default: [],
});

export const currentCompaniesCI = selector<ICompanyDataModel[]>({
  key: 'currentCompaniesCI',
  get: ({ get }) => {
    const primary = get(primaryCompanyCI);
    const competitors = get(competitorsCI);

    return [primary, ...(competitors ?? [])].filter((c) => c !== undefined) as ICompanyDataModel[];
  },
});

export const companiesColoursCI = selector<ItemsColourScheme<number>>({
  key: 'currentCompaniesColoursCI',
  get: ({ get }) => {
    const companies = get(currentCompaniesCI);

    return ColourSchemeGenerator.getInstance().generateScheme<number>(
      'companiesCI',
      companies.map((c) => c.id)
    );
  },
});

export const selectedCompaniesCI = atom<ICompanyDataModel[]>({
  key: 'selectedCompaniesCI',
  default: currentCompaniesCI,
});
