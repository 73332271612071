import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { get } from 'lodash-es';
import * as yup from 'yup';
import { MainContainerWithAlert } from '../../components/commonStyledComponents';
import { isValidScenarioModel } from '../../utils/scenarioUtils';
import { currentScenarioState } from '../../../state/ScenariosState';
import { SubSection } from '../../../Summary/components/SubSection';
import {
  IScenarioRunDataModel,
  IScenarioRunResponseDataModel,
} from '../../../../../data-models/scenario-run.data-model';
import { runScenario } from '../../../../../services/queries/MaggieScenarioQueries';
import { Loader } from '../../../../../components/Loader/Loader';
import { IScenarioDataModel, ScenarioTransactionType } from '../../../../../data-models/scenario.data-model';
import { scenarioRunFieldsSchema } from '../../../../../schemas/ScenarioRun.schema';
import { schemaToFormFields, useSchemaToGrid } from '../../../../../util/schema-utils';
import { IFormField } from '../../../../../view-models/form.view-model';
import { NoDataMessage2 } from '../../../components/Messages/HelpMessage';
import { VisualizationHeading } from './VisualizationHeading';
import { ScenarioKPIs } from './ScenarioKPIs';
import { ScenarioCharts } from './ScenarioCharts';
import { ScenarioRunByFund } from './ScenarioRunByFund';

export type ScenarioTypeByTransactions =
  | 'roundOnly'
  | 'exitOnly'
  | 'partialExit'
  | 'roundAndExit'
  | 'roundAndPartialExit';

export function scenarioVisualizationSchemaRoundOnly() {
  return scenarioRunFieldsSchema().pick(['grossMoic', 'fmv', 'postMoneyValuation', 'ownershipPercentage']);
}
export function scenarioVisualizationSchemaExitOnly() {
  return scenarioRunFieldsSchema().pick(['grossMoic', 'grossProceeds', 'ownershipPercentage']);
}
export function scenarioVisualizationSchemaPartialExit() {
  return scenarioRunFieldsSchema().pick(['grossMoic', 'grossProceeds', 'partialNumberOfSharesPercentage']);
}
export function scenarioVisualizationSchemaRoundAndExit() {
  return scenarioRunFieldsSchema().pick(['grossMoic', 'grossProceeds', 'ownershipPercentage']);
}
export function scenarioVisualizationSchemaRoundOnlyGrid() {
  return scenarioRunFieldsSchema().pick([
    'name',
    'seniority',
    'pricePerShare',
    'investedAmount',
    'fmv',
    'grossMoic',
    'numberOfSharesOwned',
    'fullyDilutedShares',
    'ownershipPercentage',
    'postMoneyValuation',
  ]);
}
export function scenarioVisualizationSchemaRoundAndPartialExitGrid() {
  return scenarioRunFieldsSchema().pick([
    'name',
    'pricePerShare',
    'impliedPricePerShare',
    'partialInvestedAmount',
    'grossProceeds',
    'grossMoic',
    'partialNumberOfShares',
    'partialNumberOfSharesPercentage',
    'remainingNumberOfSharesHeld',
    'fullyDilutedShares',
  ]);
}
export function scenarioVisualizationSchemaPartialExitOnlyGrid() {
  return scenarioVisualizationSchemaRoundAndPartialExitGrid();
}
export function scenarioVisualizationSchemaRoundAndExitGrid() {
  return scenarioRunFieldsSchema().pick([
    'name',
    'seniority',
    'pricePerShare',
    'impliedPricePerShare',
    'investedAmount',
    'liquidationProceeds',
    'commonProceeds',
    'cappedProceeds',
    'grossProceeds',
    'grossMoic',
    'numberOfSharesOwned',
    'fullyDilutedShares',
    'ownershipPercentage',
    'postMoneyValuation',
  ]);
}
export function scenarioVisualizationSchemaExitOnlyGrid() {
  return scenarioRunFieldsSchema().pick([
    'name',
    'seniority',
    'pricePerShare',
    'impliedPricePerShare',
    'investedAmount',
    'liquidationProceeds',
    'commonProceeds',
    'cappedProceeds',
    'grossProceeds',
    'grossMoic',
    'numberOfSharesOwned',
    'fullyDilutedShares',
    'ownershipPercentage',
  ]);
}

export function ScenarioVisualization() {
  const currentScenario = useRecoilValue(currentScenarioState);
  const [availableFields, setAvailableFields] = useState([] as IFormField<unknown>[]);
  const [scenarioData, setScenarioData] = useState<IScenarioRunResponseDataModel>();
  const [loading, setLoading] = useState(false);
  const schemaToGrid = useSchemaToGrid();
  const scenarioType = useMemo(() => {
    if (!currentScenario?.scenarioTransactions) {
      return null;
    } else if (currentScenario.scenarioTransactions.every((t) => t.type === ScenarioTransactionType.exit)) {
      return 'exitOnly';
    } else if (currentScenario.scenarioTransactions.every((t) => t.type === ScenarioTransactionType.round)) {
      return 'roundOnly';
    } else if (
      currentScenario.scenarioTransactions.every((t) => t.type === ScenarioTransactionType.partialExit)
    ) {
      return 'partialExit';
    } else if (
      currentScenario.scenarioTransactions.some((t) => t.type === ScenarioTransactionType.partialExit)
    ) {
      return 'roundAndPartialExit';
    } else {
      return 'roundAndExit';
    }
  }, [currentScenario]);

  const gridFieldsSchema = useMemo(() => {
    switch (scenarioType) {
      case 'exitOnly':
        return scenarioVisualizationSchemaExitOnlyGrid();
      case 'roundOnly':
        return scenarioVisualizationSchemaRoundOnlyGrid();
      case 'roundAndExit':
        return scenarioVisualizationSchemaRoundAndExitGrid();
      case 'partialExit':
        return scenarioVisualizationSchemaPartialExitOnlyGrid();
      case 'roundAndPartialExit':
        return scenarioVisualizationSchemaRoundAndPartialExitGrid();
      default:
        return yup.object();
    }
  }, [scenarioType]);

  const gridColdDefs = useMemo(() => {
    return schemaToGrid(gridFieldsSchema);
  }, [gridFieldsSchema, schemaToGrid]);

  const visualizationFieldsSchema = useMemo(() => {
    if (!currentScenario?.scenarioTransactions) {
      return yup.object();
    } else if (currentScenario.scenarioTransactions.every((t) => t.type === ScenarioTransactionType.exit)) {
      return scenarioVisualizationSchemaExitOnly();
    } else if (currentScenario.scenarioTransactions.every((t) => t.type === ScenarioTransactionType.round)) {
      return scenarioVisualizationSchemaRoundOnly();
    } else if (
      currentScenario.scenarioTransactions.some((t) => t.type === ScenarioTransactionType.partialExit)
    ) {
      return scenarioVisualizationSchemaPartialExit();
    } else {
      return scenarioVisualizationSchemaRoundAndExit();
    }
  }, [currentScenario]);

  useEffect(() => {
    if (!currentScenario || !isValidScenarioModel(currentScenario)) return;
    setLoading(true);
    runScenario(prepRunData(currentScenario))
      .then((data) => {
        const runItem = data.rounds.at(-1);
        const fields = schemaToFormFields(visualizationFieldsSchema).filter((field) => {
          return field.dataType === 'number' && get(runItem, field.key) !== undefined;
        });

        setScenarioData(data);
        setAvailableFields(fields);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [visualizationFieldsSchema, currentScenario]);

  const kpiData = scenarioData?.rounds.at(-1);

  if (loading) return <Loader />;

  if (!currentScenario || !scenarioType || !isValidScenarioModel(currentScenario)) return <NoDataMessage2 />;

  return (
    <MainContainerWithAlert>
      <VisualizationHeading scenario={currentScenario} />
      {!scenarioData ? (
        <NoDataMessage2 />
      ) : (
        <>
          {kpiData && (
            <SubSection title={'Key Performance Indicators'} noCard={true}>
              <ScenarioKPIs fields={availableFields} lastRoundRunData={kpiData} />
            </SubSection>
          )}

          <SubSection
            title={'Investment Details'}
            noCard={true}
            collapseProps={{ collapsible: true, defaultExpanded: true }}
          >
            <ScenarioRunByFund
              colDefs={gridColdDefs}
              scenarioRunData={prepGridData(scenarioData.rounds)}
              initialClientFilterState={scenarioType !== 'roundOnly'}
              scenarioType={scenarioType}
            />
          </SubSection>

          <SubSection title={'KPIs By Share Class'} noCard={true}>
            <ScenarioCharts fields={availableFields} scenarioRunData={prepChartData(scenarioData.rounds)} />
          </SubSection>
        </>
      )}
    </MainContainerWithAlert>
  );
}

export function prepGridData(rounds: IScenarioRunDataModel[]) {
  return rounds.filter((r) => r.type === ScenarioTransactionType.round);
}

export function prepChartData(rounds: IScenarioRunDataModel[]) {
  return rounds.slice(0, -1);
}

function prepRunData(scenario: Partial<IScenarioDataModel>) {
  const name = scenario.name || 'untitled';
  return { ...scenario, name };
}
