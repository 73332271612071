import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GenericFallbacksWrapper } from '../../components/Fallback/GenericFallbacksWrapper';
import { ContainerName } from '../../constants/styles';
import { Fund } from '../../schemas/Fund.schema';
import {
  contributionsDistributionsState,
  selectedDateFPState,
  selectedFundIdStateFP,
  selectedFundStateFP,
} from './state/FPState';
import { useSyncFundFromPath } from './useSyncFundFromPath';
import { FundProfileTabs } from './FundProfileTabs';
import { FPHeader } from './FPHeader';
import { useFundMetricsFP } from './useFundMetricsFP';
import { FundSettings } from './FundSettings';

const FPContainer = css`
  container-name: ${ContainerName.fundProfilesContainer};
  container-type: inline-size;
  padding: 1rem 1.5rem;
  width: 100%;
  overflow: auto;
`;

export function FundProfiles() {
  useSyncFundFromPath();
  useFundMetricsFP();
  const selectedFundId = useRecoilValue(selectedFundIdStateFP);
  useRecoilValue(selectedFundStateFP);
  useRecoilValue(contributionsDistributionsState);
  const resetSelectedDate = useResetRecoilState(selectedDateFPState);

  useEffect(() => {
    return () => {
      resetSelectedDate();
    };
  }, [resetSelectedDate]);

  if (selectedFundId == null) return null;
  return <FundProfilesContent />;
}

export function FundProfilesContent() {
  const fund = useRecoilValue(selectedFundStateFP);
  const path = window.location.pathname;

  if (!fund) return null;
  return (
    <Typography component='section' color={'text.primary'} css={FPContainer}>
      <FPHeader />
      <FundProfileTabs />
      <GenericFallbacksWrapper>
        <motion.div animate={{ opacity: [0.3, 1] }} transition={{ duration: 0.6 }} key={path}>
          <Outlet />
        </motion.div>
      </GenericFallbacksWrapper>
      <FundProfileModals fund={fund} />
    </Typography>
  );
}

interface IFundProfileModalsProps {
  fund: Fund;
}
function FundProfileModals({ fund }: IFundProfileModalsProps) {
  return (
    <GenericFallbacksWrapper>
      <FundSettings fund={fund} />
    </GenericFallbacksWrapper>
  );
}
