import {
  IScenarioDataModel,
  ScenarioTransactionTypeToLabel,
  ScenarioType,
} from '../../../../data-models/scenario.data-model';

export function getSimulationLabels(scenario: ValidScenarioModel) {
  return new Set(
    scenario.scenarioTransactions?.map((transaction) => {
      return ScenarioTransactionTypeToLabel[transaction.type];
    })
  );
}

export function filterScenarios(
  scenarios: IScenarioDataModel[],
  search: string,
  creatorEmails: string[] | null
) {
  let filteredScenarios = scenarios;
  if (creatorEmails !== null && creatorEmails.length > 0) {
    filteredScenarios = scenarios.filter((sc) => !!sc.createdBy && creatorEmails.includes(sc.createdBy));
  }
  if (search) {
    filteredScenarios = filteredScenarios.filter((sc) => {
      return sc.name.toLowerCase().includes(search.toLowerCase());
    });
  }
  return filteredScenarios;
}

export function getExistingScenarioTypes(scenarios: IScenarioDataModel[]) {
  const tagSet = new Set<ScenarioType>();
  scenarios.forEach((scenario) => {
    scenario.tags?.forEach((tag) => {
      if (tag) tagSet.add(tag);
    });
  });
  return tagSet;
}

export interface ValidScenarioModel extends Omit<IScenarioDataModel, 'id'> {
  id?: number;
}

// just a stub for now, will need to be updated once we know all required fields for visualization
export function isValidScenarioModel(
  draftScenario: Partial<IScenarioDataModel>
): draftScenario is ValidScenarioModel {
  return 'scenarioTransactions' in draftScenario && 'companyId' in draftScenario;
}
