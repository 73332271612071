import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import {
  fetchAllInvestmentRounds,
  fetchDashboardData,
  fetchRoundRecon,
  fetchRoundReconConfig,
  fetchTransactionHistory,
  fetchMetricsRecon,
  fetchTransactionsByCompany2,
  fetchReconConfig,
} from '../../../services/queries/MaggieTransactionQueries';
import { TransactionAudit } from '../../../types';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { IRoundReconDataModel } from '../../../data-models/finance-round-recon.data-model';
import { IFinanceDashboardResponseDataModel } from '../../../data-models/dashboard-transaction.data-model';
import { ReconConfigDataModel } from '../../../schemas/ReconConfig.schema';
import { MetricsReconDataModel } from '../../../schemas/MetricsRecon.schema';
import { getSecurityReport } from '../../../services/queries/MaggieMetricsQueries';
import { SecurityReport } from '../../../schemas/SecurityReport.schema';

export const financeTransactionsByCompanyState = atomFamily<ITransactionDataModel[], number>({
  key: 'FinanceTransactionsByCompanyState',
  default: selectorFamily({
    key: 'FinanceTransactionsByCompanyState/default',
    get: (id) => () => {
      return fetchTransactionsByCompany2(id);
    },
  }),
});

export const transactionsByIdState = selectorFamily<Map<string, ITransactionDataModel>, number>({
  key: 'TransactionsByIdState',
  get:
    (id) =>
    ({ get }) => {
      const transactions = get(financeTransactionsByCompanyState(id));
      const map = new Map<string, ITransactionDataModel>();
      transactions.forEach((t) => map.set(String(t.id), t));
      return map;
    },
});

export const transactionReconState = atom<MetricsReconDataModel[]>({
  key: 'transactionReconState',
  default: selector({
    key: 'transactionReconState/get',
    get: () => {
      return fetchMetricsRecon();
    },
  }),
});

export const reconConfigState = atom<ReconConfigDataModel[]>({
  key: 'reconConfigState',
  default: selector({
    key: 'reconConfigState/get',
    get: () => {
      return fetchReconConfig();
    },
  }),
});

export const reconConfigMapState = selector<Map<number, ReconConfigDataModel>>({
  key: 'reconConfigMapState',
  get: ({ get }) => {
    const reconConfig = get(reconConfigState);
    const map = new Map<number, ReconConfigDataModel>();
    reconConfig.forEach((r) => map.set(r.id, r));
    return map;
  },
});

export const roundReconState = atom<IRoundReconDataModel[]>({
  key: 'roundReconState',
  default: selector({
    key: 'roundReconState/get',
    get: () => {
      return fetchRoundRecon();
    },
  }),
});

export const roundReconConfigState = atom({
  key: 'roundReconConfigState',
  default: selector({
    key: 'roundReconConfigState/get',
    get: () => {
      return fetchRoundReconConfig();
    },
  }),
});

export const transactionHistoryByCompanyState = atomFamily<TransactionAudit[], number>({
  key: 'TransactionHistoryByCompanyState',
  default: selectorFamily({
    key: 'TransactionHistoryByCompanyState/default',
    get: (id) => () => {
      return fetchTransactionHistory(id);
    },
  }),
});

export const roundsByCompanyIdState = selectorFamily<IFinanceRoundDataModel[] | null, number>({
  key: 'RoundsByCompanyIdState',

  get:
    (id) =>
    ({ get }) => {
      const allInvestments = get(allInvestmentRoundsState);
      return allInvestments.filter((r) => r.companyId === id);
    },
});

export const companyRoundsByIdState = selectorFamily<Map<number, IFinanceRoundDataModel>, number>({
  key: 'CompanyRoundsByIdState',
  get:
    (companyId) =>
    ({ get }) => {
      const rounds = get(roundsByCompanyIdState(companyId));
      const map = new Map<number, IFinanceRoundDataModel>();
      rounds?.forEach((r) => map.set(r.id, r));
      return map;
    },
});

export const financeDashboardState = atom<IFinanceDashboardResponseDataModel>({
  key: 'FinanceDashboardState',
  default: selector({
    key: 'FinanceDashboardState/default',
    get: () => {
      return fetchDashboardData();
    },
  }),
});

export const forceAllTransactionsByDateUpdate = atom<number>({
  key: 'forceAllTransactionsByDateUpdate',
  default: 0,
});

export const allInvestmentRoundsState = atom<IFinanceRoundDataModel[]>({
  key: 'AllInvestmentRoundsState',
  default: selector({
    key: 'AllInvestmentRoundsState/default',
    get: () => {
      return fetchAllInvestmentRounds();
    },
  }),
});

export const allInvestmentRoundsMapState = selector<Map<number, IFinanceRoundDataModel>>({
  key: 'AllInvestmentRoundsMapState',
  get: ({ get }) => {
    const rounds = get(allInvestmentRoundsState);
    const map = new Map<number, IFinanceRoundDataModel>();
    rounds.forEach((r) => map.set(r.id, r));
    return map;
  },
});

export const securityReportState = atom<SecurityReport[]>({
  key: 'securityReportState',
  default: selector({
    key: 'securityReportState/default',
    get: () => {
      return getSecurityReport();
    },
  }),
});
