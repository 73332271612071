import { IconButton, TextField, BaseTextFieldProps, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, FC, useCallback, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

interface ISearchInputProps extends Omit<BaseTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
  showSearchIcon?: boolean;
  autoFocus?: boolean;
}

export const SearchInput: FC<ISearchInputProps> = ({
  onChange: handleChange,
  placeholder,
  inputProps,
  autoFocus,
  showSearchIcon = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { colors } = useTheme();

  const clearInput = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    handleChange('');
  }, [handleChange]);

  return (
    <TextField
      role='search'
      autoFocus={autoFocus}
      placeholder={placeholder ?? 'Search'}
      inputProps={{
        'aria-label': 'search',
        ref: inputRef,
        sx: {
          padding: 0,
          paddingLeft: showSearchIcon ? '0.5rem' : '1rem',
        },

        ...inputProps,
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
      InputProps={{
        startAdornment: showSearchIcon ? <SearchIcon color='disabled' fontSize='small' /> : null,
        endAdornment: (
          <IconButton onClick={clearInput} title='clear input'>
            <ClearIcon htmlColor={colors.neutral[40]} fontSize='small' />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
};
