import { useMemo } from 'react';
import { useSetAtom } from 'jotai';
import { ButtonProps, IconButton } from '@mui/material';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MenuButton } from '../Finance2/common-grid-defs/MenuButton';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { showFundFormsState } from './state/FPState';

export function FPMenu() {
  const setShowFundForm = useSetAtom(showFundFormsState);
  const { showFundCapitalAllocation } = useFlags<MaggieFeatureFlags>();

  const items = useMemo(() => {
    const res = [
      {
        name: 'Fund Waterfall Settings',
        icon: <WaterfallChartIcon aria-label='waterfall' />,
        onClick: () => {
          setShowFundForm('waterfall');
        },
      },
    ];
    if (showFundCapitalAllocation) {
      res.push({
        name: 'Fund Allocation Settings',
        icon: <RequestQuoteIcon aria-label='allocation' />,
        onClick: () => {
          setShowFundForm('allocation');
        },
      });
    }
    return res;
  }, [setShowFundForm, showFundCapitalAllocation]);

  return <MenuButton menuItems={items} buttonComponent={SettingsButton} />;
}

function SettingsButton(props: ButtonProps) {
  return (
    <IconButton {...props} title='fund settings'>
      <SettingsIcon color='secondary' />
    </IconButton>
  );
}
