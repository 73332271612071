import { CSSProperties, FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import { Icon, Stack } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { RecoilState, useRecoilState } from 'recoil';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { currentStepState } from './TransactionFormUIState';

export interface IStepperFormButtonsProps {
  backButtonLabel?: string;
  stepIsValid: () => Promise<boolean>;
  nextButtonLabel?: string;
  handleGoBack?: () => void;
  secondaryActionProps?: {
    label: string;
    handler: () => Promise<void>;
    buttonProps?: ButtonProps;
  };
  stepState?: RecoilState<number>;
  incrementOnNext?: boolean;
  style?: CSSProperties;
}

export function StepperFormButtons(props: IStepperFormButtonsProps) {
  const {
    backButtonLabel = 'Previous',
    nextButtonLabel = 'Next',
    stepIsValid,
    handleGoBack,
    secondaryActionProps,
    stepState = currentStepState,
    incrementOnNext = true,
    style,
  } = props;
  const [currentStep, setCurrentStep] = useRecoilState(stepState);
  const [loading, setLoading] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);

  const handleNext = useCallback(async () => {
    setLoading(true);

    if ((await stepIsValid()) && incrementOnNext) {
      setCurrentStep(currentStep + 1);
    }
    setLoading(false);
  }, [currentStep, incrementOnNext, setCurrentStep, stepIsValid]);

  const handleBack = () => {
    if (handleGoBack) return handleGoBack();
    setCurrentStep(currentStep - 1);
  };

  const handleSecondaryAction = useCallback(async () => {
    if (!secondaryActionProps) return;
    setLoadingSecondary(true);
    await secondaryActionProps.handler();
    setLoadingSecondary(false);
  }, [secondaryActionProps]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', pt: '2rem', pb: '1rem', position: 'sticky', bottom: 0 }}
      style={style}
    >
      {currentStep > 0 || Boolean(handleGoBack) ? (
        <Button
          color='secondary'
          variant='outlined'
          size='medium'
          type='button'
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          {backButtonLabel}
        </Button>
      ) : (
        <div />
      )}
      <Box sx={{ flex: '1 1 auto' }} />
      <Stack direction={'row'} gap='0.5rem'>
        {secondaryActionProps && (
          <ActionLoadingButton
            loading={loadingSecondary}
            disabled={loadingSecondary}
            onClick={handleSecondaryAction}
            {...secondaryActionProps.buttonProps}
          >
            {secondaryActionProps.label}
          </ActionLoadingButton>
        )}

        <ActionLoadingButton onClick={handleNext} loading={loading} disabled={loading}>
          {nextButtonLabel}
        </ActionLoadingButton>
      </Stack>
    </Box>
  );
}

const ActionLoadingButton: FC<LoadingButtonProps> = (props) => {
  const { loading, children, onClick, ...rest } = props;
  return (
    <LoadingButton
      color='secondary'
      type='button'
      variant='contained'
      size='medium'
      loading={loading}
      disabled={loading}
      loadingPosition='start'
      startIcon={loading ? <Icon /> : <></>}
      onClick={onClick}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
