import { FC, useCallback, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  isOpenDrawerState,
  selectedDealCategoryState,
  selectedDealState,
} from '../../state/DealboardUIState';
import { useDealActions2, useDeleteDeal } from '../../hooks/useDealActions';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { DealModal } from '../DealModal/DealModal';
import { DealPassModalContent } from '../DealModal/DealPassModalContent';
import { dealsByCategoryState } from '../../state/DealboardDataState';
import { ColumnStage } from '../../data-models/columnStage.data-model';
import { DealBoardDealCategory } from '../../../../types';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { companyState } from '../../../../services/state/CompanyState';
import { ConfirmDeleteDeal } from '../DealModal/ConfirmDeleteDeal';
import { getNextDeal, getPrevDeal } from './dealNavigationUtils';

export const MenuCard = styled('ul')<{ width: number | undefined }>`
  all: unset;
  list-style: none;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  padding: 8px 8px;
  border-radius: 4px;
  display: block;
  position: absolute;
  background: #fff;
  z-index: 100;
  width: ${({ width }) => (width ? `calc(${width}px + 2rem)` : 'auto')};
  transform: translate(-0.1rem, 0.2rem);
`;

export const MenuItem = styled('li')`
  display: flex;
  align-items: center;
  height: 2.8rem;
  gap: 8px;
  cursor: pointer;
  & svg {
    height: 1.3rem;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary[5]};
  }
  padding: 0 0.6rem;
`;

const Container = styled('div')``;

export const TrackSplitButton: FC = () => {
  const setIsOpenDrawer = useSetRecoilState(isOpenDrawerState);
  const [deal, setSelectedDeal] = useRecoilState(selectedDealState);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const deleteDeal = useDeleteDeal();
  const { handleMoveToCurrent } = useDealActions2();
  const { onOpenModal } = useModalState();
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const dealsByCategory = useRecoilValue(
    dealsByCategoryState(selectedDealCategory.id as DealBoardDealCategory)
  );
  const company = useRecoilValue(companyState(deal?.companyId ?? -1));
  const companyName = `“${company!.name}”`;

  const NonGenericDealboardState = useMemo(() => {
    return [{ stage: { id: deal?.stageId }, deals: dealsByCategory }];
  }, [dealsByCategory, deal]);

  useOutsideClick(containerRef, () => setOpen(false));
  const { colors } = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onConfirmDelete = useCallback(async () => {
    if (!deal) return;
    setShowConfirmDelete(false);
    let nearestDeal = getNextDeal(NonGenericDealboardState as ColumnStage[], deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(NonGenericDealboardState as ColumnStage[], deal);

    await deleteDeal(deal, {
      onSuccess: () => {
        if (!nearestDeal) setIsOpenDrawer(false);
        setSelectedDeal(nearestDeal);
      },

      onError: () => {
        setIsOpenDrawer(false);
      },
    });
  }, [NonGenericDealboardState, deal, deleteDeal, setIsOpenDrawer, setSelectedDeal]);

  const handleSelectOption = useCallback(
    (optionId: string) => {
      if (!deal) return;

      if (optionId === 'CURRENT') {
        setOpen(false);
        handleMoveToCurrent(deal);
        setIsOpenDrawer(false);
      } else if (optionId === 'PASS') {
        setOpen(false);
        onOpenModal(
          <DealModal title={`Move the Deal ${companyName} to Pass?`}>
            <DealPassModalContent deal={deal} />
          </DealModal>
        );
      } else if (optionId === 'DELETE') {
        setOpen(false);
        setShowConfirmDelete(true);
      } else {
        throw new Error('Unknown option');
      }
    },
    [companyName, deal, handleMoveToCurrent, onOpenModal, setIsOpenDrawer]
  );

  if (!deal || !company) return null;

  return (
    <Container ref={containerRef} data-testid='track-split-btn'>
      <ButtonGroup
        variant='outlined'
        color={'secondary'}
        size={'medium'}
        ref={anchorRef}
        aria-label='split button'
        style={{ position: 'relative' }}
      >
        <Button
          color={'secondary'}
          onClick={handleToggle}
          style={{ width: '100px', padding: '3px 18px', justifyContent: 'flex-start' }}
        >
          Actions
        </Button>

        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select deal action'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {open && (
        <MenuCard style={{ right: '30px' }} width={140} role='listbox'>
          <MenuItem onClick={() => handleSelectOption('CURRENT')} role='option'>
            <GpsFixedIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Move to Current
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('PASS')} role='option'>
            <DangerousOutlinedIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Move to Passed
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('DELETE')} role='option'>
            <DeleteOutlineIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Delete Deal
            </Typography>
          </MenuItem>
        </MenuCard>
      )}
      <ConfirmDeleteDeal
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={onConfirmDelete}
        deal={deal}
        company={company}
      />
    </Container>
  );
};
