import { CSSProperties, FC } from 'react';
import { formatExternalLink } from '../../../../../../../util/url-utils';

interface LinkRendererProps {
  value?: string | null;
  style?: CSSProperties;
  textContent?: string;
}

export const LinkRenderer: FC<LinkRendererProps> = (params) => {
  const linkText = params.textContent || params.value?.replace(/^(https?:\/\/)?(www\.)?/, '');
  if (!params.value || params.value === '0') return <></>;

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      style={{ ...params.style }}
      href={formatExternalLink(params.value)}
      onClick={(e) => e.stopPropagation()}
    >
      {linkText}
    </a>
  );
};
