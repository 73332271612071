export const customTableNotation = {
  localeText: {
    sum: 'Σ ',
    max: 'MAX ',
    min: 'MIN ',
    count: 'COUNT ',
    avg: 'AVG ',
    rowGroupColumnsEmptyMessage: ' ', // replaced icon with message text in order to show it at all times
    func: 'Σ ', // TODO: will need to change if we add more functions
  },
};
