import { IBaseModelDataModel } from './base-model.data-model';

export interface IAirtableSyncDataModel extends IAirtableSyncPayloadDataModel, IBaseModelDataModel {
  id: number;
}
export interface IAirtableSyncPayloadDataModel {
  airtableBaseId: string;
  airtableBaseName: string;
  // if there are multiple identifierColumns, they should be concatenated with a comma, no whitespace
  airtableIdentifierColumnId: string;
  airtableTableId: string;
  airtableTableName: string;
  entity: string;
  // if there are multiple identifierColumns, they should be concatenated with a comma, no whitespace
  identifierColumn: string;
  syncFields: IAirTableSyncColumnDataModel[];
}

export interface IEstablishedSyncDataModel extends IBaseModelDataModel {
  id: number;
  entity: string;
  airtableTableId: string;
  airtableTableName: string;
  airtableBaseId: string;
  airtableBaseName: string;
  identifierColumn: string;
  airtableIdentifierColumnId: string;
  airtableSyncColumns: ISyncedColumnDataModel[];
}

export function createEstablishedSyncDataModel(overrides: Partial<IEstablishedSyncDataModel> = {}) {
  return {
    id: 0,
    entity: '',
    airtableTableId: '',
    airtableTableName: '',
    airtableBaseId: '',
    airtableBaseName: '',
    identifierColumn: '',
    airtableIdentifierColumnId: '',
    airtableSyncColumns: [],
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    ...overrides,
  };
}

export interface IAirTableSyncColumnDataModel {
  syncType: AirtableSyncType;
  entityColumn: string;
  airtableColumnId: string;
  airtableColumnName: string;
}
export interface ISyncedColumnDataModel extends IBaseModelDataModel, IAirTableSyncColumnDataModel {
  id: number;
  airtableSyncId: number;
}

export function createSyncedColumnDataModel(overrides: Partial<ISyncedColumnDataModel> = {}) {
  return {
    id: 0,
    airtableSyncId: 0,
    entityColumn: '',
    airtableColumnId: '',
    airtableColumnName: '',
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    syncType: AirtableSyncType.readOnly,
    ...overrides,
  };
}

export function createAirtableSyncDataModel(
  overrides: Partial<IAirtableSyncDataModel> = {}
): IAirtableSyncDataModel {
  return {
    id: 1,
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    entity: '',
    airtableTableId: '',
    airtableTableName: '',
    airtableBaseId: '',
    airtableBaseName: '',
    identifierColumn: '',
    airtableIdentifierColumnId: '',
    syncFields: [],
    ...overrides,
  };
}

export enum AirtableSyncType {
  readOnly = 'pullFromForesight',
  editable = 'pushToForesight',
}

export function createAirTableSyncColumnDataModel(
  overrides: Partial<IAirTableSyncColumnDataModel> = {}
): IAirTableSyncColumnDataModel {
  return {
    syncType: AirtableSyncType.readOnly,
    entityColumn: '',
    airtableColumnId: '',
    airtableColumnName: '',
    ...overrides,
  };
}

export interface ISyncColumnsDataModel {
  airtableColumns: IBaseColumnDataModel[];
  foresightColumns: IBaseColumnDataModel[];
}

export interface IBaseColumnDataModel {
  displayName: string;
  value: string;
  isSynced: ISyncDataModel | false;
}

export function createBaseColumnDataModel(
  overrides: Partial<IBaseColumnDataModel> = {}
): IBaseColumnDataModel {
  return { displayName: '', value: '', isSynced: false, ...overrides };
}

export interface ISyncDataModel {
  id: number;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: Date;
  updatedAt: Date;
  entityColumn: string;
  airtableColumnId: string;
  airtableColumnName: string;
  syncType: string;
  airtableSync: IAirtableSyncDataModel;
}
export function createSyncDataModel(overrides: Partial<ISyncDataModel> = {}) {
  return {
    id: 0,
    createdBy: '',
    updatedBy: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    entityColumn: '',
    airtableColumnId: '',
    airtableColumnName: '',
    syncType: '',
    airtableSync: createAirtableSyncDataModel(),
    ...overrides,
  };
}
