import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { StyledTooltip } from '../../../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { LinkRenderer } from './LinkRenderer';

interface MultiLinkRendererProps {
  value: string[];
}

const Wrapper = styled('span')`
  display: flex;
  gap: 0.5rem;
`;

/**
 * Expects a comma seperated list of URLs to render as links.
 */

export const MultiLinkRenderer: FC<MultiLinkRendererProps> = ({ value: urls }) => {
  const { primary } = useTheme().colors;

  if (!urls?.length) return null;

  if (!Array.isArray(urls)) {
    return urls; // return value as is
  }

  return (
    <StyledTooltip
      role={'tooltip'}
      title={
        <ul style={{ listStyle: 'none' }}>
          {urls.map((url, i) => (
            <li key={i}>
              <LinkRenderer value={url} style={{ textDecoration: 'none', color: `${primary[60]}` }} />
            </li>
          ))}
        </ul>
      }
      placement='top-start'
      className='CellTooltip'
    >
      <Wrapper>
        {urls.map((url, i) => (
          <LinkRenderer value={url} key={i} />
        ))}
      </Wrapper>
    </StyledTooltip>
  );
};
