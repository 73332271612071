import { endOfMonth, sub, subQuarters } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { selectedCompanyIdProfile } from '../state/UIState';
import { allCompanyFinancialsState } from '../state/CompanyFinancialsState';
import { KpiPeriod, KpiSection, KpiPeriodExtended } from '../../../data-models/company-financials.data-model';
import { getTypedFinancials, TypedFinancials } from '../utils/getTypedFinancials';
import { kpiConfigByIdMapState } from '../../../services/state/KPI/KPITemplatesState';
import { FDMap } from '../../../util/data-structure/FDMap';

export type KpiSeriesBySectionByPeriod = FDMap<
  KpiSection,
  FDMap<KpiPeriod | KpiPeriodExtended, FDMap<string, TypedFinancials[]>>
>;

export const useGetFinancialsBySectionAndPeriod = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const companyFinancials = useRecoilValue(allCompanyFinancialsState(companyId));
  const kpiConfigMap = useRecoilValue(kpiConfigByIdMapState);
  const typedFinancials = getTypedFinancials(companyFinancials, kpiConfigMap);

  return useCallback(
    (from: Date, to: Date) => {
      const seriesBySectionAndPeriod: KpiSeriesBySectionByPeriod = new FDMap();

      typedFinancials.forEach((dataItem) => {
        const { date, kpiId, period, section } = dataItem;
        const kpi = kpiConfigMap.get(kpiId);
        const kpiDate = new Date(date);

        if (endOfMonth(kpiDate).getTime() < from.getTime() || kpiDate.getTime() > endOfMonth(to).getTime()) {
          return;
        }

        if (kpi && section && period) {
          seriesBySectionAndPeriod.update(section, (bySection) => {
            return (bySection ?? new FDMap<KpiPeriod, FDMap<string, TypedFinancials[]>>()).update(
              period,
              (byPeriod) => {
                return (byPeriod ?? new FDMap<string, TypedFinancials[]>()).setOrUpdate(
                  kpi.displayName,
                  [dataItem],
                  (curr) => [...curr, dataItem]
                );
              }
            );
          });
        }
      });

      return seriesBySectionAndPeriod;
    },
    [kpiConfigMap, typedFinancials]
  );
};

/**
 *
 * @param period
 * @param baseDate: end date for fiscal period
 * @param periodsToSubtract
 * @returns end of period preceding base date by periodsToSubtract
 */
export function getDateForPeriod(period: KpiPeriod, baseDate: Date, periodsToSubtract: number) {
  switch (period) {
    case KpiPeriod.month:
      return endOfMonth(sub(baseDate, { months: periodsToSubtract }));
    case KpiPeriod.quarter:
      return endOfMonth(subQuarters(baseDate, periodsToSubtract));
    case KpiPeriod.year:
      return endOfMonth(sub(baseDate, { years: periodsToSubtract }));
  }
}

/**
 * @param period
 * @param selectedDate end of fiscal period
 * @param periodCountToDisplay
 * @returns
 */
export function getDateRange(period: KpiPeriod, selectedDate: Date, periodCountToDisplay: number) {
  const periodCountIncludingCurrentDate = periodCountToDisplay - 1;

  switch (period) {
    case KpiPeriod.month:
      return {
        from: endOfMonth(sub(selectedDate, { months: periodCountIncludingCurrentDate })),
        to: endOfMonth(selectedDate),
      };
    case KpiPeriod.quarter:
      return {
        from: endOfMonth(subQuarters(selectedDate, periodCountIncludingCurrentDate)),
        to: endOfMonth(selectedDate),
      };
    case KpiPeriod.year:
      return {
        from: endOfMonth(sub(selectedDate, { years: periodCountIncludingCurrentDate })),
        to: endOfMonth(selectedDate),
      };
  }
}
