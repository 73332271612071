import { useRecoilValue } from 'recoil';
import { merge } from 'lodash-es';
import { FieldValues } from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../constants/RouteNameMapping';
import { GenericConfigPage } from '../Common/GenericConfigPage';

import {
  BasicFormConfig,
  createFieldDataModel,
  createFieldFormConfigDataModel,
  FieldEntity,
  IField,
  KpiConfigScenario,
  KpiConfigSection,
} from '../../../data-models/field2.data-model';
import { createFormatterDataModel } from '../../../data-models/formatter.data-model';
import { kpiConfigState } from '../../../services/state/KPI/KPITemplatesState';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { useGetKpiFields, getKpiFormFields } from './KPIFields';
import { SortKPIs } from './KPISort';

function getDefaultValues(): Partial<IField<unknown>> {
  return { sectionType: KpiConfigSection.Both, kpiScenario: KpiConfigScenario.Accrued };
}

export const KPIConfigBaseURL = '/kpiConfig';

export function ConfigKPIs() {
  const { showManageGroups, showConfigKPIForm2 } = useFlags<MaggieFeatureFlags>();
  const universalKPIsFields = [...(useRecoilValue(kpiConfigState) ?? [])].sort(sortKpisByType);

  return (
    <GenericConfigPage
      baseTitle={'KPI'}
      customToolbarElem={
        <Box display={'flex'} columnGap={'.25rem'}>
          {showManageGroups && <GoToGroupsBtn />}
          <SortKPIs />
        </Box>
      }
      data={universalKPIsFields}
      fields={useGetKpiFields()}
      pageTitle={ROUTE_TITLE_MAPPING[ROUTES.CONFIG_KPI]}
      queryURL={KPIConfigBaseURL}
      transformBeforeSubmit={(data) => {
        return transformKpi(data);
      }}
      defaultValues={getDefaultValues()}
      dynamicFormTransformer={showConfigKPIForm2 ? getKpiFormFields : undefined}
    />
  );
}

function transformKpi(formData: Partial<IField<unknown>>): IField<unknown> {
  if (!formData.formMeta?.formatter?.type || !formData.displayName) {
    throw new Error('Invalid form data');
  }

  const type = formData.formMeta.formatter.type;

  const partialData = {
    entity: formData.entity,
    displayName: formData.displayName,
    formMeta: createFieldFormConfigDataModel({
      formatter: createFormatterDataModel({
        type,
        id: type === 'percent' ? 'numberAsPercent' : type,
      }),
      renderer: {
        type,
        id: type,
        config: (formData.formMeta.formatter.config ?? {}) as BasicFormConfig,
      },
    }),
    gridMeta: {
      group: formData.gridMeta?.group,
    },
    type: 'number',
  };

  return merge(createFieldDataModel(), { ...formData }, partialData) as IField<unknown>;
}

export function isSystemKPI(field: FieldValues): boolean {
  return field.entity === FieldEntity.systemKPI;
}

export function sortKpisByType(a: IField<unknown>, b: IField<unknown>): number {
  if (isSystemKPI(a) && !isSystemKPI(b)) {
    return 1;
  } else if (!isSystemKPI(a) && isSystemKPI(b)) {
    return -1;
  } else {
    return a.displayName.localeCompare(b.displayName);
  }
}

export function GoToGroupsBtn() {
  const navigate = useNavigate();

  return (
    <Button
      color={'secondary'}
      onClick={() => {
        navigate(`/${ROUTES.ADMIN_PANEL}/${ROUTES.CONFIG}/${ROUTES.CONFIG_KPI_GROUPS}`);
      }}
      variant={'contained'}
    >
      Manage Groups
    </Button>
  );
}
