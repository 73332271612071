import { cloneDeep } from 'lodash-es';
import { useRecoilCallback } from 'recoil';
import { IKeyResultDataModel } from '../../../data-models/key-result.data-model';
import {
  createObjective,
  createKeyResult,
  KeyResultPayload,
  EditObjectivePayload,
  ObjectivePayload,
  editObjective,
  deleteObjective,
  EditKeyResultPayload,
  editKeyResult,
  deleteKeyResult,
} from '../../../services/queries/MaggieObjectivesQueries';
import { objectivesByCompanyState } from '../state/ObjectivesState';

export type KeyResultValues = Omit<KeyResultPayload, 'objectiveId' | 'companyId'>;
export interface CreateObjectiveData extends ObjectivePayload {
  ownerId: number;
  keyResults: KeyResultValues[];
}

// errors should be handled in the caller

/**
 * Creates an objective and then its key results, optimistically updating recoil state.
 * If the objective creation fails, its key results are not created and an error is thrown.
 * If any of the key results fail to create, returns an array of errors, but the state will still be updated with the successfully created objective and key results.
 * @returns array of errors, if any
 */
export const useObjectiveActions = () => {
  const handleCreateObjective = useRecoilCallback(
    ({ set }) =>
      async (data: CreateObjectiveData) => {
        const { name, ownerId, companyId, categoryId } = data;

        const objRes = await createObjective({ name, ownerId, companyId, categoryId });

        const krPayloads = data.keyResults.map((kr) => {
          return { ...kr, objectiveId: objRes.id };
        });

        const krs: IKeyResultDataModel[] = [];
        const errors = [];

        for (const payload of krPayloads) {
          try {
            const kr = await createKeyResult(payload);
            krs.push(kr);
          } catch (err) {
            console.error(err);
            errors.push(err);
          }
        }

        set(objectivesByCompanyState(data.companyId), (prev) => {
          if (!prev) return [{ ...objRes, keyResults: krs }];
          return [...cloneDeep(prev), { ...objRes, keyResults: krs }];
        });

        // will be handled in the caller - lets the user know if there were some errors
        return errors;
      },
    []
  );

  const handleEditObjective = useRecoilCallback(
    ({ set }) =>
      async (payload: EditObjectivePayload) => {
        const res = await editObjective(payload);
        set(objectivesByCompanyState(payload.companyId), (oldObjectives) => {
          if (!oldObjectives) return [res];
          return oldObjectives.map((obj) => {
            if (obj.id === payload.id) return res;
            else return obj;
          });
        });
        return res;
      },
    []
  );

  const handleCreateKeyResult = useRecoilCallback(
    ({ set }) =>
      async (companyId: number, payload: KeyResultPayload) => {
        const res = await createKeyResult(payload);
        set(objectivesByCompanyState(companyId), (oldObjectives) => {
          if (!oldObjectives) return null;
          return oldObjectives.map((obj) => {
            if (obj.id !== payload.objectiveId) return obj;
            return {
              ...cloneDeep(obj),
              keyResults: [...cloneDeep(obj.keyResults), res],
            };
          });
        });
        return res;
      },
    []
  );

  const handleEditKeyResult = useRecoilCallback(
    ({ set }) =>
      async (companyId: number, payload: EditKeyResultPayload) => {
        const res = await editKeyResult(payload);
        set(objectivesByCompanyState(companyId), (oldObjectives) => {
          if (!oldObjectives) return null;
          return oldObjectives.map((obj) => {
            if (obj.id !== payload.objectiveId) return obj;
            return {
              ...cloneDeep(obj),
              keyResults: obj.keyResults.map((kr) => {
                if (kr.id === payload.id) return res;
                else return kr;
              }),
            };
          });
        });
        return res;
      },
    []
  );

  const handleDeleteKeyResult = useRecoilCallback(
    ({ set }) =>
      async (companyId: number, keyResult: IKeyResultDataModel) => {
        await deleteKeyResult(keyResult.id);
        set(objectivesByCompanyState(companyId), (oldObjectives) => {
          if (!oldObjectives) return null;
          return oldObjectives.map((obj) => {
            if (obj.id !== keyResult.objectiveId) return obj;
            return {
              ...cloneDeep(obj),
              keyResults: obj.keyResults.filter((kr) => kr.id !== keyResult.id),
            };
          });
        });
      },
    []
  );

  const handleDeleteObjective = useRecoilCallback(
    ({ set }) =>
      async (companyId: number, objectiveId: number) => {
        await deleteObjective(objectiveId);
        set(objectivesByCompanyState(companyId), (oldObjectives) => {
          if (!oldObjectives) return null;
          return oldObjectives.filter((obj) => obj.id !== objectiveId);
        });
      },
    []
  );

  return {
    handleCreateObjective,
    handleEditObjective,
    handleDeleteObjective,
    handleCreateKeyResult,
    handleEditKeyResult,
    handleDeleteKeyResult,
  };
};
