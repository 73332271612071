import { useCallback, useMemo } from 'react';
import { useAtom } from 'jotai';
import { css } from '@emotion/react';
import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import { ControllerRenderProps } from 'react-hook-form';
import { LinearProgress, Stack } from '@mui/material';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { showProjectionFormState } from '../state/FPState';
import { AgTableWithBaseStyle } from '../../../components/AgTable/AgTable2';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { schemaToFormFields } from '../../../util/schema-utils';
import { projectedFeesAndExpensesTransactionSchema } from '../../../schemas/FundCapitalAllocation.schema';
import { FieldFactory } from '../../../components/Form/FieldFactory';
import { useFocusElement } from '../../../hooks/useFocusElement';
import { LoadingBarPlaceholder } from '../../../components/LoadingBar/LoadingBar';
import { FPAllocationProps } from './FPAllocation';
import {
  FeesAndExpensesFormData,
  getFeesAndExpensesFormData,
  useFeesAndExpensesFormColDefs,
} from './feesAndExpensesFormData';
import { useFeesAndExpensesFormData } from './feesAndExpensesActions';

const FormGridContainer = css`
  width: 90dvw;
  height: 85dvh;
  max-width: 80rem;
  padding: 0 2.5rem 2rem;
  overflow-y: auto;
`;

export function FeesAndExpensesFormModal({ data, fund }: FPAllocationProps) {
  const [showForm, setShowForm] = useAtom(showProjectionFormState);
  function onClose() {
    setShowForm(null);
  }

  if (!showForm || !data || !fund) return null;
  return (
    <GenericFallbacksWrapper suspenseFallback={<></>}>
      <BasicDialog title={'Edit Fees & Expenses'} open={showForm !== null} onClose={onClose}>
        <FeesAndExpensesForm data={data} fund={fund} />
      </BasicDialog>
    </GenericFallbacksWrapper>
  );
}

export function FeesAndExpensesForm({ data, fund }: FPAllocationProps) {
  const formData = useMemo(() => {
    return getFeesAndExpensesFormData(fund, data);
  }, [data, fund]);
  const colDefs = useFeesAndExpensesFormColDefs();
  const getRowId = useCallback((params: GetRowIdParams) => {
    return params.data?.period;
  }, []);
  const { onCellValueChange, loading } = useFeesAndExpensesFormData();

  return (
    <div css={FormGridContainer}>
      {loading ? <LinearProgress variant='indeterminate' /> : <LoadingBarPlaceholder />}
      <AgTableWithBaseStyle
        rowData={formData}
        columnDefs={colDefs}
        defaultColDef={defaultColDefs}
        getRowId={getRowId}
        suppressScrollOnNewData={true}
        onCellValueChanged={onCellValueChange}
      />
    </div>
  );
}

const defaultColDefs: ColDef = {
  flex: 1,
};

const EditorWrapper = css`
  height: 100%;
  width: 100%;
  & .MuiFormControl-root {
    margin: 0.15rem;
    padding: 0;
    z-index: 4;
  }
`;

export function ProjectedFeesAndExpensesEditor(props: CustomCellEditorProps<FeesAndExpensesFormData>) {
  const formField = useMemo(() => {
    return schemaToFormFields(projectedFeesAndExpensesTransactionSchema(), ['amount']).at(0);
  }, []);
  const { value, onValueChange, stopEditing, data } = props;
  const formProps = {
    onChange: onValueChange,
    onBlur: stopEditing,
    value,
  } as ControllerRenderProps;

  useFocusElement(data?.period, 'input');

  if (formField) {
    return (
      <Stack css={EditorWrapper} id={data?.period}>
        <FieldFactory formField={formField} formProps={formProps} />
      </Stack>
    );
  }
  return <div>{value}</div>;
}
