import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Typography, useTheme } from '@mui/material';
import { IPersonDataModel } from '../../../../data-models/person.data-model';
import { formatLocation } from '../../../../util/location-formatter';
import { StrongestConnections } from '../../People/CellRenderers/StrongestConnection';
import { useUpdatePersonEmail } from '../../Summary/hooks/useUpdatePersonEmail';
import { ActivityDetails } from '../../../CompanyProfiles2/Header/ActivityDetails';
import { getEngagementSummary } from '../../../CompanyProfiles2/utils/getPersonActivityData';

const Container = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: start;
`;

const Card = styled('div')`
  box-shadow: 0px 4px 10px rgba(44, 46, 49, 0.1);
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
`;

const MainInfo = styled('section')`
  background: ${({ theme }) => theme.colors.neutral[2]};
  border-radius: 4px;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: minmax(6rem, 1fr) 4fr;
  row-gap: 1rem;
`;

const titleStyles = { marginBottom: '0.5rem' };

interface IPersonGeneralInfoProps {
  person: IPersonDataModel;
}
export const PersonGeneralInfo: FC<IPersonGeneralInfoProps> = ({ person }) => {
  const { colors } = useTheme();
  const { handleUpdateEmail } = useUpdatePersonEmail(person.id);
  const [primaryEmailState, setPrimaryEmailState] = useState(person.primaryEmail);
  const [emails, setEmails] = useState([primaryEmailState, ...(person.secondaryEmails ?? [])]);
  const location = person.locations?.[0];
  const { activity, relationships } = person;

  useEffect(() => {
    if (primaryEmailState != null && primaryEmailState !== 'email_not_unlocked@domain.com') return;
    handleUpdateEmail().then((email) => {
      setPrimaryEmailState(email ?? '');
      setEmails([email ?? '', ...(person.secondaryEmails ?? [])]);
    });
  }, [handleUpdateEmail, person.secondaryEmails, primaryEmailState]);

  const engagementSummary = getEngagementSummary(activity);

  return (
    <Container>
      <MainInfo>
        <Typography variant='body2'>Location</Typography>
        <Typography variant='body2'>{location ? formatLocation(location) : ''}</Typography>
        <Typography variant='body2'>Email</Typography>
        <div>
          {emails.map((email, i) => (
            <a
              key={email}
              href={`mailto:${email}`}
              style={{ color: colors.primary[60], fontSize: '0.875rem' }}
            >
              {email}
              {i < emails.length - 1 && ', '}
            </a>
          ))}
        </div>
      </MainInfo>
      <div>
        <Typography variant='body2'>Relationships Intelligence</Typography>
        {(relationships.length > 0 || Boolean(activity)) && (
          <Card>
            {relationships.length > 0 && (
              <StrongestConnections relationships={relationships} titleStyle={titleStyles} />
            )}

            {relationships.length > 0 && activity && <Divider style={{ margin: '1rem 0' }} />}
            {activity && <ActivityDetails engagementSummary={engagementSummary} />}
          </Card>
        )}
      </div>
    </Container>
  );
};
