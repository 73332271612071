import { useSetRecoilState } from 'recoil';
import { SerializedEditorState } from 'lexical';
import { selectedNoteState } from '../../../state/UIState';
import { INoteDataModel } from '../../../../../data-models/note.data-model';
import { LexicalEditor } from '../../../../../components/LexicalEditor/LexicalEditor';
import { LexicalProvider } from '../../../../../components/LexicalEditor/provider/LexicalProvider';

export interface INotesListProps {
  companyId: number;
}

interface INoteEditor {
  companyId: number;
  note: INoteDataModel<unknown>;
}

export function NoteEditor(props: INoteEditor) {
  const { companyId, note } = props;
  const setSelectedNote = useSetRecoilState(selectedNoteState);
  const noteEditorProps = {
    companyId,
    note,
    onExit: () => {
      setSelectedNote(undefined);
    },
  };
  const lexicalNoteEditorProps = {
    ...noteEditorProps,
    note: note as INoteDataModel<SerializedEditorState>,
  };

  return (
    <LexicalProvider {...lexicalNoteEditorProps}>
      <LexicalEditor />
    </LexicalProvider>
  );
}
