import { FC, MouseEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Chip } from '@mui/material';
import { IScenarioDataModel, ScenarioType } from '../../../../data-models/scenario.data-model';
import { ChipStack } from '../components/commonStyledComponents';
import { scenariosByCompanyIdState } from '../../state/ScenariosState';
import { getExistingScenarioTypes } from '../utils/scenarioUtils';
import { useScenarioActions } from '../hooks/useScenarioActions';
import { useCanEditScenario } from '../hooks/useCanEditScenario';

export const TypeChip: FC<{ type: ScenarioType }> = ({ type }) => {
  return <Chip label={type} color={typeToColorMap[type]} />;
};

export const ScenarioTypes: FC<{ scenario: IScenarioDataModel }> = ({ scenario }) => {
  const canEditType = useCanEditScenario(scenario);

  return canEditType ? (
    <EditableScenarioTypes scenario={scenario} />
  ) : (
    <ChipStack>
      {scenario.tags?.map((tag) => (
        <TypeChip key={tag} type={tag!} />
      ))}
    </ChipStack>
  );
};

const typeToColorMap: Record<ScenarioType, 'primary' | 'success' | 'error'> = {
  [ScenarioType.base]: 'primary',
  [ScenarioType.upside]: 'success',
  [ScenarioType.downside]: 'error',
};

export const EditableScenarioTypes: FC<{ scenario: IScenarioDataModel }> = ({ scenario }) => {
  const allCompanyScenarios = useRecoilValue(scenariosByCompanyIdState(scenario.companyId)) ?? [];
  const existing = getExistingScenarioTypes(allCompanyScenarios);
  const { handleUpdateScenario } = useScenarioActions();
  const [loading, setLoading] = useState(false);
  const tags = scenario.tags ?? [];

  const handleChipClick = async (e: MouseEvent, type: ScenarioType) => {
    e.stopPropagation();
    setLoading(true);
    if (tags.includes(type)) {
      const newTags = tags.filter((tag) => tag !== type);
      await handleUpdateScenario(scenario.id, { ...scenario, tags: newTags });
    } else {
      const newTags = [...tags, type];
      await handleUpdateScenario(scenario.id, { ...scenario, tags: newTags });
    }
    setLoading(false);
  };

  return (
    <ChipStack>
      {(Object.values(ScenarioType) as ScenarioType[]).map((type) => {
        return (
          <Chip
            onClick={(e) => handleChipClick(e, type)}
            disabled={loading || shouldDisableTagging(type, scenario, existing)}
            key={type}
            label={type}
            variant={tags.includes(type) ? 'filled' : 'outlined'}
            color={tags.includes(type) ? typeToColorMap[type] : 'default'}
            sx={{ backgroundColor: !tags.includes(type) ? 'white' : undefined }}
          />
        );
      })}
    </ChipStack>
  );
};

export function shouldDisableTagging(
  type: ScenarioType,
  scenario: IScenarioDataModel,
  existingTags: Set<ScenarioType>
) {
  return (existingTags.has(type) || Boolean(scenario.tags?.length)) && !scenario.tags?.includes(type);
}
