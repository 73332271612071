import { Stack } from '@mui/material';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { kpiUnassignedCompaniesListState } from '../../../../services/state/KPI/KPICompaniesState';
import { NoDataMessage2 } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { KPIRequestsSectorsFilter } from '../KPIFilters/KPIRequestsSectorsFilter';
import { FilterSection } from '../KPIRequests/components/Filters/FilterSection';
import { useKPICompaniesUnassignedColDefs } from './hooks/useKPICompaniesUnassignedColDefs';

/**@deprecated: replaced by AllCompanies */
export function KPICompaniesUnassignedGrid() {
  const { columnDefs, defaultColDef } = useKPICompaniesUnassignedColDefs();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const unassignedPortfolioCompaniesList = useRecoilValue(kpiUnassignedCompaniesListState);
  const [selectedSectors, setSelectedSectors] = useState<number[] | null>(null);

  const handleSectorsOnChange = useCallback(
    (value: number[] | null) => {
      setSelectedSectors(value);
    },
    [setSelectedSectors]
  );

  const unassignedPortfolioCompanies = useMemo(() => {
    if (!selectedSectors?.length) return unassignedPortfolioCompaniesList;

    return unassignedPortfolioCompaniesList?.filter((unassigned) =>
      selectedSectors?.includes(unassigned.sectorId as number)
    );
  }, [unassignedPortfolioCompaniesList, selectedSectors]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const onSearchCompanyChange = (value: string) => {
    gridApi?.setGridOption('quickFilterText', value);
  };

  if (!unassignedPortfolioCompanies) {
    return (
      <div style={{ marginTop: '2rem' }}>
        <NoDataMessage2 />
      </div>
    );
  }

  return (
    <Stack height={'100%'}>
      <Stack direction='row' alignItems='center' marginBottom={'1.5rem'} marginTop={'.5rem'}>
        <FilterSection nFilters={2} marginTop='1rem'>
          <SearchInput onChange={onSearchCompanyChange} placeholder='Search for a company' />
          <KPIRequestsSectorsFilter value={selectedSectors} onChange={handleSectorsOnChange} />
        </FilterSection>
      </Stack>
      <FadeInGridWrapper>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          groupIncludeTotalFooter={false}
          onGridReady={onGridReady}
          rowData={unassignedPortfolioCompanies}
          rowGroupPanelShow='always'
          suppressMovableColumns={true}
          suppressRowDrag={true}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
