import { AxiosRequestConfig } from 'axios';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { IDealConversionResponse } from '../../data-models/deal-conversion.data-model';
import { ILabelDataModel, ILabelResponseDataModel } from '../../data-models/deal-label.data-model';
import { IDealStatsDataModel } from '../../data-models/deal-stats.data-model';
import { IDealDataModel } from '../../data-models/deal.data-model';
import { DealBoardConfig, DealComplete, DealResponse } from '../../types';
import { MaggieHttp } from '../MaggieHttp';
import { ITodoDataModel } from '../../data-models/todo.data-model';

export const DEAL_BOARD_CONFIG = ['dealBoardConfig'];

export async function fetchDealBoardConfig(): Promise<DealBoardConfig> {
  const { data } = await MaggieHttp.maggie.get<DealBoardConfig>('/deal/config');
  data.dealStages.sort((a, b) => a.sortOrder - b.sortOrder);
  data.dealTypes.sort((a, b) => a.sortOrder - b.sortOrder);
  data.todoList.sort((a, b) => a.sortOrder - b.sortOrder);
  data.missedReasons.sort((a, b) => a.sortOrder - b.sortOrder);

  return data;
}
export async function fetchDealLabels(): Promise<ILabelResponseDataModel[]> {
  const { data } = await MaggieHttp.maggie.get<ILabelResponseDataModel[]>('/label');
  return data;
}

export async function createDealLabel(name: string): Promise<ILabelResponseDataModel> {
  const { data } = await MaggieHttp.maggie.post<Partial<ILabelDataModel>, ILabelResponseDataModel>(`/label`, {
    name,
  });
  return data;
}

export const DEALS = 'deals';

export function dealSort(d1: DealComplete | IDealDataModel, d2: DealComplete | IDealDataModel) {
  if (d1.priority === 1 && d2.priority === 1) {
    return new Date(d2.stageUpdateDate).getTime() - new Date(d1.stageUpdateDate).getTime();
  }
  if (d1.priority === 1) {
    return -1;
  }
  if (d2.priority === 1) {
    return 1;
  }
  return new Date(d2.stageUpdateDate).getTime() - new Date(d1.stageUpdateDate).getTime();
}

export async function fetchDealsByStageId(stageIds: number[], lastUpdated?: Date) {
  const res = await MaggieHttp.maggie.get<DealResponse>(`/deal`, {
    params: {
      stageIds,
      lastUpdated: lastUpdated?.toISOString(),
    },
  });
  return res.data;
}
export async function fetchDealsByCompanyIds(ids: number[]): Promise<IDealDataModel[]> {
  if (!ids.length) return [];
  const { data: dealData } = await MaggieHttp.maggie.get<DealResponse>(`/deal`, {
    params: {
      companyIds: ids,
    },
  });

  return dealData.deals.map((d) => ({
    ...d,
    company: dealData.companies.find((c) => c.id === d.companyId) as ICompanyDataModel,
  }));
}

export async function deleteDeal(id: number) {
  await MaggieHttp.maggie.delete<number>(`/deal/${id}`);

  return id;
}

export async function updateDeal(deal: IDealDataModel): Promise<IDealDataModel> {
  const { data } = await MaggieHttp.maggie.put<IDealDataModel, IDealDataModel>(`/deal/${deal.id}`, deal);

  return data;
}

export interface IDealStatsHistoryRequest {
  from: string;
  sectors?: number[];
  dealLead?: number;
  dealType?: number;
  page?: number;
  pageSize?: number;
}

export async function createDeal(
  deal: Partial<IDealDataModel>,
  params?: AxiosRequestConfig
): Promise<IDealDataModel> {
  const { data } = await MaggieHttp.maggie.post<Partial<IDealDataModel>, IDealDataModel>(
    `/deal`,
    deal,
    params
  );

  return data;
}

export async function fetchDealConversion(
  request: IDealStatsHistoryRequest
): Promise<IDealConversionResponse> {
  const parsedSectors: string | undefined = request.sectors?.join(',');

  const { data } = await MaggieHttp.maggie.get<IDealConversionResponse>(`/dealAnalytics/conversion`, {
    params: {
      ...request,
      sectors: parsedSectors,
    },
  });
  return data;
}

//params are: sectors,dealLead, from, page, pageSize
export async function fetchDealStatsHistory(request: IDealStatsHistoryRequest) {
  const parsedSectors: string | undefined = request.sectors?.join(',');

  const { data } = await MaggieHttp.maggie.get<IDealStatsDataModel[]>(`/dealAnalytics/history`, {
    params: {
      ...request,
      sectors: parsedSectors,
    },
  });

  return data;
}

export async function fetchDealTodos() {
  const { data } = await MaggieHttp.maggie.get<ITodoDataModel[]>('/dealTodoList');

  return data;
}
