import { styled } from '@mui/material/styles';
import { IListDataModel } from '../../../../../data-models/list.data-model';
import { CPListItem } from './CPListItem';

const Wrapper = styled('div')`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CPLists = ({ items }: { items: IListDataModel[] }) => {
  return (
    <Wrapper>
      {items.map((item) => (
        <CPListItem item={item} key={item.id} />
      ))}
    </Wrapper>
  );
};
