import { useEffect, useState } from 'react';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { fetchTransactions2 } from '../../../services/queries/MaggieTransactionQueries';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { allInvestmentRoundsMapState } from '../state/FinanceState';
import { transactionsDateFilterState } from './AllTransactionsFilterState';

export interface IExtendedTransactionDataModel extends ITransactionDataModel {
  investmentName?: string | null;
}

export const defaultDateRange = {
  fromDate: startOfDay(sub(new Date(), { years: 1 })).toISOString(),
  toDate: endOfDay(new Date()).toISOString(),
};

interface ITransactionDataParams {
  fromDate?: string;
  toDate?: string;
  forceRefetchCount?: number;
}

export function useAllTransactionsData({ forceRefetchCount }: ITransactionDataParams = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allTransactions, setAllTransactions] = useState<IExtendedTransactionDataModel[]>();
  const investmentsById = useRecoilValue(allInvestmentRoundsMapState);
  const { fromDate, toDate } = useRecoilValue(transactionsDateFilterState);

  useEffect(() => {
    setLoading(true);
    fetchTransactions2({ fromDate, toDate })
      .then((res) => {
        setAllTransactions(
          res.transactions.map((t) => {
            const investment = investmentsById.get(t.investmentRoundId ?? -1);
            return {
              ...t,
              investmentName: investment?.name,
              roundId: investment?.roundId ?? t.roundId,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fromDate, toDate, forceRefetchCount, investmentsById]);

  return { loading, error, allTransactions };
}
