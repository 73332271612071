import {
  DeprecatedGetterFormatting,
  DeprecatedGetterFormatType,
  GetterFormatting,
  GetterFormatType,
} from '../util/getFormatter';

export type FieldFormatting = GetterFormatting | DeprecatedGetterFormatting | null;

export interface FieldDataModel {
  chartType?: null;
  createdAt?: string;
  createdBy?: string;
  description?: string;
  displayName: string;
  dynamicHistogram?: boolean;
  formatting: FieldFormatting;
  groupName: string | null;
  groupOrder?: number;
  groupPriority?: number;
  heatMapping?: boolean;
  id: number;
  meta: FieldMeta;
  name: string;
  popup?: boolean;
  series: boolean;
  source?: string;
  type: GetterFormatType | DeprecatedGetterFormatType;
  updatedAt?: string;
  updatedBy?: string;
  visible?: boolean;
}

export interface FieldMeta {
  chart?: {
    type: string;
  };
}

export function createFieldDataModel(overrides: Partial<FieldDataModel> = {}): FieldDataModel {
  return {
    chartType: null,
    createdAt: '',
    createdBy: '',
    description: '',
    displayName: '',
    dynamicHistogram: false,
    formatting: null,
    groupName: null,
    groupOrder: 0,
    groupPriority: 0,
    heatMapping: false,
    id: 0,
    meta: {},
    name: '',
    popup: false,
    series: false,
    source: '',
    type: 'string',
    updatedAt: '',
    updatedBy: '',
    visible: false,
    ...overrides,
  };
}
export enum RendererType {
  /**
   * Core renderers
   */
  boolean = 'boolean',
  date = 'date',
  id = 'id',
  multiSelect = 'multiSelect',
  number = 'number',
  singleSelect = 'singleSelect',
  text = 'text',

  /**
   * Fancy renderers
   */
  colorStatus = 'colorStatus',
  colorStatusWithLabel = 'colorStatusWithLabel',
  companyId = 'companyId',
  companySearch = 'companySearch',
  companySocialMedia = 'companySocialMedia',
  companyWebsite = 'companyWebsite',
  currencyId = 'currencyId',
  fileUpload = 'fileUpload',
  freeLabels = 'freeLabels',
  kpiTable = 'kpiTable',
  location = 'location',
  universalKpiTable = 'universalKpiTable',

  /**
   * @deprecated: Use Renderer=number & configure formatter
   */
  currency = 'currency',
  /**
   * @deprecated: Use Renderer=number & configure formatter
   */
  integer = 'integer',
  /**
   * @deprecated: Use Renderer=number & configure formatter
   */
  multiplier = 'multiplier',
  /**
   * @deprecated: identical to RendererType.number
   */
  numeric = 'numeric',
  /**
   * @deprecated: Use Renderer=number & configure formatter
   */
  percent = 'percent',
  /**
   * @deprecated: Use alternative or customize non-deprecated with fieldSettings config
   */
  fundType = 'fundType',
}
