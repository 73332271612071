/** Returns the ids of collapsed stages for the given deal type id, extracted from collapsedDealStages stored as Recoil state */

export function getCollapsedStageIds(
  collapsedStages: Record<string, boolean>,
  activeDealTypeId: number
): number[] {
  return Object.keys(collapsedStages).reduce((arr, curr) => {
    if (collapsedStages[curr]) {
      // "dealTypeId-stageId"
      const [dealTypeId, stageId] = curr
        .split('-')
        .map((id) => parseInt(id))
        .filter(Boolean);
      if (dealTypeId !== activeDealTypeId) return arr;
      if (stageId) arr.push(stageId);
    }
    return arr;
  }, [] as number[]);
}
