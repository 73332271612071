import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Event } from '../../../types';
import { useNotificationConfig } from '../hooks/useNotificationConfig';
import { NotificationRow } from './NotificationRow';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`;

export function NotificationHeader() {
  const {
    data: {
      deal: { types, events },
    },
  } = useNotificationConfig();
  const [sortedEvents, setSortedEvents] = useState<Event[] | null>();

  useEffect(() => {
    if (!events) return;
    setSortedEvents([...events].sort(({ sortOrder: a }, { sortOrder: b }) => a - b));
  }, [events, types]);

  return (
    <Wrapper data-testid={'notification-preferences-events'}>
      {sortedEvents?.map((event) => (
        <NotificationRow event={event} key={event.id} />
      ))}
    </Wrapper>
  );
}
