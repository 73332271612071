import { Stack, Typography, useTheme } from '@mui/material';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { merge } from 'lodash-es';
import { IField } from '../../../data-models/field2.data-model';
import { universalAndSystemKPIState } from '../../../services/state/KPI/KPITemplatesState';
import { IKPITableDataModel, IKPITemplate } from '../../../data-models/kpi-template.data-model';
import { KpiSection } from '../../../data-models/company-financials.data-model';
import { KPITemplateBuildMenu } from './KPITemplateBuildMenu';
import { createKPIItemByType } from './KPITemplateFactory';
import { SectionItem } from './Sections/SectionItem';
import { disableActual } from './Sections/FormPreviewItem/KPITableConfig';

export function KPITemplateFormBuilder() {
  const { colors } = useTheme();
  const { fields: sections, append, remove, swap } = useFieldArray<IKPITemplate>({ name: 'sections' });
  const universalAndSystemKPI = useRecoilValue(universalAndSystemKPIState);

  const { control } = useFormContext();
  const existingSections = useWatch({ name: 'sections', control });
  const disableActualSection = useMemo(() => disableActual(existingSections), [existingSections]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onAddSectionItem = useCallback(
    (type: string) => {
      const section = createKPIItemByType(type, universalAndSystemKPI);
      append(
        merge(section, {
          meta: {
            ...section.meta,
            section: disableActualSection ? KpiSection.budget : KpiSection.actual,
          },
        })
      );
    },
    [append, disableActualSection, universalAndSystemKPI]
  );

  const onRemoveSectionItem = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const sectionsIds = sections.map((section) => section.id);
        const oldIndex = sectionsIds.findIndex((id) => id === active.id);
        const newIndex = sectionsIds.findIndex((id) => id === over?.id);

        swap(newIndex, oldIndex);
      }
    },
    [sections, swap]
  );

  return (
    <Stack width={'100%'}>
      <Stack width={'100%'} direction='row' gap='1rem' sx={{ marginBottom: '1rem' }}>
        <Stack width={'50%'}>
          <Typography variant='body1' color={colors.primary[100]} fontWeight={700}>
            Building
          </Typography>
        </Stack>
        <Typography variant='body1' color={colors.primary[100]} fontWeight={700}>
          Preview
        </Typography>
      </Stack>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext items={sections} strategy={rectSortingStrategy}>
          {sections.map((section, index) => {
            const sectionRef = `sections.${index}`;
            return (
              <SectionItem
                sectionId={section.id}
                sectionRef={sectionRef}
                key={sectionRef}
                onRemoveSection={() => onRemoveSectionItem(index)}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      <KPITemplateBuildMenu
        onAddSectionItem={onAddSectionItem}
        disableKpis={hasBothMetricsSections(sections)}
      />
    </Stack>
  );
}

export function hasBothMetricsSections(sections: (Partial<IField<unknown>> | IKPITableDataModel)[]) {
  return sections.filter(isKpiTableDataModel).length > 1;
}

function isKpiTableDataModel(
  section: Partial<IField<unknown>> | IKPITableDataModel
): section is IKPITableDataModel {
  return 'meta' in section && (section?.meta as IKPITableDataModel)?.metrics != null;
}
