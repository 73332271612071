import { useMemo } from 'react';
import { CashFlow } from '@webcarrot/xirr';
import { useRecoilValue } from 'recoil';
import { LineChartData, TQuoter } from '../../../../../types';
import { calculateXIRR } from '../../../providers/calculateXIRR';
import { QuartersBuilder } from '../../../services/QuartersBuilder';
import { mapLineChartData } from '../../../../../components/Charts/LineChart/mapLineChartData';
import { selectedViewDatePF } from '../../../state/PageState';
import { selectedViewIdPF } from '../../../state/ViewState';
import { metricsFilteredByViewState } from '../../../state/MetricsState';

import { MetricsTransactionDataModel } from '../../../../../schemas/MetricsTransaction.schema';

type TQuoterInvestments = Record<string, CashFlow[]>;

interface IrrByGroup {
  data: LineChartData[];
  last12Quoters: TQuoter[];
}

export function useIrrOverall(): IrrByGroup {
  const viewId = useRecoilValue(selectedViewIdPF);
  const rawMetricsData = useRecoilValue(metricsFilteredByViewState(viewId));
  const date = useRecoilValue(selectedViewDatePF);

  const last12Quarters = useMemo(
    () =>
      QuartersBuilder.last12Quarters(date ?? new Date()).sort(
        (a: TQuoter, b: TQuoter) => a.start.getTime() - b.start.getTime()
      ),
    [date]
  );

  const data = useMemo(() => {
    const detaset: TQuoterInvestments = getFundQuoters(last12Quarters);

    function getTransactionQuoters(transaction: MetricsTransactionDataModel, quoters: TQuoter[]): TQuoter[] {
      if (!transaction.transactionDate) return [];

      const tDate = new Date(transaction.transactionDate);
      tDate.setHours(0);
      tDate.setMinutes(0);
      tDate.setSeconds(0);

      return quoters.filter((q) => tDate <= q.end);
    }

    function getFundQuoters(quoters: TQuoter[]) {
      return quoters.reduce((map, q) => ({ ...map, [q.label]: [] }), {});
    }

    rawMetricsData?.forEach((rawMetric) => {
      rawMetric.transactions.forEach((transaction) => {
        const transactionQuoters = getTransactionQuoters(transaction, last12Quarters);
        if (!transactionQuoters?.length) return;

        transactionQuoters.forEach((quoter) => {
          if (!detaset[quoter.label]) {
            detaset[quoter.label] = [];
          }

          if (transaction.investmentAmount) {
            detaset[quoter.label].push({
              date: new Date(transaction.transactionDate),
              amount: -1 * transaction.investmentAmount,
            });
          }

          if (transaction.distributions) {
            detaset[quoter.label].push({
              date: new Date(transaction.transactionDate),
              amount: transaction.distributions,
            });
          }

          if (transaction.currentInvestment) {
            detaset[quoter.label].push({
              date: quoter.end,
              amount: transaction.currentInvestment,
            });
          }
        });
      });
    });

    const fundQuarterXirr: Record<string, number> = {};
    Object.keys(detaset).forEach((quarter) => {
      const investments = detaset[quarter];
      fundQuarterXirr[quarter] = investments.length < 2 ? 0 : calculateXIRR(investments);
    });

    const data = Object.keys(fundQuarterXirr).map((quoter) => ({
      value: fundQuarterXirr[quoter],
      period: quoter,
    }));

    return mapLineChartData(data);
  }, [last12Quarters, rawMetricsData]);

  return useMemo(() => ({ data, last12Quoters: last12Quarters }), [data, last12Quarters]);
}
