import { styled, SxProps } from '@mui/material';
import { AvatarSize } from '../Avatar/MUIAvatar';
import { MUIAvatarGroup } from '../Avatar/MUIAvatarGroup';
import { IUserDataModel } from '../../data-models/user.data-model';
import { UserAvatar } from '../UserAvatar/UserAvatar';

interface IAvatarListProps {
  users: number[] | IUserDataModel[];
  size?: AvatarSize;
  sx?: SxProps;
}

const Container = styled('div')``;

const StyledAvatarGroup = styled(MUIAvatarGroup)`
  display: inline-flex;
  > .MuiAvatarGroup-avatar {
    z-index: 1;
  }
  > .MuiAvatar-root.MuiAvatar-circular {
    outline-width: 1px;
  }
`;

export const UserAvatarList = ({ users, size = 'small', sx }: IAvatarListProps) => {
  return (
    <Container data-testid='avatarlist-container' sx={sx}>
      <StyledAvatarGroup max={4} size={size}>
        {users.map((user, i) => (
          <UserAvatar key={i} user={user} />
        ))}
      </StyledAvatarGroup>
    </Container>
  );
};
