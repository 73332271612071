import { groupBy } from 'lodash-es';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { FMT } from '../../../../../util/formatter-service';

export function groupRequestsByMonth(reqs: IKPIRequestDataModel[]) {
  const filteredAndSortedReqs = reqs
    .filter((req) => !!getReqDate(req))
    .sort((a, b) => {
      return new Date(getReqDate(a)!).getTime() - new Date(getReqDate(b)!).getTime();
    });
  return groupBy(filteredAndSortedReqs, (req) => FMT.format('monthYearLong', getReqDate(req)));
}

function getReqDate(req: IKPIRequestDataModel) {
  return req.period;
}
