import { useCallback } from 'react';
import { useFundIdField } from '../../../data-fields/CommonFields';
import { transactionDateField } from '../../../data-fields/TransactionFields';

export function useMiscWriteOffFields() {
  const fundIdField = useFundIdField();
  return useCallback(() => {
    return [fundIdField, transactionDateField()];
  }, [fundIdField]);
}
