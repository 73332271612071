import { useCallback } from 'react';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { simpleMultilineTextField } from '../../../data-fields/CommonFields';
import { useGetAmountField } from './TransactionFormUtils';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useEscrowFields() {
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const getCommonInitialFields = useFundDateCurrencyFields();

  const getEscrowReceivedFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      getAmountField({ key: 'escrowAmount', label: 'Cash Received', required: true }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getAmountField, getCommonInitialFields]);

  const getEscrowWriteOffFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      getAmountField({ key: 'escrowAmount', label: 'Amount', required: true }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getAmountField, getCommonInitialFields]);

  return { getEscrowReceivedFields, getEscrowWriteOffFields };
}
