import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, useTheme, Alert, IconButton, Link } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useRecoilValue } from 'recoil';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { dealStageIdToCategoryState } from '../../state/DealboardDataState';
import { roundsState } from '../../../../services/state/AppConfigState';
import { DealBoardDealCategory, LoadingId } from '../../../../types';
import { getDealCategory } from '../../utils/getDealCategory';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { FormTextField } from '../../../../components/FormField/FormTextField';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { useUpdateCompanyAndState } from '../../../../services/hooks/useUpdateCompanyAndState';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { toURLFriendlyStringWithDashes } from '../../../../util/url-utils';
import { StageStatusBar } from './StageStatusBar';
import { DATA_SECTION_WIDTH } from './DataSection/DataSection';
import { TrackSplitButton } from './TrackSplitButton';
import { PassSplitButton } from './PassSplitButton';
import { SplitButton } from './SplitButton';
import { PriorityStar } from './PriorityStar';
import { NextStageButton } from './NextStageButton';
import { DrawerNavigation } from './DrawerNavigation';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const DealInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const CompanyNameAndLogoWrapper = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr auto auto;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  width: ${DATA_SECTION_WIDTH};
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & img {
    height: 27px;
    max-width: 5rem;
    object-fit: contain;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const TypographyWrapper = styled('div')`
  margin-bottom: 12px;
  height: 1.5rem; // fix the height whether there is any content or not
`;

const alertDuration = 2000;

export interface IDrawerHeaderProps {
  selectedDeal: IDealDataModel;
  company: ICompanyDataModel;
}

export const DrawerHeader: FC<IDrawerHeaderProps> = (props) => {
  const { selectedDeal, company } = props;
  const updateCompany = useUpdateCompanyAndState();
  const dealStageIdToCategory = useRecoilValue(dealStageIdToCategoryState);
  const { colors } = useTheme();
  const rounds = useRecoilValue(roundsState);
  const [showLoader, setShowLoader] = useState(false);
  const [alert, setAlert] = useState<{ message: string; severity: 'success' | 'error' }>();
  const [showAlert, setShowAlert] = useState(false);
  const { actions } = useLoadingBarState();
  const { pushErrorToast } = useToastMessageState();

  useEffect(() => {
    if (!alert) return;
    setShowAlert(true);
    const timerId = setTimeout(() => {
      setShowAlert(false);
      setAlert(undefined);
    }, alertDuration);
    return () => clearTimeout(timerId);
  }, [alert]);

  const round = rounds.find((r) => r.id === selectedDeal.roundId);
  const dealCategory = getDealCategory(dealStageIdToCategory, selectedDeal.stageId);
  const isPassedDeal = dealCategory === DealBoardDealCategory.PASS;
  const isTrackedDeal = dealCategory === DealBoardDealCategory.TRACK;
  const showButtons = dealCategory === DealBoardDealCategory.CURRENT;
  const [companyNameValue, setCompanyNameValue] = useState('');
  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const formattedRange = useMemo(() => {
    const valuationMin = selectedDeal.gcAmountMin;
    const valuationMax = selectedDeal.gcAmountMax;
    if (!valuationMax || !valuationMin) return '';
    if (valuationMax === valuationMin) return formatUSDShort(valuationMax);
    return `${formatUSDShort(valuationMin)} - ${formatUSDShort(valuationMax)}`;
  }, [selectedDeal.gcAmountMax, selectedDeal.gcAmountMin]);

  useEffect(() => {
    setCompanyNameValue(company.name);
  }, [company.name]);

  const onCompanyEdited = useCallback(
    async (newName: string) => {
      actions.startLoading(LoadingId.updateCompany);
      try {
        await updateCompany(selectedDeal.companyId, { name: newName }, true);
      } catch (error) {
        pushErrorToast({ message: 'Failed to update company' });
      } finally {
        actions.stopLoading(LoadingId.updateCompany);
      }
    },
    [actions, pushErrorToast, selectedDeal, updateCompany]
  );

  const logo = company.logoUrl;

  return (
    <>
      {showLoader && <LinearProgress style={{ marginBottom: '2.5rem' }} />}
      {showAlert && (
        <Alert severity={alert?.severity} style={{ position: 'absolute' }}>
          {alert?.message}
        </Alert>
      )}
      {!showLoader && <DrawerNavigation />}
      <DealInfoWrapper>
        <Wrapper>
          <CompanyNameAndLogoWrapper>
            <MUIAvatar size='medium' src={logo ?? ''} nameParts={company.name.split(' ')} />
            <FormTextField
              value={companyNameValue}
              onChange={(e) => setCompanyNameValue(e.target.value)}
              onValueChanged={onCompanyEdited}
              inputProps={{
                style: { fontSize: 20 },
              }}
              InputLabelProps={{ style: { fontSize: 20 } }}
              disabled={company.crunchbaseId !== null}
            />
            <PriorityStar deal={selectedDeal} />
            <IconButton
              component={Link}
              href={`/${ROUTES.COMPANY_PROFILES}/${toURLFriendlyStringWithDashes(company.name)}-${
                selectedDeal.companyId
              }/summary`}
              target='_blank'
              rel='noreferrer'
            >
              <OpenInNewIcon htmlColor={colors.primary[60]} fontSize='small' />
            </IconButton>
          </CompanyNameAndLogoWrapper>
          {canEditDeal && (
            <ButtonWrapper>
              {isTrackedDeal && <TrackSplitButton />}
              {isPassedDeal && <PassSplitButton />}
              {showButtons && (
                <>
                  <SplitButton />
                  <NextStageButton setShowLoader={setShowLoader} setAlert={setAlert} />
                </>
              )}
            </ButtonWrapper>
          )}
        </Wrapper>

        <TypographyWrapper>
          <Typography component='div' variant='body2' color={colors.neutral[60]}>
            <InnerWrapper>
              <span>{round?.displayName ?? ''}</span>
              <span>{formattedRange}</span>
            </InnerWrapper>
          </Typography>
        </TypographyWrapper>
        <StageStatusBar />
      </DealInfoWrapper>
    </>
  );
};
