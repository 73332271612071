import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce } from 'lodash-es';
import { ValidationError } from 'yup';
import { Collapse } from '@mui/material';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { CompanySuggestion } from '../../DealFlow2/components/DealModal/CompanyForm/CompanySuggestion';
import { DuplicateWebsiteErrorType, companyWebsiteSchema } from './CompanyFormUtils';

export function CompanyDuplicate() {
  const [duplicate, setDuplicate] = useState<ICompanyDataModel | null>(null);

  const websiteValue = useWatch({ name: 'website' });
  const {
    formState: { defaultValues },
  } = useFormContext<Partial<ICompanyDataModel>>();

  const debouncedCheck = useMemo(
    () =>
      debounce((input: string) => {
        companyWebsiteSchema
          .validate(input)
          .then(() => {
            setDuplicate(null);
          })
          .catch((err: ValidationError) => {
            if (err.type !== DuplicateWebsiteErrorType || !err.params?.company) {
              setDuplicate(null);
              return;
            }
            setDuplicate(err.params.company as ICompanyDataModel);
          });
      }, 300),
    []
  );

  useEffect(() => {
    if (!websiteValue || defaultValues?.website === websiteValue || defaultValues?.domain === websiteValue) {
      setDuplicate(null);
      return;
    }
    debouncedCheck(websiteValue);
  }, [debouncedCheck, defaultValues?.domain, defaultValues?.website, websiteValue]);

  return (
    <Collapse in={Boolean(duplicate)}>
      {duplicate ? <CompanySuggestion company={duplicate} style={{ marginBottom: '1rem' }} /> : <div />}
    </Collapse>
  );
}
