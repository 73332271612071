import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { createFilterOptions, Autocomplete, TextField, Chip, useTheme, Typography } from '@mui/material';

const CreateNewPrompt = ' (create new)';

interface CreatableOption {
  inputValue: string;
  label: string;
}

const filter = createFilterOptions<CreatableOption>();

export interface ISelectWithCreatableOptionsProps {
  initialValue?: string;
  options: string[];
  handleChange: (newValue: string) => void;
  fullWidth?: boolean;
  className?: string;
}

export function SelectWithCreatableOptions(props: ISelectWithCreatableOptionsProps) {
  const { initialValue, handleChange, options, fullWidth } = props;
  const { colors } = useTheme();
  const [value, setValue] = useState(initialValue && typeToOption(initialValue));

  const opts = useMemo(() => mapTypesToOptions(options), [options]);

  function _handleChange(event: SyntheticEvent, newValue: CreatableOption | string | null) {
    if (!newValue) return;
    if (typeof newValue === 'string') {
      setValue(typeToOption(newValue));
      handleChange(newValue);
    } else if (newValue.inputValue) {
      setValue(typeToOption(newValue.inputValue));
      handleChange(newValue.inputValue);
    }
  }

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: CreatableOption) => {
      const label = option.label.replace(CreateNewPrompt, '');
      return (
        <Typography
          component='li'
          variant={'body2'}
          {...props}
          key={option.label}
          sx={{ marginLeft: '-0.5rem' }}
        >
          <Chip
            size='small'
            label={label}
            style={{ background: colors.tertiary[10], color: 'text.primary' }}
          />

          {option.label.endsWith(CreateNewPrompt) && (
            <Typography variant='caption' color='secondary' pl='0.5rem'>
              {CreateNewPrompt}
            </Typography>
          )}
        </Typography>
      );
    },
    [colors.tertiary]
  );

  return (
    <Autocomplete
      value={value}
      onChange={_handleChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: `${inputValue}${CreateNewPrompt}`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={opts}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Create new "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={renderOption}
      freeSolo
      openOnFocus
      renderInput={(params) => <TextField {...params} />}
      fullWidth={fullWidth}
      blurOnSelect
      componentsProps={{
        paper: {
          sx: {
            paddingTop: '0.5rem',
            ':before': {
              content: '"Select an option or create a new one"',
              transform: { translate: '0, 1.25rem' },
              marginLeft: '0.5rem',
              fontSize: '0.75rem',
              color: 'text.secondary',
            },
            '.MuiAutocomplete-option.Mui-focused': {
              backgroundColor: `${colors.secondary[5]}`,
            },
          },
        },
      }}
    />
  );
}

function mapTypesToOptions(types: string[]): CreatableOption[] {
  return types.map(typeToOption);
}

function typeToOption(type: string): CreatableOption {
  return {
    inputValue: type,
    label: type,
  };
}
