import { styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';

const CHART_SIZE = 300;

const SkeletonLoaderWrapper = styled('div')`
  margin-top: 2rem;
  width: 100%;
  height: 60vh;
`;

export function ChartSkeletonLoader() {
  const chartRects = [];
  const startX = 0;
  const startY = 0;
  const gap = 16;
  for (let row = 0; row < 2; row++) {
    for (let col = 0; col < 4; col++) {
      chartRects.push(
        <rect
          key={`${row}-${col}`}
          x={(startX + gap + CHART_SIZE) * col}
          y={(startY + gap + CHART_SIZE) * row}
          rx='0'
          ry='0'
          width={CHART_SIZE}
          height={CHART_SIZE}
          fill='#EEEFF1'
        />
      );
    }
  }

  return (
    <SkeletonLoaderWrapper>
      <ContentLoader speed={2} width={'100%'} height={'100%'}>
        <>{chartRects}</>
      </ContentLoader>
    </SkeletonLoaderWrapper>
  );
}
