import { styled } from '@mui/material';
import { CountBadge } from '../../../../pages/KPI/components/KPIRequests/TabComponents';

export const NavbarBadge = styled(CountBadge)`
  & .MuiBadge-badge {
    background: ${({ theme }) => theme.colors.tertiary[50]};
    color: ${({ theme }) => theme.colors.primary[0]};
  }
  top: 30%;
`;
