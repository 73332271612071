import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { IPersonDataModel } from '../../../../data-models/person.data-model';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';

const Wrapper = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
`;

export const PersonRenderer = (params: ICellRendererParams<IPersonDataModel>) => {
  const person = params.data;

  if (!person) return null;

  const { firstName, lastName, logoUrl } = person;
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <Wrapper>
      <MUIAvatar size={'medium'} nameParts={[firstName ?? '', lastName ?? '']} src={logoUrl} />
      <Typography variant='body2'>{fullName}</Typography>
    </Wrapper>
  );
};
