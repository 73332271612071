import { Box, Modal as MUIModal } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { useModalState } from './ModalContext';

const style: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  outline: 'none',
};

export function Modal() {
  const { modalContent, onCloseModal } = useModalState();
  const isOpen = !!modalContent;

  return (
    <MUIModal open={isOpen} onClose={onCloseModal}>
      <Box sx={style}>{modalContent ?? <></>}</Box>
    </MUIModal>
  );
}
