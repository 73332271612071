import { ICellRendererParams } from 'ag-grid-community';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { mapSectorToColor } from '../../DealCard/mapSectorToColor';
import { IDealGridData } from '../DealGrid';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';

const NameCell = styled('span')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[60]};
  width: 100%;
  height: 100%;
  padding: 0.3rem;
`;

const CompanyName = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CompanyCellRenderer: React.FC<ICellRendererParams> = memo(function CompanyCellRenderer({
  node,
  value,
}: ICellRendererParams) {
  const data: IDealGridData | undefined = node.data;
  const logoUrl = data?.company?.logoUrl;
  const sectorId = data?.company.sectorId;
  const sectorColor = mapSectorToColor(sectorId);

  return node.group ? null : (
    <NameCell data-testid='deal-company-cell-renderer' style={{ borderLeft: `4px solid ${sectorColor}` }}>
      <MUIAvatar src={logoUrl ?? ''} nameParts={data?.company?.name?.split(' ') ?? []} />

      <CompanyName>{data?.company.name || value}</CompanyName>
    </NameCell>
  );
});
