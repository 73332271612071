import { sum } from 'lodash-es';
import { DistributionSegment } from './FPWaterfallData';

/**
 * @returns array of column widths as percentages;
 * if any segment value is less than 0.01, its corresponding column width is set to 1%;
 * if the difference in totals is less than the maximum column width,
 * and max column width is > 1, the maximum column width
 * is reduced by the difference.
 *
 */
export function getVisibleColumnWidths(segments: DistributionSegment[]) {
  const colWidths = segments.map((seg) => Math.max(seg.percentValue, 0.01) * 100);
  const diff = sum(colWidths) - sum(segments.map((seg) => seg.percentValue * 100));
  if (diff > 0 && colWidths.some((w) => w > 1)) {
    const maxIndex = colWidths.indexOf(Math.max(...colWidths));
    if (diff < colWidths[maxIndex]) colWidths[maxIndex] -= diff;
  }
  return colWidths;
}
