import { atom, selector, useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { IFundDataModel } from '../../data-models/fund.data-model';
import { IAppConfigDataModel, IGenericSortable } from '../../data-models/app-config.data-model';
import { IRoundDataModel } from '../../data-models/round.data-model';
import { TransactionCategory, TransactionType } from '../../data-models/transaction-type.data-model';
import { IUserDataModel } from '../../data-models/user.data-model';
import { IMetricsConfigDataModel } from '../../data-models/metrics-config.data-model';
import { ICurrencyDataModel } from '../../data-models/currency.data-model';

export const appConfigState = atom<IAppConfigDataModel>({
  key: 'appConfigState',
});

export const fundsState = selector<IFundDataModel[]>({
  key: 'fundsState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.funds;
  },
});

export const fundsByIdMapState = selector<Map<number, IFundDataModel>>({
  key: 'fundsMapState',
  get: ({ get }) => {
    const map = new Map<number, IFundDataModel>();
    get(fundsState).forEach((fund) => {
      map.set(fund.id, fund);
    });

    return map;
  },
});

export const fundTypeListState = selector<Set<string>>({
  key: 'fundTypeListState',
  get: ({ get }) => {
    const funds = get(fundsState);
    return new Set(funds.map((fund) => fund.fundType));
  },
});

export const roundsState = selector<IRoundDataModel[]>({
  key: 'roundsState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.rounds;
  },
});

export const roundsMapState = selector<Map<number, IRoundDataModel>>({
  key: 'roundsMapState',
  get: ({ get }) => {
    const map = new Map<number, IRoundDataModel>();
    get(roundsState).forEach((round) => {
      map.set(round.id, round);
    });

    return map;
  },
});

export const metricsConfigState = selector<IMetricsConfigDataModel | null>({
  key: 'metricsConfigState',
  get: ({ get }) => {
    return get(appConfigState).metricsConfig;
  },
});

export const roundsMapByNameState = selector<Map<string, IRoundDataModel>>({
  key: 'roundsMapByNameState',
  get: ({ get }) => {
    const map = new Map<string, IRoundDataModel>();
    get(roundsState).forEach((round) => {
      map.set(round.displayName, round);
    });
    return map;
  },
});

export const sectorsState = selector<IGenericSortable[]>({
  key: 'sectorsState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.sectors;
  },
});

export const sectorsMapState = selector<Map<number, IGenericSortable>>({
  key: 'sectorsMapState',
  get: ({ get }) => {
    const map = new Map<number, IGenericSortable>();
    get(sectorsState).forEach((sector) => {
      map.set(sector.id, sector);
    });

    return map;
  },
});

export const sectorsOrderedState = selector<IGenericSortable[]>({
  key: 'sectorsOrderedState',
  get: ({ get }) => {
    const sectors = get(sectorsState);

    return [...sectors].sort((a, b) => a.sortOrder - b.sortOrder);
  },
});

export const teamsState = selector<IGenericSortable[]>({
  key: 'teamsState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.teams;
  },
});

export const teamsMapState = selector<Map<number, IGenericSortable>>({
  key: 'teamsMapState',
  get: ({ get }) => {
    const map = new Map<number, IGenericSortable>();
    get(teamsState).forEach((team) => {
      map.set(team.id, team);
    });

    return map;
  },
});

export const transactionTypesState = selector<TransactionType[]>({
  key: 'transactionTypesState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.transactionTypes;
  },
});

export const transactionTypesAndSubTypeState = selector({
  key: 'transactionTypesAndSubTypeState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.transactionTypes.reduce((res, transactionType) => {
      if (!res.has(transactionType.catergory)) {
        res.set(transactionType.catergory, new Map());
      }
      res.get(transactionType.catergory)!.set(transactionType.name, transactionType);

      return res;
    }, new Map<string, Map<string, TransactionType>>());
  },
});

export const transactionTypesMapState = selector<Map<number, TransactionType>>({
  key: 'transactionTypesMapState',
  get: ({ get }) => {
    const map = new Map<number, TransactionType>();
    get(transactionTypesState).forEach((transactionType) => {
      map.set(transactionType.id, transactionType);
    });

    return map;
  },
});

export const usersState = selector<IUserDataModel[]>({
  key: 'usersState',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return [...appConfig.users].sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );
  },
});

export const activeUsersState = selector<IUserDataModel[]>({
  key: 'activeUsersState',
  get: ({ get }) => {
    const allUsers = get(usersState);

    return allUsers.filter((user) => user.activeStatus === true);
  },
});

interface IUserOption {
  id: number;
  value: string;
  label: string;
}

export const activeUserOptionsState = selector<IUserOption[]>({
  key: 'activeUserOptionsState',
  get: ({ get }) => {
    const activeUsers = get(activeUsersState);
    return activeUsers.map((user) => ({
      id: user.id,
      value: user.name,
      label: user.name,
    }));
  },
});

export const usersByIdMapState = selector<Map<number, IUserDataModel>>({
  key: 'usersByIdMapState',
  get: ({ get }) => {
    const map = new Map<number, IUserDataModel>();
    get(usersState).forEach((user) => {
      map.set(user.id, user);
    });

    return map;
  },
});

export const usersByEmailMapState = selector<Map<string, IUserDataModel>>({
  key: 'usersByEmailMapState',
  get: ({ get }) => {
    const map = new Map<string, IUserDataModel>();
    get(usersState).forEach((user) => {
      map.set(user.email, user);
    });

    return map;
  },
});

export const currenciesState = selector<ICurrencyDataModel[] | null>({
  key: 'currenciesStateConfig',
  get: ({ get }) => {
    const appConfig = get(appConfigState);

    return appConfig.currencies;
  },
});

export const currencyMapByIdState = selector<Map<number, ICurrencyDataModel>>({
  key: 'currencyMaByIdState',
  get: ({ get }) => {
    const currencies = get(currenciesState);
    if (!currencies) {
      return new Map();
    }
    return new Map(currencies.map((c) => [c.id, c]));
  },
});

export const currencyMapByCodeState = selector<Map<string, ICurrencyDataModel>>({
  key: 'currencyMapByCodeState',
  get: ({ get }) => {
    const currencies = get(currenciesState);
    if (!currencies) {
      return new Map();
    }
    return new Map(currencies.map((c) => [c.code, c]));
  },
});

export function useGetTransactionCategoryAndSubtype() {
  const transactionTypesById = useRecoilValue(transactionTypesMapState);

  return useCallback(
    (transactionTypeId: number) => {
      const transactionType = transactionTypesById.get(transactionTypeId);
      const transactionCategory = (transactionType?.catergory as TransactionCategory) ?? null;
      const transactionSubType = transactionType?.name ?? null;

      return {
        transactionCategory,
        transactionSubType,
      };
    },
    [transactionTypesById]
  );
}
