import { atom, selector, useResetRecoilState } from 'recoil';
import { SelectItem } from '../../../types';
import { transactionTypesState } from '../../../services/state/AppConfigState';
import { defaultDateRange } from './useAllTransactionsData';

export const fundFilterState = atom<SelectItem[] | null>({
  key: 'fundFilterState',
  default: null,
});

export const transactionTypeOptionsState = selector<SelectItem[]>({
  key: 'transactionTypeOptionsState',
  get: ({ get }) => {
    const transactionTypes = get(transactionTypesState);
    const groups = new Set<string>(transactionTypes.map((type) => type.catergory));
    return Array.from(groups).map((type) => ({ value: type, id: type }));
  },
});

export const transactionTypeFilterState = atom<SelectItem[] | null>({
  key: 'transactionTypeFilterState',
  default: null,
});

export const transactionsDateFilterState = atom<{ fromDate: string; toDate: string }>({
  key: 'transactionsDateFilterState',
  default: {
    fromDate: defaultDateRange.fromDate,
    toDate: defaultDateRange.toDate,
  },
});

export function useResetTransactionFilters() {
  const resetFundFilter = useResetRecoilState(fundFilterState);
  const resetTransactionTypeFilter = useResetRecoilState(transactionTypeFilterState);
  const resetTransactionsDateFilter = useResetRecoilState(transactionsDateFilterState);

  return () => {
    resetFundFilter();
    resetTransactionTypeFilter();
    resetTransactionsDateFilter();
  };
}
