import { FC, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Status } from '../../../../../data-models/objective.data-model';
import { MilestoneStatus } from '../../../../../data-models/company-milestone.data-model';

const StatusCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.2rem;

  & .ellipsize {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface StatusComponentProps {
  status: Status | MilestoneStatus;
}

export const StatusComponent: FC<StatusComponentProps> = ({ status }) => {
  const { colors } = useTheme();

  const colorMap: Record<Status | MilestoneStatus, string> = useMemo(
    () => ({
      'At Risk': colors.critical[50],
      'Behind Plan': colors.critical[50],
      'On Track': colors.success[60],
      Completed: colors.success[60],
      Complete: colors.neutral[50],
      Progress: colors.warning[50],
    }),
    [colors]
  );

  const IconComp = getStatusIcon(status);

  return (
    <StatusCell>
      {IconComp !== null && <IconComp htmlColor={colorMap[status] ?? colors.neutral[50]} fontSize='small' />}
      <Typography className='ellipsize' variant='body2' color={colorMap[status] ?? colors.neutral[50]}>
        {status === 'Progress' ? 'In Progress' : status}
      </Typography>
    </StatusCell>
  );
};

function getStatusIcon(status: Status | MilestoneStatus) {
  switch (status) {
    case 'Behind Plan':
    case 'At Risk':
      return InfoOutlinedIcon;

    case 'Complete':
    case 'Completed':
      return CheckCircleOutlineIcon;

    case 'On Track':
    case 'Progress':
      return RadioButtonUncheckedIcon;
    default:
      return null;
  }
}
