import Badge from '@mui/material/Badge';
import { SxProps, styled } from '@mui/material/styles';

export const CountBadge = styled(Badge, { shouldForwardProp: (prop) => prop !== 'active' })<{
  active?: boolean;
}>`
  & .MuiBadge-badge {
    position: relative;
    top: 44%;
    right: 0.25rem;
    padding: 0 0.25rem;
    color: ${({ theme }) => theme.colors.primary[0]};
    background-color: ${({ theme, active }) =>
      active ? theme.colors.primary[60] : theme.colors.neutral[40]};
    font-size: 0.75rem;
  }
`;

export const KPITabSx: SxProps = {
  paddingLeft: 0,
  paddingRight: '1.5rem',
  minWidth: 'fit-content',
  paddingBottom: '0',
  marginBottom: '0.5rem',
  '&:not(:first-of-type)': {
    paddingLeft: '1rem',
  },
};
