import { capitalize } from 'lodash-es';
import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { BooleanFormattersConfig } from '../util/formatters/BooleanFormatters';
import { currencyIdField, integerField, multiplierField, usdField } from './common-schema-defs';
import { StakeholderInvestmentSchemas } from './StakeholderInvestment.schema';

export const CapTableInvestmentTypes = ['note-block', 'option-pool', 'share-class', 'warrant-block'] as const;
export type CapTableInvestmentType = (typeof CapTableInvestmentTypes)[number];

export const ShareClassTypes = ['common', 'preferred'] as const;
export type ShareClassType = (typeof ShareClassTypes)[number];

export enum InterestType {
  annually = 'annually',
  monthly = 'monthly',
  none = 'none',
  quarterly = 'quarterly',
}

export enum CompoundingPeriod {
  compound = 'compound',
  none = 'none',
  simple = 'simple',
}

export function captableInvestmentFields() {
  return {
    authorizedShares: integerField().nullable().label('Authorized Shares').default(null),
    captableId: yup.number().required().label('Captable ID'),
    cashRaised: usdField().nullable().label('Raised Amount').default(null),
    compoundingPeriod: yup
      .string()
      .oneOf(Object.values(CompoundingPeriod))
      .nullable()
      .label('Compounding Period')
      .default(null),
    conversionPrice: usdField(4, 4).nullable().label('Conversion Price').default(null),
    conversionRatio: multiplierField().nullable().label('Conversion Ratio').default(null),
    converted: yup.boolean().nullable().label('Converted').default(null),
    currencyId: currencyIdField().required().default(1),
    discountRate: yup.number().nullable().label('Discount Rate').default(null).meta({
      renderer: RendererType.percent,
    }),
    dividendAccrualPeriod: yup.string().nullable().label('Dividend Accrual Period').default(null),
    dividendAccrualType: yup.string().nullable().label('Dividend Accrual Type').default(null),
    dividendInterestType: yup.string().nullable().label('Dividend Interest Type').default(null),
    dividendType: yup.string().nullable().label('Dividend Type').default(null),
    dividendYield: yup.number().nullable().label('Dividend Yield').default(null),
    fullyDilutedShares: integerField().nullable().label('Total FDS').default(null),
    grantedOptions: integerField().nullable().label('Granted Options').default(null),
    id: yup.number().nullable().required(),
    interest: yup.number().nullable().label('Interest').default(null),
    interestRate: yup.number().nullable().label('Interest Rate').default(null).meta({
      renderer: RendererType.percent,
    }),
    interestType: yup
      .string()
      .oneOf(Object.values(InterestType))
      .nullable()
      .label('Interest Type')
      .default(null)
      .meta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: Object.values(InterestType).map((interestType) => ({
            value: interestType,
            displayName: capitalize(interestType),
          })),
        },
      }),
    investmentDate: yup.date().nullable().label('Investment Date').default(null),
    investmentType: yup
      .string()
      .oneOf(CapTableInvestmentTypes)
      .nullable()
      .label('Investment Type')
      .default(null),
    liquidationMultiplier: multiplierField().nullable().label('Liquidation Multiplier').default(null),
    maturityDate: yup.date().nullable().label('Maturity Date').default(null),
    name: yup.string().nullable().required().label('Name').default(''),
    outstandingCommittedRestrictedStockAwards: integerField()
      .nullable()
      .label('Committed Restricted Awards')
      .default(null),
    outstandingEquityAwardDerivatives: integerField().nullable().label('Total Granted Options').default(null),
    outstandingShares: integerField().nullable().label('Outstanding Shares').default(null),
    outstandingWarrants: integerField().nullable().label('Outstanding Warrants').default(null),
    pariPassu: yup.boolean().nullable().label('Pari-Passu').default(null),
    participating: yup.boolean().nullable().label('Participating').default(null),
    participationCap: multiplierField().nullable().label('Participation Cap').default(null),
    participationType: yup.string().nullable().label('Participation Type').default(null),
    pricePerShare: usdField(4, 4).nullable().label('Original Issue Price').default(null),
    principal: usdField(0).nullable().label('Principal').default(null),
    seniority: integerField().nullable().label('Seniority').default(null),
    shareClassRef: yup.string().nullable().label('Share Class Ref').default(null),
    shareClassType: yup.string().oneOf(ShareClassTypes).nullable().label('Share Class Type').default(null),
    stakeholderInvestments: yup
      .array()
      .of(StakeholderInvestmentSchemas.create())
      .nullable()
      .label('Stakeholder Investments')
      .default(null),
    strikePrice: usdField(4, 4).nullable().label('Strike Price').default(null),
    totalOptionPool: integerField().nullable().label('Total Unissued Options').default(null),
    ungrantedOptions: integerField().nullable().label('Ungranted Options').default(null),
    valuationCap: usdField().nullable().label('Valuation Cap').default(null),
  };
}

export function captableInvestmentGridSchema() {
  const fields = captableInvestmentFields();
  return yup.object().shape({
    ...fields,
    cashRaised: fields.cashRaised.gridMeta({
      aggFunc: 'sum',
    }),
    fullyDilutedShares: fields.fullyDilutedShares.gridMeta({
      aggFunc: 'sum',
    }),
    investmentDate: fields.investmentDate.gridMeta({
      renderer: RendererType.date,
    }),
    maturityDate: fields.maturityDate.gridMeta({
      renderer: RendererType.date,
    }),
    pariPassu: fields.pariPassu.gridMeta({
      renderer: RendererType.boolean,
      formatter: BooleanFormattersConfig.trueFalse,
    }),
  });
}

export function captableInvestmentFormSchema() {
  return yup.object({ ...captableInvestmentFields(), investmentDate: yup.string().nullable() }).omit(['id']);
}
