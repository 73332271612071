import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get as lodashGet } from 'lodash-es';
import { LinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/LinkRenderer';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { getFormattedValue } from '../../../../../util/getFormattedValue';
import { FormattedComponent } from '../../../../../util/FormattedComponent';
import { genericComparator } from '../../../../../util/comparators';
import { BoardMembersRenderer } from '../CellRenderers/BoardMembersRenderer';
import { MultiLinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/MultiLinkRenderer';

/** @deprecated */
const handleDeprecatedCases = (type: string): ColDef | ColGroupDef | undefined => {
  if (type === 'link')
    return {
      cellRenderer: LinkRenderer,
    };
};

const commonNumberColSettings: ColDef | ColGroupDef = {
  headerClass: 'ag-left-aligned-header',

  filter: 'agNumberColumnFilter',
  sortingOrder: ['desc', 'asc', null],
};

export const getTypeBasedDefs = (field: FieldDataModel, loading?: boolean): ColDef | ColGroupDef => {
  const { type, formatting } = field;

  let defs: ColDef | ColGroupDef = {
    valueFormatter: (params: ValueFormatterParams) =>
      getFormattedValue({ type, formatting, value: params.value }) || '',
    cellRenderer: FormattedComponent,
    cellRendererParams: {
      type,
      formatting,
      loading,
    },
    sortable: true,
  };

  switch (type) {
    case 'string':
      defs = {
        ...defs,
      };
      break;
    case 'number':
      defs = {
        ...defs,
        ...commonNumberColSettings,
      };
      break;
    case 'date':
      defs = {
        ...defs,
        // must have Date in grid as value for proper filtering/sorting
        valueGetter: (params: ValueGetterParams) => {
          const value = lodashGet(params?.data, field.name);
          return value ? new Date(value) : undefined;
        },
        valueFormatter: (params: ValueFormatterParams) => {
          const value = lodashGet(params?.data, field.name);
          return getFormattedValue({ type, formatting, value }) || '';
        },
        comparator: (val1: Date | null | undefined, val2: Date | null | undefined) => {
          return genericComparator(val1, val2);
        },
        filter: 'agDateColumnFilter',
      };
      break;
    case 'array':
      if (formatting === 'boardMembers') {
        defs = {
          cellRenderer: BoardMembersRenderer,
          filter: 'agTextColumnFilter',
        };
      }
      if (formatting === 'url') {
        defs = {
          cellRenderer: MultiLinkRenderer,
          filter: 'agTextColumnFilter',
        };
      }
      break;

    default:
      defs = { ...defs, ...(handleDeprecatedCases(type) || {}) };
  }

  return defs;
};
