import { VALID_EMAIL_REGEX } from '../../../../../../util/regex';

export const InvalidEmailsErrorMessage = 'Please make sure all emails are valid';

export function emailArrayValidator(values: string[]) {
  return validateEmails(values) || InvalidEmailsErrorMessage;
}

export function validateEmail(email: string) {
  return email.match(VALID_EMAIL_REGEX);
}

export function validateEmails(emails: string[]) {
  return emails.every(validateEmail);
}
