import { createTheme, PaletteOptions, ThemeOptions } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { colors } from './colors';
import { gradients } from './gradients';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#6A45D3',
    light: '#A992EB',
    dark: '#31177A',
  },
  secondary: {
    main: '#282E9A',
    light: '#9397EB',
    dark: '#161957',
  },
  error: {
    main: '#D04246',
    dark: '#A4282C',
    light: '#EFBEBF',
  },
  warning: {
    main: '#FFB650',
    dark: '#CC7700',
    light: '#FFE1B8',
  },
  success: {
    main: '#4E9B93',
    dark: '#336661',
    light: '#8CC5BF',
  },
  info: {
    main: '#144D60',
    dark: '#0C2D38',
    light: '#45B8DD',
  },
  background: {
    default: 'white',
    paper: 'white',
  },
};

const components: Components<Omit<Theme, 'components'>> = {
  MuiAutocomplete: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiChip: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFab: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiFilledInput: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiInputBase: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
  },
  MuiInputLabel: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
  },
  MuiListItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiMenuItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '.MuiTabs-indicator': {
          backgroundColor: '#282E9A',
        },
        '.MuiTab-root': {
          fontWeight: 400,
          color: '#808999',
        },
        '.MuiTab-root.Mui-selected': {
          fontWeight: 500,
          color: '#282E9A',
        },
      },
    },
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
      margin: 'dense',
    },
    styleOverrides: {
      root: {
        '&&& .MuiInputBase-input': {
          fontFamily: 'Wotfard-Regular',
          fontWeightBold: 'Wotfard-Bold',
          fontSize: '14px',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#42229C',
          },
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiToolbar: {
    defaultProps: {
      variant: 'dense',
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: '#10253EDE',
        backgroundColor: 'white',
        boxShadow: `rgb(255 255 255 / 10%) 0px -4px 40px, rgb(16 37 62 / 6%) 0px 4px 24px,rgb(75 82 93 / 4%) 0px 24px 48px`,
        border: 'thin solid #D2D5DB',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      standardInfo: {
        backgroundColor: '#D6EEF5',
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        caption2: 'span',
        monotype: 'span',
        tpgrOverline: 'span',
      },
    },
  },
};

export const CustomTypographyVariants = {
  h1: {
    fontSize: '40px',
    lineHeight: '116.7%',
    fontFamily: 'Wotfard-Light',
    fontWeight: 300,
  },
  // TODO: sync with Maria to update the Figma file for h2-h6
  h2: {
    fontSize: '36px',
    lineHeight: '116.5%',
    fontFamily: 'Wotfard-Light',
    fontWeight: 300,
  },
  h3: {
    fontSize: '32px',
    lineHeight: '123.4%',
  },
  h4: {
    fontSize: '28px',
    lineHeight: '123.5%',
    fontWeight: 'lighter',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '160%',
  },
  caption2: {
    fontSize: '10px',
    lineHeight: '166%',
  },
  monotype: {
    fontFamily: 'IBMPlexSans-Regular',
    fontSize: '14px',
    lineHeight: '144%',
  },
  tpgrOverline: {
    fontSize: '12px',
    lineHeight: '266%',
    textTransform: 'uppercase' as const,
  },
};

export const greycroftTheme: ThemeOptions = createTheme({
  palette,
  gradients,
  spacing: 2,
  typography: {
    fontFamily: 'Wotfard-Regular',
    fontWeightBold: 'Wotfard-Bold',
    fontWeightLight: 'Wotfard-Light',
    ...CustomTypographyVariants,
  },
  components,
  colors,
});
