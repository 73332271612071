import { useRecoilValue } from 'recoil';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { getInvestmentLabel, useInvestmentAssignmentField } from '../../../data-fields/TransactionFields';
import { companyRoundsByIdState } from '../state/FinanceState';
import { roundsMapState } from '../../../services/state/AppConfigState';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { IInvestmentFormProps } from './InvestmentSelectOrCreateForm';

const minSelectorHeight = '3.75rem';

interface IInvestmentSelectFormProps extends IInvestmentFormProps {
  onAddNewInvestment: (name?: string) => void;
}
export function InvestmentSelectForm(props: IInvestmentSelectFormProps) {
  const [showRemoveInfo, setShowRemoveInfo] = useState(false);
  const { companyId, onAddNewInvestment, removeConfig } = props;
  const assignInvestmentField = useInvestmentAssignmentField(companyId, onAddNewInvestment);
  const assignToExistingForm = createFormFromFieldsArray([assignInvestmentField]);

  const handleRemove = useCallback(() => {
    setShowRemoveInfo(true);
    removeConfig!.onRemove();
  }, [removeConfig]);

  return (
    <Stack
      display={'grid'}
      style={{
        gridTemplateColumns: '1fr auto',
        alignItems: 'end',
        gap: '0',
        alignSelf: 'start',
      }}
    >
      {showRemoveInfo && removeConfig ? (
        <RemoveInvestmentInfo transaction={removeConfig.transaction} />
      ) : (
        <>
          <FormFactoryWithStandardLayout form={assignToExistingForm} />
          {Boolean(removeConfig) && (
            <Button
              type='button'
              color={'secondary'}
              size='medium'
              onClick={handleRemove}
              style={{ marginLeft: '0.5rem' }}
            >
              Remove
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}
interface IRemoveInvestmentInfoProps {
  transaction: ITransactionDataModel;
}
export const RemoveInvestmentInfo: FC<IRemoveInvestmentInfoProps> = ({ transaction }) => {
  const { investmentRoundId, companyId } = transaction;
  const investmentsById = useRecoilValue(companyRoundsByIdState(companyId));
  const investment = investmentsById.get(investmentRoundId ?? -1);
  const roundsById = useRecoilValue(roundsMapState);

  if (!investment) {
    return null;
  }

  return (
    <Stack style={{ gap: '0.75rem', minHeight: minSelectorHeight }}>
      <Typography variant='caption' color='text.primary'>
        Investment Round
      </Typography>
      <Typography variant='body2' color='text.secondary'>
        {`Associated investment ${getInvestmentLabel(investment, roundsById)} removed`}
      </Typography>
    </Stack>
  );
};
