import { Typography } from '@mui/material';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  ProcessCellForExportParams,
  RowClassParams,
} from 'ag-grid-community';
import { FC, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { AgTable } from '../../../components/AgTable/AgTable';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { commonProcessCb } from '../../../components/AgTable/exportToExcelDefs';
import { colors } from '../../../theme/colors';
import { ExportableColumns, exportParams } from './usePeopleColDefs';
import { useColumnTextValueGetters } from './columnTextValueGetters';

const GridContainer = styled('section')`
  height: 30vh;
  margin: 1rem 0 2rem;
  border-radius: 4px;
`;

interface PeopleGridProps {
  title: string;
  columnDefs: ColDef[];
  rowData?: IPersonDataModel[] | null;
}

export const PeopleGrid: FC<PeopleGridProps> = ({ title, columnDefs, rowData }) => {
  const { getWorkHistory, getConnections } = useColumnTextValueGetters();

  const getRowId: GetRowIdFunc = useCallback((params: GetRowIdParams<IPersonDataModel>) => {
    return params.data?.id.toString() ?? '';
  }, []);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      width: 200,
      resizable: true,
      sortable: true,
      filter: false,
      menuTabs: [],
      suppressMovable: true,
    }),
    []
  );

  const processCellCallback = useCallback(
    (params: ProcessCellForExportParams<IPersonDataModel>) => {
      const data = params.node?.data;
      if (!data) return '';
      if (params.column.getId() === ExportableColumns.workHistory) {
        return getWorkHistory(data);
      }
      if (params.column.getId() === ExportableColumns.connections) {
        return getConnections(data);
      }
      return commonProcessCb(params);
    },
    [getConnections, getWorkHistory]
  );

  return (
    <div>
      <Typography variant='body1' component='h6' color='text.primary'>
        {title}
      </Typography>

      <GridContainer>
        <AgTable
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowGroupPanelShow='never'
          groupIncludeTotalFooter={false}
          getRowId={getRowId}
          defaultExcelExportParams={{ processCellCallback, ...exportParams }}
          defaultCsvExportParams={{ processCellCallback, ...exportParams }}
          getRowStyle={getRowStyle}
        />
      </GridContainer>
    </div>
  );
};

export function getRowStyle<T extends { hidden?: boolean }>(params: RowClassParams<T>) {
  if (params.data?.hidden) {
    return { filter: 'grayscale(0.7)', background: colors.neutral[5], color: colors.neutral[20] };
  }
}
