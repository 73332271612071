import { atom, selector } from 'recoil';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { NotificationConfigResponse } from '../../../types';

export const getDefaultNotificationConfigState = async () => {
  const { data } = await MaggieHttp.maggie.get<NotificationConfigResponse>('/notificationConfig');
  return data;
};

export const notificationConfig = selector({
  key: 'NotificationConfig',
  get: getDefaultNotificationConfigState,
});

const notificationConfigDefaultState = selector({
  key: 'notificationConfigDefaultState',
  get: ({ get }) => {
    return get(notificationConfig);
  },
});

export const notificationConfigState = atom<NotificationConfigResponse>({
  key: 'NotificationConfigState',
  default: notificationConfigDefaultState,
});
