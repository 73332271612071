import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class TotalProjectedValue implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_PROJECTED_VALUE as const;
  private title = 'Total Projected Value';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ kpis }: KpiCalculationParams): Kpi {
    const value =
      (kpis?.[KPI_COMPONENTS.POTENTIAL_UPSIDE]?.value ?? 0) +
      (kpis?.[KPI_COMPONENTS.TOTAL_VALUE]?.value ?? 0);

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
