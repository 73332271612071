import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ResponsesActionsCellRenderer from '../components/ResponsesActionsCellRenderer';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useCommonKpiColDefs } from './useCommonKpiColDefs';
import { IKpiRequestGridData } from './useKPIRequestsFilters';

export default function useKPIResponsesColDefs(status: KPIRequestStatus) {
  const {
    respondentColDef,
    periodColDef,
    templateColDef,
    frequencyColDef,
    granularityColDef,
    commonCompanyDefs,
  } = useCommonKpiColDefs();
  const allowSelection = status === KPIRequestStatus.Submitted;
  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef<IKpiRequestGridData>[] = useMemo(() => {
    const res = [
      {
        ...commonCompanyDefs,
        checkboxSelection: allowSelection,
        headerCheckboxSelection: allowSelection,
        headerCheckboxSelectionFilteredOnly: allowSelection,
      },
      templateColDef,
      frequencyColDef,
      periodColDef,
      respondentColDef,
      {
        maxWidth: 60,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: ResponsesActionsCellRenderer,
      },
    ] as ColDef<IKpiRequestGridData>[];

    if (showGranularitySelector) {
      res.splice(3, 0, granularityColDef as unknown as ColDef<IKpiRequestGridData>);
    }

    return res;
  }, [
    allowSelection,
    commonCompanyDefs,
    frequencyColDef,
    granularityColDef,
    periodColDef,
    respondentColDef,
    showGranularitySelector,
    templateColDef,
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      menuTabs: [],
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
