import * as yup from 'yup';
import {
  requiredMsg,
  greaterThenMsg,
} from '../../../../Finance/components/TransactionModal/Forms/utils/validationMessages';

export const DealSchema = yup.object().shape({
  dealTypeId: yup.number().required(requiredMsg('Deal Type')).nullable(),
  roundId: yup
    .number()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
  fundIds: yup.array().optional().nullable(),
  dealLeadId: yup.string().required(requiredMsg('Deal Lead')).nullable(),
  dealTeamIds: yup.array().optional().nullable(),
  labels: yup.array().optional().nullable(),
  raiseAmount: yup
    .number()
    .min(0, greaterThenMsg('Raise Amount', 0))
    .optional()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
  gcAmountMin: yup
    .number()
    .min(0, greaterThenMsg('GC Minimum Amount', 0))
    .optional()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
  gcAmountMax: yup
    .number()
    .min(0, greaterThenMsg('GC Maximum Amount', 0))
    .optional()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
  totalPic: yup
    .number()
    .min(0, greaterThenMsg('Total PIC', 0))
    .optional()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
});
