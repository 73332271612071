import { atom, selector, selectorFamily } from 'recoil';
import { DealBoardConfig, DealBoardDealCategory, LoadingStatus } from '../../../types';
import {
  dealSort,
  fetchDealBoardConfig,
  fetchDealLabels,
  fetchDealTodos,
} from '../../../services/queries/MaggieDealQueries';
import { IDealStageDataModel } from '../data-models/dealStage.data-model';
import { getDealCategory } from '../utils/getDealCategory';
import { AtomUtils } from '../../../util/atom-utils';
import { ILabelDataModel } from '../../../data-models/deal-label.data-model';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { IDealTypeDataModel } from '../../../data-models/deal-type.data-model';
import { ITodoDataModel } from '../../../data-models/todo.data-model';

export interface RepeatQueryStatus {
  status: LoadingStatus;
  lastUpdated: Date | undefined;
}

export const allDealsLoadStatusState = atom<RepeatQueryStatus>({
  key: 'allDealsLoadedState',
  default: {
    status: LoadingStatus.idle,
    lastUpdated: undefined,
  },
});

export const allDealsState = atom<IDealDataModel[]>({
  key: 'allDealsState',
  effects: [
    ({ onSet, setSelf }) => {
      onSet((_, oldValue) => {
        AtomUtils.instance.setUndo(allDealsState.key, () => {
          setSelf(oldValue);
        });
      });
    },
  ],
});

export const allDealsSortedState = selector<IDealDataModel[]>({
  key: 'allDealsSortedState',
  get: ({ get }) => {
    const allDeals = get(allDealsState);
    return [...allDeals].sort(dealSort);
  },
});

export const dealsByCategoryState = selectorFamily<IDealDataModel[], DealBoardDealCategory>({
  key: 'dealsByCategoryState',
  get:
    (category) =>
    ({ get }) => {
      const mapping = get(dealStageIdToCategoryState);
      const allDeals = get(allDealsSortedState);

      return allDeals.filter((deal) => getDealCategory(mapping, deal.stageId) === category);
    },
});

export const dealBoardConfigState = atom<DealBoardConfig>({
  key: 'dealBoardConfigState',
  default: selector({
    key: 'dealBoardConfigState/default',
    get: () => {
      return fetchDealBoardConfig();
    },
  }),
});

export const dealStageIdToCategoryState = selector<Map<DealBoardDealCategory, Set<number>>>({
  key: 'dealCategoriesState',
  get: ({ get }) => {
    const dealStages = get(dealStagesByIdMapState);
    const dealStageNameToDealStage: Record<string, number> = {};
    dealStages.forEach((dealStage) => {
      dealStageNameToDealStage[dealStage.name] = dealStage.id;
    });

    const dealsCatMap = new Map<DealBoardDealCategory, Set<number>>();
    addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.PASS, 'Pass');
    addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.TRACK, 'Track');
    addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.MISSED, 'Missed');
    addCategoryToMap(dealsCatMap, dealStageNameToDealStage, DealBoardDealCategory.CLOSED, 'Closed');

    const remainingStages = Object.values(dealStageNameToDealStage);
    dealsCatMap.set(DealBoardDealCategory.CURRENT, new Set(remainingStages));

    return dealsCatMap;
  },
});

export const dealStagesIdsState = selector<number[]>({
  key: 'dealStagesIdsState',
  get: ({ get }) => {
    const stagesArray = get(dealBoardConfigState).dealStages;

    return stagesArray.map((stage) => {
      return stage.id;
    });
  },
});

export const dealStagesByIdMapState = selector<Map<number, IDealStageDataModel>>({
  key: 'dealStagesMapByIdState',
  get: ({ get }) => {
    const stagesArray = get(dealBoardConfigState).dealStages;

    return stagesArray.reduce((res, stage) => {
      return res.set(stage.id, stage);
    }, new Map<number, IDealStageDataModel>());
  },
});

export const dealStagesByNameMapState = selector<Map<string, IDealStageDataModel>>({
  key: 'dealStagesByNameMapState',
  get: ({ get }) => {
    const stagesArray = get(dealBoardConfigState).dealStages;

    return stagesArray.reduce((res, stage) => {
      return res.set(stage.name, stage);
    }, new Map<string, IDealStageDataModel>());
  },
});

export const dealTypesByIdMapState = selector<Map<number, IDealTypeDataModel>>({
  key: 'dealTypesByIdMapState',
  get: ({ get }) => {
    const dealTypesArray = get(dealBoardConfigState).dealTypes;

    return dealTypesArray.reduce((res, dealType) => {
      return res.set(dealType.id, dealType);
    }, new Map<number, IDealTypeDataModel>());
  },
});

export const recentlyClosedDealsState = selector<IDealDataModel[]>({
  key: 'recentlyClosedDealsState',
  get: ({ get }) => {
    const closedDeals = get(dealsByCategoryState(DealBoardDealCategory.CLOSED));

    return closedDeals.slice(0, 3);
  },
});

export const recentlyPassedDealsState = selector<IDealDataModel[]>({
  key: 'recentlyPassedDealsState',
  get: ({ get }) => {
    const passedDeals = get(dealsByCategoryState(DealBoardDealCategory.PASS));

    return passedDeals.slice(0, 3);
  },
});

export const dealLabelsState = atom<ILabelDataModel[]>({
  key: 'dealLabelsState',
  default: [],
  effects: [
    ({ setSelf }) => {
      setSelf(fetchDealLabels());
    },
  ],
});

export const dealTodosState = atom<ITodoDataModel[]>({
  key: 'dealTodosState',
  default: [],
  effects: [
    ({ setSelf }) => {
      setSelf(fetchDealTodos());
    },
  ],
});

function addCategoryToMap(
  dealsCatMap: Map<DealBoardDealCategory, Set<number>>,
  dealStageNameToDealStage: Record<string, number>,
  category: DealBoardDealCategory,
  stageName: string
) {
  dealsCatMap.set(category, new Set([dealStageNameToDealStage[stageName]]));
  delete dealStageNameToDealStage[stageName];
}
