import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';

const NoDataWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface NoDataProps {
  mainMessage?: string;
  subMessage?: string;
}

export const NoDataAddOne: FC<NoDataProps> = ({ mainMessage, subMessage }) => {
  const { colors } = useTheme();
  return (
    <NoDataWrapper>
      <Typography variant='subtitle2' color={colors.primary[100]}>
        {mainMessage ?? 'No data'}
      </Typography>
      {Boolean(subMessage) && (
        <Typography variant='body2' color={colors.neutral[50]}>
          Start by adding one
        </Typography>
      )}
    </NoDataWrapper>
  );
};
