import { useCallback } from 'react';
import {
  IUpdateTransactionPayload,
  createTransaction,
  updateTransaction,
  deleteTransaction,
  getTransactionPreview,
} from '../../../services/queries/MaggieTransactionQueries';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../services/queryHelpers';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { ITransactionDataModel, isInternalTransaction } from '../../../data-models/transaction.data-model';
import { TransactionPayload } from '../../../types';
import { useInvalidateFinanceData } from './useInvalidateFinanceData';

export function useFinanceActions(next?: () => void) {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { invalidateAll } = useInvalidateFinanceData();

  const handleAssignToExistingInvestment = useCallback(
    async (transactionId: number, investmentRoundId: number, companyId: number) => {
      try {
        await updateTransaction({
          payload: { id: transactionId, investmentRoundId, companyId },
          transactionId,
        });
        await invalidateAll(companyId);
        pushSuccessToast({ message: 'Assignment successful' });
        next?.();
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Assignment failed');
        pushErrorToast({ message });
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleAssignToNewInvestment = useCallback(
    async (transactionId: number, investmentRound: Partial<IFinanceRoundDataModel>, companyId: number) => {
      try {
        await updateTransaction({
          payload: { round: { ...investmentRound, companyId }, id: transactionId, companyId },
          transactionId,
        });
        await invalidateAll(companyId);
        pushSuccessToast({ message: 'Assignment successful' });
        next?.();
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Assignment failed');
        pushErrorToast({ message });
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleUnassignInvestment = useCallback(
    async (transaction: ITransactionDataModel) => {
      const { companyId, id } = transaction;
      try {
        await updateTransaction({
          payload: { id, companyId, investmentRoundId: null },
          transactionId: id,
        });
        await invalidateAll(companyId);
        pushSuccessToast({ message: 'Successfully removed associated investment' });
        next?.();
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to remove associated investment');
        pushErrorToast({ message });
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleUpdateTransaction = useCallback(
    async (transaction: IUpdateTransactionPayload) => {
      try {
        let data: ITransactionDataModel;
        if (
          isInternalTransaction(transaction as ITransactionDataModel) ||
          transaction.linkedTransactionId != null
        ) {
          data = await updateTransaction({
            payload: transaction,
            transactionId: Number.isInteger(transaction.id)
              ? transaction.id
              : transaction.linkedTransactionId!,
          });
        } else {
          data = await createTransaction(transaction as unknown as TransactionPayload);
        }
        await invalidateAll(data.companyId);
        pushSuccessToast({ message: 'Successfully updated transaction' });
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to update transaction');
        pushErrorToast({ message });
      } finally {
        next?.();
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleCreateTransaction = useCallback(
    async (transaction: Partial<ITransactionDataModel>) => {
      try {
        const newTransaction = { ...transaction, status: 'Pending' } as unknown as TransactionPayload;
        const data = await createTransaction(newTransaction);
        await invalidateAll(data.companyId);
        pushSuccessToast({ message: 'Successfully created transaction' });
        next?.();
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to create transaction');
        pushErrorToast({ message });
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleDeleteTransaction = useCallback(
    async (transaction: ITransactionDataModel) => {
      try {
        await deleteTransaction(transaction.id);
        await invalidateAll(transaction.companyId);
        pushSuccessToast({ message: 'Successfully deleted transaction' });
        next?.();
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to delete transaction');
        pushErrorToast({ message });
      }
    },
    [invalidateAll, next, pushErrorToast, pushSuccessToast]
  );

  const handleGetTransactionPreview = useCallback(
    async (transaction: Partial<ITransactionDataModel>) => {
      try {
        const data = await getTransactionPreview(transaction as unknown as TransactionPayload);
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(err, 'Failed to get transaction summary');
        pushErrorToast({ message });
      }
    },
    [pushErrorToast]
  );

  return {
    handleAssignToExistingInvestment,
    handleAssignToNewInvestment,
    handleUnassignInvestment,
    handleUpdateTransaction,
    handleCreateTransaction,
    handleDeleteTransaction,
    handleGetTransactionPreview,
  };
}
