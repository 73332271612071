import { useCallback, useState } from 'react';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RubricConfig, rubricConfigSchema } from '../../../schemas/Rubric.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { useRubricConfigActions } from './useRubricConfigActions';
import { RubricConfigActions } from './RubricConfigFormState';
import { useRubricConfigFormData } from './useRubricConfigFormData';

interface IRubricConfigFormProps extends Pick<UseFormProps<RubricConfig>, 'defaultValues'> {
  action: Extract<RubricConfigActions, 'create' | 'edit'>;
  onClose: () => void;
}
export function RubricConfigForm({ defaultValues, action, onClose }: IRubricConfigFormProps) {
  const { createNewRubricConfig, updateRubricConfig } = useRubricConfigActions();
  const { selectedConfig } = useRubricConfigFormData();
  const [loading, setLoading] = useState(false);
  const methods = useForm<Pick<RubricConfig, 'displayName' | 'description'>>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(rubricConfigSchema().pick(['displayName', 'description'])),
  });

  const handleSubmit = useCallback(async () => {
    const { displayName, description } = methods.getValues();
    const payload = { ...defaultValues, displayName, description };
    setLoading(true);
    if (action === 'edit') {
      const id = selectedConfig?.id;
      if (!id) throw new Error('Invalid data for editing rubric config');
      await updateRubricConfig(id, payload as RubricConfig);
    } else {
      await createNewRubricConfig(payload as RubricConfig);
    }
    setLoading(false);
    onClose();
  }, [
    action,
    createNewRubricConfig,
    defaultValues,
    methods,
    onClose,
    selectedConfig?.id,
    updateRubricConfig,
  ]);

  return (
    <FormProvider {...methods}>
      <RubricConfigFields />
      <StickyFormButtons
        onCancel={onClose}
        onSubmit={methods.handleSubmit(handleSubmit)}
        loading={loading}
        style={{ padding: '0.5rem 0 2rem' }}
        submitLabel={action === 'edit' ? 'Save Changes' : 'Create Rubric'}
      />
    </FormProvider>
  );
}

function RubricConfigFields() {
  const fields = schemaToFormFields(rubricConfigSchema().pick(['displayName', 'description']));
  const form = createFormFromFieldsArray(fields);
  return <FormFactoryWithStandardLayout form={form} />;
}
