import { Fund, createFund } from '../schemas/Fund.schema';

/** @deprecated: just an alias now - use Fund from Fund.schema instead */
export type IFundDataModel = Fund;

export function createFundDataModel(overrides: Partial<IFundDataModel> = {}): IFundDataModel {
  return {
    ...createFund(overrides),
  };
}

// Used to map to deals with null fund.
export const UnassignedFund = createFundDataModel({
  id: -Number.MAX_SAFE_INTEGER,
  name: 'Unassigned',
});
