import { IUserDataModel } from '../../../data-models/user.data-model';
import { DealComment } from '../data-models/comment.data-model';

export function getLatestCommentString(
  comments: DealComment[] | undefined,
  usersByEmail: Map<string, IUserDataModel>
) {
  if (!comments?.length) return '';
  const commentsCopy = comments.map((c) => ({ ...c }));
  const sortedComments = commentsCopy.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const author = usersByEmail.get(sortedComments[0].createdBy);
  return author ? `${author.name}: ${sortedComments[0].comment}` : sortedComments[0].comment;
}
