import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Icon, IconTypes } from '../../../../../components/Icon';
import { ICompanySearchResultDataModel } from '../../../../../data-models/company-search-result.data-model';
import { useDeal } from '../../../providers/DealProvider';
import { Circle, StepNumber } from './Circle';
import { CompanySectionContent } from './CompanySectionContent';

const Wrapper = styled('div')<{ isActive: boolean }>`
  margin-bottom: 28px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary[5] : theme.colors.neutral[2]};
  padding: 24px;
`;

const Heading = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
`;

const CaptionWrapper = styled('div')`
  margin: 13px 0;
`;

export const CompanySection = ({
  scrollUp,
  companyData,
}: {
  scrollUp: () => void;
  companyData?: ICompanySearchResultDataModel;
}) => {
  const { colors } = useTheme();
  const { activeStep } = useDeal();

  return (
    <Wrapper isActive={activeStep === 1}>
      <Heading>
        <Circle isOpen={activeStep === 1} isChecked={activeStep !== 1}>
          {activeStep === 1 && <StepNumber isOpen={true}>{1}</StepNumber>}
          {activeStep !== 1 && <Icon type={IconTypes.CHECK} color={colors.success[60]} />}
        </Circle>
        <Typography variant={'subtitle1'} color={colors.primary[90]}>
          Company
        </Typography>
      </Heading>
      <CaptionWrapper>
        <Typography variant='caption' color={colors.neutral[50]}>
          Select a company to add a deal
        </Typography>
      </CaptionWrapper>
      <CompanySectionContent scrollUp={scrollUp} companyData={companyData} />
    </Wrapper>
  );
};
