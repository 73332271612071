import * as yup from 'yup';
import { addQuarters, differenceInQuarters, endOfQuarter } from 'date-fns';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { FieldPath } from 'react-hook-form';
import {
  capitalAllocationFields,
  CapitalAllocationResponse,
  projectedFeesAndExpensesTransactionFields,
} from '../../../schemas/FundCapitalAllocation.schema';
import { Fund } from '../../../schemas/Fund.schema';
import { getISODate } from '../../../services/queries/MaggieMetricsQueries';
import { FMT } from '../../../util/formatter-service';
import { KPIRequestFrequency } from '../../../data-models/kpi-requests.data-model';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { withOverrides } from '../../../util/ag-grid-utils';
import { ProjectedFeesAndExpensesEditor } from './FeesAndExpensesForm';

function feesAndExpensesFormFields() {
  const { feesAndExpenses, period } = capitalAllocationFields();
  const { amount, fundId, id, updatedAt, updatedBy } = projectedFeesAndExpensesTransactionFields();
  return {
    amount: amount.label('Projected').default(null),
    date: yup.string(),
    feesAndExpenses: feesAndExpenses.label('Paid').default(null),
    fundId, // hidden
    id: id.default(null), // hidden
    period: period.label('Quarter'),
    updatedAt: updatedAt.label('Last Updated'),
    updatedBy: updatedBy.customMeta({
      formatter: 'userByEmail',
    }),
  };
}
function feesAndExpensesFormSchema() {
  return yup.object().shape(feesAndExpensesFormFields());
}
export type FeesAndExpensesFormData = yup.InferType<ReturnType<typeof feesAndExpensesFormSchema>>;

export function getFeesAndExpensesFormData(
  fund: Fund,
  data?: CapitalAllocationResponse
): FeesAndExpensesFormData[] {
  if (!data) return [];
  const start = data.quarterlyMetrics.at(0)?.dateTo ?? fund.inceptionDate;
  const end = fund.managementFeeTerminationDate ?? data.quarterlyMetrics.at(-1)?.dateTo;
  if (!start || !end) {
    console.warn('Missing start and/or close date');
    return [];
  }
  const fundId = fund.id;
  const result = transformAllocationsToFormData(data, fundId);
  const closeDate = endOfQuarter(new Date(end));
  const lastDate = endOfQuarter(new Date(data.quarterlyMetrics.at(-1)?.dateTo ?? start));
  if (lastDate.getTime() < closeDate.getTime()) {
    result.push(...generateEmptyFeesAndExpensesRows(lastDate, closeDate, fundId));
  }
  return result;
}

function transformAllocationsToFormData(data: CapitalAllocationResponse, fundId: number) {
  const result: FeesAndExpensesFormData[] = [];

  data.quarterlyMetrics.forEach((item) => {
    const { dateTo, feesAndExpenses, period, projectedFeesAndExpensesTransaction } = item;
    const {
      amount = null,
      id = null,
      updatedAt = null,
      updatedBy = null,
    } = projectedFeesAndExpensesTransaction ?? {};
    result.push({
      feesAndExpenses,
      date: dateTo!,
      fundId,
      id,
      period,
      amount,
      updatedAt,
      updatedBy,
    });
  });

  return result;
}

function generateEmptyFeesAndExpensesRows(start: Date, end: Date, fundId: number) {
  const result: FeesAndExpensesFormData[] = [];
  const diffInQuarters = differenceInQuarters(end, start);
  for (let i = 0; i < diffInQuarters; i++) {
    const dateTo = getISODate(endOfQuarter(addQuarters(start, i + 1)));
    result.push({
      amount: null,
      date: dateTo,
      feesAndExpenses: null,
      fundId,
      id: null,
      period: FMT.formatKPIPeriod(dateTo, {
        frequency: KPIRequestFrequency.Quarterly,
        showYear: true,
        yearFormat: 'yyyy',
      }),
      updatedAt: null,
      updatedBy: null,
    });
  }
  return result;
}

export function useFeesAndExpensesFormColDefs(): ColDef<FeesAndExpensesFormData>[] {
  const schemaToGrid = useSchemaToGrid();
  return useMemo(() => {
    const overrides: Partial<
      Record<FieldPath<FeesAndExpensesFormData>, Partial<ColDef<FeesAndExpensesFormData>>>
    > = {
      date: {
        field: 'date',
        headerName: 'Quarter',
        valueFormatter: (params: ValueFormatterParams<FeesAndExpensesFormData>) => {
          if (!params.data) return '';
          return params.data.period;
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams<FeesAndExpensesFormData>) => {
            if (!params.data) return '';
            return params.data.period;
          },
        },
        filter: true,
      },
      amount: {
        editable: true,
        cellEditor: ProjectedFeesAndExpensesEditor,
      },
    };

    return withOverrides(
      schemaToGrid(feesAndExpensesFormSchema(), [
        'date',
        'feesAndExpenses',
        'amount',
        'updatedBy',
        'updatedAt',
      ]),
      overrides
    ) as ColDef<FeesAndExpensesFormData>[];
  }, [schemaToGrid]);
}
