import { Typography, styled } from '@mui/material';
import { FC } from 'react';

const StyledCard = styled('li')`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 0.75rem;
  row-gap: 0.5rem;
  border-radius: 4px;
  border: 2px solid var(--White, #fff);
  background: radial-gradient(
    227.68% 141.42% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  /* card shadow */
  box-shadow: 0px 24px 48px 0px rgba(75, 82, 93, 0.02), 0px 4px 24px 0px rgba(16, 37, 62, 0.04),
    0px -4px 40px 0px rgba(255, 255, 255, 0.06);
`;

interface IDataCardProps {
  title: string;
  formattedValue: string;
}

export const DataCard: FC<IDataCardProps> = ({ title, formattedValue: value }) => {
  return (
    <StyledCard>
      <Typography variant='caption' color='text.secondary' noWrap component='label'>
        {title}
      </Typography>
      <Typography color='text.primary' noWrap>
        {value}
      </Typography>
    </StyledCard>
  );
};
