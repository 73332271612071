import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { peopleByCompanyState } from '../state/PeopleByCompanyState';
import {
  filteredBoardMembersByCompanyState,
  filteredEmployeesByCompanyState,
} from '../state/PeopleByCompanyUIState';
import { selectedCompanyIdProfile } from '../state/UIState';

export const usePeopleData = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  // all other data is derived from this:
  const allPeopleByCompanyLoadable = useRecoilValueLoadable(peopleByCompanyState(companyId));
  const filteredPeopleByCompanyLoadable = useRecoilValueLoadable(filteredEmployeesByCompanyState(companyId));
  const filteredBoardMembersByCompanyLoadable = useRecoilValueLoadable(
    filteredBoardMembersByCompanyState(companyId)
  );

  const hiddenCount = allPeopleByCompanyLoadable.valueMaybe()?.filter((p) => p.hidden).length ?? 0;

  const loading =
    allPeopleByCompanyLoadable.state === 'loading' ||
    filteredPeopleByCompanyLoadable.state === 'loading' ||
    filteredBoardMembersByCompanyLoadable.state === 'loading';
  const error =
    allPeopleByCompanyLoadable.state === 'hasError' ||
    filteredPeopleByCompanyLoadable.state === 'hasError' ||
    filteredBoardMembersByCompanyLoadable.state === 'hasError';

  return {
    allPeople: allPeopleByCompanyLoadable.valueMaybe(),
    filteredEmployees: filteredPeopleByCompanyLoadable.valueMaybe(),
    filteredBoardMembers: filteredBoardMembersByCompanyLoadable.valueMaybe(),
    hiddenCount,
    loading,
    error,
  };
};
