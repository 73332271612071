import { SerializedEditorState } from 'lexical';
import { useRecoilValue } from 'recoil';
import { isAfter } from 'date-fns';
import { INoteDataModel } from '../../../../data-models/note.data-model';
import { selectedCompanyIdProfile } from '../../../CompanyProfiles/state/UIState';
import { notesState } from '../../../../services/state/NoteState';
import { Panel } from '../Panel';
import { NoDataMessage2 } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { NoteSummaryCard } from './NoteSummaryCard';

export function RecentActivityPanel() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const allNotes = useRecoilValue(notesState(companyId));
  const lastNote = getLastNote(allNotes ?? []);

  return (
    <Panel title={'Recent Activity'} style={{ gap: '0.5rem' }}>
      {lastNote ? (
        <NoteSummaryCard note={lastNote as INoteDataModel<SerializedEditorState>} />
      ) : (
        <NoDataMessage2 />
      )}
    </Panel>
  );
}

function getLastNote(notes: INoteDataModel<unknown>[]) {
  return notes.reduce((latest, note) => {
    if (!latest) {
      return note;
    }

    return isAfter(new Date(note.updatedAt), new Date(latest.updatedAt)) ? note : latest;
  }, null as INoteDataModel<unknown> | null);
}
