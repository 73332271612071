import { FC } from 'react';
import { Chip, Stack, styled } from '@mui/material';
import {
  startOfYear,
  sub,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  startOfMonth,
} from 'date-fns';
import { colors } from '../../theme/colors';
import { IRangeDatePickerProps } from './RangeDatePicker';

const ShortcutStack = styled(Stack)({
  paddingBlock: '1.5rem',
  paddingInline: '1rem',
  width: '9rem',
  borderRight: `solid thin ${colors.neutral[10]}`,
  gap: '0.5rem',
});

export interface IRangeShortcut {
  label: string;
  start: Date;
  end: Date;
}

export type RangeShortcutsProps = Pick<
  IRangeDatePickerProps,
  'setStartingDate' | 'setEndingDate' | 'handleApply' | 'shortcuts'
>;

export const DefaultShortcuts: IRangeShortcut[] = [
  {
    label: 'All Dates',
    start: sub(new Date(), { years: 19 }),
    end: endOfDay(new Date()),
  },
  {
    label: 'Last 2 years',
    start: sub(new Date(), { years: 2 }),
    end: endOfDay(new Date()),
  },
  {
    label: 'Last year',
    start: startOfYear(sub(new Date(), { years: 1 })),
    end: endOfYear(sub(new Date(), { years: 1 })),
  },
  {
    label: 'Last quarter',
    start: startOfQuarter(sub(new Date(), { months: 3 })),
    end: endOfQuarter(sub(new Date(), { months: 3 })),
  },
  {
    label: 'Last 7 days',
    start: startOfDay(sub(new Date(), { days: 7 })),
    end: endOfDay(new Date()),
  },
  {
    label: 'Last week',
    start: startOfWeek(sub(new Date(), { weeks: 1 })),
    end: endOfWeek(sub(new Date(), { weeks: 1 })),
  },
  {
    label: 'Current month',
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
  },
];

export const RangeShortcuts: FC<RangeShortcutsProps> = ({
  setStartingDate,
  setEndingDate,
  shortcuts = [],
}: RangeShortcutsProps) => {
  return (
    <ShortcutStack>
      {shortcuts.map((shortcut) => (
        <Chip
          key={shortcut.label}
          label={shortcut.label}
          onClick={() => {
            setStartingDate(shortcut.start);
            setEndingDate(shortcut.end);
          }}
        />
      ))}
    </ShortcutStack>
  );
};
