import { useMemo, useState } from 'react';
import { ColDef } from 'ag-grid-community';
import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
} from '../../../../data-models/captable2.data-model';
import { SubSection } from '../../Summary/components/SubSection';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { InvestmentTypeToName, ShareClass } from '../../../../view-models/captable.view-model';
import { ChipSelector } from '../../../../components/ChipSelector';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { CapTableInvestmentType } from '../../../../schemas/CaptableInvestment.schema';
import { useInvestmentRowData } from './CTInvestmentRowData';
import { DefaultColDefs, useInvestmentColDefs } from './CTInvestmentDetailsColDefs';

interface ICTInvestmentDetailsProps {
  data: ICaptableDataModel;
}
export function CTInvestmentDetails({ data }: ICTInvestmentDetailsProps) {
  const [selectedType, setSelectedType] = useState<CapTableInvestmentType>('share-class');
  const options = useMemo(() => {
    return Object.entries(InvestmentTypeToName).map(([key, value]) => ({
      displayName: value,
      value: key as CapTableInvestmentType,
    }));
  }, []);

  const rowData = useInvestmentRowData(data, {
    investmentTypeFilter: selectedType,
  });
  const colDefs = useInvestmentColDefs(selectedType) as ColDef<ICaptableInvestmentDataModel | ShareClass>[];

  return (
    <SubSection
      title='Investment Details'
      collapseProps={{ defaultExpanded: true, collapsible: true }}
      noCard
    >
      <ChipSelector
        value={selectedType}
        options={options}
        onChange={(newValue) => setSelectedType(newValue)}
      />
      <FadeInGridWrapper key={selectedType}>
        <div style={{ height: '20rem', padding: '0' }}>
          <AgTable
            rowData={rowData}
            columnDefs={colDefs}
            rowGroupPanelShow='never'
            groupIncludeTotalFooter={false}
            defaultColDef={DefaultColDefs}
          />
        </div>
      </FadeInGridWrapper>
    </SubSection>
  );
}
