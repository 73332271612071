import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ComposeOption, ECharts, init, use } from 'echarts/core';
import { BarChart, LineChart, LineSeriesOption } from 'echarts/charts';
import {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { createPortal } from 'react-dom';
import { ChartWrapper } from '../../../../../components/Charts/ChartWrapper/ChartWrapper';
import { ChartTitleWrapper } from '../../../../../components/Charts/ChartTitleWrapper';
import { Icon, IconTypes } from '../../../../../components/Icon';
import {
  Chart,
  ChartContainer,
  ChartSizes,
  findNearestValue,
  getTooltip,
} from '../../../../../components/Charts/chart-utils';
import { doNothingFunc } from '../../../../../util/doNothingFunc';
import { formatDateNumeric } from '../../../../../util/formatters/DateFormatters';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import { DeprecatedGetterFormatType, GetterFormatType } from '../../../../../util/getFormatter';
import { getFormattedValue } from '../../../../../util/getFormattedValue';
import { selectedCompaniesCI } from '../../../state/CompaniesState';
import { FieldFormatting } from '../../../../../data-models/field.data-model';
import { LoadingStatus } from '../../../../../types';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';

const ModalWrapper = styled('div')<{ isFullScreen: boolean }>`
  display: ${({ isFullScreen }) => (isFullScreen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100001;
`;

const Modal = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  width: calc(100vw - 250px);
  height: 70vh;
  z-index: 10000;
  background-color: #fff;
  padding: 3rem;
  border-radius: 4px;
  animation: moveIn 0.6s ease-out;

  @keyframes moveIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const ModalHeader = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;

  & > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const NoDataWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
`;

export interface ISeries {
  name: string;
  type: string;
  data: [Date | number, number][];
  itemStyle: { color: string };
}

interface HistoryComparisonChartProps {
  YAxisTicksFormatting?: (tick: string | number) => string;
  series: ISeries[];
  title: string;
  caption?: string;
  width?: number;
  id: string;
  defaultSize?: string;
  tickRotation?: number;
  formatting?: FieldFormatting;
  type: GetterFormatType | DeprecatedGetterFormatType;
  status?: LoadingStatus;
}

type ECLineOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
>;

use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export const HistoryComparisonChart: FC<HistoryComparisonChartProps> = ({
  id,
  title,
  caption,
  series,
  defaultSize = '1/4 Screen',
  width = 700,
  formatting,
  type,
  status,
}) => {
  const [widthState, setWidthState] = useState<string | number>(width);
  const [size, setSize] = useState(defaultSize);
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const fullScreenChartContainer = useRef<HTMLDivElement | null>(null);
  const historyComparisonChart = useRef<ECharts | null>(null);
  const fullScreenChart = useRef<ECharts | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { primary, secondary, neutral } = useTheme().colors;
  const closeRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const activeCompanies = useRecoilValue(selectedCompaniesCI);

  const percentageOfDataLoaded = useMemo(
    () => (series.length / activeCompanies.length) * 100,
    [activeCompanies.length, series.length]
  );

  const isDataPresent = useMemo(() => series.some(({ data }) => data.length), [series]);
  const isLoadingComplete = useMemo(
    () => percentageOfDataLoaded === 100 || status === LoadingStatus.success,
    [percentageOfDataLoaded, status]
  );

  const handleOutsideClick = () => {
    setIsFullScreen(false);
  };

  useOutsideClick(closeRef, handleOutsideClick);

  const handleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  const YAxisTicksFormatting = useCallback(
    (val: number | string) => {
      return getFormattedValue({ type, formatting, value: val }) || '';
    },
    [formatting, type]
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useEffect(() => {
    const container = chartContainer.current;
    const fullScreenContainer = fullScreenChartContainer.current;

    if (!container || !fullScreenContainer) {
      return;
    }

    if (!historyComparisonChart.current) {
      historyComparisonChart.current = init(container);
    }

    if (!fullScreenChart.current) {
      fullScreenChart.current = init(fullScreenContainer);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          historyComparisonChart.current!.resize();
        }
      }
    });

    const resizeObserverSecond = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          fullScreenChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);
    resizeObserverSecond.observe(fullScreenContainer);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
      if (fullScreenContainer) {
        resizeObserverSecond.unobserve(fullScreenContainer);
      }
    };
  }, [chartContainer, fullScreenChartContainer]);

  useEffect(() => {
    const option: ECLineOption = {
      grid: {
        containLabel: true,
        top: 5,
        bottom: 0,
        left: 0,
        right: 10,
      },
      legend: {
        type: 'plain',
        bottom: 0,
        padding: 5,
        textStyle: {
          fontFamily: 'Wotfard-Regular',
          fontSize: 12,
        },
        show: false,
      },
      series: series as LineSeriesOption,
      tooltip: {
        trigger: 'axis',
        confine: true,
        backgroundColor: 'rgba(250,252,254,0.7)',
        order: 'seriesAsc',
        valueFormatter: (params) => {
          return YAxisTicksFormatting(params as string);
        },
        textStyle: {
          fontFamily: 'Wotfard-Regular',
          fontSize: 14,
        },
        formatter: (params) => {
          const [firstParam] = params as unknown as { axisValue: number }[];
          const xDatePos = new Date(firstParam.axisValue);
          const xTime = xDatePos.getTime();
          let tooltip = `<p>${formatDateNumeric(xDatePos.toISOString())}</p> `;

          series.forEach((aSeries) => {
            const nearestValue = findNearestValue(aSeries, xTime);

            if (nearestValue !== null) {
              tooltip += getTooltip(aSeries.name, nearestValue, aSeries.itemStyle.color);
            }
          });
          return tooltip;
        },
      },
      xAxis: {
        type: 'time',

        axisLabel: {
          hideOverlap: true,
        },
      },
      yAxis: {
        axisLabel: {
          fontFamily: 'Wotfard-Regular',
          fontSize: 11,
          formatter: YAxisTicksFormatting,
        },
        type: 'value',
      },
    };

    const fullScreenOptions = {
      ...option,
      legend: { ...option.legend, show: true },
      grid: { containLabel: true, top: 5, bottom: 50, left: 0, right: 10 },
    };

    if (fullScreenChart.current) {
      fullScreenChart.current.setOption(fullScreenOptions, true);
    }

    if (historyComparisonChart.current) {
      historyComparisonChart.current.setOption(option, true);
    }
  }, [YAxisTicksFormatting, series]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      width={widthState}
      dataTestid={'history-comparison-chart'}
      padding={'0px'}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={title}
        showMore={false}
        setShowMore={doNothingFunc}
        more={false}
        id={id}
        caption={caption}
        icon={IconTypes.FINANCE}
        handleSizeChange={setSize}
        size={size}
        refProp={chartContainer}
        showIcon={false}
        showFullScreen={isLoadingComplete}
        isFullScreen={isFullScreen}
        handleFullScreen={handleFullScreen}
      />
      {createPortal(
        <ModalWrapper isFullScreen={isFullScreen}>
          <Modal ref={closeRef}>
            <ModalHeader>
              <Typography variant={'subtitle1'} color={primary[100]}>
                {title}
              </Typography>
              <span onClick={() => handleFullScreen()}>
                <Icon type={IconTypes.CLOSE_MENU_2} color={secondary[60]} />
              </span>
            </ModalHeader>
            <Chart width={'100%'} height={'60vh'} ref={fullScreenChartContainer} />
          </Modal>
        </ModalWrapper>,
        document.getElementById('portal-root')!
      )}
      {!isLoadingComplete && !isDataPresent && (
        <NoDataWrapper>
          <Typography variant={'body2'} color={neutral['60']}>
            Loading data
          </Typography>
        </NoDataWrapper>
      )}
      {isLoadingComplete && !isDataPresent && (
        <NoDataWrapper>
          <Typography variant={'body2'} color={neutral['60']}>
            No data
          </Typography>
        </NoDataWrapper>
      )}
      <ChartContainer isHidden={!isDataPresent}>
        <Chart height={'180px'} width={'100%%'} ref={chartContainer} />
      </ChartContainer>
      {!isLoadingComplete && <LinearProgressWithLabel value={percentageOfDataLoaded} variant='determinate' />}
    </ChartWrapper>
  );
};
