import { IGenericSortable } from '../../../data-models/app-config.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IListItemDataModel } from '../../../data-models/list-item.data-model';
import { IUserDataModel } from '../../../data-models/user.data-model';

export function getFilteredList({
  items,
  searchQuery,
  filteredSectors,
  sectors,
  filteredOwners,
  users,
  companiesMap,
  showPreviousDeals = true,
}: {
  items: IListItemDataModel[];
  searchQuery: string;
  filteredSectors: number[] | null;
  sectors: IGenericSortable[];
  filteredOwners: number[] | null;
  users: IUserDataModel[];
  companiesMap: Map<number, ICompanyDataModel>;
  showPreviousDeals?: boolean;
}) {
  let filtered = items;

  if (!showPreviousDeals) {
    filtered = filtered.filter((item) => !item.previousDeal);
  }

  if (searchQuery)
    filtered = items?.filter((item) => {
      const searchTerm = searchQuery.toLocaleLowerCase();
      const company = companiesMap.get(item.companyId);
      return (
        company &&
        (company.name.toLocaleLowerCase().includes(searchTerm) ||
          company.website?.toLocaleLowerCase().includes(searchTerm))
      );
    });

  if (filteredSectors && filteredSectors.length < sectors.length) {
    filtered = filtered?.filter((item) => {
      const company = companiesMap.get(item.companyId);
      return company && company.sectorId != null && filteredSectors.includes(company.sectorId);
    });
  }

  if (filteredOwners && filteredOwners.length < users.length) {
    filtered = filtered?.filter((item) => {
      const company = companiesMap.get(item.companyId);
      return company && company.assignee != null && filteredOwners.includes(company.assignee);
    });
  }

  return filtered;
}
