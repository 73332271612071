import { FC } from 'react';
import { usePeopleData } from '../hooks/usePeopleData';
import { PeopleGrid } from './PeopleGrid';
import { usePeopleColDefs } from './usePeopleColDefs';

export const EmployeeGrids: FC = () => {
  const { filteredEmployees, filteredBoardMembers } = usePeopleData();
  const colDefs = usePeopleColDefs();

  if (!colDefs) return null;

  return (
    <>
      <PeopleGrid title={'All Employees'} columnDefs={colDefs} rowData={filteredEmployees} />

      <PeopleGrid
        title={'Board Members & Advisor Profiles'}
        columnDefs={colDefs}
        rowData={filteredBoardMembers}
      />
    </>
  );
};
