import { ResponseStatus, ISocketErrorData, ISocketResponseBaseDataModel } from './socket-base.data-model';

export interface ISocketRequestCompIntelDataModel<T> {
  action: string;
  data: T;
  requestId: string;
}

export interface ISocketResponseCompIntelBase extends ISocketResponseBaseDataModel {
  requestId: string;
}

export interface ISocketResponseCompIntelData<T> extends ISocketResponseCompIntelBase {
  data: T[];
}

export interface ISocketResponseCompIntelStatus extends ISocketResponseCompIntelBase {
  error?: ISocketErrorData;
  status: ResponseStatus;
}

export function createSocketRequestCompIntelDataModel<T>(
  action: string,
  data: T,
  requestId: string = self.crypto.randomUUID()
): ISocketRequestCompIntelDataModel<T> {
  return {
    action,
    data,
    requestId,
  };
}

export function createSocketResponseCompIntelData<T>(
  responsePayload: T[],
  requestId: string = self.crypto.randomUUID()
): ISocketResponseCompIntelData<T> {
  return {
    data: responsePayload,
    requestId,
    type: 'ciDataUpdate',
  };
}

export function createSocketResponseCompIntelStatus(
  overrides: Partial<ISocketResponseCompIntelStatus>
): ISocketResponseCompIntelStatus {
  return {
    error: undefined,
    requestId: 'foo',
    status: ResponseStatus.complete,
    type: 'ciStatusUpdate',
    ...overrides,
  };
}
