import { Breakpoints } from './structure';

export const breakpoints: Breakpoints = {
  laptop: {
    min: '0',
    max: '1919px',
  },
  desktop: {
    min: '1920px',
    max: '2559px',
  },
  desktopLarge: {
    min: '2560px',
    max: '4000px',
  },
};
