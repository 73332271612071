import { IPersonDataModel, IWorkExperienceDataModel } from '../../../data-models/person.data-model';

export function getLocation(person: IPersonDataModel) {
  const { locations } = person;
  if (!locations || locations.length === 0) return '';

  const { city, state, country } = locations[0];
  const result = [];
  if (city) result.push(city);

  if (country === 'United States') {
    if (state) result.push(state);
    else result.push(country);
  } else if (country) result.push(country);

  return result.join(', ');
}

export function getTitlesForCompany(person: IPersonDataModel, companyId: number) {
  const experience = getAllExperience(person);

  const allTitles = experience.reduce((acc, curr) => {
    if (curr.isCurrent && curr.companyId === companyId && curr.title) {
      acc.add(curr.title);
    }
    return acc;
  }, new Set<string>());

  return Array.from(allTitles).join(', ');
}

export function getEmployeesForCompany(people: IPersonDataModel[], companyId: number) {
  return people.filter((p) => p.workExperience?.some((w) => w.companyId === companyId));
}

export function getBoardMembersForCompany(people: IPersonDataModel[], companyId: number) {
  return people.filter((p) => p.boardAssociations?.some((w) => w.companyId === companyId));
}

// a single person can have experiences as employee and board member
export function getAllExperience(person: IPersonDataModel) {
  return [...(person.workExperience ?? []), ...(person.boardAssociations ?? [])].sort(sortWorkHistory);
}

const sortWorkHistory = (a: IWorkExperienceDataModel, b: IWorkExperienceDataModel) => {
  if (a.isCurrent && !b.isCurrent) {
    return -1;
  }
  if (!a.isCurrent && b.isCurrent) {
    return 1;
  }
  if (a.isCurrent && b.isCurrent) {
    return 0;
  }
  if (a.startDate && b.startDate) {
    return a.startDate > b.startDate ? -1 : 1;
  }
  if (a.startDate && !b.startDate) {
    return -1;
  }
  if (!a.startDate && b.startDate) {
    return 1;
  }
  return 0;
};
