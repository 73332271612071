export const ADMIN_PANEL = 'admin-panel';
export const ADMIN_PANEL_COLORED = 'admin-panel-colored';
export const BELL_NOTIFICATION = 'bell-notification';
export const ALARM_DEFAULT = 'alarm-default';
export const ALARM_PRESSED_WITH_DOT = 'alarm-pressed-with-dot';
export const ALARM_PRESSED = 'alarm-pressed';
export const ALARM_ACTIVE = 'alarm-active';
export const ARROW_DOWN = 'arrow-down';
export const ARROW_UP = 'arrow-up';
export const ANALYSIS_COLORED = 'analysis-colored';
export const CALENDAR = 'calendar';
export const CALENDAR_BIG = 'calendar-big';
export const CALENDAR_FOR_DROPDOWNS = 'calendar-for-dropdowns';
export const CANCEL_CHIPS = 'cancel-chips';
export const CHECK = 'check';
export const CHEVRON_DOWN = 'chevron-down';
export const CHEVRON_LEFT = 'chevron-left';
export const CHEVRON_NEXT = 'chevron-next';
export const CHEVRON_RIGHT = 'chevron-right';
export const CLOSE_MENU_1 = 'close-menu-1';
export const CLOSE_MENU_2 = 'close-menu-2';
export const COMMENT = 'comments-2';
export const COMPANIES = 'companies';
export const COMPANIES_COLORED = 'companies-colored';
export const COMPARE = 'compare';
export const CSV = 'csv';
export const DEAL_BOARD = 'deal-board';
export const DELETE_1 = 'delete-1';
export const DELETE_2 = 'delete-2';
export const DOCUMENT = 'document';
export const DONER = 'doner';
export const DOWNLOAD = 'download';
export const DRAG = 'drag';
export const DUPLICATE = 'duplicate';
export const DEALBOARD_COLORED = 'deal-board-colored';
export const EDIT = 'edit';
export const EXPORT = 'export const';
export const FILTER = 'filter';
export const FINANCE = 'finance';
export const FINANCE_COLORED = 'finance-colored';
export const FOLLOW = 'follow';
export const FULLSCREEN = 'fullscreen';
export const HALF_SCREEN = 'halfscreen';
export const FOURTH_SCREEN = 'fourthscreen';
export const GRID = 'grid';
export const HISTORY = 'history';
export const INFO = 'info';
export const KANBAN = 'kanban';
export const LIGHTNING = 'lightning';
export const LIST = 'list';
export const LIST_VIEW = 'list-view';
export const LOCK = 'lock';
export const LOGOUT = 'logout';
export const NOTIFICATIONS_COLORED = 'notifications-colored';
export const MENU_TAB = 'menu-tab';
export const MENU = 'menu';
export const MAPPINGS = 'mappings';
export const MAPPINGS_COLORED = 'mappings-colored';
export const KPI = 'kpis';
export const KPI_COLORED = 'kpis-colored';
export const NOTE = 'note';
export const MINUS = 'minus';
export const MORE_VERTICAL = 'more-vertical';
export const PDF = 'pdf';
export const PEOPLE = 'people';
export const PEOPLE_COLORED = 'people-colored';
export const PLUS = 'plus';
export const PLUS_SMALL = 'plus-small';
export const PLUS_BIG = 'plus-big';
export const PNG = 'png';
export const PORTFOLIO = 'portfolio';
export const PORTFOLIO_COLORED = 'portfolio-colored';
export const PRESENT_MODE = 'present-mode';
export const PRESENT_MODE_OFF = 'present-mode-off';
export const PREFERENCES = 'preferences';
export const REPLACE_FOR_BUTTON = 'replace-for-button';
export const REPLACE = 'replace';
export const REPORT = 'report';
export const REPORT_COLORED = 'report-colored';
export const RESET = 'reset';
export const RESIZE = 'resize';
export const SEARCH = 'search';
export const SETTINGS = 'settings';
export const SETTINGS_COLORED = 'settings-colored';
export const SETTINGS_1 = 'settings-1';
export const SETTINGS_2 = 'settings-2';
export const SHARE_1 = 'share-1';
export const SHARE_2 = 'share-2';
export const SORT = 'sort';
export const STAR = 'star';
export const STAR_FILLED = 'star-filled';
export const STATISTICS_1 = 'statistics-1';
export const STATISTICS_2 = 'statistics-2';
export const STATISTICS_3 = 'statistics-3';
export const STATISTICS_4 = 'statistics-4';
export const SUCCESS = 'success';
export const TEMPERATURE_OFF = 'temperature-off';
export const TEMPERATURE_ON = 'temperature-on';
export const TRACK = 'track';
export const TRANSACTION = 'transaction';
export const TRIANGLE_DOWN = 'triangle-down';
export const TRIANGLE_DOWN_RED = 'triangle-down-red';
export const TRIANGLE_UP_GREEN = 'triangle-down-green';
export const TRIANGLE_UP = 'triangle-up';
export const USER_HEART = 'user-heart';
export const USER_SHARED_BY_ME = 'user-shared-by-me';
export const USER_SHARED_WITH_ME = 'user-shared-with-me';
export const UPLOAD = 'upload';
export const WARNING = 'warning';
export const XLSX = 'xlsx';
export const CLOCK_ROTATE_LEFT = 'clock-rotate-left';
export const COLLAPSE = 'collapse';
export const EXPAND = 'expand';
export const ZZ = 'zz';
export const UNDO = 'undo';
export const LN = 'linkedin';
export const FB = 'facebook';
export const IG = 'instagram';
export const TW = 'twitter';
export const CB = 'CB-icon';
export const PITCHBOOK = 'pitchbook';
export const CB_INSIGHTS = 'cb-insights';
