import { useEffect } from 'react';
import { useToken } from '../services/hooks/useToken';
import { isDevelopment, isLocal } from '../util/environment';

export function useCopyTokenToConsole(): void {
  const getToken = useToken();

  useEffect(() => {
    const onClick = async (evt: KeyboardEvent) => {
      const isDev = isDevelopment() || isLocal();
      const token = await getToken();

      // option/alt + T
      if (isDev && token && evt.altKey && evt.code === 'KeyT') {
        console.log(token);
        alert('Token loged in console!');
      }
    };

    document.addEventListener('keydown', onClick);
    return () => document.removeEventListener('keydown', onClick);
  }, [getToken]);
}
