import { useMemo, useState } from 'react';
import { Typography, Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CapitalAllocation } from '../../../schemas/FundCapitalAllocation.schema';
import { FMT } from '../../../util/formatter-service';
import { MixedChart } from '../../../components/Charts/MixedChart/MixedChart';
import { cardStyles } from '../../../theme/component-styles';
import {
  capitalDeploymentBarSeries,
  CapitalDeploymentFilter,
  capitalDeploymentXData,
} from './capitalDeploymentChartData';

export function CapitalDeploymentChart({ data }: { data: CapitalAllocation[] }) {
  const [filter, setFilter] = useState<CapitalDeploymentFilter>('actual');
  const xData = useMemo(() => capitalDeploymentXData(data), [data]);
  const yData = useMemo(() => capitalDeploymentBarSeries(data, filter), [data, filter]);

  return (
    <div style={{ ...cardStyles, marginBottom: '1rem' }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'body1'} my={'1rem'} component={'h6'}>
          Capital Deployment Overview
        </Typography>
        <ToggleButtonGroup
          color='secondary'
          value={filter}
          exclusive
          onChange={(e, newValue) => {
            if (newValue === null) return;
            setFilter(newValue);
          }}
        >
          <ToggleButton value={'actual'}>Actual</ToggleButton>
          <ToggleButton value={'projected'}>Projected</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Box width={'100%'} height={'25rem'}>
        <MixedChart
          xCategoryData={xData}
          yValueData={yData}
          yAxis={{
            type: 'value',
            axisLabel: {
              formatter: (value: number | null) => FMT.format('usdShort', value) || '',
            },
          }}
          staticOptions={{
            legend: {
              bottom: 5,
              height: 40,
              left: 'center',
            },
            grid: {
              top: 20,
              left: 0,
              right: 0,
              bottom: 50,
            },
          }}
        />
      </Box>
    </div>
  );
}
