import { css } from '@emotion/react';
import { CSSProperties, ReactNode, useMemo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { MUIAvatarGroup } from '../../../components/Avatar/MUIAvatarGroup';
import { IActivityParticipantDataModel } from '../../../data-models/person.data-model';
import {
  formatParticipant,
  splitParticipantsByRole,
  IEngagementSummary,
  activityTypeToLabel,
} from '../utils/getPersonActivityData';
import { SubgridRow } from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { FMT } from '../../../util/formatter-service';

const activityTable = css`
  display: grid;
  grid-template-columns: repeat(2, max-content) 1fr auto;
  padding: 0%.5;
  column-gap: 1rem;
  width: 100%;
`;
function ActivityCell({ children, color, title }: TypographyProps) {
  return (
    <Typography variant='body2' noWrap color={color} title={title}>
      {children}
    </Typography>
  );
}

interface IActivityDetailsProps {
  engagementSummary: IEngagementSummary;
  showAllParticipants?: boolean;
  style?: CSSProperties;
}
export function ActivityDetails({
  engagementSummary,
  showAllParticipants = true,
  style,
}: IActivityDetailsProps) {
  const { firstEmail, lastEmail, firstMeeting, lastMeeting } = engagementSummary;
  const activities = useMemo(() => {
    return Object.entries({ firstEmail, firstMeeting, lastEmail, lastMeeting }).reduce(
      (acc, [key, value]) => {
        if (!value || !value.createdAt) return acc;
        const { organizers, rest } = splitParticipantsByRole(value.participants);

        acc.push(
          <ActivityItem
            key={key}
            activity={activityTypeToLabel[key] ?? key}
            organizers={organizers}
            participants={showAllParticipants ? rest : []}
            date={value.createdAt}
          />
        );
        return acc;
      },
      [] as ReactNode[]
    );
  }, [firstEmail, firstMeeting, lastEmail, lastMeeting, showAllParticipants]);
  return (
    <div css={activityTable} style={style}>
      {activities}
    </div>
  );
}

interface IActivityItemProps {
  activity: string;
  date: string;
  organizers: IActivityParticipantDataModel[];
  participants: IActivityParticipantDataModel[];
}
const maxParticipants = 3;

export function ActivityItem({ activity: label, date, organizers, participants }: IActivityItemProps) {
  const moreTitle = useMemo(() => {
    if (participants.length <= maxParticipants) return '';
    return participants
      .slice(maxParticipants - 1)
      .map((p) => formatParticipant(p))
      .join('\n');
  }, [participants]);
  return (
    <SubgridRow>
      <ActivityCell>{label}</ActivityCell>
      <ActivityCell color='text.secondary'>{FMT.format('date', date)}</ActivityCell>
      <ActivityCell title={organizers.map((o) => formatParticipant(o)).join(', ')}>
        {organizers.map((o) => o.name).join(', ')}
      </ActivityCell>
      {participants.length ? (
        <MUIAvatarGroup max={maxParticipants} size='small' additionalAvatarTitle={moreTitle}>
          {participants.map((participant) => (
            <MUIAvatar
              key={participant.name}
              nameParts={participant.name.split(' ')}
              src={''}
              title={formatParticipant(participant)}
            />
          ))}
        </MUIAvatarGroup>
      ) : (
        <div />
      )}
    </SubgridRow>
  );
}
