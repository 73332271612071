import { FC } from 'react';
import { useTheme } from '@mui/material';
import { RightAligned } from './RightAligned';

interface ErrorCellProps {
  message?: string;
  style?: React.CSSProperties;
}

export const ErrorCell: FC<ErrorCellProps> = ({ message, style }) => {
  const { neutral } = useTheme().colors;
  return (
    <RightAligned style={{ color: `${neutral[50]}`, height: '100%', ...style }}>
      {message || 'Request Failed'}
    </RightAligned>
  );
};
