import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ButtonProps, Typography, styled } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useFundSelector } from '../../../../../../pages/PortfolioOverview/hooks/useFundSelector';
import {
  selectedFundsAsOptionsPFState,
  fundOptionsState,
} from '../../../../../../pages/PortfolioOverview/state/FundFilterState';
import { SelectItem } from '../../../../../../types';
import { pluralizeWord } from '../../../../../../util/stringUtils';
import { PopupMenu } from '../../../../../PopupMenu/PopupMenu';
import { ContainerName, Breakpoint } from '../../../../../../constants/styles';

const StyledLabel = styled(Typography)`
  @container ${ContainerName.headerContainer} (max-width: ${Breakpoint.small}) {
    font-size: 0.825rem;
  }
`;

export const FundFilterPF2: FC = () => {
  const { onChangeSelection, onSelectAll } = useFundSelector();
  const fundOptions = useRecoilValue(fundOptionsState);

  const selected = useRecoilValue(selectedFundsAsOptionsPFState);
  const label = (
    <StyledLabel variant='body2'>
      {`${selected.length}/${fundOptions.length} ${pluralizeWord('Fund', selected.length)} Selected`}
    </StyledLabel>
  );

  const _onChange = useCallback(
    (value: SelectItem[]) => {
      if (value.length === fundOptions.length) {
        onSelectAll();
        return;
      }
      onChangeSelection(value);
    },
    [fundOptions.length, onChangeSelection, onSelectAll]
  );

  return (
    <PopupMenu
      items={fundOptions}
      groupable
      searchable
      label={label}
      onChange={_onChange}
      selectedItems={selected}
      ActionComponent={FundSelectorButton}
    />
  );
};

function FundSelectorButton({ children, ...rest }: ButtonProps) {
  return (
    <Button endIcon={<FilterAltIcon />} size='medium' {...rest} title='Filter by fund'>
      {children}
    </Button>
  );
}
