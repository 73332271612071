import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { capitalize } from 'lodash-es';
import { FormatterService } from '../../../../util/formatter-service';
import { RendererType } from '../../../../data-models/field.data-model';

export const ColDefOverrides: Record<string, ColDef> = {
  ['name']: {
    width: 200,
    pinned: 'left',
  },
  ['compoundingPeriod']: {
    valueFormatter: capitalizer,
  },
  ['interestType']: {
    valueFormatter: capitalizer,
  },
  ['participationType']: {
    valueFormatter: capitalizer,
    cellClass: ['text-align-right'],
  },
  ['participationCap']: {
    valueFormatter: (params: ValueFormatterParams) => {
      const formatter = FormatterService.get().getFormatterForFormField({
        renderer: RendererType.multiplier,
        key: 'participationCap',
      });
      if (params.value != null) return formatter(params.value);
      else if (!params.node?.footer && !params.node?.group) return 'N/A';
      else return '';
    },
  },
};

function capitalizer(params: ValueFormatterParams) {
  return params.value != null ? capitalize(params.value) : '';
}
