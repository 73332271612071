import { CashFlow } from '@webcarrot/xirr';
import { IMetricsDataModel } from '../../../../../data-models/metrics.data-model';
import { calculateXIRR } from '../../../providers/calculateXIRR';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';

export interface ICalculatedMetricsByUser {
  irr: number;
  moic: number;
  userId: number;
}

export interface IRawMetricsByUser {
  amountInvested: number;
  cashflows: CashFlow[];
  distributions: number;
  escrowAmount: number;
  fmv: number;
}

export function computeData(
  metrics: IMetricsDataModel[],
  asOfDate: Date,
  getCompany: (id: number) => ICompanyDataModel | null
) {
  const transactionsByUsers = metrics.reduce((res, metric) => {
    const company = getCompany(metric.companyId);
    if (company) {
      const { dealLeadId, internalSourceId } = company;
      addMetricToUser(res, metric, dealLeadId, asOfDate);
      addMetricToUser(res, metric, internalSourceId, asOfDate);
    }

    return res;
  }, new Map<number, IRawMetricsByUser>());

  const metricsByUsers: ICalculatedMetricsByUser[] = [];
  for (const [userId, data] of transactionsByUsers) {
    metricsByUsers.push({
      irr: calculateXIRR(data.cashflows),
      moic: calculateMOIC(data),
      userId,
    });
  }

  return metricsByUsers;
}

function addMetricToUser(
  res: Map<number, IRawMetricsByUser>,
  metric: IMetricsDataModel,
  userId: number | null,
  asOfDate: Date
) {
  if (userId == null) {
    return res;
  }
  if (!res.has(userId)) {
    res.set(userId, {
      amountInvested: 0,
      cashflows: [],
      distributions: 0,
      escrowAmount: 0,
      fmv: 0,
    });
  }

  const userMetrics = res.get(userId)!;
  metric.transactions.forEach((transaction) => {
    if (transaction.investmentAmount) {
      userMetrics.cashflows.push({
        date: new Date(transaction.transactionDate),
        amount: -1 * transaction.investmentAmount,
      });
    }

    if (transaction.distributions) {
      userMetrics.cashflows.push({
        date: new Date(transaction.transactionDate),
        amount: transaction.distributions,
      });
    }
  });

  userMetrics.amountInvested += metric.amountInvested;
  userMetrics.distributions += metric.distributions;
  userMetrics.escrowAmount += metric.escrowAmount;
  userMetrics.fmv += metric.fmv;
  userMetrics.cashflows.push({
    date: asOfDate,
    amount: metric.fmv,
  });

  return res;
}

function calculateMOIC(userMetrics: IRawMetricsByUser) {
  return (
    (userMetrics.fmv + userMetrics.distributions + userMetrics.escrowAmount) /
    (userMetrics.amountInvested || 1)
  );
}
