import { FC, useRef } from 'react';
import { TooltipProps, Typography, TypographyProps } from '@mui/material';
import { StyledTooltip } from '../pages/CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { useContainerDimensions } from '../hooks/useContainerDimensions';

interface IConditionalTooltipWrapperProps {
  text: string;
  tooltipProps?: TooltipProps;
  typographyProps?: TypographyProps;
}

export const ConditionalTooltipWrapper: FC<IConditionalTooltipWrapperProps> = ({
  text,
  tooltipProps,
  typographyProps,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);

  const overflowing = ref?.current && ref?.current?.scrollWidth > width;

  const typography = (
    <Typography
      ref={ref}
      variant='body2'
      {...typographyProps}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...typographyProps?.style,
      }}
    >
      {text}
    </Typography>
  );

  return overflowing ? (
    <StyledTooltip title={text} placement='top-start' {...tooltipProps} data-testid={'tooltip'}>
      {typography}
    </StyledTooltip>
  ) : (
    typography
  );
};
