import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import {
  fundsByIdMapState,
  roundsMapState,
  transactionTypesMapState,
} from '../../../services/state/AppConfigState';
import { UNKNOWN_GROUP_NAME } from '../../../components/AgTable/tableConstants';
import { RoundFromInvestmentCellRenderer } from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { TransactionsMenuCell } from '../common-grid-defs/TransactionsMenuCell';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { companyRoundsByIdState } from '../state/FinanceState';
import { getCurrencyColDefs } from '../common-grid-defs/commonColDefs';
import { FMT } from '../../../util/formatter-service';

export function useAllTransactionsColumnDefs(): ColDef<ITransactionDataModel>[] {
  const transactionTypeMap = useRecoilValue(transactionTypesMapState);
  const selectedCompanyId = useRecoilValue(selectedCompanyIdStateFinance);
  const investmentsById = useRecoilValue(companyRoundsByIdState(selectedCompanyId ?? -1));
  const roundsById = useRecoilValue(roundsMapState);
  const fundsById = useRecoilValue(fundsByIdMapState);

  return useMemo(() => {
    if (!investmentsById) return [];
    const defs: ColDef<ITransactionDataModel>[] = [
      {
        headerName: 'Transaction Date',
        field: 'transactionDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          maxNumConditions: 1,
        },
        valueGetter: (params: ValueGetterParams) => {
          return params.data?.transactionDate ? new Date(params.data?.transactionDate) : null;
        },
        valueFormatter: (params: ValueFormatterParams) =>
          params.data?.transactionDate ? FMT.format('date', params.data?.transactionDate) : '',
        cellClass: ['monospace', 'text-align-right', 'dateISO'],
        sortable: true,
        initialSort: 'desc',
      },
      {
        headerName: 'Fund',
        colId: 'fund.name',
        valueGetter: (params: ValueGetterParams<ITransactionDataModel>) => {
          const fundId = params.data?.fundId;
          if (!fundId) return '';
          return fundsById.get(fundId)?.name ?? '';
        },
        minWidth: 200,
      },
      {
        headerName: 'Transaction Type',
        valueGetter: (params: ValueGetterParams) => {
          const transactionTypeId = params.data?.transactionTypeId;
          if (!transactionTypeId) return '';
          return transactionTypeMap.get(transactionTypeId)?.catergory;
        },
      },
      {
        headerName: 'Transaction Sub-Type',
        valueGetter: (params: ValueGetterParams) => {
          const transactionTypeId = params.data?.transactionTypeId;
          if (!transactionTypeId) return '';
          return transactionTypeMap.get(transactionTypeId)?.name;
        },
      },
      {
        headerName: 'Stage',
        valueGetter: (params: ValueGetterParams<ITransactionDataModel>) => {
          const investment = investmentsById.get(params.data?.investmentRoundId ?? -1);
          if (!investment) return '';
          const round = roundsById.get(investment.roundId ?? -1);
          return round?.name ?? '';
        },

        cellRenderer: RoundFromInvestmentCellRenderer,
        keyCreator: (params) => {
          return params.value || UNKNOWN_GROUP_NAME;
        },

        filterParams: {
          excelMode: 'mac',
        },
      },
      {
        headerName: 'Round',
        valueGetter: (params: ValueGetterParams<ITransactionDataModel>) => {
          const investment = investmentsById.get(params.data?.investmentRoundId ?? -1);
          return investment?.name ?? '';
        },
        keyCreator: (params) => {
          return params.value || UNKNOWN_GROUP_NAME;
        },
      },
      {
        headerName: 'PPS',
        field: 'pricePerShare',
        ...getCurrencyColDefs(),
      },
      {
        headerName: 'Amount',
        field: 'amountNormalized',
        filter: 'agNumberColumnFilter',
        headerClass: 'ag-left-aligned-header',
        cellClass: ['monospace', 'text-align-right', 'currencyUSD'],
        aggFunc: 'sum',
        enableValue: true,
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        valueFormatter: (params: ValueFormatterParams) => FMT.format('usd', params.value),
      },
      {
        headerName: 'Number Of Shares',
        field: 'noOfShares',
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ['greaterThan', 'lessThan', 'equals', 'inRange'],
        },
        headerClass: 'ag-left-aligned-header',
        type: 'rightAligned',
        aggFunc: 'sum',
        enableValue: true,
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        valueFormatter: (params: ValueFormatterParams) => FMT.format('integer', params.value),
        cellClass: ['monospace', 'text-align-right', 'int'],
      },
      { headerName: 'Status', field: 'status' },
      {
        headerName: '',
        maxWidth: 70,
        flex: 0,
        menuTabs: [],
        cellRenderer: TransactionsMenuCell,
        pinned: 'right',
      },
    ];
    return defs;
  }, [investmentsById, fundsById, transactionTypeMap, roundsById]);
}
