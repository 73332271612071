import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export function useContainerDimensions(containerRef: MutableRefObject<HTMLElement | null>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const callback = useCallback((entries: ResizeObserverEntry[]) => {
    if (!entries.length) return;

    const { width, height } = entries[0].contentRect;
    setDimensions({ width, height });
  }, []);

  useEffect(() => {
    const el = containerRef.current;
    if (!el) return;

    const observer = new ResizeObserver(callback);
    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [callback, containerRef]);

  return dimensions;
}
