import { IDealDataModel } from '../../../data-models/deal.data-model';

export function getNextStageId(deal: IDealDataModel, sortedStageIds: number[] | null): number | null {
  if (!sortedStageIds) return null;

  const currentStageIndex = sortedStageIds.findIndex((id) => id === deal.stageId);
  if (currentStageIndex === -1 || currentStageIndex === sortedStageIds.length - 1) return null;

  return sortedStageIds[currentStageIndex + 1];
}
