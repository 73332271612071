import { FC } from 'react';
import { Typography } from '@mui/material';
import { IPersonDataModel } from '../../../../data-models/person.data-model';
import { StyledTable } from '../../People/CellRenderers/StyledHistoryTable';
import { NoDataMessage2 } from '../../components/Messages/HelpMessage';

interface IEducationHistoryProps {
  person: IPersonDataModel;
}
export const EducationHistory: FC<IEducationHistoryProps> = ({ person }) => {
  const education = person.education;

  return (
    <div style={{ marginTop: '1.5rem' }}>
      {!education || education.length === 0 ? (
        <NoDataMessage2 />
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>Institution</th>
              <th>Major</th>
              <th>Graduation Year</th>
            </tr>
          </thead>

          <tbody>
            {education.map((ed, i) => (
              <tr key={i}>
                <td>
                  <Typography variant='caption' color='text.primary'>
                    {ed.institution ?? ''}
                  </Typography>
                </td>
                <td>
                  <Typography variant='caption' color='text.primary'>
                    {ed.degree ?? ''}
                  </Typography>
                </td>
                <td>{ed.graduationYear ?? ''}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </div>
  );
};
