import { createFormatterDataModel, IFormatterDataModel } from '../../data-models/formatter.data-model';
import { PrimitiveType } from '../../data-models/field3.data-model';
import { formatNumber2dp, formatPercentRoundAsIs, formatUSD } from './NumericFormatters';

export interface IArrayFormatConfig {
  itemType: PrimitiveType;
  itemFormatter?: IFormatterDataModel<unknown> | string;
}

export enum ArrayFormatterId {
  stringArray = 'stringArray',
  numberArray = 'numberArray',
}

export const ArrayFormattersConfig = {
  stringArray: createFormatterDataModel({
    type: 'array',
    id: 'stringArray',
    config: {
      itemType: 'string',
    },
  }),
  numberArray: createFormatterDataModel({
    type: 'array',
    id: 'numberArray',
    config: {
      itemType: 'number',
    },
  }),
};

/** @deprecated use FormatterService instead. */
export function formatStringArray(arr: string[]): string {
  if (!Array.isArray(arr)) return 'INVALID FIELD TYPE';
  return arr.join(', ');
}

/** @deprecated use FormatterService instead. */
export function formatNumberArray(arr: number[]): string {
  if (!Array.isArray(arr)) return 'INVALID FIELD TYPE';
  return arr.map((val) => formatNumber2dp(val)).join(', ');
}

/** @deprecated use FormatterService instead. */
export function formatCurrencyArray(arr: number[]): string {
  if (!Array.isArray(arr)) return 'INVALID FIELD TYPE';
  return arr.map((val) => formatUSD(val)).join(', ');
}

/** @deprecated use FormatterService instead. */
export function formatPercentArray(arr: number[]): string {
  if (!Array.isArray(arr)) return 'INVALID FIELD TYPE';
  return arr.map((val) => formatPercentRoundAsIs(val)).join(', ');
}
