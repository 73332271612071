import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { ConfirmDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDialog';
import { FooterButtons } from '../../../components/Dialog/ConfirmDialog/FooterButtons';
import ListTemplates from '../components/ListTemplates';
import {
  customFieldsOpenUploadModalState,
  customFieldsSelectedTemplateState,
  templatesTypeListState,
} from '../../../services/state/AdminPanel/CustomFieldsState';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { ITemplate } from '../../../data-models/template.data-model';

export interface IUploadBulkModalProps {
  open: boolean;
  onClose: () => void;
}

interface ILoadListTemplatesProps {
  onSelectTemplate: (targetTemplate: ITemplate) => void;
}

function LoadListTemplates({ onSelectTemplate }: ILoadListTemplatesProps) {
  const { state, contents: templatesList } = useRecoilValueLoadable(templatesTypeListState);

  switch (state) {
    case 'loading':
      return <Skeleton variant='rounded' width={'100%'} height={30} />;
    case 'hasValue':
      return <ListTemplates onClick={onSelectTemplate} templatesList={templatesList?.templates ?? null} />;
    case 'hasError':
      return <ErrorOccurredMessage />;
  }
}

export default function UploadBulkModal({ open, onClose }: IUploadBulkModalProps) {
  const { colors } = useTheme();
  const [customFieldsSelectedTemplate, setCustomFieldsSelectedTemplate] = useRecoilState(
    customFieldsSelectedTemplateState
  );
  const setCustomFieldsOpenUploadModalState = useSetRecoilState(customFieldsOpenUploadModalState);

  const handleOnSelectTemplate = (targetTemplate: ITemplate) => {
    if (targetTemplate.template_key === customFieldsSelectedTemplate) {
      return setCustomFieldsSelectedTemplate('');
    }

    setCustomFieldsSelectedTemplate(targetTemplate.template_key);
  };

  return (
    <>
      <ConfirmDialog
        title='Bulk Upload'
        open={open}
        onClose={onClose}
        actionButtons={
          <FooterButtons
            onConfirmDisabled={!customFieldsSelectedTemplate}
            onCancel={onClose}
            onConfirm={() => setCustomFieldsOpenUploadModalState(true)}
            confirmButtonText='Upload'
          />
        }
      >
        <Stack direction='row' spacing={0}>
          <Typography variant='body2' sx={{ mb: 2 }}>
            Entity{' '}
          </Typography>
          <Typography variant='body2' sx={{ ml: '.25rem', color: colors.critical[60] }}>
            *
          </Typography>
        </Stack>
        <LoadListTemplates onSelectTemplate={handleOnSelectTemplate} />
      </ConfirmDialog>
    </>
  );
}
