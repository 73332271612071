import { CircularProgress, IconButton, useTheme, Typography } from '@mui/material';
import {
  CSSProperties,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { SetterOrUpdater } from 'recoil';
import { styled } from '@mui/material/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
interface Props {
  activeField: string | null;
  alignItems?: string;
  children: ReactNode;
  contentAlign?: string;
  contentMarginTop?: string;
  disabled?: boolean;
  inputComponent: ReactNode;
  inputMarginTop?: string;
  isActive: boolean;
  isLoading?: boolean;
  label?: string;
  minHeight?: string;
  minWidth?: string;
  name: string;
  onUpdate?: () => void;
  paddingLeft?: string;
  setActiveField: Dispatch<SetStateAction<string | null>> | SetterOrUpdater<string>;
  style?: CSSProperties;
  width?: string;
  wrap?: boolean;
  hideIcon?: boolean;
  iconPosition?: { top?: string; right?: string; bottom?: string; left?: string };
}

const Wrapper = styled('div')<{ alignItems?: string; flexWrap: boolean; disabled: boolean }>`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  overflow: visible !important;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  position: relative;
`;

const LabelWrapper = styled('div')<{ alignItems?: string; isEditMode: boolean }>`
  width: 110px;
`;

const IconWrapper = styled('div')`
  width: 15px;
  height: 15px;
  margin-top: -2px;
  overflow: visible !important;
`;

const SpinnerWrapper = styled('div')`
  margin-top: 2px;
`;

const ContentWrapper = styled('div')<{ paddingLeft?: string; minHeight?: string; marginTop: string }>`
  align-items: center;
  display: flex;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '40px')};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '12px')};
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
`;

const InputWrapper = styled('div')<{ inputMarginTop: string }>`
  margin-top: ${({ inputMarginTop }) => inputMarginTop};
`;

const DataWrapper = styled('div')<{ width?: string; minHeight?: string }>`
  width: ${({ width }) => (width ? width : 'calc(100% - 125px)')};
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '40px')};
`;

/** @deprecated */
export const EditableField: FC<Props> = ({
  activeField,
  alignItems = 'flex-start',
  children,
  contentAlign,
  contentMarginTop = '0px',
  disabled = false,
  inputComponent,
  inputMarginTop = '0px',
  isActive,
  isLoading,
  label,
  minHeight,
  name,
  onUpdate,
  paddingLeft,
  setActiveField,
  style,
  width,
  iconPosition,
  hideIcon,
  wrap = true,
}) => {
  const { colors } = useTheme();
  const [showIcon, setShowIcon] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () =>
    setTimeout(() => {
      setActiveField('');
      if (onUpdate) onUpdate();
    }, 300)
  );

  const handleMouseEnter = useCallback(() => {
    if (!disabled) {
      setShowIcon(true);
    }
  }, [disabled]);

  const handleMouseLeave = useCallback(() => {
    if (!disabled) {
      setShowIcon(false);
    }
  }, [disabled]);

  const onInputClick = useCallback(() => {
    if (disabled) return;
    if (!activeField) setActiveField(name);
    setShowIcon(false);
  }, [activeField, disabled, name, setActiveField]);

  const IconComponent = iconPosition ? (
    <IconButton sx={{ position: 'absolute', ...iconPosition }}>
      <EditOutlinedIcon htmlColor={colors.secondary[50]} fontSize='small' />
    </IconButton>
  ) : (
    <IconWrapper>
      <IconButton>
        <EditOutlinedIcon htmlColor={colors.secondary[50]} fontSize='small' />
      </IconButton>
    </IconWrapper>
  );

  const LoadingIndicator = iconPosition ? (
    <IconButton sx={{ position: 'absolute', ...iconPosition }}>
      <CircularProgress color={'secondary'} size={16} />
    </IconButton>
  ) : (
    <SpinnerWrapper>
      <CircularProgress color={'secondary'} size={16} />
    </SpinnerWrapper>
  );

  return (
    <Wrapper
      alignItems={alignItems}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onInputClick();
      }}
      flexWrap={wrap}
      style={style}
    >
      {label && (
        <LabelWrapper alignItems={alignItems} isEditMode={isActive}>
          <Typography component={'label'} variant={'body2'} color={colors.primary[100]} className='Label'>
            {label}
          </Typography>
        </LabelWrapper>
      )}
      <DataWrapper width={width} minHeight={minHeight}>
        {isActive ? (
          <InputWrapper ref={ref} inputMarginTop={inputMarginTop}>
            {inputComponent}
          </InputWrapper>
        ) : (
          <ContentWrapper
            paddingLeft={paddingLeft}
            minHeight={minHeight}
            marginTop={contentMarginTop}
            style={{
              alignItems: contentAlign ? contentAlign : '',
              marginTop: contentAlign === 'start' ? '1px' : '',
            }}
          >
            {children}
          </ContentWrapper>
        )}
      </DataWrapper>
      {Boolean(showIcon && !isActive && !activeField && !isLoading) && IconComponent && !hideIcon}
      {isLoading && LoadingIndicator}
    </Wrapper>
  );
};
