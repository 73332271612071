import { styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';

const SkeletonLoaderWrapper = styled('div')`
  width: 100%;
  height: 85vh;
`;

export function CompanyListSkeletonLoader() {
  const titleDimensions = [300, 46];
  const TitleRect = (
    <rect x='0' y={0} rx='0' ry='0' width={titleDimensions[0]} height={titleDimensions[1]} fill='#EEEFF1' />
  );

  const filterRects = [];
  const filterDimensions = [180, 38];
  const filterStartY = 68;
  const gap = 16;
  const searchGap = 48;
  for (let i = 0; i < 4; i++) {
    filterRects.push(
      <rect
        key={i}
        x={(filterDimensions[0] + gap) * i + (i === 0 ? 0 : searchGap)}
        y={filterStartY}
        rx='0'
        ry='0'
        width={filterDimensions[0]}
        height={filterDimensions[1]}
        fill='#EEEFF1'
      />
    );
  }

  return (
    <SkeletonLoaderWrapper>
      <ContentLoader speed={2} width={'100%'} height={'100%'}>
        <>
          {TitleRect}
          {filterRects}
        </>
      </ContentLoader>
    </SkeletonLoaderWrapper>
  );
}
