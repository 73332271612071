import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useSaveCompanyData } from '../../../services/hooks/useSaveCompanyData';
import { QuartersBuilder } from '../services/QuartersBuilder';
import { metricsResponseByDateState } from '../state/MetricsState';
import { selectedViewDatePF } from '../state/PageState';

export function useInitializeMetricsData() {
  const date = useRecoilValue(selectedViewDatePF);
  const metricsResponse = useRecoilValue(metricsResponseByDateState(date));

  const previousQuarterDate = useMemo(() => QuartersBuilder.getPreviousQuarterEnd(date), [date]);
  const previousMetricsResponse = useRecoilValue(metricsResponseByDateState(previousQuarterDate));

  const { batchSaveCompanyData } = useSaveCompanyData(true);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!metricsResponse || !previousMetricsResponse) return;
    batchSaveCompanyData(metricsResponse.included);
    setReady(true);
  }, [batchSaveCompanyData, metricsResponse, previousMetricsResponse]);

  return {
    previousQuarterDate,
    ready,
  };
}
