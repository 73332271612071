import { ExpandMore } from '@mui/icons-material';
import { Autocomplete, SxProps, TextField, useTheme } from '@mui/material';

export interface Option {
  id: number | string | null;
  value: string;
  label?: string;
}

interface Props {
  options: Option[];
  componentsProps?: { clearIndicator?: object; paper?: object; popper?: object; popupIndicator?: object };
  onChange: (e: React.SyntheticEvent, newValue: Option | null) => void;
  value: Option | null | undefined;
  style?: React.CSSProperties;
  disablePortal?: boolean;
  fieldName?: string; // for Formik
  fieldPlaceholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  openOnfocus?: boolean;
  autofocus?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  className?: string;
  fontSize?: string;
  disableClearable?: boolean;
  blurOnSelect?: boolean | 'touch' | 'mouse';
  textFieldSx?: SxProps;
  inputHeight?: string;
}

export function MuiSingleSelect(props: Props): JSX.Element {
  const { colors } = useTheme();

  const {
    fieldName,
    disabled = false,
    fieldPlaceholder,
    disablePortal = true,
    openOnfocus = false,
    autofocus = false,
    inputVariant = 'outlined',
    className,
    sx,
    fontSize,
    textFieldSx,
    inputHeight,
    ...rest
  } = props;

  return (
    <Autocomplete
      className={className}
      autoHighlight
      disabled={disabled}
      getOptionLabel={function (option: Option): string {
        return String(option?.value) ?? '';
      }}
      openOnFocus={openOnfocus}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={fieldName}
          variant={inputVariant}
          placeholder={fieldPlaceholder}
          autoFocus={autofocus}
          inputProps={{
            ...params.inputProps,
            style: { fontSize: fontSize ?? undefined, height: inputHeight ?? 'inherit' },
          }}
          sx={textFieldSx ?? null}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} style={{ fontFamily: 'Wotfard-Regular', fontSize: fontSize ?? '0.875rem' }}>
            {option?.value}
          </li>
        );
      }}
      disablePortal={disablePortal}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      popupIcon={<ExpandMore />}
      sx={
        sx ?? {
          "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
            visibility: 'visible',
            color: colors.neutral[40],
          },
        }
      }
      {...rest}
    />
  );
}
