import { AnimationProps, motion } from 'framer-motion';
import { IconButton, styled } from '@mui/material';

const IconButtonWhiteRipple = styled(IconButton)`
  .MuiTouchRipple-child {
    background-color: white;
  }
`;
const Path = <Props extends { [key: string]: unknown }>(props: Props & AnimationProps) => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='hsl(0, 0%, 100%)'
    strokeLinecap='round'
    {...props}
  />
);

export interface IMenuToggle {
  isOpen: boolean;
  onClick: () => void;
}
export const MenuToggle = ({ isOpen, onClick }: IMenuToggle) => (
  <IconButtonWhiteRipple onClick={onClick} sx={{ height: '38px', width: '38px' }}>
    <motion.svg animate={isOpen ? 'open' : 'closed'} width='24' height='24' viewBox='-1 -4 24 24'>
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d='M 2 9.423 L 20 9.423'
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </motion.svg>
  </IconButtonWhiteRipple>
);
