import * as yup from 'yup';
import { isDate, startOfDay } from 'date-fns';

export const transactionDateSchema = (): yup.StringSchema =>
  yup.string().nullable().required('Transaction Date is a required field').transform(transformDateToString);

export const futureDateSchema = () =>
  yup.date().optional().nullable().min(startOfDay(new Date()), 'Date cannot be in the past');

//cf https://github.com/react-hook-form/react-hook-form/issues/7730#issuecomment-1028794992
export function transformDateToString(value?: Date | string | null, originalValue?: string | Date | null) {
  if (isDate(originalValue)) {
    return (originalValue as Date).toISOString();
  } else {
    return originalValue ?? null;
  }
}
export const transactionCategorySelectorSchema = () =>
  yup.object().shape({
    _viewModel: yup.object().shape({
      transactionCategory: yup.string().required('Transaction type is required'),
    }),
    transactionTypeId: yup
      .number()
      .nullable()
      .min(0, 'Transaction sub-type is required')
      .required('Transaction sub-type is required'),
  });

export const proceedsTypeSchema = () =>
  yup
    .array()
    .nullable()
    .min(1, 'At least 1 Proceed Type Required')
    .required('Proceeds Type is a required field');
