import { FC, ReactNode, useMemo } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { ColumnStage } from '../../data-models/columnStage.data-model';
import { collapsedColWidth, expandedColWidth } from './columnSizes';

const DroppableWrapper = styled('div')<{ isCollapsed: boolean; isOver: boolean }>`
  width: ${({ isCollapsed }) => (isCollapsed ? collapsedColWidth : expandedColWidth)};
  min-width: ${({ isCollapsed }) => (isCollapsed ? collapsedColWidth : expandedColWidth)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${({ isOver, theme }) => (isOver ? theme.colors.primary[0] : 'transparent')};
  ${({ isOver, theme }) => isOver && `border: thin dashed ${theme.colors.neutral[20]}; border-radius: 4px;`};
`;

const VerticalChip = styled('div')`
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: 4px;
  padding: 6px 0;
`;

const VerticalInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  writing-mode: tb-rl;
  transform: rotate(-180deg);
`;

interface DroppableProps {
  children: ReactNode;
  data: ColumnStage;
  isCollapsed: boolean;
}

export const Droppable: FC<DroppableProps> = ({ data, children, isCollapsed }) => {
  const { colors } = useTheme();
  const { isOver, setNodeRef } = useDroppable({
    id: String(data.stage.id),
  });

  const { deals } = data;
  const valuationMaxSum = useMemo(() => {
    if (!deals.length) return '';
    const sum = deals.reduce((acc, current) => (current.gcAmountMax ? acc + current.gcAmountMax : acc), 0);
    return `(${formatUSDShort(sum)})`;
  }, [deals]);

  return (
    <DroppableWrapper ref={setNodeRef} isCollapsed={isCollapsed} isOver={isOver}>
      {isCollapsed ? (
        <VerticalInfoWrapper style={{ position: 'sticky', top: '0' }}>
          <Typography variant='subtitle1' color={colors.primary[100]}>
            {data.stage.displayName}
          </Typography>
          <VerticalChip>
            <Typography variant='body2'>{`${deals.length} ${valuationMaxSum}`}</Typography>
          </VerticalChip>
        </VerticalInfoWrapper>
      ) : null}

      {!isCollapsed && children}
    </DroppableWrapper>
  );
};
