import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { ErrorAlertCard } from '../../../../components/Card/AlertCard';
import { pluralizeWord } from '../../../../util/stringUtils';

interface IMissedDataPtsProps {
  numMissedDataPts: number;
}
export const MissedDataPts: FC<IMissedDataPtsProps> = ({ numMissedDataPts }) => {
  const theme = useTheme();

  return (
    <ErrorAlertCard style={{ marginTop: '1rem' }}>
      <Typography variant={'subtitle2'}>
        {`There ${pluralizeWord('is', numMissedDataPts)} `}
        <Typography variant={'subtitle2'} color={theme.palette.error.main} component='span' fontWeight={500}>
          {numMissedDataPts}
        </Typography>{' '}
        {` missed data ${pluralizeWord('point', numMissedDataPts)}`}
      </Typography>
    </ErrorAlertCard>
  );
};
