import { useEffect, useMemo } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StepSx, StepperBox, StepperSx } from '../../../components/Form/FormComponents';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { InvestmentFormStep } from './InvestmentSelectOrCreateForm';
import { TransactionFormStep } from './TransactionFormStep';
import { CompanySelectForm } from './CompanySelectForm';
import {
  currentStepState,
  companyDataState,
  useResetTransactionUIState,
  investmentDataState,
} from './TransactionFormUIState';
import { TransactionFormSummaryStep } from './TransactionFormSummaryStep';

export interface ITransactionFormProps {
  onClose: () => void;
}

export function AddTransactionForm(props: ITransactionFormProps) {
  const { onClose } = props;
  const currentStep = useRecoilValue(currentStepState);
  const resetUIState = useResetTransactionUIState();

  const selectedCompany = useRecoilValue(companyDataState);
  const selectedInvestment = useRecoilValue(investmentDataState);

  const { showTransactionSummary2 } = useFlags<MaggieFeatureFlags>();

  const steps = useMemo(() => {
    const result = [
      { label: selectedCompany?.name ?? 'Company', component: <CompanySelectForm /> },
      { label: selectedInvestment?.name ?? 'Round', component: <InvestmentFormStep /> },
      { label: 'Transaction', component: <TransactionFormStep /> },
    ];
    if (showTransactionSummary2) {
      result.push({ label: 'Summary', component: <TransactionFormSummaryStep /> });
    }
    return result;
  }, [selectedCompany?.name, selectedInvestment?.name, showTransactionSummary2]);

  useEffect(() => {
    if (currentStep === steps.length) {
      onClose();
    }
  }, [currentStep, onClose, steps.length]);

  useEffect(() => {
    return resetUIState;
  }, [resetUIState]);

  return (
    <>
      <StepperBox>
        <Stepper activeStep={currentStep} sx={StepperSx}>
          {steps.map(({ label }) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps} sx={StepSx}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>{steps[currentStep]?.component}</>
      </StepperBox>
    </>
  );
}
