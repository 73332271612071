import React, { useState } from 'react';
import { Fade, IconButton, Menu, MenuItem, MenuList, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { styled } from '@mui/material/styles';
import { ConfirmDeletionDialog } from '../../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';

const Wrapper = styled('div')`
  z-index: 50;
  position: absolute;
  right: 5px;
  top: 12px;
`;

interface INoteActionButtons {
  deleteNote: () => void;
}

export default function NoteActionButtons({ deleteNote }: INoteActionButtons) {
  const { neutral, primary } = useTheme().colors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState(false);
  const handleCloseDeleteNoteModal = () => setOpenDeleteNoteModal(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteNoteAndCloseModal = () => {
    deleteNote();
    handleCloseDeleteNoteModal();
  };

  return (
    <Wrapper data-testid='noteitem-menuoptions'>
      <ConfirmDeletionDialog
        open={openDeleteNoteModal}
        onClose={handleCloseDeleteNoteModal}
        onConfirm={() => handleDeleteNoteAndCloseModal()}
        title='Delete this Note?'
      >
        <Typography variant='body2' color={neutral[60]}>
          Are you sure you want to delete this note permanently?
        </Typography>
      </ConfirmDeletionDialog>

      <IconButton
        id='fade-button'
        data-testid='menuoptions-iconbtn'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color='secondary'
        size={'medium'}
        sx={{
          zIndex: 50,
        }}
      >
        <MoreVertIcon sx={{ color: neutral['60'] }} />
      </IconButton>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        elevation={2}
      >
        <MenuList sx={{ width: '180px', py: 0 }}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteNoteModal(true);
              handleClose();
            }}
            sx={{ display: 'flex', justifyItems: 'space-between', px: 1, py: 2 }}
          >
            <DeleteOutlinedIcon sx={{ color: neutral['60'], mr: 0.5 }} />
            <Typography variant='body2' color={primary[100]}>
              Delete
            </Typography>
          </MenuItem>
        </MenuList>
      </Menu>
    </Wrapper>
  );
}
