import { SerializedEditorState } from 'lexical';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  createNoteDataModel,
  ILexicalNoteFormValues,
  INoteDataModel,
  INoteDraftDataModel,
} from '../../../data-models/note.data-model';
import { useCreateNote, useUpdateNote } from '../../../services/hooks/useNoteActions';
import { useLexicalContext } from '../provider/LexicalProvider';

export const useSaveNote = () => {
  const values = useLexicalContext();
  const { setNoteData, setLastSuccessUpdateOnNotes, companyId, linkedCompanyIds, editorStateRef } = values;
  const { getValues } = useFormContext<ILexicalNoteFormValues>();

  const createNoteAction = useCreateNote(companyId);
  const updateNoteAction = useUpdateNote(companyId);

  const saveNote = useCallback(
    async (noteData: INoteDataModel<SerializedEditorState>) => {
      if (!editorStateRef.current) return;

      const currentIsoDate = new Date().toISOString();
      const isANewNote = !noteData?.version || noteData?.version === -1;
      const { body, title } = getValues();
      const editorStateJSON = editorStateRef.current.toJSON();

      const noteQueryParams = {
        body: {
          data: editorStateJSON,
          type: 'lexicaljs-json',
          attendees: body?.attendees ?? [],
        },
        companyIds: linkedCompanyIds,
        // FIXME: MAGGIE-3904
        // tags,
        title,
      } as INoteDataModel<SerializedEditorState>;

      if (!isANewNote) {
        const updatedNote = {
          ...noteData,
          ...noteQueryParams,
        };

        const updatedNoteResponse = await updateNoteAction(companyId, updatedNote);

        if (updatedNoteResponse) {
          setLastSuccessUpdateOnNotes(currentIsoDate);
          setNoteData(updatedNoteResponse);

          return {
            ...updatedNoteResponse,
            lastSuccessUpdateOnNotes: currentIsoDate,
          };
        }
      } else {
        const newNote = createNoteDataModel<SerializedEditorState>({
          ...noteQueryParams,
        });

        const createdNoteResponse = await createNoteAction(
          companyId,
          newNote as INoteDraftDataModel<SerializedEditorState>
        );

        if (createdNoteResponse) {
          setNoteData(createdNoteResponse);
          setLastSuccessUpdateOnNotes(currentIsoDate);

          return {
            ...createdNoteResponse,
            lastSuccessUpdateOnNotes: currentIsoDate,
          };
        }
      }
    },
    [
      companyId,
      createNoteAction,
      editorStateRef,
      getValues,
      linkedCompanyIds,
      setLastSuccessUpdateOnNotes,
      setNoteData,
      updateNoteAction,
    ]
  );

  return saveNote;
};
