import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { IListDataModel, ListSubtype, ListType } from '../../../../../data-models/list.data-model';
import { listsState } from '../../../../../pages/CompanyProfiles/state/ListsState';
import {
  archivedListSort,
  discoveryListSort,
  listSort,
} from '../../../../../pages/CompanyProfiles/utils/listSortingUtils';
import { NavigationWrapper } from '../NavigationWrapper';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { CPLists } from './CPLists';
import { AddCompanyList } from './UploadCompanyList/AddCompanyList';

const Wrapper = styled('div')``;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface INavSectionProps {
  title: string;
  items: IListDataModel[];
  showAddButton?: boolean;
}

const NavSection: FC<INavSectionProps> = ({ title, items, showAddButton = false }) => {
  const { secondary } = useTheme().colors;

  return (
    <Wrapper>
      <TitleWrapper>
        <Typography variant='subtitle2' color={secondary[10]}>
          {title}
        </Typography>
        {showAddButton && <AddCompanyList />}
      </TitleWrapper>
      <CPLists items={items} />
    </Wrapper>
  );
};

export const CPNavigation = () => {
  const companyLists = useRecoilValueLoadable(listsState).valueMaybe();
  const canEditLists = PermissionService.get().hasPermission(PermissionKey.canEditList);

  const mappedCompanyLists = useMemo(() => {
    if (!listsState || !companyLists?.length) return null;
    const result: Record<ListType, IListDataModel[]> = {
      [ListType.DISCOVERY]: [],
      [ListType.MANAGEMENT]: [],
      [ListType.CUSTOM]: [],
      [ListType.ARCHIVE]: [],
    };
    const today = new Date();

    companyLists.forEach((l) => {
      if (l.archiveDate) {
        // Check to see if 30 days has passed since archiving
        const diff = new Date(l.archiveDate).getTime() - today.getTime();
        const numOfDays = diff / (1000 * 3600 * 24);

        if (numOfDays < 30 && numOfDays > 0) {
          result[ListType.ARCHIVE].push(l);
        }
      } else {
        result[l.type].push(l);
      }
    });

    Object.keys(result).forEach((key) => {
      if (key === ListType.DISCOVERY) {
        result[key].sort(discoveryListSort);
      } else if (key === ListType.ARCHIVE) {
        result[key as ListType].sort(archivedListSort);
      } else {
        result[key as ListType].sort(listSort);
      }
    });

    return result;
  }, [companyLists]);

  const discoveryItems = useMemo(() => {
    if (!mappedCompanyLists || !mappedCompanyLists[ListType.DISCOVERY]) return [];
    const discItems = mappedCompanyLists[ListType.DISCOVERY];
    const dataScienceItems = [];
    const upladedItems = [];
    for (const item of discItems) {
      if (item.subtype === ListSubtype.DATA_SCIENCE) {
        dataScienceItems.push(item);
      }
      if (item.subtype === ListSubtype.UPLOADED) {
        upladedItems.push(item);
      }
    }
    return [...dataScienceItems, ...upladedItems];
  }, [mappedCompanyLists]);

  if (!mappedCompanyLists) return null;

  return (
    <NavigationWrapper title={'Companies'}>
      {Boolean(discoveryItems.length) && (
        <NavSection title='DISCOVERY' items={discoveryItems} showAddButton={canEditLists} />
      )}
      {Boolean(mappedCompanyLists[ListType.MANAGEMENT].length) && (
        <NavSection title='MANAGEMENT' items={mappedCompanyLists[ListType.MANAGEMENT]} />
      )}
      {Boolean(mappedCompanyLists[ListType.CUSTOM].length) && (
        <NavSection title={'WATCHLISTS'} items={mappedCompanyLists[ListType.CUSTOM]} />
      )}
      {Boolean(mappedCompanyLists[ListType.ARCHIVE].length) && (
        <NavSection title='Recently Deleted' items={mappedCompanyLists[ListType.ARCHIVE]} />
      )}
    </NavigationWrapper>
  );
};
