import { CompetitiveIntelligenceItem } from '../../../../../data-models/competitive-intelligence.data-model';
import { getFieldsMap } from '../Columns/getFieldsMap';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { removeAllWhitespace } from '../../../../../util/stringUtils';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { GroupColName } from '../Columns/getColumnDefinitionsTransposed';
import { getCellValue } from './getCellValue';

export const prepRowDataTransposed = (
  companies: ICompanyDataModel[],
  fields: FieldDataModel[],
  responseData: CompetitiveIntelligenceItem[]
) => {
  const fieldsMap = getFieldsMap(fields);

  const rowDataMap: Record<number, Record<string, unknown>> = responseData.reduce((map, curr) => {
    map[curr.fieldId] = map[curr.fieldId] || {};
    map[curr.fieldId].id = String(curr.fieldId);
    map[curr.fieldId].field = fieldsMap[curr.fieldId];
    map[curr.fieldId][curr.companyId] = getCellValue(curr);

    const groupName = fieldsMap[curr.fieldId]?.groupName ?? null;

    if (groupName) map[curr.fieldId][GroupColName] = groupName;
    else map[curr.fieldId][GroupColName] = 'Other';
    return map;
  }, {} as Record<number, Record<string, unknown>>);

  // also add any fields that are not in the response data, so that the rows appear as soon as the selected fields are changed
  const rowsWithNoData: Record<string, unknown>[] = [];
  fields.forEach((f) => {
    if (rowDataMap[f.id]) return; // already added
    const row: Record<string, unknown> = {};
    row.id = String(f.id);
    row.field = fieldsMap[f.id];
    if (f.groupName) row[removeAllWhitespace(f.groupName)] = f.groupName;
    else row['Other'] = 'Other';
    rowsWithNoData.push(row);
  });

  return Object.values(rowDataMap).concat(rowsWithNoData);
};
