import { FieldPath } from 'react-hook-form';
import { get } from 'lodash-es';
import { OptionDataValue } from 'echarts/types/src/util/types';
import { CapitalAllocation, capitalAllocationSchema } from '../../../schemas/FundCapitalAllocation.schema';
import { MixedSeriesOption } from '../../../components/Charts/MixedChart/MixedChart';
import { schemaToFormFields } from '../../../util/schema-utils';
import { colors } from '../../../theme/colors';
import { camelCaseToCapitalizedString } from '../../../util/stringUtils';
import { IFormField } from '../../../view-models/form.view-model';
import { FMT, IPartialFormatterDataModel, StandardFormatterId } from '../../../util/formatter-service';

function fieldToColor(field: FieldPath<CapitalAllocation>) {
  const fieldLc = field.toLowerCase();
  switch (true) {
    case fieldLc.includes('initial'):
      return colors.neutral[30];
    case fieldLc.includes('reserve'):
      return colors.tertiary[60];
    case fieldLc.includes('fees'):
      return colors.neutral[70];
    case fieldLc.includes('recycled'):
      return colors.secondary[70];
    default:
      return colors.secondary[30];
  }
}
export const actualCDFields = new Set<FieldPath<CapitalAllocation>>([
  'feesAndExpenses',
  'initialInvestmentsInvested',
  'reservesInvested',
]);
export const projectedCDFields = new Set<FieldPath<CapitalAllocation>>([
  'projectedFeesAndExpensesTransaction.amount',
  'projectedReservesAmount',
]);

export function capitalDeploymentXData(data: CapitalAllocation[]) {
  return data.map((d) => d.period);
}

export type CapitalDeploymentFilter = 'actual' | 'projected';
export function capitalDeploymentBarSeries(data: CapitalAllocation[], filter: CapitalDeploymentFilter) {
  const schema = capitalAllocationSchema();
  let fields = new Set<FieldPath<CapitalAllocation>>();
  if (filter === 'actual') {
    fields = actualCDFields;
  } else if (filter === 'projected') {
    fields = projectedCDFields;
  }

  const fieldMap = schemaToFormFields(schema, [...fields]).reduce(
    (map, field) => map.set(field.key as FieldPath<CapitalAllocation>, field),
    new Map<FieldPath<CapitalAllocation>, IFormField<unknown>>()
  );

  const fieldToSeries: Map<FieldPath<CapitalAllocation>, MixedSeriesOption> = new Map(
    Array.from(fields).map((f) => {
      const formatterForField = fieldMap.get(f)?.formatter;
      const formatter = (value?: number | null) => {
        return formatterForField
          ? FMT.format(formatterForField as StandardFormatterId | IPartialFormatterDataModel<unknown>, value)
          : value;
      };

      return [
        f,
        {
          name: fieldMap.get(f)?.label ?? camelCaseToCapitalizedString(f),
          data: [],
          stack: 'Capital Deployment',
          type: 'bar',
          barMinHeight: 1,
          label: {
            formatter,
          },
          tooltip: {
            valueFormatter: formatter,
          },
          color: fieldToColor(f),
        },
      ];
    })
  );
  data.forEach((quarterlyVals) => {
    fields.forEach((f) => {
      const series = fieldToSeries.get(f);
      if (series) {
        (series.data as OptionDataValue[][]).push([
          quarterlyVals.period,
          get(quarterlyVals, f) as number | null,
        ]);
      }
    });
  });

  return Array.from(fieldToSeries.values());
}
