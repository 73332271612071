import { Button } from '@mui/material';
import { FC } from 'react';
import { ActionButtonWrapper, ActionButtonStack } from './UnsentKpiRequestsGrid';

interface IBulkActionButtonsProps {
  onAction: () => void | Promise<void>;
  onCancel: () => void;
  actionLabel: string;
}
export const BulkActionButtons: FC<IBulkActionButtonsProps> = ({ onAction, onCancel, actionLabel }) => {
  return (
    <ActionButtonWrapper>
      <ActionButtonStack direction={'row'}>
        <Button onClick={onAction} color={'secondary'} variant='contained'>
          {actionLabel}
        </Button>
        <Button onClick={onCancel} color={'secondary'} variant='outlined'>
          Cancel
        </Button>
      </ActionButtonStack>
    </ActionButtonWrapper>
  );
};
