import * as yup from 'yup';
import { Schema } from 'yup';
import { IGridField } from '../view-models/grid.view-model';
import { IFormField } from '../view-models/form.view-model';
import { IDisplayField } from '../view-models/display-field.view-model';

export function initYup() {
  initGridMeta();
  initFormMeta();
  initFieldMeta();
  initCustomMeta();
}

function initGridMeta() {
  function gridMeta<T extends Schema>(this: T, gridMeta: Partial<IGridField<T>>) {
    const current = this.meta() ?? {};

    return this.meta({
      ...current,
      ...gridMeta,
    });
  }

  yup.addMethod(yup.array, 'gridMeta', gridMeta);
  yup.addMethod(yup.boolean, 'gridMeta', gridMeta);
  yup.addMethod(yup.date, 'gridMeta', gridMeta);
  yup.addMethod(yup.number, 'gridMeta', gridMeta);
  yup.addMethod(yup.string, 'gridMeta', gridMeta);
  yup.addMethod(yup.object, 'gridMeta', gridMeta);
}

function initFormMeta() {
  function formMeta<T extends Schema>(this: T, formMeta: Partial<IFormField<T>>) {
    const current = this.meta() ?? {};

    return this.meta({
      ...current,
      ...formMeta,
    });
  }

  yup.addMethod(yup.array, 'formMeta', formMeta);
  yup.addMethod(yup.boolean, 'formMeta', formMeta);
  yup.addMethod(yup.date, 'formMeta', formMeta);
  yup.addMethod(yup.number, 'formMeta', formMeta);
  yup.addMethod(yup.string, 'formMeta', formMeta);
}

function initFieldMeta() {
  function fieldMeta<T extends Schema>(this: T, formMeta: Partial<IDisplayField<T>>) {
    const current = this.meta() ?? {};

    return this.meta({
      ...current,
      ...formMeta,
    });
  }

  yup.addMethod(yup.array, 'fieldMeta', fieldMeta);
  yup.addMethod(yup.boolean, 'fieldMeta', fieldMeta);
  yup.addMethod(yup.date, 'fieldMeta', fieldMeta);
  yup.addMethod(yup.number, 'fieldMeta', fieldMeta);
  yup.addMethod(yup.string, 'fieldMeta', fieldMeta);
}

function initCustomMeta() {
  function customMeta<T extends Schema>(this: T, formMeta: Partial<T>) {
    const current = this.meta() ?? {};

    return this.meta({
      ...current,
      ...formMeta,
    });
  }

  yup.addMethod(yup.array, 'customMeta', customMeta);
  yup.addMethod(yup.boolean, 'customMeta', customMeta);
  yup.addMethod(yup.date, 'customMeta', customMeta);
  yup.addMethod(yup.number, 'customMeta', customMeta);
  yup.addMethod(yup.string, 'customMeta', customMeta);
}

export const fdYup = (() => {
  initYup();
  return yup;
})();
