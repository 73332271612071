import { useRecoilValue } from 'recoil';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { Stack, Tabs, styled } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../constants/RouteNameMapping';
import { kpiRequestsCountState } from '../../../../services/state/KPI/KPIRequestsState';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { CountBadge, KPITabSx } from './TabComponents';

export const CustomTabPanel = styled(TabPanel)`
  padding: 0;
  height: 100%;
`;

export function KPIRequestsTabs() {
  const { pathname } = useLocation();
  const requestCount = useRecoilValue(kpiRequestsCountState);

  const tabs = useMemo(() => {
    const toSendPath = `/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`;
    return [
      {
        path: toSendPath,
        title: (
          <Stack direction='row'>
            To Send
            <CountBadge
              badgeContent={requestCount[KPIRequestStatus.NotSent]}
              active={pathname === toSendPath}
            />
          </Stack>
        ),
      },
      {
        path: `/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}/${ROUTES.KPI_REQUESTS_IN_PROGRESS}`,
        title: ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS_IN_PROGRESS],
      },
      {
        path: `/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}/${ROUTES.KPI_REQUESTS_ARCHIVED}`,
        title: ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS_ARCHIVED],
      },
    ];
  }, [pathname, requestCount]);

  return (
    <Tabs variant='scrollable' scrollButtons='auto' value={pathname}>
      {tabs.map((t) => (
        <Tab
          component={NavLink}
          key={t.path}
          value={t.path}
          label={t.title}
          sx={KPITabSx}
          to={{ pathname: `${t.path}` }}
        />
      ))}
    </Tabs>
  );
}
