import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/RouteNameMapping';

export const AdminPanel = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const subsection = pathname.split('/')?.[2];
    if (!subsection) {
      navigate(`/${ROUTES.ADMIN_PANEL}/${ROUTES.ADMIN_PANEL_FIELDS}`, { replace: true });
    }
  }, [navigate, pathname]);

  return <Outlet />;
};
