import { FC, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useViewMode } from '../../hooks/useViewMode';
import {
  IScenarioDataModel,
  IScenarioTransactionDataModel,
} from '../../../../../data-models/scenario.data-model';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { TransactionFields } from './TransactionFields';
import { getInitialScenarioTransactionValues } from './scenarioInitialValues';
import { shouldDisableAddTransaction } from './scenarioFormUtils';

interface IScenarioTransactionsProps {
  captable: ICaptableDataModel;
}

export const ScenarioTransactions: FC<IScenarioTransactionsProps> = ({ captable }) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext<IScenarioDataModel>();
  const fieldArrayMethods = useFieldArray({
    control,
    name: 'scenarioTransactions',
  });
  const { fields, append } = fieldArrayMethods;
  const isViewMode = useViewMode();

  const onAddTransaction = useCallback(() => {
    append(getInitialScenarioTransactionValues() as IScenarioTransactionDataModel);
  }, [append]);

  const [disableAdd, setDisableAdd] = useState(() => {
    const transactions = getValues('scenarioTransactions') ?? [];
    return shouldDisableAddTransaction(transactions);
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change' || !name?.endsWith('type')) return;

      if (
        shouldDisableAddTransaction(value.scenarioTransactions as Partial<IScenarioTransactionDataModel>[])
      ) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      {fields.map((item, index) => (
        <TransactionFields
          key={item.id}
          index={index}
          fieldArrayMethods={fieldArrayMethods}
          captable={captable}
        />
      ))}
      {errors.scenarioTransactions && (
        <Typography color='error' variant='caption' component={'div'}>
          {errors.scenarioTransactions.message}
        </Typography>
      )}
      {!isViewMode && (
        <Button color='secondary' onClick={onAddTransaction} startIcon={<AddIcon />} disabled={disableAdd}>
          Add Transaction
        </Button>
      )}
    </>
  );
};
