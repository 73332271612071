import {
  FC,
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';

export interface MenuContexInterface {
  open: RefObject<HTMLDivElement> | null;
  onToggle: (ref: RefObject<HTMLDivElement> | null) => void;
  onClose: () => void;
  onCancel: () => void;
}

const MenuContext = createContext<MenuContexInterface | undefined>(undefined);

interface MenuStateProviderProps {
  children: ReactNode;
  onCloseCallback?: () => void;
}

export const MenuStateProvider: FC<MenuStateProviderProps> = ({ onCloseCallback, children }) => {
  const [open, setOpen] = useState<RefObject<HTMLDivElement> | null>(null);
  const [closed, setClosed] = useState(false); // used to prevent a lag between outside click and menu close when calling onCloseCallback

  const onToggle = useCallback(
    (ref: RefObject<HTMLDivElement> | null) => {
      if (open) {
        setOpen(null);
      } else {
        setOpen(ref);
      }
    },
    [open]
  );

  const onClose = useCallback(() => {
    setOpen(null);
    setClosed(true);
  }, []);

  useEffect(() => {
    if (closed) {
      onCloseCallback?.();
      setClosed(false);
    }
  }, [onCloseCallback, closed]);

  const onCancel = useCallback(() => {
    setOpen(null);
  }, []);

  useOutsideClick(open, onClose);

  const value = useMemo(
    () => ({
      open,
      onToggle,
      onClose,
      onCancel,
    }),
    [open, onToggle, onClose, onCancel]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenuState = (): MenuContexInterface => {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw new Error('useMenuState cannot be used outside MenuStateProvider');
  }

  return context;
};
