import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CashIn from '../../../../assets/icons/cashin.svg?react';
import CashOut from '../../../../assets/icons/cashout.svg?react';
import Valuation from '../../../../assets/icons/valuation.svg?react';
import { DashboardTableNames } from './DashboardTables';

const StyledCard = styled('div')`
  border-radius: 4px;
  border: 2px solid #fff;
  box-shadow: 0px 24px 48px 0px rgba(75, 82, 93, 0.02), 0px 4px 24px 0px rgba(16, 37, 62, 0.04),
    0px -4px 40px 0px rgba(255, 255, 255, 0.06);
`;

export const StyledDashboardCard = styled(StyledCard)`
  background: radial-gradient(
    227.68% 141.42% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
`;
export const RowContainer = styled('div')`
  padding: 0.75em 0.5rem;
  display: grid;
  gap: 0.5rem;
  align-content: start;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
  max-height: 30vh;
`;

export const StyledDashboardRow = styled(StyledCard)`
  background: radial-gradient(
    227.68% 141.42% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  padding: 0.5em 1rem;
  display: grid;
  grid-template-columns: 4fr 3fr 2fr;
  align-items: center;
  column-gap: 1rem;
  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > .numeric {
    justify-self: end;
  }
`;

interface IDashboardSubtitleProps {
  title: DashboardTableNames;
}

export const DashboardSubtitle = ({ title }: IDashboardSubtitleProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={'0.5rem'}
      marginTop={'0.75rem'}
      marginLeft={'1rem'}
      marginBottom={'0.5rem'}
    >
      {title === DashboardTableNames.cashInData ? (
        <CashIn />
      ) : title === DashboardTableNames.cashOutData ? (
        <CashOut />
      ) : (
        <Valuation />
      )}

      <Typography variant={'body1'} component={'h4'}>
        {title}
      </Typography>
    </Stack>
  );
};

const ContainerBreakpoint = 25;
export const DashboardSection = styled('section')`
  container-type: inline-size;
  margin-top: 2rem;
`;

export const DashboardTableContainer = styled('div')`
  margin: 1rem 0 2rem;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);

  @supports (container-type: inline-size) {
    grid-template-columns: 1fr;
    @container (min-width: ${ContainerBreakpoint}rem) {
      grid-template-columns: repeat(2, 1fr);
    }
    @container (min-width: ${ContainerBreakpoint * 2}rem) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;
