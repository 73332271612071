import { CustomHeaderProps } from 'ag-grid-react';
import { memo, useCallback } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { css } from '@emotion/react';
import { useVisibleColumns } from './useVisibleColumns';

const headerStyle = css`
  & .clear {
    opacity: 0;
  }
  &:hover .clear {
    opacity: 0.6;
  }
`;

export interface IPinnedYearHeaderProps extends CustomHeaderProps {
  yearIndex: number;
}

export const PinnedYearHeader = memo(function PinnedYearHeader(params: IPinnedYearHeaderProps) {
  const { displayName, column } = params;
  const { onHideSingleColumn } = useVisibleColumns();

  const onClick = useCallback(() => {
    onHideSingleColumn(column.getColId());
  }, [onHideSingleColumn, column]);

  return (
    <Stack
      css={headerStyle}
      width={'100%'}
      height={'100%'}
      display={'grid'}
      gridTemplateColumns={'minmax(0, 1fr) minmax(0,1fr) auto'}
      alignItems={'center'}
    >
      <Typography variant={'body2'} sx={{ gridColumn: '2' }}>
        {displayName}
      </Typography>
      <IconButton onClick={onClick} className='clear' title='remove' sx={{ gridColumn: '3' }}>
        <ClearIcon />
      </IconButton>
    </Stack>
  );
});
