import { useCallback, useEffect, useMemo } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useRecoilValue } from 'recoil';
import { add, endOfMonth } from 'date-fns';
import { atomWithDefault } from 'jotai/utils';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { companyState } from '../../../../services/state/CompanyState';
import { monthsInPeriod } from '../../Performance/components/DateSettings/DateSettings';
import { companyMetricsByIdState } from '../../../../services/state/CompanyMetricsByIdState';
import {
  defaultRangeForFrequency,
  endOfPreviousFiscalPeriod,
  financialsDateRangeLabel,
} from './financeDatePickerUtils';

export const selectedFrequencyFinancialsState = atom<KpiPeriod | null>(null);

export const selectedFinancialsRangeState = atom<number | null>(null);

// FIXME: this should be a derived atom based on range - once showFinancialsDatePicker flag is removed, it will be easier to refactor and move logic from useSyncRangeAndEndDate to this atom
export const selectedEndDateFinancialsState = atom<Date | null>(null);

const pastRangeState = atomWithDefault<number | null>((get) => {
  const frequency = get(selectedFrequencyFinancialsState);
  return frequency ? defaultRangeForFrequency(frequency) : null;
});
const futureRangeState = atom<number | null>(null);

export function useFinancialsDateSettings() {
  const [selectedFrequency, setSelectedFrequency] = useAtom(selectedFrequencyFinancialsState);
  const [pastRange, setPastRange] = useAtom(pastRangeState);
  const [futureRange, setFutureRange] = useAtom(futureRangeState);
  useSyncRangeAndEndDate(pastRange, futureRange);
  const endDate = useAtomValue(selectedEndDateFinancialsState);

  const handleFrequencyChange = useCallback(
    (frequency: KpiPeriod) => {
      setSelectedFrequency(frequency);
      setPastRange(defaultRangeForFrequency(frequency));
      setFutureRange(null);
    },
    [setFutureRange, setPastRange, setSelectedFrequency]
  );

  const handleSelectRange = useCallback(
    (selectedRange: number, future: boolean) => {
      if (future) {
        if (futureRange === selectedRange) {
          if (pastRange != null) {
            setFutureRange(null);
          }
        } else {
          setFutureRange(selectedRange);
        }
      } else {
        if (pastRange === selectedRange) {
          if (futureRange != null) {
            setPastRange(null);
          }
        } else {
          setPastRange(selectedRange);
        }
      }
    },
    [futureRange, pastRange, setFutureRange, setPastRange]
  );

  const label = financialsDateRangeLabel(selectedFrequency!, pastRange, futureRange);

  return {
    label,
    selectedFrequency,
    pastRange,
    futureRange,
    handleSelectRange,
    handleFrequencyChange,
    endDate,
  };
}

function useSyncRangeAndEndDate(pastRange: number | null, futureRange: number | null) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;
  const selectedFrequency = useAtomValue(selectedFrequencyFinancialsState)!;
  const setRange = useSetAtom(selectedFinancialsRangeState);
  const setEndDate = useSetAtom(selectedEndDateFinancialsState);

  const defaultEndDate = useMemo(() => {
    return endOfPreviousFiscalPeriod(fye - 1, selectedFrequency);
  }, [fye, selectedFrequency]);

  useEffect(() => {
    setRange((pastRange ?? 0) + (futureRange ?? 0));
  }, [pastRange, futureRange, setRange]);

  useEffect(() => {
    if (futureRange === null) {
      setEndDate(defaultEndDate);
    } else {
      setEndDate(
        endOfMonth(
          add(defaultEndDate, {
            months: monthsInPeriod[selectedFrequency] * futureRange,
          })
        )
      );
    }
  }, [defaultEndDate, setEndDate, futureRange, pastRange, selectedFrequency]);
}

export function useMostRecentReportDate() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const mostRecentReportDate = useRecoilValue(companyMetricsByIdState(companyId))?.metrics?.at(
    0
  )?.kpiLastReportedDateActual;

  return { company, mostRecentReportDate };
}
