import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { BasicDialog, IFormDialogProps } from '../../../../../components/Dialog/BasicDialog';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { RequestsCompaniesList } from '../UnsentKpiRequestsGrid';
import { MaxModalFormHeight, MaxModalFormWidth } from '../../../../../constants/styles';
import { IKpiRequestGridData } from '../hooks/useKPIRequestsFilters';

interface IConfirmBulkAcceptProps extends IFormDialogProps {
  requests: IKpiRequestGridData[];
  onConfirm: () => void;
  loading?: boolean;
  onClose: () => void;
  title: string;
  message?: string;
}

export const RequestConfirmDialog: FC<IConfirmBulkAcceptProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
  requests,
  title,
  message,
}) => {
  return (
    <BasicDialog open={open} onClose={onClose} titleComponent={<Typography variant='h6'>{title}</Typography>}>
      <FormFieldsContainer
        style={{ maxWidth: MaxModalFormWidth, width: '60rem', maxHeight: MaxModalFormHeight }}
      >
        <Stack display='grid' alignContent='start' gap='1rem' py='0.5rem'>
          <Typography variant='body2' color='textSecondary'>
            {message}
          </Typography>
          <RequestsCompaniesList selectedRows={requests} />
        </Stack>
      </FormFieldsContainer>
      <StickyFormButtons onSubmit={onConfirm} onCancel={onClose} submitLabel='Confirm' loading={loading} />
    </BasicDialog>
  );
};
