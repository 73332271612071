import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Snackbar, Button, Typography } from '@mui/material';
import { theme } from '../../theme';
import { Icon, IconTypes } from '../Icon';
import { ToastMessageType, ToastProps } from '../../types';
import { ZINDEX } from '../../constants/styles';

export const AutoHideDuration = 6000;

export interface ExtendedToastProps extends ToastProps {
  type: ToastMessageType;
  isOpen: boolean;
  handleClose: () => void;
}

interface ContentProps {
  type: ToastMessageType;
}
const MESSAGE_TYPES = {
  [ToastMessageType.success]: {
    background: theme.colors.success[10],
    gradient: '',
    iconType: IconTypes.SUCCESS,
    iconColor: theme.colors.success[70],
    title: 'SUCCESS MESSAGE',
  },
  [ToastMessageType.warning]: {
    background: theme.colors.warning[10],
    gradient: 'linear-gradient(270deg, #FFE1B8 0%, #FFCC85 40.62%, #FF9500 82.64%, #D04246 100%)',
    iconType: IconTypes.WARNING,
    iconColor: theme.colors.warning[50],
    title: 'WARNING MESSAGE',
  },
  [ToastMessageType.error]: {
    background: theme.colors.critical[10],
    gradient: 'linear-gradient(270deg, #FFCC85 0%, #E59597 40.62%, #D04246 82.64%, #A4282C 100%)',
    iconType: IconTypes.WARNING,
    iconColor: theme.colors.critical[50],
    title: 'ERROR MESSAGE',
  },
  [ToastMessageType.info]: {
    background: theme.colors.primary[10],
    gradient: 'linear-gradient(270deg, #E7E8FF 0%, #45B8DD 40.62%, #A992EB 82.64%, #565CDE 100%)',
    iconType: IconTypes.SUCCESS,
    iconColor: theme.colors.primary[40],
    title: 'INFO MESSAGE',
  },
};

export const ToastWrapper = styled('div')`
  width: 480px;
  z-index: 1300;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(16, 37, 62, 0.06);
  overflow: hidden;
`;

const ButtonWrapper = styled('div')`
  margin-top: 20px;
`;

const Content = styled('div')<ContentProps>`
  background-color: ${({ type }) => MESSAGE_TYPES[type].background};
  width: 100%;
  padding: 21px 18px 21px 21px;
  display: flex;
`;

const GradientStripe = styled('div')<ContentProps>`
  height: 4px;
  background: ${({ type }) => MESSAGE_TYPES[type].gradient};
`;

const InfoWrapper = styled('div')`
  width: 90%;
  padding-left: 22px;
`;
const MessageWrapper = styled('div')`
  margin: 8px 0 0;
`;
const CloseIconWrapper = styled('div')`
  margin-top: -3px;
`;

const ButtonIconWrapper = styled('div')`
  margin-bottom: -3px;
`;

export const ToastMessage = ({
  type,
  message,
  linkText,
  linkUrl,
  title,
  autoHideDuration = AutoHideDuration,
  buttonLabel,
  buttonAction,
  buttonIconType,
  isOpen,
  handleClose,
}: ExtendedToastProps): ReactElement => {
  const navigate = useNavigate();

  const onLinkClick = () => {
    if (linkUrl) navigate(linkUrl);
  };

  const onButtonClick = () => {
    if (!buttonAction) return;
    buttonAction();
    handleClose();
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ zIndex: ZINDEX.TOAST_MESSAGE }}
    >
      <ToastWrapper>
        <Content type={type}>
          <Icon type={MESSAGE_TYPES[type].iconType} color={MESSAGE_TYPES[type].iconColor} />
          <InfoWrapper>
            <Typography variant='body2' color={theme.colors.primary[100]}>
              {title ? title : MESSAGE_TYPES[type].title}
            </Typography>
            <MessageWrapper>
              <Typography variant='body2' color={'#6D7786'}>
                {message}
              </Typography>
            </MessageWrapper>
            {linkText && (
              <Link component='button' underline='hover' fontSize={'small'} onClick={onLinkClick}>
                {linkText}
              </Link>
            )}
            {buttonLabel && (
              <ButtonWrapper>
                <Button
                  startIcon={
                    buttonIconType ? (
                      <ButtonIconWrapper>
                        <Icon type={buttonIconType} color={'white'} />
                      </ButtonIconWrapper>
                    ) : undefined
                  }
                  onClick={onButtonClick}
                  color='primary'
                  variant={'contained'}
                  size='small'
                  style={{ backgroundColor: '#282E9A', height: '36px' }}
                >
                  {buttonLabel}
                </Button>
              </ButtonWrapper>
            )}
          </InfoWrapper>
          <CloseIconWrapper onClick={handleClose}>
            <Icon type={IconTypes.CLOSE_MENU_1} color={theme.colors.neutral[60]} />
          </CloseIconWrapper>
        </Content>
        {type !== ToastMessageType.success && <GradientStripe type={type}></GradientStripe>}
      </ToastWrapper>
    </Snackbar>
  );
};
