import {
  FormProvider,
  useForm,
  Resolver,
  useFormContext,
  ControllerRenderProps,
  useWatch,
} from 'react-hook-form';
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { Collapse } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { FieldFactory } from '../../../components/Form/FieldFactory';
import {
  CompanySearchResponse,
  createCompanySearchResponse,
} from '../../../schemas/CompanySearchResponse.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { portCosByNameMapState } from '../../../services/state/PortCosState';
import { useFocusCallback, useFocusElement } from '../../../hooks/useFocusElement';
import { ReservesAction } from '../state/FPState';
import { useReservesActions } from './reservesActions';
import {
  AddInvestmentSchema,
  companyField,
  useGetInvestmentSchemaForCompany,
} from './addInvestmentFormUtils';
import { useReservesFormState } from './useReservesFormState';

interface IFutureInvestmentFormProps {
  action: ReservesAction;
  defaultValues?: Partial<AddInvestmentSchema>;
}
export function FutureInvestmentForm({ defaultValues, action }: IFutureInvestmentFormProps) {
  const { loading, loadingAddAnother, methods, onSubmit, resetFormState } = useFutureInvestmentForm({
    defaultValues,
    action,
  });

  return (
    <FormProvider {...methods}>
      <FormFieldsContainer key={String(loadingAddAnother)} style={{ paddingBlock: '0.1rem' }}>
        {!defaultValues?.company && <CompanySelectField />}
        <FutureTransactionFields action={action} />
      </FormFieldsContainer>
      <StickyFormButtons
        onCancel={resetFormState}
        loading={loading}
        onSubmit={() => onSubmit(false)}
        secondaryActionProps={
          action == ReservesAction.EditTransaction
            ? undefined
            : {
                label: 'Submit and Add Another',
                onClick: () => onSubmit(true),
                loading: loadingAddAnother,
              }
        }
      />
    </FormProvider>
  );
}

function CompanySelectField() {
  const { control, setValue } = useFormContext<AddInvestmentSchema>();
  const portcosByName = useRecoilValue(portCosByNameMapState);

  const onChange = useCallback(
    (company: CompanySearchResponse) => {
      setValue('company', company);
    },
    [setValue]
  );

  const companySelectField = {
    ...companyField,
    rendererMeta: {
      ...companyField.rendererMeta,
      onAdd: (companyName: string) => {
        const id = portcosByName.get(companyName)?.id ?? '';
        onChange(
          createCompanySearchResponse({
            id,
            fields: {
              name: companyName,
            },
          } as CompanySearchResponse)
        );
      },
    },
  };

  const company = useWatch({ control, name: 'company' });
  const companySelectFormProps = {
    onChange,
    value: company,
  } as ControllerRenderProps;

  useFocusElement('company-select', 'input');

  return (
    <>
      <div id='company-select'>
        <FieldFactory formField={companySelectField} formProps={companySelectFormProps} />
      </div>
    </>
  );
}

function FutureTransactionFields({ action }: { action: ReservesAction }) {
  const fields = useSyncFieldsWithCompany({ action });
  const focus = useFocusCallback();
  useLayoutEffect(() => {
    if (!fields.length) return;
    focus('transaction-fields', 'input');
  }, [fields.length, focus]);

  return (
    <Collapse in={fields.length > 0} id='transaction-fields'>
      {!fields.length ? (
        <div />
      ) : (
        fields.map((field) => (
          <Fragment key={field.key}>
            <FormFieldWithLabelFactory key={field.key} formField={field} />
            <br />
          </Fragment>
        ))
      )}
    </Collapse>
  );
}

function useGetInvestmentResolver() {
  const getSchema = useGetInvestmentSchemaForCompany();
  const resolver: Resolver<AddInvestmentSchema> = useCallback(
    (values: AddInvestmentSchema, context, options) => {
      const { company } = values;
      const schema = getSchema(company) as ObjectSchema<AddInvestmentSchema>;
      return yupResolver(schema)(values, context, options);
    },
    [getSchema]
  );

  return resolver;
}

function useSyncFieldsWithCompany({ action }: { action: ReservesAction }) {
  const methods = useFormContext<AddInvestmentSchema>();
  const { setValue } = methods;

  const company = useWatch({ control: methods.control, name: 'company' });

  const getSchema = useGetInvestmentSchemaForCompany();

  const fields = useMemo(() => {
    if (!company) return [];
    const schema = getSchema(company);
    return schemaToFormFields(schema, ['amount', 'date']);
  }, [company, getSchema]);

  useEffect(() => {
    if (!company || action == ReservesAction.EditTransaction) return;
    const schema = getSchema(company);
    const { type } = schema.getDefault();
    setValue('type', type);
  }, [company, getSchema, setValue, action]);

  return fields;
}

function useFutureInvestmentForm({ defaultValues, action }: IFutureInvestmentFormProps) {
  const { resetFormState } = useReservesFormState();
  const { addInvestment, editInvestmentTransaction } = useReservesActions();
  const [loading, setLoading] = useState(false);
  const [loadingAddAnother, setLoadingAddAnother] = useState(false);
  const getSchema = useGetInvestmentSchemaForCompany();

  const resolver = useGetInvestmentResolver();
  const methods = useForm<AddInvestmentSchema>({
    defaultValues,
    resolver,
  });

  const onSubmit = useCallback(
    async (addAnother = false) => {
      const isValid = await methods.trigger();
      if (isValid) {
        if (addAnother) {
          setLoadingAddAnother(true);
        } else {
          setLoading(true);
        }
        const { company, ...rest } = methods.getValues();
        let callback;
        switch (action) {
          case ReservesAction.AddInvestment:
          case ReservesAction.AddTransaction:
            callback = addInvestment;
            break;
          case ReservesAction.EditTransaction:
            callback = editInvestmentTransaction;
            break;
        }
        if (!callback) {
          console.error('No callback found for action', action);
          return false;
        }
        await callback({
          company,
          transaction: {
            ...rest,
          },
        });
        if (addAnother) {
          setLoadingAddAnother(false);
          const schema = getSchema(company);
          methods.reset({ ...schema.getDefault(), company } as unknown as AddInvestmentSchema);
        } else {
          setLoading(false);
          resetFormState();
        }
      } else {
        return false;
      }
    },
    [action, addInvestment, editInvestmentTransaction, getSchema, methods, resetFormState]
  );
  return { loading, loadingAddAnother, methods, onSubmit, resetFormState };
}
