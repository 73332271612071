import { FC, useCallback } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { useCustomFieldsActions } from '../CustomFields/hooks/useCustomFieldsActions';
import { IField } from '../../../data-models/field2.data-model';
import { CustomFieldForm } from '../forms/CustomFieldForm';

interface EditCustomFieldProps<T> {
  open: boolean;
  onClose: () => void;
  customFieldValues?: IField<T>;
}

export const EditCustomField: FC<EditCustomFieldProps<unknown>> = ({ open, onClose, customFieldValues }) => {
  const { updateField } = useCustomFieldsActions();

  const onSubmit = useCallback(
    async (data: Partial<IField<unknown>>) => {
      await updateField(data);
      onClose();
    },
    [updateField, onClose]
  );

  if (!customFieldValues) return null;

  return (
    <BasicDialog title={'Edit Custom Field'} open={open} onClose={onClose}>
      <CustomFieldForm initialValues={customFieldValues} editMode onClose={onClose} onSubmit={onSubmit} />
    </BasicDialog>
  );
};
