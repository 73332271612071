import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useFetchDealsForCompany } from '../../CompanyProfiles/Summary/hooks/useFetchDealsForCompany';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { usePeopleData } from '../../CompanyProfiles/hooks/usePeopleData';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { CompanyFundingState } from '../../CompanyProfiles/state/CompanyFundingsState';
import { useCompanyInvestments } from '../../CompanyProfiles/hooks/useCompanyInvestments';
import { notesState } from '../../../services/state/NoteState';

export function useInitCompanyProfile() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const companyMetricsLoadable = useRecoilValueLoadable(companyMetricsByIdState(companyId));
  const companyFundingsLoadable = useRecoilValueLoadable(CompanyFundingState(companyId));
  const notesLoadable = useRecoilValueLoadable(notesState(companyId));

  const { isLoading: investmentsLoading } = useCompanyInvestments(companyId);
  const { isDataLoaded: dealsLoaded } = useFetchDealsForCompany(companyId);
  const { loading: peopleLoading } = usePeopleData();

  return (
    dealsLoaded &&
    !peopleLoading &&
    !investmentsLoading &&
    companyMetricsLoadable.state !== 'loading' &&
    companyFundingsLoadable.state !== 'loading' &&
    notesLoadable.state !== 'loading'
  );
}
