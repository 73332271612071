import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useConnectCompetitiveIntelligenceSeriesMetrics } from '../../../hooks/useConnectCompetitiveIntelligenceSeriesMetrics';
import { HistoryComparisonChart, ISeries } from '../HistoryComparisonChart/HistoryComparisonChart';
import { selectedFieldsCI } from '../../../state/DashboardsState';
import { companiesColoursCI, currentCompaniesCI, selectedCompaniesCI } from '../../../state/CompaniesState';
import { DummyAllField, selectedFilterCI } from '../HistoryComparisonState';
import { DeprecatedGetterFormatType, GetterFormatType } from '../../../../../util/getFormatter';
import { FieldFormatting } from '../../../../../data-models/field.data-model';

interface IHistoryComparisonData {
  title: string;
  formatting?: FieldFormatting;
  type: GetterFormatType | DeprecatedGetterFormatType;
  series: ISeries[];
}

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, 24%);
  gap: 1rem;
  width: 100%;
`;

export function HistoryComparisonChartList() {
  const selectedFields = useRecoilValue(selectedFieldsCI);
  const companies = useRecoilValue(currentCompaniesCI);
  const companyColours = useRecoilValue(companiesColoursCI);
  const selectedFilter = useRecoilValue(selectedFilterCI);
  const [chartsData, setChartsData] = useState<IHistoryComparisonData[]>([]);
  const seriesData = useConnectCompetitiveIntelligenceSeriesMetrics();
  const activeCompanies = useRecoilValue(selectedCompaniesCI);

  useEffect(() => {
    if (!seriesData?.data) return;
    const selectedSeriesFields = selectedFields.filter((field) => field.series);
    const computedChartsData: IHistoryComparisonData[] = selectedSeriesFields.map((field) => {
      const { id, displayName, formatting, type } = field;

      const chartSeries = seriesData.data.reduce((res, data) => {
        if (data.fieldId === id) {
          if (!Array.isArray(data.value)) {
            console.warn(
              '[HistoryComparisonChartList] Unexpected non array value returned: ',
              seriesData.data
            );

            return res;
          }

          const company = companies.find((company) => company.id === data.companyId);
          const companyName = company?.name ?? data.companyId.toString();
          const colour = companyColours.get(company?.id ?? -1) ?? 'grey';
          res.push({
            name: companyName,
            type: 'line',
            data: data.value.map((value) => [new Date(value[0]).getTime(), value[1]]),
            itemStyle: { color: colour },
          });
        }
        return res.filter(({ name }) => activeCompanies.find((company) => company.name === name));
      }, [] as ISeries[]);

      return {
        formatting,
        type: type,
        series: chartSeries,
        title: displayName,
      };
    });

    if (selectedFilter !== DummyAllField) {
      const filteredChartData = computedChartsData.filter(
        ({ title }) => title === selectedFilter.displayName
      );
      setChartsData(filteredChartData);
      return;
    }
    setChartsData(computedChartsData);
  }, [activeCompanies, companies, companyColours, selectedFields, selectedFilter, seriesData?.data]);

  if (chartsData.length === 0) return null;

  return (
    <Wrapper>
      {chartsData.map(({ formatting, type, title, series }) => (
        <HistoryComparisonChart
          series={series}
          title={title}
          id={title}
          formatting={formatting}
          type={type}
          key={title}
          status={seriesData?.status}
        />
      ))}
    </Wrapper>
  );
}
