import { useCallback, useEffect, useState } from 'react';
import { MenuItem, Select, Stack, TextField } from '@mui/material';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { FormField, Label } from '../../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { Formatting } from '../../../../../types';
import { createKPIField } from '../../KPITemplateFactory';
import { useKPIFieldActions } from '../../../hooks/useKPIFieldActions';

export interface ICreateKPIModelProps {
  initialName: string;
  onClose: () => void;
  open: boolean;
}

export function CreateKPIModal(props: ICreateKPIModelProps) {
  const { addKPI } = useKPIFieldActions();
  const { initialName, onClose, open } = props;
  const [metricName, setNewMetricName] = useState('');
  const [unit, setUnit] = useState(Formatting.Currency);

  const handleSubmit = useCallback(async () => {
    if (metricName === '') {
      return;
    }

    await addKPI(createKPIField(metricName, unit));

    onClose();
  }, [addKPI, metricName, onClose, unit]);

  useEffect(() => {
    setNewMetricName(initialName);
  }, [initialName]);

  return (
    <BasicDialog open={open} onClose={onClose} title={'Create KPI'}>
      <Stack direction={'column'} spacing={4} sx={{ width: '696px', px: '2.5rem' }}>
        <FormField>
          <Label required>Name</Label>
          <TextField value={metricName} onChange={(e) => setNewMetricName(e.target.value ?? '')} />
        </FormField>

        <FormField>
          <Label required>Unit</Label>
          <Select
            labelId='kpi-unit-label'
            value={unit}
            title={'unit'}
            size={'small'}
            onChange={(event) => setUnit(event.target.value as Formatting)}
          >
            <MenuItem value={Formatting.Currency}>currency ($)</MenuItem>
            <MenuItem value={Formatting.Percent}>percent (%)</MenuItem>
            <MenuItem value={Formatting.Numeric}>numeric (#)</MenuItem>
            <MenuItem value={Formatting.Multiplier}>multiplier (X)</MenuItem>
          </Select>
        </FormField>
      </Stack>

      <StickyFormButtons onSubmit={handleSubmit} onCancel={onClose} />
    </BasicDialog>
  );
}
