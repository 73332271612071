import { MaggieHttp } from '../MaggieHttp';
import {
  ICreateNoteResponsePayload,
  IGetNotesRawResponse,
  INoteDataModel,
  INoteDraftDataModel,
  INotePayloadDataModel,
} from '../../data-models/note.data-model';

export async function getAllNotesForCompany(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<IGetNotesRawResponse>(`/note`, {
    params: {
      companyId,
    },
  });
  const notes: INoteDataModel<unknown>[] = data.notes.map((note) => {
    const parsedBody = JSON.parse(note.body);
    return { ...note, body: parsedBody };
  });
  return { notes, companies: data.companies };
}

export async function createNote<T>(note: INoteDraftDataModel<T>): Promise<ICreateNoteResponsePayload<T>> {
  const stringifiedBody = JSON.stringify(note.body);
  const { data } = await MaggieHttp.maggie.post<INotePayloadDataModel, ICreateNoteResponsePayload<T>>(
    `/note`,
    {
      ...note,
      body: stringifiedBody,
    }
  );
  const noteData: INoteDataModel<T> = { ...data.note, body: note.body };
  return { ...data, note: noteData };
}

export async function updateNote<T>(note: INoteDataModel<T>) {
  const stringifiedBody = JSON.stringify(note.body);
  const { data } = await MaggieHttp.maggie.put<INotePayloadDataModel, ICreateNoteResponsePayload<T>>(
    `/note/${note.id}`,
    { ...note, body: stringifiedBody }
  );

  const noteData: INoteDataModel<T> = { ...note, ...data.note, body: note.body };
  return { ...data, note: noteData };
}

export async function deleteNote(noteId: number) {
  const { data } = await MaggieHttp.maggie.delete(`/note/${noteId}`);

  return data;
}
