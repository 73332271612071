import { useCallback, useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { cardStyles } from '../../../../../theme/component-styles';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { FMT } from '../../../../../util/formatter-service';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { createFormField } from '../../../../../view-models/form.view-model';
import { RendererType } from '../../../../../data-models/field.data-model';

interface IConfirmRejectProps {
  company: ICompanyDataModel;
  request: IKPIRequestDataModel;
  onCancel: () => void;
  onConfirm: (observation?: string) => Promise<void>;
}
export function ConfirmReject({ request, company, onCancel, onConfirm }: IConfirmRejectProps) {
  const [loading, setLoading] = useState(false);
  const [observation, setObservation] = useState<string | undefined>(undefined);

  const _onConfirm = useCallback(async () => {
    setLoading(true);
    await onConfirm(observation);
    setLoading(false);
  }, [observation, onConfirm]);
  return (
    <BasicDialog title={'Reject Response?'} open onClose={onCancel}>
      <Stack p={'1rem 2.5rem 0'} gap={'0.5rem'}>
        <Card
          component={Stack}
          style={cardStyles}
          display={'grid'}
          gridTemplateColumns={'1fr auto 1fr'}
          alignItems={'center'}
        >
          <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
          <Typography variant='body2' noWrap sx={{ justifySelf: 'center' }}>
            {FMT.formatKPIPeriod(request?.period, {
              frequency: request.frequency,
              showYear: true,
              yearFormat: 'yyyy',
            })}
          </Typography>
          <Typography variant='body2' noWrap sx={{ justifySelf: 'end' }}>
            {FMT.format('stringArray', company?.respondents)}
          </Typography>
        </Card>
        <InfoBox message='Rejection email will be automatically sent to the respondent. You can add an additional message with your feedback.' />
        <ObservationField value={observation} onChange={setObservation} />
      </Stack>

      <StickyFormButtons onCancel={onCancel} onSubmit={_onConfirm} loading={loading} submitLabel='Reject' />
    </BasicDialog>
  );
}

interface IObservationFieldProps {
  value?: string;
  onChange: (value: string) => void;
}
function ObservationField({ value, onChange }: IObservationFieldProps) {
  const formField = createFormField({
    key: 'observation',
    label: '',
    renderer: RendererType.text,
    rendererMeta: {
      multiline: true,
      rows: 4,
      placeholder: 'Add your comment here',
    },
  });
  const formProps = {
    onChange: (e) => {
      onChange(e.target.value);
    },
    value,
  } as ControllerRenderProps;

  return <FieldFactory formField={formField} formProps={formProps} />;
}
