import { styled } from '@mui/material';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { NavbarBadge } from './NavbarBadge';
import { SubNavItem } from './components/SubNavItem/SubNavItem';

export const SubNavSectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export type NavItemType = {
  id: ROUTES | string;
  value: string;
  badgeIndicator?: number;
  RightSlot?: React.ReactNode;
};

type SubSectionNavItemsProps = {
  sectionList: NavItemType[];
  parentRoute?: ROUTES | string;
  onClickActionButton?: (section: Partial<NavItemType>) => void;
};

export default function SubSectionNavItems({ sectionList, parentRoute }: SubSectionNavItemsProps) {
  return (
    <SubNavSectionWrapper>
      {sectionList.map((section) => (
        <SubNavItem
          key={section.id}
          section={section}
          parentRoute={parentRoute}
          BadgeSlot={section.badgeIndicator ? <NavbarBadge badgeContent={section.badgeIndicator} /> : null}
        />
      ))}
    </SubNavSectionWrapper>
  );
}
