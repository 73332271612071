import { theme } from '../../theme';

const { primary, secondary, tertiary, neutral, warning, success } = theme.colors;

export const generateChartColourScheme = (length: number): string[] =>
  length > 7
    ? [
        primary[70],
        primary[50],
        primary[20],
        secondary[60],
        secondary[40],
        secondary[20],
        tertiary[60],
        tertiary[50],
        tertiary[20],
        success[50],
        warning[40],
        neutral[40],
      ]
    : [primary[70], primary[50], secondary[30], warning[40], success[50], tertiary[50], neutral[40]];
