import { cloneDeep } from 'lodash-es';
import { useRecoilCallback } from 'recoil';
import { ICompanyMilestoneDataMode } from '../../../data-models/company-milestone.data-model';
import {
  createCompanyMilestone,
  deleteMilestone,
  editMilestone,
} from '../../../services/queries/MaggieMilestonesQueries';
import { milestonesByCompanyState } from '../state/MilestonesState';

// errors should be handled in the component

export const useMilestoneActions = () => {
  const handleCreateMilestone = useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<ICompanyMilestoneDataMode>) => {
        if (!payload.companyId) {
          throw new Error('Company ID is required to create a milestone');
        }

        const res = await createCompanyMilestone(payload);
        set(milestonesByCompanyState(payload.companyId), (prevMilestones) => {
          if (!prevMilestones) return [res];
          return [...cloneDeep(prevMilestones), res];
        });
        return res;
      }
  );

  const handleEditMilestone = useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<ICompanyMilestoneDataMode>) => {
        if (!payload.companyId || !payload.id) {
          throw new Error('companyId and id are required to edit a milestone');
        }

        const res = await editMilestone(payload.id, payload);
        set(milestonesByCompanyState(payload.companyId), (prevMilestones) => {
          if (!prevMilestones) return null;
          return prevMilestones.map((ms) => {
            if (ms.id === payload.id) return res;
            else return ms;
          });
        });
        return res;
      }
  );

  const handleDeleteMilestone = useRecoilCallback(({ set }) => async (companyId: number, id: number) => {
    await deleteMilestone(id);
    set(milestonesByCompanyState(companyId), (prevMilestones) => {
      if (!prevMilestones) return null;
      return prevMilestones.filter((ms) => ms.id !== id);
    });
  });

  return {
    handleCreateMilestone,
    handleEditMilestone,
    handleDeleteMilestone,
  };
};
