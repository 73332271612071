import {
  createFormatterDataModel,
  IFormatterDataModel,
  IStringFormatConfig,
} from '../../data-models/formatter.data-model';
import { FormatterFn } from '../formatter-service';

export enum StringTransform {
  capitalize = 'capitalize',
}

export const StringTransforms: Record<string, FormatterFn<string>> = {
  capitalize: (value: string) => {
    return value[0].toUpperCase() + value.slice(1);
  },
};

export enum StringFormatterId {
  capitalizedString = 'capitalizedString',
  string = 'string',
}

export const StringFormattersConfig: Record<StringFormatterId, IFormatterDataModel<IStringFormatConfig>> = {
  capitalizedString: createFormatterDataModel<IStringFormatConfig>({
    id: 'capitalizedString',
    type: 'string',
    config: {
      transforms: [StringTransform.capitalize],
    },
  }),
  string: createFormatterDataModel({
    id: 'string',
    type: 'string',
  }),
};

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatStringArray(value: string[]) {
  return value.join(', ');
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function capitalizeString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
