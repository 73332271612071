import { TextField, Typography, useTheme } from '@mui/material';
import { useFormContext, useFormState } from 'react-hook-form';
import { get } from 'lodash-es';
import { IRendererConfig, ITextFieldConfig } from '../../../../../data-models/field2.data-model';

interface IParagraphFieldProps {
  responseFormRef?: string;
  renderer: IRendererConfig<ITextFieldConfig>;
}

const ParagraphFieldPlaceHolder = 'Your answer';

export function ParagraphField({ renderer }: IParagraphFieldProps) {
  const { maxLength } = renderer.config;
  const noLimits = maxLength === -1;

  return (
    <>
      <TextField placeholder={ParagraphFieldPlaceHolder} />
      <Typography variant='body2'>{noLimits ? 'No limits' : `${maxLength}/${maxLength}`}</Typography>
    </>
  );
}

export function EditableParagraphField({ renderer, responseFormRef = '' }: IParagraphFieldProps) {
  const { colors } = useTheme();
  const { register, watch } = useFormContext();
  const { errors } = useFormState();
  const { maxLength } = renderer.config;
  const noLimits = maxLength === -1;
  const errorPointer = get(errors, responseFormRef, null);
  const hasError = Boolean(errorPointer);

  const currentCharacters = watch(responseFormRef)?.length ?? 0;

  return (
    <>
      <TextField
        {...register(responseFormRef, {
          maxLength: noLimits ? undefined : (maxLength as number),
        })}
        fullWidth
        error={hasError}
        multiline
        placeholder={ParagraphFieldPlaceHolder}
        minRows={4}
        maxRows={10}
        inputProps={{
          maxLength: noLimits ? undefined : (maxLength as number),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              border: '1px solid black',
            },
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
        }}
      />
      {!noLimits && (
        <Typography
          variant='body2'
          sx={{
            color: hasError ? colors.critical[50] : colors.neutral[60],
          }}
        >{`${currentCharacters}/${maxLength}`}</Typography>
      )}
    </>
  );
}
