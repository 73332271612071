import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { BasicDialog } from '../../components/Dialog/BasicDialog';
import { useMergeCompanies } from '../../services/hooks/useMergeCompanies';
import { duplicateErrorState, mappingsActionState } from './state/MappingsUIState';
import { MappingsEventType } from './MappingsGrid/mappingsGridEvents';
import { PrimaryWebsiteForm } from './forms/PrimaryWebsiteForm';
import { SecondaryWebsitesForm } from './forms/SecondaryWebsitesForm';
import { DuplicateError } from './forms/DuplicateError';
import { VendorIdsForm } from './forms/VendorIdsForm';

export const MappingsFormDialog: FC = () => {
  const [currentAction, setCurrentAction] = useRecoilState(mappingsActionState);
  const [loading, setLoading] = useState(false);

  const [duplicateError, setDuplicateError] = useRecoilState(duplicateErrorState);
  const mergeCompanies = useMergeCompanies();

  const handleFormClose = useCallback(() => {
    setCurrentAction(null);
  }, [setCurrentAction]);

  const handleErrorClose = useCallback(() => {
    setDuplicateError(null);
  }, [setDuplicateError]);

  const duplicateErrorMessage = useMemo(() => {
    if (!duplicateError) return null;
    return `${duplicateError.value} (${duplicateError.key} ${
      duplicateError.action === MappingsEventType.editVendorIds ? 'id' : 'website'
    })`;
  }, [duplicateError]);

  const handleMerge = useCallback(async () => {
    if (!duplicateError) return;
    setLoading(true);
    await mergeCompanies({ id: duplicateError.id, intoId: duplicateError.intoId });
    setLoading(false);
    setDuplicateError(null);
  }, [duplicateError, mergeCompanies, setDuplicateError]);

  const { title, content }: { title: string; content: ReactNode } = useMemo(() => {
    if (!currentAction || !currentAction.data) return { title: '', content: null };

    let vendorName =
      currentAction.type === MappingsEventType.editVendorIds ? currentAction.data.provider : '';
    if (vendorName) vendorName = vendorName.charAt(0).toUpperCase() + vendorName.slice(1);

    switch (currentAction.type) {
      case MappingsEventType.editPrimary:
        return {
          title: 'Primary Website',
          content: <PrimaryWebsiteForm data={currentAction.data} onCancel={handleFormClose} />,
        };
      case MappingsEventType.editSecondary:
        return {
          title: 'Secondary Websites',
          content: <SecondaryWebsitesForm data={currentAction.data} onCancel={handleFormClose} />,
        };
      case MappingsEventType.editVendorIds:
        return {
          title: `${vendorName} Vendor IDs`,
          content: <VendorIdsForm data={currentAction.data} onCancel={handleFormClose} />,
        };
      default:
        return { title: '', content: null };
    }
  }, [currentAction, handleFormClose]);

  if (!duplicateError && (currentAction === null || !content)) return null;

  return (
    <>
      <BasicDialog open={true} onClose={handleFormClose} title={title}>
        {content}
      </BasicDialog>
      {duplicateError && (
        <DuplicateError
          open={true}
          companyId={duplicateError!.intoId}
          error={duplicateErrorMessage}
          onMerge={handleMerge}
          isLoading={loading}
          onCancel={handleErrorClose}
          onClose={handleErrorClose}
          additionalInfo={
            <>
              <p>Merging the companies will combine all vendor mappings into one single company.</p>
              <p>The duplicate will be removed.</p>
            </>
          }
          action={duplicateError.action}
        />
      )}
    </>
  );
};
