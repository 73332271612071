import { atomFamily, selectorFamily } from 'recoil';
import { IDealConversionResponse } from '../../../data-models/deal-conversion.data-model';
import { fetchDealConversion, fetchDealStatsHistory } from '../../../services/queries/MaggieDealQueries';
import { IDealStatsDataModel } from '../../../data-models/deal-stats.data-model';
import { cleanQueryParams } from './../utils/cleanQueryParams';
import { GroupByOptions } from './DealConversionFilterState';

export type IDealConversionStateKey = {
  to: string;
  from: string;
  sectors?: number[];
  dealLead?: number;
  dealType?: number;
  groupBy?: GroupByOptions;
};

export const DealConversionState = atomFamily<IDealConversionResponse | null, IDealConversionStateKey>({
  key: 'DealConversionState',
  default: null,
  effects: (keys) => [
    ({ setSelf, trigger, resetSelf }) => {
      if (trigger === 'get') {
        const params = cleanQueryParams(keys);

        fetchDealConversion(params)
          .then((data) => {
            setSelf(data);
          })
          .catch((error) => {
            console.error(error);
            resetSelf();
          });
      }
    },
  ],
});

export const dealsStatsHistory = selectorFamily<IDealStatsDataModel[], IDealConversionStateKey>({
  key: 'dealsStatsHistory',
  get: (keys) => () => {
    const params = cleanQueryParams(keys);

    return fetchDealStatsHistory(params);
  },
});
