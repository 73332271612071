// FIXME: Remove istanbul and add test + https://foresightdata.atlassian.net/browse/MAGGIE-4306
import { useRecoilValueLoadable } from 'recoil';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { ROUTES } from '../../../../../constants/RouteNameMapping';

import { createKPITemplateDataModel, IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { ConfirmDeletionDialog } from '../../../../Dialog/ConfirmDialog/ConfirmDeletionDialog';

import PlusIconButton from '../../../../IconButton/PlusIconButton';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import { kpiTemplatesListState } from '../../../../../services/state/KPI/KPITemplatesState';
import { useKPITemplateActions } from '../../../../../pages/KPI/hooks/useKPITemplateActions';
import KPITemplatesNavItems from './KPITemplatesNavItems';

export const KPITemplatesSubNavigation = () => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isNewTemplate = useMatch(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}/new`);

  const templateListValues = useRecoilValueLoadable(kpiTemplatesListState).valueMaybe();
  const { deleteKPITemplateAction, createKPITemplateAction } = useKPITemplateActions();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const selectedSection = useMemo(() => pathname.split('/')?.pop(), [pathname]);

  const handleNavigateNewTemplate = useCallback(() => {
    navigate(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}/new`);
  }, [navigate]);

  const templatesListWithNewOne = useMemo(() => {
    if (!templateListValues) return null;
    const newTemplate = isNewTemplate ? createKPITemplateDataModel({ id: -1, name: 'New Template' }) : null;

    const templates = isNewTemplate ? [newTemplate, ...templateListValues] : templateListValues;

    return templates;
  }, [isNewTemplate, templateListValues]);

  const selectedTemplateSection = useMemo(() => {
    if (!selectedSection) return null;
    const template = templatesListWithNewOne?.find((t) => String(t?.id) === selectedSection);
    return template;
  }, [selectedSection, templatesListWithNewOne]);

  const onDuplicateTemplate = useCallback(async () => {
    if (!selectedTemplateSection) return;

    const kpiTemplate = await createKPITemplateAction({
      ...selectedTemplateSection,
      name: `${selectedTemplateSection.name} (Copy)`,
    });

    if (!kpiTemplate?.id) return;

    return navigate(`/${ROUTES.KPI_TEMPLATES_FULL_ROUTE}/${kpiTemplate.id}`);
  }, [createKPITemplateAction, navigate, selectedTemplateSection]);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOnDeleteTemplate = useCallback(async () => {
    if (!selectedTemplateSection) return;
    await deleteKPITemplateAction({
      id: selectedTemplateSection.id,
      name: selectedTemplateSection.name,
    });
    handleCloseDeleteModal();
    handleNavigateNewTemplate();
  }, [deleteKPITemplateAction, handleNavigateNewTemplate, selectedTemplateSection]);

  if (!templatesListWithNewOne) return null;

  return (
    <>
      <ConfirmDeletionDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleOnDeleteTemplate}
        title={`Delete template "${selectedTemplateSection?.name}"?`}
      >
        <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem', minWidth: '30rem' }}>
          The template will be deleted permanently
        </Typography>
      </ConfirmDeletionDialog>
      <SubSectionNavigation
        title='Templates'
        RightSlot={
          <PlusIconButton sx={{ color: colors.secondary[20] }} onClick={handleNavigateNewTemplate}>
            Add New
          </PlusIconButton>
        }
      />
      <KPITemplatesNavItems
        selectedSection={selectedSection ?? ''}
        sectionList={templatesListWithNewOne as IKPITemplate[]}
        parentRoute={ROUTES.KPI_TEMPLATES_FULL_ROUTE}
        onOpenConfirmDeleteModal={() => {
          setOpenDeleteModal(true);
        }}
        onDuplicateTemplate={onDuplicateTemplate}
      />
    </>
  );
};
