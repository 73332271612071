import { FC } from 'react';
import { useNumberOfRoundsOverTime } from '../../hooks/useAmountOfDealsOverTime';
import { CHART_COMPONENTS } from '../../../../types';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const NumberOfRoundsOverTimeChart: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const data = useNumberOfRoundsOverTime(props.filteredMetrics);

  return (
    <LineChart
      data={data}
      id={CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME}
      showLegend={false}
      title={'Participating Rounds'}
      width={640}
      yAxisLabelFormatter={(value) => value as string}
    />
  );
};
