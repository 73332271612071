import { CSSProperties, FC } from 'react';
import { LinkRenderer } from './LinkRenderer';

interface ICompanyLinkRendererProps {
  domain: string | null;
  website: string | null;
  style?: CSSProperties;
}

export const CompanyLinkRenderer: FC<ICompanyLinkRendererProps> = (params) => {
  const { domain, website, style } = params;
  return <LinkRenderer value={website} textContent={domain || undefined} style={style} />;
};
