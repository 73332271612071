import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { ListActionType, IListItemDataModel } from '../../../data-models/list-item.data-model';

export function isItemHidden(
  item: IListItemDataModel,
  company?: ICompanyDataModel | null,
  currentUserId?: number
) {
  return (
    item.actionType === 'hidden' ||
    item.actionType === 'pipeline' ||
    Boolean(company && currentUserId && isFollowedByAny(company))
  );
}

export function isFollowedByUser(company: ICompanyDataModel | null, currentUserId: number) {
  return (Boolean(company) && company!.followers?.includes(currentUserId)) ?? false;
}

export function isFollowedByAny(company: ICompanyDataModel) {
  return Boolean(company.followers?.length);
}

export function compareItems(
  item1: IListItemDataModel,
  item2: IListItemDataModel,
  company1?: ICompanyDataModel | null,
  company2?: ICompanyDataModel | null,
  currentUserId?: number
) {
  if (isItemHidden(item1, company1, currentUserId) && !isItemHidden(item2, company2, currentUserId)) return 1;
  if (!isItemHidden(item1, company1, currentUserId) && isItemHidden(item2, company2, currentUserId))
    return -1;

  const d1 = new Date(item1.updatedAt).getTime();
  const d2 = new Date(item2.updatedAt).getTime();

  return d2 - d1;
}

export type ListItemAction = ListActionType | 'followedByUser' | 'followedByOthers' | null;

export function getAllItemActionsByPriority(
  item: IListItemDataModel,
  company: ICompanyDataModel,
  currentUserId: number
): ListItemAction[] {
  const action = item.actionType;
  const result: ListItemAction[] = [];
  if (action === 'hidden' || action === 'pipeline') {
    result.push(action);
  }
  if (isFollowedByUser(company, currentUserId)) result.push('followedByUser');
  if (isFollowedByAny(company)) result.push('followedByOthers');
  return result;
}
