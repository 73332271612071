import { useCallback } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { EmptySection } from '../../../components/EmptySection';
import { useRubricConfigFormData } from './useRubricConfigFormData';

export function NoRubricsMessage() {
  const { setSelectedAction } = useRubricConfigFormData();
  const handleClick = useCallback(() => {
    setSelectedAction('create');
  }, [setSelectedAction]);
  return (
    <EmptySection
      title='No Rubrics Created'
      message={`You can create firm-wide rubrics.
   Those will be your unique vectors to measure company performance against.`}
      actionComponent={
        <Button
          size='medium'
          variant='contained'
          color='secondary'
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Create Rubric
        </Button>
      }
    />
  );
}
