import { selector } from 'recoil';
import { ColourSchemeGenerator, ItemsColourScheme } from '../../util/ColourSchemeGenerator';
import { sectorsState } from './AppConfigState';

export const sectorsColourState = selector<ItemsColourScheme<string>>({
  key: 'sectorsColourState',
  get: ({ get }) => {
    const sectors = get(sectorsState);
    const sectorNames = sectors.map((sector) => sector.displayName);

    return ColourSchemeGenerator.getInstance().generateScheme<string>('sectorColours', sectorNames);
  },
});
