import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getCapTablesForCompany } from '../../../services/queries/MaggieCapTableQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import { fetchScenariosForCompany } from '../../../services/queries/MaggieScenarioQueries';
import { scenariosByCompanyIdState } from '../state/ScenariosState';
import {
  captablesByCompanyState,
  primaryOrDefaultCaptableState,
  selectedCaptableIdState,
} from './CapTableDataState';

/**
 *  BE does more than just creating/updating single captables,
 *  e.g. on a PUT request it will create a new captable with a different ID,
 * and might also make changes to other captables,
 * so the safest option is to invalidate all on every action;
 */

export function useInvalidateCaptables() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (companyId: number, selectedCaptableId?: number) => {
        try {
          const captables = await getCapTablesForCompany(companyId);
          set(captablesByCompanyState(companyId), captables);
          let selected;
          if (selectedCaptableId != null) {
            selected = captables.find((c) => c.id === selectedCaptableId);
          }
          if (!selected)
            selected = snapshot.getLoadable(primaryOrDefaultCaptableState(companyId)).valueOrThrow();
          set(selectedCaptableIdState(companyId), selected?.id ?? null);

          const scenarios = await fetchScenariosForCompany(companyId);
          set(scenariosByCompanyIdState(companyId), scenarios);
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Failed to refresh cap tables');
          pushErrorToast({ message });
        }
      },
    []
  );
}
