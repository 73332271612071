import { FC, Fragment, PropsWithChildren, useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { MaxInputWidth } from '../../components/commonStyledComponents';
import { PartialExitBreakdown } from '../../../../../schemas/Scenario.schema';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { createInitialPartialExitBreakdownValues } from './scenarioInitialValues';
import { PartialSaleType } from './PartialSaleToggle';
import { AddButton, RemoveButton } from './FormButtons';
import { PartialBreakdownElem } from './PartialBreakdownElem';

export interface IPartialScenario {
  scenarioTransactions: { partialExitBreakdown: Partial<PartialExitBreakdown>[] }[];
}

interface IPartialSaleFieldsProps {
  transactionIndex: number;
  captable: ICaptableDataModel;
  type: PartialSaleType;
}

export const PartialSaleFields: FC<IPartialSaleFieldsProps> = ({ transactionIndex, captable, type }) => {
  const { control, formState, getValues, setValue } = useFormContext<IPartialScenario>();
  const errors = formState.errors?.scenarioTransactions?.[transactionIndex]?.partialExitBreakdown;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `scenarioTransactions.${transactionIndex}.partialExitBreakdown`,
  });

  useEffect(() => {
    if (getValues(`scenarioTransactions.${transactionIndex}.partialExitBreakdown`)?.length !== 0) return;
    setValue(`scenarioTransactions.${transactionIndex}.partialExitBreakdown`, [
      createInitialPartialExitBreakdownValues(),
    ]);
    return () => {
      setValue(`scenarioTransactions.${transactionIndex}.partialExitBreakdown`, []);
    };
  }, [getValues, setValue, transactionIndex]);

  const onAddShareClass = useCallback(() => {
    append(createInitialPartialExitBreakdownValues());
  }, [append]);

  const onRemoveShareClass = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <FieldContainer nFields={type === PartialSaleType.aggregated ? 3 : 4}>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <PartialBreakdownElem
              captable={captable}
              transactionIndex={transactionIndex}
              breakdownIndex={index}
              type={type}
            />
            {index > 0 ? (
              <RemoveButton
                onClick={() => {
                  onRemoveShareClass(index);
                }}
                disabled={fields.length === 1}
                style={{ alignSelf: 'start', marginTop: '1.5rem' }}
              />
            ) : (
              <div />
            )}
          </Fragment>
        );
      })}

      <div style={{ gridColumn: '1 / -1' }}>
        {<AddButton onClick={onAddShareClass}>Add Share Class</AddButton>}
        {errors && (
          <Typography color='error' variant='caption' component={'div'}>
            {errors.message}
          </Typography>
        )}
      </div>
    </FieldContainer>
  );
};

interface IFieldContainerProps extends PropsWithChildren {
  nFields: number;
}
const FieldContainer: FC<IFieldContainerProps> = ({ children, nFields }) => {
  return (
    <Stack
      display='grid'
      gridTemplateColumns={`repeat(${nFields}, ${MaxInputWidth}) auto`}
      gap='1rem'
      justifyContent={'start'}
      alignItems={'start'}
      style={{ gridColumn: '1 / -1' }}
    >
      {children}
    </Stack>
  );
};
