import { useEffect, useRef } from 'react';
import { atom, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { startOfDay, sub as subDate } from 'date-fns';
import { CompetitiveIntelligenceSeriesItem } from '../../../data-models/competitive-intelligence.data-model';
import { ISocketResponseCompIntelData } from '../../../data-models/socket-comp-intel.data-model';
import { LoadingStatus } from '../../../types';
import { currentCompaniesCI } from '../state/CompaniesState';
import { selectedFieldsCI, timeRange } from '../state/DashboardsState';
import { getSeries } from '../../../services/queries/CompetitiveIntelligenceSocketQueries';
import { usePreviousValue } from '../../../hooks/usePreviousValue';

export interface CompetitiveIntelligenceSeriesMetrics {
  data: CompetitiveIntelligenceSeriesItem[];
  status: LoadingStatus;
}

export const competitiveIntelligenceSeriesMetrics = atom<CompetitiveIntelligenceSeriesMetrics>({
  key: 'competitiveIntelligenceSeriesMetrics2',
  default: {
    data: [],
    status: LoadingStatus.idle,
  },
});

export function useConnectCompetitiveIntelligenceSeriesMetrics() {
  const companies = useRecoilValueLoadable(currentCompaniesCI).valueMaybe();
  const selectedFields = useRecoilValueLoadable(selectedFieldsCI).valueMaybe();

  const previousSelectedFields = usePreviousValue(selectedFields);
  const socketQueryId = useRef<string>('');
  const ciSeriesMetricsLoadable = useRecoilValueLoadable(competitiveIntelligenceSeriesMetrics);
  const setSeriesCIMetrics = useSetRecoilState(competitiveIntelligenceSeriesMetrics);
  const selectedTimeRangeLoadable = useRecoilValueLoadable(timeRange);
  const selectedTimeRange = selectedTimeRangeLoadable.valueMaybe();

  useEffect(() => {
    if (!companies || !selectedFields || !selectedTimeRange) return;
    const isDeselect = previousSelectedFields ? selectedFields.length < previousSelectedFields.length : false;
    if (isDeselect) {
      return;
    }
    if (selectedFields.length === 0 || companies.length === 0) {
      setSeriesCIMetrics({
        data: [],
        status: LoadingStatus.success,
      });

      return;
    }

    const selectedSeriesFieldsIds = selectedFields.filter((f) => f.series).map((field) => field.id);

    if (selectedSeriesFieldsIds.length === 0) {
      setSeriesCIMetrics(() => {
        return {
          data: [],
          status: LoadingStatus.success,
        };
      });

      return;
    }

    setSeriesCIMetrics((current) => {
      return {
        data: current?.data ?? [],
        status: LoadingStatus.loading,
      };
    });

    const companiesId = companies.map((company) => company.id);
    const toDate = startOfDay(new Date());
    const fromDate = startOfDay(
      subDate(toDate, {
        months: selectedTimeRange,
      })
    );

    socketQueryId.current = self.crypto.randomUUID();
    getSeries(companiesId, selectedSeriesFieldsIds, fromDate, toDate).subscribe({
      next: (response: ISocketResponseCompIntelData<CompetitiveIntelligenceSeriesItem>) => {
        setSeriesCIMetrics((current) => {
          const newData = current.data.filter((currentSeries) => {
            return !response.data.some((newSeries) => {
              return (
                newSeries.companyId === currentSeries.companyId && newSeries.fieldId === currentSeries.fieldId
              );
            });
          });

          return {
            data: newData.concat(response.data),
            status: LoadingStatus.loading,
          };
        });
      },
      complete: () => {
        setSeriesCIMetrics((current) => {
          return {
            data: current?.data ?? [],
            status: LoadingStatus.success,
          };
        });
        socketQueryId.current = '';
      },
    });
  }, [companies, selectedFields, setSeriesCIMetrics, selectedTimeRange, previousSelectedFields]);

  return ciSeriesMetricsLoadable.valueMaybe();
}
