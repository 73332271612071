import { Autocomplete, SxProps, TextField } from '@mui/material';
import { throttle } from 'lodash-es';
import { FC, FocusEventHandler, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { searchCompanies } from '../../services/queries/MaggieCompanyQueries';
import { ICompanySearchResponseDataModel } from '../../data-models/company-search-result.data-model';

const Wrapper = styled('div')`
  .MuiPaper-root .MuiAutocomplete-listbox .MuiAutocomplete-option {
    margin: 8px 8px 0;
    font-size: 14px;
  }
`;

interface Props {
  value?: string;
  onChange: (value: string | null) => void;
  onBlur?: FocusEventHandler;
  onFocus?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  autofocus?: boolean;
  searchFunction?: (searchText: string) => Promise<ICompanySearchResponseDataModel[]>;
  fieldName?: string;
  className?: string;
  disabled?: boolean;
  sx?: SxProps;
}

export const CompanyAutocomplete: FC<Props> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  placeholder,
  autofocus,
  searchFunction,
  fieldName,
  className,
  disabled,
  sx,
}) => {
  const [options, setOptions] = useState<string[]>([]);

  const fetch = useMemo(
    () =>
      throttle(
        async (input: string, callback: (results?: readonly ICompanySearchResponseDataModel[]) => void) => {
          const searchFunc = searchFunction ?? searchCompanies;
          const results = await searchFunc(input);
          callback(results);
        },
        200
      ),
    [searchFunction]
  );

  useEffect(() => {
    let active = true;
    if (!value) {
      setOptions([]);
      return;
    }
    fetch(value, (results?: readonly ICompanySearchResponseDataModel[]) => {
      if (!active) return;
      const mappedResults = results?.map((c) => c.fields.name) ?? [];
      const uniqueResults = [...new Set(mappedResults)];
      setOptions(uniqueResults);
    });

    return () => {
      active = false;
    };
  }, [value, fetch]);

  return (
    <Wrapper>
      <Autocomplete
        className={className}
        disabled={disabled}
        autoHighlight
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          onChange(newInputValue);
        }}
        disablePortal
        getOptionLabel={(option) => option}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        freeSolo
        filterSelectedOptions
        inputValue={value ? value : ''}
        isOptionEqualToValue={(option, value) => option === value}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            fullWidth
            placeholder={placeholder}
            autoFocus={autofocus}
            name={fieldName}
            sx={sx}
          />
        )}
        renderOption={(props, option) => {
          return <li {...props}>{option}</li>;
        }}
      />
    </Wrapper>
  );
};
