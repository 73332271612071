import { Loader } from '@googlemaps/js-api-loader';

export class LocationService {
  static #instance: google.maps.places.AutocompleteService | undefined;

  static async get() {
    if (!LocationService.#instance) {
      const loader = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY as string,
        libraries: ['places'],
      });

      const google = await loader.loadPromise();
      LocationService.#instance = new google.maps.places.AutocompleteService();
    }

    return LocationService.#instance;
  }

  static destroyService() {
    LocationService.#instance = undefined;
  }
}
