import { useCallback, useState } from 'react';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { fetchCompaniesByWebsite } from '../../../../../services/queries/MaggieCompanyQueries';
import { VALID_URL_REGEX } from '../../../../../util/regex';

export function useDuplicateWebsiteHandling() {
  const [suggestedCompany, setSuggestedCompany] = useState<ICompanyDataModel | null>(null);

  const checkForDuplicates = useCallback(async (url: string) => {
    if (!VALID_URL_REGEX.test(url)) {
      setSuggestedCompany(null);
      return;
    }
    try {
      const companies = await fetchCompaniesByWebsite(url);
      if (companies.length > 0) {
        const suggestion = companies[0];
        setSuggestedCompany(suggestion);
      } else {
        setSuggestedCompany(null);
      }
    } catch (error) {
      // no need to break the form if this fails
      console.error(error);
    }
  }, []);

  const resetSuggestions = useCallback(() => {
    setSuggestedCompany(null);
  }, []);

  return { suggestedCompany, checkForDuplicates, resetSuggestions };
}
