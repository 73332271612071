import { IProviderFieldsDataModel } from '../../../data-models/provider-fields.data-model';
import { IUpdateProviderPayload } from '../../../services/hooks/useMappingsActions';
import { IVendorIdValues } from './VendorIdsForm';

export function getInitialVendorIdFormValues<T>(data: IProviderFieldsDataModel<T>) {
  let primary: string;
  if (Array.isArray(data.primary)) primary = data.primary.join(', ');
  else primary = data.primary ? String(data.primary) : '';

  let secondary: string[] = [];

  secondary =
    data.secondary?.map((val) => {
      if (Array.isArray(data.primary)) {
        return (val as []).join(', ');
      } else {
        return String(val ?? '');
      }
    }) ?? [];

  return { primary, secondary } as IVendorIdValues;
}

export function splitAndTrim(str: string) {
  return str.split(/,/).reduce((acc, item) => {
    const trimmed = item.trim();
    if (trimmed) acc.push(trimmed);
    return acc;
  }, [] as string[]);
}

export function prepareVendorIDsPayload<T>(
  initialData: IProviderFieldsDataModel<T>,
  values: IVendorIdValues
): IUpdateProviderPayload {
  const payload: IUpdateProviderPayload = { ...initialData };
  if (Array.isArray(initialData.primary)) {
    return {
      ...payload,
      primary: splitAndTrim(values.primary),
      secondary: values.secondary.reduce((acc, val) => {
        if (val) {
          acc.push(splitAndTrim(val));
        }
        return acc;
      }, [] as string[][]),
    };
  } else {
    return {
      ...payload,
      primary: values.primary,
      secondary: values.secondary.filter((val) => Boolean(val)),
    };
  }
}
