import { merge } from 'lodash-es';
import { mapRenderTypeToPrimitive } from '../CustomFields/hooks/useCustomFieldsActions';
import { RendererType } from '../../../data-models/field.data-model';
import {
  FormOptions,
  GridRef,
  IField,
  ISimpleChoice,
  createFieldDataModel,
  createFieldFormConfigDataModel,
} from '../../../data-models/field2.data-model';
import { createFormatterDataModel } from '../../../data-models/formatter.data-model';
import {
  CustomFieldFormType,
  CustomFieldFormViewModel,
  CustomFieldViewModelSchema,
} from './CustomField.schema';

export function createInnerViewModel(
  overrides: Partial<CustomFieldFormViewModel> = {}
): CustomFieldFormViewModel {
  return merge(CustomFieldViewModelSchema.model().getDefault(), overrides);
}

export function createFieldViewModel(overrides: Partial<CustomFieldFormType> = {}): CustomFieldFormType {
  return merge(
    {
      ...createFieldDataModel(),
      _viewModel: createInnerViewModel(overrides._viewModel),
    },
    overrides
  );
}

export function toFieldViewModel<T>(field: IField<T>): CustomFieldFormType {
  return {
    ...(field as Omit<CustomFieldFormType, '_viewModel'>),
    _viewModel: {
      rendererType: field.formMeta?.renderer?.type as RendererType,
      currencyConfig: field?.formMeta?.formatter?.config?.currency as string | undefined,
      selectConfigValues: (field?.formMeta?.renderer?.config?.values as ISimpleChoice<string>[])?.map(
        (opt) => opt.value
      ),
      [GridRef.portfolioReporting]: field.systemMeta?.gridRefs?.includes(GridRef.portfolioReporting),
      [GridRef.roundTracker]: field.systemMeta?.gridRefs?.includes(GridRef.roundTracker),
      [GridRef.securityOverview]: field.systemMeta?.gridRefs?.includes(GridRef.securityOverview),
      addingCaptableSecurity: field.systemMeta?.formRefs?.includes(FormOptions.addingCaptableSecurity),
      addingDeal: field.systemMeta?.formRefs?.includes(FormOptions.addingDeal),
      addingRound: field.systemMeta?.formRefs?.includes(FormOptions.addingRound),
      addingTransaction: field.systemMeta?.formRefs?.includes(FormOptions.addingTransaction),
      companyProfile: field.systemMeta?.formRefs?.includes(FormOptions.companyProfile),
    },
  };
}

export function fromFieldViewModel(field: CustomFieldFormType & IField<unknown>): IField<unknown> {
  const { _viewModel, ...fieldWithoutViewModel } = field;
  return {
    ...fieldWithoutViewModel,
    formMeta: merge(createFieldFormConfigDataModel(), {
      renderer: {
        id: _viewModel.rendererType ?? RendererType.text,
        type: _viewModel.rendererType,
        config: {
          values: _viewModel.selectConfigValues?.map((value) => ({
            displayName: value,
            value,
          })),
        },
      },
      formatter: createFormatterDataModel({
        id: rendererToFormatterId(_viewModel.rendererType),
        config: {
          currency: _viewModel.currencyConfig,
        },
      }),
    }),
    systemMeta: {
      formRefs: getFormRefs(_viewModel),
      gridRefs: getGridRefs(_viewModel),
      source: field.systemMeta?.source,
    },
    type: mapRenderTypeToPrimitive(_viewModel.rendererType),
  } as IField<unknown>;
}

export function rendererToFormatterId(type: RendererType) {
  switch (type) {
    case RendererType.multiSelect:
      return 'stringArray';
    case RendererType.singleSelect:
    case RendererType.text:
      return 'string';
    default:
      return type;
  }
}

export function getGridRefs(viewModel: CustomFieldFormViewModel): GridRef[] {
  const result: GridRef[] = [];

  Object.values(GridRef).forEach((ref) => {
    if (viewModel[ref]) result.push(ref);
  });

  return result;
}

export function getFormRefs(viewModel: CustomFieldFormViewModel): FormOptions[] {
  const result: FormOptions[] = [];

  Object.keys(FormOptions).forEach((ref) => {
    if (viewModel[ref as keyof typeof viewModel]) result.push(FormOptions[ref as keyof typeof FormOptions]);
  });

  return result;
}
