import { FC, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import { selectedInvestmentStateFinance, showModalStateFinance } from '../state/FinanceUIState';
import { RoundTable } from './RoundTable';
import { RoundModals } from './modals/RoundModals';

export const FinanceRounds: FC = () => {
  const resetModalState = useResetRecoilState(showModalStateFinance);
  const resetSelectedInvestment = useResetRecoilState(selectedInvestmentStateFinance);

  useEffect(() => {
    return () => {
      resetModalState();
      resetSelectedInvestment();
    };
  });

  return (
    <>
      <RoundTable />
      <RoundModals />
    </>
  );
};
