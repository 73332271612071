import { styled } from '@mui/material/styles';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { activeCompanyListIdState, activeListState, companyListState } from '../../state/ListsState';
import { listSearchQueryState } from '../state/FiltersState';
import { SectorFilter } from './SectorFilter';
import { TogglePreviousDealsSelector } from './TogglePreviousDealsSelector';
import { OwnerFilter } from './OwnerFilter';

const FilterContainer = styled('section')`
  margin: 1rem 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 1rem;
  justify-content: start;
`;

const FilterSelectorWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MainContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
`;

export function FiltersAndViewSettings() {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const activeList = useRecoilValue(activeListState);

  const companyList = useRecoilValueLoadable(companyListState(activeListId));
  const setListSearchQuery = useSetRecoilState(listSearchQueryState);

  if (!companyList.valueMaybe()?.items?.length) return null;

  return (
    <MainContainer>
      <FilterContainer>
        {/* marginTop for alignment with the dropdown */}
        <SearchInput onChange={setListSearchQuery} style={{ width: '12rem', marginTop: '0.5rem' }} />
        <SectorFilter />
        {activeList?.type === 'discovery' && <OwnerFilter />}
      </FilterContainer>
      {
        <FilterSelectorWrapper>
          {activeList?.type === 'discovery' && <TogglePreviousDealsSelector />}
        </FilterSelectorWrapper>
      }
    </MainContainer>
  );
}
