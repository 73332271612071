import { Button, ButtonProps, Typography } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SelectItem } from '../types';
import { pluralizeWord } from '../util/stringUtils';
import { PopupMenu } from './PopupMenu/PopupMenu';

export interface IPopupFilterProps {
  selected: SelectItem[] | null;
  setSelected: (newValue: SelectItem[] | null) => void;
  options: SelectItem[];
  labels: {
    item: string;
    all?: string;
  };
  groupable?: boolean;
  searchable?: boolean;
}
export function PopupFilter({
  selected,
  setSelected,
  options,
  labels,
  groupable,
  searchable = true,
}: IPopupFilterProps) {
  const onChange = (newValue: SelectItem[]) => {
    if (newValue.length === options.length) {
      setSelected(null);
    } else {
      setSelected(newValue);
    }
  };
  const label = (
    <Typography variant='body2'>
      {!selected?.length || selected.length === options.length
        ? labels.all ?? `All ${pluralizeWord(labels.item, options.length)}`
        : selected.length === 1
        ? `${selected[0].value} `
        : `${selected.length} ${pluralizeWord(labels.item, selected.length)} Selected`}
    </Typography>
  );

  return (
    <PopupMenu
      items={options}
      groupable={groupable}
      searchable={searchable}
      label={label}
      onChange={onChange}
      selectedItems={selected ?? options}
      ActionComponent={FilterSelectorButton}
    />
  );
}

function FilterSelectorButton({ children, ...rest }: ButtonProps) {
  return (
    <Button endIcon={<FilterAltIcon />} variant={'outlined'} size='medium' {...rest}>
      {children}
    </Button>
  );
}

export function getFilterLabel(
  selected: { value: string }[] | null,
  total: number,
  labels: IPopupFilterProps['labels']
) {
  let result = '';
  switch (true) {
    case !selected?.length || selected.length === total: {
      result = labels.all ?? `All ${pluralizeWord(labels.item, total)}`;
      break;
    }
    case selected?.length === 1: {
      result = `${selected[0].value}`;
      break;
    }
    default: {
      result = `${selected.length} ${pluralizeWord(labels.item, selected.length)} Selected`;
    }
  }

  return result;
}
