import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { IFormField } from '../../../view-models/form.view-model';
import { ShareClass } from '../../../view-models/captable.view-model';
import { ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { ParticipationType } from '../../../data-models/scenario.data-model';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { field2ToFormField, FieldEntity, FormOptions } from '../../../data-models/field2.data-model';

export function useShareClassFields() {
  const { watch } = useFormContext<ShareClass>();
  const type = watch('type');
  const participationPref = watch('participationType');

  const filterOutConditionalFields = useCallback(
    (fields: IFormField<unknown>[]) => {
      switch (type) {
        case ViewModelInvestmentType.preferred: {
          return fields.filter((field) => {
            if (participationPref !== ParticipationType.capped) {
              return field.key !== 'participationCap';
            }
            return true;
          });
        }
        default: {
          return fields;
        }
      }
    },
    [participationPref, type]
  );

  return filterOutConditionalFields;
}

export function useCaptableInvestmentCustomFields() {
  const customFields = useRecoilValue(customFieldsByEntity);

  return useMemo(() => {
    return (
      customFields.get(FieldEntity.captableInvestment)?.reduce((acc, field) => {
        if (!field.systemMeta?.formRefs?.includes(FormOptions.addingCaptableSecurity)) {
          return acc;
        }
        const cf = field2ToFormField(field);
        acc.push(cf);
        return acc;
      }, [] as IFormField<unknown>[]) ?? []
    );
  }, [customFields]);
}
