import { atomFamily } from 'recoil';
import { INoteDataModel } from '../../data-models/note.data-model';
import { getAllNotesForCompany } from '../queries/MaggieNoteQueries';

export const notesState = atomFamily<INoteDataModel<unknown>[], number>({
  key: 'notesState',
  default: async (companyId: number) => {
    const { notes } = await getAllNotesForCompany(companyId);
    return notes;
  },
});
