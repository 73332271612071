import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { kpiGroupsState } from '../services/state/KPI/KPITemplatesState';
import { createField3DataModel, ISelectMeta } from '../data-models/field3.data-model';
import { RendererType } from '../data-models/field.data-model';
import { ISimpleChoice } from '../data-models/field2.data-model';

export function useGetKPIGroupField() {
  const kpiGroups = useRecoilValue(kpiGroupsState);

  return useCallback(
    (fieldId: string, label: string) => {
      const groups: ISimpleChoice<number>[] = kpiGroups.map((group) => {
        return {
          value: group.id,
          displayName: group.name,
        };
      });

      return createField3DataModel<ISelectMeta<number>>({
        entityKey: fieldId,
        displayName: label,
        format: RendererType.singleSelect,
        meta: {
          values: groups,
          multi: false,
        },
      });
    },
    [kpiGroups]
  );
}
