import { IConversionRateDataModel } from '../../../data-models/deal-conversion.data-model';

export function useConversionRateBarChartData(conversionRates: IConversionRateDataModel[]) {
  const nonZeroRates = filterNonZeroRates(conversionRates);
  const data = nonZeroRates
    .map((rate) => {
      return { value: rate.conversionRate, name: rate.stage, averageDuration: rate.averageDuration };
    })
    .reverse();
  return { data };
}

function filterNonZeroRates(conversionRates: IConversionRateDataModel[]) {
  return conversionRates.filter((rate) => rate?.conversionRate != null && rate.conversionRate > 0);
}
