import { styled } from '@mui/system';
import { useRecoilValue } from 'recoil';
import { FinancingHistoryTable } from '../../CompanyProfiles/Summary/components/FinancingHistory/FinancingHistoryTable';
import { NewsSection } from '../../CompanyProfiles/Summary/components/NewsSection';
import {
  TotalRevenueChart,
  TotalRevenueChartTitle,
} from '../../CompanyProfiles/Performance/components/Charts/TotalRevenueChart';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { Panel } from './Panel';
import { CompanyDetailsPanel } from './CompanyDetailsPanel';
import { DigestPanel } from './DigestPanel';
import { LeadershipPanel } from './LeadershipPanel';
import { InvestmentMetricsPanel } from './InvestmentMetricsPanel';
import { RecentActivityPanel } from './RecentActivity/RecentActivityPanel';

const MainWrapper = styled('div')`
  container-name: company-profiles-container;
  container-type: inline-size;
  display: grid;
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const SummaryLayout = styled('div')`
  display: grid;
  gap: 1rem;
  height: 100%;
  padding: 1rem;
  width: 100%;

  @container company-profiles-container (width < 900px) {
    grid-template-columns: 1fr 0fr;
    grid-template-areas:
      'digest'
      'digest'
      'digest'
      'details'
      'details'
      'leader'
      'leader'
      'leader'
      'ttl-rev'
      'invst-mtr'
      'invst-mtr'
      'activity'
      'activity'
      'fin-hist'
      'fin-hist'
      'perf-time'
      'news'
      'news';
  }

  @container company-profiles-container (width >= 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'digest details'
      'digest details'
      'digest leader'
      'ttl-rev leader'
      'ttl-rev leader'
      'invst-mtr activity'
      'invst-mtr activity'
      'fin-hist fin-hist'
      'fin-hist fin-hist'
      'perf-time perf-time'
      'news news'
      'news news';
  }
`;

const PanelWrapper = styled('div')`
  height: 100%;
  width: 100%;
  min-height: 160px;
`;

export function CompanySummaryPortfolio() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  return (
    <MainWrapper>
      <SummaryLayout>
        <PanelWrapper style={{ gridArea: 'digest' }}>
          <DigestPanel companyId={companyId} />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'details' }}>
          <CompanyDetailsPanel companyId={companyId} />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'leader' }}>
          <GenericFallbacksWrapper>
            <LeadershipPanel />
          </GenericFallbacksWrapper>
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'ttl-rev' }}>
          <TotalRevenueChartTitle>
            <TotalRevenueChart />
          </TotalRevenueChartTitle>
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'invst-mtr' }}>
          <InvestmentMetricsPanel companyId={companyId} />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'activity' }}>
          <RecentActivityPanel />
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'fin-hist' }}>
          <Panel title={'Financing History'}>
            <FinancingHistoryTable />
          </Panel>
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'perf-time' }}>
          <Panel title={'Performance over Time'}></Panel>
        </PanelWrapper>
        <PanelWrapper style={{ gridArea: 'news' }}>
          <Panel title={'News'}>
            <NewsSection />
          </Panel>
        </PanelWrapper>
      </SummaryLayout>
    </MainWrapper>
  );
}
