import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { editInvestmentSchema } from '../Transactions/AssignToRound/investmentSchemas';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import {
  fromFinanceRoundDataModel,
  IFinanceRoundFormViewModel,
  toFinanceRoundDataModel,
} from '../../../view-models/finance-round-form.view-model';
import { InvestmentCreateForm } from './InvestmentSelectOrCreateForm';

interface IEditInvestmentFormProps {
  investment: IFinanceRoundDataModel;
  onSubmit: (investment: Partial<IFinanceRoundDataModel>) => Promise<IFinanceRoundDataModel | undefined>;
  onCancel: () => void;
}

export const EditInvestmentForm: FC<IEditInvestmentFormProps> = ({ investment, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IFinanceRoundFormViewModel) => {
      setLoading(true);
      await onSubmit(toFinanceRoundDataModel(data));
      setLoading(false);
    },
    [onSubmit]
  );

  const methods = useForm<IFinanceRoundFormViewModel>({
    defaultValues: fromFinanceRoundDataModel(investment),
    mode: 'all',
    resolver: yupResolver(editInvestmentSchema),
  });

  return (
    <FormProvider {...methods}>
      <form>
        <FormFieldsContainer>
          <InvestmentCreateForm formConfig={{ title: '' }} />
        </FormFieldsContainer>
        <StickyFormButtons
          onCancel={onCancel}
          onSubmit={methods.handleSubmit(handleSubmit)}
          loading={loading}
        />
      </form>
    </FormProvider>
  );
};
