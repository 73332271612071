import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, Resolver, useForm, useFormContext } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ObjectSchema } from 'yup';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';

import { useSchemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { TypeToSchemaMap, ShareClass } from '../../../view-models/captable.view-model';
import { shareClassesState } from './CapTableFormState';
import { useCaptableInvestmentCustomFields, useShareClassFields } from './useShareClassFields';

interface IAddShareClassForm {
  shareClass: ShareClass;
  onClose: () => void;
  mode: 'create' | 'edit';
}

export function AddShareClassForm(props: IAddShareClassForm) {
  const { onClose, shareClass, mode } = props;
  const resolver = useShareClassResolver();
  const methods = useForm({
    defaultValues: { ...shareClass },
    resolver,
  });
  const setShareClasses = useSetRecoilState(shareClassesState);
  const getUniqueInvestmentName = useGetUniqueInvestmentName();

  const onSubmit = useCallback(
    (data: ShareClass) => {
      const name =
        data.name !== methods.formState.defaultValues?.name || mode === 'create'
          ? getUniqueInvestmentName(data.name!)
          : data.name;
      const newData = { ...data, name };
      if (mode === 'edit') {
        setShareClasses((old) =>
          old.map((curr) => (curr.name === methods.formState.defaultValues?.name ? { ...newData } : curr))
        );
      } else {
        setShareClasses((old) => [...old, newData]);
      }
      onClose();
    },
    [getUniqueInvestmentName, methods.formState.defaultValues?.name, mode, onClose, setShareClasses]
  );

  return (
    <FormProvider {...methods}>
      <FormFieldsContainer style={{ maxHeight: 'calc(100vh - 10rem)' }}>
        <ShareClassForm />
      </FormFieldsContainer>
      <StickyFormButtons
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={onClose}
        submitLabel={mode === 'create' ? 'Add' : 'Save'}
      />
    </FormProvider>
  );
}

export function ShareClassForm() {
  const methods = useFormContext<ShareClass>();
  const [rerender, setRerender] = useState(0);
  const type = methods.watch('type');

  const { watch, reset } = methods;
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'type' && type === 'change' && value.type) {
        setRerender((old) => old + 1);
        reset({
          ...(TypeToSchemaMap[value.type].create().getDefault() as Partial<ShareClass>),
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [reset, watch]);

  const schemaToFormField = useSchemaToFormFields();
  const filterOutConditionalFields = useShareClassFields();
  const customFields = useCaptableInvestmentCustomFields();
  const form = useMemo(() => {
    const formFields = schemaToFormField(
      (TypeToSchemaMap[type].create() as ObjectSchema<ShareClass>).omit(['customData'])
    ).concat(customFields);

    const visibleFields = filterOutConditionalFields(formFields);

    return createFormFromFieldsArray(visibleFields);
  }, [customFields, filterOutConditionalFields, schemaToFormField, type]);

  return <FormFactoryWithStandardLayout form={form} key={rerender} />;
}

export function useShareClassResolver() {
  const resolver: Resolver<ShareClass> = useCallback((values: ShareClass, context, options) => {
    const schema = TypeToSchemaMap[values.type!].create();

    return yupResolver(schema as ObjectSchema<ShareClass>)(values, context, options);
  }, []);

  return resolver;
}

function useGetUniqueInvestmentName() {
  const shareClasses = useRecoilValue(shareClassesState);

  return useCallback(
    (name: string) => {
      const existing = new Set(shareClasses.map((sc) => sc.name));
      if (!existing.has(name)) {
        return name;
      }

      let i = 1;
      while (existing.has(`${name} ${i}`)) {
        i++;
      }
      return `${name} ${i}`;
    },
    [shareClasses]
  );
}
