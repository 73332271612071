import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import { Circle } from '../../../../DealFlow2/components/DealModal/DealAddModalContent/Circle';

import { ClosedSubFormContainer, formSubtitleSx } from './StyledFormComponents';

export const EmptyKeyResult: FC = () => {
  const { colors } = useTheme();
  return (
    <ClosedSubFormContainer>
      <Typography data-testid='emptykey-result' component='h6' variant='body1' sx={{ ...formSubtitleSx }}>
        <Circle
          isOpen={false}
          style={{
            background: 'transparent',
            color: colors.neutral[40],
            border: `2px solid ${colors.neutral[40]}`,
          }}
        >
          {2}
        </Circle>
        Key Result
      </Typography>
    </ClosedSubFormContainer>
  );
};
