import { FC, HTMLAttributes, ReactNode } from 'react';
import { Typography, useTheme } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IInfoBoxProps extends HTMLAttributes<HTMLDivElement> {
  message: string | ReactNode;
  variant?: 'info' | 'warning';
}

const BoxContainer = styled('div')<{ variant: 'info' | 'warning' }>`
  border-radius: 4px;
  background-color: ${({ theme, variant }) =>
    variant === 'info' ? theme.colors.secondary[5] : `${theme.colors.warning[10]}88`};
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: start;
`;

export const InfoBox: FC<IInfoBoxProps> = ({ message, variant = 'info', ...rest }) => {
  const { colors } = useTheme();
  return (
    <BoxContainer {...rest} aria-label='infobox' variant={variant}>
      {variant === 'info' ? (
        <InfoOutlinedIcon htmlColor={colors.neutral[50]} fontSize='small' />
      ) : (
        <WarningAmberOutlinedIcon htmlColor={colors.warning[50]} fontSize='small' />
      )}
      {typeof message === 'string' ? (
        <Typography variant='body2' component={'span'}>
          {message}
        </Typography>
      ) : (
        message
      )}
    </BoxContainer>
  );
};
