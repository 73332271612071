import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../../types';
import { format } from '../kpi-formatter';

export class CountOfCompaniesConfigRoundTracker implements KpiConfig {
  private id = KPI_COMPONENTS.COUNT_OF_COMPANIES_RT as const;
  private title = 'Companies';
  private type = 'integer';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value =
      data?.filteredData?.reduce((res, metric) => {
        return res.add(metric.company);
      }, new Set()).size ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
