import { useTheme } from '@mui/material/styles';
import { Dictionary, OptionDataItem, TextCommonOption } from 'echarts/types/src/util/types';
import { get } from 'lodash-es';

export function formatDiff(data: OptionDataItem, diffFieldName: string) {
  const diffFieldValue = get(data, diffFieldName);
  if (diffFieldValue == null) return '';
  if (diffFieldValue > 0) {
    return `{positive|▲${diffFieldValue}%}`;
  } else if (diffFieldValue < 0) {
    return `{negative|▼${Math.abs(diffFieldValue)}%}`;
  } else {
    return `{neutral|  0%}`;
  }
}

export function useChartStyles() {
  const { colors } = useTheme();

  const commonStyles = {
    shadowColor: 'rgba(0, 0, 0, 0.1)',
    shadowBlur: 3,
    borderRadius: 4,
    padding: [4, 2],
  };

  const indicatorRich: Dictionary<TextCommonOption> = {
    positive: {
      backgroundColor: colors.success[20],
      color: colors.success[60],
      ...commonStyles,
    },
    negative: {
      backgroundColor: colors.critical[10],
      color: colors.critical[50],
      ...commonStyles,
    },
    neutral: {
      backgroundColor: colors.neutral[10],
      color: colors.neutral[50],
      width: 30,
      ...commonStyles,
    },
    strong: {
      fontWeight: 'bold',
    },
  };

  return { indicatorRich };
}
