import { useMemo } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useRecoilValue } from 'recoil';
import { Stack, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useFundMetricsFP } from '../useFundMetricsFP';
import { selectedFundStateFP } from '../state/FPState';
import { FundMetrics } from '../../../schemas/FundMetrics.schema';
import { Fund } from '../../../schemas/Fund.schema';
import { colors } from '../../../theme/colors';
import { FMT, IPartialFormatterDataModel, StandardFormatterId } from '../../../util/formatter-service';
import { ToggleIconButton } from '../../../components/ToggleIconButton/ToggleIconButton';
import { cardStyles } from '../../../theme/component-styles';
import { getWaterfallData, WaterfallGridData } from './FPWaterfallData';
import { useWaterfallCalcsData } from './useWaterfallCalcsData';
import { WaterfallField, WfKey } from './WaterfallFieldsGenerator';

export const selectedCellKey = atom<WfKey | null>(null);
export const highlightedCells = atom<Set<string> | null>(null);

const CalcsContainerName = 'waterfall-calcs-container';

const FormulasTable = css`
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: inset 0 0 0 1px ${colors.neutral[20]};
  width: 100%;
`;
const CalcCell = css`
  padding: 0.2rem 0.25rem;
  font-size: 0.75rem;
  border: thin solid ${colors.neutral[20]};
  box-shadow: inset 0 0 0 1px transparent;
  background-color: transparent;
  &.selectable {
    cursor: pointer;
  }
  &.selected {
    box-shadow: inset 0 0 0 2px ${colors.secondary[40]};
    background-color: transparent;
  }
  &.highlighted {
    background-color: ${colors.primary[10]}88;
  }
  transition: all 0.3s;
`;

const CalcsTitle = css`
  width: 0;
  overflow: visible;
  visibility: visible;
  @container (max-width: 1100px) {
    visibility: hidden;
  }
  transition: all;
`;

const CalcsBox = css({
  ...cardStyles,
  border: 'none',
  containerName: CalcsContainerName,
  containerType: 'inline-size',
});

const FormulaBox = css`
  padding: 0.2rem 0.4rem;
`;

const StyledFormula = css`
  display: block;
  ${FormulaBox};
  margin-inline: 0.5rem;
  box-shadow: inset 0 0 0 2px ${colors.secondary[40]};
`;

export const showWaterfallCalcs = atom(false);

export function ToggleCalcsButton() {
  const [showCalcs, setShowCalcs] = useAtom(showWaterfallCalcs);
  const fund = useRecoilValue(selectedFundStateFP);
  const fundMetrics = useFundMetricsFP();
  const gridData = useMemo(() => {
    if (!fundMetrics) return [];
    return getWaterfallData(fundMetrics.metrics);
  }, [fundMetrics]);

  if (!fund || !fundMetrics || !gridData) return <div />;

  if (!fund) return null;
  return (
    <ToggleIconButton
      size='small'
      sx={{ p: '0.1rem 0.3rem' }}
      color='secondary'
      selected={showCalcs}
      value={'toggle calculations view'}
      title={showCalcs ? 'hide calculations' : 'view calculations'}
      onChange={() => setShowCalcs((prev) => !prev)}
    >
      <Typography variant='body2'>
        𝑓<sub>x</sub>
      </Typography>
    </ToggleIconButton>
  );
}

interface IWaterfallCalcsTableProps {
  fund: Fund;
  fundMetrics: FundMetrics;
  gridData: WaterfallGridData[];
}
export function WaterfallCalcsTableProto({ fund, fundMetrics, gridData }: IWaterfallCalcsTableProps) {
  const {
    fundInputs,
    fundMetricsInputs,
    initialTierFields,
    tier0Fields,
    tier1Fields,
    tier2Fields,
    tier3Fields,
  } = useWaterfallCalcsData(fund, fundMetrics, gridData);

  console.debug({
    fundInputs,
    fundMetricsInputs,
    initialTierFields,
    tier0Fields,
    tier1Fields,
    tier2Fields,
    tier3Fields,
  });
  const selectedKey = useAtomValue(selectedCellKey);
  const fieldMap = useMemo(() => {
    return [
      fundInputs,
      fundMetricsInputs,
      initialTierFields,
      tier0Fields,
      tier1Fields,
      tier2Fields,
      tier3Fields,
    ]
      .flat()
      .reduce((map, field) => {
        return map.set(field.key, field);
      }, new Map<string, WaterfallField>());
  }, [fundInputs, fundMetricsInputs, initialTierFields, tier0Fields, tier1Fields, tier2Fields, tier3Fields]);
  const selected = fieldMap.get(selectedKey ?? '');

  return (
    <Stack mt='1rem'>
      <motion.div
        animate={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        key={String(selectedKey)}
        css={CalcsBox}
      >
        {
          <Stack
            display='grid'
            gridTemplateColumns={'auto 1fr'}
            alignItems={'center'}
            width='100%'
            justifyContent={'center'}
            gap='0.2rem'
          >
            <Typography variant='body2' noWrap css={CalcsTitle}>
              Waterfall Calculation
            </Typography>
            <div style={{ justifySelf: 'center', display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
              {selectedKey ? (
                <>
                  <Typography variant='body2' fontWeight={'bold'} color='primary'>
                    𝑓<sub>x</sub>
                  </Typography>
                  <Typography variant='caption' css={StyledFormula}>{` = ${selected?.formula}`}</Typography>
                </>
              ) : (
                <Stack direction='row' css={FormulaBox} alignItems={'center'} gap='0.2rem'>
                  <InfoOutlined fontSize='small' htmlColor={colors.neutral[50]} />
                  <Typography variant='caption' color='text.secondary'>
                    Select a cell to view formula
                  </Typography>
                </Stack>
              )}
            </div>
          </Stack>
        }
      </motion.div>

      <Stack display='grid' gridTemplateColumns={'1fr 1fr'} columnGap={'1rem'} mt='0.4rem'>
        <div>
          <Typography variant='caption'>Inputs</Typography>
          <CalcTable fields={fundInputs} />
          <br />
          <CalcTable fields={fundMetricsInputs} />
        </div>
        <Stack display='grid' alignItems={'space-between'}>
          <div>
            <Typography variant='caption'>Initial</Typography>
            <CalcTable fields={initialTierFields} />
          </div>
          <div>
            <Typography variant='caption'>Tier 0</Typography>
            <CalcTable fields={tier0Fields} />
          </div>
          <div>
            <Typography variant='caption'>Tier 1</Typography>
            <CalcTable fields={tier1Fields} />
          </div>
          <div>
            <Typography variant='caption'>Tier 2</Typography>
            <CalcTable fields={tier2Fields} />
          </div>
          <div>
            <Typography variant='caption'>Tier 3</Typography>
            <CalcTable fields={tier3Fields} />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}

function CalcTable({ fields }: { fields: WaterfallField[] }) {
  const [selectedKey, setSelectedKey] = useAtom(selectedCellKey);
  const [highlighted, setHighlighted] = useAtom(highlightedCells);

  return (
    <table css={FormulasTable}>
      {fields.map((field) => {
        const isSelected = selectedKey === field.key;
        const selectable = Boolean(field.formula);
        let classes = '';
        if (selectable) {
          classes = 'selectable';
        }
        if (isSelected) {
          classes += ' selected';
        } else if (highlighted?.has(field.key)) {
          classes += ' highlighted';
        }

        const _onClick = () => {
          setSelectedKey(field.key);
          setHighlighted(field.inputs ?? new Set());
        };

        const onClick = selectable ? _onClick : undefined;
        return (
          <tr key={field.key}>
            <td css={CalcCell}>{field.label}</td>
            <td css={CalcCell} className={classes} onClick={onClick}>
              {FMT.format(
                field.formatter as StandardFormatterId | IPartialFormatterDataModel<unknown>,
                field.value
              )}
            </td>
          </tr>
        );
      })}
    </table>
  );
}
