import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ControllerRenderProps } from 'react-hook-form';
import { Button, Card, Chip, Stack, Typography } from '@mui/material';
import { createFormField } from '../../../../view-models/form.view-model';
import { ISelectMeta } from '../../../../data-models/field3.data-model';
import { RendererType } from '../../../../data-models/field.data-model';
import { selectedCompanyIdProfile } from '../../state/UIState';
import {
  captablesByCompanyState,
  selectedCaptableIdState,
  selectedCaptableState,
} from '../CapTableDataState';
import { ICaptableDataModel } from '../../../../data-models/captable2.data-model';
import { FormatterService } from '../../../../util/formatter-service';
import { DateFormattersConfig } from '../../../../util/formatters/DateFormatters';
import { useMakeCaptablePrimary } from '../CapTableActions';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { ModalFormWidth, MaxModalFormWidth } from '../../../../constants/styles';
import { InfoBox } from '../../../Mappings/forms/InfoBox';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { FieldSelect } from '../../../../components/Form/Display/FieldSelect';
import { CaptableSourceMap, getCaptableSourceLabel } from '../captable-utils';
import { cardStyles } from '../../../../theme/component-styles';
import { CaptableSourceLogo } from './CaptableSourceLogo';

export function useCaptableSelectorField() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captablesForCompany = useRecoilValue(captablesByCompanyState(companyId ?? -1));

  return useMemo(
    () =>
      createFormField<ISelectMeta<number>>({
        key: 'selectedCaptable',
        label: '',
        dataType: 'string',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: captablesForCompany.map((opt, i) => {
            const source = getCaptableSourceLabel(opt);
            return {
              value: opt.id,
              displayName: `Cap Table #${i + 1} - ${source}`,
            };
          }),
          multi: false,
        },
        disableClearable: true,
      }),
    [captablesForCompany]
  );
}

export const CaptableSelector: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captablesForCompany = useRecoilValue(captablesByCompanyState(companyId ?? -1));

  const [selectedCaptableId, setSelectedCaptableId] = useRecoilState(
    selectedCaptableIdState(companyId ?? -1)
  );
  const selectedCaptable = useRecoilValue(selectedCaptableState(companyId ?? -1));

  const selector = useCaptableSelectorField();

  if (!captablesForCompany) return null;

  return (
    <Stack
      display={'grid'}
      gridTemplateColumns={'15rem 2rem auto auto'}
      justifyContent={'start'}
      alignItems={'center'}
      gap={'1rem'}
    >
      <FieldSelect
        formField={selector}
        formProps={
          {
            onChange: (val) => setSelectedCaptableId(val),
            value: selectedCaptableId,
          } as ControllerRenderProps
        }
      />
      <MoreCaptableInfo captable={selectedCaptable} />
    </Stack>
  );
};

function LastUpdate({ captable }: { captable: ICaptableDataModel }) {
  const dateFormatter = FormatterService.get().getFormatterForModel(DateFormattersConfig.date);
  if (!captable.updatedAt) return null;
  return (
    <Typography variant={'body2'} color='text.secondary'>
      {`Last Update: ${dateFormatter(captable.updatedAt)}`}
    </Typography>
  );
}

export function MoreCaptableInfo({ captable }: { captable: ICaptableDataModel | null }) {
  if (!captable) return null;
  return (
    <>
      <CaptableSourceLogo captable={captable} key={captable.id} />
      {captable?.primary ? <Chip label='Primary' color='primary' /> : <MakePrimary captable={captable} />}
      <LastUpdate captable={captable} />
    </>
  );
}

export function MakePrimary({ captable }: { captable: ICaptableDataModel | null }) {
  const makePrimary = useMakeCaptablePrimary();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const canEditCaptable = PermissionService.get().hasPermission(PermissionKey.canEditTransaction);

  const onConfirm = useCallback(async () => {
    if (!captable) return;
    setLoading(true);
    await makePrimary(captable.id);
    setLoading(false);
    setShowConfirm(false);
  }, [makePrimary, captable]);

  if (!captable || !canEditCaptable) return null;
  return (
    <>
      {showConfirm && (
        <BasicDialog
          titleComponent={<Typography>Make This Cap Table Primary</Typography>}
          open={true}
          onClose={() => setShowConfirm(false)}
        >
          <Stack
            style={{ padding: '0.25rem 2.5rem 0', width: ModalFormWidth, maxWidth: MaxModalFormWidth }}
            gap='0.5rem'
          >
            <Card
              sx={{ ...cardStyles, display: 'grid', gridAutoFlow: 'column', justifyContent: 'space-between' }}
            >
              <Typography variant='body2'>{`${CaptableSourceMap[captable.source]} Cap Table`}</Typography>
              <LastUpdate captable={captable} />
            </Card>

            <InfoBox
              variant='warning'
              message={`Data from this Cap Table source will be used by default across the Foresight platform. Are you sure you want to make this change?`}
            />
          </Stack>
          <StickyFormButtons
            onSubmit={onConfirm}
            onCancel={() => setShowConfirm(false)}
            submitLabel={'Make Primary'}
            loading={loading}
          />
        </BasicDialog>
      )}
      <Button color='secondary' onClick={() => setShowConfirm(true)}>
        Make Primary
      </Button>
    </>
  );
}
