export enum ROUTES {
  ADMIN_PANEL = 'admin-panel',
  ADMIN_PANEL_DATA_DISPLAY = 'data-display',
  ADMIN_PANEL_FIELDS = 'fields',
  ADMIN_PANEL_RUBRICS = 'rubrics',
  ASSIGNED_COMPANIES = 'assigned',
  BULK_UPLOAD_REPORT = 'bulk-upload-report',
  COMPANIES = 'companies',
  COMPANY_COMPARISON = 'company-comparison',
  COMPANY_PROFILES = 'company-profiles',
  COMPANY_PROFILE_CAP_TABLE = 'cap-table',
  CAP_TABLE_FORM = 'form',
  COMPANY_PROFILE_CI = 'competitive-intelligence',
  COMPANY_PROFILE_COMPETITORS = 'competitors',
  COMPANY_PROFILE_NICHE_VCS = 'niche-vcs',
  COMPANY_PROFILE_PEOPLE = 'people',
  COMPANY_PROFILE_PERFORMANCE = 'performance',
  COMPANY_PROFILE_SCENARIOS = 'scenarios',
  COMPANY_PROFILE_SINGLE_SCENARIO = 'modelling',
  COMPANY_PROFILE_SCENARIO_SETTINGS = 'sc-settings',
  COMPANY_PROFILE_SCENARIO_RUN = 'sc-run',
  COMPANY_PROFILE_SUMMARY = 'summary',
  COMPANY_PROFILE_FINANCIALS = 'financials',
  COMPETITIVE_INTELLIGENCE = 'company-comparison/ci-report',
  CONFIG = 'config',
  CONFIG_DEAL_TYPES = 'deal-types',
  CONFIG_DEAL_STAGES = 'deal-stages',
  CONFIG_PASS_REASONS = 'pass-reasons',
  CONFIG_MISS_REASONS = 'miss-reasons',
  CONFIG_FUNDS = 'funds',
  CONFIG_SECTORS = 'sectors',
  CONFIG_KPI = 'all-kpis',
  CONFIG_KPI_GROUPS = 'kpi-groups',
  DEAL_ANALYTICS = 'analytics',
  DEAL_ANALYTICS_KPI = 'deal-kpis',
  DEAL_ANALYTICS_DURATION_DETAILS = 'deal-duration',
  DEAL_FLOW = 'deal-flow',
  DEALS = 'deals',
  DETAILS_CONFIG = 'details-config',
  FINANCE = 'finance',
  FINANCE2 = 'fin2.0',
  FINANCE2_OVERVIEW = 'main',
  FINANCE_ALL_SECURITIES = 'security-report',
  FINANCE_RECON = 'recon',
  FINANCE_RECON_METRICS = 'metrics',
  FINANCE_RECON_ROUNDS = 'round',
  FINANCE_DETAILS = 'details',
  FINANCE_TRANSACTION_HISTORY = 'history',
  FINANCE_TRANSACTIONS = 'transactions',
  FINANCE_TRANSACTIONS_3 = 'transactions3',
  FINANCE_ROUNDS = 'rounds',
  FINANCE_LEDGER = 'ledger',
  FINANCE_HISTORY = 'finance/:companyId',
  FUND_PROFILES = 'fund-profiles',
  GENERAL_LEDGER = 'general-ledger',
  FP_CAPITAL_ALLOCATION = 'fp-capital-allocation',
  FP_RESERVES = 'fp-reserves',
  FP_SUMMARY = 'fp-summary',
  FP_WATERFALL = 'fp-waterfall',
  GROWTH_ANALYSIS = 'company-comparison/ga-report',
  INTEGRATIONS = 'integrations',
  INTEGRATIONS_APPS = 'apps',
  INTEGRATIONS_APPS_AIRTABLE = 'airtable',
  NOTIFICATION_PREFERENCES = 'notification-preferences',
  PORTFOLIO = 'portfolio',
  KPI = 'kpis',
  KPI_REQUESTS = 'requests',
  KPI_REQUESTS_RESPONSES_HOME = 'kpis/response',
  KPI_PENDING_REQUESTS = 'kpis/pending-requests/:companyId',
  KPI_PENDING_REQUESTS_2 = 'kpis/pending-requests-2/:companyId',
  KPI_THANK_YOU_SCREEN = 'kpis/thank-you',
  KPI_REQUEST_REVIEW = 'kpis/response/:requestId',
  KPI_REQUEST_REVIEW_2 = 'kpis/response-2/:requestId',
  KPI_REQUESTS_IN_PROGRESS = 'in-progress',
  KPI_REQUESTS_ARCHIVED = 'archived',
  KPI_RESPONSES = 'responses',
  KPI_RESPONSES_ACCEPTED = 'accepted',
  KPI_RESPONSES_REJECTED = 'rejected',
  KPI_TEMPLATES = 'templates',
  KPI_TEMPLATES_FULL_ROUTE = 'kpis/templates',
  EXCEL_LOGIN = 'excel-login',
  MAPPINGS = 'mappings',
  METRICS_CONFIG = 'metrics-config',
  SETTINGS = 'settings',
  UI = 'ui',
}

export const ROUTE_TITLE_MAPPING = {
  [ROUTES.ADMIN_PANEL]: 'Admin Panel',
  [ROUTES.ADMIN_PANEL_DATA_DISPLAY]: 'Data Display',
  [ROUTES.ADMIN_PANEL_FIELDS]: 'Fields',
  [ROUTES.ADMIN_PANEL_RUBRICS]: 'Rubrics',
  [ROUTES.ASSIGNED_COMPANIES]: 'Assigned',
  [ROUTES.BULK_UPLOAD_REPORT]: 'Bulk Upload Report',
  [ROUTES.CAP_TABLE_FORM]: 'Add Cap Table',
  [ROUTES.COMPANIES]: 'Companies',
  [ROUTES.COMPANY_COMPARISON]: 'Analysis',
  [ROUTES.COMPANY_PROFILES]: 'Companies',
  [ROUTES.COMPANY_PROFILE_CAP_TABLE]: 'Cap Table',
  [ROUTES.COMPANY_PROFILE_CI]: 'Competitive Intelligence',
  [ROUTES.COMPANY_PROFILE_COMPETITORS]: 'Competitors',
  [ROUTES.COMPANY_PROFILE_FINANCIALS]: 'Financials',
  [ROUTES.COMPANY_PROFILE_NICHE_VCS]: 'Niche VCs',
  [ROUTES.COMPANY_PROFILE_PEOPLE]: 'People',
  [ROUTES.COMPANY_PROFILE_PERFORMANCE]: 'Performance',
  [ROUTES.COMPANY_PROFILE_SCENARIOS]: 'Scenario Modeling',
  [ROUTES.COMPANY_PROFILE_SCENARIO_RUN]: 'Scenario Run',
  [ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS]: 'Scenario Settings',
  [ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO]: 'Scenario',
  [ROUTES.COMPANY_PROFILE_SUMMARY]: 'Summary',
  [ROUTES.COMPETITIVE_INTELLIGENCE]: 'Competitive Intelligence',
  [ROUTES.CONFIG]: 'Config',
  [ROUTES.CONFIG_DEAL_STAGES]: 'Deal Stages',
  [ROUTES.CONFIG_DEAL_TYPES]: 'Deal Types',
  [ROUTES.CONFIG_FUNDS]: 'Funds',
  [ROUTES.CONFIG_KPI]: 'KPIs',
  [ROUTES.CONFIG_KPI_GROUPS]: 'KPI Groups',
  [ROUTES.CONFIG_MISS_REASONS]: 'Miss Reasons',
  [ROUTES.CONFIG_PASS_REASONS]: 'Pass Reasons',
  [ROUTES.CONFIG_SECTORS]: 'Sectors',
  [ROUTES.DEALS]: 'Deals',
  [ROUTES.DEAL_ANALYTICS]: 'Analytics',
  [ROUTES.DEAL_ANALYTICS_DURATION_DETAILS]: 'Deal Duration Details',
  [ROUTES.DEAL_ANALYTICS_KPI]: 'Deal KPIs',
  [ROUTES.DEAL_FLOW]: 'Pipeline',
  [ROUTES.DETAILS_CONFIG]: 'Edit Details Section',
  [ROUTES.FINANCE2]: 'Finance',
  [ROUTES.FINANCE]: 'Finance',
  [ROUTES.FINANCE_ALL_SECURITIES]: 'All Securities',
  [ROUTES.FINANCE_HISTORY]: 'Finance History',
  [ROUTES.FINANCE_RECON]: 'Reconciliation',
  [ROUTES.FINANCE_RECON_METRICS]: 'Metrics',
  [ROUTES.FINANCE_RECON_ROUNDS]: 'Rounds',
  [ROUTES.FINANCE_ROUNDS]: 'Rounds',
  [ROUTES.FINANCE_LEDGER]: 'Ledger',
  [ROUTES.FINANCE_TRANSACTIONS]: 'Transactions',
  [ROUTES.FINANCE_TRANSACTIONS_3]: 'Transactions',
  [ROUTES.FINANCE_TRANSACTION_HISTORY]: 'History',
  [ROUTES.FUND_PROFILES]: 'Fund Profiles',
  [ROUTES.FP_CAPITAL_ALLOCATION]: 'Capital Allocation Model',
  [ROUTES.FP_RESERVES]: 'Reserves',
  [ROUTES.FP_SUMMARY]: 'Summary',
  [ROUTES.FP_WATERFALL]: 'Waterfall',
  [ROUTES.GENERAL_LEDGER]: 'General Ledger',
  [ROUTES.GROWTH_ANALYSIS]: 'Growth Analysis',
  [ROUTES.INTEGRATIONS]: 'Integrations',
  [ROUTES.INTEGRATIONS_APPS]: 'Apps',
  [ROUTES.INTEGRATIONS_APPS_AIRTABLE]: 'Airtable',
  [ROUTES.KPI]: 'Data Collection',
  [ROUTES.KPI_REQUESTS]: 'Requests',
  [ROUTES.KPI_REQUESTS_ARCHIVED]: 'Deleted',
  [ROUTES.KPI_REQUESTS_IN_PROGRESS]: 'In Progress',
  [ROUTES.KPI_RESPONSES]: 'Responses',
  [ROUTES.KPI_RESPONSES_ACCEPTED]: 'Accepted',
  [ROUTES.KPI_RESPONSES_REJECTED]: 'Rejected',
  [ROUTES.KPI_TEMPLATES]: 'Templates',
  [ROUTES.MAPPINGS]: 'Mappings',
  [ROUTES.METRICS_CONFIG]: 'Edit Investment Metrics Section',
  [ROUTES.NOTIFICATION_PREFERENCES]: 'Notification Preferences',
  [ROUTES.PORTFOLIO]: 'Portfolio Reporting',
  [ROUTES.SETTINGS]: 'Settings',
  [ROUTES.UI]: 'UI Components',
} as Record<string, string>;
