export enum ListSharingType {
  PRIVATE = 'private',
  INDIVIDUAL = 'individual',
  TEAM = 'team',
  PUBLIC = 'public',
}

export enum ListType {
  ARCHIVE = 'archive',
  CUSTOM = 'custom',
  DISCOVERY = 'discovery',
  MANAGEMENT = 'management',
}

export enum ListSubtype {
  DATA_SCIENCE = 'dataScience',
  UPLOADED = 'uploaded',
}

export enum ListSortType {
  asc = 'ASC',
  desc = 'DESC',
}

export interface IListDataModel {
  archiveDate: Date | null;
  config: object | null;
  createdAt: string | null;
  createdBy: string | null;
  default: boolean;
  id: number;
  internalSource: number | null;
  meta: object | null;
  name: string;
  numberOfItems: number;
  shareWithTeamIds: number[];
  shareWithUserIds: number[];
  sharing: ListSharingType;
  sortField: string;
  sortOrder: number;
  sortType: ListSortType;
  sourceCompany: string;
  sourceType: string;
  source: string;
  type: ListType;
  subtype: ListSubtype | null;
  updatedAt: string | null;
  updatedBy: string | null;
}

export type IListDataModelUpdatePayload = Omit<IListDataModel, 'id'>;

export function createListDataModel(overrides: Partial<IListDataModel> = {}): IListDataModel {
  return {
    archiveDate: null,
    config: {},
    createdAt: '',
    createdBy: null,
    default: false,
    id: 0,
    internalSource: null,
    meta: {},
    name: '',
    numberOfItems: 0,
    shareWithTeamIds: [],
    shareWithUserIds: [],
    sharing: ListSharingType.PRIVATE,
    sortField: '',
    sortOrder: 0,
    sortType: ListSortType.asc,
    source: '',
    sourceCompany: '',
    sourceType: '',
    subtype: ListSubtype.DATA_SCIENCE,
    type: ListType.DISCOVERY,
    updatedAt: '',
    updatedBy: null,
    ...overrides,
  };
}
