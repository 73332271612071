import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import {
  selectedCommentaryState,
  selectedNoteState,
  selectedRequestIdState,
  showCommentaryEditorState,
  showNoteEditorState,
} from '../../state/UIState';
import { AppName } from '../../../../data-models/integrations-app.data-model';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { ActivityDrawerContents } from './ActivityDrawerContents';
import { ActivityDrawerHeader2 } from './ActivityDrawerHeader2';
import { NoteEditor } from './Notes/NoteEditor';
import { CommentaryEditor } from './Commentaries/CommentaryEditor';
import { ResponseViewer } from './KpiReports/ResponseViewer';

export type ActivityTabs = 'Notes' | 'KPI Responses' | AppName;

const DrawerWrapper = styled('div')<{ isOpen: boolean }>`
  background-color: ${(props) => props.theme.palette.background.paper};
  border-left: thin solid ${(props) => props.theme.colors.neutral['20']};
  display: grid;
  grid-template-rows: 1fr;
  width: ${(props) => (props.isOpen ? '650px' : '0')};
  transition: width 0.25s;
`;

const ContentsWithHeader = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
`;

interface IActivityDrawerProps {
  companyId: number;
  isOpen: boolean;
}

export function ActivityDrawer2(props: IActivityDrawerProps) {
  const { companyId, isOpen } = props;
  const showNoteEditor = useRecoilValue(showNoteEditorState);
  const showCommentaryEditor = useRecoilValue(showCommentaryEditorState);
  const selectedCommentary = useRecoilValue(selectedCommentaryState);
  const selectedNote = useRecoilValue(selectedNoteState);
  const selectedRequestId = useRecoilValue(selectedRequestIdState);

  if (companyId < 0) {
    return null;
  }

  let contents;
  if (showNoteEditor && selectedNote) {
    contents = <NoteEditor companyId={companyId} note={selectedNote} />;
  } else if (showCommentaryEditor && selectedCommentary) {
    contents = <CommentaryEditor companyId={companyId} commentary={selectedCommentary} />;
  } else if (selectedRequestId) {
    contents = (
      <GenericFallbacksWrapper>
        <ResponseViewer requestId={selectedRequestId} />
      </GenericFallbacksWrapper>
    );
  } else {
    contents = (
      <ContentsWithHeader>
        <ActivityDrawerHeader2 companyId={companyId} />
        <ActivityDrawerContents companyId={companyId} />
      </ContentsWithHeader>
    );
  }

  return <DrawerWrapper isOpen={isOpen}>{isOpen ? contents : null}</DrawerWrapper>;
}
