import { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import { FieldValues, FormProvider, UseFormReturn, useForm, useFormContext } from 'react-hook-form';
import { get } from 'lodash-es';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import {
  ErrorMessage,
  FormActionButtons,
  FormField,
  FormWrapper,
  Label,
} from '../../../components/Form/FormComponents';
import { FormFieldFactory } from '../../../components/Form/FormFieldFactory';
import { RendererType } from '../../../data-models/field.data-model';
import { IForm, IFormField } from '../../../view-models/form.view-model';

const modalStyle = {
  display: 'grid',
  gridGap: '1rem',
  width: '50vw',
  padding: '0 2.5rem 2rem',
};

export type DynamicFormTransformer<T extends FieldValues> = (
  form: IForm<T>,
  methods: UseFormReturn<T>
) => IForm<T>;

export interface IConfigModalProps<T extends FieldValues> {
  form: IForm<T>;
  open: boolean;
  onClose: () => void;
  onSubmit: (updatedData: T) => void;
  title: string;
  dynamicFormTransformer?: DynamicFormTransformer<T>;
}
export function ConfigModal<T extends FieldValues>(props: IConfigModalProps<T>) {
  const { form, onClose, open, onSubmit, title } = props;
  const formMethods = useForm({
    values: getInitialValues(form),
  });

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={title}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Box sx={modalStyle}>
        <FormProvider {...formMethods}>
          <ConfigForm
            form={form}
            onSubmit={onSubmit}
            onCancel={onClose}
            dynamicFormTransformer={props.dynamicFormTransformer}
          />
        </FormProvider>
      </Box>
    </BasicDialog>
  );
}

export interface IConfigFormProps<T extends FieldValues> {
  form: IForm<T>;
  onSubmit: (updatedData: T) => void;
  onCancel: () => void;
  dynamicFormTransformer?: DynamicFormTransformer<T>;
}
export function ConfigForm<T extends FieldValues>(props: IConfigFormProps<T>) {
  const { form: staticForm, onCancel, onSubmit } = props;
  const formMethods = useFormContext();
  const form = props.dynamicFormTransformer
    ? props.dynamicFormTransformer(staticForm, formMethods as UseFormReturn<T>)
    : staticForm;
  const { errors } = formMethods.formState;
  const errorCount = Object.keys(errors).length;

  const formElems = form.fields.toArray((item) => {
    const { label, key, description, required } = item;
    const initialValue = getInitialValue(form, item);

    return (
      <FormField key={key ?? label}>
        <Label required={required} description={description}>
          {label}
        </Label>
        <FormFieldFactory formField={item} initialValue={initialValue} />
      </FormField>
    );
  });

  function _onSubmit(data: FieldValues) {
    onSubmit({
      ...form.data,
      ...data,
    } as T);
  }

  return (
    <form onSubmit={formMethods.handleSubmit(_onSubmit)}>
      <FormWrapper>{formElems}</FormWrapper>
      <FormActionButtons>
        <Button type={'submit'} color={'secondary'} variant={'contained'}>
          Save
        </Button>
        <Button onClick={onCancel} color={'secondary'} variant={'outlined'}>
          Cancel
        </Button>
      </FormActionButtons>
      {errorCount > 0 && <ErrorMessage>{errorCount} error(s) in this form, please review.</ErrorMessage>}
    </form>
  );
}

export function useConfigModal() {
  const [open, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback((isEdit: boolean) => {
    setIsEdit(isEdit);
    setIsOpen(true);
  }, []);

  return {
    open,
    handleClose,
    handleOpen,
    isEdit,
  };
}

function getInitialValues(form: IForm<FieldValues>) {
  return form.fields.reduce((res, item) => {
    res[item.key] = getInitialValue(form, item);
    return res;
  }, {} as Record<string, unknown>);
}

function getInitialValue(form: IForm<FieldValues>, item: IFormField<unknown>) {
  const rendererType = item.renderer;
  if (rendererType === RendererType.singleSelect) {
    return get(form.data, item.key) ?? ''; // select complains when the value is undefined
  }
  return get(form.data, item.key);
}
