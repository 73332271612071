import { useRecoilCallback } from 'recoil';
import { set } from 'lodash-es';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { LoadingId } from '../../../../types';
import { getErrorMessage } from '../../../../services/queryHelpers';

import {
  createFieldDataModel,
  createFieldFormConfigDataModel,
  FieldSource,
  IField,
} from '../../../../data-models/field2.data-model';
import { RendererType } from '../../../../data-models/field.data-model';
import {
  createCustomField,
  deleteCustomField,
  updateCustomField,
} from '../../../../services/queries/MaggieAdminPanelQueries';
import { customFieldsListState } from '../../../../services/state/AdminPanel/CustomFieldsState';

export function useCustomFieldsActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const deleteField = useRecoilCallback(
    ({ set }) =>
      async <T>(customField: IField<T>) => {
        actions.startLoading(LoadingId.deleteCustomField);

        try {
          await deleteCustomField(customField);
          set(customFieldsListState, (current) => current?.filter((d) => d.id !== customField.id) ?? []);
          pushSuccessToast({ message: 'Field deleted.' });
        } catch (err) {
          const message = getErrorMessage(err, 'Field deletion failed.');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.deleteCustomField);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  const createField = useRecoilCallback(
    ({ set }) =>
      async <T>(payload: Partial<IField<T>>) => {
        actions.startLoading(LoadingId.createCustomField);
        try {
          const res = await createCustomField(prepareCreatePayload(payload));
          set(customFieldsListState, (current) => [...(current ?? []), res as IField<unknown>]);
          pushSuccessToast({ message: 'Field created.' });
          return res;
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to create field.');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.createCustomField);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  const updateField = useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<IField<unknown>>) => {
        actions.startLoading(LoadingId.updateCustomField);
        try {
          const res = await updateCustomField({
            ...prepareCreatePayload(payload),
            id: payload.id,
          });

          set(customFieldsListState, (current) => {
            const newValues = current?.map((value) => {
              if (value.id === res.id) {
                return res;
              }
              return value;
            });

            return [...(newValues ?? [])];
          });

          pushSuccessToast({ message: 'Field updated.' });
          return res;
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to update field.');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.updateCustomField);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  return { deleteField, createField, updateField };
}

export function prepareCreatePayload<T>(payload: Partial<IField<T>>) {
  const result = createFieldDataModel(payload);
  const rendererT = payload.formMeta?.renderer?.type;

  result.systemMeta = {
    source: FieldSource.Foresight,
    formRefs: payload.systemMeta?.formRefs ?? [],
    gridRefs: payload.systemMeta?.gridRefs ?? [],
  };

  if (rendererT === RendererType.singleSelect || rendererT === RendererType.multiSelect) {
    result.formMeta = createFieldFormConfigDataModel({
      ...payload.formMeta,
      formatter: {
        id: rendererT === RendererType.multiSelect ? 'stringArray' : 'string',
      },
    });
  }

  if (rendererT === RendererType.percent) {
    set(result, 'formMeta.formatter.id', 'percent');
    set(result, 'formMeta.formatter.config.style', 'percent');
  }

  if (result.formMeta?.renderer) {
    result.formMeta.renderer.id = rendererT ?? 'text';
  }
  result.type = mapRenderTypeToPrimitive(rendererT ?? RendererType.text);
  return result;
}

export function mapRenderTypeToPrimitive(renderT: string) {
  switch (renderT) {
    case RendererType.currency:
    case RendererType.number:
    case RendererType.percent:
      return 'number';
    case RendererType.multiSelect:
      return 'stringArray';
    case RendererType.singleSelect:
      return 'string';
    default:
      return 'string';
  }
}
