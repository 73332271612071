import { capitalize } from 'lodash-es';
import { object } from 'yup';
import { RendererType } from '../../../../data-models/field.data-model';
import { ParticipationType, ShareClassSeniorityOption } from '../../../../data-models/scenario.data-model';
import { CalendarShortcut } from '../../../../components/DatePicker/Calendar';
import { createFormField } from '../../../../view-models/form.view-model';
import { FieldSettings } from '../../../../data-fields/CommonFields';
import { partialExitBreakdownFields } from '../../../../schemas/Scenario.schema';
import { ICaptableDataModel } from '../../../../data-models/captable2.data-model';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { ISimpleChoice } from '../../../../data-models/field2.data-model';
import { scenarioDefaultFormItemSettings } from './scenarioFormSettings';

//FIXME: foresightdata.atlassian.net/browse/MAGGIE-5580

export function multipleField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Liquidation Multiple',
    key: `scenarioTransactions.${index}.multiple`,
    renderer: RendererType.multiplier,
    ...scenarioDefaultFormItemSettings(),
  });
}

export function conversionRatioField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Conversion Ratio',
    key: `scenarioTransactions.${index}.conversionRatio`,
    renderer: RendererType.multiplier,
    ...scenarioDefaultFormItemSettings(),
  });
}

export function seniorityOptionField(index: number) {
  return createFormField({
    dataType: 'string',
    label: 'Seniority ',
    key: `scenarioTransactions.${index}.seniorityOption`,
    description: 'Compared to most senior actual/hypothetical round',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(ShareClassSeniorityOption).map((val) => ({
        displayName: capitalize(val),
        value: val,
      })),
    },
    ...scenarioDefaultFormItemSettings(),
  });
}

export function participationField({ key, label = 'Participation Preference', required }: FieldSettings) {
  return createFormField({
    dataType: 'string',
    label,
    key,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(ParticipationType).map((val) => ({
        displayName: capitalize(val),
        value: val,
      })),
    },
    disableClearable: false,
    required: Boolean(required),
    variant: 'form',
    disabled: !required,
  });
}

export function capField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Cap',
    key: `scenarioTransactions.${index}.participationCap`,
    renderer: RendererType.multiplier,
    ...scenarioDefaultFormItemSettings(),
  });
}

export function investmentDateField(index: number) {
  return createFormField({
    dataType: 'string',
    label: 'Investment Date',
    key: `scenarioTransactions.${index}.investmentDate`,
    renderer: RendererType.date,
    rendererMeta: {
      presets: [CalendarShortcut.today, CalendarShortcut.endOfQuarter, CalendarShortcut.endOfYear],
    },
    ...scenarioDefaultFormItemSettings(),
    required: false,
  });
}

export function fundDataInvestedAmountField(transactionIndex: number, fundIndex: number) {
  return createFormField({
    dataType: 'number',
    label: 'Investment Amount',
    key: `scenarioTransactions.${transactionIndex}.fundData.${fundIndex}.investedAmount`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
    ...scenarioDefaultFormItemSettings(),
    required: fundIndex === 0,
  });
}

export function roundSizeField(index: number) {
  return createFormField({
    ...scenarioDefaultFormItemSettings(),
    dataType: 'number',
    label: 'Round Size',
    key: `scenarioTransactions.${index}.roundSize`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
  });
}

export function preMoneyValuationField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Pre-Money Valuation',
    key: `scenarioTransactions.${index}.preMoneyValuation`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
    ...scenarioDefaultFormItemSettings(),
  });
}
export function optionPoolRefreshField(index: number) {
  return createFormField({
    dataType: 'boolean',
    label: 'Option Pool Refresh',
    key: `scenarioTransactions.${index}.optionPoolRefresh`,
    renderer: RendererType.boolean,
    variant: 'form',
  });
}

export function optionPoolField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Option Pool %',
    key: `scenarioTransactions.${index}.optionPoolPercentage`,
    renderer: RendererType.percent,
    ...scenarioDefaultFormItemSettings(),
  });
}

export function pariPassuField(index: number) {
  return createFormField({
    dataType: 'boolean',
    label: 'Pari-Passu',
    key: `scenarioTransactions.${index}.pariPassu`,
    renderer: RendererType.boolean,
    variant: 'form',
  });
}

export function exitValuationField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Exit Valuation',
    key: `scenarioTransactions.${index}.exitValuation`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
    ...scenarioDefaultFormItemSettings(),
  });
}

export function exitDateField(index: number) {
  return createFormField({
    dataType: 'string',
    label: 'Date of Exit',
    key: `scenarioTransactions.${index}.exitDate`,
    renderer: RendererType.date,
    rendererMeta: {
      presets: [CalendarShortcut.today, CalendarShortcut.endOfQuarter, CalendarShortcut.endOfYear],
    },
    ...scenarioDefaultFormItemSettings(),
    required: false,
  });
}

export function optionStrikePriceField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Average Strike Price (Options)',
    key: `scenarioTransactions.${index}.optionStrikePrice`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
    ...scenarioDefaultFormItemSettings(),
  });
}

export function warrantStrikePriceField(index: number) {
  return createFormField({
    dataType: 'number',
    label: 'Average Strike Price (Warrants)',
    key: `scenarioTransactions.${index}.warrantStrikePrice`,
    renderer: RendererType.currency,
    rendererMeta: {
      defaultCurrency: 'USD',
    },
    ...scenarioDefaultFormItemSettings(),
  });
}

interface IPartialSalesFieldsParams {
  captable: ICaptableDataModel;
  transactionIndex: number;
  breakdownIndex: number;
  fundOptionsByShareClassId: Map<number, ISimpleChoice<number>[]>;
  captableInvestmentId?: number | null;
}
export function partialSalesFields({
  transactionIndex,
  breakdownIndex,
  captable,
  fundOptionsByShareClassId,
  captableInvestmentId,
}: IPartialSalesFieldsParams) {
  const schema = object().shape(partialExitBreakdownFields(captable));
  return {
    captableInvestmentId: {
      ...schemaToFormFields(schema, ['captableInvestmentId'])[0],
      key: `scenarioTransactions.${transactionIndex}.partialExitBreakdown.${breakdownIndex}.captableInvestmentId`,
    },
    fundId: {
      ...schemaToFormFields(schema, ['fundId'])[0],
      key: `scenarioTransactions.${transactionIndex}.partialExitBreakdown.${breakdownIndex}.fundId`,
      rendererMeta: {
        values: !captableInvestmentId ? [] : fundOptionsByShareClassId.get(captableInvestmentId),
      },
    },
    numberOfShares: {
      ...schemaToFormFields(schema, ['numberOfShares'])[0],
      key: `scenarioTransactions.${transactionIndex}.partialExitBreakdown.${breakdownIndex}.numberOfShares`,
    },
    numberOfSharesPercentage: {
      ...schemaToFormFields(schema, ['numberOfSharesPercentage'])[0],
      key: `scenarioTransactions.${transactionIndex}.partialExitBreakdown.${breakdownIndex}.numberOfSharesPercentage`,
    },
  };
}
