import { IconButton, Typography } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { cardStyles } from '../../../theme/component-styles';
import { PaneContents, PanelContainer, IPanelProps, ShowMoreButton, PanelHeader } from './Panel';

interface IChartPanelProps extends Omit<IPanelProps, 'paginationConfig'> {
  onExpand?: () => void;
}

export function ChartPanel({
  onExpand,
  title,
  showContentOverflow,
  showMore,
  style,
  children,
}: IChartPanelProps) {
  return (
    <GenericFallbacksWrapper>
      <PanelContainer style={{ ...cardStyles, ...(style ?? {}) }}>
        <PanelHeader>
          <Typography>{title}</Typography>
          {showMore && <ShowMoreButton url={showMore.url} title={'Go to Performance'} />}

          {onExpand && (
            <IconButton
              onClick={onExpand}
              color='secondary'
              sx={{ transform: 'rotate(45deg)', gridArea: 'pages' }}
            >
              <UnfoldMoreIcon />
            </IconButton>
          )}
        </PanelHeader>
        <PaneContents showOverflow={showContentOverflow}>{children}</PaneContents>
      </PanelContainer>
    </GenericFallbacksWrapper>
  );
}
