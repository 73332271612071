import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useRecoilValue } from 'recoil';
import { AirtableSyncType, ISyncedColumnDataModel } from '../../../../data-models/airtable-sync.data-model';
import { StyledColumnBox } from '../components/CommonStyledComponents';
import { allFDFieldsMapState } from '../../state/IntegrationsState';

const StyledColumnGrid = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(8rem, 20rem) 1.5rem minmax(8rem, 20rem) auto;
  gap: 1rem;
  align-items: center;
`;

const StyledColumnContainer = styled('div')`
  display: grid;
  gap: 0.5rem;
`;

export const SyncFieldBox = styled(StyledColumnBox)`
  padding: 0.5rem;
  font-size: 0.875rem;
`;

interface ISyncedColumnsProps {
  columns: ISyncedColumnDataModel[];
  onDisconnect: (id: number) => void;
  disableAction?: boolean;
}

export const SyncedColumns: FC<ISyncedColumnsProps> = ({ columns, disableAction, onDisconnect }) => {
  const allFDFieldsMap = useRecoilValue(allFDFieldsMapState);

  return (
    <StyledColumnContainer>
      <StyledColumnGrid>
        <Typography variant='caption' color={'text.secondary'}>
          Foresight Field
        </Typography>
        <span />
        <Typography variant='caption' color={'text.secondary'}>
          Airtable Columns
        </Typography>
        <span />
      </StyledColumnGrid>

      {columns.map((column) => (
        <StyledColumnGrid key={column.id}>
          <SyncFieldBox>{`${
            allFDFieldsMap.get(column.entityColumn)?.displayName || column.entityColumn
          }`}</SyncFieldBox>
          {column.syncType === AirtableSyncType.editable ? (
            <ArrowBackIcon color='disabled' />
          ) : (
            <ArrowForwardIcon color='disabled' />
          )}
          <SyncFieldBox>{column.airtableColumnName}</SyncFieldBox>
          <Button
            variant='outlined'
            color='secondary'
            style={{ justifySelf: 'end' }}
            onClick={() => onDisconnect(column.id)}
            disabled={disableAction}
          >
            Disconnect
          </Button>
        </StyledColumnGrid>
      ))}
    </StyledColumnContainer>
  );
};
