import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { BooleanRendererYesNo } from '../../../../components/AgTable/cell-renderers/BooleanRenderer';
import { StringListCellRenderer } from '../../../../components/AgTable/cell-renderers/StringListCellRenderer';
import { RendererType } from '../../../../data-models/field.data-model';
import { AgFormatterService } from '../../../../util/ag-formatter-service';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { MONTHS } from '../Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { IKpiCompanyData } from './AllCompaniesData';
import { AllCompaniesMenu } from './components/cellRenderers/AllCompaniesMenu';
import { AssignmentStatusRenderer } from './components/cellRenderers/AssignmentStatusRenderer';
import { RequiredDataRenderer } from './components/cellRenderers/RequiredDataRenderer';

const AllCompaniesSchema = {
  base() {
    return {
      reporting: yup.boolean().optional().label('Reporting'),
      id: yup.number().label('Company'),
      sectorId: yup.number().label('Sector'),
      assigned: yup.boolean(),
      fye: yup.number().optional().label('FYE'),
      respondents: yup.array().of(yup.string()).optional(),
    };
  },
  gridSchema() {
    const base = this.base();

    return yup.object().shape({
      ...base,
      id: base.id.gridMeta({
        renderer: RendererType.companyId,
        enableRowGroup: true,
      }),
      sectorId: base.sectorId.gridMeta({
        renderer: RendererType.id,
        formatter: 'sector',
        enableRowGroup: true,
      }),
      assigned: base.assigned.gridMeta({
        enableRowGroup: true,
      }),
      fye: base.fye.gridMeta({
        renderer: RendererType.text,
      }),
    });
  },
};

export function useAllCompaniesColDefs() {
  const userFormatter = AgFormatterService.get().getFormatterForId('userByEmail');
  const schemaToColDefs = useSchemaToGrid();

  const overrideDefs: Partial<Record<keyof IKpiCompanyData, ColDef<IKpiCompanyData>>> = useMemo(
    () => ({
      reporting: {
        cellRenderer: BooleanRendererYesNo,
      },
      id: {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      respondents: {
        // valueGetter will make sure the set filter works as expected with string arrays
        valueGetter: (params: ValueGetterParams<IKpiCompanyData>) => {
          return params.data?.respondents?.map((user) =>
            userFormatter({ value: user } as ValueFormatterParams)
          );
        },
        valueFormatter: AgFormatterService.get().getFormatterForId('stringArray'),
        filter: 'agSetColumnFilter',
        enableRowGroup: false,
        cellRenderer: RequiredDataRenderer,
        cellRendererParams: {
          valueRenderer: StringListCellRenderer,
          title: 'Respondents',
        },
      },
      assigned: {
        cellRenderer: AssignmentStatusRenderer,
        initialSort: 'asc',
      },
      fye: {
        valueGetter: (params: ValueGetterParams) => {
          return params.data?.fye ? MONTHS[params.data?.fye - 1] : null;
        },
        valueFormatter: ({ value }) => value,
        cellRenderer: RequiredDataRenderer,
      },
    }),
    [userFormatter]
  );

  return useCallback(() => {
    const columnDefs = schemaToColDefs(AllCompaniesSchema.gridSchema(), [
      'id',
      'sectorId',
      'reporting',
      'assigned',
      'fye',
      'respondents',
    ]);

    return withOverrides(columnDefs, overrideDefs).concat({
      headerName: '',
      maxWidth: 70,
      flex: 0,
      menuTabs: [],
      cellRenderer: AllCompaniesMenu,
      pinned: 'right',
    });
  }, [overrideDefs, schemaToColDefs]);
}

export const defaultColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: 'agSetColumnFilter',
  enableRowGroup: true,
  minWidth: 150,
  getQuickFilterText: () => {
    return '';
  },
};
