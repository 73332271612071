import { useRecoilValue } from 'recoil';
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StepSx } from '../../../components/Form/FormComponents';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { IStepperFormButtonsProps } from '../../Finance2/Forms/StepperFormButtons';
import { useProfileBasePath } from '../useProfileBasePath';
import { CPWrapper } from '../components/CompanyProfilesWrapper';
import { AddShareClass } from './AddShareClasses';
import { currentStepState } from './CapTableFormState';
import { AddYourInvestments } from './AddYourInvestments';
import { AddOtherInvestments } from './AddOtherInvestments';
import { ReviewAndUpload } from './ReviewAndUpload';
import { useSyncCaptableFormFromPath } from './useSyncCaptableFormFromPath';

const steps = [
  { label: 'Add Investment', component: <AddShareClass /> },
  { label: 'Add Your Investment Details', component: <AddYourInvestments /> },
  { label: 'Add Other Investment Details', component: <AddOtherInvestments /> },
  { label: 'Review and Save', component: <ReviewAndUpload /> },
];

export function CaptableForm() {
  const currentStep = useRecoilValue(currentStepState);

  return (
    <CPWrapper>
      <Stepper activeStep={currentStep} sx={{ mt: '2rem' }}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box my='1rem'>{steps[currentStep]?.component}</Box>
    </CPWrapper>
  );
}

export function CaptableFormWrapper() {
  const ready = useSyncCaptableFormFromPath();

  if (!ready) {
    return null;
  }
  return <CaptableForm />;
}

export function useCancelButtonProps(): IStepperFormButtonsProps['secondaryActionProps'] {
  const profileBasePath = useProfileBasePath();
  const navigate = useNavigate();

  return {
    label: 'Cancel',
    handler: () => {
      return Promise.resolve(navigate(`${profileBasePath}/${ROUTES.COMPANY_PROFILE_CAP_TABLE}`));
    },
    buttonProps: {
      variant: 'outlined',
      color: 'secondary',
    },
  };
}
