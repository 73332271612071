import { styled } from '@mui/material/styles';
import { formatNumber } from '../utils/formattingFunctions';

const RightAligned = styled('span')`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

type NumberRendererProps = {
  value: number | null | undefined;
};

export const NumberRenderer = ({ value }: NumberRendererProps) => {
  return value === 0 || value ? (
    <RightAligned className='monospace'>{formatNumber(value)}</RightAligned>
  ) : null;
};
