import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import {
  endOfFiscalPeriod,
  getEachDateByPeriodAndFrequency,
  getFormattedFiscalDate,
} from '../../../../CompanyProfiles/utils/financialUtils';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import {
  IKPIRequestDataModel,
  KPIRequestFrequency,
} from '../../../../../data-models/kpi-requests.data-model';
import { ISimpleChoice } from '../../../../../data-models/field2.data-model';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { TemplateAssignment } from '../../../../../schemas/template-assignment-schema';

export function getPeriodOptions({
  period,
  fye,
}: {
  period: KpiPeriod;
  fye: number;
}): ISimpleChoice<string>[] {
  let nPeriods = 13;
  if (period === KpiPeriod.quarter) nPeriods = 5;
  if (period === KpiPeriod.year) nPeriods = 3;

  const dates: Date[] = getEachDateByPeriodAndFrequency({
    frequency: period,
    totalSelectedPeriods: nPeriods,
    fye,
    endDate: endOfFiscalPeriod(new Date(), period, fye - 1),
  }).dates;

  return dates.slice(0, dates.length - 1).reduceRight((acc, date) => {
    acc.push({
      value: formatISODateOnly(date),
      displayName: getFormattedFiscalDate(formatISODateOnly(date), period, fye),
    });
    return acc;
  }, [] as ISimpleChoice<string>[]);
}

export const frequencyToKpiPeriod: Record<KPIRequestFrequency, KpiPeriod> = {
  [KPIRequestFrequency.Quarterly]: KpiPeriod.quarter,
  [KPIRequestFrequency.Annual]: KpiPeriod.year,
  [KPIRequestFrequency.Monthly]: KpiPeriod.month,
  [KPIRequestFrequency.None]: KpiPeriod.month, // ??? TODO: check this
};

interface IIsDuplicateKpiRequestParams {
  allRequests: IKPIRequestDataModel[];
  company?: ICompanyDataModel;
  assignment?: TemplateAssignment;
  period: string | null;
}
export function isDuplicateKpiRequest({
  allRequests,
  company,
  assignment,
  period,
}: IIsDuplicateKpiRequestParams): boolean {
  return (
    !!company &&
    !!assignment &&
    allRequests.some(
      (r) =>
        r.companyId === company.id &&
        r.templateUuid === assignment.templateUuid &&
        period &&
        r.period === period
    )
  );
}

export function getFirstNonDuplicatePeriod({
  allRequests,
  company,
  assignment,
  periodOptions,
}: Omit<IIsDuplicateKpiRequestParams, 'period'> & { periodOptions: ISimpleChoice<string>[] }): number {
  const idx = periodOptions.findIndex(
    (p) => !isDuplicateKpiRequest({ allRequests, company, assignment, period: p.value })
  );
  return idx === -1 ? 0 : idx;
}
