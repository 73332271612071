import { createField3DataModel, ICurrencyMeta } from './field3.data-model';
import { RendererType } from './field.data-model';

export const CapTableDataFields = {
  fullyDilutedShares: createField3DataModel({
    displayName: 'Fully Diluted Shares',
    entityKey: 'fullyDilutedShares',
    dataType: 'number',
    format: RendererType.integer,
  }),
  outstandingShares: createField3DataModel({
    displayName: 'Outstanding Shares',
    entityKey: 'outstandingShares',
    dataType: 'number',
    format: RendererType.integer,
  }),
  cashRaised: createField3DataModel<ICurrencyMeta>({
    displayName: 'Total Raised',
    entityKey: 'cashRaised',
    dataType: 'number',
    format: RendererType.currency,
    meta: {
      defaultCurrency: 'USD',
    },
  }),

  asOfDate: createField3DataModel({
    displayName: 'As of Date',
    entityKey: 'date',
    dataType: 'string',
    format: RendererType.date,
  }),

  // shareClassDetails fields, entityKey needs to be dynamically determined to include last index of array
  lastRoundSize: createField3DataModel<ICurrencyMeta>({
    displayName: 'Last Share Class Size',
    entityKey: 'shareClassDetails.0.cashRaised',
    dataType: 'number',
    format: RendererType.currency,
    meta: {
      defaultCurrency: 'USD',
    },
  }),
};
