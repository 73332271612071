import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { dealSort, fetchDealsByCompanyIds } from '../../../../services/queries/MaggieDealQueries';
import { allDealsState } from '../../../DealFlow2/state/DealboardDataState';
import { DealsByCompanyIdState, DealsForCompanyLoadedState } from '../../../../services/state/DealState';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';

const companyDataLoadedOrLoading = new Set<number>();

// Use this hook to acquire the company deals data and ensure it remains synced with
// the deals from deal board as the user navigates through the page.
export function useFetchDealsForCompany(companyId: number, autoInit = true) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const setDealsByCompanyLoadedState = useSetRecoilState(DealsForCompanyLoadedState(companyId));
  const permSvc = PermissionService.get();

  const initData = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        if (companyDataLoadedOrLoading.has(companyId)) {
          return await snapshot.getPromise(DealsByCompanyIdState(companyId));
        }

        companyDataLoadedOrLoading.add(companyId);

        const companyDeals = permSvc.hasPermission(PermissionKey.canViewDeal)
          ? await fetchDealsByCompanyIds([companyId])
          : [];
        setDealsByCompanyLoadedState(true);

        if (snapshot.getInfo_UNSTABLE(allDealsState).isSet) {
          set(allDealsState, (currentDeals) => {
            const updatedDeals = currentDeals.filter((deal) => deal.companyId !== companyId);
            updatedDeals.push(...(companyDeals as IDealDataModel[]));
            return updatedDeals.sort(dealSort);
          });
        } else {
          set(allDealsState, companyDeals);
          return companyDeals;
        }
      },
    [companyId]
  );

  useEffect(() => {
    if (!autoInit) return;
    initData().finally(() => {
      setIsDataLoaded(true);
    });
  }, [initData, companyId, autoInit]);

  return { isDataLoaded, initData };
}
