import * as yup from 'yup';
import { ListSharingType } from '../../../../../../data-models/list.data-model';
import { requiredMsg } from '../../../../../../pages/Finance/components/TransactionModal/Forms/utils/validationMessages';

export const CompanyListSchema = yup.object().shape({
  internalSource: yup.number().optional().nullable(),
  name: yup.string().required(requiredMsg('Name')),
  sharing: yup.mixed<ListSharingType>().oneOf(Object.values(ListSharingType)).required(),
  source: yup.string().optional().nullable(),
  sourceCompany: yup.string().optional().nullable(),
  sourceType: yup.string().optional().nullable(),
});
