import { atomFamily, selectorFamily } from 'recoil';
import { isEqual, max } from 'date-fns';
import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { fetchCompanyFinancials } from '../../../services/queries/MaggieCompanyQueries';
import { kpiConfigByIdMapState } from '../../../services/state/KPI/KPITemplatesState';
import { getTypedFinancials, isDateString, TypedFinancials } from '../utils/getTypedFinancials';
import { companyState } from '../../../services/state/CompanyState';
import { endOfFiscalPeriod, isEqualToKpiDate } from '../utils/financialUtils';
import { extractISODate } from '../../../util/date-utilts';

// financials item date fields should always be last day of period
export type ICompanyFinancialsByPeriodKey = {
  companyId: number;
  date: string;
  period: KpiPeriod;
};

export const typedFinancialsByPeriodState = selectorFamily<TypedFinancials[], ICompanyFinancialsByPeriodKey>({
  key: 'typedFinancialsByPeriodState',
  get:
    ({ companyId, date, period }) =>
    ({ get }) => {
      const kpiConfigMap = get(kpiConfigByIdMapState);
      const allCompanyFinancials = get(allCompanyFinancialsState(companyId));
      const companyFinancialsForMonth = allCompanyFinancials.filter((item) => item.date === date);
      const fye = get(companyState(companyId))?.fye ?? 12;
      const companyFinancials = filterFinancialsByPeriod(companyFinancialsForMonth ?? [], period, fye);

      return getTypedFinancials(companyFinancials, kpiConfigMap);
    },
});

export const allCompanyFinancialsState = atomFamily<ICompanyFinancialsDataModel[], number>({
  key: 'allCompanyFinancialsState',
  default: selectorFamily({
    key: 'allCompanyFinancialsStateDefault',
    get: (companyId) => async () => {
      const params = {
        companyId,
      };
      const financials = await fetchCompanyFinancials(params);
      const validFinancialsOnly = filterOutInvalidFinancials(financials ?? []);

      return validFinancialsOnly.map((item) => {
        return {
          ...item,
          date: extractISODate(item.date),
        };
      });
    },
  }),
});

export function filterOutInvalidFinancials(financials: ICompanyFinancialsDataModel[]) {
  return financials.filter((item) => {
    const isInvalid = !isDateString(item.date);
    if (isInvalid) {
      console.error('invalid financials date', { item });
    }
    return !isInvalid;
  });
}

export function findLatestDateAndPeriod(financials: ICompanyFinancialsDataModel[], fye: number) {
  let period: KpiPeriod | undefined;

  const latestDate = financials.reduce((res: Date | undefined, financial) => {
    if (financial.period && isKpiPeriod(financial.period!) && financial.section === KpiSection.actual) {
      const endOfPeriod = endOfFiscalPeriod(new Date(financial.date), financial.period!, fye - 1);
      if (!isEqualToKpiDate(financial.date, endOfPeriod)) {
        return res;
      }
      const itemDate = new Date(financial.date);
      if (res === undefined) {
        res = itemDate;
        period = financial.period;
      } else {
        const max = getMaxByHierarchy(
          { date: res, period: period! },
          { date: itemDate, period: financial.period! }
        );
        res = max.date;
        period = max.period;
      }
    }
    return res;
  }, undefined);

  return { latestDate, period };
}

/**
 * @param companyFinancials
 * @param period
 * @param fye - [1-12], reflecting company.fye field value range
 * @returns
 */
export function filterFinancialsByPeriod(
  companyFinancials: ICompanyFinancialsDataModel[],
  period: KpiPeriod,
  fye: number
) {
  return companyFinancials.filter((item) => {
    const endOfPeriod = endOfFiscalPeriod(new Date(item.date), period, fye - 1);
    return item.period === period && isEqualToKpiDate(item.date, endOfPeriod);
  });
}

const supportedPeriods = new Set<KpiPeriod>([KpiPeriod.month, KpiPeriod.quarter, KpiPeriod.year]);
export function isKpiPeriod(period?: KpiPeriod | unknown): period is KpiPeriod {
  return Boolean(period) && supportedPeriods.has(period as KpiPeriod);
}

const periodHierarchy = { [KpiPeriod.quarter]: 1, [KpiPeriod.year]: 2, [KpiPeriod.month]: 3 };
interface IDateAndPeriod {
  date: Date;
  period: KpiPeriod;
}
export function getMaxByHierarchy(item1: IDateAndPeriod, item2: IDateAndPeriod): IDateAndPeriod {
  if (!isEqual(item1.date, item2.date)) {
    const latestDate = max([item1.date, item2.date]);
    return isEqual(latestDate, item1.date) ? item1 : item2;
  }
  return periodHierarchy[item1.period as keyof typeof periodHierarchy] <
    periodHierarchy[item2.period as keyof typeof periodHierarchy]
    ? item1
    : item2;
}
