import { get } from 'lodash-es';
import { BarSeriesOption } from 'echarts';
import { Theme, useTheme } from '@mui/material';
import { BarDataItemOption } from 'echarts/types/src/chart/bar/BarSeries';
import { styled } from '@mui/material/styles';
import { BarChart, IBarChartProps } from '../../../../../components/Charts/Barchart/BarChart';
import { IScenarioRunDataModel } from '../../../../../data-models/scenario-run.data-model';
import { FormatterService } from '../../../../../util/formatter-service';
import { IFormField } from '../../../../../view-models/form.view-model';

const ChartsWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
`;

interface IScenarioChartsProps {
  fields: IFormField<unknown>[];
  scenarioRunData: IScenarioRunDataModel[];
}

export function ScenarioCharts(props: IScenarioChartsProps) {
  const theme = useTheme();
  const { fields, scenarioRunData } = props;

  const charts = getChartsData(fields, scenarioRunData, theme).map((barProps) => (
    <BarChart key={barProps.displayName} {...barProps} />
  ));

  return <ChartsWrapper>{charts}</ChartsWrapper>;
}

const cumulativeFields = new Set(['ownershipPercentage']);

export function getChartsData(
  availableFields: IFormField<unknown>[],
  scenarioRunData: IScenarioRunDataModel[],
  theme: Theme
): IBarChartProps[] {
  return availableFields.map((field) => {
    const { xValues, yBarValues } = scenarioRunData.reduce(
      (res, runData) => {
        const value = get(runData, field.key);
        const color =
          runData.source === 'cap-table' ? theme.colors.primary['60'] : theme.colors.neutral['40'];

        res.xValues.push(runData.name);
        res.yBarValues.push({
          value: cumulativeFields.has(field.key) ? (res.yBarValues.at(-1)?.value ?? 0) + value : value,
          itemStyle: {
            color,
          },
        });

        return res;
      },
      { xValues: [], yBarValues: [] } as { xValues: string[]; yBarValues: BarDataItemOption[] }
    );

    const barData: BarSeriesOption[] = [
      {
        data: yBarValues,
        showBackground: false,
        type: 'bar',
      },
    ];

    const format = FormatterService.get().getFormatterForFormField(field);

    return {
      displayName: field.label ?? field.key,
      key: field.key,
      title: field.label,
      valueFormatter: format,
      xCategoryData: xValues,
      yValueData: barData,
    };
  });
}
