import { FC } from 'react';
import { MostRecentDataChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentDataChart';
import { MostRecentChartData } from '../../hooks/useMostRecentRounds';
import { CHART_COMPONENTS } from '../../../../types';
import { IInvestmentDataModel } from '../../../../data-models/investment.data-model';
import { createCompanyDataModel } from '../../../../data-models/company.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const MostRecentRoundsRoundTracker: FC<IPortfolioChartProps<IInvestmentDataModel>> = (props) => {
  const data = calculateChartData(props.filteredMetrics);

  return <MostRecentDataChart id={CHART_COMPONENTS.MOST_RECENT_ROUNDS_RT} width={640} data={data} />;
};

export function calculateChartData(data: IInvestmentDataModel[]): MostRecentChartData[] {
  const uniqueData = new Map<string, MostRecentChartData>();

  const sortedData = (data ?? [])
    .reduce((res, metric) => {
      if (metric.round) {
        res.push({
          company: createCompanyDataModel({ name: metric.company, logoUrl: metric.logoUrl ?? '' }),
          date: new Date(metric.investmentDate),
          round: metric.round,
        });
      }
      return res;
    }, [] as MostRecentChartData[])
    .sort(({ date: a }, { date: b }) => {
      return b.getTime() - a.getTime();
    });

  for (let i = 0; i < sortedData.length && uniqueData.size < 5; i++) {
    uniqueData.set(sortedData[i].company.name, sortedData[i]);
  }

  return Array.from(uniqueData.values());
}
