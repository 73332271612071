import { atomFamily } from 'recoil';
import { ICompanyMilestoneDataMode } from '../../../data-models/company-milestone.data-model';
import { fetchCompanyMilestones } from '../../../services/queries/MaggieMilestonesQueries';

export const milestonesByCompanyState = atomFamily<ICompanyMilestoneDataMode[] | null, number>({
  key: 'milestonesByCompanyState',
  default: null,
  effects: (companyId) => [
    ({ setSelf }) => {
      try {
        const milestones = fetchCompanyMilestones(companyId);
        setSelf(milestones ?? null);
      } catch (err) {
        console.error(err);
        setSelf(null);
      }
    },
  ],
});
