import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { milestonesByCompanyState } from '../../../state/MilestonesState';
import { SubSection } from '../../../Summary/components/SubSection';
import { MilestoneModal } from '../MilestoneForms/MilestoneModal';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { Milestones } from './Milestones';

export const MilestonesSection = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  const milestones = useRecoilValueLoadable(milestonesByCompanyState(companyId));
  const [showModal, setShowModal] = useState(false);

  return (
    <SubSection
      title='Milestones'
      actionComponent={
        <Button
          color={'secondary'}
          endIcon={<AddIcon />}
          onClick={() => setShowModal(true)}
          variant={'outlined'}
          sx={{ marginLeft: 'auto' }}
        >
          Add milestone
        </Button>
      }
      noCard={!milestones.contents?.length}
      cardPadding={'.75rem .7rem'}
    >
      <GenericFallbacksWrapper>
        <MilestoneModal open={showModal} onClose={() => setShowModal(false)} />
        <Milestones />
      </GenericFallbacksWrapper>
    </SubSection>
  );
};
