import { atom, selector } from 'recoil';
import { FieldDataModel, createFieldDataModel } from '../../../../data-models/field.data-model';
import { selectedFieldsCI } from '../../state/DashboardsState';

export const DummyAllField: FieldDataModel = createFieldDataModel({
  name: 'All',
  displayName: 'All',
  id: -1,
  series: true,
});

export const filterListCI = selector<FieldDataModel[]>({
  key: 'filterListCI',
  get: ({ get }) => {
    const selectedFields = get(selectedFieldsCI);
    const seriesFields = selectedFields.filter((field) => field.series);

    seriesFields.unshift(DummyAllField);

    return seriesFields;
  },
});

export const selectedFilterCI = atom<FieldDataModel>({
  key: 'selectedFilterCI',
  default: DummyAllField,
});
