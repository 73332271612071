// returns true if initialValues fields hold the same values as the form fields, or form fields are empty
export function noChange<T extends object>(initialValues: T | undefined | null, values: T) {
  if (Object.keys(values).length === 0 && !initialValues) return true;
  if (!initialValues && Object.keys(values).length > 0) return false;

  for (const key in values) {
    // use != and == operators instead of !== because number fields are converted to strings, and initialValues[key] may be undefined or null
    if (
      initialValues &&
      key in initialValues &&
      (initialValues[key] != values[key] || initialValues[key] == null)
    )
      return false;
  }
  if (initialValues && Object.keys(initialValues).length !== Object.keys(values).length) return false;
  return true;
}
