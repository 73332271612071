import { PropsWithChildren, useState } from 'react';
import { Button, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IAdvancedSettingsProps extends PropsWithChildren {
  hasErrors?: boolean;
}

export function AdvancedSettings({ children, hasErrors = false }: IAdvancedSettingsProps) {
  const [expanded, setExpanded] = useState(hasErrors);

  return (
    <>
      <div style={{ gridColumn: '1 / -1', justifySelf: 'start', marginBottom: '-1rem' }}>
        <Button
          color='secondary'
          onClick={() => {
            setExpanded((prev) => !prev);
          }}
          title={expanded ? 'Hide advanced settings' : 'Show advanced settings'}
          disabled={hasErrors}
          endIcon={
            <ExpandMoreIcon
              sx={{
                transition: 'transform .2s',
                transform: `${!expanded ? 'rotate(-90deg)' : ''}`,
              }}
            />
          }
        >
          Advanced Settings
        </Button>
      </div>
      <Collapse in={expanded || hasErrors} style={{ gridColumn: '1 / -1' }}>
        {children}
      </Collapse>
    </>
  );
}
