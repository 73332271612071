import { CSSProperties } from 'react';
import { IMUIAvatarProps, MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { ICaptableDataModel } from '../../../../data-models/captable2.data-model';
import carta from '../../../../assets/icons/carta-sm.svg';
import { FMT } from '../../../../util/formatter-service';
import { getCaptableSourceLabel } from '../captable-utils';
import { colors } from '../../../../theme/colors';

const customAvatarStyles: CSSProperties = {
  background: colors.primary[0],
  boxShadow: '0px 4px 6px 0px rgba(16, 37, 62, 0.06)',
  padding: '0.15rem',
};

export function CaptableSourceLogo({ captable }: { captable: ICaptableDataModel }) {
  const source = getCaptableSourceLabel(captable);
  let props = {
    nameParts: [source],
    src: source === 'Carta' ? carta : null,
  };
  if (source === 'User Created') {
    props = {
      ...props,
      nameParts: FMT.format('userByEmail', captable.createdBy ?? '').split(' '),
    };
  } else if (source === 'Carta') {
    props = {
      ...props,
      style: customAvatarStyles,
    } as IMUIAvatarProps;
  }

  return <MUIAvatar {...props} size='medium' />;
}
