import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

const transTypes = new Set([
  'Investment - Return of Capital',
  'Investment - Write Off',
  'Investment - Capitalized Expenses',
]);

export class RealizedCostConfig implements KpiConfig {
  private id = KPI_COMPONENTS.REALIZED_COST as const;
  private title = 'Realized Cost';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    let value = -(
      data?.rawCompanies
        ?.flatMap((c) => c.transactions)
        .reduce((acc, t) => {
          if (transTypes.has(t.transType)) {
            acc += t.amount ?? 0;
          }
          return acc;
        }, 0) ?? 0
    );

    if (value < 0) value = 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
