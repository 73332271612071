import { IGroupOrderDataModel } from '../../../data-models/group-order-data.data-model';

export function groupComparator(
  groupAName: string | null,
  groupBName: string | null,
  groupOrderMap: Map<string, IGroupOrderDataModel>
) {
  if (!groupAName && !groupBName) return 0;
  if (!groupAName) return -1;
  if (!groupBName) return 1;

  const a = groupOrderMap.get(groupAName);
  const b = groupOrderMap.get(groupBName);

  if (a?.packagePriority === b?.packagePriority) {
    return (a?.groupPriority ?? -1) - (b?.groupPriority ?? -1);
  } else {
    return (a?.packagePriority ?? -1) - (b?.packagePriority ?? -1);
  }
}
