import { useRecoilCallback, useRecoilValue } from 'recoil';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { selectedCompanyIdProfile } from '../state/UIState';
import { updateCompanyFund } from '../../../services/queries/MaggieCompanyQueries';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../types';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { IMetricFundDataModel, IMetricsResponseDataModel } from '../../../data-models/metrics.data-model';
import { getErrorMessage } from '../../../services/queryHelpers';
import { metricsResponseByDateState } from '../../PortfolioOverview/state/MetricsState';
import { defaultViewDate } from '../../PortfolioOverview/state/PageState';
import { fetchMetrics } from '../../../services/queries/MaggieMetricsQueries';
import { partialEqualsIgnoreUnsetValues } from '../../../util/partialEquals';

export function useUpdateInvestmentData() {
  const selectedCompanyId = useRecoilValue(selectedCompanyIdProfile);

  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const refetchMetrics = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        try {
          const defaultDate = defaultViewDate();
          if (!snapshot.getInfo_UNSTABLE(metricsResponseByDateState(defaultDate)).loadable?.valueMaybe())
            return;
          const data = await fetchMetrics(defaultDate.toISOString());
          set(metricsResponseByDateState(defaultDate), data);
        } catch (err) {
          console.error(err);
        }
      },
    []
  );

  const updateCompanyFundData = useRecoilCallback(
    ({ set, snapshot }) =>
      async (companyFundId: number, payload: Partial<IMetricFundDataModel>) => {
        try {
          const currentData = snapshot
            .getInfo_UNSTABLE(companyMetricsByIdState(selectedCompanyId))
            .loadable?.valueMaybe();
          const currentFundData = currentData?.metrics
            .at(0)
            ?.fundData.find((fundData) => fundData.id === companyFundId);

          if (currentData && partialEqualsIgnoreUnsetValues(payload, currentFundData)) return;

          actions.startLoading(LoadingId.updateCompanyFund);
          const response = await updateCompanyFund({ companyFundId, payload });

          set(companyMetricsByIdState(selectedCompanyId), (current) => {
            if (!current) return current;
            const { metrics } = current;
            const updatedFundData = metrics[0].fundData.map((fundData) => {
              if (fundData.id !== response.id) {
                return fundData;
              } else {
                // don't return response since it's a different model
                return { ...fundData, ...payload };
              }
            });

            return {
              ...current,
              metrics: [{ ...metrics[0], fundData: updatedFundData }],
            } as IMetricsResponseDataModel;
          });
          pushSuccessToast({ message: 'Investment data successfully updated' });
          refetchMetrics(); // as long as we're updating only from Profiles, we can let it happen in bg
        } catch (e) {
          console.error(e);
          const message = getErrorMessage(e, 'Error updating investment data');
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.updateCompanyFund);
        }
      },
    [actions, pushErrorToast, selectedCompanyId]
  );

  return updateCompanyFundData;
}
