import { Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta, KPI_COMPONENTS } from '../../../../../types';
import { format } from '../kpi-formatter';

export class CountOfDealsConfigRoundTracker implements KpiConfig {
  private id = KPI_COMPONENTS.COUNT_OF_DEALS_RT as const;
  private title = 'Rounds';
  private type = 'integer';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value = data?.filteredData?.length ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
