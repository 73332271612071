import { IScenarioRunResponseDataModel } from '../../data-models/scenario-run.data-model';
import { IScenarioDataModel } from '../../data-models/scenario.data-model';
import { MaggieHttp } from '../MaggieHttp';

export async function fetchScenariosForCompany(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<IScenarioDataModel[]>(`/company/${companyId}/scenarios`);
  return data;
}

export async function createScenario(scenarioPayload: Partial<IScenarioDataModel>) {
  const { data } = await MaggieHttp.maggie.post<Partial<IScenarioDataModel>, IScenarioDataModel>(
    '/scenario',
    scenarioPayload
  );
  return data;
}

export async function updateScenario(scenarioId: number, scenarioPayload: Partial<IScenarioDataModel>) {
  const { data } = await MaggieHttp.maggie.put<Partial<IScenarioDataModel>, IScenarioDataModel>(
    `/scenario/${scenarioId}`,
    scenarioPayload
  );
  return data;
}

export async function deleteScenario(scenarioId: number) {
  return await MaggieHttp.maggie.delete<IScenarioDataModel>(`scenario/${scenarioId}`);
}

export async function runScenario(scenario: Partial<IScenarioDataModel>) {
  const { data } = await MaggieHttp.maggie.post<Partial<IScenarioDataModel>, IScenarioRunResponseDataModel>(
    `/scenario/run`,
    scenario
  );
  return data;
}
