import { styled } from '@mui/material/styles';
import { NotificationPreferences } from './components/NotificationPreferences';

const InnerWrapper = styled('div')`
  height: 100%;
  background-color: #f3f3fe;
  padding: 2rem 2rem 0 2rem;
  height: 100%;
`;

export function NotificationPreferencesContainer() {
  return (
    <InnerWrapper>
      <NotificationPreferences />
    </InnerWrapper>
  );
}
