import { Stack, styled, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import { ISimpleChoice } from '../../../../../data-models/field2.data-model';

const ToggleOptionButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  ...theme.typography.body2,
  background: 'transparent',
  display: 'flex',
  width: '100%',
  '& .MuiToggleButton-root.Mui-selected': {
    background: 'transparent',
    borderColor: theme.colors.secondary[60],
    color: theme.colors.secondary[60],
  },
  '& .MuiToggleButton-root': {
    width: '100px',
    marginRight: '.5rem',
    padding: 0,
    background: theme.colors.neutral[10],
    color: theme.colors.primary[100],
    borderColor: 'transparent',
    borderRadius: '4px !important',
  },
}));
const AMOUNT_SYMBOL_OPTIONS: ISimpleChoice<number>[] = [
  {
    displayName: '400 max',
    value: 400,
  },
  {
    displayName: '3000 max',
    value: 3000,
  },
  {
    displayName: 'No limit',
    value: -1,
  },
];
export function ParagraphConfig({ sectionRef }: { sectionRef: string }) {
  const { colors } = useTheme();
  const { setValue } = useFormContext();
  const maxChartLengthPath = `${sectionRef}.formMeta.renderer.config.maxLength`;
  const value = useWatch({ name: maxChartLengthPath }) as number;

  const onChange = useCallback(
    (event: unknown, value: number) => {
      setValue(maxChartLengthPath, value);
    },
    [maxChartLengthPath, setValue]
  );

  return (
    <Stack gap='.5rem'>
      <Typography variant='body2' color={colors.primary[100]} fontWeight={500}>
        Maximum Length
      </Typography>

      <ToggleOptionButtonGroup
        value={value}
        onChange={onChange}
        color='primary'
        exclusive
        aria-label='Platform'
      >
        {AMOUNT_SYMBOL_OPTIONS.map((amount) => (
          <ToggleButton key={`${amount.value}`} value={amount.value}>
            {amount.displayName}
          </ToggleButton>
        ))}
      </ToggleOptionButtonGroup>
    </Stack>
  );
}
