import { memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { styled } from '@mui/material/styles';

const StyledCell = styled('span')`
  font-weight: bold;
`;

export const MetricCellRenderer: React.FC<ICellRendererParams> = memo(function MetricCellRenderer({
  value,
}: ICellRendererParams) {
  return <StyledCell>{value || ''}</StyledCell>;
});
