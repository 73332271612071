import { Stack, Typography, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function FileUploadConfig() {
  const { colors } = useTheme();

  return (
    <Stack
      direction='row'
      alignItems='center'
      p={'10px'}
      justifyContent='center'
      sx={{
        backgroundColor: colors.primary[5],
        borderRadius: '4px',
      }}
    >
      <InfoOutlinedIcon sx={{ color: colors.neutral[50], mr: '0.5rem' }} />
      <Typography
        variant='body2'
        sx={{
          color: colors.primary[100],
        }}
      >
        Submitter will only be able to add one file
      </Typography>
    </Stack>
  );
}
