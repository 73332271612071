import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { ColDef } from 'ag-grid-community';
import { useFundIdField } from '../../../data-fields/CommonFields';
import { getAgColDefFromGridField } from '../../../components/AgTable/cell-editors/AgColDefFactory';
import { createGridField } from '../../../view-models/grid.view-model';
import { RendererType } from '../../../data-models/field.data-model';
import { useSchemaToColDef } from '../../../util/schema-utils';
import { sortedShareClassesState } from './CapTableFormState';
import { getClientInvestmentsShareClasses as getClientInvestmentsShareClasses } from './InvestmentGridDefs';
import { getGroupedInvestmentsColDefs } from './investment-utils';

export function useGetMyInvestmentsColDefs() {
  const shareClasses = useRecoilValue(sortedShareClassesState);
  const fundIdField = useFundIdField();
  const getColDefs = useSchemaToColDef();

  return useCallback((): ColDef[] => {
    const filteredShareClasses = [...getClientInvestmentsShareClasses(shareClasses)];

    const investmentDefs = getGroupedInvestmentsColDefs(filteredShareClasses);

    const fundIdDisplayColDefs = getColDefs(
      'fundId',
      yup
        .object()
        .shape({ fundId: yup.number() })
        .meta({
          renderer: RendererType.id,
          formatter: 'fund',
        })
        .describe()
    );

    return [
      {
        ...fundIdDisplayColDefs,
        ...getAgColDefFromGridField(
          createGridField({
            ...fundIdField,
            editable: true,
          })
        ),
        checkboxSelection: true,
        headerClass: 'investment-header',
      },
      ...investmentDefs,
    ];
  }, [fundIdField, getColDefs, shareClasses]);
}
