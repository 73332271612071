import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { closestCorners, DndContext } from '@dnd-kit/core';

import { Box } from '@mui/material';
import { IItemWithId } from '../SortableList/SortableList';
import { ListContainer } from './ListContainer';
import { IMultiSortableListProps } from './MultiSortableList';
import { useMultiSortableList } from './useMultiSortableList';

export interface IRenderGroupProps extends PropsWithChildren {
  groupName: string;
}
interface IFixedSizeMultiSortableListProps<T extends IItemWithId>
  extends Omit<IMultiSortableListProps<T>, 'renderGroup'> {
  renderGroup: (props: IRenderGroupProps) => ReactNode;
  groupSize: number;
}
export function FixedSizeMultiSortable<T extends IItemWithId>(props: IFixedSizeMultiSortableListProps<T>) {
  const { items, renderItem, renderGroup } = props;
  const {
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragOverFixedSizeGroup: handleDragOver,
  } = useMultiSortableList(props);

  const elems = useMemo(() => {
    return Object.keys(items).map((groupName) => {
      const value = items[groupName];
      const children = <ListContainer id={groupName} items={value} renderItem={renderItem} />;
      return renderGroup({ groupName, children });
    });
  }, [items, renderGroup, renderItem]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div>{elems}</div>
      </DndContext>
    </Box>
  );
}
