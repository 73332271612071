import SsidChartIcon from '@mui/icons-material/SsidChart';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties, FC } from 'react';

const IconContainer = styled('div')`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.secondary[10]};
  width: 1.5rem;
  height: 1.5rem;
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`;

interface ChartTitleProps {
  title: string;
  style?: CSSProperties;
}

export const ChartTitleWithIcon: FC<ChartTitleProps> = ({ title, style }) => {
  return (
    <TitleContainer style={style}>
      <IconContainer>
        <SsidChartIcon />
      </IconContainer>
      <Typography variant='body1' component='h5'>
        {title}
      </Typography>
    </TitleContainer>
  );
};
