import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { theme } from '../../../../theme';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';

const { primary, neutral, success, secondary, tertiary, warning } = theme.colors;

const CompaniesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  animation: moveInFromTop 0.2s linear;

  @keyframes moveInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-1rem);
    }

    100% {
      opacity: 100;
      transform: translateY(0rem);
    }
  }
`;

const Company = styled('div')<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isSelected }) => (isSelected ? primary[0] : neutral[10])};
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.7rem;
`;

const CompanyColor = styled('div')<{ color: string }>`
  width: 1rem;
  height: 1rem;
  background-color: ${({ color }) => color};
  box-shadow: 0 4px 10px rgba(44, 46, 49, 0.1);
  border-radius: 2px;
`;

export const chartsColorScheme = [
  primary[90],
  primary[45],
  success[60],
  warning[50],
  tertiary[50],
  neutral[50],
  primary[70],
  primary[50],
  tertiary[60],
  secondary[30],
  warning[30],
  success[40],
  neutral[30],
  tertiary[30],
  tertiary[10],
  primary[20],
];

export interface CompanyListProps {
  companies: ICompanyDataModel[];
  onChange: (newSelection: ICompanyDataModel[]) => void;
}

export function CompanyList(props: CompanyListProps) {
  const currentCompanies = props.companies;
  const [selectedCompanies, setSelectedCompanies] = useState(props.companies);

  const handleClick = (clickedCompany: ICompanyDataModel) => {
    const removed = selectedCompanies.filter((company) => company.id !== clickedCompany.id);
    const newSelection =
      removed.length < selectedCompanies.length ? removed : [...selectedCompanies, clickedCompany];

    setSelectedCompanies(newSelection);
    props.onChange?.(newSelection);
  };

  const companyItems = currentCompanies.map((company, index) => {
    const { name, logoUrl } = company;
    const isSelected = selectedCompanies.indexOf(company) !== -1;

    return (
      <Company key={name} role='option' onClick={() => handleClick(company)} isSelected={isSelected}>
        <CompanyColor color={isSelected ? chartsColorScheme[index] : neutral[30]} />
        <MUIAvatar src={logoUrl ?? ''} nameParts={name?.split(' ') ?? []} />
        <Typography variant={'caption'} color={isSelected ? primary[100] : neutral[40]}>
          {name}
        </Typography>
      </Company>
    );
  });

  return <CompaniesWrapper role='listbox'>{companyItems}</CompaniesWrapper>;
}
