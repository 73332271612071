import { IAggFunc, IAggFuncParams } from 'ag-grid-community';
import { ScenarioRunByFundDataModel, ScenarioRunDataModel } from '../../../../schemas/ScenarioRun.schema';

export const runAggFuncs: Record<string, IAggFunc<ScenarioRunDataModel | ScenarioRunByFundDataModel>> = {
  partialNumberOfSharesPercentageAggFunc: (
    params: IAggFuncParams<ScenarioRunDataModel | ScenarioRunByFundDataModel>
  ) => {
    const aggData = params.rowNode.aggData;
    if (!aggData) return;
    if (!aggData.numberOfSharesOwned) return 0;
    return ((aggData.partialNumberOfShares ?? 0) / aggData.numberOfSharesOwned) * 100;
  },
  grossMoicAggFunc: (params: IAggFuncParams<ScenarioRunDataModel | ScenarioRunByFundDataModel>) => {
    const aggData = params.rowNode.aggData;
    const scenarioType = params.context.scenarioType;
    if (!aggData || !scenarioType) return;
    switch (scenarioType) {
      case 'roundOnly': {
        if (!aggData.investedAmount) return 0;
        return (aggData.fmv ?? 0) / aggData.investedAmount;
      }
      case 'roundAndExit':
      case 'exitOnly': {
        if (!aggData.investedAmount) return 0;
        return (aggData.grossProceeds ?? 0) / aggData.investedAmount;
      }
      case 'partialExit':
      case 'roundAndPartialExit': {
        if (!aggData.partialInvestedAmount) return 0;
        return (aggData.grossProceeds ?? 0) / aggData.partialInvestedAmount;
      }
      default: {
        return null;
      }
    }
  },
  postMoneyValuationAggFunc: (params: IAggFuncParams<ScenarioRunDataModel | ScenarioRunByFundDataModel>) => {
    const values = params.values;
    const scenarioType = params.context.scenarioType;
    if (!values || !scenarioType) return;
    switch (scenarioType) {
      case 'roundOnly': {
        return values.at(0);
      }
      case 'roundAndExit': {
        return 0;
      }
      default: {
        return null;
      }
    }
  },
};
