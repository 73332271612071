import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IActivityListItem } from '../../../../../../view-models/activity-list-item';
import { NoteItem } from './NoteItem';

const NotesForMonthWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export interface INotesForMonthProps {
  monthLabel: string;
  notes: IActivityListItem[];
  onSelectNote: (note: IActivityListItem) => void;
  onDelete: (note: IActivityListItem) => void;
}

export function NotesForMonth(props: INotesForMonthProps) {
  const { monthLabel, notes, onDelete, onSelectNote } = props;
  const theme = useTheme();

  return (
    <NotesForMonthWrapper>
      <Typography variant={'subtitle2'} color={theme.colors.neutral[50]}>
        {monthLabel}
      </Typography>
      {notes.map((note) => (
        <NoteItem key={`${note.type}_${note.id}`} note={note} onClick={onSelectNote} onDelete={onDelete} />
      ))}
    </NotesForMonthWrapper>
  );
}
