import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { pageConfigPF } from '../state/PageState';
import { updatePageConfig } from '../../../services/queries/MaggieViewQueries';

export function useReorderViews() {
  const setPageConfig = useSetRecoilState(pageConfigPF);

  return useCallback(
    async (newOrder: string[]) => {
      const newPageConfig = await updatePageConfig({ order: newOrder });

      setPageConfig(newPageConfig);
    },
    [setPageConfig]
  );
}
