import { styled } from '@mui/material/styles';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';

const InputBaseColor = '#fffa';

export const StyledNavbarSearch = styled(SearchInput)`
  width: 100%;
  background-color: rgb(88, 103, 192);
  border-radius: 4px;
  & .MuiOutlinedInput-root {
    color: #fff;
    & fieldset {
      border-color: rgba(0, 0, 0, 0);
      border-width: 'thin';
    }
    &.Mui-focused fieldset {
      border-color: ${InputBaseColor} !important;
    }
    &:hover fieldset {
      border-color: InputBaseColor;
    }
  }
  & .MuiSvgIcon-root.MuiSvgIcon-colorDisabled {
    fill: ${InputBaseColor};
  }

  & input {
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
  }
`;
