import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import {
  ITransactionViewModel,
  toTransactionDataModel,
  useFromTransactionDataModel,
} from '../../../view-models/transaction-form.view-model';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { IUpdateTransactionPayload } from '../../../services/queries/MaggieTransactionQueries';
import { TransactionForm, useTransactionResolver } from './TransactionFormStep';

interface IEditTransactionFormProps {
  transaction: ITransactionDataModel;
  onSubmit: (transaction: IUpdateTransactionPayload) => Promise<ITransactionDataModel | undefined>;
  onCancel: () => void;
}

export const EditTransactionForm: FC<IEditTransactionFormProps> = ({ transaction, onSubmit, onCancel }) => {
  const resolver = useTransactionResolver();
  const [loading, setLoading] = useState(false);
  const getViewModelFromTransaction = useFromTransactionDataModel();

  const handleSubmit = useCallback(
    async (data: Partial<ITransactionViewModel>) => {
      setLoading(true);
      const payload = toTransactionDataModel(data);
      await onSubmit(payload as IUpdateTransactionPayload);
      setLoading(false);
    },
    [onSubmit]
  );

  const methods = useForm({
    defaultValues: async () => await getViewModelFromTransaction(transaction),
    mode: 'all',
    resolver,
  });

  return (
    <FormProvider {...methods}>
      <FormFieldsContainer>
        <TransactionForm title='' />
      </FormFieldsContainer>
      <StickyFormButtons
        onCancel={onCancel}
        onSubmit={methods.handleSubmit(handleSubmit)}
        loading={loading}
      />
    </FormProvider>
  );
};
