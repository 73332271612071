import {
  createFieldDataModel,
  FieldEntity,
  FieldSource,
  IField,
  ITextFieldConfig,
} from '../../../../data-models/field2.data-model';
import { RendererType } from '../../../../data-models/field.data-model';

export function getCustomTextFieldInitialValues(values: Partial<IField<string, ITextFieldConfig>> = {}) {
  return createFieldDataModel<string, ITextFieldConfig>({
    type: 'string',
    entity: FieldEntity.company,
    formMeta: {
      defaultValue: null,
      formatter: {
        id: 'string',
      },
      renderer: {
        id: 'text',
        type: RendererType.text,
        config: {
          placeholder: null,
          maxLength: 0,
        },
      },
      required: null,
    },
    systemMeta: {
      gridRefs: [],
      formRefs: [],
      source: FieldSource.Foresight,
    },
    ...values,
  });
}
