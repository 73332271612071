import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { kpiTemplateAssignmentsListState } from '../../../../services/state/KPI/KPICompaniesState';
import { portCosByIdMapState } from '../../../../services/state/PortCosState';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { TemplateAssignment } from '../../../../schemas/template-assignment-schema';

export interface IAssignedRowData
  extends Pick<TemplateAssignment, 'templateUuid' | 'frequency' | 'reminderThreshold' | 'id'> {
  company: ICompanyDataModel;
}

export function useAssignedCompaniesData() {
  const templates = useRecoilValue(kpiTemplateAssignmentsListState);
  const portcos = useRecoilValue(portCosByIdMapState);

  return useMemo(() => {
    return templates?.reduce((acc, t) => {
      const company = portcos.get(t.companyId);
      if (company) {
        acc.push({
          ...t,
          company,
        });
      }
      return acc;
    }, [] as IAssignedRowData[]);
  }, [templates, portcos]);
}
