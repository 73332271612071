import { set } from 'lodash-es';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
  KpiConfigPeriod,
  KpiPeriod,
  KpiPeriodExtended,
  KpiSection,
} from '../../../../../data-models/company-financials.data-model';
import { IField } from '../../../../../data-models/field2.data-model';
import { kpiConfigByIdMapState, kpiConfigState } from '../../../../../services/state/KPI/KPITemplatesState';
import { allCompanyFinancialsState } from '../../../state/CompanyFinancialsState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { getTypedFinancials, TypedFinancials } from '../../../utils/getTypedFinancials';
import { FinancialsDisplaySettings } from '../components/FinancialsSettingsState';

export type FinancialType = KpiSection | KpiPeriodExtended | 'growth';

export interface IFinancialsRowData2 extends IField<unknown> {
  valuesByDate2: Record<string, Record<string, Record<KpiSection, TypedFinancials>>>;
}

export function useFinancialsGridData() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const companyFinancials = useRecoilValue(allCompanyFinancialsState(companyId));
  const kpiConfigMap = useRecoilValue(kpiConfigByIdMapState);
  const typedFinancials = getTypedFinancials(companyFinancials, kpiConfigMap);
  const allKpis = useRecoilValue(kpiConfigState);

  return useCallback(
    (
      period: KpiPeriod,
      displaySettings = {} as Partial<FinancialsDisplaySettings>
    ): IFinancialsRowData2[] => {
      const filterFn = displaySettingsToFinancialsFilter(displaySettings, period);
      const dataMap2 = typedFinancials.filter(filterFn).reduce(
        (acc, item) => {
          return set(acc, [item.kpiId, item.date, item.period!, item.section!], item);
        },
        {} as Record<number, IFinancialsRowData2['valuesByDate2']>
      );

      return allKpis
        .filter((kpi) => {
          // If show reportedOnly check if there is no data for the KPI
          return !displaySettings.reportedOnly || kpi.id in dataMap2;
        })
        .map((kpi) => {
          return { ...kpi, valuesByDate2: dataMap2[kpi.id] };
        });
    },
    [allKpis, typedFinancials]
  );
}

function displaySettingsToFinancialsFilter(
  displaySettings: Partial<FinancialsDisplaySettings>,
  selectedPeriod: KpiPeriod
): (item: TypedFinancials) => boolean {
  const includedPeriods = new Set<KpiPeriod | KpiPeriodExtended>([selectedPeriod]);
  if (displaySettings.showPinnedYears) {
    includedPeriods.add(KpiPeriod.year);
  }
  if (displaySettings.showYTD) {
    includedPeriods.add(KpiConfigPeriod.CYTD);
  }
  if (displaySettings.showLTM) {
    includedPeriods.add(KpiConfigPeriod.LTM);
  }
  if (displaySettings.showGrowth) {
    includedPeriods.add(`Growth${selectedPeriod}`);
    if (displaySettings.showPinnedYears) {
      includedPeriods.add(`Growth${KpiPeriod.year}`);
    }
  }

  const standardPeriods = new Set(Object.values(KpiPeriod));
  return function isFinancialIncluded(financials: TypedFinancials) {
    if (!financials.section || !financials.period) {
      return false;
    }
    if (!includedPeriods.has(financials.period)) {
      return false;
    }
    if (!displaySettings.showBudget && financials.section === KpiSection.budget) {
      return false;
    }
    if (!standardPeriods.has(financials.period as KpiPeriod) && financials.section !== KpiSection.actual) {
      return false;
    }

    return true;
  };
}
