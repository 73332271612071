import { useRecoilValue } from 'recoil';
import { dealStagesByIdMapState } from '../../pages/DealFlow2/state/DealboardDataState';
import { FieldEntity, ISimpleChoice } from '../../data-models/field2.data-model';
import { RendererType } from '../../data-models/field.data-model';
import { createField3DataModel, ISelectMeta } from '../../data-models/field3.data-model';

enum StaticFieldsId {
  DealStage = -10,
  FundType = -20,
}

export function useDealStageField() {
  const stages = useRecoilValue(dealStagesByIdMapState);
  const options: ISimpleChoice<number>[] = Array.from(stages.values()).map((stage) => {
    return {
      value: stage.id,
      displayName: stage.name,
    };
  });

  return createField3DataModel<ISelectMeta<number>>({
    dataType: 'array',
    id: StaticFieldsId.DealStage,
    entity: FieldEntity.deal,
    entityKey: 'dealStageIds',
    displayName: 'Deal Stages',
    format: RendererType.multiSelect,
    meta: {
      multi: true,
      values: options,
    },
  });
}
