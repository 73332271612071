import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { calculateXIRR } from '../../providers/calculateXIRR';
import { minMax } from './StandardIterators';

export function adjustedRealizedGL(metrics: IMetricsDataModel) {
  return (
    metrics.distributions +
    proceeds(metrics) -
    (realizedValueAdjusted(metrics) + metrics.interest + metrics.expenses)
  );
}

export function adjustedRealizedMOIC(metrics: IMetricsDataModel) {
  const _realizedValueAdjusted = realizedValueAdjusted(metrics);
  if (_realizedValueAdjusted + metrics.interest + metrics.expenses !== 0) {
    metrics.adjustedRealizedMOIC = Number(
      (metrics.adjustedRealizedGL / (_realizedValueAdjusted + metrics.interest + metrics.expenses)).toFixed(2)
    );
  }

  return 0;
}

export function adjustedUnrealizedGL(metrics: IMetricsDataModel) {
  return metrics.fmv - (unrealizedValue(metrics) + metrics.interest + metrics.expenses);
}

export function adjustedUnrealizedMOIC(metrics: IMetricsDataModel) {
  const _unrealizedValue = unrealizedValue(metrics);
  if (_unrealizedValue + metrics.interest + metrics.expenses !== 0) {
    return Number(
      (adjustedUnrealizedGL(metrics) / (_unrealizedValue + metrics.interest + metrics.expenses)).toFixed(2)
    );
  }

  return 0;
}

export function dpi(metrics: IMetricsDataModel) {
  return metrics.distributions / (metrics.amountInvested || 1);
}

export function followOnInvestment(metrics: IMetricsDataModel) {
  return metrics.amountInvested - (metrics.initialInvestment || 0);
}

export function investmentAge(metrics: IMetricsDataModel, asOfDate: Date) {
  if (metrics.initialInvestmentDate === '') return 0;
  return asOfDate.getFullYear() - new Date(metrics.initialInvestmentDate).getFullYear();
}

export function investmentStatus(metrics: IMetricsDataModel) {
  const realizedValue = realizedValueAdjusted(metrics);
  if (metrics.fmv <= 0.9) {
    return 'Realized';
  } else if (metrics.fmv > 0.9 && realizedValue > 0.9) {
    return 'Partially Realized';
  } else {
    return 'Unrealized';
  }
}

export function irr(metrics: IMetricsDataModel, asOfDate: Date) {
  const { cashflows, fmv } = metrics;

  cashflows.push({
    date: asOfDate,
    amount: fmv,
  });

  return cashflows.length < 2 ? 0 : calculateXIRR(cashflows);
}

export function moic(metrics: IMetricsDataModel) {
  return (metrics.fmv + metrics.distributions + metrics.escrowAmount) / (metrics.amountInvested || 1);
}

export function proceeds(context: IMetricsDataModel) {
  return context.acquisitionShares > 0 ? context.fmv : 0;
}

export function realizedGL(metrics: IMetricsDataModel) {
  return metrics.distributions - realizedValueAdjusted(metrics) || 0;
}

export function realizedMOIC(metrics: IMetricsDataModel) {
  return Number((metrics.distributions / realizedValueAdjusted(metrics)).toFixed(2));
}

export function realizedValueAdjusted(metrics: IMetricsDataModel) {
  if (metrics.realizedValue < 0) {
    return 0;
  } else if (metrics.realizedValue > metrics.amountInvested) {
    return metrics.amountInvested;
  }
  return metrics.realizedValue;
}

export function realizedAmountWithEscrow(metrics: IMetricsDataModel) {
  return metrics.distributions + metrics.escrowAmount;
}

export function rvpi(metrics: IMetricsDataModel) {
  return metrics.fmv / (metrics.amountInvested || 1);
}

export function totalCost(metrics: IMetricsDataModel, restructureAmount: number) {
  return metrics.amountInvested + metrics.interest + restructureAmount;
}

export function totalReturn(metrics: IMetricsDataModel) {
  return totalValue(metrics) - metrics.amountInvested;
}

export function totalValue(metrics: IMetricsDataModel) {
  return metrics.fmv + metrics.distributions + metrics.escrowAmount;
}

export function tvpi(metrics: IMetricsDataModel) {
  return (metrics.fmv + metrics.distributions) / (metrics.amountInvested || 1);
}

export function unrealizedCost(metrics: IMetricsDataModel, restructureAmount: number) {
  return totalCost(metrics, restructureAmount) - metrics.realizedCost;
}

export function unrealizedGL(metrics: IMetricsDataModel) {
  return metrics.fmv - unrealizedValue(metrics) || 0;
}

export function unrealizedMOIC(metrics: IMetricsDataModel) {
  return Number((metrics.fmv / unrealizedValue(metrics)).toFixed(2));
}

export function unrealizedValue(metrics: IMetricsDataModel) {
  return minMax(metrics.amountInvested - realizedValueAdjusted(metrics), 0);
}

export function navPercentage(metrics: IMetricsDataModel, totalFMV: number) {
  return Number(((metrics.fmv * 100) / totalFMV).toFixed(2));
}

export const metricsFormulas = {
  adjustedRealizedGL,
  adjustedRealizedMOIC,
  adjustedUnrealizedGL,
  adjustedUnrealizedMOIC,
  dpi,
  followOnInvestment,
  investmentAge,
  investmentStatus,
  irr,
  moic,
  proceeds,
  realizedGL,
  realizedMOIC,
  realizedValueAdjusted,
  realizedAmountWithEscrow,
  rvpi,
  totalCost,
  totalReturn,
  totalValue,
  tvpi,
  unrealizedCost,
  unrealizedGL,
  unrealizedMOIC,
  unrealizedValue,
};
