export interface KeyResultInitialValues {
  id?: number;
  objectiveId?: number;
  name: string;
  status: string;
  displayType: string;
  value: number | null;
  goal: number | null;
}

export const keyResultInitialValues = { name: '', status: '', displayType: '', value: null, goal: null };
