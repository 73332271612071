import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { rest } from 'lodash-es';
import { KpiPeriod } from '../../../../../../data-models/company-financials.data-model';
import { ZINDEX } from '../../../../../../constants/styles';

export const PERIODS_MAP: Record<KpiPeriod, number[]> = {
  [KpiPeriod.month]: [6, 12, 24],
  [KpiPeriod.quarter]: [4, 8, 12],
  [KpiPeriod.year]: [2, 3, 4, 5],
};

export function SelectPeriodDropdown({
  period,
  selectedAmountOfPeriodsIdx,
  onChange,
}: {
  period: KpiPeriod;
  selectedAmountOfPeriodsIdx: number;
  onChange: (e: SelectChangeEvent<number>) => void;
}) {
  return (
    <Select
      sx={{
        fontSize: 14,
        width: '220px',
      }}
      size={'small'}
      id='select-period-dropdown'
      placeholder=''
      value={selectedAmountOfPeriodsIdx ?? ''}
      displayEmpty
      onChange={onChange}
      MenuProps={{ sx: { zIndex: ZINDEX.MENU_INDEX } }}
      {...rest}
    >
      {PERIODS_MAP[period].map((value, idx) => {
        const amountSelected = PERIODS_MAP[period][idx];
        const periodWithAmountLabel = `${amountSelected} ${period}s`;

        return (
          <MenuItem key={idx} value={idx}>
            {periodWithAmountLabel}
          </MenuItem>
        );
      })}
    </Select>
  );
}
