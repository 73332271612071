import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Button, Divider, Typography, Chip, useTheme } from '@mui/material';
import { noop } from 'lodash-es';
import { Loader } from '../../../../components/Loader/Loader';
import { ErrorOccurredMessage } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import {
  indexColumnMatchesState,
  selectedEntityState,
  selectedTableState,
} from '../../state/ConfigurationState';
import {
  AirtableSyncType,
  IAirTableSyncColumnDataModel,
  createAirTableSyncColumnDataModel,
} from '../../../../data-models/airtable-sync.data-model';
import { ColumnSyncher } from '../components/ColumnSyncher';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { useAirtableActions } from '../hooks/useAirtableActions';
import { SlideUpPopper } from '../../../../components/SlideUpPopper';
import {
  airTableBasesMapState,
  airtableColumnsMapState,
  foresightColumnsMapState,
  identifierFDColumnsForSelectedEntityState,
  syncColumnsState,
} from '../../state/IntegrationsState';
import { useResetConfigState } from '../hooks/useResetConfigState';
import {
  getForesightIndexColumns,
  getSyncPayload,
  isFDColumnSyncedAsEditable,
  filterOutIndexColumns,
  isSyncEstablished,
  getInfoMessage,
  getSyncedColumns,
} from '../utils/syncUtils';
import { usersByEmailMapState } from '../../../../services/state/AppConfigState';
import { StyledBorderCard, StyledSyncGrid, SyncFormCard } from '../components/CommonStyledComponents';
import { ConfirmSyncDialog } from './ConfirmSyncDialog';

export const SyncColumns: FC = () => {
  const syncedColumnsLoadable = useRecoilValueLoadable(syncColumnsState);
  const foresightColumns = syncedColumnsLoadable.valueMaybe()?.foresightColumns;
  const airtableColumns = syncedColumnsLoadable.valueMaybe()?.airtableColumns;
  const selectedTable = useRecoilValue(selectedTableState);
  const selectedBase = useRecoilValue(airTableBasesMapState)?.get(selectedTable?.baseId || '');
  const selectedEntity = useRecoilValue(selectedEntityState);
  const indexColumns = useRecoilValue(indexColumnMatchesState);
  const { createSync } = useAirtableActions();
  const resetConfigState = useResetConfigState();

  const nonIndexAirtableColumns = useMemo(
    () => (!airtableColumns ? [] : filterOutIndexColumns(airtableColumns, indexColumns)),
    [airtableColumns, indexColumns]
  );

  const [filteredNonIndexATFields, setFilteredNonIndexATFields] = useState(nonIndexAirtableColumns || []);

  const selectedFDIndexCols = useMemo(
    () => new Set(getForesightIndexColumns(indexColumns ?? [])),
    [indexColumns]
  );
  const allFDIndexColumnsForEntity = useRecoilValue(identifierFDColumnsForSelectedEntityState);

  const foresightColumnsMap = useRecoilValue(foresightColumnsMapState);
  const airtableColumnsMap = useRecoilValue(airtableColumnsMapState);
  const usersByEmailMap = useRecoilValue(usersByEmailMapState);
  // map airtable field ID's to IAirTableSyncColumnDataModel
  const [syncFieldsMap, setSyncFieldsMap] = useState<Record<string, IAirTableSyncColumnDataModel>>(() =>
    getSyncedColumns(airtableColumns ?? [])
  );

  const [showConfirm, setShowConfirm] = useState(false);

  const options = useMemo(
    () => (!foresightColumns ? [] : foresightColumns.filter((col) => !selectedFDIndexCols.has(col.value))),
    [foresightColumns, selectedFDIndexCols]
  );

  // filter out fields that are already synced
  const newSyncMap = useMemo(() => {
    if (!foresightColumnsMap || !selectedTable) return {};
    const newSyncMap = { ...syncFieldsMap };
    Object.entries(syncFieldsMap).forEach(([airtableColId, syncField]) => {
      if (isSyncEstablished(airtableColumnsMap.get(airtableColId)!, selectedTable, syncField.entityColumn)) {
        delete newSyncMap[airtableColId];
      }
    });
    return newSyncMap;
  }, [airtableColumnsMap, foresightColumnsMap, selectedTable, syncFieldsMap]);

  // keep track of all editable FD columns in current sync
  // map of fd column id to airtable column id
  const editableFDColumnsInCurrentSync = useMemo(() => {
    const editableColumnsMap = new Map<string, string[]>();
    Object.entries(syncFieldsMap).reduce((acc, [airtableColId, syncField]) => {
      if (syncField.syncType === AirtableSyncType.editable) {
        if (!acc.has(syncField.entityColumn)) {
          acc.set(syncField.entityColumn, [airtableColId]);
        } else {
          acc.get(syncField.entityColumn)!.push(airtableColId);
        }
      }
      return acc;
    }, editableColumnsMap);
    return editableColumnsMap;
  }, [syncFieldsMap]);

  const errorMessage = useMemo(() => {
    //
    if ([...editableFDColumnsInCurrentSync.values()].some((airtableColIds) => airtableColIds.length > 1)) {
      return 'Error: duplicate editable columns';
    } else {
      return null;
    }
  }, [editableFDColumnsInCurrentSync]);

  const handleSync = useCallback(async () => {
    if (!selectedBase || !selectedTable || !selectedEntity || !indexColumns) return;
    const payload = getSyncPayload({
      selectedBase,
      selectedTable,
      selectedEntity,
      matches: indexColumns,
      syncFields: Object.values(newSyncMap),
    });

    await createSync(payload);
    setShowConfirm(false);
    resetConfigState();
  }, [createSync, indexColumns, newSyncMap, resetConfigState, selectedBase, selectedEntity, selectedTable]);

  const nSelections = Object.keys(newSyncMap).length;
  const disableSync = nSelections === 0 || Boolean(errorMessage);

  const columnPairs = useMemo(() => {
    if (Object.keys(newSyncMap).length === 0) return [];
    return Object.values(newSyncMap).map((syncField) => {
      const fdFieldValue = syncField.entityColumn;

      const fdColumnName = foresightColumnsMap.get(fdFieldValue)?.displayName ?? fdFieldValue;
      return {
        col1Name: fdColumnName,
        col2Name: syncField.airtableColumnName,
        syncType: syncField.syncType,
      };
    });
  }, [foresightColumnsMap, newSyncMap]);

  const { colors } = useTheme();

  if (syncedColumnsLoadable.state === 'loading') return <Loader />;
  if (syncedColumnsLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (!foresightColumns || !airtableColumns || !selectedTable || !foresightColumnsMap) return null;

  return (
    <>
      <ConfirmSyncDialog
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={handleSync}
        titleComponent={
          <Typography variant='h6'>{`Confirm Synchronization for "${selectedTable.name}" Tables`}</Typography>
        }
        columnPairs={columnPairs}
      />
      <SyncFormCard
        title={`Synchronize "${selectedTable.name}" Columns with Foresight`}
        chipComponent={
          <Chip size='medium' label={`${nSelections} column${nSelections > 1 ? 's' : ''} selected`} />
        }
      >
        <StyledBorderCard style={{ margin: '1rem 0 1.5rem' }}>
          <Typography variant='body2'>
            Please select the Foresight column(s) that you want to populate with Airtable data
          </Typography>
          <StyledSyncGrid>
            <SearchInput
              placeholder='Search Airtable columns'
              onChange={(value) => {
                if (!value) {
                  return setFilteredNonIndexATFields(nonIndexAirtableColumns);
                }
                setFilteredNonIndexATFields(
                  nonIndexAirtableColumns.filter((field) =>
                    field.displayName.toLowerCase().includes(value.toLowerCase())
                  )
                );
              }}
              style={{ margin: '1rem 1rem 0 0', width: 'unset' }}
            />
          </StyledSyncGrid>
          <Divider style={{ margin: '1.75rem 0' }} />
          <StyledSyncGrid style={{ marginBottom: '0.3rem' }}>
            <Typography variant='caption' color={colors.neutral[50]}>
              Foresight Field
            </Typography>
            <span />
            <Typography variant='caption' color={colors.neutral[50]} style={{ marginLeft: '1.5rem' }}>
              Airtable Columns
            </Typography>
          </StyledSyncGrid>
          {[...indexColumns.entries()].map(([foresightColId, airtableCol]) => {
            const value = foresightColumnsMap.get(foresightColId) ?? {
              value: foresightColId,
              displayName: foresightColId,
              isSynced: false,
            };
            return (
              <ColumnSyncher
                key={foresightColId}
                fixedColumnName={airtableCol.name}
                syncTypeValue={AirtableSyncType.readOnly}
                onSyncTypeChange={noop}
                options={[value]}
                value={value}
                disabled
                onChange={noop}
                info={getInfoMessage({
                  column: foresightColumnsMap.get(foresightColId)!,
                  usersByEmailMap,
                  isIndexColumn: true,
                })}
              />
            );
          })}
          {filteredNonIndexATFields.map((airtableField) => {
            const syncField = syncFieldsMap[airtableField.value];
            const foresightFieldId = syncField?.entityColumn ?? null;
            const foresightCol = foresightColumnsMap.get(foresightFieldId) ?? null;

            const isIndexColumn = selectedFDIndexCols.has(foresightFieldId);

            const isIndexColumnInAnyTable = allFDIndexColumnsForEntity.has(foresightFieldId);
            const isAlreadySynced = isSyncEstablished(
              airtableField,
              selectedTable,
              foresightCol?.value ?? null
            );
            const isReadOnly =
              (isFDColumnSyncedAsEditable(foresightCol) && !isAlreadySynced) ||
              isIndexColumn ||
              isIndexColumnInAnyTable;

            const info = getInfoMessage({
              column: foresightCol,
              usersByEmailMap,
              isReadOnly,
              isIndexColumn: isIndexColumn || isIndexColumnInAnyTable,
            });
            const hasError =
              editableFDColumnsInCurrentSync.get(foresightFieldId) &&
              editableFDColumnsInCurrentSync.get(foresightFieldId)!.length > 1;
            return (
              <ColumnSyncher
                key={airtableField.value}
                fixedColumnName={airtableField.displayName}
                syncTypeValue={isReadOnly ? AirtableSyncType.readOnly : syncField?.syncType ?? null}
                onSyncTypeChange={(value) => {
                  if (value && syncField) {
                    setSyncFieldsMap((prev) => ({
                      ...prev,
                      [airtableField.value]: { ...syncField, syncType: value },
                    }));
                  }
                }}
                options={options}
                value={foresightCol}
                disabled={isAlreadySynced}
                readonly={isReadOnly}
                isAlreadySynced={isAlreadySynced}
                info={info}
                onChange={(newValue) => {
                  if (syncField && newValue) {
                    return setSyncFieldsMap((prev) => ({
                      ...prev,
                      [airtableField.value]: { ...syncField, entityColumn: newValue.value },
                    }));
                  } else if (syncField) {
                    const newMap = { ...syncFieldsMap };
                    delete newMap[airtableField.value];
                    return setSyncFieldsMap(newMap);
                  } else if (newValue) {
                    return setSyncFieldsMap((prev) => ({
                      ...prev,
                      [airtableField.value]: createAirTableSyncColumnDataModel({
                        syncType: AirtableSyncType.readOnly,
                        entityColumn: newValue!.value,
                        airtableColumnId: airtableField.value,
                        airtableColumnName: airtableField.displayName,
                      }),
                    }));
                  }
                }}
                hasError={hasError}
              />
            );
          })}
          {errorMessage && (
            <Typography variant='caption' color='error'>
              {errorMessage}
            </Typography>
          )}
        </StyledBorderCard>

        <Button
          variant='contained'
          color='secondary'
          disabled={disableSync}
          onClick={() => setShowConfirm(true)}
        >
          Sync
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          style={{ marginLeft: '1rem' }}
          onClick={() => {
            resetConfigState();
          }}
        >
          Cancel
        </Button>
      </SyncFormCard>
      <SlideUpPopper
        message={
          <Typography variant='caption' textAlign={'center'}>
            <>{`We are almost finished 🙂 Please select the columns that you want to sync with Foresight workspace.`}</>
            <br />
            <>{`You will be able to make any changes later in the “Established Sync” section.`}</>
          </Typography>
        }
      />
    </>
  );
};
