import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import { Autocomplete } from '@mui/material';
import { activeUsersState, usersByIdMapState } from '../../services/state/AppConfigState';
import { IIdSelectorProps, popperSx, renderInput } from './commonSelectorDefs';

export const UserSelector = (props: IIdSelectorProps) => {
  const { getOptionDisabled, value, onChange, componentsProps, ...rest } = props;

  const usersById = useRecoilValue(usersByIdMapState);
  const activeUsers = useRecoilValue(activeUsersState);

  const userOptions = useMemo<number[]>(() => {
    return activeUsers.map((user) => user.id);
  }, [activeUsers]);

  const getOptionLabel = useCallback(
    (option: number) => {
      return usersById.get((option as number) ?? 0)?.name ?? '';
    },
    [usersById]
  );

  return (
    <Autocomplete
      openOnFocus
      options={userOptions}
      value={value ?? undefined}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel as (option: unknown) => string}
      onChange={(e, newValue) => onChange(newValue as number | number[] | null)}
      getOptionDisabled={getOptionDisabled as (option: unknown) => boolean}
      componentsProps={{ popper: { sx: { ...popperSx } }, ...componentsProps }}
      {...rest}
    />
  );
};
