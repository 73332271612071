export enum LinkType {
  CBINSIGHTS = 'cbinsights',
  CRUNCHBASE = 'crunchbase',
  PITCHBOOK = 'pitchbook',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
}

export interface ISocialLinkDataModel {
  type: LinkType;
  url: string;
}

export function createSocialLinkDataModel(overrides?: Partial<ISocialLinkDataModel>) {
  return {
    type: LinkType.CBINSIGHTS,
    url: '',
    ...overrides,
  };
}
