/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, GridOptions, ProcessDataFromClipboardParams } from 'ag-grid-community';
import { useCallback } from 'react';

export function useEditableGridDefinitions() {
  // might require small changes when we upgrade to latest version of ag-grid
  // (see https://www.ag-grid.com/react-data-grid/clipboard/#pasting-new-rows-at-the-bottom-of-the-grid)
  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data = [...params.data];
      const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
      if (emptyLastRow) {
        data.splice(data.length - 1, 1);
      }
      const lastIndex = params.api.getModel().getRowCount() - 1;
      const focusedCell = params.api.getFocusedCell();
      const focusedIndex = focusedCell!.rowIndex;
      if (focusedIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = focusedIndex + (data.length - 1);
        const numRowsToAdd = resultLastIndex - lastIndex;
        const rowsToAdd: any[] = [];
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = data.length - 1;
          const row = data.slice(index, index + 1)[0];
          // Create row object
          const rowObject: any = {};
          let currentColumn: any = focusedCell!.column;
          row.forEach((item) => {
            if (!currentColumn) {
              return;
            }
            rowObject[currentColumn.colDef.field] = item;
            currentColumn = params.api.getDisplayedColAfter(currentColumn);
          });
          rowsToAdd.push(rowObject);
        }
        params.api.applyTransaction({ add: rowsToAdd });
      }
      return data;
    },
    []
  );

  return { processDataFromClipboard };
}
export const EditableGridDefaultColDefs: ColDef = {
  editable: true,
  flex: 1,
  minWidth: 150,
};

export const EditableGridOptions: GridOptions = {
  enableRangeSelection: true,
  rowSelection: 'multiple',
  suppressCopySingleCellRanges: true,
  suppressClipboardPaste: true, // temporarily disable until we have defined a parser
};
