import { Collapse } from '@mui/material';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { TemplateAssignment } from '../../../../../schemas/template-assignment-schema';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { getFormattedFiscalDate } from '../../../../CompanyProfiles/utils/financialUtils';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { FMT } from '../../../../../util/formatter-service';
import { frequencyToKpiPeriod } from './create-request-utils';

interface IConfirmCreateDuplicateRequestProps {
  company: ICompanyDataModel;
  assignment: TemplateAssignment;
  period: string | null;
  show: boolean;
}

export function DuplicateRequestWarning({
  assignment,
  company,
  period,
  show,
}: IConfirmCreateDuplicateRequestProps) {
  const template = FMT.format('kpiTemplateName', assignment.templateUuid);

  return (
    <Collapse in={show}>
      {show ? (
        <InfoBox
          role='alert'
          variant='warning'
          message={`${company.name} already has a ${template} request created for ${getFormattedFiscalDate(formatISODateOnly(period!), frequencyToKpiPeriod[assignment.frequency], company.fye ?? 12)}.`}
        />
      ) : (
        <div style={{ height: '2rem' }} />
      )}
    </Collapse>
  );
}
