import { useMemo } from 'react';
import { noop, upperFirst } from 'lodash-es';
import { ControllerRenderProps } from 'react-hook-form';
import { KPIRequestFrequency } from '../../../../../../data-models/kpi-requests.data-model';
import { ISimpleChoice } from '../../../../../../data-models/field2.data-model';
import { FieldFactory } from '../../../../../../components/Form/FieldFactory';
import { createFormField, IFormFieldSelectMeta } from '../../../../../../view-models/form.view-model';
import { RendererType } from '../../../../../../data-models/field.data-model';

const frequencyOptions: ISimpleChoice<string>[] = Object.values(KPIRequestFrequency).map((freq) => ({
  value: freq,
  displayName: upperFirst(freq),
}));

export function KPIRequestsFrequenciesFilter({
  onChange,
  value,
}: Pick<ControllerRenderProps, 'onChange' | 'value'>) {
  const formField = useMemo(() => {
    return createFormField<IFormFieldSelectMeta<string>>({
      key: 'frequency',
      label: '',
      renderer: RendererType.multiSelect,
      rendererMeta: {
        values: frequencyOptions,
        multi: true,
      },
      placeholder: 'Filter by frequency',
    });
  }, []);
  const formProps = useMemo(() => {
    return {
      name: 'frequency',
      onChange,
      value,
      onBlur: noop,
      ref: noop,
    };
  }, [onChange, value]);
  return <FieldFactory formField={formField} formProps={formProps} />;
}
