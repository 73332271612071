import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { FC, MouseEvent, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { endOfDay } from 'date-fns';
import {
  monthsBackState,
  PresetPeriods,
  toDateFilterStateDA,
} from '../../../../state/DealConversionFilterState';
import { Calendar } from '../../../../../../components/DatePicker/Calendar';

const DateSettingsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: start;
  justify-content: end;
  gap: 0.5rem;
  align-self: start;
`;

export const PeriodFilters: FC = () => {
  const [toDate, setToDate] = useRecoilState(toDateFilterStateDA);
  const today = endOfDay(new Date());
  const colors = useTheme().colors;

  const toDateChangeHandler = useCallback(
    (newValue: Date | null) => {
      if (newValue !== null) {
        setToDate(newValue);
      }
    },
    [setToDate]
  );

  return (
    <DateSettingsContainer>
      <label style={{ color: colors.neutral[40], fontSize: '.825rem', alignSelf: 'center' }}>
        Date Added
      </label>
      <div>
        <Calendar initialValue={toDate} onChange={toDateChangeHandler} maxDate={today} />
      </div>
      <RangeBucketSelector />
    </DateSettingsContainer>
  );
};

export function RangeBucketSelector() {
  const colors = useTheme().colors;
  const [fromPeriod, setFromPeriod] = useRecoilState(monthsBackState);
  const buttonSx = { borderColor: colors.neutral[30], width: '2.5rem' };
  const handleFromDateChange = useCallback(
    (e: MouseEvent<HTMLElement>, newValue: PresetPeriods) => {
      if (newValue !== null) setFromPeriod(newValue);
    },
    [setFromPeriod]
  );

  return (
    <ToggleButtonGroup
      orientation='horizontal'
      value={fromPeriod}
      exclusive
      onChange={handleFromDateChange}
      sx={{ height: '40px', mt: '.25rem' }}
    >
      <ToggleButton value={PresetPeriods.L1M} aria-label='list' sx={buttonSx}>
        {`30d`}
      </ToggleButton>
      <ToggleButton value={PresetPeriods.L3M} aria-label='module' sx={buttonSx}>
        {`90d`}
      </ToggleButton>
      <ToggleButton value={PresetPeriods.L12M} aria-label='quilt' sx={buttonSx}>
        {'1y'}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
