import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useMemo } from 'react';
import { selectedViewPF } from '../state/ViewState';
import { selectedViewDatePF } from '../state/PageState';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { metricsByDateState, metricsFilteredByViewState } from '../state/MetricsState';
import {
  roundTrackerMetricsByDate,
  roundTrackerMetricsFilteredByViewState,
} from '../state/RoundTrackerMetricsState';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { useInitializeMetricsData } from './useInitializeMetricsData';

export function useCurrentViewMetrics() {
  const view = useRecoilValue(selectedViewPF);
  const date = useRecoilValue(selectedViewDatePF);
  const { previousQuarterDate } = useInitializeMetricsData();
  const metrics = useRecoilValueLoadable(metricsByDateState(date));
  const previousQuarterMetrics = useRecoilValueLoadable(metricsByDateState(previousQuarterDate));
  const filteredMetrics = useRecoilValueLoadable(metricsFilteredByViewState(view.id));

  const roundTrackerMetrics = useRecoilValueLoadable(roundTrackerMetricsByDate(date));
  const filteredRoundTrackerMetrics = useRecoilValueLoadable(roundTrackerMetricsFilteredByViewState(view.id));

  const prevRoundTrackerMetricsFiltered = useMemo(() => {
    if (filteredRoundTrackerMetrics.state === 'hasValue') {
      const previousQuarterRoundTrackerMetrics =
        filteredRoundTrackerMetrics
          .getValue()
          ?.filter((metric) => new Date(metric.investmentDate).getTime() < previousQuarterDate.getTime()) ??
        [];
      return filterPreviousQuarterRoundTrackerMetrics(
        filteredRoundTrackerMetrics.getValue(),
        previousQuarterRoundTrackerMetrics
      );
    }
    return [];
  }, [filteredRoundTrackerMetrics, previousQuarterDate]);

  const prevMetricsFiltered = useMemo(() => {
    if (filteredMetrics.state === 'hasValue' && previousQuarterMetrics.state === 'hasValue') {
      return filterPreviousQuarterMetrics(filteredMetrics.getValue(), previousQuarterMetrics.getValue());
    }
    return [];
  }, [filteredMetrics, previousQuarterMetrics]);

  return useMemo(() => {
    return view.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER
      ? {
          metrics: roundTrackerMetrics,
          previousQuarterMetrics: prevRoundTrackerMetricsFiltered,
          filteredMetrics: filteredRoundTrackerMetrics,
        }
      : {
          metrics,
          previousQuarterMetrics: prevMetricsFiltered,
          filteredMetrics,
        };
  }, [
    filteredMetrics,
    filteredRoundTrackerMetrics,
    metrics,
    prevMetricsFiltered,
    prevRoundTrackerMetricsFiltered,
    roundTrackerMetrics,
    view.companyViewType,
  ]);
}

function filterPreviousQuarterMetrics(
  filteredMetrics: IMetricsDataModel[],
  previousQuarterMetrics: IMetricsDataModel[]
) {
  const includedCompanies = filteredMetrics.reduce((res, m) => res.add(m.companyId), new Set<number>());

  return previousQuarterMetrics.filter((m) => includedCompanies.has(m.companyId));
}

function filterPreviousQuarterRoundTrackerMetrics(
  filteredMetrics: IInvestmentDataModel[],
  previousQuarterMetrics: IInvestmentDataModel[]
) {
  const includedCompanies = filteredMetrics.reduce((res, m) => res.add(m.companyId), new Set<number>());

  return previousQuarterMetrics.filter((m) => includedCompanies.has(m.companyId));
}
