import { atomFamily, selectorFamily } from 'recoil';
import { INewsDataModel } from '../../data-models/news.data-model';
import { fetchCompanyNews } from '../queries/MaggieCompanyQueries';

export const newsByCompanyIdState = atomFamily<INewsDataModel[], number>({
  key: 'newsByCompanyIdState',
  default: selectorFamily({
    key: 'newsByCompanyIdState/default',
    get: (companyId) => () => {
      return fetchCompanyNews(companyId);
    },
  }),
});
