import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageIcon from '@mui/icons-material/Image';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { ReactElement, RefObject, useCallback, useState } from 'react';
import { useChartSection } from '../../pages/PortfolioOverview/components/ChartsSection/ChartsSection';
import { useView } from '../../pages/PortfolioOverview/hooks/useView';
import { ScreenshotGenerator } from '../../services/ScreenshotGenerator';

interface IChartMenuProps {
  refProp?: RefObject<HTMLElement>;
  id: string | undefined;
}

export function ChartMenu({ refProp, id }: IChartMenuProps): ReactElement {
  const { getComponentsOrder, setComponentsOrder } = useView();
  const sectionId = useChartSection();
  const selectedCharts = getComponentsOrder(sectionId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  };

  const capture = useCallback(() => {
    ScreenshotGenerator.capture(refProp?.current);
  }, [refProp]);

  const handleDelete = () => {
    setComponentsOrder(
      sectionId,
      selectedCharts.filter((i) => i.toString() !== (id as unknown as string))
    );
  };

  return (
    <>
      <IconButton
        aria-label='chart menu'
        aria-controls={open ? 'chart-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        id='open-chart-menu'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='chart-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => capture()} aria-label={'export to png'}>
                    <ListItemIcon>
                      <ImageIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Export to PNG</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleDelete} aria-label={'delete chart'}>
                    <ListItemIcon>
                      <DeleteOutlineIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
