import { useRecoilValue } from 'recoil';
import { isEqual } from 'lodash-es';
import { useCanEditScenario } from '../../hooks/useCanEditScenario';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { ScenariosByIdMapState } from '../../../state/ScenariosState';
import { prepareScenarioPayload } from '../form/scenarioFormUtils';

export function useAllowSave(scenario: Partial<IScenarioDataModel>) {
  const canEdit = useCanEditScenario(scenario);
  const savedScenario = useRecoilValue(ScenariosByIdMapState(scenario.companyId ?? -1)).get(
    scenario.id ?? -1
  );

  return (
    canEdit &&
    (!savedScenario?.id || !isEqual(prepareScenarioPayload(savedScenario), prepareScenarioPayload(scenario)))
  );
}
