import * as yup from 'yup';
import { minZeroMsg, requiredMsg } from '../../utils/validationMessages';
import { requiredCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function capitalCallFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().required(requiredMsg('Transaction Date')),
    investmentAmount: usdField()
      .nullable()
      .required(requiredMsg('Capital Call Amount'))
      .min(0, minZeroMsg('Amount')),
    currencyid: requiredCurrencyIdField(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val ? val : null)),
  };
}

export function capitalCallFormSchema() {
  return yup.object().shape(capitalCallFields());
}

export function capitalCallGridSchema() {
  return yup.object().shape(capitalCallFields());
}
