import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { GridApi, IRowNode } from 'ag-grid-community';
import { IExtendedTransactionDataModel } from './useAllTransactionsData';
import { fundFilterState, transactionTypeFilterState } from './AllTransactionsFilterState';

export function useAllTransactionsGridFilters(gridApi: GridApi | null) {
  const fundFilter = useRecoilValue(fundFilterState);
  const transactionSubTypeFilter = useRecoilValue(transactionTypeFilterState);
  const selectedTypes = useMemo(
    () => new Set(transactionSubTypeFilter?.map((t) => t.id as number)),
    [transactionSubTypeFilter]
  );

  const funds = useMemo(() => new Set(fundFilter?.map((f) => f.id)), [fundFilter]);
  const transactionTypes = useMemo(
    () => new Set(transactionSubTypeFilter?.map((t) => t.id)),
    [transactionSubTypeFilter]
  );

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<IExtendedTransactionDataModel>) => {
      const { data } = node;
      if (!data) return false;
      return (
        (!fundFilter || funds.has(data.fundId ?? -1)) &&
        (!transactionSubTypeFilter || transactionTypes.has(data?.transactionTypeId as number))
      );
    },
    [fundFilter, funds, transactionSubTypeFilter, transactionTypes]
  );

  const isExternalFilterPresent = useCallback(
    () => Boolean(fundFilter?.length) || Boolean(transactionSubTypeFilter?.length),
    [fundFilter, transactionSubTypeFilter]
  );

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [fundFilter, transactionSubTypeFilter, gridApi]);

  return { doesExternalFilterPass, isExternalFilterPresent, selectedTypes };
}
