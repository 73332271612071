import {
  ColDef,
  KeyCreatorParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { CompanyHeaderComponent } from '../CellRenderers/CompanyHeaderComponent';
import { MetricCellRenderer } from '../CellRenderers/MetricCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';

export const GroupColName = 'fieldGroup';

const mapFieldGroupNamesToColDefs = (): ColDef[] => {
  return [
    {
      field: GroupColName,
      hide: true,
      rowGroup: true,
      lockVisible: true,
    },
  ];
};

const mapCompaniesToColDefs = (companies: ICompanyDataModel[]): ColDef[] => {
  return companies.map((c) => ({
    field: String(c.id),
    colId: String(c.id),
    headerName: c.name,
    headerComponent: CompanyHeaderComponent,
    headerComponentParams: { value: c.name, name: c.name, id: c.id, logo: c.logoUrl, website: c.website },
  }));
};

export const compareByField = (
  a: FieldDataModel,
  b: FieldDataModel,
  fieldName: keyof FieldDataModel
): number => {
  if (
    (a[fieldName] === undefined || a[fieldName] === null) &&
    (b[fieldName] === undefined || b[fieldName] === null)
  )
    return 0;

  if (a[fieldName] === undefined || a[fieldName] === null) return 1;
  if (b[fieldName] === undefined || b[fieldName] === null) return -1;

  return (a[fieldName] as number) - (b[fieldName] as number);
};

export const fieldComparator = (
  valA: FieldDataModel,
  valB: FieldDataModel,
  nodeA: IRowNode,
  nodeB: IRowNode
): number => {
  const a = nodeA?.data?.field;
  const b = nodeB?.data?.field;
  if (!a || !b) return 0;

  // no need to compare with fields from other groups, since they are always grouped
  if (a?.groupName === b?.groupName) return compareByField(a, b, 'groupOrder');
  else return 0;
};

const fieldNameColumn: ColDef = {
  headerName: 'Metrics',
  field: 'field',
  minWidth: 220,
  sort: 'asc',
  comparator: fieldComparator,
  valueGetter: (params: ValueGetterParams) => {
    return params.data?.field?.displayName;
  },
  keyCreator: (params: KeyCreatorParams) => params.data?.field?.displayName || '',
  valueFormatter: (params: ValueFormatterParams) => params.data?.field?.displayName || '',
  filter: true,
  filterParams: {
    excelMode: 'mac',
  },
  pinned: 'left',
  cellRenderer: MetricCellRenderer,
};

export function getColumnDefinitionsTransposed(companies: ICompanyDataModel[]) {
  const colDefs: ColDef[] = mapCompaniesToColDefs(companies);
  const fieldGroupNames = mapFieldGroupNamesToColDefs();
  return [fieldNameColumn, ...colDefs, ...fieldGroupNames];
}
