import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { ObjectSchema } from 'yup';
import { FormContainer } from '../../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../../components/Form/FormFieldAndLabelFactory';
import { fundCapitalAllocationSchemaStep1, FundViewModel } from '../../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { IFormField } from '../../../../view-models/form.view-model';
import { StepperFormButtons } from '../../../Finance2/Forms/StepperFormButtons';
import { fundFormCurrentStepState, fundFormState } from '../../state/FPState';
import { IFundFormProps } from '.././FundForm';
import { SyncedFields, useSyncAllocationFields } from './SyncedFields';

interface IFundFormStep1Props extends Pick<IFundFormProps, 'defaultValues'> {}

export function AllocationStep1({ defaultValues }: IFundFormStep1Props) {
  const [formData, setFormData] = useRecoilState(fundFormState);

  const methods = useForm<FundViewModel>({
    defaultValues: formData ?? defaultValues,
    mode: 'all',
    resolver: yupResolver(fundCapitalAllocationSchemaStep1() as ObjectSchema<FundViewModel>),
  });

  const handleNext = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setFormData((curr) => ({ ...curr, ...methods.getValues() }));
      return true;
    } else {
      return false;
    }
  }, [methods, setFormData]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <AllocationStep1Fields />
      </FormContainer>
      <StepperFormButtons stepIsValid={handleNext} stepState={fundFormCurrentStepState} />
    </FormProvider>
  );
}

function AllocationStep1Fields() {
  const fields = useMemo(() => {
    return schemaToFormFields(fundCapitalAllocationSchemaStep1(), [
      'committedCapital',
      'initialInvestmentAllocationPercentage',
      'initialInvestmentAllocationAmount',
      'reservesAllocationPercentage',
      'reservesAllocationAmount',
      'feesAndExpensesAllocationPercentage',
      'feesAndExpensesAllocationAmount',
      '_viewModel.totalAllocationPercentage',
      '_viewModel.totalAllocationAmount',
      'recyclingLimitPercentage',
      'recyclingLimitAmount',
    ]);
  }, []);

  const fieldMap = useMemo(() => {
    return fields.reduce((map, curr) => {
      const f = curr;
      if (
        curr.key === '_viewModel.totalAllocationPercentage' ||
        curr.key === '_viewModel.totalAllocationAmount'
      ) {
        f.disabled = true;
      }
      return map.set(curr.key, { ...f });
    }, new Map<string, IFormField<unknown>>());
  }, [fields]);

  const syncedFields: [FieldPath<FundViewModel>, FieldPath<FundViewModel>][] = [
    ['initialInvestmentAllocationPercentage', 'initialInvestmentAllocationAmount'],
    ['reservesAllocationPercentage', 'reservesAllocationAmount'],
    ['feesAndExpensesAllocationPercentage', 'feesAndExpensesAllocationAmount'],
    ['_viewModel.totalAllocationPercentage', '_viewModel.totalAllocationAmount'],
    ['recyclingLimitPercentage', 'recyclingLimitAmount'],
  ];

  useSyncAllocationFields();

  return (
    <>
      <FormFieldWithLabelFactory key={'committedCapital'} formField={fieldMap.get('committedCapital')!} />
      {syncedFields.map(([percentageFieldPath, amountFieldPath]) => {
        const key = `${percentageFieldPath}-${amountFieldPath}`;

        return (
          <SyncedFields
            key={key}
            percentageField={fieldMap.get(percentageFieldPath)!}
            amountField={fieldMap.get(amountFieldPath)!}
          />
        );
      })}
    </>
  );
}
