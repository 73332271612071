import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { IGroupOrderDataModel } from '../../../../../data-models/group-order-data.data-model';
import { companyColDef } from './companyColDef';
import { mapFieldsToColDefs } from './mapFieldsToColDefs';

export const useColumnDefinitions = (
  fields: FieldDataModel[],
  groupOrderMap: Map<string, IGroupOrderDataModel>,
  loading?: boolean
) => {
  const colDefs: (ColDef | ColGroupDef)[] = useMemo(() => {
    if (!fields) return [];

    return mapFieldsToColDefs(fields, groupOrderMap, loading);
  }, [fields, groupOrderMap, loading]);

  // assume pinned col is company with logo
  const allColDefs = useMemo(() => [companyColDef, ...colDefs], [colDefs]);

  return {
    colDefs: allColDefs,
  };
};
