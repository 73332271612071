import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { MultiselectSansPills } from '../../../../components/MuiMultiselect/MultiselectSansPills';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { sectorsState } from '../../../../services/state/AppConfigState';
import { listSectorFilterState } from '../state/FiltersState';

interface Props {
  open?: boolean;
}

export const SectorFilter: FC<Props> = ({ open }) => {
  const sectorsLoadable = useRecoilValueLoadable(sectorsState);
  const sectors = sectorsLoadable.valueMaybe();
  const [selectedSectors, setSelectedSectors] = useRecoilState(listSectorFilterState);

  const allSelected = useMemo(
    () => selectedSectors === null || selectedSectors.length === sectors?.length,
    [selectedSectors, sectors]
  );

  const handleChange = useCallback(
    (e: SyntheticEvent, values: Option[] | undefined) => {
      if (!values) return;
      setSelectedSectors(values.map((opt) => opt.id as number));
    },
    [setSelectedSectors]
  );

  const orderedSectors = useMemo(() => {
    return !sectors ? null : [...sectors].sort((a, b) => a.sortOrder - b.sortOrder);
  }, [sectors]);

  const sectorOptions: Option[] = useMemo(() => {
    return !orderedSectors ? [] : orderedSectors.map((s) => ({ id: s.id, value: s.name, label: s.name }));
  }, [orderedSectors]);

  return (
    <MultiselectSansPills
      options={sectorOptions}
      onChange={handleChange}
      value={
        allSelected
          ? sectorOptions
          : sectorOptions.filter((opt) => selectedSectors?.includes(opt.id as number))
      }
      fieldPlaceholder='Select Sectors'
      optionName={'Sector'}
      style={{ width: '14rem' }}
      open={open}
    />
  );
};
