import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { StakeholderType } from './CaptableSummaryGrid';

const noFilterValue = 'all';

interface IInvestmentsFilterProps {
  onChange: (value?: StakeholderType) => void;
  value?: StakeholderType;
}
export function InvestmentsFilter({ onChange, value }: IInvestmentsFilterProps) {
  return (
    <ToggleButtonGroup
      color='secondary'
      value={value ?? noFilterValue}
      exclusive
      onChange={(e, newValue) => {
        if (newValue === null) return;
        else if (newValue === noFilterValue) onChange();
        else onChange(newValue);
      }}
      aria-label='Filter investments'
    >
      <ToggleButton value={noFilterValue}>All Investments</ToggleButton>
      <ToggleButton value={StakeholderType.client}>My Investments</ToggleButton>
    </ToggleButtonGroup>
  );
}
