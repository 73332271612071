import { ReservesAction } from '../state/FPState';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FutureInvestmentForm } from './FutureInvestmentForm';
import { useReservesFormState } from './useReservesFormState';
import { ConfirmDeleteReservesInvestment, ConfirmDeleteReservesTransaction } from './ConfirmDeleteModals';
import { AddInvestmentSchema } from './addInvestmentFormUtils';

const forms = new Set<string>([
  ReservesAction.AddInvestment,
  ReservesAction.AddTransaction,
  ReservesAction.EditTransaction,
]);

export function ReservesModals() {
  const {
    resetFormState,
    selectedInvestment,
    showForm,
    selectedCompany,
    selectedCompanyName,
    selectedTransaction,
  } = useReservesFormState();

  let title;
  switch (showForm) {
    case ReservesAction.AddInvestment:
      title = selectedCompany
        ? `Add Future Investment for ${selectedCompany.fields.name}`
        : 'Add Future Investments';
      break;
    case ReservesAction.AddTransaction:
      title = `Add Transaction for ${selectedCompanyName}`;
      break;
    case ReservesAction.EditTransaction:
      title = `Edit Transaction for ${selectedCompanyName}`;
      break;
  }

  if (showForm === ReservesAction.DeleteInvestment && selectedInvestment) {
    return <ConfirmDeleteReservesInvestment investment={selectedInvestment!} />;
  }

  if (showForm === ReservesAction.DeleteTransaction && selectedTransaction) {
    return <ConfirmDeleteReservesTransaction transaction={selectedTransaction!} />;
  }

  const openModal = Boolean(showForm) && forms.has(showForm!);

  const defaultValues = {
    company: selectedCompany,
    ...(selectedTransaction ?? {}),
  } as AddInvestmentSchema;

  return (
    <BasicDialog
      open={openModal}
      title={title}
      onClose={() => {
        resetFormState();
      }}
    >
      {showForm === ReservesAction.AddInvestment && (
        <FutureInvestmentForm defaultValues={defaultValues} action={showForm} />
      )}
      {showForm === ReservesAction.AddTransaction && (
        <FutureInvestmentForm defaultValues={defaultValues} action={showForm} />
      )}
      {showForm === ReservesAction.EditTransaction && selectedTransaction && (
        <FutureInvestmentForm defaultValues={defaultValues} action={showForm} />
      )}
    </BasicDialog>
  );
}
