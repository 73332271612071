export const ActivityType = {
  boardNote: 'boardNote',
  commentary: 'commentary',
} as const;
export type ActivityType = (typeof ActivityType)[keyof typeof ActivityType];

export interface IActivityListItem {
  createdAt: string;
  createdBy: string;
  id: number;
  title: string;
  type: ActivityType;
}

export function createActivityListItem(overrides: Partial<IActivityListItem> = {}): IActivityListItem {
  return {
    createdAt: '',
    createdBy: '',
    id: -1,
    title: '',
    type: 'commentary',
    ...overrides,
  };
}
