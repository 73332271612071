import { useCallback, useState } from 'react';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useRubricConfigActions } from './useRubricConfigActions';
import { useRubricConfigFormData } from './useRubricConfigFormData';

export function RubricConfigConfirmDelete() {
  const { selectedAction, resetFormState, selectedConfig } = useRubricConfigFormData();
  const { deleteRubricConfig } = useRubricConfigActions();
  const [loading, setLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    if (!selectedConfig) return;
    setLoading(true);
    await deleteRubricConfig(selectedConfig.id);
    setLoading(false);
    resetFormState();
  }, [deleteRubricConfig, resetFormState, selectedConfig]);

  const open = selectedAction === 'delete' && selectedConfig != null;

  return (
    <ConfirmDeletionDialog
      onClose={resetFormState}
      onConfirm={onConfirm}
      loading={loading}
      title={'Delete Rubric'}
      open={open}
    >
      Are you sure you want to delete this rubric?
    </ConfirmDeletionDialog>
  );
}
