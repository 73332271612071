import { FC, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import { selectedTransactionStateFinance, showModalStateFinance } from '../state/FinanceUIState';
import { AllTransactionsTable } from '../Grids/AllTransactionsTable';
import { TransactionModals } from './modals/TransactionModals';

export const Transactions: FC = () => {
  const resetModalState = useResetRecoilState(showModalStateFinance);
  const resetSelectedTransaction = useResetRecoilState(selectedTransactionStateFinance);

  useEffect(() => {
    return () => {
      resetModalState();
      resetSelectedTransaction();
    };
  });

  return (
    <>
      <AllTransactionsTable />
      <TransactionModals />
    </>
  );
};
