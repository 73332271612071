import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Stack } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BasicDialog } from '../../Dialog/BasicDialog';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';
import { toURLFriendlyStringWithDashes } from '../../../util/url-utils';
import { CompanySearch } from '../../CompanySearch/CompanySearch';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';

const companySearchSx = {
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  minWidth: '14rem',
};

const commonSx = {
  width: '40rem',
  minWidth: 'min(40rem, calc(100vw - 4rem))',
  maxWidth: 'calc(100vw - 4rem)',
};

export const HeaderSearch: FC = () => {
  const navigate = useNavigate();
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();
  const showPortcoOnly = !PermissionService.get().hasPermission(PermissionKey.canViewDeal);

  const redirectToCompanySummary = (company: CompanySearchResponseWithRealId) => {
    const { name } = company.fields;
    navigate(`${ROUTES.COMPANY_PROFILES}/${toURLFriendlyStringWithDashes(name)}-${company.id}/summary`);
  };

  if (showElasticSearchResults) {
    return <DialogSearch onSelectCompany={redirectToCompanySummary} showPortcoOnly={showPortcoOnly} />;
  }

  return (
    <CompanySearch
      onChange={redirectToCompanySummary}
      disablePortal={false}
      showPortcoOnly={showPortcoOnly}
      sx={{ ...companySearchSx, maxWidth: '35rem' }}
      fullWidth
    />
  );
};

interface IDialogSearchProps {
  onSelectCompany: (company: CompanySearchResponseWithRealId) => void;
  showPortcoOnly?: boolean;
}

export function DialogSearch({ onSelectCompany, showPortcoOnly }: IDialogSearchProps) {
  const [open, setOpen] = useState(false);

  const _onSelectCompany = useCallback(
    (company: CompanySearchResponseWithRealId) => {
      setOpen(false);
      onSelectCompany(company);
    },
    [onSelectCompany]
  );

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
        size='large'
        sx={{ justifySelf: 'end', height: '2.5rem', width: '2.5rem' }}
      >
        <SearchIcon />
      </IconButton>

      <BasicDialog
        onClose={() => setOpen(false)}
        closeOnEscOrBackdropClick
        showTitleBar={false}
        open={open}
        sx={{ '& .MuiPaper-root': { position: 'absolute', top: '-1.75rem', ...commonSx } }}
        disableRestoreFocus
      >
        <Stack p={'0.5rem 1rem'}>
          <CompanySearch
            autoFocus
            onChange={_onSelectCompany}
            disablePortal={false}
            showPortcoOnly={showPortcoOnly}
            sx={companySearchSx}
            fullWidth
            popperSx={commonSx}
          />
        </Stack>
      </BasicDialog>
    </>
  );
}
