import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RendererType } from '../../../../data-models/field.data-model';
import { ISelectMeta } from '../../../../data-models/field3.data-model';
import { createFormField } from '../../../../view-models/form.view-model';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { captablesByCompanyState } from '../CapTableDataState';
import { CaptableSourceMap, isDuplicateSource } from '../captable-utils';

export function useCaptableSourceSelector() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captablesForCompany = useRecoilValue(captablesByCompanyState(companyId ?? -1));

  return useMemo(() => {
    const options = captablesForCompany.filter(isDuplicateSource);
    return createFormField<ISelectMeta<number>>({
      key: 'selectedCaptable',
      label: '',
      dataType: 'string',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: options.map((opt) => {
          return {
            value: opt.id,
            displayName: `${CaptableSourceMap[opt.source]} ${opt.primary ? '(Primary)' : ''}`,
          };
        }),
        multi: false,
      },
      disableClearable: true,
    });
  }, [captablesForCompany]);
}
