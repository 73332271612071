import { FC, useCallback, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { useInitCiData } from '../hooks/useInitCiData';
import { HistoryComparisonChartList } from '../../CompetitiveIntelligence/components/HistoryComparison/HistoryComparisonChartList/HistoryComparisonChartList';
import { useInitCiState } from '../hooks/useInitCiState';
import { TimeRangeWrapper } from '../../CompetitiveIntelligence/components/TimeRange/TimeRangeWrapper';
import { selectedCompanyIdProfile } from '../state/UIState';
import { AddCompetitorsDialog } from './AddCompetitorsDialog';

import { SingleCompanyMetrics } from './SingleCompanyMetrics';

import { GridSkeletonLoader } from './GridSkeletonLoader';
import { ChartSkeletonLoader } from './ChartSkeletonLoader';
import { SingleCompanyCiActions } from './SingleCompanyCiActions';

const Container = styled('div')`
  padding: 1rem 2.5rem;
`;

export type ciView = 'metrics' | 'charts';

const ChartsContainer = styled('div')`
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TimeRangeContainer = styled('div')`
  margin: 1rem 0;
`;

export const SingleCompanyCI: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  const [view, setView] = useState<ciView>('charts');
  const [showCompetitorsModal, setShowCompetitorsModal] = useState(false);

  const dataLoaded = useInitCiData();
  const stateSet = useInitCiState({ companyId, dataLoaded });

  const handleToggleView = useCallback(
    (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, view: ciView) => {
      setView(view);
    },
    []
  );

  const handleAddCompaniesClick = useCallback(() => {
    setShowCompetitorsModal(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowCompetitorsModal(false);
  }, []);

  return (
    <Container>
      <SingleCompanyCiActions
        view={view}
        handleToggleView={handleToggleView}
        onAddCompaniesClick={handleAddCompaniesClick}
      />

      {view === 'metrics' && (stateSet ? <SingleCompanyMetrics /> : <GridSkeletonLoader />)}

      {view === 'charts' &&
        (stateSet ? (
          <ChartsContainer>
            <TimeRangeContainer>
              <TimeRangeWrapper />
            </TimeRangeContainer>
            <HistoryComparisonChartList />
          </ChartsContainer>
        ) : (
          <ChartSkeletonLoader />
        ))}

      {showCompetitorsModal && (
        <AddCompetitorsDialog
          open={showCompetitorsModal}
          onClose={handleClose}
          primaryCompanyId={companyId}
        />
      )}
    </Container>
  );
};
