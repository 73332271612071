export interface IDealStageDataModel {
  id: number;
  name: string;
  displayName: string;
  sortOrder: number;
  meta: { required: string[] };
  default: boolean;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

export function createDealStageDataModel(overrides: Partial<IDealStageDataModel> = {}): IDealStageDataModel {
  return {
    id: 0,
    name: '',
    displayName: '',
    sortOrder: 0,
    meta: { required: [] },
    default: false,
    createdBy: null,
    updatedBy: null,
    createdAt: '',
    updatedAt: '',
    ...overrides,
  };
}
