import { atom, selector } from 'recoil';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';

export const investmentFormCompanyIdState = atom<number | null>({
  key: 'investmentFormCompanyIdState',
  default: selector({
    key: 'investmentFormCompanyIdState/default',
    get: ({ get }) => {
      return get(selectedCompanyIdStateFinance);
    },
  }),
});

export const investmentFormCompanyState = selector<ICompanyDataModel | null>({
  key: 'investmentFormCompanyState',
  get: ({ get }) => {
    const companyId = get(investmentFormCompanyIdState);
    return companyState(companyId ?? -1) ?? null;
  },
});

export const investmentFormInvestmentDataState = atom<Partial<IFinanceRoundDataModel | null>>({
  key: 'investmentFormInvestmentDataState',
  default: null,
});
