import { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { titleSearchQueryState } from '../state/PeopleByCompanyUIState';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';

export const SearchByTitle: FC = () => {
  const setTitleQuery = useSetRecoilState(titleSearchQueryState);

  useEffect(() => {
    return () => {
      setTitleQuery('');
    };
  }, [setTitleQuery]);

  return <SearchInput style={{ width: '14rem' }} onChange={setTitleQuery} placeholder='Search by title' />;
};
