import { Typography, useTheme } from '@mui/material';
import { ECharts, init } from 'echarts/core';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Chart } from '../../../../../components/Charts/chart-utils';
import { ChartContainer } from '../../../../../components/Charts/ChartContainer';
import { ChartTitleWithIcon } from '../../../../../components/Charts/ChartTitleWithIcon';
import { IConversionRateDataModel } from '../../../../../data-models/deal-conversion.data-model';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import { useConversionRateBarChartData } from '../../../hooks/useConversionRateBarChartData';

interface IConversionBarChartProps {
  dealConversion: IConversionRateDataModel[];
}

export const ConversionBarChart: FC<IConversionBarChartProps> = ({ dealConversion }) => {
  const { data: conversionData } = useConversionRateBarChartData(dealConversion);
  const colors = useTheme().colors;

  const chartContainer = useRef<HTMLDivElement | null>(null);
  const conversionRateChart = useRef<ECharts | null>(null);
  const yAxisData = useMemo(() => conversionData.map((item) => item.name), [conversionData]);

  const isInViewport = useIsInViewport(
    {
      threshold: 0.5,
    },
    chartContainer
  );

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) return;

    if (!conversionRateChart.current) {
      conversionRateChart.current = init(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          conversionRateChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(conversionRateChart.current && isInViewport)) {
      return;
    }

    const series = [
      {
        type: 'bar',
        name: 'Conversion Rate',
        data: conversionData,
        direction: 'vertical',
        itemStyle: {
          color: '#b9a9e749',
          gap: 5,
        },
        label: {
          show: true,
          position: 'insideRight',
          rich: {
            emphasis: {
              fontWeight: 'bold',
              color: colors.primary[100],
            },
          },
          formatter: (params: FormatterParams) => formatConversionLabel(params),
        },
        xAxisIndex: 0,
      },
      {
        type: 'line',
        name: 'Average Duration',
        data: conversionData.map((item) => item.averageDuration),
        label: {
          show: true,
          position: 'right',
          formatter: (params: FormatterParams) => formatDurationLabel(params),
        },
        tooltip: {
          show: true,
          formatter: (params: FormatterParams) => formatDurationTooltip(params),
        },
        xAxisIndex: 1,
        color: colors.primary[40],
        smooth: true,
      },
    ];
    const option = {
      xAxis: [
        {
          type: 'value',
          position: 'top',
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: 'value',
          name: 'Average Duration in Days',
          position: 'bottom',
          alignTicks: true,
          axisLine: {
            show: true,
          },
          minInterval: 1,
          axisLabel: {
            interval: 0,
            formatter: function (value: number) {
              return `${value}`;
            },
          },
          splitLine: {
            show: true,
            lineStyle: { color: colors.primary[20] },
          },
          axisTick: {
            show: true,
            inside: true,
            alignWithLabel: true,
          },
        },
      ],
      yAxis: {
        type: 'category',
        data: yAxisData,
        gridIndex: 0,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          align: 'right',
        },
      },
      series,
      tooltip: [
        {
          trigger: 'item',
          triggerOn: 'mousemove',
          textStyle: { color: colors.primary[100], fontSize: 12 },
          formatter: (params: FormatterParams) => formatTooltip(params),
        },
      ],
      grid: {
        show: false,
        width: '60%',
        top: '6%',
        right: '30%',
        left: '180px', // for all the labels to fit, safer in pixels
        bottom: '5%',
      },
    };

    conversionRateChart.current.setOption(option, true);
  }, [colors.neutral, colors.primary, colors.secondary, conversionData, isInViewport, yAxisData]);

  return (
    <>
      <ChartContainer>
        <ChartTitleWithIcon title='Conversion Rate to Next Stage' />
        {conversionData.length ? (
          <Chart ref={chartContainer} width={'100%'} height={'440px'} />
        ) : (
          <Typography color={colors.neutral[40]} style={{ marginTop: '2rem' }}>
            No data.
          </Typography>
        )}
      </ChartContainer>
    </>
  );
};

interface FormatterParams {
  name: string;
  data: { name: string; value: number; averageDuration: number };
}

function formatConversionLabel({ data }: FormatterParams) {
  const { value } = data;
  return `{emphasis|${value}%}`;
}

function formatDurationLabel({ data }: FormatterParams) {
  const { value } = data;
  return value != null ? `{emphasis|${value}d}` : '';
}

function formatTooltip({ name, data }: FormatterParams) {
  const { value, averageDuration } = data;
  return ` <strong>${name}</strong><br/> Conversion rate: ${value}% <br />${
    averageDuration != null ? `Average duration: ~${averageDuration}d` : ''
  }`;
}

function formatDurationTooltip({ name, data }: FormatterParams) {
  return data != null ? `<strong>${name}</strong><br/> Average duration: ${data}d` : '';
}
