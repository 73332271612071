import { ICompanyDataModel } from '../data-models/company.data-model';
import { BaseLocationDataModel } from '../schemas/Location.schema';

export interface ICompanyViewModel extends ICompanyDataModel {
  location: BaseLocationDataModel | null;
}

export function createCompanyViewModel(data: Partial<ICompanyDataModel>): Partial<ICompanyViewModel> {
  return {
    ...data,
    location: data.city ? { city: data.city, country: data.country, state: data.state } : null,
  };
}

export function companyFromViewModel(data: Partial<ICompanyViewModel>): Partial<ICompanyDataModel> {
  const { location, ...rest } = data;
  return {
    ...rest,
    city: location?.city,
    country: location?.country,
    state: location?.state,
  };
}
