import { useCallback, useEffect, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { GridReadyEvent, ValueFormatterFunc } from 'ag-grid-community';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { KPIRequestSchema } from '../../../../../data-schemas/KPIRequestSchema';
import { gradients } from '../../../../../theme/gradients';
import { fetchAssignedKPIRequests } from '../../../../../services/queries/KPIRequestsQueries';
import ResponsesActionsCellRenderer from '../components/ResponsesActionsCellRenderer';
import { kpiFiscalDateFormatter } from '../../../common/GridFormatters';

const LandingHolder = styled('div')`
  display: grid;
  height: 100%;
  width: 100%;
  padding: 1rem;
  grid-template-rows: min-content 1fr;
  background: ${gradients.primary};
`;

export function KPIRequestResponseHome() {
  const [data, setData] = useState<IKPIRequestDataModel[]>([]);
  const columnDefs = useColumnDefs();

  useEffect(() => {
    fetchAssignedKPIRequests().then((response) => {
      setData(response);
    });
  }, []);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  }, []);

  return (
    <LandingHolder>
      <Typography variant={'h6'} sx={{ marginBottom: '2rem' }}>
        My pending requests:
      </Typography>
      <AgTable
        columnDefs={columnDefs}
        groupIncludeTotalFooter={false}
        onGridReady={onGridReady}
        rowData={data}
        rowGroupPanelShow='never'
      />
    </LandingHolder>
  );
}

function useColumnDefs() {
  const schemaToGrid = useSchemaToGrid();

  const colDefs = schemaToGrid(KPIRequestSchema.recipientGridSchema()).concat([
    {
      maxWidth: 60,
      pinned: 'right',
      sortable: false,
      resizable: false,
      cellRenderer: ResponsesActionsCellRenderer,
    },
  ]);

  colDefs.forEach((def) => {
    def.flex = 1;

    if (def.field === 'period') {
      def.valueFormatter = kpiFiscalDateFormatter as ValueFormatterFunc;
    }
  });

  return colDefs;
}
