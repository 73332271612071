import { FC } from 'react';
import { Chip, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { IScenarioDataModel } from '../../../../data-models/scenario.data-model';
import {
  ChipStack,
  ScenarioListItem,
  StyledScenarioGrid,
  listCardStyles,
} from '../components/commonStyledComponents';
import { formatDate } from '../../../../util/formatters/DateFormatters';
import { getSimulationLabels } from '../utils/scenarioUtils';
import { usersByEmailMapState } from '../../../../services/state/AppConfigState';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { UnknownUser } from '../../../../data-models/user.data-model';
import { ScenarioActions } from './ScenarioActions';
import { ScenarioTypes } from './ScenarioTypes';
import { RelevanceTag } from './RelevanceTag';

const ScenarioColumns = [
  'Scenario Name',
  'Simulated',
  // 'MOIC',
  'Created Date',
  'Owner',
  'Type',
  'Relevance',
  '', //empty heading for layout
];

interface IScenarioListProps {
  scenarios: IScenarioDataModel[];
}

export const ScenarioList: FC<IScenarioListProps> = ({ scenarios }) => {
  return (
    <StyledScenarioGrid>
      <ScenarioListItem style={{ padding: '0 1rem' }}>
        {ScenarioColumns.map((heading) => (
          <Typography variant='caption' key={heading} color='text.secondary'>
            {heading}
          </Typography>
        ))}
      </ScenarioListItem>
      {scenarios.map((scenario) => {
        return (
          <ScenarioListItem key={scenario.id} sx={listCardStyles}>
            <ScenarioItem scenario={scenario} />
          </ScenarioListItem>
        );
      })}
    </StyledScenarioGrid>
  );
};

export const ScenarioItem: FC<{ scenario: IScenarioDataModel }> = ({ scenario }) => {
  const userMap = useRecoilValue(usersByEmailMapState);
  const createdBy = userMap.get(scenario.createdBy!) ?? UnknownUser;

  return (
    <>
      <Typography noWrap variant='body2' justifyContent={'start'}>
        {scenario.name}
      </Typography>
      <ChipStack>
        {Array.from(getSimulationLabels(scenario))
          .sort()
          .map((label) => (
            <Chip key={label} label={label} />
          ))}
      </ChipStack>
      {/* <Typography variant='body2'>{scenario.moic != null && formatMultiplier(scenario.moic)}</Typography> */}
      <Typography variant='body2'>{formatDate(scenario.createdAt)}</Typography>
      <Typography variant='body2' alignItems={'center'} display={'flex'} gap={'0.5rem'} component='div'>
        <MUIAvatar nameParts={createdBy.name?.split(' ') ?? []} src={null} />
        {createdBy.name}
      </Typography>
      <ScenarioTypes scenario={scenario} />
      <RelevanceTag outdated={scenario.outdated} />
      <ScenarioActions scenario={scenario} />
    </>
  );
};
