import axios from 'axios';
import { MaggieHttp } from '../MaggieHttp';

export type UploadedFileMeta = {
  contentType: string;
  fileId?: string;
  fileName: string;
  fileSize: number;
  fileURL?: string;
};

export type S3URLRequestPayload = {
  companyId: number;
  contentType: string;
};

export type S3URLRequestResponse = {
  companyId: number;
  fileId: string;
  signedUrl: string;
};

export type S3FileUploadResponse = {
  companyId: number;
  fileId: string;
  signedUrl: string;
};

async function getS3UploadURLForFile(companyId: number, contentType: string): Promise<S3URLRequestResponse> {
  const { data } = await MaggieHttp.maggie.post<S3URLRequestPayload, S3URLRequestResponse>(`/file-upload`, {
    companyId,
    contentType,
  });

  return data;
}

async function uploadFileToS3URL(file: File, presignedPostUrl: string) {
  const arrayBuffer = await readFileAsArrayBuffer(file);
  const { data } = await axios.put<S3FileUploadResponse>(presignedPostUrl, arrayBuffer, {
    headers: { 'Content-Type': file.type },
  });

  return data;
}

export async function uploadFile(companyId: number, file: File) {
  try {
    // Get AWS S3 signed url
    const { fileId, signedUrl } = await getS3UploadURLForFile(companyId, file.type);

    // Upload file to S3 using signed url
    await uploadFileToS3URL(file, signedUrl);

    return {
      data: {
        fileURL: signedUrl,
        fileId,
      },
      error: null,
      status: 'success',
    };
  } catch (_err) {
    return { data: null, error: 'Failed to upload file', status: 'success' };
  }
}

function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      resolve(fileReader.result as ArrayBuffer);
    });
    fileReader.readAsArrayBuffer(file);
  });
}
