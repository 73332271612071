import { FC } from 'react';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';

import { IFinanceRoundDataModel } from '../../../../data-models/finance-round.data-model';
import { useInvestmentActions } from '../../hooks/useInvestmentActions';
import { EditInvestmentForm } from '../../Forms/EditInvestmentForm';

export interface IInvestmentFormModalProps {
  open: boolean;
  onClose: () => void;
  investment: IFinanceRoundDataModel;
}

export const EditInvestmentModal: FC<IInvestmentFormModalProps> = ({ open, onClose, investment }) => {
  const { handleUpdateInvestment } = useInvestmentActions(onClose);

  return (
    <BasicDialog open={open} onClose={onClose} title={'Edit Investment'}>
      {open ? (
        <EditInvestmentForm onSubmit={handleUpdateInvestment} investment={investment} onCancel={onClose} />
      ) : (
        <div />
      )}
    </BasicDialog>
  );
};
