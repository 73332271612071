import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { kpiRequestsByCompanyState } from '../../../../../services/state/KPI/KPIRequestsState';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { companyState } from '../../../../../services/state/CompanyState';

interface IRespondentWarningParams {
  companyId: number;
  respondents: string[] | null;
  initialRespondents: Set<string>;
}
export function useRespondentChangeWarning({
  companyId,
  respondents,
  initialRespondents,
}: IRespondentWarningParams) {
  const requestsByCompany = useRecoilValue(kpiRequestsByCompanyState);
  const company = useRecoilValue(companyState(companyId));

  return useMemo(() => {
    if (
      !requestsByCompany.get(companyId)?.find((r) => r.status === KPIRequestStatus.Sent) ||
      respondents?.every((r) => initialRespondents.has(r))
    )
      return '';
    return `Company ${company?.name} has a request in progress.
    This in-progress request will be sent to the newly added respondent(s).`;
  }, [requestsByCompany, companyId, respondents, initialRespondents, company?.name]);
}
