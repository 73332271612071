import { useMemo, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useRecoilValue } from 'recoil';
import { MenuButton } from '../../../Finance2/common-grid-defs/MenuButton';
import { useEditCaptable } from '../CapTableActions';
import { selectedCaptableState } from '../CapTableDataState';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { isUserCreated } from '../captable-utils';
import { DeleteCaptableDialog } from './DeleteCaptableDialog';

export function CaptableActionsMenu() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const editCaptable = useEditCaptable();
  const currentCaptable = useRecoilValue(selectedCaptableState(companyId));
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const showMenu = currentCaptable != null && isUserCreated(currentCaptable);

  const menuItems = useMemo(() => {
    if (!currentCaptable) return [];
    return [
      {
        name: 'Modify Cap Table',
        icon: <EditOutlinedIcon />,
        onClick: () => {
          editCaptable(currentCaptable);
        },
      },
      {
        name: 'Delete Cap Table',
        icon: <DeleteOutlineIcon />,
        onClick: () => {
          setShowDeleteDialog(true);
        },
      },
    ];
  }, [currentCaptable, editCaptable]);

  if (!showMenu) {
    return null;
  }
  return (
    <>
      {showDeleteDialog && <DeleteCaptableDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} />}
      <MenuButton menuItems={menuItems} label='Cap table actions' />
    </>
  );
}
