import { colors } from './colors';
import { Theme } from './structure';
import { breakpoints } from './breakpoints';
import { gradients } from './gradients';

export const theme: Theme = {
  colors,
  breakpoints,
  gradients,
};
