import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { get } from 'lodash-es';
import { ISimpleChoice } from '../../../data-models/field2.data-model';

export interface ISelectConfigProps {
  valuesPath: string;
}
export function SelectConfig(props: ISelectConfigProps) {
  const { valuesPath } = props;
  const { control, getValues, formState } = useFormContext();
  const filter = createFilterOptions<string>();

  return (
    <Controller
      control={control}
      name={valuesPath}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          open={false}
          value={(value as ISimpleChoice<unknown>[])?.map((v) => v.displayName) ?? []}
          onChange={(event, newValue) => {
            onChange(newValue.map((v) => ({ displayName: v, value: v })));
          }}
          multiple
          filterOptions={(options, params) => {
            const filtered = filter(options as string[], params);
            const { inputValue } = params;
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(inputValue);
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={(getValues(valuesPath) as string[]) ?? []}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(get(formState.errors, valuesPath))}
              helperText={(get(formState.errors, valuesPath)?.message as string) ?? ''}
            />
          )}
        />
      )}
    />
  );
}
