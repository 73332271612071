import { styled } from '@mui/material/styles';

export const StyledTable = styled('table')`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;

  thead {
    color: ${({ theme }) => theme.colors.neutral[60]};
    background: ${({ theme }) => theme.colors.neutral[10]};
    height: 1.5rem;
  }

  th {
    text-align: left;
  }

  th,
  td {
    padding: 0 1em;
    min-width: 0;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td {
    color: ${({ theme }) => theme.colors.neutral[60]};
  }

  tr {
    height: 1.75rem;
    &:nth-of-type(2n) {
      background-color: ${({ theme }) => theme.colors.neutral[5]};
    }
  }
`;
