import * as yup from 'yup';
import { merge } from 'lodash-es';
import { FundMetrics } from '../../../schemas/FundMetrics.schema';
import { usdField } from '../../../schemas/common-schema-defs';

export function distributionSegmentFields() {
  return {
    highlight: yup.boolean().nullable().default(false),
    percentValue: yup.number().required().default(0),
    type: yup.string().oneOf(['lp', 'gp']),
  };
}

export function distributionSegmentSchema() {
  return yup.object().shape(distributionSegmentFields());
}

export type DistributionSegment = yup.InferType<ReturnType<typeof distributionSegmentSchema>>;

export function createDistributionSegment(overrides: Partial<DistributionSegment> = {}): DistributionSegment {
  return merge({}, distributionSegmentSchema().getDefault(), overrides);
}

export function waterfallGridDataFields() {
  return {
    phase: yup
      .string()
      .oneOf(['Initial', 'Tier 0', 'Tier 1', 'Tier 2', 'Tier 3'])
      .nullable()
      .label('Phase')
      .default(null),
    tier: yup
      .string()
      .oneOf(['LP ROC', 'Preferred Return', 'Catch Up', 'Super Return'])
      .nullable()
      .label('Tier Name')
      .default(null),
    distributableProceeds: usdField().nullable().label('Distributable Proceeds').default(null),
    totalDistributed: usdField().nullable().label('Total Distributed').default(null),
    lpDistributed: usdField().nullable().label('LP Distributed').default(null),
    lpPortion: usdField().nullable().label('LP Portion').default(null),
    gpDistributed: usdField().nullable().label('GP Distributed').default(null),
    gpPortion: usdField().nullable().label('GP Portion').default(null),
    visualization: yup.array().of(distributionSegmentSchema()).nullable().label('Visualization').default([]),
  };
}

export function waterfallGridDataSchema() {
  return yup.object().shape(waterfallGridDataFields());
}

export type WaterfallGridData = yup.InferType<ReturnType<typeof waterfallGridDataSchema>>;

export function createWaterfallGridData(overrides: Partial<WaterfallGridData> = {}): WaterfallGridData {
  return merge({}, waterfallGridDataSchema().getDefault(), overrides);
}

export function getWaterfallData(metrics: FundMetrics): WaterfallGridData[] {
  return [
    createWaterfallGridData({
      phase: 'Initial',
      distributableProceeds: metrics.distributableProceeds,
    }),
    createWaterfallGridData({
      phase: 'Tier 0',
      tier: 'LP ROC',
      distributableProceeds: metrics.distributableProceeds,
      totalDistributed: metrics.contributionsForCalculation,
      lpDistributed: metrics.contributionsForCalculation,
    }),
    createWaterfallGridData({
      phase: 'Tier 1',
      tier: 'Preferred Return',
      distributableProceeds: metrics.amountAvailableForLpGpSplit,
      totalDistributed: (metrics.lpNavFromLpGpSplit ?? 0) + (metrics.gpIncentiveFromLpGpSplit ?? 0),
      lpDistributed: metrics.lpNavFromLpGpSplit,
      gpDistributed: metrics.gpIncentiveFromLpGpSplit,
      lpPortion: metrics.navFromLpGpSplitLP ?? 0,
      gpPortion: metrics.navFromLpGpSplitGp ?? 0,
    }),
    createWaterfallGridData({
      phase: 'Tier 2',
      tier: 'Catch Up',
      distributableProceeds: metrics.availableForGpCatchup,
      totalDistributed: metrics.gpIncentiveFromGpCatchup,
      lpDistributed: 0,
      gpDistributed: metrics.gpIncentiveFromGpCatchup,
    }),
    createWaterfallGridData({
      phase: 'Tier 3',
      tier: 'Super Return',
      distributableProceeds: metrics.availableForSuperReturn,
      totalDistributed: (metrics.lpNavFromSuperReturn ?? 0) + (metrics.gpIncentiveFromSuperReturn ?? 0),
      lpDistributed: metrics.lpNavFromSuperReturn,
      gpDistributed: metrics.gpIncentiveFromSuperReturn ?? 0,
      lpPortion: metrics.navFromSuperReturnLP ?? 0,
      gpPortion: metrics.navFromSuperReturnGP ?? 0,
    }),
  ].reduce((acc, curr) => {
    const prev = acc.at(-1);
    const visualization = getDistributions(
      curr,
      metrics.distributableProceeds ?? 0,
      prev?.visualization ?? []
    );
    return [...acc, { ...curr, visualization }];
  }, [] as WaterfallGridData[]);
}

export function getWaterfallTotals(metrics: FundMetrics): WaterfallGridData {
  return createWaterfallGridData({
    lpDistributed: metrics.lpNav,
    gpDistributed: metrics.gpIncentive,
    lpPortion: metrics.navLPPortion ?? 0,
    gpPortion: metrics.navGPPortion ?? 0,
  });
}

export function getDistributions(
  data: WaterfallGridData,
  initialDistributableProceeds: number,
  prevSegments: DistributionSegment[] = []
): DistributionSegment[] {
  const { gpDistributed, lpDistributed } = data;
  const lpSegmentValue = initialDistributableProceeds
    ? (lpDistributed ?? 0) / initialDistributableProceeds
    : 0;
  const gpSegmentValue = initialDistributableProceeds
    ? (gpDistributed ?? 0) / initialDistributableProceeds
    : 0;
  const lpSegment = createDistributionSegment({
    percentValue: lpSegmentValue,
    highlight: true,
    type: 'lp' as const,
  });
  const gpSegment = createDistributionSegment({
    percentValue: gpSegmentValue,
    highlight: true,
    type: 'gp' as const,
  });
  const result: DistributionSegment[] = prevSegments.map((s) => ({ ...s, highlight: false }));
  result.push(lpSegment, gpSegment);
  return result;
}
