import { styled } from '@mui/material/styles';
import { Autocomplete, Button, InputLabel, TextField, Typography } from '@mui/material';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { LoadingButton } from '@mui/lab';
import { Publish } from '@mui/icons-material';
import { IObjectiveCategoryDataModel } from '../../../../../data-models/objective-category.data-model';
import { activeUserOptionsState, appConfigState } from '../../../../../services/state/AppConfigState';
import { Circle } from '../../../../DealFlow2/components/DealModal/DealAddModalContent/Circle';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import {
  FieldContainer,
  FormGrid,
  FormikOption,
  SubFormContainer,
  formSubtitleSx,
} from './StyledFormComponents';
import { objectiveInitialValues } from './objectiveInitialValues';
import { objectiveSchema } from './objectiveSchema';
import { noChange } from './formHelpers';

export const ButtonContainer = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
`;

export interface ObjectiveFormValues {
  id?: number;
  name: string;
  categoryId: number | null;
  ownerId: number | null;
}

export interface ObjectiveFormProps {
  onClose: () => void;
  initialValues?: ObjectiveFormValues;
  onCancel?: () => void;
  onSubmit: (
    values: ObjectiveFormValues,
    formikHelpers: FormikHelpers<ObjectiveFormValues>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => void | Promise<any>;
}

export const ObjectiveForm: FC<ObjectiveFormProps> = ({ onClose, initialValues, onSubmit, onCancel }) => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  if (!companyId) throw new Error('companyId is undefined');

  const formik = useFormik({
    initialValues: initialValues ?? objectiveInitialValues,
    validationSchema: objectiveSchema,
    onSubmit: onSubmit,
    validateOnChange: true,
    initialTouched: initialValues ? { name: true } : undefined,
  });

  const { values, errors, touched, handleSubmit, setFieldValue, handleBlur, handleChange, isSubmitting } =
    formik;

  const objectiveCategories: IObjectiveCategoryDataModel[] =
    useRecoilValueLoadable(appConfigState).contents?.objectiveCategory;

  const userOptions = useRecoilValue(activeUserOptionsState);

  const categoryOptions: FormikOption[] = objectiveCategories.map((category) => ({
    id: category.id,
    value: category.name,
    label: category.name,
  }));

  const handleCategoryChange = useCallback(
    (event: SyntheticEvent, value: FormikOption | null) => {
      if (!value) setFieldValue('categoryId', null);
      else setFieldValue('categoryId', value.id);
    },
    [setFieldValue]
  );

  const handleOwnerChange = useCallback(
    (event: SyntheticEvent, value: FormikOption | null) => {
      if (!value) setFieldValue('ownerId', undefined);
      else setFieldValue('ownerId', value.id);
    },
    [setFieldValue]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
    else onClose();
  }, [onClose, onCancel]);

  const submitDisabled = useMemo(() => noChange(initialValues, values), [initialValues, values]);

  if (!objectiveCategories) return null;

  return (
    <FormikProvider value={formik}>
      <SubFormContainer>
        <Typography component='h6' variant='body1' sx={{ ...formSubtitleSx }}>
          <Circle isOpen={true}>
            <Typography variant='body1' color={'white'}>
              {1}
            </Typography>
          </Circle>
          Objective
        </Typography>
        <FieldContainer>
          <FormGrid>
            <InputLabel htmlFor='name'>Name</InputLabel>
            <TextField
              id='name'
              name='name'
              error={Boolean(errors.name && touched.name)}
              helperText={errors.name && touched.name ? errors.name : ''}
              onBlur={handleBlur}
              value={values.name}
              onChange={handleChange}
              placeholder='Enter Name'
            />

            <InputLabel htmlFor='categoryId'>Category</InputLabel>
            <Autocomplete
              options={categoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='categoryId'
                  error={Boolean(errors.categoryId && touched.categoryId)}
                  helperText={errors.categoryId && touched.categoryId ? errors.categoryId : ''}
                  placeholder='Select Category'
                />
              )}
              onChange={handleCategoryChange}
              onBlur={handleBlur}
              value={categoryOptions.find((option) => option.id === values.categoryId) ?? null}
              selectOnFocus
            />

            <InputLabel htmlFor='ownerId'>Owner</InputLabel>
            <Autocomplete
              options={userOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='ownerId'
                  error={Boolean(errors.ownerId && touched.ownerId)}
                  helperText={errors.ownerId && touched.ownerId ? errors.ownerId : ''}
                  placeholder='Select Owner '
                />
              )}
              onChange={handleOwnerChange}
              onBlur={handleBlur}
              value={userOptions.find((option) => option.id === values.ownerId) ?? null}
              selectOnFocus
            />
            {/* empty div for proper grid layout */}
            <div></div>
            <ButtonContainer>
              <LoadingButton
                variant='contained'
                color={'secondary'}
                size={'large'}
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length !== 0 ||
                  isSubmitting ||
                  submitDisabled
                }
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                loading={isSubmitting}
                endIcon={<Publish />}
                loadingPosition='end'
              >
                {initialValues ? 'Save Changes' : 'Save and Next'}
              </LoadingButton>
              <Button
                type='button'
                variant='outlined'
                color='secondary'
                size={'large'}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </FormGrid>
        </FieldContainer>
      </SubFormContainer>
    </FormikProvider>
  );
};
