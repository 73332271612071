import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { generalLedgerFormSchema, GeneralLedgerPayload } from '../../../schemas/GeneralLedgerPayload.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { IFormField } from '../../../view-models/form.view-model';
import { FormFieldAndLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { colors } from '../../../theme/colors';
import { useUploadGeneralLedger } from './uploadGeneralLedgerActions';

const requiredFormattingUrl = `/images/required-formatting.png`;
interface IUploadLedgerFormProps {
  onClose: () => void;
}

export function UploadLedgerForm({ onClose }: IUploadLedgerFormProps) {
  const uploadGeneralLedger = useUploadGeneralLedger();
  const [loading, setLoading] = useState(false);
  const schema = useMemo(() => generalLedgerFormSchema(), []);
  const methods = useForm<GeneralLedgerPayload>({
    resolver: yupResolver(schema),
  });

  const fieldsMap = useMemo(() => {
    return schemaToFormFields(schema).reduce(
      (map, field) => map.set(field.key, field),
      new Map<string, IFormField<unknown>>()
    );
  }, [schema]);

  const handleSubmit = useCallback(async () => {
    const { file, date } = methods.getValues();
    setLoading(true);
    await uploadGeneralLedger(file as File, date);
    setLoading(false);
    onClose();
  }, [methods, onClose, uploadGeneralLedger]);

  return (
    <FormProvider {...methods}>
      <FormFieldAndLabelFactory formField={fieldsMap.get('file')!} />
      <RequiredFormatting />

      <Divider />
      <FormFieldAndLabelFactory formField={fieldsMap.get('date')!} />
      <StickyFormButtons
        onCancel={onClose}
        onSubmit={methods.handleSubmit(handleSubmit)}
        loading={loading}
        style={{ padding: '0.5rem 0 2rem' }}
        submitLabel={'Upload'}
      />
    </FormProvider>
  );
}

const thumbnail = css`
  display: block;
  margin-bottom: 0.2rem;
  width: 4.6rem;
  &:hover {
    box-shadow: 0 0 0.5rem 0.1rem ${colors.primary[0]}dd;
  }
`;
function RequiredFormatting() {
  return (
    <div>
      <a target='_blank' href={requiredFormattingUrl} rel='noreferrer'>
        <img css={thumbnail} src={requiredFormattingUrl} alt='required formatting' loading='lazy' />
        <Typography variant='caption' color='text.secondary'>
          Required Formatting
        </Typography>
      </a>
    </div>
  );
}
