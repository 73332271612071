import { merge } from 'lodash-es';

export interface ICompanySearchResultDataModel {
  id: string | number;
  companyName: string;
  website: string;
  logo: string;
  value: string;
  description?: string;
}

export function createCompanySearchResultDataModel(
  overrides: Partial<ICompanySearchResultDataModel> = {}
): ICompanySearchResultDataModel {
  return {
    companyName: '',
    description: '',
    id: -1,
    logo: '',
    value: '',
    website: '',
    ...overrides,
  };
}

type CompanyType = 'portfolio' | 'crunchbase';

type CompanyFields = {
  description: string;
  logo: string | null;
  name: string;
  website: string | null;
  type: CompanyType | string;
};

/** @deprecated use src/schemas/CompanySearchResponse.schema.ts/CompanySearchResponse instead */
export type ICompanySearchResponseDataModel = {
  id: string;
  fields: CompanyFields;
};

export interface ICompanyRealIdResponse {
  id: number;
  requestedId: string;
}

/** @deprecated use src/schemas/CompanySearchResponse.schema.ts/createCompanySearchResponse instead */
export function createCompanySearchResponseDataModel(
  overrides: Partial<ICompanySearchResponseDataModel> = {}
): ICompanySearchResponseDataModel {
  return merge(
    {
      fields: {
        name: '',
        description: '',
        logo: '',
        website: '',
        type: 'portfolio',
      },
      id: '',
    },
    overrides
  );
}

export function createCompanySearchResultFields(overrides: Partial<CompanyFields>): CompanyFields {
  return {
    name: '',
    description: '',
    logo: '',
    website: '',
    type: 'portfolio',
    ...overrides,
  };
}
