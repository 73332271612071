import { IPageConfigDataModel } from '../../data-models/page-config.data-model';
import { COMPANY_VIEW_TYPE, OriginViewDataModel } from '../../data-models/view-config.data-model';
import { IViewDataModel, ViewDataModelCreatePayload } from '../../data-models/view.data-model';
import { ViewModel, deserializeView } from '../../models/view.model';
import { ColumnMeta, RawPageConfig } from '../../types';
import { MaggieHttp } from '../MaggieHttp';
import { UnSharePayload } from './MaggieUploadQueries';

export type SharePayload = string[];

export async function deleteView(view: OriginViewDataModel) {
  await MaggieHttp.maggie.delete<number>(`/view/${view.id}`);

  return view;
}

export async function duplicateView(viewPayload: ViewDataModelCreatePayload) {
  const { data } = await MaggieHttp.maggie.post<IViewDataModel, IViewDataModel>('/view', viewPayload);

  return deserializeView(data);
}

export async function shareView(id: number, emails: SharePayload) {
  const { data } = await MaggieHttp.maggie.put<SharePayload, IViewDataModel>(`/view/share/${id}`, emails);

  return data?.sharing?.map((user) => user.userId) ?? [];
}

export async function unshareView(id: number, emails: UnSharePayload) {
  const { data } = await MaggieHttp.maggie.put<UnSharePayload, number>(`/view/unshare/${id}`, emails);

  return data;
}
export async function updateView(viewDataModel: IViewDataModel): Promise<ViewModel> {
  const { data } = await MaggieHttp.maggie.put<IViewDataModel, IViewDataModel>(
    `/view/${viewDataModel.id}`,
    viewDataModel
  );

  return deserializeView(data);
}

export async function fetchViews(): Promise<ViewModel[]> {
  const { data } = await MaggieHttp.maggie.get<IViewDataModel[]>('/view');

  return data.map((viewDataModel) => deserializeView(viewDataModel));
}

export async function updatePageConfig(pageConfig: IPageConfigDataModel): Promise<IPageConfigDataModel> {
  const payload = {
    config: pageConfig,
  };
  const { data } = await MaggieHttp.maggie.put<RawPageConfig, RawPageConfig>(`/view/config`, payload);

  const config = data?.config;
  return config;
}

export async function fetchPageConfig(): Promise<IPageConfigDataModel> {
  const { data } = await MaggieHttp.maggie.get<RawPageConfig>(`/view/config`);
  const config = data?.config ?? {};

  if (config?.order) {
    config.order = Array.from(new Set(config?.order ?? []));
  }

  return config;
}

export async function fetchColumns(companyViewType: COMPANY_VIEW_TYPE | undefined): Promise<ColumnMeta[]> {
  const queryParam = companyViewType ? `?viewType=${companyViewType}` : '';
  const response = await MaggieHttp.maggie.get<ColumnMeta[]>(`/column${queryParam}`);

  return response.data ?? [];
}
