import { FC } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { styled } from '@mui/material/styles';
import { allFDFieldsMapState, establishedSyncsState } from '../../state/IntegrationsState';
import { Loader } from '../../../../components/Loader/Loader';
import { ErrorOccurredMessage } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { MaxHeaderHeightInRems } from '../../../../constants/styles';
import { SyncTable } from './SyncTable';

const EstablishedSyncsContainer = styled('div')`
  padding: 1rem 0;
  display: grid;
  align-content: start;
  gap: 1rem;
  height: calc(100vh - ${MaxHeaderHeightInRems}rem - 8rem);
  overflow-y: auto;
`;

export const EstablishedSync: FC = () => {
  const establishedSyncLoadable = useRecoilValueLoadable(establishedSyncsState);
  const establishedSyncs = establishedSyncLoadable.valueMaybe();

  const allFDColumnsMapLoadable = useRecoilValueLoadable(allFDFieldsMapState);

  if (establishedSyncLoadable.state === 'loading' || allFDColumnsMapLoadable.state == 'loading')
    return <Loader />;
  if (establishedSyncLoadable.state === 'hasError' || allFDColumnsMapLoadable.state == 'hasError')
    return <ErrorOccurredMessage />;

  if (!establishedSyncs) return null;

  return (
    <EstablishedSyncsContainer>
      {establishedSyncs.map((sync) => (
        <SyncTable key={sync.id} sync={sync} />
      ))}
    </EstablishedSyncsContainer>
  );
};
