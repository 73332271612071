import { FC, useCallback, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { DealModal } from '../DealModal/DealModal';
import { DealPassModalContent } from '../DealModal/DealPassModalContent';
import { DealMissedModalContent } from '../DealModal/DealMissedModalContent';
import { dealBoardSettingsState, isOpenDrawerState, selectedDealState } from '../../state/DealboardUIState';
import { useDealActions2, useDeleteDeal } from '../../hooks/useDealActions';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { companyState } from '../../../../services/state/CompanyState';
import { ConfirmDeleteDeal } from '../DealModal/ConfirmDeleteDeal';
import { getNextDeal, getPrevDeal } from './dealNavigationUtils';

export const MenuCard = styled('ul')<{ width: number | undefined }>`
  all: unset;
  list-style: none;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  padding: 8px 8px;
  border-radius: 4px;
  display: block;
  position: absolute;
  background: #fff;
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  transform: translate(-0.1rem, 0.2rem);
`;

export const MenuItem = styled('li')`
  display: flex;
  align-items: center;
  height: 2.8rem;
  gap: 8px;
  cursor: pointer;
  & svg {
    height: 1.3rem;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.primary[5]};
  }
  padding: 0 8px;
`;

const Container = styled('div')``;
const LabelWrapper = styled('div')`
  margin-top: 2px;
`;

export const SplitButton: FC = () => {
  const setIsOpenDrawer = useSetRecoilState(isOpenDrawerState);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [deal, setSelectedDeal] = useRecoilState(selectedDealState);
  const dealBoardSettings = useRecoilValue(dealBoardSettingsState);
  const containerRef = useRef<HTMLDivElement>(null);
  const deleteDeal = useDeleteDeal();
  const { handleClose } = useDealActions2();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const company = useRecoilValue(companyState(deal?.companyId ?? -1));
  const companyName = `“${company!.name}”`;

  useOutsideClick(containerRef, () => setOpen(false));
  const { colors } = useTheme();
  const { onOpenModal } = useModalState();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onConfirmDelete = useCallback(async () => {
    if (!deal) return;
    setShowConfirmDelete(false);
    let nearestDeal = getNextDeal(dealBoardSettings, deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);

    await deleteDeal(deal, {
      onSuccess: () => {
        if (!nearestDeal) setIsOpenDrawer(false);
        setSelectedDeal(nearestDeal);
      },

      onError: () => {
        setIsOpenDrawer(false);
      },
    });
  }, [deal, dealBoardSettings, deleteDeal, setIsOpenDrawer, setSelectedDeal]);

  const onClose = useCallback(async () => {
    if (!deal) return;
    let nearestDeal = getNextDeal(dealBoardSettings, deal);
    if (!nearestDeal) nearestDeal = getPrevDeal(dealBoardSettings, deal);
    await handleClose(deal);
    if (!nearestDeal) setIsOpenDrawer(false);
    setSelectedDeal(nearestDeal);
  }, [deal, dealBoardSettings, handleClose, setIsOpenDrawer, setSelectedDeal]);

  const handleSelectOption = useCallback(
    (optionId: string) => {
      if (!deal) return;
      switch (optionId) {
        case 'CLOSE':
          setOpen(false);
          onClose();
          return;
        case 'PASS':
          setOpen(false);
          onOpenModal(
            <DealModal title={`Move the Deal ${companyName} to Pass?`}>
              <DealPassModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'MISSED':
          setOpen(false);
          onOpenModal(
            <DealModal title={`Mark the Deal ${companyName} as Missed?`}>
              <DealMissedModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'DELETE':
          setOpen(false);
          setShowConfirmDelete(true);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [companyName, deal, onClose, onOpenModal]
  );

  if (!deal || !company) return null;

  return (
    <Container ref={containerRef} data-testid='deal-split-btn'>
      <ButtonGroup
        variant='outlined'
        color={'secondary'}
        size={'medium'}
        ref={anchorRef}
        aria-label='split button'
        style={{ position: 'relative' }}
      >
        <Button
          color={'secondary'}
          startIcon={<DangerousOutlinedIcon />}
          onClick={() => handleSelectOption('PASS')}
          style={{ padding: '3px 18px', justifyContent: 'flex-start' }}
        >
          <LabelWrapper>Pass</LabelWrapper>
        </Button>

        <Button
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select deal action'
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      {open && (
        <MenuCard width={164} role='listbox'>
          <MenuItem onClick={() => handleSelectOption('CLOSE')} role='option'>
            <CheckCircleOutlineIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Close
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('MISSED')} role='option'>
            <CallMissedOutgoingIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Missed
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectOption('DELETE')} role='option'>
            <DeleteOutlineIcon />
            <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
              Delete
            </Typography>
          </MenuItem>
        </MenuCard>
      )}
      <ConfirmDeleteDeal
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={onConfirmDelete}
        deal={deal}
        company={company}
      />
    </Container>
  );
};
