import { IPackageDataModel } from '../../data-models/package.data-model';
import { Dashboard, DashboardCreateDataModel } from '../../types';
import { MaggieHttp } from '../MaggieHttp';
import { tempDashboards } from '../../temp-data/temp-dashboards';
import { tempFields } from '../../temp-data/temp-fields';
import { tempPackages } from '../../temp-data/temp-packages';

export async function createDashboard(dashboard: DashboardCreateDataModel): Promise<Dashboard> {
  const { data } = await MaggieHttp.maggie.post<DashboardCreateDataModel, Dashboard>(
    '/dashboards',
    dashboard
  );

  return data;
}

export async function fetchDashboards(): Promise<Dashboard[]> {
  // FIXME Restore after MAGGIE-3617
  // const { data } = await MaggieHttp.maggie.get<Dashboard[]>('/dashboards');
  //
  // return data;

  return Promise.resolve(tempDashboards);
}

export async function deleteDashboard(dashboard: Dashboard): Promise<Dashboard> {
  await MaggieHttp.maggie.delete<number>(`/dashboards/${dashboard.id}`);

  return dashboard;
}

export async function updateDashboard(dashboard: Dashboard): Promise<Dashboard> {
  const payload = {
    displayName: dashboard.displayName,
    config: dashboard.config,
  };
  const { data } = await MaggieHttp.maggie.put<Partial<Dashboard>, Dashboard>(
    `/dashboards/${dashboard.id}`,
    payload
  );

  return data;
}

export async function fetchFields() {
  // FIXME Restore after MAGGIE-3617
  // const { data } = await MaggieHttp.maggie.get<FieldDataModel[]>('/fields');

  // return data;
  return Promise.resolve(tempFields);
}

export async function fetchPackages(): Promise<IPackageDataModel[]> {
  // FIXME Restore after MAGGIE-3617
  // const { data } = await MaggieHttp.maggie.get<Package[]>('/packages');

  // return data;
  return Promise.resolve(tempPackages);
}
