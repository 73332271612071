type DateFormatterProps = {
  value: Date | undefined | null;
};

export const DateFormatter = ({ value }: DateFormatterProps) => {
  return value ? (
    <span>
      {value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}
    </span>
  ) : null;
};
