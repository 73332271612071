import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Link, useTheme } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import Linkedin from '../../../../../assets/icons/ln.svg?react';
import Twitter from '../../../../../assets/icons/tw.svg?react';
import Facebook from '../../../../../assets/icons/fb.svg?react';
import { formatExternalLink } from '../../../../../util/url-utils';
import { EmailButton } from './EmailButton';

interface SocialMediaProps {
  companyId?: number;
  personId: number;
  primaryEmail?: string | null;
  secondaryEmails?: string[] | null;
  twitterUrl?: string | null;
  linkedinUrl?: string | null;
  facebookUrl?: string | null;
  githubUrl?: string | null;
  style?: CSSProperties;
  showEmails?: boolean;
}

const IconButtonStyles = {
  padding: '0.1rem',
};

const Container = styled('div')`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: start;
  & > a {
    height: 20px;
  }
`;

export const SocialMedia: FC<SocialMediaProps> = ({
  companyId,
  personId,
  primaryEmail,
  secondaryEmails,
  twitterUrl,
  linkedinUrl,
  facebookUrl,
  githubUrl,
  style,
  showEmails = true,
}) => {
  const { colors } = useTheme();
  return (
    <Container data-testid='social-media' style={style}>
      {showEmails && (
        <EmailButton
          primaryEmail={primaryEmail}
          secondaryEmails={secondaryEmails}
          personId={personId}
          companyId={companyId}
        />
      )}
      {linkedinUrl && (
        <IconButton
          style={IconButtonStyles}
          component={Link}
          href={formatExternalLink(linkedinUrl)}
          target='_blank'
          aria-label='linkedin url'
        >
          <Linkedin />
        </IconButton>
      )}
      {twitterUrl && (
        <IconButton
          style={IconButtonStyles}
          component={Link}
          href={formatExternalLink(twitterUrl)}
          target='_blank'
          aria-label='twitter url'
        >
          <Twitter />
        </IconButton>
      )}
      {facebookUrl && (
        <IconButton
          style={IconButtonStyles}
          component={Link}
          href={formatExternalLink(facebookUrl)}
          target='_blank'
          aria-label='facebook url'
        >
          <Facebook />
        </IconButton>
      )}
      {githubUrl && (
        <IconButton
          style={IconButtonStyles}
          component={Link}
          href={formatExternalLink(githubUrl)}
          target='_blank'
          aria-label='github url'
        >
          <GitHubIcon htmlColor={colors.primary[40]} sx={{ fontSize: 20 }} />
        </IconButton>
      )}
    </Container>
  );
};
