import { Button, Divider, Icon, Stack, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, MouseEvent } from 'react';
import { LoadingButton } from '@mui/lab';
import { ConfirmDialog, IConfirmDialogProps } from './ConfirmDialog';

const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
`;

export enum ConfirmDialogTitleOptions {
  Warning = 'warning',
}

interface IConfirmDeletionDialogProps extends Pick<IConfirmDialogProps, 'open' | 'title'> {
  onConfirm: (e?: MouseEvent<HTMLElement>) => void;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  children: ReactNode | string;
  loading?: boolean;
  sx?: SxProps;
}

type IDeleteAndCancelButtons = Pick<IConfirmDeletionDialogProps, 'onClose' | 'onConfirm' | 'loading'>;

const DeleteAndCancelButtons = ({ onClose, onConfirm, loading }: IDeleteAndCancelButtons) => (
  <Stack width='100%' gap='2rem'>
    <Divider />
    <ButtonsContainer>
      <Button variant='outlined' color='secondary' onClick={onClose} size='medium'>
        Cancel
      </Button>
      <LoadingButton
        variant='contained'
        color='error'
        onClick={onConfirm}
        size='medium'
        loading={loading}
        disabled={loading}
        loadingPosition='start'
        startIcon={loading ? <Icon /> : <></>}
      >
        <span>Yes, Delete</span>
      </LoadingButton>
    </ButtonsContainer>
  </Stack>
);

export const ConfirmDeletionDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  children,
  loading,
}: IConfirmDeletionDialogProps) => {
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={title}
      type={ConfirmDialogTitleOptions.Warning}
      actionButtons={<DeleteAndCancelButtons onClose={onClose} onConfirm={onConfirm} loading={loading} />}
    >
      {children}
    </ConfirmDialog>
  );
};
