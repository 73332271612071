import { ReactNode } from 'react';
import { Button, Paper, Typography, useTheme, Chip, styled } from '@mui/material';

const CardTitle = styled('div')`
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
  min-height: 0;
  max-height: fit-content;
`;

interface IAppBoxProps {
  name: string;
  icon: ReactNode;
  description: string;
  isConnected: boolean;
  connectHandler: () => void;
  configurationHandler: (() => void) | null;
}

export function AppBox({
  name,
  icon,
  description,
  connectHandler,
  isConnected,
  configurationHandler,
}: IAppBoxProps) {
  const { colors } = useTheme();
  return (
    <Paper
      style={{
        padding: '1rem',
        width: '20rem',
        height: '12rem',
        display: 'grid',
        alignItems: 'start',
        alignContent: 'space-between',
        background:
          'radial-gradient(100% 204.54% at 0% 0%, rgba(249, 250, 250, 0.8) 0%, rgba(255, 255, 255, 0.32) 100%)',
        border: '2px solid #FFFFFF',
        boxShadow:
          '0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04), 0px 24px 48px rgba(75, 82, 93, 0.02)',
      }}
    >
      <CardTitle>
        {icon}
        <Typography variant='h6'>{name}</Typography>
        {isConnected && <Chip label='Connected' variant='outlined' color='success' size='medium' />}
      </CardTitle>
      <Typography variant='body2' color={colors.neutral[60]} style={{ alignSelf: 'start' }}>
        {description}
      </Typography>
      {!isConnected && (
        <Button
          variant={'contained'}
          color='secondary'
          onClick={connectHandler}
          style={{ alignSelf: 'end', justifySelf: 'start' }}
        >
          {'Connect'}
        </Button>
      )}
      {isConnected && configurationHandler && (
        <Button
          variant={'outlined'}
          color='secondary'
          onClick={configurationHandler}
          style={{ alignSelf: 'end', justifySelf: 'start' }}
        >
          {'Manage Configuration'}
        </Button>
      )}
      {isConnected && !configurationHandler && <div style={{ height: '2rem' }} />}
    </Paper>
  );
}
