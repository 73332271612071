import { IDealDataModel } from '../../../data-models/deal.data-model';
import { IDealStageDataModel, createDealStageDataModel } from './dealStage.data-model';

export interface ColumnStage {
  stage: IDealStageDataModel;
  deals: IDealDataModel[];
  isCollapsed: boolean;
}

export function createColumnStage(overrides: Partial<ColumnStage> = {}): ColumnStage {
  return {
    stage: createDealStageDataModel(overrides.stage),
    deals: [],
    isCollapsed: false,
    ...overrides,
  };
}
