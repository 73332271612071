import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Temporal } from 'temporal-polyfill';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { Commentary, createEmptyCommentary, NewCommentaryId } from '../../../../../schemas/Commentary.schema';
import {
  createOrUpdateCommentary,
  deleteCommentaryById,
} from '../../../../../services/queries/MaggieCommentaryQueries';
import { commentariesByCompanyIdState } from '../../../../../services/state/CommentariesState';
import { LoadingId } from '../../../../../types';
import { selectedCommentaryState } from '../../../state/UIState';

export function useGetTodaysCommentary(companyId: number) {
  const today = Temporal.Now.plainDateISO().toString();
  const commentaries = useRecoilValue(commentariesByCompanyIdState(companyId));
  const todaysCommentary = commentaries.find((commentary) => {
    return commentary.date === today;
  });

  return (
    todaysCommentary ??
    createEmptyCommentary({
      entity: 'company',
      entityId: companyId,
      date: today,
      id: NewCommentaryId,
    })
  );
}

export function useCreateOrUpdateCommentary() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ gotoSnapshot, set, snapshot }) =>
      async (companyId: number, updatedCommentary: Commentary) => {
        const currentCommentaries = await snapshot.getPromise(commentariesByCompanyIdState(companyId));
        const selectedCommentary = await snapshot.getPromise(selectedCommentaryState);

        try {
          if (updatedCommentary.id === NewCommentaryId) {
            const updatedCommentaries = [updatedCommentary, ...currentCommentaries];

            let updatedSnapShot = snapshot.map((mutableSnapshot) => {
              mutableSnapshot.set(commentariesByCompanyIdState(companyId), updatedCommentaries);
            });
            gotoSnapshot(updatedSnapShot);

            const response = await createOrUpdateCommentary(updatedCommentary);
            const updatedCommentariesFromResponse = [response, ...currentCommentaries];
            updatedSnapShot = snapshot.map((mutableSnapshot) => {
              mutableSnapshot.set(commentariesByCompanyIdState(companyId), updatedCommentariesFromResponse);
              if (selectedCommentary?.id === NewCommentaryId) {
                mutableSnapshot.set(selectedCommentaryState, response);
              }
            });

            gotoSnapshot(updatedSnapShot);

            return response;
          } else {
            const updatedCommentaries = currentCommentaries.map((commentary) => {
              if (commentary.id === updatedCommentary.id) {
                return updatedCommentary;
              }
              return commentary;
            });

            const updatedSnapShot = snapshot.map((mutableSnapshot) => {
              mutableSnapshot.set(commentariesByCompanyIdState(companyId), updatedCommentaries);
            });
            gotoSnapshot(updatedSnapShot);

            const response = await createOrUpdateCommentary(updatedCommentary);
            const updatedCommentariesFromResponse = currentCommentaries.map((commentary) => {
              if (commentary.id === response.id) {
                return response;
              }
              return commentary;
            });

            const updatedSnapShotFromResp = snapshot.map((mutableSnapshot) => {
              mutableSnapshot.set(commentariesByCompanyIdState(companyId), updatedCommentariesFromResponse);
            });

            gotoSnapshot(updatedSnapShotFromResp);

            return response;
          }
        } catch (err) {
          set(commentariesByCompanyIdState(companyId), currentCommentaries);
          pushErrorToast({ message: 'Failed to update commentary' });

          return null;
        }
      }
  );
}

export function useDeleteCommentary() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useRecoilCallback(({ snapshot, set }) => async (companyId: number, noteId: number) => {
    actions.startLoading(LoadingId.deleteNote);
    const currentCommentaries = await snapshot.getPromise(commentariesByCompanyIdState(companyId));
    const updatedCommentaries = currentCommentaries.filter((note) => note.id !== noteId);

    try {
      await deleteCommentaryById(noteId);
      set(commentariesByCompanyIdState(companyId), updatedCommentaries);
      pushSuccessToast({ message: 'Performance commentary deleted' });
      return true;
    } catch (err) {
      set(commentariesByCompanyIdState(companyId), currentCommentaries);
      pushErrorToast({ message: 'Failed to delete performance commentary' });
      return false;
    } finally {
      actions.stopLoading(LoadingId.deleteNote);
    }
  });
}
