import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useAtom } from 'jotai';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { allCompanyFinancialsState } from '../../CompanyProfiles/state/CompanyFinancialsState';
import { companyState } from '../../../services/state/CompanyState';
import {
  getDefaultFinancialsEndDate,
  getDefaultFrequency,
} from '../../CompanyProfiles/state/CompanyFinancialsDateState';
import {
  selectedEndDateFinancialsState,
  selectedFinancialsRangeState,
  selectedFrequencyFinancialsState,
} from '../../CompanyProfiles/components/FinancialsDatePickers/useFinancialsDateSettings';
import { PastPeriodRangeOptions } from '../../CompanyProfiles/components/FinancialsDatePickers/financeDatePickerUtils';

export function useInitFinancialsDateSettings() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const fye = company?.fye ?? 12;
  const financials = useRecoilValue(allCompanyFinancialsState(companyId));

  const [selectedFrequency, setSelectedFrequency] = useAtom(selectedFrequencyFinancialsState);
  const [selectedRange, setSelectedRange] = useAtom(selectedFinancialsRangeState);
  const [selectedEndDate, setSelectedEndDate] = useAtom(selectedEndDateFinancialsState);

  return useCallback(() => {
    if (!financials || !company) return false;

    const frequency = selectedFrequency ?? getDefaultFrequency(financials, fye);

    if (!selectedFrequency) {
      setSelectedFrequency(frequency);
    }
    if (!selectedRange) {
      setSelectedRange(PastPeriodRangeOptions[frequency][0]);
    }
    if (!selectedEndDate) {
      setSelectedEndDate(getDefaultFinancialsEndDate(fye - 1, frequency));
    }

    return Boolean(selectedFrequency) && Boolean(selectedRange) && Boolean(selectedEndDate);
  }, [
    financials,
    company,
    selectedFrequency,
    fye,
    selectedRange,
    selectedEndDate,
    setSelectedFrequency,
    setSelectedRange,
    setSelectedEndDate,
  ]);
}
