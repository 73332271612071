import { FC } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

export enum RoundParticipationType {
  none = 'None',
  proRata = 'Pro Rata',
  other = 'Other',
}

interface IRoundParticipationToggleProps {
  value: RoundParticipationType;
  handleChange: (value: RoundParticipationType) => void;
  disabled?: boolean;
}

export const RoundParticipationToggle: FC<IRoundParticipationToggleProps> = ({
  value,
  handleChange,
  disabled,
}) => {
  return (
    <>
      <ToggleButtonGroup
        color='secondary'
        exclusive
        aria-label='transaction type'
        value={value}
        onChange={(event, value) => {
          if (value) handleChange(value);
        }}
        disabled={disabled}
      >
        <ToggleButton value={RoundParticipationType.none}>{RoundParticipationType.none}</ToggleButton>
        <ToggleButton value={RoundParticipationType.proRata}>{RoundParticipationType.proRata}</ToggleButton>
        <ToggleButton value={RoundParticipationType.other}>{RoundParticipationType.other}</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
