import { FC, MouseEvent, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Divider, Stack, styled } from '@mui/material';
import { selectedCompanyIdProfile, selectedKpiChartState } from '../../../state/UIState';
import {
  selectedMetricsDateCPState,
  selectedFrequencyPerfState,
} from '../../../state/CompanyFinancialsDateState';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { ReportingFrequencyToggler } from '../DateSettings/ReportingFrequencyToggler';
import { BarChart } from '../../../../../components/Charts/Barchart/BarChart';
import { MixedChart } from '../../../../../components/Charts/MixedChart/MixedChart';
import { companyState } from '../../../../../services/state/CompanyState';
import { PeriodRangeToggler, RangeOptions } from './PeriodRangeToggler';
import { useGetPerfBarChartData } from './PerfBarChart';
import { IChartFieldParams, IViewConfig, useGetMixedPerfChartData } from './MixedPerfChart';

export const ChartContent = styled('div')`
  width: 94vw;
  min-height: 26rem;
  padding: 0 2rem 2rem;
`;

export function useExpandedChartSettings(viewConfig?: Pick<IViewConfig, 'frequency'>) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  const selectedPerfFrequency = useRecoilValue(selectedFrequencyPerfState(companyId));

  const [frequency, setFrequency] = useState<KpiPeriod>(viewConfig?.frequency ?? selectedPerfFrequency);

  const [periodRange, setPeriodRange] = useState(RangeOptions[frequency][0]);
  const resetPeriodRange = useCallback((frequency: KpiPeriod) => {
    setPeriodRange(RangeOptions[frequency][0]);
  }, []);

  const handleRangeChange = (event: MouseEvent, value: number) => {
    if (value === null) return;
    setPeriodRange(value);
  };

  const handleFrequencyChange = (event: MouseEvent, newFreq: KpiPeriod) => {
    if (newFreq === null) return;
    setFrequency(newFreq);
    resetPeriodRange(newFreq);
  };

  return {
    frequency,
    periodRange,
    handleRangeChange,
    handleFrequencyChange,
  };
}

interface IExpandedChartSettingsPanelProps {
  frequency: KpiPeriod;
  periodRange: number;
  handleRangeChange: (event: MouseEvent, value: number) => void;
  handleFrequencyChange: (event: MouseEvent, newFreq: KpiPeriod) => void;
}
export function ExpandedChartSettingsPanel({
  frequency,
  periodRange,
  handleRangeChange,
  handleFrequencyChange,
}: IExpandedChartSettingsPanelProps) {
  return (
    <Stack direction='row' gap='1rem' mb='1rem'>
      <ReportingFrequencyToggler value={frequency} onChange={handleFrequencyChange} />
      <PeriodRangeToggler period={frequency} value={periodRange} onChange={handleRangeChange} />
    </Stack>
  );
}

export const ExpandedPerfChart: FC = () => {
  const kpi = useRecoilValue(selectedKpiChartState);
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId))!;
  const { frequency, periodRange, handleRangeChange, handleFrequencyChange } = useExpandedChartSettings();
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const getChartData = useGetPerfBarChartData();

  const { barData, timeCategories, formatter } = getChartData(
    kpi!,
    selectedDate,
    frequency,
    periodRange,
    fye
  );

  return (
    <ChartContent>
      <Divider sx={{ mb: '1rem' }} />
      <ExpandedChartSettingsPanel
        frequency={frequency}
        handleFrequencyChange={handleFrequencyChange}
        periodRange={periodRange}
        handleRangeChange={handleRangeChange}
      />
      <Stack>
        <BarChart
          displayName={kpi?.displayName ?? ''}
          title={kpi?.displayName ?? ''}
          xCategoryData={timeCategories}
          valueFormatter={formatter}
          yValueData={barData}
          size='Full Screen'
        />
      </Stack>
    </ChartContent>
  );
};

interface IExpendedMixedChartProps {
  kpis: IChartFieldParams[];
  initialViewConfig?: Pick<IViewConfig, 'frequency'>;
}
export const ExpandedMixedChart: FC<IExpendedMixedChartProps> = ({ kpis, initialViewConfig }) => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId))!;
  const { frequency, periodRange, handleRangeChange, handleFrequencyChange } =
    useExpandedChartSettings(initialViewConfig);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const getChartData = useGetMixedPerfChartData();

  const { chartData, timeCategories, yAxis } = getChartData(kpis, selectedDate, frequency, periodRange, fye);

  return (
    <ChartContent>
      <Divider sx={{ mb: '1rem' }} />
      <ExpandedChartSettingsPanel
        frequency={frequency}
        handleFrequencyChange={handleFrequencyChange}
        periodRange={periodRange}
        handleRangeChange={handleRangeChange}
      />
      <Stack width='100%' height='22rem'>
        <MixedChart xCategoryData={timeCategories} yValueData={chartData} yAxis={yAxis} />
      </Stack>
    </ChartContent>
  );
};
