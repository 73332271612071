import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { selectedCompanyIdProfile } from '../state/UIState';
import { companyCaptablesByIdState } from './CapTableDataState';
import { useSetCaptableFormState } from './CapTableActions';
import { useResetCapTableFormState } from './CapTableFormState';

export const CaptableIdParam = 'captableId';

export function useSyncCaptableFormFromPath() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [search] = useSearchParams();
  const captableId = parseInt(search.get(CaptableIdParam) ?? '-1');
  const setFormState = useSetCaptableFormState();

  const captableMap = useRecoilValue(companyCaptablesByIdState(companyId));

  const [ready, setReady] = useState(false);

  const syncForm = useCallback(() => {
    if (!captableMap) return;
    const captable = captableMap.get(captableId);
    if (!captable || captable.companyId !== companyId) {
      return;
    }
    setFormState(captable);
  }, [captableId, captableMap, companyId, setFormState]);

  useEffect(() => {
    if (ready) return;
    syncForm();
    setReady(true);
  }, [ready, syncForm]);

  const resetState = useResetCapTableFormState();

  useEffect(() => {
    return resetState;
  }, [resetState]);

  return ready;
}
