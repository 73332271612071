import { atom } from 'recoil';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';

export type TFinanceAction =
  | 'addTransaction'
  | 'editTransaction'
  | 'assignToInvestment'
  | 'deleteTransaction'
  | 'editInvestment'
  | 'deleteInvestment';

export const showModalStateFinance = atom<TFinanceAction | null>({
  key: 'ShowModalStateFinance',
  default: null,
});

export const selectedTransactionStateFinance = atom<ITransactionDataModel | null>({
  key: 'SelectedTransactionStateFinance',
  default: null,
});

export const selectedInvestmentStateFinance = atom<IFinanceRoundDataModel | null>({
  key: 'SelectedInvestmentStateFinance',
  default: null,
});
