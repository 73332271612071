import { IKeyResultDataModel } from '../../data-models/key-result.data-model';
import { IObjectiveDataModel } from '../../data-models/objective.data-model';
import { ObjectiveFormValues } from '../../pages/CompanyProfiles/Performance/components/OkrForms/ObjectiveForm';
import { MaggieHttp } from '../MaggieHttp';

export interface ObjectivePayload extends ObjectiveFormValues {
  companyId: number;
}

export interface EditObjectivePayload extends ObjectivePayload {
  id: number;
}

export interface EditKeyResultPayload extends KeyResultPayload {
  id: number;
}
export interface KeyResultPayload {
  name: string;
  objectiveId: number;
  ownerId: number;
  displayType: string;
  status: string;
  value: number | null;
  goal: number;
  meta: object | null;
}

export async function fetchObjectivesForCompany(companyId: number): Promise<IObjectiveDataModel[]> {
  const { data } = await MaggieHttp.maggie.get<IObjectiveDataModel[]>(`/objective?companyId=${companyId}`);

  return data;
}

export async function createObjective(payload: ObjectivePayload): Promise<IObjectiveDataModel> {
  const { data } = await MaggieHttp.maggie.post<ObjectivePayload, IObjectiveDataModel>(`/objective`, payload);

  return data;
}

export async function editObjective(payload: EditObjectivePayload): Promise<IObjectiveDataModel> {
  const { data } = await MaggieHttp.maggie.put<EditObjectivePayload, IObjectiveDataModel>(
    `/objective/${payload.id}`,
    payload
  );

  return data;
}

export async function createKeyResult(payload: KeyResultPayload) {
  const { data } = await MaggieHttp.maggie.post<KeyResultPayload, IKeyResultDataModel>(`/keyResult`, payload);
  return data;
}

export async function editKeyResult(payload: EditKeyResultPayload) {
  const { data } = await MaggieHttp.maggie.put<EditKeyResultPayload, IKeyResultDataModel>(
    `/keyResult/${payload.id}`,
    payload
  );
  return data;
}

export async function deleteKeyResult(keyResultId: number) {
  await MaggieHttp.maggie.delete(`/keyResult/${keyResultId}`);
}

export async function deleteObjective(id: number) {
  await MaggieHttp.maggie.delete(`/objective/${id}`);
}
