import { Chip, Stack, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { ITemplate } from '../../../data-models/template.data-model';
import { customFieldsSelectedTemplateState } from '../../../services/state/AdminPanel/CustomFieldsState';
interface ListTemplatesProps {
  templatesList: ITemplate[] | null;
  onClick: (targetTemplate: ITemplate) => void;
}

const StyledChip = styled(Chip)(
  ({ theme }) => `
  background: ${theme.colors.neutral[10]};
  border-radius: 4px;
  border: none;
  padding: .25rem 2rem;
  width: 30%;
  height: 2rem;
`
);

const StyledSelectedChip = styled(StyledChip)(
  ({ theme }) => `
  background: white;
  border: 2px solid ${theme.colors.primary[60]};
`
);

function ListTemplates({ templatesList, onClick }: ListTemplatesProps) {
  const customFieldsSelectedTemplate = useRecoilValue(customFieldsSelectedTemplateState);

  const selectedTemplateKey = useMemo(() => {
    if (!templatesList) return;

    return templatesList?.find((template) => template.template_key.includes(customFieldsSelectedTemplate))
      ?.template_key;
  }, [customFieldsSelectedTemplate, templatesList]);

  if (!templatesList) return null;

  return (
    <Stack direction='row' spacing={4}>
      {templatesList.map((template) =>
        template.template_key === selectedTemplateKey ? (
          <StyledSelectedChip
            color='secondary'
            variant='outlined'
            key={template.template_key}
            label={template.name}
            onClick={() => onClick(template)}
          />
        ) : (
          <StyledChip key={template.template_key} label={template.name} onClick={() => onClick(template)} />
        )
      )}
    </Stack>
  );
}

export default ListTemplates;
