import { useCallback } from 'react';
import { fetchKPIRequests } from '../../../../../../services/queries/KPIRequestsQueries';
import { kpisRequestsListState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { useInvalidateData } from '../../../../../../hooks/useInvalidateData';

export function useInvalidateKPIRequests() {
  const invalidateData = useInvalidateData();

  return useCallback(
    () => invalidateData({ state: kpisRequestsListState, query: fetchKPIRequests }),
    [invalidateData]
  );
}
