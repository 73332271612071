/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Divider, Stack, Typography, styled } from '@mui/material';
import { css } from '@emotion/react';
import { endOfDay } from 'date-fns';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { companyState } from '../../../services/state/CompanyState';
import { IPersonDataModel, IWorkExperienceDataModel } from '../../../data-models/person.data-model';
import { PersonData } from '../../CompanyProfiles/Summary/components/People/PersonData';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { getFormattedDuration } from '../../CompanyProfiles/utils/workHistoryUtils';
import { CompanyLogoAndName } from '../../../components/grid-renderers/CompanyCellRenderer';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { useProfileBasePath } from '../../CompanyProfiles/useProfileBasePath';
import { formatExternalLink } from '../../../util/url-utils';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { usePeopleBreakdown } from '../utils/usePeopleBreakdown';
import { TypographyWithTooltip } from '../../../components/EllipsisText/EllipsisText';
import { Panel } from './Panel';
import { usePanelPagination } from './CompanyDetailsPanel';

export const DetailedPersonDataContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1.5rem;
`;

const leadershipContainer = css`
  display: grid;
  align-items: start;
  height: 100%;
`;

export function LeadershipPanel() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const isPortco = company?.companyType === 'portfolio';
  const { getLeaders, getRelationships } = usePeopleBreakdown();

  const leaders = useMemo(() => getLeaders(), [getLeaders]);
  const relationships = useMemo(() => {
    if (!isPortco) return [];
    return getRelationships().slice(0, 4);
  }, [getRelationships, isPortco]);
  const baseProfileUrl = useProfileBasePath();

  let maxLeaders = 1;
  if (isPortco) {
    if (relationships.length === 0) maxLeaders = 3;
    else if (relationships.length < 3) maxLeaders = 2;
  }
  const { paginationConfig, visibleFields } = usePanelPagination({
    fields: leaders ?? [],
    itemsPerPage: maxLeaders,
  });

  if (!leaders) {
    return null;
  } else {
    const noData = leaders.length === 0;
    return (
      <Panel
        title={'Leadership'}
        showMore={
          noData
            ? undefined
            : {
                url: `${baseProfileUrl}/${ROUTES.COMPANY_PROFILE_PEOPLE}`,
                title: 'Go to People',
              }
        }
        paginationConfig={paginationConfig}
      >
        {noData ? (
          <NoDataMessage2 />
        ) : (
          <div css={leadershipContainer}>
            <DetailedPersonDataContainer>
              {visibleFields.map((person) => (
                <DetailedPersonData person={person} key={person.id} />
              ))}
            </DetailedPersonDataContainer>
            {relationships.length > 0 && isPortco && (
              <Stack alignSelf={'self-end'} mb={'1rem'}>
                <Divider style={{ gridColumn: '1/-1', margin: '1.5rem 0 0.75rem' }} />
                <Typography mb='0.5rem'>Relationships</Typography>
                <Relationships people={relationships} />
              </Stack>
            )}
          </div>
        )}
      </Panel>
    );
  }
}

export function Relationships({ people }: { people: IPersonDataModel[] }) {
  return (
    <Stack
      display={'grid'}
      gridTemplateColumns={'1fr 1fr'}
      columnGap={'1rem'}
      rowGap={'2rem'}
      alignContent={'stretch'}
    >
      {people.map((p) => (
        <PersonData person={p} key={p.id} avatarSize='medium' />
      ))}
    </Stack>
  );
}

export function DetailedPersonData({ person }: { person: IPersonDataModel }) {
  return (
    <>
      <PersonData person={person} />
      <WorkHistory workExperience={person.workExperience?.slice(0, 3) ?? []} />
    </>
  );
}

export function WorkHistory({ workExperience }: { workExperience: IWorkExperienceDataModel[] }) {
  return (
    <GenericFallbacksWrapper>
      <Stack
        display={'grid'}
        gridTemplateColumns={'auto 1fr auto'}
        alignItems={'center'}
        columnGap={'1rem'}
        rowGap={'0.4rem'}
        alignContent={'start'}
      >
        <Typography variant='caption' color='text.secondary' gridColumn={'1/-1'}>
          Recent Work History
        </Typography>
        {workExperience.map((workExperience) => (
          <WorkHistoryItem
            workHistory={workExperience}
            key={`${workExperience.orgName}-${workExperience.startDate}-${workExperience.endDate}`}
          />
        ))}
      </Stack>
    </GenericFallbacksWrapper>
  );
}

const today = endOfDay(new Date());
export function WorkHistoryItem({ workHistory }: { workHistory: IWorkExperienceDataModel }) {
  const { companyId, orgName, startDate, endDate, title } = workHistory;
  const duration = startDate
    ? getFormattedDuration(new Date(startDate), endDate ? new Date(endDate) : today)
    : '';
  const company = useRecoilValueLoadable(companyState(companyId ?? -1)).valueMaybe();
  const website = company?.website ? formatExternalLink(company.website) : '';

  const companyProps = {
    name: orgName ?? '',
    logoUrl: company?.logoUrl ?? '',
    url: website,
    avatarProps: {
      size: 'small' as const,
    },
  };
  return (
    <>
      <CompanyLogoAndName {...companyProps} />
      <TypographyWithTooltip variant='body2' color='text.primary' noWrap>
        {title ?? ''}
      </TypographyWithTooltip>
      <Typography variant='body2' color='text.primary'>
        {duration}
      </Typography>
    </>
  );
}
