import { FC, useCallback } from 'react';
import { Stack, Button } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { IScenarioTransactionDataModel } from '../../../../../data-models/scenario.data-model';
import { ITransactionFieldsProps } from './TransactionFields';
import {
  getClearedRoundValues,
  getClearedExitValues,
  createInitialPartialExitValues,
} from './scenarioInitialValues';

export const TransactionFieldActions: FC<ITransactionFieldsProps> = ({ index, fieldArrayMethods }) => {
  const transactionType = useWatch({ name: `scenarioTransactions.${index}.type` });
  const id = useWatch({ name: `scenarioTransactions.${index}.id` });

  const { update, remove } = fieldArrayMethods;
  const {
    formState: { defaultValues },
  } = useFormContext();

  const handleClear = useCallback(() => {
    //`update` data is required and not partial, so we must get non-editable fields from initialValues if any
    let initialValues;
    if (id) {
      initialValues = defaultValues?.scenarioTransactions?.find(
        (t: IScenarioTransactionDataModel) => t.id === id
      );
    }
    update(index, {
      ...(initialValues ?? {}),
      ...getClearedRoundValues(),
      ...getClearedExitValues(),
      ...createInitialPartialExitValues(),
      type: transactionType,
    });
  }, [defaultValues?.scenarioTransactions, id, index, transactionType, update]);

  return (
    <Stack direction='row' gap='1rem'>
      <Button color='secondary' onClick={handleClear}>
        Clear Inputs
      </Button>
      <Button color='secondary' onClick={() => remove(index)}>
        Remove
      </Button>
    </Stack>
  );
};
