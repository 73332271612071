import { atomFamily, selectorFamily } from 'recoil';
import { endOfMonth, subMonths } from 'date-fns';
import { companyState } from '../../../services/state/CompanyState';
import { ICompanyFinancialsDataModel, KpiPeriod } from '../../../data-models/company-financials.data-model';
import { endOfFiscalPeriod } from '../utils/financialUtils';
import { allCompanyFinancialsState, findLatestDateAndPeriod } from './CompanyFinancialsState';

export const selectedMetricsDateCPState = atomFamily<Date, number>({
  key: 'selectedMetricsDateCPState',
  default: selectorFamily({
    key: 'selectedMetricsDateCPStateDefault',
    get:
      (companyId) =>
      ({ get }) => {
        const financials = get(allCompanyFinancialsState(companyId));
        const fye = get(companyState(companyId))?.fye ?? 12;
        const { latestDate } = findLatestDateAndPeriod(financials, fye);
        return latestDate ?? new Date();
      },
  }),
});
export const selectedFrequencyPerfState = atomFamily<KpiPeriod, number>({
  key: 'selectedFrequencyPerfState',
  default: selectorFamily({
    key: 'selectedFrequencyPerfState/Default',
    get:
      (companyId) =>
      ({ get }) => {
        const financials = get(allCompanyFinancialsState(companyId));
        const fye = get(companyState(companyId))?.fye ?? 12;
        return getDefaultFrequency(financials, fye);
      },
  }),
});

export function getDefaultFrequency(financials: ICompanyFinancialsDataModel[], fye = 12) {
  const { period } = findLatestDateAndPeriod(financials, fye);
  return period ?? KpiPeriod.quarter;
}
/**

 * @param fye [0-11]
 * @param frequency
 * @returns
 */

export function getDefaultFinancialsEndDate(fye: number, frequency: KpiPeriod) {
  const monthsToSubtract = frequency === KpiPeriod.year ? 12 : frequency === KpiPeriod.quarter ? 3 : 1;
  return endOfFiscalPeriod(endOfMonth(subMonths(new Date(), monthsToSubtract)), frequency, fye);
}
