import { Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, PropsWithChildren } from 'react';

const RightAligned = styled('span')`
  display: block;
  width: 100%;
  text-align: right;
`;

type BooleanRendererProps = {
  value: boolean | null | undefined;
};

export const BooleanRenderer = ({ value }: BooleanRendererProps) => {
  return value === true ? <RightAligned className='monospace'>{`✔`}</RightAligned> : null;
};

export const BooleanRenderer2 = ({ value }: BooleanRendererProps) => {
  return value ? <Cell>TRUE</Cell> : value === false ? <Cell>FALSE</Cell> : null;
};

const Cell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack direction='row' alignItems={'center'} justifyContent={'end'} height='100%' width='100%'>
      <Typography variant='body2' component={'span'}>
        {children}
      </Typography>
    </Stack>
  );
};

export const BooleanRendererYesNo = ({ value }: BooleanRendererProps) => {
  if (value === null || value === undefined) {
    return null;
  }
  return (
    <Stack direction='row' alignItems={'center'} justifyContent={'start'} height='100%' width='100%'>
      <Chip label={value ? 'Yes' : 'No'} color={value ? undefined : 'error'} />
    </Stack>
  );
};
