import { FC } from 'react';
import { ToggleButtonGroup, ToggleButton, Divider, Stack, styled } from '@mui/material';

const StyledStack = styled(Stack)({
  gridColumn: '1 / -1',
});
const NoWrapButton = styled(ToggleButton)({
  minWidth: 'fit-content',
});

export enum PartialSaleType {
  aggregated = 'Aggregated Shares',
  byFund = 'Shares by Fund',
}

interface IRoundParticipationToggleProps {
  value: PartialSaleType;
  handleChange: (value: PartialSaleType) => void;
  disabled?: boolean;
}

export const PartialSaleToggle: FC<IRoundParticipationToggleProps> = ({ value, handleChange, disabled }) => {
  return (
    <StyledStack>
      <Divider sx={{ my: '1rem' }} />
      <ToggleButtonGroup
        color='secondary'
        exclusive
        aria-label='partial sale type'
        value={value}
        onChange={(event, value) => {
          if (value) handleChange(value);
        }}
        disabled={disabled}
      >
        <NoWrapButton value={PartialSaleType.aggregated}>{PartialSaleType.aggregated}</NoWrapButton>
        <NoWrapButton value={PartialSaleType.byFund}>{PartialSaleType.byFund}</NoWrapButton>
      </ToggleButtonGroup>
    </StyledStack>
  );
};
