import { useEffect, useMemo } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { competitorsIdsCI } from '../../CompetitiveIntelligence/state/CompaniesState';
import {
  companyProfileDashboard,
  primaryCompanyIdCI,
  selectedDashboardIdCI,
} from '../../CompetitiveIntelligence/state/DashboardsState';

interface IUseInitCiState {
  companyId: number;
  dataLoaded: boolean;
}

export const useInitCiState = ({ companyId, dataLoaded }: IUseInitCiState) => {
  const primaryCompanyId = useRecoilValueLoadable(primaryCompanyIdCI);
  const setPrimaryCompanyId = useSetRecoilState(primaryCompanyIdCI);
  const setCompetitorsIdsCI = useSetRecoilState(competitorsIdsCI);

  const activeDashboardId = useRecoilValueLoadable(selectedDashboardIdCI);

  const setActiveDashboardId = useSetRecoilState(selectedDashboardIdCI);
  const cpDashboard = useRecoilValueLoadable(companyProfileDashboard);

  useEffect(() => {
    const dashboard = cpDashboard.valueMaybe();
    if (!dataLoaded || !dashboard) return;

    setActiveDashboardId(dashboard.id);
  }, [dataLoaded, cpDashboard, setActiveDashboardId]);

  useEffect(() => {
    setPrimaryCompanyId(companyId);
    setCompetitorsIdsCI([]);
  }, [companyId, setCompetitorsIdsCI, setPrimaryCompanyId]);

  const stateSet = useMemo(
    () => Boolean(primaryCompanyId != null && dataLoaded && cpDashboard && activeDashboardId),
    [cpDashboard, dataLoaded, primaryCompanyId, activeDashboardId]
  );

  return stateSet;
};
