import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { schemaToFormFields } from '../../../util/schema-utils';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { createFundData, FundData, fundDataFormSchema } from '../../../schemas/FundData.schema';
import { Fund } from '../../../schemas/Fund.schema';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { isEditingFundDataState } from '../state/FPState';
import { SettingsFieldset } from './WaterfallSettingsFields';
import { EditableComponent } from './EditableComponent';

export function WaterfallSettingsEditableFields({ fund, fundData }: { fund: Fund; fundData?: FundData }) {
  const setEdit = useSetRecoilState(isEditingFundDataState);
  const fields = useMemo(() => {
    return schemaToFormFields(
      fundDataFormSchema().pick(['netAssets', 'contributedSecurities', 'deemedContributions'])
    ).map((f) => ({ ...f, variant: 'form-inline' as const, disabled: true }));
  }, []);
  const methods = useForm<FundData>({
    defaultValues: fundData ?? createFundData({ fundId: fund.id }),
  });
  return (
    <FormProvider {...methods}>
      <EditableComponent
        onEdit={() => {
          setEdit(true);
        }}
      >
        <UnstyledFieldset css={SettingsFieldset}>
          {fields.map((field) => (
            <FormFieldWithLabelFactory key={field.key} formField={field} />
          ))}
        </UnstyledFieldset>
      </EditableComponent>
    </FormProvider>
  );
}
