import { createFinanceRoundDataModel, IFinanceRoundDataModel } from '../data-models/finance-round.data-model';
import {
  createCompanySearchResponseDataModel,
  createCompanySearchResultFields,
  ICompanySearchResponseDataModel,
} from '../data-models/company-search-result.data-model';

export interface IFinanceRoundFormViewModel extends IFinanceRoundDataModel {
  _viewModel: {
    coInvestors: ICompanySearchResponseDataModel[];
    leadInvestors?: ICompanySearchResponseDataModel[];
    shouldBeRemoved?: boolean;
  };
}

export function createFinanceRoundFormViewModel(
  overrides: Partial<IFinanceRoundFormViewModel>
): IFinanceRoundFormViewModel {
  return {
    _viewModel: {
      coInvestors: [],
      leadInvestors: [],
    },
    ...createFinanceRoundDataModel(overrides),
  };
}

export function isNewFinanceRound(data: Partial<IFinanceRoundFormViewModel>) {
  return isNewRoundId(data.id);
}

export function isNewRoundId(id: number | null | undefined) {
  return typeof id !== 'number' || id < 0;
}

export function fromFinanceRoundDataModel(dataModel: IFinanceRoundDataModel) {
  return createFinanceRoundFormViewModel({
    _viewModel: {
      leadInvestors: (dataModel.leadInvestors ?? []).map((investor) => {
        return createCompanySearchResponseDataModel({
          fields: createCompanySearchResultFields({
            name: investor,
          }),
        });
      }),
      coInvestors: (dataModel.coInvestors ?? []).map((investor) => {
        return createCompanySearchResponseDataModel({
          fields: createCompanySearchResultFields({
            name: investor,
          }),
        });
      }),
    },
    ...dataModel,
  });
}

export function toFinanceRoundDataModel(viewModel: IFinanceRoundFormViewModel): IFinanceRoundDataModel {
  const { _viewModel, ...dataModel } = viewModel;
  const leadInvestors = _viewModel.leadInvestors?.map((investor) => investor.fields.name);
  const coInvestors = _viewModel.coInvestors?.map((coInvestor) => coInvestor.fields.name);

  return {
    ...dataModel,
    leadInvestors,
    coInvestors,
  };
}
