import { Typography, styled } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useListCommentActions } from '../../../services/hooks/useListActions';
import { IListItemDataModel } from '../../../data-models/list-item.data-model';
import { CommentCreation } from '../../../components/Comment/CommentCreation';
import { CommentList } from '../../../components/Comment/CommentsList';

const NotesSection = styled('div')`
  margin-top: 1rem;
  display: grid;
  align-content: start;
  gap: 1rem;
`;

interface IItemCommentsSectionProps {
  item: IListItemDataModel;
}

export const ItemCommentsSection: FC<IItemCommentsSectionProps> = ({ item }) => {
  const { createItemComment, updateItemComment, deleteItemComment, loading, loadingId } =
    useListCommentActions();

  const allCommentsWithLoadingStatus = useMemo(
    () => item.comments?.map((c) => ({ ...c, isLoading: loadingId === c.id })),
    [item.comments, loadingId]
  );

  const handleCreate = useCallback(
    (comment: string) => {
      createItemComment(item, comment);
    },
    [createItemComment, item]
  );

  const handleUpdate = useCallback(
    async (comment: string, commentId: number) => {
      await updateItemComment(item, commentId, comment);
    },
    [item, updateItemComment]
  );

  const handleDelete = useCallback(
    (commentId: number) => {
      deleteItemComment(item, commentId);
    },
    [deleteItemComment, item]
  );

  return (
    <div>
      <Typography variant='body2'>Notes</Typography>

      <CommentCreation onCreate={handleCreate} isLoading={loading} />
      <NotesSection>
        <CommentList
          comments={allCommentsWithLoadingStatus ?? []}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      </NotesSection>
    </div>
  );
};
