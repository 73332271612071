import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { kpisRequestsMapByIdState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { companyState } from '../../../../../../services/state/CompanyState';
import { kpiTemplatesMapByUuidState } from '../../../../../../services/state/KPI/KPITemplatesState';

export function useResponseFileName() {
  const { requestId } = useParams();
  const request = useRecoilValue(kpisRequestsMapByIdState)?.get(Number(requestId));
  const companyName = useRecoilValue(companyState(request?.companyId ?? -1))?.name;
  const template = useRecoilValue(kpiTemplatesMapByUuidState)?.get(request?.templateUuid ?? '');

  return useCallback(() => {
    return `${companyName}_${template?.name}_${request?.period}`;
  }, [companyName, request?.period, template?.name]);
}
