import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { filterMetricsTransactionsAndFundDataByFund, KpiCalculator } from '../services/kpi/KpiCalculator';
import { KpiConfigMeta, KPI } from '../../../types';
import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { selectedViewPF } from '../state/ViewState';
import { selectedViewDatePF } from '../state/PageState';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { QuartersBuilder } from '../services/QuartersBuilder';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { useCurrentViewMetrics } from './useCurrentViewMetrics';

export interface KPIMeta {
  isLoading: boolean;
  isFetching: boolean;
  kpis: KPI | null;
  previousQuarterKPIs: KPI | null;
  kpiMetas: KpiConfigMeta[]; // not needed if we decide to always calculate all kpis
}

export const useKPIs = (): KPIMeta => {
  const view = useRecoilValue(selectedViewPF);
  const date = useRecoilValue(selectedViewDatePF);
  const previousQuarter = useMemo(() => QuartersBuilder.getPreviousQuarterEnd(date), [date]);
  const { metrics, previousQuarterMetrics, filteredMetrics } = useCurrentViewMetrics();
  const [kpis, setKpis] = useState<KPI | null>(null);
  const [previousQuarterKPIs, setPreviousQuarterKPIs] = useState<KPI | null>(null);

  useEffect(() => {
    let calculatedKPIs = {} as KPI;
    let previousQuarterKPIs = {} as KPI;
    const selectedFunds =
      view.filteredFunds?.reduce((res, fund) => {
        return res.add(fund.id);
      }, new Set<number>()) ?? new Set();

    if (filteredMetrics.state === 'hasValue') {
      let dataFilteredByFund = filteredMetrics.valueMaybe() ?? [];
      if (view.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER && selectedFunds !== null) {
        dataFilteredByFund = filterMetricsTransactionsAndFundDataByFund(
          dataFilteredByFund as IMetricsDataModel[],
          selectedFunds
        );
      }

      calculatedKPIs = KpiCalculator.calculate(
        {
          date: date || new Date(),
          filteredData: dataFilteredByFund as IInvestmentDataModel[],
          rawCompanies: dataFilteredByFund as IMetricsDataModel[],
        },
        view.companyViewType
      );
    }

    if (previousQuarterMetrics.length > 0) {
      let dataFilteredByFund = filteredMetrics.valueMaybe() ?? [];
      if (view.companyViewType === COMPANY_VIEW_TYPE.ROUND_TRACKER && selectedFunds !== null) {
        dataFilteredByFund = filterMetricsTransactionsAndFundDataByFund(
          previousQuarterMetrics as IMetricsDataModel[],
          selectedFunds
        );
      }

      previousQuarterKPIs = KpiCalculator.calculate(
        {
          date: previousQuarter,
          filteredData: dataFilteredByFund as IInvestmentDataModel[],
          rawCompanies: dataFilteredByFund as IMetricsDataModel[],
        },
        view.companyViewType
      );
    }

    setKpis(calculatedKPIs);
    setPreviousQuarterKPIs(previousQuarterKPIs);
  }, [
    date,
    filteredMetrics,
    view.companyViewType,
    setKpis,
    setPreviousQuarterKPIs,
    previousQuarterMetrics,
    previousQuarter,
    view.filteredFunds,
  ]);

  return useMemo(
    () => ({
      isLoading: metrics.state === 'loading' || filteredMetrics.state === 'loading',
      isFetching: metrics.state === 'loading' || filteredMetrics.state === 'loading',
      kpis,
      previousQuarterKPIs,
      kpiMetas: KpiCalculator.getKpiMetas(view?.companyViewType),
    }),
    [metrics.state, filteredMetrics.state, kpis, previousQuarterKPIs, view?.companyViewType]
  );
};
