import { styled } from '@mui/material/styles';

const RightAligned = styled('span')`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

type PercentageFormatterProps = {
  value: number | null | undefined;
};

export const PercentageFormatter = ({ value }: PercentageFormatterProps) => {
  return value ? <RightAligned className='monospace'> {value + '%'}</RightAligned> : null;
};
