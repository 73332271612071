import {
  ITrackCompanyPayload,
  ITrackingDataModel,
  TrackingAction,
} from '../../data-models/tracking.data-model';
import { MaggieHttp } from '../MaggieHttp';

// fetch all tracking data for user
export async function fetchTrackingData() {
  const { data } = await MaggieHttp.maggie.get<ITrackingDataModel[]>(`/tracking`);
  return data;
}

/**
`track`/`untrack` action will update the `company.followers` field by adding/removing the current user's id.
 */
export async function trackCompany(
  companyId: number,
  trackAction: TrackingAction,
  payload?: ITrackCompanyPayload
) {
  const transformedPayload = transformTrackingPayload(payload!);
  const { data } = await MaggieHttp.maggie.put<ITrackCompanyPayload, ITrackingDataModel>(
    `/tracking/${companyId}/${trackAction}`,
    transformedPayload
  );
  return data;
}

function transformTrackingPayload(payload?: ITrackCompanyPayload) {
  if (!payload) return;
  return {
    ...payload,
    snoozeDate: payload.snoozeDate ?? null,
    snoozeComment: payload.snoozeComment ?? null,
  };
}
