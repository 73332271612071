import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

const transTypes = new Set([
  'Investment - Capitalized Interest',
  'Investment - Restructure Out',
  'Investment - Restructure In',
]);

export class TotalCostConfig implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_COST as const;
  private title = 'Total Cost';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value =
      data?.rawCompanies
        ?.flatMap((c) => c.transactions)
        .reduce((acc, t) => {
          acc += t.investmentAmount ?? 0;

          if (transTypes.has(t.transType)) {
            acc += t.amount ?? 0;
          }
          return acc;
        }, 0) ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
