import { IconButton, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { uniq } from 'lodash-es';
import PersonDetailsIcon from '../../../../assets/icons/demography.svg?react';
import { ICompanyPersonDataModel, ICompanyDataModel } from '../../../../data-models/company.data-model';
import { StyledTooltip } from '../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { PersonDrawer } from './PersonDrawer';

interface ICompanyPeopleNamesProps {
  names: string | null;
  peopleData: ICompanyPersonDataModel[] | null;
  company: ICompanyDataModel;
}

export const CompanyPeopleNames: FC<ICompanyPeopleNamesProps> = ({ names, peopleData, company }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<ICompanyPersonDataModel | null>(null);
  const { colors } = useTheme();

  const handleMoreDetails = useCallback((person: IPersonData) => {
    if (!person.id) return;
    setSelectedPerson(person as ICompanyPersonDataModel);
    setDrawerOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const people = getPersonData(names ?? '', peopleData ?? []);

  return (
    <>
      <PersonDrawer
        anchor={'right'}
        open={drawerOpen}
        onClose={handleClose}
        personData={selectedPerson}
        company={company}
      />

      <div>
        {people.map((person) => (
          <Typography variant='body2' key={person.name}>
            {person.name}
            <StyledTooltip title={person.id ? 'Review details' : 'No details available'} placement='top'>
              <span>
                <IconButton
                  style={{ marginLeft: '0.25rem' }}
                  onClick={() => handleMoreDetails(person)}
                  disabled={!person.id}
                >
                  <PersonDetailsIcon fill={!person.id ? colors.neutral[40] : colors.primary[60]} />
                </IconButton>
              </span>
            </StyledTooltip>
          </Typography>
        ))}
      </div>
    </>
  );
};

interface IPersonData extends Pick<ICompanyPersonDataModel, 'name'> {
  id: number | null;
}

/**
 * Returned value will include only those names that are in `peopleNames`;
 * null id means there is no corresponding name in `peopleData`, so no additional info can be shown
 */
export function getPersonData(peopleNames: string, peopleData: ICompanyPersonDataModel[]): IPersonData[] {
  if (!peopleNames) return [];
  const names = uniq(peopleNames?.split(',').map((name) => name.trim()));

  const result: IPersonData[] = [];

  const peopleMap = new Map<string, ICompanyPersonDataModel>();
  peopleData.forEach((person) => peopleMap.set(person.name.toLowerCase(), person));

  names.forEach((name) => {
    const lowerCasedName = name.toLowerCase();
    const person = peopleMap.get(lowerCasedName);
    const id = person?.id ?? null;
    result.push({ id, name });
  });

  return result;
}
