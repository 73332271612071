import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { theme } from '../../../../../theme';
import { selectedFilterCI } from '../HistoryComparisonState';
import { FieldDataModel } from '../../../../../data-models/field.data-model';

interface FieldDataModelProps {
  field: FieldDataModel;
}

const { primary, neutral } = theme.colors;

const Wrapper = styled('div')<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${primary[0]};
  border: 1px solid ${({ isActive }) => (isActive ? primary[60] : neutral[10])};
  padding: 0.5rem;
  border-radius: 4px;
`;

export const HistoryComparisonFilter: FC<FieldDataModelProps> = ({ field }) => {
  const [selectedFilter, setSelectedFilter] = useRecoilState(selectedFilterCI);
  const { primary } = useTheme().colors;

  return (
    <Wrapper isActive={field.id === selectedFilter.id} onClick={() => setSelectedFilter(field)}>
      <Typography variant={'caption'} color={primary[100]}>
        {field.displayName}
      </Typography>
    </Wrapper>
  );
};
