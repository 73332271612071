import { LineChartData } from '../../../types';

type RawData = { period: string }[];

export const mapLineChartData = (data: RawData) => {
  const mappedData: Record<string, LineChartData> = {};
  const groupedData: Record<string, Record<string, number>> = {};

  data.forEach(({ period, ...rest }) => {
    groupedData[period] = { ...rest };
  });

  for (const [key, value] of Object.entries(groupedData)) {
    Object.keys(value).forEach((v) => {
      if (!(v in mappedData)) mappedData[v] = { id: v, data: [] } as LineChartData;
      mappedData[v] = { id: v, data: [...mappedData[v].data, { y: value[v], x: key }] };
    });
  }
  return Object.values(mappedData);
};
