import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { notificationConfigState } from '../state/ConfigState';
import { NotificationConfigResponse } from '../../../types';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';

export interface INotificationConfig {
  data: NotificationConfigResponse;
  setData: Dispatch<SetStateAction<NotificationConfigResponse>>;
  setIsMutating: Dispatch<SetStateAction<boolean>>;
}

export const useNotificationConfig = (): INotificationConfig => {
  const [data, setData] = useRecoilState(notificationConfigState);
  const [isMutating, setIsMutating] = useState(false);
  const { pushErrorToast } = useToastMessageState();

  useEffect(() => {
    if (!data || !isMutating) return;
    const updateConfig = async () => {
      try {
        const { deal, user, id } = data;
        await MaggieHttp.maggie.put(`/notificationConfig/${id}`, { deal, user });
      } catch {
        pushErrorToast({ message: 'An error occurred while updating notification preferences.' });
      }
    };
    updateConfig();
  }, [data, isMutating, pushErrorToast]);

  return { data, setData, setIsMutating };
};
