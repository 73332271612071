import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dashboardsCI, primaryCompanyIdCI, selectedDashboardIdCI } from '../state/DashboardsState';
import { DASHBOARD_TYPES, LoadingStatus } from '../../../types';
import { competitorsIdsCI } from '../state/CompaniesState';
import { useSetSearchParams } from '../../../hooks/useSetSearchParams';

export function useSyncFromPath() {
  const setActiveDashboardId = useSetRecoilState(selectedDashboardIdCI);
  const setPrimaryCompanyId = useSetRecoilState(primaryCompanyIdCI);
  const setCompetitorsId = useSetRecoilState(competitorsIdsCI);
  const dashboards = useRecoilValue(dashboardsCI);
  const [initLoadingStatus, setInitLoadingStatus] = useState<LoadingStatus>(LoadingStatus.loading);
  const [params] = useSearchParams();
  const setSearchParams = useSetSearchParams();
  const dashboardId = params.get('dashboardId');
  const primary = params.get('primary');
  const competitorsIds = params.get('competitors');

  useEffect(() => {
    const primaryCompany = parseInt(primary ?? '');
    setPrimaryCompanyId(primaryCompany);
  }, [primary, setPrimaryCompanyId]);

  useEffect(() => {
    const competitorsId = (competitorsIds ?? '')
      .split(',')
      .filter((val) => val.length > 0)
      .map((val) => parseInt(val))
      .filter((val) => !Number.isNaN(val));

    setCompetitorsId(competitorsId as number[]);
  }, [competitorsIds, setCompetitorsId]);

  useEffect(() => {
    const dashboardIdAsInt = parseInt(dashboardId || '');
    const dashboardExists = dashboards.find((dashboard) => dashboard.id === dashboardIdAsInt);

    if (dashboardExists) {
      setActiveDashboardId(dashboardIdAsInt);
      setInitLoadingStatus(LoadingStatus.success);
    } else {
      setSearchParams(
        {
          dashboardId:
            // find first dashboard that is not of type company-profile - should never reach fallback
            dashboards
              .find((dashboard) => dashboard.type !== DASHBOARD_TYPES.CompanyProfile)
              ?.id.toString() ?? dashboards[0].id.toString(),
        },
        { replace: true }
      );
    }
  }, [dashboardId, dashboards, setActiveDashboardId, setSearchParams]);

  return initLoadingStatus;
}
