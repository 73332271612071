import { Outlet } from 'react-router-dom';
import { usePortCos } from '../Finance2/hooks/usePortCos';

export function KpiLoader() {
  const { dataReady } = usePortCos();

  if (!dataReady) return null;

  return <Outlet />;
}
