import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { TimeRange } from './TimeRange';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export function TimeRangeWrapper() {
  const { primary } = useTheme().colors;
  const timeRanges = useMemo(
    () => [
      { value: 1, label: '1m' },
      { value: 3, label: '3m' },
      { value: 6, label: '6m' },
      { value: 24, label: '2y' },
    ],
    []
  );

  return (
    <Wrapper data-testid={'time-range-wrapper'}>
      <Typography variant={'subtitle2'} color={primary['100']}>
        Time Range
      </Typography>
      {timeRanges.map((tr) => (
        <TimeRange data={tr} key={tr.value} />
      ))}
    </Wrapper>
  );
}
