import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import { fundsByIdMapState, fundsState } from '../../../services/state/AppConfigState';
import { SelectItem } from '../../../types';
import { activeFundsState, areAllFundsSelectedState, checkedFundsState } from '../state/FundFilterState';
import { useDebouncedValue } from './useDebouncedValue';
import { useView } from './useView';

export interface IFundSelect {
  clearSelection: () => void;
  onSelect: (item: SelectItem) => void;
  onSelectAll: () => void;
  onSelectDeselectAll: () => void;
  onSelectGroup: (fundType: string) => void;
  onDeselectGroup: (fundType: string) => void;
  onChangeSelection: (newValue: SelectItem[]) => void;
}

export function useFundSelector() {
  const allFunds = useRecoilValue(fundsState);
  const fundsMap = useRecoilValue(fundsByIdMapState);
  const { setFundsFilter } = useView();

  const activeFundsFromView = useRecoilValue(activeFundsState);
  const activeFunds = useMemo(() => {
    return (
      activeFundsFromView?.reduce((acc, f) => {
        const fund = fundsMap.get(f.id);
        if (!fund) return acc;
        acc.push(fund);
        return acc;
      }, [] as IFundDataModel[]) ?? null
    );
  }, [activeFundsFromView, fundsMap]);

  const [checkedFunds, setCheckedFunds] = useRecoilState(checkedFundsState);
  const debouncedLocalFunds = useDebouncedValue(checkedFunds, 200);

  const hasAllSelected = useRecoilValue(areAllFundsSelectedState);

  useEffect(() => {
    const local = checkedFunds?.map((f) => f.id).toString() ?? '';
    const global = activeFunds?.map((f) => f.id).toString() ?? '';

    if (Array.isArray(checkedFunds) === Array.isArray(activeFunds) && local === global) return;

    const hasAllFundsSelected = activeFunds?.length === allFunds?.length;
    setCheckedFunds(hasAllFundsSelected ? null : activeFunds);
  }, [activeFunds, setCheckedFunds]); // eslint-disable-line

  useEffect(() => {
    const local = debouncedLocalFunds?.map((f) => f.id).toString() ?? '';
    const global = activeFunds?.map((f) => f.id).toString() ?? '';

    if (Array.isArray(debouncedLocalFunds) === Array.isArray(activeFunds) && local === global) return;

    setFundsFilter(debouncedLocalFunds);
  }, [debouncedLocalFunds]); // eslint-disable-line

  // handler for mui autocomplete
  const onChangeSelection = useCallback(
    (newValue: SelectItem[]) => {
      setCheckedFunds(newValue.map((v) => fundsMap.get(v.id as number)!));
    },
    [fundsMap, setCheckedFunds]
  );

  const onSelect = useCallback(
    ({ id }: SelectItem) => {
      if (!allFunds) return;

      const clickedFund: IFundDataModel | undefined = fundsMap.get(id as number);
      if (!clickedFund) return;

      if (!checkedFunds || checkedFunds.length === allFunds.length) {
        const allExceptOne = allFunds.filter((af) => af.id !== clickedFund.id);
        return setCheckedFunds(allExceptOne);
      }

      const fundToRemove: IFundDataModel | undefined = checkedFunds?.find((f) => f.id === id);
      if (fundToRemove) {
        const reduced = checkedFunds?.filter((f) => f.id !== id) ?? [];
        return setCheckedFunds(reduced);
      }

      const newFundsList = [...(checkedFunds ?? []), clickedFund];
      const isAllSelected = newFundsList?.length === allFunds?.length;
      setCheckedFunds(isAllSelected ? null : newFundsList);
    },
    [allFunds, fundsMap, checkedFunds, setCheckedFunds]
  );

  const onSelectAll = useCallback(() => {
    if (!allFunds) return;

    setCheckedFunds(null);
  }, [allFunds, setCheckedFunds]);

  const onSelectGroup = useCallback(
    (fundType: string) => {
      setCheckedFunds((prev) => {
        const previousItems =
          (prev ?? allFunds).filter((fund) => {
            return fund.fundType.toLowerCase() !== fundType.toLocaleLowerCase();
          }) ?? null;
        const newItems =
          allFunds.filter((fund) => {
            return fund.fundType.toLowerCase() === fundType.toLocaleLowerCase();
          }) ?? null;

        return !previousItems && !newItems ? null : [...(previousItems ?? []), ...(newItems ?? [])];
      });
    },
    [allFunds, setCheckedFunds]
  );

  const onDeselectGroup = useCallback(
    (fundType: string) => {
      setCheckedFunds((prev) => {
        return (prev ?? allFunds).filter((fund) => {
          return fund.fundType.toLowerCase() !== fundType.toLocaleLowerCase();
        });
      });
    },
    [allFunds, setCheckedFunds]
  );

  const onSelectDeselectAll = useCallback(() => {
    if (!allFunds) return;
    if (hasAllSelected) setCheckedFunds([]);
    else onSelectAll();
  }, [allFunds, hasAllSelected, onSelectAll, setCheckedFunds]);

  const clearSelection = useCallback(() => {
    return setCheckedFunds([]);
  }, [setCheckedFunds]);

  const value: IFundSelect = useMemo(
    () => ({
      clearSelection,
      onSelect,
      onSelectAll,
      onSelectDeselectAll,
      onSelectGroup,
      onDeselectGroup,
      onChangeSelection,
    }),
    [
      clearSelection,
      onSelect,
      onSelectAll,
      onSelectDeselectAll,
      onSelectGroup,
      onDeselectGroup,
      onChangeSelection,
    ]
  );

  return value;
}

export function matchRoundTrackerFunds(fundStringList: string, selectedFunds?: IFundDataModel[] | null) {
  return (
    !selectedFunds ||
    Boolean(selectedFunds?.some((f) => fundStringList.match(new RegExp('\\b' + f.name + '\\b'))))
  );
}
