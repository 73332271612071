import { FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputContainer } from '../../components/commonStyledComponents';
import { exitDateField, exitValuationField } from '../../forms/scenarioFields';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { IExtendedTransactionFieldsProps } from './TransactionFields';
import { PartialSaleToggle, PartialSaleType } from './PartialSaleToggle';
import { PartialSaleFields } from './PartialSaleFields';
import { createInitialPartialExitBreakdownValues } from './scenarioInitialValues';

export const PartialExitFields: FC<IExtendedTransactionFieldsProps> = ({ index, captable }) => {
  const { getValues, clearErrors, setValue } = useFormContext<Partial<IScenarioDataModel>>();
  const [type, setType] = useState<PartialSaleType>(() => {
    const transaction = getValues(`scenarioTransactions.${index}`);
    return transaction?.partialExitBreakdown?.some((breakdown) => breakdown.fundId)
      ? PartialSaleType.byFund
      : PartialSaleType.aggregated;
  });
  const handleTypeChange = useCallback(
    (typeValue: PartialSaleType) => {
      setType(typeValue);
      setValue(`scenarioTransactions.${index}.partialExitBreakdown`, [
        createInitialPartialExitBreakdownValues(),
      ]);
      clearErrors();
    },
    [clearErrors, index, setValue]
  );

  return (
    <InputContainer>
      <FormFieldWithLabelFactory formField={exitValuationField(index)} />
      <FormFieldWithLabelFactory formField={exitDateField(index)} />
      <PartialSaleToggle handleChange={handleTypeChange} value={type} />
      <PartialSaleFields key={type} captable={captable} transactionIndex={index} type={type} />
    </InputContainer>
  );
};
