import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Stepper, Step, StepLabel } from '@mui/material';
import { StepperBox, StepperSx, StepSx } from '../../../components/Form/FormComponents';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { CustomFieldFormStep1 } from './CustomFieldFormStep1';
import { CustomFieldFormStep2 } from './CustomFieldFormStep2';
import { CustomFieldFormCurrentStepState, useResetCustomFieldFormState } from './CustomFieldFormState';

const steps = [
  { label: 'Field Settings', component: <CustomFieldFormStep1 /> },
  { label: 'Additional Settings', component: <CustomFieldFormStep2 /> },
];

interface ICustomFieldFormProps {
  onClose: () => void;
  editMode?: boolean;
}
export function CustomFieldForm2({ onClose, editMode }: ICustomFieldFormProps) {
  const title = editMode ? 'Edit Custom Field' : 'Create Custom Field';

  return (
    <BasicDialog open title={title} onClose={onClose}>
      <CustomFieldForm2Content onClose={onClose} />
    </BasicDialog>
  );
}

export function CustomFieldForm2Content({ onClose }: ICustomFieldFormProps) {
  const currentStep = useRecoilValue(CustomFieldFormCurrentStepState);
  const resetFormState = useResetCustomFieldFormState();

  useEffect(() => {
    if (currentStep === steps.length) {
      onClose();
    }
  }, [currentStep, onClose]);

  useEffect(() => {
    return resetFormState;
  }, [resetFormState]);

  return (
    <StepperBox>
      <Stepper activeStep={currentStep} sx={StepperSx}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{steps[currentStep]?.component}</>
    </StepperBox>
  );
}
