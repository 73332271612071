import { useRecoilValue } from 'recoil';
import { useEffect, useMemo } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { fundFormCurrentStepState, useResetFundFormState, selectedFundStateFP } from '../state/FPState';
import { StepperContainer, StepperSx, StepSx } from '../../../components/Form/FormComponents';
import { FundViewModel } from '../../../schemas/FundViewModel.schema';
import { Fund } from '../../../schemas/Fund.schema';
import { FundFormStep1 } from './FundFormStep1';
import { FundFormStep2 } from './FundFormStep2';

export interface IFundFormProps {
  defaultValues?: Partial<FundViewModel>;
  onSubmit: (data: Partial<Fund>) => Promise<Fund | void>;
}

export function FundForm({ defaultValues, onSubmit }: IFundFormProps) {
  const fund = useRecoilValue(selectedFundStateFP);
  const currentStep = useRecoilValue(fundFormCurrentStepState);
  const resetForm = useResetFundFormState();

  useEffect(() => {
    return resetForm;
  }, [resetForm]);

  const steps = useMemo(
    () => [
      { label: 'Fund Settings', component: <FundFormStep1 defaultValues={defaultValues} /> },
      { label: 'Waterfall Tiers', component: <FundFormStep2 onSubmit={onSubmit} /> },
    ],
    [defaultValues, onSubmit]
  );

  if (!fund) return null;

  return (
    <StepperContainer>
      <Stepper activeStep={currentStep} sx={StepperSx}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{steps[currentStep]?.component}</>
    </StepperContainer>
  );
}
