import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { MONTHS } from '../pages/KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { DateFormattersId } from '../util/formatters/DateFormatters';
import { SearchType } from '../services/queries/MaggieCompanyQueries';
import { locationFields } from './Location.schema';

export const CompanySchema = {
  base() {
    return {
      acquirer: yup
        .string()
        .label('Acquirer')
        .fieldMeta({
          renderer: RendererType.companySearch,
          rendererMeta: {
            multiSelect: false,
            showAdd: false,
            searchType: SearchType.Company,
            createOnSelection: false,
          },
        }),
      adjustHistoricKPIs: yup.boolean().nullable(),
      aliases: yup.string().label('Also Known As'),
      activeStatus: yup.string().nullable().optional().label('Status'),
      boardActiveStatus: yup.string().nullable().optional().label('Board Active Status'),
      boardDirectorId: yup.number().label('Board Director'),
      boardStatus: yup
        .string()
        .nullable()
        .optional()
        .label('Board Type')
        .customMeta({
          renderer: RendererType.singleSelect,
          rendererMeta: {
            values: Object.values(BoardStatus).map((status) => ({
              value: status,
              displayName: status,
            })),
          },
        }),
      ceoName: yup.string().label('CEOs'),
      description: yup.string().label('Long Description'),
      dealLeadId: yup.number().label('Deal Lead'),
      dealTeamIds: yup.array().of(yup.number()).label('Deal Team'),
      eventsRegion: yup.string().nullable().optional().label('Events Region'),
      floRegion: yup.string().nullable().optional().label('FLO Region'),
      founded: yup.number().label('Founded'),
      foundedDate: yup.string().optional().label('Founded Year'),
      fye: yup
        .number()
        .nullable()
        .optional()
        .label('FYE')
        .meta({
          formatter: 'fye',
          renderer: RendererType.singleSelect,
          rendererMeta: {
            values: MONTHS.map((month, index) => ({
              value: index + 1,
              displayName: month,
            })),
          },
        }),
      id: yup.number().nullable().optional(),
      internalDescription: yup.string().nullable(),
      internalSourceId: yup.number().label('Description'),
      lastPostMoney: yup.number().label('Last Post Money Valuation'),
      latestFundingAmount: yup.number().label('Last Funding Amount'),
      latestFundingDate: yup.date().label('Last Funding Date'),
      latestLeadInvestor: yup.array(yup.string()).label('Latest Lead Investors'),
      latestFundingRound: yup.string().label('Last Funding Round'),
      legalName: yup.string().label('Legal Name'),
      name: yup.string().label('Name'),
      primaryLocation: yup.object(locationFields()).nullable().label('Primary Location'),
      region: yup.string().nullable().optional().label('Region'),
      respondents: yup.array().of(yup.string()).label('Respondent(s)').default([]),
      secondaryLocations: yup.array().of(yup.object(locationFields())).nullable().default([]),
      secondaryRespondents: yup.array().of(yup.string()).label('CC email').nullable().default([]),
      sectorId: yup.number().nullable().optional().label('Sector'),
      shortDescription: yup.string().label('Description'),
      socials: yup.string().label('Profiles'),
    };
  },
  formSchema() {
    return yup.object(this.base());
  },
  gridSchema() {
    const base = this.base();

    return yup.object().shape({
      ...base,
      boardDirectorId: base.boardDirectorId.gridMeta({
        renderer: RendererType.id,
        formatter: 'user',
      }),
      dealLeadId: base.dealLeadId.gridMeta({ renderer: RendererType.id, formatter: 'user' }),
      foundedDate: base.foundedDate.label('Founded Year').gridMeta({
        renderer: RendererType.date,
        formatter: DateFormattersId.YYYY,
      }),
      id: base.id.label('Company').gridMeta({ renderer: RendererType.companyId }),
      sectorId: base.sectorId.gridMeta({ renderer: RendererType.id, formatter: 'sector' }),
    });
  },
};
export const BoardStatus = {
  Director: 'Director',
  Observer: 'Observer',
  None: 'None',
} as const;

export type BoardStatus = (typeof BoardStatus)[keyof typeof BoardStatus];
