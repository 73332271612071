import { IRoundDataModel } from '../../data-models/round.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class RoundFormatter
  implements IInitializedFormatter<Map<number, IRoundDataModel>, number | number[]>
{
  #rounds: Map<number, IRoundDataModel> = new Map();

  init(rounds: Map<number, IRoundDataModel>) {
    this.#rounds = rounds;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#rounds.get(value)?.displayName ?? value.toString();
  };
}
