import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useAtomValue } from 'jotai';
import {
  fundReservesTransactionUuidToInvestmentState,
  selectedFundCompanyNameState,
  selectedFundIdStateFP,
  selectedFundReservesByCompanyNameState,
  selectedInvestmentTransactionUuidState,
  showReservesFormState,
  useResetReservesFormState,
} from '../state/FPState';
import { portCosByNameMapState } from '../../../services/state/PortCosState';
import { companyToSearchResult } from '../../../util/companyUtils';
import {
  CompanySearchResponse,
  createCompanySearchResponse,
} from '../../../schemas/CompanySearchResponse.schema';

export function useReservesFormState() {
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const showForm = useAtomValue(showReservesFormState);
  const selectedCompanyName = useAtomValue(selectedFundCompanyNameState);
  const portcosByName = useRecoilValue(portCosByNameMapState);

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyName) return;
    const portco = portcosByName.get(selectedCompanyName ?? '');
    if (portco) return companyToSearchResult(portco);

    return createCompanySearchResponse({
      fields: { name: selectedCompanyName ?? '' },
    } as CompanySearchResponse);
  }, [portcosByName, selectedCompanyName]);

  const selectedTransactionUuid = useAtomValue(selectedInvestmentTransactionUuidState);
  const transactionsByUuid = useRecoilValue(fundReservesTransactionUuidToInvestmentState(fundId ?? -1));
  const selectedTransaction = useMemo(() => {
    return transactionsByUuid
      .get(selectedTransactionUuid ?? '')
      ?.transactions?.find((t) => t.uuid === selectedTransactionUuid);
  }, [transactionsByUuid, selectedTransactionUuid]);
  const companyNameToInvestment = useRecoilValue(selectedFundReservesByCompanyNameState(fundId ?? -1));
  const selectedInvestment =
    companyNameToInvestment.get(selectedCompanyName ?? '') ??
    transactionsByUuid.get(selectedTransactionUuid ?? '');
  const resetFormState = useResetReservesFormState();

  return {
    resetFormState,
    selectedCompany,
    selectedCompanyName,
    selectedInvestment,
    selectedTransaction,
    showForm,
  };
}
