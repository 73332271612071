import { merge } from 'lodash-es';
import * as yup from 'yup';
import { useUserIdField } from '../../../data-fields/CommonFields';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { percentFieldHighPrecision0to100 } from '../../../schemas/common-schema-defs';
import { Fund, fundFields } from '../../../schemas/Fund.schema';
import { IUpdateFundFields } from '../../../services/queries/MaggieFundQueries';

export function useFPDigestSchema(fund: Fund) {
  const fields = fundFields();

  const partnersMeta = useUserIdField({
    key: 'generalPartners',
    multi: true,
    label: 'General Partners',
  });

  const strategyValues: ISimpleChoice<string>[] =
    fund.strategy?.map((strategy) => ({
      value: strategy as string,
      displayName: strategy,
    })) ?? [];

  const { generalPartners, strategy, lpCommitmentSplit, commitments } = fields;

  return yup
    .object({
      ...fields,
      commitments: commitments.optional(),
      generalPartners: generalPartners.meta({
        ...merge({}, partnersMeta),
      }),
      gpCommitmentPercentage: percentFieldHighPrecision0to100()
        .label('GP Commitment %')
        .nullable()
        .default(null),
      lpCommitmentSplit: lpCommitmentSplit.optional(),
      strategy: strategy.meta({
        ...strategy.meta,
        rendererMeta: {
          allowCustomAdd: true,
          values: strategyValues,
        },
      }),
    })
    .pick([
      'vintage',
      'lpCommitmentSplit',
      'gpCommitmentPercentage',
      'commitments',
      'investedManagementFee',
      'legalName',
      'stepDownDate',
      'taxId',
      'managementFeeTerminationDate',
      'generalPartners',
      'initialManagementFee',
      'investmentPeriodEndDate',
      'lineOfCredit',
      'inceptionDate',
      'finalCloseDate',
      'strategy',
    ]);
}
export type FPDigestSchema = Omit<
  yup.InferType<ReturnType<typeof useFPDigestSchema>>,
  'managementFeeTerminationDate'
> & {
  managementFeeTerminationDate?: string | null;
};

export function digestViewModelToFundDataModel(
  fundId: number,
  data: Partial<FPDigestSchema>
): IUpdateFundFields {
  const { gpCommitmentPercentage, ...fundDataModel } = data;

  return {
    ...fundDataModel,
    id: fundId,
  };
}

export function fundDataModelToDigestViewModel(data: Fund): FPDigestSchema {
  return {
    ...data,
    gpCommitmentPercentage: data.lpCommitmentSplit != null ? 100 - data.lpCommitmentSplit : null,
  };
}
