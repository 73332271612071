import { css } from '@emotion/react';

export const ActionContainer = css`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ExportButtonContainer = css`
  margin-left: auto;
  margin-bottom: 1.5rem;
  transform: translateY(-1.5rem);
  height: 0;
  overflow: visible;
`;
