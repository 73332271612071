import { useRecoilValue } from 'recoil';
import { QuartersBuilder } from '../services/QuartersBuilder';
import { mapLineChartData } from '../../../components/Charts/LineChart/mapLineChartData';
import { LineChartData } from '../../../types';
import { selectedViewDatePF } from '../state/PageState';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';

const sort = (a: string, b: string, index: number) => {
  const property1 = a.split(' ')[index];
  const property2 = b.split(' ')[index];
  if (property1 < property2) return -1;
  if (property1 > property2) return 1;
  return 0;
};

export const sortByYears = (a: string, b: string) => sort(a, b, 1);
export const sortByQuarters = (a: string, b: string) => sort(a, b, 0);

export const useNumberOfRoundsOverTime = (filteredData: IMetricsDataModel[]): LineChartData[] => {
  const date = useRecoilValue(selectedViewDatePF);
  const previous3Years = new Date(date || new Date());
  previous3Years.setFullYear(previous3Years.getFullYear() - 3);

  const filteredTransactionsInTheLast3Years = filteredData?.map(({ transactions }) =>
    transactions.filter(
      ({ transactionDate, investmentAmount }) =>
        new Date(transactionDate) >= previous3Years && investmentAmount !== 0
    )
  );

  const transactionsMappedByQuarter = filteredTransactionsInTheLast3Years?.flat().map((transaction) => {
    const transactionDate = new Date(transaction.transactionDate);
    const quarter = Math.floor(transactionDate.getMonth() / 3);
    return `Q${quarter + 1} ${transactionDate.getFullYear()}`;
  });

  const last12Quoters = QuartersBuilder.last12Quarters(date || new Date()).reduce(
    (acc, { label }) => ({ ...acc, [label]: 0 }),
    {} as Record<string, number>
  );

  transactionsMappedByQuarter?.forEach((quarter) => {
    if (quarter in last12Quoters) last12Quoters[quarter] += 1;
  });

  const mappedQuarters = Object.entries(last12Quoters).map(([period, value]) => ({
    period,
    'Number of Rounds': value,
  }));

  mappedQuarters?.sort(({ period: period1 }, { period: period2 }) => sortByQuarters(period1, period2));
  mappedQuarters?.sort(({ period: period1 }, { period: period2 }) => sortByYears(period1, period2));

  return mapLineChartData(
    mappedQuarters.map((quarter, index) => {
      if (index === 0) return { ...quarter };
      let sum = quarter['Number of Rounds'];

      for (let i = 0; i < index; i++) sum += mappedQuarters[i]['Number of Rounds'];
      return {
        ...quarter,
        'Number of Rounds': sum,
      };
    })
  );
};
