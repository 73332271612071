import { useCallback } from 'react';
import { addYears, subYears } from 'date-fns';
import { ColDef } from 'ag-grid-community';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { endOfFiscalPeriod, getFormattedFiscalDate } from '../../../utils/financialUtils';
import { FinancialsDisplaySettings } from '../components/FinancialsSettingsState';
import { useGetFinancialPeriodColDef } from '../hooks/useFinancialsColumnDefs';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { AddPinnedYearsMenu } from './AddPinnedYearsMenu';
import { useVisibleColumns } from './useVisibleColumns';
import { PinnedYearHeader } from './PinnedYearHeader';

export const PinnedYearColumnTag = 'pinnedYear';

interface IYearColumnDefinitionsParams {
  fye?: number; //[1-12]
  options?: Partial<FinancialsDisplaySettings>;
}

export function usePinnedYearColDefs() {
  const getPeriodColDef = useGetFinancialPeriodColDef();
  const { selectedYears } = useVisibleColumns();

  return useCallback(
    ({ fye = 12, options }: IYearColumnDefinitionsParams) => {
      if (!options?.showPinnedYears) return [];

      const fiscalYears = getPinnedYearColumnDates(fye - 1);
      const defs = [] as ColDef[];
      fiscalYears.forEach(({ date }, i) => {
        const colDef = getPeriodColDef({ date, frequency: KpiPeriod.year, fye, options });
        defs.push({
          ...colDef,
          colId: `${getPinnedYearColId(i)}`,
          headerClass: PinnedYearColumnTag,
          pinned: 'right',
          headerComponent: PinnedYearHeader,
          headerComponentParams: { yearIndex: i, ...(colDef.headerComponentParams ?? {}) },
          menuTabs: [],
          hide: !selectedYears.has(i),
        });
      });
      defs.push({
        colId: 'addPinnedYears',
        headerComponent: AddPinnedYearsMenu,
        headerComponentParams: { fye },
        maxWidth: 40,
        pinned: 'right',
        lockPosition: 'right',
        headerClass: PinnedYearColumnTag,
      });
      return defs;
    },
    [getPeriodColDef, selectedYears]
  );
}

/**
 * @param fye [0-11]
 */
export function getPinnedYearColumnDates(fye: number, currentDate = new Date()) {
  const dates = [
    endOfFiscalPeriod(subYears(currentDate, 2), KpiPeriod.year, fye),
    endOfFiscalPeriod(subYears(currentDate, 1), KpiPeriod.year, fye),
    endOfFiscalPeriod(currentDate, KpiPeriod.year, fye),
    endOfFiscalPeriod(addYears(currentDate, 1), KpiPeriod.year, fye),
    endOfFiscalPeriod(addYears(currentDate, 2), KpiPeriod.year, fye),
  ];

  return dates.map((date) => {
    const isoDate = formatISODateOnly(date);
    return {
      date,
      formattedDate: getFormattedFiscalDate(isoDate, KpiPeriod.year, fye + 1),
      isoDate,
    };
  });
}

export function getPinnedYearColId(idx: number) {
  return `${PinnedYearColumnTag}_${idx}`;
}

export const pinnedYearIndices = getPinnedYearColumnDates(0).map((_, i) => i);
export const allColIds = new Set(pinnedYearIndices.map((year) => getPinnedYearColId(year)));

export function parsePinnedYearIndex(colId: string) {
  return parseInt(colId.split('_')[1]);
}
