import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Button, IconButton, LinearProgress, Stack, styled, Typography, useTheme } from '@mui/material';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';
import { convertFileSize, validateFileExtension } from '../../../pages/KPI/utils';
import { UploadedFileMeta, uploadFile } from '../../../services/queries/FileQueries';
import { IFormFieldUploadMeta } from '../../../view-models/form.view-model';
import { IBaseFieldProps } from './types';

const DefaultMaxFileSize = 10 * 1024 * 1024; // 10MB

const InputFileWrapper = styled('div')`
  position: relative;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    height: 20px;
  }

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  & input {
    all: unset;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;

// FIXME MAGGIE-6618 - See if we can replace with FieldFile by moving upload code out.
export function FieldKPIFileUpload(props: IBaseFieldProps<IFormFieldUploadMeta>) {
  const { formField, formProps } = props;
  const { disabled, onChange } = formProps;
  const { companyId, maxFileSizeMb = DefaultMaxFileSize } = formField.rendererMeta!;
  const fileMeta = formProps.value as UploadedFileMeta | null;
  const [value, setValue] = useState(fileMeta);
  const { colors, palette } = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const borderColor = error ? palette.error.main : colors.neutral[60];

  const doUpload = useCallback(
    async (file: File) => {
      setError(null);

      if (file && file.size > maxFileSizeMb) {
        setError(`File size exceeds the limit of ${maxFileSizeMb / 1024 / 1024}MB`);
        return;
      }

      if (!validateFileExtension(file.name)) {
        setError('Invalid file type, please select a valid type (xlsx, xls, csv, pdf).');
        return;
      }

      setIsUploading(true);
      setValue({ fileName: file.name, fileSize: file.size, contentType: file.type });

      const { data, error } = await uploadFile(companyId, file);

      if (error || !data) {
        setError(error);
        setValue(null);
        onChange(null);
      } else {
        const uploadedMeta = { fileName: file.name, fileSize: file.size, contentType: file.type, ...data };
        setValue(uploadedMeta);
        onChange(uploadedMeta);
      }

      setIsUploading(false);
    },
    [maxFileSizeMb, companyId, onChange]
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.[0]) {
        doUpload(event.target.files[0]);
      }
    },
    [doUpload]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      doUpload(e.dataTransfer.files[0]);
    },
    [doUpload]
  );

  const onRemoveFile = useCallback(() => {
    setValue(null);
    onChange(null);
  }, [onChange]);

  const onCancelUploading = useCallback(() => {
    setValue(null);
    onChange(null);
    setIsUploading(false);
  }, [onChange]);

  return (
    <>
      {value ? (
        <ChosenFile
          cancelUploading={onCancelUploading}
          isUploading={isUploading}
          file={value}
          onRemoveFile={onRemoveFile}
          readOnly={disabled}
        />
      ) : null}
      {!isUploading && !disabled && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            border: `1px dashed ${borderColor}`,
            borderRadius: '4px',
          }}
        >
          <InputFileWrapper>
            <CloudUploadOutlinedIcon sx={{ color: colors.neutral[50], mr: '0.5rem' }} />
            <Typography
              variant='body2'
              sx={{
                colors: colors.neutral[60],
              }}
            >
              Drag and Drop or
              <Typography ml={'.25rem'} component='span' variant='body2' color='secondary'>
                Browse your files
              </Typography>
            </Typography>

            {/* This hidden input allows us to validate file easier without extra logic*/}
            <input type='hidden' value={companyId} />
            <input
              data-testid='file-upload-field'
              type='file'
              onChange={handleFileChange}
              onDrop={handleDrop}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,text/csv,application/xls,application/xlsx'
            />
          </InputFileWrapper>
        </Stack>
      )}
    </>
  );
}

function FileTitle({ name, size, url }: { name: string; size: number; url?: string }) {
  const { colors } = useTheme();

  return (
    <Typography
      variant='body2'
      component={'a'}
      sx={{ color: colors.neutral[70], mt: '.1rem' }}
      href={url ?? ''}
    >
      <p>{name}</p>
      {convertFileSize(size ?? 0)}
    </Typography>
  );
}

function ChosenFile({
  file,
  isUploading,
  onRemoveFile,
  cancelUploading,
  readOnly,
}: {
  file: UploadedFileMeta;
  onRemoveFile?: () => void;
  isUploading?: boolean;
  cancelUploading: () => void;
  readOnly?: boolean;
}) {
  const { colors } = useTheme();

  return (
    <Stack
      gap='.5rem'
      sx={{
        border: `1px solid ${colors.neutral[30]}`,
        p: '1rem',
        borderRadius: '0.25rem',
      }}
    >
      <Stack direction={'row'} alignItems='center' justifyContent='space-between'>
        <Stack direction={'row'} gap='.5rem' justifyContent={'center'}>
          <InsertDriveFileOutlinedIcon sx={{ color: colors.neutral[60] }} fontSize='medium' />
          <Button
            disabled={!file?.fileId}
            sx={{
              p: 0,
            }}
          >
            <FileTitle name={file.fileName} url={file.fileName} size={file.fileSize} />
          </Button>
        </Stack>
        {!readOnly &&
          (isUploading ? (
            <Typography variant='body2' component={'span'} sx={{ color: colors.neutral[70], mt: '.1rem' }}>
              {convertFileSize(file.fileSize ?? 0)}
            </Typography>
          ) : (
            <IconButton
              size='small'
              onClick={onRemoveFile}
              sx={{
                color: colors.neutral[50],
              }}
            >
              <CloseIcon />
            </IconButton>
          ))}
      </Stack>
      {isUploading && (
        <>
          <LinearIndeterminate />
          <Button
            onClick={cancelUploading}
            sx={{
              placeSelf: 'end',
              width: 'fit-content',
            }}
          >
            <Typography color='secondary' variant='body2' component={'span'} sx={{ mt: '.1rem' }}>
              Cancel Uploading
            </Typography>
          </Button>
        </>
      )}
    </Stack>
  );
}

function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%', px: '.25rem' }}>
      <LinearProgress color={'primary'} />
    </Box>
  );
}
