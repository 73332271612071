import {
  IScenarioRunByFundDataModel,
  IScenarioRunDataModel,
} from '../../../../data-models/scenario-run.data-model';
import { ScenarioTypeByTransactions } from '../SingleScenario/visualization/ScenarioVisualization';

export function getRunDataByFund(
  scenarioRunData: IScenarioRunDataModel[]
): Map<string, IScenarioRunByFundDataModel[]> {
  const result = new Map<string, IScenarioRunByFundDataModel[]>();

  scenarioRunData
    .map((runDataForRound) => runDataForRound.breakdownByFund ?? [])
    .flat()
    .reduce((map, curr) => {
      if (map.has(curr.fund)) {
        map.get(curr.fund)?.push(curr);
      } else {
        map.set(curr.fund, [curr]);
      }
      return map;
    }, result);

  return result;
}

export function getClientRunData(
  runData: (IScenarioRunDataModel | IScenarioRunByFundDataModel)[],
  scenarioType: ScenarioTypeByTransactions
) {
  if (scenarioType === 'partialExit' || scenarioType == 'roundAndPartialExit') {
    return runData.filter((round) => !!round.partialNumberOfShares);
  }
  return runData.filter((round) => round.numberOfSharesOwned > 0);
}

export function removeTotalRow(runData: IScenarioRunDataModel[]) {
  return runData.filter((round) => round.name !== 'Total');
}
