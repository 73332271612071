import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { CaptableType } from '../../../data-models/captable2.data-model';
import { getErrorMessage } from '../../../services/queryHelpers';
import { toCaptableDataModel, createCaptableViewModel } from '../../../view-models/captable.view-model';
import { selectedCompanyIdProfile } from '../state/UIState';
import { useCaptableActions } from './CapTableActions';
import { companyCaptablesByIdState } from './CapTableDataState';
import { shareClassesState, clientInvestmentsState, otherInvestmentsState } from './CapTableFormState';
import { CaptableIdParam } from './useSyncCaptableFormFromPath';

export function useCreateOrUpdateCaptable() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { updateCaptableHandler, createCaptableHandler } = useCaptableActions();
  const { pushErrorToast } = useToastMessageState();

  const [search] = useSearchParams();
  const editedCaptableId = parseInt(search.get(CaptableIdParam) ?? '');

  const shareClasses = useRecoilValue(shareClassesState);
  const clientInvestments = useRecoilValue(clientInvestmentsState);
  const otherInvestments = useRecoilValue(otherInvestmentsState);
  const captableMap = useRecoilValue(companyCaptablesByIdState(companyId));

  const captablePayload = useMemo(() => {
    const existing = captableMap.get(editedCaptableId ?? -1);
    const base = existing
      ? existing
      : { companyId, currencyId: 1, type: CaptableType.user, date: new Date().toISOString() };

    return toCaptableDataModel(
      createCaptableViewModel({
        ...base,
        _viewModel: {
          shareClasses,
          clientInvestments,
          otherInvestments,
        },
      })
    );
  }, [captableMap, clientInvestments, companyId, editedCaptableId, otherInvestments, shareClasses]);

  const createOrUpdateCaptable = useCallback(
    async (onSuccess?: () => void) => {
      let success = false;
      try {
        const data = editedCaptableId
          ? await updateCaptableHandler(captablePayload)
          : await createCaptableHandler(companyId, captablePayload);
        success = true;
        return data;
      } catch (err) {
        console.error(err);
        const message = getErrorMessage(
          err,
          `Failed to ${editedCaptableId != null ? 'update' : 'create'} cap table`
        );
        pushErrorToast({ message });
      } finally {
        if (success && onSuccess) onSuccess();
      }
    },
    [
      editedCaptableId,
      updateCaptableHandler,
      captablePayload,
      createCaptableHandler,
      companyId,
      pushErrorToast,
    ]
  );

  return { captablePayload, createOrUpdateCaptable };
}
