import * as yup from 'yup';
import { VALID_URL_REGEX } from '../../../../util/regex';
import FormErrorMessages from '../FormErrorMessages';
import { arePrimaryAndSecondaryFieldsUnique } from './schemaUtils';

export const secondaryWebsitesSchema = yup.object().shape({
  primary: yup.string().matches(VALID_URL_REGEX, FormErrorMessages.INVALID_URL).optional().nullable(),
  secondary: yup
    .array()
    .of(
      yup
        .string()
        .required(FormErrorMessages.EMPTY_FIELD)
        .matches(VALID_URL_REGEX, FormErrorMessages.INVALID_URL)
    )
    .test('unique', '', arePrimaryAndSecondaryFieldsUnique),
});
