import { BaseLocationDataModel } from '../../schemas/Location.schema';
import { LocationService } from '../GooglePlaces';

export async function getLocations(queryText: string): Promise<BaseLocationDataModel[]> {
  try {
    const locationService = await LocationService.get();
    const results = await locationService.getPlacePredictions({
      input: queryText,
      types: ['locality'],
    });

    return results.predictions.map((r) => {
      const city = r.structured_formatting.main_text;
      const secondaryText = r.structured_formatting.secondary_text;
      const secondaryTextArr = secondaryText.split(',');
      const country = secondaryTextArr.pop()?.trim() as string;
      const state = secondaryTextArr.join(',');
      return { city, state, country };
    });
  } catch (err) {
    console.error(`Failed to query locations for ${queryText}: ${err}`);

    return [];
  }
}
