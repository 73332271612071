import { SxProps, TextField } from '@mui/material';
import { FunctionComponent, KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { NumericInputProps } from '../../types';
import { ReadWriteTextField } from '../../components/FormField/FormTextField';
import { getTextWidth } from '../../util/getTextWidth';

import { IForm } from '../../view-models/form.view-model';

export const NumericFieldInput = (
  props: NumericInputProps & {
    onValueChanged: (newValue: number | null) => void;
    value?: number | null;
    defaultValue?: number;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    sx?: SxProps;
    fullWidth?: boolean;
    formatOnBlur?: (value: number) => string;
    dynamicWidth?: boolean;
    maxDynamicWidth?: string;
  } & Pick<IForm, 'variant'>
) => {
  const {
    onValueChanged,
    formatOnBlur,
    value,
    defaultValue,
    style,
    sx,
    fullWidth,
    disabled,
    helperText,
    placeholder,
    error,
    dynamicWidth,
    maxDynamicWidth,
    variant = 'form-inline',
    ...rest
  } = props;

  const [focused, setFocused] = useState(false);
  const [numericValue, setNumericValue] = useState<number | null>(() => {
    if (value != null) {
      return Number(value);
    } else if (defaultValue != null) {
      return Number(defaultValue);
    }
    return null;
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleBlur = useCallback(() => {
    setFocused(false);
    // defaultValue/value are initial values, so return if no change
    if (defaultValue != null && defaultValue === numericValue) return;
    if (value != null && value === numericValue) return;

    onValueChanged(numericValue);
  }, [defaultValue, numericValue, onValueChanged, value]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const formattedValue = useMemo(() => {
    if (formatOnBlur && numericValue !== null) return formatOnBlur(numericValue);
    return String(numericValue ?? '');
  }, [formatOnBlur, numericValue]);

  const handleChange = useCallback((values: NumberFormatValues) => {
    setNumericValue(values.floatValue ?? null);
  }, []);

  const onEnterPressed = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  }, []);

  const { prefix, suffix } = props;
  const textWidth = useMemo(() => {
    if (!dynamicWidth) return undefined;
    const minWidth = 80;
    const padding = 20;
    if (focused) {
      return Math.max(
        getTextWidth(`${String(numericValue ?? '')} + ${prefix ?? ''} + ${suffix ?? ''}`) + padding,
        minWidth
      );
    } else {
      return Math.max(getTextWidth(formattedValue) + padding, minWidth);
    }
  }, [dynamicWidth, focused, formattedValue, prefix, suffix, numericValue]);

  const CustomInputComponent = variant === 'form-inline' ? ReadWriteTextField : TextField;

  const commonProps = useMemo(
    () => ({
      onFocus: handleFocus,
      onBlur: handleBlur,
      className: variant === 'form-inline' ? 'read-write-input transparent aligned' : '',
      style,
      placeholder,
      error,
      InputProps: {
        style: { width: dynamicWidth ? `${textWidth}px` : undefined, maxWidth: maxDynamicWidth ?? undefined },
      },
    }),
    [handleFocus, handleBlur, variant, style, placeholder, error, dynamicWidth, textWidth, maxDynamicWidth]
  );

  return (focused || !formatOnBlur) && !disabled ? (
    <NumericFormat
      disabled={disabled}
      getInputRef={inputRef}
      customInput={CustomInputComponent as FunctionComponent}
      onValueChange={handleChange}
      onKeyUp={onEnterPressed}
      value={numericValue}
      autoFocus={focused}
      valueIsNumericString
      thousandSeparator
      {...commonProps}
      {...rest}
    />
  ) : (
    <CustomInputComponent
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      ref={inputRef}
      sx={sx}
      value={formattedValue}
      {...commonProps}
    />
  );
};
