import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { ReactElement, ReactNode, useState } from 'react';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Indicator } from '../Indicator/Indicator';
import { theme } from '../../theme';
import { Icon, IconTypes } from '../Icon';
import { MaggieFeatureFlags } from '../../util/feature-flags';

export interface IKPIProps {
  attributes?: DraggableAttributes;
  indicator: number | null;
  listeners?: SyntheticListenerMap;
  onDelete?: () => void;
  secondaryValue?: string;
  title: string;
  value: string;
  description?: string;
  icon?: ReactNode;
}

const { primary, neutral } = theme.colors;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const SecondaryValueWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OptionsWrapper = styled('div')<{ show: boolean }>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const FlexWrapper = styled('div')<{ vertical: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const iconContainer = css`
  display: grid;
  justify-content: center;
  align-content: center;
`;

export function KPI({
  indicator,
  value,
  title,
  onDelete,
  attributes,
  listeners,
  secondaryValue,
  description,
  icon,
}: IKPIProps): ReactElement {
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showSecondaryValueInCard } = useFlags<MaggieFeatureFlags>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setAnchorEl(null);
    } else if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  };

  const enableDrag = Boolean(attributes && listeners);
  const enableActions = Boolean(onDelete);
  const enableOptions = enableDrag || enableActions;

  return (
    <>
      <Wrapper
        className='kpi-card'
        data-testid={'KPI'}
        onMouseLeave={!enableOptions ? undefined : () => setShowActionButtons(false)}
        onMouseEnter={!enableOptions ? undefined : () => setShowActionButtons(true)}
      >
        <Header>
          {enableDrag && (
            <OptionsWrapper
              {...attributes}
              {...listeners}
              data-testid='drag-action-btn'
              show={showActionButtons}
              title='drag to reorder'
              role='button'
            >
              <Icon type={IconTypes.DRAG} color={neutral[30]} />
            </OptionsWrapper>
          )}
          <Typography
            color={'text.secondary'}
            component={Stack}
            direction={'row'}
            alignItems={'center'}
            gap='0.5rem'
            noWrap
            title={title}
          >
            <Typography variant={'subtitle2'} noWrap={true}>
              {title}
            </Typography>
            {description && (
              <Tooltip title={description} placement='top'>
                <InfoOutlinedIcon fontSize='inherit' />
              </Tooltip>
            )}
          </Typography>

          {enableActions && (
            <OptionsWrapper show={showActionButtons} data-testid='action-more-btn'>
              <IconButton
                aria-label='menu'
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                id='open-menu'
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </OptionsWrapper>
          )}
        </Header>
        {icon && <div css={iconContainer}>{icon}</div>}
        <FlexWrapper vertical={false}>
          <Typography variant={'h6'} color={primary[100]}>
            {value}
          </Typography>
          <Indicator indicator={indicator} />
        </FlexWrapper>
        {showSecondaryValueInCard && secondaryValue ? (
          <SecondaryValueWrapper>
            <Typography variant={'caption'} color={neutral[50]}>
              {secondaryValue}
            </Typography>
          </SecondaryValueWrapper>
        ) : (
          <div />
        )}
      </Wrapper>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='chart-menu'
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={onDelete} aria-label={'delete chart'}>
                    <ListItemIcon>
                      <DeleteOutlineIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete KPI</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
