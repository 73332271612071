import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { IPersonDataModel } from '../../../data-models/person.data-model';
import { usersByIdMapState } from '../../../services/state/AppConfigState';
import { getAllExperience } from '../utils/peopleHelpers';

export function getBasicContactInfo(person: IPersonDataModel) {
  if (person.primaryEmail) return person.primaryEmail;
  return '';
}

export function getTop5ConnectionIds(person: IPersonDataModel) {
  const { relationships } = person;
  return relationships?.map((relationship) => relationship.userId).slice(0, 5) ?? [];
}

export function getBasicWorkHistory(person: IPersonDataModel) {
  const experience = getAllExperience(person);
  return experience
    .reduce((acc, exp) => {
      if (exp.orgName) {
        if (exp.title) acc.push(`${exp.orgName} (${exp.title})`);
        else acc.push(exp.orgName);
      }
      return acc;
    }, [] as string[])
    .join(', ');
}

export function useColumnTextValueGetters() {
  const users = useRecoilValue(usersByIdMapState);

  const getConnections = useCallback(
    (person: IPersonDataModel) => {
      return getTop5ConnectionIds(person)
        .reduce((acc, id) => {
          const name = users.get(id)?.name;
          if (name) acc.push(name);
          return acc;
        }, [] as string[])
        .join(', ');
    },
    [users]
  );

  return {
    getContactInfo: getBasicContactInfo,
    getWorkHistory: getBasicWorkHistory,
    getConnections,
  };
}
