import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSSProperties, Dispatch, ReactElement, SetStateAction, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CSS } from '@dnd-kit/utilities';
import { useRecoilValue } from 'recoil';
import { SelectItem, ViewItem } from '../../../../../types';
import { selectedViewPF } from '../../../../../pages/PortfolioOverview/state/ViewState';
import { useViewNavigation } from '../../../../../pages/PortfolioOverview/components/ViewNavigation/ViewNavigationProvider';
import { VIEW_TYPE, ViewTypeByData } from '../../../../../data-models/view-config.data-model';
import { IconTypes } from '../../../../Icon';
import { ViewOption } from './ViewOption';

interface Props {
  items: ViewItem[];
  onItemSelect: (item: SelectItem) => void;
  style?: CSSProperties;
}

interface ItemProps {
  item: SelectItem;
  onClick: (item: SelectItem) => void;
  isDragDisabled: boolean;
  isSelected: boolean;
  viewTypeIcon?: string;
  viewType: VIEW_TYPE;
  activeField?: null | string;
  setActiveField?: Dispatch<SetStateAction<string | null>>;
}

const OptionsWrapper = styled('div')`
  max-height: 8rem;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OptionWrapper = styled('div')``;

const DraggableItem = ({
  item,
  onClick,
  isDragDisabled,
  isSelected,
  viewTypeIcon,
  viewType,
}: ItemProps): JSX.Element => {
  const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: item.id as string });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <OptionWrapper ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ViewOption
        viewType={viewType}
        viewTypeIcon={viewTypeIcon}
        isSelected={isSelected}
        isDraggable={!isDragDisabled}
        text={item.value}
        onClick={() => onClick(item)}
      />
    </OptionWrapper>
  );
};

export const Options = ({ items, onItemSelect, style }: Props): ReactElement => {
  const { viewsMap } = useViewNavigation();
  const [searchValue] = useState('');
  const selectedView = useRecoilValue(selectedViewPF);

  const getViewTypeIcon = useCallback(
    (id: string | number) => {
      const viewType = viewsMap[id]?.type;
      if (viewType === VIEW_TYPE.COMPANY) return undefined;
      if (viewType === VIEW_TYPE.PERSONAL && !!viewsMap[id]?.sharing?.length)
        return IconTypes.USER_SHARED_BY_ME;
      if (viewType === VIEW_TYPE.PERSONAL) return IconTypes.USER_HEART;
      if (viewType === VIEW_TYPE.SHARED) return IconTypes.USER_SHARED_WITH_ME;
    },
    [viewsMap]
  );

  const viewType = useCallback(
    (id: string | number) => {
      return viewsMap[id]?.type;
    },
    [viewsMap]
  );

  return (
    <OptionsWrapper style={style}>
      <SortableContext items={items as { value: string; id: string }[]}>
        {items.map(
          (item: ViewItem, index: number) =>
            item.isCompany && (
              <div style={{ width: '100%', display: 'block' }} key={index}>
                <ViewOption
                  isDraggable={false}
                  text={item.value}
                  onClick={() => onItemSelect(item)}
                  isSelected={selectedView.viewId === item.id}
                  viewTypeIcon={getViewTypeIcon(item.id)}
                  viewType={viewType(item.id)}
                  // hide until we have a way to save fund and people views
                  // FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5710
                  suppressMenu={
                    item.viewType === ViewTypeByData.fund || item.viewType === ViewTypeByData.people
                  }
                />
              </div>
            )
        )}

        {items.map(
          (item: ViewItem) =>
            !item.isCompany && (
              <div style={{ width: '100%', display: 'block', textAlign: 'start' }} key={item.id}>
                <DraggableItem
                  viewTypeIcon={getViewTypeIcon(item.id)}
                  isSelected={selectedView.viewId === item.id}
                  item={item}
                  onClick={onItemSelect}
                  isDragDisabled={!!searchValue}
                  viewType={viewType(item.id)}
                />
              </div>
            )
        )}
      </SortableContext>
    </OptionsWrapper>
  );
};
