import { useRecoilCallback } from 'recoil';
import { createKPI, deleteKPI } from '../../../services/queries/KPIConfigQueries';
import { kpiConfigState } from '../../../services/state/KPI/KPITemplatesState';
import { IField } from '../../../data-models/field2.data-model';
import { sortFields } from '../../../util/field-utils';

export function useKPIFieldActions() {
  const addKPIField = useRecoilCallback(({ set }) => async (kpiField: IField<unknown>) => {
    const newKPI = await createKPI(kpiField);
    set(kpiConfigState, (current) => {
      const newKPIs = [...current];
      newKPIs.push(newKPI);
      newKPIs.sort(sortFields);

      return newKPIs;
    });
  });

  const deleteKPIField = useRecoilCallback(({ set }) => async (kpiId: number) => {
    await deleteKPI(kpiId);
    set(kpiConfigState, (current) => {
      return current.filter((kpi) => kpi.id !== kpiId);
    });
  });

  return {
    addKPI: addKPIField,
    deleteKPI: deleteKPIField,
  };
}
