import { FC } from 'react';
import { Button } from '@mui/material';
import { useScenarioActions } from '../hooks/useScenarioActions';

export const ScenarioCancelButton: FC = () => {
  const { handleCancel } = useScenarioActions();

  return (
    <Button variant='outlined' size='medium' color='secondary' onClick={handleCancel} type='button'>
      Cancel
    </Button>
  );
};
