import { FieldPath, UseFormReturn, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormFundData, FundData } from '../../../schemas/FundData.schema';
import { formatISODateOnly } from '../../../util/formatters/DateFormatters';
import { IUpdateFundDataPayload } from '../../../services/queries/MaggieFundQueries';
import { useFundDataActions } from './useFundDataActions';

interface IFundFormActionParams {
  methods: UseFormReturn<FormFundData>;
  dataByDate: Map<string, FundData>;
  fundId: number;
}

interface ISubmitFundDataParams extends IFundFormActionParams {
  fundId: number;
}
export function useSubmitFundData({ methods, dataByDate, fundId }: ISubmitFundDataParams) {
  const {
    getValues,
    trigger,
    formState: { errors },
  } = methods;
  const { handleUpdateFundData, handleCreateFundData } = useFundDataActions();

  return useCallback(async () => {
    const formData = getValues();
    const isValid = await trigger();
    if (!isValid) {
      console.error(errors);
      return;
    }
    const date = formatISODateOnly(new Date(formData.date));
    const payload = { ...formData, fundId, date };
    const current = dataByDate.get(date);
    if (!current) {
      await handleCreateFundData(payload as FundData);
    } else {
      await handleUpdateFundData({ ...payload, id: current.id } as IUpdateFundDataPayload);
    }
  }, [getValues, trigger, fundId, dataByDate, handleCreateFundData, handleUpdateFundData, errors]);
}

const editableFields: FieldPath<FormFundData>[] = [
  'netAssets',
  'contributedSecurities',
  'deemedContributions',
];

export function useResetOnDateChange({ methods, dataByDate, fundId }: IFundFormActionParams) {
  const { control, setValue } = methods;
  const date = useWatch<FormFundData>({ control, name: 'date' });
  const [render, setRender] = useState(0);

  useEffect(() => {
    if (date && isValidDate(date as Date)) {
      const currentValues = dataByDate.get(formatISODateOnly(new Date(date)));
      setRender((r) => r + 1);
      editableFields.forEach((field) => {
        setValue(field as FieldPath<FormFundData>, currentValues?.[field] ?? null);
      });
      setValue('fundId', fundId);
    }
  }, [dataByDate, date, fundId, setValue]);

  return render;
}

export function useShouldDisableSubmit({ methods, dataByDate }: Omit<IFundFormActionParams, 'fundId'>) {
  const { control } = methods;
  const values = useWatch<FormFundData>({
    control,
  });

  return useMemo(() => {
    return shouldDisableSubmit({ methods, dataByDate, values });
  }, [dataByDate, methods, values]);
}

interface IShouldDisableSubmitParams extends Omit<IFundFormActionParams, 'fundId'> {
  values: Partial<FormFundData>;
}
export function shouldDisableSubmit({ methods, dataByDate, values }: IShouldDisableSubmitParams) {
  if (!values.date || !isValidDate(values.date) || !methods.formState.isDirty) {
    return true;
  }
  const currentValues = dataByDate.get(formatISODateOnly(values.date as Date));
  for (const field of editableFields) {
    if (!areFieldValuesEqual(currentValues?.[field], values[field])) return false;
  }
  return true;
}

function areFieldValuesEqual(a: unknown, b: unknown) {
  if ((a === undefined || a === null) && (b === undefined || b === null)) return true;
  return a === b;
}

function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.valueOf());
}
