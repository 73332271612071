import { FC, useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { Button, Typography } from '@mui/material';
import {
  packagesCI,
  selectedDashboardConfigCI,
} from '../../../../../pages/CompetitiveIntelligence/state/DashboardsState';
import { IPackageDataModel as PackageDataModel } from '../../../../../data-models/package.data-model';
import { theme } from '../../../../../theme';
import { Divider } from '../../../../Divider/Divider';
import { useUpdateDashboardConfig } from '../../../../../pages/CompetitiveIntelligence/hooks/useDashboardActions';

import { StyledNavbarSearch } from '../StyledNavbarSearch';
import { Package } from './Package';

const Wrapper = styled('div')`
  margin-top: 40px;
  width: 100%;
`;
const PackageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MetricsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding-right: 1rem;
  position: relative;
  overflow-y: auto;
  overscroll-behavior: contain;
  max-height: 520px;
  & [role='search'] {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  scrollbar-gutter: stable;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-right: 1rem;
`;

export const PackageSelector: FC = () => {
  const packages = useRecoilValue(packagesCI);
  const dashboardConfig = useRecoilValue(selectedDashboardConfigCI);
  const updateDashboardConfig = useUpdateDashboardConfig();
  const [filteredPackages, setFilteredPackages] = useState<PackageDataModel[] | null>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const onDeselectAll = useCallback(() => {
    if (!dashboardConfig) return;
    updateDashboardConfig({ ...dashboardConfig, selectedCategories: [] });
  }, [dashboardConfig, updateDashboardConfig]);

  useEffect(() => {
    if (!packages) return;
    setFilteredPackages(() => {
      const prevStateCopy = [...packages];

      if (searchFilter === '') return prevStateCopy;

      return prevStateCopy.map(({ categories, ...rest }) => {
        const filteredCategories = categories.filter(({ displayName }) =>
          displayName.toLowerCase().includes(searchFilter.toLowerCase().trim())
        );
        return { ...rest, categories: filteredCategories };
      });
    });
  }, [packages, searchFilter]);

  if (!packages) return null;

  return (
    <Wrapper>
      <Header>
        <Typography variant={'subtitle2'} color={theme.colors.secondary['10']}>
          PACKAGES
        </Typography>
        <Button variant='text' style={{ color: theme.colors.secondary['10'] }} onClick={onDeselectAll}>
          Remove All
        </Button>
      </Header>
      <MetricsWrapper>
        <StyledNavbarSearch onChange={(val: string) => setSearchFilter(val)} placeholder={'Find a metric'} />
        {filteredPackages?.map(({ id, displayName, categories }, i) => (
          <PackageWrapper key={id}>
            <Package name={displayName} categories={categories} />
            {Boolean(i !== filteredPackages.length - 1 && categories.length) && <Divider color='#867EC4' />}
          </PackageWrapper>
        ))}
      </MetricsWrapper>
    </Wrapper>
  );
};
