import { Button, Stack, styled, TextField, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Controller,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useRecoilValueLoadable } from 'recoil';
import { kpiTemplatesListState } from '../../services/state/KPI/KPITemplatesState';
import { PageContainer } from '../../components/Layout/PageContainer';
import { ROUTES } from '../../constants/RouteNameMapping';
import { createKPITemplateDataModel, IKPITemplate } from '../../data-models/kpi-template.data-model';
import { KPITemplateFormBuilder } from './components/KPITemplateFormBuilder';
import { useKPITemplateActions } from './hooks/useKPITemplateActions';
import { assignSortOrderTemplateSections, sortTemplateSections } from './utils';
import { KpiTemplateFormNavBlocker } from './KpiTemplateFormNavBlocker';

const ActionButtons = styled('div')`
  position: fixed;
  top: 0.8rem;
  right: 2rem;
`;

const TemplateNameTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'transparent',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '0',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export function KPITemplate() {
  const selectedTemplate = useCurrentTemplate();

  const { colors } = useTheme();
  const methods = useForm<IKPITemplate>({
    mode: 'onTouched',
    values: selectedTemplate,
  });
  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    // Auto focus on name field
    setTimeout(() => methods.setFocus('name'), 50);
  }, [methods, selectedTemplate]);

  return (
    <PageContainer>
      <FormProvider {...methods}>
        {selectedTemplate?.id && <ActionBar template={selectedTemplate} />}
        <Controller
          name='name'
          control={methods.control}
          defaultValue={selectedTemplate?.name || ''}
          render={({ field: { ref, ...field } }) => (
            <TemplateNameTextField
              {...field}
              required
              fullWidth
              sx={{
                '& fieldset': { border: 'none' },
                marginLeft: '-1rem',
              }}
              placeholder='Template Name'
              error={!!errors.name}
              helperText={errors.name ? <>{errors.name?.message}</> : ''}
              inputRef={ref}
              inputProps={{
                style: {
                  fontSize: '1.5rem',
                  fontWeight: 300,
                  color: colors.primary[60],
                },
              }}
            />
          )}
        />
        <Stack direction='row' gap={'.1rem'} width={'100%'} mt={'1.5rem'}>
          <KPITemplateFormBuilder />
        </Stack>
      </FormProvider>
    </PageContainer>
  );
}

interface IActionBarProps {
  template: IKPITemplate;
}
function ActionBar(props: IActionBarProps) {
  const { formState, watch, handleSubmit, trigger } = useFormContext();
  const { isDirty, isValid, isSubmitting } = formState;
  const name = watch('name').trim();
  const disableSave = useMemo(() => {
    return (!isDirty && isValid && !isSubmitting) || !name;
  }, [isDirty, isSubmitting, isValid, name]);
  const navigate = useNavigate();
  const { updateKPITemplateAction: updateKPITemplate, createKPITemplateAction } = useKPITemplateActions();

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async (data: FieldValues) => {
      const dataWithSortedSections = assignSortOrderTemplateSections(data);

      if (props.template.id === -1) {
        const kpiTemplate = await createKPITemplateAction(dataWithSortedSections);

        if (!kpiTemplate?.id) return;

        return navigate(`/${ROUTES.KPI_TEMPLATES_FULL_ROUTE}/${kpiTemplate.id}`);
      }
      await updateKPITemplate(dataWithSortedSections);
    },
    [createKPITemplateAction, navigate, props.template.id, updateKPITemplate]
  );

  return (
    <>
      <ActionButtons>
        <Stack direction='row' gap={'.5rem'}>
          <Button
            onClick={handleSubmit(onSubmit)}
            color='secondary'
            variant='contained'
            disabled={disableSave}
          >
            Save Changes
          </Button>
        </Stack>
      </ActionButtons>
      <KpiTemplateFormNavBlocker onSave={handleSubmit(onSubmit)} trigger={trigger} isDirty={isDirty} />
    </>
  );
}

function useCurrentTemplate() {
  const { templateId } = useParams();
  const isNewTemplate = useMemo(() => templateId === 'new', [templateId]);
  const templateList = useRecoilValueLoadable(kpiTemplatesListState).valueMaybe();

  return useMemo(() => {
    if (!templateList || isNewTemplate) {
      return createKPITemplateDataModel({
        id: -1,
        name: '',
        sections: [],
      });
    }

    const selectedTemplated = templateList.find((template) => `${template.id}` === templateId)!;

    return sortTemplateSections(selectedTemplated) as IKPITemplate;
  }, [isNewTemplate, templateId, templateList]);
}
