import { ColDef, IAggFunc, IAggFuncParams, ProcessCellForExportParams } from 'ag-grid-community';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { compareAsc } from 'date-fns';
import { commonProcessCb } from '../../../components/AgTable/exportToExcelDefs';
import { restructureIdToPositionMapState } from '../../../services/state/CompanyMetricsByIdState';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { isRealizedGainLoss } from '../../../data-models/transaction.data-model';
import { formatUSD } from '../../../util/formatters/NumericFormatters';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';

export function useSecurityUtils() {
  const companyId = useRecoilValue(selectedCompanyIdStateFinance);
  const restructureIdToPositionMap = useRecoilValue(restructureIdToPositionMapState(companyId ?? -1));

  const securityProcessCellCallback = useCallback(
    (params: ProcessCellForExportParams) => {
      if (params.node?.group && params.column.getColDef().cellRenderer === 'agGroupCellRenderer') {
        return restructureIdToPositionMap.get(params.node?.key ?? '') ?? 'N/A';
      }
      if (params.column.getColDef().colId === 'pps') {
        return params.value?.pps;
      }
      return commonProcessCb(params);
    },
    [restructureIdToPositionMap]
  );

  const autoGroupColumnDef: ColDef = {
    comparator: (a, b) => restructureIdComparator(a, b, restructureIdToPositionMap),
  };

  return { securityProcessCellCallback, autoGroupColumnDef };
}

export function eventFormatter(event: string): string {
  const lowerCaseEvent = event.toLowerCase();
  switch (lowerCaseEvent) {
    case 'investment - cost': {
      return 'Investment';
    }
    case 'investment - restructure in':
    case 'investment - restructure out': {
      return 'Restructure';
    }
    case 'investment - capitalized expense': {
      return 'Capitalized Expense';
    }
    case 'investment - capitalized interest': {
      return 'Capitalized Interest';
    }
    case 'investment - return of capital': {
      return ' Return of Capital';
    }
    case 'cash in - main account':
    case 'cash in - investment': {
      return 'Cash In';
    }
    case 'escrow receivable - principal': {
      return 'Escrow';
    }
    default: {
      return event;
    }
  }
}

export function restructureIdComparator(
  a: string,
  b: string,
  restructureIdToPositionMap: Map<string, string>
) {
  const positionA = restructureIdToPositionMap.get(a) ?? 'N/A';
  const positionB = restructureIdToPositionMap.get(b) ?? 'N/A';
  return positionA.localeCompare(positionB);
}

export const ppsAggFunc: IAggFunc = (
  params: IAggFuncParams<
    MetricsTransactionDataModel,
    Pick<MetricsTransactionDataModel, 'pps' | 'transactionDate'>
  >
) => {
  if (!params.values?.length) return;

  const latestPps = params.values.reduce((currentLatest, val) => {
    if (!val) {
      return currentLatest;
    } else if (compareAsc(new Date(currentLatest!.transactionDate), new Date(val.transactionDate)) === 1) {
      return currentLatest;
    } else {
      return val;
    }
  }, params.values.at(0));

  return {
    ...(latestPps ?? {}),
    toString: () => (latestPps?.pps != null ? formatUSD(latestPps.pps) : ''),
  };
};

export const SecurityAggFuncs: Record<string, IAggFunc> = {
  ['latest']: ppsAggFunc,
};

export function filterOutSecurityDataTemp(transactions: MetricsTransactionDataModel[]) {
  return transactions.filter((transaction) => {
    return !isRealizedGainLoss(transaction);
  });
}
