import { useRecoilCallback } from 'recoil';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import {
  bulkAssignTemplateQuery,
  deleteTemplateAssignment,
  fetchTemplatesAssignments,
  updateTemplateAssignment,
} from '../../../../../services/queries/KPITemplatesQueries';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { kpiTemplateAssignmentsListState } from '../../../../../services/state/KPI/KPICompaniesState';
import { LoadingId } from '../../../../../types';
import { useInvalidateKPIRequests } from '../../KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';
import { TemplateAssignment } from '../../../../../schemas/template-assignment-schema';
import { useInvalidateData } from '../../../../../hooks/useInvalidateData';
import { prepareBulkAssignPayload } from '../AllCompaniesForms/template-assignment-utils';
import { useHandleBulkResponse } from './useHandleBulkResponse';

export interface IAssignTemplateParams extends TemplateAssignment {
  companyIds: number[];
}
export function useTemplateAssignmentActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const invalidateData = useInvalidateData();
  const handleBulkResponse = useHandleBulkResponse();

  const assignTemplate = useRecoilCallback(
    () => async (payload: IAssignTemplateParams) => {
      actions.startLoading(LoadingId.bulkAssignTemplate);
      try {
        const response = await bulkAssignTemplateQuery(prepareBulkAssignPayload(payload));
        handleBulkResponse({
          response,
          onSuccess: async () => {
            await invalidateKpiRequests();
            await invalidateData({
              state: kpiTemplateAssignmentsListState,
              query: fetchTemplatesAssignments,
            });
          },
        });

        return response;
      } catch (err) {
        const message = getErrorMessage(err, `Failed to assign template`);
        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.bulkAssignTemplate);
      }
    },
    []
  );

  const updateAssignment = useRecoilCallback(
    () => async (payload: Partial<TemplateAssignment>) => {
      actions.startLoading(LoadingId.updateTemplateAssignmentRequest);
      try {
        const response = await updateTemplateAssignment(payload);
        await invalidateKpiRequests();

        await invalidateData({ state: kpiTemplateAssignmentsListState, query: fetchTemplatesAssignments });

        pushSuccessToast({
          message: 'Successfully updated template assignment',
        });
        return response;
      } catch (err) {
        const message = getErrorMessage(err, `Failed to update template assignment`);
        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.updateTemplateAssignmentRequest);
      }
    },
    []
  );

  const deleteAssignment = useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        actions.startLoading(LoadingId.deleteTemplateAssignmentRequest);

        try {
          const response = await deleteTemplateAssignment(id);
          await invalidateKpiRequests();
          set(
            kpiTemplateAssignmentsListState,
            (current) => current?.filter((assignment) => assignment.id !== id) ?? []
          );
          const successMsg = `Template successfully removed`;
          pushSuccessToast({
            message: successMsg,
          });
          return response;
        } catch (err) {
          const message = getErrorMessage(err, `Failed to delete assignment.`);
          pushErrorToast({ message });
        } finally {
          actions.stopLoading(LoadingId.deleteTemplateAssignmentRequest);
        }
      },
    [actions, pushErrorToast, pushSuccessToast]
  );

  return { assignTemplate, deleteAssignment, updateAssignment };
}
