import { atom, atomFamily, selector } from 'recoil';
import { IListDataModel } from '../../../data-models/list.data-model';
import { ListItemsResponse } from '../../../data-models/list-item.data-model';
import { fetchLists } from '../../../services/queries/MaggieListQueries';
import { ITrackingDataModel } from '../../../data-models/tracking.data-model';
import { fetchTrackingData } from '../../../services/queries/MaggieTrackingQueries';

export const listsState = atom<IListDataModel[] | null>({
  key: 'companiesLists',
  default: null,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          const companyLists = fetchLists();
          setSelf(companyLists ?? null);
        } catch (error) {
          console.error(error);
          setSelf(null);
        }
      }
    },
  ],
});

export const listsByIdState = selector<Map<number, IListDataModel>>({
  key: 'listsByIdState',
  get: ({ get }) => {
    const lists = get(listsState);

    return lists?.reduce((res, list) => res.set(list.id, list), new Map()) ?? new Map();
  },
});

export const companyListState = atomFamily<Omit<ListItemsResponse, 'companies'> | null, number | null>({
  key: 'companyListState',
  default: null,
});

export const activeCompanyListIdState = atom<number | null>({
  key: 'activeCompanyListIdState',
  default: null,
});

export const activeListState = selector<IListDataModel | null>({
  key: 'activeListState',
  get: ({ get }) => {
    const activeListId = get(activeCompanyListIdState);
    const listsById = get(listsByIdState);
    return activeListId ? listsById.get(activeListId) ?? null : null;
  },
});

export const togglePreviousDealsState = atom<boolean>({
  key: 'togglePreviousDealsState',
  default: false,
});

export const trackingDataState = atom<ITrackingDataModel[] | null>({
  key: 'trackingState',
  default: null,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          const trackingData = fetchTrackingData();
          setSelf(trackingData);
        } catch (error) {
          console.error(error);
          setSelf(null);
        }
      }
    },
  ],
});

export const trackingByCompanyIdState = selector<Map<number, ITrackingDataModel>>({
  key: 'trackingByCompanyIdState',
  get: ({ get }) => {
    const trackingData = get(trackingDataState);

    return (
      trackingData?.reduce((res, tracking) => res.set(tracking.companyId, tracking), new Map()) ?? new Map()
    );
  },
});
