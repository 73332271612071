import { TQuoter } from '../../../types';

export class QuartersBuilder {
  private static getQuarterEndDate(quarter: number, year: number): Date {
    if (quarter === 3) return new Date(`01/01/${year + 1} 23:59:59`);

    const qEnd = new Date((quarter + 1) * 3 + 1 + '/01/' + year);
    qEnd.setHours(23);
    qEnd.setMinutes(59);
    qEnd.setSeconds(59);

    return qEnd;
  }

  static getPreviousQuarterEnd(today = new Date()) {
    const quarter = Math.floor(today.getMonth() / 3);
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    return new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
  }

  private static getQuarter(date: Date, id: number): TQuoter {
    // quarter is 0-based here
    let quarter = Math.floor(date.getMonth() / 3);
    let year = date.getFullYear();
    quarter -= id;

    if (quarter < 0) {
      const yearsChanged = Math.ceil(-quarter / 4);
      year -= yearsChanged;
      // Shift quarter back to a nonnegative number
      quarter += 4 * yearsChanged;
    }

    const start = new Date(quarter * 3 + 1 + '/01/' + year);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);

    const end = QuartersBuilder.getQuarterEndDate(quarter, year);

    return {
      label: 'Q' + (quarter + 1) + ' ' + year,
      start,
      end,
    };
  }

  static lastFourQuarters(today = new Date()): TQuoter[] {
    const d = QuartersBuilder.getPreviousQuarterEnd(today);

    const dates: TQuoter[] = [];
    for (let i = 0; i < 4; i += 1) {
      dates.push(QuartersBuilder.getQuarter(d, i));
    }

    return dates;
  }

  static last12Quarters(today = new Date()): TQuoter[] {
    const d = QuartersBuilder.getPreviousQuarterEnd(today);

    const dates: TQuoter[] = [];
    for (let i = 0; i < 12; i += 1) {
      dates.push(QuartersBuilder.getQuarter(d, i));
    }

    return dates;
  }
}
