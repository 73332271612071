import { Tabs, Tab, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../constants/RouteNameMapping';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { kpiRequestsCountState } from '../../../../services/state/KPI/KPIRequestsState';
import { CountBadge, KPITabSx } from './TabComponents';

export default function KPIResponsesTabs() {
  const { pathname } = useLocation();
  const requestCount = useRecoilValue(kpiRequestsCountState);

  const tabs = useMemo(() => {
    const result = [];
    const toReviewPath = `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`;
    result.push(
      {
        path: toReviewPath,
        title: (
          <Stack direction='row'>
            To Review
            <CountBadge
              badgeContent={requestCount[KPIRequestStatus.Submitted]}
              active={pathname === toReviewPath}
            />
          </Stack>
        ),
      },
      {
        path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_ACCEPTED}`,
        title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_ACCEPTED],
      },
      {
        path: `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_REJECTED}`,
        title: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES_REJECTED],
      }
    );

    return result;
  }, [pathname, requestCount]);

  return (
    <Tabs variant='scrollable' scrollButtons='auto' value={pathname}>
      {tabs.map((t) => (
        <Tab
          component={NavLink}
          key={t.path}
          value={t.path}
          label={t.title}
          style={{ paddingLeft: 0, marginRight: '1rem' }}
          to={{ pathname: `${t.path}` }}
          sx={KPITabSx}
        />
      ))}
    </Tabs>
  );
}
