import { ICommentDataModel } from '../pages/DealFlow2/data-models/comment.data-model';
import { INoteAttendeeDataModel } from './note.data-model';

export interface INoteCommentDataModel extends ICommentDataModel {
  noteId: number;
}

export type NoteCommentCreationPayload = Pick<INoteCommentDataModel, 'noteId' | 'comment'>;

export type NoteCommentUpdatePayload = Pick<INoteCommentDataModel, 'noteId' | 'comment'> & {
  commentId: number;
};

export function createNoteAttendeeDataModel(
  overrides?: Partial<INoteAttendeeDataModel>
): INoteAttendeeDataModel {
  return {
    userId: 1,
    rating: -1,
    ...overrides,
  };
}

const currentDateISOString = new Date().toISOString();

export function createNoteCommentDataModel(overrides?: Partial<INoteCommentDataModel>): ICommentDataModel {
  return {
    id: 0,
    comment: '',
    createdBy: '',
    updatedBy: null,
    createdAt: currentDateISOString,
    updatedAt: null,
    isLoading: false,
    ...overrides,
  };
}
