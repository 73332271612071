import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { CapTableInvestmentTypes } from './CaptableInvestment.schema';
import { integerField, percentField, usdField } from './common-schema-defs';

export const StakeholderInvestmentSchemas = {
  base() {
    return {
      authorizedShares: yup.number().nullable().optional().label('Authorized Shares').default(undefined),
      cashRaised: yup.number().nullable().optional().label('Cash Raised').default(undefined),
      currencyId: yup
        .number()
        .nullable()
        .required()
        .label('Currency')
        .default(1)
        .meta({ renderer: RendererType.currencyId }),
      discount: yup.number().nullable().optional().label('Discount').default(undefined),
      fullyDilutedShares: integerField()
        .nullable()
        .optional()
        .label('Fully Diluted Shares')
        .default(undefined),
      fundId: yup.number().nullable().optional().label('Fund ID').default(undefined),
      fundName: yup.string().nullable().optional().label('Fund Name').default(undefined),
      grantedOptions: yup.number().nullable().optional().label('Granted Options').default(undefined),
      interest: usdField(0).nullable().optional().label('Interest').default(undefined),
      investmentDate: yup.date().nullable().optional().label('Investment Date').default(undefined),
      outstandingCommittedRestrictedStockAwards: integerField()
        .label('Outstanding Committed Restricted Stock Awards')
        .default(undefined),
      outstandingEquityAwardDerivatives: yup
        .number()
        .nullable()
        .optional()
        .label('Outstanding Equity Award Derivatives')
        .default(undefined),
      outstandingShares: integerField().nullable().optional().label('Outstanding Shares').default(undefined),
      outstandingWarrants: integerField()
        .nullable()
        .optional()
        .label('Outstanding Warrants')
        .default(undefined),
      ownershipPercentage: percentField()
        .nullable()
        .optional()
        .label('Ownership Percentage')
        .default(undefined),
      principal: usdField().nullable().optional().label('Principal').default(undefined),
      shareClassRef: yup.string().nullable().optional().label('Share Class Ref').default(undefined),
      stakeholderId: yup.number().nullable().optional().label('Stakeholder ID').default(undefined),
      stakeholderInvestmentType: yup
        .string()
        .oneOf(CapTableInvestmentTypes)
        .nullable()
        .optional()
        .label('Stakeholder Investment Type')
        .default(undefined),
      stakeholderName: yup
        .string()
        .nullable()
        .optional()
        .required()
        .label('Stakeholder Name')
        .default(undefined),
      ungrantedOptions: integerField().nullable().optional().label('Ungranted Options').default(undefined),
      valuationCap: usdField().nullable().optional().label('Valuation Cap').default(undefined),
    };
  },
  create() {
    return yup.object(this.base());
  },
  gridSchema() {
    return yup.object(this.base());
  },
};
