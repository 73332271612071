import { useFieldArray } from 'react-hook-form';
import { IKPITemplateSectionDataModel } from '../../../../../data-models/kpi-template.data-model';
import { IField } from '../../../../../data-models/field2.data-model';
import { KPIItemFactory } from '../../Sections/FormItem/KPIItemFactory';

export interface IKPIRecipientViewSectionsProps {
  kpiTemplateSections: IKPITemplateSectionDataModel[] | null | undefined;
  readOnly?: boolean;
}

export function KPIRecipientViewSections(props: IKPIRecipientViewSectionsProps) {
  const { kpiTemplateSections, readOnly } = props;
  const { fields } = useFieldArray({ name: 'sections' });
  const templateSectionsForm = fields as unknown as IField<unknown>[];

  if (!kpiTemplateSections) return null;

  return (
    <>
      {templateSectionsForm.map((_section, index) => {
        const sectionRef = `sections.${index}`;

        return (
          <KPIItemFactory
            editSectionIndex={index}
            key={sectionRef}
            sectionRef={sectionRef}
            editMode
            readOnly={readOnly}
            showErrors={false}
          />
        );
      })}
    </>
  );
}
