import { useState, useEffect } from 'react';
import { deleteInvestment, IDryRunDeleteResponse } from '../../../services/queries/MaggieTransactionQueries';

export function useDryRunDeleteInvestment(id: number) {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);
  const [nAttachedTransactions, setNAttachedTransactions] = useState<number>();

  useEffect(() => {
    if (loading || error || nAttachedTransactions !== undefined) return;
    async function dryRunDelete() {
      return (await deleteInvestment(id, true)) as IDryRunDeleteResponse;
    }
    setLoading(true);
    dryRunDelete()
      .then((data) => {
        if (data) {
          setNAttachedTransactions(data.attachedTransactions);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [error, id, loading, nAttachedTransactions]);

  return { loading, nAttachedTransactions };
}
