// FIXME: Remove istanbul and add test + https://foresightdata.atlassian.net/browse/MAGGIE-4306
import { MenuItem, Stack, styled, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { MoreOptionsMenu } from '../../../../MoreOptionsMenu/MoreOptionsMenu';
import { IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { SubNavItem } from '../../SubSectionNavigation/components/SubNavItem/SubNavItem';

export const SubNavSectionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RelativeWrapper = styled('div')`
  position: relative;
`;

type SubSectionNavItemsProps = {
  sectionList: IKPITemplate[] | null;
  parentRoute?: ROUTES | string;
  selectedSection: string;
  onOpenConfirmDeleteModal: () => void;
  onDuplicateTemplate: () => void;
};

const ActionButtonWrapper = styled('div')`
  position: absolute;
  right: 0;
  margin-top: -4px;
`;

type ActionButtonMenuOptionsProps = {
  onOpenConfirmDeleteModal: () => void;
  onDuplicateTemplate: () => void;
};

export const ActionButtonMenuOptions = ({
  onOpenConfirmDeleteModal: handleOnDelete,
  onDuplicateTemplate: handleOnDuplicate,
}: ActionButtonMenuOptionsProps) => {
  const { colors } = useTheme();

  return (
    <RelativeWrapper>
      <ActionButtonWrapper>
        <MoreOptionsMenu moreMenuIconSx={{ color: colors.primary[20] }}>
          <MenuItem
            onClick={handleOnDelete}
            sx={{ display: 'flex', justifyItems: 'space-between', p: '.5rem', color: 'white' }}
          >
            <DeleteOutlinedIcon sx={{ color: colors.neutral['60'], mr: '.25rem' }} />
            <Typography variant='body2' color={colors.primary[100]}>
              Delete
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={handleOnDuplicate}
            sx={{ display: 'flex', justifyItems: 'space-between', p: '.5rem', color: 'white' }}
          >
            <ContentCopyIcon sx={{ color: colors.neutral['60'], mr: '.25rem' }} />
            <Typography variant='body2' color={colors.primary[100]}>
              Duplicate
            </Typography>
          </MenuItem>
        </MoreOptionsMenu>
      </ActionButtonWrapper>
    </RelativeWrapper>
  );
};

export default function KPITemplatesNavItems({
  sectionList,
  parentRoute,
  selectedSection,
  onDuplicateTemplate,
  onOpenConfirmDeleteModal,
}: SubSectionNavItemsProps) {
  // ignore the action button if user is on creation of the template
  const isSetUpForCreate = useMemo(() => selectedSection === 'new', [selectedSection]);

  if (!sectionList) return null;

  return (
    <SubNavSectionWrapper>
      {sectionList.map((templateSection) => {
        const sectionId = templateSection.id === -1 ? 'new' : String(templateSection.id);

        const section = {
          id: sectionId,
          value: templateSection.name,
        };

        // verify if the selected section is an existing template or new one
        const isSelectedSection = isSetUpForCreate
          ? templateSection.id === -1
          : section.id === selectedSection;

        return (
          <Stack key={section.id} direction='row' justifyContent='space-between'>
            <SubNavItem key={section.id} section={section} parentRoute={parentRoute} />
            {isSelectedSection && !isSetUpForCreate && (
              <ActionButtonMenuOptions
                onDuplicateTemplate={onDuplicateTemplate}
                onOpenConfirmDeleteModal={onOpenConfirmDeleteModal}
              />
            )}
          </Stack>
        );
      })}
    </SubNavSectionWrapper>
  );
}
