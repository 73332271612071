import { styled } from '@mui/material';

const minFundColWidth = 300;

export const CaptableGridContainer = styled('div')`
  .type-header,
  .type-header:hover {
    background-color: ${({ theme }) => theme.colors.primary[10]} !important;
  }
  .total-header .ag-header-cell-comp-wrapper {
    justify-content: center;
  }
  .investment-header,
  .investment-header:hover {
    background-color: ${({ theme }) => theme.colors.primary[5]} !important;
  }

  .ag-header-group-cell.ag-header-group-cell-with-group.type-header {
    border-top: 1px solid transparent;
  }
  .ag-popup-editor .ag-rich-select-value {
    min-width: ${minFundColWidth}px;
  }
`;
