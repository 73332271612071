import { FC, useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { noop } from 'lodash-es';
import { useSectorIdField } from '../../../../data-fields/CommonFields';
import { FieldFactory } from '../../../../components/Form/FieldFactory';
import { RendererType } from '../../../../data-models/field.data-model';

export const KPIRequestsSectorsFilter: FC<Pick<ControllerRenderProps, 'value' | 'onChange'>> = ({
  onChange,
  value,
}) => {
  const sectorField = useSectorIdField({ key: 'sectorId' });
  const formField = useMemo(() => {
    return {
      ...sectorField,
      label: '',
      renderer: RendererType.multiSelect,
      rendererMeta: {
        ...sectorField.rendererMeta,
        multi: true,
      },
      placeholder: 'Filter by sector',
    };
  }, [sectorField]);
  const formProps = useMemo(() => {
    return {
      name: 'sectorId',
      onChange,
      value,
      onBlur: noop,
      ref: noop,
    };
  }, [onChange, value]);

  return <FieldFactory formField={formField} formProps={formProps} />;
};
