import { IconButton, Stack, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useRecoilValue } from 'recoil';
import DownloadIcon from '@mui/icons-material/Download';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { selectedCompanyIdProfile } from '../../state/UIState';
import {
  selectedFinancialsRangeState,
  selectedFrequencyFinancialsState,
} from '../FinancialsDatePickers/useFinancialsDateSettings';
import { companyState } from '../../../../services/state/CompanyState';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { FinancialsDateSettings2 } from '../FinancialsDatePickers/FinancialsDateSettings2';
import { useInitFinancialsDateSettings } from '../../../CompanyProfiles2/hooks/useInitFinancialsDateSettings';
import { CompanyProfileFinancialsTable } from './components/CompanyProfileFinancialsTable';
import { SelectPeriodDropdown } from './components/SelectPeriodDropdown/SelectPeriodDropdown';
import { useSelectPeriodDropdown } from './components/SelectPeriodDropdown/useSelectPeriodDropdown';
import { CompanyProfileFinancialsExportModal } from './components/CompanyProfileFinancialsExportModal';
import { FinancialsDateSettings } from './components/FinancialsDateSettings';
import { FinancialsSettings } from './components/FinancialsSettings';
import { FyeChip } from './components/FyeChip';

export const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2.5rem;
  width: 100%;

  .ag-row-level-1 .ag-cell.ag-cell-last-left-pinned {
    padding-left: 3.75rem; // indent first column to signify grouping when groupDisplayType='groupRows'
  }
`;

export function CompanyProfileFinancials() {
  const initialize = useInitFinancialsDateSettings();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    setInitialized(initialize());
  }, [initialize, initialized]);

  if (!initialized) return null;
  return <CompanyProfileFinancialsContent />;
}

export function CompanyProfileFinancialsContent() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const reportingFrequency = useAtomValue(selectedFrequencyFinancialsState)!;

  const { selectedAmountOfPeriodsIdx, totalSelectedPeriods, onChangeSelectedAmountOfPeriods } =
    useSelectPeriodDropdown(reportingFrequency);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const { showFinancialsMenu, showFinancialsDatePicker } = useFlags<MaggieFeatureFlags>();
  const selectedPeriodRange = useAtomValue(selectedFinancialsRangeState);

  return (
    <GenericFallbacksWrapper>
      <PageWrapper>
        {openModal && <CompanyProfileFinancialsExportModal open={openModal} onClose={handleCloseModal} />}
        <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
          {showFinancialsDatePicker ? (
            <Stack direction={'row'} alignItems={'center'} gap={'1rem'}>
              <FinancialsDateSettings2 />
            </Stack>
          ) : (
            <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
              <SelectPeriodDropdown
                period={reportingFrequency}
                onChange={onChangeSelectedAmountOfPeriods}
                selectedAmountOfPeriodsIdx={selectedAmountOfPeriodsIdx}
              />
              <FinancialsDateSettings />
            </Stack>
          )}
          <Stack direction='row' alignItems={'center'} gap='0.2rem' justifyContent={'center'} ml='0.5rem'>
            {showFinancialsDatePicker && <FyeChip fye={fye} />}
            <IconButton onClick={handleOpenModal} aria-label='export' color='secondary'>
              <DownloadIcon />
            </IconButton>
            {showFinancialsMenu && <FinancialsSettings />}
          </Stack>
        </Stack>

        <CompanyProfileFinancialsTable
          totalSelectedPeriods={showFinancialsDatePicker ? selectedPeriodRange! : totalSelectedPeriods}
          frequency={reportingFrequency}
          fye={fye}
        />
      </PageWrapper>
    </GenericFallbacksWrapper>
  );
}
