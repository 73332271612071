import { FormControlLabel, Switch, Typography } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { usePeopleData } from '../hooks/usePeopleData';
import { showHiddenPeopleState } from '../state/PeopleByCompanyState';
import { SearchByName } from './SearchByName';
import { SearchByTitle } from './SearchByTitle';

const FilterContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 1.5rem;
  padding: 1.5rem 0;
  width: 100%;
`;

const HideFilterWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  margin-left: auto;
`;

export const PeopleFilters: FC = () => {
  const { allPeople, hiddenCount } = usePeopleData();
  const [showHidden, setShowHidden] = useRecoilState(showHiddenPeopleState);

  return (
    <FilterContainer>
      <SearchByName />
      <SearchByTitle />
      <HideFilterWrapper>
        <FormControlLabel
          control={
            <Switch
              checked={showHidden}
              onClick={() => setShowHidden((prevState) => !prevState)}
              size='small'
            />
          }
          label={<Typography variant='body2'>{`Show hidden ${hiddenCount}/${allPeople?.length}`}</Typography>}
        />
      </HideFilterWrapper>
    </FilterContainer>
  );
};
