import * as yup from 'yup';
import { greaterThenMsg, minZeroMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { integerField, percentField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function noteIssuanceFields() {
  return {
    coInvestors: yup.array().of(yup.string()).optional().nullable(),
    compoundingFrequency: yup.string().optional().nullable(),
    coupon: yup.number().min(0, greaterThenMsg('Coupon', 0)).nullable(),
    couponType: yup.string().optional().nullable(),
    currencyid: transactionCurrencyIdField(),
    discountRate: percentField().min(0, greaterThenMsg('Discount rate', 0)).optional().nullable(),
    fullyDilutedShares: integerField().nullable().optional(),
    fundId: yup.number().optional().nullable(),
    leadInvestor: yup.string().optional().nullable(),
    maturityDate: yup.date().optional().nullable(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    pikEndDate: yup
      .date()
      .nullable()
      .when('couponType', {
        is: 'PIK',
        then: (schema) => schema.required(requiredMsg('PIK End Date')),
      }),
    pikCadence: yup
      .string()
      .nullable()
      .when('couponType', {
        is: 'PIK',
        then: (schema) => schema.required(requiredMsg('PIK Cadence')),
      }),
    pikStartDate: yup
      .date()
      .nullable()
      .when('couponType', {
        is: 'PIK',
        then: (schema) => schema.required(requiredMsg('PIK Start Date')),
      }),
    principalAmount: usdField().nullable().required(requiredMsg('Principal')).min(0, minZeroMsg('Principal')),
    raiseAmount: usdField()
      .min(0, greaterThenMsg('Raise Amount', 0))
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    transactionDate: transactionDateSchema(),
    valuationCap: usdField().optional().min(0, minZeroMsg('Valuation Cap')).nullable(),
    triggerPrice: yup.number().optional().min(0, minZeroMsg('Trigger Price')).nullable(),
  };
}

export function noteIssuanceFormSchema() {
  return yup.object().shape(noteIssuanceFields());
}

export function noteIssuanceGridSchema() {
  return yup
    .object(noteIssuanceFields())
    .omit(['coInvestors', 'leadInvestor', 'raiseAmount', 'fullyDilutedShares']);
}
