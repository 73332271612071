import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { styled } from '@mui/material';
import { selectedActivityTypeState } from '../../state/UIState';
import { DropBoxEmbed } from '../../../../components/Embed/DropBoxEmbed';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { GoogleDriveEmbed } from '../../../../components/Embed/GoogleDriveEmbed';
import { AppName } from '../../../../data-models/integrations-app.data-model';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { DropboxFolderFieldProto } from './DropboxFolderFieldProto';
import { ActivityItemsList } from './Commentaries/ActivityItemsList';
import { RequestList } from './KpiReports/RequestList';

export interface IActivityDrawerContentsProps {
  companyId: number;
}

const DropboxContainer = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
`;

const ActivityListWrapper = styled('div')`
  height: 100%;
  padding: 1rem;
  overflow: auto;
`;

export function ActivityDrawerContents(props: IActivityDrawerContentsProps) {
  const { showNotesInActivityDrawer } = useFlags<MaggieFeatureFlags>();
  const { companyId } = props;
  const selectedActivityType = useRecoilValue(selectedActivityTypeState);

  switch (selectedActivityType) {
    case AppName.Dropbox:
      return (
        <GenericFallbacksWrapper>
          <DropboxContainer>
            <DropboxFolderFieldProto />
            <DropBoxEmbed />
          </DropboxContainer>
        </GenericFallbacksWrapper>
      );
    case AppName.GoogleDrive:
      return (
        <GenericFallbacksWrapper>
          <GoogleDriveEmbed />
        </GenericFallbacksWrapper>
      );
    case 'KPI Responses':
      return (
        <GenericFallbacksWrapper>
          <RequestList />
        </GenericFallbacksWrapper>
      );
    default:
      return showNotesInActivityDrawer ? (
        <ActivityListWrapper>
          <ActivityItemsList companyId={companyId} />
        </ActivityListWrapper>
      ) : null;
  }
}
