import { of } from 'rxjs';
import {
  CompetitiveIntelligenceDataRequest,
  CompetitiveIntelligenceItem,
  CompetitiveIntelligenceRequestType,
  CompetitiveIntelligenceSeriesDataRequest,
  CompetitiveIntelligenceSeriesItem,
} from '../../data-models/competitive-intelligence.data-model';
import {
  createSocketRequestCompIntelDataModel,
  createSocketResponseCompIntelData,
  ISocketResponseCompIntelData,
} from '../../data-models/socket-comp-intel.data-model';
import { tempFieldValues } from '../../temp-data/temp-field-values';
import { tempTimeseries } from '../../temp-data/temp-timeseries';
import { WebSocketService } from '../WebSocketService';

export function getLatestValue0(companyIds: number[], fields: number[]) {
  const baseQueryId = self.crypto.randomUUID();

  const query = createSocketRequestCompIntelDataModel<CompetitiveIntelligenceDataRequest>(
    CompetitiveIntelligenceRequestType.metrics,
    {
      companies: companyIds,
      fields: fields,
    },
    baseQueryId
  );

  return WebSocketService.get().send<typeof query, ISocketResponseCompIntelData<CompetitiveIntelligenceItem>>(
    query
  );
}

// FIXME Delete and use above after MAGGIE-3617
export function getLatestValue(companyIds: number[], fields: number[]) {
  console.log('xxx', companyIds, fields);
  return of<ISocketResponseCompIntelData<CompetitiveIntelligenceItem>>(
    createSocketResponseCompIntelData(tempFieldValues)
  );
}

export function getSeries0(companyIds: number[], fields: number[], fromDate: Date, toDate: Date) {
  const baseQueryId = self.crypto.randomUUID();
  const query = createSocketRequestCompIntelDataModel<CompetitiveIntelligenceSeriesDataRequest>(
    CompetitiveIntelligenceRequestType.metrics,
    {
      companies: companyIds,
      fields: fields,
      dateFrom: fromDate.toISOString(),
      dateUntil: toDate.toISOString(),
    },
    baseQueryId
  );

  return WebSocketService.get().send<
    typeof query,
    ISocketResponseCompIntelData<CompetitiveIntelligenceSeriesItem>
  >(query);
}

// FIXME Delete and use above after MAGGIE-3617
export function getSeries(companyIds: number[], fields: number[], fromDate: Date, toDate: Date) {
  console.log('xxx 000', companyIds, fields, fromDate, toDate);
  return of<ISocketResponseCompIntelData<CompetitiveIntelligenceSeriesItem>>(
    createSocketResponseCompIntelData(tempTimeseries)
  );
}
