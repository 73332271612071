import { FC, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { usersState } from '../../../../services/state/AppConfigState';
import { listOwnerFilterState } from '../state/FiltersState';
import { UserSelect } from '../../../../components/Form/UserSelect';

export const OwnerFilter: FC = () => {
  const [selectedOwners, setSelectedOwners] = useRecoilState(listOwnerFilterState);
  const users = useRecoilValue(usersState);

  // we need to reset the state when switching btw lists because the selector is not available in all lists
  useEffect(() => {
    return () => setSelectedOwners(null);
  }, [setSelectedOwners]);

  const userIds = useMemo(() => {
    return users.map((user) => user.id);
  }, [users]);

  const allSelected = useMemo(
    () => selectedOwners === null || selectedOwners.length === users?.length,
    [selectedOwners, users]
  );

  return (
    <UserSelect
      onChange={setSelectedOwners}
      value={allSelected ? userIds : selectedOwners ?? []}
      fieldPlaceholder='Select Owners'
      optionName={'Owner'}
      style={{ width: '14rem' }}
    />
  );
};
