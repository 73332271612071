import { IDealTypeDataModel } from '../../data-models/deal-type.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class DealTypeFormatter
  implements IInitializedFormatter<Map<number, IDealTypeDataModel>, number | number[]>
{
  #dealTypes: Map<number, IDealTypeDataModel> = new Map();

  init(dealTypes: Map<number, IDealTypeDataModel>) {
    this.#dealTypes = dealTypes;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(',');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#dealTypes.get(value)?.displayName ?? value.toString();
  };
}
