import { atom } from 'jotai';
import { atomWithLazy } from 'jotai/utils';
import {
  IPreferencesV3DataModel,
  PreferenceKey,
  PreferenceScope,
} from '../data-models/preferences-v3.data-model';
import { updatePreferencesV3 } from '../services/queries/MaggiePreferencesQueries';
import { FDMap } from './data-structure/FDMap';

export class PreferencesServiceV3 {
  static #instance: PreferencesServiceV3 | undefined;
  #preferences: FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>;

  private constructor() {
    this.#preferences = new FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>([
      [PreferenceScope.USER, new FDMap<PreferenceKey, unknown>()],
      [PreferenceScope.ORGANIZATION, new FDMap<PreferenceKey, unknown>()],
    ]);
  }

  static initService(preferences: IPreferencesV3DataModel<unknown>[]) {
    if (!this.#instance) {
      this.#instance = new PreferencesServiceV3();
    }

    const prefsAsMap = preferences.reduce((res, preference) => {
      const { scope, preferenceKey, value } = preference;

      return res.setOrUpdate(scope, new FDMap([[preferenceKey as PreferenceKey, value]]), (existingPrefs) =>
        existingPrefs.set(preferenceKey as PreferenceKey, value)
      );
    }, new FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>());

    this.#instance.setAllPreferences(prefsAsMap);

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Preferences service not initialized');
    }

    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }

  setAllPreferences(preferences: FDMap<PreferenceScope, FDMap<PreferenceKey, unknown>>) {
    this.#preferences = preferences;
  }

  getAllPreferences(scope = PreferenceScope.USER) {
    return this.#preferences.get(scope);
  }

  getPreference<T>(preferenceKey: PreferenceKey, scope = PreferenceScope.USER): T | undefined {
    return this.#preferences.get(scope)?.get(preferenceKey) as T;
  }

  setPreference<T>(key: PreferenceKey, value: T, scope = PreferenceScope.USER) {
    this.#preferences.getOrDefault(scope, new FDMap()).set(key, value);

    return updatePreferencesV3({
      scope,
      preferenceKey: key,
      value,
    });
  }
}

export type PreferenceRef<T> = Pick<IPreferencesV3DataModel<T>, 'preferenceKey' | 'scope'>;

export const preferenceAtom = <T>(
  getterPrefRef: PreferenceRef<T> | PreferenceRef<T>[],
  setterPrefRef?: PreferenceRef<T>
) => {
  let _getterPrefRef;
  let _setterPrefRef;

  if (Array.isArray(getterPrefRef)) {
    _getterPrefRef = getFirstKeyWithValue(getterPrefRef) ?? getterPrefRef[0];
    _setterPrefRef = setterPrefRef ?? getterPrefRef[0];
  } else {
    _getterPrefRef = getterPrefRef;
    _setterPrefRef = setterPrefRef ?? getterPrefRef;
  }

  const baseAtom = atomWithLazy(
    () =>
      PreferencesServiceV3.get().getPreference(_getterPrefRef.preferenceKey, _getterPrefRef.scope) as
        | T
        | undefined
  );

  return atom(
    (get) => {
      return get(baseAtom);
    },
    (_get, set, newValue: T) => {
      set(baseAtom, newValue);
      PreferencesServiceV3.get().setPreference(_setterPrefRef.preferenceKey, newValue, _setterPrefRef.scope);
    }
  );
};

function getFirstKeyWithValue<T>(preferenceRefs: PreferenceRef<T>[]) {
  return preferenceRefs.find(
    (pref) => PreferencesServiceV3.get().getPreference(pref.preferenceKey, pref.scope) !== undefined
  );
}
