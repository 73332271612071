import { useCallback, useMemo } from 'react';
import { MenuItem, Select, SelectChangeEvent, SxProps, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRecoilValue } from 'recoil';
import { sectorsMapState, sectorsOrderedState } from '../../../services/state/AppConfigState';

type SectorSelectProps = {
  value?: number | null;
  onChange: (id: number | null) => void;
  onFocus?: () => void;
  sx?: SxProps;
};

export const SectorSelect = ({ value, onChange, onFocus, sx }: SectorSelectProps) => {
  const { colors } = useTheme();
  const sortedSectors = useRecoilValue(sectorsOrderedState);
  const sectorsMap = useRecoilValue(sectorsMapState);

  const handleChange = useCallback(
    (event: SelectChangeEvent<number | string>) => {
      onChange(event.target.value as number);
    },
    [onChange]
  );

  const sectorItems = useMemo(
    () =>
      sortedSectors.map((sector) => (
        <MenuItem key={sector.name} value={sector.id as number}>
          {sector.name}
        </MenuItem>
      )),
    [sortedSectors]
  );

  return (
    <Select
      aria-label={'select-sector'}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'Without label', sx: { fontSize: '0.875rem' } }}
      MenuProps={{ sx: { zIndex: 15000 } }}
      displayEmpty
      onFocus={onFocus}
      renderValue={(value) =>
        !value ? (
          <span style={{ color: colors.neutral[40] }}>Select Sector</span>
        ) : (
          sectorsMap.get(value)?.name ?? 'Unknown'
        )
      }
      value={!value ? '' : value}
      IconComponent={ExpandMoreIcon}
      sx={{ '& .MuiSelect-iconOutlined': { fill: colors.neutral[40] }, ...sx }}
    >
      {sectorItems}
    </Select>
  );
};
