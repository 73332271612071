import { useRecoilValue } from 'recoil';
import { ValueFormatterFunc } from 'ag-grid-community';
import { useCallback } from 'react';
import { usersByEmailMapState } from '../services/state/AppConfigState';

export default function useCreatedByFormatter() {
  const usersMapByEmailMap = useRecoilValue(usersByEmailMapState);

  const createdByFormatter = useCallback<ValueFormatterFunc>(
    (params) => {
      const user = usersMapByEmailMap.get(params.value);

      return user ? `${user.firstName} ${user.lastName}` : params.value;
    },
    [usersMapByEmailMap]
  );

  return {
    createdByFormatter,
  };
}
