import { ICellRendererParams } from 'ag-grid-community';
import { styled } from '@mui/system';
import { FC, useCallback, useMemo, MouseEvent } from 'react';
import { useSetRecoilState } from 'recoil';
import { mappingsActionState } from '../state/MappingsUIState';
import { IMappingsRowDataModel, isPrimaryWebsite, isWebsiteProvider } from './mappingDataUtils';
import { IMappingsEvent, MappingsEventType } from './mappingsGridEvents';
import { WebsiteRenderer } from './WebsiteRenderer';
import { ProviderDataRenderer } from './ProviderDataRenderer';

const Cell = styled('span')<{ actionable?: boolean }>`
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: ${({ actionable }) => (actionable ? 'pointer' : 'auto')};
`;

export const MappingsDataRenderer: FC<ICellRendererParams<IMappingsRowDataModel>> = (params) => {
  const { data, column } = params;

  const setAction = useSetRecoilState(mappingsActionState);

  const companyId = useMemo(() => (column ? Number(column.getColId()) : -1), [column]);

  const dataType: 'primary' | 'secondary' | 'other' = useMemo(() => {
    if (isPrimaryWebsite(data)) return 'primary';
    else if (data && isWebsiteProvider(data)) return 'secondary';
    else return 'other';
  }, [data]);

  const InnerRenderer = useMemo(() => {
    if (dataType === 'other') return ProviderDataRenderer;
    else return WebsiteRenderer;
  }, [dataType]);

  const actionable = useMemo(() => Boolean(data?.[companyId]), [companyId, data]);

  const handleCellClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!data || !companyId || !data[companyId]) {
        return;
      }

      let type: MappingsEventType;
      if (dataType === 'primary') type = MappingsEventType.editPrimary;
      else if (dataType === 'secondary') type = MappingsEventType.editSecondary;
      else type = MappingsEventType.editVendorIds;

      setAction({
        type,
        data: data?.[companyId],
      } as IMappingsEvent);
    },
    [companyId, data, dataType, setAction]
  );

  return (
    <Cell
      data-testid={'mappings-data-cell'}
      key={`${companyId}-${actionable}`}
      actionable={actionable}
      onClick={handleCellClick}
    >
      <InnerRenderer {...params} />
    </Cell>
  );
};
