import { Button } from '@mui/material';
import { Dispatch, FC, HTMLAttributes, SetStateAction, useCallback, useMemo } from 'react';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { dealBoardSettingsState, selectedDealState } from '../../state/DealboardUIState';
import { useDoUpdateStage, useUpdateSelectedDealIfNeeded } from '../../hooks/useDealActions';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { getCurrentDealPosition } from './dealNavigationUtils';

interface NextStageButtonProps extends HTMLAttributes<HTMLButtonElement> {
  setShowLoader: Dispatch<SetStateAction<boolean>>;
  setAlert: Dispatch<SetStateAction<{ message: string; severity: 'error' | 'success' } | undefined>>;
}

export const NextStageButton: FC<NextStageButtonProps> = ({ setShowLoader, setAlert }) => {
  const selectedDeal = useRecoilValue(selectedDealState);
  const dealBoardSettings = useRecoilValue(dealBoardSettingsState);
  const doUpdateStage = useDoUpdateStage();
  const updateSelectedDeal = useUpdateSelectedDealIfNeeded();

  const nextStageId = useMemo(() => {
    if (!selectedDeal || !dealBoardSettings) return null;
    const { columnIndex } = getCurrentDealPosition(dealBoardSettings, selectedDeal);
    if (columnIndex === dealBoardSettings.length - 1) {
      return null;
    } else {
      return dealBoardSettings[columnIndex + 1].stage.id;
    }
  }, [selectedDeal, dealBoardSettings]);

  const handleMoveToNextStage = useCallback(async () => {
    if (!selectedDeal || !dealBoardSettings || !nextStageId) return;
    setShowLoader(true);

    const updatedDeal: IDealDataModel = {
      ...cloneDeep(selectedDeal),
      stageId: nextStageId,
      stageUpdateDate: new Date().toISOString(),
    };

    await doUpdateStage(selectedDeal, updatedDeal, {
      onError: () => setAlert({ message: 'Error moving to next stage', severity: 'error' }),
      onComplete: () => {
        updateSelectedDeal(updatedDeal);
        setShowLoader(false);
      },
    });
  }, [
    selectedDeal,
    dealBoardSettings,
    nextStageId,
    setShowLoader,
    doUpdateStage,
    setAlert,
    updateSelectedDeal,
  ]);

  return (
    <Button
      startIcon={<ArrowRightAltOutlinedIcon />}
      variant={'contained'}
      color={'secondary'}
      size={'medium'}
      disabled={!nextStageId}
      onClick={handleMoveToNextStage}
      sx={{ width: '220px' }}
    >
      Move to next stage
    </Button>
  );
};
