import { FC, useMemo } from 'react';
import { format } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { UserAvatar } from '../../../../../components/UserAvatar/UserAvatar';
import { UnknownUser } from '../../../../../data-models/user.data-model';
import { usersByEmailMapState } from '../../../../../services/state/AppConfigState';
import { DealComment } from '../../../data-models/comment.data-model';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';

const StyledCell = styled('span')`
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0;

  min-width: 0; // need that for ellipsis to work inside flex parent
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledInfo = styled('span')`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

interface CommentsRendererParams {
  value: DealComment[];
  valueFormatted?: string;
}

export const CommentsRenderer: FC<CommentsRendererParams> = ({ value: comments, valueFormatted }) => {
  const { colors } = useTheme();
  const usersByEmail = useRecoilValue(usersByEmailMapState);
  const commentsCopy: DealComment[] = useMemo(
    () => (!comments || !comments.length ? [] : comments.map((c) => ({ ...c }))),
    [comments]
  );

  const sortedComments: DealComment[] | null | undefined = useMemo(
    () =>
      !commentsCopy?.length
        ? []
        : commentsCopy.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          }),
    [commentsCopy]
  );

  const latestComment: DealComment | null = useMemo(() => sortedComments?.[0] || null, [sortedComments]);

  const dateFormatted = useMemo(
    () => (!latestComment?.createdAt ? '' : format(new Date(latestComment.createdAt), 'LLL dd, hh:mm a')),
    [latestComment]
  );

  if (!latestComment) return null;

  const author = usersByEmail.get(latestComment.createdBy) ?? UnknownUser;

  return (
    <StyledTooltip title={valueFormatted ?? ''} placement={'top-start'}>
      <StyledCell data-testid='comments-cell'>
        <StyledInfo>
          {author !== UnknownUser ? (
            <UserAvatar size='small' user={author} />
          ) : (
            <Typography component='span' variant='caption' color={colors.neutral[50]}>
              {' - '}
            </Typography>
          )}
          <Typography component='span' variant='caption' color={colors.neutral[50]}>
            {dateFormatted}
          </Typography>
        </StyledInfo>
        <Typography component='span' variant='body2' color={colors.primary[100]}>
          {latestComment.comment}
        </Typography>
      </StyledCell>
    </StyledTooltip>
  );
};
