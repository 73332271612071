import { CSSProperties, ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

interface LoaderProps {
  height?: string;
  style?: CSSProperties;
}

const LoaderWrapper = styled('div')<LoaderProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  ${({ height }) => (height ? `height: ${height};` : '')}
`;
export const Loader = ({ style, height = '' }: LoaderProps): ReactElement => {
  return (
    <LoaderWrapper height={height} aria-busy={true} style={style}>
      <CircularProgress variant={'indeterminate'} />
    </LoaderWrapper>
  );
};
