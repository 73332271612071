import { Icon, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from '../../../../components/Form/FormComponents';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { companyState } from '../../../../services/state/CompanyState';
import { useUpdateDropboxFolder } from '../../hooks/useUpdateDropboxFolder';
import { DropboxFormData, DropboxFolderSchema } from './DropboxFolderSchema';

const Form = styled('form')`
  padding: 0.5rem 1rem 0;
`;

export const DropboxFolderFieldProto: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const [loading, setLoading] = useState<boolean>(false);
  const updateFolder = useUpdateDropboxFolder();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<DropboxFormData>({
    resolver: yupResolver(DropboxFolderSchema),
    defaultValues: {
      folder: company?.dropboxFolder ?? '',
    },
  });

  const handleUpdate = useCallback(
    async (payload: { folder?: string | null }) => {
      if (!payload.folder || payload.folder === company?.dropboxFolder) return;
      setLoading(true);
      await updateFolder(companyId, payload.folder);
      setLoading(false);
    },
    [company?.dropboxFolder, companyId, updateFolder]
  );

  if (!company) return null;

  return (
    <Form onSubmit={handleSubmit(handleUpdate)}>
      <Label description='Please create and copy a link to share the Dropbox folder'>
        Dropbox Folder URL
      </Label>
      <Stack direction={'row'} alignItems={'start'} gap={'1rem'} style={{ marginTop: '0.25rem' }}>
        <TextField
          {...register('folder')}
          error={Boolean(errors.folder)}
          helperText={(touchedFields.folder && errors.folder?.message) ?? ''}
          style={{ margin: 0 }}
          fullWidth
        />
        <LoadingButton
          type='submit'
          loading={loading}
          size='medium'
          variant='contained'
          color='secondary'
          loadingPosition='start'
          startIcon={loading ? <Icon /> : <></>}
          style={{ height: '2.5rem', whiteSpace: 'nowrap' }}
        >
          {loading ? 'Updating' : company.dropboxFolder ? 'Update' : 'Add'}
        </LoadingButton>
      </Stack>
    </Form>
  );
};
