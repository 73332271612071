import { FC, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Button, List, SvgIcon, useTheme } from '@mui/material';
import { airTableBasesState } from '../../state/IntegrationsState';
import ConnectIcon from '../../../../assets/icons/connect.svg?react';
import { Loader } from '../../../../components/Loader/Loader';
import { ErrorOccurredMessage } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { SlideUpPopper } from '../../../../components/SlideUpPopper';
import { IAirTableBaseDataModel } from '../../../../data-models/airtable-base.data-model';
import { useReconnectToAirtable } from '../hooks/useConnectToAirtable';
import { AirTableBase } from './AirTableBase';

export const ConnectedBases: FC = () => {
  const connectedBasesLoadable = useRecoilValueLoadable(airTableBasesState);
  const connectedBases = connectedBasesLoadable.valueMaybe();
  const [selectedBase, setSelectedBase] = useState<IAirTableBaseDataModel | null>(null);
  const reconnect = useReconnectToAirtable();

  const { colors } = useTheme();

  if (connectedBasesLoadable.state === 'loading') return <Loader />;
  if (connectedBasesLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (connectedBasesLoadable.state !== 'hasValue') return null;

  return (
    <>
      <Button color='secondary' onClick={reconnect}>
        <SvgIcon fontSize='small' titleAccess='connect'>
          <ConnectIcon fill={colors.primary[60]} />
        </SvgIcon>
        Connect Additional Bases
      </Button>
      <List
        sx={{
          background: 'transparent',
          padding: '0',
          marginTop: '1rem',
        }}
      >
        {connectedBases &&
          connectedBases.bases.map((b) => (
            <AirTableBase
              key={b.id}
              base={b}
              onClick={() => {
                setSelectedBase((prev) => (prev?.id === b.id ? null : b));
              }}
            />
          ))}
      </List>

      <SlideUpPopper
        message={
          selectedBase
            ? 'Thank you 🙂 Please select one of available tables which you would like to synchronize 👆'
            : 'Please select one of your Airtable bases which you would like to synchronize with Foresight 👆'
        }
      />
    </>
  );
};
