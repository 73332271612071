import { CustomCellRendererProps } from 'ag-grid-react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Icon, Typography } from '@mui/material';
import { UploadStatus } from '../../../data-models/bulk-upload-status.data-model';
import { IBulkUploadReportGridData } from './bulkUploadReportGridData';

export function UploadStatusRenderer(params: CustomCellRendererProps<IBulkUploadReportGridData>) {
  if (!params.value) {
    return null;
  }
  let icon = <Icon />;
  let color;
  if (params.value === UploadStatus.success) {
    icon = <CheckCircleIcon color='success' fontSize='small' />;
    color = 'success';
  } else if (params.value === UploadStatus.error) {
    icon = <WarningAmberIcon color='error' fontSize='small' />;
    color = 'error';
  }

  return (
    <Typography
      height={'100%'}
      width='100%'
      display={'flex'}
      alignItems={'center'}
      gap='0.25rem'
      color={color}
      variant='body2'
    >
      {icon}
      {params.value}
    </Typography>
  );
}
