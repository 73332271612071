import { useRecoilState, useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { Dashboard, DashboardConfigObject, DashboardCreateDataModel, LoadingId } from '../../../types';
import { dashboardsCI, selectedDashboardCI } from '../state/DashboardsState';
import {
  createDashboard,
  deleteDashboard,
  updateDashboard,
} from '../../../services/queries/CompetitiveIntelligenceDashboardQueries';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { buildUniqueName, nameDuplicate } from '../../../util/buildUniqueName';
import { VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { useSetSearchParams } from '../../../hooks/useSetSearchParams';

export function useCreateDashboard() {
  const [dashboards, setDashboards] = useRecoilState(dashboardsCI);
  const setSelectedDashboard = useSetSelectedDashboard();
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useCallback(
    async (newName: string, onSuccessAction: () => void) => {
      actions.startLoading(LoadingId.createDashboard);

      const id = self.crypto.randomUUID();
      const newUniqueName = buildUniqueName(
        newName,
        dashboards.map((d) => d.displayName)
      );
      const newDashboard: DashboardCreateDataModel = {
        config: {
          selectedCategories: [],
        },
        dashboardId: `${VIEW_TYPE.PERSONAL}_${id}`,
        description: 'Personal dashboard',
        displayName: newUniqueName,
        isDefault: false,
        name: `${VIEW_TYPE.PERSONAL}_${id}`,
        version: 1,
      };

      try {
        const newDashboardRes = await createDashboard(newDashboard);

        setDashboards([...dashboards, newDashboardRes]);
        pushSuccessToast({ message: 'New dashboard created.' });
        setSelectedDashboard(newDashboardRes.id);
        onSuccessAction();
      } catch (err) {
        pushErrorToast({ message: 'Failed to rename Dashboard!' });
      }

      actions.stopLoading(LoadingId.createDashboard);
    },
    [actions, dashboards, pushErrorToast, pushSuccessToast, setDashboards, setSelectedDashboard]
  );
}

export function useDuplicateDashboard() {
  const [dashboards, setDashboards] = useRecoilState(dashboardsCI);
  const setSelectedDashboard = useSetSelectedDashboard();
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useCallback(
    async (dashboardToDuplicate: Dashboard) => {
      actions.startLoading(LoadingId.duplicateDashboard);

      try {
        const id = self.crypto.randomUUID();
        const name = nameDuplicate(
          dashboardToDuplicate.displayName,
          dashboards.map((d) => d.displayName)
        );
        const newDashboard: DashboardCreateDataModel = {
          config: {
            ...dashboardToDuplicate.config,
          },
          dashboardId: `${VIEW_TYPE.PERSONAL}_${id}`,
          description: dashboardToDuplicate.description,
          displayName: name,
          isDefault: false,
          name: `${VIEW_TYPE.PERSONAL}_${id}`,
          version: dashboardToDuplicate.version,
        };
        const newDashboardRes = await createDashboard(newDashboard);

        setDashboards([...dashboards, newDashboardRes]);
        pushSuccessToast({ message: 'Dashboard duplicated.' });
        setSelectedDashboard(newDashboardRes.id);
      } catch (err) {
        pushErrorToast({ message: 'Dashboard duplication failed.' });
      }

      actions.stopLoading(LoadingId.duplicateDashboard);
    },
    [actions, dashboards, pushErrorToast, pushSuccessToast, setDashboards, setSelectedDashboard]
  );
}

export function useDeleteDashboard() {
  const [dashboards, setDashboards] = useRecoilState(dashboardsCI);
  const setSelectedDashboard = useSetSelectedDashboard();
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useCallback(
    async (dashboardToDelete: Dashboard) => {
      actions.startLoading(LoadingId.duplicateDashboard);

      try {
        await deleteDashboard(dashboardToDelete);

        const newDashboards = dashboards.filter((d) => d.id !== dashboardToDelete.id);
        setDashboards(newDashboards);
        pushSuccessToast({ message: 'Dashboard deleted.' });
        setSelectedDashboard(newDashboards[newDashboards.length - 1].id);
      } catch (err) {
        pushErrorToast({ message: 'Dashboard deletion failed.' });
      }

      actions.stopLoading(LoadingId.duplicateDashboard);
    },
    [actions, dashboards, pushErrorToast, pushSuccessToast, setDashboards, setSelectedDashboard]
  );
}

export function useRenameDashboard() {
  const [dashboards, setDashboards] = useRecoilState(dashboardsCI);
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useCallback(
    async (dashboard: Dashboard, newName: string) => {
      actions.startLoading(LoadingId.duplicateDashboard);

      const updatedDashboard = {
        ...dashboard,
        displayName: newName,
      };
      const newDashboards = dashboards.map((d) => {
        if (d.id === dashboard.id) {
          return updatedDashboard;
        }
        return d;
      });

      setDashboards(newDashboards);

      try {
        await updateDashboard(updatedDashboard);

        pushSuccessToast({ message: 'Dashboard deleted.' });
      } catch (err) {
        pushErrorToast({ message: 'Dashboard deletion failed.' });
        setDashboards(dashboards);
      }

      actions.stopLoading(LoadingId.duplicateDashboard);
    },
    [actions, dashboards, pushErrorToast, pushSuccessToast, setDashboards]
  );
}

const defaultUpdateWarningShown = new Set<number>();
export const useUpdateDashboardConfig = () => {
  const selectedDashboard = useRecoilValue(selectedDashboardCI);
  const [dashboards, setDashboards] = useRecoilState(dashboardsCI);
  const { pushWarningToast } = useToastMessageState();

  return useCallback(
    async (newConfig: DashboardConfigObject) => {
      const prevDashboards = dashboards;
      const updatedDashboard: Dashboard = {
        ...selectedDashboard,
        config: newConfig,
      };
      const updatedDashboards: Dashboard[] = dashboards.map((dashboard) => {
        if (dashboard.id === selectedDashboard.id) {
          return updatedDashboard;
        }
        return dashboard;
      });

      setDashboards(updatedDashboards);

      if (selectedDashboard.isDefault) {
        if (!defaultUpdateWarningShown.has(selectedDashboard.id)) {
          defaultUpdateWarningShown.add(selectedDashboard.id);
          pushWarningToast({
            message:
              'You are editing a default Dashboard, changes will not be saved.\n' +
              'Please duplicate and edit if you want them preserved.',
          });
        }
      } else {
        try {
          await updateDashboard(updatedDashboard);
        } catch (err) {
          console.error(
            '[CompetitiveIntelligenceState] failed to update dashboard',
            selectedDashboard,
            'to',
            updatedDashboard
          );
          setDashboards(prevDashboards);
        }
      }
    },
    [dashboards, pushWarningToast, selectedDashboard, setDashboards]
  );
};

export function useSetSelectedDashboard() {
  const setSearchParams = useSetSearchParams();

  return useCallback(
    (newDashboardId: number) => {
      setSearchParams({
        dashboardId: newDashboardId.toString(),
      });
    },
    [setSearchParams]
  );
}
