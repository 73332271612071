import { FC, useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useUpdateMappings } from '../../../services/hooks/useMappingsActions';
import { MappingsEventType } from '../MappingsGrid/mappingsGridEvents';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { primaryWebsiteInitialValues } from './schemas/primaryWebsiteInitialValues';
import { primaryWebsiteSchema } from './schemas/primaryWebsiteSchema';
import { ReasonForChange, ReasonsForChangeLabels } from './reasons-for-change';
import { InfoBox } from './InfoBox';
import { FieldAndButtonContainer, FieldContainer, FormContainer, Label } from './components/FormUI';
import { FormFooterButtons } from './components/FormFooterButtons';
import { IMappingsFormProps } from './IMappingsFormProps';

const rebrandMessage =
  'The new website will reflect across the app but connections to data vendors will be maintained ';

const incorrectMatchingMessage = 'Changing the website will cause an update of all data vendor mappings';

export const PrimaryWebsiteForm: FC<IMappingsFormProps> = ({ data, onCancel }) => {
  const { primary: currentPrimary } = data;
  const updateMappings = useUpdateMappings();

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: primaryWebsiteInitialValues,
    validateOnChange: true,
    validationSchema: primaryWebsiteSchema,
    onSubmit: async (values) => {
      await updateMappings(
        { id: data.id, companyId: data.companyId, primary: values.primary },
        MappingsEventType.editPrimary,
        {
          params: { reason: values.reason },
        }
      );
      onCancel();
    },
  });

  const { values, errors, touched, handleSubmit, setFieldValue, handleChange, handleBlur, isSubmitting } =
    formik;

  const handleDeletePrimary = useCallback(async () => {
    if (!currentPrimary) return;
    setDeleting(true);
    await updateMappings(
      { primary: null, id: data.id, companyId: data.companyId },
      MappingsEventType.editPrimary
    );
    setDeleting(false);
    setShowConfirmDelete(false);
    onCancel();
  }, [currentPrimary, data.companyId, data.id, onCancel, updateMappings]);

  const noChange = useMemo(() => values.primary === currentPrimary, [values.primary, currentPrimary]);

  const textFieldProps = { margin: 0 };

  const { colors } = useTheme();

  return showConfirmDelete ? (
    <ConfirmDeletionDialog
      open={showConfirmDelete}
      onClose={() => setShowConfirmDelete(false)}
      onConfirm={handleDeletePrimary}
      title={`Delete Primary Website "${currentPrimary}"?`}
      loading={deleting}
    >
      <>
        <Typography
          variant='body2'
          color={colors.neutral[60]}
          sx={{ pt: '.25rem', minWidth: '30rem' }}
        >{`All data vendor links to "${currentPrimary}" will be removed`}</Typography>
      </>
    </ConfirmDeletionDialog>
  ) : (
    <FormContainer>
      <FieldContainer>
        <Label htmlFor='primary'>Current Primary Website</Label>
        <FieldAndButtonContainer>
          <TextField
            sx={textFieldProps}
            disabled
            value={currentPrimary ?? ''}
            InputProps={{
              sx: {
                background: colors.neutral[10],
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.neutral[10]} !important`,
                },
              },
            }}
          />
          {Boolean(currentPrimary) && (
            <IconButton
              aria-label='Delete Primary Website'
              onClick={() => setShowConfirmDelete(true)}
              sx={{
                color: colors.neutral[60],
              }}
            >
              <DeleteOutlineIcon fontSize='small' />
            </IconButton>
          )}
        </FieldAndButtonContainer>
      </FieldContainer>

      <FieldContainer>
        <Label htmlFor='primary'>Reason for Change</Label>
        <ToggleButtonGroup
          value={values.reason}
          exclusive
          onChange={(e, value) => {
            if (value !== null) setFieldValue('reason', value);
          }}
          aria-label='reason for change'
        >
          <ToggleButton value={ReasonForChange.rebrand} aria-label='rebrand'>
            {ReasonsForChangeLabels[ReasonForChange.rebrand]}
          </ToggleButton>
          <ToggleButton value={ReasonForChange.incorrectMatching} aria-label='incorrect matching'>
            {ReasonsForChangeLabels[ReasonForChange.incorrectMatching]}
          </ToggleButton>
        </ToggleButtonGroup>
      </FieldContainer>

      <FieldContainer>
        <Label htmlFor='primary'>New Website</Label>
        <TextField
          sx={textFieldProps}
          value={values.primary}
          name='primary'
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder='Enter new address'
          error={touched.primary && !!errors.primary}
          helperText={touched.primary && errors.primary ? errors.primary : ''}
        />
      </FieldContainer>

      <InfoBox
        message={values.reason === ReasonForChange.rebrand ? rebrandMessage : incorrectMatchingMessage}
      />

      <FormFooterButtons
        isSubmitting={isSubmitting}
        disabled={isSubmitting || noChange}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        onClose={onCancel}
      />
    </FormContainer>
  );
};
