import { Link, useTheme, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Loader } from '../../../../../components/Loader/Loader';
import { useDeal } from '../../../providers/DealProvider';
import { CompanyForm } from '../CompanyForm/CompanyForm';
import { ICompanySearchResultDataModel } from '../../../../../data-models/company-search-result.data-model';
import { CompanySearch } from '../../../../../components/CompanySearch/CompanySearch';
import { useGetCompanyData } from '../../../../CompanyProfiles/hooks/useGetCompanyData';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';

const Wrapper = styled('div')`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  border: 1px solid ${({ theme }) => theme.colors.primary[20]};
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CompanyNameWrapper = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const TypographyWrapper = styled('div')`
  width: 12rem;
`;

export const CompanySectionContent = ({
  scrollUp,
  companyData,
}: {
  scrollUp: () => void;
  companyData?: ICompanySearchResultDataModel;
}) => {
  const { colors } = useTheme();
  const {
    setCompanyInfo,
    companyInfo,
    setCompanyAdditionalInfo,
    showForm,
    setShowForm,
    activeStep,
    setActiveStep,
    setCompanyInput,
    companyInput,
  } = useDeal();
  const [isLoading, setIsLoading] = useState(false);
  const getCompanyData = useGetCompanyData();

  const handleCompanySelect = useCallback(
    async (company: ICompanySearchResultDataModel) => {
      if (!company?.id) return;
      // without this we might get a false positive on dataChanged
      setCompanyInfo({ ...company, id: Number(company.id) });
      setShowForm(true);

      setIsLoading(true);
      const [companyData] = await getCompanyData([Number(company.id)]);
      setIsLoading(false);
      setCompanyAdditionalInfo(companyData);
    },
    [getCompanyData, setCompanyAdditionalInfo, setCompanyInfo, setShowForm]
  );

  const onCreateLinkClick = (name: string) => {
    setShowForm(true);
    setCompanyInput(name);
  };

  useEffect(() => {
    const prepolateCompany = async () => {
      if (!companyData) return;
      await handleCompanySelect(companyData);
      setActiveStep(2);
    };
    prepolateCompany();
  }, [companyData, handleCompanySelect, setActiveStep]);

  return (
    <Wrapper>
      {Boolean(companyInfo && activeStep !== 1) && (
        <Header>
          <CompanyNameWrapper>
            <MUIAvatar src={companyInfo?.logo ?? ''} nameParts={companyInfo?.companyName?.split(' ') ?? []} />

            <Typography variant={'subtitle1'} color={colors.primary[100]}>
              {companyInfo?.companyName}
            </Typography>
          </CompanyNameWrapper>
          <Link
            component='button'
            onClick={() => setActiveStep(1)}
            color='secondary'
            underline='hover'
            fontSize={14}
          >
            Edit
          </Link>
        </Header>
      )}
      {activeStep === 1 && !companyInput && !showForm && (
        <InnerWrapper>
          <TypographyWrapper>
            <Typography variant={'body2'} color={colors.primary[100]}>
              Company
            </Typography>
          </TypographyWrapper>
          <CompanySearch
            autoFocus={true}
            fullWidth={true}
            onChange={(company) => {
              handleCompanySelect({
                id: company.id,
                companyName: company?.fields.name,
                website: company?.fields.website ?? '',
                logo: company?.fields.logo ?? '',
                value: company?.fields.name,
              });
            }}
            onCompanyCreateClick={onCreateLinkClick}
            showCreateOption={true}
            disablePortal={false}
          />
        </InnerWrapper>
      )}
      {isLoading && <Loader height={'30rem'} />}
      {Boolean(showForm && !isLoading && activeStep === 1) && <CompanyForm scrollUp={scrollUp} />}
    </Wrapper>
  );
};
