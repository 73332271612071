import { styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { companyState } from '../../services/state/CompanyState';
import { selectedCompanyIdProfile } from '../../pages/CompanyProfiles/state/UIState';

const EmbedderContainer = styled('div')`
  padding: 1rem;
  width: 100%;
  height: 100%;
`;

export function DropBoxEmbed() {
  const dropBoxContainer = useRef<HTMLDivElement | null>(null);
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const dropboxFolderLink = useRecoilValue(companyState(companyId))?.dropboxFolder;

  useEffect(() => {
    let embed: Element | undefined;
    if (dropBoxContainer.current !== null) {
      const options = {
        // Shared link to Dropbox file
        link: dropboxFolderLink,
        file: {
          // Sets the zoom mode for embedded files. Defaults to 'best'.
          zoom: 'best', // or "fit"
        },
        folder: {
          // Sets the view mode for embedded folders. Defaults to 'list'.
          view: 'list', // or "grid"
          headerSize: 'small', // or "small"
        },
      };

      // eslint-disable-next-line
      embed = (window as any).Dropbox.embed(options, dropBoxContainer.current);
    }

    return () => {
      if (embed) {
        // eslint-disable-next-line
        (window as any).Dropbox.unmount(embed);
      }
    };
  }, [dropboxFolderLink]);

  return <EmbedderContainer ref={dropBoxContainer}></EmbedderContainer>;
}
