import { styled } from '@mui/material/styles';

const CELL_PADDING = '1rem';

export const Table = styled('table')`
  table-layout: fixed;
  width: 100%;

  font-size: 0.9rem;
  border-radius: 4px;

  & td:first-of-type,
  & th:first-of-type {
    padding-left: 1rem;
  }

  /* & th:first-of-type {
    padding-left: 2.2rem;
  } */

  border-collapse: separate;
  border-spacing: 0.5em;
`;

export const StyledHead = styled('th')`
  color: ${({ theme }) => theme.colors.neutral[50]};
  text-align: center;
  font-weight: 200;

  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
  /* max-width: 16rem; */
  padding-right: ${CELL_PADDING};
`;

export const StyledHeadLeftAlign = styled(StyledHead)`
  text-align: left;
`;

export const StyledRow = styled('tr')`
  height: 3rem;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
`;

export const Td = styled('td')`
  padding-right: ${CELL_PADDING};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TdLeftAlign = styled(Td)`
  text-align: left;
  &.Date {
    font-family: IBMPlexSans-Regular;
  }
`;
