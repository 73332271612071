import { CSSProperties, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { usersByIdMapState, usersState } from '../../services/state/AppConfigState';
import { Option } from '../MuiSingleSelect/MuiSingleSelect';
import { MultiselectSansPills } from '../MuiMultiselect/MultiselectSansPills';

interface IUserSelectProps {
  value: number[];
  onChange: (value: number[]) => void;
  fieldPlaceholder?: string;
  style?: CSSProperties;
  optionName?: string;
}

export function UserSelect(props: IUserSelectProps) {
  const { onChange, value, fieldPlaceholder, style, optionName } = props;
  const usersById = useRecoilValue(usersByIdMapState);
  const users = useRecoilValue(usersState);
  const userOptions = useMemo<Option[]>(() => {
    return users.map((user) => ({ id: user.id, value: user.name }));
  }, [users]);

  return (
    <MultiselectSansPills
      openOnFocus
      options={userOptions}
      value={value.map((id) => ({ id, value: usersById.get(id)?.name ?? '' }))}
      onChange={(e, newValue) => onChange(newValue?.map((opt) => opt.id as number) ?? [])}
      sx={{ margin: 0 }}
      style={style}
      fieldPlaceholder={fieldPlaceholder ?? 'Select Users'}
      optionName={optionName ?? 'User'}
    />
  );
}
