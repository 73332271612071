import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { TransposedTable } from '../../CompetitiveIntelligence/components/Tables/TransposedTable';

const TableContainer = styled('div')`
  width: 100%;
  margin-top: 2rem;
`;

export const SingleCompanyMetrics: FC = () => {
  return (
    <TableContainer>
      <TransposedTable distanceFromTopInRems={18} companyProfilesView />
    </TableContainer>
  );
};
