import PublishIcon from '@mui/icons-material/Publish';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { EmptySection } from '../../../components/EmptySection';

export function NoCapTable() {
  return (
    <EmptySection
      title='No Cap Table Found'
      message='Create a new cap table to get started'
      actionComponent={
        <Button
          size='medium'
          variant='contained'
          color='secondary'
          startIcon={<PublishIcon />}
          component={NavLink}
          to={ROUTES.CAP_TABLE_FORM}
        >
          Create Cap Table
        </Button>
      }
    />
  );
}
