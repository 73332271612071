import { useCallback, useState } from 'react';
import { GridApi } from 'ag-grid-community';

export function useInvestmentRowData<T>(initialRowData: T[], gridApi: GridApi | null) {
  const [rowData, setRowData] = useState<T[]>(initialRowData);
  const [nSelectedRows, setNSelectedRows] = useState<number>(0);

  const onRowSelected = useCallback(() => {
    if (!gridApi) return;
    setNSelectedRows(gridApi.getSelectedNodes().length);
  }, [gridApi]);

  const onAddRow = useCallback(() => {
    setRowData((prev) => [{} as T, ...prev]);
    setNSelectedRows(0);
  }, []);

  const onDeleteRows = useCallback(() => {
    if (!gridApi) return;
    const selectedNodeIndices = new Set(gridApi.getSelectedNodes().map((node) => node.rowIndex));
    const markedForDeletion = rowData.map((row, index) => {
      return selectedNodeIndices.has(index) ? { ...row, toDelete: true } : row;
    });
    const updated = markedForDeletion.filter((row) => !(row as T & { toDelete?: boolean }).toDelete);
    setRowData(updated);
    setNSelectedRows(0);
  }, [gridApi, rowData]);

  return { rowData, nSelectedRows, onRowSelected, onAddRow, onDeleteRows };
}
