import { useRecoilValue } from 'recoil';
import { latestFundingsByCompanyState } from '../../../state/CompanyFundingsState';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { FMT } from '../../../../../util/formatter-service';
import { LabeledField } from '../../../../../components/LabeledField';

export function PostMoneyValuationField() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const latestNonNull = useRecoilValue(latestFundingsByCompanyState(companyId));

  const { valuationInMillions, dealDate } = latestNonNull ?? {};

  return (
    <LabeledField
      label={'Latest Post Money'}
      description={dealDate ? FMT.format('date', dealDate) : undefined}
      value={valuationInMillions != null ? FMT.format('usdShort', valuationInMillions * 1_000_000) : '-'}
    />
  );
}
