import { useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, Stack } from '@mui/material';
import { IPersonDataModel } from '../../../../data-models/person.data-model';
import { useHidePerson } from '../../hooks/useHidePerson';
import { Person } from '../../../../schemas/Person.schema';
import { useHidePortfolioPerson } from '../../../PortfolioOverview/PeopleReporting/PeopleReportingActions';

export const PersonLink = (props: ICellRendererParams<IPersonDataModel>) => {
  const { handleHidePerson, handleUnHidePerson } = useHidePerson();
  const isHidden = props?.data?.hidden;

  const handleClick = async () => {
    isHidden
      ? await handleUnHidePerson(Number(props.data?.id))
      : await handleHidePerson(Number(props.data?.id));
  };

  return <HideButton isHidden={!!isHidden} handleClick={handleClick} />;
};

export const HideRenderer = (props: ICellRendererParams<Person>) => {
  const handleHide = useHidePortfolioPerson();
  const { hidden, companyId, id } = props.data ?? {};

  const handleClick = useCallback(async () => {
    if (!companyId || !id) return;
    await handleHide({ companyId, personId: id, action: hidden ? 'unhide' : 'hide' });
  }, [handleHide, companyId, id, hidden]);

  if (!props.data) return null;

  return <HideButton isHidden={!!hidden} handleClick={handleClick} />;
};

interface IHideButtonProps {
  handleClick: () => void;
  isHidden: boolean;
}
export const HideButton = ({ handleClick, isHidden }: IHideButtonProps) => {
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} height='100%'>
      <IconButton onClick={handleClick} title={isHidden ? 'show' : 'hide'}>
        {isHidden ? <VisibilityOffIcon /> : <VisibilityIcon color={'secondary'} />}
      </IconButton>
    </Stack>
  );
};
