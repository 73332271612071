import * as yup from 'yup';
import { AnyObject } from 'yup';
import FormErrorMessages from '../FormErrorMessages';

type NewType = yup.TestContext<AnyObject>;

export function arePrimaryAndSecondaryFieldsUnique(
  this: NewType,
  value: (string | undefined)[] | undefined
): true | yup.ValidationError {
  const uniqueValues = new Set(value);

  const parent = this.parent;
  uniqueValues.add(parent.primary);

  const uniqueNonEmptyValues = Array.from(uniqueValues.values()).filter(Boolean);

  if (
    (!parent.primary && uniqueNonEmptyValues.length === value?.filter(Boolean)?.length) ||
    (parent.primary && uniqueNonEmptyValues.length === (value ?? []).filter(Boolean)?.length + 1)
  )
    return true;

  if (value && uniqueValues.size < value.length + 1) {
    return this.createError({
      path: `secondary[${value.findLastIndex(
        (element) =>
          value.indexOf(element) !== value.lastIndexOf(element) ||
          (parent.primary && element === parent.primary)
      )}]`,
      message: FormErrorMessages.REPEATED_VALUE,
    });
  }
  return true;
}
