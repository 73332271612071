import { IProviderFieldsDataModel } from '../../../data-models/provider-fields.data-model';

export const WEBSITE_PROVIDER_NAME = 'website';

export class VendorError extends Error {}

export interface IMappingsRowDataModel extends Record<number, IProviderFieldsDataModel<unknown>> {
  id: number;
  provider: string;
  category: string;
  hasError: boolean;
  displayName: string;
}

export function getIdsAndSources(provider?: IProviderFieldsDataModel<unknown>) {
  const result: string[] = [];

  if (!provider) return null;

  if (provider.primary === null) return new VendorError();

  const flatIds = [provider.primary, ...(provider.secondary ?? [])].flat();

  flatIds.forEach((id) => {
    const source = provider.sources?.[String(id)];
    if (source) {
      result.push(`${id} (${source})`);
    } else {
      result.push(String(id));
    }
  });

  return result.join(', ');
}

export function isPrimaryWebsite(data?: { provider: string; id: number }) {
  if (!data) return false;
  return isWebsiteProvider(data) && data?.id > 0;
}

export function isSecondaryWebsite(data?: { provider: string; id: number }) {
  if (!data) return false;
  return isWebsiteProvider(data) && data.id < 0;
}

export function categoryComparator(a: string, b: string) {
  const order: Record<string, number> = {
    universal: 1,
    'primary-vendor': 2,
    'secondary-vendor': 3,
  };
  return (order[a] ?? Number.MAX_SAFE_INTEGER) - (order[b] ?? Number.MAX_SAFE_INTEGER);
}

export function getProvidersMap(providers: IProviderFieldsDataModel<unknown>[]) {
  const map = new Map<string, IProviderFieldsDataModel<unknown>[]>();

  providers.reduce((acc, provider) => {
    const providerDisplayName = getProviderDisplayName(provider);

    if (!acc.has(providerDisplayName)) {
      acc.set(providerDisplayName, [provider]);
    } else {
      acc.get(providerDisplayName)!.push(provider);
    }
    return acc;
  }, map);

  return map;
}

export function getRowsWithErrors(rowData: { hasError: boolean }[]) {
  return rowData.filter((row) => row.hasError);
}

export function isWebsiteProvider(data: { provider: string }) {
  return data.provider.toLowerCase() === WEBSITE_PROVIDER_NAME;
}

export function prepProviderData<T>(providers: IProviderFieldsDataModel<T>[]) {
  const result: IProviderFieldsDataModel<T>[] = [];

  providers.forEach((provider) => {
    if (isWebsiteProvider(provider)) {
      const primary = { ...provider };
      const secondary = { ...provider, id: -provider.id };
      result.push(primary, secondary);
    } else {
      result.push(provider);
    }
  });
  return result;
}

export function getProviderDisplayName(data: IProviderFieldsDataModel<unknown>) {
  if (isPrimaryWebsite(data)) return 'Primary Website';
  if (isSecondaryWebsite(data)) return 'Secondary Website';
  else return data.provider.charAt(0).toUpperCase() + data.provider.slice(1);
}

export function formatCategoryName(category: string) {
  const name = category.split('-').join(' ');
  // capitalize each word
  return name.replace(/\b\w/g, (l) => l.toUpperCase());
}
