import { cloneDeep } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { ITransactionDataModel } from '../data-models/transaction.data-model';
import { createFormField, IFormField } from '../view-models/form.view-model';
import { IFinanceRoundDataModel } from '../data-models/finance-round.data-model';
import { SearchType } from '../services/queries/MaggieCompanyQueries';
import { RendererType } from '../data-models/field.data-model';
import { ICompanySearchMeta } from '../data-models/field3.data-model';
import { field2ToFormField, FieldEntity } from '../data-models/field2.data-model';
import { customFieldsByEntity } from '../services/state/AdminPanel/CustomFieldsState';
import { formatISODateOnly } from '../util/formatters/DateFormatters';
import {
  amountField,
  booleanField,
  dateField,
  sharesField,
  simpleTextField,
  useRoundIdField,
} from './CommonFields';

export function useInvestmentFields() {
  const customFields = useRecoilValue(customFieldsByEntity);
  const roundCustomFields = (customFields.get(FieldEntity.round) ?? []).map((field) =>
    field2ToFormField(field)
  );

  const fields: IFormField<unknown>[] = [
    simpleTextField({ key: 'name', label: 'Round Name', required: true }),
    useRoundIdField({ key: 'roundId', required: false }),
    dateField({ key: 'roundDate', label: 'Round Date', required: true }),
    booleanField({ key: 'initialQSBSQualification', label: 'Qualify for QSBS', required: true }),
    sharesField({ key: 'fullyDilutedShares', label: `Company's Post-Money Fully Diluted Shares` }),
    amountField({ key: 'postMoneyValuation', label: 'Post Money Valuation' }),
    amountField({ key: 'raiseAmount', label: `Raise Amount` }),
    ...investorFields,
    ...roundCustomFields,
  ];

  return fields;
}

export const investorFields = [
  createFormField<ICompanySearchMeta>({
    key: '_viewModel.leadInvestors',
    label: 'Lead Investor(s)',
    renderer: RendererType.companySearch,
    rendererMeta: {
      addLabel: (inputText) => inputText,
      createOnSelection: false,
      multiSelect: true,
      showAdd: true,
      searchType: SearchType.Investor,
    },
  }),
  createFormField<ICompanySearchMeta>({
    key: '_viewModel.coInvestors',
    label: 'Co-Investors',
    renderer: RendererType.companySearch,
    rendererMeta: {
      addLabel: (inputText) => inputText,
      createOnSelection: false,
      multiSelect: true,
      showAdd: true,
      searchType: SearchType.Investor,
    },
  }),
];

export function getInitialTransactionValues(transaction: ITransactionDataModel) {
  return cloneDeep({
    ...transaction,
  });
}

export function createInitialInvestmentValues(
  transaction?: ITransactionDataModel
): Partial<IFinanceRoundDataModel> {
  return {
    companyId: transaction?.companyId ?? -1,
    id: transaction?.investmentRoundId ?? undefined,
    name: null,
    roundDate: formatISODateOnly(new Date()),
    roundId: null,
    fullyDilutedShares: null,
    postMoneyValuation: null,
    raiseAmount: null,
  };
}
