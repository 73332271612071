import { Tooltip, useTheme, Typography } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { formatDate } from '../../../../util/formatters/DateFormatters';
import { recentlyClosedDealsState, recentlyPassedDealsState } from '../../state/DealboardDataState';
import { companyState } from '../../../../services/state/CompanyState';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';

const Wrapper = styled('div')`
  padding: 21px 20px 20px;
  display: flex;
  gap: 45px;
`;

const ListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const ItemWrapper = styled('div')`
  width: 200px;
  height: 16px;
  display: flex;
  justify-content: space-between;
`;

const ClosedIconWrapper = styled('div')`
  margin: 0 -2px;
  & svg {
    transform: scale(0.65);
  }
`;

const PassedIconWrapper = styled('div')`
  margin: 0 -2px;
  & svg {
    transform: scale(0.7);
  }
`;

const IconAndTextWrapper = styled('div')`
  display: flex;
  max-width: 120px;
  align-items: center;
  gap: 0.5rem;
  & img {
    width: 1.25rem;
    object-fit: contain;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ExpandedMenu: FC = () => {
  const { colors } = useTheme();
  const recentClosed = useRecoilValue(recentlyClosedDealsState);
  const recentPassed = useRecoilValue(recentlyPassedDealsState);

  const getLatestDealsWithCompanies = useLatestDealsWithCompanies();

  const { deals: latest3ClosedDeals, companies: latest3ClosedCompanies } =
    getLatestDealsWithCompanies(recentClosed);
  const { deals: latest3PassedDeals, companies: latest3PassedCompanies } =
    getLatestDealsWithCompanies(recentPassed);

  return (
    <Wrapper>
      <ListWrapper>
        <ItemWrapper>
          <IconAndTextWrapper>
            <ClosedIconWrapper>
              <CheckCircleOutlineIcon style={{ color: colors.primary[60] }} />
            </ClosedIconWrapper>
            <Typography variant='caption' color={colors.primary[60]}>
              Recent Closed
            </Typography>
          </IconAndTextWrapper>
        </ItemWrapper>
        {latest3ClosedDeals.map((deal, i) => {
          const { name, logoUrl } = latest3ClosedCompanies[i];
          return (
            <ItemWrapper key={deal.id}>
              <Tooltip
                title={
                  <Typography variant='caption' color={colors.primary[100]}>
                    {name}
                  </Typography>
                }
                placement='top'
                enterDelay={1000}
              >
                <IconAndTextWrapper>
                  <MUIAvatar src={logoUrl ?? ''} nameParts={name?.split(' ') ?? []} />
                  <Typography variant='caption' color={colors.neutral[70]}>
                    {name}
                  </Typography>
                </IconAndTextWrapper>
              </Tooltip>
              <Typography variant='caption' color={colors.neutral[60]}>
                {formatDate(deal.stageUpdateDate)}
              </Typography>
            </ItemWrapper>
          );
        })}
      </ListWrapper>
      <ListWrapper>
        <ItemWrapper>
          <IconAndTextWrapper>
            <PassedIconWrapper>
              <DangerousOutlinedIcon style={{ color: colors.primary[60] }} />
            </PassedIconWrapper>
            <Typography variant='caption' color={colors.primary[60]}>
              Recent Passed
            </Typography>
          </IconAndTextWrapper>
        </ItemWrapper>
        {latest3PassedDeals.map((deal, i) => {
          const { name, logoUrl } = latest3PassedCompanies[i];
          return (
            <ItemWrapper key={deal.id}>
              <Tooltip
                title={
                  <Typography variant='caption' color={colors.primary[100]}>
                    {name}
                  </Typography>
                }
                placement='top'
                enterDelay={1000}
              >
                <IconAndTextWrapper>
                  <MUIAvatar src={logoUrl ?? ''} nameParts={name?.split(' ') ?? []} />
                  <Typography variant='caption' color={colors.neutral[70]}>
                    {name}
                  </Typography>
                </IconAndTextWrapper>
              </Tooltip>
              <Typography variant='caption' color={colors.neutral[60]}>
                {formatDate(deal.stageUpdateDate)}
              </Typography>
            </ItemWrapper>
          );
        })}
      </ListWrapper>
    </Wrapper>
  );
};

interface DealsWithCompanies {
  deals: IDealDataModel[];
  companies: ICompanyDataModel[];
}

function useLatestDealsWithCompanies() {
  return useRecoilCallback(({ snapshot }) => (deals: IDealDataModel[] | null): DealsWithCompanies => {
    const result: DealsWithCompanies = { deals: [], companies: [] };
    if (!deals) return result;

    const latest3 = deals.slice(0, 3);
    return latest3.reduce((acc, deal) => {
      const company = snapshot.getLoadable(companyState(deal.companyId)).valueMaybe();
      if (company) {
        acc.deals.push(deal);
        acc.companies.push(company);
      } else {
        console.warn("Couldn't find company for deal", deal);
      }
      return acc;
    }, result);
  });
}
