import { useEffect } from 'react';

export function useCleanLocalStorageShortcut(): void {
  useEffect(() => {
    const onClick = async (evt: KeyboardEvent) => {
      // option/alt + R
      if (evt.altKey && evt.code === 'KeyR') {
        const isPositiveAction = confirm('Do you want to reset View to default state?');
        if (isPositiveAction) {
          localStorage.clear();
          location.reload();
        }
      }
    };

    document.addEventListener('keydown', onClick);
    return () => document.removeEventListener('keydown', onClick);
  }, []);
}
