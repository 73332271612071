import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../services/queryHelpers';
import {
  createFundData,
  ICreateFundDataPayload,
  IUpdateFundDataPayload,
  updateFundData,
} from '../../../services/queries/MaggieFundQueries';
import { fundDataStateFP } from '../state/FPState';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../types';
import { useInvalidateFundMetrics } from './useInvalidateFundMetrics';

export function useFundDataActions() {
  const { pushErrorToast } = useToastMessageState();
  const { actions } = useLoadingBarState();
  const invalidateFundMetrics = useInvalidateFundMetrics();

  const handleUpdateFundData = useRecoilCallback(
    ({ set }) =>
      async (data: IUpdateFundDataPayload) => {
        try {
          actions.startLoading(LoadingId.updateFundData);
          const updated = await updateFundData(data);
          set(fundDataStateFP(data.fundId), (curr) => {
            return curr.map((fd) => {
              if (fd.date === updated.date && fd.fundId === updated.fundId) {
                return updated;
              }
              return fd;
            });
          });
          await invalidateFundMetrics(data.date, data.fundId);

          return updated;
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to update fund data.') });
        } finally {
          actions.stopLoading(LoadingId.updateFundData);
        }
      },
    [pushErrorToast]
  );

  const handleCreateFundData = useRecoilCallback(
    ({ set }) =>
      async (data: ICreateFundDataPayload) => {
        try {
          const created = await createFundData(data);
          set(fundDataStateFP(data.fundId), (curr) => {
            return curr.concat({ ...created });
          });
          await invalidateFundMetrics(data.date, data.fundId);
          return created;
        } catch (err) {
          console.error(err);
          pushErrorToast({ message: getErrorMessage(err, 'Failed to create fund data.') });
        }
      },
    [pushErrorToast]
  );

  return { handleUpdateFundData, handleCreateFundData };
}
