import { SelectChangeEvent } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { KpiPeriod } from '../../../../../../data-models/company-financials.data-model';
import { PERIODS_MAP } from './SelectPeriodDropdown';

export function useSelectPeriodDropdown(period: KpiPeriod) {
  const [selectedAmountOfPeriodsIdx, setSelectedAmountOfPeriodsIdx] = useState<number>(0);

  useEffect(() => {
    setSelectedAmountOfPeriodsIdx(0);
  }, [period]);

  const onChangeSelectedAmountOfPeriods = useCallback((e: SelectChangeEvent<number>) => {
    const { value } = e.target;
    setSelectedAmountOfPeriodsIdx(Number(value));
  }, []);

  const totalSelectedPeriods = PERIODS_MAP[period][selectedAmountOfPeriodsIdx] ?? PERIODS_MAP[period][0];

  return {
    selectedAmountOfPeriodsIdx,
    totalSelectedPeriods,
    onChangeSelectedAmountOfPeriods,
  };
}
