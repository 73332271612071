import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Icon, IconTypes } from '../../../../../components/Icon';
import { filterListCI } from '../HistoryComparisonState';
import { HistoryComparisonFilter } from './HistoryComparisonFilter';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const IconWrapper = styled('div')`
  cursor: pointer;
`;

const FiltersWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
  animation: moveInFromTop 0.2s linear;

  @keyframes moveInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-1rem);
    }

    100% {
      opacity: 100;
      transform: translateY(0rem);
    }
  }
`;

export function HistoryComparisonHeader() {
  const { primary } = useTheme().colors;
  const [showFilters, setShowFilters] = useState(true);
  const filterList = useRecoilValue(filterListCI);

  return (
    <Wrapper>
      <Header>
        <IconWrapper onClick={() => setShowFilters((prevState) => !prevState)}>
          <Icon type={showFilters ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_RIGHT} />
        </IconWrapper>
        <Typography variant={'h6'} color={primary[100]}>
          History Comparison
        </Typography>
      </Header>

      {showFilters && (
        <FiltersWrapper>
          {filterList.map((field) => (
            <HistoryComparisonFilter field={field} key={field.id} />
          ))}
        </FiltersWrapper>
      )}
    </Wrapper>
  );
}
