import { Tooltip, Typography, useTheme } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import { styled } from '@mui/material/styles';
import { FieldLoadingIndicator } from './KeyInsigths/FieldLoadingIndicator';

interface IKpiProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  data: string | number | JSX.Element | undefined;
  dataColor?: string;
  isLoading?: boolean;
  dataMaxWidth?: string;
  height?: string;
}

const DataWrapper = styled('div')<{ height: string }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: ${({ height }) => height};
`;

const TypographyWrapper = styled('div')<{ dataMaxWidth?: string }>`
  ${({ dataMaxWidth }) => (dataMaxWidth ? `max-width: ${dataMaxWidth};` : '')}
  p {
    ${({ dataMaxWidth }) => (dataMaxWidth ? `text-overflow: ellipsis;` : '')}
    ${({ dataMaxWidth }) => (dataMaxWidth ? `white-space: nowrap;` : '')}
		${({ dataMaxWidth }) => (dataMaxWidth ? `overflow: hidden;` : '')}
  }
`;

export const Kpi: FC<IKpiProps> = ({
  label,
  data,
  isLoading,
  dataColor,
  dataMaxWidth,
  height = '100%',
  style,
}) => {
  const { colors } = useTheme();

  return (
    <DataWrapper height={height} style={style}>
      <Typography variant='caption' color={colors.neutral[50]} className='label'>
        {label}
      </Typography>
      {isLoading ? (
        <FieldLoadingIndicator />
      ) : (
        <>
          {typeof data === 'string' ? (
            <Tooltip
              disableHoverListener={!dataMaxWidth}
              title={
                <Typography variant='caption' color={dataColor ?? colors.primary[100]}>
                  {data}
                </Typography>
              }
              placement='bottom'
              enterDelay={1000}
            >
              <TypographyWrapper dataMaxWidth={dataMaxWidth}>
                <Typography
                  variant='body2'
                  color={colors.primary[100]}
                  sx={
                    isLoading
                      ? {}
                      : {
                          height: '100%',
                          display: 'flex',
                          alignItems: 'flex-start',
                        }
                  }
                >
                  {data}
                </Typography>
              </TypographyWrapper>
            </Tooltip>
          ) : (
            data
          )}
        </>
      )}
    </DataWrapper>
  );
};
