import { ColorPalette } from './structure';

export const colors: ColorPalette = {
  primary: {
    100: '#10253E',
    95: '#000000DE', // mui text.primary
    90: '#0C0E3E',
    80: '#161957',
    70: '#252977',
    60: '#282E9A',
    50: '#2D34C0',
    45: '#777CE5',
    40: '#565CDE',
    30: '#9397EB',
    20: '#C7C9FB',
    10: '#E7E8FF',
    5: '#F8F8FF',
    0: '#FFFFFF',
  },
  secondary: {
    90: '#140934',
    80: '#231156',
    70: '#31177A',
    60: '#42229C',
    50: '#6A45D3',
    40: '#896ADE',
    30: '#A992EB',
    20: '#CCBFF3',
    10: '#ECE5FF',
    5: '#F8F8FF',
  },
  tertiary: {
    90: '#0C2D38',
    80: '#144D60',
    70: '#1E6A83',
    60: '#2995B8',
    50: '#45B8DD',
    40: '#6EC4DF',
    30: '#9BDBEF',
    20: '#C3ECF9',
    10: '#E0F7FE',
  },
  neutral: {
    70: '#4B525D',
    60: '#636C7B',
    50: '#808999',
    40: '#99A1AD',
    30: '#B6BBC4',
    20: '#D2D5DB',
    10: '#EEEFF1',
    5: '#F9FAFA',
    2: '#FAFAFA',
  },
  critical: {
    60: '#A4282C',
    50: '#D04246',
    40: '#DC7073',
    30: '#E59597',
    20: '#EFBEBF',
    10: '#F9E6E7',
    5: '#FBEFEF',
  },
  warning: {
    60: '#CC7700',
    50: '#FF9500',
    40: '#FFB650',
    30: '#FFCC85',
    20: '#FFE1B8',
    10: '#FFF2E0',
  },
  success: {
    70: '#336661',
    60: '#4E9B93',
    50: '#6AB4AC',
    40: '#8CC5BF',
    30: '#AED6D2',
    20: '#D2EEEB',
    10: '#F1F8F7',
  },
  gray: {
    700: '#2D3748',
  },
};
