import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class TotalRealizedValueConfig implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_REALIZED_VALUE as const;
  private title = 'Total Realized Value';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value =
      data?.rawCompanies
        ?.flatMap((c) => c.transactions)
        .reduce((prev, t) => prev + (Number(t.distributions) || 0), 0) ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
