import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addYears } from 'date-fns';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { companyState } from '../../../../../services/state/CompanyState';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { EditableProfileNumericField } from '../../../Summary/components/InvestmentsSection/EditableProfileNumericField';
import { formatUSDShort } from '../../../../../util/formatters/NumericFormatters';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { Kpi } from '../../../Summary/components/Kpi';
import { ReadWriteInputStyles } from '../../../../../components/FormField/FormTextField';
import { Calendar } from '../../../../../components/DatePicker/Calendar';
import { Panel } from '../../../../CompanyProfiles2/Summary/Panel';

const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, auto));
  justify-content: start;
  column-gap: 1.875rem;
`;

export const Projections: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));

  const { projectedExitValuation, projectedExitTiming } = company ?? {};

  const updateCompany = useUpdateCompanyAndState();
  const handleUpdate = useCallback(
    (payload: Partial<ICompanyDataModel>) => {
      if (!company) return;
      updateCompany(companyId, {
        ...payload,
      });
    },
    [company, companyId, updateCompany]
  );

  const isEditable =
    company?.companyType !== 'portfolio' ||
    PermissionService.get().hasPermission(PermissionKey.canEditPortfolioCompany);

  const handleExitTimingUpdate = useCallback(
    (val: Date) => {
      handleUpdate({ projectedExitTiming: new Date(val).toISOString() });
    },
    [handleUpdate]
  );

  if (!company) return null;
  return (
    <Panel title={'Projections'}>
      <Container>
        <EditableProfileNumericField
          name={'Exit Valuation'}
          value={projectedExitValuation ?? undefined}
          onValueChanged={(newValue) => {
            handleUpdate({ projectedExitValuation: newValue });
          }}
          formatter={formatUSDShort}
          prefix='$'
          disabled={!isEditable}
          kpiStyle={{ gap: ' 0.4rem' }}
        />

        <Kpi
          label='Exit Timing'
          data={
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Calendar
                initialValue={projectedExitTiming ? new Date(projectedExitTiming) : undefined}
                onChange={handleExitTimingUpdate}
                maxDate={addYears(new Date(), 50)}
                disabled={!isEditable}
                triggerUpdateOnChange={true}
                textFieldProps={{
                  className: 'read-write-input transparent form-date-picker',
                  style: { marginLeft: '-0.875rem' },
                  sx: {
                    ...ReadWriteInputStyles,
                  },
                }}
              />
            </LocalizationProvider>
          }
        />
      </Container>
    </Panel>
  );
};
