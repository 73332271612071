import { atomFamily, selectorFamily } from 'recoil';
import { Commentary } from '../../schemas/Commentary.schema';
import { getCommentariesForEntity } from '../queries/MaggieCommentaryQueries';
import { EntityType } from '../../data-models/field2.data-model';

export const commentariesByCompanyIdState = atomFamily<Commentary[], number>({
  key: 'commentariesByCompanyIdState',
  default: selectorFamily<Commentary[], number>({
    key: 'commentariesByCompanyIdState/default',
    get: (companyId) => () => {
      return getCommentariesForEntity({ entity: EntityType.company, entityId: companyId });
    },
  }),
});

export const commentariesByIdState = selectorFamily<Map<number, Commentary>, number>({
  key: 'commentariesByIdState',
  get:
    (companyId) =>
    ({ get }) => {
      const commentaries = get(commentariesByCompanyIdState(companyId));
      return commentaries.reduce(
        (map, commentary) => map.set(commentary.id, commentary),
        new Map<number, Commentary>()
      );
    },
});
