import { useSortable } from '@dnd-kit/sortable';
import { Switch, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Dispatch, ReactElement, RefObject, SetStateAction } from 'react';
import { Icon, IconTypes } from '../Icon';
import { ChartMenu } from '../OverflowMenu/ChartMenu';
import { ChartIconWrapper, ChartTitle } from './chart-utils';

interface Props {
  isHovering: boolean;
  caption?: string;
  title: string;
  more?: boolean;
  showMore: boolean;
  setShowMore: Dispatch<SetStateAction<boolean>>;
  id: string;
  icon: string;
  handleSizeChange: Dispatch<SetStateAction<string>>;
  size: string;
  refProp: RefObject<HTMLElement>;
  logo?: string;
  isNormalized?: boolean;
  onToggle?: () => void;
  showIcon?: boolean;
  showFullScreen?: boolean;
  isFullScreen?: boolean;
  handleFullScreen?: () => void;
}

const StyledChartTitleWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
  position: relative;

  & > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  flex: 1;
  min-width: 0;
  position: relative;
`;

const PlaceHolerForMenu = styled('div')`
  width: 40px;
`;

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LogoWrapper = styled('div')`
  width: 2rem;
  height: 2rem;

  img {
    width: 2rem;
    height: 2rem;
  }
`;

const ToggleWrapper = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  & label {
    margin-right: 0.5rem;
  }
  & input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
  }
`;

const ChartMenuContainer = styled('div')`
  margin-left: auto;
`;

export function ChartTitleWrapper({
  isHovering,
  caption,
  title,
  more = true,
  id,
  icon,
  refProp,
  logo,
  isNormalized,
  onToggle,
  showIcon = true,
  showFullScreen = false,
  isFullScreen = false,
  handleFullScreen,
}: Props): ReactElement {
  const { neutral, secondary, primary } = useTheme().colors;
  const { attributes, listeners } = useSortable({ id });

  return (
    <StyledChartTitleWrapper>
      <HeaderWrapper>
        <ChartTitle>
          {isHovering && more ? (
            <ChartIconWrapper color={'rgba(0,0,0,0)'} {...attributes} {...listeners}>
              <Icon type={IconTypes.DRAG} color={neutral[30]} />
            </ChartIconWrapper>
          ) : (
            showIcon && (
              <ChartIconWrapper color={secondary[10]}>
                <Icon type={icon} color={secondary['60']} disablePointer={true} />
              </ChartIconWrapper>
            )
          )}

          <TitleWrapper>
            {logo && (
              <LogoWrapper>
                <img src={logo} alt={'logo'} />
              </LogoWrapper>
            )}
            <Typography component='h6' variant='body1' color={primary[100]}>
              {title}
            </Typography>
            {caption && (
              <Typography variant={'caption'} color={primary[100]}>
                {caption}
              </Typography>
            )}
            {isHovering ? (
              <ChartMenuContainer>
                <ChartMenu id={id} refProp={refProp} />
              </ChartMenuContainer>
            ) : (
              <PlaceHolerForMenu />
            )}
          </TitleWrapper>
        </ChartTitle>
        {onToggle && (
          <ToggleWrapper>
            <Typography variant='subtitle1' sx={{ color: isNormalized ? secondary[60] : neutral[60] }}>
              Normalize
            </Typography>
            <Switch checked={!!isNormalized} onChange={onToggle} />
          </ToggleWrapper>
        )}
      </HeaderWrapper>
      {showFullScreen && isHovering && (
        <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
          <span onClick={() => handleFullScreen?.()}>
            <Icon type={isFullScreen ? IconTypes.CLOSE_MENU_2 : IconTypes.FULLSCREEN} color={secondary[60]} />
          </span>
        </div>
      )}
    </StyledChartTitleWrapper>
  );
}
