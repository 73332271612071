import { useDraggable } from '@dnd-kit/core';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { DealCardMemo } from '../DealCard/DealCard';
import { IDealDataModel } from '../../../../data-models/deal.data-model';

const Wrapper = styled('section')`
  margin-bottom: 8px;
`;

export const Draggable: FC<{ deal: IDealDataModel; stageId: number }> = ({ deal, stageId }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: String(deal.id),
    data: {
      stageId,
      deal,
    },
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        opacity: 0,
      }
    : undefined;

  return (
    <Wrapper ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <DealCardMemo deal={deal} />
    </Wrapper>
  );
};
