import { ColDef, KeyCreatorParams, ValueGetterParams } from 'ag-grid-community';
import { CompanyCellRenderer } from '../CellRenderers/CompanyCellRenderer';

const companyComparator = (valA: string, valB: string) => {
  const name1 = valA;
  const name2 = valB;

  if (!name1 && !name2) return 0;
  if (!name1) return 1;
  if (!name2) return -1;

  return name1.localeCompare(name2, 'standard', { sensitivity: 'base' });
};

export const companyColDef: ColDef = {
  headerName: 'Company',
  field: 'company',
  filter: true,
  cellRenderer: CompanyCellRenderer,
  valueGetter: (params: ValueGetterParams) => params.data?.company?.name || '',
  keyCreator: (params: KeyCreatorParams) => params.data?.company?.name || '',
  comparator: companyComparator,
  filterParams: {
    comparator: companyComparator,
  },
  pinned: 'left',
};
