import { IconButton, styled, useTheme } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { StyledTooltip } from '../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';

const ActionButtons = styled('div')`
  display: flex;

  & > button {
    height: 40px;
    width: 40px;
  }
`;

// NOTE: This is added because button might be disabled therefore tooltip throws error.
const WrapperForTooltip = styled('div')`
  display: flex;
  width: 40px;
  height: 40px;

  & > button {
    height: 100%;
    width: 100%;
  }
`;

interface IFieldActionButtonsProps {
  onRemove: () => void;
  onMakeAsPrimary: () => void;
  markAsPrimaryDisabled: boolean;
}

export const FieldActionButtons = ({
  onRemove,
  onMakeAsPrimary,
  markAsPrimaryDisabled,
}: IFieldActionButtonsProps) => {
  const { colors } = useTheme();

  return (
    <ActionButtons>
      <StyledTooltip title='Set as a primary website' placement='bottom-start'>
        <WrapperForTooltip>
          <IconButton
            disabled={markAsPrimaryDisabled}
            aria-label='Set as a primary website'
            onClick={onMakeAsPrimary}
            sx={{
              color: colors.primary[60],
            }}
          >
            <FileUploadIcon fontSize='small' />
          </IconButton>
        </WrapperForTooltip>
      </StyledTooltip>
      <StyledTooltip title='Delete website' placement='bottom-start'>
        <IconButton
          aria-label='Delete Website'
          onClick={onRemove}
          sx={{
            color: colors.neutral[60],
          }}
        >
          <DeleteOutlineIcon fontSize='small' />
        </IconButton>
      </StyledTooltip>
    </ActionButtons>
  );
};
