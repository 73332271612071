import { ICellRendererParams } from 'ag-grid-community';
import { isErrorValue } from '../../../../../util/FormattedComponent';
import { NoDataCell } from '../../../../../components/AgTable/cell-renderers/NoDataCell';
import { ErrorCell } from './ErrorCell';
import { LoadingCell } from './LoadingCell';

/** @deprecated */
const handleArraysAndObjects = (value: (unknown | undefined | null)[] | Record<string, unknown>) => {
  if (Array.isArray(value)) {
    return <span>{(value as string[]).join(',')}</span>;
  }
  if (value instanceof Object) {
    // Should not happen but is a fallback in case of unexpected Object
    // arriving here (MAGGIE-1751).
    return <span>?</span>;
  }
};

interface DefaultRendererParams extends ICellRendererParams {
  loading?: boolean;
}

export const DefaultCellRenderer = (params: DefaultRendererParams) => {
  const result = handleArraysAndObjects(params.value);
  if (result) return result;

  if (isErrorValue(params.value)) {
    return <ErrorCell />;
  }

  if (params.value === null) {
    return <NoDataCell />;
  }

  if (params.loading && !params.value) {
    return <LoadingCell />;
  }
  return <span>{params.value}</span>;
};
