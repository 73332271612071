import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonProps, useTheme } from '@mui/material';
import React from 'react';

type PlusIconButtonProps = {
  children: React.ReactNode;
} & ButtonProps;

export default function PlusIconButton({ children, ...props }: PlusIconButtonProps) {
  const { primary } = useTheme().colors;

  return (
    <Button variant='text' sx={{ color: primary['60'] }} startIcon={<AddIcon />} {...props}>
      {children}
    </Button>
  );
}
