import { useCallback, useMemo } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { ICellRendererParams } from 'ag-grid-community';
import { IKpiCompanyData } from '../../AllCompaniesData';
import { IMenuItem, MenuButton } from '../../../../../Finance2/common-grid-defs/MenuButton';
import { useAllKpiCompaniesActions } from '../../AllCompaniesForms/useAllKpiCompaniesActions';
import { useSetReportingStatus } from '../../hooks/useSetReportingStatus';

export function AllCompaniesMenu(params: ICellRendererParams<IKpiCompanyData>) {
  const company = params.data;

  const { handleSelectAction, handleReview } = useAllKpiCompaniesActions();
  const startReporting = useSetReportingStatus();

  const handleStartReporting = useCallback(() => {
    if (!company) return;
    startReporting(company.id, true);
  }, [company, startReporting]);

  const menuItems = useMemo(() => {
    const company = params.data;
    if (!company) return [];
    const menu: IMenuItem[] = [
      {
        name: 'Assign',
        icon: <AssignmentOutlinedIcon />,
        onClick: () => handleSelectAction([company], 'assign'),
      },
      {
        name: 'Manage',
        icon: <SettingsIcon />,
        onClick: () => handleSelectAction([company], 'manage'),
      },
    ];

    if (company.assigned) {
      menu.splice(1, 0, {
        name: 'Review Templates',
        icon: <ChevronRightIcon />,
        onClick: () => handleReview(company),
      });
    }

    const { reporting } = company;
    let reportingAction = {
      name: 'Start Reporting',
      icon: <LibraryBooksOutlinedIcon />,
      onClick: handleStartReporting,
      divider: true,
    };
    if (reporting) {
      reportingAction = {
        name: 'Stop Reporting',
        icon: <BlockOutlinedIcon />,
        onClick: () => handleSelectAction([company], 'confirmStopReporting'),
        divider: true,
      };
    }
    menu.unshift(reportingAction);

    return menu;
  }, [handleReview, handleSelectAction, handleStartReporting, params.data]);

  if (!company) return null;
  return <MenuButton menuItems={menuItems} disabled={menuItems.length === 0} />;
}
