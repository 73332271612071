import * as yup from 'yup';
import { merge } from 'lodash-es';
import { dateField, usdField } from './common-schema-defs';
import { BaseModelWithID, baseModelWithIDFields } from './BaseModel.schema';
import { fundInvestmentTransactionFields } from './FundReserves.schema';

interface QuarterlyDateFields {
  dateFrom?: string | null;
  dateTo: string | null;
}

export function capitalAllocationResponseFields() {
  return {
    id: yup.number().required(), // fund id
    quarterlyMetrics: yup.array().of(capitalAllocationSchema()).default([]),
    summary: capitalAllocationSchema(),
  };
}

export function capitalAllocationResponseSchema() {
  return yup.object().shape(capitalAllocationResponseFields());
}
export type CapitalAllocationResponse = Omit<
  yup.InferType<ReturnType<typeof capitalAllocationResponseSchema>>,
  'quarterlyMetrics'
> & { quarterlyMetrics: CapitalAllocation[] };

export function createCapitalAllocationResponse(
  overrides: Partial<CapitalAllocationResponse> = {}
): CapitalAllocationResponse {
  return merge({ id: 0 }, capitalAllocationResponseSchema().getDefault(), overrides);
}

export function capitalAllocationFields() {
  return {
    allocatedCapitalBalance: usdField().nullable().default(null), // sum of Initial Investments Balance + Reserves Balance + Fees & Expenses Balance
    dateFrom: dateField().nullable().default(null),
    dateTo: dateField().nullable().default(null),
    feesAndExpenses: usdField().nullable().default(null),
    feesAndExpensesBalance: usdField().nullable().default(null), // relative to allocation
    initialInvestmentsBalance: usdField().nullable().default(null), // Initial Investment Allocation - Initial Investments Invested
    initialInvestmentsInvested: usdField().nullable().default(null), // cumulative sum of real initial investments
    initialInvestmentsPacing: usdField().nullable().default(null), // cumulative sum of real and future initial investments
    initialInvestmentsPacingBalance: usdField().nullable().default(null), // Initial Investment Allocation - Initial Investments Pacing
    period: yup.string().default(''), // e.g. 'Q2 2021'
    projectedFeesAndExpenses: usdField().nullable().default(null), //  sum of future fees and expenses
    projectedFeesAndExpensesBalance: usdField().nullable().default(null), // Fees & Expenses Allocation - Fees & Expenses Projected
    projectedFeesAndExpensesTransaction: projectedFeesAndExpensesTransactionSchema().nullable().default(null), // user-entered, projected fees and expenses
    projectedReservesAmount: usdField().nullable().default(null),
    reservesBalance: usdField().nullable().default(null),
    reservesInvested: usdField().nullable().default(null),
    reservesPacing: usdField().nullable().default(null),
    reservesPacingBalance: usdField().nullable().default(null),
    totalCapitalInvested: usdField().nullable().default(null), // Initial Investments Invested + Reserves Invested + Fees & Expenses
  };
}

export function capitalAllocationSchema() {
  return yup.object().shape(capitalAllocationFields());
}

export type CapitalAllocation = Omit<
  yup.InferType<ReturnType<typeof capitalAllocationSchema>>,
  'dateTo' | 'dateFrom'
> &
  QuarterlyDateFields;

export function createCapitalAllocation(overrides: Partial<CapitalAllocation> = {}): CapitalAllocation {
  return merge({}, capitalAllocationSchema().getDefault(), overrides);
}

export function projectedFeesAndExpensesTransactionFields() {
  const { id, ...baseFields } = baseModelWithIDFields();
  const { amount, date } = fundInvestmentTransactionFields();
  return {
    ...baseFields,
    amount: amount.label('Projected Fees And Expenses'),
    date,
    fundId: yup.number().required(),
    id: id.nullable(),
    name: yup.string(),
  };
}

export function projectedFeesAndExpensesTransactionSchema() {
  return yup.object().shape(projectedFeesAndExpensesTransactionFields());
}

export function createProjectedFeesAndExpensesTransaction(
  overrides: Partial<ProjectedFeesAndExpensesTransaction> = {}
): ProjectedFeesAndExpensesTransaction {
  return merge({ fundId: 0 }, projectedFeesAndExpensesTransactionSchema().getDefault(), overrides);
}

export type ProjectedFeesAndExpensesTransaction = Omit<
  yup.InferType<ReturnType<typeof projectedFeesAndExpensesTransactionSchema>>,
  'createdAt' | 'date' | 'updatedAt'
> & { date: string } & Pick<BaseModelWithID, 'createdAt' | 'updatedAt'>;
