import { CashFlow, xirr } from '@webcarrot/xirr';

export function calculateXIRR(investments: CashFlow[]): number {
  try {
    return getValueWith2Dp(xirr(investments) * 100);
  } catch (e) {
    try {
      return getValueWith2Dp(xirr(investments, -0.99) * 100);
    } catch (e) {
      try {
        return getValueWith2Dp(xirr(investments, -0.5) * 100);
      } catch (e) {
        return -100;
      }
    }
  }
}

function getValueWith2Dp(value: number) {
  const res = Number(value.toFixed(2));

  return Object.is(res, -0) ? 0 : res;
}
