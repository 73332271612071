import { selector } from 'recoil';
import { IUserDataModel, UnknownUser } from '../data-models/user.data-model';
import { usersState } from '../services/state/AppConfigState';

export class UserService {
  static #instance: UserService | undefined;
  #users: Map<number, IUserDataModel>;

  private constructor() {
    this.#users = new Map<number, IUserDataModel>();
  }

  init(users: IUserDataModel[]) {
    users.forEach((user) => {
      this.#users.set(user.id, user);
    });
  }

  getUser(id: number, returnDummyIfNotFound = false) {
    const user = this.#users.get(id);

    return returnDummyIfNotFound && !user ? UnknownUser : user;
  }

  static initService(users: IUserDataModel[]) {
    if (!this.#instance) {
      this.#instance = new UserService();
      this.#instance.init(users);
    }
  }

  static get() {
    if (!this.#instance) {
      throw new Error('User service has not been initialized');
    }

    return this.#instance!;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

export const userServiceState = selector<UserService>({
  key: 'userServiceState',
  get: ({ get }) => {
    const users = get(usersState);

    UserService.destroyService();
    UserService.initService(users);

    return UserService.get();
  },
});
