import { FC } from 'react';
import { useTheme } from '@mui/material';

export const MISSING_DATA_ITEM_INDICATOR = '-';

interface MembersData {
  name: string;
  company: string;
  linkedin: string;
}

interface Props {
  membersData: MembersData[];
}

export const BoardMembersTooltipContent: FC<Props> = ({ membersData }) => {
  const { primary } = useTheme().colors;
  return (
    <>
      {membersData.map((member) => {
        const { name, company, linkedin } = member;
        return (
          <li key={name} style={{ listStyle: 'none' }}>
            <span>
              {linkedin && linkedin !== MISSING_DATA_ITEM_INDICATOR ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={linkedin.indexOf('http') === 0 ? linkedin : `//${linkedin}`}
                  style={{ textDecoration: 'none', color: `${primary[60]}` }}
                >
                  {`${name}, `}
                </a>
              ) : (
                <>{`${name},`} </>
              )}
              {`${company}`}
            </span>
            <br />
          </li>
        );
      })}
    </>
  );
};
