import { styled } from '@mui/material';

export const ChartContainer = styled('div')`
  padding: 1rem;
  width: 100%;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border: 2px solid #ffffff;
  box-shadow: 0px -4px 40px rgb(255 255 255 / 6%), 0px 4px 24px rgb(16 37 62 / 4%),
    0px 24px 48px rgb(75 82 93 / 2%);
  border-radius: 4px;
  overflow: hidden; // <-- This fixes chart not shrinking with its container
`;
