import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { CardList, ICardProps } from '../../../../PortfolioOverview/components/KPISection/CardList';
import {
  selectedFrequencyPerfState,
  selectedMetricsDateCPState,
} from '../../../state/CompanyFinancialsDateState';
import { typedFinancialsByPeriodState } from '../../../state/CompanyFinancialsState';
import { selectedCompanyIdProfile } from '../../../state/UIState';

import { SubSection } from '../../../Summary/components/SubSection';
import { TypedFinancials } from '../../../utils/getTypedFinancials';
import { AddStatistic } from './AddStatistic';
import { useKPISelectMenu } from './useKPISelectMenu';

interface KpiData {
  id: number;
  displayName: string;
  valueFormatted: string;
}

export function Statistics() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId));
  const selectedPeriod = useRecoilValue(selectedFrequencyPerfState(companyId));
  const { handleDelete, handleReorder, selectedStatistics } = useKPISelectMenu();
  const financials = useRecoilValue(
    typedFinancialsByPeriodState({
      companyId,
      date: formatISODateOnly(selectedDate!),
      period: selectedPeriod,
    })
  );
  const financialsByIdAndSection = useMemo(() => getFinancialsByIdAndSection(financials), [financials]);

  const kpis: ICardProps[] = useMemo(() => {
    return selectedStatistics.map((item) => {
      const financialsById = financialsByIdAndSection?.get(item.id);
      const kpiActual = financialsById?.get(KpiSection.actual);
      const kpiBudget = financialsById?.get(KpiSection.budget);

      return {
        id: String(item.id),
        title: item.value,
        value: kpiActual?.valueFormatted ?? '-',
        indicator: null,
        secondaryValue: kpiBudget?.valueFormatted ? `${kpiBudget?.valueFormatted} (budget)` : '-',
      };
    }, [] as KpiData[]);
  }, [financialsByIdAndSection, selectedStatistics]);

  return (
    <SubSection title={'Statistics'} actionComponent={<AddStatistic />} noCard>
      <CardList
        kpis={kpis}
        onDelete={handleDelete}
        onReorder={handleReorder}
        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(10.5rem, 1fr))' }}
      />
    </SubSection>
  );
}

function getFinancialsByIdAndSection(typedFinancials: TypedFinancials[]) {
  const typedFinancialsByKpiId = new Map<number, Map<KpiSection, TypedFinancials>>();

  typedFinancials.forEach((item) => {
    if (!typedFinancialsByKpiId.has(item.kpiId)) {
      typedFinancialsByKpiId.set(item.kpiId, new Map<KpiSection, TypedFinancials>());
    }
    typedFinancialsByKpiId.get(item.kpiId)!.set(item.section ?? KpiSection.budget, item);
  });

  return typedFinancialsByKpiId;
}
