// FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-5225

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { debounce, isEqual } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSaveNote } from '../hooks/useSaveNote';
import { useLexicalContext } from '../provider/LexicalProvider';

const DebounceAutoSaveTime = 500;

export function AutoSavePlugin() {
  const [editor] = useLexicalComposerContext();
  const { noteData } = useLexicalContext();
  const { watch, trigger } = useFormContext();
  const saveNote = useSaveNote();

  const removeTextListenerRef = useRef<(() => void) | null>(null);
  const debouncedSave = useRef(debounce((noteData) => saveNote(noteData), DebounceAutoSaveTime)).current;

  const checkIfValidAndDebounceSave = useCallback(async () => {
    // Trigger form validation
    const isValid = await trigger();

    if (isValid) {
      debouncedSave(noteData);
    }
  }, [debouncedSave, noteData, trigger]);

  useEffect(() => {
    const removeTextContentListener = editor.registerUpdateListener(({ editorState, prevEditorState }) => {
      editorState.read(() => {
        const currentState = editorState.toJSON();
        const previousState = prevEditorState.toJSON();

        const hasStateChanged = !isEqual(currentState, previousState);

        if (hasStateChanged) {
          checkIfValidAndDebounceSave();
        }
      });
    });

    if (!removeTextListenerRef.current) {
      removeTextListenerRef.current = removeTextContentListener;
    }

    return () => {
      removeTextListenerRef.current?.();
    };
  }, [checkIfValidAndDebounceSave, editor]);

  useEffect(() => {
    const subscription = watch(() => {
      checkIfValidAndDebounceSave();
    });

    return () => subscription.unsubscribe();
  }, [checkIfValidAndDebounceSave, debouncedSave, watch]);

  return null;
}
