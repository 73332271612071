type SumCalculatorConfig<T> = {
  filter?: (transaction: T) => boolean;
};
export type FDIterator<ReturnType, ValueType, ContextType = ValueType> = {
  value: ReturnType;
  next: (nextValue: ValueType, context?: ContextType) => ReturnType;
};

export function sumIterator<T>(field: keyof T, config?: SumCalculatorConfig<T>): FDIterator<number, T> {
  let res = 0;

  return {
    get value() {
      return res;
    },
    next: (transaction: T) => {
      res += config?.filter?.(transaction) === false ? 0 : ((transaction[field] as number) ?? 0);
      return res;
    },
  };
}

export function subtractIterator<T>(field: keyof T, config?: SumCalculatorConfig<T>): FDIterator<number, T> {
  let res = 0;

  return {
    get value() {
      return res;
    },
    next: (transaction: T) => {
      if (!config?.filter || config?.filter(transaction)) {
        res -= transaction[field] as number;
      }

      return res;
    },
  };
}

export function fallBackIterator<ReturnType, ValueType>(
  errorMessage: string,
  defaultValue: ReturnType
): FDIterator<ReturnType, ValueType> {
  console.error('Using fallback calculator:', errorMessage);

  return {
    get value() {
      return defaultValue;
    },
    next: () => {
      return defaultValue;
    },
  };
}

export function firstValueMatchingRule<ValueType>(
  rule: (transaction: ValueType) => boolean,
  defaultValue?: ValueType
): FDIterator<ValueType | null, ValueType> {
  let res: ValueType | null = null;

  return {
    get value() {
      return res ?? defaultValue ?? null;
    },
    next: (nextValue) => {
      if (res === null && rule(nextValue)) {
        res = nextValue;
      }

      return res;
    },
  };
}

export function lastValueMatchingRule<ValueType>(
  rule: (transaction: ValueType) => boolean,
  defaultValue?: ValueType
): FDIterator<ValueType | null, ValueType> {
  let res: ValueType | null = null;

  return {
    get value() {
      return res ?? defaultValue ?? null;
    },
    next: (nextValue) => {
      if (rule(nextValue)) {
        res = nextValue;
      }

      return res ?? defaultValue ?? null;
    },
  };
}

export function lastFieldValueMatchingRule<ReturnType, ValueType>(
  field: keyof ValueType,
  rule: (transaction: ValueType) => boolean,
  defaultValue?: ReturnType
): FDIterator<ReturnType | null, ValueType> {
  let res: ReturnType | null = null;

  return {
    get value() {
      return res ?? defaultValue ?? null;
    },
    next: (nextValue) => {
      if (rule(nextValue)) {
        res = nextValue[field] as ReturnType | null;
      }

      return res;
    },
  };
}

export function minMax(value: number | null | undefined, min = -Number.MAX_VALUE, max = Number.MAX_VALUE) {
  if (value == null) {
    return min;
  }
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

// function nonZeroRule(value: number) {
//   return value > 0;
// }

// function firstNonZero<ValueType>(
//   field: keyof MetricsTransactionDataModel,
//   defaultValue?: ValueType
// ): FDIterator<ValueType | null, MetricsTransactionDataModel> {
//   return firstValueMatchingRule<ValueType>(
//     field,
//     (transaction) => nonZeroRule(transaction[field] as number),
//     defaultValue
//   );
// }
