import { useCallback, useMemo } from 'react';
import { ColDef, SuppressKeyboardEventParams, ValueSetterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { capitalize, isEqual } from 'lodash-es';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  KpiConfigPeriod,
  KpiPeriod,
  KpiSection,
} from '../../../../../data-models/company-financials.data-model';
import { IField } from '../../../../../data-models/field2.data-model';
import {
  kpiConfigByDisplayNameMapState,
  kpiGroupsByIdState,
} from '../../../../../services/state/KPI/KPITemplatesState';
import { companyProfileFinancialsDefaultColDef } from '../../../commonGridColDefs';
import { KpiNameCellRenderer, KpiValueRenderer } from '../components/KpiCellRenderers';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { currencyMapByCodeState } from '../../../../../services/state/AppConfigState';
import { KpiEditor } from '../components/KpiEditor';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { companyState } from '../../../../../services/state/CompanyState';
import { FinancialsDisplaySettings } from '../components/FinancialsSettingsState';
import { usePinnedYearColDefs } from '../pinnedYears/usePinnedYearColDefs';
import { FMT } from '../../../../../util/formatter-service';
import { FinancialType, IFinancialsRowData2 } from './useFinancialsGridData';
import { ICustomKPIActions, useBulkCustomKPIActions } from './useCustomKPIActions';

export type IValuesBySection<T> = Record<FinancialType, T>;

export type HeaderCompParamsType = {
  colDate: Date;
  kpiPeriod: KpiPeriod;
};

export interface IFinancialsColumnDefsParams {
  allPeriods: Array<Date>;
  frequency: KpiPeriod;
  options?: Partial<FinancialsDisplaySettings>;
}
export function useFinancialsColumnDefs({
  allPeriods,
  frequency,
  options = {},
}: IFinancialsColumnDefsParams) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const { kpiNameDef, kpiGroupDef } = useKpiColumnDefs(options);
  const getFinancialPeriodColDef = useGetFinancialPeriodColDef();

  const getPinnedYearDefs = usePinnedYearColDefs();
  const pinnedYearDefs = useMemo(() => {
    return getPinnedYearDefs({ fye: company?.fye ?? 12, options });
  }, [company?.fye, getPinnedYearDefs, options]);

  const columnDefs = useMemo(() => {
    if (!frequency) return [];
    const columnsDefs: ColDef<IFinancialsRowData2>[] = [
      kpiGroupDef,
      kpiNameDef,
    ] as ColDef<IFinancialsRowData2>[];

    const fye = company?.fye ?? 12;

    allPeriods.forEach((currentPeriod) => {
      const def = getFinancialPeriodColDef({ date: currentPeriod, frequency, fye, options });
      columnsDefs.push(def);
    });

    return [...columnsDefs, ...pinnedYearDefs];
  }, [
    frequency,
    kpiGroupDef,
    kpiNameDef,
    company?.fye,
    allPeriods,
    pinnedYearDefs,
    getFinancialPeriodColDef,
    options,
  ]);

  return {
    columnDefs,
    defaultColDef: companyProfileFinancialsDefaultColDef,
  };
}

export function kpiNameComparator(
  valueA: string,
  valueB: string,
  kpisByNameMap: Map<string, IField<unknown>>
) {
  return (
    (kpisByNameMap.get(valueA)?.sortOrder ?? Number.MAX_SAFE_INTEGER) -
    (kpisByNameMap.get(valueB)?.sortOrder ?? Number.MAX_SAFE_INTEGER)
  );
}

export type TKpiValueSetterParams = Pick<
  ValueSetterParams<IFinancialsRowData2, IValuesBySection<unknown>>,
  'data' | 'colDef' | 'newValue' | 'node' | 'api'
>;

export function useKpiValueSetter() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const currencies = useRecoilValue(currencyMapByCodeState);
  const bulkUpdateCustomKPIData = useBulkCustomKPIActions();

  return useCallback(
    (params: TKpiValueSetterParams) => {
      const { data, colDef, newValue } = params;
      const { colDate, kpiPeriod } = colDef.headerComponentParams as HeaderCompParamsType;
      const colTitle = colDef.headerName;
      const isoDate = formatISODateOnly(colDate);
      if (!data || !colTitle) return false;

      const actualFinancialsId = data?.valuesByDate2?.[isoDate]?.[kpiPeriod]?.actual?.id;
      const budgetFinancialsId = data?.valuesByDate2?.[isoDate]?.[kpiPeriod]?.budget?.id;

      const currentActual = data?.valuesByDate2?.[isoDate]?.[kpiPeriod]?.actual?.value;
      const currentBudget = data?.valuesByDate2?.[isoDate]?.[kpiPeriod]?.budget?.value;

      if (isEqual(newValue?.actual, currentActual) && isEqual(newValue?.budget, currentBudget)) {
        return false;
      }

      const payload = {
        companyId,
        date: isoDate,
        kpiId: data.id,
        period: kpiPeriod,
        currencyId: currencies.get('USD')!.id,
      };

      const actions: ICustomKPIActions = {
        companyId,
        create: [],
        update: [],
        delete: [],
      };

      if (newValue?.actual == null && currentActual != null && actualFinancialsId) {
        actions.delete.push([companyId, actualFinancialsId]);
      }

      if (newValue?.budget == null && currentBudget != null && budgetFinancialsId) {
        actions.delete.push([companyId, budgetFinancialsId]);
      }

      if (newValue?.actual != null && !isEqual(newValue.actual, currentActual)) {
        const payloadValue = toPayloadValue(newValue.actual);
        if (actualFinancialsId) {
          actions.update.push([
            companyId,
            actualFinancialsId,
            { date: payload.date, value: payloadValue, section: KpiSection.actual },
          ]);
        } else {
          actions.create.push([companyId, { ...payload, value: payloadValue, section: KpiSection.actual }]);
        }
      }

      if (newValue?.budget != null && !isEqual(newValue.budget, currentBudget)) {
        const payloadValue = toPayloadValue(newValue.budget);
        if (budgetFinancialsId) {
          actions.update.push([
            companyId,
            budgetFinancialsId,
            { date: payload.date, value: payloadValue, section: KpiSection.budget },
          ]);
        } else {
          actions.create.push([companyId, { ...payload, value: payloadValue, section: KpiSection.budget }]);
        }
      }

      if (!actions.create.length && !actions.update.length && !actions.delete.length) return false;
      bulkUpdateCustomKPIData(actions);
      return true;
    },
    [bulkUpdateCustomKPIData, companyId, currencies]
  );
}

export function toPayloadValue(value: unknown) {
  if (value instanceof Date) return formatISODateOnly(value);
  return String(value);
}

export function suppressNavigation(params: SuppressKeyboardEventParams<IFinancialsRowData2>) {
  const {
    event: { key },
    editing,
  } = params;
  return editing && (key === 'Tab' || key === 'Enter');
}

export function useKpiColumnDefs(options: IFinancialsColumnDefsParams['options'] = {}) {
  const kpisByNameMap = useRecoilValue(kpiConfigByDisplayNameMapState);
  const kpiGroupsById = useRecoilValue(kpiGroupsByIdState);
  const { showManageGroups } = useFlags<MaggieFeatureFlags>();

  const kpiGroupDef: ColDef<IField<unknown>> = useMemo(() => {
    if (!showManageGroups)
      return {
        field: 'gridMeta.group',
        headerName: 'KPI Type',
        rowGroup: true,
        hide: true,
      };
    return {
      colId: 'kpiGroupId',
      field: 'kpiGroupId',
      headerName: 'KPI Group',
      hide: true,
      initialSort: 'asc',
      rowGroup: true,
      valueFormatter: (params) => {
        return kpiGroupsById.get(Number(params.value) ?? -1)?.name ?? '-';
      },
      comparator: (valueA, valueB, nodeA, nodeB) => {
        const groupA = kpiGroupsById.get(Number(nodeA.key));
        const groupB = kpiGroupsById.get(Number(nodeB.key));
        const indexA = groupA?.sortOrder ?? Number.MAX_SAFE_INTEGER;
        const indexB = groupB?.sortOrder ?? Number.MAX_SAFE_INTEGER;

        return indexA - indexB;
      },
    };
  }, [kpiGroupsById, showManageGroups]);

  const kpiNameDef: ColDef<IFinancialsRowData2> = useMemo(() => {
    return {
      colId: 'kpiName',
      field: 'displayName',
      headerName: 'KPI Name',
      minWidth: 250,
      pinned: 'left',
      comparator: (valueA, valueB, nodeA, nodeB) => {
        return kpiNameComparator(nodeA.data?.displayName ?? '', nodeB.data?.displayName ?? '', kpisByNameMap);
      },
      sortable: true,
      initialSort: 'asc',
      cellRenderer: KpiNameCellRenderer,
      cellStyle: { padding: 0 },
      cellRendererParams: { ...options },
    };
  }, [kpisByNameMap, options]);

  return {
    kpiGroupDef,
    kpiNameDef,
  };
}

interface ICommonFinancialsColumnDefsParams
  extends Pick<IFinancialsColumnDefsParams, 'frequency' | 'options'> {
  date: Date;
  fye: number; //[1-12]
}
export function useGetFinancialPeriodColDef() {
  return useCallback(
    ({
      date,
      frequency,
      fye,
      options = {},
    }: ICommonFinancialsColumnDefsParams): ColDef<IFinancialsRowData2> => {
      const isoDate = formatISODateOnly(date);
      const colTitle = getPeriodColumnLabel(isoDate, frequency);

      return {
        headerName: colTitle,
        editable: true,
        valueGetter: (params) => {
          const { valuesByDate2 } = params.data ?? {};
          const valuesForDate = valuesByDate2?.[isoDate];

          return {
            actual: valuesForDate?.[frequency]?.[KpiSection.actual]?.value ?? null,
            budget: valuesForDate?.[frequency]?.[KpiSection.budget]?.value ?? null,
            growth: valuesForDate?.[`Growth${capitalize(frequency)}`]?.[KpiSection.actual]?.value ?? null,
            [KpiConfigPeriod.LTM]: valuesForDate?.[KpiConfigPeriod.LTM]?.[KpiSection.actual]?.value ?? null,
            [KpiConfigPeriod.CYTD]: valuesForDate?.[KpiConfigPeriod.CYTD]?.[KpiSection.actual]?.value ?? null,
          };
        },
        cellRenderer: KpiValueRenderer,
        cellRendererParams: { ...options, fullWidth: true },
        cellEditor: KpiEditor,
        cellStyle: { padding: 0 },
        cellEditorPopup: true,
        cellEditorParams: {
          fye,
        },
        headerComponentParams: {
          colDate: date,
          kpiPeriod: frequency,
        },
        headerClass: 'centered-header',
        suppressKeyboardEvent: (params) => {
          return suppressNavigation(params);
        },
      };
    },
    []
  );
}

export function getFinancialsFromRowData(data: IFinancialsRowData2, isoDate: string, kpiPeriod: KpiPeriod) {
  return data?.valuesByDate2?.[isoDate]?.[kpiPeriod];
}

function getPeriodColumnLabel(isoDateOnlyString: string, frequency: KpiPeriod) {
  const date = new Date(isoDateOnlyString);

  switch (frequency.toLowerCase()) {
    case 'quarter':
    case 'month': {
      return FMT.format('monthYear', date);
    }
    case 'year':
      return FMT.format('YYYY', new Date(isoDateOnlyString));
    default:
      return isoDateOnlyString;
  }
}
