export interface ICompanyFundingsDataModel {
  fundingRoundCount: number;
  acceleratorIncubatorAlum: string[];
  totalRaisedToDate: number;
  lastFundingAmount: number;
  lastFundingDate: string;
  lastFundingType: string;
  fundings: IFundingDataModel[]; // array of funding rounds sorted by date, ascending
}

export interface IFundingDataModel {
  dealId: number;
  dealDate: string;
  fundingRoundId: number;
  fundingRound: string;
  fundingRoundCategoryId: number;
  fundingRoundCategory: string;
  dealSizeInMillions: number;
  valuationInMillions: number | null;
  investors: Investor[];
  leadInvestors?: string;
  fundedOrg: Investor;
  valuationSourceType: string;
  valuationIsEstimate: boolean;
  valuationSourceUrls: string[] | null;
  lastUpdateTime: string;
}

export interface Investor {
  orgId: number;
  orgName: string;
  url: null | string;
}

export function createFundingDataModel(overrides: Partial<IFundingDataModel> = {}): IFundingDataModel {
  return {
    dealId: 0,
    dealDate: '',
    fundingRoundId: 0,
    fundingRound: '',
    fundingRoundCategoryId: 0,
    fundingRoundCategory: '',
    dealSizeInMillions: 0,
    valuationInMillions: null,
    investors: [],
    leadInvestors: '',

    fundedOrg: {
      orgId: 0,
      orgName: '',
      url: null,
    },
    valuationSourceType: '',
    valuationIsEstimate: false,
    valuationSourceUrls: null,
    lastUpdateTime: '',
    ...overrides,
  };
}

export function createCompanyFundingDataModel(
  overrides?: Partial<ICompanyFundingsDataModel>
): ICompanyFundingsDataModel {
  return {
    fundingRoundCount: 0,
    acceleratorIncubatorAlum: [],
    totalRaisedToDate: 0,
    lastFundingAmount: 0,
    lastFundingDate: '',
    lastFundingType: '',
    fundings: [],

    ...overrides,
  };
}
