import { FC } from 'react';
import { Tooltip, useTheme } from '@mui/material';

interface ILabelsRendererProps {
  value: string[] | undefined | null;
}

export const LabelsRenderer: FC<ILabelsRendererProps> = ({ value }) => {
  const { neutral } = useTheme().colors;
  const labels = value ? value.join(', ') : '';

  return (
    <span>
      <Tooltip
        role={'tooltip'}
        title={<span style={{ color: neutral[60], backgroundColor: 'white' }}>{labels}</span>}
        placement='top-start'
      >
        <span>{labels}</span>
      </Tooltip>
    </span>
  );
};
