import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  fetchFundReserves,
  getFundProjectedInvestmentHistory,
} from '../../../services/queries/MaggieFundQueries';
import {
  selectedFundIdStateFP,
  selectedFundReservesState,
  forceReservesUpdateState,
  fundReservesHistoryState,
  getDefaultProjectionsAsOfDate,
  selectedFundStateFP,
} from '../state/FPState';

export function useInvalidateReservesData() {
  const fundId = useRecoilValue(selectedFundIdStateFP);
  const fund = useRecoilValue(selectedFundStateFP);
  const setReserves = useSetRecoilState(selectedFundReservesState(fundId ?? -1));
  const forceReservesUpdate = useSetRecoilState(forceReservesUpdateState);
  const setReservesHistory = useSetRecoilState(fundReservesHistoryState(fundId));

  return useCallback(async () => {
    if (!fundId) return;
    try {
      const reserves = await fetchFundReserves(getDefaultProjectionsAsOfDate(fund), fundId);
      setReserves(reserves);
      forceReservesUpdate((curr) => curr + 1); // ensures we refetch for any other date, not just the selected one
      const reservesHistory = await getFundProjectedInvestmentHistory(fundId);
      setReservesHistory(reservesHistory);
    } catch (err) {
      console.error(err);
    }
  }, [forceReservesUpdate, fund, fundId, setReserves, setReservesHistory]);
}
