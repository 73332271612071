/* istanbul ignore */
import { useFormik } from 'formik';

import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { Autocomplete, Button, InputLabel, TextField } from '@mui/material';
import { isEqual } from 'lodash-es';
import { LoadingButton } from '@mui/lab';
import { Publish } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { useMilestoneActions } from '../../../hooks/useMilestoneActions';

import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { FieldContainer, FormGrid } from '../OkrForms/StyledFormComponents';
import { ButtonContainer } from '../OkrForms/ObjectiveForm';
import { noChange } from '../OkrForms/formHelpers';
import {
  ICompanyMilestoneDataMode,
  MilestoneStatus,
} from '../../../../../data-models/company-milestone.data-model';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { milestoneSchema } from './milestoneSchema';
import { milestoneInitialValues } from './initialValues';

interface MilestoneStatusOption {
  id: MilestoneStatus;
  value: MilestoneStatus;
  label: string;
}

const statusOptions: MilestoneStatusOption[] = [
  { id: 'Behind Plan', value: 'Behind Plan', label: 'Behind Plan' },
  { id: 'On Track', value: 'On Track', label: 'On Track' },
  { id: 'Complete', value: 'Complete', label: 'Complete' },
];

export interface MilestoneInitialValues {
  id?: number;
  title: string;
  status: string;
}

interface MilestoneFormProps {
  initialValues?: MilestoneInitialValues;
  onClose: () => void;
}

export const MilestoneForm: FC<MilestoneFormProps> = ({ initialValues, onClose }) => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { handleCreateMilestone, handleEditMilestone } = useMilestoneActions();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const formik = useFormik({
    initialValues: initialValues ?? milestoneInitialValues,
    validationSchema: milestoneSchema,
    validateOnChange: true,
    initialTouched: initialValues ? { title: true } : undefined,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = { ...values, companyId: companyId };

      const action = typeof initialValues?.id === 'number' ? 'edit' : 'add';

      if (action === 'edit' && isEqual(initialValues, values)) {
        onClose();
        return;
      }

      try {
        if (action === 'add') await handleCreateMilestone(payload as Partial<ICompanyMilestoneDataMode>);
        else await handleEditMilestone(payload as Partial<ICompanyMilestoneDataMode>);
        pushSuccessToast({ message: `Milestone ${action}ed successfully` });
      } catch (err) {
        pushErrorToast({ message: `Failed to ${action} a milestone` });
      } finally {
        setSubmitting(false);
        onClose();
      }
    },
  });

  const { values, errors, touched, handleSubmit, setFieldValue, handleChange, handleBlur, isSubmitting } =
    formik;

  const submitDisabled = useMemo(() => noChange(initialValues, values), [initialValues, values]);

  const handleStatusChange = useCallback(
    (e: SyntheticEvent, value: MilestoneStatusOption | null) => {
      if (!value) setFieldValue('status', null);
      else setFieldValue('status', value.value);
    },
    [setFieldValue]
  );

  return (
    <FieldContainer>
      <FormGrid>
        <InputLabel htmlFor={`title`}>Name</InputLabel>
        <TextField
          id={`title`}
          name={`title`}
          error={Boolean(errors.title && touched.title)}
          helperText={errors.title && touched.title ? errors.title : ''}
          onBlur={handleBlur}
          value={values.title}
          onChange={handleChange}
          placeholder='Enter Name'
        />
        <InputLabel htmlFor={`status`}>Status</InputLabel>
        <Autocomplete
          options={statusOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`status`}
              id={`status`}
              error={Boolean(errors.status && touched.status)}
              helperText={errors.status && touched.status ? errors.status : ''}
              placeholder='Select Status'
            />
          )}
          onChange={(e, v) => handleStatusChange(e, v)}
          onBlur={handleBlur}
          value={statusOptions.find((option) => option.id === values.status) ?? null}
          selectOnFocus
        />

        <div></div>
        <ButtonContainer>
          <LoadingButton
            variant='contained'
            color={'secondary'}
            size={'large'}
            disabled={
              Object.keys(touched).length === 0 ||
              Object.keys(errors).length !== 0 ||
              isSubmitting ||
              submitDisabled
            }
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            loading={isSubmitting}
            loadingPosition='end'
            endIcon={<Publish />}
          >
            {typeof initialValues?.id === 'number' ? 'Edit Milestone' : 'Add Milestone'}
          </LoadingButton>
          <Button type='button' variant='outlined' color='secondary' size={'large'} onClick={onClose}>
            Cancel
          </Button>
        </ButtonContainer>
      </FormGrid>
    </FieldContainer>
  );
};
