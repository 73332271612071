import { useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

export const ColoredNumericRenderer: FC<ICellRendererParams> = (params) => {
  const { colors } = useTheme();
  const { value, valueFormatted } = params;

  if (value == null) return null;

  const isGrouped = params.node?.group;

  let color;
  if (isGrouped) color = undefined;
  else if (value < 0) color = colors.critical[60];
  else if (value > 0) color = colors.success[60];
  else color = undefined;

  return <span style={{ color }}>{valueFormatted ?? value}</span>;
};
