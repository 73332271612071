import { FC, useCallback } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { useFinanceActions } from '../hooks/useFinanceActions';
import { StepperFormButtons } from './StepperFormButtons';
import { currentStepState, transactionDataState } from './TransactionFormUIState';
import { TransactionFormSummary } from './TransactionFormSummary';

export const TransactionFormSummaryStep: FC = () => {
  const setCurrentStep = useSetRecoilState(currentStepState);
  const transactionPayload = useRecoilValue(transactionDataState);
  const { handleCreateTransaction } = useFinanceActions();
  const onSubmitAndAdd = useSubmitTransactionAndAddAnother(true);

  const handleGoBack = useCallback(() => {
    setCurrentStep((step) => step - 1);
  }, [setCurrentStep]);

  const onSubmit = useCallback(async () => {
    if (!transactionPayload) return false;
    await handleCreateTransaction(transactionPayload);
    return true;
  }, [handleCreateTransaction, transactionPayload]);

  return (
    <>
      <StepperFormFieldsContainer>
        <TransactionFormSummary />
      </StepperFormFieldsContainer>
      <StepperFormButtons
        stepIsValid={onSubmit}
        nextButtonLabel={'Submit'}
        handleGoBack={handleGoBack}
        secondaryActionProps={{ handler: onSubmitAndAdd, label: 'Submit and Add Another' }}
      />
    </>
  );
};

export function useSubmitTransactionAndAddAnother(goBackOnSubmit = false) {
  const setCurrentStep = useSetRecoilState(currentStepState);
  const transactionPayload = useRecoilValue(transactionDataState);
  const resetTransactionData = useResetRecoilState(transactionDataState);
  const { handleCreateTransaction } = useFinanceActions();

  return useCallback(async () => {
    if (!transactionPayload) return;

    await handleCreateTransaction(transactionPayload);
    resetTransactionData();

    if (goBackOnSubmit) {
      setCurrentStep((step) => step - 1);
    }
  }, [goBackOnSubmit, handleCreateTransaction, resetTransactionData, setCurrentStep, transactionPayload]);
}
