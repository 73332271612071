import { set } from 'lodash-es';
import {
  CaptableSource,
  CaptableType,
  ICaptableDataModel,
  IStakeholderInvestmentDataModel,
} from '../../../data-models/captable2.data-model';
import { ICompanyDataModel, isPortfolioCompany } from '../../../data-models/company.data-model';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import { ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { ShareClass } from '../../../view-models/captable.view-model';

export function isDuplicateSource(captable: ICaptableDataModel) {
  return captable.type === CaptableType.system && captable.source !== CaptableSource.none;
}

export function isUserCreated(captable: ICaptableDataModel) {
  return captable.type === CaptableType.user;
}

export function isClientInvestment(
  investment: Pick<IStakeholderInvestmentDataModel, 'fundId' | 'stakeholderName'>
) {
  return investment.fundId != null;
}

export const CaptableSourceMap = {
  [CaptableSource.aumni]: 'Aumni',
  [CaptableSource.carta]: 'Carta',
  [CaptableSource.diligentEquity]: 'Diligent Equity',
  [CaptableSource.none]: '',
};

export function getCaptableSourceLabel(captable: ICaptableDataModel) {
  return isUserCreated(captable) ? 'User Created' : CaptableSourceMap[captable.source];
}

interface IInitialRowGetterParams {
  company: ICompanyDataModel;
  companyFundIds: Iterable<number>;
  shareClasses: ShareClass[];
  funds: IFundDataModel[];
  excludedShareClasses: Set<ViewModelInvestmentType>;
}
export function getInitialClientInvestmentRows({
  company,
  funds,
  companyFundIds,
  shareClasses,
  excludedShareClasses,
}: IInitialRowGetterParams) {
  function setShareClassData(row: Record<string, unknown>) {
    shareClasses.forEach((shareClass) => {
      if (!excludedShareClasses.has(shareClass.type)) {
        set(row, shareClass.name, null);
      }
    });
  }

  const rows = [];
  if (isPortfolioCompany(company!)) {
    for (const fundId of companyFundIds) {
      const row = { fundId };
      setShareClassData(row);
      rows.push(row);
    }
  } else {
    const row = { fundId: funds.at(0)!.id };
    setShareClassData(row);
    rows.push(row);
  }
  return rows;
}
