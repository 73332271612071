import React, { useCallback } from 'react';
import { Rating, Stack } from '@mui/material';
import { clone } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { UserCell } from '../../../pages/CompanyProfiles/Performance/components/Objectives/UserCell';
import { ILexicalNoteFormValues } from '../../../data-models/note.data-model';

export function LexicalEditorAttendeesTab() {
  const { watch, setValue } = useFormContext<ILexicalNoteFormValues>();

  const selectedAttendees = watch('body.attendees');

  const onChangeRating = useCallback(
    (newRating: number | null, index: number) => {
      const newAttendees = [...selectedAttendees];
      const attendee = clone(newAttendees[index]);
      newAttendees[index] = attendee;
      newAttendees[index].rating = !newRating ? 0 : (newRating as number);

      setValue('body.attendees', newAttendees);
    },
    [selectedAttendees, setValue]
  );

  return (
    <Stack justifyContent='space-between' gap='1rem'>
      {selectedAttendees.map((attendee, index) => (
        <Stack key={attendee.userId} direction='row' justifyContent='space-between'>
          <UserCell userId={attendee.userId} />
          <UserRating
            onChangeRating={(event, value) => onChangeRating(value, index)}
            rating={attendee.rating ?? 0}
          />
        </Stack>
      ))}
    </Stack>
  );
}

function UserRating({
  rating,
  onChangeRating,
}: {
  rating: number;
  onChangeRating: (event: React.SyntheticEvent, value: number | null) => void;
}) {
  return <Rating onChange={onChangeRating} defaultValue={rating} size='medium' />;
}
