import { useCallback, useState } from 'react';
import { ITransactionDataModel } from '../../../../data-models/transaction.data-model';
import { IFinanceRoundDataModel } from '../../../../data-models/finance-round.data-model';
import { useFinanceActions } from '../../hooks/useFinanceActions';

export function anyFieldHasValue(obj?: object | null, ignoreKeys?: Set<string>): boolean {
  if (!obj) return false;
  const entries = Object.entries(obj);

  for (const [key, value] of entries) {
    if (value != null && value !== '' && !ignoreKeys?.has(key)) {
      return true;
    }
  }
  return false;
}

export function useAssignToInvestmentSubmitHandler(next?: () => void) {
  const { handleAssignToExistingInvestment, handleAssignToNewInvestment, handleUnassignInvestment } =
    useFinanceActions();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (
      transaction: ITransactionDataModel,
      financeRound: Partial<IFinanceRoundDataModel>,
      unassign = false
    ) => {
      setLoading(true);
      if (unassign) {
        await handleUnassignInvestment(transaction);
      } else if (typeof financeRound.id === 'number') {
        await handleAssignToExistingInvestment(transaction.id, financeRound.id, transaction.companyId);
      } else {
        await handleAssignToNewInvestment(transaction.id, financeRound, transaction.companyId);
      }
      setLoading(false);
      next?.();
    },
    [handleAssignToExistingInvestment, handleAssignToNewInvestment, handleUnassignInvestment, next]
  );

  return { handleSubmit, loading };
}
