import { useCallback } from 'react';
import { ColumnVisibleEvent, GridApi, GridReadyEvent } from 'ag-grid-community';
import { atom, useRecoilState } from 'recoil';
import {
  allColIds,
  getPinnedYearColId,
  parsePinnedYearIndex,
  pinnedYearIndices,
} from './usePinnedYearColDefs';

export const defaultPinnedYearIndices = [1, 2];

const selectedYearsState = atom<Set<number>>({
  key: 'selectedYearsState',
  default: new Set([...defaultPinnedYearIndices]),
});

const gridApiState = atom<GridApi | null>({
  key: 'financialsGridApiState',
  default: null,
  dangerouslyAllowMutability: true,
});

export function useVisibleColumns() {
  const [selectedYears, setSelectedYears] = useRecoilState(selectedYearsState);
  const [gridApi, setFinancialsApi] = useRecoilState(gridApiState);

  const setGridApi = useCallback(
    (api: GridApi | null) => {
      setFinancialsApi(api);
    },
    [setFinancialsApi]
  );

  const onHideSingleColumn = useCallback(
    (colId: string) => {
      gridApi?.setColumnsVisible([colId], false);
    },
    [gridApi]
  );

  const onChangeSelectedYears = useCallback(
    (newValue: number[]) => {
      if (!gridApi) return;
      const pinned = new Set(newValue);

      const visibleIds = [] as string[];
      const hiddenIds = [] as string[];
      pinnedYearIndices.forEach((year) => {
        if (pinned.has(year)) {
          visibleIds.push(getPinnedYearColId(year));
        } else {
          hiddenIds.push(getPinnedYearColId(year));
        }
      });
      gridApi.setColumnsVisible(visibleIds, true);
      gridApi.setColumnsVisible(hiddenIds, false);
    },
    [gridApi]
  );

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      const affectedColumns = e.columns?.map((col) => col.getColId()) || [];
      const hasPinnedYear = affectedColumns.some((col) => allColIds.has(col));
      if (!hasPinnedYear) return;
      setSelectedYears(getSelectedYears(e.api));
    },
    [setSelectedYears]
  );

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      setGridApi(e.api);
    },
    [setGridApi]
  );

  const onGridPreDestroyed = useCallback(() => {
    setGridApi(null);
  }, [setGridApi]);

  return {
    gridApi,
    onChangeSelectedYears,
    onColumnVisible,
    onGridPreDestroyed,
    onGridReady,
    onHideSingleColumn,
    selectedYears,
  };
}

function getSelectedYears(api?: GridApi) {
  const result = new Set<number>();
  api?.getColumnState().forEach((col) => {
    if (allColIds.has(col.colId) && !col.hide) {
      result.add(parsePinnedYearIndex(col.colId));
    }
  });

  return result;
}
