import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IUserDataModel } from '../../../../data-models/user.data-model';
import { SharedButtonStatus, TSharedUser } from '../../../../types';
import { selectedViewPF } from '../../state/ViewState';
import { useShareView } from '../../hooks/useViewActions';
import { MuiMultiselect } from '../../../../components/MuiMultiselect/MuiMultiselect';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { activeUsersState } from '../../../../services/state/AppConfigState';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { FormField, FormFieldsContainer, Label } from '../../../../components/Form/FormComponents';
import { SharedUser } from './SharedUser';

interface IShareViewModalProps {
  onCloseClick: () => void;
  users: IUserDataModel[] | undefined;
}

export const ShareViewModal = ({ users, onCloseClick }: IShareViewModalProps): JSX.Element => {
  const view = useRecoilValue(selectedViewPF);
  const shareView = useShareView();
  const [tags, setTags] = useState<Option[]>([]);
  const [sharedUsers, setSharedUsers] = useState<TSharedUser[]>([]);
  const [owner, setOwner] = useState<TSharedUser | null>(null);

  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((e: SyntheticEvent, value: Option[] | undefined) => {
    if (!value) return;
    setTags(value);
  }, []);

  const onShare = () => {
    if (!tags || !tags.length || !view) return;
    const emails = tags.map((t) => t.id as string);

    setLoading(true);

    shareView(view, emails).then(() => {
      setLoading(false);
      setTags([]);
      onCloseClick();
    });
  };

  const onCancel = () => {
    onCloseClick();
  };

  useEffect(() => {
    if (!view || !view.sharing) return;
    const newSharedUsers = [] as TSharedUser[];
    view.sharing?.forEach((email) => {
      const sharedUser = users?.find((u) => u.email === email);
      if (sharedUser) {
        newSharedUsers.push({
          id: sharedUser.email,
          value: `${sharedUser.name} (${sharedUser.email})`,
          type: SharedButtonStatus.shared,
        });
      }
    });
    setSharedUsers(newSharedUsers);
  }, [view, users]);

  useEffect(() => {
    if (!view) return;
    const userOwner = users?.find((u) => u.email === view.createdBy);
    if (userOwner)
      setOwner({
        id: userOwner.email,
        value: `${userOwner.name} (${userOwner.email})`,
        type: SharedButtonStatus.owner,
      });
  }, [view, users]);

  const sortedActiveUsers = useRecoilValue(activeUsersState);
  const options = useMemo(
    () =>
      sortedActiveUsers
        .filter((u) => sharedUsers.every((su) => su.id !== u.email))
        .filter((u) => owner?.id !== u.email)
        .map((u) => ({ id: u.email, value: `${u.name} (${u.email})` })),
    [owner?.id, sharedUsers, sortedActiveUsers]
  );

  return (
    <>
      <FormFieldsContainer>
        <FormField>
          <Label required>With</Label>
          <MuiMultiselect
            options={options}
            value={tags}
            onChange={handleChange}
            style={{ width: '100%' }}
            fieldPlaceholder={'Add people by entering emails or names'}
            disablePortal={false}
          />
        </FormField>
        <>
          {owner && <SharedUser item={owner} setSharedUsers={setSharedUsers} />}
          {sharedUsers.map((user) => (
            <SharedUser key={user.id} item={user} setSharedUsers={setSharedUsers} />
          ))}
        </>
      </FormFieldsContainer>
      <StickyFormButtons onSubmit={onShare} onCancel={onCancel} submitLabel='Share' loading={loading} />
    </>
  );
};
