import { IConnectionActivityDataModel } from '../../../data-models/connection-activity.data-model';
import {
  IActivityParticipantDataModel,
  IContactActivityDataModel,
  IContactActivityItemDataModel,
  IPersonDataModel,
} from '../../../data-models/person.data-model';
import { IUserRelationDataModel } from '../../../data-models/user-relation.data-model';
import { sortByRelationshipScore } from './usePeopleBreakdown';

export type PersonActivity = IConnectionActivityDataModel & { primaryConnectionId?: number };

export interface IPersonActivitiesFlattened {
  firstEmail: PersonActivity | null;
  lastEmail: PersonActivity | null;
  firstMeeting: PersonActivity | null;
  lastMeeting: PersonActivity | null;
  scheduledMeetings: PersonActivity[];
}

export function getActivities(person: IPersonDataModel): IPersonActivitiesFlattened {
  if (person.activity) {
    return getExtendedPersonActivityData(person);
  } else {
    return getPersonActivityData(person);
  }
}

export function getPersonActivityData(person: IPersonDataModel) {
  const result: IPersonActivitiesFlattened = {
    firstEmail: person.activities?.emails?.oldest ?? null,
    lastEmail: null,
    firstMeeting: person.activities?.meetings?.oldest ?? null,
    lastMeeting: null,
    scheduledMeetings: [],
  };

  if (person.activities?.emails?.mostRecent?.length) {
    result.lastEmail = [...person.activities.emails.mostRecent].sort(compareByCreatedAt).at(-1) ?? null;
  }
  if (person.activities?.meetings?.mostRecent?.length) {
    const sorted = [...person.activities.meetings.mostRecent]
      .sort(compareByCreatedAt)
      .filter((m) => Boolean(m.createdAt));

    if (sorted.length > 0) {
      const scheduledStartIndex = sorted.findIndex((m) => new Date(m.createdAt!) > new Date());

      if (scheduledStartIndex !== -1) {
        result.lastMeeting = sorted.at(scheduledStartIndex - 1)!;
        result.scheduledMeetings = sorted.slice(scheduledStartIndex);
      } else {
        result.lastMeeting = sorted.at(-1)!;
      }
    }
  }
  return result;
}

export function getExtendedPersonActivityData(person: IPersonDataModel): IPersonActivitiesFlattened {
  const { firstEmail, lastEmail, firstMeeting, lastMeeting, scheduledMeetings } = getEngagementSummary(
    person.activity
  );
  return {
    firstEmail: firstEmail ? getActivityData(firstEmail, person.relationships) : null,
    lastEmail: lastEmail ? getActivityData(lastEmail, person.relationships) : null,
    firstMeeting: firstMeeting ? getActivityData(firstMeeting, person.relationships) : null,
    lastMeeting: lastMeeting ? getActivityData(lastMeeting, person.relationships) : null,
    scheduledMeetings: scheduledMeetings.map((m) => getActivityData(m, person.relationships)),
  };
}

export function compareByCreatedAt(
  a: { createdAt: string | null },
  b: { createdAt: string | null },
  order: 'asc' | 'desc' = 'asc'
) {
  const aValue = a.createdAt ? new Date(a.createdAt).getTime() : Number.MIN_SAFE_INTEGER;
  const bValue = b.createdAt ? new Date(b.createdAt).getTime() : Number.MIN_SAFE_INTEGER;
  return order === 'asc' ? aValue - bValue : bValue - aValue;
}

export function getActivityData(
  activity: IContactActivityItemDataModel,
  relationships: IUserRelationDataModel[]
): PersonActivity {
  return {
    userIds: activity.participants.reduce((acc, p) => {
      if (!p.id) return acc;
      return [...acc, p.id];
    }, [] as number[]),
    primaryConnectionId: getPrimaryConnection(activity.participants, relationships),
    createdAt: activity.createdAt,
  };
}

export function getPrimaryConnection(
  participants: IActivityParticipantDataModel[],
  relationships: IUserRelationDataModel[]
): number | undefined {
  const from = participants.find((p) => p.id != null && (p.type === 'from' || p.type === 'creator'));
  if (from) return from.id!;

  const tos = new Set(
    participants.reduce((acc, p) => {
      if (p.id != null && (p.type === 'to' || p.type === 'attendee')) {
        return [...acc, p.id];
      }
      return acc;
    }, [] as number[])
  );

  return (
    relationships
      .filter((r) => tos.has(r.userId))
      .sort(sortByRelationshipScore)
      .at(0)?.userId ?? [...tos].at(0)
  );
}

function createEmptyActivitySummary() {
  return {
    firstEmail: null,
    lastEmail: null,
    firstMeeting: null,
    lastMeeting: null,
    scheduledMeetings: [],
  };
}

export interface IEngagementSummary {
  firstEmail: IContactActivityItemDataModel | null;
  lastEmail: IContactActivityItemDataModel | null;
  firstMeeting: IContactActivityItemDataModel | null;
  lastMeeting: IContactActivityItemDataModel | null;
  scheduledMeetings: IContactActivityItemDataModel[];
}

export function getEngagementSummary(activity?: IContactActivityDataModel | null): IEngagementSummary {
  if (!activity) return createEmptyActivitySummary();
  const emails = (activity?.emails ?? []).filter((a) => a?.createdAt) as IContactActivityItemDataModel[];
  const meetings = (activity?.meetings ?? []).filter((a) => a?.createdAt) as IContactActivityItemDataModel[];
  const sortedEmails = emails.sort(compareByCreatedAt);
  const sortedMeetings = meetings.sort(compareByCreatedAt);
  const firstEmail = sortedEmails.at(0) ?? null;
  const lastEmail = sortedEmails.at(-1) ?? null;

  const scheduledStartIndex = sortedMeetings.findIndex(
    (m) => !!m.createdAt && new Date(m.createdAt) > new Date()
  );
  const completedMeetings =
    scheduledStartIndex !== -1 ? sortedMeetings.slice(0, scheduledStartIndex) : sortedMeetings;
  const firstMeeting = completedMeetings.at(0) ?? null;
  const lastMeeting = completedMeetings.at(-1) ?? null;
  const scheduledMeetings = scheduledStartIndex !== -1 ? sortedMeetings.slice(scheduledStartIndex) : [];

  return {
    firstEmail,
    lastEmail,
    firstMeeting,
    lastMeeting,
    scheduledMeetings,
  };
}

export const activityRoleToLabel: Record<string, string> = {
  from: 'from',
  to: 'to',
  cc: 'cc',
  creator: 'organizer',
  attendee: 'attendee',
};

export function formatParticipant(participant: IActivityParticipantDataModel) {
  if (!participant.type) return participant.name;
  return `${participant.name} (${activityRoleToLabel[participant.type] ?? participant.type})`;
}

export function splitParticipantsByRole(participants: IActivityParticipantDataModel[]) {
  return participants.reduce(
    (acc, p) => {
      if (p.type === 'creator' || p.type === 'from') {
        acc.organizers.push(p);
      } else {
        acc.rest.push(p);
      }
      return acc;
    },
    { organizers: [], rest: [] } as {
      organizers: IActivityParticipantDataModel[];
      rest: IActivityParticipantDataModel[];
    }
  );
}

export const activityTypeToLabel: Record<string, string> = {
  firstEmail: 'Oldest Email',
  lastEmail: 'Latest Email',
  firstMeeting: 'Oldest Meeting',
  lastMeeting: 'Latest Meeting',
  scheduledMeetings: 'Scheduled Meetings',
};
