import { FC, useCallback } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Stack } from '@mui/material';
import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { transactionReconState } from '../../state/FinanceState';
import { defaultColDef } from '../../common-grid-defs/defaultColDefs';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { MetricsReconDataModel } from '../../../../schemas/MetricsRecon.schema';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { useMetricsReconColDefs } from './useMetricsReconColDefs';
import { MissedDataPts } from './MissedDataPts';

export const TransactionReconGrid: FC = () => {
  const reconData = useRecoilValueLoadable(transactionReconState).valueMaybe();
  const { exportParams } = useExtendedExportSettings(new Set(['companyId', 'fundId']), 'Metrics Recon');

  const columnDefs = useMetricsReconColDefs();

  const getRowId = useCallback((params: GetRowIdParams<MetricsReconDataModel>): string => {
    return `${params.data?.companyId}-${params.data?.fundId}-${params.data?.reconConfigId}`;
  }, []);

  if (!reconData) return null;

  return (
    <>
      <MissedDataPts numMissedDataPts={reconData.length} />
      <Stack height={'100%'}>
        <FadeInGridWrapper>
          <div style={{ height: '80vh', paddingTop: '0.5rem' }}>
            <AgTable
              key={'transaction-recon'}
              columnDefs={columnDefs as ColDef<MetricsReconDataModel>[]}
              rowData={reconData}
              defaultColDef={defaultColDef}
              getRowId={getRowId}
              defaultExcelExportParams={exportParams}
              defaultCsvExportParams={exportParams}
              suppressMovableColumns
              rowGroupPanelShow='never'
            />
          </div>
        </FadeInGridWrapper>
      </Stack>
    </>
  );
};
