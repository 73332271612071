import { useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import {
  configurationStepsState,
  indexColumnMatchesState,
  selectedEntityState,
  selectedTableState,
} from '../../state/ConfigurationState';

export function useResetConfigState() {
  const setActiveStep = useSetRecoilState(configurationStepsState);
  const setSelectedTableId = useSetRecoilState(selectedTableState);
  const setSelectedEntity = useSetRecoilState(selectedEntityState);
  const setIndexCols = useSetRecoilState(indexColumnMatchesState);

  return useCallback(() => {
    setActiveStep(0);
    setSelectedTableId(null);
    setSelectedEntity(null);
    setIndexCols(new Map());
  }, [setActiveStep, setIndexCols, setSelectedEntity, setSelectedTableId]);
}
