import { IPackageDataModel } from '../data-models/package.data-model';

export const tempPackages: IPackageDataModel[] = [
  {
    id: 14,
    name: 'company-package',
    description: 'Package for company data',
    displayName: 'Company',
    isDefault: true,
    categories: [
      {
        id: 'd64ac85b-9b38-4998-b062-498371abaaad',
        name: 'group-profile',
        description: 'Company profile data',
        displayName: 'Profile',
        isDefault: true,
        order: 1,
        priority: 1,
        fields: [398, 408, 37, 304, 306, 401, 8, 251, 411, 406],
      },
      {
        id: '71a1541b-7528-4854-917d-99302b09fdef',
        name: 'group-investment',
        description: 'Investment Data for Universal Package',
        displayName: 'Investment',
        isDefault: true,
        order: 2,
        priority: 1,
        fields: [47, 287, 286, 4, 3, 51, 50, 247, 267, 200],
      },
      {
        id: '6490e207-fe11-48c6-b81b-d4c689840a2d',
        name: 'group-ceo',
        description: "Company's leaderhship data",
        displayName: 'Leadership',
        isDefault: true,
        order: 3,
        priority: 1,
        fields: [299, 300, 262, 241, 290, 395, 272],
      },
      {
        id: '80134cda-5a03-44cb-9bae-47cb5d5fb0a1',
        name: 'group-board',
        description: 'Board member data for company',
        displayName: 'Board',
        isDefault: true,
        order: 5,
        priority: 1,
        fields: [89],
      },
    ],
    order: 1,
    priority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-18T06:09:55.028Z',
    updatedAt: '2022-11-16T12:57:37.830Z',
    fields: [],
  },
  {
    id: 13,
    name: 'employee-package',
    description: 'Package for relevant employee data',
    displayName: 'Employee',
    isDefault: true,
    categories: [
      {
        id: 'beead523-563d-4e48-bf2e-ac349ed81dd5',
        name: 'group-headcount',
        description: 'Company headcount data',
        displayName: 'Headcount',
        isDefault: false,
        order: 1,
        priority: 1,
        fields: [323, 457, 459, 453, 455],
      },
      {
        id: '4fbd69a6-84d8-41be-af71-14961d681901',
        name: 'group-job-flow',
        description: 'Company job flow data',
        displayName: 'Job Flow',
        isDefault: false,
        order: 1,
        priority: 4,
        fields: [412, 414, 415, 416, 418, 419],
      },
      {
        id: '45d7dd9f-72ad-45e2-aa75-fc6395b856a8',
        name: 'group-active-postings',
        description: 'Company active job postings data',
        displayName: 'Active Job Postings',
        isDefault: false,
        order: 1,
        priority: 5,
        fields: [324, 327, 331, 334, 337],
      },
      {
        id: 'fea2a94d-d597-4965-972a-d3de52853716',
        name: 'group-new-postings',
        description: 'Company new job postings data',
        displayName: 'New Job Postings',
        isDefault: false,
        order: 1,
        priority: 6,
        fields: [338, 341, 344, 347, 349],
      },
      {
        id: 'c51640cb-f263-4a70-bafe-1c5f2ec9ad06',
        name: 'group-removed-postings',
        description: 'Company removed job postings data',
        displayName: 'Removed Job Postings',
        isDefault: false,
        order: 1,
        priority: 7,
        fields: [350, 353, 356, 359, 362],
      },
    ],
    order: 1,
    priority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-18T05:10:12.666Z',
    updatedAt: '2022-12-02T19:01:37.185Z',
    fields: [],
  },
  {
    id: 11,
    name: 'social-media-package',
    description: 'Package for relevant social media data',
    displayName: 'Social Media',
    isDefault: true,
    categories: [
      {
        id: '15a2603b-c642-463e-8883-c5e7061d3b36',
        name: 'group-linkedin',
        description: 'LinkedIn data for companies',
        displayName: 'LinkedIn',
        isDefault: false,
        order: 1,
        priority: 1,
        fields: [69, 164, 162, 161, 163],
      },
      {
        id: '2dfbe766-de0a-4dec-a2f2-bb86fb53732c',
        name: 'group-twitter',
        description: 'Twitter data for companies',
        displayName: 'Twitter',
        isDefault: false,
        order: 2,
        priority: 1,
        fields: [71, 175, 176, 177, 174],
      },
      {
        id: '116380fb-00ea-4d38-8d69-673d8e6c1995',
        name: 'group-instagram',
        description: 'Instagram data for companies',
        displayName: 'Instagram',
        isDefault: false,
        order: 3,
        priority: 1,
        fields: [64, 149, 150, 151, 148],
      },
      {
        id: '65e75546-0710-4fa2-bdd6-b9cb0a9e5a7e',
        name: 'group-facebook',
        description: 'Facebook data for companies',
        displayName: 'Facebook',
        isDefault: false,
        order: 4,
        priority: 1,
        fields: [59, 145, 146, 144, 147],
      },
    ],
    order: 1,
    priority: 5,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T23:47:54.442Z',
    updatedAt: '2022-11-11T23:15:54.115Z',
    fields: [],
  },
  {
    id: 17,
    name: 'sentiment-package',
    description: 'Company and Employee Sentiment and News',
    displayName: 'News & Sentiment',
    isDefault: true,
    categories: [
      {
        id: 'd9bd24df-37c6-4315-ad87-ca9d19f43541',
        name: 'group-activity',
        description: 'Activity associated with company',
        displayName: 'Activity',
        isDefault: false,
        order: 1,
        priority: 1,
        fields: [101, 104, 103, 99, 97, 100],
      },
      {
        id: 'a6b781ba-6a5c-4fe2-a3aa-fb6eb0d477fb',
        name: 'group-ratings',
        description: 'Glassdoor ratings for companies',
        displayName: 'Glassdoor Ratings',
        isDefault: true,
        order: 2,
        priority: 1,
        fields: [314, 315, 316, 317, 318, 319, 320, 321, 322, 422],
      },
    ],
    order: 1,
    priority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-16T12:55:57.045Z',
    updatedAt: '2022-11-16T13:03:10.257Z',
    fields: [],
  },
  {
    id: 12,
    name: 'product-package',
    description: 'Package for relevant product data',
    displayName: 'Product',
    isDefault: true,
    categories: [
      {
        id: 'e8dc304c-dfe4-4222-8e21-d29d0ae43e75',
        name: 'group-usage',
        description: 'Product usage data',
        displayName: 'App Usage',
        isDefault: false,
        order: 1,
        priority: 1,
        fields: [87, 105, 79],
      },
      {
        id: '64aeedb0-2cb8-4ae2-bdb7-7935b4b47d97',
        name: 'group-download',
        description: 'Product download data',
        displayName: 'App Download',
        isDefault: false,
        order: 2,
        priority: 1,
        fields: [10, 9, 11, 83],
      },
      {
        id: 'f5f50991-6b8a-4296-b18b-d2ec315c3ae1',
        name: 'group-transactions',
        description: 'Product US Card Transactions',
        displayName: 'Transactions',
        isDefault: false,
        order: 5,
        priority: 1,
        fields: [365, 366, 371, 375, 372, 378],
      },
      {
        id: 'f58612a4-7cb3-4142-8d04-66923795ebf9',
        name: 'group-g2',
        description: 'G2 data for product',
        displayName: 'G2 Metrics',
        isDefault: false,
        order: 6,
        priority: 1,
        fields: [425, 426, 427, 428, 429, 430, 431, 432, 433, 434, 435, 437, 438, 439, 440, 441, 442, 477],
      },
      {
        id: '19aef139-503c-4b3d-a538-4cbdd7f0d5e4',
        name: 'group-github',
        description: 'Github data for company',
        displayName: 'Github Repo',
        isDefault: false,
        order: 7,
        priority: 1,
        fields: [188, 190, 191, 192, 194, 195, 196, 198, 199],
      },
    ],
    order: 1,
    priority: 7,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-18T00:22:44.695Z',
    updatedAt: '2022-12-08T17:27:04.213Z',
    fields: [],
  },
];
