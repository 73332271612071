import { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Icon, IconTypes } from '../../../../components/Icon';

const VerticalInfoWrapper = styled('div')`
  display: flex;
  padding-bottom: 19px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
`;

const IconWrapper = styled('div')`
  transform: rotate(90deg);
`;

const Container = styled('div')<{ isOver: boolean }>`
  display: inline-block;
  padding-bottom: 16px;
  background-color: ${({ isOver, theme }) => (isOver ? theme.colors.primary[10] : theme.colors.primary[5])};
  border: 1px dashed ${({ theme, isOver }) => (isOver ? theme.colors.primary[40] : theme.colors.neutral[30])};
  min-height: 505px;
  padding: 8px;
  border-radius: 4px;
  margin-left: auto;
  min-width: 2.5rem;
`;

interface DroppableProps {
  stageId: number;
}

export const CloseDroppable: FC<DroppableProps> = ({ stageId }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: String(stageId),
  });

  const { colors } = useTheme();

  return (
    <Container ref={setNodeRef} isOver={isOver}>
      <VerticalInfoWrapper>
        <IconWrapper>
          <Icon type={IconTypes.SUCCESS} color={isOver ? colors.primary[60] : colors.neutral[30]} />
        </IconWrapper>
        <Typography variant='subtitle1' color={isOver ? colors.primary[60] : colors.neutral[30]}>
          {'Closed'}
        </Typography>
      </VerticalInfoWrapper>
    </Container>
  );
};
