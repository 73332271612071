import * as React from 'react';
import {
  NumberFormatBase,
  NumberFormatBaseProps,
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from 'react-number-format';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/material';
import { InputProps } from '../../types';

type NumericInputProps = NumericFormatProps & Pick<NumberFormatBaseProps, 'format'>;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<HTMLInputElement, CustomProps & NumericInputProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    const commonProps = {
      getInputRef: ref,
      onValueChange: (values: NumberFormatValues) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      },
      thousandSeparator: true,
      valueIsNumericString: true,
      ...other,
    };

    // If there is a prefix or suffix, use the NumericFormat component Numeric does not have format props but NumberFormat does
    if (props.suffix || props.prefix) {
      return <NumericFormat {...commonProps} />;
    }

    // If we need to pass a custom format, use the NumberFormatBase component does not have suffix or prefix props but NumericFormat does
    return <NumberFormatBase {...commonProps} />;
  }
);

export const NumericInput = (
  props: NumericInputProps & { error?: boolean; helperText?: string; sx?: SxProps }
) => {
  const { helperText, error, disabled, sx, onKeyDown, ...rest } = props;

  return (
    <TextField
      fullWidth
      sx={sx}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
        onKeyDown,
      }}
      variant='outlined'
      error={error}
      helperText={helperText}
      inputProps={{ ...(rest as InputProps) }}
      disabled={disabled}
    />
  );
};
