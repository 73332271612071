import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { useRecoilValue } from 'recoil';
import { useCurrentUser } from '../../services/hooks/useCurrentUser';
import { usersState } from '../../services/state/AppConfigState';
import { AvatarSize, MUIAvatar } from './MUIAvatar';

export interface AvatarProps {
  size: AvatarSize;
  style?: CSSProperties;
}

export function CurrentUserAvatar({ size, style }: AvatarProps) {
  const currentUser = useCurrentUser();
  const { primary } = useTheme().colors;
  const users = useRecoilValue(usersState);

  const iconSize = getIconSize(size);

  if (!currentUser || !users?.length)
    return <NoAccountsIcon aria-label='unknown user' fontSize={iconSize} htmlColor={primary[20]} />;

  return (
    <MUIAvatar
      size={iconSize}
      src={null}
      nameParts={[currentUser.firstName ?? '', currentUser.lastName ?? '']}
      style={style}
    />
  );
}

function getIconSize(size: AvatarProps['size']): 'small' | 'medium' | 'large' {
  return size === 'mediumLarge' || size === 'gridCell' ? 'medium' : size;
}
