import { useRecoilValue } from 'recoil';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Typography } from '@mui/material';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import {
  allCompaniesActionState,
  selectedAssignmentIdState,
  selectedKpiCompaniesStateDC,
} from '../AllCompaniesState';
import { kpiTemplateAssignmentsByIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IBaseFieldProps } from '../../../../../components/Form/Display/types';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { RendererType } from '../../../../../data-models/field.data-model';
import { createFormField } from '../../../../../view-models/form.view-model';
import { TemplateAssignment } from '../../../../../schemas/template-assignment-schema';
import { kpisRequestsListState } from '../../../../../services/state/KPI/KPIRequestsState';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';
import { TemplateAssignmentSummary } from './AssignTemplateStep3';
import {
  getPeriodOptions,
  frequencyToKpiPeriod,
  getFirstNonDuplicatePeriod,
  isDuplicateKpiRequest,
} from './create-request-utils';
import { useCreateKpiRequest } from './useCreateKpiRequest';
import { DuplicateRequestWarning } from './DuplicateRequestWarning';

export function CreateRequestModal() {
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const { resetUIState: handleCloseModal } = useAllKpiCompaniesActions();
  const [period, setPeriod] = useState<string | null>(null);
  const action = useRecoilValue(allCompaniesActionState);
  const company = selectedCompanies.at(0);
  const selectedAssignmentId = useRecoilValue(selectedAssignmentIdState);
  const assignmentsById = useRecoilValue(kpiTemplateAssignmentsByIdState);
  const assignment = assignmentsById.get(selectedAssignmentId ?? -1);
  const open = action === 'createRequest' && Boolean(company) && Boolean(assignment);
  const allRequests = useRecoilValue(kpisRequestsListState) ?? [];
  const showConfirm = isDuplicateKpiRequest({ allRequests, company, assignment, period });

  if (!open) return null;

  return (
    <BasicDialog open={true} title={`Create Request for ${company!.name}`} onClose={handleCloseModal}>
      {open && (
        <>
          <FormFieldsContainer>
            <TemplateAssignmentSummary
              id={company!.id}
              frequency={assignment!.frequency}
              granularity={assignment!.granularity}
              templateUuid={assignment!.templateUuid}
              reminderThreshold={assignment!.reminderThreshold!}
            />
            <PeriodField company={company!} assignment={assignment!} period={period} setPeriod={setPeriod} />
            <DuplicateRequestWarning
              company={company!}
              assignment={assignment!}
              period={period}
              show={showConfirm}
            />
          </FormFieldsContainer>
          <CreateRequestAction
            handleCancel={handleCloseModal}
            company={company!}
            assignment={assignment!}
            period={period}
          />
        </>
      )}
    </BasicDialog>
  );
}

interface IPeriodFieldProps {
  company: ICompanyDataModel;
  assignment: TemplateAssignment;
  period: string | null;
  setPeriod: Dispatch<SetStateAction<string | null>>;
}
function PeriodField({ company, assignment, period, setPeriod }: IPeriodFieldProps) {
  const values = useMemo(
    () =>
      getPeriodOptions({
        period: frequencyToKpiPeriod[assignment.frequency],
        fye: company.fye ?? 12,
      }),
    [assignment.frequency, company.fye]
  );
  const allRequests = useRecoilValue(kpisRequestsListState);

  const defaultPeriod = useMemo(() => {
    const idx = getFirstNonDuplicatePeriod({
      allRequests: allRequests ?? [],
      company,
      assignment,
      periodOptions: values,
    });
    return values.at(idx)?.value;
  }, [allRequests, assignment, company, values]);

  const periodSelectorProps = useMemo(() => {
    const formField = createFormField({
      key: 'period',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values,
      },
      placeholder: 'Select Period',
    });

    return {
      formProps: { value: period ?? defaultPeriod, onChange: setPeriod } as ControllerRenderProps,
      formField,
    } as IBaseFieldProps<unknown>;
  }, [defaultPeriod, period, setPeriod, values]);

  useEffect(() => {
    if (!defaultPeriod) return;
    setPeriod(defaultPeriod);
  }, [defaultPeriod, setPeriod]);

  return (
    <div>
      <Typography variant={'caption'} color={'text.secondary'} component={'label'}>
        Period
      </Typography>
      <FieldFactory {...periodSelectorProps} />
    </div>
  );
}

interface ICreateRequestActionPros {
  company: ICompanyDataModel;
  assignment: TemplateAssignment;
  period?: string | null;
  handleCancel: () => void;
}
function CreateRequestAction({ company, assignment, handleCancel, period }: ICreateRequestActionPros) {
  const createRequest = useCreateKpiRequest();
  const [loading, setLoading] = useState(false);

  const { frequency, templateUuid, granularity, reminderThreshold } = assignment;

  const handleSubmit = useCallback(async () => {
    if (!period) return;
    setLoading(true);
    await createRequest({
      companyId: company.id,
      frequency: frequency,
      granularity,
      reminderThreshold,
      templateUuid: templateUuid,
      respondent: company.respondents!,
      period,
    });
    setLoading(false);
    handleCancel();
  }, [
    company.id,
    company.respondents,
    createRequest,
    frequency,
    granularity,
    handleCancel,
    period,
    reminderThreshold,
    templateUuid,
  ]);

  return (
    <StickyFormButtons
      onCancel={handleCancel}
      submitLabel='Create'
      loading={loading}
      onSubmit={handleSubmit}
      disabled={!period}
    />
  );
}
