import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Button, Divider, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import {
  indexColumnMatchesState,
  configurationStepsState,
  selectedEntityState,
  selectedTableState,
} from '../../state/ConfigurationState';
import { airtableEntitiesState, airTableBasesMapState } from '../../state/IntegrationsState';
import { IUniqueColumnDataModel } from '../../../../data-models/airtable-entity.data-model';
import { InfoBox } from '../../../Mappings/forms/InfoBox';
import { ColumnMatcher } from '../components/ColumnMatcher';
import { getAvailableOptionsForColumn } from '../utils/syncUtils';
import { SlideUpPopper } from '../../../../components/SlideUpPopper';
import { StyledBorderCard, StyledMatchGrid, SyncFormCard } from '../components/CommonStyledComponents';
import { useResetConfigState } from '../hooks/useResetConfigState';

export const MatchWithForesight: FC = () => {
  const selectedTable = useRecoilValue(selectedTableState);
  const entitiesLoadable = useRecoilValueLoadable(airtableEntitiesState);
  const entities = entitiesLoadable.valueMaybe();

  const setConfigurationStep = useSetRecoilState(configurationStepsState);
  const resetConfigState = useResetConfigState();

  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntityState);
  const [selectedColumn, setSelectedColumn] = useState<IUniqueColumnDataModel | null>(null);
  const selectedBase = useRecoilValue(airTableBasesMapState)?.get(selectedTable?.baseId || '');
  const [matches, setMatches] = useRecoilState(indexColumnMatchesState);

  const { colors } = useTheme();

  // all FD columns for entity must be matched
  const disableMatch = useMemo(() => {
    if (!selectedTable || !selectedEntity || !selectedColumn) return true;
    return matches.size !== selectedColumn.columns.length;
  }, [matches, selectedColumn, selectedEntity, selectedTable]);

  const handleMatch = useCallback(async () => {
    if (!selectedTable || !selectedBase || !selectedColumn || !selectedEntity) return;
    setConfigurationStep((prev) => prev + 1);
  }, [selectedTable, selectedBase, selectedColumn, selectedEntity, setConfigurationStep]);

  if (!selectedTable || !entities?.length) return null;

  return (
    <>
      <SyncFormCard title={`Match "${selectedTable.name}" Table with Foresight`}>
        <StyledBorderCard style={{ margin: '1rem 0' }}>
          <Typography variant='body2'>
            Which Foresight data category are you trying to enrich with Airtable data?
          </Typography>
          <ToggleButtonGroup
            color='secondary'
            exclusive
            aria-label='entity'
            value={selectedEntity}
            onChange={(e, newValue) => {
              setSelectedEntity(newValue);
              setSelectedColumn(null);
              setMatches(new Map());
            }}
            style={{ margin: '0.5rem 0' }}
          >
            {entities.map((entity) => (
              <ToggleButton key={entity.entityValue} value={entity}>
                {entity.entityDisplayName}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <InfoBox message='NOTE: You can only choose one category for a sync process. You will have to create a new sync if you want to pull from a different category' />

          {selectedEntity && (
            <>
              <Divider style={{ margin: '1rem 0' }} />
              <Typography variant='body2'>{`Select a unique column (or group of unique columns) that we can use to match your AirTable to Foresight’s database.`}</Typography>
              <ToggleButtonGroup
                color='secondary'
                exclusive
                aria-label='unique column'
                value={selectedColumn}
                onChange={(e, newValue) => setSelectedColumn(newValue)}
                style={{ margin: '0.5rem 0' }}
              >
                {selectedEntity.uniqueColumns.map((column) => (
                  <ToggleButton key={column.displayName} value={column}>
                    {column.displayName}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {selectedColumn && (
                <>
                  <StyledMatchGrid>
                    <Typography
                      variant='caption'
                      color={colors.neutral[50]}
                    >{`${selectedEntity.entityDisplayName}'s Unique Column`}</Typography>
                    <span />
                    <Typography
                      variant='caption'
                      color={colors.neutral[50]}
                      style={{ marginLeft: '1rem' }}
                    >{`${selectedTable.name}'s Unique Column`}</Typography>

                    {selectedColumn.columns.map((col) => {
                      return (
                        <ColumnMatcher
                          key={col.value}
                          fixedColumn={col}
                          options={getAvailableOptionsForColumn(col, selectedTable.fields, matches)}
                          value={matches.get(col.value) || null}
                          onChange={(newValue) => {
                            if (!newValue) {
                              setMatches((prev) => {
                                const newMatches = new Map(prev);
                                newMatches.delete(col.value);
                                return newMatches;
                              });
                            } else {
                              setMatches((prev) => {
                                const newMatches = new Map(prev);
                                newMatches.set(col.value, newValue);
                                return newMatches;
                              });
                            }
                          }}
                        />
                      );
                    })}
                  </StyledMatchGrid>
                </>
              )}
            </>
          )}
        </StyledBorderCard>
        <Button variant='contained' color='secondary' disabled={disableMatch} onClick={handleMatch}>
          Match
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          style={{ marginLeft: '1rem' }}
          onClick={() => {
            resetConfigState();
          }}
        >
          Cancel
        </Button>
      </SyncFormCard>
      <SlideUpPopper
        message={
          <Typography variant='caption' textAlign={'center'}>
            <>{`Great! Now you can set your connection settings. It will help match records from Foresight to your Airtable workspace.`}</>
            <br />
            <>{`This can be edited later 🙂`}</>
          </Typography>
        }
      />
    </>
  );
};
