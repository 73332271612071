import { FC, useState, MouseEvent, useCallback, SyntheticEvent } from 'react';
import { Button, IconButton, MenuItem, MenuList, Popover, Typography, useTheme } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { IScenarioDataModel } from '../../../../data-models/scenario.data-model';
import { ActiveScenarioIdParamName, DuplicateParam, ViewModeParamName } from '../constants';
import { FullScreenParam } from '../../../../hooks/useFullScreen';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { useScenarioActions } from '../hooks/useScenarioActions';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useCanEditScenario } from '../hooks/useCanEditScenario';

const ModellingUrlSegment = `${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS}`;
const RunUrlSegment = `${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_RUN}`;

export const ScenarioActions: FC<{ scenario: IScenarioDataModel }> = ({ scenario }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  const { handleDeleteScenario } = useScenarioActions();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const canEdit = useCanEditScenario(scenario);

  const { colors } = useTheme();

  const onDelete = useCallback(() => {
    handleDeleteScenario(scenario.id);
  }, [handleDeleteScenario, scenario.id]);

  const onDuplicateScenario = () => {
    navigate({
      pathname: `${location.pathname}/${ModellingUrlSegment}`,
      search: createSearchParams({
        [FullScreenParam]: 'true',
        [ActiveScenarioIdParamName]: String(scenario.id),
        [DuplicateParam]: 'true',
      }).toString(),
    });
  };

  const onViewScenario = (id: number) => {
    navigate({
      pathname: `${location.pathname}/${ROUTES.COMPANY_PROFILE_SINGLE_SCENARIO}/${ROUTES.COMPANY_PROFILE_SCENARIO_SETTINGS}`,
      search: createSearchParams({
        [FullScreenParam]: 'true',
        [ViewModeParamName]: 'true',
        [ActiveScenarioIdParamName]: String(id),
      }).toString(),
    });
  };

  const onEditScenario = () => {
    navigate({
      pathname: `${location.pathname}/${ModellingUrlSegment}`,
      search: createSearchParams({
        [FullScreenParam]: 'true',
        [ActiveScenarioIdParamName]: String(scenario.id),
      }).toString(),
    });
  };

  const onRunScenario = () => {
    const searchParams = createSearchParams({
      [FullScreenParam]: 'true',
      [ActiveScenarioIdParamName]: String(scenario.id),
    });
    if (!canEdit) {
      searchParams.set(ViewModeParamName, 'true');
    }
    navigate({
      pathname: `${location.pathname}/${RunUrlSegment}`,
      search: searchParams.toString(),
    });
  };

  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label='run scenario' onClick={onRunScenario}>
        <PlayArrowIcon fontSize='small' color='secondary' />
      </IconButton>
      <IconButton size='small' onClick={onMenuClick} aria-label='scenario actions'>
        <MoreVertIcon fontSize='small' color='secondary' />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList aria-label={'scenario actions list'}>
          {canEdit ? (
            <MenuItem
              component={Button}
              startIcon={<ModeEditOutlinedIcon />}
              onClick={onEditScenario}
              aria-label='edit'
              sx={{ width: '100%' }}
            >
              Edit
            </MenuItem>
          ) : (
            <MenuItem
              component={Button}
              startIcon={<VisibilityIcon />}
              onClick={onViewScenario.bind(null, scenario.id)}
              aria-label='view'
              sx={{ width: '100%' }}
            >
              View
            </MenuItem>
          )}
          <MenuItem
            component={Button}
            startIcon={<ContentCopyOutlinedIcon />}
            onClick={onDuplicateScenario}
            sx={{ width: '100%' }}
            divider={canEdit}
          >
            Duplicate
          </MenuItem>
          {canEdit && (
            <MenuItem
              component={Button}
              aria-label='delete'
              startIcon={<DeleteOutlinedIcon />}
              onClick={() => {
                setShowConfirmDelete(true);
              }}
              sx={{ width: '100%' }}
            >
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Popover>
      {showConfirmDelete && (
        <ConfirmDeletionDialog
          open={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={onDelete}
          title='Are you sure you want to delete this scenario?'
        >
          <Typography variant='body2' color={colors.neutral[60]}>
            {`The scenario ${scenario.name}, will be deleted permanently`}
          </Typography>
        </ConfirmDeletionDialog>
      )}
    </div>
  );
};
