import * as yup from 'yup';
import { merge } from 'lodash-es';
import { RendererType } from '../data-models/field.data-model';
import { dateField } from './common-schema-defs';

const ledgerFileTypes = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export function generalLedgerFields() {
  return {
    file: yup
      .mixed()
      .required()
      .test(
        'is-valid-size',
        'Max file size is 50MB',
        (value) => value && (value as File).size <= 50 * 1024 * 1024
      )
      .test('is-valid-type', 'Invalid file type', (value) => {
        return value && ledgerFileTypes.includes((value as File).type);
      })
      .default(null)
      .meta({
        renderer: RendererType.fileUpload,
        rendererMeta: {
          acceptTypes: ledgerFileTypes,
        },
      }),
    date: dateField().required().default(null),
  };
}

export function generalLedgerFormSchema() {
  return yup.object(generalLedgerFields());
}

export type GeneralLedgerPayload = yup.InferType<ReturnType<typeof generalLedgerFormSchema>>;

export function createGeneralLedgerUploadPayload(overrides: Partial<GeneralLedgerPayload> = {}) {
  return merge({}, generalLedgerFormSchema().getDefault(), overrides);
}
