import { memo, useEffect, useRef, useState } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, use as echartsUse, init } from 'echarts/core';
import { RadarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useTheme } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  RadarSeriesOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { RadarSeriesDataItemOption } from 'echarts/types/src/chart/radar/RadarSeries';
import { ChartTitleWrapper } from '../../../../components/Charts/ChartTitleWrapper';
import { ChartWrapper } from '../../../../components/Charts/ChartWrapper/ChartWrapper';
import { IconTypes } from '../../../../components/Icon';
import { doNothingFunc } from '../../../../util/doNothingFunc';
import { Chart } from '../../../../components/Charts/chart-utils';
import { FieldDataModel } from '../../../../data-models/field.data-model';
import { selectedFieldsCI } from '../../state/DashboardsState';
import { companiesColoursCI, selectedCompaniesCI } from '../../state/CompaniesState';
import {
  CompetitiveIntelligenceMetricsDataModel,
  competitiveIntelligenceMetrics,
} from '../../hooks/useConnectCompetitiveIntelligenceMetrics';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';

echartsUse([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  RadarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export type ECRadarOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | RadarSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
>;

export interface IProps {
  title: string;
  caption?: string;
  id: string;
}

export interface SpiderChartData {
  data: RadarSeriesDataItemOption[];
  indicator: { name: string; id: number }[];
}

export const SpiderChart = memo(function SpiderChart({ title, caption, id }: IProps) {
  const metrics = useRecoilValue(competitiveIntelligenceMetrics);
  const selectedFields = useRecoilValue(selectedFieldsCI);
  const selectedCompanies = useRecoilValue(selectedCompaniesCI);
  const companyColours = useRecoilValue(companiesColoursCI);
  const [spiderChartData, setSpiderChartData] = useState<SpiderChartData>({
    data: [],
    indicator: [],
  });
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const spiderChart = useRef<ECharts | null>(null);
  const { primary } = useTheme().colors;

  useEffect(() => {
    const chartData = computeChartData(selectedCompanies, companyColours, selectedFields, metrics);
    setSpiderChartData(chartData);
  }, [selectedCompanies, companyColours, metrics, selectedFields]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!spiderChart.current) {
      spiderChart.current = init(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          spiderChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    const option: ECRadarOption = {
      grid: {
        containLabel: true,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
      axisName: {
        color: primary[100],
      },
      legend: {
        type: 'plain',
        bottom: 0,
        padding: 5,
        textStyle: {
          fontFamily: 'Wotfard-Regular',
          fontSize: 12,
        },
        show: false,
      },
      radar: {
        indicator: spiderChartData.indicator,
        axisName: {
          color: primary[90],
        },
        splitArea: {
          areaStyle: {
            color: ['#fff'],
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 0,
          },
        },
      },
      series: [
        {
          type: 'radar',
          data: spiderChartData.data,
        },
      ],
    };

    if (spiderChart.current && spiderChartData.indicator.length > 0) {
      spiderChart.current.setOption(option);
    }
  }, [primary, spiderChartData]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={doNothingFunc}
      onMouseEnter={doNothingFunc}
      width={'100%'}
      dataTestid={'radar-chart'}
      padding={'0px'}
    >
      <ChartTitleWrapper
        isHovering={false}
        title={title}
        showMore={false}
        showIcon={false}
        setShowMore={doNothingFunc}
        id={id}
        caption={caption}
        icon={IconTypes.FINANCE}
        handleSizeChange={doNothingFunc}
        size={'Full Screen'}
        refProp={chartContainer}
        more={false}
      />
      <Chart height={'320px'} width={'99%'} ref={chartContainer} />
    </ChartWrapper>
  );
});

function computeChartData(
  selectedCompanies: ICompanyDataModel[],
  companyColours: Map<number, string>,
  selectedFields: FieldDataModel[],
  metrics: CompetitiveIntelligenceMetricsDataModel
): SpiderChartData {
  if (selectedFields.length === 0) {
    return { data: [], indicator: [] };
  }

  const spiderChartFields = selectedFields.reduce((res, field) => {
    if (field.meta.chart?.type === 'spider') {
      res[field.id] = Number.NaN;
    }

    return res;
  }, {} as Record<number, number>);

  // Initialize with default values.
  const companiesMetrics = selectedCompanies.reduce((res, company) => {
    res[company.id] = {
      ...spiderChartFields,
    };
    return res;
  }, {} as Record<number, Record<number, number>>);

  // Fill in real values.
  metrics.data.forEach((metric) => {
    if (companiesMetrics[metric.companyId]?.[metric.fieldId] !== undefined) {
      companiesMetrics[metric.companyId][metric.fieldId] = metric.value as number;
    }
  }, companiesMetrics);

  // Format for Spider Chart.
  const spiderChartData: RadarSeriesDataItemOption[] = Object.keys(companiesMetrics).map((companyId) => {
    const company = selectedCompanies.find((c) => c.id === Number(companyId));
    const companyName = company?.name ?? companyId;
    const color = company ? companyColours.get(company.id) : 'grey';

    return {
      value: Object.values(companiesMetrics[Number(companyId)]),
      name: companyName,
      itemStyle: { color },
    };
  });

  const spiderChartIndicators = Object.keys(spiderChartFields).map((metricId) => {
    const fieldMeta = selectedFields.find((field) => field.id === Number(metricId));
    return { id: Number(metricId), name: fieldMeta?.displayName ?? metricId };
  });

  return {
    data: spiderChartData,
    indicator: spiderChartIndicators,
  };
}
