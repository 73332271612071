import { colors } from '../../../../theme/colors';

const SECTOR_COLORS = [
  colors.neutral[20],
  colors.critical[20],
  colors.primary[20],
  colors.success[30],
  colors.secondary[60],
  colors.warning[20],
  colors.tertiary[30],
];

export const mapSectorToColor = (sectorId: number | null | undefined) => {
  return sectorId ? SECTOR_COLORS[sectorId % SECTOR_COLORS.length] : colors.neutral[20];
};
