export const buildUniqueName = (name: string, currentViewNames: string[]) => {
  let newName = name;
  let counter = 1;
  while (currentViewNames.indexOf(newName) !== -1) {
    newName = `${name} (${counter++})`;
  }
  return newName;
};

export const nameDuplicate = (originLabel: string, currentViewLabels: string[]): string => {
  let label = originLabel + ' Copy';
  while (currentViewLabels.indexOf(label) !== -1) {
    label += ' Copy';
  }
  return label;
};
