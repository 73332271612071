import { useCallback } from 'react';
import { FieldSettings, simpleMultilineTextField } from '../../../data-fields/CommonFields';
import { RendererType } from '../../../data-models/field.data-model';
import { ITransactionDataModel, MiscPaymentType } from '../../../data-models/transaction.data-model';
import { IFormFieldSelectMeta, createFormField } from '../../../view-models/form.view-model';
import { useGetAmountField } from './TransactionFormUtils';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useMiscPaymentFields() {
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const getCommonInitialFields = useFundDateCurrencyFields();

  return useCallback(() => {
    return [
      ...getCommonInitialFields(),
      getAmountField({ key: 'miscPaymentAmount', label: 'Amount', required: true }),
      getMiscPaymentTypeField({ required: true }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getAmountField, getCommonInitialFields]);
}

export function getMiscPaymentTypeField({
  key = 'miscPaymentType',
  label = 'Payment Type',
  required = false,
  disabled = false,
}: Partial<FieldSettings>) {
  return createFormField<IFormFieldSelectMeta<string>>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.singleSelect,
    rendererMeta: {
      values: Object.values(MiscPaymentType).map((type) => {
        return { value: type, displayName: type };
      }),
      multi: false,
    },
  });
}
