// a single board member is represented as a string including name, company and linkedin url, separated by commas
// e.g Marco Diaz,-,linkedin.com/in/marco-diaz-2910a127,
// Gary Earl, Sharecare, linkedin.com /in /garyearl,
// the order is always name, company, linkedin url
// if any data is missing, it is represented as a "-"

import { BoardMembersTooltipContent } from './BoardMembersTooltipContent';
import { StyledTooltip } from './StyledTooltip';

interface Props {
  value: string[];
}

export const BoardMembersRenderer = ({ value: allBoardMembersData }: Props) => {
  const membersData = !Array.isArray(allBoardMembersData)
    ? null
    : allBoardMembersData.map((member) => {
        const [name, company, linkedin] = member.split(',');
        return { name, company, linkedin };
      });

  const names: string = !membersData ? '' : membersData.map((member) => member.name || '').join(', ');

  if (!names) return null;

  return (
    <>
      <StyledTooltip
        title={membersData ? <BoardMembersTooltipContent membersData={membersData} /> : ''}
        placement='top-start'
        className='CellTooltip'
      >
        <span>{names}</span>
      </StyledTooltip>
    </>
  );
};
