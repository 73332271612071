import { atom, selector } from 'recoil';
import { ITemplatesResponse } from '../../../data-models/template.data-model';
import { fetchCustomFields, fetchCustomFieldTemplates } from '../../queries/MaggieAdminPanelQueries';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { isCustomField } from '../../../util/field-utils';

export const customFieldsSelectedTemplateState = atom<string>({
  key: 'customFieldsSelectedTemplateState',
  default: 'company',
});

export const customFieldsOpenUploadModalState = atom<boolean>({
  key: 'customFieldsOpenUploadModalState',
  default: false,
});

export const templatesTypeListState = atom<ITemplatesResponse | null>({
  key: 'templatesTypeListState',
  default: null,
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        try {
          const res = fetchCustomFieldTemplates();
          setSelf(res);
        } catch (error) {
          console.error(error);
        }
      }
    },
  ],
});

export const customFieldsListState = atom<IField<unknown>[] | null>({
  key: 'customFieldsListState',
  default: selector({
    key: 'customFieldsListState/default',
    get: () => {
      return fetchCustomFields();
    },
  }),
});

export const customFieldsByIdMapState = selector<Map<number, IField<unknown>>>({
  key: 'customFieldsByIdMapState',
  get: ({ get }) => {
    const fields = get(customFieldsListState) ?? [];

    return new Map(fields.map((field) => [field.id, field]));
  },
});

export const customFieldsByEntity = selector<Map<FieldEntity, IField<unknown>[]>>({
  key: 'customFieldsByEntityState',
  get: ({ get }) => {
    const fields = get(customFieldsListState) ?? [];

    return fields.reduce((res, field) => {
      const entity = field.entity as FieldEntity;
      if (isCustomField(field)) {
        if (res.has(entity)) {
          res.get(entity)!.push(field);
        } else {
          res.set(entity, [field]);
        }
      }

      return res;
    }, new Map<FieldEntity, IField<unknown>[]>());
  },
});
