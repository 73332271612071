import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class ImpairmentRatioConfig implements KpiConfig {
  private id = KPI_COMPONENTS.IMPAIRMENT_RATIO as const;
  private title = 'Impairment Ratio';
  private type = 'percentage';
  private description = 'The percentage of invested capital in companies realized or valued below cost';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      description: this.description,
    };
  }

  calculate(params: KpiCalculationParams): Kpi {
    let totalAmountInvested = 0;
    let totalInvestmentInLossCompanies = 0;

    params.data?.rawCompanies.forEach((metrics) => {
      totalAmountInvested += metrics.amountInvested;
      if (countsAsImpairment(metrics)) {
        totalInvestmentInLossCompanies += metrics.amountInvested;
      }
    });

    const value =
      totalAmountInvested === 0 ? 0 : (totalInvestmentInLossCompanies / totalAmountInvested) * 100;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}

function countsAsImpairment(metrics: IMetricsDataModel) {
  return metrics.totalValue < metrics.amountInvested;
}
