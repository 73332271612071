import { styled } from '@mui/material/styles';

export const AlertCard = styled('div')`
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  padding: 0.75rem 1rem;
  background: white;
`;

export const ErrorAlertCard = styled(AlertCard)`
  border-left: 4px solid ${({ theme }) => theme.palette.error.main};
`;

export const SuccessAlertCard = styled(AlertCard)`
  border-left: 4px solid ${({ theme }) => theme.palette.success.main};
`;
