import { IFormField } from '../../../../view-models/form.view-model';

export function scenarioDefaultFormItemSettings(
  overrides: Omit<IFormField<unknown>, 'key' | 'renderer'> = {}
): Partial<IFormField<unknown>> {
  return {
    required: true,
    variant: 'form',
    ...overrides,
  };
}
