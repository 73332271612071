import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { CompanySearchMultiSelect } from '../../../../components/CompanySearch/CompanySearchMultiSelect';
import { ICompanySearchResponseDataModel } from '../../../../data-models/company-search-result.data-model';
import { CompanySearchResponseWithRealId } from '../../../../schemas/CompanySearchResponse.schema';

type AutocompleteWrapperProps = {
  errorMsg?: string;
};

const AutocompleteWrapper = styled('div')<AutocompleteWrapperProps>`
  max-width: 515px;
  &:after {
    font-size: 0.8em;
    color: ${({ theme }) => theme.colors.critical[50]};
    content: ${({ errorMsg: msg }) => (msg ? `'${msg}'` : '""')};
  }
`;

interface CompetitorsSelectorProps {
  onChange: (selected: CompanySearchResponseWithRealId[]) => void;
  initialValues?: ICompanySearchResponseDataModel[];
  disabledMsg?: string;
  hideLoaderOnCompanyCreation?: boolean;
}

export const CompetitorsSelector: FC<CompetitorsSelectorProps> = ({
  onChange,
  disabledMsg,
  initialValues,
  hideLoaderOnCompanyCreation,
}) => {
  return (
    <AutocompleteWrapper errorMsg={disabledMsg}>
      <CompanySearchMultiSelect
        autoFocus={true}
        initialValues={initialValues}
        onChange={onChange}
        hideLoaderOnCompanyCreation={hideLoaderOnCompanyCreation}
      />
    </AutocompleteWrapper>
  );
};
