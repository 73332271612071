import { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ITransactionDataModel } from '../../../../data-models/transaction.data-model';
import { FormFieldsContainer } from '../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { createInitialInvestmentValues } from '../../../../data-fields/InvestmentFields';
import { IFinanceRoundDataModel } from '../../../../data-models/finance-round.data-model';
import { InvestmentSelectOrCreateForm } from '../../Forms/InvestmentSelectOrCreateForm';
import {
  createFinanceRoundFormViewModel,
  IFinanceRoundFormViewModel,
  toFinanceRoundDataModel,
} from '../../../../view-models/finance-round-form.view-model';
import { assignToInvestmentSchema } from './investmentSchemas';

export const assignSubmitLabel = 'Save Changes';

interface IAssignToInvestmentFormProps {
  onClose: () => void;
  transaction: ITransactionDataModel;
  onSubmit: (
    transaction: ITransactionDataModel,
    round: Partial<IFinanceRoundDataModel>,
    unassign?: boolean
  ) => Promise<void>;
  loading?: boolean;
}

export const AssignToInvestmentForm: FC<IAssignToInvestmentFormProps> = ({
  onClose,
  transaction,
  onSubmit,

  loading,
}) => {
  const defaultValues = createFinanceRoundFormViewModel(
    transaction.round ?? createInitialInvestmentValues(transaction)
  );
  const formMethods = useForm<IFinanceRoundFormViewModel>({
    defaultValues,
    resolver: yupResolver(assignToInvestmentSchema),
  });

  const isTransactionAssigned = Boolean(transaction.investmentRoundId);

  const onRemove = useCallback(() => {
    formMethods.setValue('_viewModel.shouldBeRemoved', true);
  }, [formMethods]);

  const handleSubmit = useCallback(
    (financeRoundViewModel: Partial<IFinanceRoundFormViewModel>) => {
      const financeRound = toFinanceRoundDataModel(financeRoundViewModel as IFinanceRoundFormViewModel);

      return onSubmit(transaction, financeRound, financeRoundViewModel._viewModel?.shouldBeRemoved ?? false);
    },
    [onSubmit, transaction]
  );

  return (
    <FormProvider {...formMethods}>
      <form>
        <FormFieldsContainer>
          <InvestmentSelectOrCreateForm
            companyId={transaction.companyId}
            removeConfig={isTransactionAssigned ? { transaction, onRemove } : undefined}
          />
        </FormFieldsContainer>

        <StickyFormButtons
          onSubmit={formMethods.handleSubmit(handleSubmit)}
          onCancel={onClose}
          submitLabel={assignSubmitLabel}
          loading={loading}
        />
      </form>
    </FormProvider>
  );
};
