import { FieldDataModel } from '../data-models/field.data-model';

export const tempFields: FieldDataModel[] = [
  {
    id: 577,
    name: 'app-paid-download-percent-change-2y',
    displayName: 'App Paid Daily Download % Change (2y)',
    description: 'App Paid Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 28,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:23:35.169Z',
    updatedAt: '2023-01-05T20:23:35.169Z',
  },
  {
    id: 576,
    name: 'app-paid-download-percent-change-1y',
    displayName: 'App Paid Daily Download % Change (1y)',
    description: 'App Paid Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 27,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:23:28.693Z',
    updatedAt: '2023-01-05T20:23:28.693Z',
  },
  {
    id: 575,
    name: 'app-paid-download-percent-change-6m',
    displayName: 'App Paid Daily Download % Change (6m)',
    description: 'App Paid Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 26,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:23:20.461Z',
    updatedAt: '2023-01-05T20:23:20.461Z',
  },
  {
    id: 574,
    name: 'app-paid-download-percent-change-3m',
    displayName: 'App Paid Daily Download % Change (3m)',
    description: 'App Paid Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 25,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:23:13.041Z',
    updatedAt: '2023-01-05T20:23:13.041Z',
  },
  {
    id: 573,
    name: 'app-paid-download-percent-change-1m',
    displayName: 'App Paid Daily Download % Change (1m)',
    description: 'App Paid Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 24,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:23:08.769Z',
    updatedAt: '2023-01-05T20:23:08.769Z',
  },
  {
    id: 572,
    name: 'app-organic-download-percent-change-2y',
    displayName: 'App Free Daily Download % Change (2y)',
    description: 'App Free Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 23,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:16:27.673Z',
    updatedAt: '2023-01-05T20:16:27.673Z',
  },
  {
    id: 571,
    name: 'app-organic-download-percent-change-1y',
    displayName: 'App Free Daily Download % Change (1y)',
    description: 'App Free Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 22,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:16:19.833Z',
    updatedAt: '2023-01-05T20:16:19.833Z',
  },
  {
    id: 570,
    name: 'app-organic-download-percent-change-6m',
    displayName: 'App Free Daily Download % Change (6m)',
    description: 'App Free Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 21,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:16:11.740Z',
    updatedAt: '2023-01-05T20:16:11.740Z',
  },
  {
    id: 569,
    name: 'app-organic-download-percent-change-3m',
    displayName: 'App Free Daily Download % Change (3m)',
    description: 'App Free Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 20,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:15:59.813Z',
    updatedAt: '2023-01-05T20:15:59.813Z',
  },
  {
    id: 568,
    name: 'app-organic-download-percent-change-1m',
    displayName: 'App Free Daily Download % Change (1m)',
    description: 'App Free Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 19,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:15:53.045Z',
    updatedAt: '2023-01-05T20:15:53.045Z',
  },
  {
    id: 567,
    name: 'app-download-percent-change-2y',
    displayName: 'App Daily Download % Change (2y)',
    description: 'App Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 18,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:12:39.169Z',
    updatedAt: '2023-01-05T20:12:39.169Z',
  },
  {
    id: 566,
    name: 'app-download-percent-change-1y',
    displayName: 'App Daily Download % Change (1y)',
    description: 'App Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 17,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:12:31.725Z',
    updatedAt: '2023-01-05T20:12:31.725Z',
  },
  {
    id: 565,
    name: 'app-download-percent-change-6m',
    displayName: 'App Daily Download % Change (6m)',
    description: 'App Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 16,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:12:24.389Z',
    updatedAt: '2023-01-05T20:12:24.389Z',
  },
  {
    id: 564,
    name: 'app-download-percent-change-3m',
    displayName: 'App Daily Download % Change (3m)',
    description: 'App Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 15,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:12:16.424Z',
    updatedAt: '2023-01-05T20:12:16.424Z',
  },
  {
    id: 563,
    name: 'app-download-percent-change-1m',
    displayName: 'App Daily Download % Change (1m)',
    description: 'App Daily Download % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 14,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T20:12:11.249Z',
    updatedAt: '2023-01-05T20:12:11.249Z',
  },
  {
    id: 562,
    name: 'app-time-per-active-user-percent-change-2y',
    displayName: 'Time Per Active User % Change (2y)',
    description: 'Time Per Active User % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 30,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:59:01.189Z',
    updatedAt: '2023-01-05T19:59:01.189Z',
  },
  {
    id: 561,
    name: 'app-time-per-active-user-percent-change-1y',
    displayName: 'Time Per Active User % Change (1y)',
    description: 'Time Per Active User % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 29,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:58:51.425Z',
    updatedAt: '2023-01-05T19:58:51.425Z',
  },
  {
    id: 560,
    name: 'app-time-per-active-user-percent-change-6m',
    displayName: 'Time Per Active User % Change (6m)',
    description: 'Time Per Active User % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 28,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:58:43.605Z',
    updatedAt: '2023-01-05T19:58:43.605Z',
  },
  {
    id: 559,
    name: 'app-time-per-active-user-percent-change-3m',
    displayName: 'Time Per Active User % Change (3m)',
    description: 'Time Per Active User % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 27,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:58:34.737Z',
    updatedAt: '2023-01-05T19:58:34.737Z',
  },
  {
    id: 558,
    name: 'app-time-per-active-user-percent-change-1m',
    displayName: 'Time Per Active User % Change (1m)',
    description: 'Time Per Active User % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 26,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:58:28.013Z',
    updatedAt: '2023-01-05T19:58:28.013Z',
  },
  {
    id: 557,
    name: 'app-time-usage-percent-change-2y',
    displayName: 'Time Usage (mins) % Change (2y)',
    description: 'Time Usage (mins) % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 25,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:54:01.173Z',
    updatedAt: '2023-01-05T19:54:01.173Z',
  },
  {
    id: 556,
    name: 'app-time-usage-percent-change-1y',
    displayName: 'Time Usage (mins) % Change (1y)',
    description: 'Time Usage (mins) % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 24,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:53:51.733Z',
    updatedAt: '2023-01-05T19:53:51.733Z',
  },
  {
    id: 555,
    name: 'app-time-usage-percent-change-6m',
    displayName: 'Time Usage (mins) % Change (6m)',
    description: 'Time Usage (mins) % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 23,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:53:43.769Z',
    updatedAt: '2023-01-05T19:53:43.769Z',
  },
  {
    id: 554,
    name: 'app-time-usage-percent-change-3m',
    displayName: 'Time Usage (mins) % Change (3m)',
    description: 'Time Usage (mins) % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 22,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:53:35.709Z',
    updatedAt: '2023-01-05T19:53:35.709Z',
  },
  {
    id: 553,
    name: 'app-time-usage-percent-change-1m',
    displayName: 'Time Usage (mins) % Change (1m)',
    description: 'Time Usage (mins) % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 21,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:53:25.485Z',
    updatedAt: '2023-01-05T19:53:25.485Z',
  },
  {
    id: 552,
    name: 'app-active-users-percent-change-1m',
    displayName: 'Active Users % Change (1m)',
    description: 'Active Users % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:44:17.833Z',
    updatedAt: '2023-01-05T19:44:17.833Z',
  },
  {
    id: 551,
    name: 'app-active-users-percent-change-3m',
    displayName: 'Active Users % Change (3m)',
    description: 'Active Users % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 17,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:44:10.267Z',
    updatedAt: '2023-01-05T19:44:10.267Z',
  },
  {
    id: 550,
    name: 'app-active-users-percent-change-6m',
    displayName: 'Active Users % Change (6m)',
    description: 'Active Users % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 18,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:44:01.537Z',
    updatedAt: '2023-01-05T19:44:01.537Z',
  },
  {
    id: 549,
    name: 'app-active-users-percent-change-1y',
    displayName: 'Active Users % Change (1y)',
    description: 'Active Users % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 19,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:43:53.520Z',
    updatedAt: '2023-01-05T19:43:53.520Z',
  },
  {
    id: 548,
    name: 'app-active-users-percent-change-2y',
    displayName: 'Active Users % Change (2y)',
    description: 'Active Users % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 20,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2023-01-05T19:43:47.775Z',
    updatedAt: '2023-01-05T19:43:47.775Z',
  },
  {
    id: 547,
    name: 'transactions-percentage-change-2y',
    displayName: 'Transactions Count % Change (2y)',
    description: 'Transactions Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 45,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:50:16.687Z',
    updatedAt: '2022-12-09T14:50:16.687Z',
  },
  {
    id: 546,
    name: 'transactions-percentage-change-1y',
    displayName: 'Transactions Count % Change (1y)',
    description: 'Transactions Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 44,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:50:10.369Z',
    updatedAt: '2022-12-09T14:50:10.369Z',
  },
  {
    id: 545,
    name: 'transactions-percentage-change-6m',
    displayName: 'Transactions Count % Change (6m)',
    description: 'Transactions Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 43,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:50:04.016Z',
    updatedAt: '2022-12-09T14:50:04.016Z',
  },
  {
    id: 544,
    name: 'transactions-percentage-change-3m',
    displayName: 'Transactions Count % Change (3m)',
    description: 'Transactions Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 42,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:49:56.864Z',
    updatedAt: '2022-12-09T14:49:56.864Z',
  },
  {
    id: 543,
    name: 'transactions-percentage-change-1m',
    displayName: 'Transactions Count % Change (1m)',
    description: 'Transactions Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 41,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:49:49.319Z',
    updatedAt: '2022-12-09T14:49:49.319Z',
  },
  {
    id: 542,
    name: 'customer-count-percentage-change-2y',
    displayName: 'Customer Count % Change (2y)',
    description: 'Customer Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 36,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:46:54.071Z',
    updatedAt: '2022-12-09T14:46:54.071Z',
  },
  {
    id: 541,
    name: 'customer-count-percentage-change-1y',
    displayName: 'Customer Count % Change (1y)',
    description: 'Customer Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 35,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:46:47.590Z',
    updatedAt: '2022-12-09T14:46:47.590Z',
  },
  {
    id: 540,
    name: 'customer-count-percentage-change-6m',
    displayName: 'Customer Count % Change (6m)',
    description: 'Customer Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 34,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:46:39.059Z',
    updatedAt: '2022-12-09T14:46:39.059Z',
  },
  {
    id: 539,
    name: 'customer-count-percentage-change-3m',
    displayName: 'Customer Count % Change (3m)',
    description: 'Customer Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 33,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:46:31.344Z',
    updatedAt: '2022-12-09T14:46:31.344Z',
  },
  {
    id: 538,
    name: 'customer-count-percentage-change-1m',
    displayName: 'Customer Count % Change (1m)',
    description: 'Customer Count % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 32,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:46:24.620Z',
    updatedAt: '2022-12-09T14:46:24.620Z',
  },
  {
    id: 537,
    name: 'sales-percentage-change-2y',
    displayName: 'Sales % Change (2y)',
    description: 'Sales % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 27,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:42:30.707Z',
    updatedAt: '2022-12-09T14:42:30.707Z',
  },
  {
    id: 536,
    name: 'sales-percentage-change-1y',
    displayName: 'Sales % Change (1y)',
    description: 'Sales % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 26,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:42:23.505Z',
    updatedAt: '2022-12-09T14:42:23.505Z',
  },
  {
    id: 535,
    name: 'sales-percentage-change-6m',
    displayName: 'Sales % Change (6m)',
    description: 'Sales % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 25,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:42:17.479Z',
    updatedAt: '2022-12-09T14:42:17.479Z',
  },
  {
    id: 534,
    name: 'sales-percentage-change-3m',
    displayName: 'Sales % Change (3m)',
    description: 'Sales % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 24,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:42:10.367Z',
    updatedAt: '2022-12-09T14:42:10.367Z',
  },
  {
    id: 533,
    name: 'sales-percentage-change-1m',
    displayName: 'Sales % Change (1m)',
    description: 'Sales % Change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 23,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:42:03.005Z',
    updatedAt: '2022-12-09T14:42:03.005Z',
  },
  {
    id: 532,
    name: 'average-transactions-per-customer-6m',
    displayName: 'Average Daily Transactions per Customer (6m)',
    description: 'Average Daily Transactions per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 53,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:36:24.019Z',
    updatedAt: '2022-12-09T14:36:24.019Z',
  },
  {
    id: 531,
    name: 'average-transactions-per-customer-3m',
    displayName: 'Average Daily Transactions per Customer (3m)',
    description: 'Average Daily Transactions per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 52,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:36:17.911Z',
    updatedAt: '2022-12-09T14:36:17.911Z',
  },
  {
    id: 530,
    name: 'average-transactions-per-customer-1m',
    displayName: 'Average Daily Transactions per Customer (1m)',
    description: 'Average Daily Transactions per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 51,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:36:10.510Z',
    updatedAt: '2022-12-09T14:36:10.510Z',
  },
  {
    id: 529,
    name: 'average-transactions-per-customer',
    displayName: 'Average Daily Transactions per Customer',
    description: 'Average Daily Transactions per Customer',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 50,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:36:03.331Z',
    updatedAt: '2022-12-09T14:36:03.331Z',
  },
  {
    id: 528,
    name: 'average-transaction-value-6m',
    displayName: 'Average Daily Transaction Value (6m)',
    description: 'Average Daily Transaction Value',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 57,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:31:11.577Z',
    updatedAt: '2022-12-09T14:31:11.577Z',
  },
  {
    id: 527,
    name: 'average-transaction-value-3m',
    displayName: 'Average Daily Transaction Value (3m)',
    description: 'Average Daily Transaction Value',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 56,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:31:01.441Z',
    updatedAt: '2022-12-09T14:31:01.441Z',
  },
  {
    id: 526,
    name: 'average-transaction-value-1m',
    displayName: 'Average Daily Transaction Value (1m)',
    description: 'Average Daily Transaction Value',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 55,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:30:50.708Z',
    updatedAt: '2022-12-09T14:30:50.708Z',
  },
  {
    id: 525,
    name: 'average-sales-per-customer-6m',
    displayName: 'Average Daily Sales per Customer (6m)',
    description: 'Average Daily Sales per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 49,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:27:01.861Z',
    updatedAt: '2022-12-09T14:27:01.861Z',
  },
  {
    id: 524,
    name: 'average-sales-per-customer-3m',
    displayName: 'Average Daily Sales per Customer (3m)',
    description: 'Average Daily Sales per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 48,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:26:54.502Z',
    updatedAt: '2022-12-09T14:26:54.502Z',
  },
  {
    id: 523,
    name: 'average-sales-per-customer-1m',
    displayName: 'Average Daily Sales per Customer (1m)',
    description: 'Average Daily Sales per Customer',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 47,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:26:46.244Z',
    updatedAt: '2022-12-09T14:26:46.244Z',
  },
  {
    id: 522,
    name: 'average-sales-per-customer',
    displayName: 'Average Daily Sales per Customer',
    description: 'Average Daily Sales per Customer',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 46,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:26:40.173Z',
    updatedAt: '2022-12-09T14:26:40.173Z',
  },
  {
    id: 521,
    name: 'average-transactions-6m',
    displayName: 'Average Daily Transactions (6m)',
    description: 'Average Daily Transactions',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 40,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:19:32.547Z',
    updatedAt: '2022-12-09T14:19:32.547Z',
  },
  {
    id: 520,
    name: 'average-transactions-3m',
    displayName: 'Average Daily Transactions (3m)',
    description: 'Average Daily Transactions',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 39,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:19:25.460Z',
    updatedAt: '2022-12-09T14:19:25.460Z',
  },
  {
    id: 519,
    name: 'average-transactions-1m',
    displayName: 'Average Daily Transactions (1m)',
    description: 'Average Daily Transactions',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 38,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:19:14.125Z',
    updatedAt: '2022-12-09T14:19:14.125Z',
  },
  {
    id: 518,
    name: 'average-transactions',
    displayName: 'Average Daily Transactions',
    description: 'Average Daily Transactions',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 37,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:19:02.114Z',
    updatedAt: '2022-12-09T14:19:02.114Z',
  },
  {
    id: 517,
    name: 'average-customers-6m',
    displayName: 'Average Daily Customers (6m)',
    description: 'Average Daily Customers',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 31,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:15:10.912Z',
    updatedAt: '2022-12-09T14:15:10.912Z',
  },
  {
    id: 516,
    name: 'average-customers-3m',
    displayName: 'Average Daily Customers (3m)',
    description: 'Average Daily Customers',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 30,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:15:01.779Z',
    updatedAt: '2022-12-09T14:15:01.779Z',
  },
  {
    id: 515,
    name: 'average-customers-1m',
    displayName: 'Average Daily Customers (1m)',
    description: 'Average Daily Customers',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 29,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:14:52.308Z',
    updatedAt: '2022-12-09T14:14:52.308Z',
  },
  {
    id: 514,
    name: 'average-customers',
    displayName: 'Average Daily Customers',
    description: 'Average Daily Customers',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 28,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:14:46.953Z',
    updatedAt: '2022-12-09T14:14:46.953Z',
  },
  {
    id: 513,
    name: 'average-sales-6m',
    displayName: 'Average Daily Sales (6m)',
    description: 'Average Daily Sales',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 22,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:07:35.751Z',
    updatedAt: '2022-12-09T14:11:47.484Z',
  },
  {
    id: 512,
    name: 'average-sales-3m',
    displayName: 'Average Daily Sales (3m)',
    description: 'Average Daily Sales',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 21,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:07:26.254Z',
    updatedAt: '2022-12-09T14:11:33.041Z',
  },
  {
    id: 511,
    name: 'average-sales-1m',
    displayName: 'Average Daily Sales (1m)',
    description: 'Average Daily Sales',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 20,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:07:15.537Z',
    updatedAt: '2022-12-09T14:11:28.300Z',
  },
  {
    id: 510,
    name: 'average-sales',
    displayName: 'Average Daily Sales',
    description: 'Average Daily Sales',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 19,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-09T14:07:12.280Z',
    updatedAt: '2022-12-09T14:07:12.280Z',
  },
  {
    id: 509,
    name: 'employee-outflow-percentage-change-2y',
    displayName: 'Employee Outflow % Change (2y)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 22,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:06:06.195Z',
    updatedAt: '2022-12-02T20:49:20.619Z',
  },
  {
    id: 508,
    name: 'employee-outflow-percentage-change-1y',
    displayName: 'Employee Outflow % Change (1y)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 21,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:05:47.630Z',
    updatedAt: '2022-12-02T20:49:24.821Z',
  },
  {
    id: 507,
    name: 'employee-outflow-percentage-change-6m',
    displayName: 'Employee Outflow % Change (6m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 20,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:05:41.691Z',
    updatedAt: '2022-12-02T20:49:27.498Z',
  },
  {
    id: 506,
    name: 'employee-outflow-percentage-change-3m',
    displayName: 'Employee Outflow % Change (3m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 19,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:05:22.927Z',
    updatedAt: '2022-12-02T20:49:30.248Z',
  },
  {
    id: 505,
    name: 'employee-outflow-percentage-change-1m',
    displayName: 'Employee Outflow % Change (1m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 18,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:05:17.676Z',
    updatedAt: '2022-12-02T20:49:32.784Z',
  },
  {
    id: 504,
    name: 'employee-inflow-percentage-change-2y',
    displayName: 'Employee Inflow % Change (2y)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 17,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:03:22.656Z',
    updatedAt: '2022-12-02T20:49:35.214Z',
  },
  {
    id: 503,
    name: 'employee-inflow-percentage-change-1y',
    displayName: 'Employee Inflow % Change (1y)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 16,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:03:04.830Z',
    updatedAt: '2022-12-02T20:49:37.990Z',
  },
  {
    id: 502,
    name: 'employee-inflow-percentage-change-6m',
    displayName: 'Employee Inflow % Change (6m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 15,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:02:58.121Z',
    updatedAt: '2022-12-02T20:49:40.462Z',
  },
  {
    id: 501,
    name: 'employee-inflow-percentage-change-3m',
    displayName: 'Employee Inflow % Change (3m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 14,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:02:24.153Z',
    updatedAt: '2022-12-02T20:49:42.977Z',
  },
  {
    id: 500,
    name: 'employee-inflow-percentage-change-1m',
    displayName: 'Employee Inflow % Change (1m)',
    description: 'Percentage of Employee Flow',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 13,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T23:02:09.878Z',
    updatedAt: '2022-12-02T20:49:45.444Z',
  },
  {
    id: 499,
    name: 'employee-outflow-2y',
    displayName: 'Employee Outflow (2y)',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 12,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:58:59.443Z',
    updatedAt: '2022-12-02T20:49:49.270Z',
  },
  {
    id: 498,
    name: 'employee-inflow-2y',
    displayName: 'Employee Inflow (2y)',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:58:25.830Z',
    updatedAt: '2022-12-02T20:49:57.539Z',
  },
  {
    id: 497,
    name: 'active-postings-percent-change-2y',
    displayName: 'Active Postings % Change (2y)',
    description: 'Number of active postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 42,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:52:26.836Z',
    updatedAt: '2022-12-01T22:52:26.836Z',
  },
  {
    id: 496,
    name: 'new-postings-percent-change-2y',
    displayName: 'New Postings % Change (2y)',
    description: 'Number of new postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 41,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:51:21.587Z',
    updatedAt: '2022-12-01T22:51:21.587Z',
  },
  {
    id: 495,
    name: 'removed-postings-percent-change-2y',
    displayName: 'Removed Postings % Change (2y)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 40,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:49:42.084Z',
    updatedAt: '2022-12-01T22:49:42.084Z',
  },
  {
    id: 494,
    name: 'employee-count-percentage-change-2y',
    displayName: 'Employee Count % Change (2y)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 17,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-12-01T22:47:55.301Z',
    updatedAt: '2022-12-01T22:47:55.301Z',
  },
  {
    id: 493,
    name: 'retention-percentage-6m',
    displayName: 'Retention % (6m)',
    description: 'Retention Percentage',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:40:57.262Z',
    updatedAt: '2022-11-30T22:40:57.262Z',
  },
  {
    id: 492,
    name: 'retention-percentage-3m',
    displayName: 'Retention % (3m)',
    description: 'Retention Percentage',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 6,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:40:35.990Z',
    updatedAt: '2022-11-30T22:40:35.990Z',
  },
  {
    id: 491,
    name: 'retention-percentage',
    displayName: 'Retention %',
    description: 'Retention Percentage',
    type: 'number',
    formatting: 'percent',
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:40:25.512Z',
    updatedAt: '2022-11-30T22:40:25.512Z',
  },
  {
    id: 490,
    name: 'total-time-cumulative',
    displayName: 'Total Count',
    description: 'Total Count of Ratings',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Store Ratings',
    groupOrder: 5,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:34:50.430Z',
    updatedAt: '2022-11-30T22:34:50.430Z',
  },
  {
    id: 489,
    name: 'critical-percentage-cumulative',
    displayName: 'Critical Percentage',
    description: 'Critical Percentage',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Store Ratings',
    groupOrder: 4,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:34:29.150Z',
    updatedAt: '2022-11-30T22:42:59.639Z',
  },
  {
    id: 488,
    name: 'neutral-percentage-cumulative',
    displayName: 'Neutral Percentage',
    description: 'Neutral Percentage',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Store Ratings',
    groupOrder: 3,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:34:22.848Z',
    updatedAt: '2022-11-30T22:42:56.010Z',
  },
  {
    id: 487,
    name: 'favorable-percentage-cumulative',
    displayName: 'Favorable Percentage',
    description: 'Favorable Percentage',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Store Ratings',
    groupOrder: 2,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:33:57.362Z',
    updatedAt: '2022-11-30T22:42:52.193Z',
  },
  {
    id: 486,
    name: 'average-star-cumulative',
    displayName: 'Average Star',
    description: 'Average star rating',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Store Ratings',
    groupOrder: 1,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:33:53.145Z',
    updatedAt: '2022-11-30T22:33:53.145Z',
  },
  {
    id: 485,
    name: 'app-retention-6m',
    displayName: 'Active User Retention (6m)',
    description: 'Active User Retention',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:24:11.626Z',
    updatedAt: '2022-11-30T22:24:11.626Z',
  },
  {
    id: 484,
    name: 'app-retention-3m',
    displayName: 'Active User Retention (3m)',
    description: 'Active User Retention',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:24:06.263Z',
    updatedAt: '2022-11-30T22:24:06.263Z',
  },
  {
    id: 483,
    name: 'app-retention-1m',
    displayName: 'Active User Retention (1m)',
    description: 'Active User Retention',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Retention',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:23:39.320Z',
    updatedAt: '2022-11-30T22:23:39.320Z',
  },
  {
    id: 482,
    name: 'app-retention',
    displayName: 'Active User Retention',
    description: 'Active User Retention',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Retention',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:23:34.535Z',
    updatedAt: '2022-11-30T22:23:34.535Z',
  },
  {
    id: 481,
    name: 'app-active-user-growth-6m',
    displayName: 'Active User Growth (6m)',
    description: 'Active User Growth',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:10:27.040Z',
    updatedAt: '2022-11-30T22:20:35.847Z',
  },
  {
    id: 480,
    name: 'app-active-user-growth-3m',
    displayName: 'Active User Growth (3m)',
    description: 'Active User Growth',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 14,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:09:53.463Z',
    updatedAt: '2022-11-30T22:20:32.605Z',
  },
  {
    id: 479,
    name: 'app-active-user-growth',
    displayName: 'Active User Growth',
    description: 'Active User Growth',
    type: 'number',
    formatting: 'percent',
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Usage',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-30T22:09:31.096Z',
    updatedAt: '2022-11-30T22:09:31.096Z',
  },
  {
    id: 478,
    name: 'activity-negative-1y',
    displayName: 'Negative Activity (1y)',
    description: 'Negative activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-15T19:45:09.914Z',
    updatedAt: '2022-11-15T19:45:09.914Z',
  },
  {
    id: 477,
    name: 'annual-recurring-cost',
    displayName: 'Annual Recurring Cost',
    description: 'Survey of annual recurring cost from reviewers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 16,
    groupPriority: 5,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'emiliano.mongelo@greycroft.com',
    createdAt: '2022-09-16T18:22:36.757Z',
    updatedAt: '2022-11-12T16:03:30.658Z',
  },
  {
    id: 476,
    name: 'facebook-likes-percentage-change-1y',
    displayName: 'Facebook Likes % Change (1y)',
    description: 'Percentage change in number of Facebook likes',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 29,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:34:54.456Z',
    updatedAt: '2022-09-16T14:34:54.456Z',
  },
  {
    id: 475,
    name: 'facebook-likes-change-1y',
    displayName: 'Facebook Likes Change (1y)',
    description: 'Change in number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 28,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:34:48.599Z',
    updatedAt: '2022-09-16T14:34:48.599Z',
  },
  {
    id: 474,
    name: 'facebook-likes-1y',
    displayName: 'Facebook Likes (1y)',
    description: 'Count of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 27,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:34:41.461Z',
    updatedAt: '2022-09-16T14:34:41.461Z',
  },
  {
    id: 473,
    name: 'facebook-likes-percentage-change-6m',
    displayName: 'Facebook Likes % Change (6m)',
    description: 'Percentage change in number of Facebook likes',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 26,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:33:40.585Z',
    updatedAt: '2022-09-16T14:33:40.585Z',
  },
  {
    id: 472,
    name: 'facebook-likes-change-6m',
    displayName: 'Facebook Likes Change (6m)',
    description: 'Change in number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 25,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:33:33.132Z',
    updatedAt: '2022-09-16T14:33:33.132Z',
  },
  {
    id: 471,
    name: 'facebook-likes-6m',
    displayName: 'Facebook Likes (6m)',
    description: 'Count of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 24,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:33:26.641Z',
    updatedAt: '2022-09-16T14:33:26.641Z',
  },
  {
    id: 470,
    name: 'facebook-likes-percentage-change-3m',
    displayName: 'Facebook Likes % Change (3m)',
    description: 'Percentage change in number of Facebook likes',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 23,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:32:07.721Z',
    updatedAt: '2022-09-16T14:32:07.721Z',
  },
  {
    id: 469,
    name: 'facebook-likes-change-3m',
    displayName: 'Facebook Likes Change (3m)',
    description: 'Change in number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 22,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:32:02.223Z',
    updatedAt: '2022-09-16T14:32:02.223Z',
  },
  {
    id: 468,
    name: 'facebook-likes-3m',
    displayName: 'Facebook Likes (3m)',
    description: 'Count of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 21,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:31:56.403Z',
    updatedAt: '2022-09-16T14:31:56.403Z',
  },
  {
    id: 467,
    name: 'facebook-likes-percentage-change-1m',
    displayName: 'Facebook Likes % Change (1m)',
    description: 'Percentage change in number of Facebook likes',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 20,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:29:29.065Z',
    updatedAt: '2022-09-16T14:29:29.065Z',
  },
  {
    id: 466,
    name: 'facebook-likes-change-1m',
    displayName: 'Facebook Likes Change (1m)',
    description: 'Change in number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 19,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:29:23.982Z',
    updatedAt: '2022-09-16T14:29:23.982Z',
  },
  {
    id: 465,
    name: 'facebook-likes-1m',
    displayName: 'Facebook Likes (1m)',
    description: 'Count of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 18,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:29:16.767Z',
    updatedAt: '2022-09-16T14:29:16.767Z',
  },
  {
    id: 464,
    name: 'facebook-likes-percent-change-14d',
    displayName: 'Facebook Likes % Change (14d)',
    description: 'Percentage change in number of Facebook  likes',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 17,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:24:52.253Z',
    updatedAt: '2022-09-16T14:24:52.253Z',
  },
  {
    id: 463,
    name: 'facebook-likes-change-14d',
    displayName: 'Facebook Likes Change (14d)',
    description: 'Change in number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 16,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:24:39.160Z',
    updatedAt: '2022-09-16T14:24:39.160Z',
  },
  {
    id: 462,
    name: 'facebook-likes-14d',
    displayName: 'Facebook Likes (14d)',
    description: 'Count of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 15,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-09-16T14:16:58.355Z',
    updatedAt: '2022-09-16T14:16:58.355Z',
  },
  {
    id: 461,
    name: 'facebook-likes',
    displayName: 'Facebook Likes',
    description: 'Number of Facebook likes',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Facebook',
    groupOrder: 14,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'emiliano.mongelo@greycroft.com',
    createdAt: '2022-09-15T22:20:03.003Z',
    updatedAt: '2022-09-16T12:57:37.254Z',
  },
  {
    id: 460,
    name: 'employee-count-percentage-change-6m',
    displayName: 'Employee Count % Change (6m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:23:02.025Z',
    updatedAt: '2022-08-17T15:23:02.025Z',
  },
  {
    id: 459,
    name: 'employee-count-percentage-change-6m',
    displayName: 'Employee Count % Change (6m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:22:57.447Z',
    updatedAt: '2022-08-17T15:22:57.447Z',
  },
  {
    id: 458,
    name: 'employee-count-percentage-change-3m',
    displayName: 'Employee Count % Change (3m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:22:31.355Z',
    updatedAt: '2022-08-17T15:22:31.355Z',
  },
  {
    id: 457,
    name: 'employee-count-percentage-change-3m',
    displayName: 'Employee Count % Change (3m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:22:26.180Z',
    updatedAt: '2022-08-17T15:22:26.180Z',
  },
  {
    id: 456,
    name: 'employee-count-percentage-change-1y',
    displayName: 'Employee Count % Change (1y)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:22:03.816Z',
    updatedAt: '2022-08-17T15:22:03.816Z',
  },
  {
    id: 455,
    name: 'employee-count-percentage-change-1y',
    displayName: 'Employee Count % Change (1y)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:21:59.279Z',
    updatedAt: '2022-08-17T15:21:59.279Z',
  },
  {
    id: 454,
    name: 'employee-count-percentage-change-1m',
    displayName: 'Employee Count % Change (1m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:21:36.716Z',
    updatedAt: '2022-08-17T15:21:36.716Z',
  },
  {
    id: 453,
    name: 'employee-count-percentage-change-1m',
    displayName: 'Employee Count % Change (1m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:21:31.476Z',
    updatedAt: '2022-08-17T15:21:31.476Z',
  },
  {
    id: 452,
    name: 'employee-count-change-6m',
    displayName: 'Employee Count Change (6m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:20:56.997Z',
    updatedAt: '2022-08-17T15:20:56.997Z',
  },
  {
    id: 451,
    name: 'employee-count-change-6m',
    displayName: 'Employee Count Change (6m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:20:51.258Z',
    updatedAt: '2022-08-17T15:20:51.258Z',
  },
  {
    id: 450,
    name: 'employee-count-change-3m',
    displayName: 'Employee Count Change (3m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 9,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:19:08.453Z',
    updatedAt: '2022-08-17T15:19:08.453Z',
  },
  {
    id: 449,
    name: 'employee-count-change-3m',
    displayName: 'Employee Count Change (3m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 9,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:19:03.594Z',
    updatedAt: '2022-08-17T15:19:03.594Z',
  },
  {
    id: 448,
    name: 'employee-count-change-1y',
    displayName: 'Employee Count Change (1y)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:18:42.762Z',
    updatedAt: '2022-08-17T15:18:42.762Z',
  },
  {
    id: 447,
    name: 'employee-count-change-1y',
    displayName: 'Employee Count Change (1y)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:18:37.214Z',
    updatedAt: '2022-08-17T15:18:37.214Z',
  },
  {
    id: 446,
    name: 'employee-count-change-1m',
    displayName: 'Employee Count Change (1m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:16:22.681Z',
    updatedAt: '2022-08-17T15:16:22.681Z',
  },
  {
    id: 445,
    name: 'employee-count-change-1m',
    displayName: 'Employee Count Change (1m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-17T15:16:16.658Z',
    updatedAt: '2022-08-17T15:16:16.658Z',
  },
  {
    id: 444,
    name: 'new-postings-change-1y',
    displayName: 'New Postings Change (1y)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 25,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-16T12:34:18.818Z',
    updatedAt: '2022-11-11T17:02:20.055Z',
  },
  {
    id: 443,
    name: 'employee-count',
    displayName: 'Employee Count',
    description: 'Number of Employees',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio,harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-12T16:11:00.651Z',
    updatedAt: '2022-11-11T22:52:57.076Z',
  },
  {
    id: 442,
    name: 'time-on-page',
    displayName: 'Time on G2 Page (secs)',
    description: 'Time on G2 Page (secs)',
    type: 'number',
    formatting: null,
    series: true,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 19,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.469Z',
    updatedAt: '2022-11-11T16:21:46.682Z',
  },
  {
    id: 441,
    name: 'unique-page-views',
    displayName: 'Unique G2 Page Views',
    description: 'Distinct count of G2 page views',
    type: 'number',
    formatting: null,
    series: true,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 18,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.468Z',
    updatedAt: '2022-11-11T16:21:16.601Z',
  },
  {
    id: 440,
    name: 'page-views',
    displayName: 'G2 Page Views',
    description: "Total count of product's G2 page views",
    type: 'number',
    formatting: null,
    series: true,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 17,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.465Z',
    updatedAt: '2022-11-11T16:20:24.176Z',
  },
  {
    id: 439,
    name: 'review-source',
    displayName: 'Review Source',
    description: 'Source of reviewers',
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 15,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.460Z',
    updatedAt: '2022-11-11T15:03:49.396Z',
  },
  {
    id: 438,
    name: 'current-users',
    displayName: 'Current Users',
    description: 'Yes/No confirmation of product usage by reviewers',
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 14,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.448Z',
    updatedAt: '2022-11-11T16:19:24.335Z',
  },
  {
    id: 437,
    name: 'role',
    displayName: "Reviewer's Role",
    description: "Role of Reviewer's",
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 13,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.448Z',
    updatedAt: '2022-11-11T17:32:07.472Z',
  },
  {
    id: 436,
    name: 'company-employee-size',
    displayName: 'Company Size',
    description: 'Company size of reviewer',
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 12,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.445Z',
    updatedAt: '2022-11-11T15:03:41.933Z',
  },
  {
    id: 435,
    name: 'ease-of-doing-business-with',
    displayName: 'Ease of Doing Business',
    description: 'Ease of doing business with company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 8,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.444Z',
    updatedAt: '2022-11-11T15:03:38.961Z',
  },
  {
    id: 434,
    name: 'quality-of-support',
    displayName: 'Quality of support',
    description: '-',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 5,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.442Z',
    updatedAt: '2022-11-11T15:03:36.260Z',
  },
  {
    id: 433,
    name: 'ease-of-use',
    displayName: 'Ease of Use',
    description: 'Ease of use of product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 6,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.441Z',
    updatedAt: '2022-11-11T15:03:33.887Z',
  },
  {
    id: 432,
    name: 'ease-of-admin',
    displayName: 'Ease of Admin',
    description: 'Ease of administration of Product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 9,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.426Z',
    updatedAt: '2022-11-11T15:03:31.330Z',
  },
  {
    id: 431,
    name: 'likely-to-recommend',
    displayName: 'Likely to Recommend',
    description: 'Likely to recommend',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 10,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.424Z',
    updatedAt: '2022-11-11T15:03:28.898Z',
  },
  {
    id: 430,
    name: 'ease-of-setup',
    displayName: 'Ease of Setup',
    description: 'Ease of setup of product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 7,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.422Z',
    updatedAt: '2022-11-11T15:03:26.207Z',
  },
  {
    id: 429,
    name: 'price',
    displayName: 'Price',
    description: 'Product cost',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 4,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.421Z',
    updatedAt: '2022-11-11T15:03:23.251Z',
  },
  {
    id: 428,
    name: 'company-segment',
    displayName: 'Company Segment',
    description: 'Company segment of reviewer',
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 11,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.409Z',
    updatedAt: '2022-11-11T15:03:20.523Z',
  },
  {
    id: 427,
    name: 'review-count',
    displayName: 'Review Count',
    description: 'Distinct number of reviewers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 2,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.406Z',
    updatedAt: '2022-11-11T15:03:17.852Z',
  },
  {
    id: 426,
    name: 'g2-rating',
    displayName: 'G2 Rating',
    description: 'G2 ratings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 3,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.402Z',
    updatedAt: '2022-11-11T15:03:15.215Z',
  },
  {
    id: 425,
    name: 'product-name',
    displayName: 'Product Name',
    description: 'Products name(s) for company',
    type: 'array',
    formatting: null,
    series: false,
    source: 'g2',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'G2 Metrics & Ratings',
    groupOrder: 1,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-10T12:45:13.364Z',
    updatedAt: '2022-11-11T15:03:12.485Z',
  },
  {
    id: 423,
    name: 'repeat-ceo',
    displayName: 'Repeat CEO',
    description: 'Yes/No entry to verify if contact is a repeat CEO',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'cbinsights,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-08-03T18:25:32.154Z',
    updatedAt: '2022-08-12T15:06:35.805Z',
  },
  {
    id: 422,
    name: 'ceo-approval',
    displayName: 'CEO Approval',
    description: "Company's CEO Approval",
    type: 'number',
    formatting: 'percentFromDecimal',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 11,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-31T19:22:47.039Z',
    updatedAt: '2022-08-12T14:53:27.649Z',
  },
  {
    id: 421,
    name: 'employee-inflow-1y',
    displayName: 'Employee Inflow (1y)',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:59:10.374Z',
    updatedAt: '2022-12-02T20:50:23.923Z',
  },
  {
    id: 420,
    name: 'employee-outflow-1y',
    displayName: 'Employee Outflow (1y)',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:55:36.381Z',
    updatedAt: '2022-12-02T20:50:26.496Z',
  },
  {
    id: 419,
    name: 'employee-outflow-6m',
    displayName: 'Employee Outflow (6m)',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 9,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:55:22.825Z',
    updatedAt: '2022-12-02T20:50:29.756Z',
  },
  {
    id: 418,
    name: 'employee-outflow-3m',
    displayName: 'Employee Outflow (3m)',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 8,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:55:11.489Z',
    updatedAt: '2022-12-02T20:50:32.650Z',
  },
  {
    id: 417,
    name: 'employee-outflow-1m',
    displayName: 'Employee Outflow (1m)',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 7,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:55:01.173Z',
    updatedAt: '2022-12-02T20:50:35.286Z',
  },
  {
    id: 416,
    name: 'employee-outflow',
    displayName: 'Employee Outflow',
    description: 'Number of employee outflow',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Job Flow',
    groupOrder: 18,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:54:46.756Z',
    updatedAt: '2022-12-02T20:55:06.433Z',
  },
  {
    id: 415,
    name: 'employee-inflow-6m',
    displayName: 'Employee Inflow (6m)',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:50:27.603Z',
    updatedAt: '2022-12-02T20:50:49.609Z',
  },
  {
    id: 414,
    name: 'employee-inflow-3m',
    displayName: 'Employee Inflow (3m)',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:50:18.702Z',
    updatedAt: '2022-12-02T20:50:51.828Z',
  },
  {
    id: 413,
    name: 'employee-inflow-1m',
    displayName: 'Employee Inflow (1m)',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Job Flow',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:50:08.975Z',
    updatedAt: '2022-12-02T20:50:54.477Z',
  },
  {
    id: 412,
    name: 'employee-inflow',
    displayName: 'Employee Inflow',
    description: 'Number of employee inflow',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Job Flow',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T02:49:54.427Z',
    updatedAt: '2022-12-02T20:50:57.179Z',
  },
  {
    id: 411,
    name: 'email',
    displayName: 'Email',
    description: 'Email contact of the company',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'cbinsights,crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:26:11.961Z',
    updatedAt: '2022-07-29T01:26:11.961Z',
  },
  {
    id: 410,
    name: 'email',
    displayName: 'Email',
    description: 'Email contact of the company',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:26:01.211Z',
    updatedAt: '2022-07-29T01:26:01.211Z',
  },
  {
    id: 409,
    name: 'email',
    displayName: 'Email',
    description: 'Email contact of the company',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:25:53.343Z',
    updatedAt: '2022-07-29T01:25:53.343Z',
  },
  {
    id: 408,
    name: 'state',
    displayName: 'State',
    description: 'State',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic,cbinsights,crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:18:26.128Z',
    updatedAt: '2022-07-29T01:18:26.128Z',
  },
  {
    id: 407,
    name: 'state',
    displayName: 'State',
    description: 'State',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:16:38.487Z',
    updatedAt: '2022-07-29T01:16:38.487Z',
  },
  {
    id: 406,
    name: 'founded-date',
    displayName: 'Founded',
    description: 'Year company was founded',
    type: 'date',
    formatting: 'YYYY',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:13:03.246Z',
    updatedAt: '2022-07-29T01:13:03.246Z',
  },
  {
    id: 405,
    name: 'founded',
    displayName: 'Founded',
    description: 'Year company was founded',
    type: 'date',
    formatting: 'YYYY',
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:12:49.279Z',
    updatedAt: '2022-07-29T12:53:40.333Z',
  },
  {
    id: 404,
    name: 'founded',
    displayName: 'Founded',
    description: 'Year company was founded',
    type: 'date',
    formatting: 'YYYY',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:12:38.046Z',
    updatedAt: '2022-07-29T12:53:46.035Z',
  },
  {
    id: 401,
    name: 'country',
    displayName: 'Country',
    description: 'Country',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic,cbinsights,crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:05:10.879Z',
    updatedAt: '2022-07-29T01:05:10.879Z',
  },
  {
    id: 400,
    name: 'country',
    displayName: 'Country',
    description: 'Country',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:04:46.781Z',
    updatedAt: '2022-07-29T01:04:46.781Z',
  },
  {
    id: 399,
    name: 'country',
    displayName: 'Country',
    description: 'Country',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T01:04:40.225Z',
    updatedAt: '2022-07-29T01:04:40.225Z',
  },
  {
    id: 398,
    name: 'city',
    displayName: 'City',
    description: 'City',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic,cbinsights,crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T00:58:00.476Z',
    updatedAt: '2022-07-29T00:58:00.476Z',
  },
  {
    id: 397,
    name: 'city',
    displayName: 'City',
    description: 'City',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T00:56:59.432Z',
    updatedAt: '2022-07-29T00:56:59.432Z',
  },
  {
    id: 396,
    name: 'city',
    displayName: 'City',
    description: 'City',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T00:56:53.564Z',
    updatedAt: '2022-07-29T00:56:53.564Z',
  },
  {
    id: 395,
    name: 'ceo-past-founded-companies',
    displayName: 'CEO Past Founded Companies',
    description: 'CEO past founded companies',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T00:47:12.406Z',
    updatedAt: '2022-11-11T19:44:22.188Z',
  },
  {
    id: 394,
    name: 'investor-org-name',
    displayName: 'Co-Investors',
    description: 'Co-Investors',
    type: 'array',
    formatting: null,
    series: false,
    source: 'diligentEquity',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-29T00:35:01.645Z',
    updatedAt: '2022-07-29T00:35:01.645Z',
  },
  {
    id: 393,
    name: 'social-media-links',
    displayName: 'Social Media Links',
    description: '-',
    type: 'array',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'emiliano.mongelo@greycroft.com',
    createdAt: '2022-07-28T13:06:55.098Z',
    updatedAt: '2022-07-28T13:06:55.098Z',
  },
  {
    id: 392,
    name: 'employee-count-1y',
    displayName: 'Employee Count (1y)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 14,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T05:15:37.604Z',
    updatedAt: '2022-07-28T05:15:37.604Z',
  },
  {
    id: 391,
    name: 'employee-count-6m',
    displayName: 'Employee Count (6m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T05:15:29.672Z',
    updatedAt: '2022-07-28T05:15:29.672Z',
  },
  {
    id: 390,
    name: 'employee-count-3m',
    displayName: 'Employee Count (3m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T05:15:11.551Z',
    updatedAt: '2022-07-28T05:15:11.551Z',
  },
  {
    id: 389,
    name: 'employee-count-1m',
    displayName: 'Employee Count (1m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T05:14:56.941Z',
    updatedAt: '2022-07-28T05:14:56.941Z',
  },
  {
    id: 388,
    name: 'ceo-company-linkedin-url',
    displayName: 'CEO Company LinkedIn',
    description: "LinkedIn url CEO's employer",
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 14,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:54:58.192Z',
    updatedAt: '2022-08-12T15:07:08.808Z',
  },
  {
    id: 387,
    name: 'ceo-company',
    displayName: 'CEO Company',
    description: 'CEO employer',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 13,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:52:59.714Z',
    updatedAt: '2022-08-12T15:07:20.795Z',
  },
  {
    id: 386,
    name: 'ceo-skills',
    displayName: 'CEO Skills (LinkedIn)',
    description: 'CEO skills from LinkedIn',
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 12,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:51:05.175Z',
    updatedAt: '2022-08-12T15:07:32.014Z',
  },
  {
    id: 385,
    name: 'ceo-work-location',
    displayName: 'CEO Work Location',
    description: 'Work location for CEO',
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 10,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:49:36.703Z',
    updatedAt: '2022-08-12T15:07:44.705Z',
  },
  {
    id: 384,
    name: 'ceo-field',
    displayName: 'CEO Degree Major',
    description: "Area of focus for CEO's degree",
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 9,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:36:25.321Z',
    updatedAt: '2022-08-12T15:07:55.413Z',
  },
  {
    id: 383,
    name: 'ceo-degree',
    displayName: 'CEO Degree Type',
    description: 'CEO degree type',
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 8,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:22:10.598Z',
    updatedAt: '2022-08-12T15:08:05.989Z',
  },
  {
    id: 382,
    name: 'ceo-school',
    displayName: 'CEO School',
    description: 'List of schools CEO attended',
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T04:08:25.284Z',
    updatedAt: '2022-08-12T15:08:22.098Z',
  },
  {
    id: 381,
    name: 'ceo',
    displayName: 'CEO Name',
    description: 'Company CEO',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-28T03:09:03.917Z',
    updatedAt: '2022-08-12T15:08:25.712Z',
  },
  {
    id: 380,
    name: 'transactions-per-customer-retail',
    displayName: 'Transactions per Customer (Retail)',
    description: 'Transactions per Customer (Retail)',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 9,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:10:01.858Z',
    updatedAt: '2022-11-11T15:51:18.519Z',
  },
  {
    id: 379,
    name: 'transactions-per-customer-online',
    displayName: 'Transactions per Customer (Online)',
    description: 'Transactions per Customer (Online)',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 8,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:09:23.815Z',
    updatedAt: '2022-11-11T15:51:51.657Z',
  },
  {
    id: 378,
    name: 'transactions-per-customer',
    displayName: 'Transactions per Customer',
    description: 'Transactions per Customer',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 7,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:08:19.915Z',
    updatedAt: '2022-11-11T15:51:55.540Z',
  },
  {
    id: 377,
    name: 'sales-per-customer-retail',
    displayName: 'Sales per Customer (Retail)',
    description: 'Sales per Customer (Retail)',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 3,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:06:38.080Z',
    updatedAt: '2022-11-11T15:51:59.321Z',
  },
  {
    id: 376,
    name: 'sales-per-customer-online',
    displayName: 'Sales per Customer (Online)',
    description: 'Sales per Customer (Online)',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 2,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:05:07.952Z',
    updatedAt: '2022-11-11T15:52:03.781Z',
  },
  {
    id: 375,
    name: 'sales-per-customer',
    displayName: 'Sales per Customer',
    description: 'Sales per Customer',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 1,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:03:42.815Z',
    updatedAt: '2022-11-11T15:52:08.015Z',
  },
  {
    id: 374,
    name: 'average-transaction-value-retail',
    displayName: 'Average Transaction Value (Retail)',
    description: 'Average transaction value (Retail)',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 6,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T06:01:30.377Z',
    updatedAt: '2022-11-11T15:52:12.020Z',
  },
  {
    id: 373,
    name: 'average-transaction-value-online',
    displayName: 'Average Transaction Value (Online)',
    description: 'Average transaction value (Online)',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 5,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:59:22.628Z',
    updatedAt: '2022-11-11T15:52:16.035Z',
  },
  {
    id: 372,
    name: 'average-transaction-value',
    displayName: 'Average Transaction Value',
    description: 'Average transaction value',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 4,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:58:25.188Z',
    updatedAt: '2022-11-11T15:52:19.848Z',
  },
  {
    id: 371,
    name: 'transactions',
    displayName: 'Transactions',
    description: 'Number of observed transactions',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:56:50.686Z',
    updatedAt: '2022-11-11T15:52:23.423Z',
  },
  {
    id: 370,
    name: 'retail-transactions',
    displayName: 'Retail Transactions',
    description: 'Number of retail transactions',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:55:41.225Z',
    updatedAt: '2022-11-11T15:52:27.121Z',
  },
  {
    id: 369,
    name: 'online-transactions',
    displayName: 'Online Transactions',
    description: 'Number of online transactions',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:54:43.546Z',
    updatedAt: '2022-11-11T15:52:52.374Z',
  },
  {
    id: 368,
    name: 'retail-customers',
    displayName: 'Retail Customer Count',
    description: 'Number of observed retail customers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:52:07.352Z',
    updatedAt: '2022-11-11T15:52:56.221Z',
  },
  {
    id: 367,
    name: 'online-customers',
    displayName: 'Online Customer Count',
    description: 'Number of observed online customers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:51:14.982Z',
    updatedAt: '2022-11-11T15:53:00.636Z',
  },
  {
    id: 366,
    name: 'customers',
    displayName: 'Customer Count',
    description: 'Number of observed customers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:50:21.564Z',
    updatedAt: '2022-11-11T15:53:35.797Z',
  },
  {
    id: 365,
    name: 'sales',
    displayName: 'Sales',
    description: 'Total Value of observed transactions',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:47:57.831Z',
    updatedAt: '2022-11-11T15:53:39.553Z',
  },
  {
    id: 364,
    name: 'retail-sales',
    displayName: 'Retail Sales',
    description: 'Total Value of observed retail transactions',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:39:52.160Z',
    updatedAt: '2022-11-11T15:53:43.426Z',
  },
  {
    id: 363,
    name: 'online-sales',
    displayName: 'Online Sales',
    description: 'Total Value of observed online transactions',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'secondMeasure',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'US Card Transactions',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:39:06.425Z',
    updatedAt: '2022-11-11T15:53:47.836Z',
  },
  {
    id: 362,
    name: 'removed-postings-percent-change-1y',
    displayName: 'Removed Postings % Change (1y)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 39,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:24:11.155Z',
    updatedAt: '2022-11-11T17:04:01.693Z',
  },
  {
    id: 361,
    name: 'removed-postings-change-1y',
    displayName: 'Removed Postings Change (1y)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 38,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:23:25.692Z',
    updatedAt: '2022-11-11T17:04:04.616Z',
  },
  {
    id: 360,
    name: 'removed-postings-1y',
    displayName: 'Removed Postings (1y)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 37,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:23:01.471Z',
    updatedAt: '2022-11-11T17:04:07.227Z',
  },
  {
    id: 359,
    name: 'removed-postings-percent-change-6m',
    displayName: 'Removed Postings % Change (6m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 36,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:21:27.834Z',
    updatedAt: '2022-11-11T17:04:10.068Z',
  },
  {
    id: 358,
    name: 'removed-postings-change-6m',
    displayName: 'Removed Postings Change (6m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 35,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:20:20.736Z',
    updatedAt: '2022-11-11T17:04:12.135Z',
  },
  {
    id: 357,
    name: 'removed-postings-6m',
    displayName: 'Removed Postings (6m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 34,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:19:46.641Z',
    updatedAt: '2022-11-11T17:04:14.329Z',
  },
  {
    id: 356,
    name: 'removed-postings-percent-change-3m',
    displayName: 'Removed Postings % Change (3m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 33,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:19:06.990Z',
    updatedAt: '2022-11-11T17:04:16.847Z',
  },
  {
    id: 355,
    name: 'removed-postings-change-3m',
    displayName: 'Removed Postings Change (3m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 32,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:18:05.038Z',
    updatedAt: '2022-11-11T17:04:19.300Z',
  },
  {
    id: 354,
    name: 'removed-postings-3m',
    displayName: 'Removed Postings (3m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 31,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:17:32.585Z',
    updatedAt: '2022-11-11T17:04:21.551Z',
  },
  {
    id: 353,
    name: 'removed-postings-percent-change-1m',
    displayName: 'Removed Postings % Change (1m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 30,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:14:07.460Z',
    updatedAt: '2022-11-11T17:04:24.005Z',
  },
  {
    id: 352,
    name: 'removed-postings-change-1m',
    displayName: 'Removed Postings Change (1m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 29,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:13:35.747Z',
    updatedAt: '2022-11-11T17:04:26.467Z',
  },
  {
    id: 351,
    name: 'removed-postings-1m',
    displayName: 'Removed Postings (1m)',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Removed Job Postings',
    groupOrder: 28,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:13:08.525Z',
    updatedAt: '2022-11-11T17:04:29.264Z',
  },
  {
    id: 350,
    name: 'removed-postings',
    displayName: 'Removed Postings',
    description: 'Number of removed postings',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Removed Job Postings',
    groupOrder: 27,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:12:45.089Z',
    updatedAt: '2022-11-11T17:04:37.432Z',
  },
  {
    id: 349,
    name: 'new-postings-percent-change-1y',
    displayName: 'New Postings % Change (1y)',
    description: 'Number of new postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 26,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:11:14.825Z',
    updatedAt: '2022-11-11T17:02:33.347Z',
  },
  {
    id: 348,
    name: 'new-postings-1y',
    displayName: 'New Postings (1y)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 24,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:10:24.672Z',
    updatedAt: '2022-11-11T17:02:51.650Z',
  },
  {
    id: 347,
    name: 'new-postings-percent-change-6m',
    displayName: 'New Postings % Change (6m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 23,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:09:42.462Z',
    updatedAt: '2022-11-11T17:03:03.362Z',
  },
  {
    id: 346,
    name: 'new-postings-change-6m',
    displayName: 'New Postings Change (6m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 22,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:08:55.815Z',
    updatedAt: '2022-11-11T17:03:06.482Z',
  },
  {
    id: 345,
    name: 'new-postings-6m',
    displayName: 'New Postings (6m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 21,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:08:24.334Z',
    updatedAt: '2022-11-11T17:03:08.966Z',
  },
  {
    id: 344,
    name: 'new-postings-percent-change-3m',
    displayName: 'New Postings % Change (3m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 20,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:06:51.248Z',
    updatedAt: '2022-11-11T17:03:11.104Z',
  },
  {
    id: 343,
    name: 'new-postings-change-3m',
    displayName: 'New Postings Change (3m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 19,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:05:44.932Z',
    updatedAt: '2022-11-11T17:03:14.286Z',
  },
  {
    id: 342,
    name: 'new-postings-3m',
    displayName: 'New Postings (3m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 18,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:04:21.921Z',
    updatedAt: '2022-11-11T17:03:16.792Z',
  },
  {
    id: 341,
    name: 'new-postings-percent-change-1m',
    displayName: 'New Postings % Change (1m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 17,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:02:14.001Z',
    updatedAt: '2022-11-11T17:03:19.062Z',
  },
  {
    id: 340,
    name: 'new-postings-change-1m',
    displayName: 'New Postings Change (1m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 16,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:01:21.623Z',
    updatedAt: '2022-11-11T17:03:21.402Z',
  },
  {
    id: 339,
    name: 'new-postings-1m',
    displayName: 'New Postings (1m)',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'New Job Postings',
    groupOrder: 15,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T05:00:39.259Z',
    updatedAt: '2022-11-11T17:03:24.283Z',
  },
  {
    id: 338,
    name: 'new-postings',
    displayName: 'New Postings',
    description: 'Number of new postings',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'New Job Postings',
    groupOrder: 14,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:59:52.915Z',
    updatedAt: '2022-11-11T17:03:26.876Z',
  },
  {
    id: 337,
    name: 'active-postings-percent-change-1y',
    displayName: 'Active Postings % Change (1y)',
    description: 'Number of active postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 13,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:58:15.123Z',
    updatedAt: '2022-11-11T17:00:40.222Z',
  },
  {
    id: 336,
    name: 'active-postings-change-1y',
    displayName: 'Active Postings Change (1y)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 12,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:57:40.153Z',
    updatedAt: '2022-11-11T17:00:56.647Z',
  },
  {
    id: 335,
    name: 'active-postings-1y',
    displayName: 'Active Postings (1y)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 11,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:57:02.188Z',
    updatedAt: '2022-11-11T17:00:59.719Z',
  },
  {
    id: 334,
    name: 'active-postings-percent-change-6m',
    displayName: 'Active Postings % Change (6m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 10,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:55:49.888Z',
    updatedAt: '2022-11-11T17:01:02.754Z',
  },
  {
    id: 333,
    name: 'active-postings-change-6m',
    displayName: 'Active Postings Change (6m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 9,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:54:19.498Z',
    updatedAt: '2022-11-11T17:01:05.683Z',
  },
  {
    id: 332,
    name: 'active-postings-6m',
    displayName: 'Active Postings (6m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 8,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:53:09.501Z',
    updatedAt: '2022-11-11T17:01:08.261Z',
  },
  {
    id: 331,
    name: 'active-postings-percent-change-3m',
    displayName: 'Active Postings % Change (3m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:51:08.916Z',
    updatedAt: '2022-11-11T17:01:10.763Z',
  },
  {
    id: 329,
    name: 'active-postings-change-3m',
    displayName: 'Active Postings Change (3m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 6,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:49:57.557Z',
    updatedAt: '2022-11-11T17:01:16.321Z',
  },
  {
    id: 328,
    name: 'active-postings-3m',
    displayName: 'Active Postings (3m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:48:24.867Z',
    updatedAt: '2022-11-11T17:01:33.571Z',
  },
  {
    id: 327,
    name: 'active-postings-percent-change-1m',
    displayName: 'Active Postings % Change (1m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:42:18.463Z',
    updatedAt: '2022-11-11T17:01:36.325Z',
  },
  {
    id: 326,
    name: 'active-postings-change-1m',
    displayName: 'Active Postings Change (1m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:40:28.854Z',
    updatedAt: '2022-11-11T17:01:39.179Z',
  },
  {
    id: 325,
    name: 'active-postings-1m',
    displayName: 'Active Postings (1m)',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Active Job Postings',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:38:34.279Z',
    updatedAt: '2022-11-11T17:01:41.751Z',
  },
  {
    id: 324,
    name: 'active-postings',
    displayName: 'Active Postings',
    description: 'Number of active postings',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Active Job Postings',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T04:34:08.501Z',
    updatedAt: '2022-11-11T17:01:44.320Z',
  },
  {
    id: 323,
    name: 'employee-count',
    displayName: 'Employee Count',
    description: 'Number of Employees',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'emiliano.mongelo@greycroft.com',
    createdAt: '2022-07-24T04:07:09.543Z',
    updatedAt: '2022-07-30T17:29:31.943Z',
  },
  {
    id: 322,
    name: 'recommend-to-a-friend',
    displayName: 'Recommend to a Friend',
    description: 'Recommend to a Friend Rating',
    type: 'number',
    formatting: 'percentFromDecimal',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 9,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:55:51.217Z',
    updatedAt: '2022-11-16T05:13:39.534Z',
  },
  {
    id: 321,
    name: 'diversity-and-inclusion',
    displayName: 'Diversity & Inclusion',
    description: 'Diversity & Inclusion at company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 8,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:54:26.742Z',
    updatedAt: '2022-08-12T14:53:43.995Z',
  },
  {
    id: 320,
    name: 'compensation-and-benefits',
    displayName: 'Compensation & Benefits',
    description: 'Compensation & Benefits at company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 7,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:52:24.715Z',
    updatedAt: '2022-08-12T14:53:49.539Z',
  },
  {
    id: 319,
    name: 'work-life-balance',
    displayName: 'Work Life Balance',
    description: 'Work Life Balance at company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 6,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:45:57.197Z',
    updatedAt: '2022-08-12T14:53:56.082Z',
  },
  {
    id: 318,
    name: 'culture-and-values',
    displayName: 'Cultures & Values',
    description: 'Cultures & Values of company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 5,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:44:44.356Z',
    updatedAt: '2022-08-12T14:54:01.604Z',
  },
  {
    id: 317,
    name: 'career-opportunities',
    displayName: 'Career Opportunities',
    description: 'Career Opportunities at company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 4,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:42:44.858Z',
    updatedAt: '2022-08-12T14:54:08.155Z',
  },
  {
    id: 316,
    name: 'senior-leadership',
    displayName: 'Senior Leadership',
    description: 'Senior Leadership for company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 3,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:41:52.821Z',
    updatedAt: '2022-08-12T14:54:13.929Z',
  },
  {
    id: 315,
    name: 'business-outlook',
    displayName: 'Business Outlook',
    description: 'business outlook for company',
    type: 'number',
    formatting: 'percentFromDecimal',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 10,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:36:16.944Z',
    updatedAt: '2022-08-12T14:54:20.425Z',
  },
  {
    id: 314,
    name: 'overall',
    displayName: 'Overall',
    description: 'Overall company rating',
    type: 'number',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Glassdoor Ratings',
    groupOrder: 1,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:34:49.822Z',
    updatedAt: '2022-08-12T14:54:26.353Z',
  },
  {
    id: 313,
    name: 'ceo',
    displayName: 'Test REvelio CEO',
    description: 'Test Revelio CEO',
    type: 'array',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:20:23.217Z',
    updatedAt: '2022-08-12T15:08:40.741Z',
  },
  {
    id: 312,
    name: 'linkedin-followers',
    displayName: 'LinkedIn Followers',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: true,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'LinkedIn',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-24T03:03:34.848Z',
    updatedAt: '2022-07-24T03:03:34.848Z',
  },
  {
    id: 311,
    name: 'country',
    displayName: 'Country',
    description: 'Country',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:27:28.691Z',
    updatedAt: '2022-07-23T20:27:28.691Z',
  },
  {
    id: 310,
    name: 'state',
    displayName: 'State',
    description: 'State',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:27:04.758Z',
    updatedAt: '2022-07-23T20:27:04.758Z',
  },
  {
    id: 309,
    name: 'city',
    displayName: 'City',
    description: 'City',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:26:35.773Z',
    updatedAt: '2022-07-23T20:28:10.506Z',
  },
  {
    id: 308,
    name: 'company-linkedin',
    displayName: 'Company LinkedIn',
    description: 'LinkedIn URL of Company',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 21,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:25:33.462Z',
    updatedAt: '2022-07-23T20:25:33.462Z',
  },
  {
    id: 307,
    name: 'company-name',
    displayName: 'Company',
    description: 'Name of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 20,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:24:27.478Z',
    updatedAt: '2022-07-23T20:24:27.478Z',
  },
  {
    id: 306,
    name: 'company-description',
    displayName: 'Description',
    description: 'General company description',
    type: 'string',
    formatting: 'longText',
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 19,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:23:48.532Z',
    updatedAt: '2022-08-03T23:09:04.807Z',
  },
  {
    id: 305,
    name: 'specialities',
    displayName: 'Specialties',
    description: 'Specialties',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 17,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:22:18.427Z',
    updatedAt: '2022-07-27T16:29:33.504Z',
  },
  {
    id: 304,
    name: 'company-slogan',
    displayName: 'Company Slogan',
    description: 'Company Slogan',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:21:00.827Z',
    updatedAt: '2022-07-23T20:21:00.827Z',
  },
  {
    id: 303,
    name: 'company-type',
    displayName: 'Company Type',
    description: 'Company Type',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:16:28.953Z',
    updatedAt: '2022-07-23T20:16:28.953Z',
  },
  {
    id: 302,
    name: 'industry',
    displayName: 'Industry',
    description: 'Industry',
    type: 'string',
    formatting: null,
    series: false,
    source: 'revelio',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:04:57.069Z',
    updatedAt: '2022-07-23T20:04:57.069Z',
  },
  {
    id: 301,
    name: 'founded-date',
    displayName: 'Founded',
    description: 'Year company was founded',
    type: 'date',
    formatting: 'YYYY',
    series: false,
    source: 'revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-23T20:02:55.918Z',
    updatedAt: '2022-08-01T22:12:41.613Z',
  },
  {
    id: 300,
    name: 'repeat-founder',
    displayName: 'Repeat Founder',
    description: 'Yes/No entry to verify if contact is a repeat Founder',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:56:21.564Z',
    updatedAt: '2022-11-11T18:01:41.581Z',
  },
  {
    id: 299,
    name: 'founder',
    displayName: 'Founder(s)',
    description: 'Company Founder(s)',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:54:58.384Z',
    updatedAt: '2022-08-12T15:08:59.510Z',
  },
  {
    id: 298,
    name: 'repeat-founder',
    displayName: 'Repeat Founder',
    description: 'Yes/No entry to verify if contact is a repeat Founder',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:51:44.944Z',
    updatedAt: '2022-08-12T15:09:19.048Z',
  },
  {
    id: 297,
    name: 'founder',
    displayName: 'Founder(s)',
    description: 'Company Founder(s)',
    type: 'array',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:48:27.934Z',
    updatedAt: '2022-11-11T18:05:04.960Z',
  },
  {
    id: 296,
    name: 'co-investors',
    displayName: 'Co-Investors',
    description: 'Co-investors',
    type: 'array',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:43:47.548Z',
    updatedAt: '2022-07-22T23:43:47.548Z',
  },
  {
    id: 295,
    name: 'customer-type',
    displayName: 'Customer Type',
    description: 'Customer Type',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 12,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-22T23:39:24.035Z',
    updatedAt: '2022-08-12T14:43:07.146Z',
  },
  {
    id: 294,
    name: 'latest-lead-investor',
    displayName: 'Most Recent Lead Investor',
    description: 'Most Recent lead investor',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 19,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'emiliano.mongelo@greycroft.com',
    createdAt: '2022-07-01T21:28:49.483Z',
    updatedAt: '2022-08-01T20:32:16.591Z',
  },
  {
    id: 292,
    name: 'valuation-is-estimate',
    displayName: 'Estimated Valuation',
    description: 'Estimated Valuation',
    type: 'number',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:34:21.597Z',
    updatedAt: '2022-07-19T04:53:17.223Z',
  },
  {
    id: 291,
    name: 'state',
    displayName: 'State',
    description: 'State',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:27:22.416Z',
    updatedAt: '2022-07-23T20:06:53.468Z',
  },
  {
    id: 290,
    name: 'repeat-ceo',
    displayName: 'Repeat CEO',
    description: 'Yes/No entry to verify if contact is a repeat CEO',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:23:52.424Z',
    updatedAt: '2022-08-12T15:09:40.752Z',
  },
  {
    id: 289,
    name: 'region',
    displayName: 'Region',
    description: 'Region',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:23:21.800Z',
    updatedAt: '2022-07-23T20:07:59.528Z',
  },
  {
    id: 287,
    name: 'last-funding-type',
    displayName: 'Last Funding Type',
    description: 'Most recent funding type for company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:18:22.592Z',
    updatedAt: '2022-07-15T19:52:03.080Z',
  },
  {
    id: 286,
    name: 'last-funding-date',
    displayName: 'Last Funding Date',
    description: 'Most recent funding date',
    type: 'date',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:17:40.522Z',
    updatedAt: '2022-07-19T04:50:31.962Z',
  },
  {
    id: 285,
    name: 'last-funding-amount',
    displayName: 'Last Funding Amount',
    description: 'Most recent amount funded to company',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:16:42.301Z',
    updatedAt: '2022-07-29T12:43:46.148Z',
  },
  {
    id: 284,
    name: 'investor-category',
    displayName: 'Investor Category',
    description: 'Investor Category',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 13,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:09:26.838Z',
    updatedAt: '2022-08-12T14:43:44.821Z',
  },
  {
    id: 283,
    name: 'fundings-last-update-time',
    displayName: 'Last Funding Update (Time)',
    description: 'Most recent date of funding update',
    type: 'date',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 7,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:08:48.339Z',
    updatedAt: '2022-07-28T04:33:17.805Z',
  },
  {
    id: 282,
    name: 'funding-round-count',
    displayName: 'Funding Count',
    description: 'Count of Funding Rounds',
    type: 'number',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 8,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:07:30.335Z',
    updatedAt: '2022-07-19T04:55:52.244Z',
  },
  {
    id: 281,
    name: 'funding-round',
    displayName: 'Funding Round',
    description: 'Funding Round',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 14,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:05:53.259Z',
    updatedAt: '2022-08-12T14:44:00.190Z',
  },
  {
    id: 280,
    name: 'funded-org-url',
    displayName: 'Funded Company Website',
    description: 'Website of Funded Organization',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 15,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:05:11.600Z',
    updatedAt: '2022-08-12T14:44:12.648Z',
  },
  {
    id: 279,
    name: 'ceo-past-founded-companies',
    displayName: 'CEO Past Founded Companies',
    description: 'CEO past founded companies',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T20:01:38.632Z',
    updatedAt: '2022-08-12T15:09:51.042Z',
  },
  {
    id: 278,
    name: 'twitter-followers-percent-change-14d',
    displayName: 'Twitter Followers % Change (14d)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:57:47.849Z',
    updatedAt: '2022-07-11T22:02:20.439Z',
  },
  {
    id: 276,
    name: 'linkedin-followers-percent-change-14d',
    displayName: 'LinkedIn Followers % Change (14d)',
    description: 'Percentage change in number of LinkedIn followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 4,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:48:03.814Z',
    updatedAt: '2022-07-18T21:04:12.724Z',
  },
  {
    id: 275,
    name: 'linkedin-followers-change-14d',
    displayName: 'LinkedIn Followers Change (14d)',
    description: 'Change in number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:46:36.609Z',
    updatedAt: '2022-07-18T21:03:46.196Z',
  },
  {
    id: 274,
    name: 'linkedin-followers-14d',
    displayName: 'LinkedIn Followers (14d)',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:45:53.693Z',
    updatedAt: '2022-07-18T21:03:13.737Z',
  },
  {
    id: 273,
    name: 'ceo-school',
    displayName: 'CEO School',
    description: 'Most recent school of attendance',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:33:41.312Z',
    updatedAt: '2022-08-12T15:10:05.916Z',
  },
  {
    id: 272,
    name: 'ceo-linkedin',
    displayName: 'CEO LinkedIn',
    description: 'LinkedIn url for CEO',
    type: 'array',
    formatting: 'url',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 6,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:32:17.060Z',
    updatedAt: '2022-11-11T18:05:49.201Z',
  },
  {
    id: 271,
    name: 'ceo-degree-type',
    displayName: 'CEO Degree Type',
    description: 'CEO degree type',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 8,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:30:47.779Z',
    updatedAt: '2022-08-12T15:10:27.830Z',
  },
  {
    id: 270,
    name: 'ceo-degree-major',
    displayName: 'CEO Degree Major',
    description: "Area of focus for CEO's degree",
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 9,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:30:11.469Z',
    updatedAt: '2022-08-12T15:10:33.241Z',
  },
  {
    id: 269,
    name: 'ceo-degree-completed',
    displayName: 'CEO Degree Completed',
    description: 'Confirmation of degree completion',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 11,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:29:21.193Z',
    updatedAt: '2022-08-12T15:10:47.908Z',
  },
  {
    id: 267,
    name: 'accelerator-incubator-alum',
    displayName: 'Accelerator/Incubator Alum',
    description: 'Company participated in list of defined list of accelerators and incubators',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 9,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:24:24.435Z',
    updatedAt: '2022-07-29T02:28:46.991Z',
  },
  {
    id: 266,
    name: 'sector',
    displayName: 'Sector',
    description: 'Sector',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:21:04.575Z',
    updatedAt: '2022-07-23T19:55:30.788Z',
  },
  {
    id: 264,
    name: 'latest-post-valuation',
    displayName: 'Last Post-Money Valuation',
    description: 'Latest post money valuation',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-07-01T19:12:21.310Z',
    updatedAt: '2022-07-28T06:32:59.134Z',
  },
  {
    id: 263,
    name: 'ceo',
    displayName: 'CEO Name',
    description: 'Company CEO',
    type: 'array',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-22T19:43:01.702Z',
    updatedAt: '2022-08-12T15:10:55.094Z',
  },
  {
    id: 262,
    name: 'ceo',
    displayName: 'CEO Name',
    description: 'Company CEO',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-22T19:42:48.462Z',
    updatedAt: '2022-08-12T15:11:08.712Z',
  },
  {
    id: 257,
    name: 'employee-count-percentage-change-3m',
    displayName: 'Employee Count % Change (3m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-18T04:43:36.972Z',
    updatedAt: '2022-11-11T22:53:48.548Z',
  },
  {
    id: 255,
    name: 'vc-backed',
    displayName: 'VC Backed',
    description: 'VC backed status of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:38:21.738Z',
    updatedAt: '2022-07-23T20:19:13.869Z',
  },
  {
    id: 254,
    name: 'valuation-in-millions',
    displayName: 'Valuation',
    description: 'Valuation',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:36:04.078Z',
    updatedAt: '2022-07-19T04:52:54.140Z',
  },
  {
    id: 253,
    name: 'total-funding-in-millions',
    displayName: 'Total Raised to Date',
    description: 'Total amount of funding raised to date for company',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:33:24.616Z',
    updatedAt: '2022-07-29T12:44:02.612Z',
  },
  {
    id: 251,
    name: 'sub-industry',
    displayName: 'Sub Industry',
    description: 'Sub industry of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 4,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:26:33.630Z',
    updatedAt: '2022-07-19T04:20:29.257Z',
  },
  {
    id: 250,
    name: 'shortDescription',
    displayName: 'Short Description',
    description: 'Short description of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 18,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:25:44.770Z',
    updatedAt: '2022-07-23T20:22:58.193Z',
  },
  {
    id: 248,
    name: 'org-status',
    displayName: 'Company Status',
    description: 'Status of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:23:51.531Z',
    updatedAt: '2022-07-23T20:17:39.187Z',
  },
  {
    id: 247,
    name: 'num-fundings',
    displayName: 'Funding Round Count',
    description: 'Number of funding rounds for company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:23:08.370Z',
    updatedAt: '2022-11-11T20:08:23.703Z',
  },
  {
    id: 245,
    name: 'deal-date',
    displayName: 'Deal Date',
    description: 'Date of deal for company',
    type: 'date',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'group name',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T20:19:28.072Z',
    updatedAt: '2022-07-15T20:12:30.623Z',
  },
  {
    id: 241,
    name: 'ceo-school',
    displayName: 'CEO School',
    description: 'List of schools CEO attended',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:59:21.964Z',
    updatedAt: '2022-08-12T15:11:20.296Z',
  },
  {
    id: 240,
    name: 'ceo-degree-major',
    displayName: 'CEO Degree Major',
    description: "Area of focus for CEO's degree",
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 9,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:58:33.383Z',
    updatedAt: '2022-08-12T15:11:32.194Z',
  },
  {
    id: 200,
    name: 'investor-org-name',
    displayName: 'Co-Investors',
    description: 'Co-Investors',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:09:33.525Z',
    updatedAt: '2022-07-27T21:48:06.851Z',
  },
  {
    id: 199,
    name: 'github-repo-watches-6m',
    displayName: 'Watches (6m)',
    description: "Number of watches for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 12,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:06:27.705Z',
    updatedAt: '2022-08-11T18:31:13.957Z',
  },
  {
    id: 198,
    name: 'github-repo-watches-3m',
    displayName: 'Watches (3m)',
    description: "Number of watches for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 11,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:06:20.284Z',
    updatedAt: '2022-08-11T18:30:53.946Z',
  },
  {
    id: 197,
    name: 'github-repo-watches-1m',
    displayName: 'Watches (1m)',
    description: "Number of watches for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 10,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:06:12.543Z',
    updatedAt: '2022-08-11T18:30:34.497Z',
  },
  {
    id: 196,
    name: 'github-repo-watches',
    displayName: 'Watches',
    description: "Number of watches for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: true,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 9,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:06:01.903Z',
    updatedAt: '2022-08-11T18:30:09.584Z',
  },
  {
    id: 195,
    name: 'github-repo-stars-6m',
    displayName: 'Stars (6m)',
    description: "Number of stars for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 8,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:01:30.482Z',
    updatedAt: '2022-08-11T18:29:39.275Z',
  },
  {
    id: 194,
    name: 'github-repo-stars-3m',
    displayName: 'Stars (3m)',
    description: "Number of stars for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 7,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:01:20.963Z',
    updatedAt: '2022-08-11T18:29:11.296Z',
  },
  {
    id: 193,
    name: 'github-repo-stars-1m',
    displayName: 'Stars (1m)',
    description: "Number of stars for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 6,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:01:14.941Z',
    updatedAt: '2022-08-11T18:28:44.235Z',
  },
  {
    id: 192,
    name: 'github-repo-stars',
    displayName: 'Stars',
    description: "Number of stars for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: true,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 5,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T19:01:07.764Z',
    updatedAt: '2022-08-11T18:28:19.021Z',
  },
  {
    id: 191,
    name: 'github-repo-forks-6m',
    displayName: 'Forks (6m)',
    description: "Number of forks for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 4,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:59:29.441Z',
    updatedAt: '2022-08-11T18:27:24.103Z',
  },
  {
    id: 190,
    name: 'github-repo-forks-3m',
    displayName: 'Forks (3m)',
    description: "Number of forks for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 3,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:59:20.903Z',
    updatedAt: '2022-08-11T18:26:56.701Z',
  },
  {
    id: 189,
    name: 'github-repo-forks-1m',
    displayName: 'Forks (1m)',
    description: "Number of forks for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 2,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:59:10.883Z',
    updatedAt: '2022-08-11T18:26:33.886Z',
  },
  {
    id: 188,
    name: 'github-repo-forks',
    displayName: 'Forks',
    description: "Number of forks for company's public GitHub repository",
    type: 'number',
    formatting: null,
    series: true,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Github',
    groupOrder: 1,
    groupPriority: 6,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:59:06.304Z',
    updatedAt: '2022-08-11T18:25:58.220Z',
  },
  {
    id: 187,
    name: 'funding-round-category',
    displayName: 'Last Funding Type',
    description: "Company's last funding category",
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:47:59.732Z',
    updatedAt: '2022-07-19T04:49:45.803Z',
  },
  {
    id: 185,
    name: 'twitter-followers-1m',
    displayName: 'Twitter Followers (1m)',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:40:18.188Z',
    updatedAt: '2022-07-15T18:43:44.785Z',
  },
  {
    id: 184,
    name: 'twitter-followers-1y',
    displayName: 'Twitter Followers (1y)',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 14,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:40:08.466Z',
    updatedAt: '2022-07-15T18:44:05.346Z',
  },
  {
    id: 183,
    name: 'twitter-followers-3m',
    displayName: 'Twitter Followers (3m)',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 8,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:40:01.814Z',
    updatedAt: '2022-07-18T21:08:47.479Z',
  },
  {
    id: 182,
    name: 'twitter-followers-6m',
    displayName: 'Twitter Followers (6m)',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 11,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:54.627Z',
    updatedAt: '2022-07-15T18:44:33.304Z',
  },
  {
    id: 181,
    name: 'twitter-followers-change-1m',
    displayName: 'Twitter Followers Change (1m)',
    description: 'Change in number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:44.127Z',
    updatedAt: '2022-07-15T18:44:52.812Z',
  },
  {
    id: 180,
    name: 'twitter-followers-change-1y',
    displayName: 'Twitter Followers Change (1y)',
    description: 'Change in number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 15,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:36.286Z',
    updatedAt: '2022-07-15T18:45:29.766Z',
  },
  {
    id: 179,
    name: 'twitter-followers-change-3m',
    displayName: 'Twitter Followers Change (3m)',
    description: 'Change in number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 9,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:26.688Z',
    updatedAt: '2022-07-15T18:45:56.689Z',
  },
  {
    id: 178,
    name: 'twitter-followers-change-6m',
    displayName: 'Twitter Followers Change (6m)',
    description: 'Change in number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 12,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:19.688Z',
    updatedAt: '2022-07-15T18:20:50.376Z',
  },
  {
    id: 177,
    name: 'twitter-followers-percentage-change-1m',
    displayName: 'Twitter Followers % Change (1m)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 7,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:14.687Z',
    updatedAt: '2022-07-11T19:56:42.605Z',
  },
  {
    id: 176,
    name: 'twitter-followers-percentage-change-1y',
    displayName: 'Twitter Followers % Change (1y)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 16,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:39:02.380Z',
    updatedAt: '2022-07-11T19:56:49.122Z',
  },
  {
    id: 175,
    name: 'twitter-followers-percentage-change-3m',
    displayName: 'Twitter Followers % Change (3m)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:38:52.800Z',
    updatedAt: '2022-07-11T19:56:56.792Z',
  },
  {
    id: 174,
    name: 'twitter-followers-percentage-change-6m',
    displayName: 'Twitter Followers % Change (6m)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 13,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:38:49.838Z',
    updatedAt: '2022-07-11T19:57:04.273Z',
  },
  {
    id: 172,
    name: 'linkedin-followers-1m',
    displayName: 'LinkedIn Followers (1m)',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:45.515Z',
    updatedAt: '2022-07-18T21:05:38.558Z',
  },
  {
    id: 171,
    name: 'linkedin-followers-1y',
    displayName: 'LinkedIn Followers (1y)',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 14,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:37.931Z',
    updatedAt: '2022-07-18T21:13:10.749Z',
  },
  {
    id: 170,
    name: 'linkedin-followers-3m',
    displayName: 'LinkedIn Followers (3m)',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:30.033Z',
    updatedAt: '2022-07-18T21:09:49.104Z',
  },
  {
    id: 169,
    name: 'linkedin-followers-6m',
    displayName: 'LinkedIn Followers (6m)',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:21.018Z',
    updatedAt: '2022-07-18T21:11:21.055Z',
  },
  {
    id: 168,
    name: 'linkedin-followers-change-1m',
    displayName: 'LinkedIn Followers Change (1m)',
    description: 'Change in number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:14.071Z',
    updatedAt: '2022-07-18T21:06:05.581Z',
  },
  {
    id: 167,
    name: 'linkedin-followers-change-1y',
    displayName: 'LinkedIn Followers Change (1y)',
    description: 'Change in number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:28:05.252Z',
    updatedAt: '2022-07-18T21:13:43.779Z',
  },
  {
    id: 166,
    name: 'linkedin-followers-change-3m',
    displayName: 'LinkedIn Followers Change (3m)',
    description: 'Change in number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:58.091Z',
    updatedAt: '2022-07-18T21:07:43.729Z',
  },
  {
    id: 165,
    name: 'linkedin-followers-change-6m',
    displayName: 'LinkedIn Followers Change (6m)',
    description: 'Change in number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:50.671Z',
    updatedAt: '2022-07-18T21:12:03.080Z',
  },
  {
    id: 164,
    name: 'linkedin-followers-percentage-change-1m',
    displayName: 'LinkedIn Followers % Change (1m)',
    description: 'Percentage change in number of LinkedIn followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:40.455Z',
    updatedAt: '2022-07-18T21:06:34.067Z',
  },
  {
    id: 163,
    name: 'linkedin-followers-percentage-change-1y',
    displayName: 'LinkedIn Followers % Change (1y)',
    description: 'Percentage change in number of LinkedIn followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:32.511Z',
    updatedAt: '2022-07-18T21:14:28.792Z',
  },
  {
    id: 162,
    name: 'linkedin-followers-percentage-change-3m',
    displayName: 'LinkedIn Followers % Change (3m)',
    description: 'Percentage change in number of LinkedIn followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:22.591Z',
    updatedAt: '2022-07-18T21:10:49.184Z',
  },
  {
    id: 161,
    name: 'linkedin-followers-percentage-change-6m',
    displayName: 'LinkedIn Followers % Change (6m)',
    description: 'Percentage change in number of LinkedIn followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'LinkedIn',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:27:13.573Z',
    updatedAt: '2022-07-18T21:12:30.992Z',
  },
  {
    id: 159,
    name: 'instagram-followers-1m',
    displayName: 'Instagram Followers (1m)',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:07:39.810Z',
    updatedAt: '2022-07-18T21:22:36.409Z',
  },
  {
    id: 158,
    name: 'instagram-followers-1y',
    displayName: 'Instagram Followers (1y)',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 14,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:07:28.811Z',
    updatedAt: '2022-07-18T21:26:17.825Z',
  },
  {
    id: 157,
    name: 'instagram-followers-3m',
    displayName: 'Instagram Followers (3m)',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 8,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:07:19.730Z',
    updatedAt: '2022-07-18T21:24:10.948Z',
  },
  {
    id: 156,
    name: 'instagram-followers-6m',
    displayName: 'Instagram Followers (6m)',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 11,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:07:11.670Z',
    updatedAt: '2022-07-18T21:25:16.808Z',
  },
  {
    id: 155,
    name: 'instagram-followers-change-1m',
    displayName: 'Instagram Followers Change (1m)',
    description: 'Change in number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 6,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:07:02.030Z',
    updatedAt: '2022-07-18T21:23:01.964Z',
  },
  {
    id: 154,
    name: 'instagram-followers-change-1y',
    displayName: 'Instagram Followers Change (1y)',
    description: 'Change in number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 15,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:06:54.289Z',
    updatedAt: '2022-07-18T21:26:34.490Z',
  },
  {
    id: 153,
    name: 'instagram-followers-change-3m',
    displayName: 'Instagram Followers Change (3m)',
    description: 'Change in number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 9,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:06:44.089Z',
    updatedAt: '2022-07-18T21:24:29.319Z',
  },
  {
    id: 152,
    name: 'instagram-followers-change-6m',
    displayName: 'Instagram Followers Change (6m)',
    description: 'Change in number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 12,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:06:35.990Z',
    updatedAt: '2022-07-18T21:25:31.456Z',
  },
  {
    id: 151,
    name: 'instagram-followers-percentage-change-1m',
    displayName: 'Instagram Followers % Change (1m)',
    description: 'Percentage change in number of Instagram followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 7,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:06:28.210Z',
    updatedAt: '2022-07-18T21:23:17.247Z',
  },
  {
    id: 150,
    name: 'instagram-followers-percentage-change-1y',
    displayName: 'Instagram Followers % Change (1y)',
    description: 'Percentage change in number of Instagram followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 16,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:06:15.431Z',
    updatedAt: '2022-07-18T21:26:49.911Z',
  },
  {
    id: 149,
    name: 'instagram-followers-percentage-change-3m',
    displayName: 'Instagram Followers % Change (3m)',
    description: 'Percentage change in number of Instagram followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 10,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:05:19.230Z',
    updatedAt: '2022-07-18T21:24:47.963Z',
  },
  {
    id: 148,
    name: 'instagram-followers-percentage-change-6m',
    displayName: 'Instagram Followers % Change (6m)',
    description: 'Percentage change in number of Instagram followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 13,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T18:04:49.862Z',
    updatedAt: '2022-07-18T21:25:46.970Z',
  },
  {
    id: 147,
    name: 'facebook-followers-percentage-change-6m',
    displayName: 'Facebook Followers % Change (6m)',
    description: 'Percentage change in number of Facebook followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 13,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:46:39.829Z',
    updatedAt: '2022-07-18T21:34:37.806Z',
  },
  {
    id: 146,
    name: 'facebook-followers-percentage-change-3m',
    displayName: 'Facebook Followers % Change (3m)',
    description: 'Percentage change in number of Facebook followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 10,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:46:31.109Z',
    updatedAt: '2022-07-18T21:32:57.223Z',
  },
  {
    id: 145,
    name: 'facebook-followers-percentage-change-1y',
    displayName: 'Facebook Followers % Change (1y)',
    description: 'Percentage change in number of Facebook followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 16,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:46:22.448Z',
    updatedAt: '2022-07-18T21:35:39.247Z',
  },
  {
    id: 144,
    name: 'facebook-followers-percentage-change-1m',
    displayName: 'Facebook Followers % Change (1m)',
    description: 'Percentage change in number of Facebook followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 7,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:45:41.887Z',
    updatedAt: '2022-07-18T21:31:30.355Z',
  },
  {
    id: 143,
    name: 'facebook-followers-change-6m',
    displayName: 'Facebook Followers Change (6m)',
    description: 'Change in number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 12,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:42:46.345Z',
    updatedAt: '2022-07-18T21:34:19.879Z',
  },
  {
    id: 142,
    name: 'facebook-followers-change-3m',
    displayName: 'Facebook Followers Change (3m)',
    description: 'Change in number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 9,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:42:36.725Z',
    updatedAt: '2022-07-18T21:32:32.357Z',
  },
  {
    id: 141,
    name: 'facebook-followers-change-1y',
    displayName: 'Facebook Followers Change (1y)',
    description: 'Change in number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 15,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:42:26.805Z',
    updatedAt: '2022-07-18T21:35:23.002Z',
  },
  {
    id: 140,
    name: 'facebook-followers-change-1m',
    displayName: 'Facebook Followers Change (1m)',
    description: 'Change in number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 6,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:42:16.204Z',
    updatedAt: '2022-07-18T21:31:05.982Z',
  },
  {
    id: 139,
    name: 'facebook-followers-6m',
    displayName: 'Facebook Followers (6m)',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 11,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:37:58.381Z',
    updatedAt: '2022-07-18T21:34:01.937Z',
  },
  {
    id: 138,
    name: 'facebook-followers-3m',
    displayName: 'Facebook Followers (3m)',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 8,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:37:31.639Z',
    updatedAt: '2022-07-18T21:32:01.354Z',
  },
  {
    id: 137,
    name: 'facebook-followers-1y',
    displayName: 'Facebook Followers (1y)',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 14,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:37:12.080Z',
    updatedAt: '2022-07-18T21:35:06.153Z',
  },
  {
    id: 136,
    name: 'facebook-followers-1m',
    displayName: 'Facebook Followers (1m)',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 5,
    groupPriority: 4,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:37:03.379Z',
    updatedAt: '2022-07-18T21:30:44.712Z',
  },
  {
    id: 133,
    name: 'employee-count-percentage-change-6m',
    displayName: 'Employee Count % Change (6m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 13,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:31:50.574Z',
    updatedAt: '2022-11-11T22:53:57.148Z',
  },
  {
    id: 132,
    name: 'employee-count-percentage-change-1y',
    displayName: 'Employee Count % Change (1y)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 16,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:31:20.994Z',
    updatedAt: '2022-11-11T22:54:07.780Z',
  },
  {
    id: 131,
    name: 'employee-count-change-6m',
    displayName: 'Employee Count Change (6m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:27:04.530Z',
    updatedAt: '2022-07-28T05:33:18.227Z',
  },
  {
    id: 130,
    name: 'employee-count-change-3m',
    displayName: 'Employee Count Change (3m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 9,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:26:41.610Z',
    updatedAt: '2022-07-28T05:33:10.724Z',
  },
  {
    id: 129,
    name: 'employee-count-change-1y',
    displayName: 'Employee Count Change (1y)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 15,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:26:33.750Z',
    updatedAt: '2022-07-28T05:33:03.163Z',
  },
  {
    id: 128,
    name: 'employee-count-change-1m',
    displayName: 'Employee Count Change (1m)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:26:24.472Z',
    updatedAt: '2022-07-28T05:32:54.260Z',
  },
  {
    id: 127,
    name: 'employee-count-6m',
    displayName: 'Employee Count (6m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:22:55.766Z',
    updatedAt: '2022-07-28T05:31:56.506Z',
  },
  {
    id: 126,
    name: 'employee-count-3m',
    displayName: 'Employee Count (3m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 8,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:22:44.428Z',
    updatedAt: '2022-07-28T05:31:49.107Z',
  },
  {
    id: 125,
    name: 'employee-count-1y',
    displayName: 'Employee Count (1y)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 14,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:22:34.706Z',
    updatedAt: '2022-07-28T05:31:41.430Z',
  },
  {
    id: 124,
    name: 'employee-count-1m',
    displayName: 'Employee Count (1m)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic,revelio',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:22:31.625Z',
    updatedAt: '2022-07-28T05:31:28.647Z',
  },
  {
    id: 123,
    name: 'app-total-time-6m',
    displayName: 'Time Usage (6m - mins)',
    description: 'Total time usage in minutes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:16:44.816Z',
    updatedAt: '2022-11-11T15:21:49.147Z',
  },
  {
    id: 122,
    name: 'app-total-time-3m',
    displayName: 'Time Usage (3m - mins)',
    description: 'Total time usage in minutes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T17:16:35.295Z',
    updatedAt: '2022-11-11T15:22:03.286Z',
  },
  {
    id: 119,
    name: 'app-revenue-6m',
    displayName: 'App Daily Revenue (6m)',
    description: 'Revenue from product',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Revenue',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:31:45.732Z',
    updatedAt: '2022-11-11T15:32:19.412Z',
  },
  {
    id: 118,
    name: 'app-revenue-3m',
    displayName: 'App Daily Revenue (3m)',
    description: 'Revenue from product',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Revenue',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:31:35.932Z',
    updatedAt: '2022-11-11T15:32:35.557Z',
  },
  {
    id: 117,
    name: 'app-revenue-1m',
    displayName: 'App Daily Revenue (1m)',
    description: 'Revenue from product',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Revenue',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:31:27.053Z',
    updatedAt: '2022-11-11T15:32:38.526Z',
  },
  {
    id: 116,
    name: 'app-paid-download-6m',
    displayName: 'Paid Monthly Download (6m)',
    description: 'Total number of paid downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 13,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:31:18.174Z',
    updatedAt: '2022-11-11T17:55:12.668Z',
  },
  {
    id: 115,
    name: 'app-paid-download-3m',
    displayName: 'Paid Monthly Download (3m)',
    description: 'Total number of paid downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 12,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:27:28.891Z',
    updatedAt: '2022-11-11T17:55:26.961Z',
  },
  {
    id: 114,
    name: 'app-organic-download-6m',
    displayName: 'Free Monthly Download (6m)',
    description: 'Average number of free product downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 9,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:27:25.730Z',
    updatedAt: '2022-11-11T17:53:00.368Z',
  },
  {
    id: 113,
    name: 'app-organic-download-3m',
    displayName: 'Free Monthly Download (3m)',
    description: 'Average number of free product downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 8,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:27:07.746Z',
    updatedAt: '2022-11-11T17:53:27.322Z',
  },
  {
    id: 112,
    name: 'app-download-6m',
    displayName: 'App Monthly Download (6m)',
    description: 'Average number of product download',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:26:56.186Z',
    updatedAt: '2022-11-11T17:50:56.098Z',
  },
  {
    id: 111,
    name: 'app-download-3m',
    displayName: 'App Monthly Download (3m)',
    description: 'Average number of product download',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:26:52.386Z',
    updatedAt: '2022-11-11T17:51:29.817Z',
  },
  {
    id: 110,
    name: 'app-average-time-per-users-6m',
    displayName: 'Time Per Active User (6m)',
    description: 'Time per user of product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 12,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:21:07.844Z',
    updatedAt: '2022-11-11T15:22:11.165Z',
  },
  {
    id: 109,
    name: 'app-average-time-per-users-3m',
    displayName: 'Time Per Active User (3m)',
    description: 'Time per user of product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:20:55.023Z',
    updatedAt: '2022-11-11T15:22:23.539Z',
  },
  {
    id: 108,
    name: 'app-average-active-users-6m',
    displayName: 'Active Users (6m)',
    description: 'Number of active users of product',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 4,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:20:42.803Z',
    updatedAt: '2022-11-11T15:22:26.956Z',
  },
  {
    id: 107,
    name: 'app-average-active-users-3m',
    displayName: 'Active Users (3m)',
    description: 'Number of active users of product',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:20:28.344Z',
    updatedAt: '2022-11-11T15:22:42.229Z',
  },
  {
    id: 106,
    name: 'app-average-active-users-1m',
    displayName: 'Active Users (1m)',
    description: 'Number of active users of product',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:20:20.305Z',
    updatedAt: '2022-11-11T15:22:45.745Z',
  },
  {
    id: 105,
    name: 'app-average-active-users',
    displayName: 'Active Users',
    description: 'Number of active users of product',
    type: 'number',
    formatting: 'integer',
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Usage',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:19:58.244Z',
    updatedAt: '2022-11-11T15:22:48.286Z',
  },
  {
    id: 104,
    name: 'activity-positive-6m',
    displayName: 'Positive Activity (6m)',
    description: 'Positive activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:07:56.366Z',
    updatedAt: '2022-07-28T15:30:58.102Z',
  },
  {
    id: 103,
    name: 'activity-positive-3m',
    displayName: 'Positive Activity (3m)',
    description: 'Positive activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:07:46.486Z',
    updatedAt: '2022-07-28T15:33:25.824Z',
  },
  {
    id: 102,
    name: 'activity-positive-1y',
    displayName: 'Positive Activity (1y)',
    description: 'Positive activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 8,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:07:36.706Z',
    updatedAt: '2022-07-28T15:33:22.045Z',
  },
  {
    id: 101,
    name: 'activity-positive',
    displayName: 'Positive Activity',
    description: 'Positive activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:07:26.266Z',
    updatedAt: '2022-07-28T15:40:33.759Z',
  },
  {
    id: 100,
    name: 'activity-negative-6m',
    displayName: 'Negative Activity (6m)',
    description: 'Negative activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:04:12.344Z',
    updatedAt: '2022-07-28T15:33:41.649Z',
  },
  {
    id: 99,
    name: 'activity-negative-3m',
    displayName: 'Negative Activity (3m)',
    description: 'Negative activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:04:02.663Z',
    updatedAt: '2022-07-28T15:34:06.040Z',
  },
  {
    id: 97,
    name: 'activity-negative',
    displayName: 'Negative Activity',
    description: 'Negative activity associated with company',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'predictleads',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Activity',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-17T16:03:45.663Z',
    updatedAt: '2022-07-28T15:40:02.207Z',
  },
  {
    id: 91,
    name: 'sector',
    displayName: 'Sector',
    description: '-',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-13T14:12:24.296Z',
    updatedAt: '2022-07-23T19:57:16.349Z',
  },
  {
    id: 89,
    name: 'board-members',
    displayName: 'Board Members',
    description: 'List of associated members with current job employer, board title and linkedin url ',
    type: 'array',
    formatting: 'boardMembers',
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Board Information',
    groupOrder: 1,
    groupPriority: 5,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-08T13:16:45.026Z',
    updatedAt: '2022-08-12T14:57:51.350Z',
  },
  {
    id: 88,
    name: 'app-total-time-1m',
    displayName: 'Time Usage (1m - mins)',
    description: 'Total time usage in minutes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:34:06.378Z',
    updatedAt: '2022-11-11T15:23:05.765Z',
  },
  {
    id: 87,
    name: 'app-total-time',
    displayName: 'Time Usage (mins)',
    description: 'Total time usage in minutes',
    type: 'number',
    formatting: 'integer',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:58.540Z',
    updatedAt: '2022-11-11T15:23:09.019Z',
  },
  {
    id: 86,
    name: 'app-paid-download-1m',
    displayName: 'Paid Monthly Download (1m)',
    description: 'Total number of paid downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 11,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:48.420Z',
    updatedAt: '2022-11-11T17:54:31.550Z',
  },
  {
    id: 84,
    name: 'app-organic-download-1m',
    displayName: 'Organic Daily Download (1m)',
    description: 'Average number of organic product download',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 7,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:28.938Z',
    updatedAt: '2022-11-11T15:26:13.057Z',
  },
  {
    id: 83,
    name: 'app-organic-download',
    displayName: 'Free Monthly Download',
    description: 'Average number of free product downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:19.117Z',
    updatedAt: '2022-11-11T17:53:42.927Z',
  },
  {
    id: 82,
    name: 'app-demographics-index',
    displayName: 'App Demographics Index',
    description: 'Demographics index for app',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Demographics',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:08.258Z',
    updatedAt: '2022-07-15T20:44:34.679Z',
  },
  {
    id: 81,
    name: 'app-demographics-percent',
    displayName: 'App Demographics %',
    description: 'Demographics percentage for app',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Demographics',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:33:00.358Z',
    updatedAt: '2022-07-15T20:44:51.033Z',
  },
  {
    id: 80,
    name: 'app-average-time-per-users-1m',
    displayName: 'Time Per Active User (1m)',
    description: 'Time per user of product',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Usage',
    groupOrder: 10,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:32:48.158Z',
    updatedAt: '2022-11-11T15:23:21.648Z',
  },
  {
    id: 79,
    name: 'app-average-time-per-users',
    displayName: 'Time Per Active User',
    description: 'Time per user of product',
    type: 'number',
    formatting: null,
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Usage',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:32:39.938Z',
    updatedAt: '2022-11-11T15:23:24.979Z',
  },
  {
    id: 78,
    name: 'app-download-1m',
    displayName: 'App Monthly Download (1m)',
    description: 'Average number of product download',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:32:30.238Z',
    updatedAt: '2022-11-11T17:51:49.532Z',
  },
  {
    id: 76,
    name: 'app-cumulative-revenue',
    displayName: 'App Cumulative Revenue',
    description: 'App Cumulative Revenue',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Revenue',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:32:14.237Z',
    updatedAt: '2022-11-11T15:32:53.639Z',
  },
  {
    id: 74,
    name: 'twitter-followers-14d',
    displayName: 'Twitter Followers (14d)',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:31:13.297Z',
    updatedAt: '2022-07-15T18:46:47.492Z',
  },
  {
    id: 73,
    name: 'twitter-followers-change-14d',
    displayName: 'Twitter Followers Change (14d)',
    description: 'Change in number of Twitter followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:31:04.459Z',
    updatedAt: '2022-07-15T18:46:59.445Z',
  },
  {
    id: 72,
    name: 'twitter-followers-percentage-change-14d',
    displayName: 'Twitter Followers % Change (14d)',
    description: 'Percentage change in number of Twitter followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Twitter',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:54.837Z',
    updatedAt: '2022-07-11T19:48:20.603Z',
  },
  {
    id: 71,
    name: 'twitter-followers',
    displayName: 'Twitter Followers',
    description: 'Number of Twitter followers',
    type: 'number',
    formatting: null,
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Twitter',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:47.137Z',
    updatedAt: '2022-07-15T18:20:13.069Z',
  },
  {
    id: 70,
    name: 'twitter-url',
    displayName: 'Twitter URL',
    description: 'Twitter URL',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:38.717Z',
    updatedAt: '2022-07-15T20:47:37.295Z',
  },
  {
    id: 69,
    name: 'linkedin-followers',
    displayName: 'LinkedIn Followers',
    description: 'Number of LinkedIn followers',
    type: 'number',
    formatting: null,
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'LinkedIn',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:22.117Z',
    updatedAt: '2022-07-15T18:53:37.569Z',
  },
  {
    id: 68,
    name: 'linkedin-url',
    displayName: 'LinkedIn URL',
    description: 'LinkedIn URL',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:15.198Z',
    updatedAt: '2022-07-15T20:47:57.161Z',
  },
  {
    id: 67,
    name: 'instagram-followers-14d',
    displayName: 'Instagram Followers (14d)',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:30:06.119Z',
    updatedAt: '2022-07-18T21:21:08.239Z',
  },
  {
    id: 66,
    name: 'instagram-followers-change-14d',
    displayName: 'Instagram Followers Change (14d)',
    description: 'Change in number of Instagram followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 3,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:29:55.941Z',
    updatedAt: '2022-07-18T21:21:34.879Z',
  },
  {
    id: 65,
    name: 'instagram-followers-percent-change-14d',
    displayName: 'Instagram Followers % Change (14d)',
    description: 'Percentage change in number of Instagram followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Instagram',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:29:43.039Z',
    updatedAt: '2022-07-18T21:21:57.194Z',
  },
  {
    id: 64,
    name: 'instagram-followers',
    displayName: 'Instagram Followers',
    description: 'Number of Instagram followers',
    type: 'number',
    formatting: null,
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Instagram',
    groupOrder: 1,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:29:31.757Z',
    updatedAt: '2022-07-18T21:20:02.695Z',
  },
  {
    id: 63,
    name: 'instagram-url',
    displayName: 'Instagram URL',
    description: 'Instagram URL',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:29:19.536Z',
    updatedAt: '2022-07-15T20:48:33.282Z',
  },
  {
    id: 62,
    name: 'facebook-followers-14d',
    displayName: 'Facebook Followers (14d)',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 2,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:29:10.277Z',
    updatedAt: '2022-07-18T21:29:27.549Z',
  },
  {
    id: 61,
    name: 'facebook-followers-change-14d',
    displayName: 'Facebook Followers Change (14d)',
    description: 'Change in number of Facebook followers',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 3,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:57.117Z',
    updatedAt: '2022-07-18T21:29:46.004Z',
  },
  {
    id: 60,
    name: 'facebook-followers-percentage-change-14d',
    displayName: 'Facebook Followers % Change (14d)',
    description: 'Percentage change in number of Facebook followers',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Facebook',
    groupOrder: 4,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:47.719Z',
    updatedAt: '2022-07-15T19:03:56.665Z',
  },
  {
    id: 59,
    name: 'facebook-followers',
    displayName: 'Facebook Followers',
    description: 'Number of Facebook followers',
    type: 'number',
    formatting: null,
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Facebook',
    groupOrder: 1,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:39.637Z',
    updatedAt: '2022-07-18T21:29:02.710Z',
  },
  {
    id: 58,
    name: 'facebook-url',
    displayName: 'Facebook URL',
    description: 'Facebook URL',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:30.337Z',
    updatedAt: '2022-07-15T20:49:04.723Z',
  },
  {
    id: 57,
    name: 'highlights-date',
    displayName: 'Highlights Date',
    description: 'Date associated with highlights',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Highlights',
    groupOrder: 7,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:17.576Z',
    updatedAt: '2022-08-12T15:02:41.751Z',
  },
  {
    id: 56,
    name: 'highlights-text',
    displayName: 'Highlights',
    description: 'Highlights associated with the company',
    type: 'string',
    formatting: 'text',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Highlights',
    groupOrder: 6,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:11.996Z',
    updatedAt: '2022-08-12T15:02:30.074Z',
  },
  {
    id: 55,
    name: 'highlights-category',
    displayName: 'Highlights Category',
    description: 'Categories of company highlights',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Highlights',
    groupOrder: 5,
    groupPriority: 4,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:05.977Z',
    updatedAt: '2022-08-12T15:01:46.688Z',
  },
  {
    id: 54,
    name: 'state',
    displayName: 'State',
    description: 'State',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:28:00.897Z',
    updatedAt: '2022-07-23T20:07:13.724Z',
  },
  {
    id: 53,
    name: 'ownership-status',
    displayName: 'Ownership Status',
    description: 'Ownership status of company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 14,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:51.218Z',
    updatedAt: '2022-07-23T20:18:27.791Z',
  },
  {
    id: 52,
    name: 'most-recent-round',
    displayName: 'Most Recent Round',
    description: 'Most recent round of investment in company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:40.237Z',
    updatedAt: '2022-07-15T20:50:32.597Z',
  },
  {
    id: 51,
    name: 'total-raised-to-date',
    displayName: 'Total Raised to Date',
    description: 'Total amount of funding raised to date for company',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 4,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:31.015Z',
    updatedAt: '2022-07-29T12:44:07.359Z',
  },
  {
    id: 50,
    name: 'total-investor-count',
    displayName: 'Total Investors Count',
    description: 'Sum of all investors in the company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 7,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:21.876Z',
    updatedAt: '2022-07-29T04:16:56.337Z',
  },
  {
    id: 49,
    name: 'last-funding-type',
    displayName: 'Last Funding Type',
    description: 'Most recent funding type for company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:10.756Z',
    updatedAt: '2022-07-15T20:52:09.416Z',
  },
  {
    id: 48,
    name: 'last-funding-date',
    displayName: 'Last Funding Date',
    description: 'Most recent funding date',
    type: 'date',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 3,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:27:00.576Z',
    updatedAt: '2022-07-19T04:50:20.129Z',
  },
  {
    id: 47,
    name: 'last-funding-amount',
    displayName: 'Last Funding Amount',
    description: 'Most recent amount funded to company',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:26:50.076Z',
    updatedAt: '2022-07-29T12:43:55.352Z',
  },
  {
    id: 46,
    name: 'founded',
    displayName: 'Founded',
    description: 'Year company was founded',
    type: 'date',
    formatting: 'YYYY',
    series: false,
    source: 'cbinsights,crunchbase',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:26:39.476Z',
    updatedAt: '2022-07-29T12:13:27.315Z',
  },
  {
    id: 45,
    name: 'employee-count-14d',
    displayName: 'Employee Count (14d)',
    description: 'Company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:26:28.195Z',
    updatedAt: '2022-07-19T03:04:01.706Z',
  },
  {
    id: 44,
    name: 'employee-count-change-14d',
    displayName: 'Employee Count Change (14d)',
    description: 'Change in company employee count',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:26:17.117Z',
    updatedAt: '2022-07-19T03:04:22.836Z',
  },
  {
    id: 43,
    name: 'employee-count-percentage-change-1m',
    displayName: 'Employee Count % Change (1m)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'revelio,harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 7,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:26:03.855Z',
    updatedAt: '2022-11-11T22:53:37.507Z',
  },
  {
    id: 42,
    name: 'employee-count-percent-change-14d',
    displayName: 'Employee Count % Change (14d)',
    description: 'Company employee count percentage change',
    type: 'number',
    formatting: 'percent',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Headcount',
    groupOrder: 4,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:51.316Z',
    updatedAt: '2022-07-19T03:04:48.115Z',
  },
  {
    id: 41,
    name: 'employee-count',
    displayName: 'Employee Count',
    description: 'Number of Employees',
    type: 'number',
    formatting: null,
    series: true,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Headcount',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:42.456Z',
    updatedAt: '2022-08-12T16:08:31.026Z',
  },
  {
    id: 40,
    name: 'country',
    displayName: 'Country',
    description: 'Country',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 9,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:31.735Z',
    updatedAt: '2022-07-23T20:08:31.116Z',
  },
  {
    id: 39,
    name: 'count-funding-round',
    displayName: 'Funding Round Count',
    description: 'Number of funding rounds for company',
    type: 'number',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'Funding Profile',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:21.159Z',
    updatedAt: '2022-07-29T04:16:48.443Z',
  },
  {
    id: 38,
    name: 'website-status',
    displayName: 'Website Status',
    description: 'Company website status',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:12.416Z',
    updatedAt: '2022-07-15T20:55:42.889Z',
  },
  {
    id: 37,
    name: 'website',
    displayName: 'Website',
    description: 'Company website',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:07.157Z',
    updatedAt: '2022-07-19T04:17:27.763Z',
  },
  {
    id: 36,
    name: 'name',
    displayName: 'name',
    description: '-',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:25:01.101Z',
    updatedAt: '2022-07-15T20:56:18.461Z',
  },
  {
    id: 35,
    name: 'logo',
    displayName: 'Logo',
    description: 'Logo',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: null,
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:24:49.635Z',
    updatedAt: '2022-07-15T20:56:35.280Z',
  },
  {
    id: 34,
    name: 'city',
    displayName: 'City',
    description: 'City',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 6,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:24:44.135Z',
    updatedAt: '2022-07-23T20:05:58.346Z',
  },
  {
    id: 33,
    name: 'ceo-past-founded-companies',
    displayName: 'CEO Past Founded Companies',
    description: 'CEO past founded companies',
    type: 'array',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 5,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:24:32.015Z',
    updatedAt: '2022-11-11T19:45:37.127Z',
  },
  {
    id: 32,
    name: 'ceo-linkedin',
    displayName: 'CEO LinkedIn',
    description: 'LinkedIn url for CEO',
    type: 'array',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 6,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:24:21.215Z',
    updatedAt: '2022-08-12T15:11:54.973Z',
  },
  {
    id: 31,
    name: 'repeat-ceo',
    displayName: 'Repeat CEO',
    description: 'Yes/No entry to verify if contact is a repeat CEO',
    type: 'boolean',
    formatting: 'yesNo',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 4,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:24:09.775Z',
    updatedAt: '2022-08-12T15:11:58.569Z',
  },
  {
    id: 29,
    name: 'accelerator-incubator-alum',
    displayName: 'Accelerator/Incubator Alum',
    description: 'Company participated in list of defined list of accelerators and incubators',
    type: 'array',
    formatting: 'longText',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 9,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-30T13:23:44.935Z',
    updatedAt: '2022-07-29T02:28:29.766Z',
  },
  {
    id: 25,
    name: 'test-field-6',
    displayName: 'Test Six',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Test',
    groupOrder: 6,
    groupPriority: 2,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:07:53.748Z',
    updatedAt: '2022-07-15T20:59:14.465Z',
  },
  {
    id: 24,
    name: 'test-field-5',
    displayName: 'Test Five',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Name',
    groupOrder: 5,
    groupPriority: 1,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:07:20.407Z',
    updatedAt: '2022-07-15T20:59:25.108Z',
  },
  {
    id: 23,
    name: 'test-field-4',
    displayName: 'Test Four',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Name',
    groupOrder: 4,
    groupPriority: 1,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:06:57.967Z',
    updatedAt: '2022-07-15T20:59:28.459Z',
  },
  {
    id: 22,
    name: 'test-field-3',
    displayName: 'Test Three',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Name',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:06:35.949Z',
    updatedAt: '2022-07-15T20:59:39.940Z',
  },
  {
    id: 21,
    name: 'test-field-2',
    displayName: 'Test Two',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Name',
    groupOrder: 2,
    groupPriority: 1,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:06:15.728Z',
    updatedAt: '2022-07-15T20:59:43.322Z',
  },
  {
    id: 19,
    name: 'test-field-1',
    displayName: 'Test One',
    description: 'Logo of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'Test Name',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'dario.stamenkovic@starcier.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-26T12:04:51.776Z',
    updatedAt: '2022-07-15T20:59:57.551Z',
  },
  {
    id: 16,
    name: 'company-logo',
    displayName: 'Company Logo',
    description: 'Logo of the company',
    type: 'string',
    formatting: 'url',
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'group name',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:48:10.652Z',
    updatedAt: '2022-07-15T21:00:12.984Z',
  },
  {
    id: 13,
    name: 'company-name',
    displayName: 'Company Name',
    description: 'Name of the company',
    type: 'string',
    formatting: null,
    series: false,
    source: 'harmonic',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'group name',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:47:24.792Z',
    updatedAt: '2022-07-15T21:00:30.430Z',
  },
  {
    id: 12,
    name: 'app-revenue',
    displayName: 'App Daily Revenue',
    description: 'Total Daily Revenue',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Revenue',
    groupOrder: 2,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:46:15.373Z',
    updatedAt: '2022-11-11T15:32:56.617Z',
  },
  {
    id: 11,
    name: 'app-paid-download',
    displayName: 'Paid Monthly Download',
    description: 'Total number of paid downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: {},
    groupName: 'App Download',
    groupOrder: 10,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:45:48.554Z',
    updatedAt: '2022-11-11T17:54:54.681Z',
  },
  {
    id: 10,
    name: 'app-cumulative-download',
    displayName: 'App Cumulative Download',
    description: 'Total number of cumulative downloads',
    type: 'number',
    formatting: null,
    series: false,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Download',
    groupOrder: 1,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:45:22.633Z',
    updatedAt: '2022-11-11T15:26:50.647Z',
  },
  {
    id: 9,
    name: 'app-download',
    displayName: 'App Monthly Download',
    description: 'Average number of product download',
    type: 'number',
    formatting: null,
    series: true,
    source: 'appannie',
    visible: true,
    popup: false,
    meta: { chart: { type: 'spider' } },
    groupName: 'App Download',
    groupOrder: 2,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-20T11:44:54.634Z',
    updatedAt: '2022-11-11T17:52:06.518Z',
  },
  {
    id: 8,
    name: 'industry',
    displayName: 'Industry',
    description: 'Industry',
    type: 'string',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 3,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:37:38.459Z',
    updatedAt: '2022-07-19T04:20:07.728Z',
  },
  {
    id: 7,
    name: 'contact-phone',
    displayName: 'Phone',
    description: 'Phone contact number',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Company Profile',
    groupOrder: 11,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:37:21.298Z',
    updatedAt: '2022-07-23T20:10:34.712Z',
  },
  {
    id: 6,
    name: 'ceo-past-companies-status',
    displayName: 'Past Companies Status',
    description: 'Past companies status',
    type: 'string',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'group name',
    groupOrder: 1,
    groupPriority: 1,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:36:59.399Z',
    updatedAt: '2022-07-15T21:03:00.126Z',
  },
  {
    id: 5,
    name: 'ceo-degree-type',
    displayName: 'CEO Degree Type',
    description: 'CEO degree type',
    type: 'array',
    formatting: null,
    series: false,
    source: 'cbinsights',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Leadership',
    groupOrder: 8,
    groupPriority: 3,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:36:37.119Z',
    updatedAt: '2022-08-12T15:12:15.668Z',
  },
  {
    id: 4,
    name: 'latest-lead-investor',
    displayName: 'Latest Lead Investor',
    description: 'Latest Lead investor',
    type: 'array',
    formatting: null,
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 8,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:36:07.738Z',
    updatedAt: '2022-08-01T21:47:19.701Z',
  },
  {
    id: 3,
    name: 'latest-post-valuation',
    displayName: 'Last Post-Money Valuation',
    description: 'Latest post money valuation',
    type: 'number',
    formatting: 'currency',
    series: false,
    source: 'crunchbase',
    visible: true,
    popup: true,
    meta: {},
    groupName: 'Funding Profile',
    groupOrder: 5,
    groupPriority: 2,
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-05-13T16:35:41.476Z',
    updatedAt: '2022-07-28T06:32:52.706Z',
  },
];
