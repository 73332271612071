import { styled } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { Button, Icon, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { LoadingButton } from '@mui/lab';
import { ErrorBox } from '../../../components/Dialog/ErrorBox';
import { companyState } from '../../../services/state/CompanyState';
import {
  ConfirmDialog,
  ConfirmDialogTitleOptions,
} from '../../../components/Dialog/ConfirmDialog/ConfirmDialog';
import { MappingsEventType } from '../MappingsGrid/mappingsGridEvents';

const InfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  border-radius: 4px;
`;

const InfoDetails = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  padding: 0.5rem;

  & .error,
  .error * {
    color: ${({ theme }) => theme.colors.critical[50]};
  }
`;

const ContentContainer = styled('div')`
  width: 43.5rem;
  padding: 0 0.5rem 0 0;
  display: grid;
  gap: 1.5rem;
`;

const StyledLogo = styled('img')`
  box-shadow: 0px 4px 6px rgba(16, 37, 62, 0.06);
  border-radius: 4px;
  margin-right: 0.5rem;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 1rem;
  padding-right: 1rem;
`;

interface IDuplicateErrorProps {
  open: boolean;
  companyId: number;
  error: string | ReactNode;
  action?: MappingsEventType;
  additionalInfo?: string | ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  onMerge?: () => void;
  isLoading?: boolean;
}

export const DuplicateError: FC<IDuplicateErrorProps> = ({
  open,
  error,
  companyId,
  onClose,
  additionalInfo,
  onCancel,
  onMerge,
  action,
  isLoading,
}) => {
  const company = useRecoilValue(companyState(companyId));
  const { name: companyName, logoUrl: logo } = company ?? {};
  const country = company?.secondaryLocations?.[0]?.country;

  const vendorIDError = action === MappingsEventType.editVendorIds;

  if (!company) return null;

  return (
    <ConfirmDialog
      open={open}
      type={ConfirmDialogTitleOptions.Warning}
      onClose={onClose}
      title={`${vendorIDError ? 'ID' : 'Website'} Already Exists`}
      actionButtons={
        <ButtonContainer>
          {(Boolean(onClose) || Boolean(onCancel)) && (
            <Button variant='outlined' color='secondary' size='medium' onClick={onCancel ?? onClose}>
              Cancel
            </Button>
          )}
          {Boolean(onMerge) && (
            <LoadingButton
              variant='contained'
              color='error'
              size='medium'
              loadingPosition='start'
              disabled={isLoading}
              loading={isLoading}
              onClick={onMerge}
              startIcon={isLoading ? <Icon /> : <></>}
            >
              {isLoading ? 'Processing' : 'Yes, merge'}
            </LoadingButton>
          )}
        </ButtonContainer>
      }
    >
      <ContentContainer>
        <ErrorBox style={{ padding: '1rem' }}>
          <Typography
            variant='body2'
            sx={{
              display: 'block',
              fontWeight: '600',
              width: '100%',
              padding: '0.5rem',
              paddingTop: '0',
            }}
          >
            Duplicate
          </Typography>
          <InfoContainer>
            <InfoDetails>
              <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
                {logo && (
                  <StyledLogo loading='lazy' src={logo} width={'24'} height={'24'} alt={companyName} />
                )}
                {companyName}
              </Typography>
              <Typography variant='body2' className='error'>
                {error}
              </Typography>
              <Typography variant='body2'>{country}</Typography>
            </InfoDetails>
          </InfoContainer>
        </ErrorBox>
        <Typography variant='body2' component='div'>
          {additionalInfo}
        </Typography>
      </ContentContainer>
    </ConfirmDialog>
  );
};
