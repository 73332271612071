import { format, getQuarter, getYear } from 'date-fns';
import { KpiPeriod } from '../data-models/company-financials.data-model';
import { KPIRequestFrequency } from '../data-models/kpi-requests.data-model';
import { MONTHS } from '../pages/KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { FormatterFn } from './formatter-service';

export const StaticFormattersById = {
  kpiPeriod: formatKPIPeriod as FormatterFn<unknown>,
  fye: formatCompanyFye as FormatterFn<unknown>,
} satisfies Record<string, FormatterFn<unknown>>;

export interface IKPIFormatterConfig {
  frequency: KpiPeriod | KPIRequestFrequency;
  showYear?: boolean;
  yearFormat?: string;
}

function formatKPIPeriod(date: string | Date, config: IKPIFormatterConfig) {
  const { frequency, showYear = false } = config ?? {};
  const dateValue = new Date(date);
  if (frequency === KpiPeriod.year || frequency === KPIRequestFrequency.Annual) {
    return getYear(dateValue).toString();
  } else if (frequency === KpiPeriod.quarter || frequency === KPIRequestFrequency.Quarterly) {
    return `Q${getQuarter(dateValue)} ${showYear ? getYear(dateValue) : ''}`;
  } else {
    const yearFormat = config.yearFormat ?? 'yy';
    return format(dateValue, `MMM${showYear ? ` ${yearFormat}` : ''}`);
  }
}

function formatCompanyFye(fye: number) {
  if (fye < 1 || fye > 12) {
    console.warn(`Invalid fye value: ${fye}`);
    return '';
  }
  return MONTHS[fye - 1];
}
