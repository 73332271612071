import { atom } from 'jotai';
import { FieldPath } from 'react-hook-form';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import {
  PanelPreferences,
  PreferenceKey,
  PreferenceScope,
} from '../../../data-models/preferences-v3.data-model';
import { CompanyFundMetricsDataModel } from '../../../schemas/CompanyFundMetrics.schema';
import { preferenceAtom } from '../../../util/preferences-service-v3';

const companyProfileSummaryPrefAtom = preferenceAtom<PanelPreferences>({
  preferenceKey: `${PreferenceKey.companySummaryDetails}.$default`,
  scope: PreferenceScope.ORGANIZATION,
});

export const selectedDetailsFieldsOrgState = atom(
  (get) => {
    return (get(companyProfileSummaryPrefAtom)?.selectedFields ?? []) as FieldPath<ICompanyDataModel>[];
  },
  (get, set, selectedFields: string[]) => {
    set(companyProfileSummaryPrefAtom, {
      ...get(companyProfileSummaryPrefAtom),
      selectedFields,
    });
  }
);

const companyProfileInvestmentsPrefAtom = preferenceAtom<PanelPreferences>({
  preferenceKey: `${PreferenceKey.companySummaryInvestments}.$default`,
  scope: PreferenceScope.ORGANIZATION,
});

export const selectedMetricsFieldsOrgState = atom(
  (get) => {
    return (get(companyProfileInvestmentsPrefAtom)?.selectedFields ??
      []) as FieldPath<CompanyFundMetricsDataModel>[];
  },
  (get, set, selectedFields: string[]) => {
    set(companyProfileInvestmentsPrefAtom, {
      ...get(companyProfileInvestmentsPrefAtom),
      selectedFields,
    });
  }
);
