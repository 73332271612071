import { atom, selector, useResetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { ICompanyMetricsDataModel } from '../../../data-models/metrics.data-model';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';

export const selectedCompanyIdState = atom<number | null>({
  key: 'selectedCompanyIdTransactionState',
  default: selector({
    key: 'selectedCompanyIdTransactionState/default',
    get: ({ get }) => {
      return get(selectedCompanyIdStateFinance);
    },
  }),
});

export const currentStepState = atom<number>({
  key: 'currentStepTransactionState',
  default: selector({
    key: 'currentStepTransactionState/default',
    get: ({ get }) => {
      if (get(selectedCompanyIdState)) return 1;
      return 0;
    },
  }),
});

export const companyDataState = selector<ICompanyDataModel | null>({
  key: 'companyDataTransactionState',
  get: ({ get }) => {
    const selectedCompanyId = get(selectedCompanyIdState);
    if (!selectedCompanyId) return null;
    return get(companyState(selectedCompanyId));
  },
});

export const investmentDataState = atom<Partial<IFinanceRoundDataModel> | null>({
  key: 'investmentDataState',
  default: null,
});

export const transactionDataState = atom<Partial<ITransactionDataModel> | null>({
  key: 'transactionDataState',
  default: null,
});

export const transactionPreviewState = atom<ICompanyMetricsDataModel | null>({
  key: 'transactionPreviewState',
  default: null,
});

export function useResetTransactionUIState() {
  const resetSelectedCompany = useResetRecoilState(selectedCompanyIdState);
  const resetCurrentStepState = useResetRecoilState(currentStepState);
  const resetInvestmentDataState = useResetRecoilState(investmentDataState);
  const resetTransactionDataState = useResetRecoilState(transactionDataState);
  const resetTransactionPreviewState = useResetRecoilState(transactionPreviewState);

  return useCallback(() => {
    resetSelectedCompany();
    resetCurrentStepState();
    resetInvestmentDataState();
    resetTransactionDataState();
    resetTransactionPreviewState();
  }, [
    resetCurrentStepState,
    resetInvestmentDataState,
    resetSelectedCompany,
    resetTransactionDataState,
    resetTransactionPreviewState,
  ]);
}
