import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { ISelectMeta } from '../data-models/field3.data-model';
import { IDisplayField } from '../view-models/display-field.view-model';
import { percentField, usdField } from './common-schema-defs';
import { CalculationType, Fund, fundFields, fundProfilesFormSchema } from './Fund.schema';

export enum FundViewModelCalcType {
  total = 'Total',
  lpOnly = 'LP Only',
}

const calcTypeMeta = (key: string): IDisplayField<ISelectMeta<FundViewModelCalcType>> => ({
  key,
  renderer: RendererType.singleSelect,
  rendererMeta: {
    values: Object.values(FundViewModelCalcType).map((value) => ({
      value,
      displayName: value,
    })),
  },
});

export function fundViewModelFields() {
  return {
    contributionsCalcType: yup
      .string()
      .label('What contributions are returned as "Return of Capital" before the first tier?')
      .oneOf(Object.values(FundViewModelCalcType))
      .customMeta<IDisplayField<ISelectMeta<FundViewModelCalcType>>>({
        ...calcTypeMeta('_viewModel.contributionsCalcType'),
      })
      .required()
      .default(FundViewModelCalcType.total),
    distributionsCalcType: yup
      .string()
      .label('What distributions do you want to use to calculate distributable proceeds?')
      .oneOf(Object.values(FundViewModelCalcType))
      .customMeta<IDisplayField<ISelectMeta<FundViewModelCalcType>>>({
        ...calcTypeMeta('_viewModel.distributionsCalcType'),
      })
      .required()
      .default(FundViewModelCalcType.total),
    tier1: yup.boolean().nullable().label('Tier 1: Preferred Return').default(true),
    tier2: yup.boolean().nullable().label('Tier 2: Catch-Up').default(true),
    tier3: yup.boolean().nullable().label('Tier 3: Super Return Split').default(true),
    totalAllocationAmount: usdField().nullable().default(null).label(''),
    totalAllocationPercentage: percentField().nullable().default(null).label('Total Allocation'),
  };
}

function fundViewModelBase() {
  return yup.object(fundViewModelFields()).test(calculationTypeTest);
}

export function fundViewModelStep1Schema() {
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
  });
}

export function fundViewModelSchema() {
  const { lpGpSplit, lpGpSplitThreshold, gpCatchUpPercentage, superReturnSplit } = fundFields();
  return fundProfilesFormSchema().shape({
    _viewModel: fundViewModelBase(),
    lpGpSplit: lpGpSplit.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    lpGpSplitThreshold: lpGpSplitThreshold.when('_viewModel.tier1', {
      is: true,
      then: (schema) => schema.required(),
    }),
    gpCatchUpPercentage: gpCatchUpPercentage.when('_viewModel.tier2', {
      is: true,
      then: (schema) => schema.required(),
    }),
    superReturnSplit: superReturnSplit.when('_viewModel.tier3', {
      is: true,
      then: (schema) => schema.required(),
    }),
  });
}

export type FundViewModel = Omit<
  yup.InferType<ReturnType<typeof fundViewModelSchema>>,
  | 'followOnInvestingWindowCloseDate'
  | 'initialInvestingWindowCloseDate'
  | 'investingWindowInceptionDate'
  | 'managementFeeTerminationDate'
> & {
  followOnInvestingWindowCloseDate?: string | null;
  initialInvestingWindowCloseDate?: string | null;
  investingWindowInceptionDate?: string | null;
  managementFeeTerminationDate?: string | null;
};

const ViewSchema = yup.object(fundViewModelFields());
type FundViewModelBase = yup.InferType<typeof ViewSchema>;
function calculationTypeTest(
  value: FundViewModelBase | null | undefined,
  context: yup.TestContext<yup.AnyObject>
) {
  if (!value || !value?.contributionsCalcType || !value?.distributionsCalcType) return true; // other validations will catch this

  if (
    !(
      value.contributionsCalcType === FundViewModelCalcType.total &&
      value.distributionsCalcType === FundViewModelCalcType.lpOnly
    )
  ) {
    return true;
  } else {
    return context.createError({
      // note that we assume value will always be nested in _viewModel
      path: `_viewModel.contributionsCalcType`,
      message: `Contributions must be "${FundViewModelCalcType.lpOnly}" when distributions are "${FundViewModelCalcType.lpOnly}"`,
      type: 'Invalid calculation type',
    });
  }
}

export function fromFundViewModel(fundViewModel: FundViewModel): Fund {
  let calculationType: CalculationType;
  const { _viewModel, ...rest } = fundViewModel;
  const result = { ...rest };

  if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.total &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.total
  ) {
    calculationType = CalculationType.combined;
  } else if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.lpOnly &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.lpOnly
  ) {
    calculationType = CalculationType.lpOnly;
  } else if (
    _viewModel.contributionsCalcType === FundViewModelCalcType.lpOnly &&
    _viewModel.distributionsCalcType === FundViewModelCalcType.total
  ) {
    calculationType = CalculationType.mixed;
  } else {
    throw new Error('Invalid calculation type');
  }

  if (!_viewModel.tier1) {
    result.lpGpSplit = null;
    result.lpGpSplitThreshold = null;
  }
  if (!_viewModel.tier2) {
    result.gpCatchUpPercentage = null;
    result.enableGPCatchup = false;
  } else {
    result.enableGPCatchup = true;
  }
  if (!_viewModel.tier3) {
    result.superReturnSplit = null;
    result.enableSuperReturn = false;
  } else {
    result.enableSuperReturn = true;
  }

  return {
    ...result,
    calculationType,
  };
}

export function toFundViewModel(fund: Fund): FundViewModel {
  let contributionsCalcType, distributionsCalcType;

  switch (fund.calculationType) {
    case CalculationType.combined: {
      contributionsCalcType = FundViewModelCalcType.total;
      distributionsCalcType = FundViewModelCalcType.total;
      break;
    }
    case CalculationType.lpOnly: {
      contributionsCalcType = FundViewModelCalcType.lpOnly;
      distributionsCalcType = FundViewModelCalcType.lpOnly;
      break;
    }

    case CalculationType.mixed: {
      contributionsCalcType = FundViewModelCalcType.lpOnly;
      distributionsCalcType = FundViewModelCalcType.total;
      break;
    }

    default: {
      throw new Error('Invalid calculation type');
    }
  }
  const totalAllocationPercentage =
    (fund.initialInvestmentAllocationPercentage ?? 0) +
    (fund.feesAndExpensesAllocationPercentage ?? 0) +
    (fund.reservesAllocationPercentage ?? 0);

  return {
    ...fund,
    _viewModel: {
      contributionsCalcType,
      distributionsCalcType,
      tier1: fund.lpGpSplit != null || fund.lpGpSplitThreshold != null,
      tier2: fund.enableGPCatchup || fund.gpCatchUpPercentage != null,
      tier3: fund.enableSuperReturn || fund.superReturnSplit != null,
      totalAllocationAmount: (totalAllocationPercentage / 100) * (fund.committedCapital ?? 0),
      totalAllocationPercentage,
    },
  };
}

export function fundCapitalAllocationSchemaStep1() {
  const {
    committedCapital,
    feesAndExpensesAllocationPercentage,
    feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage,
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage,
    recyclingLimitAmount,
    reservesAllocationPercentage,
    reservesAllocationAmount,
  } = fundFields();
  const { totalAllocationAmount, totalAllocationPercentage } = fundViewModelFields();

  return yup.object({
    committedCapital: committedCapital.required().min(10_000_000).max(100_000_000_000),
    feesAndExpensesAllocationPercentage: feesAndExpensesAllocationPercentage.required().min(0),
    feesAndExpensesAllocationAmount: feesAndExpensesAllocationAmount,
    initialInvestmentAllocationPercentage: initialInvestmentAllocationPercentage.required().min(0),
    initialInvestmentAllocationAmount,
    recyclingLimitPercentage: recyclingLimitPercentage.required().min(0),
    recyclingLimitAmount,
    reservesAllocationPercentage: reservesAllocationPercentage.required().min(0),
    reservesAllocationAmount,
    _viewModel: yup.object({
      totalAllocationAmount,
      totalAllocationPercentage,
    }),
  });
}

export function fundCapitalAllocationSchemaStep2() {
  const {
    investingWindowInceptionDate,
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate,
  } = fundFields();

  return yup.object({
    investingWindowInceptionDate: investingWindowInceptionDate.required(),
    initialInvestingWindowCloseDate,
    followOnInvestingWindowCloseDate,
    managementFeeTerminationDate: managementFeeTerminationDate.required(),
  });
}
