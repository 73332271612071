import { useRecoilValue } from 'recoil';
import { fetchCompanyPeople, hidePerson, unHidePerson } from '../../../services/queries/MaggieCompanyQueries';
import { selectedCompanyIdProfile } from '../state/UIState';
import { useInvalidateData } from '../../../hooks/useInvalidateData';
import { peopleByCompanyState } from '../state/PeopleByCompanyState';

export const useHidePerson = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const invalidatePeople = useInvalidateData();

  const invalidate = async () => {
    await invalidatePeople({
      state: peopleByCompanyState(companyId),
      query: () => fetchCompanyPeople(companyId),
    });
  };

  const handleHidePerson = async (personId: number) => {
    await hidePerson({ companyId, personId });
    await invalidate();
  };

  const handleUnHidePerson = async (personId: number) => {
    await unHidePerson({ companyId, personId });
    await invalidate();
  };

  return {
    handleHidePerson,
    handleUnHidePerson,
  };
};
