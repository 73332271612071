import { FDMap } from '../util/data-structure/FDMap';

export interface ITemplate {
  name: string;
  template_key: string;
  description?: string;
}

export interface ITemplatesResponse {
  token: string;
  templates: ITemplate[] | null;
  webhook: string;
}

export const DefaultReminderThreshold = 15;

export const ReminderThresholds = new FDMap([
  [7, '7d'],
  [DefaultReminderThreshold, '15d'],
  [30, '30d'],
]);

export const createTemplateDataModel = (overrides: Partial<ITemplate> = {}): ITemplate => ({
  name: '',
  template_key: '',
  description: '',
  ...overrides,
});
