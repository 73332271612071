import { FC, Fragment, PropsWithChildren, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { fundDataInvestedAmountField } from '../../forms/scenarioFields';
import { useFundIdField } from '../../../../../data-fields/CommonFields';
import { MaxInputWidth } from '../../components/commonStyledComponents';
import { IFundData } from '../../../../../data-models/scenario.data-model';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { getInitialFundDataValues } from './scenarioInitialValues';
import { AddButton, RemoveButton } from './FormButtons';

interface IPartialScenario {
  scenarioTransactions: { fundData: Partial<IFundData>[] }[];
}

interface IFundDataFieldsProps {
  transactionIndex: number;
  disabled?: boolean;
}

export const FundDataFields: FC<IFundDataFieldsProps> = ({ transactionIndex, disabled }) => {
  const { control, formState } = useFormContext<IPartialScenario>();
  const errors = formState.errors?.scenarioTransactions?.[transactionIndex]?.fundData;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `scenarioTransactions.${transactionIndex}.fundData`,
  });

  const baseFundField = useFundIdField();

  const onAddInvestment = useCallback(() => {
    append(getInitialFundDataValues());
  }, [append]);

  const onRemoveInvestment = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  return (
    <FundDataContainer>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <FormFieldWithLabelFactory formField={fundDataInvestedAmountField(transactionIndex, index)} />
            <FormFieldWithLabelFactory
              formField={{
                ...baseFundField,
                key: `scenarioTransactions.${transactionIndex}.fundData.${index}.fundId`,
              }}
            />
            {index > 0 && !disabled ? (
              <RemoveButton
                onClick={() => {
                  onRemoveInvestment(index);
                }}
                disabled={fields.length === 1}
                style={{ alignSelf: 'start', marginTop: '1.5rem' }}
              />
            ) : (
              <div />
            )}
          </Fragment>
        );
      })}

      <div style={{ gridColumn: '1 / -1' }}>
        {!disabled && <AddButton onClick={onAddInvestment}>Add investment</AddButton>}
        {errors && (
          <Typography color='error' variant='caption' component={'div'}>
            {errors.message}
          </Typography>
        )}
      </div>
    </FundDataContainer>
  );
};

const FundDataContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      display='grid'
      gridTemplateColumns={`repeat(2, ${MaxInputWidth}) auto`}
      gap='1rem'
      justifyContent={'start'}
      alignItems={'start'}
      style={{ gridColumn: '1 / -1' }}
    >
      {children}
    </Stack>
  );
};
