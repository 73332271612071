import { CSSProperties, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography, TypographyProps, styled } from '@mui/material';
import { cardStyles } from '../../../../theme/component-styles';

const StyledGrid = styled('div')`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  display: grid;
  column-gap: 1rem;
  align-items: center;
  justify-items: start;
  row-gap: 0.25rem;
`;
export const SubgridRow = styled('div')`
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: subgrid;
  grid-column: 1 / -1;
`;
const GridHeaderRow = styled(SubgridRow)`
  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.caption.fontSize};
  }
  margin-bottom: 0.25rem;
`;

const GridRow = styled(SubgridRow)({ ...cardStyles, minHeight: '3rem' });

export interface IGridCellProps extends TypographyProps {
  type?: 'numeric' | 'text';
  header?: boolean;
}
export function StyledTableCell({ children, type = 'text', header, ...rest }: IGridCellProps) {
  return (
    <Typography
      role='cell'
      noWrap
      variant={type === 'text' || header ? 'body2' : 'monotype'}
      textAlign={type === 'text' ? 'left' : 'right'}
      color={'text.primary'}
      width={'100%'}
      component={'div'}
      {...rest}
    >
      {children}
    </Typography>
  );
}

interface ITableStyle extends CSSProperties {
  gridTemplateColumns: string;
}
export interface IStyledTableProps extends PropsWithChildren {
  style: ITableStyle;
}
export function StyledTable({ style, children }: IStyledTableProps) {
  return (
    <StyledGrid style={style} role='table'>
      {children}
    </StyledGrid>
  );
}

const DataRow = styled(GridRow)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

type IStyledTableRowProps = PropsWithChildren & HTMLAttributes<HTMLDivElement>;
export function StyledTableRow({ children, ...rest }: IStyledTableRowProps) {
  return (
    <DataRow role='row' {...rest}>
      {children}
    </DataRow>
  );
}

export function StyledTableHeaderRow({ children, ...rest }: IStyledTableRowProps) {
  return (
    <GridHeaderRow role='row' {...rest}>
      {children}
    </GridHeaderRow>
  );
}
