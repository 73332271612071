import { Button, Link, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Publish } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { CreateObjectiveData, useObjectiveActions } from '../../../hooks/useObjectiveActions';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { KeyResultPayload } from '../../../../../services/queries/MaggieObjectivesQueries';
import { Circle } from '../../../../DealFlow2/components/DealModal/DealAddModalContent/Circle';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { ObjectiveForm, ObjectiveFormValues } from './ObjectiveForm';
import { KeyResultForm } from './KeyResultForm';
import { ObjectiveData } from './ObjectiveData';
import { EmptyKeyResult } from './EmptyKeyResult';

import { KeyResultsData } from './KeyResultsData';
import { KeyResultInitialValues } from './keyResultInitialValues';
import { SubFormContainer } from './StyledFormComponents';

const FormSubtitleContainer = styled('div')<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
`;

const StyledForm = styled('form')`
  display: grid;
  min-height: 100%;
  row-gap: 1rem;
`;

const ButtonWrapper = styled('div')`
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
`;

const EmptyKRWrapper = styled('button')`
  all: unset;
  &:not([disabled]) {
    cursor: pointer;
  }
`;

interface OkrFormProps {
  onClose: () => void;
}

export const OkrForm: FC<OkrFormProps> = ({ onClose }) => {
  // 0 -> no form open; 1 -> objective form; 2 -> key result form
  const [activeStep, setActiveStep] = useState<0 | 1 | 2>(1);
  const [currentObjective, setCurrentObjective] = useState<CreateObjectiveData | null>(null);
  const [initialObjectiveValues, setInitialObjectiveValues] = useState<ObjectiveFormValues>();
  const [editedKeyResultIndex, setEditedKeyResultIndex] = useState<number | null>(null);

  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const { handleCreateObjective } = useObjectiveActions();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const handleLocalEditObjective = useCallback(() => {
    if (!currentObjective) return;
    const { name, categoryId, ownerId } = currentObjective;
    setActiveStep(1);
    setInitialObjectiveValues({
      name,
      categoryId,
      ownerId,
    });
  }, [currentObjective]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!currentObjective) return;
    setSubmitting(true);

    try {
      const errors = await handleCreateObjective({ ...currentObjective, companyId });
      if (!errors?.length) pushSuccessToast({ message: 'Objective created successfully' });
      else
        pushErrorToast({
          message: `Failed to create ${errors.length} key result${errors.length > 1 ? 's' : ''}`,
        });
    } catch (err) {
      console.error(err);
      pushErrorToast({ message: 'An error occurred while creating an objective.' });
    } finally {
      setSubmitting(false);
      onClose();
    }
  }, [companyId, currentObjective, handleCreateObjective, onClose, pushErrorToast, pushSuccessToast]);

  const onSubmitObjective = useCallback(
    async (values: ObjectiveFormValues) => {
      if (values.categoryId === null || values.ownerId === null || !values.name || !companyId) return;
      setCurrentObjective((prev) => ({
        ...values,
        ownerId: values.ownerId as number,
        companyId,
        keyResults: prev?.keyResults ?? [],
      }));
      if (currentObjective?.keyResults.length) setActiveStep(0);
      else setActiveStep(2);
    },
    [companyId, currentObjective?.keyResults]
  );

  const handleCancelInObjectiveForm = useCallback(() => {
    if (currentObjective) {
      if (currentObjective.keyResults.length > 0) {
        setActiveStep(0);
      } else {
        setActiveStep(2);
      }
    } else onClose();
  }, [currentObjective, onClose]);

  const handleClickOnEmptyObjective = useCallback(() => {
    if (currentObjective) {
      setActiveStep(2);
    }
  }, [currentObjective]);

  const handleSubmitKeyResult = useCallback(
    async (values: KeyResultInitialValues) => {
      if (!currentObjective) return;

      const data = {
        ...values,
        ownerId: currentObjective.ownerId,
        meta: {},
      } as KeyResultPayload;

      if (editedKeyResultIndex !== null) currentObjective.keyResults[editedKeyResultIndex] = data;
      else currentObjective.keyResults.push(data);

      setEditedKeyResultIndex(null);
      setActiveStep(0);
    },
    [currentObjective, editedKeyResultIndex]
  );

  const handleCancelInKeyResultForm = useCallback(() => {
    setActiveStep(0);
  }, []);

  const onEditKeyResult = useCallback(
    (index: number) => {
      if (!currentObjective) return;
      setEditedKeyResultIndex(index);
      setActiveStep(2);
    },
    [currentObjective]
  );

  const index = useMemo(() => currentObjective?.keyResults?.length, [currentObjective?.keyResults?.length]);

  return (
    <StyledForm>
      <>
        {activeStep === 1 && (
          <ObjectiveForm
            onClose={onClose}
            onCancel={handleCancelInObjectiveForm}
            initialValues={initialObjectiveValues}
            onSubmit={onSubmitObjective}
          />
        )}

        {activeStep !== 1 && currentObjective && (
          <ObjectiveData
            name={currentObjective.name}
            categoryId={currentObjective.categoryId as number}
            ownerId={currentObjective.ownerId as number}
            onEdit={handleLocalEditObjective}
          />
        )}

        {activeStep !== 2 && !currentObjective?.keyResults?.length && (
          <EmptyKRWrapper disabled={!currentObjective} onClick={handleClickOnEmptyObjective}>
            <EmptyKeyResult />
          </EmptyKRWrapper>
        )}

        {currentObjective && currentObjective?.keyResults?.length > 0 && (
          <KeyResultsData
            keyResultsData={currentObjective.keyResults.map((kr, index) => ({
              ...kr,
              onEdit: () => onEditKeyResult(index),
            }))}
          />
        )}
        {activeStep === 0 && currentObjective && currentObjective?.keyResults?.length > 0 && (
          <Link
            component='button'
            type='button'
            color='secondary'
            onClick={() => {
              setEditedKeyResultIndex(null);
              setActiveStep(2);
            }}
            underline='hover'
            sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}
          >
            <AddIcon fontSize={'inherit'} /> Add Another Key Result
          </Link>
        )}

        {activeStep === 2 && currentObjective && (
          <SubFormContainer>
            <FormSubtitleContainer justifyContent={index !== undefined && index > 0 ? 'right' : ''}>
              {index === 0 && (
                <Typography
                  component='h6'
                  variant='h6'
                  sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                >
                  <Circle isOpen={true}>
                    <Typography color='white'>{2}</Typography>
                  </Circle>
                  Key Result
                </Typography>
              )}
            </FormSubtitleContainer>
            <KeyResultForm
              onClose={onClose}
              onSubmit={handleSubmitKeyResult}
              onCancel={handleCancelInKeyResultForm}
              initialValues={
                editedKeyResultIndex !== null && currentObjective
                  ? currentObjective.keyResults[editedKeyResultIndex]
                  : undefined
              }
            />
          </SubFormContainer>
        )}
      </>

      <ButtonWrapper>
        <LoadingButton
          type='button'
          onClick={onSubmit}
          variant='contained'
          color={'secondary'}
          size={'large'}
          disabled={currentObjective === null || currentObjective?.keyResults?.length === 0 || submitting}
          loading={submitting}
          loadingPosition='end'
          endIcon={<Publish />}
        >
          Submit
        </LoadingButton>
        <Button
          type='button'
          onClick={onClose}
          disabled={submitting}
          variant='outlined'
          size='large'
          color='secondary'
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </StyledForm>
  );
};
