import { SerializedEditorState, SerializedElementNode } from 'lexical';
import { ICommentDataModel } from '../pages/DealFlow2/data-models/comment.data-model';
import { ICompanyDataModel } from './company.data-model';
import { INoteCommentDataModel } from './note-comment.data-model';

export type NoteType = 'string' | 'lexicaljs-json' | 'editorjs-json';

interface IBaseNoteDataModel {
  companyIds: number[];
  createdAt: string;
  createdBy: string;
  draft: boolean;
  linkedNoteId: number | null;
  tags: string[];
  title: string;
  updatedAt: string;
  updatedBy: string | null;
  version: number;
  comments?: INoteCommentDataModel[];
}

export type NoteDataModelBody<T> = {
  type: NoteType;
  data: T;
  attendees?: INoteAttendeeDataModel[];
};

export interface INoteDataModel<T> extends IBaseNoteDataModel {
  id: number;
  body: NoteDataModelBody<T>;
}

// userId is the id of the user who is the attendee
// rating is the rate the note's author gave to the note's attendees. It is a number between 0 and 5.
// -1 indicate it is not rated yet.
export type INoteAttendeeDataModel = {
  userId: number;
  rating: number;
};

export type INoteDraftDataModel<T> = Omit<INoteDataModel<T>, 'id'>;

export interface INotePayloadDataModel extends IBaseNoteDataModel {
  body: string;
}

export interface NoteComment extends ICommentDataModel {
  noteId: number;
}

export interface INoteResponseDataModel extends INotePayloadDataModel {
  id: number;
}

export interface ICreateNoteResponsePayload<T> {
  companies: ICompanyDataModel[];
  note: INoteDataModel<T>;
}

export interface IGetNotesRawResponse {
  companies: ICompanyDataModel[];
  notes: INoteResponseDataModel[];
}

export interface ILexicalNoteFormValues
  extends Pick<INoteDataModel<SerializedEditorState>, 'id' | 'version' | 'title'> {
  body: NoteDataModelBody<SerializedEditorState> & {
    attendees: INoteAttendeeDataModel[];
  };
}

export function createNoteAttendeeDataModel(
  overrides?: Partial<INoteAttendeeDataModel>
): INoteAttendeeDataModel {
  return {
    userId: 1,
    rating: -1,
    ...overrides,
  };
}

export function createNoteDataModel<T>(overrides: Partial<INoteDataModel<T>>): INoteDataModel<T> {
  return {
    body: {
      type: 'string',
      data: '' as T,
      attendees: [],
    },
    companyIds: [],
    createdAt: '',
    createdBy: '',
    draft: false,
    id: -1,
    linkedNoteId: null,
    tags: [],
    title: '',
    updatedAt: '',
    updatedBy: null,
    version: -1,
    ...overrides,
  };
}

export function createDraftNoteModel() {
  return {
    root: {
      children: [
        {
          children: [],
          direction: null,
          format: '',
          indent: 0,
          type: 'paragraph',
          version: 1,
        },
      ] as SerializedElementNode[],
      direction: null,
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  } as SerializedEditorState;
}
