import { useRecoilValue } from 'recoil';
import { fundsState } from '../../../services/state/AppConfigState';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { getFundFields } from './FundFields';

export function ConfigFund() {
  const funds = useRecoilValue(fundsState);

  return (
    <GenericConfigPage
      baseTitle={'Fund'}
      data={funds}
      fields={getFundFields()}
      pageTitle={'Funds'}
      queryURL={'/fund'}
    />
  );
}
