import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { IconTypes } from '../../../Icon';
import { ChartTitleWrapper } from '../../ChartTitleWrapper';
import { ChartWrapper } from '../../ChartWrapper/ChartWrapper';
import { CoInvestorChartItem } from '../../../../types';
import { Chart, ChartSizes } from '../../chart-utils';
import { MostCommonCoInvestorsRow } from './MostCommonCoInvestorsRow';

interface Props {
  id: string;
  width: number;
  data: CoInvestorChartItem[];
}

const RowsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 9px;
  text-align: center;
  height: 100%;
  overflow: auto;
`;

export function MostCommonCoInvestorsChart({ id, width, data }: Props): ReactElement {
  const [showMore, setShowMore] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [widthState, setWidthState] = useState<string | number>(width);
  const [size, setSize] = useState('1/2 Screen');
  const ref = useRef(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={() => {
        setShowMore(false);
        setIsHovering(false);
      }}
      onMouseEnter={() => setIsHovering(true)}
      dataTestid={'mostCommonCoInvestorsChart'}
      width={widthState}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={'Most Common Co-Investors'}
        showMore={showMore}
        setShowMore={setShowMore}
        id={id}
        icon={IconTypes.STATISTICS_1}
        handleSizeChange={setSize}
        refProp={ref}
        size={size}
      />
      <Chart width={'100%'} ref={ref}>
        <RowsWrapper>
          {data.map((i, k) => (
            <MostCommonCoInvestorsRow name={i.name} deals={i.deals} key={k} />
          ))}
        </RowsWrapper>
      </Chart>
    </ChartWrapper>
  );
}
