import { FC, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { personState } from '../../../../services/state/PersonState';
import { ErrorOccurredMessage } from '../../components/Messages/HelpMessage';
import { Loader } from '../../../../components/Loader/Loader';
import { PersonGeneralInfo } from './PersonGeneralInfo';
import { PersonWorkHistory } from './PersonWorkHistory';
import { EducationHistory } from './EducationHistory';

const Main = styled('main')`
  padding: 1rem 1.5rem;
`;

export const PersonTabs = [
  { value: 0, label: 'General' },
  { value: 1, label: 'Work History' },
  { value: 2, label: 'Education History' },
];

interface IPersonMainContentProps {
  personId: number;
}

export const PersonMainContent: FC<IPersonMainContentProps> = ({ personId }) => {
  const personLoadable = useRecoilValueLoadable(personState(personId));
  const person = personLoadable.valueMaybe();

  const [activeTab, setActiveTab] = useState(0);

  if (personLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (personLoadable.state === 'loading') return <Loader />;
  if (!person) return null;

  return (
    <Main>
      <Tabs
        variant='scrollable'
        scrollButtons='auto'
        value={activeTab}
        onChange={(e, tab) => setActiveTab(tab)}
        style={{ marginBottom: '1rem' }}
      >
        {PersonTabs.map((t) => (
          <Tab
            key={t.value}
            value={t.value}
            label={t.label}
            style={{ paddingLeft: 0, marginRight: '1rem' }}
          />
        ))}
      </Tabs>
      {activeTab === 0 && <PersonGeneralInfo person={person} />}
      {activeTab === 1 && <PersonWorkHistory person={person} />}
      {activeTab === 2 && <EducationHistory person={person} />}
    </Main>
  );
};
