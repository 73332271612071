import { atom } from 'recoil';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { BulkTemplateAssignmentError } from '../../../../schemas/BulkTemplateAssignmentResponse.schema';
import { IKpiCompanyData } from './AllCompaniesData';

export type KpiCompaniesAction =
  | 'assign'
  | 'confirmStopReporting'
  | 'createRequest'
  | 'deleteAssignment'
  | 'editAssignment'
  | 'manage';
export const allCompaniesActionState = atom<KpiCompaniesAction | null>({
  key: 'allCompaniesActionState_data_collection',
  default: null,
});

/**
 * Snapshot of selected companies at the time of action (can change inside the form)
 * Determines the number of steps in the stepper, to avoid that number changing
 * with user interaction.
 * To get current form data for selected companies, use currentFormCompanyDataState and
 * companiesWithMissingDataState selectors
 */
export const selectedKpiCompaniesStateDC = atom<(ICompanyDataModel | IKpiCompanyData)[]>({
  key: 'selectedCompanyState_data_collection',
  default: [],
});

export const selectedAssignmentIdState = atom<number | null>({
  key: 'selectedAssignmentIdState_data_collection',
  default: null,
});

export const assignmentErrorsState = atom<BulkTemplateAssignmentError[] | null>({
  key: 'assignmentErrorsState_data_collection',
  default: null,
});
