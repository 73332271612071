import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Stack, styled, Typography, useTheme } from '@mui/material';
import OneSchemaImporter from '@oneschema/react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { AgTable } from '../../../components/AgTable/AgTable';
import { PageContainer } from '../../../components/Layout/PageContainer';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { ZINDEX } from '../../../constants/styles';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';
import {
  customFieldsListState,
  customFieldsOpenUploadModalState,
  customFieldsSelectedTemplateState,
  templatesTypeListState,
} from '../../../services/state/AdminPanel/CustomFieldsState';
import { getEnvVariable, isDevelopment } from '../../../util/environment';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { CustomFieldForm2 } from '../forms2/CustomFieldForm2';
import { CreateCustomField } from '../modals/CreateCustomField';
import UploadBulkModal from '../modals/UploadBulkModal';
import { useBulkUploadStatus } from './hooks/useBulkUploadStatus';
import useCustomFieldsColDefs from './hooks/useCustomFieldsColDefs';

const HeaderContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const HeaderSlotRight = styled('div')`
  display: flex;
  height: 40px;
  justify-content: center;
  gap: 0.5rem;
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;

const GridContainer = styled('div')`
  width: 100%;
  flex: 1;
  margin-top: 1rem;
  & .ag-cell:has(.fields-menu) {
    padding: 0;
  }
`;

export function CustomFields() {
  const { colors } = useTheme();
  const [customFieldsOpenUploadModal, setCustomFieldsOpenUploadModal] = useRecoilState(
    customFieldsOpenUploadModalState
  );
  const customFieldsSelectedTemplate = useRecoilValue(customFieldsSelectedTemplateState);

  const templatesTypeList = useRecoilValueLoadable(templatesTypeListState).valueMaybe();
  const oneSchemaClientId = getEnvVariable('VITE_ONESCHEMA_CLIENT_ID');
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [openUploadBulkModal, setOpenUploadBulkModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const customFields = useRecoilValueLoadable(customFieldsListState);
  const customFieldsList = customFields.valueMaybe();
  const { columnDefs, defaultColDef } = useCustomFieldsColDefs();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { showBulkUploadStatusUpdates, showCustomFieldForm2 } = useFlags<MaggieFeatureFlags>();

  const selectedTemplateKey = useMemo(() => {
    if (!templatesTypeList?.templates) return;

    return templatesTypeList?.templates?.find((template) =>
      template.template_key.includes(customFieldsSelectedTemplate)
    )?.template_key;
  }, [customFieldsSelectedTemplate, templatesTypeList?.templates]);

  const handleSuccessUpload = useCallback(() => {
    setCustomFieldsOpenUploadModal(false);
    if (showBulkUploadStatusUpdates) return;
    pushSuccessToast({ message: `Upload successful` });
    false;
  }, [pushSuccessToast, setCustomFieldsOpenUploadModal, showBulkUploadStatusUpdates]);

  const handleErrorUpload = useCallback(() => {
    setCustomFieldsOpenUploadModal(false);
    if (showBulkUploadStatusUpdates) return;
    pushErrorToast({ message: 'An error occurred while uploading the file' });
  }, [pushErrorToast, setCustomFieldsOpenUploadModal, showBulkUploadStatusUpdates]);

  const handleClickBulkUpload = useCallback(() => {
    setOpenUploadBulkModal(true);
  }, []);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const { subscribe, unsubscribe } = useBulkUploadStatus();

  useEffect(() => {
    if (!showBulkUploadStatusUpdates) return;
    subscribe();
    return unsubscribe;
  }, [showBulkUploadStatusUpdates, subscribe, unsubscribe]);

  const onSearchTermChange = (value: string) => {
    if (!gridApi) return;
    gridApi.setGridOption('quickFilterText', value);
    setSearchTerm(value);
  };

  if (!customFieldsList && customFields.state !== 'loading') {
    return (
      <div style={{ marginTop: '2rem' }}>
        <NoDataMessage2 />
      </div>
    );
  }

  return (
    <PageContainer>
      <OneSchemaImporter
        isOpen={customFieldsOpenUploadModal}
        onRequestClose={() => {
          setCustomFieldsOpenUploadModal(false);
        }}
        clientId={oneSchemaClientId}
        userJwt={templatesTypeList?.token ?? ''}
        templateKey={selectedTemplateKey as string}
        importConfig={{ type: 'webhook', key: templatesTypeList?.webhook ?? '' }}
        devMode={isDevelopment()}
        className='oneschema-importer'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: ZINDEX.UPLOAD_MODAL,
        }}
        onSuccess={handleSuccessUpload}
        onError={handleErrorUpload}
        onLaunched={() => {
          setOpenUploadBulkModal(false);
        }}
      />
      <UploadBulkModal open={openUploadBulkModal} onClose={() => setOpenUploadBulkModal(false)} />
      {openCreateModal && showCustomFieldForm2 && (
        <CustomFieldForm2 onClose={() => setOpenCreateModal(false)} />
      )}
      {!showCustomFieldForm2 && (
        <CreateCustomField open={openCreateModal} onClose={() => setOpenCreateModal(false)} />
      )}
      <HeaderContainer>
        <Container>
          <Typography variant='h4' color={colors.primary[90]}>
            Fields
          </Typography>
          <HeaderSlotRight>
            <Button
              onClick={handleClickBulkUpload}
              color='secondary'
              variant='outlined'
              size='medium'
              startIcon={<FileUploadIcon />}
            >
              Bulk Upload
            </Button>
            <Button
              color='secondary'
              variant='contained'
              size='medium'
              startIcon={<AddIcon />}
              onClick={() => setOpenCreateModal(true)}
            >
              Create Custom Field
            </Button>
          </HeaderSlotRight>
        </Container>
        <Stack direction='row' width='100%' alignItems={'center'} height='40px' gap='1rem' marginTop='1rem'>
          <SearchInput
            style={{ width: '200px' }}
            value={searchTerm}
            onChange={onSearchTermChange}
            placeholder='Search for a field'
          />
        </Stack>
      </HeaderContainer>
      <GridContainer>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          groupIncludeTotalFooter={false}
          onGridReady={onGridReady}
          rowData={customFieldsList}
          rowGroupPanelShow='never'
          suppressMovableColumns={true}
          suppressRowDrag={true}
        />
      </GridContainer>
    </PageContainer>
  );
}
