import { useRecoilValueLoadable } from 'recoil';
import { useConnectCompetitiveIntelligenceMetrics } from '../../CompetitiveIntelligence/hooks/useConnectCompetitiveIntelligenceMetrics';
import { useConnectCompetitiveIntelligenceSeriesMetrics } from '../../CompetitiveIntelligence/hooks/useConnectCompetitiveIntelligenceSeriesMetrics';
import { dashboardsCI, fieldsCI, packagesCI } from '../../CompetitiveIntelligence/state/DashboardsState';

export const useInitCiData = () => {
  // fetch data
  const fields = useRecoilValueLoadable(fieldsCI);
  const packages = useRecoilValueLoadable(packagesCI);
  const dashboards = useRecoilValueLoadable(dashboardsCI);

  useConnectCompetitiveIntelligenceMetrics();
  useConnectCompetitiveIntelligenceSeriesMetrics();

  return fields.state === 'hasValue' && packages.state === 'hasValue' && dashboards.state === 'hasValue';
};
