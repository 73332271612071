import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { useRecoilState } from 'recoil';
import { FormContainer } from '../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FundViewModel, fundViewModelStep1Schema } from '../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { fundFormCurrentStepState, fundFormState } from '../state/FPState';
import { IFundFormProps } from './FundForm';

const step1Fields: FieldPath<FundViewModel>[] = [
  '_viewModel.distributionsCalcType',
  '_viewModel.contributionsCalcType',
  'isProceedsPercentAdjusted',
];

interface IFundFormStep1Props extends Pick<IFundFormProps, 'defaultValues'> {}

export function FundFormStep1({ defaultValues }: IFundFormStep1Props) {
  const [formData, setFormData] = useRecoilState(fundFormState);
  const fields = useMemo(() => {
    return schemaToFormFields(fundViewModelStep1Schema(), step1Fields);
  }, []);
  const methods = useForm<FundViewModel>({
    defaultValues: formData ?? defaultValues,
    mode: 'all',
    resolver: yupResolver(fundViewModelStep1Schema() as ObjectSchema<FundViewModel>),
  });

  const handleNext = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setFormData((curr) => ({ ...curr, ...methods.getValues() }));
      return true;
    } else {
      return false;
    }
  }, [methods, setFormData]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        {fields.map((field) => {
          return <FormFieldWithLabelFactory key={field.key} formField={field} />;
        })}
      </FormContainer>
      <StepperFormButtons stepIsValid={handleNext} stepState={fundFormCurrentStepState} />
    </FormProvider>
  );
}
