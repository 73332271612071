import { useRecoilValue } from 'recoil';
import { Divider } from '@mui/material';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { allCompaniesActionState, selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';
import { IManageCompanyFormProps, ManageSingleCompanyForm } from './ManageCompanyForm';

export function ManageModal() {
  const selectedCompany = useRecoilValue(selectedKpiCompaniesStateDC).at(0);
  const { resetUIState: handleCloseModal } = useAllKpiCompaniesActions();
  const open = useRecoilValue(allCompaniesActionState) === 'manage';
  return (
    <BasicDialog open={open} title='Manage Company Settings' onClose={handleCloseModal}>
      {open && (
        <FormFieldsContainer>
          <CompanyLogoAndName name={selectedCompany?.name ?? ''} logoUrl={selectedCompany?.logoUrl ?? ''} />
          <Divider />
          <ManageSingleCompanyForm
            defaultValues={selectedCompany as IManageCompanyFormProps['defaultValues']}
            companyId={selectedCompany!.id}
          />
        </FormFieldsContainer>
      )}
    </BasicDialog>
  );
}
