import { Stack, StackProps, styled } from '@mui/material';
import { FC } from 'react';
import { formCardStyles } from '../Scenarios/components/commonStyledComponents';

export const FormCard: FC<StackProps> = ({ children, style = {}, ...rest }) => {
  return (
    <Stack
      style={{ ...formCardStyles, paddingBlock: '1rem', ...style }}
      display={'grid'}
      gap='1rem'
      {...rest}
    >
      {children}
    </Stack>
  );
};

export const GridCell = styled('td')`
  border: thin solid ${({ theme }) => theme.colors.neutral[20]};
  padding: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GridHeader = styled('th')`
  background: ${({ theme }) => theme.colors.neutral[10]};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 400;
  text-align: left;
  border: thin solid ${({ theme }) => theme.colors.neutral[20]};
  padding: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledInvestmentGrid = styled('table')`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: thin solid ${({ theme }) => theme.colors.neutral[20]};
`;
