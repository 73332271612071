import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { ShareClass } from '../../../view-models/captable.view-model';
import { ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { clientInvestmentsState, otherInvestmentsState } from './CapTableFormState';
import {
  useSyncClientInvestmentDataWithSharesState,
  useSyncOtherInvestmentDataWithSharesState,
} from './useSyncInvestmentDataWithSharesState';

export interface IInvestmentGridRow extends Record<string, unknown> {
  fundId: number | null;
}

export function useInvestmentGridRowData() {
  const clientInvestments = useRecoilValue(clientInvestmentsState);
  const syncInvestments = useSyncClientInvestmentDataWithSharesState();

  const getInitialRowData = useCallback((): IInvestmentGridRow[] => {
    return syncInvestments(cloneDeep(clientInvestments));
  }, [clientInvestments, syncInvestments]);

  return { getInitialRowData };
}

export interface IOtherInvestmentsGridRow extends Record<string, unknown> {
  investor: string;
}

export function useOtherInvestmentsRowData() {
  const otherInvestments = useRecoilValue(otherInvestmentsState);
  const syncOtherInvestments = useSyncOtherInvestmentDataWithSharesState();

  const getInitialRowData = useCallback((): IOtherInvestmentsGridRow[] => {
    return cloneDeep(syncOtherInvestments(otherInvestments));
  }, [otherInvestments, syncOtherInvestments]);

  return { getInitialRowData };
}
export const ExcludedShareClasses = new Set<ViewModelInvestmentType>([ViewModelInvestmentType.options]);

export function getClientInvestmentsShareClasses(shareClasses: ShareClass[]) {
  return [...shareClasses].filter((shareClass) => !ExcludedShareClasses.has(shareClass.type));
}
