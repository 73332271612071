import { FC, useCallback, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BasicDialog, IFormDialogProps } from '../../../../components/Dialog/BasicDialog';
import { InfoBox } from '../../../Mappings/forms/InfoBox';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { StyledColumnBox } from '../components/CommonStyledComponents';
import { AirtableSyncType } from '../../../../data-models/airtable-sync.data-model';

const ContentContainer = styled('div')`
  padding: 0 2.5rem 2rem;
  display: grid;
  gap: 1rem;
  width: 42rem;
  max-width: 85vw;
`;

const ColumnInfoGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
`;

interface IColumnData {
  col1Name: string;
  col2Name: string;
  syncType: AirtableSyncType;
}

interface IConfirmSyncDialogProps extends IFormDialogProps {
  columnPairs: IColumnData[];
  onConfirm: () => Promise<void>;
  onClose: () => void;
}

export const ConfirmSyncDialog: FC<IConfirmSyncDialogProps> = ({
  columnPairs,
  onConfirm,
  onClose,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  }, [onConfirm, onClose]);

  return (
    <BasicDialog onClose={onClose} {...rest}>
      <ContentContainer>
        <Typography variant='body2' color={colors.neutral[70]} style={{}}>{`Selected ${
          columnPairs.length
        } column${columnPairs.length > 1 ? 's' : ''}`}</Typography>
        {columnPairs.map((colPair) => (
          <ColumnInfoGrid key={colPair.col1Name}>
            <StyledColumnBox>
              <Typography variant='body2' color={colors.neutral[70]}>
                {colPair.col1Name}
              </Typography>
            </StyledColumnBox>
            {colPair.syncType === AirtableSyncType.editable ? (
              <ArrowBackIcon color='disabled' />
            ) : (
              <ArrowForwardIcon color='disabled' />
            )}
            <StyledColumnBox>
              <Typography variant='body2' color={colors.neutral[70]}>
                {colPair.col2Name}
              </Typography>
            </StyledColumnBox>
          </ColumnInfoGrid>
        ))}
        <InfoBox message='Synchronization with Airtable will run nightly' />
        <StickyFormButtons
          onSubmit={handleSubmit}
          onCancel={onClose}
          submitLabel='Confirm'
          loading={loading}
          disabled={loading}
          style={{ padding: '1rem 0 0' }}
        />
      </ContentContainer>
    </BasicDialog>
  );
};
