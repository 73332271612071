import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { useRecoilValue } from 'recoil';
import { IPersonDataModel } from '../../../../../data-models/person.data-model';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { getTitlesForCompany } from '../../../utils/peopleHelpers';
import { IMUIAvatarProps, MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { RelationshipScore } from '../../../../../components/RelationshipScore/RelationshipScore';
import { SocialMedia } from './SocialMedia';

const Container = styled('div')`
  display: grid;
  min-width: fit-content;
  min-height: fit-content;
`;

const NameAndTitleContainer = styled('div')`
  padding-top: 0.1rem;
  min-width: 0;

  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MainInfoContainer = styled('div')`
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.25rem;

  grid-template-columns: min-content 1fr;
`;

export interface PersonDataProps {
  person: IPersonDataModel;
  avatarSize?: IMUIAvatarProps['size'];
}
export const PersonData: FC<PersonDataProps> = ({ person, avatarSize = 'large' }) => {
  const currentCompany = useRecoilValue(selectedCompanyIdProfile);
  const {
    firstName,
    lastName,
    primaryEmail,
    secondaryEmails,
    socials,
    logoUrl,
    relationshipStrengthScoreNormalized,
  } = person;
  const { city = '', state = '', country = '' } = person.locations?.at(0) ?? {};
  const locationString = [city, state, country].filter(Boolean).join(', ');
  const titles = getTitlesForCompany(person, currentCompany);

  return (
    <Container>
      <MainInfoContainer>
        <MUIAvatar size={avatarSize} nameParts={[firstName ?? '', lastName ?? '']} src={logoUrl} />
        <NameAndTitleContainer>
          <Typography variant='body2' component='p' color='text.primary'>{`${firstName ?? ''} ${
            lastName ?? ''
          }`}</Typography>
          <Typography variant='caption' component='p' color='text.secondary' noWrap>
            {titles}
          </Typography>
        </NameAndTitleContainer>
      </MainInfoContainer>
      <Stack my='0.2rem'>
        {relationshipStrengthScoreNormalized && (
          <RelationshipScore
            relationshipStrengthScoreNormalized={relationshipStrengthScoreNormalized ?? null}
          />
        )}
      </Stack>
      {location && (
        <Stack direction='row' spacing={1} color='text.secondary' minWidth={'1rem'} mb='0.2rem'>
          <PlaceOutlinedIcon fontSize='inherit' htmlColor='inherit' />
          <Typography variant='caption' component='p' noWrap>
            {locationString}
          </Typography>
        </Stack>
      )}
      <SocialMedia
        companyId={currentCompany}
        personId={person.id}
        primaryEmail={primaryEmail}
        secondaryEmails={secondaryEmails}
        twitterUrl={socials?.twitter}
        linkedinUrl={socials?.linkedin}
        facebookUrl={socials?.facebook}
        style={{ marginTop: '0' }}
      />
    </Container>
  );
};
