import { useCallback } from 'react';
import { atom, selector, useResetRecoilState } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { IField } from '../../../data-models/field2.data-model';
import { CustomFieldFormType } from './CustomField.schema';
import { toFieldViewModel } from './custom-field-form.view-model';

export const CustomFieldFormInitialValuesState = atom<IField<unknown> | null>({
  key: 'CustomFieldFormInitialValuesState',
  default: null,
});

export const CustomFieldFormDataState = atom<Partial<CustomFieldFormType> | null>({
  key: 'CustomFieldFormDataState',
  default: selector({
    key: 'CustomFieldFormDataStateDefault',
    get: ({ get }) => {
      const initialValues = get(CustomFieldFormInitialValuesState);
      if (!initialValues) return null;
      return cloneDeep(toFieldViewModel(initialValues));
    },
  }),
});

export const CustomFieldFormCurrentStepState = atom<number>({
  key: 'CustomFieldFormCurrentStepState',
  default: 0,
});

export function useResetCustomFieldFormState() {
  const resetStep1 = useResetRecoilState(CustomFieldFormDataState);
  const resetInitialState = useResetRecoilState(CustomFieldFormInitialValuesState);
  const resetCurrentStep = useResetRecoilState(CustomFieldFormCurrentStepState);

  return useCallback(() => {
    resetStep1();
    resetInitialState();
    resetCurrentStep();
  }, [resetCurrentStep, resetStep1, resetInitialState]);
}
