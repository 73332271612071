import { CashFlow } from '@webcarrot/xirr';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { LineChartData, TQuoter } from '../../../types';
import { calculateXIRR } from '../providers/calculateXIRR';
import { QuartersBuilder } from '../services/QuartersBuilder';
import { mapLineChartData } from '../../../components/Charts/LineChart/mapLineChartData';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { selectedViewDatePF } from '../state/PageState';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { MetricsTransactionDataModel } from '../../../schemas/MetricsTransaction.schema';

type TQuoterInvestments = Record<string, CashFlow[]>;

export type GroupExtractorFn = (params: {
  transaction: MetricsTransactionDataModel;
  company: ICompanyDataModel;
}) => string;

interface IrrByGroup {
  data: LineChartData[];
  last12Quarters: TQuoter[];
}

export function useIrrByGroup(
  filteredData: IMetricsDataModel[],
  transactionGroupExtractor: GroupExtractorFn
): IrrByGroup {
  const date = useRecoilValue(selectedViewDatePF);
  const last12Quarters = useMemo(
    () =>
      QuartersBuilder.last12Quarters(date ?? new Date()).sort(
        (a: TQuoter, b: TQuoter) => a.start.getTime() - b.start.getTime()
      ),
    [date]
  );
  const getCompany = useGetCompanyIfSet();

  const data = useMemo(() => {
    const datasetPerGroup: Record<string, TQuoterInvestments> = {};

    function getTransactionQuoters(transaction: MetricsTransactionDataModel, quoters: TQuoter[]): TQuoter[] {
      if (!transaction.transactionDate) return [];

      const tDate = new Date(transaction.transactionDate);
      tDate.setHours(0);
      tDate.setMinutes(0);
      tDate.setSeconds(0);

      return quoters.filter((q) => tDate <= q.end);
    }

    function getFundQuoters(quoters: TQuoter[]) {
      return quoters.reduce((map, q) => ({ ...map, [q.label]: [] }), {});
    }

    filteredData?.forEach((rawMetric) => {
      rawMetric.transactions.forEach((transaction) => {
        const company = getCompany(rawMetric.companyId);
        if (!company) return;
        const transactionGroup = transactionGroupExtractor({ transaction, company });
        if (!transactionGroup) return;

        if (!datasetPerGroup[transactionGroup]) {
          datasetPerGroup[transactionGroup] = getFundQuoters(last12Quarters);
        }

        const transactionQuoters = getTransactionQuoters(transaction, last12Quarters);

        if (!transactionQuoters?.length) return;

        transactionQuoters.forEach((quoter) => {
          if (!datasetPerGroup[transactionGroup][quoter.label]) {
            datasetPerGroup[transactionGroup][quoter.label] = [];
          }

          if (transaction.investmentAmount) {
            datasetPerGroup[transactionGroup][quoter.label].push({
              date: new Date(transaction.transactionDate),
              amount: -1 * transaction.investmentAmount,
            });
          }
          if (transaction.distributions) {
            datasetPerGroup[transactionGroup][quoter.label].push({
              date: new Date(transaction.transactionDate),
              amount: transaction.distributions,
            });
          }

          if (transaction.currentInvestment) {
            datasetPerGroup[transactionGroup][quoter.label].push({
              date: quoter.end,
              amount: transaction.currentInvestment,
            });
          }
        });
      });
    });

    const fundQuarterXirr: Record<string, Record<string, number>> = {};
    Object.keys(datasetPerGroup).forEach((fundName) => {
      Object.keys(datasetPerGroup[fundName]).forEach((quarter) => {
        const investments = datasetPerGroup[fundName][quarter];

        fundQuarterXirr[quarter] = {
          ...(fundQuarterXirr[quarter] ?? {}),
          [fundName]: investments.length < 2 ? 0 : calculateXIRR(investments),
        };
      });
    });

    const data = Object.keys(fundQuarterXirr).map((quoter) => ({
      ...(fundQuarterXirr[quoter] as Record<string, number>),
      period: quoter,
    }));
    return mapLineChartData(data);
  }, [filteredData, getCompany, last12Quarters, transactionGroupExtractor]);

  return useMemo(() => ({ data, last12Quarters }), [data, last12Quarters]);
}
