import { SxProps } from '@mui/material';
import { css } from '@emotion/react';

export const HeaderButton = css`
  width: 2.3rem;
  height: 2.3rem;
`;

export const ContainerName = {
  headerContainer: 'header-container',
  fundProfilesContainer: 'fund-profiles-container',
  rubricSectionContainer: 'rubric-section-container',
};

export const Breakpoint = {
  small: '40rem',
  hideBreadcrumbs: '50rem',
  profilePanels: '900px',
};

export const ZINDEX = {
  EDITABLE_TEXT_OPTIONS_TOOLTIP: '1',
  FORM_STEPPER: '2',
  POPPER_TOOLTIP: '1201', // mui Drawer has a default z-index of 1200
  PRESENT_MODE_HEADER: '10014',
  ELLIPSIS_TEXT: '10015',
  MODAL: '10016',
  MENU_INDEX: '10017',
  CALENDAR_POPUP: '10018',
  TOAST_MESSAGE: '10018',
  UPLOAD_MODAL: '19999',
};

export const MaxHeaderHeightInRems = 4.25;
export const MaxNav2HeaderHeight = '3rem';
export const TextFieldXPadding = 16;
export const NavbarWidth = 72;
export const SideBarWidth = 320;

export const FormBottomPadding = '2rem';

export const ModalFormWidth = '43.5rem';
export const MaxModalFormWidth = 'calc(100vw - 6rem)';
export const MaxModalFormHeight = 'calc(100vh - 20rem)'; // inner scrollable part, not counting header and footer with buttons

export const NoBorderAutocompleteSx: SxProps = {
  '& .MuiOutlinedInput-root': {
    border: '1px solid transparent',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
  '& .MuiFormControl-root': {
    m: 0,
  },
  p: 0,
  m: 0,
};

export const BorderlessTooltipProps = {
  componentsProps: {
    tooltip: {
      sx: {
        border: 'none',
      },
    },
  },
};
