import {
  createFieldDataModel,
  createFieldFormConfigDataModel,
  FieldEntity,
  IField,
  ISelectConfig,
  ITextFieldConfig,
  RendererTypeOfComponent,
} from '../../../data-models/field2.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import {
  createKPITemplateSectionDataModel,
  IKPITableDataModel,
} from '../../../data-models/kpi-template.data-model';
import { Formatting } from '../../../types';
import { KpiSection } from '../../../data-models/company-financials.data-model';

export function createKPIItemByType(sectionType: string, universalKpis: IField<unknown>[]) {
  let sectionMeta: IField<unknown> | IKPITableDataModel;

  switch (sectionType) {
    case RendererType.multiSelect:
      sectionMeta = createFieldDataModel<string, ISelectConfig<string>>({
        entity: 'template',
        entityField: 'New Question',
        type: RendererType.multiSelect,
        formMeta: createFieldFormConfigDataModel<string, ISelectConfig<string>>({
          required: false,
          renderer: {
            type: RendererType.multiSelect,
            config: {
              placeholder: 'Select options',
              multi: true,
              values: [],
            },
          },
        }),
      });
      break;

    case RendererType.fileUpload:
      sectionMeta = createFieldDataModel<string, ITextFieldConfig>({
        entity: 'template',
        entityField: 'New File',
        description: 'Select XLSX or CSV file to upload your financial data',
        type: RendererType.fileUpload,
        formMeta: createFieldFormConfigDataModel<string, ITextFieldConfig>({
          required: false,
          renderer: {
            type: RendererType.fileUpload,
            config: {
              placeholder: 'info',
              maxLength: -1,
            },
          },
        }),
      });
      break;

    case RendererType.kpiTable:
      sectionMeta = {
        displayName: 'Universal KPIs',
        description: '',
        metrics: [],
        section: KpiSection.actual,
      } as IKPITableDataModel;
      break;

    case RendererType.universalKpiTable:
      sectionMeta = {
        displayName: 'Universal KPIs',
        description: '',
        metrics: [...universalKpis],
        section: KpiSection.actual,
      } as IKPITableDataModel;
      break;

    case RendererType.text:
    default:
      sectionMeta = createFieldDataModel<string, ITextFieldConfig>({
        entity: 'template',
        entityField: 'New Question',
        type: RendererType.text,
        formMeta: createFieldFormConfigDataModel<string, ITextFieldConfig>({
          renderer: {
            type: RendererType.text,
            config: {
              placeholder: 'info',
              maxLength: -1,
            },
          },
        }),
      });
  }

  return createKPITemplateSectionDataModel({
    type:
      sectionType === RendererType.kpiTable || sectionType === RendererType.universalKpiTable
        ? RendererTypeOfComponent.grid
        : RendererTypeOfComponent.field,
    templateId: -1,
    meta: sectionMeta,
  });
}

export function createKPIField(name: string, unit: Formatting) {
  return createFieldDataModel({
    entity: FieldEntity.userKPI,
    displayName: name,
    entityField: name,
    formMeta: {
      renderer: {
        type: unit,
        id: unit,
        config: {},
      },
      formatter: {
        id: unit,
        type: unit,
        config: {},
      },
      required: false,
      defaultValue: null,
    },
    type: 'number',
  });
}
