import { InferType, number, object, string } from 'yup';

export const MetricsTransactionSchema = {
  base() {
    return {
      amount: number(),
      companyId: number(),
      createdAt: string(),
      createdBy: string(),
      currentInvestment: number(),
      dateModified: string(),
      dealId: string(),
      distributions: number(),
      escrowAmount: number(),
      fundId: number(),
      id: number(),
      investmentAmount: number(),
      investmentRoundId: number(),
      ledgerId: number(),
      position: string(),
      pps: number(),
      quantity: number(),
      restructureId: string(),
      roundId: number(),
      status: string(),
      transactionDate: string(),
      transType: string(),
      transactionEntryId: string(),
      updatedAt: string(),
      updatedBy: string(),
    };
  },
  dataModel() {
    const base = this.base();

    return object({
      ...this.base(),
      amount: base.amount.required(),
      companyId: base.companyId.required(),
      currentInvestment: base.currentInvestment.required(),
      distributions: base.distributions.required(),
      id: base.id.required(),
      investmentAmount: base.investmentAmount.required(),
      quantity: base.quantity.required(),
      transactionDate: base.transactionDate.required(),
      transType: base.transType.required(),
    });
  },
};

export type MetricsTransactionDataModel = InferType<ReturnType<typeof MetricsTransactionSchema.dataModel>>;

export function createMetricsTransactionDataModel(
  overrides?: Partial<MetricsTransactionDataModel>
): MetricsTransactionDataModel {
  return {
    id: 0,
    companyId: 0,
    createdAt: '',
    distributions: 0,
    fundId: 0,
    investmentAmount: 0,
    currentInvestment: 0,
    transactionDate: new Date().toISOString(),
    amount: 0,
    quantity: 0,
    transType: '',
    ...overrides,
  };
}
