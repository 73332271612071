import { Column, useFlexLayout, useTable } from 'react-table';
import { TransactionHistoryTableData } from '../../../types';

import { StyledTable, StyledTd, StyledTh, TableContainer } from './styledTableComponents';

type Props = {
  data: TransactionHistoryTableData[];
  columns: Column<TransactionHistoryTableData>[];
};

export const TransactionHistoryTable = ({ data, columns }: Props) => {
  const tableInstance = useTable(
    { columns, data, defaultColumn: { width: 180, minWidth: 150, maxWidth: 400 } },
    useFlexLayout
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <TableContainer>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
              {headerGroup.headers.map((column, columnIndex) => (
                <StyledTh {...column.getHeaderProps()} key={columnIndex}>
                  {column.render('Header')}
                </StyledTh>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => {
                  const currencyCode = data[rowIndex].currency?.code;
                  return (
                    <StyledTd {...cell.getCellProps()} key={cellIndex}>
                      {cell.render('Cell', { currencyCode: currencyCode })}
                    </StyledTd>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};
