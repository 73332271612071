import { Stack } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { FilterSection } from './components/Filters/FilterSection';
import { KPIRequestsFrequenciesFilter } from './components/Filters/KPIRequestsFrequenciesFilter';
import { KPIRequestsTemplatesFilter } from './components/Filters/KPIRequestsTemplatesFilter';
import { useKPIRequestsFilters } from './hooks/useKPIRequestsFilters';
import useKPIRequestsSentColDefs from './hooks/useKPIRequestsSentColDefs';
import { ActionContainer } from './components/KpiStyleDefs';

export function SentKpiRequestsGrid({
  status,
}: {
  status: KPIRequestStatus.Sent | KPIRequestStatus.Archived;
}) {
  const requests = useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIRequestsSentColDefs(status);
  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(requests);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const onSearchCompanyChange = (query: string) => {
    gridApi?.setGridOption('quickFilterText', query);
  };

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api);
  };

  const { exportParams } = useExtendedExportSettings(new Set(['company']), `${status}-kpi-requests`);

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  return (
    <Stack height={'100%'}>
      <div css={ActionContainer}>
        <FilterSection marginTop='1rem'>
          <SearchInput onChange={onSearchCompanyChange} placeholder='Search for a company' />
          <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
          <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
        </FilterSection>
        <ExportGridButton onClick={handleExport} />
      </div>
      <FadeInGridWrapper key={status}>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          groupIncludeTotalFooter={false}
          onGridReady={onGridReady}
          rowData={filteredRequests}
          rowGroupPanelShow='always'
          rowSelection='multiple'
          suppressMovableColumns={true}
          suppressRowDrag={true}
          defaultExcelExportParams={exportParams}
          defaultCsvExportParams={exportParams}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
