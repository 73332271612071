import { IHeaderParams } from 'ag-grid-enterprise';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';

export interface CompanyHeaderProps extends IHeaderParams {
  value: string;
  name: string;
  logo: string | null;
  website?: string;
}

const Link = styled('a')`
  text-decoration: none;
  color: inherit;
`;

const NameCell = styled('span')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[60]};
`;

export const CompanyHeaderComponent: FC<CompanyHeaderProps> = ({ value, name, logo, website }) => {
  return (
    <NameCell data-testid='company-header-component'>
      <MUIAvatar src={logo ?? ''} nameParts={name.split(' ') ?? []} />
      {website ? (
        <Link
          target='_blank'
          rel='noopener noreferrer'
          href={website.indexOf('http') === 0 ? website : `//${website}`}
        >
          {value}
        </Link>
      ) : (
        <span>{value}</span>
      )}
    </NameCell>
  );
};
