import * as yup from 'yup';
import { pick } from 'lodash-es';
import { RendererType } from '../../data-models/field.data-model';
import { BooleanFormatterId } from '../../util/formatters/BooleanFormatters';
import { currencyIdField, sharesField, usdField } from '../common-schema-defs';
import { requiredMsg } from '../../pages/Finance/components/TransactionModal/Forms/utils/validationMessages';

export const TransactionsCommonSchemas = {
  base() {
    return {
      coInvestors: yup.array(),
      currencyid: currencyIdField(),
      fullyDilutedShares: sharesField(),
      fundId: yup.number().nullable().label('Fund').fieldMeta({
        renderer: RendererType.id,
        formatter: 'fund',
      }),
      investmentAmount: yup.number().fieldMeta({
        formatter: 'usd',
        renderer: RendererType.number,
      }),
      investmentRoundId: investmentRoundId(),
      stage: investmentStage(),
      leadInvestor: yup.string(),
      noOfShares: yup.number().fieldMeta({
        label: 'Shares Purchased',
        renderer: RendererType.number,
      }),
      notes: yup.string(),
      position: yup.string().label('Investment'),
      postMoneyValuation: yup.number(),
      pricePerShare: usdField(),
      security: yup.string(),
      status: yup.string(),
      transactionDate: yup.date().fieldMeta({
        renderer: RendererType.date,
      }),
      transactionTypeId: yup.number().label('Transaction Type').gridMeta({
        renderer: RendererType.id,
        formatter: 'transactionType',
      }),
      valuationImpact: yup.boolean().fieldMeta({
        renderer: RendererType.boolean,
        formatter: BooleanFormatterId.yesNo,
      }),
    };
  },
  gridFields() {
    const { investmentAmount, ...base } = this.base();

    return {
      ...base,
      transactionDate: base.transactionDate.gridMeta({
        width: 200,
      }),
    };
  },
  allTransactionsCommonFields() {
    return pick(TransactionsCommonSchemas.gridFields(), [
      'transactionDate',
      'fundId',
      'transactionTypeId',
      'stage',
      'investmentRoundId',
      'status',
      'notes',
      'id',
    ]);
  },
};

export function requiredCurrencyIdField() {
  return currencyIdField().required(requiredMsg('Currency'));
}

/**
 * FIXME MAGGIE-7034 - Review if both this & requiredCurrencyIdField are needed.
 */
export function transactionCurrencyIdField() {
  return currencyIdField().nullable().required(requiredMsg('Currency'));
}

export function investmentRoundId(key = 'investmentRoundId', label = 'Round') {
  return yup.number().fieldMeta({
    label,
    key,
    formatter: 'investmentRound',
    renderer: RendererType.id,
  });
}

export function investmentStage() {
  return yup.string().customMeta({
    label: 'Stage',
    formatter: 'stage',
    renderer: RendererType.id,
  });
}
