import { EventType, FieldPath, FieldValues, PathValue, useFormContext, UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';

interface ISyncFieldsParams<T extends FieldValues> {
  methods: UseFormReturn<T>;
  fieldPath: FieldPath<T>;
  calcFunc: (values?: Partial<T>) => PathValue<T, FieldPath<T>>;
  deps: Set<string>;
}

export function useCalculatedField<T extends FieldValues>({
  methods,
  fieldPath: fieldPath,
  calcFunc: calcFunc,
  deps = new Set(),
}: ISyncFieldsParams<T>) {
  const { watch, setValue } = methods;
  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      syncFieldValue({
        calcFunc,
        data,
        deps,
        fieldPath,
        name,
        type,
        setValue,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  });
}

export interface ISyncFieldValueParams<T extends FieldValues> {
  name?: FieldPath<T>;
  setValue: ReturnType<typeof useFormContext<T>>['setValue'];
  type?: EventType;
  data?: Partial<T>;
}
export function syncFieldValue<T extends FieldValues>({
  calcFunc,
  data,
  deps,
  fieldPath,
  name,
  type,
  setValue,
}: ISyncFieldValueParams<T> & Omit<ISyncFieldsParams<T>, 'methods'>) {
  if (type !== 'change' || !deps.has(name as FieldPath<T>)) return;
  setValue(fieldPath, calcFunc(data));
}
