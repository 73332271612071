import { useWatch } from 'react-hook-form';
import { useCallback } from 'react';
import {
  securityField,
  transactionDateField,
  warrantsTypeField,
} from '../../../data-fields/TransactionFields';
import {
  amountField,
  booleanField,
  futureDateField,
  sharesField,
  simpleMultilineTextField,
  useFundIdField,
  useGetCurrencyIdField,
  useRoundIdField,
} from '../../../data-fields/CommonFields';
import { ITransactionViewModel, WarrantsType } from '../../../view-models/transaction-form.view-model';
import { createFormField, IFormField } from '../../../view-models/form.view-model';
import { RendererType } from '../../../data-models/field.data-model';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';
import { useGetAmountField } from './TransactionFormUtils';

export function useGetWarrantIssuanceFields() {
  const getCommonInitialFields = useFundDateCurrencyFields();
  const round = useRoundIdField({
    key: 'roundId',
    label: 'Round',
    required: false,
  });
  const warrantVested = useWatch<ITransactionViewModel>({ name: 'warrantVested' });

  const getConditionalFields = useConditionalWarrantIssuanceFields();

  return useCallback(() => {
    const res = [
      ...getCommonInitialFields(),
      round,
      securityField(),
      futureDateField({
        key: 'warrantExpiryDate',
        label: 'Expiration Date',
        required: true,
      }),
      warrantsTypeField(),
      ...getConditionalFields(),
      amountField({ key: 'optionsExercisePrice', label: 'Exercise Price' }),
      booleanField({ key: 'warrantVested', label: 'Warrants Vested' }),
    ];

    if (warrantVested) {
      res.push(amountField({ key: 'pricePerShare', label: 'Vested PPS', required: true }));
    }

    res.push(simpleMultilineTextField({ key: 'notes', label: 'Notes' }));

    return res;
  }, [getCommonInitialFields, getConditionalFields, round, warrantVested]);
}

export function useGetWarrantExerciseFields() {
  const getCommonInitialFields = useFundDateCurrencyFields();
  const round = useRoundIdField({
    key: 'roundId',
    label: 'Round',
    required: false,
  });

  return useCallback((): IFormField<unknown>[] => {
    return [
      ...getCommonInitialFields(),
      sharesField({ key: 'exercisedWarrantsNo', label: 'Warrants Exercised', required: true }),
      round,
      securityField(),
      amountField({
        description: 'The converted PPS is the value that the shares should be held at time of conversion.',
        key: 'pricePerShare',
        label: 'Converted PPS',
        required: true,
      }),
      amountField({
        key: 'warrantExercisePrice',
        label: 'Exercise Price',
      }),
      createFormField({
        description:
          'By toggling on, all securities held in the company will be marked to the PPS of this transaction',
        key: 'valuationImpact',
        label: 'Valuation Impacting',
        renderer: RendererType.boolean,
      }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getCommonInitialFields, round]);
}

export function useGetWarrantExpirationFields() {
  const fundIdField = useFundIdField();
  const transactionDate = transactionDateField();
  const getCurrencyIdField = useGetCurrencyIdField();

  return useCallback((): IFormField<unknown>[] => {
    return [
      fundIdField,
      transactionDate,
      getCurrencyIdField('currencyid', 'Currency'),
      amountField({ key: 'principalWriteOffAmount', label: 'Amount Write Off' }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [fundIdField, getCurrencyIdField, transactionDate]);
}

export function useWarrantFields() {
  const getWarrantExerciseFields = useGetWarrantExerciseFields();
  const getWarrantExpirationFields = useGetWarrantExpirationFields();
  const getWarrantIssuanceFields = useGetWarrantIssuanceFields();

  return {
    getWarrantExerciseFields,
    getWarrantExpirationFields,
    getWarrantIssuanceFields,
  };
}

export function useConditionalWarrantIssuanceFields() {
  const warrantsType = useWatch<ITransactionViewModel>({ name: '_viewModel.warrantsType' });
  const getAmountField = useGetAmountField<ITransactionViewModel>('currencyid');

  return useCallback(() => {
    switch (warrantsType) {
      case WarrantsType.numberOfWarrants: {
        return [
          sharesField({ key: 'warrantsIssued', label: 'Number of Warrants', required: true }),
          getAmountField({ key: 'warrantAcquiredPrice', label: 'Acquired Price Per Share', required: true }),
        ];
      }
      case WarrantsType.totalValueOfWarrants: {
        return [getAmountField({ key: 'aggregatedValue', label: 'Total Value of Warrants', required: true })];
      }
      default: {
        return [];
      }
    }
  }, [getAmountField, warrantsType]);
}
