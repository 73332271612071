import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import {
  selectedRubricConfigActionState,
  selectedRubricConfigIdState,
  selectedRubricConfigState,
  useResetRubricConfigFormState,
} from './RubricConfigFormState';

export function useRubricConfigFormData() {
  const [selectedAction, setSelectedAction] = useRecoilState(selectedRubricConfigActionState);
  const setSelectedConfigId = useSetRecoilState(selectedRubricConfigIdState);
  const selectedConfig = useRecoilValue(selectedRubricConfigState);
  const resetFormState = useResetRubricConfigFormState();

  const defaultValues = useMemo(() => {
    if (selectedAction === 'edit' && selectedConfig) {
      return { ...selectedConfig };
    }
  }, [selectedAction, selectedConfig]);

  return {
    resetFormState,
    defaultValues,
    selectedAction,
    setSelectedAction,
    setSelectedConfigId,
    selectedConfig,
  };
}
