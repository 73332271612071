import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
  IStakeholderInvestmentDataModel,
} from '../../../../../data-models/captable2.data-model';
import { ISimpleChoice } from '../../../../../data-models/field2.data-model';
import { CapTableInvestmentType } from '../../../../../schemas/CaptableInvestment.schema';
import { FMT } from '../../../../../util/formatter-service';
import { clientInvestmentsByFundId } from './InvestmentDetailsStakeholderData';

const includedInvestmentTypes = new Set<CapTableInvestmentType>(['share-class', 'warrant-block']);

export interface IClientCaptableInvestmentData extends ICaptableInvestmentDataModel {
  clientFullyDilutedShares: number;
}

export function getScenarioCaptableData(captable?: ICaptableDataModel) {
  const shareClassesById = (captable?.captableInvestments ?? [])?.reduce((map, inv) => {
    if (
      !inv.investmentType ||
      !includedInvestmentTypes.has(inv.investmentType) ||
      !inv.stakeholderInvestments?.some((si) => si.fundId && si.fullyDilutedShares)
    )
      return map;
    const clientFullyDilutedShares = inv.stakeholderInvestments?.reduce((sum, si) => {
      if (!si.fundId) return sum;
      return sum + (si!.fullyDilutedShares ?? 0);
    }, 0);
    return map.set(inv.id, { ...inv, clientFullyDilutedShares });
  }, new Map<number, IClientCaptableInvestmentData>());

  const shareClassIdToClientInvestmentsByFund = Array.from(shareClassesById.values()).reduce((map, inv) => {
    const clientInvestments = clientInvestmentsByFundId(inv, true);
    if (clientInvestments.size > 0) {
      map.set(inv.id, clientInvestments);
    }
    return map;
  }, new Map<number, Map<number, IStakeholderInvestmentDataModel>>());

  const shareClassOptions: ISimpleChoice<number>[] = Array.from(
    shareClassIdToClientInvestmentsByFund.keys()
  ).map((invId) => ({
    displayName: shareClassesById.get(invId)?.name ?? String(invId),
    value: invId,
  }));

  const fundOptionsByShareClassId = new Map<number, ISimpleChoice<number>[]>();
  shareClassIdToClientInvestmentsByFund.forEach((clientInvestments, investmentId) => {
    fundOptionsByShareClassId.set(
      investmentId,
      Array.from(clientInvestments.keys()).map((fundId) => ({
        displayName: FMT.format('fund', fundId),
        value: fundId,
      }))
    );
  });

  return {
    shareClassesById,
    shareClassIdToClientInvestmentsByFund,
    shareClassOptions,
    fundOptionsByShareClassId,
  };
}
