import { IconButton } from '@mui/material';
import { FC } from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import { StyledTooltip } from '../../../../CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';
import { TrackingAction } from '../../../../../data-models/tracking.data-model';

interface IFollowButtonProps {
  isTracked: boolean;
  onClick: (action: TrackingAction) => void;
}

export const TrackButton: FC<IFollowButtonProps> = ({ isTracked, onClick }) => {
  return (
    <StyledTooltip title={isTracked ? 'Untrack' : 'Track'} placement='top-start'>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClick(isTracked ? 'untrack' : 'track');
        }}
      >
        {isTracked ? (
          <HourglassDisabledIcon fontSize={'small'} color={'secondary'} />
        ) : (
          <HourglassEmptyIcon fontSize={'small'} color={'secondary'} />
        )}
      </IconButton>
    </StyledTooltip>
  );
};
