import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { CellProps } from 'react-table';
import { TransactionAudit } from '../../../types';

const StyledSpan = styled('span')`
  word-break: break-all;
`;

export const LongTextCellRenderer: FC<CellProps<TransactionAudit>> = ({ value }) => {
  return <StyledSpan>{value}</StyledSpan>;
};
