import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ComponentWithChildren, LoadingId } from '../../types';

export interface Store {
  state: {
    isLoading: boolean;
    ids: LoadingId[];
  };
  actions: {
    startLoading: (id: LoadingId) => void;
    stopLoading: (id: LoadingId) => void;
  };
}

const LoadingBarContext = createContext<Store | undefined>(undefined);

export const useLoadingBarState = (): Store => {
  const context = useContext(LoadingBarContext);

  if (typeof context === 'undefined') {
    throw new Error('useLoadingBarState cannot be used outside LoadingBarProvider');
  }

  return context;
};

export const LoadingBarProvider: FC<ComponentWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ids, setIds] = useState<LoadingId[]>([]);

  const startLoading = useCallback((id: LoadingId): void => {
    setIds((prev) => [...prev, id]);
  }, []);

  const stopLoading = useCallback((id: LoadingId): void => {
    setIds((prev) => prev.filter((item) => item !== id));
  }, []);

  useEffect(() => {
    setIsLoading(ids.length > 0);
  }, [ids]);

  const value = useMemo(() => {
    return {
      state: { isLoading, ids },
      actions: { startLoading, stopLoading },
    };
  }, [isLoading, startLoading, stopLoading, ids]);

  return <LoadingBarContext.Provider value={value}>{children}</LoadingBarContext.Provider>;
};
