import { atom } from 'recoil';
import { ColumnState } from 'ag-grid-community';

export const isFirstRenderDonePF = atom({
  key: 'isFirstRenderDonePF',
  default: false,
});

export const rowGroupColumnsState = atom<ColumnState[]>({
  key: 'rowGroupColumnsState',
  default: [],
});

export const collapsedDealStages = atom<Record<string, boolean>>({
  key: 'collapsedDealStages',
  default: {},
});
