import { CSSProperties, FC, PropsWithChildren, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxiosError } from 'axios';

const Wrapper = styled('div')`
  background-color: white;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  padding: 1rem;
  overflow-y: auto;
`;

const DetailsWrapper = styled('div')`
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.primary[5]};
  padding: 1.5rem;
`;

const ButtonsWrapper = styled('div')`
  display: grid;
  grid-template-columns: max-content max-content;
  margin-top: 1rem;
  gap: 1rem;
`;

export interface ISentryBoundaryFallbackProps {
  error: Error;
  componentStack?: string | null;
  eventId?: string | null;
  resetError?: () => void;
}

export interface IGenericErrorFallbackProps extends ISentryBoundaryFallbackProps {
  style: CSSProperties;
}

export function GenericErrorFallback({ error, style, resetError }: IGenericErrorFallbackProps) {
  const [showMore, setShowMore] = useState(false);

  if ((error as AxiosError).response?.status === 403) return <ForbiddenError error={error} style={style} />;

  return (
    <Wrapper style={style}>
      <ErrorTitle>It seems something went wrong</ErrorTitle>
      <div>
        <Button color='secondary' onClick={() => setShowMore((prev) => !prev)} sx={{ margin: '.25rem' }}>
          {showMore ? 'Hide technical details' : 'View technical details'}
        </Button>
      </div>
      {showMore && (
        <DetailsWrapper>
          <Typography variant={'caption'}>
            {new Date().toISOString()}
            {error.stack}
          </Typography>
        </DetailsWrapper>
      )}
      <ButtonsWrapper>
        <Button onClick={resetError} variant='contained' color='secondary'>
          Try Reload Section
        </Button>
        <Button onClick={() => window.location.reload()} variant='contained'>
          Reload page
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
}
export const ForbiddenError: FC<IGenericErrorFallbackProps> = () => {
  return (
    <Wrapper style={{ display: 'grid', height: '100dvh', alignContent: 'start' }}>
      <ErrorTitle>Forbidden</ErrorTitle>
      <DetailsWrapper>
        <Typography variant='body2' component={'div'}>
          <p>Sorry! It seems like you do not have access to this page.</p>{' '}
          <p>
            Please contact <Link href='mailto:foresight@support.com'>foresight@support.com</Link> for further
            assistance.
          </p>
        </Typography>
      </DetailsWrapper>
    </Wrapper>
  );
};
export const ErrorTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography variant='body1' display='flex' alignItems={'center'} gap='0.25rem'>
      <ErrorOutlineIcon color='warning' />
      {children}
    </Typography>
  );
};
