import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { ICellRendererParams } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';
import { useCallback, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { kpisSelectedTemplateAssignmentState } from '../../../../../../services/state/KPI/KPICompaniesState';
import { ConfirmDeletionDialog } from '../../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { kpisAssignRequestModalState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { MaggieFeatureFlags } from '../../../../../../util/feature-flags';
import { MenuButton } from '../../../../../Finance2/common-grid-defs/MenuButton';
import { useTemplateAssignmentActions } from '../../hooks/useTemplateAssignmentActions';

export const MoreAssignmentOptions = (params: ICellRendererParams) => {
  const { colors } = useTheme();
  const openAssignRequestModal = useSetRecoilState(kpisAssignRequestModalState);
  const setSelectedTemplateAssignmentId = useSetRecoilState(kpisSelectedTemplateAssignmentState);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { deleteAssignment } = useTemplateAssignmentActions();

  const { data } = params;
  const { company, id: assignmentId, template } = data ?? {};

  const handleEdit = useCallback(() => {
    if (assignmentId === -1 || !company) return;
    openAssignRequestModal(company.id);
    setSelectedTemplateAssignmentId(assignmentId);
  }, [assignmentId, company, openAssignRequestModal, setSelectedTemplateAssignmentId]);

  const handleOpenDeleteModal = useCallback(() => {
    setOpenDeleteModal(true);
  }, [setOpenDeleteModal]);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDelete = useCallback(async () => {
    if (!assignmentId) return;
    await deleteAssignment(assignmentId);
    handleCloseDeleteModal();
  }, [assignmentId, deleteAssignment]);

  const menuItems = useMemo(() => {
    if (!data) return [];
    return [
      { name: 'Edit', icon: <ModeOutlinedIcon />, onClick: handleEdit },
      { name: 'Delete', icon: <DeleteOutlinedIcon />, onClick: handleOpenDeleteModal },
    ];
  }, [data, handleEdit, handleOpenDeleteModal]);

  if (!data) return null;

  return (
    <>
      {openDeleteModal && (
        <ConfirmDeletionDialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDelete}
          title={`Delete assigned "${template?.name}" to "${company?.name}"?`}
        >
          <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem', minWidth: '30rem' }}>
            Deleting this template will remove it from the company
          </Typography>
        </ConfirmDeletionDialog>
      )}

      <Stack justifyContent={'center'} alignItems={'center'}>
        <MenuButton menuItems={menuItems} label='assignment actions' />
      </Stack>
    </>
  );
};

/**@deprecated: once showDataCollectionCompanies2 flag is no longer needed, we can use MoreAssignmentOptions directly, since there will be no actions for the row group */
export default function AllCompaniesActionsCellRenderer(params: ICellRendererParams) {
  const { colors } = useTheme();
  const openAssignRequestModal = useSetRecoilState(kpisAssignRequestModalState);
  const { showDataCollectionCompanies2 } = useFlags<MaggieFeatureFlags>();

  if (!params.node?.group) return <MoreAssignmentOptions {...params} />;

  if (!params.node?.key || showDataCollectionCompanies2) return null;

  const companyId = Number(params.node.key.split('-')[1]);

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <IconButton onClick={() => openAssignRequestModal(companyId)} color='secondary' size={'medium'}>
        <AssignmentIcon sx={{ color: colors.primary[60] }} />
      </IconButton>
    </Stack>
  );
}
