import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dealBoardConfigState } from '../../../../state/DealboardDataState';
import { dealTypeFilterStateDA } from '../../../../state/DealConversionFilterState';

export const DealTypeFilter: FC = () => {
  const [selectedDealType, setSelectedDealType] = useRecoilState(dealTypeFilterStateDA);
  const dealTypes = useRecoilValue(dealBoardConfigState).dealTypes;

  const dealTypeOptions = useMemo(() => {
    return dealTypes.map((dealType) => ({
      id: dealType.id,
      name: dealType.displayName,
      label: dealType.displayName,
    }));
  }, [dealTypes]);

  const handleDealTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      if (newValue != null) {
        setSelectedDealType(Number(newValue));
      }
    },
    [setSelectedDealType]
  );

  return (
    <Select
      value={String(selectedDealType)}
      onChange={handleDealTypeChange}
      sx={{ minWidth: '10rem', height: '40px', mt: '4px', fontSize: '14px' }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {dealTypeOptions.map((type) => (
        <MenuItem key={type.id} value={type.id} style={{ fontSize: '14px' }}>
          {type.name}
        </MenuItem>
      ))}
    </Select>
  );
};
