import { MAX_BARS_FOR_GROUP_BY_LOCATION } from '../hooks/useDealsBreakdownData';
import { GroupByOptions } from '../state/DealConversionFilterState';

export function camelCaseToTitle(str: string) {
  return str
    .replace(/^./, (str) => {
      return str.toUpperCase();
    })
    .replace(/([A-Z])/g, ' $1')
    .trim();
}

export function getGroupByOptionName(name: GroupByOptions) {
  if (name === GroupByOptions.INVESTMENT_STATUS) {
    return 'Deal Status';
  }
  if (name === GroupByOptions.LOCATION) {
    return `Location (top ${MAX_BARS_FOR_GROUP_BY_LOCATION})`;
  }
  return camelCaseToTitle(name);
}
