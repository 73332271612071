import { FC, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { selectedKpiChartState } from '../../../state/UIState';
import { ExpandedPerfChart } from './ExpandedPerfChart';
import { useKpiChartsMenu } from './useKpiChartsMenu';

export const ChartModal: FC = () => {
  const selectedChart = useRecoilValue(selectedKpiChartState);
  const resetSelectedChart = useResetRecoilState(selectedKpiChartState);

  const open = selectedChart != null;

  return (
    <BasicDialog
      open={open}
      titleComponent={open ? <ChartTitle /> : ''}
      closeOnEscOrBackdropClick
      onClose={resetSelectedChart}
    >
      {open ? <ExpandedPerfChart /> : <></>}
    </BasicDialog>
  );
};

export const ChartTitle: FC = () => {
  const [selectedChart, setSelectedChart] = useRecoilState(selectedKpiChartState);
  const { selectedKpis } = useKpiChartsMenu();

  const [selectedIndex, setSelectedIndex] = useState(() => {
    return selectedKpis.findIndex((kpi) => kpi.id === selectedChart?.id);
  });

  const handlePrev = useCallback(() => {
    const prevIndex = (selectedIndex + selectedKpis.length - 1) % selectedKpis.length;
    setSelectedIndex(prevIndex);
    setSelectedChart(selectedKpis.at(prevIndex)!);
  }, [selectedIndex, selectedKpis, setSelectedIndex, setSelectedChart]);

  const handleNext = useCallback(() => {
    const nextIndex = (selectedIndex + 1) % selectedKpis.length;
    setSelectedIndex(nextIndex);
    setSelectedChart(selectedKpis.at(nextIndex)!);
  }, [selectedIndex, selectedKpis, setSelectedIndex, setSelectedChart]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        handlePrev();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNext, handlePrev]);

  return (
    <Stack
      sx={{ width: '100%' }}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'2rem'}
    >
      <IconButton color='secondary' onClick={handlePrev} title='previous'>
        <ChevronLeftIcon />
      </IconButton>
      <Typography minWidth={'14rem'} maxWidth={'14rem'} textAlign={'center'} noWrap>
        {selectedKpis.at(selectedIndex)?.displayName}
      </Typography>
      <IconButton color='secondary' onClick={handleNext} title='next'>
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
