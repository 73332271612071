import { FC, useCallback } from 'react';
import { CHART_COMPONENTS } from '../../../../../types';
import { LineChart } from '../../../../../components/Charts/LineChart/LineChart';
import { useIrrOverall } from './useIrrOverall';

export const IrrChart: FC = () => {
  const { data } = useIrrOverall();
  const yFormatter = useCallback((tick: string | number) => `${Number(tick).toFixed(1)}%`, []);

  return (
    <LineChart
      data={data}
      id={CHART_COMPONENTS.IRR}
      showLegend={false}
      title={`IRR`}
      width={640}
      yAxisLabelFormatter={yFormatter}
    />
  );
};
