import { ReactElement, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Tooltip, Typography } from '@mui/material';
import { theme } from '../../../../theme';

const { primary } = theme.colors;

interface Props {
  name: string;
  children: ReactNode;
}

const Wrapper = styled('div')`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 5px 1rem;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border: 2px solid ${primary[0]};
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;

  & > * {
    &:last-child {
      margin-left: auto;
    }

    &:nth-child(2) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const CompanyWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-width: 0;

  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function RowWrapper({ name, children }: Props): ReactElement {
  const { primary, neutral } = useTheme().colors;

  return (
    <Wrapper>
      <Tooltip title={<span style={{ color: neutral[60], backgroundColor: 'white' }}>{name}</span>}>
        <CompanyWrapper>
          <Typography variant={'body2'} color={primary[100]}>
            {name}
          </Typography>
        </CompanyWrapper>
      </Tooltip>
      {children}
    </Wrapper>
  );
}
