// Utility so that we can set the search params with symbols like commas unencoded.
import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

// TODO: Merge this logic into useQueryParams & simplify that hook to not be Provider dependent.

export function useSetSearchParams() {
  const navigate = useNavigate();

  return useCallback(
    (searchParams: Record<string, string>, options?: NavigateOptions) => {
      const currentSearchParams = new URLSearchParams(window.location.search);
      const updatedParams = new URLSearchParams({
        ...Object.fromEntries(currentSearchParams),
        ...searchParams,
      });

      navigate(
        {
          search: decodeURIComponent(updatedParams.toString()),
        },
        options
      );
    },
    [navigate]
  );
}
