import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FilterOptionsState } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Option } from '../MuiSingleSelect/MuiSingleSelect';
import { MuiMultiselect, MultiselectProps } from './MuiMultiselect';

const TOGGLE_ALL_NONE_ID = -1;

export const StyledTag = styled('span')`
  display: flex;
  align-items: baseline;
  padding: 0;
  padding-left: 0.5rem;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary[100]};
`;

const SelectedText = styled('div')`
  display: inline-block;
  max-width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.primary[100]};
`;

export interface MultiselectSansPillsProps extends MultiselectProps {
  optionName?: string;
  openOnMount?: boolean;
  disableKeyboardSelect?: boolean;
  clearSelectsAll?: boolean;
  fontSize?: string;
}

export const MultiselectSansPills: FC<MultiselectSansPillsProps> = (props: MultiselectSansPillsProps) => {
  const {
    options,
    value,
    onChange,
    optionName,
    clearSelectsAll,
    openOnMount = false,
    disableKeyboardSelect,
    fontSize,
    ...rest
  } = props;

  const [open, setOpen] = useState(openOnMount);

  const allSelected = value?.length === options.length;

  const toggleAllNoneOption = useMemo(
    () => ({
      id: TOGGLE_ALL_NONE_ID,
      value: allSelected ? 'Deselect All' : 'Select All',
    }),
    [allSelected]
  );
  const optionsPlusToggleOption = [toggleAllNoneOption, ...options];

  const handleChange = useCallback(
    (e: SyntheticEvent, onChangeOptions: Option[] | undefined, reason: string) => {
      if (reason === 'blur') {
        setOpen(false);
        return;
      }

      if (reason === 'clear') {
        if (clearSelectsAll) {
          onChange(e, options, reason);
          return;
        } else {
          setOpen(true);
        }
      }

      if (onChangeOptions?.find((opt) => opt.id === TOGGLE_ALL_NONE_ID)) {
        if (allSelected) onChange(e, [], reason);
        else onChange(e, options, reason);
      } else {
        onChange(e, onChangeOptions, reason);
      }
    },
    [allSelected, clearSelectsAll, onChange, options]
  );

  const renderTags = useCallback(
    (options: Option[]) => {
      if (!options?.length) return <></>;

      let text;
      if (allSelected) text = optionName ? `All ${optionName}s` : 'All Selected';
      else if (options.length === 1) text = options[0].value;
      else text = `${options.length} ${optionName ? optionName : 'option'}${options.length > 1 ? 's' : ''}`;

      return allSelected ? (
        <StyledTag style={{ fontSize }}>{text}</StyledTag>
      ) : (
        <StyledTag style={{ fontSize }}>
          <SelectedText>{text}</SelectedText>
        </StyledTag>
      );
    },
    [allSelected, optionName, fontSize]
  );

  const _filterOptions = createFilterOptions<Option>({ limit: 100 });
  const filterOptions = (options: Option[], state: FilterOptionsState<Option>) => {
    const results = _filterOptions(options, state);

    if (!results.includes(toggleAllNoneOption)) {
      // make sure select/deselect all is always in the list
      results.unshift(toggleAllNoneOption);
    }

    return results;
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  return (
    <MuiMultiselect
      options={optionsPlusToggleOption}
      open={open}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      renderTags={renderTags}
      limitTags={1}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.id ?? option.value}
            style={{ fontFamily: 'Wotfard-Regular', fontSize: fontSize ?? '0.875rem' }}
          >
            {option.id !== TOGGLE_ALL_NONE_ID ? (
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            ) : (
              <></>
            )}
            {option?.value ?? ''}
          </li>
        );
      }}
      style={{
        height: '2rem',
      }}
      sx={{
        '& .MuiAutocomplete-input': { paddingLeft: '.5rem !important' }, // had to override global !important
      }}
      componentsProps={{
        popper: {
          sx: {
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option:first-of-type': {
              paddingLeft: '.5rem ',
            },
          },
        },
      }}
      openOnFocus
      filterOptions={filterOptions}
      onKeyDown={
        disableKeyboardSelect
          ? (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                setOpen(false);
              }
            }
          : undefined
      }
      {...rest}
    />
  );
};
