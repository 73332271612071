import { ICellRendererParams } from 'ag-grid-community';

/** If the keyCreator and valueFormatter return the same string,
 * this renderer makes sure the same formatted value is displayed when grouping;
 */
export function GenericIdRenderer(params: ICellRendererParams) {
  if (groupingByField(params)) {
    return <>{params.node.key}</>;
  }
  return <>{params.valueFormatted ?? params.value}</>;
}

export function groupingByField(params: ICellRendererParams) {
  return params.node?.group && params.column?.getColDef().cellRenderer === 'agGroupCellRenderer';
}
