import { IBaseModelDataModel, createBaseModelDataModel } from './base-model.data-model';
import { CustomData } from './field2.data-model';

export enum RoundSource {
  aumni = 'aumni',
  foresight = 'foresight',
  systemGenerated = 'system_generated',
}

export interface IFinanceRoundDataModel extends IBaseModelDataModel {
  coInvestors?: string[] | null;
  companyId: number;
  customData?: CustomData | null;
  fullyDilutedShares: number | null;
  id: number;
  initialQSBSQualification: boolean;
  leadInvestor?: string | null;
  leadInvestors?: string[] | null;
  name?: string | null;
  postMoneyValuation: number | null;
  raiseAmount: number | null;
  roundDate: string;
  roundId: number | null;
  source?: RoundSource | null;
}

export function createFinanceRoundDataModel(
  overrides?: Partial<IFinanceRoundDataModel>
): IFinanceRoundDataModel {
  return {
    id: -1,
    ...createBaseModelDataModel(),
    companyId: 0,
    name: '',
    roundId: null,
    postMoneyValuation: null,
    raiseAmount: null,
    fullyDilutedShares: null,
    initialQSBSQualification: false,
    leadInvestor: null,
    coInvestors: null,
    roundDate: '',
    source: null,
    ...overrides,
  };
}
