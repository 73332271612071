export interface IUserDataModel {
  activeStatus?: boolean;
  address: string | null;
  createdAt: string;
  createdBy: string | null;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  middleName: string | null;
  name: string;
  phone: string | null;
  updatedAt: string;
  updatedBy: string | null;
}

export function createUserDataModel(overrides: Partial<IUserDataModel> = {}): IUserDataModel {
  return {
    activeStatus: false,
    address: '',
    createdAt: '',
    createdBy: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    middleName: '',
    name: '',
    phone: '',
    updatedAt: '',
    updatedBy: '',
    ...overrides,
  };
}

export const UnknownUser = createUserDataModel({ firstName: '-', lastName: '', name: '-', id: -1 });
