import { EntityType } from '../../data-models/field2.data-model';
import { BaseModelWithID } from '../../schemas/BaseModel.schema';
import { RubricConfig, Rubric, RubricAudit } from '../../schemas/Rubric.schema';
import { MaggieHttp } from '../MaggieHttp';

export async function getRubricConfig() {
  const { data } = await MaggieHttp.maggie.get<RubricConfig[]>('/rubric/config');
  return data;
}

export async function getRubricConfigById(id: number) {
  const { data } = await MaggieHttp.maggie.get<RubricConfig>(`/rubric/config/${id}`);
  return data;
}

type CreateRubricConfigParams = Pick<RubricConfig, 'displayName' | 'description' | 'type' | 'format'>;
export async function createRubricConfig(payload: CreateRubricConfigParams) {
  const { data } = await MaggieHttp.maggie.post<CreateRubricConfigParams, RubricConfig>(
    '/rubric/config',
    payload
  );
  return data;
}

export async function updateRubricConfig(id: number, payload: Partial<RubricConfig>) {
  const { data } = await MaggieHttp.maggie.put<Partial<RubricConfig>, RubricConfig>(
    `/rubric/config/${id}`,
    payload
  );
  return data;
}

export interface DeleteRubricConfigParams {
  force: boolean;
}
export async function deleteRubricConfig(id: number, { force }: DeleteRubricConfigParams) {
  return await MaggieHttp.maggie.delete(`/rubric/config/${id}`, {
    params: {
      force,
    },
  });
}

interface RubricQueryParams {
  date: string;
  entity: EntityType;
  entityId: number;
  rubricConfigId: number;
}

export async function getRubrics(params: Omit<RubricQueryParams, 'rubricConfigId'>): Promise<Rubric[]> {
  const { data } = await MaggieHttp.maggie.get<Rubric[]>('/rubric', {
    params: {
      ...params,
    },
  });
  return data;
}

// get rubric values by config id for all dates
export async function getRubricsForConfigId(params: Omit<RubricQueryParams, 'date'>): Promise<Rubric[]> {
  const { data } = await MaggieHttp.maggie.get<Rubric[]>('/rubric', {
    params: {
      ...params,
    },
  });
  return data;
}

export async function getRubricForDateAndConfigId({
  date,
  entity,
  entityId,
  rubricConfigId,
}: RubricQueryParams) {
  const { data } = await MaggieHttp.maggie.get<Rubric>(`/rubric`, {
    params: {
      date,
      entity,
      entityId,
      rubricConfigId,
    },
  });
  return data;
}

export interface ICreateOrUpdateRubricPayload extends Omit<Rubric, 'id' | keyof BaseModelWithID> {}
export async function createOrUpdateRubric(payload: ICreateOrUpdateRubricPayload) {
  const { data } = await MaggieHttp.maggie.post<ICreateOrUpdateRubricPayload, Rubric>('/rubric', payload);
  return data;
}

export async function deleteRubric(id: number) {
  return await MaggieHttp.maggie.delete(`/rubric/${id}`);
}

export async function deleteRubricByEntity({ date, entity, entityId, rubricConfigId }: RubricQueryParams) {
  return await MaggieHttp.maggie.delete(`/rubric`, {
    params: {
      date,
      entity,
      entityId,
      rubricConfigId,
    },
  });
}

// for history, use getRubricsForConfigId instead
export async function getRubricAudits(id: number) {
  const { data } = await MaggieHttp.maggie.get<RubricAudit[]>(`/rubrics/${id}/history`);
  return data;
}
