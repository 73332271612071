import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { MostRecentChartData } from './useMostRecentRounds';

export const useMostRecentParticipatingRounds = (data: IInvestmentDataModel[]): MostRecentChartData[] => {
  const uniqueData = new Map<string, MostRecentChartData>();
  const getCompany = useGetCompanyIfSet();

  const sortedData = (data ?? [])
    .reduce((res, { companyId, gcAmount, investmentDate, round }) => {
      const company = getCompany(companyId);
      if (company && gcAmount && investmentDate) {
        res.push({
          company,
          date: new Date(investmentDate),
          amount: gcAmount,
          round,
        });
      }
      return res;
    }, [] as MostRecentChartData[])
    .sort(({ date: a }, { date: b }) => {
      return b.getTime() - a.getTime();
    });

  for (let i = 0; i < sortedData.length && uniqueData.size < 5; i++) {
    uniqueData.set(sortedData[i].company.name, sortedData[i]);
  }

  return Array.from(uniqueData.values());
};
