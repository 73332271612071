import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { ROUTES } from '../../constants/RouteNameMapping';
import { BackButton } from '../../components/BackButton';
import { getDetailsSubsection, getFinanceSubsection } from './FinanceTabs';
import { useRouteBasedFinanceTitles } from './useRouteBasedFinanceTitles';

export const FinanceTitle: FC = () => {
  const { pathname } = useLocation();
  const subsection = getFinanceSubsection(pathname);
  const detailsSection = getDetailsSubsection(pathname);
  const FinTitles = useRouteBasedFinanceTitles();

  let title = FinTitles.default;
  if (subsection === ROUTES.FINANCE2_OVERVIEW) title = FinTitles.main;
  else if (subsection?.includes(ROUTES.FINANCE_RECON)) title = FinTitles.recon;
  else if (subsection?.includes(ROUTES.FINANCE_ALL_SECURITIES)) title = FinTitles.securities;
  else if (detailsSection) title = FinTitles.details;

  if (title === FinTitles.main || title === FinTitles.recon)
    return <Typography variant='h4'>{title}</Typography>;

  return (
    <Stack direction={'row'} gap='0.5rem' alignItems={'center'}>
      <BackButton to={`/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}`} title={`Go to ${FinTitles.main}`} />
      <Typography variant='h4' color='text.primary'>
        {title}
      </Typography>
    </Stack>
  );
};
