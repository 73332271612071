import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import { add } from 'date-fns';
import { useMemo } from 'react';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { FMT, FormatterService } from '../../../../../util/formatter-service';
import { KPIRecipientActions } from './hooks/useKPIRequestRecipientActions';

const headerContainerCSS = css`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  grid-column-gap: 1rem;
`;

export interface IKPIRecipientViewHeaderProps {
  company: ICompanyDataModel;
  isSubmitting: boolean;
  kpiRequest: IKPIRequestDataModel;
  onSkipResponse: KPIRecipientActions['skipKPIResponse'];
  onSubmitResponse: KPIRecipientActions['submitKPIResponse'];
}

export function KPIRecipientViewHeader3(props: IKPIRecipientViewHeaderProps) {
  const { company, isSubmitting, kpiRequest, onSkipResponse, onSubmitResponse } = props;

  return (
    <div css={headerContainerCSS}>
      <CompanyLogoAndName
        name={company.name}
        logoUrl={company.logoUrl ?? ''}
        typographyProps={{ fontSize: '26px' }}
      />
      <HeaderInfo kpiRequest={kpiRequest} />
      <div />
      <KPIRecipientViewButtons
        isSubmitting={isSubmitting}
        onSubmitResponse={onSubmitResponse}
        onSkipResponse={onSkipResponse}
      />
    </div>
  );
}

const HeaderInfo = ({ kpiRequest }: { kpiRequest: IKPIRequestDataModel | null }) => {
  const respondentFormatter = FormatterService.get().getFormatterForId('userByEmail');
  const { colors } = useTheme();

  const deadLineDate = useMemo(() => {
    if (kpiRequest?.status !== KPIRequestStatus.Sent || !kpiRequest?.sentAt) return null;

    const sentAt = new Date(kpiRequest.sentAt);
    return FMT.format('date', add(sentAt, { days: 14 }).toISOString());
  }, [kpiRequest?.status, kpiRequest?.sentAt]);

  return kpiRequest?.status === KPIRequestStatus.Sent ? (
    <Chip
      size='medium'
      label={`Deadline ${deadLineDate || 'N/A'}`}
      sx={{
        marginTop: '0.25rem',
        '& .MuiChip-label': {
          display: 'block',
          color: colors.secondary[80],
          fontSize: '1rem',
          whiteSpace: 'normal',
        },
      }}
    />
  ) : (
    <Stack direction='row' style={{ paddingTop: '.25rem' }} gap='1rem'>
      <Typography variant='body1' sx={{ color: colors.gray[700] }}>
        <b>From:</b> {respondentFormatter(kpiRequest?.respondent ?? [])}
      </Typography>
    </Stack>
  );
};

interface IKPIRecipientViewFooterProps {
  onSkipResponse: KPIRecipientActions['skipKPIResponse'];
  onSubmitResponse: KPIRecipientActions['submitKPIResponse'];
  isSubmitting: boolean;
}

export function KPIRecipientViewButtons({
  onSkipResponse,
  onSubmitResponse,
  isSubmitting,
}: IKPIRecipientViewFooterProps) {
  return (
    <Stack direction='row' gap='.5rem'>
      <LoadingButton
        loading={isSubmitting}
        onClick={onSkipResponse}
        size='medium'
        variant='outlined'
        color='secondary'
      >
        Skip
      </LoadingButton>
      <LoadingButton
        loading={isSubmitting}
        onClick={onSubmitResponse}
        size='medium'
        variant='contained'
        color='secondary'
      >
        Submit & Continue
      </LoadingButton>
    </Stack>
  );
}
