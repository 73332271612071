import { IDealStatsHistoryRequest } from '../../../services/queries/MaggieDealQueries';
import { IDealConversionStateKey } from '../state/DealConversionState';

export const cleanQueryParams = (arrParams: IDealConversionStateKey) =>
  Object.keys(arrParams).reduce((rest, param: string) => {
    const paramValue = arrParams[param as keyof IDealConversionStateKey];
    if (!paramValue || paramValue === -1) return rest;

    return { ...rest, [param]: paramValue };
  }, {}) as IDealStatsHistoryRequest;
