import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { showModalStateFinance } from '../state/FinanceUIState';
import { securityReportState } from '../state/FinanceState';
import { AllSecuritiesGrid } from './AllSecuritiesGrid';
import { AllSecuritiesFilters } from './AllSecuritiesFilters';

export function AllSecurities() {
  const resetModalState = useResetRecoilState(showModalStateFinance);
  const securityData = useRecoilValue(securityReportState);

  useEffect(() => {
    return resetModalState;
  }, [resetModalState]);

  if (!securityData) return null;

  return <AllSecuritiesContent />;
}

function AllSecuritiesContent() {
  return (
    <>
      <AllSecuritiesFilters />
      <AllSecuritiesGrid />
    </>
  );
}
