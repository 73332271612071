import { FC } from 'react';
import { useTheme } from '@mui/material';
import { styled } from '@mui/system';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const Cell = styled('span')`
  display: flex;
  min-height: 2.5rem;

  align-items: center;
  justify-content: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MappingErrorCell: FC = () => {
  const { colors } = useTheme();

  return (
    <Cell>
      <ErrorOutlineOutlinedIcon htmlColor={colors.critical[50]} />
    </Cell>
  );
};
