export const CompanyInitialValues = {
  name: '',
  website: '',
  sectorId: undefined,
  city: '',
  state: '',
  country: '',
  ceoName: '',
  shortDescription: '',
  sourceType: '',
  source: '',
  sourceCompany: '',
  internalSourceId: undefined,
};
