import { Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PopupFilter } from '../../../components/PopupFilter';
import { SelectItem } from '../../../types';
import { fundOptionsState } from '../../PortfolioOverview/state/FundFilterState';
import { securitiesFundFilterState, securitiesRealizedFilterState } from './AllSecuritiesUIState';

const realizedOptions: SelectItem[] = [
  { value: 'Realized', id: 'realized' },
  { value: 'Unrealized', id: 'unrealized' },
];
export function AllSecuritiesFilters() {
  const [realizedFilter, setRealizedFilter] = useRecoilState(securitiesRealizedFilterState);
  const [fundFilter, setFundFilter] = useRecoilState(securitiesFundFilterState);
  const fundOptions = useRecoilValue(fundOptionsState);

  return (
    <Stack direction={'row'} gap='1rem'>
      <PopupFilter
        selected={fundFilter}
        setSelected={setFundFilter}
        options={fundOptions}
        labels={{ item: 'Fund' }}
        groupable
      />
      <PopupFilter
        selected={realizedFilter}
        setSelected={setRealizedFilter}
        options={realizedOptions}
        labels={{ item: '', all: 'Realized/Unrealized' }}
        searchable={false}
      />
    </Stack>
  );
}
