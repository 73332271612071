import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormFactoryWithStandardLayout } from '../../../../../components/Form/FormFactory';
import {
  granularityDefaultFromFrequency,
  useAssignTemplateFormsSchema,
} from '../../../../../schemas/template-assignment-schema';
import { schemaToFormFields } from '../../../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../../../view-models/form.view-model';
import { selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { StepperContainer, StepperSx, StepSx } from '../../../../../components/Form/FormComponents';
import { pluralizeWord } from '../../../../../util/stringUtils';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { AssignTemplateStep2 } from './AssignTemplateStep2';
import { AssignTemplateStep1 } from './AssignTemplateStep1';
import { AssignTemplateStep3 } from './AssignTemplateStep3';
import { currentStepStateTA, useResetTemplateAssignmentFormState } from './AssignTemplateFormState';
import { validateCompanyData } from './template-assignment-utils';

export function AssignTemplateFields() {
  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();
  const { setValue } = useFormContext();
  const frequency = useWatch({ name: 'frequency' });
  const assignTemplateFormSchema = useAssignTemplateFormsSchema();

  const fields = schemaToFormFields(
    assignTemplateFormSchema(frequency),
    showGranularitySelector
      ? ['templateUuid', 'frequency', 'granularity', 'reminderThreshold']
      : ['templateUuid', 'frequency', 'reminderThreshold']
  );

  const form = createFormFromFieldsArray(fields);

  useEffect(() => {
    const defaultGranularity = granularityDefaultFromFrequency(frequency);
    setValue('granularity', defaultGranularity, { shouldValidate: true });
  }, [frequency, setValue]);

  return <FormFactoryWithStandardLayout form={form} />;
}

export function AssignTemplateForm() {
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const currentStep = useRecoilValue(currentStepStateTA);
  const resetForm = useResetTemplateAssignmentFormState();
  const steps = useMemo(() => {
    const missingData = selectedCompanies.some((c) => !validateCompanyData(c));
    const result = [
      { label: 'Request Settings', component: <AssignTemplateStep1 /> },
      { label: 'Summary', component: <AssignTemplateStep3 /> },
    ];
    if (missingData) {
      result.splice(1, 0, {
        label: `${pluralizeWord('Company', selectedCompanies.length)} Settings`,
        component: <AssignTemplateStep2 />,
      });
    }

    return result;
  }, [selectedCompanies]);

  useEffect(() => {
    return resetForm;
  }, [resetForm]);

  return (
    <StepperContainer>
      <Stepper activeStep={currentStep} sx={StepperSx}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{steps[currentStep]?.component}</>
    </StepperContainer>
  );
}
