import { throttle } from 'lodash-es';
import { SyntheticEvent, useMemo, useState } from 'react';

export const SearchThrottleDelayMs = 200;

export type ICreateOption<T> = T & {
  _typedText: string;
};

export type AutocompleteValue<Value, Multiple> = Multiple extends true ? Array<Value> : Value | null;

export interface IOptionsFromSearchConfig<Value, Multiple> {
  generateCreateOption?: (currentText: string) => Value;
  initialValue: AutocompleteValue<Value, Multiple>;
  onChange?: (newValue: AutocompleteValue<Value, Multiple>) => void;
  onCreateClick?: (typedText: string) => void;
  searchFn: (text: string) => Promise<Value[]>;
  selectedFilter?: (inputText: string, values: Value[]) => Value[];
}

export function useAsyncAutoComplete<Value, Multiple extends boolean | undefined = false>(
  config: IOptionsFromSearchConfig<Value, Multiple>
) {
  const { initialValue, searchFn, onChange, generateCreateOption, onCreateClick } = config;
  const [value, setValue] = useState<AutocompleteValue<Value, Multiple>>(initialValue);
  const [options, setOptions] = useState<ReadonlyArray<Value>>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);

  const doSearch = useMemo(
    () =>
      throttle(
        async (searchText) => {
          setIsSearching(true);
          const createOption: ICreateOption<Value> | undefined = generateCreateOption
            ? {
                ...generateCreateOption(searchText),
                _typedText: searchText,
              }
            : undefined;
          if (createOption) {
            setOptions([createOption]);
          } else {
            setOptions([]);
          }

          try {
            const searchResults = await searchFn(searchText);
            if (createOption) {
              searchResults.unshift(createOption);
            }

            setOptions(searchResults);
          } catch (e) {
            console.error(e);
            setOptions([]);
          } finally {
            setIsSearching(false);
          }
        },
        SearchThrottleDelayMs,
        {
          leading: false,
          trailing: true,
        }
      ),
    [generateCreateOption, searchFn]
  );

  const onInputChange = (event: SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input' || reason === 'clear') {
      setOpen(value.length > 0);

      if (value === '') {
        setOptions([]);
      } else {
        doSearch.cancel();
        doSearch(value);
      }
    }
  };

  const onFocus = () => {
    setOpen(true);
    if (Array.isArray(initialValue)) {
      setOptions([...initialValue]);
    } else {
      setOptions([initialValue as Value]);
    }
  };

  const onValueChange = (event: SyntheticEvent, newValue: AutocompleteValue<Value, Multiple>) => {
    setValue(newValue);

    if ((newValue as ICreateOption<Value>)?._typedText) {
      onCreateClick?.((newValue as ICreateOption<Value>)._typedText);
    } else {
      onChange?.(newValue);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return {
    isSearching,
    onClose,
    onFocus,
    onInputChange,
    onValueChange,
    open,
    options,
    value,
  };
}
