import { atom, atomFamily, selector } from 'recoil';
import { fetchColumns, fetchViews } from '../../../services/queries/MaggieViewQueries';
import { ViewModel } from '../../../models/view.model';
import { COMPANY_VIEW_TYPE } from '../../../data-models/view-config.data-model';
import { ColumnMeta } from '../../../types';

export const selectedViewPF = selector<ViewModel>({
  key: 'selectedViewPF',
  get: ({ get }) => {
    const viewId = get(selectedViewIdPF);
    const views = get(viewsPF);

    return views.find((view) => view.id === viewId)!;
  },
});

export const selectedViewIdPF = atom<number>({
  key: 'selectedViewIdPF',
  default: -1,
});

export const viewsPF = atom<ViewModel[]>({
  key: 'viewsPF',
  effects: [
    ({ setSelf }) => {
      setSelf(fetchViews());
    },
  ],
});

export const columnsMetaState = atomFamily<ColumnMeta[], COMPANY_VIEW_TYPE>({
  key: 'columnsMetaState',
  effects: (companyViewType) => [
    ({ setSelf }) => {
      return setSelf(fetchColumns(companyViewType));
    },
  ],
});
