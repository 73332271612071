import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import DownloadIcon from '@mui/icons-material/Download';
import { ListType } from '../../../../data-models/list.data-model';
import { activeCompanyListIdState, activeListState } from '../../state/ListsState';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { filteredAndSortedListsState } from '../state/FiltersState';
import { formatDateNumeric } from '../../../../util/formatters/DateFormatters';
import { convertToCSV } from '../../utils/csvUtils';
import { useGenerateCompanyRows } from './hooks/useGenerateCompanyRows';

export const CSV_LIST_DETAILS_COLUMNS = [
  'Company Name',
  'Website',
  'Sector',
  'Added Date',
  'Action',
  'Action Date',
  'Owner',
];

export function ListExportButton() {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const activeList = useRecoilValue(activeListState);
  const sortedItemsList = useRecoilValue(filteredAndSortedListsState(activeListId ?? -1));

  const generateCompanyRows = useGenerateCompanyRows();

  const canEditLists = PermissionService.get().hasPermission(PermissionKey.canEditList);

  const downloadListContent = useCallback(() => {
    if (!sortedItemsList) return;

    const companiesRows = generateCompanyRows();
    const currentDate = formatDateNumeric(new Date().toISOString());
    const activeListName = activeList?.name ?? '';
    const fileName = `[${activeListName}.${currentDate}].csv`;

    convertToCSV(companiesRows, fileName, CSV_LIST_DETAILS_COLUMNS);
  }, [sortedItemsList, generateCompanyRows, activeList?.name]);

  if (!(sortedItemsList && canEditLists && activeList?.type === ListType.DISCOVERY)) return null;

  return (
    <>
      <IconButton onClick={downloadListContent} aria-label='Download list content' color='secondary'>
        <DownloadIcon />
      </IconButton>
    </>
  );
}
