import { useRecoilValue } from 'recoil';
import {
  ICaptableDataModel,
  IStakeholderInvestmentDataModel,
  IStakeholderTotals,
} from '../../../../data-models/captable2.data-model';
import { fundsByIdMapState } from '../../../../services/state/AppConfigState';
import { ViewModelInvestmentType } from '../../../../schemas/common-schema-defs';
import { getShareClassType } from '../../../../view-models/captable.view-model';
import { isClientInvestment } from '../captable-utils';

export interface IInvestmentData {
  cashRaised?: number | null;
  fullyDilutedShares?: number | null;
  investmentName: string;
  investmentType: ViewModelInvestmentType;
  ownershipPercentage?: number | null;
  principal?: number | null;
  outstandingEquityAwardDerivatives?: number | null;
  outstandingCommittedRestrictedStockAwards?: number | null;
}

export interface ICaptableSummaryRowData {
  fundId?: number; // hidden field, signifies client investment
  investments: Record<string, Record<string, IInvestmentData>>; // investments grouped by type, then by name (names are not unique across different types)
  stakeholder: string | number; // hidden field, fundId or stakeholderName, used for mapping
  stakeholderName: string;
  totals?: Omit<IStakeholderTotals, 'stakeholderName' | 'fundId'>;
}

export function useCaptableSummaryRowData(captable: ICaptableDataModel | null) {
  const fundMap = useRecoilValue(fundsByIdMapState);
  if (!captable || !captable.captableInvestments?.length) return [];

  const totalsMap = new Map<string | number, ICaptableSummaryRowData['totals']>();
  withAggregatedExternalStakeholderData((captable.stakeholdersTotals as StakeholderData[]) ?? [])?.forEach(
    (st) => {
      const { fundId, stakeholderName, ...rest } = st;
      if (fundId != null || stakeholderName != null) {
        totalsMap.set(fundId ?? stakeholderName!, rest);
      }
    }
  );

  const stakeholderData = new Map<string | number, ICaptableSummaryRowData>();

  captable.captableInvestments.forEach((investment) => {
    const investmentType = getShareClassType(investment.investmentType, investment.shareClassType);
    const investmentName = investment.name;

    if (!investmentType || !investmentName) return;

    const stakeholderInvestments = withAggregatedExternalStakeholderData(
      investment.stakeholderInvestments ?? []
    );
    // the key is either fundId (for client) or stakeholderName (external)
    stakeholderInvestments.forEach((inv, key) => {
      const row: Partial<ICaptableSummaryRowData> = stakeholderData.get(key) ?? {
        stakeholder: key,
        stakeholderName:
          inv.fundId != null ? fundMap.get(inv.fundId)?.name ?? 'Unknown Fund' : inv.stakeholderName!,
        fundId: inv.fundId ?? undefined,
        investments: {
          [investmentType]: {},
        },
      };

      const currentInvestments = stakeholderData.get(key)?.investments?.[investmentType] ?? {};

      const {
        cashRaised,
        fullyDilutedShares,
        principal,
        outstandingEquityAwardDerivatives,
        outstandingCommittedRestrictedStockAwards,
        ownershipPercentage,
      } = inv;

      row.investments![investmentType]! = {
        ...currentInvestments,
        [investmentName!]: {
          cashRaised,
          fullyDilutedShares,
          investmentName: investmentName!,
          investmentType,
          outstandingEquityAwardDerivatives,
          outstandingCommittedRestrictedStockAwards,
          ownershipPercentage,
          principal,
        },
      };
      stakeholderData.set(key, row as ICaptableSummaryRowData);
    });
  });

  stakeholderData.forEach((value, key) => {
    const totals = totalsMap.get(key);
    if (totals) {
      value.totals = totals;
    }
  });

  return Array.from(stakeholderData.values());
}

type StakeholderData = Omit<IStakeholderInvestmentDataModel, 'currencyId'>;
/* data might contain multiple entries for the same external stakeholder (at fund level), 
but we want just one for the table */
export function withAggregatedExternalStakeholderData(investments: StakeholderData[]) {
  const investmentsByStakeholder = new Map<
    string | number,
    Omit<IStakeholderInvestmentDataModel, 'currencyId'>
  >();
  investments.forEach((inv) => {
    if (isClientInvestment(inv) && inv.fundId != null) {
      investmentsByStakeholder.set(inv.fundId, inv);
      return;
    }
    if (!inv.stakeholderName) return; // bad data

    if (!investmentsByStakeholder.get(inv.stakeholderName!)) {
      investmentsByStakeholder.set(inv.stakeholderName, inv);
      return;
    }

    const {
      cashRaised,
      fullyDilutedShares,
      principal,
      ownershipPercentage,
      outstandingEquityAwardDerivatives,
      outstandingCommittedRestrictedStockAwards,
    } = inv;
    const current = investmentsByStakeholder.get(inv.stakeholderName)!;
    const aggregated = {
      cashRaised: (current.cashRaised ?? 0) + (cashRaised ?? 0),
      fullyDilutedShares: (current.fullyDilutedShares ?? 0) + (fullyDilutedShares ?? 0),
      principal: (current.principal ?? 0) + (principal ?? 0),
      ownershipPercentage: (current.ownershipPercentage ?? 0) + (ownershipPercentage ?? 0),
      outstandingEquityAwardDerivatives:
        (current.outstandingEquityAwardDerivatives ?? 0) + (outstandingEquityAwardDerivatives ?? 0),
      outstandingCommittedRestrictedStockAwards:
        (current.outstandingCommittedRestrictedStockAwards ?? 0) +
        (outstandingCommittedRestrictedStockAwards ?? 0),
    };
    investmentsByStakeholder.set(inv.stakeholderName, { ...current, ...aggregated, fundName: undefined }); // unset fundName since it's different for each investment
  });
  return investmentsByStakeholder;
}
