import { IViewDataModel, ViewDataModelCreatePayload } from '../data-models/view.data-model';
import {
  COMPANY_VIEW_TYPE,
  RowGroupModel,
  VIEW_TYPE,
  ViewComponents,
  ViewConfigDataModel,
  ViewOrder,
  ViewSections,
  ViewTypeByData,
} from '../data-models/view-config.data-model';
import { IFundDataModel } from '../data-models/fund.data-model';

const VERSION = 1;

export interface ViewModel extends ViewConfigDataModel {
  companyViewType: COMPANY_VIEW_TYPE;
  components: ViewComponents;
  createdBy: string;
  filteredFunds: IFundDataModel[] | null;
  id: number;
  isDefault: boolean;
  viewType: ViewTypeByData;
  label: string;
  name: string;
  order: ViewOrder;
  origin: null | ViewModel; // null for VIEW_TYPE.COMPANY, View for personal/custom views, used for resetting
  rowGroupModel?: RowGroupModel | null;
  sections: ViewSections;
  sharing?: string[];
  tableFilters: Record<string, unknown> | null;
  type: VIEW_TYPE;
  viewId: string;
}

export function deserializeView(viewDataModel: IViewDataModel): ViewModel {
  return {
    ...viewDataModel.config,
    createdBy: viewDataModel.createdBy ?? '',
    id: viewDataModel.id || 0,
    isDefault: viewDataModel.isDefault,
    viewType: viewDataModel.viewType,
    label: viewDataModel.displayName,
    name: viewDataModel.name,
    sharing: viewDataModel.sharing?.map((item) => item.userId) ?? [],
    type: getViewType(viewDataModel.isDefault, viewDataModel.shared ?? false),
    viewId: viewDataModel.viewId,
  };
}

export function serializeView(viewModel: ViewModel): IViewDataModel {
  const { createdBy, id, label, name, sharing, type, viewId, viewType, ...config } = viewModel;

  return {
    createdBy,
    config,
    displayName: label,
    id,
    isDefault: type === VIEW_TYPE.COMPANY,
    viewType,
    name,
    sharing: sharing?.map((item) => {
      return { userId: item };
    }),
    shared: viewId === VIEW_TYPE.SHARED,
    version: VERSION,
    viewId,
  };
}

export function serializeViewForCreation(viewModel: ViewModel): ViewDataModelCreatePayload {
  const newView = serializeView(viewModel);
  delete newView.id;

  return newView;
}

function getViewType(isDefault: boolean, isShared: boolean): VIEW_TYPE {
  if (isDefault) {
    return VIEW_TYPE.COMPANY;
  }
  if (isShared) {
    return VIEW_TYPE.SHARED;
  }
  return VIEW_TYPE.PERSONAL;
}
