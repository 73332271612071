import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { useRubricConfigFormData } from './useRubricConfigFormData';
import { RubricConfigForm } from './RubricConfigForm';

export function RubricConfigFormModal() {
  const { resetFormState, defaultValues, selectedAction } = useRubricConfigFormData();

  const openForm = selectedAction === 'edit' || selectedAction === 'create';
  if (!openForm) return null;

  const title = selectedAction === 'edit' ? 'Edit Rubric' : 'Create Rubric';

  return (
    <BasicDialog open={openForm} onClose={resetFormState} title={title}>
      <FormFieldsContainer>
        <RubricConfigForm defaultValues={defaultValues} action={selectedAction!} onClose={resetFormState} />
      </FormFieldsContainer>
    </BasicDialog>
  );
}
