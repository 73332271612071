import { get, set } from 'lodash-es';
import { ColDef } from 'ag-grid-community';
import { FieldPath } from 'react-hook-form';
import {
  CapitalAllocation,
  capitalAllocationFields,
  capitalAllocationSchema,
} from '../../../schemas/FundCapitalAllocation.schema';
import { camelCaseToCapitalizedString } from '../../../util/stringUtils';
import { schemaToFormFields } from '../../../util/schema-utils';
import { numericCellClasses } from '../../Finance2/common-grid-defs/commonColDefs';
import { fundFormSchema } from '../../../schemas/Fund.schema';
import { IFormField } from '../../../view-models/form.view-model';
import { FMT, StandardFormatterId } from '../../../util/formatter-service';

function fieldToGroup(field: CapitalAllocationRowData['field'] | FieldPath<CapitalAllocation>) {
  const fieldLc = field.toLowerCase();
  switch (true) {
    case fieldLc.includes('initialinvestment'):
      return 'Initial Investments';
    case fieldLc.includes('reserve'):
      return 'Reserves';
    case fieldLc.includes('fees'):
      return 'Fees & Expenses';
  }
}
export interface CapitalAllocationRowData extends Record<string, unknown> {
  field: keyof CapitalAllocation | 'committedCapital';
  name: string;
  group?: string;
}

const excludedFields = new Set<FieldPath<CapitalAllocation>>([
  'dateTo',
  'dateFrom',
  'period',
  'projectedFeesAndExpensesTransaction',
  'projectedFeesAndExpenses', // duplicate of 'projectedFeesAndExpensesTransaction.amount'
]);

const includedFields = () =>
  [
    ...(Object.keys(capitalAllocationFields()).filter(
      (path) => !excludedFields.has(path as FieldPath<CapitalAllocation>)
    ) as (keyof CapitalAllocation)[]),
    'projectedFeesAndExpensesTransaction.amount',
  ] as FieldPath<CapitalAllocation>[];

export function committedCapitalRow() {
  const committedCapitalField = schemaToFormFields(fundFormSchema(), ['committedCapital']).at(0);
  return {
    field: 'committedCapital',
    name: committedCapitalField?.label ?? 'Committed Capital',
  } as CapitalAllocationRowData;
}

export function getCapitalAllocationRowData(data: CapitalAllocation[]): CapitalAllocationRowData[] {
  const fieldMap = schemaToFormFields(capitalAllocationSchema(), includedFields()).reduce(
    (map, field) => map.set(field.key, field),
    new Map<string, IFormField<unknown>>()
  );
  const dataByField = data.reduce(
    (acc, row) => {
      includedFields().forEach((field) => {
        const f = fieldMap.get(field);
        set(acc, [field, row.period], get(row, field));
        set(acc, [field, 'name'], f?.label ?? camelCaseToCapitalizedString(field));
        set(acc, [field, 'field'], field);
        set(acc, [field, 'group'], fieldToGroup(field as FieldPath<CapitalAllocation>));
      });
      return acc;
    },
    {} as Record<keyof CapitalAllocation, CapitalAllocationRowData>
  );

  return Object.values(dataByField);
}

export function getCapitalAllocationColDefs(
  data?: CapitalAllocation[] | null
): ColDef<CapitalAllocationRowData>[] {
  const fieldMap = schemaToFormFields(capitalAllocationSchema(), includedFields()).reduce(
    (map, field) => map.set(field.key, field),
    new Map<string, IFormField<unknown>>()
  );
  const periodDefs: ColDef<CapitalAllocationRowData>[] =
    data?.map((quarterlyData) => {
      return {
        field: quarterlyData.period,
        headerName: quarterlyData.period,
        suppressHeaderMenuButton: true,
        cellClass: numericCellClasses,
        valueFormatter: (params) => {
          if (params.value == null || !params.data) return '';
          const formatter = fieldMap.get(params.data.field)?.formatter;
          return formatter ? FMT.format(formatter as StandardFormatterId, params.value) : params.value;
        },
      };
    }) ?? [];

  const nameDef: ColDef<CapitalAllocationRowData> = {
    field: 'name',
    headerName: '',
    pinned: true,
    filter: true,
    minWidth: NameColumnWidth,
    maxWidth: NameColumnWidth,
    tooltipField: 'name',
    resizable: false,
  };
  const groupDef: ColDef<CapitalAllocationRowData> = {
    hide: true,
    field: 'group',
    rowGroup: true,
    pinned: 'left',
  };

  return [groupDef, nameDef, ...periodDefs];
}

export const NameColumnWidth = 250;
