import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { usdField } from '../../../../../../../schemas/common-schema-defs';
import { escrowFields } from './EscrowSchema';

export function escrowWriteOffFields() {
  return {
    ...escrowFields(),
    escrowAmount: usdField().nullable().required(requiredMsg('Amount')),
  };
}

export function escrowWriteOffFormSchema() {
  return yup.object().shape(escrowWriteOffFields());
}

export function escrowWriteOffGridSchema() {
  return yup.object().shape(escrowWriteOffFields());
}
