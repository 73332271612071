import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { removeAllWhitespace } from '../../../../../util/stringUtils';
import { getFieldsMap } from '../Columns/getFieldsMap';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';

export const prepInitialRowDataTransposed = (companies: ICompanyDataModel[], fields: FieldDataModel[]) => {
  const result: Record<string, unknown>[] = [];
  const fieldsMap = getFieldsMap(fields);
  fields.forEach((f) => {
    const row: Record<string, unknown> = {};
    row.id = String(f.id);
    row.field = fieldsMap[f.id];
    if (f.groupName) row[removeAllWhitespace(f.groupName)] = f.groupName;
    else row['Other'] = 'Other';
    result.push(row);
  });
  return result;
};
