import { ICurrencyDataModel } from './currency.data-model';
import { IUserDataModel } from './user.data-model';
import { IFundDataModel } from './fund.data-model';
import { IRoundDataModel } from './round.data-model';
import { TransactionType } from './transaction-type.data-model';
import { IObjectiveCategoryDataModel } from './objective-category.data-model';
import { IFormatterDataModel } from './formatter.data-model';
import { IMetricsConfigDataModel } from './metrics-config.data-model';

export interface ISortableItem {
  sortOrder?: number;
}

export interface IGenericSortable extends ISortableItem {
  displayName: string;
  id: number;
  name: string;
  sortOrder: number;
}

export interface IAppConfigDataModel {
  currencies: ICurrencyDataModel[];
  formatters: IFormatterDataModel<unknown>[];
  funds: IFundDataModel[];
  metricsConfig: IMetricsConfigDataModel;
  objectiveCategory: IObjectiveCategoryDataModel[];
  rounds: IRoundDataModel[];
  sectors: IGenericSortable[];
  teams: IGenericSortable[];
  transactionTypes: TransactionType[];
  users: IUserDataModel[];
  updatedAt: string; // This field won't be available until MAGGIE-4091 is completed.
}

export function createGenericSortableDataModel(overrides: Partial<IGenericSortable> = {}): IGenericSortable {
  return {
    displayName: '',
    id: -1,
    name: '',
    sortOrder: -1,
    ...overrides,
  };
}

export function sortGenericSortable(item1: ISortableItem, item2: ISortableItem) {
  if (item1.sortOrder !== undefined && item2.sortOrder !== undefined) {
    return item1.sortOrder - item2.sortOrder;
  }
  return item1.sortOrder ?? item2.sortOrder ?? 0;
}
