import { useMemo } from 'react';
import { css } from '@emotion/react';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { UnstyledChipSelector } from '../../../../components/ChipSelector';
import { MONTHS } from '../../../KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { ISimpleChoice } from '../../../../data-models/field2.data-model';
import { FinanceDateSelector, PeriodPicker } from './FinanceDatePickers';
import { useFiscalDatePicker } from './useFiscalDatePicker';

const mainPickerContainer = css`
  padding: 1rem;
  display: grid;
  column-gap: 2rem;
  transition: 300ms;
`;

const frequencyToColumns: Record<KpiPeriod, string> = {
  [KpiPeriod.month]: '1fr 1fr',
  [KpiPeriod.quarter]: '1fr 2fr',
  [KpiPeriod.year]: '1fr 0fr',
};

const gridContainer = css`
  display: grid;
  gap: 0.5rem;
  align-content: start;
`;

const monthGridContainer = css`
  ${gridContainer};
  grid-template-columns: repeat(3, 1fr);
`;

const quarterGridContainer = css`
  ${gridContainer};
  grid-template-columns: repeat(2, 1fr);
`;

const yearGridContainer = css`
  ${gridContainer};
  grid-template-columns: repeat(5, 1fr);
`;

interface IFiscalDatePickerProps {
  fye: number;
  range?: {
    startYear: number;
    endYear: number;
  };
}
export function FiscalDatePicker({
  fye,
  range = {
    startYear: new Date().getFullYear() - 9,
    endYear: new Date().getFullYear() + 1,
  },
}: IFiscalDatePickerProps) {
  const { frequency, onChangeFrequency, label, year, month, quarter, setMonth, setQuarter, setYear } =
    useFiscalDatePicker({ fye });

  return (
    <FinanceDateSelector label={label}>
      <div css={mainPickerContainer} style={{ gridTemplateColumns: `auto ${frequencyToColumns[frequency]}` }}>
        <div css={gridContainer}>
          <PeriodPicker value={frequency} onChange={onChangeFrequency} />
        </div>
        {frequency === KpiPeriod.month && <MonthPicker value={month} onChange={setMonth} />}
        {frequency === KpiPeriod.quarter && <QuarterPicker value={quarter} onChange={setQuarter} />}
        <YearPicker startYear={range.startYear} endYear={range.endYear} value={year} onChange={setYear} />
      </div>
    </FinanceDateSelector>
  );
}

interface IPickerProps {
  value: number | null;
  onChange: (value: number) => void;
}
const monthOptions = Array.from({ length: 12 }, (_, i) => i).map((i) => ({
  value: i,
  displayName: MONTHS[i],
}));
export function MonthPicker({ value, onChange }: IPickerProps) {
  return (
    <div css={monthGridContainer}>
      <UnstyledChipSelector options={monthOptions} value={value} onChange={onChange} />
    </div>
  );
}

const quarterOptions = Array.from({ length: 4 }, (_, i) => i).map((i) => ({
  value: i + 1,
  displayName: `Q${i + 1}`,
}));
export function QuarterPicker({ value, onChange }: IPickerProps) {
  return (
    <div css={quarterGridContainer}>
      <UnstyledChipSelector options={quarterOptions} value={value} onChange={onChange} />
    </div>
  );
}

interface IYearPickerProps extends IPickerProps {
  startYear: number;
  endYear: number;
}
export function YearPicker({ startYear, endYear, value, onChange }: IYearPickerProps) {
  const yearOptions = useMemo(() => {
    const result = [] as ISimpleChoice<number>[];
    for (let i = startYear; i <= endYear; i++) {
      result.push({ value: i, displayName: i.toString() });
    }
    return result;
  }, [startYear, endYear]);
  return (
    <div css={yearGridContainer}>
      <UnstyledChipSelector options={yearOptions} value={value} onChange={onChange} />
    </div>
  );
}
