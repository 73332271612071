import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { noop } from 'lodash-es';
import { schemaToFormFields } from '../../../util/schema-utils';
import { Fund, fundProfilesFormSchema } from '../../../schemas/Fund.schema';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { UnstyledFieldset } from '../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';

export const SettingsFieldset = css`
  display: grid;
  margin-top: 1rem;
`;

export function WaterfallSettingsFields({ fund }: { fund: Fund }) {
  const form = useMemo(() => {
    const fields = schemaToFormFields(
      fundProfilesFormSchema().pick([
        'lpGpSplit',
        'lpGpSplitThreshold',
        'gpCatchUpPercentage',
        'superReturnSplit',
      ])
    );
    return createFormFromFieldsArray(fields, { variant: 'form-inline' });
  }, []);
  const methods = useForm<Fund>({
    defaultValues: fund!,
  });
  return (
    <FormProvider {...methods}>
      <UnstyledFieldset disabled css={SettingsFieldset}>
        <FormFactoryNoLayout form={form} onChange={noop} />
      </UnstyledFieldset>
    </FormProvider>
  );
}
