import { atomFamily } from 'recoil';
import { IObjectiveDataModel } from '../../../data-models/objective.data-model';
import { fetchObjectivesForCompany } from '../../../services/queries/MaggieObjectivesQueries';

export const objectivesByCompanyState = atomFamily<IObjectiveDataModel[] | null, number>({
  key: 'allObjectivesState',
  default: null,
  effects: (companyId) => [
    ({ setSelf }) => {
      try {
        const objectives = fetchObjectivesForCompany(companyId);
        setSelf(objectives ?? null);
      } catch (err) {
        console.error('Error fetching objectives for company: ', err);
        setSelf(null);
      }
    },
  ],
});
