import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { LinkRenderer } from '../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/LinkRenderer';
import { MultiLinkRenderer } from '../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/MultiLinkRenderer';

import { IMappingsRowDataModel, isPrimaryWebsite, isSecondaryWebsite } from './mappingDataUtils';
import { MappingErrorCell } from './MappingErrorCell';

export const WebsiteRenderer: FC<ICellRendererParams<IMappingsRowDataModel>> = (params) => {
  const { data, value } = params;

  if (value === null && isPrimaryWebsite(data)) return <MappingErrorCell />;

  if (!value) return null;

  if (isPrimaryWebsite(data))
    return <LinkRenderer value={String(value)} style={{ maxWidth: 'fit-content' }} />;

  if (isSecondaryWebsite(data)) return <MultiLinkRenderer value={value as unknown as string[]} />;
  return null;
};
