import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { transactionTypesAndSubTypeAtom } from '../../../services/state/AppConfigStateJ';
import { SelectItem } from '../../../types';

export function useTransactionTypeOptions() {
  const typeMap = useAtomValue(transactionTypesAndSubTypeAtom);

  return useMemo(() => {
    const options = [] as SelectItem[];
    typeMap.forEach((subtypeMap, group) => {
      subtypeMap.forEach((subtype, name) => {
        options.push({ value: name, id: subtype.id, group });
      });
    });
    return options;
  }, [typeMap]);
}
