import { FC, useCallback, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { add, endOfMonth, sub } from 'date-fns';
import { Stack } from '@mui/material';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import {
  selectedMetricsDateCPState,
  selectedFrequencyPerfState,
} from '../../../state/CompanyFinancialsDateState';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { companyState } from '../../../../../services/state/CompanyState';
import { endOfFiscalPeriod } from '../../../utils/financialUtils';

import { FyeChip } from '../../../components/Financials/components/FyeChip';
import { FiscalDatePicker } from '../../../components/FinancialsDatePickers/FiscalDatePicker';
import { ReportingFrequencyToggler } from './ReportingFrequencyToggler';
import { FinancialDateSelector } from './FinancialDateSelector';

const SettingsBar = styled('div')`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DateSettings: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedMetricsDateCPState(companyId));
  const [selectedPeriod, setSelectedPeriod] = useRecoilState(selectedFrequencyPerfState(companyId));
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const onPeriodChange = useCallback(
    (event: MouseEvent, value: KpiPeriod) => {
      if (value === null) return;
      setSelectedPeriod(value);
      if (value === KpiPeriod.quarter || value === KpiPeriod.year) {
        setSelectedDate((curr) => endOfFiscalPeriod(curr ?? new Date(), value, fye - 1));
      }
    },
    [fye, setSelectedDate, setSelectedPeriod]
  );

  const onNextPeriodClick = useCallback(() => {
    setSelectedDate((current) => {
      return endOfMonth(add(current, { months: monthsInPeriod[selectedPeriod] }));
    });
  }, [selectedPeriod, setSelectedDate]);

  const onPreviousPeriodClick = useCallback(() => {
    setSelectedDate((current) => {
      return endOfMonth(sub(current, { months: monthsInPeriod[selectedPeriod] }));
    });
  }, [selectedPeriod, setSelectedDate]);

  return (
    <SettingsBar>
      <FinancialDateSelector
        label={'As of Date'}
        onPreviousPeriodClick={onPreviousPeriodClick}
        onNextPeriodClick={onNextPeriodClick}
        fye={fye}
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod}
      />
      <Stack direction={'row'} justifyContent={'start'} alignItems={'center'} gap={'0.5rem'}>
        <FyeChip fye={fye} />
        <ReportingFrequencyToggler value={selectedPeriod} onChange={onPeriodChange} />
      </Stack>
    </SettingsBar>
  );
};

export const monthsInPeriod = {
  [KpiPeriod.month]: 1,
  [KpiPeriod.quarter]: 3,
  [KpiPeriod.year]: 12,
};

export function DateSettings2() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;
  return (
    <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} mt='1rem' mb='-1rem'>
      <FiscalDatePicker fye={fye} />
      <FyeChip fye={fye} />
    </Stack>
  );
}
