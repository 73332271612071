import { AvatarGroup, Theme } from '@mui/material';
import * as React from 'react';
import { SxProps } from '@mui/system';
import { AvatarSize, avatarSizeMapping } from './MUIAvatar';

export interface IMUIAvatarGroupProps {
  children?: React.ReactNode;
  max: number;
  size: AvatarSize;
  sx?: SxProps<Theme>;
  className?: string;
  additionalAvatarTitle?: string;
}

const groupSpacingMapping: Record<AvatarSize, number> = {
  gridCell: 8,
  small: 6,
  medium: 8,
  mediumLarge: 12,
  large: 16,
};

export function MUIAvatarGroup(props: IMUIAvatarGroupProps) {
  const { children, max, size, sx, className, additionalAvatarTitle } = props;
  const sizeSettings = avatarSizeMapping[size];

  return (
    <AvatarGroup
      className={className}
      max={max}
      componentsProps={{
        additionalAvatar: {
          sx: sizeSettings,
          title: additionalAvatarTitle,
        },
      }}
      spacing={groupSpacingMapping[size]}
      sx={{
        ...sx,
        justifyItems: 'left',
        justifyContent: 'start',
      }}
    >
      {children}
    </AvatarGroup>
  );
}
