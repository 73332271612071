import { atomFamily } from 'recoil';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { fetchCompanyRaw } from '../queries/MaggieCompanyQueries';

export const companyState = atomFamily<ICompanyDataModel | null, number>({
  key: 'companyById',
  default: null,
  effects: (companyId) => [
    ({ setSelf, trigger }) => {
      if (companyId >= 0 && trigger === 'get') {
        try {
          setSelf(fetchCompanyRaw(companyId));
        } catch (err) {
          console.error(err);
          setSelf(null);
        }
      } else {
        setSelf(null);
      }
    },
  ],
});
