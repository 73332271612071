import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React, { CSSProperties, ReactNode } from 'react';
import { IItemWithId } from '../SortableList/SortableList';

export interface IListContainerProps<T extends IItemWithId> {
  id: string;
  items: T[];
  renderItem(item: T, index: number): ReactNode;
  containerStyle?: CSSProperties;
}

export function ListContainer<T extends IItemWithId>(props: IListContainerProps<T>) {
  const { id, items, renderItem, containerStyle } = props;
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
      <div ref={setNodeRef} style={containerStyle}>
        {items.map((item, index) => (
          <React.Fragment key={item.id}>{renderItem(item, index)}</React.Fragment>
        ))}
      </div>
    </SortableContext>
  );
}
