import { useRecoilValue, useSetRecoilState } from 'recoil';
import { updateList } from '../../../../../services/queries/MaggieListQueries';
import { useToastMessageState } from '../../../../ToastMessage/ToastMessageProvider';
import { activeCompanyListIdState, listsState } from '../../../../../pages/CompanyProfiles/state/ListsState';

export const useSetArchiveDateOnList = (numOfDays: number | null): (() => Promise<void>) => {
  const { pushErrorToast } = useToastMessageState();
  const listId = useRecoilValue(activeCompanyListIdState);
  const setLists = useSetRecoilState(listsState);

  return async () => {
    try {
      if (listId === null) throw new Error('No list id provided');

      let archiveDate: Date | null = new Date();

      if (numOfDays) {
        archiveDate.setDate(archiveDate.getDate() + numOfDays);
      }

      if (numOfDays == null) {
        archiveDate = null;
      }

      const updatedList = await updateList({ archiveDate }, listId);

      setLists((currVal) => {
        if (!currVal) return currVal;
        return currVal.map((item) => (item.id === listId ? updatedList : item));
      });
    } catch {
      pushErrorToast({ message: 'An error occurred while trying to delete the list.' });
    }
  };
};
