import { FC, MouseEvent } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';

export const RangeOptions: Record<KpiPeriod, number[]> = {
  [KpiPeriod.month]: [6, 12, 24, 48],
  [KpiPeriod.quarter]: [4, 8, 16, 24],
  [KpiPeriod.year]: [2, 3, 5],
};

interface IPeriodRangeTogglerProps {
  value: number;
  onChange: (event: MouseEvent, value: number) => void;
  period: KpiPeriod;
}

export const PeriodRangeToggler: FC<IPeriodRangeTogglerProps> = ({ value, onChange, period }) => {
  let label = '';
  switch (period) {
    case KpiPeriod.month:
      label = 'months';
      break;
    case KpiPeriod.quarter:
      label = 'quarters';
      break;
    case KpiPeriod.year:
      label = 'years';
      break;
  }

  return (
    <ToggleButtonGroup
      color='secondary'
      value={value}
      exclusive
      onChange={onChange}
      aria-label='Select reporting frequency'
    >
      {RangeOptions[period].map((option) => (
        <ToggleButton key={option} value={option}>
          {`${option} ${label}`}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
