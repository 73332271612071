import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedTransactionStateFinance, showModalStateFinance } from '../../state/FinanceUIState';
import { AssignToInvestmentModal } from '../AssignToRound/AssignToInvestmentModal';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { useTransactionModalActions } from '../../hooks/useFinanceModalActions';
import { DeleteTransactionModal } from './DeleteTransactionModal';
import { EditTransactionModal } from './EditTransactionModal';

export const TransactionModals: FC = () => {
  const selectedTransaction = useRecoilValue(selectedTransactionStateFinance);
  const showModalState = useRecoilValue(showModalStateFinance);
  const { handleCloseModal } = useTransactionModalActions();

  const commonModalProps = useMemo(() => {
    if (!selectedTransaction) return null;
    return {
      onClose: handleCloseModal,
      transaction: selectedTransaction!,
    };
  }, [handleCloseModal, selectedTransaction]);

  if (!commonModalProps) return null;
  return (
    <GenericFallbacksWrapper>
      <AssignToInvestmentModal open={showModalState === 'assignToInvestment'} {...commonModalProps} />

      <EditTransactionModal open={showModalState === 'editTransaction'} {...commonModalProps} />

      <DeleteTransactionModal open={showModalState === 'deleteTransaction'} {...commonModalProps} />
    </GenericFallbacksWrapper>
  );
};
