import { useMemo } from 'react';
import { CellClassParams, ColDef, ProcessCellForExportParams } from 'ag-grid-community';
import { CompanyProfileFinancialsTableProps } from '../components/CompanyProfileFinancialsTable';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { getFormattedFiscalDate } from '../../../utils/financialUtils';
import { useKpiColumnDefs } from '../hooks/useFinancialsColumnDefs';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { field2ToField3 } from '../../../../../data-models/field2.data-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import {
  processValueForExport,
  rendererTypeToExcelStyleId,
} from '../../../../../components/AgTable/exportToExcelDefs';
import { IFinancialsSortState } from '../components/financialsSortState';
import { StandardFormatterId } from '../../../../../util/formatter-service';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import { IFinancialsExportData } from './useFinancialsExportData';

export function useFinancialsExportColumnDefs({
  allPeriods,
  frequency,
  company,
  sortState,
}: {
  allPeriods: Date[];
  frequency: CompanyProfileFinancialsTableProps['frequency'];
  company: ICompanyDataModel;
  sortState?: IFinancialsSortState[];
}) {
  const { kpiNameDef, kpiGroupDef } = useKpiColumnDefs();

  const columnDefs = useMemo(() => {
    if (!frequency) return [];

    const columnsDefs: ColDef<IFinancialsExportData>[] = [
      {
        ...kpiGroupDef,
        initialSort: sortState?.find((s) => s.colId === 'kpiGroupId')?.sort,
      },
      {
        ...kpiNameDef,
        cellRenderer: undefined,
        initialSort: sortState?.find((s) => s.colId === 'kpiName')?.sort,
      },
      { field: 'section', headerName: 'Section' },
    ] as ColDef<IFinancialsExportData>[];

    const fye = company.fye ?? 12;

    allPeriods.forEach((currentPeriod) => {
      const dateISO = formatISODateOnly(currentPeriod);
      const colTitle = getFormattedFiscalDate(dateISO, frequency, fye);
      columnsDefs.push({
        colId: colTitle,
        headerName: colTitle,
        valueGetter: (params) => {
          const section = params.getValue('section') as KpiSection;
          return params.data?.valuesByDate2?.[dateISO]?.[frequency]?.[section]?.value ?? null;
        },
        cellClass: (params: CellClassParams<IFinancialsExportData>) => {
          const data = params.data;
          if (!data) return undefined;
          const field = field2ToField3(data);
          return field.format ? rendererTypeToExcelStyleId[field.format!] : '';
        },
      });
    });

    return columnsDefs;
  }, [allPeriods, company.fye, frequency, kpiGroupDef, kpiNameDef, sortState]);

  return {
    columnDefs,
    defaultExportParams: {
      processCellCallback: financialsProcessCellCb,
      fileName: `${company.name}_financials`,
    },
  };
}

function financialsProcessCellCb(params: ProcessCellForExportParams<IFinancialsExportData>) {
  const colId = params.column.getColId();
  if (colId === 'kpiGroupId') {
    return params.formatValue(params.value);
  }
  if (params.value === undefined || params.value === null) return '';
  const data = params.node?.data;
  if (data) {
    const field = field2ToField3(data);

    let format: StandardFormatterId | undefined;
    if (field.format === RendererType.percent) {
      format = 'percent2dpAsIs';
    } else if (field.format === RendererType.date) format = 'date';

    return processValueForExport(params.value, format);
  }
  return params.value;
}
