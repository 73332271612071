import { atom } from 'recoil';
import { IMappingsEvent, MappingsEventType } from '../MappingsGrid/mappingsGridEvents';

export const filterOutErrorsState = atom<boolean>({
  key: 'filterOutErrorsState',
  default: true,
});

//  will determine whether we show a form modal, and which one to show
// also stores the data for the forms
export const mappingsActionState = atom<IMappingsEvent | null>({
  key: 'mappingsActionState',
  default: null,
});

export interface DuplicateError {
  key: 'primary' | 'secondary';
  value: string;
  id: number;
  intoId: number;
  action: MappingsEventType;
}

export const duplicateErrorState = atom<DuplicateError | null>({
  key: 'duplicateErrorState',
  default: null,
});
