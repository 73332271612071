import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Box, Divider, Typography } from '@mui/material';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { BulkUploadReportResponse } from '../../../schemas/BulkUploadReport.schema';
import { getBulkUploadReport } from '../../../services/queries/MaggieUploadQueries';
import { Loader } from '../../../components/Loader/Loader';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../constants/RouteNameMapping';
import { BulkUploadReportGrid } from './BulkUploadReportGrid';

export function BulkUploadReportLoader() {
  const { uploadId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<BulkUploadReportResponse | null>(null);

  useEffect(() => {
    if (data || error) return;

    const id = parseInt(uploadId ?? '');
    if (isNaN(id)) {
      setError('Invalid upload id');
      return;
    }
    getBulkUploadReport(id)
      .then(setData)
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [data, error, loading, uploadId]);

  return (
    <Typography color='text.primary' component={'div'} height='100%' width='100%' m={'1.5rem'}>
      <Typography variant='h4' mb='1rem'>
        {ROUTE_TITLE_MAPPING[ROUTES.BULK_UPLOAD_REPORT]}
      </Typography>
      {loading ? (
        <Loader />
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : !data ? (
        <NoDataMessage2 />
      ) : (
        <BulkUploadReport report={data} />
      )}
    </Typography>
  );
}

export function BulkUploadReport({ report }: { report: BulkUploadReportResponse }) {
  return (
    <Box>
      <Typography variant='body2' color='text.secondary' style={{ whiteSpace: 'pre-line' }}>
        {`After validation, we found errors in the data.
        Please review the error details, make necessary adjustments, and `}
        <Link to={`/${ROUTES.ADMIN_PANEL}/${ROUTES.ADMIN_PANEL_FIELDS}`}>re-upload it to Foresight.</Link>
      </Typography>
      <Divider style={{ marginBlock: '1rem' }} />
      <BulkUploadReportGrid report={report} />
    </Box>
  );
}
