import { useEffect, useState } from 'react';
import { FieldPath, FieldValue, FieldValues, UseFormReturn } from 'react-hook-form';

interface IResetOnInvestmentChangeParams<T extends FieldValues> {
  triggerFieldPaths: Set<FieldPath<T>>;
  subscriptionFieldPaths: Set<FieldPath<T>>;
  methods: UseFormReturn<T>;
}
export function useClearValueOnChange<T extends FieldValues>({
  triggerFieldPaths,
  subscriptionFieldPaths,
  methods,
}: IResetOnInvestmentChangeParams<T>) {
  const { watch, setValue } = methods;
  const [forceRerender, setForceRerender] = useState(0);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change' || !name) return;
      if (triggerFieldPaths.has(name)) {
        subscriptionFieldPaths.forEach((fieldPath) => {
          setValue(fieldPath, null as FieldValue<T>);
          setForceRerender((prev) => prev + 1);
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, triggerFieldPaths, subscriptionFieldPaths]);
  return forceRerender;
}
