import { useLocation } from 'react-router-dom';
import { NavigationWrapper } from '../NavigationWrapper';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { ViewNavigationProvider } from '../../../../../pages/PortfolioOverview/components/ViewNavigation/ViewNavigationProvider';
import { ViewNavigation } from './ViewNavigation';

export const PortfolioReportingNavigation = () => {
  const { pathname } = useLocation();

  if (!pathname.startsWith(`/${ROUTES.PORTFOLIO}`)) return null;

  return (
    <ViewNavigationProvider>
      <NavigationWrapper title={'Portfolio Reporting'}>
        <ViewNavigation />
      </NavigationWrapper>
    </ViewNavigationProvider>
  );
};
