import { FC, ReactNode } from 'react';
import { useIntersection } from '../../hooks/useIntersection';

interface IProps {
  children: ReactNode;
}

export const LazyLoad: FC<IProps> = ({ children }) => {
  const [isIntersecting, ref] = useIntersection();

  return <div ref={ref}>{isIntersecting && children}</div>;
};
