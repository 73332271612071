import { AvatarSize, MUIAvatar } from '../Avatar/MUIAvatar';
import { UserService } from '../../util/user-service';
import { IUserDataModel, UnknownUser } from '../../data-models/user.data-model';

interface IUserAvatarProps {
  user: IUserDataModel | number;
  size?: AvatarSize;
}

export const UserAvatar = ({ user, size }: IUserAvatarProps) => {
  const userModel = typeof user === 'number' ? UserService.get().getUser(user) ?? UnknownUser : user;

  return <MUIAvatar size={size ?? 'small'} src={null} nameParts={userModel.name.split(' ') ?? []} />;
};
