import { Stack, Typography, IconButton, Box, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type LexicalEditorTabPageProps = {
  onClose: () => void;
  tabTitle: string;
  children: React.ReactNode;
};

export function LexicalEditorTabPage({ onClose, tabTitle, children }: LexicalEditorTabPageProps) {
  const { colors } = useTheme();

  return (
    <Box
      sx={{
        height: '100%',
        padding: '0.75rem 1.75rem',
        backgroundColor: colors.neutral[5],
        overflowY: 'scroll',
      }}
    >
      <Stack direction='row' justifyContent='space-between' mt={'1.5rem'} mb={'1.25rem'}>
        <Typography variant='h5' component='div' sx={{ flexGrow: 1 }}>
          {tabTitle}
        </Typography>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            color: colors.neutral[40],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Box>
  );
}
