import { IconButton, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useState, MouseEvent, useCallback } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { ICellRendererParams } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash-es';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { FieldEntity, IField } from '../../../../data-models/field2.data-model';
import { MoreOptionsMenu, MenuItemWithDivider } from '../../../../components/MoreOptionsMenu/MoreOptionsMenu';
import { useCustomFieldsActions } from '../hooks/useCustomFieldsActions';
import { EditCustomField } from '../../modals/EditCustomField';
import {
  customFieldsOpenUploadModalState,
  customFieldsSelectedTemplateState,
} from '../../../../services/state/AdminPanel/CustomFieldsState';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { CustomFieldFormInitialValuesState } from '../../forms2/CustomFieldFormState';
import { CustomFieldForm2 } from '../../forms2/CustomFieldForm2';

export default function ActionsCellRenderer(params: ICellRendererParams<IField<unknown>>) {
  const { data } = params;
  const { colors } = useTheme();
  const { deleteField } = useCustomFieldsActions();
  const [openDeleteCFModal, setOpenDeleteCFModal] = useState(false);
  const [openEditCFModal, setOpenEditCFModal] = useState(false);

  const { showCustomFieldForm2 } = useFlags<MaggieFeatureFlags>();
  const setFormInitialState = useSetRecoilState(CustomFieldFormInitialValuesState);

  const setCustomFieldsSelectedTemplate = useSetRecoilState(customFieldsSelectedTemplateState);
  const setCustomFieldsOpenUploadModal = useSetRecoilState(customFieldsOpenUploadModalState);

  const entity = params.data?.entity;
  const showUploadAction = entity === FieldEntity.company || entity === FieldEntity.fundCompany;

  const handleOnUploadBulk = () => {
    setCustomFieldsOpenUploadModal(true);
    setCustomFieldsSelectedTemplate(params.data?.entity as string);
  };

  const handleCloseDeleteModal = () => setOpenDeleteCFModal(false);

  const handleCloseEditModal = () => setOpenEditCFModal(false);

  const handleDeleteField = () => {
    if (!data) return;
    deleteField(data);
    handleCloseDeleteModal();
  };

  const handleEditField = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (showCustomFieldForm2 && data) {
        setFormInitialState(cloneDeep(data));
      }
      setOpenEditCFModal(true);
    },
    [data, setFormInitialState, showCustomFieldForm2]
  );
  if (params?.node?.group) return null;

  return (
    <Stack
      display='grid'
      gridAutoFlow={'column'}
      alignItems='center'
      justifyItems={'center'}
      className='fields-menu'
    >
      {showCustomFieldForm2 && openEditCFModal && (
        <CustomFieldForm2 onClose={handleCloseEditModal} editMode />
      )}
      {!showCustomFieldForm2 && (
        <EditCustomField open={openEditCFModal} onClose={handleCloseEditModal} customFieldValues={data} />
      )}
      <ConfirmDeletionDialog
        open={openDeleteCFModal}
        onClose={handleCloseDeleteModal}
        onConfirm={() => handleDeleteField()}
        title={`Delete Custom Field "${data?.displayName}"?`}
      >
        <Typography variant='body2' color={colors.neutral[60]} sx={{ pt: '.25rem', minWidth: '30rem' }}>
          {' '}
          Deleting the field will permanently remove all data for this field from the system
        </Typography>
      </ConfirmDeletionDialog>
      {showUploadAction && (
        <IconButton color='secondary' size={'medium'} onClick={handleOnUploadBulk} title='upload'>
          <UploadIcon />
        </IconButton>
      )}
      {!openEditCFModal && !openDeleteCFModal && (
        <MoreOptionsMenu style={{ justifySelf: 'end' }}>
          <MenuItemWithDivider
            onClick={handleEditField}
            sx={{ display: 'flex', justifyItems: 'space-between', p: '.5rem' }}
          >
            <ModeOutlinedIcon sx={{ color: colors.neutral['60'], mr: '.25rem' }} />
            <Typography variant='body2' color={colors.primary[100]}>
              Edit
            </Typography>
          </MenuItemWithDivider>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteCFModal(true);
            }}
            sx={{ display: 'flex', justifyItems: 'space-between', p: '.5rem' }}
          >
            <DeleteOutlinedIcon sx={{ color: colors.neutral['60'], mr: '.25rem' }} />
            <Typography variant='body2' color={colors.primary[100]}>
              Delete
            </Typography>
          </MenuItem>
        </MoreOptionsMenu>
      )}
    </Stack>
  );
}
