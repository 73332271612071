import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { fundTypeListState } from '../../../services/state/AppConfigState';
import { SelectWithCreatableOptions } from '../../../CoreComponents/base/SelectWithCreatableOptions';

export interface IFundTypeSelectProps {
  initialValue?: string;
  handleChange: (newValue: string) => void;
  fullWidth?: boolean;
}

export function FundTypeSelect(props: IFundTypeSelectProps) {
  const { initialValue = 'Primary', handleChange, fullWidth } = props;

  const fundTypes = useRecoilValue(fundTypeListState);
  const options = useMemo(() => Array.from(fundTypes.values()), [fundTypes]);

  return (
    <SelectWithCreatableOptions
      initialValue={initialValue}
      options={options}
      handleChange={handleChange}
      fullWidth={fullWidth}
    />
  );
}
