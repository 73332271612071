import * as yup from 'yup';
import { minZeroMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { pricePerShareField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function optionExerciseFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    currencyid: transactionCurrencyIdField(),
    optionsExercisedNo: yup
      .number()
      .typeError(typeMsg('Options Exercised', 'number'))
      .required(requiredMsg('Options Exercised'))
      .min(0, minZeroMsg('Options Exercised')),
    optionsExercisePrice: usdField()
      .required(requiredMsg('Exercised Price'))
      .min(0, minZeroMsg('Exercised Price'))
      .nullable()
      .typeError(typeMsg('Exercised Price', 'number')),
    valuationImpact: yup
      .boolean()
      .required(requiredMsg('Valuation Impacting'))
      .transform((_, val) => (val ? val : false)),
    pricePerShare: pricePerShareField()
      .when('valuationImpact', {
        is: (valuationImpacting: boolean) => Boolean(valuationImpacting),
        then: (schema) =>
          schema
            .required(requiredMsg('Valuation PPS'))
            .min(0, minZeroMsg('Valuation PPS'))
            .nullable()
            .typeError(typeMsg('Valuation PPS', 'number')),
      })
      .when('valuationImpact', {
        is: (valuationImpacting: boolean) => !valuationImpacting,
        then: (schema) =>
          schema
            .optional()
            .min(0, minZeroMsg('Valuation PPS'))
            .nullable()
            .typeError(typeMsg('Valuation PPS', 'number')),
      }),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function optionExerciseFormSchema() {
  return yup.object().shape(optionExerciseFields());
}

export function optionExerciseGridSchema() {
  return yup.object().shape(optionExerciseFields());
}
