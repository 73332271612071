import { Stack, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { EditConfigsLoader } from './EditDisplayConfigs';

const panelConfigContainer = css`
  padding-block: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 24rem);
  grid-template-rows: 16rem 16rem;
  align-content: start;
  align-items: start;
  justify-content: start;
`;

export function ProfileCustomization() {
  return (
    <Stack py='1rem'>
      <Typography variant='h6' mb='0.5rem'>
        Company Profile
      </Typography>
      <Typography variant='body2' color='text.secondary'>
        Here you can customize the type of information displayed. Changes will be applied to all company
        profiles across the platform.
      </Typography>
      <section css={panelConfigContainer}>
        <EditConfigsLoader />
      </section>
    </Stack>
  );
}
