import { UseMutationResult, useMutation, useQuery } from '@tanstack/react-query';
import { IDealDataModel } from '../../data-models/deal.data-model';
import { queryClient } from '../MaggieQueryClient';
import { DEALS, DEAL_BOARD_CONFIG, fetchDealBoardConfig, updateDeal } from '../queries/MaggieDealQueries';

export interface MutateDealProps {
  payload: IDealDataModel;
  stageIds: number[];
}

export function useDealBoardConfig() {
  return useQuery({ queryKey: DEAL_BOARD_CONFIG, queryFn: () => fetchDealBoardConfig() });
}

export function useUpdateDeal(): UseMutationResult<IDealDataModel, Error, MutateDealProps> {
  return useMutation({
    mutationFn: (data: MutateDealProps) => updateDeal(data.payload),
    onMutate: async (data) => {
      const { payload: newDeal, stageIds } = data;
      await queryClient.cancelQueries({ queryKey: [DEALS, { stageIds }] });
      const prevDeals = queryClient.getQueryData<IDealDataModel[]>([DEALS, { stageIds }]);

      if (prevDeals) {
        const prevDealsSansNewDeal = prevDeals.filter((deal) => deal.id !== newDeal.id);
        queryClient.setQueriesData<IDealDataModel[]>({ queryKey: [DEALS] }, [
          ...prevDealsSansNewDeal,
          newDeal,
        ]);
      }
      return { prevDeals };
    },
    onError: (_err, vars, ctx) => {
      if (ctx?.prevDeals) {
        const { stageIds } = vars;
        queryClient.setQueriesData<IDealDataModel[]>({ queryKey: [DEALS, { stageIds }] }, ctx.prevDeals);
      }
    },
    onSuccess: (_data, vars) => {
      const { stageIds } = vars;
      queryClient.invalidateQueries({ queryKey: [DEALS, { stageIds }] });
    },
    onSettled: (_data, _err, vars) => {
      const { stageIds } = vars;
      queryClient.invalidateQueries({ queryKey: [DEALS, { stageIds }] });
    },
  });
}
