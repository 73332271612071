import { ICellRendererParams } from 'ag-grid-community';
import { Typography, Stack, useTheme } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export interface IStatusRendererParams extends Pick<ICellRendererParams<unknown, string>, 'value'> {
  status: 'warning' | 'success';
}

export function StatusRenderer(params: IStatusRendererParams) {
  const { value, status } = params;
  const { colors } = useTheme();

  if (!value) {
    return null;
  }
  return (
    <Stack direction={'row'} alignItems={'center'} gap='0.5rem' height={'100%'}>
      {status === 'warning' ? (
        <WarningAmberIcon color='warning' fontSize='small' />
      ) : (
        <CheckCircleIcon color='success' fontSize='small' />
      )}
      <Typography variant='body2' color={status === 'warning' ? 'text.secondary' : colors.success[70]} noWrap>
        {value}
      </Typography>
    </Stack>
  );
}
