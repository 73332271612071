import { MaggieHttp } from '../MaggieHttp';
import { ITemplatesResponse } from '../../data-models/template.data-model';
import { IField } from '../../data-models/field2.data-model';

export async function fetchCustomFieldTemplates() {
  const { data } = await MaggieHttp.maggie.get<ITemplatesResponse>('/customField/templates');
  return data;
}

export async function fetchCustomFields<T>() {
  const { data } = await MaggieHttp.maggie.get<IField<T>[]>('/customField');
  return data;
}

export async function deleteCustomField<T>(customField: IField<T>): Promise<IField<T>> {
  await MaggieHttp.maggie.delete<number>(`/customField/${customField.id}`);

  return customField;
}

export async function createCustomField<T>(payload: Partial<IField<T>>) {
  const { data } = await MaggieHttp.maggie.post<Partial<IField<T>>, IField<T>>('/customField', payload);
  return data;
}

export async function updateCustomField<T>(payload: Partial<IField<T>>): Promise<IField<T>> {
  const { data } = await MaggieHttp.maggie.put<Partial<IField<T>>, IField<T>>(
    `/customField/${payload.id}`,
    payload
  );
  return data;
}
