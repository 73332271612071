import { FC } from 'react';
import { CHART_COMPONENTS } from '../../../../types';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { useInvestmentAmountOverTime } from '../../hooks/useInvestmentAmountOverTime';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const InvestmentAmountOverTime: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const data = useInvestmentAmountOverTime(props.filteredMetrics);

  const yAxisFormatting = (value: number | string) => formatUSDShort(Number(value));

  return (
    <LineChart
      id={CHART_COMPONENTS.INVESTMENT_AMOUNT_OVER_TIME}
      data={data}
      yAxisLabelFormatter={yAxisFormatting}
      showLegend={false}
      title={'Investment Amount'}
      width={640}
    />
  );
};
