import { Box, Button, IconButton, LinearProgress, Stack, Typography, styled, useTheme } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useCallback, useState } from 'react';
import { get, useFormContext, useFormState } from 'react-hook-form';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useRecoilValueLoadable } from 'recoil';
import { KPIFileUploadedType } from '../../../../../../data-models/kpi-requests.data-model';
import { kpisRequestUploadedFileS3UrlState } from '../../../../../../services/state/KPI/KPIRequestsState';
import { convertFileSize, formatFileName, validateFileExtension } from '../../../../utils';
import { useKPIRequestResponseActions } from '../../../KPIRequests/KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { useResponseFileName } from './useResponseFileName';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const InputFileWrapper = styled('div')`
  position: relative;
  padding: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    height: 20px;
  }

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  & input {
    all: unset;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;

const FileTitle = ({ fileUploadValue }: { fileUploadValue: KPIFileUploadedType }) => {
  const { colors } = useTheme();
  const getFileName = useResponseFileName();
  const uploadedFileS3Url = useRecoilValueLoadable(
    kpisRequestUploadedFileS3UrlState({
      ...fileUploadValue,
      fileName: getFileName(),
    })
  );

  return (
    <>
      <Typography
        key={uploadedFileS3Url.contents}
        variant='body2'
        component={'a'}
        sx={{ color: colors.neutral[70], mt: '.1rem' }}
        href={uploadedFileS3Url.valueMaybe() ?? ''}
      >
        {fileUploadValue.fileName}&nbsp;&nbsp;&nbsp;&nbsp;
        {convertFileSize(fileUploadValue.fileSize ?? 0)}
      </Typography>
    </>
  );
};

function ChosenFile({
  fileUploadValue,
  isUploading,
  onRemoveFile,
  cancelUploading,
  readOnly,
}: {
  fileUploadValue: KPIFileUploadedType;
  onRemoveFile?: () => void;
  isUploading?: boolean;
  cancelUploading: () => void;
  readOnly?: boolean;
}) {
  const { colors } = useTheme();

  if (!fileUploadValue || !fileUploadValue?.fileName) return null;

  return (
    <Stack
      gap='.5rem'
      sx={{
        border: `1px solid ${colors.neutral[30]}`,
        p: '1rem',
        borderRadius: '0.25rem',
      }}
    >
      <Stack direction={'row'} alignItems='center' justifyContent='space-between'>
        <Stack direction={'row'} gap='.5rem' justifyContent={'center'}>
          <InsertDriveFileOutlinedIcon sx={{ color: colors.neutral[60] }} fontSize='medium' />
          <Button
            disabled={!fileUploadValue?.fileId}
            sx={{
              p: 0,
            }}
          >
            <FileTitle fileUploadValue={fileUploadValue} />
          </Button>
        </Stack>
        {!readOnly &&
          (isUploading ? (
            <Typography variant='body2' component={'span'} sx={{ color: colors.neutral[70], mt: '.1rem' }}>
              {convertFileSize(fileUploadValue.fileSize ?? 0)}
            </Typography>
          ) : (
            <IconButton
              size='small'
              onClick={onRemoveFile}
              sx={{
                color: colors.neutral[50],
              }}
            >
              <CloseIcon />
            </IconButton>
          ))}
      </Stack>
      {isUploading && (
        <>
          <LinearIndeterminate />
          <Button
            onClick={cancelUploading}
            sx={{
              placeSelf: 'end',
              width: 'fit-content',
            }}
          >
            <Typography color='secondary' variant='body2' component={'span'} sx={{ mt: '.1rem' }}>
              Cancel Uploading
            </Typography>
          </Button>
        </>
      )}
    </Stack>
  );
}

export function FileUploadField({
  responseFormRef = '',
  readOnly = false,
}: {
  responseFormRef?: string;
  readOnly?: boolean;
}) {
  const { colors, palette } = useTheme();
  const [isUploading, setIsUploading] = useState(false);
  const { uploadFileRequestResponse } = useKPIRequestResponseActions();
  const { register, setError, setValue, getValues, watch, clearErrors } = useFormContext();
  const { errors } = useFormState();
  const kpiRequestCompanyId = watch('kpiRequest.companyId');

  const fileUploadValue = watch(responseFormRef);

  const hasError = get(errors, responseFormRef);
  const borderColor = hasError ? palette.error.main : colors.neutral[60];

  const uploadFile = useCallback(
    async (file: File) => {
      setIsUploading(true);

      const uploadedSuccessfully = await uploadFileRequestResponse({
        file,
        companyId: kpiRequestCompanyId,
      });

      if (!uploadedSuccessfully) {
        setError(responseFormRef, {
          type: 'file_upload',
          message: 'File upload failed, try again.',
        });
        setValue(responseFormRef, null);
      } else {
        setValue(
          responseFormRef,
          {
            ...getValues(responseFormRef),
            companyId: kpiRequestCompanyId,
            ...uploadedSuccessfully,
          },
          { shouldDirty: true }
        );
      }
      setIsUploading(false);
    },
    [uploadFileRequestResponse, kpiRequestCompanyId, setValue, responseFormRef, getValues, setError]
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!responseFormRef || !event.target.files?.[0]) return;

      const file = event.target.files[0];

      if (file && file.size > MAX_FILE_SIZE) {
        setError(responseFormRef, {
          type: 'file_size',
          message: 'File size is limited up to 10 megabytes',
        });
        return setValue(responseFormRef, '');
      }

      clearErrors(responseFormRef);
      setValue(responseFormRef, {
        fileName: formatFileName(file?.name ?? ''),
        fileSize: file?.size ?? 0,
      });
      uploadFile(file);
    },
    [clearErrors, responseFormRef, setError, setValue, uploadFile]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      if (!responseFormRef) return;
      e.preventDefault();
      const droppedFile = e.dataTransfer.files[0];

      if (!validateFileExtension(droppedFile.name)) {
        return setError(responseFormRef, {
          type: 'file_type',
          message: 'Invalid file type, please select a valid type (xlsx, xls, csv, pdf).',
        });
      }

      clearErrors(responseFormRef);
      setValue(responseFormRef, {
        fileName: formatFileName(droppedFile.name),
        fileSize: droppedFile.size,
      });
      uploadFile(droppedFile);
    },
    [clearErrors, responseFormRef, setError, setValue, uploadFile]
  );

  const onCancelUploading = useCallback(() => {
    if (!responseFormRef) return;
    setValue(responseFormRef, null);
    setIsUploading(false);
  }, [responseFormRef, setValue]);

  const registerFileIdInput = register(`${responseFormRef}.fileId`);
  const fileId = watch(`${responseFormRef}.fileId`) ?? '';

  return (
    <>
      <ChosenFile
        cancelUploading={onCancelUploading}
        isUploading={isUploading}
        fileUploadValue={fileUploadValue}
        onRemoveFile={() =>
          setValue(responseFormRef, {
            fileId: '',
          })
        }
        readOnly={readOnly}
      />
      {!isUploading && !readOnly && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            border: `1px dashed ${borderColor}`,
            borderRadius: '4px',
          }}
        >
          <InputFileWrapper>
            <CloudUploadOutlinedIcon sx={{ color: colors.neutral[50], mr: '0.5rem' }} />
            <Typography
              variant='body2'
              sx={{
                colors: colors.neutral[60],
              }}
            >
              Drag and Drop or
              <Typography ml={'.25rem'} component='span' variant='body2' color='secondary'>
                Browse your files
              </Typography>
            </Typography>
            {responseFormRef && (
              <>
                {/* This hidden input allows us to validate file easier without extra logic*/}
                <input type='hidden' {...registerFileIdInput} value={fileId} />
                <input
                  data-testid='file-upload-field'
                  type='file'
                  onChange={handleFileChange}
                  onDrop={handleDrop}
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,text/csv,application/xls,application/xlsx'
                />
              </>
            )}
          </InputFileWrapper>
        </Stack>
      )}
    </>
  );
}

export default function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%', px: '.25rem' }}>
      <LinearProgress color={'primary'} />
    </Box>
  );
}
