import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Autocomplete, TextField } from '@mui/material';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import { dealFiltersFundsState, selectedFundsState } from '../state/DealboardUIState';
import { commonMultiselectProps } from './commonMultiselectProps';

export const FundsFilter: FC = () => {
  const allFunds = useRecoilValue(dealFiltersFundsState);
  const [selectedFunds, setSelectedFunds] = useRecoilState(selectedFundsState);

  const fundOptions: Option[] = useMemo(() => {
    return !allFunds ? [] : allFunds?.map((f) => ({ id: f.id, value: f.name }));
  }, [allFunds]);

  const value = useMemo(
    () =>
      allFunds.length === selectedFunds.length
        ? []
        : fundOptions.filter((opt) => selectedFunds?.includes(opt.id as number)),
    [allFunds.length, fundOptions, selectedFunds]
  );

  const handleSelectFunds = useCallback(
    (e: SyntheticEvent, selected: Option[] | undefined) => {
      if (!fundOptions || !selected) return;
      if (!selected?.length) {
        setSelectedFunds(fundOptions.map((opt) => opt.id as number));
        return;
      }
      setSelectedFunds(selected.map((opt) => opt.id as number));
    },
    [fundOptions, setSelectedFunds]
  );

  if (!allFunds) return null;

  return (
    <Autocomplete
      multiple
      options={fundOptions}
      getOptionLabel={(option) => option.value}
      value={value}
      onChange={handleSelectFunds}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value?.length ? '' : 'Filter by Fund'}
          aria-label='no-label'
          sx={{ '& .MuiInputBase-root.Mui-focused': { background: 'white' } }}
        />
      )}
      disablePortal
      {...commonMultiselectProps}
    />
  );
};
