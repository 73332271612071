import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../constants/RouteNameMapping';
import { toURLFriendlyStringWithDashes } from '../../util/url-utils';
import { companyState } from '../../services/state/CompanyState';
import { selectedCompanyIdProfile } from './state/UIState';

export function useProfileBasePath() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId ?? -1));
  const urlFriendly = toURLFriendlyStringWithDashes(`${company?.name}-${companyId}`);
  return `/${ROUTES.COMPANY_PROFILES}/${urlFriendly}`;
}
