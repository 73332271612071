import { MaggieHttp } from '../MaggieHttp';
import { IField } from '../../data-models/field2.data-model';
import { KPIGroup } from '../../schemas/kpi-group.schema';

export async function getAllKPIs() {
  const { data } = await MaggieHttp.maggie.get<IField<unknown>[]>('/kpiConfig');

  return data;
}

export async function createKPI(field: IField<unknown>) {
  const { data } = await MaggieHttp.maggie.post<IField<unknown>, IField<unknown>>(`/kpiConfig`, field);

  return data;
}

export async function deleteKPI(id: number) {
  const { data } = await MaggieHttp.maggie.delete(`/kpiConfig/${id}`);

  return data;
}

export async function getKPIGroupsSettings() {
  const { data } = await MaggieHttp.maggie.get<KPIGroup[]>(`/kpiGroupConfig`);

  return data;
}
