import { useCallback, useState } from 'react';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { IKpiCompanyData } from '../AllCompaniesData';

export function useAllCompaniesRowSelection(gridApi: GridApi | null) {
  const [selected, setSelected] = useState<IKpiCompanyData[]>([]);

  const selectAll = useCallback(() => {
    if (gridApi) {
      gridApi.selectAll();
      setSelected(gridApi.getSelectedRows());
    }
  }, [gridApi]);

  const deselectAll = useCallback(() => {
    if (gridApi) {
      gridApi?.deselectAll();
      setSelected([]);
    }
  }, [gridApi]);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    setSelected(selectedRows);
  }, []);

  return { selectAll, deselectAll, onSelectionChanged, selected };
}
