import { useRecoilCallback } from 'recoil';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { allDealsState } from '../state/DealboardDataState';
import { DealComment } from '../data-models/comment.data-model';
import { selectedDealState } from '../state/DealboardUIState';
import { getErrorMessage } from '../../../services/queryHelpers';

export const deleteComment = ({ commentId }: { commentId: number }) => {
  return MaggieHttp.maggie.delete<DealComment>(`/dealComment/${commentId}`);
};

interface IDeleteCommentParams {
  dealId: number;
  commentId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const deleteDealComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ commentId, dealId, onSuccess, onError }: IDeleteCommentParams) => {
        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(allDealsState, (current) => {
            if (!current) return current;
            return current.map((d) => {
              if (d.id === dealId) {
                const filteredComments = d.comments.filter(({ id }) => id !== commentId);
                return { ...d, comments: filteredComments };
              }
              return d;
            });
          });

          const selectedDeal = mutable.getLoadable(selectedDealState).valueMaybe();
          if (selectedDeal && selectedDeal.id === dealId) {
            const filteredComments = selectedDeal.comments.filter(({ id }) => id !== commentId);
            mutable.set(selectedDealState, { ...selectedDeal, comments: filteredComments });
          }
        });
        gotoSnapshot(updatedSnapshot);
        const release = snapshot.retain();

        try {
          await deleteComment({ commentId });
          pushSuccessToast({ message: 'Comment deleted successfully' });
          onSuccess?.();
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to delete comment');
          pushErrorToast({ message });
          onError?.();
        } finally {
          release();
        }
      },
    []
  );

  return deleteDealComment;
};
