import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { CustomFormatterId } from '../util/formatter-service';

export const MetricsReconSchema = {
  base() {
    return {
      companyId: yup.number().label('Company'),
      fundId: yup.number().label('Fund'),
      reconConfigId: yup.number(),
      foresightValue: yup.number().label('Foresight'),
      soiValue: yup.number().label('SOI Value'),
    };
  },

  gridSchema() {
    const base = this.base();
    return yup.object().shape({
      ...base,
      companyId: base.companyId.gridMeta({ renderer: RendererType.companyId }),
      fundId: base.fundId.gridMeta({
        renderer: RendererType.id,
        formatter: CustomFormatterId.fund,
      }),
    });
  },

  formSchema() {
    const base = this.base();
    return yup.object().shape({
      ...base,
      companyId: base.companyId.default(0),
      fundId: base.fundId.default(0),
      reconConfigId: base.reconConfigId.default(0),
      foresightValue: base.foresightValue.default(0),
      soiValue: base.soiValue.default(0),
    });
  },
};

export type MetricsReconDataModel = yup.InferType<ReturnType<typeof MetricsReconSchema.formSchema>>;

export function createMetricsReconDataModel(
  overrides: Partial<MetricsReconDataModel> = {}
): MetricsReconDataModel {
  return { ...MetricsReconSchema.formSchema().getDefault(), ...overrides };
}
