import { styled } from '@mui/material/styles';
import { useTheme, Dialog, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CSSProperties, FC, ReactNode } from 'react';

const HEADER_HEIGHT = '3rem';

const Container = styled('div')`
  width: 50rem;
`;

const ModalHeader = styled('header')`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background: ${({ theme }) => theme.colors.primary[60]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem;
`;

const FormWrapper = styled('div')`
  padding: 2rem 2.5rem;
  width: 100%;
  max-height: min(90vh, 50rem);
  overflow: auto;
`;

export interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  style?: CSSProperties;
}

export const ModalWrapper: FC<ModalWrapperProps> = ({ open, onClose, children, title, style }) => {
  const { colors } = useTheme();
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} scroll={'body'}>
      <Container>
        <ModalHeader>
          <Typography color={colors.primary[0]}>{title}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon htmlColor={colors.primary[0]} />
          </IconButton>
        </ModalHeader>
        <FormWrapper style={{ ...style }}>{children}</FormWrapper>
      </Container>
    </Dialog>
  );
};
