import { useRecoilCallback } from 'recoil';
import { useLoadingBarState } from '../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { LoadingId } from '../../types';
import { createCompany } from '../queries/MaggieCompanyQueries';
import { getErrorMessage } from '../queryHelpers';
import { companyState } from '../state/CompanyState';
import { prepareCompanyPayload } from './useUpdateCompanyAndState';

export function useCreateCompanyAndUpdateState(showInfoToast = true) {
  const { pushErrorToast, pushInfoToast } = useToastMessageState();
  const { actions } = useLoadingBarState();

  return useRecoilCallback(
    ({ set }) =>
      async (payload: Partial<ICompanyDataModel>) => {
        actions.startLoading(LoadingId.createCompany);

        try {
          const res = await createCompany(prepareCompanyPayload(payload));
          const newCompany = res.data;
          set(companyState(newCompany.id), newCompany);
          if (showInfoToast) {
            pushInfoToast({ message: 'Successfully created company' });
          }
          return newCompany;
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Failed to create company');
          pushErrorToast({
            message,
          });
        } finally {
          actions.stopLoading(LoadingId.createCompany);
        }
      },
    []
  );
}
