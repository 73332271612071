import { FC, useCallback } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { ProcessCellForExportParams } from 'ag-grid-community';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { commonProcessCb } from '../../../../components/AgTable/exportToExcelDefs';
import { Loader } from '../../../../components/Loader/Loader';
import { roundReconState } from '../../state/FinanceState';
import { ErrorOccurredMessage } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { defaultColDef } from '../../common-grid-defs/defaultColDefs';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { useGetCompanyIfSet } from '../../../CompanyProfiles/hooks/useGetCompanyData';
import { IRoundReconDataModel } from '../../../../data-models/finance-round-recon.data-model';
import { useRoundReconColDefs } from './useRoundReconColDefs';
import { MissedDataPts } from './MissedDataPts';

export const RoundReconGrid: FC = () => {
  const roundReconLoadable = useRecoilValueLoadable(roundReconState);
  const rowData = roundReconLoadable.valueMaybe();
  const getCompanyData = useGetCompanyIfSet();

  const columnDefs = useRoundReconColDefs();

  const processCellCallback = useCallback(
    (params: ProcessCellForExportParams<IRoundReconDataModel>) => {
      if (params.column.getId() === 'company') {
        return getCompanyData(params?.node?.data?.round?.companyId ?? -1)?.name ?? '';
      }
      return commonProcessCb(params);
    },
    [getCompanyData]
  );

  if (roundReconLoadable.state === 'loading') return <Loader height='80%' />;
  if (roundReconLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (!rowData) return null;

  return (
    <>
      <MissedDataPts numMissedDataPts={rowData.length} />
      <FadeInGridWrapper>
        <div style={{ height: '80vh', paddingTop: '0.5rem' }}>
          {' '}
          <AgTable
            key={'round-recon'}
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            defaultExcelExportParams={{ processCellCallback }}
            defaultCsvExportParams={{ processCellCallback }}
            suppressMovableColumns
            rowGroupPanelShow='never'
          />
        </div>
      </FadeInGridWrapper>
    </>
  );
};
