import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { NestedFieldPaths } from 'ag-grid-enterprise';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import * as yup from 'yup';
import { CompanySchema } from '../../../../schemas/Company.schema';
import { templateAssignmentGridSchema } from '../../../../schemas/template-assignment-schema';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { IAssignedRowData } from './AssignedCompaniesData';
import { AssignedActionsRenderer } from './components/cellRenderers/AssignedActionsRenderer';
import { CompanyColId } from './hooks/useKPICompaniesAllColDefs';

export function useAssignedCompaniesColDefs() {
  const templateAssignmentSchema = templateAssignmentGridSchema();

  const schemaToColDefs = useSchemaToGrid();

  return useMemo(() => {
    const assignedSchema = templateAssignmentSchema.concat(
      yup.object({
        company: CompanySchema.gridSchema(),
      })
    );

    const overrideDefs: Partial<Record<NestedFieldPaths<IAssignedRowData>, ColDef<IAssignedRowData>>> = {
      ['company.id']: {
        colId: CompanyColId,
        rowGroup: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
      },
      ['frequency']: {
        filterParams: {
          valueFormatter: (params: ValueFormatterParams<IAssignedRowData>) => {
            return params.value ? capitalize(params.value) : '';
          },
        },
      },
    };

    const columnDefs = schemaToColDefs(assignedSchema, [
      'company.id',
      'company.sectorId',
      'templateUuid',
      'frequency',
    ]) as ColDef[];

    return withOverrides(columnDefs, overrideDefs as Record<string, ColDef<IAssignedRowData>>).concat({
      headerName: '',
      maxWidth: 70,
      flex: 0,
      menuTabs: [],
      cellRenderer: AssignedActionsRenderer,
      pinned: 'right',
    });
  }, [templateAssignmentSchema, schemaToColDefs]);
}

export const defaultColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: 'agSetColumnFilter',
  enableRowGroup: true,
  minWidth: 150,
};
