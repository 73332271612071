import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledTooltip } from '../../pages/CompetitiveIntelligence/components/Tables/CellRenderers/StyledTooltip';

export const ToggleIconButton = styled(ToggleButton)`
  border: thin solid transparent;
  transition-property: border-color, background;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  //border-radius: 50%;
  border-color: ${(props) => (props.selected ? props.theme.colors.secondary[30] : 'transparent')};
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${(props) => props.theme.colors.secondary[10]};
  }
`;

export interface IToggleIconButtonWithTooltipProps extends ToggleButtonProps {
  tooltipText: string;
}

export const ToggleIconButtonWithTooltip = (props: IToggleIconButtonWithTooltipProps) => {
  const { children, tooltipText, ...toggleIconProps } = props;

  return (
    <StyledTooltip title={tooltipText} placement='top-start'>
      <ToggleIconButton size='small' {...toggleIconProps}>
        {children}
      </ToggleIconButton>
    </StyledTooltip>
  );
};
