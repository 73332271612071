import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';
import { IFormField } from '../../../view-models/form.view-model';
import { companyState } from '../../../services/state/CompanyState';
import { isPortfolioCompany } from '../../../data-models/company.data-model';

export function useCompanyEditPerms(companyId: number) {
  const company = useRecoilValue(companyState(companyId));
  const isPortco = Boolean(company) && isPortfolioCompany(company!);
  const canEdit = !isPortco || PermissionService.get().hasPermission(PermissionKey.canEditPortfolioCompany);

  return useCallback(
    (fields: IFormField<unknown>[]) => {
      if (canEdit) return fields;
      return fields.map((field) => {
        return { ...field, disabled: true, placeholder: '' };
      });
    },
    [canEdit]
  );
}
