import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { selectedGeneralLedgerActionState } from './generalLedgerState';
import { UploadLedgerForm } from './UploadLedgerForm';

export function UploadLedgerModal() {
  const [selectedAction, setSelectedAction] = useAtom(selectedGeneralLedgerActionState);
  const onClose = useCallback(() => setSelectedAction(null), [setSelectedAction]);
  const open = selectedAction !== null;

  return (
    <BasicDialog open={open} onClose={onClose} title={'Upload General Ledger'}>
      <FormFieldsContainer>{open && <UploadLedgerForm onClose={onClose} />}</FormFieldsContainer>
    </BasicDialog>
  );
}
