import {
  Autocomplete,
  Box,
  CircularProgress,
  PopperProps,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { CSSProperties, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import omit from 'lodash-es/omit';
import { ICompanySearchResponseDataModel } from '../../data-models/company-search-result.data-model';
import { getRealCompanyId, searchCompanies, SearchType } from '../../services/queries/MaggieCompanyQueries';
import { IOptionsFromSearchConfig, useAsyncAutoComplete } from '../Form/Display/useAsyncAutomplete';
import { MaggieFeatureFlags } from '../../util/feature-flags';
import { CompanyOption, StyledListBox } from '../Form/Display/FieldCompanySearch';
import { CompanySearchResponseWithRealId } from '../../schemas/CompanySearchResponse.schema';

export const DummyCreateId = 'dummyCreate';

export const SearchThrottleDelayMs = 200;

export interface ICompanySearchProps {
  autoFocus?: boolean;
  disablePortal?: boolean;
  fullWidth?: boolean;
  initialValue?: ICompanySearchResponseDataModel | undefined;
  onChange: (company: CompanySearchResponseWithRealId) => void;
  onCompanyCreateClick?: (typedCompanyName: string) => void;
  showCreateOption?: boolean;
  showSelectedOption?: boolean;
  style?: CSSProperties;
  sx?: SxProps;
  showPortcoOnly?: boolean;
  popperSx?: SxProps;
}

export function CompanySearch({
  autoFocus,
  disablePortal = true,
  fullWidth,
  initialValue,
  onChange,
  onCompanyCreateClick,
  showCreateOption = false,
  showSelectedOption,
  style,
  sx,
  popperSx = {},
  showPortcoOnly = false,
}: ICompanySearchProps) {
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();
  const [isGettingCompanyId, setIsGettingCompanyId] = useState(false);
  const searchType = showPortcoOnly ? SearchType.Portfolio : SearchType.Company;
  const autoCompleteConfig = useMemo(() => {
    const conf: IOptionsFromSearchConfig<ICompanySearchResponseDataModel, false> = {
      generateCreateOption: showCreateOption
        ? (currentText) => {
            return {
              id: DummyCreateId,
              fields: {
                website: '',
                logo: '',
                description: `Select to create a new company`,
                name: `Create new company with name '${currentText}'`,
                type: SearchType.Portfolio,
              },
            };
          }
        : undefined,
      initialValue: initialValue ?? null,
      searchFn: (searchTerm) => searchCompanies(searchTerm, searchType, false, showElasticSearchResults),
      onChange: async (newValue: ICompanySearchResponseDataModel | null) => {
        if (newValue === null) {
          return;
        }

        setIsGettingCompanyId(true);
        const changedCompany: CompanySearchResponseWithRealId = {
          ...newValue,
          id: (await getRealCompanyId(newValue.id)).id,
        };

        setIsGettingCompanyId(false);
        onChange(changedCompany);
      },
      onCreateClick: onCompanyCreateClick,
    };

    return conf;
  }, [initialValue, onChange, onCompanyCreateClick, searchType, showCreateOption, showElasticSearchResults]);
  const { isSearching, onClose, onInputChange, onValueChange, open, options, value } =
    useAsyncAutoComplete<ICompanySearchResponseDataModel>(autoCompleteConfig);

  const getOptionLabel = useCallback(
    (e: ICompanySearchResponseDataModel) => {
      if (!showSelectedOption) return '';
      return e.fields.name;
    },
    [showSelectedOption]
  );

  const showCompanyType = searchType === SearchType.Company && showElasticSearchResults;
  const autocompleteProps = showCompanyType
    ? {
        ListboxComponent: StyledListBox,
        componentsProps: {
          popper: {
            sx: { ...popperSx },
          } as PopperProps,
        },
      }
    : undefined;

  return (
    <Autocomplete
      autoHighlight
      blurOnSelect
      style={style}
      disablePortal={disablePortal}
      disabled={isGettingCompanyId}
      fullWidth={fullWidth}
      filterOptions={(options) => options}
      getOptionLabel={getOptionLabel}
      id='companies-autocomplete'
      isOptionEqualToValue={(option, newValue) => {
        return option.id === newValue.id;
      }}
      noOptionsText={null}
      onChange={onValueChange}
      onClose={onClose}
      onInputChange={onInputChange}
      open={open}
      options={options}
      popupIcon={null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus={autoFocus ?? false}
            placeholder={
              isGettingCompanyId ? 'Fetching company details...' : 'Search for a company name or website'
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching || isGettingCompanyId ? (
                    <CircularProgress color='secondary' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: ICompanySearchResponseDataModel) => {
        const otherProps = omit(props, 'key');
        const addOptionClasses = props.className ? `${props.className} add-option` : 'add-option';
        if (option.id === DummyCreateId) {
          return (
            <Box {...otherProps} key={option.id} component='li' className={addOptionClasses}>
              <Typography variant={'caption'}>{option.fields.name}</Typography>
            </Box>
          );
        }
        return (
          <CompanyOption
            company={option}
            key={option.id}
            otherProps={otherProps}
            showCompanyType={showCompanyType}
          />
        );
      }}
      sx={sx}
      value={value}
      {...autocompleteProps}
    />
  );
}
