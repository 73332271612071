import { FC } from 'react';
import { styled } from '@mui/material/styles';

const Cell = styled('span')`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const LoadingContent = styled('span')`
  display: block;
  background: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: 4px;
  height: 60%;
  width: 80%;
`;

export const LoadingCell: FC = () => {
  return (
    <Cell>
      <LoadingContent />
    </Cell>
  );
};
