import * as yup from 'yup';
import { fdYup } from '../util/yup-bootstrap';
import { investmentTypeField, shareClassNameField, ViewModelInvestmentType } from './common-schema-defs';
import { securityFields } from './Security.schema';

export function abstractShareClassFields() {
  const { customData } = securityFields();
  return {
    type: investmentTypeField().default(ViewModelInvestmentType.common),
    name: shareClassNameField(),
    customData,
  };
}

export function abstractShareClassSchema() {
  return fdYup.object().shape(abstractShareClassFields());
}

export type TAbstractShareClass = yup.InferType<ReturnType<typeof abstractShareClassSchema>>;

export function createAbstractShareClass(overrides: Partial<TAbstractShareClass> = {}): TAbstractShareClass {
  return abstractShareClassSchema().cast(overrides);
}
