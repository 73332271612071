import { useRecoilCallback } from 'recoil';
import { allCompanyFinancialsState, filterOutInvalidFinancials } from '../../../state/CompanyFinancialsState';
import {
  createCompanyFinancialsDataModel,
  ICompanyFinancialsDataModel,
} from '../../../../../data-models/company-financials.data-model';
import {
  createCustomKPIData,
  deleteCustomKPIData,
  ICompanyFinancialsDataModelCreatePayload,
  updateCustomKPIData,
} from '../../../../../services/queries/KPIRequestsQueries';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { fetchCompanyFinancials } from '../../../../../services/queries/MaggieCompanyQueries';

export function useUpdateCustomKPIData() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ snapshot, gotoSnapshot, set }) =>
      async (companyId: number, kpiDataId: number, updateKpiData: Partial<ICompanyFinancialsDataModel>) => {
        const companyFinancials = await snapshot.getPromise(allCompanyFinancialsState(companyId));
        const updatedFinancials = companyFinancials.map((financial) => {
          if (financial.id === kpiDataId) {
            return { ...financial, ...updateKpiData };
          }
          return financial;
        });

        const updatedSnapShot = snapshot.map((mutableSnapshot) => {
          return mutableSnapshot.set(allCompanyFinancialsState(companyId), updatedFinancials);
        });
        const release = snapshot.retain();
        gotoSnapshot(updatedSnapShot);

        try {
          const updated = await updateCustomKPIData(kpiDataId, updateKpiData);
          set(allCompanyFinancialsState(companyId), (curr) => {
            return curr.map((financial) => {
              if (financial.id === kpiDataId) {
                return updated;
              }
              return financial;
            });
          });
        } catch (err) {
          console.error('Failed to update KPI data', kpiDataId, updateKpiData);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to update KPI data');
          pushErrorToast({ message });
        } finally {
          release();
        }
      }
  );
}

export function useCreateCustomKPIData() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (companyId: number, newKPIData: ICompanyFinancialsDataModelCreatePayload) => {
        const randomId = Math.round(Math.random() * 10000000);
        const companyFinancials = await snapshot.getPromise(allCompanyFinancialsState(companyId));
        const updatedFinancials = [
          ...companyFinancials,
          createCompanyFinancialsDataModel({ id: randomId, ...newKPIData }),
        ];
        const updatedSnapShot = snapshot.map((mutableSnapshot) => {
          return mutableSnapshot.set(allCompanyFinancialsState(companyId), updatedFinancials);
        });

        const release = snapshot.retain();
        gotoSnapshot(updatedSnapShot);

        try {
          const newKpi = await createCustomKPIData(newKPIData);
          gotoSnapshot(snapshot);
          const updatedFromResponse = snapshot.map((mutableSnapshot) => {
            return mutableSnapshot.set(allCompanyFinancialsState(companyId), (curr) => [...curr, newKpi]);
          });
          gotoSnapshot(updatedFromResponse);
        } catch (err) {
          console.error('Failed to create KPI data', newKPIData);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to create KPI data');
          pushErrorToast({ message });
        } finally {
          release();
        }
      }
  );
}

export function useDeleteCustomKPIData() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(({ snapshot, gotoSnapshot }) => async (companyId: number, kpiDataId: number) => {
    const companyFinancials = await snapshot.getPromise(allCompanyFinancialsState(companyId));
    const updatedFinancials = companyFinancials.filter((financial) => {
      return financial.id !== kpiDataId;
    });

    const updatedSnapShot = snapshot.map((mutableSnapshot) => {
      return mutableSnapshot.set(allCompanyFinancialsState(companyId), updatedFinancials);
    });
    const release = snapshot.retain();
    gotoSnapshot(updatedSnapShot);

    try {
      await deleteCustomKPIData(kpiDataId);
    } catch (err) {
      console.error('Failed to update KPI data', kpiDataId);
      gotoSnapshot(snapshot);
      const message = getErrorMessage(err, 'Failed to delete KPI data');
      pushErrorToast({ message });
    } finally {
      release();
    }
  });
}

type UpdateParams = Parameters<ReturnType<typeof useUpdateCustomKPIData>>;
type CreateParams = Parameters<ReturnType<typeof useCreateCustomKPIData>>;
type DeleteParams = Parameters<ReturnType<typeof useDeleteCustomKPIData>>;

export interface ICustomKPIActions {
  companyId: number;
  update: UpdateParams[];
  create: CreateParams[];
  delete: DeleteParams[];
}

export function useBulkCustomKPIActions() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(({ snapshot, gotoSnapshot, set }) => async (actions: ICustomKPIActions) => {
    const companyFinancials = await snapshot.getPromise(allCompanyFinancialsState(actions.companyId));
    const toDelete = new Set(actions.delete?.map(([, id]) => id));
    const toUpdate = new Map(actions.update?.map(([, id, payload]) => [id, payload]));
    const updatedFinancials = companyFinancials
      .filter((financial) => {
        return !toDelete.has(financial.id);
      })
      .map((financial) => {
        if (toUpdate.has(financial.id)) {
          return { ...financial, ...toUpdate.get(financial.id) };
        } else {
          return financial;
        }
      })
      .concat(
        actions.create?.map(([, payload]) => {
          const randomId = Math.round(Math.random() * 10000000);
          return createCompanyFinancialsDataModel({ ...payload, id: randomId });
        })
      );

    const updatedSnapshot = snapshot.map((mutableSnapshot) => {
      return mutableSnapshot.set(allCompanyFinancialsState(actions.companyId), updatedFinancials);
    });
    const release = snapshot.retain();
    gotoSnapshot(updatedSnapshot);

    try {
      const promises: Promise<ICompanyFinancialsDataModel | void>[] = [];
      actions.create?.forEach(([, payload]) => {
        promises.push(createCustomKPIData(payload));
      });
      actions.update?.forEach(([, id, payload]) => {
        promises.push(updateCustomKPIData(id, payload));
      });
      actions.delete?.forEach(([, id]) => {
        promises.push(deleteCustomKPIData(id));
      });

      await Promise.all(promises).then(async () => {
        const financials = await fetchCompanyFinancials({ companyId: actions.companyId });
        set(allCompanyFinancialsState(actions.companyId), filterOutInvalidFinancials(financials ?? []));
      });
    } catch (err) {
      console.error('Failed to update KPI data');
      gotoSnapshot(snapshot);
      const message = getErrorMessage(err, 'Failed to update KPI data');
      pushErrorToast({ message });
    } finally {
      release();
    }
  });
}
