import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { requiredCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function warrantExpirationFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),
    currencyid: requiredCurrencyIdField(),
    principalWriteOffAmount: usdField().optional().nullable(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function warrantExpirationFormSchema() {
  return yup.object().shape(warrantExpirationFields());
}
