import { CSSProperties, useCallback, useMemo } from 'react';
import { Alert, Card, Typography, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { capitalize } from 'lodash-es';
import { cardStyles } from '../../../../../theme/component-styles';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { kpiTemplatesMapByUuidState } from '../../../../../services/state/KPI/KPITemplatesState';
import { MONTHS } from '../../Sections/FormItem/KPITable/useKPITableMetricsInputs';
import { FMT } from '../../../../../util/formatter-service';
import { allCompaniesActionState, selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import {
  AssignTemplateFieldTypes,
  TemplateAssignment,
} from '../../../../../schemas/template-assignment-schema';
import { companyState } from '../../../../../services/state/CompanyState';
import { IAssignTemplateParams, useTemplateAssignmentActions } from '../hooks/useTemplateAssignmentActions';
import { StepperFormButtons } from '../../../../Finance2/Forms/StepperFormButtons';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import {
  currentFormCompanyDataState,
  currentStepStateTA,
  templateAssignmentFormState,
} from './AssignTemplateFormState';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';
import { FormCardContainer } from './common-styled-components';

const AssignCard = styled(Card)({
  ...cardStyles,
  display: 'grid',
  gridTemplateColumns: '3fr repeat(3, 2fr) 3fr',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
});

type IAssignTemplateSummaryProps = Exclude<AssignTemplateFieldTypes, 'period'> &
  Pick<ICompanyDataModel, 'id'> & {
    style?: CSSProperties;
  };
export function TemplateAssignmentSummary({
  id: companyId,
  templateUuid,
  frequency,
  style = {},
}: IAssignTemplateSummaryProps) {
  const company = useRecoilValue(companyState(companyId));
  const templates = useRecoilValue(kpiTemplatesMapByUuidState);
  const templateName = templates.get(templateUuid)?.name ?? '';

  if (!company) return null;
  return (
    <AssignCard
      style={{
        ...style,
      }}
    >
      <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
      <Typography variant='body2' noWrap>
        {templateName}
      </Typography>
      <Typography variant='body2' noWrap>
        {capitalize(frequency)}
      </Typography>
      <Typography variant='body2' noWrap>
        {company.fye ? MONTHS[company.fye - 1] ?? '' : ''}
      </Typography>
      <Typography variant='body2' noWrap>
        {FMT.format('stringArray', company?.respondents)}
      </Typography>
    </AssignCard>
  );
}

export function AssignTemplateStep3() {
  const companyIds = useRecoilValue(selectedKpiCompaniesStateDC)?.map((c) => c.id ?? []);
  const currentFormCompanyDataMap = useRecoilValue(currentFormCompanyDataState);
  const { resetUIState } = useAllKpiCompaniesActions();
  const action = useRecoilValue(allCompaniesActionState);

  const templateData = useRecoilValue(templateAssignmentFormState)!;
  const { assignTemplate, updateAssignment } = useTemplateAssignmentActions();

  const onSubmit = useCallback(async () => {
    if (companyIds.length === 0 || !templateData) return false;

    let res;
    if (action === 'editAssignment') {
      res = await updateAssignment(templateData as Partial<TemplateAssignment>);
    } else {
      res = await assignTemplate({ ...templateData, companyIds } as IAssignTemplateParams);
    }

    resetUIState();
    return Boolean(res);
  }, [action, assignTemplate, companyIds, resetUIState, templateData, updateAssignment]);

  const summary = useMemo(() => {
    return companyIds.map((id) => {
      const company = currentFormCompanyDataMap.get(id)!;
      return (
        <TemplateAssignmentSummary
          key={company.id}
          templateUuid={templateData.templateUuid!}
          frequency={templateData.frequency!}
          granularity={templateData.granularity!}
          reminderThreshold={templateData.reminderThreshold!}
          id={company.id}
        />
      );
    });
  }, [companyIds, currentFormCompanyDataMap, templateData]);

  return (
    <>
      <FormCardContainer>
        {companyIds.length === 0 || !templateData ? (
          <Alert severity='error'>Missing data</Alert>
        ) : (
          <>{summary}</>
        )}
      </FormCardContainer>
      <StepperFormButtons
        stepIsValid={onSubmit}
        nextButtonLabel={'Submit'}
        stepState={currentStepStateTA}
        incrementOnNext={false}
      />
    </>
  );
}
