import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';

const WIDTH = '5rem';
const HEIGHT = '1.5rem';
const BUTTON_GAP = '1rem';

const ButtonContainer = styled('div')<{ buttonsGap?: string; alignButtons?: string }>`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: ${({ alignButtons }) => (alignButtons ? alignButtons : 'space-between')};
  align-items: flex-start;
  gap: ${({ buttonsGap }) => (buttonsGap ? buttonsGap : BUTTON_GAP)};
`;

type CustomRadioProps = {
  label: string;
  disabled: boolean;
  buttonsWidth?: string;
};

interface Item {
  id: number;
  name: string;
}

const CustomRadio = styled('input')<CustomRadioProps>`
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'cursor')};
  appearance: none;
  width: ${({ buttonsWidth }) => (buttonsWidth ? buttonsWidth : WIDTH)};
  height: ${HEIGHT};

  &:before {
    display: flex;
    content: '${({ label }) => label}';
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.neutral[10]};
    border-radius: 4px;
  }
  &:checked:before {
    border: 2px solid #42229c;
    background: ${({ theme }) => theme.colors.primary[0]};
  }
`;

type SourceTypeSelectorProps = {
  name?: string;
  value?: string;
  onChange: (id: string) => void;
  onFocus?: () => void;
  disabled?: boolean;
  buttonsGap?: string;
  buttonsWidth?: string;
  alignButtons?: string;
};

export const sourceTypes = [
  { id: 1, name: 'Referral' },
  { id: 2, name: 'Direct' },
  { id: 3, name: 'Outbound' },
];

export const SourceTypeSelector = ({
  onChange,
  onFocus,
  value,
  disabled = false,
  buttonsGap,
  buttonsWidth,
  alignButtons,
}: SourceTypeSelectorProps) => {
  const [selectedSourceType, setSelectedSourceType] = useState<Item | undefined>(
    sourceTypes.find((r) => r.name === value)
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedSourceTypeId = parseInt(e.target.value);

      if (selectedSourceTypeId) {
        const sourceTypeToSelect = sourceTypes.find((r) => r.id === selectedSourceTypeId);
        setSelectedSourceType(sourceTypeToSelect);
        onChange(sourceTypeToSelect?.name as string);
      }
    },
    [onChange]
  );

  return (
    <>
      <ButtonContainer onFocus={onFocus} buttonsGap={buttonsGap} alignButtons={alignButtons}>
        {sourceTypes.map((sourceType) => (
          <CustomRadio
            key={sourceType.id}
            type='radio'
            value={sourceType.id}
            checked={selectedSourceType?.id === sourceType.id}
            label={sourceType.name}
            onChange={handleChange}
            disabled={disabled}
            buttonsWidth={buttonsWidth}
          />
        ))}
      </ButtonContainer>
    </>
  );
};
