import { BulkUploadReportResponse } from '../../schemas/BulkUploadReport.schema';
import { MaggieHttp } from '../MaggieHttp';

export type UnSharePayload = string[];
export type CheckStatusResponse = {
  processed: boolean;
  comments: string;
  companyId: number;
  fileId: string;
  fileName: string;
  id: number;
  status: 'processing' | 'error' | 'processed' | 'pending';
};

export async function fetchUploadStatus(fileId: string): Promise<CheckStatusResponse> {
  const { data } = await MaggieHttp.maggie.get<CheckStatusResponse>(
    `/growth-analysis/uploadStatus?id=${fileId}`
  );
  if (!data.processed) throw new Error('The file has not yet been processed');
  return data;
}

export type InitUploadResponse = {
  companyId: number;
  signedUrl: string;
  fileId: string;
};

export async function initUpload(companyId: number) {
  const payload = {
    companyId,
    contentType: 'text/csv',
  };
  const { data } = await MaggieHttp.maggie.post<typeof payload, InitUploadResponse>(
    '/growth-analysis/initateUpload',
    payload
  );

  return data;
}

export async function getBulkUploadReport(id: number) {
  const { data } = await MaggieHttp.maggie.get<BulkUploadReportResponse>(`/customField/upload/status/${id}`);
  return data;
}
