import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { Divider, Tab, Tabs, Typography } from '@mui/material';
import { primaryCompanyCI, selectedDashboardCI } from '../state/DashboardsState';
import { CompetitiveIntelligenceSkeletonLoader } from '../../../components/SkeletonLoader/CompetitiveIntelligence.SkeletonLoader';
import { theme } from '../../../theme';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { useConnectCompetitiveIntelligenceMetrics } from '../hooks/useConnectCompetitiveIntelligenceMetrics';
import { BreadCrumbs } from './Breadcrumbs/Breadcrumbs';
import { SpiderChart } from './SpiderChart/SpiderChart';
import { CompanyListWrapper } from './CompanyList/CompanyListWrapper';
import { CITable } from './Tables/CITable';
import { HistoryComparisonHeader } from './HistoryComparison/HistoryComparisonHeader/HistoryComparisonHeader';
import { HistoryComparisonChartList } from './HistoryComparison/HistoryComparisonChartList/HistoryComparisonChartList';
import { TimeRangeWrapper } from './TimeRange/TimeRangeWrapper';

const Container = styled('div')`
  height: 100%;
  width: 100%;
  padding-left: 2.3rem;
`;

const TableWrapper = styled('section')`
  min-width: 100%;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;

const Logo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;

  & img {
    max-width: 80%;
  }
`;

const MainContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > * {
    width: 100%;
  }
`;

const SubHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: -9px;
`;

export enum CI_TABS {
  METRICS_COMPARISON = 'metrics-comparison',
  VISUALIZATION = 'visualization',
}

const TABS_DATA = [
  {
    value: CI_TABS.METRICS_COMPARISON,
    label: 'Metrics Comparison',
  },
  {
    value: CI_TABS.VISUALIZATION,
    label: 'Visualization',
  },
];

export function CIMainContent() {
  useConnectCompetitiveIntelligenceMetrics();
  const primaryCompany = useRecoilValue(primaryCompanyCI);
  const dashboard = useRecoilValue(selectedDashboardCI);
  const [activeTab, setActiveTab] = useState(CI_TABS.METRICS_COMPARISON);

  if (!dashboard || !primaryCompany) return <CompetitiveIntelligenceSkeletonLoader />;

  return (
    <Container>
      <Wrapper>
        <BreadCrumbs />
        <Header>
          <Logo>
            <img src={primaryCompany.logoUrl ?? ''} alt={'logo'} />
          </Logo>
          <Typography variant='h5' color={theme.colors.primary[90]}>
            {`${primaryCompany.name} vs Key Competitors`}
          </Typography>
        </Header>
        <SubHeader>
          <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
            {TABS_DATA.map(({ value, label }) => (
              <Tab key={value} value={value} label={label} className={`mui-custom-tab`} />
            ))}
          </Tabs>
          <TimeRangeWrapper />
        </SubHeader>
        <Divider />
        <CompanyListWrapper />
        {activeTab === CI_TABS.VISUALIZATION && (
          <MainContentWrapper>
            <GenericFallbacksWrapper>
              <SpiderChart title={'Spider Chart'} id={'spider-chart'} />
            </GenericFallbacksWrapper>
            <HistoryComparisonHeader />
            <HistoryComparisonChartList />
          </MainContentWrapper>
        )}
      </Wrapper>
      {activeTab === CI_TABS.METRICS_COMPARISON && <TableWrapper>{<CITable />}</TableWrapper>}
    </Container>
  );
}
