import { useRecoilValue } from 'recoil';
import { createFormField, IFormFieldFreeLabelsMeta } from '../view-models/form.view-model';
import { RendererType } from '../data-models/field.data-model';
import { dealTodosState } from '../pages/DealFlow2/state/DealboardDataState';
import { FieldSettings } from './CommonFields';

export function useDealTodoField({
  key,
  label = key,
  required = false,
  disabled = false,
  maxTags = -1,
}: FieldSettings & Pick<IFormFieldFreeLabelsMeta, 'maxTags'>) {
  const todos = useRecoilValue(dealTodosState);

  return createFormField<IFormFieldFreeLabelsMeta>({
    key,
    label,
    required,
    disabled,
    renderer: RendererType.freeLabels,
    rendererMeta: {
      maxTags,
      values: todos.map((todo) => {
        return {
          value: todo.name,
          displayName: todo.name,
        };
      }),
    },
  });
}
