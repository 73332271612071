import { atom, selector } from 'recoil';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { companyState } from './CompanyState';

// need to have only one source of truth for company data, hence this selector instead of storing fetched portcos directly in state

export const portCosState = selector<ICompanyDataModel[] | null>({
  key: 'PortCosState',
  get: ({ get }) => {
    const portCoIds = get(portCoIdsState);

    return portCoIds.reduce((acc, id) => {
      const company = get(companyState(id));
      if (company) {
        acc.push(company);
      }
      return acc;
    }, [] as ICompanyDataModel[]);
  },
});

export const portCosByIdMapState = selector<Map<number, ICompanyDataModel>>({
  key: 'PortCosByIdMapState',
  get: ({ get }) => {
    const portCos = get(portCosState);
    const portCoMap = new Map<number, ICompanyDataModel>();
    if (!portCos) return portCoMap;
    portCos.forEach((c) => portCoMap.set(c.id, c));
    return portCoMap;
  },
});

export const portCosByNameMapState = selector<Map<string, ICompanyDataModel>>({
  key: 'portCosByNameMapState',
  get: ({ get }) => {
    const portCos = get(portCosState);
    const portCoMap = new Map<string, ICompanyDataModel>();
    if (!portCos) return portCoMap;
    portCos.forEach((c) => portCoMap.set(c.name, c));
    return portCoMap;
  },
});

export const portCoIdsState = atom<number[]>({
  key: 'portCoIdsState',
  default: [],
});

export const selectedCompanyIdStateFinance = atom<number | null>({
  key: 'SelectedTransactionCompanyIdState',
  default: null,
});
