import { FC } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useRecoilValueLoadable } from 'recoil';
import { ICompanyPersonDataModel, ICompanyDataModel } from '../../../../data-models/company.data-model';
import { personState } from '../../../../services/state/PersonState';
import { SocialMedia } from '../../Summary/components/People/SocialMedia';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';

const Header = styled('header')`
  width: 100%;
  padding: 1rem 1.5rem;
`;

interface IPersonDrawerHeaderProps {
  personData: ICompanyPersonDataModel;
  company: ICompanyDataModel;
  onClose: () => void;
}

export const PersonDrawerHeader: FC<IPersonDrawerHeaderProps> = ({
  personData,
  company,

  onClose,
}) => {
  const { colors } = useTheme();
  const { name, id } = personData;
  const person = useRecoilValueLoadable(personState(id)).valueMaybe();
  const { socials } = person ?? {};
  const nameParts = name.toUpperCase().split(' ') ?? [];

  return (
    <Header>
      <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          variant='caption'
          color={colors.neutral[60]}
        >{`${company.name} / More details about ${name}`}</Typography>
        <Stack direction={'row'} gap={'0.25rem'}>
          <IconButton onClick={onClose} size='small' aria-label='close drawer'>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Stack>
      </Stack>
      <Stack marginTop={'1rem'}>
        <Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
          <MUIAvatar nameParts={nameParts} src={null} size='mediumLarge' />
          <Stack>
            <Typography variant='h6' color='text.primary'>
              {name}
            </Typography>

            <SocialMedia
              companyId={company.id}
              personId={id}
              twitterUrl={socials?.twitter}
              linkedinUrl={socials?.linkedin}
              showEmails={false}
              style={{ marginTop: 0 }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Header>
  );
};
