import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  TFinanceAction,
  selectedInvestmentStateFinance,
  selectedTransactionStateFinance,
  showModalStateFinance,
} from '../state/FinanceUIState';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { useTransactionHistoryAction } from './useTransactionHistoryAction';

export function useTransactionModalActions() {
  const { navigateToTransactionHistory } = useTransactionHistoryAction();
  const setShowModal = useSetRecoilState(showModalStateFinance);
  const setSelectedTransaction = useSetRecoilState(selectedTransactionStateFinance);

  const handleCloseModal = useCallback(() => {
    setShowModal(null);
    setSelectedTransaction(null);
  }, [setSelectedTransaction, setShowModal]);

  const handleClickAction = useCallback(
    (data: ITransactionDataModel, action: TFinanceAction) => {
      setSelectedTransaction(data);
      setShowModal(action);
    },
    [setSelectedTransaction, setShowModal]
  );

  const handleClickHistory = useCallback(
    (data: ITransactionDataModel) => {
      navigateToTransactionHistory(data);
    },
    [navigateToTransactionHistory]
  );

  return {
    handleClickAction,
    handleClickHistory,
    handleCloseModal,
  };
}

export function useInvestmentModalActions() {
  const setShowModal = useSetRecoilState(showModalStateFinance);
  const setSelectedInvestment = useSetRecoilState(selectedInvestmentStateFinance);

  const handleCloseModal = useCallback(() => {
    setShowModal(null);
    setSelectedInvestment(null);
  }, [setSelectedInvestment, setShowModal]);

  const handleClickAction = useCallback(
    (data: IFinanceRoundDataModel, action: TFinanceAction) => {
      setSelectedInvestment(data);
      setShowModal(action);
    },
    [setSelectedInvestment, setShowModal]
  );

  return {
    handleClickAction,
    handleCloseModal,
  };
}
