import { Dialog, DialogContent, DialogTitle, IconButton, SxProps, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Title = styled('span')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface IFormDialogProps extends PropsWithChildren {
  open: boolean;
  closeOnEscOrBackdropClick?: boolean;
  disableRestoreFocus?: boolean;
  onClose?: () => void;
  showTitleBar?: boolean;
  sx?: SxProps;
  title?: string;
  titleComponent?: ReactNode;
}

export function BasicDialog(props: IFormDialogProps) {
  const {
    open,
    children,
    onClose,
    title,
    titleComponent,
    sx,
    disableRestoreFocus = false,
    closeOnEscOrBackdropClick = false,
    showTitleBar = true,
  } = props;
  const { colors } = useTheme();

  const _onClose = useCallback(() => {
    if (!closeOnEscOrBackdropClick) return;
    onClose?.();
  }, [closeOnEscOrBackdropClick, onClose]);

  return (
    <Dialog open={open} maxWidth={false} onClose={_onClose} sx={sx} disableRestoreFocus={disableRestoreFocus}>
      {showTitleBar && (
        <DialogTitle sx={{ paddingX: '2.5rem', paddingTop: '1rem' }}>
          <Title>
            {titleComponent ? titleComponent : <Typography>{title}</Typography>}
            {onClose ? (
              <IconButton
                aria-label='close'
                onClick={onClose}
                sx={{
                  color: colors.neutral[40],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Title>
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}
