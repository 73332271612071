import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { ToastProps } from '../../../../../types';
import {
  BulkTemplateAssignmentError,
  BulkTemplateAssignmentResponse,
} from '../../../../../schemas/BulkTemplateAssignmentResponse.schema';
import { assignmentErrorsState } from '../AllCompaniesState';

interface IHandleBulkResponseParams {
  response: BulkTemplateAssignmentResponse;
  onSuccess: () => void;
}
export function useHandleBulkResponse() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const setErrors = useSetRecoilState(assignmentErrorsState);

  const onShowErrors = useCallback(
    (errors: BulkTemplateAssignmentError[]) => {
      setErrors(errors);
    },
    [setErrors]
  );

  return useCallback(
    ({ response, onSuccess }: IHandleBulkResponseParams) => {
      const { errors, results } = response;

      const errorProps: Partial<ToastProps> = {
        buttonLabel: 'Show details',
        buttonAction: () => {
          onShowErrors(errors);
        },
        autoHideDuration: 10000,
      };
      if (errors.length === 0) {
        onSuccess();
        pushSuccessToast({
          message: 'Successfully assigned templates to companies',
        });
      } else if (results.length === 0) {
        pushErrorToast({
          message: 'Failed to assign templates to companies',
          ...errorProps,
        });
      } else {
        onSuccess();
        pushErrorToast({
          message: 'Some templates were not assigned to companies',
          ...errorProps,
        });
      }
    },
    [onShowErrors, pushErrorToast, pushSuccessToast]
  );
}
