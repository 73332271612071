import { Chip, SxProps, Typography, useTheme } from '@mui/material';

export interface IPassChipProps {
  handleTogglerPassDetails: () => void;
  showPassDetails: boolean;
  sx?: SxProps;
}

export const PassChip = ({ handleTogglerPassDetails, showPassDetails, sx }: IPassChipProps) => {
  const { colors } = useTheme();

  return (
    <Chip
      role={'button'}
      aria-label={'toggle pass details'}
      component={'button'}
      sx={{
        backgroundColor: colors.neutral[10],
        color: colors.primary[60],
        '&:hover': { backgroundColor: colors.primary[5] },
        ...sx,
      }}
      onClick={handleTogglerPassDetails}
      label={
        <>
          <Typography component={'span'} variant='body2' color={colors.neutral[70]} mr={8}>
            Pass
          </Typography>
          {showPassDetails ? 'Hide Details' : 'Show Details'}
        </>
      }
    />
  );
};
