const TEXT_MEASURING_CANVAS_ID = '#text-measuring-canvas';

export function getTextWidth(text: string, font = '16px Wotfard-Regular') {
  let canvas: HTMLCanvasElement = document.querySelector(TEXT_MEASURING_CANVAS_ID) as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    canvas.id = TEXT_MEASURING_CANVAS_ID;
  }
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.font = font;
    return ctx.measureText(text).width;
  } else {
    return 16 * text.length; // 16px for single character
  }
}
