import * as yup from 'yup';
import { BaseModelWithID } from './BaseModel.schema';

export const LocationSource = {
  Apollo: 'APOLLO',
  Crunchbase: 'CRUNCHBASE',
  Harmonic: 'HARMONIC',
  User: 'USER',
};

export function locationFields() {
  return {
    city: yup.string().nullable(),
    country: yup.string().nullable(),
    countryCode: yup.string().nullable(),
    id: yup.number().nullable(),
    region: yup.string().nullable(),
    source: yup.string().oneOf(Object.values(LocationSource)).nullable(),
    state: yup.string().nullable(),
    stateCode: yup.string().nullable(),
    zip: yup.string().nullable(),
  };
}

export function locationSchema() {
  return yup.object(locationFields());
}

export type BaseLocationDataModel = yup.InferType<ReturnType<typeof locationSchema>>;
export type LocationDataModel = BaseLocationDataModel & BaseModelWithID;
export type CompanyLocationDataModel = BaseLocationDataModel & { companyId: number };
export function createCompanyLocationDataModel(
  overrides: Partial<CompanyLocationDataModel> = {}
): CompanyLocationDataModel {
  return {
    city: '',
    country: '',
    state: '',
    companyId: 0,
    ...overrides,
  };
}

export function createBaseLocationDataModel(
  overrides: Partial<BaseLocationDataModel> = {}
): BaseLocationDataModel {
  return {
    city: '',
    country: '',
    state: '',
    ...overrides,
  };
}
