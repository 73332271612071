import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import { IUniqueColumnFieldDataModel } from '../../../../data-models/airtable-entity.data-model';
import { IAirTableFieldDataModel } from '../../../../data-models/airtable-base.data-model';
import ConnectIcon from '../../../../assets/icons/connect.svg?react';
import { StyledColumnBox } from './CommonStyledComponents';
import { FieldSelector } from './FieldSelector';

interface IColumnMatcherProps {
  fixedColumn: IUniqueColumnFieldDataModel;
  options: IAirTableFieldDataModel[];
  value: IAirTableFieldDataModel | null;
  onChange: (value: IAirTableFieldDataModel | null) => void;
}

export const ColumnMatcher: FC<IColumnMatcherProps> = ({ fixedColumn, options, value, onChange }) => {
  const { colors } = useTheme();
  const iconColor = value ? colors.primary[60] : colors.neutral[30];

  return (
    <>
      <StyledColumnBox style={{ marginRight: '1rem' }}>
        <Typography variant='body2'>{fixedColumn.displayName}</Typography>
      </StyledColumnBox>
      <ConnectIcon fill={iconColor} title='connect' />
      <FieldSelector options={options} value={value} onChange={onChange} sx={{ marginLeft: '1rem' }} />
    </>
  );
};
