import { NavigationBlocker } from '../CompanyProfiles/Scenarios/SingleScenario/block-navigation/NavigationBlocker';
import { InfoBox } from '../Mappings/forms/InfoBox';
import { IKpiTemplateFormNavBlocker, useKpiTemplateFormNavBlocker } from './useKpiTemplateFormNavBlocker.ts';

export function KpiTemplateFormNavBlocker({ onSave, trigger, isDirty }: IKpiTemplateFormNavBlocker) {
  const { shouldBlock, doBeforeProceed } = useKpiTemplateFormNavBlocker({ onSave, isDirty, trigger });

  return (
    <NavigationBlocker
      shouldBlock={shouldBlock}
      title={'Do you want to save changes before leaving?'}
      message={<InfoBox message={'You have unsaved changes'} />}
      doBeforeProceed={doBeforeProceed}
      proceedProps={{ label: 'Leave Without Saving' }}
    />
  );
}
