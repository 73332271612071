import { useRecoilCallback, useRecoilValue } from 'recoil';
import { SerializedEditorState } from 'lexical';
import { LoadingId } from '../../types';
import { useLoadingBarState } from '../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { INoteDataModel, INoteDraftDataModel } from '../../data-models/note.data-model';
import { createNote, deleteNote, updateNote } from '../queries/MaggieNoteQueries';
import { notesState } from '../state/NoteState';
import { selectedNoteState } from '../../pages/CompanyProfiles/state/UIState';

export function useCreateNote(companyId: number) {
  const { pushErrorToast } = useToastMessageState();
  const currentNotes = useRecoilValue(notesState(companyId));

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async <T>(companyId: number, newNoteData: INoteDraftDataModel<T>) => {
        try {
          const selectedNote = await snapshot.getPromise(selectedNoteState);
          const response = await createNote<T>(newNoteData);
          if (selectedNote?.id === -1) {
            set(selectedNoteState, response.note as INoteDataModel<SerializedEditorState>);
          }
          const updatedNotes = [response.note, ...currentNotes];
          set(notesState(companyId), updatedNotes);

          return response.note;
        } catch (err) {
          set(notesState(companyId), currentNotes);
          pushErrorToast({ message: 'Failed to create note' });
        }
      }
  );
}

export function useUpdateNote(companyId: number) {
  const { pushErrorToast } = useToastMessageState();
  const currentNotes = useRecoilValue(notesState(companyId));

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async <T>(companyId: number, updatedNote: INoteDataModel<T>) => {
        const selectedNote = await snapshot.getPromise(selectedNoteState);
        const updatedNotes = currentNotes.map((note) => {
          if (note.id === updatedNote.id) {
            return updatedNote;
          }
          return note;
        });

        try {
          const response = await updateNote(updatedNote);
          set(notesState(companyId), updatedNotes);

          if (selectedNote?.id === updatedNote.id) {
            set(selectedNoteState, updatedNote as INoteDataModel<SerializedEditorState>);
          }

          return response.note;
        } catch (err) {
          set(notesState(companyId), currentNotes);
          pushErrorToast({ message: 'Failed to update note' });
        }
      }
  );
}

export function useDeleteNote() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useRecoilCallback(({ snapshot, set }) => async (companyId: number, noteId: number) => {
    actions.startLoading(LoadingId.deleteNote);
    const currentNotes = await snapshot.getPromise(notesState(companyId));
    const notesWithoutDeletedOne = currentNotes.filter((note) => note.id !== noteId);

    try {
      await deleteNote(noteId);
      set(notesState(companyId), notesWithoutDeletedOne);
      pushSuccessToast({ message: 'Note deleted' });
      // Return true if it was deleted successfully
      return true;
    } catch (err) {
      set(notesState(companyId), currentNotes);
      pushErrorToast({ message: 'Failed to delete note' });
      // Return false if it was NOT  deleted successfully
      return false;
    } finally {
      actions.stopLoading(LoadingId.deleteNote);
    }
  });
}
