import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { GenericErrorFallback } from './GenericErrorFallback';
import { GenericLoadingFallback } from './GenericLoadingFallback';

export interface IGenericFallbacksWrapperProps extends PropsWithChildren {
  style?: CSSProperties;
  suspenseFallback?: ReactNode;
}

export function GenericFallbacksWrapper(props: IGenericFallbacksWrapperProps) {
  const { children, style } = props;
  const suspenseFallback = props.suspenseFallback ?? <GenericLoadingFallback />;

  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => (
        <GenericErrorFallback error={error} resetError={resetError} style={style ?? {}} />
      )}
    >
      <React.Suspense fallback={suspenseFallback}>{children}</React.Suspense>
    </ErrorBoundary>
  );
}
