import { Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CurrencySelector } from '../../../../../Finance/form-components/CurrencySelector';

export const KPITableHeader = () => {
  const { colors } = useTheme();
  const { setValue, watch } = useFormContext();

  const inputCurrency = watch(`kpiData.currencyId`) ?? 1;

  return (
    <Stack direction='row' gap='.5rem' justifyContent={'space-between'}>
      <Stack gap='.5rem'>
        <Typography variant='body2' color={colors.primary[100]} fontWeight={700}>
          Input Currency
        </Typography>
        <CurrencySelector
          initialValue={inputCurrency}
          onSelectCurrency={(val) => setValue(`kpiData.currencyId`, val)}
        />
      </Stack>
    </Stack>
  );
};
