import axios, { AxiosResponse } from 'axios';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

type useUploadToS3Props = {
  file: File;
  url: string;
};

export async function uploadToS3(file: File, presignedPostUrl: string): Promise<AxiosResponse> {
  return new Promise((res) => {
    const fr = new FileReader();
    fr.addEventListener('load', async () => {
      const response = await axios.put(presignedPostUrl, fr.result, {
        headers: { 'Content-Type': file.type },
      });

      res(response);
    });
    fr.readAsArrayBuffer(file);
  });
}

export function useUploadToS3(): UseMutationResult<AxiosResponse, Error, useUploadToS3Props> {
  return useMutation({
    mutationFn: ({ file, url }: useUploadToS3Props) => uploadToS3(file, url),
  });
}
