import { CustomCellRendererProps } from 'ag-grid-react';
import { useCallback, useMemo } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { MenuButton } from '../../Finance2/common-grid-defs/MenuButton';
import { RubricConfig } from '../../../schemas/Rubric.schema';
import { useRubricConfigFormData } from './useRubricConfigFormData';

export function RubricConfigMenu(params: CustomCellRendererProps<RubricConfig>) {
  const { setSelectedAction, setSelectedConfigId } = useRubricConfigFormData();
  const { data } = params;

  const onEdit = useCallback(() => {
    if (!data) return;
    setSelectedAction('edit');
    setSelectedConfigId(data.id);
  }, [data, setSelectedAction, setSelectedConfigId]);

  const onDelete = useCallback(() => {
    if (!data) return;
    setSelectedAction('delete');
    setSelectedConfigId(data.id);
  }, [data, setSelectedAction, setSelectedConfigId]);

  const menuItems = useMemo(() => {
    return [
      {
        name: 'Edit',
        icon: <EditOutlinedIcon />,
        onClick: onEdit,
      },
      {
        name: 'Delete',
        icon: <DeleteOutlinedIcon />,
        onClick: onDelete,
      },
    ];
  }, [onDelete, onEdit]);

  if (!data) return null;
  return <MenuButton menuItems={menuItems} label={'rubric actions'} />;
}
