import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { MostRecentDataChart } from '../../../../components/Charts/Leaderboard/MostRecentRounds/MostRecentDataChart';
import { CHART_COMPONENTS } from '../../../../types';
import { useMostRecentParticipatingRounds } from '../../hooks/useMostRecentParticipatingRounds';
import { roundTrackerMetricsByDate } from '../../state/RoundTrackerMetricsState';
import { selectedViewDatePF } from '../../state/PageState';

export function MostRecentParticipatingRounds() {
  const date = useRecoilValue(selectedViewDatePF);
  const roundTrackerMetrics = useRecoilValueLoadable(roundTrackerMetricsByDate(date));

  const data = useMostRecentParticipatingRounds(roundTrackerMetrics.valueMaybe() || []);

  return (
    <MostRecentDataChart
      id={CHART_COMPONENTS.MOST_RECENT_PARTICIPATING_ROUNDS}
      width={640}
      data={data}
      columnHeaders={['Company', 'Stage', 'Date', 'Investment Amount']}
      loading={roundTrackerMetrics.state === 'loading'}
    />
  );
}
