import { useRecoilValue } from 'recoil';
import { dealBoardConfigState } from '../../DealFlow2/state/DealboardDataState';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { getDealReasonFields } from './DealReasonFields';
import { transformDataModelBeforeSubmit } from './DealReasonUtils';

export function ConfigPassReasons() {
  const { passReasons } = useRecoilValue(dealBoardConfigState);

  return (
    <GenericConfigPage
      baseTitle={'Passed Reason'}
      data={passReasons}
      fields={getDealReasonFields('passedReason')}
      pageTitle={'Passed Reasons'}
      queryURL={'/passReason'}
      transformBeforeSubmit={transformDataModelBeforeSubmit}
    />
  );
}
