import { useRecoilCallback } from 'recoil';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { updateReportingStatus } from '../../../../../services/queries/MaggieCompanyQueries';
import { companyState } from '../../../../../services/state/CompanyState';
import { useInvalidateKPIRequests } from '../../KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';

export function useSetReportingStatus() {
  const updateCompany = useUpdateCompanyAndState();
  const { pushSuccessToast, pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();

  return useRecoilCallback(
    ({ set }) =>
      async (companyId: number, reporting: boolean) => {
        try {
          await updateReportingStatus(companyId, reporting);
          set(companyState(companyId), (prev) =>
            !prev
              ? prev
              : {
                  ...prev,
                  reporting,
                }
          );
          invalidateKpiRequests();
          pushSuccessToast({
            message: `Data collection is now ${reporting ? 'enabled' : 'disabled'}`,
          });
        } catch (error) {
          console.error(error);
          pushErrorToast({
            message: getErrorMessage(error, 'Failed to update reporting status'),
          });
        }
      },
    [pushSuccessToast, updateCompany]
  );
}
