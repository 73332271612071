export const filterEmptyValues = (obj: object) => {
  const objCopy = { ...obj };

  Object.keys(objCopy).forEach((key) => {
    if (objCopy[key as keyof typeof obj] === '') {
      delete objCopy[key as keyof typeof obj];
    }
  });

  return objCopy;
};
