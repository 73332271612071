import { atomFamily } from 'recoil';
import { IPersonDataModel } from '../../data-models/person.data-model';
import { fetchPerson } from '../queries/MaggieCompanyQueries';

export const personState = atomFamily<IPersonDataModel | null, number>({
  key: 'personById',
  default: null,
  effects: (personId) => [
    ({ trigger, setSelf }) => {
      if (trigger === 'get') {
        setSelf(fetchPerson(personId));
      }
    },
  ],
});
