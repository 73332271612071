import { PresetPeriods } from '../state/DealConversionFilterState';

export function getDurationText(spanInMonths: PresetPeriods) {
  switch (spanInMonths) {
    case PresetPeriods.L1M:
      return 'MoM% change';
    case PresetPeriods.L3M:
      return 'QoQ% change';
    case PresetPeriods.L12M:
      return 'YoY% change';
    default:
      return '';
  }
}
