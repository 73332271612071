import CheckIcon from '@mui/icons-material/Check';
import { Button, useTheme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { theme } from '../../theme';
import { Icon, IconTypes } from '../Icon';

interface Props {
  maxDate?: Date;
  minDate?: Date | null;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  handleApply: () => void;
}

const { primary } = theme.colors;

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  box-shadow:
    0px -4px 40px rgba(255, 255, 255, 0.1),
    0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  gap: 5px;
  position: absolute;
  top: 2.5rem;
  left: -19rem;
  background-color: ${primary[0]};
  z-index: 120;
  cursor: initial;
`;

const RightSide = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  margin-left: auto;
  gap: 15px;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-top: auto;
`;

const LeftSize = styled('div')`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d2d5db;
  gap: 10px;
  padding: 1rem;
  width: 11rem;
`;

const Period = styled('div')<{ isActive: boolean; isDisabled: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? primary[5] : primary[0])};
  border-radius: 4px;
  padding: 0 0.8rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  height: 3rem;

  & > * {
    &:last-child {
      margin-left: ${({ isActive }) => (isActive ? 'auto' : 'inherit')};
    }
  }
`;

export function DatePicker({
  maxDate = new Date(),
  minDate,
  selectedDate,
  setSelectedDate,
  handleApply,
}: Props): ReactElement {
  const [displayDate, setDisplayDate] = useState(new Date());
  const [period, setPeriod] = useState('');
  const { primary, secondary, neutral } = useTheme().colors;
  const [eoyDisabled, setEoyDisabled] = useState(false);
  const [quarterEndDisabled, setQuarterEndDisabled] = useState(false);
  const minDateMapped = useMemo(() => {
    if (minDate) return minDate;
    const today = new Date();
    today.setFullYear(today.getFullYear() - 19);
    return today;
  }, [minDate]);

  const handleClear = () => {
    setDisplayDate(new Date());
    setSelectedDate(null);
  };

  const setToday = () => {
    const today = new Date();
    setPeriod('Today');
    setDisplayDate(today);
    setSelectedDate(today);
  };

  const setEOY = () => {
    const today = new Date();
    const eoy = new Date(displayDate);
    eoy.setMonth(11);
    eoy.setDate(31);
    if (eoy > today) return;
    setPeriod('EOY');
    setDisplayDate(eoy);
    setSelectedDate(eoy);
  };

  const setQuarterEnd = () => {
    const today = new Date();
    const quarter = Math.floor(displayDate.getMonth() / 3);
    const startFullQuarter = new Date(displayDate.getFullYear(), quarter * 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    if (endFullQuarter > today) return;
    setPeriod('Quarter End');
    setDisplayDate(endFullQuarter);
    setSelectedDate(endFullQuarter);
  };

  useEffect(() => {
    const today = new Date();
    const eoy = new Date(displayDate);
    eoy.setMonth(11);
    eoy.setDate(31);
    if (eoy > today) return setEoyDisabled(true);
    setEoyDisabled(false);
  }, [displayDate]);

  useEffect(() => {
    const today = new Date();
    const quarter = Math.floor(displayDate.getMonth() / 3);
    const startFullQuarter = new Date(displayDate.getFullYear(), quarter * 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    if (endFullQuarter > today) return setQuarterEndDisabled(true);
    setQuarterEndDisabled(false);
  }, [displayDate]);

  return (
    <Wrapper>
      <LeftSize>
        <Period isActive={period === 'Today'} onClick={setToday} isDisabled={false}>
          <Typography variant={'body2'} color={primary[100]}>
            Today
          </Typography>
          {period === 'Today' && <Icon type={IconTypes.CHECK} color={secondary[60]} />}
        </Period>
        <Period isActive={period === 'Quarter End'} onClick={setQuarterEnd} isDisabled={quarterEndDisabled}>
          <Typography variant={'body2'} color={quarterEndDisabled ? neutral[50] : primary[100]}>
            Quarter End
          </Typography>
          {period === 'Quarter End' && <Icon type={IconTypes.CHECK} color={secondary[60]} />}
        </Period>
        <Period isActive={period === 'EOY'} onClick={setEOY} isDisabled={eoyDisabled}>
          <Typography variant={'body2'} color={eoyDisabled ? neutral[50] : primary[100]}>
            EOY
          </Typography>
          {period === 'EOY' && <Icon type={IconTypes.CHECK} color={secondary[60]} />}
        </Period>
      </LeftSize>
      <RightSide>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            value={selectedDate}
            openTo={'year'}
            minDate={minDateMapped}
            onChange={(val: Date | null) => setSelectedDate(val)}
            onMonthChange={(date: Date) => {
              setDisplayDate(date);
            }}
            onYearChange={(date: Date) => {
              setDisplayDate(date);
            }}
            maxDate={maxDate}
          />
        </LocalizationProvider>
        <ButtonsWrapper>
          <Button variant={'outlined'} color={'secondary'} onClick={handleClear}>
            Clear
          </Button>
          <Button
            variant={'contained'}
            color={'secondary'}
            disableElevation={true}
            startIcon={<CheckIcon />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </ButtonsWrapper>
      </RightSide>
    </Wrapper>
  );
}
