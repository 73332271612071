import { Dispatch, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { theme } from '../../../../theme';
import { SharedButtonStatus, TSharedUser } from '../../../../types';
import { selectedViewPF } from '../../state/ViewState';
import { useShareView, useUnShareView } from '../../hooks/useViewActions';

interface Props {
  item: TSharedUser;
  setSharedUsers: Dispatch<SetStateAction<TSharedUser[]>>;
}
interface TypographyWrapperProps {
  type: SharedButtonStatus;
}

const Wrapper = styled('div')`
  display: flex;
  padding: 0 6px;
  align-items: center;
  justify-content: space-between;
`;

const TypographyWrapper = styled('div')<TypographyWrapperProps>`
  text-decoration: ${({ type, theme }) =>
    type === SharedButtonStatus.removed ? `line-through 1px ${theme.colors.neutral[50]}` : 'none'};
`;

const ButtonWrapper = styled('div')<TypographyWrapperProps>`
  cursor: ${({ type }) => (type === SharedButtonStatus.owner ? 'auto' : 'pointer')};
  :hover {
    text-decoration: ${({ type }) => (type === SharedButtonStatus.owner ? 'none' : 'underline')};
  }
`;

export const SharedUser = ({ item, setSharedUsers }: Props): JSX.Element => {
  const view = useRecoilValue(selectedViewPF);
  const shareView = useShareView();
  const unShareView = useUnShareView();

  const handleUnshare = async (emails: string[]) => {
    const success = await unShareView(view, emails);

    if (success) {
      const [unsharedEmail] = emails;
      setSharedUsers((prevSharedUsers) => {
        return prevSharedUsers.map((u) =>
          u.id === unsharedEmail ? { ...u, type: SharedButtonStatus.removed } : u
        );
      });
    }
  };

  const handleShare = async (emails: string[]) => {
    const success = await shareView(view, emails);

    if (success) {
      const [sharedEmail] = emails;
      setSharedUsers((prevSharedUsers) => {
        return prevSharedUsers.map((u) =>
          u.id === sharedEmail ? { ...u, type: SharedButtonStatus.shared } : u
        );
      });
    }
  };

  const handleAction = (item: TSharedUser) => {
    if (item.type === SharedButtonStatus.owner) return;
    if (item.type === SharedButtonStatus.shared) handleUnshare([item.id as string]);
    if (item.type === SharedButtonStatus.removed) handleShare([item.id as string]);
  };

  return (
    <Wrapper>
      <TypographyWrapper type={item.type}>
        <Typography
          variant='body2'
          color={
            item.type === SharedButtonStatus.removed ? theme.colors.neutral[50] : theme.colors.primary[100]
          }
        >
          {item.value}
        </Typography>
      </TypographyWrapper>
      <ButtonWrapper type={item.type} onClick={() => handleAction(item)}>
        <Typography
          variant='caption'
          color={item.type === SharedButtonStatus.owner ? theme.colors.neutral[50] : theme.colors.primary[60]}
        >
          {item.type}
        </Typography>
      </ButtonWrapper>
    </Wrapper>
  );
};
