import { IconButton, useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { styled } from '@mui/material/styles';

const StyledContainer = styled('div')`
  top: 0;
  right: 0;
  height: 100%;
`;

/**
 * Contains edit and/or delete buttons, positioned absolutely over the component with the className   .componentWithActionOnHover
 * Will be visible only on hover, when its display will be flex
 * Any additional positioning can be done by passing in a style object
 * A button will be shown only if the corresponding handler is passed in.
 */

export interface EditActionsProps {
  onEdit?: () => void;
  onDelete?: () => void;
  style?: CSSProperties;
}

export const EditActions: FC<EditActionsProps> = ({ onEdit, onDelete, style }) => {
  const { colors } = useTheme();
  return (
    <StyledContainer className='actionsContainer' style={style}>
      {onEdit && (
        <IconButton onClick={onEdit}>
          <EditOutlinedIcon htmlColor={colors.secondary[60]} fontSize='small' />
        </IconButton>
      )}
      {onDelete && (
        <IconButton onClick={onDelete}>
          <DeleteOutlineOutlinedIcon htmlColor={colors.secondary[60]} fontSize='small' />
        </IconButton>
      )}
    </StyledContainer>
  );
};
