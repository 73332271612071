import { styled } from '@mui/material/styles';
import { FC, PropsWithChildren, ReactNode } from 'react';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Typography, useTheme } from '@mui/material';
import { MaxHeaderHeightInRems } from '../../../../constants/styles';

const NonCardContentHeightInRems = 22 + MaxHeaderHeightInRems;
const BottomPaddingInRems = 2.5;

export const StyledMatchGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`;

export const StyledSyncGrid = styled('div')`
  display: grid;
  grid-template-columns: 2fr auto 3fr;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
`;

export const StyledColumnBox = styled('div')`
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(44, 46, 49, 0.1);
  border-radius: 4px;
  padding: 0.75rem;
`;

export const BorderedFormCard = styled('div')`
  padding: 1rem 1.5rem;
  background: #ffffff;
  border-left: 4px solid ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  backdrop-filter: blur(5px);
  border-radius: 4px;
`;

export const StyledBorderCard = styled(BorderedFormCard)`
  max-height: calc(100vh - ${NonCardContentHeightInRems + BottomPaddingInRems}rem);
  overflow-y: auto;
`;

export const StyledCard = styled('div')`
  background: #ffffff;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  padding: 1.5rem;
`;

const TitleContainer = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.5rem;
  align-items: center;
`;

interface ISyncFormCardProps extends PropsWithChildren {
  title: string;
  chipComponent?: ReactNode;
}
export const SyncFormCard: FC<ISyncFormCardProps> = ({ title, chipComponent, children }) => {
  const { colors } = useTheme();

  return (
    <StyledCard>
      <TitleContainer>
        <TableChartOutlinedIcon htmlColor={colors.tertiary[60]} />
        <Typography>{title}</Typography>
        {chipComponent}
      </TitleContainer>
      {children}
    </StyledCard>
  );
};
