import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Stack } from '@mui/material';
import { get, set } from 'lodash-es';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import { FormFieldWithLabelFactory } from '../../../../components/Form/FormFieldAndLabelFactory';
import { FundViewModel } from '../../../../schemas/FundViewModel.schema';
import { percentValue } from '../../../../util/math-utils';
import { IFormField } from '../../../../view-models/form.view-model';

function FieldStack({ children }: PropsWithChildren) {
  return (
    <Stack display='grid' gridTemplateColumns={'1fr auto 1fr'} gap={'0.1rem'} alignItems={'start'}>
      {children}
    </Stack>
  );
}

function NoLabelFieldHolder({ children }: PropsWithChildren) {
  return (
    <div>
      <span>&nbsp;</span> {/* placeholder for alignment */}
      {children}
    </div>
  );
}

interface ISyncedFieldsProps {
  percentageField: IFormField<unknown>;
  amountField: IFormField<unknown>;
}

export function SyncedFields({ percentageField, amountField }: ISyncedFieldsProps) {
  return (
    <FieldStack>
      <FormFieldWithLabelFactory formField={percentageField} />
      <SwapHorizIcon color='disabled' sx={{ mt: '1.75rem' }} />
      <NoLabelFieldHolder>
        <FormFieldWithLabelFactory formField={amountField} />
      </NoLabelFieldHolder>
    </FieldStack>
  );
}

function getDefaultCalculationConfigs(
  percentFieldPath: FieldPath<FundViewModel>,
  amountFieldPath: FieldPath<FundViewModel>
) {
  return [
    {
      fieldPath: percentFieldPath,
      calcFunc: (data: Partial<FundViewModel>) => {
        const amount = (get(data, amountFieldPath) as number) ?? 0;
        const totalAmount = data.committedCapital ?? 0;
        if (totalAmount === 0) {
          return null;
        }

        return percentValue(amount, totalAmount);
      },
      deps: new Set([amountFieldPath]),
    },
    {
      fieldPath: amountFieldPath,
      calcFunc: (data: Partial<FundViewModel>) => {
        const percentage = (get(data, percentFieldPath) as number) ?? 0;
        const baseAmount = data.committedCapital ?? 0;

        return (baseAmount * percentage) / 100;
      },
      deps: new Set(['committedCapital', percentFieldPath]),
    },
  ];
}

function getTotalCalculationConfigs(
  percentFieldPath: FieldPath<FundViewModel>,
  amountFieldPath: FieldPath<FundViewModel>
) {
  return [
    {
      fieldPath: percentFieldPath,
      calcFunc: (data: Partial<FundViewModel>, changedFieldPath?: FieldPath<FundViewModel>) => {
        if (changedFieldPath?.toLowerCase().includes('percentage')) {
          return (
            (data.initialInvestmentAllocationPercentage ?? 0) +
            (data.feesAndExpensesAllocationPercentage ?? 0) +
            (data.reservesAllocationPercentage ?? 0)
          );
        } else {
          const totalAllocationAmount =
            (data?.initialInvestmentAllocationAmount ?? 0) +
            (data?.feesAndExpensesAllocationAmount ?? 0) +
            (data?.reservesAllocationAmount ?? 0);
          return percentValue(totalAllocationAmount, data.committedCapital ?? 0);
        }
      },
      deps: new Set([
        'initialInvestmentAllocationAmount',
        'initialInvestmentAllocationPercentage',
        'feesAndExpensesAllocationAmount',
        'feesAndExpensesAllocationPercentage',
        'reservesAllocationAmount',
        'reservesAllocationPercentage',
      ]),
    },
    {
      fieldPath: amountFieldPath,
      calcFunc: (data: Partial<FundViewModel>, changedFieldPath?: FieldPath<FundViewModel>) => {
        if (changedFieldPath?.toLowerCase().includes('amount')) {
          return (
            (data?.initialInvestmentAllocationAmount ?? 0) +
            (data?.feesAndExpensesAllocationAmount ?? 0) +
            (data?.reservesAllocationAmount ?? 0)
          );
        } else {
          const totalAllocationPercentage =
            (data?.initialInvestmentAllocationPercentage ?? 0) +
            (data?.feesAndExpensesAllocationPercentage ?? 0) +
            (data?.reservesAllocationPercentage ?? 0);
          return (data.committedCapital ?? 0) * (totalAllocationPercentage / 100);
        }
      },
      deps: new Set([
        'committedCapital',
        'initialInvestmentAllocationAmount',
        'initialInvestmentAllocationPercentage',
        'feesAndExpensesAllocationAmount',
        'feesAndExpensesAllocationPercentage',
        'reservesAllocationAmount',
        'reservesAllocationPercentage',
      ]),
    },
  ];
}

export function useSyncAllocationFields() {
  const { watch, reset, setValue } = useFormContext();
  const calculationConfigs = useMemo(() => {
    return [
      ...getDefaultCalculationConfigs(
        'initialInvestmentAllocationPercentage',
        'initialInvestmentAllocationAmount'
      ),
      ...getDefaultCalculationConfigs('reservesAllocationPercentage', 'reservesAllocationAmount'),
      ...getDefaultCalculationConfigs(
        'feesAndExpensesAllocationPercentage',
        'feesAndExpensesAllocationAmount'
      ),
      ...getTotalCalculationConfigs(
        '_viewModel.totalAllocationPercentage',
        '_viewModel.totalAllocationAmount'
      ),
      ...getDefaultCalculationConfigs('recyclingLimitPercentage', 'recyclingLimitAmount'),
    ];
  }, []);

  useEffect(() => {
    const subscription = watch((currentValues, { name, type }) => {
      if (type !== 'change') return;

      const changeField = name as FieldPath<FundViewModel>;
      const newValues = { ...currentValues };

      calculationConfigs.forEach((conf) => {
        if (conf.deps.has(changeField)) {
          const newFieldValue = conf.calcFunc(currentValues, name as FieldPath<FundViewModel>);
          set(newValues, conf.fieldPath, newFieldValue);
          setValue(conf.fieldPath, newFieldValue, { shouldValidate: false });
        }
      });

      reset(newValues);
    });
    return () => subscription.unsubscribe();
  }, [calculationConfigs, reset, setValue, watch]);
}
