import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Typography, useTheme } from '@mui/material';
import {
  IKPIRequestDataModel,
  KPIRequestFrequency,
} from '../../../../../data-models/kpi-requests.data-model';
import { ConfirmDeletionDialog } from '../../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { companyState } from '../../../../../services/state/CompanyState';
import { useKPIRequestsNotSentActions } from '../hooks/useKPIRequestsNotSentActions';
import { ROUTES } from '../../../../../constants/RouteNameMapping';

interface IConfirmDeleteRequestProps {
  open: boolean;
  onClose: () => void;
  request: IKPIRequestDataModel;
}
export function ConfirmDeleteRequest({ open, onClose, request }: IConfirmDeleteRequestProps) {
  const { deleteKPIRequestAction } = useKPIRequestsNotSentActions();
  const [loading, setLoading] = useState(false);
  const company = useRecoilValue(companyState(request.companyId));
  const { colors } = useTheme();
  const archivedUrl = `/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}/${ROUTES.KPI_REQUESTS_ARCHIVED}`;

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await deleteKPIRequestAction(request.id, archivedUrl);
    setLoading(false);
    onClose();
  }, [archivedUrl, deleteKPIRequestAction, onClose, request.id]);

  return open ? (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title={`Delete ${RequestFrequencyDict[request.frequency]} request for ${company?.name}?`}
      loading={loading}
    >
      <Typography variant='body2' color={colors.neutral[60]}>
        The respondent will not be able to complete the deleted request
      </Typography>
    </ConfirmDeletionDialog>
  ) : (
    <div style={{ height: '1rem' }} />
  );
}

export const RequestFrequencyDict: Record<KPIRequestFrequency, string> = {
  [KPIRequestFrequency.Monthly]: 'monthly',
  [KPIRequestFrequency.Quarterly]: 'quarterly',
  [KPIRequestFrequency.Annual]: 'annual',
  [KPIRequestFrequency.None]: '',
};
