import { SxProps, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { DatePicker } from '../DatePicker/DatePicker';
import { Icon, IconTypes } from '../Icon';

interface Props {
  minDate?: Date | undefined | null;
  maxDate?: Date;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  placeholder?: string;
  padding?: string;
  height?: string;
  onBlur?: () => void;
  style?: CSSProperties;
  sx?: SxProps<Theme>;
  formatDate?: boolean;
}

const Wrapper = styled('div')<{ padding?: string; height?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : '0.3rem 9px 0.3rem 12px')};
  ${({ height }) => (height ? `height:${height};` : '')};
  border: 1px solid #b6bbc4;
  border-radius: 4px;
  width: 100%;

  input {
    background-color: rgba(0, 0, 0, 0);
  }

  & > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

const IconWrapper = styled('div')<{ disabled: boolean }>`
  position: relative;
  margin-bottom: -4px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'cursor')};
`;

const DatePickerWrapper = styled('div')`
  display: flex;
  position: relative;
`;

const StyledInput = styled('input')`
  &::placeholder,
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral[30]};
    font-weight: 200;
  }
  outline: none;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export function DatePickerInput({
  minDate = new Date(),
  maxDate,
  value,
  onChange,
  disabled = false,
  placeholder = '',
  padding,
  height,
  onBlur,
  style,
  sx,
  formatDate = true,
}: Props) {
  const [datePickerModal, setDatePickerModal] = useState(false);
  const { neutral } = useTheme().colors;
  const datePickerRef = useRef(null);
  useOutsideClick(datePickerRef, () => setDatePickerModal(false));
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value?.toLocaleDateString());

  const dayMapping: Record<string, string> = useMemo(
    () => ({
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    }),
    []
  );

  const maxDateMapped = useMemo(() => {
    if (maxDate) return maxDate;
    const today = new Date();
    today.setFullYear(today.getFullYear() + 19);
    return today;
  }, [maxDate]);

  const handleEditChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEditValue(value);
  };

  const isValidDate = (date: unknown) => {
    return date instanceof Date && !isNaN(Number(date));
  };

  const handleBlur = () => {
    setIsEditing(false);
    const dateVal = new Date(String(editValue));
    if (!isValidDate(dateVal)) setEditValue('');
    onChange(isValidDate(dateVal) ? dateVal : null);
  };

  const formatDateVal = useMemo(() => {
    if (!value) return '';

    const today = new Date().toDateString();
    const dateVal = value?.toDateString();

    const dateValArr = value?.toDateString().split(' ');
    dateValArr?.splice(0, 1, dayMapping[dateValArr[0]]);

    if (!dateValArr?.length) return '';

    const [day, month, date, year] = dateValArr;
    const isToday = today === dateVal;

    return `${day}, ${month} ${date}, ${year} ${isToday ? '(Today)' : ''}`;
  }, [dayMapping, value]);

  useEffect(() => {
    if (isValidDate(value)) setEditValue(value?.toLocaleDateString());
  }, [value]);

  return (
    <Wrapper
      ref={datePickerRef}
      padding={padding}
      height={height}
      onBlur={() => onBlur?.()}
      style={style}
      sx={sx}
    >
      <StyledInput
        type={'text'}
        value={isEditing || !formatDate ? editValue : formatDateVal}
        onFocus={() => setIsEditing(true)}
        onBlur={handleBlur}
        onChange={handleEditChange}
        disabled={disabled}
        placeholder={placeholder}
        sx={{ fontSize: '14px' }}
      />
      <DatePickerWrapper>
        <IconWrapper
          disabled={disabled}
          onClick={() => {
            if (!disabled) setDatePickerModal((prevState) => !prevState);
          }}
        >
          <Icon type={IconTypes.CALENDAR} color={neutral[60]} disablePointer={disabled} />
        </IconWrapper>
        {datePickerModal && (
          <DatePicker
            minDate={minDate}
            maxDate={maxDateMapped}
            selectedDate={value}
            setSelectedDate={onChange}
            handleApply={() => setDatePickerModal(false)}
          />
        )}
      </DatePickerWrapper>
    </Wrapper>
  );
}
