import { Stack, SxProps, Typography } from '@mui/material';
import { IBaseFieldProps } from '../Display/types';
import { IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { SelectType } from '../Display/FieldSelect';
import { ColorStatus } from './ColorStatus';

const fieldWidth = '5.75rem';
export const InlineColorStatusSx: SxProps = {
  '& .MuiSelect': {
    height: '2.5rem',
    width: fieldWidth,
  },
  '& .MuiSelect-select~fieldset': { marginLeft: '0' },
  '&&&&& .MuiSelect-icon': {
    visibility: 'visible',
  },
};

export function ColorStatusWithLabel(props: IBaseFieldProps<IFormFieldSelectMeta<SelectType | string>>) {
  const label = props.formField.label;
  return (
    <Stack
      display={'grid'}
      gridTemplateColumns={`${fieldWidth} 1fr`}
      alignItems={'center'}
      justifyContent={'start'}
      gap={'0.5rem'}
      sx={InlineColorStatusSx}
    >
      <ColorStatus {...props} />
      <Typography variant='body2' color={'text.primary'} justifySelf={'start'}>
        {label}
      </Typography>
    </Stack>
  );
}
