import { ExpandMore } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  TextField,
  PopperProps,
  AutocompleteRenderOptionState,
  ChipProps,
  SxProps,
  FilterOptionsState,
  useTheme,
} from '@mui/material';
import { FC, SyntheticEvent, ReactNode, JSXElementConstructor, HTMLAttributes, CSSProperties } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import styled from '@mui/material/styles/styled';
import { Option } from '../MuiSingleSelect/MuiSingleSelect';

export interface MultiselectProps {
  options: Option[];
  value: Option[];
  onChange: (e: SyntheticEvent, value: Option[] | undefined, reason: string) => void;
  width?: string;
  fullWidth?: boolean;
  limitTags?: number;
  style?: CSSProperties;
  renderTags?: (values: Option[]) => JSX.Element;
  fieldPlaceholder?: string;
  fieldName?: string; // for Formik
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disablePortal?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  getLimitTagsText?: (more: number) => ReactNode;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  openOnFocus?: boolean;
  autofocus?: boolean;
  autocomplete?: boolean;
  componentsProps?: { clearIndicator?: object; paper?: object; popper?: object; popupIndicator?: object };
  ChipProps?: ChipProps;
  sx?: SxProps;
  isOptionEqualToValue?: (option: Option, value: Option) => boolean;
  ListboxProps?: HTMLAttributes<HTMLUListElement>;
  inputValue?: string;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
  autoSelect?: boolean;
  disableClearable?: boolean;
  filterOptions?: (options: Option[], state: FilterOptionsState<Option>) => Option[];
  onKeyDown?: (e: React.KeyboardEvent) => void;
  alwaysShowPlaceholder?: boolean;
  textFieldSx?: SxProps;
}

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 999999 !important;
  }

  &&& .MuiInputBase-root.Mui-focused {
    z-index: 1000;
  }
`;

export const MuiMultiselect: FC<MultiselectProps> = (props) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  const {
    fieldPlaceholder,
    alwaysShowPlaceholder: showPlaceholder,
    fieldName,
    sx,
    textFieldSx,
    inputVariant = 'outlined',
    autofocus = false,
    ...rest
  } = props;
  const { colors } = useTheme();

  return (
    <StyleContainer>
      <Autocomplete
        autoHighlight
        getOptionLabel={function (option): string {
          return option?.value ?? '';
        }}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            autoFocus={autofocus}
            {...params}
            fullWidth
            variant={inputVariant}
            name={fieldName}
            placeholder={props.value?.length && !showPlaceholder ? '' : fieldPlaceholder}
            onKeyDown={props.onKeyDown}
            sx={textFieldSx}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} style={{ fontFamily: 'Wotfard-Regular', fontSize: '0.875rem' }}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option?.value ?? ''}
            </li>
          );
        }}
        disablePortal
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        popupIcon={<ExpandMore />}
        multiple
        ChipProps={props.ChipProps ?? { size: 'small', sx: { margin: 0 } }}
        sx={{
          "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
            visibility: 'visible',
            color: colors.neutral[40],
          },
          '& .MuiAutocomplete-tag': {
            maxHeight: '21px',
          },
          ...sx,
        }}
        {...rest}
      />
    </StyleContainer>
  );
};
