import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, SyntheticEvent, useCallback } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import Comments from '../../../assets/icons/comments-2.svg?react';
import { Option } from '../../../components/MuiSingleSelect/MuiSingleSelect';
import {
  searchTermState,
  selectedDealLeadState,
  selectedSectorsState,
  showCommentsState,
} from '../state/DealboardUIState';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';
import { DealLeadFilter } from './DealLeadFilter';
import { FundsFilter } from './FundsFilter';
import { RoundFilter } from './RoundFilter';
import { SectorFilter } from './SectorFilter';

const InnerContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
`;

const Container = styled('section')`
  margin-top: 1rem;
  padding: 2rem 1.4rem 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  gap: 1rem;

  .customSvg {
    stroke: ${({ theme }) => theme.colors.neutral[50]};
    * {
      stroke: ${({ theme }) => theme.colors.neutral[50]};
    }
    &.active {
      stroke: ${({ theme }) => theme.colors.secondary[60]};
      * {
        stroke: ${({ theme }) => theme.colors.secondary[60]};
      }
    }
  }
`;

const SwitchWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const FilterOptions: FC = () => {
  const setSearchTerm = useSetRecoilState(searchTermState);
  const [showComments, setShowComments] = useRecoilState(showCommentsState);
  const [selectedSectors, setSelectedSectors] = useRecoilState(selectedSectorsState);
  const [selectedDealLead, setSelectedDealLead] = useRecoilState(selectedDealLeadState);
  const resetSelectedSectors = useResetRecoilState(selectedSectorsState);

  const handleSectorChange = useCallback(
    (e: SyntheticEvent, options: Option[] | undefined) => {
      if (!options) return;
      if (options.length === 0) {
        resetSelectedSectors();
        return;
      }
      setSelectedSectors(options.map((opt) => opt.id as number));
    },
    [resetSelectedSectors, setSelectedSectors]
  );

  const handleDealLeadOnChange = useCallback(
    (newValue: number | null) => {
      setSelectedDealLead(newValue);
    },
    [setSelectedDealLead]
  );

  return (
    <Container>
      <InnerContainer>
        <SearchInput
          onChange={(newVal) => setSearchTerm(newVal)}
          placeholder='Search for a deal'
          sx={{ marginTop: '0.5rem' }}
        />

        <SectorFilter selectedSectors={selectedSectors} onChange={handleSectorChange} />
        <RoundFilter />
        <FundsFilter />
        <DealLeadFilter onChange={handleDealLeadOnChange} selectedDealLead={selectedDealLead} />
      </InnerContainer>
      <SwitchWrapper>
        <Switch
          aria-label={'toggle comments'}
          checked={showComments}
          onChange={() => setShowComments((prevState) => !prevState)}
        />
        <Comments className={showComments ? 'customSvg active' : 'customSvg'} />
      </SwitchWrapper>
    </Container>
  );
};
