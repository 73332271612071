import { useSearchParams } from 'react-router-dom';

export const FullScreenParam = 'fullScreen';

export function useFullScreen() {
  const search = useSearchParams()[0];
  const isFullScreenMode = search.get(FullScreenParam) === 'true';

  return { isFullScreenMode };
}
