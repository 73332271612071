import { Column } from 'react-table';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { TransactionHistoryTableData } from '../../../types';
import { formatNumber } from '../utils/formattingFunctions';
import { ICompanyMetricsDataModel } from '../../../data-models/metrics.data-model';
import { companyState } from '../../../services/state/CompanyState';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { CompanyHeading } from './CompanyHeading';
import { TransactionHistoryTable } from './TransactionHistoryTable';

const StyledSection = styled('section')`
  max-width: fit-content;
  margin: 1.6rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 0.8rem;
`;

const InfoEntry = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`;

type MainTableProps = {
  columns: Column<TransactionHistoryTableData>[];
  activeTransaction: ITransactionDataModel;
  companyMetrics: ICompanyMetricsDataModel | null;
};

export const MainTableSection = ({ activeTransaction, columns, companyMetrics }: MainTableProps) => {
  const { colors } = useTheme();

  // data should be an array of length == 1, including only the active transaction
  const data = [activeTransaction];
  const company = useRecoilValue(companyState(activeTransaction.companyId));

  if (!columns.length) return null;

  return (
    <>
      {company ? <CompanyHeading name={company.name} logoUrl={company.logoUrl ?? ''} /> : null}
      {companyMetrics && (
        <StyledSection>
          <InfoEntry>
            <Typography variant='subtitle2' color={colors.primary[100]}>
              Total Invested:
            </Typography>
            <Typography variant='subtitle2' color={colors.primary[100]}>
              $ {formatNumber(companyMetrics.amountInvested)}
            </Typography>
          </InfoEntry>
          {companyMetrics.impliedEquityValue ? (
            <InfoEntry>
              <Typography variant='subtitle2' color={colors.primary[100]}>
                Implied Equity Value:
              </Typography>
              <Typography variant='subtitle2' color={colors.primary[100]}>
                $ {formatNumber(companyMetrics.impliedEquityValue)}
              </Typography>
            </InfoEntry>
          ) : null}
          <InfoEntry>
            <Typography variant='subtitle2' color={colors.primary[100]}>
              FMV:
            </Typography>
            <Typography variant='subtitle2' color={colors.primary[100]}>
              $ {formatNumber(companyMetrics.fmv)}
            </Typography>
          </InfoEntry>
          {companyMetrics.ownerShipPercentage ? (
            <InfoEntry>
              <Typography variant='subtitle2' color={colors.primary[100]}>
                Ownership Percentage:
              </Typography>
              <Typography variant='subtitle2' color={colors.primary[100]}>
                {companyMetrics.ownerShipPercentage}%
              </Typography>
            </InfoEntry>
          ) : null}
        </StyledSection>
      )}
      <TransactionHistoryTable data={data} columns={columns} />
    </>
  );
};
