import { merge } from 'lodash-es';
import { ExcelStyle } from 'ag-grid-community';
import { GetterFormatType } from '../util/getFormatter';
import { StringTransform } from '../util/formatters/StringFormatters';
import { hashObject } from '../util/object-util';

export interface IFormatterDataModel<T> {
  config?: T;
  extends?: string;
  id: string; // This can be a primitive (number, boolean, etc...) or a custom type (dealStage, etc...)
  type?: GetterFormatType | string; // Primitive type, matches typescript type e.g. number, string, stringArray etc...
  excelStyle?: Omit<ExcelStyle, 'id'>;
}

export interface IBooleanFormatConfig {
  falseValue: string;
  noValue: string;
  trueValue: string;
}

export interface ICustomNumberFormatConfig extends Intl.NumberFormatOptions {
  invalidValue?: string;
  nullValue?: string;
  showNegativeAsZero?: boolean;
  suffix?: string;
  undefinedValue?: string;
}

export interface IStringFormatConfig {
  transforms?: Array<keyof typeof StringTransform>;
}

export function createFormatterDataModel<T>(
  overrides: Partial<IFormatterDataModel<T>> = {}
): IFormatterDataModel<T> {
  return merge(
    {
      config: {} as T,
      extends: '',
      id: 'string',
      type: 'string',
    },
    overrides
  );
}

export function createUSDFormatterDataModel(maxDecimals = 2, minDecimals = maxDecimals) {
  const formatterConfig: ICustomNumberFormatConfig = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
  };

  return {
    id: hashObject(formatterConfig), // FIXME MAGGIE-6628 - we won't need to pass id
    type: 'number',
    config: formatterConfig,
  };
}

export function createMultiplierFormatterDataModel(maxDecimals = 2, minDecimals = maxDecimals) {
  const formatterConfig: ICustomNumberFormatConfig = {
    suffix: 'x',
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
  };

  return {
    id: hashObject(formatterConfig), // FIXME MAGGIE-6628 - we won't need to pass id
    type: 'number',
    config: formatterConfig,
  };
}
