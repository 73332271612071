import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { Stack } from '@mui/material';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { primaryOrDefaultCaptableState } from '../../CapTable/CapTableDataState';
import { HelpMessage } from '../../components/Messages/HelpMessage';
import { AllScenarios } from './AllScenarios';

export const ScenariosWrapper: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);

  const captable = useRecoilValue(primaryOrDefaultCaptableState(companyId));

  if (!captable) {
    return (
      <Stack mt='2rem'>
        <HelpMessage severity='info' message='Cap table not available for company' />
      </Stack>
    );
  } else if (!captable.captableInvestments?.length) {
    return (
      <Stack mt='2rem'>
        <HelpMessage severity='info' message='Shareholder details are not available for this company' />
      </Stack>
    );
  } else if (captable.fullyDilutedShares === 0) {
    return (
      <Stack mt='2rem'>
        <HelpMessage severity='info' message='Cap Table error - Total FDS for a share class is zero' />
      </Stack>
    );
  }

  return <AllScenarios />;
};
