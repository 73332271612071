import { useCallback } from 'react';
import { simpleMultilineTextField } from '../../../data-fields/CommonFields';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { useGetAmountField } from './TransactionFormUtils';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useFundCapitalCallFields() {
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const getCommonInitialFields = useFundDateCurrencyFields();

  return useCallback(
    () => [
      ...getCommonInitialFields(),
      getAmountField({ key: 'investmentAmount', label: 'Capital Call Amount', required: true }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ],
    [getAmountField, getCommonInitialFields]
  );
}
