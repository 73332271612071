import { useMemo } from 'react';
import { ColDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { reconConfigMapState } from '../../state/FinanceState';
import { MetricsReconDataModel, MetricsReconSchema } from '../../../../schemas/MetricsRecon.schema';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { FMT, StandardFormatterId } from '../../../../util/formatter-service';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { CompanyTransactionsLink } from '../../common-grid-defs/CompanyTransactionsLink';

export function useMetricsReconColDefs() {
  const reconConfigMap = useRecoilValue(reconConfigMapState);
  const schema = useMemo(() => MetricsReconSchema.gridSchema(), []);

  const schemaToColDefs = useSchemaToGrid();

  return useMemo(() => {
    const defs = schemaToColDefs(schema, [
      'companyId',
      'fundId',
      'foresightValue',
      'soiValue',
    ]) as ColDef<MetricsReconDataModel>[];

    const commonOverrides: ColDef<MetricsReconDataModel> = {
      valueFormatter: (params: ValueFormatterParams<MetricsReconDataModel>) => {
        const { data, value } = params;
        if (!data?.reconConfigId || !value) return '';
        return FMT.format(
          (reconConfigMap.get(data.reconConfigId)?.formatter as StandardFormatterId) ?? 'numeric',
          value
        );
      },
      sortable: false,
    };

    const overrides: Record<string, ColDef<MetricsReconDataModel>> = {
      ['foresightValue']: { ...commonOverrides },
      ['soiValue']: {
        ...commonOverrides,
      },
    };

    const result = withOverrides(defs, overrides);
    result.unshift({
      headerName: 'Field',
      valueGetter: (params: ValueGetterParams<MetricsReconDataModel>) => {
        const { data } = params;
        if (!data?.reconConfigId) return '';
        return reconConfigMap.get(data.reconConfigId)?.displayName ?? 'Unknown field';
      },
    });
    result.push({
      headerName: '',
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.company;
      },
      cellRenderer: CompanyTransactionsLink,
      maxWidth: 70,
      flex: 0,
      suppressMenu: true,
      pinned: 'right',
      resizable: false,
    });
    return result as ColDef<MetricsReconDataModel>[];
  }, [schemaToColDefs, schema, reconConfigMap]);
}
