import { FC } from 'react';
import { Typography } from '@mui/material';
import { DashboardTables } from './DashboardData/DashboardTables';

export const FinanceDashboard: FC = () => {
  return (
    <Typography component={'section'} color='text.primary' mt='1rem'>
      <Typography variant='body2'>
        Here you can find all the financial information regarding specific companies
      </Typography>
      <DashboardTables />
    </Typography>
  );
};
