import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { ReactElement, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';
import { LoadingBar } from '../../LoadingBar/LoadingBar';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { PortfolioDatePicker } from '../../../pages/PortfolioOverview/components/ViewNavigation/components/PortfolioDatePicker';
import {
  ContainerName,
  Breakpoint,
  MaxHeaderHeightInRems,
  MaxNav2HeaderHeight,
} from '../../../constants/styles';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { FundFilterPF2 } from '../Navbar/Navigation/PortfolioReporting/FundNavigation/FundFilterPF2';
import { selectedViewIdPF, selectedViewPF } from '../../../pages/PortfolioOverview/state/ViewState';
import { ViewTypeByData } from '../../../data-models/view-config.data-model';
import { colors } from '../../../theme/colors';
import { Breadcrumb as Breadcrumbs } from './Breadcrumbs';
import { useBreadCrumbs } from './useBreadCrumbs';

const HeaderContainer = css`
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.75rem;
  justify-content: end;
  padding-inline: 1.5rem;
  align-items: center;
  width: 100%;
  background: ${colors.primary[0]};
  height: ${MaxNav2HeaderHeight};
  max-height: ${MaxNav2HeaderHeight};
`;

const Wrapper = styled('header')`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  grid-auto-columns: auto 1fr auto auto;
  @container ${ContainerName.headerContainer} (max-width: ${Breakpoint.hideBreadcrumbs}) {
    &.portfolio {
      grid-auto-columns: 1fr auto auto;
      .MuiBreadcrumbs-root {
        display: none;
      }
    }
    column-gap: 0.5rem;
  }
  align-items: center;
  justify-content: stretch;
  padding: 0.5rem 1.5rem;
  min-height: 65px;
  width: 100%;
  background: #fafbfe;
  max-height: ${MaxHeaderHeightInRems}rem;

  header {
    width: 100%;
  }
`;

const LoadingBarWrapper = styled('div')`
  position: sticky;
  top: 0;
`;

interface IHeaderProps {
  children?: ReactNode | null;
}

export function Header({ children = null }: IHeaderProps): ReactElement {
  const breadCrumbs = useBreadCrumbs();
  const { pathname } = useLocation();
  const isPortfolio = useMemo(() => pathname.startsWith(`/${ROUTES.PORTFOLIO}`), [pathname]);
  const viewType = useRecoilValue(selectedViewPF)?.viewType;
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();
  const currentView = useRecoilValue(selectedViewIdPF);

  return (
    <>
      {showElasticSearchResults ? (
        <header css={HeaderContainer}>{children}</header>
      ) : (
        <Wrapper data-testid={'header'} className={isPortfolio ? 'portfolio' : ''}>
          <Breadcrumbs breadcrumbs={breadCrumbs} />
          {children}
          {isPortfolio && <PortfolioDatePicker key={currentView} />}
          {isPortfolio && viewType !== ViewTypeByData.people && viewType !== ViewTypeByData.security && (
            <FundFilterPF2 />
          )}
        </Wrapper>
      )}
      <LoadingBarWrapper>
        <LoadingBar />
      </LoadingBarWrapper>
    </>
  );
}
