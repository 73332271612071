import { Alert, List, Paper, styled } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { activeCompanyListIdState, companyListState } from '../state/ListsState';
import { filteredAndSortedListsState } from '../Companies/state/FiltersState';
import { ListItemDetailsPanel } from './ListItemDetailsPanel';
import { MemoizedListCard } from './ListCard';

const CompanyListContainer = styled('div')`
  display: grid;
  grid-column-gap: 0.5rem;
  grid-template-columns: 300px 1fr;
  width: 100%;
  overflow: hidden;
`;

const RightSection = styled('div')`
  display: grid;
  grid-row-gap: 0.5rem;
  overflow: auto;
`;

// TODO maybe returns selctedIndex to here
export function CompanyList2({
  setSelectedIndex,
  selectedIndex,
}: {
  setSelectedIndex: (index: number) => void;
  selectedIndex: number;
}) {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const listItems = useRecoilValue(companyListState(activeListId))?.items;
  const sortedList = useRecoilValue(filteredAndSortedListsState(activeListId ?? -1));

  useEffect(() => {
    setSelectedIndex(0);
  }, [setSelectedIndex, sortedList?.length]);

  const handleListItemClick = useCallback(
    (newIndex: number) => {
      setSelectedIndex(newIndex);
    },
    [setSelectedIndex]
  );

  const gotoNext = useCallback(() => {
    // const nextIndex = (selectedIndex + 1) % (sortedList?.length ?? 0);
    // setSelectedIndex(nextIndex);
    // Do nothing for now
  }, []);

  if (!listItems?.length) {
    return (
      <Alert sx={{ mt: 3, mb: 1 }} severity={'info'}>
        The list is empty.
      </Alert>
    );
  }

  if (!sortedList || !sortedList[selectedIndex]) return null;

  return (
    <CompanyListContainer>
      <Paper elevation={0} sx={{ overflow: 'auto', background: 'transparent', padding: 0 }}>
        <List dense sx={{ background: 'transparent', padding: 0, margin: 0 }}>
          {sortedList.map((item, i) => {
            return (
              <motion.div key={item.id} layout transition={{ duration: 0.5 }}>
                <MemoizedListCard
                  selected={selectedIndex === i}
                  item={item}
                  onClick={() => handleListItemClick(i)}
                />
              </motion.div>
            );
          })}
        </List>
      </Paper>
      <RightSection>
        <Paper elevation={0} variant={'outlined'}>
          <ListItemDetailsPanel gotoNext={gotoNext} item={sortedList[selectedIndex]} />
        </Paper>
      </RightSection>
    </CompanyListContainer>
  );
}
