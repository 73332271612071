import { FC, MouseEvent } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ciView } from './SingleCompanyCI';

const ToggleContainer = styled('div')`
  display: grid;
  gap: 1.5rem;
`;

const OptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const AddCompaniesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  & .MuiTypography-root {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

interface SingleCompanyCiActionsProps {
  view: ciView;
  handleToggleView: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, view: ciView) => void;
  onAddCompaniesClick: () => void;
}

export const SingleCompanyCiActions: FC<SingleCompanyCiActionsProps> = ({
  view,
  handleToggleView,
  onAddCompaniesClick,
}) => {
  const { colors } = useTheme();

  return (
    <OptionsContainer>
      <ToggleContainer>
        <Typography variant='h6'>Company CI Analysis</Typography>
        <ToggleButtonGroup
          color='secondary'
          exclusive
          aria-label='View'
          value={view}
          onChange={handleToggleView}
        >
          <ToggleButton value='charts' sx={{ textTransform: 'capitalize' }}>
            Charts
          </ToggleButton>
          <ToggleButton value='metrics' sx={{ textTransform: 'capitalize' }}>
            Metrics
          </ToggleButton>
        </ToggleButtonGroup>
      </ToggleContainer>
      <AddCompaniesContainer>
        <Typography variant='caption' color={colors.neutral[50]}>
          <InfoOutlinedIcon />
          Launch multiple companies comparison
        </Typography>
        <Button
          variant='contained'
          color='secondary'
          size='medium'
          onClick={onAddCompaniesClick}
          aria-label='add-companies-dialog'
        >
          Add Companies
        </Button>
      </AddCompaniesContainer>
    </OptionsContainer>
  );
};
