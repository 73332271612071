import { atom } from 'recoil';
import { SelectItem } from '../../../types';

export const securitiesFundFilterState = atom<SelectItem[] | null>({
  key: 'securitiesFundFilterState',
  default: null,
});

export const securitiesRealizedFilterState = atom<SelectItem[] | null>({
  key: 'securitiesRealizedFilterState',
  default: null,
});
