import * as yup from 'yup';
import { VALID_URL_REGEX } from '../../../../util/regex';
import { ReasonForChange } from '../reasons-for-change';

export const primaryWebsiteSchema = yup.object().shape({
  primary: yup.string().required().matches(VALID_URL_REGEX, 'Website must be a valid URL'),
  reason: yup
    .string()
    .required()
    .matches(new RegExp(`^(${ReasonForChange.rebrand}|${ReasonForChange.incorrectMatching})$`)),
});
