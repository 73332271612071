import { FC, useCallback, useMemo, useState } from 'react';
import {
  ButtonProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface IMenuItem {
  name: string;
  icon?: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  hide?: boolean;
  divider?: boolean;
}
interface MenuButtonProps {
  buttonComponent?: (props: ButtonProps) => JSX.Element;
  menuItems: IMenuItem[];
  label?: string;
  menuProps?: Omit<MenuProps, 'open'>;
  disabled?: boolean;
}

export const MenuButton: FC<MenuButtonProps> = ({
  menuItems,
  label,
  buttonComponent,
  menuProps,
  disabled,
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const open = Boolean(anchor);

  const handleItemClick = useCallback((item: IMenuItem) => {
    setAnchor(null);
    item.onClick();
  }, []);

  const button = useMemo(() => {
    if (buttonComponent) {
      return buttonComponent({ onClick: (e) => setAnchor(e.currentTarget), title: label });
    }

    return (
      <IconButton onClick={(e) => setAnchor(e.currentTarget)} title={label} disabled={disabled}>
        <MoreVertIcon color={disabled ? 'disabled' : 'secondary'} />
      </IconButton>
    );
  }, [buttonComponent, disabled, label]);

  return (
    <>
      {button}
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...menuProps}
      >
        {open &&
          menuItems.map((item) => {
            if (item.hide) return null;
            return (
              <MenuItem
                key={item.name}
                onClick={() => handleItemClick(item)}
                disabled={item.disabled}
                divider={item.divider}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};
