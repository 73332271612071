import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

type FieldGroupProps = {
  children: ReactNode;
  button?: ReactElement;
  hasBorder?: boolean;
  label?: string;
  space?: string;
  labelMargin?: string;
};

type WrapperProps = {
  hasBorder: boolean;
  space?: string;
};

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  ${({ hasBorder }) => (hasBorder ? 'border-top: 1px solid #d2d5db;' : '')}
  padding: ${({ space }) => (space ? space : '2rem')} 0;
  gap: 5px;
`;

const LabelWrapper = styled('div')<{ margin?: string }>`
  margin-bottom: ${({ margin }) => (margin ? margin : '16px')};
  display: flex;
  align-items: center;
`;

export function FieldGroup({
  children,
  hasBorder = true,
  label,
  space,
  button,
  labelMargin,
}: FieldGroupProps) {
  const { colors } = useTheme();
  return (
    <Wrapper data-testid={'field-group'} hasBorder={hasBorder} space={space}>
      {label && (
        <LabelWrapper margin={labelMargin}>
          <Typography variant='subtitle2' color={colors.primary[100]}>
            {label}
          </Typography>
          {button && button}
        </LabelWrapper>
      )}
      {children}
    </Wrapper>
  );
}
