import { useRecoilCallback } from 'recoil';
import { IMergeCompanyParams, mergeCompanies } from '../queries/MaggieCompanyQueries';
import { getErrorMessage } from '../queryHelpers';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { selectedMappingsCompaniesIds } from '../../pages/Mappings/state/MappingsState';
import { getProviderFields } from '../queries/MappingsQueries';
import { fieldsMappingByCompanyState } from '../state/CompanyProvidersState';
import { ID_DELIMITER, MAPPING_COMPANIES_PARAM } from '../../pages/Mappings/hooks/useSyncMappingsFromPath';
import { useSetSearchParams } from '../../hooks/useSetSearchParams';
import { companyState } from '../state/CompanyState';

export function useMergeCompanies() {
  const { pushErrorToast } = useToastMessageState();
  const setSearchParams = useSetSearchParams();

  const handleMergeCompanies = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ id, intoId }: IMergeCompanyParams) => {
        try {
          await mergeCompanies({ id, intoId });
          // refresh provider data for merged company
          const providerData = await getProviderFields<unknown>(intoId);

          const selectedCompanyIds = snapshot.getLoadable(selectedMappingsCompaniesIds).valueMaybe() ?? [];

          const updatedSnapshot = snapshot.map((mutable) => {
            mutable.set(fieldsMappingByCompanyState(intoId), providerData);
            mutable.set(companyState(id), null); // this company will be deleted and merged into the other one
          });
          gotoSnapshot(updatedSnapshot);

          const filteredSelectedCompanies = selectedCompanyIds
            .filter((companyId) => companyId !== id)
            .join(ID_DELIMITER);
          setSearchParams({
            [MAPPING_COMPANIES_PARAM]: filteredSelectedCompanies,
          });
        } catch (err) {
          const message = getErrorMessage(err, 'Error merging companies');
          pushErrorToast({ message });
        }
      },
    [pushErrorToast]
  );

  return handleMergeCompanies;
}
