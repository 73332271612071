import * as yup from 'yup';
import { merge } from 'lodash-es';
import { dateField, integerField, usdField } from './common-schema-defs';

export function fundDataFields() {
  return {
    date: dateField().nullable().required().default(null),
    contributedSecurities: usdField().nullable().default(null).label('Contributed Securities'),

    deemedContributions: usdField().nullable().default(null).label('Deemed Contributions'),
    fundId: yup.number().nullable().required().default(null),
    id: integerField().nullable().default(null),
    netAssets: usdField().nullable().default(null).label('Remaining Cash Balance'),
    updatedAt: yup.string(),
  };
}

export function fundDataFormSchema() {
  return yup.object(fundDataFields());
}

export function createFormFundData(overrides: Partial<FormFundData> = {}): FormFundData {
  return merge({}, fundDataFormSchema().getDefault(), overrides);
}

export type FundData = Omit<yup.InferType<ReturnType<typeof fundDataFormSchema>>, 'date' | 'id'> & {
  date: string;
  id: number;
};

export type FormFundData = yup.InferType<ReturnType<typeof fundDataFormSchema>>;

export function createFundData(overrides: Partial<FundData> = {}): FundData {
  return merge({}, fundDataFormSchema().getDefault(), overrides);
}
