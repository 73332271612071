import { ReactNode } from 'react';
import { useBlocker, BlockerFunction } from 'react-router-dom';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';

export const DefaultTitle = 'Are you sure you want to exit?';
export const DefaultMessage = 'Some changes may not be saved';
export const ConfirmButtonText = 'Exit';
export const CancelButtonText = 'Cancel';

const DialogBody = css`
  min-width: 44rem;
  padding: 1rem 2.5rem 0;
`;

interface IActionProps {
  label: string;
  onClick: () => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
}

interface INavigationBlockerProps {
  shouldBlock: BlockerFunction;
  title?: ReactNode;
  message?: ReactNode;
  cancelProps?: IActionProps;
  proceedProps?: Partial<IActionProps>;
  doBeforeProceed?: IActionProps;
}

export function NavigationBlocker({
  shouldBlock,
  title = <Typography>{DefaultTitle}</Typography>,
  message = <InfoBox message={DefaultMessage} />,
  cancelProps,
  proceedProps,
  doBeforeProceed,
}: INavigationBlockerProps) {
  const blocker = useBlocker(shouldBlock);

  const onClose = () => {
    cancelProps?.onClick();
    blocker.reset?.();
  };

  const onProceed = () => {
    proceedProps?.onClick?.();
    blocker.proceed?.();
  };

  const onDoBeforeProceed = async () => {
    await doBeforeProceed?.onClick();
    blocker.proceed?.();
  };

  return (
    <BasicDialog
      open={blocker.state === 'blocked'}
      closeOnEscOrBackdropClick
      titleComponent={title}
      onClose={onClose}
    >
      <div css={DialogBody}>{message}</div>

      <StickyFormButtons
        onSubmit={doBeforeProceed ? onDoBeforeProceed : onProceed}
        loading={doBeforeProceed?.loading || proceedProps?.loading}
        disabled={doBeforeProceed?.disabled || proceedProps?.disabled}
        submitLabel={doBeforeProceed?.label ?? proceedProps?.label ?? ConfirmButtonText}
        onCancel={onClose}
        cancelLabel={cancelProps?.label ?? CancelButtonText}
        secondaryActionProps={
          doBeforeProceed
            ? {
                onClick: onProceed,
                label: proceedProps?.label ?? 'Exit',
                loading: proceedProps?.loading,
                disabled: proceedProps?.disabled,
              }
            : undefined
        }
      />
    </BasicDialog>
  );
}
