import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CHART_COMPONENTS } from '../../../../types';
import { GroupExtractorFn, useIrrByGroup } from '../../hooks/useIrrByGroup';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { sectorsColourState } from '../../../../services/state/SectorState';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { sectorsMapState } from '../../../../services/state/AppConfigState';
import { IPortfolioChartProps } from './ChartComponent';

export const IrrBySector: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const sectorMap = useRecoilValue(sectorsMapState);

  const sectorExtractor = useCallback<GroupExtractorFn>(
    ({ company }) => sectorMap.get(company?.sectorId ?? -1)?.name ?? '',
    [sectorMap]
  );
  const { data } = useIrrByGroup(props.filteredMetrics, sectorExtractor);
  const yFormatter = useCallback((tick: string | number) => `${Number(tick).toFixed(1)}%`, []);
  const sectorColours = useRecoilValue(sectorsColourState);

  return (
    <LineChart
      yAxisLabelFormatter={yFormatter}
      data={data}
      chartColors={sectorColours}
      id={CHART_COMPONENTS.IRR_BY_SECTOR}
      title={'IRR by Sector'}
      width={640}
    />
  );
};
