import { styled } from '@mui/material/styles';
import { useTheme, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Event } from '../../../types';
import { useNotificationConfig } from '../hooks/useNotificationConfig';

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  width: 100%;
  border-top: 1px solid #d2d5db;
  padding: 1rem 0;

  & > * {
    &:not(:first-child) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

interface IProps {
  event: Event;
}

export function NotificationRow({ event }: IProps) {
  const {
    data: {
      deal: { types, config },
    },
    setData,
    setIsMutating,
  } = useNotificationConfig();
  const { primary } = useTheme().colors;
  const [sortedTypes, setSortedTypes] = useState<Event[] | null>();

  useEffect(() => {
    if (!types) return;
    setSortedTypes([...types].sort(({ sortOrder: a }, { sortOrder: b }) => a - b));
  }, [types]);

  const handleSelect = (id: string, checked: boolean) => {
    if (!sortedTypes) return;

    setIsMutating(true);

    const { id: eventId } = event;

    setData((prevState) => {
      const config = { ...prevState.deal.config };
      const allTypes = sortedTypes?.map(({ id }) => id);

      if (id === 'all') {
        config[eventId] = checked ? allTypes : [];

        return {
          ...prevState,
          deal: {
            ...prevState.deal,
            config: { ...config },
          },
        };
      }

      if (checked) {
        config[eventId] = [...(config[eventId] || []), id];
      } else {
        config[eventId] = config[eventId].filter((i) => i !== id);
      }

      if (config[eventId].length < 4) {
        config[eventId] = config[eventId].filter((i) => i !== 'all');
      }

      if (config[eventId].length === 3) {
        config[eventId] = ['all', ...config[eventId]];
      }

      return { ...prevState, deal: { ...prevState.deal, config: { ...config } } };
    });
  };

  return (
    <Wrapper data-testid={'notification-config-row'}>
      <Typography variant={'subtitle2'} color={primary['100']}>
        {event.displayName}
      </Typography>
      {sortedTypes?.map(({ id }) => (
        <div key={id} data-testid={`${event.displayName}-${id}`}>
          <Switch
            checked={config[event.id].includes(id)}
            onChange={(e) => handleSelect(id, e.target.checked)}
          />
        </div>
      ))}
    </Wrapper>
  );
}
