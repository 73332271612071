import { useEffect, useState } from 'react';
import { Rubric } from '../../../../../schemas/Rubric.schema';
import { getRubricsForConfigId } from '../../../../../services/queries/MaggieRubricQueries';
import { EntityType } from '../../../../../data-models/field2.data-model';

export function useRubricHistory({ rubric }: { rubric: Rubric }) {
  const [history, setHistory] = useState<Rubric[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  const { entity, entityId, rubricConfigId } = rubric;

  useEffect(() => {
    if (history || loading) return;
    setLoading(true);

    getRubricsForConfigId({ entity: entity as EntityType, entityId, rubricConfigId })
      .then((data) => {
        setHistory(data);
      })
      .catch((e) => {
        console.error(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entity, entityId, history, loading, rubricConfigId]);

  return { history, loading, error };
}
