import { useCallback } from 'react';
import { securityField } from '../../../data-fields/TransactionFields';
import {
  amountField,
  percentField,
  sharesField,
  simpleMultilineTextField,
  useRoundIdField,
} from '../../../data-fields/CommonFields';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';
import { useGetAmountField } from './TransactionFormUtils';

export function useTokenFields() {
  const getCommonInitialFields = useFundDateCurrencyFields();
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const convertedRoundField = useRoundIdField({
    key: 'convertedRoundId',
    label: 'Converted Stage',
    required: true,
  });
  const getTokenSaftFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      securityField(),
      amountField({ key: 'pricePerShare', label: 'Price Per Token', required: true }),
      sharesField({ key: 'noOfShares', label: 'Number of Tokens', required: true }),
      amountField({ key: 'valuationCap', label: 'Valuation Cap' }),
      percentField({ key: 'discountRate', label: 'Discount Rate' }),
    ];
  }, [getCommonInitialFields]);

  const getTokenBuyFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      amountField({ key: 'pricePerShare', label: 'Price Per Token', required: true }),
      sharesField({ key: 'noOfShares', label: 'Number of Tokens', required: true }),
    ];
  }, [getCommonInitialFields]);

  const getTokenSaftConversionFields = useCallback(() => {
    return [
      ...getCommonInitialFields(),
      convertedRoundField,
      securityField(),
      getAmountField({ key: 'convertedPrincipalAmount', label: 'Converted Token Value', required: true }),
      sharesField({
        key: 'convertedSharesNo',
        label: `Number of Tokens`,
        required: true,
      }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [convertedRoundField, getAmountField, getCommonInitialFields]);

  return {
    getTokenSaftFields,
    getTokenBuyFields,
    getTokenSaftConversionFields,
  };
}
