import { styled } from '@mui/material/styles';

export const Label = styled('label')`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  display: block;
`;

export const FormContainer = styled('form')`
  display: grid;
  width: 38rem;
  max-width: 38rem;
  gap: 1rem;
  padding: 0 2.5rem 2rem;
`;

export const FieldContainer = styled('div')`
  display: grid;
  gap: 0.5rem;
`;

export const LabelAndButtonContainer = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

export const FieldAndButtonContainer = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;
