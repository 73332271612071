import { FC, useState } from 'react';
import { TextField, Typography, styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { ContentContainer } from '../components/commonStyledComponents';
import { scenariosByCompanyIdState } from '../../state/ScenariosState';
import { MultiSelect } from '../../../../CoreComponents/base/MultiSelect';
import { UnknownUser } from '../../../../data-models/user.data-model';
import { usersByEmailMapState } from '../../../../services/state/AppConfigState';
import { filterScenarios } from '../utils/scenarioUtils';
import { ScenarioList } from './ScenarioList';
import { CreateScenarioButton } from './CreateScenarioButton';

const ActionContainer = styled('div')`
  display: grid;
  grid-template-columns: minmax(auto, 24rem) minmax(11rem, auto) 1fr;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const AllScenarios: FC = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const scenarios = useRecoilValue(scenariosByCompanyIdState(companyId));
  const options = [...new Set(scenarios.map((sc) => sc.createdBy!))];
  const [ownerFilter, setOwnerFilter] = useState<string[] | null>(null);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const filteredScenarios = filterScenarios(scenarios, searchFilter, ownerFilter);

  return (
    <Typography component='div' color='text.primary'>
      <ContentContainer>
        <ActionContainer>
          <SearchInput
            onChange={setSearchFilter}
            style={{ margin: 0 }}
            placeholder='Search for a scenario name'
          />
          <OwnerFilter options={options} value={ownerFilter ?? []} onChange={setOwnerFilter} />
          <CreateScenarioButton />
        </ActionContainer>
        {scenarios.length > 0 && <ScenarioList scenarios={filteredScenarios} />}
      </ContentContainer>
    </Typography>
  );
};

interface IOwnerFilterProps {
  options: string[];
  value: string[];
  onChange: (newValue: string[]) => void;
}
export const OwnerFilter: FC<IOwnerFilterProps> = ({ options, value, onChange }) => {
  const usersByEmail = useRecoilValue(usersByEmailMapState);

  return (
    <MultiSelect
      openOnFocus
      options={options}
      value={value}
      renderInput={(params) => {
        return <TextField {...params} placeholder='Filter by owner' sx={{ margin: 0 }} />;
      }}
      getOptionLabel={(option) => (usersByEmail.get(option as string) ?? UnknownUser).name}
      onChange={(e, newValue) => onChange(newValue as string[])}
    />
  );
};
