import { EntityFieldType, IField } from '../data-models/field2.data-model';
import { Formatting } from '../types';

export function isCustomField(field: IField<unknown>) {
  return field.entityField.startsWith(EntityFieldType.Custom);
}

export function sortFields(f1: IField<unknown>, f2: IField<unknown>) {
  return f1.displayName.localeCompare(f2.displayName);
}

export function getMetricUnit(unit: Formatting) {
  switch (unit) {
    case Formatting.Currency:
      return '$';
    case Formatting.Percent:
      return '%';
    case Formatting.Numeric:
      return '';
    case Formatting.Multiplier:
      return 'X';
    default:
      return '';
  }
}
