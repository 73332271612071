import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { IconButton, Link, SvgIcon } from '@mui/material';
import CrunchBase from '../../../../assets/icons/CB-icon.svg?react';
import CbInsights from '../../../../assets/icons/cb-insights.svg?react';
import Pitchbook from '../../../../assets/icons/pitchbook.svg?react';
import Linkedin from '../../../../assets/icons/ln.svg?react';
import Twitter from '../../../../assets/icons/tw.svg?react';
import FB from '../../../../assets/icons/fb.svg?react';
import Instagram from '../../../../assets/icons/ig.svg?react';
import { LinkType, ISocialLinkDataModel } from '../../../../data-models/social-link.data-model';

const BUTTON_PADDING = '.1rem';

const IconsWrapper = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.colors.secondary[60]};
  margin-left: -${BUTTON_PADDING}; // to align with the text
  align-items: center;
`;

const IconAndOrderMap: Record<LinkType, { icon: FC; order: number }> = {
  [LinkType.CRUNCHBASE]: { icon: CrunchBase, order: 3 },
  [LinkType.CBINSIGHTS]: { icon: CbInsights, order: 4 },
  [LinkType.PITCHBOOK]: { icon: Pitchbook, order: 2 },
  [LinkType.LINKEDIN]: { icon: Linkedin, order: 1 },
  [LinkType.TWITTER]: { icon: Twitter, order: 5 },
  [LinkType.FACEBOOK]: { icon: FB, order: 6 },
  [LinkType.INSTAGRAM]: { icon: Instagram, order: 7 },
};

interface CompanySocialMediaProps {
  socials?: ISocialLinkDataModel[];
  onlyPriorityLinks?: boolean;
}

export const CompanySocialMedia: FC<CompanySocialMediaProps> = ({ socials, onlyPriorityLinks }) => {
  const orderedSocials = useMemo(() => {
    if (!socials?.length) return [];
    const sorted = [...socials].sort((a, b) => IconAndOrderMap[a.type].order - IconAndOrderMap[b.type].order);
    if (onlyPriorityLinks) return sorted.filter((s) => IconAndOrderMap[s.type].order <= 4);
    else return sorted;
  }, [onlyPriorityLinks, socials]);

  return (
    <IconsWrapper>
      {orderedSocials.map((social) => {
        const Icon = IconAndOrderMap[social.type].icon;
        return (
          <IconButton
            component={Link}
            href={social.url}
            target='_blank'
            rel='noreferrer'
            key={social.url}
            sx={{ padding: BUTTON_PADDING }}
            fontSize='inherit'
            onClick={(e) => e.stopPropagation()}
          >
            <SvgIcon fontSize='inherit' viewBox={'0 0 20 20'}>
              <Icon />
            </SvgIcon>
          </IconButton>
        );
      })}
    </IconsWrapper>
  );
};
