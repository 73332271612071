import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { useSetReportingStatus } from '../hooks/useSetReportingStatus';
import { selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { sectorsMapState } from '../../../../../services/state/AppConfigState';
import { FormFieldsContainer } from '../../../../../components/Form/FormComponents';
import { cardStyles } from '../../../../../theme/component-styles';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { StatusRenderer } from '../../../../../components/AgTable/cell-renderers/StatusRenderer';
import { IKpiCompanyData } from '../AllCompaniesData';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';

const dialogContent = css`
  padding-bottom: 0;
  gap: 0.5rem;
`;
const companyCard = css({
  ...cardStyles,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, auto)',
  columnGap: '1rem',
  alignItems: 'center',
});

export function StopReportingModal() {
  const updateReportingStatus = useSetReportingStatus();
  const { selectedAction, resetUIState } = useAllKpiCompaniesActions();
  const selectedCompanies = useRecoilValue(selectedKpiCompaniesStateDC);
  const company = selectedCompanies.at(0);
  const sectorsById = useRecoilValue(sectorsMapState);
  const sector = sectorsById.get(company?.sectorId ?? -1);

  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    resetUIState();
  }, [resetUIState]);

  const handleConfirm = useCallback(async () => {
    if (!company) return;
    setLoading(true);
    await updateReportingStatus(company.id, false);
    setLoading(false);
    handleClose();
  }, [company, handleClose, updateReportingStatus]);

  if (selectedAction !== 'confirmStopReporting' || !company) return null;
  const { assigned } = company as IKpiCompanyData;

  return (
    <BasicDialog title={'Stop Reporting Data?'} open onClose={handleClose}>
      <FormFieldsContainer css={dialogContent}>
        <Card css={companyCard}>
          <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
          {sector && <Typography variant='body2'>{sector.name}</Typography>}
          <StatusRenderer
            value={assigned ? 'Assigned' : 'Unassigned'}
            status={assigned ? 'success' : 'warning'}
          />
        </Card>
        <InfoBox
          message={
            'Collecting KPIs data will be suspended and the selected company will be marked as “Not Collecting”. You can change this later.'
          }
        />
      </FormFieldsContainer>
      <StickyFormButtons
        onSubmit={handleConfirm}
        loading={loading}
        onCancel={handleClose}
        submitLabel='Stop Reporting'
      />
    </BasicDialog>
  );
}
