import { useRecoilCallback } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { companyState } from '../../../../services/state/CompanyState';
import {
  activeCompanyListIdState,
  companyListState,
  listsState,
  trackingDataState,
} from '../../state/ListsState';
import { IListItemDataModel } from '../../../../data-models/list-item.data-model';
import { updateCompany } from '../../../../services/queries/MaggieCompanyQueries';
import { IListDataModel } from '../../../../data-models/list.data-model';
import { LoadingId } from '../../../../types';
import { useLoadingBarState } from '../../../../components/LoadingBar/LoadingBarContext';
import { updateListItem } from '../../../../services/queries/MaggieListQueries';
import { partialEqualsIgnoreUnsetValues } from '../../../../util/partialEquals';
import { ITrackCompanyPayload, TrackingAction } from '../../../../data-models/tracking.data-model';
import { trackCompany } from '../../../../services/queries/MaggieTrackingQueries';
import { getErrorMessage } from '../../../../services/queryHelpers';
import { useCurrentUser } from '../../../../services/hooks/useCurrentUser';

export function useCompanyActions() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const { actions } = useLoadingBarState();
  const currentUser = useCurrentUser();

  const _handleTrackCompany = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({
        company,
        action,
        userId,
        payload,
        lists,
      }: {
        company: ICompanyDataModel;
        action: TrackingAction;
        userId: number;
        payload?: ITrackCompanyPayload;
        lists?: IListDataModel[] | null; // this will help with optimistic updates of different lists
      }) => {
        if (action === 'untrack' && !company.followers) {
          throw new Error('Company does not have followers');
        }

        try {
          const data = await trackCompany(company.id, action, payload);

          const updatedFollowers =
            action === 'track'
              ? [...(company.followers ?? []), userId]
              : company.followers!.filter((id) => id !== userId);

          const updatedSnapshot = snapshot.map((mutableSnapshot) => {
            if (snapshot.getInfo_UNSTABLE(trackingDataState).isSet) {
              mutableSnapshot.set(trackingDataState, (current) => {
                if (!current) return null;
                if (action === 'track') {
                  return [...current, data];
                } else {
                  return current.filter((item) => item.companyId !== company.id);
                }
              });
            }

            if (snapshot.getInfo_UNSTABLE(companyState(company.id)).isSet) {
              mutableSnapshot.set(companyState(company.id), (current) => {
                if (!current) return null;
                return { ...current, followers: updatedFollowers };
              });
            }

            const allLists = lists ?? mutableSnapshot.getLoadable(listsState).valueMaybe();

            const followedListId = allLists?.find((list) => list.name === 'Tracked Companies')?.id;
            if (followedListId && snapshot.getInfo_UNSTABLE(companyListState(followedListId)).isSet) {
              mutableSnapshot.set(companyListState(followedListId), (current) => {
                if (!current) return null;

                if (
                  action === 'track' &&
                  current.items.find((item: IListItemDataModel) => item.companyId === company.id)
                )
                  return current;

                if (action === 'track') {
                  const items = [
                    {
                      companyId: company.id,
                      listId: followedListId,
                      id: company.id,
                      updatedAt: new Date().toISOString(),
                    } as IListItemDataModel,
                    ...current.items,
                  ];
                  return { versions: [...current.versions], items };
                } else {
                  const items = current.items.filter((item) => item.companyId !== company.id);
                  return { versions: [...current.versions], items };
                }
              });
            }
          });

          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Failed to update track status');
          pushErrorToast({ message });
        }
      },
    []
  );

  /** @deprecated Use useUpdateCompanyAndState instead */
  const handleUpdateCompany = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (
        {
          companyId,
          payload,
        }: {
          companyId: number;
          payload: Partial<ICompanyDataModel>;
        },
        displayToastMessage = true
      ) => {
        const currentCompany = snapshot.getInfo_UNSTABLE(companyState(companyId)).loadable?.valueMaybe();
        if (currentCompany && partialEqualsIgnoreUnsetValues(payload, currentCompany)) return;

        actions.startLoading(LoadingId.updateCompany);

        const updatedSnapshot = snapshot.map((mutableSnapshot) => {
          if (snapshot.getInfo_UNSTABLE(companyState(companyId)).isSet) {
            mutableSnapshot.set(companyState(companyId), (current) => {
              if (!current) return null;
              return { ...cloneDeep(current), ...payload };
            });
          }
        });

        gotoSnapshot(updatedSnapshot);

        const release = snapshot.retain();

        try {
          await updateCompany({ companyId: companyId, payload });
          if (displayToastMessage) pushSuccessToast({ message: 'Company updated successfully' });
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          pushErrorToast({ message: 'Failed to update company' });
        } finally {
          release();
          actions.stopLoading(LoadingId.updateCompany);
        }
      },
    []
  );

  const handleChangeHideStatus = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({
        action,
        listItem,
        userId,
      }: {
        action: boolean;
        listItem: IListItemDataModel | null;
        userId: number;
      }) => {
        if (!listItem) return;
        const payload: Partial<IListItemDataModel> = { action, actionType: action ? 'hidden' : null };
        const clonedListItem = cloneDeep(listItem);
        const updatedListItem: IListItemDataModel = {
          ...clonedListItem,
          ...payload,
          updatedAt: new Date().toISOString(),
        };

        const updatedSnapshot = snapshot.map((mutable) => {
          const currentCompany = mutable.getLoadable(companyState(listItem.companyId)).valueMaybe();
          const updatedCompany = !currentCompany ? null : { ...cloneDeep(currentCompany), assignee: userId };

          mutable.set(companyState(listItem.companyId), updatedCompany);

          const activeListId = mutable.getLoadable(activeCompanyListIdState).valueMaybe();
          if (activeListId) {
            mutable.set(companyListState(activeListId), (current) => {
              if (!current) return null;
              const updatedItems = current.items.map((item) => {
                if (item.id === listItem.id) {
                  return updatedListItem;
                } else {
                  return item;
                }
              });
              return {
                ...cloneDeep(current),
                items: updatedItems,
              };
            });
          }

          mutable.set(listsState, (currentLists) => {
            if (!currentLists) return null;
            return currentLists.map((list) => {
              if (list.id === listItem.listId) {
                return {
                  ...cloneDeep(list),
                  numberOfItems: payload.action ? list.numberOfItems - 1 : list.numberOfItems + 1,
                };
              } else {
                return list;
              }
            });
          });
        });

        const release = snapshot.retain();
        gotoSnapshot(updatedSnapshot);

        try {
          await updateListItem(payload, listItem.id);
          await updateCompany({
            payload: { assignee: userId } as Record<string, unknown>,
            companyId: listItem.companyId,
          });
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          pushErrorToast({ message: 'Failed to update list item' });
        } finally {
          release();
        }
      },
    []
  );

  const handleTrackCompany2 = useCallback(
    async (company: ICompanyDataModel, data: Partial<ITrackCompanyPayload>) => {
      const payload = {
        ...data,
        snoozeDate: data.snoozeDate ?? null,
        snoozeComment: data.snoozeComment ?? null,
      };

      await _handleTrackCompany({
        company,
        action: 'track',
        userId: data.userId ?? currentUser.id,
        payload,
      });
    },
    [currentUser.id, _handleTrackCompany]
  );

  const handleUntrackCompany = useCallback(
    async (company: ICompanyDataModel) => {
      await _handleTrackCompany({
        company,
        action: 'untrack',
        userId: currentUser.id,
      });
    },
    [currentUser.id, _handleTrackCompany]
  );

  return {
    handleUpdateCompany,
    handleTrackCompany2,
    handleUntrackCompany,
    handleChangeHideStatus,
  };
}
