import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  margin-top: -2rem;
  width: 100%;
`;

export function CompetitiveIntelligenceSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <ContentLoader height={2000} width={'100%'}>
        <rect width='300' height='36' rx='4' x='0' y='80' fill='#EEEFF1' />
        <rect width='80' height='36' rx='4' x='0' y='130' fill='#EEEFF1' />
        <rect width='100' height='36' rx='4' x='100' y='130' fill='#EEEFF1' />
        <rect width='100' height='36' rx='4' x='220' y='130' fill='#EEEFF1' />
        <rect width='100' height='36' rx='4' x='340' y='130' fill='#EEEFF1' />
        <rect width='245' height='36' rx='4' x='0' y='200' fill='#EEEFF1' />
        <rect width='1000' height='650' rx='4' x='0' y='250' fill='#EEEFF1' />
        <rect width='245' height='36' rx='4' x='0' y='940' fill='#EEEFF1' />
        <rect width='1000' height='650' rx='4' x='0' y='990' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
