/**
 * Adapted from:
 * - old example: https://codesandbox.io/p/sandbox/dnd-kit-multi-containers-forked-ldns5p?file=%2Fsrc%2Fsortable_item.js%3A24%2C1-28%2C15
 * - dndkit example: https://codesandbox.io/p/sandbox/react-dndkit-multiple-containers-6wydy9?file=%2Fsrc%2Fexamples%2FSortable%2FMultipleContainers.tsx%3A96%2C1-97%2C22
 */

import { CSSProperties, PropsWithChildren, ReactNode, useMemo } from 'react';
import { closestCorners, DndContext } from '@dnd-kit/core';

import { Box, styled, Typography } from '@mui/material';
import { IItemWithId } from '../SortableList/SortableList';
import { ListContainer } from './ListContainer';
import { IMultiSortableProps, useMultiSortableList } from './useMultiSortableList';

const containerStyle: CSSProperties = {
  display: 'grid',
  rowGap: '0.25rem',
};

const ListsWrapper = styled('div')`
  display: grid;
  row-gap: 0.25rem;
`;

const ListWithName = styled('div')`
  display: grid;
  row-gap: 1rem;
  padding: 0.25rem;
`;

export interface IRenderGroupProps extends PropsWithChildren {
  groupName: string;
}

export interface IMultiSortableListProps<T extends IItemWithId> extends IMultiSortableProps<T> {
  renderGroup?: (props: IRenderGroupProps) => ReactNode;
  renderItem: (item: T, index: number) => ReactNode;
}

export function MultiSortableList<T extends IItemWithId>(props: IMultiSortableListProps<T>) {
  const { items, renderItem, renderGroup } = props;
  const { sensors, handleDragStart, handleDragEnd, handleDragOver } = useMultiSortableList(props);

  const elems = useMemo(() => {
    return Object.keys(items).map((groupName) => {
      const value = items[groupName];
      const children = (
        <ListContainer id={groupName} containerStyle={containerStyle} items={value} renderItem={renderItem} />
      );
      if (renderGroup) return renderGroup({ groupName, children });
      return (
        <ListWithName key={groupName}>
          <Typography>{groupName}</Typography>
          {children}
        </ListWithName>
      );
    });
  }, [items, renderGroup, renderItem]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <ListsWrapper>{elems}</ListsWrapper>
        {/*<DragOverlay>{activeId ? <div>FOOOOOO</div> : null}</DragOverlay>*/}
      </DndContext>
    </Box>
  );
}
