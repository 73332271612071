import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { HtmlTooltip } from './HtmlTooltip';

const IndicatorContainer = styled('div')`
  display: inline-flex;
  gap: 0.1rem;
  align-items: center;
  justify-content: flex-start;
  min-width: 2.3rem;
  [data-icon='warning'] {
    height: 1rem;
  }
`;

interface StatusIndicatorsProps {
  warning: boolean | undefined;
  warningMessage?: string | null;
}

export const StatusIndicators = ({ warning, warningMessage }: StatusIndicatorsProps) => {
  const { colors } = useTheme();

  if (!warning) {
    return null;
  }

  return (
    <IndicatorContainer>
      {warning && (
        <HtmlTooltip title={warningMessage || ''} placement='top' style={{ color: 'black' }}>
          <span>
            <ErrorOutlineRoundedIcon htmlColor={colors.neutral[60]} fontSize='small' />
          </span>
        </HtmlTooltip>
      )}
    </IndicatorContainer>
  );
};
