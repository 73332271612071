import { ICellRendererParams } from 'ag-grid-community';
import { IPersonDataModel } from '../../../../data-models/person.data-model';

import { SocialMedia } from '../../Summary/components/People/SocialMedia';

export const SocialMediaRenderer = (params: ICellRendererParams<IPersonDataModel, IPersonDataModel>) => {
  const { primaryEmail, secondaryEmails, socials, id } = params.data ?? params.value ?? {};
  const { twitter, linkedin } = socials ?? {};
  if (!params.data && !params.value) return null;

  return (
    <SocialMedia
      personId={id ?? -1}
      primaryEmail={primaryEmail ?? ''}
      secondaryEmails={secondaryEmails}
      twitterUrl={twitter}
      linkedinUrl={linkedin}
    />
  );
};
