import { FC, useMemo } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Typography } from '@mui/material';
import { financeDashboardState } from '../../state/FinanceState';
import { ErrorOccurredMessage } from '../../../CompanyProfiles/components/Messages/HelpMessage';
import { CompanyLogoAndName } from '../../../../components/grid-renderers/CompanyCellRenderer';
import { formatDate } from '../../../../util/formatters/DateFormatters';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import {
  IDashboardTransactionDataModel,
  IFinanceDashboardResponseDataModel,
} from '../../../../data-models/dashboard-transaction.data-model';
import {
  DashboardSection,
  StyledDashboardCard,
  DashboardTableContainer,
  DashboardSubtitle,
  StyledDashboardRow,
  RowContainer,
} from './DashboardStyledComponents';

export enum DashboardTableNames {
  cashOutData = 'Cash Out',
  cashInData = 'Cash In',
  valuationChangeData = 'Valuation Changes',
}

export const DashboardTables: FC = () => {
  const dataLoadable = useRecoilValueLoadable(financeDashboardState);
  const data = dataLoadable.valueMaybe();

  const dataByTable: [keyof IFinanceDashboardResponseDataModel, IDashboardTransactionDataModel[]][] | null =
    useMemo(() => {
      if (!data) return null;
      return [
        ['cashOutData', data.cashOutData],
        ['cashInData', data.cashInData],
        ['valuationChangeData', data.valuationChangeData],
      ];
    }, [data]);

  if (dataLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (!dataByTable) return null;

  return (
    <DashboardSection>
      <Typography variant='h6'>Latest Financial Data</Typography>
      <DashboardTableContainer>
        {dataByTable.map(([tableName, tableData]) => (
          <StyledDashboardCard key={DashboardTableNames[tableName]}>
            <DashboardSubtitle title={DashboardTableNames[tableName]} />
            <RowContainer role='table'>
              {tableData.map((t, i) => (
                <StyledDashboardRow key={`${t.companyName}-${t.date}-${t.value}-${i}`} role='row'>
                  <CompanyLogoAndName name={t.companyName} logoUrl={t.companyLogoUrl ?? ''} role='cell' />
                  <Typography role='cell' variant='body2'>
                    {Boolean(t.date) && formatDate(t.date!)}
                  </Typography>
                  <Typography role='cell' variant='body2' className='numeric'>
                    {t.value != null && formatUSDShort(t.value!)}
                  </Typography>
                </StyledDashboardRow>
              ))}
            </RowContainer>
          </StyledDashboardCard>
        ))}
      </DashboardTableContainer>
    </DashboardSection>
  );
};
