import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, init as echartsInit, use as echartsUse } from 'echarts/core';
import { PieChart as EChartsPie, PieSeriesOption } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { PieCallbackDataParams } from 'echarts/types/src/chart/pie/PieSeries';
import { formatUSDShort } from '../../../util/formatters/NumericFormatters';
import { ChartTitleWrapper } from '../ChartTitleWrapper';
import { ChartWrapper } from '../ChartWrapper/ChartWrapper';
import { IconTypes } from '../../Icon';
import { generateChartColourScheme } from '../generateChartColourScheme';
import { Chart, ChartSizes } from '../chart-utils';
import { ItemsColourScheme } from '../../../util/ColourSchemeGenerator';
import { useIsInViewport } from '../../../hooks/useIsInViewport';

type ECPieOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | PieSeriesOption
  | TooltipComponentOption
>;

// Register the required components
echartsUse([
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  EChartsPie,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

const margin = { top: 60, right: 60, bottom: 60, left: 60 };

const pieTotalFormat = new Intl.NumberFormat(navigator.language, {
  compactDisplay: 'short',
  currency: 'USD',
  notation: 'compact',
  style: 'currency',
  minimumFractionDigits: 2,
});
export function formatTotal(value: number) {
  return pieTotalFormat.format(value);
}

const PieWrapper = styled('div')`
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const TotalWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: ${margin.right}px;
  bottom: 0;
  left: ${margin.left}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
`;

const labelTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 12,
};

const tooltipTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 14,
};

type DataItem = {
  name: string;
  value: number;
  id: string;
};

export interface PieChartProps {
  caption?: string;
  chartColors?: ItemsColourScheme<string>;
  data: DataItem[];
  id: string;
  title: string;
  total: number;
  width?: number;
}

export const PieChart = memo(function PieChart({
  caption,
  chartColors,
  data,
  id,
  title,
  total,
  width = 700,
}: PieChartProps) {
  const [showMore, setShowMore] = useState(false);
  const [widthState, setWidthState] = useState<string | number>(width);
  const [size, setSize] = useState('1/4 Screen');
  const [isHovering, setIsHovering] = useState(false);
  const { neutral } = useTheme().colors;
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const pieChart = useRef<ECharts | null>(null);
  const totalLabel = formatTotal(total);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.6,
    },
    chartContainer
  );
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setShowMore(false);
    setIsHovering(false);
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!pieChart.current) {
      pieChart.current = echartsInit(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          pieChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(pieChart.current && isInViewport)) {
      return;
    }

    const pieChartData: PieSeriesOption = {
      type: 'pie',
      radius: ['35%', '75%'],
      itemStyle: {
        borderRadius: 5,
        borderColor: '#fff',
        borderWidth: 2,
        color: chartColors
          ? (params: PieCallbackDataParams) => {
              return chartColors?.get(params.name) ?? 'grey';
            }
          : undefined,
      },
      label: {
        ...labelTextStyle,
        position: 'outer',
      },
      data,
    };

    const option: ECPieOption = {
      color: chartColors ? null : generateChartColourScheme(data.length),
      grid: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      series: [pieChartData],
      tooltip: {
        ...tooltipTextStyle,
        formatter: (params) => {
          const { name, value } = (params as CallbackDataParams).data as DataItem;
          const pctValue = `${((value / total) * 100).toFixed(2)}%`;
          return `${name} ${formatUSDShort(value)} (${pctValue})`;
        },
        trigger: 'item',
        confine: true,
      },
    };

    if (pieChart.current) {
      pieChart.current.setOption(option, true);
    }
  }, [chartColors, data, isInViewport, total]);

  return (
    <ChartWrapper
      id={id}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      width={widthState}
      dataTestid={'pie-chart'}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={title}
        showMore={showMore}
        setShowMore={setShowMore}
        id={id}
        caption={caption}
        icon={IconTypes.FINANCE}
        handleSizeChange={setSize}
        size={size}
        refProp={chartContainer}
      />{' '}
      <PieWrapper>
        <Chart width={'100%%'} ref={chartContainer} />
        <TotalWrapper>
          <Typography variant={'body2'} color={neutral[70]}>
            {totalLabel}
          </Typography>
        </TotalWrapper>
      </PieWrapper>
    </ChartWrapper>
  );
});
