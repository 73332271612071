import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CHART_COMPONENTS } from '../../../../../types';
import { LineChart } from '../../../../../components/Charts/LineChart/LineChart';
import { sectorsColourState } from '../../../../../services/state/SectorState';
import { IPortfolioChartProps } from '../ChartComponent';
import { IMetricsDataModel } from '../../../../../data-models/metrics.data-model';
import { useMoicBySector } from './useMoicBySector';

export const MoicBySectorChart: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const { data } = useMoicBySector(props.filteredMetrics);
  const yFormatter = useCallback((tick: number | string) => `${Number(tick).toFixed(1)} x`, []);
  const sectorColours = useRecoilValue(sectorsColourState);

  return (
    <LineChart
      chartColors={sectorColours}
      data={data}
      id={CHART_COMPONENTS.MOIC_BY_SECTOR}
      title={'MOIC By Sector'}
      width={640}
      yAxisLabelFormatter={yFormatter}
    />
  );
};
