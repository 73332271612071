import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { companyState } from '../../../../services/state/CompanyState';

export function useAllScenariosLocation() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const path = useMemo(() => {
    if (!company) return '';
    return `/${ROUTES.COMPANY_PROFILES}/${company.name}-${company.id}/${ROUTES.COMPANY_PROFILE_SCENARIOS}`;
  }, [company]);

  return path;
}
