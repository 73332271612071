import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Fade, Icon, Stack } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyState } from '../../../../../services/state/CompanyState';
import { ScenarioTabs } from '../form/ScenarioTabs';
import { useScenarioActions } from '../../hooks/useScenarioActions';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';
import { currentScenarioState } from '../../../state/ScenariosState';
import { useBlockScenarioExit } from '../block-navigation/useBlockScenarioExit';
import { NavigationBlocker } from '../block-navigation/NavigationBlocker';
import { ScenarioExitWarning } from '../block-navigation/ScenarioExitWarning';
import { scenarioNameSchema } from '../../../../../schemas/Scenario.schema';
import { IVisualizationHeadingProps, HeaderStack, ScenarioTitle } from './ScenarioTitle';
import { useAllowSave } from './useAllowSave';

export type TScenarioName = Pick<Partial<IScenarioDataModel>, 'name'>;

export const VisualizationHeading: FC<IVisualizationHeadingProps> = ({ scenario }) => {
  const company = useRecoilValue(companyState(scenario.companyId));
  if (!company) return null;

  return (
    <HeadingFormWrapper scenario={scenario}>
      <Stack direction='row' justifyContent={'space-between'} sx={{ mb: '1rem' }}>
        <ScenarioTitle scenario={scenario} />
        <HeaderStack>
          <PublishAction scenario={scenario} />
        </HeaderStack>
      </Stack>
    </HeadingFormWrapper>
  );
};

export const PublishAction: FC<IVisualizationHeadingProps> = ({ scenario }) => {
  const { handleCreateScenario, handleUpdateScenario, loading } = useScenarioActions();

  const { trigger, control } = useFormContext<TScenarioName>();
  const name = useWatch({ name: 'name', control });

  const payload = useMemo(() => ({ ...scenario, name }), [name, scenario]);

  const allowPublish = useAllowSave(payload);

  const { shouldBlock } = useBlockScenarioExit(allowPublish);

  const handlePublish = useCallback(async () => {
    if (!allowPublish) return;

    const isValid = await trigger('name');
    if (!isValid) return;

    if (scenario.id) {
      return await handleUpdateScenario(scenario.id, payload);
    } else {
      return await handleCreateScenario(payload);
    }
  }, [allowPublish, handleCreateScenario, handleUpdateScenario, payload, scenario.id, trigger]);

  return (
    <>
      <NavigationBlocker shouldBlock={shouldBlock} message={<ScenarioExitWarning scenario={scenario} />} />
      <Fade in={allowPublish} timeout={300}>
        <LoadingButton
          onClick={handlePublish}
          variant='contained'
          color='secondary'
          size='medium'
          loading={loading}
          loadingPosition='start'
          disabled={loading}
          startIcon={loading ? <Icon /> : <></>}
        >
          Publish Scenario
        </LoadingButton>
      </Fade>
    </>
  );
};

type IHeadingFormWrapperProps = Pick<IVisualizationHeadingProps, 'scenario'> & PropsWithChildren;

export const HeadingFormWrapper: FC<IHeadingFormWrapperProps> = ({ scenario, children }) => {
  const methods = useForm<TScenarioName>({
    defaultValues: { name: scenario.name },
    resolver: yupResolver(scenarioNameSchema()),
    mode: 'all',
  });

  const setCurrentScenario = useSetRecoilState(currentScenarioState);

  const { getValues } = methods;
  const onGoToSettings = useCallback(() => {
    setCurrentScenario((curr) => ({ ...curr, name: getValues('name') }));
  }, [getValues, setCurrentScenario]);

  return (
    <FormProvider {...methods}>
      {children}
      <ScenarioTabs onGoToSettings={onGoToSettings} />
    </FormProvider>
  );
};
