import * as yup from 'yup';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { integerField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function tokenSaftConversionFields() {
  return {
    fundId: yup.number().nullable().required().label('Fund'),
    currencyid: transactionCurrencyIdField(),
    transactionDate: transactionDateSchema(),
    investmentType: yup.string().nullable().optional(),
    convertedRoundId: yup.number().nullable().required().label('Converted Stage'),
    security: yup.string().nullable().optional(),
    convertedPrincipalAmount: usdField().nullable().label('Converted Token Value').required().min(0),
    convertedSharesNo: integerField().nullable().required().label('Number of Tokens').min(0),
    notes: yup.string().nullable().optional(),
  };
}

export function tokenSaftConversionFormSchema() {
  return yup.object().shape(tokenSaftConversionFields());
}

export function tokenSaftConversionGridSchema() {
  return yup.object().shape(tokenSaftConversionFields());
}
