import * as yup from 'yup';
import {
  ParticipationType,
  ScenarioTransactionType,
  ScenarioType,
  ShareClassSeniorityOption,
} from '../data-models/scenario.data-model';
import { TScenarioName } from '../pages/CompanyProfiles/Scenarios/SingleScenario/visualization/VisualizationHeading';
import { ICaptableDataModel } from '../data-models/captable2.data-model';
import { getScenarioCaptableData } from '../pages/CompanyProfiles/Scenarios/SingleScenario/form/scenario-schema-utils';
import { RendererType } from '../data-models/field.data-model';
import { FMT } from '../util/formatter-service';
import {
  dateField,
  multiplierField,
  usdField,
  percentField,
  integerField,
  ppsField10dp,
} from './common-schema-defs';

const minZero = 'Value must be at least 0';
export const requiredMsg = 'This field is required';
export const investmentDataRequiredMsg = 'Please add at least one investment';

const requiredRoundField = (schema: yup.Schema) =>
  schema.when('type', {
    is: ScenarioTransactionType.round,
    then: (schema) => schema.required(requiredMsg),
  });

const requiredExitField = (schema: yup.Schema) =>
  schema.test('exitValuation', requiredMsg, function (value, context) {
    if (context?.parent?.type !== ScenarioTransactionType.exit) return true;
    return value != null;
  });

const requiredExitAndPartialExitField = (schema: yup.Schema) =>
  schema.test('exitValuation', requiredMsg, function (value, context) {
    if (
      context?.parent?.type !== ScenarioTransactionType.exit &&
      context?.parent?.type !== ScenarioTransactionType.partialExit
    )
      return true;
    return value != null;
  });

export function scenarioFundDataFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg),
    investedAmount: usdField().min(0, minZero).nullable().required(requiredMsg),
  };
}

export function scenarioTransactionFields(captable?: ICaptableDataModel) {
  return {
    // common
    createdAt: yup.string().nullable(),
    id: yup.number().required(requiredMsg),
    scenarioId: yup.number().required(requiredMsg),
    type: yup.string().oneOf(Object.values(ScenarioTransactionType)).required(requiredMsg),
    updatedAt: yup.string().nullable(),
    // round
    conversionRatio: requiredRoundField(multiplierField().nullable().positive('Value must be positive')),
    debtRepayment: yup.number().nullable(),
    fundData: yup
      .array()
      .nullable()
      .when('type', {
        is: ScenarioTransactionType.round,
        then: () => yup.array().of(scenarioFundDataSchema()),
      })
      .when('type', {
        is: ScenarioTransactionType.round,
        then: (schema) => schema.min(1, investmentDataRequiredMsg),
      })
      .transform((value, _originalValue, context) => {
        if (
          context?.type === ScenarioTransactionType.exit ||
          context?.type === ScenarioTransactionType.partialExit
        ) {
          return null;
        }
        return value;
      }),
    investedAmount: yup.number().nullable(),
    investmentDate: yup.string().nullable(),
    multiple: requiredRoundField(multiplierField().nullable().min(0, minZero)),
    // newSharesReserved: numericRoundField.min(0, 'Value must be at least 0'), // TODO: uncomment when backend is ready
    newSharesReserved: yup.number().nullable(),
    optionPoolPercentage: percentField()
      .nullable()
      .label('Option Pool %')
      .when(['type', 'optionPoolRefresh'], {
        is: (typeVal: string, optionPoolRefreshVal: boolean) =>
          typeVal === ScenarioTransactionType.round && optionPoolRefreshVal === true,
        then: (schema) => schema.required(requiredMsg).min(0, 'Value must at least 0'),
      }),
    optionPoolRefresh: yup.boolean().nullable(),
    pariPassu: yup.boolean().nullable(),
    participation: yup
      .string()
      .nullable()
      .when(['multiple', 'type'], {
        is: (multipleVal: number, type: ScenarioTransactionType) =>
          type === ScenarioTransactionType.round && multipleVal !== 0,
        then: (schema) => schema.required(requiredMsg),
      }),
    participationCap: multiplierField()
      .nullable()
      .when(['type', 'participation'], {
        is: (typeVal: string, participationVal: string) =>
          typeVal === ScenarioTransactionType.round && participationVal === ParticipationType.capped,
        then: (schema) => schema.required(requiredMsg).positive('Value must be positive'),
      }),
    preMoneyValuation: requiredRoundField(usdField().nullable().min(0, minZero)),
    roundId: requiredRoundField(yup.number().nullable()),
    roundSize: requiredRoundField(
      usdField()
        .nullable()
        .min(0, minZero)
        .test('roundSize', 'Round size cannot be less than investment amount', (value, context) => {
          if (typeof value !== 'number' || context?.parent?.type !== ScenarioTransactionType.round) {
            return true;
          }
          const { fundData } = context?.parent ?? {};
          if (!fundData) return true;
          const totalInvestmentAmount = fundData.reduce((acc: number, curr: ScenarioFundData) => {
            if (!curr.investedAmount) return acc;
            return acc + curr.investedAmount;
          }, 0);
          return value >= totalInvestmentAmount;
        })
    ),
    seniorityOption: yup
      .string()
      .oneOf(Object.values(ShareClassSeniorityOption))
      .nullable()
      .label('Seniority')
      .meta({
        description: 'Compared to most senior actual/hypothetical round',
      })
      .default(ShareClassSeniorityOption.Same)
      .when('type', {
        is: ScenarioTransactionType.round,
        then: (schema) => schema.required(requiredMsg),
      }),
    // exit & partial exit:
    exitValuation: requiredExitAndPartialExitField(usdField().nullable().min(0, minZero)),
    exitDate: yup.string().nullable(),
    // exit only:
    optionStrikePrice: requiredExitField(ppsField10dp().nullable().min(0, minZero)).default(0.01),
    warrantStrikePrice: requiredExitField(ppsField10dp().nullable().min(0, minZero)).default(0.01),
    // partial exit only:
    partialExitBreakdown: yup
      .array()
      .of(yup.object().shape(partialExitBreakdownFields(captable)))
      .nullable()
      .when('type', {
        is: ScenarioTransactionType.partialExit,
        then: (schema) => schema.min(1).required(requiredMsg),
      }),
  };
}

export function partialExitBreakdownFields(captable?: ICaptableDataModel) {
  const {
    fundOptionsByShareClassId,
    shareClassIdToClientInvestmentsByFund,
    shareClassOptions,
    shareClassesById,
  } = getScenarioCaptableData(captable);

  const allFundIds = new Set<number>([...fundOptionsByShareClassId.values()].flat().map((opt) => opt.value));
  const allFundOptions = Array.from(allFundIds).map((fundId) => ({
    displayName: FMT.format('fund', fundId),
    value: fundId,
  }));

  return {
    captableInvestmentId: yup
      .number()
      .nullable()
      .required(requiredMsg)
      .label('Share Class')
      .default(null)
      .customMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: shareClassOptions,
          placeholder: 'Select Share Class',
        },
      }),
    fundId: yup
      .number()
      .nullable()
      .label('Fund')
      .default(null)
      .customMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: allFundOptions, // TODO: should be dynamically populated based on selected share class
          placeholder: 'Select Fund',
        },
      }),
    numberOfShares: integerField()
      .nullable()
      .required(requiredMsg)
      .label('Number of Shares')
      .default(null)
      .test('numberOfShares', 'Max # of shares exceeded', function (value, context) {
        const { captableInvestmentId, fundId } = context?.parent ?? {};
        if (
          !captable ||
          !shareClassIdToClientInvestmentsByFund ||
          !captableInvestmentId ||
          context?.from?.at(1)?.value?.type !== ScenarioTransactionType.partialExit
        ) {
          return true;
        }
        if (fundId) {
          return (
            value <=
            (shareClassIdToClientInvestmentsByFund!.get(captableInvestmentId)?.get(fundId)
              ?.fullyDilutedShares ?? 0)
          );
        } else {
          return value <= (shareClassesById.get(captableInvestmentId)?.clientFullyDilutedShares ?? 0);
        }
      }),
    numberOfSharesPercentage: percentField().required().nullable().label('% of Shares').default(null),
  };
}

export function partialExitBreakdownModel() {
  return yup.object().shape(partialExitBreakdownFields());
}

export type PartialExitBreakdown = yup.InferType<ReturnType<typeof partialExitBreakdownModel>>;

export function createPartialExitBreakdown(
  overrides: Partial<PartialExitBreakdown> = {}
): PartialExitBreakdown {
  return {
    captableInvestmentId: 0,
    fundId: null,
    numberOfShares: 0,
    numberOfSharesPercentage: 0,
    ...overrides,
  };
}

export function scenarioTransactionFormFields(captable?: ICaptableDataModel) {
  return {
    ...scenarioTransactionFields(captable),
    exitDate: dateField().typeError('Invalid date').nullable(),
    id: yup.number(),
    investmentDate: dateField().nullable(),
    scenarioId: yup.number(),
  };
}

export function scenarioFormSchema(captable?: ICaptableDataModel) {
  return yup.object().shape({
    name: yup.string().nullable(),
    scenarioTransactions: yup
      .array()
      .of(
        yup.object().shape({
          ...scenarioTransactionFormFields(captable),
        })
      )
      .min(1, 'At least one transaction is required'),
  });
}

export function scenarioFields() {
  return {
    captableId: yup.number().required(requiredMsg),
    companyId: yup.number().required(requiredMsg),
    createdAt: yup.string(),
    createdBy: yup.string(),
    id: yup.number().required(requiredMsg),
    moic: yup.number().nullable(),
    name: yup.string().required(requiredMsg),
    outdated: yup.boolean().nullable(),
    scenarioTransactions: yup.array().of(yup.object().shape(scenarioTransactionFields())).nullable(),
    tags: yup
      .array()
      .of(yup.string().oneOf(Object.values(ScenarioType)))
      .nullable(),
    updatedAt: yup.string(),
    updatedBy: yup.string(),
  };
}

export function scenarioSchema() {
  return yup.object().shape(scenarioFields());
}

export function scenarioFundDataSchema() {
  return yup.object().shape(scenarioFundDataFields());
}

export function scenarioTransactionSchema(captable?: ICaptableDataModel) {
  return yup.object().shape(scenarioTransactionFields(captable));
}

export type ScenarioFundData = yup.InferType<ReturnType<typeof scenarioFundDataSchema>>;

export type ScenarioTransaction = yup.InferType<ReturnType<typeof scenarioTransactionSchema>>;

export type Scenario = yup.InferType<ReturnType<typeof scenarioSchema>>;

export const scenarioNameSchema = () =>
  yup.object().shape({
    name: yup.string().nullable().label('Name').required(),
  }) as yup.ObjectSchema<TScenarioName>;
