import { FC } from 'react';
import { RendererType } from '../../data-models/field.data-model';
import { ColorStatus } from './ColorStatus/ColorStatus';
import { ColorStatusWithLabel } from './ColorStatus/ColorStatusWithLabel';
import { FieldBoolean } from './Display/FieldBoolean';
import { FieldCompanySearch } from './Display/FieldCompanySearch';
import { FieldCompanySocialLinks } from './Display/FieldCompanySocialLinks';
import { FieldCompanyWebsite } from './Display/FieldCompanyWebsite';
import { FieldDate } from './Display/FieldDate';
import { FieldFile } from './Display/FieldFile';
import { FieldFreeLabels } from './Display/FieldFreeLabels';
import { FieldLocation } from './Display/FieldLocation';
import { FieldMultiSelect } from './Display/FieldMultiSelect';
import { FieldNumeric } from './Display/FieldNumeric';
import { FieldSelect } from './Display/FieldSelect';
import { FieldText } from './Display/FieldText';
import { IBaseFieldProps } from './Display/types';

const FieldRenderers = {
  [RendererType.boolean]: FieldBoolean,
  [RendererType.colorStatusWithLabel]: ColorStatusWithLabel,
  [RendererType.colorStatus]: ColorStatus,
  [RendererType.companySearch]: FieldCompanySearch,
  [RendererType.companySocialMedia]: FieldCompanySocialLinks,
  [RendererType.companyWebsite]: FieldCompanyWebsite,
  [RendererType.currency]: FieldNumeric,
  [RendererType.date]: FieldDate,
  [RendererType.fileUpload]: FieldFile,
  [RendererType.freeLabels]: FieldFreeLabels,
  [RendererType.integer]: FieldNumeric,
  [RendererType.location]: FieldLocation,
  [RendererType.multiSelect]: FieldMultiSelect,
  [RendererType.multiplier]: FieldNumeric,
  [RendererType.number]: FieldNumeric,
  [RendererType.percent]: FieldNumeric,
  [RendererType.singleSelect]: FieldSelect,
  [RendererType.text]: FieldText,
} as Record<RendererType, FC<IBaseFieldProps<unknown>>>;

export function FieldFactory<T>(props: IBaseFieldProps<T>) {
  const Renderer = FieldRenderers[props.formField.renderer];
  if (Renderer) {
    return <Renderer {...props} />;
  }
  return null;
}

export function hasFieldRenderer(rendererType: RendererType) {
  return FieldRenderers[rendererType] !== undefined;
}
