import { css } from '@emotion/react';
import { Typography, IconButton, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { FMT } from '../../../../../util/formatter-service';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';
import { getFormattedFiscalDate } from '../../../utils/financialUtils';

const datePickersContainer = css`
  display: flex;
  gap: 1.2rem;
  align-items: center;
`;

const dateNav = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 11rem; // fix width to prevent jerkiness when changing quarter
`;

interface IFinancialDateSelectorProps {
  fye?: number;
  label: string;
  onNextPeriodClick: () => void;
  onPreviousPeriodClick: () => void;
  selectedDate: Date;
  selectedPeriod: KpiPeriod;
}

export function FinancialDateSelector({
  fye = 12,
  label,
  onNextPeriodClick,
  onPreviousPeriodClick,
  selectedDate,
  selectedPeriod,
}: IFinancialDateSelectorProps) {
  const { colors } = useTheme();
  let formattedDate = '';
  if (selectedPeriod !== KpiPeriod.month) {
    formattedDate = getFormattedFiscalDate(formatISODateOnly(selectedDate), selectedPeriod, fye ?? 12);
  } else if (selectedDate) {
    formattedDate = FMT.format('dayMonth', formatISODateOnly(selectedDate));
  }
  return (
    <div css={datePickersContainer}>
      <Typography variant='body2' color={'text.secondary'}>
        {label}
      </Typography>
      <div css={dateNav}>
        <IconButton onClick={onPreviousPeriodClick} title='previous period'>
          <ChevronLeftIcon color='secondary' />
        </IconButton>
        <Typography variant='body2' color={colors.primary[60]}>
          {formattedDate}
        </Typography>
        <IconButton onClick={onNextPeriodClick} title='next period'>
          <ChevronRightIcon color={'secondary'} />
        </IconButton>
      </div>
    </div>
  );
}
