import { FC, useCallback } from 'react';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';

import { IField, ITextFieldConfig } from '../../../data-models/field2.data-model';
import { useCustomFieldsActions } from '../CustomFields/hooks/useCustomFieldsActions';
import { CustomFieldForm } from '../forms/CustomFieldForm';
import { getCustomTextFieldInitialValues } from '../forms/initialValues/CustomFieldInitialValues';

interface CreateCustomFieldProps {
  open: boolean;
  onClose: () => void;
}

export const CreateCustomField: FC<CreateCustomFieldProps> = ({ open, onClose }) => {
  const { createField } = useCustomFieldsActions();

  const onSubmit = useCallback(
    async (data: Partial<IField<unknown>>) => {
      // some fields might not be registered in the hook, so add them first and overwrite if needed
      const payload = getCustomTextFieldInitialValues(data as Partial<IField<string, ITextFieldConfig>>);
      await createField(payload as IField<unknown>);
      onClose();
    },
    [createField, onClose]
  );

  return (
    <BasicDialog title={'Create Custom Field'} open={open} onClose={onClose}>
      <CustomFieldForm onClose={onClose} onSubmit={onSubmit} />
    </BasicDialog>
  );
};
