import { styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';

const SkeletonLoaderWrapper = styled('div')`
  width: 100%;
`;

export function GenericSectionSkeletonLoader() {
  return (
    <SkeletonLoaderWrapper>
      <ContentLoader speed={2} width={'100%'} height={480}>
        <rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonLoaderWrapper>
  );
}
