import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { GenericFallbacksWrapper } from '../../components/Fallback/GenericFallbacksWrapper';
import { DealBoardSkeletonLoader } from '../../components/SkeletonLoader/DealBord.SkeletonLoader';
import { DealBoardDealCategory } from '../../types';
import { MaxHeaderHeightInRems } from '../../constants/styles';
import { CategoryDealTable } from './components/CategoryDealTable';
import { DealBoard } from './components/DealBoard/DealBoard';
import { DealDrawer } from './components/DealDrawer/DealDrawer';
import { DealFlowHeaderV2 } from './components/DealFlowHeaderV2';
import { FilterOptions } from './components/FilterOptions';
import { useInitDealBoard } from './hooks/useInitDealBoard';
import { useSyncFromPath } from './hooks/useSyncFromPath';
import { isOpenDrawerState, isPresentModeState, selectedDealCategoryState } from './state/DealboardUIState';

const Container = styled('div')<{ presentMode: boolean }>`
  height: calc(100vh - ${MaxHeaderHeightInRems}rem);
  ${({ presentMode }) => presentMode && 'height: 100vh'};
  width: 100%;
  max-height: calc(100vh - ${MaxHeaderHeightInRems}rem);
  ${({ presentMode }) => presentMode && 'max-height: 100vh'};
  ${({ presentMode }) => (presentMode ? `position: absolute;` : '')};
  ${({ presentMode }) => (presentMode ? `top: 0;` : '')};
  ${({ presentMode }) => (presentMode ? `left: 0;` : '')};
  ${({ presentMode }) => (presentMode ? `max-width: 100vw;` : '')};
  ${({ presentMode }) => (presentMode ? `min-width: 100vw;` : '')};
`;

export const DealFlowPreLoader = () => {
  const initialized = useInitDealBoard();

  return initialized ? <DealFlow /> : <DealBoardSkeletonLoader />;
};

export function DealFlow() {
  const [isOpenDrawer, setIsOpenDrawer] = useRecoilState(isOpenDrawerState);
  const isPresentMode = useRecoilValue(isPresentModeState);
  const resetDealCategory = useResetRecoilState(selectedDealCategoryState);

  const initialized = useSyncFromPath();

  useEffect(() => {
    return () => {
      resetDealCategory();
    };
  }, [resetDealCategory]);

  if (!initialized) return null;

  return (
    <>
      <Drawer
        anchor={'right'}
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        disablePortal={true}
      >
        <DealDrawer />
      </Drawer>
      <>
        <Container presentMode={isPresentMode}>
          <DealFlowHeaderV2 />
          <FilterOptions />
          <GenericFallbacksWrapper>
            <Outlet />
          </GenericFallbacksWrapper>
        </Container>
      </>
    </>
  );
}

export function DealsView() {
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);

  return (
    <GenericFallbacksWrapper>
      {selectedDealCategory.id === DealBoardDealCategory.CURRENT ? <DealBoard /> : <CategoryDealTable />}
    </GenericFallbacksWrapper>
  );
}
