import { Stack, styled, Typography, useTheme } from '@mui/material';
import { formatMonthYearLong } from '../../util/formatters/DateFormatters';

const MessageScreenWrapper = styled('div')`
  background: linear-gradient(
      0.75deg,
      #e2e2ff 0.71%,
      rgba(234, 235, 253, 0.76) 38.63%,
      rgba(242, 243, 254, 0.88) 67.64%,
      #fafdff 99.43%
    ),
    #ffffff;
  background-size: cover;
  height: 100%;
  display: grid;
  grid-template-rows: 400px 1fr 100px;
`;

export function MessageScreen({ children }: { children: React.ReactNode }) {
  const { colors } = useTheme();
  const date = new Date().toISOString();
  const [, currentYear] = formatMonthYearLong(date).split(' ');

  return (
    <MessageScreenWrapper>
      {children}
      <Stack
        direction='row'
        alignItems={'end'}
        p={'1rem 2rem'}
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <Typography
          variant='body2'
          sx={{
            color: colors.neutral[60],
          }}
        >
          © {currentYear} All rights reserved by Foresight Data.
        </Typography>
      </Stack>
    </MessageScreenWrapper>
  );
}
