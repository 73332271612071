import { useCallback, useMemo } from 'react';
import { ColDef, GetRowIdParams, IsFullWidthRowParams } from 'ag-grid-community';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSetAtom } from 'jotai';
import { Stack, Typography } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useRecoilValue } from 'recoil';
import { ISchema } from 'yup';
import { get } from 'lodash-es';
import { CapitalAllocation } from '../../../schemas/FundCapitalAllocation.schema';
import { AgTableWithBaseStyle } from '../../../components/AgTable/AgTable2';
import { selectedFundStateFP, showProjectionFormState } from '../state/FPState';
import { MenuButton } from '../../Finance2/common-grid-defs/MenuButton';
import { colors } from '../../../theme/colors';
import { fundFormSchema } from '../../../schemas/Fund.schema';
import { formatFieldValue } from '../../../util/schema-utils';
import { agBorderColor } from '../../../theme/ag-foresight';
import { StyledTableWrapper } from '../../../components/AgTable/AgTable';
import {
  CapitalAllocationRowData,
  committedCapitalRow,
  getCapitalAllocationColDefs,
  getCapitalAllocationRowData,
  NameColumnWidth,
} from './capitalAllocationGridDefs';

const fixedCellWidth = 200; // we need this for committed capital full width row alignment
const defaultColDef: ColDef = { minWidth: fixedCellWidth, maxWidth: fixedCellWidth, resizable: false };

export function CapitalAllocationGrid({ data }: { data?: CapitalAllocation[] | null }) {
  const rowData = useMemo(() => {
    if (!data) return;
    return getCapitalAllocationRowData(data);
  }, [data]);
  const colDefs = useMemo(() => {
    if (!data) return [];
    return getCapitalAllocationColDefs(data);
  }, [data]);

  const getRowId = useCallback((params: GetRowIdParams<CapitalAllocationRowData>) => params.data?.field, []);
  const committedCapitalData = useMemo(() => [committedCapitalRow()], []);

  return (
    <>
      <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} mb={'0.2rem'}>
        <Typography>Fund Allocation</Typography>
        <AllocationActions />
      </Stack>
      <Stack height='70vh' component={StyledTableWrapper}>
        <AgTableWithBaseStyle
          className='ag-theme-1 ag-theme-foresight'
          rowData={rowData}
          columnDefs={colDefs}
          getRowId={getRowId}
          isFullWidthRow={isCommittedCapitalRow}
          fullWidthCellRenderer={CommittedCapitalRowRenderer}
          pinnedTopRowData={committedCapitalData}
          defaultColDef={defaultColDef}
          groupDisplayType='groupRows'
          groupDefaultExpanded={-1}
          rowGroupPanelShow='never'
        />
      </Stack>
    </>
  );
}

function isCommittedCapitalRow(params: IsFullWidthRowParams<CapitalAllocationRowData>) {
  return params.rowNode?.data?.field === 'committedCapital';
}

function AllocationActions() {
  const setShowForm = useSetAtom(showProjectionFormState);
  const menuItems = useMemo(() => {
    return [
      {
        name: 'Edit Fees &  Expenses',
        onClick: () => setShowForm('feesAndExpenses'),
        icon: <EditOutlinedIcon />,
      },
    ];
  }, [setShowForm]);

  return <MenuButton menuItems={menuItems} label='edit allocation data' />;
}

export function CommittedCapitalRowRenderer(params: CustomCellRendererProps<CapitalAllocationRowData>) {
  const { data } = params;
  const fund = useRecoilValue(selectedFundStateFP);
  if (!data || !fund) return null;
  const schema = get(fundFormSchema().fields, 'committedCapital') as ISchema<unknown>;
  return (
    <Stack height='100%' direction='row' sx={{ background: colors.secondary[5] }}>
      <Typography
        variant='body2'
        pl='1rem'
        height='100%'
        display='flex'
        alignItems='center'
        minWidth={`${NameColumnWidth}px`}
        maxWidth={`${NameColumnWidth}px`}
        sx={{ borderRight: `1px solid ${agBorderColor}` }}
      >
        {`${data.name}`}
      </Typography>
      <Typography
        variant='body2'
        height='100%'
        display='flex'
        alignItems='center'
        minWidth='200px'
        maxWidth='200px'
        justifyContent={'flex-end'}
        pr='1rem'
      >{`${schema ? formatFieldValue(schema, fund.committedCapital) : data.committedCapital}`}</Typography>
    </Stack>
  );
}
