import { FC, useEffect } from 'react';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { configurationStepsState } from '../../state/ConfigurationState';
import { useConfigurationSteps } from '../hooks/useConfigurationSteps';
import { useResetConfigState } from '../hooks/useResetConfigState';
import { ConnectedBases } from './ConnectedBases';
import { MatchWithForesight } from './MatchWithForesight';
import { SyncColumns } from './SyncColumns';

export const AirTableConfiguration: FC = () => {
  return <ConfigurationSteps />;
};

export const ConfigSteps = ['Select Base and Table', 'Match with Foresight', 'Synchronize Columns'];

const ConfigurationSteps: FC = () => {
  const activeStep = useRecoilValue(configurationStepsState);
  const { handleStepClick, isActionable } = useConfigurationSteps();

  const resetConfigState = useResetConfigState();

  useEffect(() => {
    return () => {
      resetConfigState();
    };
  }, [resetConfigState]);

  return (
    <>
      <Stepper activeStep={activeStep} style={{ margin: '1rem 0 2rem' }}>
        {ConfigSteps.map((label, index) => {
          return (
            <Step
              key={label}
              active={activeStep === index}
              style={{ paddingLeft: index === 0 ? 0 : undefined }}
              sx={
                isActionable(index)
                  ? {
                      '&:hover .MuiSvgIcon-root': {
                        filter: 'drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.2))',
                      },
                    }
                  : undefined
              }
            >
              <StepButton
                disabled={!isActionable(index)}
                onClick={() => {
                  handleStepClick(index);
                }}
              >
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && <ConnectedBases />}
      {activeStep === 1 && <MatchWithForesight />}
      {activeStep === 2 && <SyncColumns />}
    </>
  );
};
