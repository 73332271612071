import { FC, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import { Button, ButtonBase, Typography, useTheme } from '@mui/material';
import { Dashboard } from '../../../../../types';
import {
  competitiveIntelligenceDashboards,
  selectedDashboardIdCI,
} from '../../../../../pages/CompetitiveIntelligence/state/DashboardsState';
import { MenuStateProvider } from '../../../../DropdownMenu/MenuContext';
import { DashboardMenuItem } from './DashboardMenuItem';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-right: 1rem;
`;

const ListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 212px;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-right: 1rem;
`;

const dummyNewDashboard: Dashboard = {
  config: {
    selectedCategories: [],
  },
  dashboardId: '-1',
  description: 'New Dashboard',
  displayName: 'New Dashboard',
  id: -1,
  isDefault: false,
  name: 'newDashboard',
  version: 1,
};

export const DashboardList: FC = () => {
  const { secondary } = useTheme().colors;
  const dashboards = useRecoilValue(competitiveIntelligenceDashboards);
  const activeDashboardId = useRecoilValue(selectedDashboardIdCI);
  const [, setSearchParams] = useSearchParams();
  const [createMode, setCreateMode] = useState(false);
  const onSelect = useCallback(
    (id: number) => {
      if (!createMode) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('dashboardId', id.toString());
        setSearchParams(searchParams);
      }
    },
    [createMode, setSearchParams]
  );

  const onCreateNewDashboardClick = () => {
    setCreateMode(true);
  };

  if (dashboards == null) {
    return <div>No dashboards found</div>;
  }

  return (
    <MenuStateProvider>
      <Header>
        <Typography variant={'subtitle2'} color={secondary[10]}>
          DASHBOARDS
        </Typography>
        <Button variant={'text'} style={{ color: secondary[20] }} onClick={onCreateNewDashboardClick}>
          Create Dashboard
        </Button>
      </Header>
      {dashboards.length > 0 && (
        <Wrapper>
          <ListWrapper id='list-wrapper'>
            {dashboards.map((dashboard) => (
              <ButtonBase
                key={dashboard.id}
                TouchRippleProps={{ color: 'white' }}
                style={{ width: '100%', display: 'block', textAlign: 'start' }}
              >
                <DashboardMenuItem
                  dashboard={dashboard}
                  isSelected={createMode ? false : activeDashboardId === dashboard.id}
                  onSelect={onSelect}
                  setCreateMode={setCreateMode}
                />
              </ButtonBase>
            ))}
            {createMode && (
              <DashboardMenuItem
                key={0}
                dashboard={dummyNewDashboard}
                isSelected={true}
                createMode={true}
                setCreateMode={setCreateMode}
              />
            )}
          </ListWrapper>
        </Wrapper>
      )}
    </MenuStateProvider>
  );
};
