import { css } from '@emotion/react';
import {
  AgGridEvent,
  ColDef,
  GetRowIdParams,
  GridPreDestroyedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { CustomCellEditorProps } from 'ag-grid-react';
import { useAtomValue } from 'jotai/index';
import { set } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { AgTableWithBaseStyle } from '../../../../../components/AgTable/AgTable2';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import { field2ToFormField, IField } from '../../../../../data-models/field2.data-model';
import {
  IKPIDataValue,
  KPIRequestFrequency,
  periodFromGranularityAndFrequency,
} from '../../../../../data-models/kpi-requests.data-model';
import {
  IKPITableDataModel,
  IKPITemplateSectionDataModel,
} from '../../../../../data-models/kpi-template.data-model';
import { kpiConfigByIdMapAtom } from '../../../../../services/state/KPIConfigState';
import { FMT } from '../../../../../util/formatter-service';
import { IFormField } from '../../../../../view-models/form.view-model';
import { companyProfileFinancialsDefaultColDef } from '../../../../CompanyProfiles/commonGridColDefs';
import { generateKPITableColHeaderData, KPITableColHeaderData } from '../../../utils';

const kpiEditorWrapperCSS = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export interface IKPIRowData {
  kpiId: number;
  formField: IFormField<unknown>;
  valuesByDate: Record<string, unknown>;
}

type DataByKPIIdDateAndSection = Record<number, Record<string, Record<KpiSection, unknown>>>;

export interface IKPIGridSectionProps {
  companyFYEDateString: string;
  kpiRequestFrequency: KPIRequestFrequency;
  kpiRequestGranularity: KPIRequestFrequency | null;
  kpiRequestPeriod: string;
  kpiResponseData: IKPIDataValue[];
  onKPIGridDataUpdated: (section: KpiSection, data: IKPIDataValue[]) => void;
  section: IKPITemplateSectionDataModel;
}

export function KPIAgGrid(props: IKPIGridSectionProps) {
  const {
    companyFYEDateString,
    kpiRequestFrequency,
    kpiRequestGranularity,
    kpiRequestPeriod,
    kpiResponseData,
    onKPIGridDataUpdated,
    section,
  } = props;
  const gridTemplateSettings = section.meta as IKPITableDataModel;
  const kpiConfig = useAtomValue(kpiConfigByIdMapAtom);

  const gridHeaderData = generateKPITableColHeaderData({
    fyeDateString: companyFYEDateString,
    periodISODate: kpiRequestPeriod,
    kpiSection: gridTemplateSettings.section,
    kpiRequestFrequency,
    selectedFrequency: periodFromGranularityAndFrequency(kpiRequestGranularity, kpiRequestFrequency),
  });

  const columnDefs = useMemo(() => {
    return generateKPIColDefs(gridHeaderData);
  }, [gridHeaderData]);

  const kpisToShow = useMemo(() => {
    return gridTemplateSettings.metrics.reduce((allKPIs, metric) => {
      // The kpi object in the settings might be outdated, so we need to get the latest config from the atom
      const kpi = kpiConfig.get(metric.id);
      if (kpi) {
        allKPIs.push(kpi);
      }
      return allKPIs;
    }, [] as IField<unknown>[]);
  }, [gridTemplateSettings.metrics, kpiConfig]);

  const rowData = useMemo(() => {
    return toKPIGridRowData(gridHeaderData, gridTemplateSettings.section, kpisToShow, kpiResponseData);
  }, [gridHeaderData, gridTemplateSettings.section, kpisToShow, kpiResponseData]);

  const onDataChange = useCallback(
    (event: AgGridEvent) => {
      const updatedRowData = event.api.getGridOption('rowData');
      const updatedResponseData = fromKPIGridRowData(
        gridTemplateSettings.section,
        updatedRowData as IKPIRowData[]
      );

      onKPIGridDataUpdated(gridTemplateSettings.section, updatedResponseData);
    },
    [gridTemplateSettings.section, onKPIGridDataUpdated]
  );

  function onGridReady(event: GridReadyEvent) {
    event.api.addEventListener('cellValueChanged', onDataChange);
    event.api.addEventListener('pasteEnd', onDataChange);
  }

  function onGridPreDestroyed(event: GridPreDestroyedEvent) {
    event.api.removeEventListener('cellValueChanged', onDataChange);
    event.api.removeEventListener('pasteEnd', onDataChange);
  }

  function getRowId(params: GetRowIdParams<IKPIRowData>) {
    return params.data.kpiId.toString();
  }

  return (
    <AgTableWithBaseStyle
      columnDefs={columnDefs}
      domLayout={'autoHeight'}
      defaultColDef={companyProfileFinancialsDefaultColDef}
      getRowId={getRowId}
      onGridReady={onGridReady}
      onGridPreDestroyed={onGridPreDestroyed}
      rowData={rowData}
      rowGroupPanelShow='never'
      suppressMovableColumns={true}
      suppressRowDrag={true}
      suppressContextMenu={true}
      suppressCsvExport
      suppressExcelExport
    />
  );
}

export function KPICellEditor(props: CustomCellEditorProps<IKPIRowData>) {
  const { value, onValueChange, stopEditing, data } = props;
  const formProps = {
    onChange: onValueChange,
    onBlur: stopEditing,
    value,
  } as ControllerRenderProps;

  if (data.formField) {
    return (
      <div css={kpiEditorWrapperCSS}>
        <FieldFactory formField={data.formField} formProps={formProps} />
      </div>
    );
  }
  return <div>{value}</div>;
}

function generateKPIColDefs(headerData: KPITableColHeaderData[]) {
  return headerData.reduce(
    (acc, header) => {
      acc.push({
        cellEditor: KPICellEditor,
        editable: true,
        field: `valuesByDate.${header.date}`,
        headerClass: 'centered-header',
        headerName: header.title,
        suppressHeaderMenuButton: true,
        valueFormatter: (params) => {
          if (params.data?.formField) {
            const formatter = FMT.get().getFormatterForFormField(params.data.formField);
            return formatter(params.value);
          }
          return params.value;
        },
      } as ColDef<IKPIRowData>);

      return acc;
    },
    [
      {
        cellStyle: { textAlign: 'left' },
        field: 'formField.label',
        headerName: 'KPI',
        pinned: true,
        suppressHeaderMenuButton: true,
      },
    ] as ColDef[]
  );
}

function toKPIGridRowData(
  gridHeaderData: KPITableColHeaderData[],
  section: KpiSection,
  kpisToShow: IField<unknown>[],
  currentData: IKPIDataValue[]
): IKPIRowData[] {
  const dataByKPIIdDateAndSection = currentData.reduce((res, data) => {
    return set(res, [data.kpiId, data.date, data.section], data.value);
  }, {} as DataByKPIIdDateAndSection);

  return kpisToShow.map((kpi) => {
    const formField = field2ToFormField(kpi);
    formField.autoFocus = true;
    formField.fullWidth = true;

    const valuesByDate: IKPIRowData['valuesByDate'] = {};
    gridHeaderData.forEach((header) => {
      valuesByDate[header.date] = dataByKPIIdDateAndSection[kpi.id]?.[header.date]?.[section] ?? null;
    });

    return { kpiId: kpi.id, formField, valuesByDate };
  }, [] as IKPIRowData[]);
}

function fromKPIGridRowData(kpiSection: KpiSection, rowData: IKPIRowData[]): IKPIDataValue[] {
  return rowData.reduce((res, row) => {
    const { kpiId, valuesByDate } = row;
    Object.entries(valuesByDate).forEach(([date, value]) => {
      if (value != null) {
        // FIXME MAGGIE-8284 Remove this once old code is unused.
        // backwards compatibility start
        const formatter = row.formField
          ? FMT.get().getFormatterForFormField(row.formField)
          : (value: unknown) => value;
        const numberFormatValue = formatter(value);
        // backwards compatibility end.

        res.push({
          kpiId,
          date,
          section: kpiSection,
          numberFormatValue,
          value: value as number,
        } as unknown as IKPIDataValue);
      }
    });
    return res;
  }, [] as IKPIDataValue[]);
}
