import { ButtonBase, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../../constants/RouteNameMapping';
import { SubNavSectionWrapper } from '../../SubSectionNavigation/SubSectionNavItems';
import { SubNavItemWrapper } from '../../SubSectionNavigation/components/SubNavItem/SubNavItem';

const sections = [
  { id: ROUTES.DEAL_ANALYTICS_KPI, value: ROUTE_TITLE_MAPPING[ROUTES.DEAL_ANALYTICS_KPI] },
  {
    id: ROUTES.DEAL_ANALYTICS_DURATION_DETAILS,
    value: ROUTE_TITLE_MAPPING[ROUTES.DEAL_ANALYTICS_DURATION_DETAILS],
  },
];
export const AnalyticsSectionsList: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedSection = useMemo(() => pathname.split('/')?.pop(), [pathname]);
  const { secondary } = useTheme().colors;
  const handleClick = useCallback(
    (path: ROUTES) => {
      navigate(`${ROUTES.DEAL_FLOW}/${ROUTES.DEAL_ANALYTICS}/${path}`);
    },
    [navigate]
  );

  return (
    <SubNavSectionWrapper>
      {sections.map((section) => (
        <ButtonBase
          key={section.id}
          onClick={() => handleClick(section.id)}
          TouchRippleProps={{ color: 'white' }}
          style={{ width: '100%', display: 'block' }}
          aria-label={section.value}
        >
          <SubNavItemWrapper isSelected={section.id === selectedSection}>
            <Typography variant='caption' color={secondary[10]}>
              {section.value}
            </Typography>
            {section.id === selectedSection && (
              <CheckIcon style={{ color: secondary[10], fontSize: '1rem', marginRight: '4px' }} />
            )}
          </SubNavItemWrapper>
        </ButtonBase>
      ))}
    </SubNavSectionWrapper>
  );
};
