import { CSSProperties, FC, PropsWithChildren, ReactNode, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, useTheme, Collapse, Box, SvgIconProps, TypographyProps } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { isNavigationBarStateOpen } from '../../state/NavigationState';

export const ADDITIONAL_MENU_WIDTH = 320;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background: linear-gradient(182.23deg, #7383cf 0%, #333389 50.61%);
  width: ${ADDITIONAL_MENU_WIDTH}px;
  backdrop-filter: blur(4px);
`;
const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  width: 100%;
  padding: 15px 2rem 0 2rem;
  overflow: auto;
  padding-bottom: 1.5rem;
`;

const TitleWrapper = styled('div')`
  margin-bottom: 0.25rem;
`;

interface IProps {
  title: string;
  children: ReactNode;
}

export const NavigationWrapper: FC<IProps> = ({ title, children }) => {
  const [isOpen] = useRecoilState(isNavigationBarStateOpen);
  const { secondary } = useTheme().colors;

  return (
    <Collapse in={isOpen} orientation='horizontal'>
      <Wrapper>
        {isOpen && (
          <ContentWrapper>
            <TitleWrapper>
              <Typography variant={'h6'} color={secondary['10']}>
                {title}
              </Typography>
            </TitleWrapper>
            {children}
          </ContentWrapper>
        )}
      </Wrapper>
    </Collapse>
  );
};

interface ICollapsibleViewSectionProps extends PropsWithChildren {
  title: string;
  defaultExpanded?: boolean;
}
export function CollapsibleViewSection({
  title,
  defaultExpanded = true,
  children,
}: ICollapsibleViewSectionProps) {
  const fontColor = useTheme().colors.secondary['10'];
  return (
    <CollapsibleSection
      title={title}
      iconPosition='end'
      defaultExpanded={defaultExpanded}
      iconProps={{ htmlColor: fontColor }}
      typographyProps={{ color: fontColor, variant: 'subtitle2' }}
    >
      {children}
    </CollapsibleSection>
  );
}

const collapsibleSectionStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

interface ICollapsibleSectionProps extends PropsWithChildren {
  title: string;
  defaultExpanded?: boolean;
  iconPosition?: 'start' | 'end';
  style?: CSSProperties;
  iconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
}
export function CollapsibleSection({
  title,
  iconPosition = 'end',
  defaultExpanded = true,
  children,
  style,
  iconProps,
  typographyProps,
}: ICollapsibleSectionProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const icon = useMemo(
    () => (
      <ChevronRightIcon
        sx={{
          transition: 'transform .1s',
          transform: `${expanded ? 'rotate(90deg)' : ''}`,
        }}
        {...iconProps}
      />
    ),
    [expanded, iconProps]
  );
  return (
    <Box>
      <div
        role='button'
        onClick={() => setExpanded((prev) => !prev)}
        css={collapsibleSectionStyles}
        style={style}
      >
        {iconPosition === 'start' && icon}
        <Typography {...typographyProps}>{title}</Typography>
        {iconPosition === 'end' && icon}
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
}
