import { styled } from '@mui/material/styles';
import { Typography, TypographyVariant } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
import { SelectItem } from '../../types';
import { noOp } from '../../util/no-op';

const { primary } = theme.colors;

export const ToggleDiv = styled('div')<{
  checked: boolean;
  colorMarker?: string;
}>`
  background: ${primary[0]};
  border-radius: 4px;
  border: ${({ checked }) => (checked ? `thin solid ${primary[60]}` : 'thin solid transparent')};
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  padding: 8px 3px 8px 2px;

  min-width: 4rem;

  &:hover {
    background: ${primary[10]};
  }

  ${({ colorMarker }) =>
    colorMarker &&
    `&::before {
    content: '';
    min-width: 4px;
    height: 12px;
    border-radius: 4px;
    margin: auto 4px;
    background: ${colorMarker};
    }`};
`;

export interface ToggleChipProps {
  checked: boolean;
  item: SelectItem;
  onToggle: (value: SelectItem) => void;
  style?: React.CSSProperties;
  colorMarker?: string;
  typographyVariant?: TypographyVariant;
  typographyColor?: string;
}

export function ToggleChip({
  checked,
  onToggle,
  item,
  style,
  colorMarker,
  typographyColor,
  typographyVariant,
}: ToggleChipProps) {
  const onChange = onToggle ? () => onToggle(item) : noOp;

  return (
    <ToggleDiv
      role={'checkbox'}
      aria-checked={checked}
      checked={checked}
      onClick={onChange}
      colorMarker={colorMarker}
      style={style}
    >
      <Typography
        variant={typographyVariant ? typographyVariant : 'subtitle2'}
        color={typographyColor ? typographyColor : theme.colors.primary[100]}
      >
        {item.value}
      </Typography>
    </ToggleDiv>
  );
}
