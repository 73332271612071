import omit from 'lodash-es/omit';
import { IListDataModel, IListDataModelUpdatePayload } from '../../data-models/list.data-model';
import { IListItemDataModel, ListItemsResponse } from '../../data-models/list-item.data-model';
import { MaggieHttp } from '../MaggieHttp';
import { IListItemCommentDataModel } from '../../pages/DealFlow2/data-models/comment.data-model';

export type ListAction = 'track' | 'pipeline';

export async function fetchLists() {
  const { data } = await MaggieHttp.maggie.get<IListDataModel[]>(`list`);
  data.sort((a, b) => (a?.sortOrder as number) - (b?.sortOrder as number));
  return data;
}

export async function fetchListItems(listId: number) {
  const { data } = await MaggieHttp.maggie.get<ListItemsResponse>(`list/${listId}/items`);

  return data;
}

export async function updateListItem(payload: Partial<IListItemDataModel>, listItemId: number) {
  const { data } = await MaggieHttp.maggie.put<Partial<IListItemDataModel>, Partial<IListItemDataModel>>(
    `/list/item/${listItemId}`,
    payload
  );

  return data;
}

export interface IAddListItemToPipelinePayload {
  action: boolean;
  actionType: ListAction;
}

export async function requestAddListItemToPipeline(listItemId: number) {
  const { data } = await MaggieHttp.maggie.put<IAddListItemToPipelinePayload, null>(
    `/list/item/${listItemId}`,
    {
      action: true,
      actionType: 'pipeline',
    }
  );

  return data;
}

export const uploadList = async (list: IListDataModel) => {
  const payload: IListDataModelUpdatePayload = omit(list, 'id');
  const { data } = await MaggieHttp.maggie.post<
    IListDataModelUpdatePayload,
    { token: string; list: IListDataModel }
  >(`/list/upload`, payload);
  return data;
};

export const createListItem = async (listId: number, companyId: number) => {
  const { data } = await MaggieHttp.maggie.post<{ companyId: number }, IListItemDataModel>(
    `list/${listId}/items`,
    {
      companyId,
    }
  );
  return data;
};

export const updateList = async (list: Partial<IListDataModel>, listId: number) => {
  const { data } = await MaggieHttp.maggie.put<Partial<IListDataModel>, IListDataModel>(
    `/list/${listId}`,
    list
  );
  return data;
};

export async function createListItemComment(companyId: number, comment: string) {
  return await MaggieHttp.maggie.put<{ companyId: number; comment: string }, IListItemCommentDataModel>(
    `/list/comment`,
    {
      companyId,
      comment,
    }
  );
}

export async function updateListItemComment(commentId: number, companyId: number, comment: string) {
  return await MaggieHttp.maggie.put<
    { comment: string; companyId: number; id: number },
    IListItemCommentDataModel
  >(`/list/comment`, {
    comment,
    companyId,
    id: commentId,
  });
}

export async function deleteListItemComment(commentId: number) {
  return await MaggieHttp.maggie.delete(`/list/comment/${commentId}`);
}
