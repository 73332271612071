import { useCallback, useMemo } from 'react';
import {
  conversionRatioField8dp,
  percentField,
  sharesField,
  simpleMultilineTextField,
  useFundIdField,
  useGetCurrencyIdField,
} from '../../../data-fields/CommonFields';
import {
  compoundingPeriodField,
  couponTypeField,
  maturityDateField,
  securityField,
  transactionDateField,
} from '../../../data-fields/TransactionFields';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';

import { useGetAmountField } from './TransactionFormUtils';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useNoteFields() {
  const optionalFundIdField = useFundIdField({ required: false });
  const getCurrencyIdField = useGetCurrencyIdField();
  const getAmountField = useGetAmountField<ITransactionDataModel>('currencyid');
  const getBaseCommonFields = useFundDateCurrencyFields();
  const commonInitialFields = useMemo(() => {
    return [...getBaseCommonFields()];
  }, [getBaseCommonFields]);

  const commonNoteAndSafeFields = useMemo(() => {
    return [
      getAmountField({ key: 'principalAmount', label: 'Principal', required: true }),
      maturityDateField(),
      compoundingPeriodField({ key: 'compoundingPeriod', label: 'Compounding Period' }),
      getAmountField({ key: 'valuationCap', label: 'Valuation Cap' }),
      percentField({ key: 'discountRate', label: 'Discount Rate' }),
      getAmountField({ key: 'triggerPrice', label: 'Trigger Price' }),
    ];
  }, [getAmountField]);

  const commonInitialConversionFields = useMemo(() => {
    return [
      ...commonInitialFields,
      securityField(), //
      sharesField({
        key: 'convertedSharesNo',
        label: `Number of Converted Shares`,
        required: true,
      }),
      getAmountField({ key: 'convertedPrincipalAmount', label: 'Converted Principal', required: true }),
    ];
  }, [getAmountField, commonInitialFields]);

  const getNoteIssuanceFields = useCallback(() => {
    return [
      optionalFundIdField,
      transactionDateField(),
      getCurrencyIdField('currencyid', 'Currency', true),
      percentField({ key: 'coupon', label: 'Coupon' }),
      couponTypeField(),
      ...commonNoteAndSafeFields,
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [optionalFundIdField, getCurrencyIdField, commonNoteAndSafeFields]);

  const getNoteConversionFields = useCallback(() => {
    return [
      ...commonInitialConversionFields,
      getAmountField({ key: 'convertedInterestAmount', label: 'Converted Interest', required: true }),
      conversionRatioField8dp(),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [commonInitialConversionFields, getAmountField]);

  const getSafeConversionFields = useCallback(() => {
    return [
      ...commonInitialConversionFields,
      conversionRatioField8dp(),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [commonInitialConversionFields]);

  const getSafeFields = useCallback(() => {
    return [
      ...commonInitialFields,
      ...commonNoteAndSafeFields,
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [commonInitialFields, commonNoteAndSafeFields]);

  const principalWriteOffField = useMemo(() => {
    return getAmountField({ key: 'principalWriteOffAmount', label: 'Amount Write Off', required: true });
  }, [getAmountField]);

  const getExpirationFields = useCallback(() => {
    return [
      ...getBaseCommonFields(),
      principalWriteOffField,
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [principalWriteOffField, getBaseCommonFields]);

  const getSafeExpirationFields = useCallback(() => {
    return [
      ...getBaseCommonFields(),
      principalWriteOffField,
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [principalWriteOffField, getBaseCommonFields]);

  return {
    getNoteIssuanceFields,
    getNoteConversionFields,
    getSafeConversionFields,
    getSafeFields,
    getExpirationFields,
    getSafeExpirationFields,
  };
}
