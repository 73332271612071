import { PropsWithChildren, useMemo, useState, MouseEvent } from 'react';
import { Button, ChipProps, Popover } from '@mui/material';
import { css } from '@emotion/react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { UnstyledChipSelector } from '../../../../components/ChipSelector';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { ISimpleChoice } from '../../../../data-models/field2.data-model';
import { colors } from '../../../../theme/colors';
import { rangeOptions } from './financeDatePickerUtils';

export const chipStack = css`
  display: grid;
  row-gap: 0.5rem;
  align-content: start;
`;

const commonChipProps: ChipProps = {
  sx: {
    minWidth: '8.5rem',
  },
};

interface IFinDatePickerProps<T> {
  value: T | null;
  onChange: (value: T) => void;
}

const frequencyOptions: ISimpleChoice<KpiPeriod>[] = Object.values(KpiPeriod).map((period) => ({
  value: period,
  displayName: `${period}ly`,
}));

export function PeriodPicker({ value, onChange }: IFinDatePickerProps<KpiPeriod>) {
  return (
    <UnstyledChipSelector
      options={frequencyOptions}
      value={value}
      onChange={onChange}
      chipProps={commonChipProps}
    />
  );
}

interface IPeriodRangePickerProps extends IFinDatePickerProps<number> {
  frequency: KpiPeriod;
  future?: boolean;
}

export function PeriodRangePicker({ value, onChange, frequency, future }: IPeriodRangePickerProps) {
  const options = useMemo(() => rangeOptions(frequency, future), [frequency, future]);
  return (
    <UnstyledChipSelector options={options} value={value} onChange={onChange} chipProps={commonChipProps} />
  );
}

interface IFinanceDateSelectorProps extends PropsWithChildren {
  label: string;
}
export function FinanceDateSelector({ label, children }: IFinanceDateSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        variant='outlined'
        color='inherit'
        onClick={handleClick}
        endIcon={<DateRangeIcon sx={{ opacity: '0.7' }} />}
        size='medium'
        sx={{ borderColor: colors.neutral[30] }}
      >
        {label}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </>
  );
}
