export function extractISODate(dateStr: string): string {
  return dateStr.split('T')[0];
}

export function dateToPlainDateString(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function plainDateToZonedDateTime(isoDate: string): Date {
  const [year, month, day] = extractISODate(isoDate).split('-');

  return new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0);
}
