import { FC } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const CellContainer = styled('div')`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ILinkButtonRendererProps {
  onClick: () => void;
  title?: string;
}

export const LinkButtonRenderer: FC<ILinkButtonRendererProps> = ({ onClick, title }) => {
  return (
    <CellContainer>
      <IconButton onClick={onClick} style={{ alignSelf: 'center' }} title={title}>
        <LaunchIcon color={'secondary'} />
      </IconButton>
    </CellContainer>
  );
};
