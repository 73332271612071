import React, { useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { field3ToFormField, IField3 } from '../../../data-models/field3.data-model';
import {
  createForm,
  createFormFromFieldsArray,
  IForm,
  IFormField,
} from '../../../view-models/form.view-model';
import { ConfigModal, DynamicFormTransformer, useConfigModal } from './ConfigModal';
import { DataWithId, useConfigActions } from './UseConfigActions';
import { ConfigPageContents } from './ConfigPageContents2';

export interface IGenericConfigPageProps<T extends FieldValues & DataWithId> {
  baseTitle: string;
  customToolbarElem?: React.JSX.Element;
  data: T[];
  defaultValues?: Partial<T>;
  fields: IField3<unknown>[];
  formFields?: IFormField<unknown>[];
  isSortable?: boolean;
  itemIsDisabled?: (item: T) => boolean;
  pageTitle: string;
  queryURL: string;
  transformBeforeSubmit?: (data: T) => T;
  dynamicFormTransformer?: DynamicFormTransformer<T>;
}
export function GenericConfigPage<T extends FieldValues & DataWithId>(props: IGenericConfigPageProps<T>) {
  const {
    baseTitle,
    customToolbarElem,
    data,
    defaultValues = {},
    fields,
    isSortable,
    itemIsDisabled,
    pageTitle,
    queryURL,
    transformBeforeSubmit,
  } = props;
  const { open, handleOpen, handleClose, isEdit } = useConfigModal();
  const { bulkUpdateItems, updateItem, deleteItem } = useConfigActions(queryURL);
  const formFields = props.formFields ? props.formFields : fields.map((field) => field3ToFormField(field));
  const [currentForm, setCurrentForm] = useState(createFormFromFieldsArray(formFields));
  const forms = data.map((data) =>
    createFormFromFieldsArray(formFields, { data, disabled: itemIsDisabled?.(data) ?? false })
  );
  const _updateItem = useCallback(
    async (formData: T) => {
      const formPayload = transformBeforeSubmit?.(formData) ?? formData;
      await updateItem(formPayload, isEdit);
    },
    [isEdit, transformBeforeSubmit, updateItem]
  );
  const _bulkUpdateItems = useCallback(
    async (formsData: T[]) => {
      const payload = formsData.map((formData) => {
        return transformBeforeSubmit?.(formData) ?? formData;
      });

      await bulkUpdateItems(payload);
    },
    [bulkUpdateItems, transformBeforeSubmit]
  );

  return (
    <>
      <ConfigPageContents
        createBtnLabel={`Create ${baseTitle}`}
        customToolbarElem={customToolbarElem}
        onCreate={() => {
          setCurrentForm(createForm(createFormFromFieldsArray(formFields, { data: defaultValues })));
          handleOpen(false);
        }}
        onEdit={(item) => {
          setCurrentForm(createForm(createFormFromFieldsArray(formFields, { data: item })));
          handleOpen(true);
        }}
        onDelete={async (item) => {
          await deleteItem(item);
        }}
        onReorder={isSortable ? _bulkUpdateItems : undefined}
        forms={forms}
        title={pageTitle}
      />
      <ConfigModal
        form={currentForm as IForm<T>}
        onClose={handleClose}
        onSubmit={_updateItem}
        open={open}
        title={isEdit ? `Edit ${baseTitle}` : `Create ${baseTitle}`}
        dynamicFormTransformer={props.dynamicFormTransformer}
      />
    </>
  );
}
