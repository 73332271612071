import { Dashboard } from '../types';

export const tempDashboards = [
  {
    id: 641,
    dashboardId: '1',
    name: 'universal-1',
    displayName: 'Company Summary',
    description: 'Company profile and leadership summary',
    type: 'competitive-intelligence',
    version: 1,
    isDefault: true,
    config: {
      selectedCategories: ['d64ac85b-9b38-4998-b062-498371abaaad', '6490e207-fe11-48c6-b81b-d4c689840a2d'],
    },
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-24T18:26:57.902Z',
    updatedAt: '2022-08-12T15:34:43.490Z',
  },
  {
    id: 642,
    dashboardId: '2',
    name: 'universal-2',
    displayName: 'Funding & Company Details',
    description: 'Funding & Company Details',
    type: 'competitive-intelligence',
    version: 1,
    isDefault: true,
    config: {
      selectedCategories: [
        'd64ac85b-9b38-4998-b062-498371abaaad',
        '71a1541b-7528-4854-917d-99302b09fdef',
        '6490e207-fe11-48c6-b81b-d4c689840a2d',
        '80134cda-5a03-44cb-9bae-47cb5d5fb0a1',
      ],
    },
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-24T18:27:31.562Z',
    updatedAt: '2022-08-12T15:40:09.135Z',
  },
  {
    id: 643,
    dashboardId: '3',
    name: 'universal-3',
    displayName: 'Headcount & Social Media',
    description: 'Headcount & Social Media',
    type: 'competitive-intelligence',
    version: 1,
    isDefault: true,
    config: {
      selectedCategories: [
        'beead523-563d-4e48-bf2e-ac349ed81dd5',
        '15a2603b-c642-463e-8883-c5e7061d3b36',
        '2dfbe766-de0a-4dec-a2f2-bb86fb53732c',
        '116380fb-00ea-4d38-8d69-673d8e6c1995',
        '65e75546-0710-4fa2-bdd6-b9cb0a9e5a7e',
      ],
    },
    createdBy: 'emiliano.mongelo@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-06-24T18:27:59.862Z',
    updatedAt: '2022-08-12T15:44:56.902Z',
  },
  {
    id: 796,
    dashboardId: '4',
    name: 'universal-4',
    displayName: 'View for Company Profiles',
    description: 'View for Company Profiles',
    type: 'company-profile',
    version: 1,
    isDefault: true,
    config: {
      selectedCategories: [
        'beead523-563d-4e48-bf2e-ac349ed81dd5',
        '4fbd69a6-84d8-41be-af71-14961d681901',
        '45d7dd9f-72ad-45e2-aa75-fc6395b856a8',
        'fea2a94d-d597-4965-972a-d3de52853716',
        'c51640cb-f263-4a70-bafe-1c5f2ec9ad06',
        '15a2603b-c642-463e-8883-c5e7061d3b36',
        '2dfbe766-de0a-4dec-a2f2-bb86fb53732c',
        '116380fb-00ea-4d38-8d69-673d8e6c1995',
        '65e75546-0710-4fa2-bdd6-b9cb0a9e5a7e',
        'd9bd24df-37c6-4315-ad87-ca9d19f43541',
        'a6b781ba-6a5c-4fe2-a3aa-fb6eb0d477fb',
      ],
    },
    createdBy: 'antonio.buddington@greycroft.com',
    updatedBy: 'antonio.buddington@greycroft.com',
    createdAt: '2022-11-03T04:32:22.549Z',
    updatedAt: '2022-11-16T13:11:41.815Z',
  },
  {
    id: 760,
    dashboardId: 'personal_83dda7fb-bbf6-401f-89c4-01b2f7166bc8',
    name: 'personal_83dda7fb-bbf6-401f-89c4-01b2f7166bc8',
    displayName: "Jason's View",
    description: 'Personal dashboard',
    type: 'competitive-intelligence',
    version: 1,
    isDefault: false,
    config: {
      selectedCategories: [
        '228f27bb-7907-4ed4-98a1-15cb4760b771',
        '5ce284bb-c0ca-4781-a789-ded5f501c77a',
        '9e4d8961-ddad-4b37-a120-e4ca353264ee',
        '4a54877c-53dc-4f3b-a18a-54c0c20200c6',
        'd64ac85b-9b38-4998-b062-498371abaaad',
        '15a2603b-c642-463e-8883-c5e7061d3b36',
        '2dfbe766-de0a-4dec-a2f2-bb86fb53732c',
        '116380fb-00ea-4d38-8d69-673d8e6c1995',
        '65e75546-0710-4fa2-bdd6-b9cb0a9e5a7e',
        'd9bd24df-37c6-4315-ad87-ca9d19f43541',
        'a6b781ba-6a5c-4fe2-a3aa-fb6eb0d477fb',
        '80134cda-5a03-44cb-9bae-47cb5d5fb0a1',
        '561ef4eb-000a-4aa9-893c-d72d711817b2',
        '6490e207-fe11-48c6-b81b-d4c689840a2d',
        'beead523-563d-4e48-bf2e-ac349ed81dd5',
        '7ce99a67-ed97-4929-83a0-c07b85c14f71',
        '4fbd69a6-84d8-41be-af71-14961d681901',
        'f58612a4-7cb3-4142-8d04-66923795ebf9',
      ],
    },
    createdBy: 'jason@greycroft.com',
    updatedBy: 'jason@greycroft.com',
    createdAt: '2022-06-30T15:11:21.660Z',
    updatedAt: '2023-01-12T15:56:14.436Z',
  },
  {
    id: 795,
    dashboardId: 'personal_159e4249-deb3-4647-9711-e73683a59217',
    name: 'personal_159e4249-deb3-4647-9711-e73683a59217',
    displayName: 'PFM',
    description: 'Personal dashboard',
    type: 'competitive-intelligence',
    version: 1,
    isDefault: false,
    config: {
      selectedCategories: [
        'd64ac85b-9b38-4998-b062-498371abaaad',
        '71a1541b-7528-4854-917d-99302b09fdef',
        '6490e207-fe11-48c6-b81b-d4c689840a2d',
        '561ef4eb-000a-4aa9-893c-d72d711817b2',
        'a6b781ba-6a5c-4fe2-a3aa-fb6eb0d477fb',
        'beead523-563d-4e48-bf2e-ac349ed81dd5',
        '7ce99a67-ed97-4929-83a0-c07b85c14f71',
        '4fbd69a6-84d8-41be-af71-14961d681901',
        'd9bd24df-37c6-4315-ad87-ca9d19f43541',
        '15a2603b-c642-463e-8883-c5e7061d3b36',
        '2dfbe766-de0a-4dec-a2f2-bb86fb53732c',
        '116380fb-00ea-4d38-8d69-673d8e6c1995',
        '65e75546-0710-4fa2-bdd6-b9cb0a9e5a7e',
        'e8dc304c-dfe4-4222-8e21-d29d0ae43e75',
        '64aeedb0-2cb8-4ae2-bdb7-7935b4b47d97',
        'c95092ef-d767-4d80-a949-1b1cf64f426b',
        '45d7dd9f-72ad-45e2-aa75-fc6395b856a8',
        'fea2a94d-d597-4965-972a-d3de52853716',
        'c51640cb-f263-4a70-bafe-1c5f2ec9ad06',
        'f5f50991-6b8a-4296-b18b-d2ec315c3ae1',
        '80134cda-5a03-44cb-9bae-47cb5d5fb0a1',
      ],
    },
    createdBy: 'jason@greycroft.com',
    updatedBy: 'jason@greycroft.com',
    createdAt: '2022-09-28T23:32:47.609Z',
    updatedAt: '2023-01-30T21:47:30.362Z',
  },
] as Dashboard[];
