import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { portCosState } from '../../../../services/state/PortCosState';
import { templateAssignmentsByCompanyIdState } from '../../../../services/state/KPI/KPICompaniesState';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';

export interface IKpiCompanyData extends ICompanyDataModel {
  assigned: boolean;
}

export function useAllCompaniesRowData(): IKpiCompanyData[] {
  const portcos = useRecoilValue(portCosState);
  const assignmentsByCompanyId = useRecoilValue(templateAssignmentsByCompanyIdState);

  return useMemo(() => {
    if (!portcos) return [];
    return portcos.map((company) => ({
      ...company,
      assigned: assignmentsByCompanyId.has(company.id),
    }));
  }, [assignmentsByCompanyId, portcos]);
}
