import { IconButton, Stack, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { ICellRendererParams } from 'ag-grid-community';
import { useSetRecoilState } from 'recoil';
import { kpisAssignRequestModalState } from '../../../../../../services/state/KPI/KPIRequestsState';

/**@deprecated */
export default function ActionsCellRenderer(params: ICellRendererParams) {
  const { colors } = useTheme();
  const openAssignRequestModal = useSetRecoilState(kpisAssignRequestModalState);

  if (params?.node?.group) return null;

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <IconButton onClick={() => openAssignRequestModal(params?.data?.id)} color='secondary' size={'medium'}>
        <AssignmentIcon sx={{ color: colors.primary[60] }} />
      </IconButton>
    </Stack>
  );
}
