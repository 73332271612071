import { useRecoilState } from 'recoil';
import { useCallback, useMemo, useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import { kpiGroupsState } from '../../../services/state/KPI/KPITemplatesState';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { BasicDragHandle, SortableItem } from '../../../components/SortableList/SortableItem';
import { MultiSortableList } from '../../../components/SortableMultiList/MultiSortableList';
import { Card } from '../../../components/Card/Card';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { KPIGroup } from '../../../schemas/kpi-group.schema';
import { useConfigActions } from '../Common/UseConfigActions';
import { KPIGroupConfigBaseURL } from '../KPIGroups/ConfigKPIGroups';

const CardWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled('div')`
  padding: 2rem;
`;

export interface IKPIGroupSortProps {
  closeModal: () => void;
}

function KPIManageGroups(props: IKPIGroupSortProps) {
  const { bulkUpdateItems } = useConfigActions(KPIGroupConfigBaseURL);
  const [kpiGroupsSettings, setKpiGroupsSettings] = useRecoilState(kpiGroupsState);
  const kpiGroups = useMemo(() => {
    return { groups: kpiGroupsSettings };
  }, [kpiGroupsSettings]);

  const renderItem = useCallback((item: KPIGroup) => {
    return (
      <SortableItem id={item.id}>
        <Card>
          <CardWrapper>
            <Typography>{item.name}</Typography>
            <BasicDragHandle />
          </CardWrapper>
        </Card>
      </SortableItem>
    );
  }, []);

  function onOrderChange(items: Record<string, KPIGroup[]>) {
    const updatedGroups = (items as typeof kpiGroups).groups.map((item, index) => {
      return {
        ...item,
        sortOrder: index,
      };
    });

    setKpiGroupsSettings(updatedGroups);
  }

  const onSave = useCallback(() => {
    bulkUpdateItems(kpiGroupsSettings);
  }, [bulkUpdateItems, kpiGroupsSettings]);

  return (
    <ContentWrapper>
      <MultiSortableList items={kpiGroups} onChange={onOrderChange} renderItem={renderItem} />
      <StickyFormButtons onCancel={props.closeModal} onSubmit={onSave} submitLabel={'Save'} />
    </ContentWrapper>
  );
}

export function SortKPIGroups() {
  const [open, setIsOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Button color={'secondary'} onClick={openModal} variant={'contained'}>
        Sort Groups
      </Button>
      {open && (
        <BasicDialog open={open} onClose={closeModal} title={'Sort Groups'}>
          <KPIManageGroups closeModal={closeModal} />
        </BasicDialog>
      )}
    </>
  );
}
