import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Icon } from '@mui/material';
import Facebook from '../../../assets/icons/sm-facebook.svg?react';
import X from '../../../assets/icons/sm-x.svg?react';
import Instagram from '../../../assets/icons/sm-instagram.svg?react';
import Employees from '../../../assets/icons/sm-employees.svg?react';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { companySocialsState } from '../state/CompanySocialsState';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { IKPIProps, KPI } from '../../../components/Card/KPI';
import { FMT } from '../../../util/formatter-service';
import { CompanySocialMedia } from '../../../schemas/CompanySocialMedia.schema';
import { cardStyles } from '../../../theme/component-styles';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { Panel } from './Panel';

const socialMediaCard = css({
  ...cardStyles,
  border: 'none',
  padding: '0.5rem 0.75rem',
});

const cardHolder = css`
  display: grid;
  grid-template-columns: repeat(4, minmax(10rem, 1fr));
  overflow: auto;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem 0.75rem;
`;

const iconHolder = css`
  margin: 0.5rem 0 0;
  min-height: 2.5rem;
`;

const socMediaDesc = 'Current follower count and percentage change over the past 90 days';

const fieldToKpiProps: Partial<Record<keyof CompanySocialMedia, Omit<IKPIProps, 'indicator' | 'value'>>> = {
  facebookFollowers: { icon: <Facebook />, title: 'Facebook', description: socMediaDesc },
  xFollowers: { icon: <X />, title: 'X (formerly Twitter)', description: socMediaDesc },
  instagramFollowers: {
    icon: <Instagram />,
    title: 'Instagram',
    description: socMediaDesc,
  },
  headCount: {
    icon: <Employees />,
    title: 'Employees',
    description: 'Current headcount and percentage change over the past 90 days',
  },
  webTraffic: {
    icon: <Icon />,
    title: 'Web Traffic',
    description: 'Current web traffic and percentage change over the past 90 days',
  },
  linkedinFollowers: {
    icon: <Icon />,
    title: 'LinkedIn',
    description: socMediaDesc,
  },
};

export function CompetitiveIntelligenceSummary() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const socials = useRecoilValue(companySocialsState(companyId));
  const { competitiveIntelligenceModule } = useFlags<MaggieFeatureFlags>();

  const elems = useMemo(() => {
    if (!socials) return null;
    const { facebookFollowers, xFollowers, instagramFollowers, headCount } = socials;
    return Object.entries({
      facebookFollowers,
      xFollowers,
      instagramFollowers,
      headCount,
    }).map(([key, kpi]) => {
      const value = kpi?.count?.ago14;
      const props = fieldToKpiProps[key as keyof CompanySocialMedia]!;
      return (
        <div css={socialMediaCard} key={key as string}>
          <KPI
            icon={<div css={iconHolder}>{props!.icon}</div>}
            title={props!.title}
            description={props!.description}
            value={typeof value === 'number' ? FMT.format('numericShort', value) : '-'}
            indicator={kpi?.percentChange?.ago90 ?? null}
          />
        </div>
      );
    });
  }, [socials]);
  return (
    <Panel
      title={'Competitive Intelligence'}
      showMore={
        competitiveIntelligenceModule
          ? {
              title: 'Go to Competitive Intelligence',
              url: `../${ROUTES.COMPANY_PROFILE_CI}`,
            }
          : undefined
      }
    >
      {!socials ? <NoDataMessage2 /> : <div css={cardHolder}>{elems}</div>}
    </Panel>
  );
}
