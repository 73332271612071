import { ColDef, GetRowIdParams, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../components/AgTable/AgTable';

import { TableStyleWrapper } from '../../CompetitiveIntelligence/components/Tables/TableStyleWrapper';
import { filterOutErrorsState } from '../state/MappingsUIState';
import { lastFieldUpdateDateState, selectedMappingsCompanies } from '../state/MappingsState';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { ErrorInfo } from './ErrorInfo';
import { categoryComparator, IMappingsRowDataModel, isWebsiteProvider } from './mappingDataUtils';
import { useMappingGridColDefs } from './hooks/useMappingGridColDefs';
import { useMappingsRowData } from './hooks/useMappingsRowData';
import { ErrorFilter } from './ErrorFilter';

export const MappingsGrid: FC = () => {
  const filterErrors = useRecoilValue(filterOutErrorsState);
  const [gridApi, setGridApi] = useState<GridApi>();
  const selectedCompanies = useRecoilValue(selectedMappingsCompanies);
  const lastFieldUpdateDate = useRecoilValue(lastFieldUpdateDateState);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const isExternalFilterPresent = useCallback(() => filterErrors, [filterErrors]);
  const doesExternalFilterPass = useCallback((node: IRowNode<IMappingsRowDataModel>) => {
    const { data } = node;
    if (!data) return false;
    return data.hasError || isWebsiteProvider(data);
  }, []);

  const { rowData, loading } = useMappingsRowData();

  const colDefs = useMappingGridColDefs();
  const defaultColDefs: ColDef = {
    flex: 1,
    width: 200,
    minWidth: 200,
    resizable: true,
  };
  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      width: 260,
      pinned: 'left',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      comparator: categoryComparator,
      initialSort: 'asc',
      sortable: false,
    }),
    []
  );

  const getRowId = useCallback((params: GetRowIdParams) => params.data?.id, []);

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [filterErrors, gridApi]);

  const gridKey = useMemo(() => selectedCompanies.map((c) => c.id).join(','), [selectedCompanies]);

  if (!rowData && selectedCompanies.length > 0 && !loading)
    return (
      <div style={{ marginTop: '2rem' }}>
        <NoDataMessage2 />
      </div>
    );
  if (!rowData || selectedCompanies.length === 0) return null;

  return (
    <div style={{ position: 'relative' }}>
      <ErrorFilter />
      <ErrorInfo rowData={rowData} />
      <TableStyleWrapper className='Transposed' distanceFromTopInRems={10} style={{ width: '100%' }}>
        <AgTable
          key={gridKey + lastFieldUpdateDate}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDefs}
          groupDefaultExpanded={1}
          groupIncludeFooter={false}
          groupIncludeTotalFooter={false}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          rowGroupPanelShow={'never'}
          autoGroupColumnDef={autoGroupColumnDef}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          getRowId={getRowId}
        />
      </TableStyleWrapper>
    </div>
  );
};
