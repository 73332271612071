import { Button, Typography, useTheme } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import { FC, useCallback, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useRecoilValue } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { useCompanyActions } from '../Summary/hooks/useCompanyActions';
import { trackingByCompanyIdState } from '../state/ListsState';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useCurrentUser } from '../../../services/hooks/useCurrentUser';
import { isDuplicateDealResponseError, useCreateDeal } from '../../DealFlow2/hooks/useDealActions';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { DuplicateDealInfo } from '../../DealFlow2/components/DealModal/DealAddModalContent/DuplicateDealInfo';
import { getErrorMessage } from '../../../services/queryHelpers';
import { TrackingSignifier } from './TrackingSignifier';

interface ITrackedListActionsProps {
  company: ICompanyDataModel;
}

export const TrackedListActions: FC<ITrackedListActionsProps> = ({ company }) => {
  const { pushInfoToast, pushErrorToast } = useToastMessageState();
  const { handleTrackCompany2, handleUntrackCompany } = useCompanyActions();
  const trackingMap = useRecoilValue(trackingByCompanyIdState);
  const trackingData = trackingMap.get(company.id);
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const createDeal = useCreateDeal();
  const [duplicateDeal, setDuplicateDeal] = useState<IDealDataModel | null>(null);
  const [showDuplicateDealInfo, setShowDuplicateDealInfo] = useState(false);
  const { colors } = useTheme();

  const handleCreateDeal = useCallback(
    async (byPassDuplicate = false) => {
      const defaultPayload = {
        dealTypeId: 1,
        dealLeadId: currentUser.id,
        companyId: company.id,
        stageId: 2,
      };
      await createDeal(defaultPayload, byPassDuplicate, false);
    },
    [company.id, createDeal, currentUser.id]
  );
  const onAddToPipeline = useCallback(async () => {
    try {
      await handleCreateDeal(false);
      setDuplicateDeal(null);
    } catch (err) {
      if (isDuplicateDealResponseError(err)) {
        setDuplicateDeal(err.response.data.deal);
        setShowDuplicateDealInfo(true);
      } else {
        const message = getErrorMessage(err, 'Failed to create deal');
        pushErrorToast({ message });
      }
    }
  }, [handleCreateDeal, pushErrorToast]);

  const onUndo = useCallback(() => {
    if (!trackingData) return;
    handleTrackCompany2(company, trackingData);
  }, [company, handleTrackCompany2, trackingData]);

  const handleRemove = useCallback(async () => {
    setShowConfirmDelete(false);
    await handleUntrackCompany(company);
    pushInfoToast({
      message: `Company ${company.name} was removed from list "Tracked companies"`,
      buttonAction: onUndo,
      buttonLabel: 'Undo',
    });
  }, [company, handleUntrackCompany, onUndo, pushInfoToast]);

  return (
    <>
      <ConfirmDeletionDialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={handleRemove}
        title={`Remove from "Tracked companies"`}
      >
        <>
          <Typography
            variant='body2'
            sx={{ pt: '.25rem', minWidth: '30rem' }}
          >{`Are you sure you want to remove ${company.name} from "Tracked companies"`}</Typography>
        </>
      </ConfirmDeletionDialog>

      <BasicDialog
        open={showDuplicateDealInfo && !!duplicateDeal}
        onClose={() => setShowDuplicateDealInfo(false)}
        titleComponent={
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <ReportProblemIcon sx={{ color: colors.critical[50] }} fontSize='small' />
            Deal already exists
          </Typography>
        }
      >
        <DuplicateDealInfo
          deal={duplicateDeal!}
          onCancel={() => setShowDuplicateDealInfo(false)}
          onConfirm={() => {
            setShowDuplicateDealInfo(false);
            handleCreateDeal(true);
          }}
          showTitle={false}
          style={{ padding: '0 2.5rem 2rem', margin: 0, width: '40rem' }}
        />
      </BasicDialog>

      <TrackingSignifier
        company={company}
        iconProps={{ fontSize: 'medium', color: 'secondary', style: { marginTop: '.25rem' } }}
      />
      <Button
        variant='outlined'
        color={'secondary'}
        startIcon={<ClearIcon />}
        onClick={() => setShowConfirmDelete(true)}
      >
        Remove
      </Button>
      <Button variant='outlined' color={'secondary'} startIcon={<AddIcon />} onClick={onAddToPipeline}>
        Add to pipeline
      </Button>
    </>
  );
};
