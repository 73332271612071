import {
  formatInteger,
  formatMultiplier,
  formatNumber2dp,
  formatPercent2dpAsIs,
  formatUSDShort,
} from '../../../../util/formatters/NumericFormatters';

function formatMultiple(val: number): string {
  if (isNaN(val)) return '';
  return formatMultiplier(val);
}

export const KPIFormatterTypesMap = {
  currency: 'currency',
  multiple: 'multiple',
  percentage: 'percentage',
  number: 'number',
  integer: 'integer',
  numeric: 'numeric',
};

export function format(val: number, type: string): string {
  switch (type) {
    case KPIFormatterTypesMap.currency:
      return formatUSDShort(val);
    case KPIFormatterTypesMap.multiple:
      return formatMultiple(val);
    case KPIFormatterTypesMap.percentage:
      return formatPercent2dpAsIs(val);
    case KPIFormatterTypesMap.number:
      return formatNumber2dp(val);
    case KPIFormatterTypesMap.numeric:
      return formatInteger(val);
    case KPIFormatterTypesMap.integer:
      return formatInteger(val);
    default:
      throw new Error('Format: unsupported type');
  }
}
