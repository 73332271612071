import { Fade, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const GridContainer = styled('div')`
  width: 100%;
  height: 100%;
`;

/**
 * This wrapper is intended to hide the initial jumpiness of ag-grid columns due to the `flex` property;
 * Especially useful when there are multiple tabs, each with it's own grid.
 */
export const FadeInGridWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Fade in={true} timeout={1000}>
      <GridContainer>{children}</GridContainer>
    </Fade>
  );
};
