import { TRANSACTION_SUB_TYPES } from '../types';

export enum TransactionCategory {
  Equity = 'Equity',
  Escrow = 'Escrow',
  Fund = 'Fund',
  Miscellaneous = 'Miscellaneous',
  Note = 'Note',
  Options = 'Options',
  Token = 'Token',
  Warrants = 'Warrants',
}

export interface TransactionType {
  catergory: string;
  createdAt: string;
  createdBy: string | null;
  id: number;
  name: TRANSACTION_SUB_TYPES;
  schema: string | null;
  sortOrder: number;
  updatedAt: string;
  updatedBy: string | null;
}

export function createTransactionTypeDataModel(overrides: Partial<TransactionType> = {}): TransactionType {
  return {
    catergory: '',
    createdAt: '',
    createdBy: null,
    id: 0,
    name: TRANSACTION_SUB_TYPES.BUY,
    schema: null,
    sortOrder: 0,
    updatedAt: '',
    updatedBy: null,
    ...overrides,
  };
}
