import { CSSProperties, FC } from 'react';
import { CenterAligned } from '../pages/CompetitiveIntelligence/components/Tables/CellRenderers/CenterAligned';
import { ErrorCell } from '../pages/CompetitiveIntelligence/components/Tables/CellRenderers/ErrorCell';
import { LoadingCell } from '../pages/CompetitiveIntelligence/components/Tables/CellRenderers/LoadingCell';
import { RightAligned } from '../pages/CompetitiveIntelligence/components/Tables/CellRenderers/RightAligned';
import { LinkRenderer } from '../pages/PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/LinkRenderer';
import { NoDataCell } from '../components/AgTable/cell-renderers/NoDataCell';
import { GetFormatterProps } from './getFormatter';
import { TooltipWrapperComponent } from './TooltipWrapperComponent';

/** Component wrapper for additional HTML formatting;
 * Given value should already be formatted as a string, unless it's null
 * Prefers valueFormatted over value, similar to Ag-grid default renderers
 */
export interface FormattedComponentProps extends GetFormatterProps {
  value: string | null;
  valueFormatted?: string;
  style?: CSSProperties;
  loading?: boolean;
}

/** @deprecated */
const handleDeprecatedCases = ({ type, formatting, value: formattedValue }: FormattedComponentProps) => {
  if (type === 'link') return <LinkRenderer value={formattedValue} />;
  else if (formatting === 'image' && formattedValue) return <img src={formattedValue} />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isErrorValue = (value: any) => typeof value === 'string' && value.startsWith('Error');

export const FormattedComponent: FC<FormattedComponentProps> = ({
  type,
  formatting,
  value: val,
  valueFormatted,
  style,
  loading,
}) => {
  // must check this before checking for null
  if (isErrorValue(val)) return <ErrorCell />;

  if (val === null) return <NoDataCell />;

  const value = valueFormatted || val;

  if (!value && loading) return <LoadingCell />;

  if (type === 'array' || type === 'numberArray' || formatting === 'longText') {
    return <TooltipWrapperComponent value={val} />;
  }

  switch (type) {
    case 'number':
    case 'date': {
      return <RightAligned style={style}>{value}</RightAligned>;
    }
    case 'string': {
      if (formatting === 'url') return <LinkRenderer style={style} value={value} />;
      else if (formatting === 'image' && value) return <img src={value} />;
      else return <>{value}</>;
    }
    case 'boolean': {
      return <CenterAligned>{value}</CenterAligned>;
    }
    default: {
      const Component = handleDeprecatedCases({ type, formatting, value: value }) || null;
      return Component ? Component : <>{value}</>;
    }
  }
};
