import { useEffect, useRef } from 'react';
import {
  BarSeriesOption,
  DatasetComponentOption,
  EChartsType,
  GridComponentOption,
  LegendComponentOption,
  TooltipComponentOption,
  LineSeriesOption,
} from 'echarts';
import { ComposeOption } from 'echarts/core';
import { Stack } from '@mui/material';
import { merge } from 'lodash-es';
import { theme } from '../../../theme';
import { Chart, useChartResizeObserver } from '../chart-utils';
import { XCategoryData } from '../Barchart/BarChart';
import { AxisTickTextStyle, CommonChartOptions } from '../chart-constants';

const { primary, secondary } = theme.colors;
export const MIXED_COLORS = [primary['60'], primary['20'], secondary['30']];

export type EMixedChartsOption = ComposeOption<
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | BarSeriesOption
  | LineSeriesOption
  | TooltipComponentOption
>;

export type MixedSeriesOption = ComposeOption<BarSeriesOption | LineSeriesOption>;

export type IChartProps = {
  valueFormatter?: (value: number) => string;
  xCategoryData: XCategoryData;
  yAxis: EMixedChartsOption['yAxis'];
  colors?: string[];
  staticOptions?: EMixedChartsOption;
};

export function MixedChart(props: IChartProps & { yValueData: MixedSeriesOption[] }): JSX.Element {
  const { xCategoryData, yValueData, yAxis, colors = MIXED_COLORS, staticOptions = {} } = props;

  const chartContainer = useRef<HTMLDivElement | null>(null);
  const mixedChart = useRef<EChartsType | null>(null);

  useChartResizeObserver(mixedChart, chartContainer);

  useEffect(() => {
    if (!mixedChart.current) {
      return;
    }
    const { yAxis: staticYAxixOptions = {}, ...rest } = staticOptions ?? {};
    const options: EMixedChartsOption = merge(
      {
        color: colors,
        ...CommonChartOptions,
        xAxis: {
          axisLabel: {
            ...AxisTickTextStyle,
          },
          data: xCategoryData,
          type: 'category',
        },
        yAxis: Array.isArray(yAxis)
          ? yAxis.map((y) => merge({ ...y }, { ...staticYAxixOptions }))
          : merge({ ...yAxis }, { ...staticYAxixOptions }),
        series: yValueData,
        tooltip: {
          axisPointer: {
            type: 'shadow',
          },
          trigger: 'axis',
        },
      },
      { ...rest }
    );

    if (mixedChart.current) {
      mixedChart.current.setOption(options, true);
    }
  }, [colors, staticOptions, xCategoryData, yAxis, yValueData]);

  return (
    <Stack display='grid' width='100%' height='100%'>
      <Chart width={'100%'} height='100%' ref={chartContainer} />
    </Stack>
  );
}
