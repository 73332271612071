import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { selectedMappingsCompaniesIds } from '../state/MappingsState';

export const ID_DELIMITER = ',';
export const MAPPING_COMPANIES_PARAM = 'companies';

export function useSyncMappingsFromPath() {
  const [initialized, setInitialized] = useState(false);
  const [params] = useSearchParams();
  const setSelectedCompaniesIds = useSetRecoilState(selectedMappingsCompaniesIds);

  const companyIds = useMemo(() => {
    return (
      params
        .get(MAPPING_COMPANIES_PARAM)
        ?.split(ID_DELIMITER)
        ?.filter((id) => id.length > 0)
        ?.map((id) => parseInt(id))
        ?.filter((id) => !isNaN(id)) || []
    );
  }, [params]);

  useEffect(() => {
    if (companyIds.length === 0) {
      setSelectedCompaniesIds([]);
      setInitialized(true);
      return;
    }
    setSelectedCompaniesIds(companyIds);
    setInitialized(true);
  }, [companyIds, setSelectedCompaniesIds]);

  return initialized;
}
