import { useRecoilCallback } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { upperFirst } from 'lodash-es';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { LoadingId } from '../../../../../types';
import { deleteKPIRequest, sendKPIRequests } from '../../../../../services/queries/KPIRequestsQueries';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { useInvalidateKPIRequests } from '../KPIRequestResponse/hooks/useInvalidateKPIRequests';

const ROUTE_IN_PROGRESS_TAB = `/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}/${ROUTES.KPI_REQUESTS_IN_PROGRESS}`;

type SendKPIRequestPayload = {
  requestsIds: number[];
  totalCompanies?: number;
  sendReminder?: boolean;
};

export function useKPIRequestsNotSentActions() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const navigate = useNavigate();
  const invalidateRequests = useInvalidateKPIRequests();

  const sendKPIRequestAction = useRecoilCallback(
    () => async (payload: SendKPIRequestPayload) => {
      const { requestsIds, totalCompanies = 1, sendReminder = false } = payload;
      actions.startLoading(LoadingId.sendKPIRequests);

      try {
        const response = await sendKPIRequests(requestsIds);

        await invalidateRequests();

        const requestOrRequests = requestsIds.length > 1 ? `requests` : `request`;
        const wasOrWere = requestsIds.length > 1 ? `were` : `was`;
        const companyOrCompanies = totalCompanies > 1 ? `companies` : `company`;

        const respondentOrRespondents = requestsIds.length > 1 ? `respondents` : `respondent`;

        const successMsg = `${upperFirst(
          requestOrRequests
        )} for ${totalCompanies} ${companyOrCompanies} ${wasOrWere} successfully sent to ${respondentOrRespondents}`;

        const actionProps = sendReminder
          ? {}
          : {
              buttonLabel: 'Go to In Progress',
              buttonAction: () => {
                navigate(ROUTE_IN_PROGRESS_TAB);
              },
            };

        pushSuccessToast({
          title: `The ${requestOrRequests} ${wasOrWere} sent`,
          message: successMsg,
          ...actionProps,
        });

        return response;
      } catch (err) {
        const message = getErrorMessage(err, `Failed to send`);
        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.sendKPIRequests);
      }
    },
    [actions, pushErrorToast, pushSuccessToast]
  );

  const deleteKPIRequestAction = useRecoilCallback(
    () => async (payload: number, navigateUrl?: string) => {
      const requestId = payload;
      actions.startLoading(LoadingId.deleteKPIRequest);

      try {
        const response = await deleteKPIRequest(requestId);

        invalidateRequests();

        const successMsg = `Request was deleted successfully`;
        const actionProps = navigateUrl
          ? { buttonLabel: 'Go to Deleted', buttonAction: () => navigate(navigateUrl) }
          : {};

        pushSuccessToast({
          message: successMsg,
          ...actionProps,
        });

        return response;
      } catch (err) {
        console.log(err);
        const message = getErrorMessage(err, `Failed to delete request`);
        pushErrorToast({ message });
      } finally {
        actions.stopLoading(LoadingId.deleteKPIRequest);
      }
    },
    [actions, pushErrorToast, pushSuccessToast]
  );

  return {
    deleteKPIRequestAction,
    invalidateRequests,
    sendKPIRequestAction,
  };
}
