import { Tooltip, Typography, TypographyProps, styled } from '@mui/material';
import { ZINDEX } from '../../constants/styles';

const TextContainer = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    overflow: visible;
    position: relative;
    padding-right: 5px;
    z-index: ${ZINDEX.ELLIPSIS_TEXT};
  }
`;

export const EllipsisText = (props: TypographyProps) => {
  return (
    <Tooltip title={props.children} disableFocusListener followCursor placement='right-end'>
      <TextContainer data-testid='ellipsis-text' variant={'body2'} whiteSpace={'nowrap'} {...props}>
        {props.children}
      </TextContainer>
    </Tooltip>
  );
};

export const TypographyWithTooltip = (props: TypographyProps) => {
  return (
    <Tooltip title={props.children} placement='top'>
      <Typography {...props}>{props.children}</Typography>
    </Tooltip>
  );
};
