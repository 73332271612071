import { IDealDataModel } from '../../../data-models/deal.data-model';
import { DealBoardConfig } from '../../../types';

export const getRequiredChecklistItems = ({
  dealBoardConfig,
  deal,
}: {
  dealBoardConfig: DealBoardConfig;
  deal: IDealDataModel;
}) => {
  const dealStage = dealBoardConfig.dealStages.find((s) => s.id === deal.stageId);
  if (!dealStage || !dealStage.meta?.required?.length) return [];

  return [...dealStage.meta.required];
};

export const getWarningMessage = ({
  dealBoardConfig,
  deal,
}: {
  dealBoardConfig: DealBoardConfig;
  deal: IDealDataModel;
}) => {
  const required = getRequiredChecklistItems({ dealBoardConfig, deal });

  const missing = !deal.todoList ? required : required.filter((r) => !deal.todoList?.includes(r));

  if (missing.length === 0) return null;

  if (missing.length === 1) return `${missing[0]} is not completed`;

  const last = missing.pop();
  return `${missing.join(', ')} and ${last} are not completed`;
};
