import { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Collapse, ListItem, ListItemButton, ListItemIcon, Typography, useTheme } from '@mui/material';
import { IAirTableBaseDataModel } from '../../../../data-models/airtable-base.data-model';
import AirtableLogo from '../../../../assets/icons/airtable-base.svg?react';
import { pluralizeWord } from '../../../../util/stringUtils';
import { AirTableTables } from './AirTableTables';

const StyledListItem = styled(ListItem)`
  padding: 1px;
  margin-bottom: 1rem;
  border: 1px solid white;
  border-radius: 4px;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 1) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.06), 0px 4px 4px rgba(16, 37, 62, 0.04),
    0px 4px 4px rgba(75, 82, 93, 0.02);
  flex-direction: column;
`;

interface IAirTableBaseProps {
  base: IAirTableBaseDataModel;
  onClick?: () => void;
}

export const AirTableBase: FC<IAirTableBaseProps> = ({ base, onClick }) => {
  const [showTables, setShowTables] = useState(false);
  const { colors } = useTheme();
  const { numOfTableSyncs } = base;

  return (
    <StyledListItem>
      <ListItemButton
        onClick={() => {
          setShowTables((prev) => !prev);
          onClick?.();
        }}
        sx={{
          width: '100%',
          borderRadius: '4px',
          padding: '0.5rem 1rem',
          '&:hover': {
            backgroundColor: showTables ? 'unset' : colors.secondary[10],
          },
        }}
      >
        <ListItemIcon>
          <AirtableLogo title='airtable-logo' />
        </ListItemIcon>

        <Typography variant='h6' sx={{ fontWeight: 400 }}>
          {base.name}
        </Typography>
        {numOfTableSyncs > 0 && (
          <Chip
            label={`${numOfTableSyncs} ${pluralizeWord('table', numOfTableSyncs)} connected`}
            color='success'
            size='medium'
            variant='outlined'
            sx={{ marginLeft: 'auto' }}
            clickable
          />
        )}
      </ListItemButton>
      <Collapse in={showTables} sx={{ width: '100%' }}>
        <AirTableTables baseId={base.id} />
      </Collapse>
    </StyledListItem>
  );
};
