import { styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { theme } from '../../theme';

const { primary } = theme.colors;

export interface CardProps {
  children: ReactNode;
  hasBorder?: boolean;
  header?: string;
}

interface WrapperProps {
  hasBorder: boolean;
  hasHeader: boolean;
}

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ hasHeader }) => {
    return hasHeader ? '0' : '.4rem .8rem';
  }};
  align-items: flex-start;
  border: ${({ hasBorder }) => {
    return hasBorder ? '1px solid rgba(182, 187, 196, 0.4)' : `2px solid ${primary[0]}`;
  }};
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  box-shadow:
    0px -4px 40px rgba(255, 255, 255, 0.06),
    0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
`;

const Header = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[10]};
  padding: 16px 32px;
  width: 100%;
`;

export function Card({ children, hasBorder = false, header = '' }: CardProps) {
  return (
    <Wrapper hasBorder={hasBorder} hasHeader={!!header} data-testid={'card'}>
      {!!header && (
        <Header>
          <Typography variant='body2' color={theme.colors.primary[100]}>
            {header}
          </Typography>
        </Header>
      )}
      {children}
    </Wrapper>
  );
}
