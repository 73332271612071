import { useAtom } from 'jotai';
import { useRecoilValue } from 'recoil';
import { selectedFundStateFP, showFundFormsState, useResetFundFormState } from '../state/FPState';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FundForm, IFundFormProps } from './FundForm';
import { FundCapitalAllocationForm } from './Allocation/FundCapitalAllocationForm';

export function FundFormModal({ defaultValues, onSubmit }: IFundFormProps) {
  const fund = useRecoilValue(selectedFundStateFP);
  const [showForm, setShowForm] = useAtom(showFundFormsState);
  const resetFormState = useResetFundFormState();

  const title =
    defaultValues?.name &&
    `${defaultValues.name} ${showForm === 'waterfall' ? 'Waterfall' : 'Allocation'} Settings`;

  if (!fund) return null;

  return (
    <BasicDialog
      open={showForm != null}
      title={title}
      onClose={() => {
        setShowForm(null);
        resetFormState();
      }}
    >
      {showForm === 'waterfall' && <FundForm defaultValues={defaultValues} onSubmit={onSubmit} />}
      {showForm === 'allocation' && (
        <FundCapitalAllocationForm defaultValues={defaultValues} onSubmit={onSubmit} />
      )}
    </BasicDialog>
  );
}
