import { useCallback, useEffect, useMemo, useState } from 'react';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import {
  FormField,
  Label,
  StepperBox,
  StepperFormFieldsContainer,
  StepperSx,
  StepSx,
} from '../../../components/Form/FormComponents';
import { CompanySearch } from '../../../components/CompanySearch/CompanySearch';
import {
  createFinanceRoundFormViewModel,
  IFinanceRoundFormViewModel,
  toFinanceRoundDataModel,
} from '../../../view-models/finance-round-form.view-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { formatISODateOnly } from '../../../util/formatters/DateFormatters';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';
import { useAddInvestmentFormState } from './useAddInvestmentFormState';
import { NewInvestmentFormStep } from './NewInvestmentFormStep';
import { StepperFormButtons } from './StepperFormButtons';
import { SelectedCompanyInfo } from './CompanySelectForm';
import { CompanyFormStep } from './CompanyFormStep';

export interface IInvestmentFormProps {
  onClose: () => void;
}

export function AddInvestmentForm(props: IInvestmentFormProps) {
  const { onClose } = props;
  const {
    currentStep,
    investmentData,
    selectedCompanyId,
    selectedCompany,
    onCompanySelect,
    handleCreateInvestment,
    resetFormState,
    setInvestmentData,
  } = useAddInvestmentFormState();

  useEffect(() => {
    return resetFormState;
  }, [resetFormState]);

  const handleSubmit = useCallback(
    async (data: IFinanceRoundFormViewModel) => {
      await handleCreateInvestment(toFinanceRoundDataModel(data));
      onClose();
    },
    [handleCreateInvestment, onClose]
  );

  const defaultInvestmentValues = useMemo(() => {
    return investmentData
      ? createFinanceRoundFormViewModel({
          ...investmentData,
          companyId: selectedCompanyId ?? undefined,
        })
      : createFinanceRoundFormViewModel({
          id: undefined,
          companyId: selectedCompanyId ?? undefined,
          roundDate: formatISODateOnly(new Date()),
        });
  }, [investmentData, selectedCompanyId]);

  const steps = useMemo(
    () => [
      {
        label: selectedCompany?.name ?? 'Company',
        component: <SelectCompanyForInvestment onCompanySelect={onCompanySelect} onCancel={onClose} />,
      },
      {
        label: 'Round',
        component: (
          <NewInvestmentFormStep
            defaultValues={defaultInvestmentValues}
            onSubmit={handleSubmit}
            cacheFormData={setInvestmentData}
          />
        ),
      },
    ],
    [
      defaultInvestmentValues,
      handleSubmit,
      onClose,
      onCompanySelect,
      selectedCompany?.name,
      setInvestmentData,
    ]
  );

  return (
    <>
      <StepperBox>
        <Stepper activeStep={currentStep} sx={StepperSx}>
          {steps.map(({ label }) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps} sx={StepSx}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>{steps[currentStep]?.component}</>
      </StepperBox>
    </>
  );
}

interface ISelectCompanyForInvestmentProps {
  onCompanySelect: (company: CompanySearchResponseWithRealId) => void;
  onCancel: () => void;
}

export function SelectCompanyForInvestment({ onCompanySelect, onCancel }: ISelectCompanyForInvestmentProps) {
  const { selectedCompany, onChangeCompany, handleValidateCompanyStep, onUpdateCompany, setCurrentStep } =
    useAddInvestmentFormState();

  const [showCompanyForm, setShowCompanyForm] = useState(false);

  const _onSubmit = useCallback(
    async (data: Partial<ICompanyDataModel>) => {
      await onUpdateCompany(data);
      setShowCompanyForm(false);
      setCurrentStep(1);
    },
    [onUpdateCompany, setCurrentStep]
  );

  const onCancelEdit = useCallback(() => {
    setShowCompanyForm(false);
  }, []);

  if (showCompanyForm && selectedCompany) {
    return (
      <CompanyFormStep
        defaultValues={selectedCompany}
        onSubmit={_onSubmit}
        title='Edit Company Details'
        onCancel={onCancelEdit}
      />
    );
  }

  return (
    <>
      <StepperFormFieldsContainer sx={{ alignSelf: 'start' }}>
        <Typography variant='body1'>Select a Company</Typography>
        <FormField>
          <Label required>Company</Label>
          {selectedCompany ? (
            <SelectedCompanyInfo
              company={selectedCompany}
              onClear={onChangeCompany}
              onEdit={() => setShowCompanyForm(true)}
            />
          ) : (
            <CompanySearch
              onChange={onCompanySelect}
              showCreateOption={false}
              disablePortal={false}
              showPortcoOnly
              autoFocus
            />
          )}
        </FormField>
      </StepperFormFieldsContainer>
      <StepperFormButtons
        handleGoBack={onCancel}
        backButtonLabel={'Cancel'}
        stepIsValid={handleValidateCompanyStep}
      />
    </>
  );
}
