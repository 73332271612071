import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { theme } from '../../theme';
import CheckIcon from '../../assets/icons/check.svg';
import MinusIcon from '../../assets/icons/minus.svg';

interface Props {
  checked: boolean;
  handleChange?: () => void;
  disabled?: boolean;
  indeterminate?: boolean;
  readOnly?: boolean;
}

const { neutral, secondary } = theme.colors;

const StyledCheckbox = styled('input')<{ checked: boolean; disabled: boolean; indeterminate: boolean }>`
  width: 15px;
  height: 15px;
  overflow: hidden;
  appearance: none;
  border-radius: 3px;
  border: 1.5px solid ${({ disabled }) => (disabled ? neutral['30'] : neutral['60'])};
  box-shadow: none;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:checked {
    border: none;
    background-color: ${secondary['60']};

    &:before {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13px;
      height: 13px;
      border: 1.5px solid ${secondary['60']};
      content: '';
      border-radius: 3px;
    }

    &:after {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0.5px;
      top: -15.5px;
      width: 14px;
      height: 14px;
      border-width: 1px;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:checked:after {
    background-image: url(${({ indeterminate }) => (indeterminate ? MinusIcon : CheckIcon)});
  }
`;

export function Checkbox({
  checked,
  handleChange,
  disabled = false,
  indeterminate = false,
  readOnly = false,
}: Props): ReactElement {
  return (
    <StyledCheckbox
      type={'checkbox'}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      data-testid={'checkbox'}
      indeterminate={indeterminate}
      readOnly={readOnly}
    />
  );
}
