import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import {
  amountField,
  dateField,
  sharesField,
  simpleMultilineTextField,
  simpleTextField,
} from '../../../data-fields/CommonFields';
import { ITransactionViewModel } from '../../../view-models/transaction-form.view-model';
import { getValuationImpactField } from '../../../data-fields/TransactionFields';
import { useFundDateCurrencyFields } from './useFundDateCurrencyFields';

export function useOptionIssuanceFields() {
  const getCommonFields = useFundDateCurrencyFields();

  return useCallback(() => {
    return [
      ...getCommonFields(),
      sharesField({ key: 'optionsIssuedNo', label: 'Options Issued', required: true }),
      amountField({ key: 'optionsStrikePrice', label: 'Strike Price', required: true }),
      dateField({ key: 'optionsExpiryDate', label: 'Expiration Date', required: true }),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];
  }, [getCommonFields]);
}

export function useOptionExerciseFields() {
  const getCommonFields = useFundDateCurrencyFields();
  const valuationImpact = useWatch<ITransactionViewModel>({ name: 'valuationImpact' });

  return useCallback(() => {
    const fields = [
      ...getCommonFields(),
      simpleTextField({ key: 'security', label: 'Security' }),
      sharesField({ key: 'optionsExercisedNo', label: 'Options Exercised', required: true }),
      amountField({ key: 'optionsExercisePrice', label: 'Exercised Price', required: true }),
      getValuationImpactField(),
      simpleMultilineTextField({ key: 'notes', label: 'Notes' }),
    ];

    if (valuationImpact) {
      fields.splice(-1, 0, amountField({ key: 'pricePerShare', label: 'Valuation PPS', required: true }));
    }

    return fields;
  }, [getCommonFields, valuationImpact]);
}
