import { styled } from '@mui/material';

export const FormTable = styled('table')`
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors.primary[100]};
  table-layout: fixed;
  width: 100%;

  th,
  td {
    border: ${({ theme }) => `thin solid ${theme.colors.neutral[20]}`};
    padding: 0.5rem 1.25rem;
  }

  tr {
    height: 47px;
  }

  td.hidden {
    border: none;
  }

  thead {
    background-color: ${({ theme }) => theme.colors.neutral[10]};
  }

  tbody {
  }
`;
