import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { styled } from '@mui/system';
import { IMappingsRowDataModel, VendorError } from './mappingDataUtils';
import { MappingErrorCell } from './MappingErrorCell';

const Cell = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProviderDataRenderer: FC<ICellRendererParams<IMappingsRowDataModel>> = (params) => {
  const { value, node } = params;

  if (node.group) return null;

  if (value === null) return <></>;

  if (value instanceof VendorError) return <MappingErrorCell />;

  return <Cell>{value}</Cell>;
};
