import { ICellRendererParams } from 'ag-grid-community';
import { Stack, SvgIcon } from '@mui/material';
import { IFinanceRoundDataModel, RoundSource } from '../../../data-models/finance-round.data-model';
import Aumni from '../../../assets/icons/vendor_aumni.svg?react';
import Foresight from '../../../assets/icons/vendor_foresight.svg?react';
import SystemGenerated from '../../../assets/icons/vendor_system_generated.svg?react';

export function RoundSourceRenderer(params: ICellRendererParams<IFinanceRoundDataModel>) {
  if (!params.value && !params.node.key) return null;
  if (params.node.group) return <>{params.node.key}</>;
  return (
    <Stack
      height={'100%'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      title={params.valueFormatted ?? params.node.key!}
    >
      <SvgIcon>
        {params.value === RoundSource.aumni && <Aumni />}
        {params.value === RoundSource.foresight && <Foresight />}
        {params.value === RoundSource.systemGenerated && <SystemGenerated />}
      </SvgIcon>
    </Stack>
  );
}
