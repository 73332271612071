import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../constants/RouteNameMapping';
import { dealLeadFilterState, sectorFilterState } from '../../state/DealConversionFilterState';
import { DealLeadFilter } from '../DealLeadFilter';
import { SectorFilter } from '../SectorFilter';
import { DealTypeFilter } from './Overview/Filters/DealTypeFilter';
import { PeriodFilters } from './Overview/Filters/PeriodFilters';

const Header = styled('header')`
  display: grid;
  row-gap: 0.5rem;
`;

const FilterContainer = styled('div')`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, auto) 1fr;
  justify-content: start;
  align-items: start;
  margin: 1rem 0;
  padding-right: 0.5rem;
  width: 100%;
  gap: 0.5rem;
  &&& * {
    margin-top: 0;
  }
`;

const tabs = [
  { path: ROUTES.DEAL_ANALYTICS_KPI, title: ROUTE_TITLE_MAPPING[ROUTES.DEAL_ANALYTICS_KPI] },
  {
    path: ROUTES.DEAL_ANALYTICS_DURATION_DETAILS,
    title: ROUTE_TITLE_MAPPING[ROUTES.DEAL_ANALYTICS_DURATION_DETAILS],
  },
];

export const DealAnalyticsHeader: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedSectors, setSelectedSectors] = useRecoilState(sectorFilterState);
  const [selectedDealLead, setSelectedDealLead] = useRecoilState(dealLeadFilterState);

  const selectedSection = useMemo(() => pathname.split('/')?.pop(), [pathname]);

  const onChangeTab = useCallback(
    (path: ROUTES) => {
      navigate(`/${ROUTES.DEAL_FLOW}/${ROUTES.DEAL_ANALYTICS}/${path}`);
    },
    [navigate]
  );

  const handleSectorsOnChange = useCallback(
    (e: SyntheticEvent, options: Option[] | undefined) => {
      if (!options) return;
      setSelectedSectors(options.map((opt) => opt.id as number));
    },
    [setSelectedSectors]
  );

  const handleDealLeadOnChange = useCallback(
    (newValue: number | null) => {
      setSelectedDealLead(newValue);
    },
    [setSelectedDealLead]
  );

  return (
    <Header>
      <Tabs
        variant='scrollable'
        scrollButtons='auto'
        value={selectedSection}
        onChange={(e, tab) => onChangeTab(tab)}
      >
        {tabs.map((t) => (
          <Tab key={t.path} value={t.path} label={t.title} className={`mui-custom-tab`} />
        ))}
      </Tabs>
      <FilterContainer>
        <DealLeadFilter selectedDealLead={selectedDealLead} onChange={handleDealLeadOnChange} />
        <SectorFilter selectedSectors={selectedSectors} onChange={handleSectorsOnChange} />
        <DealTypeFilter />
        <PeriodFilters />
      </FilterContainer>
    </Header>
  );
};
