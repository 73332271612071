import { useRecoilValue } from 'recoil';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { sectorsState } from '../../../services/state/AppConfigState';
import { IGenericSortable } from '../../../data-models/app-config.data-model';
import { getSectorFields } from './SectorFields';

export function ConfigSector() {
  const sectors = useRecoilValue(sectorsState);
  const transformBeforeSubmit = (data: IGenericSortable): IGenericSortable => {
    return {
      ...data,
      displayName: data.name,
      sortOrder: 0,
    };
  };

  return (
    <GenericConfigPage
      baseTitle={'Sector'}
      data={sectors}
      fields={getSectorFields()}
      pageTitle={'Sectors'}
      queryURL={'/sector'}
      transformBeforeSubmit={transformBeforeSubmit}
    />
  );
}
