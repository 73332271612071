import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';

const StyledHeadingSection = styled('h1')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;

  & img {
    max-width: 2rem;
  }
`;

type Props = {
  logoUrl: string;
  name: string;
};

export const CompanyHeading = ({ name, logoUrl }: Props) => {
  const { colors } = useTheme();

  return (
    <StyledHeadingSection data-testid={'company-heading'}>
      <MUIAvatar size='medium' src={logoUrl ?? ''} nameParts={name?.split(' ') ?? []} />
      <Typography
        variant='h4'
        component='span'
        color={colors.primary[90]}
      >{`${name} Transactions`}</Typography>
    </StyledHeadingSection>
  );
};
