import { styled } from '@mui/material/styles';
import { useRecoilValueLoadable } from 'recoil';
import { LoadingStatus } from '../../types';
import { Loader } from '../../components/Loader/Loader';
import { CIMainContent } from './components/CIMainContent';
import { dashboardsCI, fieldsCI, packagesCI } from './state/DashboardsState';
import { useSyncFromPath } from './hooks/useSyncFromPath';

const InnerWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem 3rem 1rem 1rem;
  gap: 0.5rem;
`;

export const CompetitiveIntelligence = () => {
  // Eagerly pre-fetch data.
  useRecoilValueLoadable(fieldsCI);
  useRecoilValueLoadable(packagesCI);
  useRecoilValueLoadable(dashboardsCI);
  // const getToken = useToken();

  const initStatus = useSyncFromPath();

  // FIXME Enable this after MAGGIE-3617
  // useEffect(() => {
  //   WebSocketService.initService(getToken);
  // }, [getToken]);

  if (initStatus !== LoadingStatus.success) {
    return <Loader height={'100%'} />;
  }

  return (
    <InnerWrapper>
      <CIMainContent />
    </InnerWrapper>
  );
};
