import { CSSProperties, FC } from 'react';
import { NumericFieldInput } from '../../../../../CoreComponents/base/NumericFieldInput';
import { Kpi } from '../Kpi';

interface IFieldProps {
  name: string;
  value?: number;
  onValueChanged: (newValue: number | null) => void;
  formatter?: (value: number) => string;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
  disabled?: boolean;
  kpiStyle?: CSSProperties;
}

const fieldStyle = { marginLeft: '-.875rem', maxWidth: '14rem' };

export const EditableProfileNumericField: FC<IFieldProps> = ({
  name,
  value,
  disabled = false,
  onValueChanged,
  formatter,
  placeholder,
  prefix,
  suffix,
  kpiStyle,
}) => {
  return (
    <Kpi
      label={name}
      style={{ gap: 0, ...kpiStyle }}
      data={
        <NumericFieldInput
          style={fieldStyle}
          prefix={prefix}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onValueChanged={(newValue) => {
            if (newValue !== null) {
              onValueChanged(newValue);
            }
          }}
          formatOnBlur={formatter}
          suffix={suffix}
        />
      }
    />
  );
};
