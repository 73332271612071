import { IconButton, Stack, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { useCallback, useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import {
  sortableKeyboardCoordinates,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormTable } from '../../../../../components/Form/FormGrid';
import { IField } from '../../../../../data-models/field2.data-model';
import { MetricNameCell } from '../../KPIMetricsNameCell';
import { KpiSection } from '../../../../../data-models/company-financials.data-model';
import {
  IKPITableDataModel,
  IKPITemplateSectionDataModel,
} from '../../../../../data-models/kpi-template.data-model';
import { KPISelector } from '../../KPISelector';
import { CreateKPIModal } from './CreateKPIModal';

export const TableHeaders = styled('thead')`
  th:nth-of-type(2) {
    width: 150px;
  }
  th:nth-of-type(3) {
    background: white;
    border: none;
    width: 50px;
  }
`;

type KPIRowMetricProps = {
  metric: IField<unknown>;
  onDeleteKPI: () => void;
};

const KPIRowMetric = ({ metric, onDeleteKPI }: KPIRowMetricProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: metric.id });

  const style = {
    // Use CSS.Translate instead of Transform to avoid weird behavior while dragging
    // transform: CSS.Translate.toString(transform),
    // transition,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr key={metric.id} ref={setNodeRef} style={style}>
      <td style={{ width: '100%' }}>
        <MetricNameCell metric={metric} />
      </td>
      <td className={'hidden'} style={{ width: '90px' }}>
        <Stack direction={'row'}>
          <IconButton aria-label='remove kpi' onClick={onDeleteKPI}>
            <ClearIcon />
          </IconButton>
          <IconButton
            {...attributes}
            {...listeners}
            aria-label='reorder kpi'
            sx={{
              cursor: 'grab',
            }}
          >
            <DragIndicatorOutlinedIcon />
          </IconButton>
        </Stack>
      </td>
    </tr>
  );
};

export function KPITableConfig({ metricsRef }: { metricsRef: string }) {
  const { fields, append, remove, move } = useFieldArray({ name: metricsRef });

  const { control } = useFormContext();
  const kpiSectionPath = `${metricsRef.substring(0, metricsRef.lastIndexOf('.'))}.section`;

  const sections = useWatch({ name: 'sections', control });
  const disableActualSection = useMemo(() => disableActual(sections), [sections]);
  const disableBudgetSection = useMemo(() => disableBudget(sections), [sections]);

  const [newMetricName, setNewMetricName] = useState('');
  const selectedMetrics = fields as unknown as IField<unknown>[];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onAddKPI = useCallback(
    (metric: IField<unknown>) => {
      append({
        ...metric,
      });
    },
    [append]
  );

  const onDeleteKPI = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const selectedSortedMetricsIds = selectedMetrics.map((section) => section.id);
        const oldIndex = selectedSortedMetricsIds.findIndex((id) => id === active.id);
        const newIndex = selectedSortedMetricsIds.findIndex((id) => id === over?.id);

        move(oldIndex, newIndex);
      }
    },
    [selectedMetrics, move]
  );

  return (
    <>
      <Stack gap='.5rem'>
        <Controller
          control={control}
          name={kpiSectionPath}
          render={({ field: { onChange, value } }) => (
            <ToggleButtonGroup
              color='secondary'
              exclusive
              aria-label='kpi-section'
              value={value ?? (disableActualSection ? KpiSection.budget : KpiSection.actual)}
              onChange={(event, value) => {
                if (value) onChange(value);
              }}
            >
              <ToggleButton value={KpiSection.actual} disabled={disableActualSection}>
                Actual
              </ToggleButton>
              <ToggleButton value={KpiSection.budget} disabled={disableBudgetSection}>
                Budget
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
        <FormTable
          sx={{
            tableLayout: 'initial',
          }}
        >
          <TableHeaders>
            <tr>
              <th>
                <Typography variant={'body2'}>Metrics</Typography>
              </th>
            </tr>
          </TableHeaders>
          <tbody>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
              <SortableContext items={selectedMetrics} strategy={rectSortingStrategy}>
                {selectedMetrics.map((metric: IField<unknown>, index: number) => (
                  <KPIRowMetric key={metric.id} metric={metric} onDeleteKPI={() => onDeleteKPI(index)} />
                ))}
              </SortableContext>
            </DndContext>
            <tr>
              <td>
                <KPISelector
                  createMetric={(metricName) => {
                    setNewMetricName(metricName);
                    handleOpen();
                  }}
                  onMetricSelect={onAddKPI}
                  selectedMetrics={selectedMetrics}
                />
              </td>
            </tr>
          </tbody>
        </FormTable>
      </Stack>
      <CreateKPIModal initialName={newMetricName} onClose={handleClose} open={open} />
    </>
  );
}

export function disableActual(sections: IKPITemplateSectionDataModel[]) {
  return sections.some((section) => (section.meta as IKPITableDataModel).section === KpiSection.actual);
}
export function disableBudget(sections: IKPITemplateSectionDataModel[]) {
  return sections.some((section) => (section.meta as IKPITableDataModel).section === KpiSection.budget);
}
