import { FC, ReactNode, useCallback } from 'react';
import { BarSeriesOption } from 'echarts';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IconButton, Stack, Typography } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { theme } from '../../../../../theme';
import {
  getDateForPeriod,
  getDateRange,
  KpiSeriesBySectionByPeriod,
  useGetFinancialsBySectionAndPeriod,
} from '../../../hooks/useLastThreePeriodData';
import { BarChart } from '../../../../../components/Charts/Barchart/BarChart';
import { IField } from '../../../../../data-models/field2.data-model';
import { KpiPeriod, KpiSection } from '../../../../../data-models/company-financials.data-model';
import { selectedCompanyIdProfile, selectedKpiChartState } from '../../../state/UIState';
import {
  selectedMetricsDateCPState,
  selectedFrequencyPerfState,
} from '../../../state/CompanyFinancialsDateState';
import { TypedFinancials } from '../../../utils/getTypedFinancials';
import { FormatterService } from '../../../../../util/formatter-service';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { companyState } from '../../../../../services/state/CompanyState';
import { endOfFiscalPeriod, getFormattedFiscalDate, isEqualToKpiDate } from '../../../utils/financialUtils';
import { formatISODateOnly } from '../../../../../util/formatters/DateFormatters';

const { primary } = theme.colors;
const BAR_COLORS = [primary['60'], primary['20']];

interface IPerfBarChart2Props {
  title: string;
  field: IField<unknown>;
  periodRange?: number;
  titleComponent?: ReactNode;
}
export function PerfBarChart2({ title, field, titleComponent, periodRange = 4 }: IPerfBarChart2Props) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId))!;
  const selectedPeriod = useRecoilValue(selectedFrequencyPerfState(companyId));
  const getChartData = useGetPerfBarChartData();
  const fye = useRecoilValue(companyState(companyId))?.fye ?? 12;

  const { barData, timeCategories, formatter } = getChartData(
    field,
    selectedDate,
    selectedPeriod,
    periodRange,
    fye
  );

  return (
    <BarChart
      displayName={title}
      title={titleComponent ?? title}
      xCategoryData={timeCategories}
      valueFormatter={formatter}
      yValueData={barData}
    />
  );
}

export function useGetPerfBarChartData() {
  const getFinancials = useGetFinancialsBySectionAndPeriod();

  return useCallback(
    (field: IField<unknown>, selectedDate: Date, selectedPeriod: KpiPeriod, range: number, fye: number) => {
      const { from, to } = getDateRange(selectedPeriod, selectedDate, range);
      const displayedDates = getDatesInRange(selectedPeriod, selectedDate, range);
      const timeCategories = displayedDates.map((date) =>
        getFormattedFiscalDate(formatISODateOnly(date), selectedPeriod, fye)
      );
      const financialsForRange = getFinancials(from, to);
      const barData = getBarSeries(field, selectedPeriod, financialsForRange, fye);
      const formatter = FormatterService.get().getFormatterForField(field);

      return { timeCategories, barData, formatter };
    },
    [getFinancials]
  );
}

function getBarSeries(
  field: IField<unknown>,
  selectedPeriod: KpiPeriod,
  dataSeries: KpiSeriesBySectionByPeriod,
  fye: number
): BarSeriesOption[] {
  const actualSeries: BarSeriesOption = {
    barGap: 0,
    color: BAR_COLORS[0],
    data: kpisAsSeries(
      dataSeries.get(KpiSection.actual)?.get(selectedPeriod)?.get(field.displayName) ?? [],
      selectedPeriod,
      fye
    ),
    emphasis: {
      focus: 'series',
    },
    name: 'Actual',
    showBackground: false,
    type: 'bar',
  };

  const budgetSeries: BarSeriesOption = {
    barGap: 0,
    color: BAR_COLORS[1],
    data: kpisAsSeries(
      dataSeries.get(KpiSection.budget)?.get(selectedPeriod)?.get(field.displayName) ?? [],
      selectedPeriod,
      fye
    ),
    emphasis: {
      focus: 'series',
    },
    name: `Budget `,
    showBackground: false,
    type: 'bar',
  };

  return [actualSeries, budgetSeries];
}

export function kpisAsSeries(
  data: TypedFinancials[],
  selectedFrequency: KpiPeriod,
  fye: number
): [string, number][] {
  return data.reduce((acc, item) => {
    const endOfCurrentPeriod = endOfFiscalPeriod(new Date(item.date), selectedFrequency, fye - 1);
    if (isEqualToKpiDate(item.date, endOfCurrentPeriod)) {
      acc.push([getFormattedFiscalDate(item.date, selectedFrequency, fye), item.value as number]);
    }
    return acc;
  }, [] as [string, number][]);
}

/**
 * @param selectedPeriod
 * @param selectedDate last day of fiscal period
 * @param range
 * @returns
 */
export function getDatesInRange(selectedPeriod: KpiPeriod, selectedDate: Date, range: number) {
  const displayedDateRange: Date[] = [];

  for (let i = range - 1; i >= 0; i--) {
    displayedDateRange.push(getDateForPeriod(selectedPeriod, selectedDate, i));
  }

  return displayedDateRange;
}

interface PerfBarChartTitleProps {
  field: IField<unknown>;
}
export const PerfBarChartTitle: FC<PerfBarChartTitleProps> = ({ field }) => {
  const setSelectedChart = useSetRecoilState(selectedKpiChartState);
  const { showExpandedPerfCharts } = useFlags<MaggieFeatureFlags>();

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Typography>{field.displayName}</Typography>
      {showExpandedPerfCharts && (
        <IconButton
          onClick={() => setSelectedChart(field)}
          color='secondary'
          sx={{ transform: 'rotate(45deg)' }}
        >
          <UnfoldMoreIcon />
        </IconButton>
      )}
    </Stack>
  );
};
