import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ButtonBase, IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { IListDataModel, ListType } from '../../../../../data-models/list.data-model';
import {
  activeCompanyListIdState,
  listsByIdState,
} from '../../../../../pages/CompanyProfiles/state/ListsState';
import { useCurrentUser } from '../../../../../services/hooks/useCurrentUser';
import { CPListActionMenu } from './CPListActionMenu';

const ItemWrapper = styled('div')<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 13px 0 18px;
  ${({ isSelected }) => (isSelected ? 'background-color: #2D3277;' : '')}
  border-radius: 4px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const NumberLabel = styled('div')`
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.tertiary[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 16px;
  color: white;
`;

const ActionButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

interface IProps {
  item: IListDataModel;
}

export const CPListItem: FC<IProps> = ({ item }) => {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const listsMap = useRecoilValue(listsByIdState);
  const currentUser = useCurrentUser();
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;
  const navigate = useNavigate();
  const { secondary } = useTheme().colors;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isEditable = useMemo(() => {
    return !item.default && item.type === ListType.DISCOVERY && item.createdBy === currentUser.email;
  }, [currentUser, item]);

  const handleActionsMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClick = useCallback(
    (list: IListDataModel) => {
      navigate(`/${ROUTES.COMPANIES}/${list.id}`);
    },
    [navigate]
  );

  return (
    <ButtonBase
      TouchRippleProps={{ color: 'white' }}
      style={{ width: '100%', display: 'block' }}
      onClick={() => handleClick(item)}
    >
      <ItemWrapper isSelected={item.id === activeList?.id}>
        <TitleWrapper>
          <Typography variant='caption' color={secondary[10]}>
            {item.name}
          </Typography>
          {item.type === ListType.DISCOVERY && <NumberLabel>{item.numberOfItems}</NumberLabel>}
        </TitleWrapper>
        {item.id === activeList?.id && (
          <ActionButtonWrapper>
            <CheckIcon style={{ color: secondary[10], fontSize: '17px' }} />
            {isEditable && (
              <IconButton onClick={handleActionsMenuClick}>
                <MoreVertIcon sx={{ color: secondary[10], fontSize: '17px' }} />
              </IconButton>
            )}
          </ActionButtonWrapper>
        )}
        <CPListActionMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </ItemWrapper>
    </ButtonBase>
  );
};
