import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class CountOfCompaniesConfig implements KpiConfig {
  private id = KPI_COMPONENTS.COUNT_OF_COMPANIES as const;
  private title = 'Companies';
  private type = 'integer';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value =
      data?.rawCompanies
        .filter(({ transactions }) =>
          transactions.some(
            ({ investmentAmount, transactionDate }) =>
              investmentAmount > 0 && new Date(transactionDate) < data.date
          )
        )
        .reduce((res, metrics) => {
          return res.add(metrics.companyId);
        }, new Set()).size ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
