import * as yup from 'yup';
import { requiredMsg } from '../../../../Finance/components/TransactionModal/Forms/utils/validationMessages';

export const keyResultSchema = yup.object().shape({
  name: yup.string().required(requiredMsg('Name')).nullable(),
  status: yup.string().required(requiredMsg('Status')).nullable(),
  displayType: yup.string().required(requiredMsg('Goal Type')).nullable(),
  value: yup
    .number()
    .optional()
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
  goal: yup
    .number()
    .required(requiredMsg('Goal'))
    .nullable()
    .transform((_, val) => (val === val ? val : null)),
});
