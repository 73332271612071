import { useMemo } from 'react';
import * as yup from 'yup';
import { ColDef, ColGroupDef, ValueGetterParams, ColDefField, ValueFormatterParams } from 'ag-grid-community';
import { capitalize, get } from 'lodash-es';
import { useSchemaToColDef } from '../../../../util/schema-utils';
import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
} from '../../../../data-models/captable2.data-model';
import { ViewModelInvestmentType } from '../../../../schemas/common-schema-defs';
import { getShareClassType } from '../../../../view-models/captable.view-model';
import {
  IField3,
  INumberMeta,
  createIntegerField,
  createPercentField,
  field3ToColumnDef,
} from '../../../../data-models/field3.data-model';
import { createUSDFormatterDataModel } from '../../../../data-models/formatter.data-model';
import { FMT } from '../../../../util/formatter-service';
import { numericCellClasses } from '../../../Finance2/common-grid-defs/commonColDefs';
import { getExcelClassForCurrency } from '../../../../components/AgTable/exportToExcelDefs';
import { ISortableCaptableInvestment, sortShareClasses } from '../investment-utils';
import { ICaptableSummaryRowData } from './CaptableSummaryRowData';

const stakeholderSchema = yup.string().required().label('Stakeholder');

export function useCaptableSummaryColDefs(ct: ICaptableDataModel | null) {
  const schemaFieldToColDef = useSchemaToColDef();

  const colDefs: (ColDef<ICaptableSummaryRowData> | ColGroupDef<ICaptableSummaryRowData>)[] = useMemo(() => {
    if (!ct) return [];

    const defs: (ColDef<ICaptableSummaryRowData> | ColGroupDef<ICaptableSummaryRowData>)[] = [
      {
        ...schemaFieldToColDef(
          'stakeholderName' as keyof ICaptableSummaryRowData,
          stakeholderSchema.describe()
        ),
        valueFormatter: (params) => {
          if (params.node?.footer) {
            return 'Total';
          }
          return params.value;
        },
        minWidth: 200,
        pinned: 'left',
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
    ];

    const sortedInvestments = sortCaptableInvestments(ct.captableInvestments ?? []);

    const typeGroups = new Map<ViewModelInvestmentType, ICaptableInvestmentDataModel[]>();
    sortedInvestments?.forEach((investment) => {
      if (!investment.investmentType) return;
      const type = getShareClassType(investment.investmentType!, investment.shareClassType);
      if (!type) return;
      const group = typeGroups.get(type) ?? [];
      group.push(investment);
      typeGroups.set(type, group);
    });

    typeGroups.forEach((investments, type) => {
      defs.push({
        headerName: capitalize(type!),
        headerClass: 'type-header',
        children: investments.map((investment) => {
          const fields = getInvestmentColumnDefs(investment, type!);

          return {
            headerName: investment.name,
            headerClass: 'investment-header',
            children: fields,
          };
        }),
      });
    });
    defs.push(getTotalColumnDefs());

    return defs;
  }, [ct, schemaFieldToColDef]);

  return colDefs;
}

export function getInvestmentColumns(investmentType: ViewModelInvestmentType) {
  switch (investmentType) {
    case ViewModelInvestmentType.preferred:
    case ViewModelInvestmentType.warrants:
    case ViewModelInvestmentType.common: {
      return new Set(['cashRaised', 'fullyDilutedShares', 'ownershipPercentage']);
    }
    case ViewModelInvestmentType.notes: {
      return new Set(['principal']);
    }

    case ViewModelInvestmentType.options: {
      return new Set(['optionsSharesHeld', 'ownershipPercentage']);
    }
    default:
      return new Set();
  }
}

function sortCaptableInvestments(captableInvestments: ICaptableInvestmentDataModel[]) {
  const investmentIds = new Map<number, ICaptableInvestmentDataModel>(
    captableInvestments?.map((i) => [i.id, i]) ?? []
  );
  const sorted = sortShareClasses(
    captableInvestments.reduce(
      (acc, inv) => {
        const sortable = captableInvestmentToSortable(inv);
        if (!sortable) return acc;
        acc.push(sortable);
        return acc;
      },
      [] as (ISortableCaptableInvestment & { id: number })[]
    )
  ) as (ISortableCaptableInvestment & { id: number })[];
  return sorted.map((inv) => investmentIds.get(inv.id)!);
}

export function captableInvestmentToSortable(inv: ICaptableInvestmentDataModel) {
  const type = getShareClassType(inv.investmentType, inv.shareClassType);
  if (!type) return null;
  return {
    ...inv,
    type,
    investmentDate: inv.investmentDate ? new Date(inv.investmentDate) : null,
  };
}

function getInvestedAmountField(colId: string) {
  const formatterDataModel = createUSDFormatterDataModel(0);
  const formatter = FMT.get().getFormatterForModel(formatterDataModel);

  return {
    cellClass: [...numericCellClasses, getExcelClassForCurrency('USD')],
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value == null) return '';

      return formatter(params.value);
    },
    valueGetter: (params: ValueGetterParams) => {
      return get(params.data, colId);
    },
    colId,
    field: colId as ColDefField,
    headerName: 'Invested Amount',
    aggFunc: 'sum',
  } as ColDef<ICaptableSummaryRowData>;
}

function getSharesHeldField(overrides: Partial<IField3<INumberMeta>> = {}) {
  return {
    ...field3ToColumnDef(
      createIntegerField({
        displayName: 'Shares Held',
        ...overrides,
      })
    ),
    aggFunc: 'sum',
  } as ColDef<ICaptableSummaryRowData>;
}

function getOwnershipPercentageField(overrides: Partial<IField3<INumberMeta>> = {}) {
  return {
    ...field3ToColumnDef(
      createPercentField({
        displayName: 'Ownership Percentage',
        ...overrides,
      })
    ),
    aggFunc: 'sum',
  } as ColDef<ICaptableSummaryRowData>;
}

export const CaptableSummaryDefaultColDefs: ColDef<ICaptableSummaryRowData> = {
  flex: 1,
  minWidth: 140,
  getQuickFilterText: () => '',
};

function getTotalColumnDefs(): ColGroupDef<ICaptableSummaryRowData> {
  return {
    headerName: '',
    headerClass: 'type-header',
    children: [
      {
        headerName: 'Total',
        headerClass: 'type-header total-header',
        children: [
          {
            ...getInvestedAmountField('totals.cashRaised'),
          },
          {
            ...getSharesHeldField({
              entityKey: 'totals.fullyDilutedShares',
            }),
          },
          {
            ...getOwnershipPercentageField({
              entityKey: 'totals.ownershipPercentage',
            }),
          },
        ],
      },
    ],
  };
}

function getInvestmentColumnDefs(investment: ICaptableInvestmentDataModel, type: ViewModelInvestmentType) {
  const columns = getInvestmentColumns(type);
  const fields = [];
  if (columns.has('cashRaised')) {
    fields.push({
      ...getInvestedAmountField(`investments.[${type}].[${investment.name}].cashRaised`),
    });
  }
  if (columns.has('fullyDilutedShares')) {
    fields.push({
      ...getSharesHeldField({
        entityKey: `investments.[${type}].[${investment.name}].fullyDilutedShares`,
      }),
    });
  }
  if (columns.has('ownershipPercentage')) {
    fields.push({
      ...getOwnershipPercentageField({
        entityKey: `investments.[${type}].[${investment.name}].ownershipPercentage`,
      }),
    });
  }
  if (columns.has('principal')) {
    fields.push({
      ...getInvestedAmountField(`investments.[${type}].[${investment.name}].cashRaised`),
    });
  }
  if (columns.has('optionsSharesHeld')) {
    fields.unshift({
      ...getSharesHeldField({
        entityKey: '',
      }),
      valueGetter: (params: ValueGetterParams<ICaptableSummaryRowData>) => {
        const { data } = params;
        if (!data) return;
        return (
          (data.investments?.[type]?.[investment.name]?.outstandingEquityAwardDerivatives ?? 0) +
          (data.investments?.[type]?.[investment.name]?.outstandingCommittedRestrictedStockAwards ?? 0)
        );
      },
    });
  }

  return fields;
}
