import { colors } from '../theme/colors';
import { ColorPalette } from '../theme/structure';

export type ItemsColourScheme<T> = Map<T, string>;

export class ColourSchemeGenerator {
  static #instance: ColourSchemeGenerator;
  readonly #colourSchemes: { [key: string]: ItemsColourScheme<unknown> };
  readonly baseColourScheme;
  readonly defaultColour;

  private constructor(colorPalette: ColorPalette) {
    const { primary, secondary, tertiary, warning, success, neutral } = colorPalette;
    this.defaultColour = colorPalette.neutral[5];
    this.baseColourScheme = [
      primary[90],
      primary[45],
      success[60],
      warning[50],
      tertiary[50],
      neutral[50],
      primary[70],
      primary[50],
      tertiary[60],
      secondary[30],
      warning[30],
      success[40],
      neutral[30],
      tertiary[30],
      tertiary[10],
      primary[20],
    ];

    this.#colourSchemes = {};
  }

  generateScheme<T>(listId: string, itemIds: T[]) {
    this.#colourSchemes[listId] = itemIds.reduce((res, id, index) => {
      res.set(id, this.baseColourScheme[index]);
      return res;
    }, new Map<T, string>());

    return this.#colourSchemes[listId] as ItemsColourScheme<T>;
  }

  getScheme<T>(listId: string) {
    return (this.#colourSchemes[listId] ?? new Map()) as ItemsColourScheme<T>;
  }

  getColourForItem<T>(listId: string, itemId: T) {
    return this.#colourSchemes[listId]?.get(itemId) ?? this.defaultColour;
  }

  static init(colorPalette: ColorPalette) {
    this.#instance = new ColourSchemeGenerator(colorPalette);
  }

  static getInstance() {
    if (!this.#instance) {
      this.#instance = new ColourSchemeGenerator(colors);
    }

    return this.#instance;
  }
}
