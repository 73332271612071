import { Stack, Typography, styled } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectedViewIdPF, selectedViewPF } from '../../../state/ViewState';
import { selectedDateByViewPF } from '../../../state/PageState';
import { Calendar } from '../../../../../components/DatePicker/Calendar';
import { Breakpoint, ContainerName } from '../../../../../constants/styles';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { ViewTypeByData } from '../../../../../data-models/view-config.data-model';

const StyledLabel = styled(Typography)`
  padding-right: 0.5rem;
  font-size: 0.825rem;
  text-align: right;
  @container ${ContainerName.headerContainer} (max-width: ${Breakpoint.hideBreadcrumbs}) {
    display: none;
  }
`;

const viewsWithDatePicker = new Set<ViewTypeByData>([
  ViewTypeByData.fund,
  ViewTypeByData.company,
  ViewTypeByData.transaction,
]);

export function PortfolioDatePicker() {
  const viewId = useRecoilValue(selectedViewIdPF);
  const selectedView = useRecoilValue(selectedViewPF);
  const [viewDate, setViewDate] = useRecoilState(selectedDateByViewPF(viewId));
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();

  function onChange(date: Date) {
    setViewDate(date);
  }

  if (!selectedView || !viewsWithDatePicker.has(selectedView.viewType)) return null;

  return (
    <Stack
      display='grid'
      gridTemplateColumns={'auto 1fr'}
      alignItems={'center'}
      width={'100%'}
      title='As of date'
    >
      {!showElasticSearchResults && <StyledLabel role='label'>As Of Date</StyledLabel>}
      <Calendar initialValue={viewDate} onChange={onChange} sx={{ maxWidth: '12rem' }} />
    </Stack>
  );
}
