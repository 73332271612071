import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Collapse, Divider, Stack } from '@mui/material';
import { IScenarioDataModel } from '../../../../../data-models/scenario.data-model';

import { Label } from '../../../../../components/Form/FormComponents';
import { useViewMode } from '../../hooks/useViewMode';
import { UnstyledFieldset } from '../../components/commonStyledComponents';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { FundDataFields } from './FundDataFields';
import { RoundParticipationToggle, RoundParticipationType } from './RoundParticipationToggle';
import { useRoundParticipation } from './useRoundParticipation';

interface IRoundParticipationSectionProps {
  transactionIndex: number;
}

export const RoundParticipationSection: FC<IRoundParticipationSectionProps> = ({ transactionIndex }) => {
  const isViewMode = useViewMode();
  const { setValue, control, getValues } = useFormContext<Partial<IScenarioDataModel>>();
  const { getInitialParticipation, getProRataValues, getNoneParticipationValues } = useRoundParticipation();

  const roundSize = useWatch({
    name: `scenarioTransactions.${transactionIndex}.roundSize`,
    control,
  });
  const noRoundSize = typeof roundSize !== 'number';

  const [type, setType] = useState<RoundParticipationType>(() => {
    const initialFundData = getValues(`scenarioTransactions.${transactionIndex}.fundData`);
    if (noRoundSize || !initialFundData) return RoundParticipationType.none;
    return getInitialParticipation(roundSize, initialFundData) ?? RoundParticipationType.none;
  });

  const handleTypeChange = useCallback(
    (typeValue: RoundParticipationType) => {
      setType(typeValue);
      if (typeof roundSize !== 'number') return;

      if (typeValue === RoundParticipationType.proRata) {
        setValue(`scenarioTransactions.${transactionIndex}.fundData`, getProRataValues(roundSize));
      } else if (typeValue === RoundParticipationType.none) {
        setValue(`scenarioTransactions.${transactionIndex}.fundData`, getNoneParticipationValues());
      }
    },
    [getNoneParticipationValues, getProRataValues, roundSize, setValue, transactionIndex]
  );

  useEffect(() => {
    if (noRoundSize) {
      setType(RoundParticipationType.none);
      setValue(`scenarioTransactions.${transactionIndex}.fundData`, getNoneParticipationValues());
    } else if (type === RoundParticipationType.proRata) {
      setValue(`scenarioTransactions.${transactionIndex}.fundData`, getProRataValues(roundSize));
    }
  }, [
    getNoneParticipationValues,
    getProRataValues,
    noRoundSize,
    roundSize,
    setValue,
    transactionIndex,
    type,
  ]);

  const disableFundData = noRoundSize || type === RoundParticipationType.proRata;
  const hideFundData = noRoundSize || type === RoundParticipationType.none;
  const disableAddInvestment = isViewMode || noRoundSize || type !== RoundParticipationType.other;

  return (
    <>
      <Stack style={{ gridColumn: '1 / -1' }}>
        <Divider sx={{ my: '1rem' }} />
        <Label>Round Participation</Label>
        <RoundParticipationToggle value={type} handleChange={handleTypeChange} disabled={noRoundSize} />
      </Stack>
      <Collapse in={!hideFundData}>
        <UnstyledFieldset disabled={disableFundData}>
          {type === RoundParticipationType.proRata && <InfoBox message='To edit values, select "Other"' />}
          <FundDataFields transactionIndex={transactionIndex} disabled={disableAddInvestment} />
        </UnstyledFieldset>
      </Collapse>
    </>
  );
};
