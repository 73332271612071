import { Kpi, KpiConfig, KpiConfigMeta, KPI_COMPONENTS, KpiCalculationParams } from '../../../../types';
import { format } from './kpi-formatter';

export class TotalUnrealizedValueConfig implements KpiConfig {
  private id = KPI_COMPONENTS.TOTAL_UNREALIZED_VALUE as const;
  private title = 'Total Unrealized Value';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate(params: KpiCalculationParams): Kpi {
    let value = 0;

    params.data?.rawCompanies?.forEach((rawCompany) => {
      value += rawCompany.transactions.reduce((sum, curr) => sum + (Number(curr.currentInvestment) ?? 0), 0);
    });

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
