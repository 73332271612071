import React, { CSSProperties, useCallback, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, Fade, MenuList, useTheme, Stack, MenuItem, MenuItemProps } from '@mui/material';

interface MenuItemOptionProps extends MenuItemProps {
  children?: React.ReactNode;
}

export const MenuItemWithDivider = ({ children, ...props }: MenuItemOptionProps) => (
  <MenuItem {...props} divider={true}>
    {children}
  </MenuItem>
);

type MoreOptionsMenuProps = {
  children: React.ReactNode;
  moreMenuIconSx?: React.CSSProperties;
  style?: CSSProperties;
};

export function MoreOptionsMenu({ children, moreMenuIconSx, style }: MoreOptionsMenuProps) {
  const { colors } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickItemClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Stack style={style}>
      <IconButton
        id='fade-button'
        data-testid='menuoptions-iconbtn'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color='secondary'
        size={'medium'}
        sx={{
          zIndex: 50,
        }}
        title='menu'
      >
        <MoreVertIcon sx={moreMenuIconSx ?? { color: colors.primary[60] }} />
      </IconButton>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        elevation={2}
      >
        <MenuList onClick={handleOnClickItemClose} sx={{ width: '150px', py: 0 }}>
          {children}
        </MenuList>
      </Menu>
    </Stack>
  );
}
