// temporary solution, will need lots of refactoring
import { ICellRendererParams } from 'ag-grid-community';
import { FC, memo } from 'react';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { FormattedComponent, isErrorValue } from '../../../../../util/FormattedComponent';
import { getFormattedValue } from '../../../../../util/getFormattedValue';
import { MultiLinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/MultiLinkRenderer';
import { BoardMembersRenderer } from './BoardMembersRenderer';
import { ErrorCell } from './ErrorCell';
import { LoadingCell } from './LoadingCell';

export interface FieldDataCellRendererProps extends ICellRendererParams {
  fieldsMap: Record<number, FieldDataModel> | null;
  loading: boolean;
}

export const FieldDataCellRenderer: FC<FieldDataCellRendererProps> = memo(function FieldDataCellRenderer(
  params: FieldDataCellRendererProps
) {
  const { node, value, fieldsMap, loading } = params;
  const field = fieldsMap?.[node.data?.id];

  if (!node.data || !field) return null;

  const { type, formatting } = field;

  if (isErrorValue(value)) return <ErrorCell />;

  if (value === undefined && loading) return <LoadingCell />;

  if (type === 'array' && formatting === 'url') return <MultiLinkRenderer value={value} />;

  if (type === 'array' && formatting === 'boardMembers' && Array.isArray(value))
    return <BoardMembersRenderer value={value} />;

  const formattedValue = getFormattedValue({ value, type, formatting });
  return <FormattedComponent type={type} formatting={formatting} value={formattedValue} />;
});
