import { Stack, Typography } from '@mui/material';
import { useState, useCallback } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { FieldSelect } from '../../../../components/Form/Display/FieldSelect';
import { FormFieldsContainer } from '../../../../components/Form/FormComponents';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { InfoBox } from '../../../Mappings/forms/InfoBox';
import { selectedCompanyIdProfile } from '../../state/UIState';
import { useDuplicateCaptable } from '../CapTableActions';
import { captablesByCompanyState } from '../CapTableDataState';
import { isDuplicateSource, isUserCreated } from '../captable-utils';
import { useCaptableSourceSelector } from './useCaptableSourceSelector';

export function DuplicateDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captablesForCompany = useRecoilValue(captablesByCompanyState(companyId ?? -1));
  const [loading, setLoading] = useState(false);
  const duplicateCaptable = useDuplicateCaptable();
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(() => {
    return captablesForCompany.find((c) => isDuplicateSource(c))?.id ?? null;
  });

  const sourceField = useCaptableSourceSelector();

  const onDuplicate = useCallback(async () => {
    if (typeof selectedSourceId !== 'number') return;
    setLoading(true);
    await duplicateCaptable(selectedSourceId);
    setLoading(false);
    onClose();
  }, [duplicateCaptable, onClose, selectedSourceId]);

  const showOverrideWarning = captablesForCompany.some((c) => isUserCreated(c));
  const prompt = showOverrideWarning ? (
    <Typography variant='body2' color='text.primary'>
      Please select an existing Cap Table to clone. It will create a new &quot;User Created&quot; Cap Table.
    </Typography>
  ) : (
    <InfoBox
      message={
        <Typography variant='body2' lineHeight={'1.75rem'}>
          <p>Please select an existing Cap Table to clone.</p>
          <p>It will create a new &quot;User Created&quot; Cap Table</p>
        </Typography>
      }
    />
  );

  return (
    <BasicDialog open={open} onClose={onClose} title={'Copy Cap Table'}>
      <FormFieldsContainer>
        <Stack gap='0.5rem'>
          {showOverrideWarning && prompt}
          <Stack>
            <Typography variant='caption' component={'label'}>
              Source
            </Typography>
            <FieldSelect
              formField={sourceField}
              formProps={
                {
                  value: selectedSourceId,
                  onChange: (val) => {
                    setSelectedSourceId(val);
                  },
                } as ControllerRenderProps
              }
            />
          </Stack>
          {showOverrideWarning ? (
            <InfoBox
              variant='warning'
              message={
                <Typography variant='body2' lineHeight={'1.75rem'}>
                  <p>This will override an existing cap table.</p>
                  <p>Do you want to proceed?</p>
                </Typography>
              }
            />
          ) : (
            prompt
          )}
        </Stack>
      </FormFieldsContainer>
      <StickyFormButtons
        onCancel={onClose}
        onSubmit={onDuplicate}
        submitLabel={showOverrideWarning ? 'Yes, Copy' : 'Copy'}
        loading={loading}
      />
    </BasicDialog>
  );
}
