import { FC, useCallback, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { AirtableSyncType, ISyncedColumnDataModel } from '../../../../data-models/airtable-sync.data-model';
import ConnectIcon from '../../../../assets/icons/connect.svg?react';
import { StickyFormButtons } from '../../../../components/Form/StickyFormButtons';
import { IForesightFieldDataModel } from '../../../../data-models/airtable-entity.data-model';
import { SyncFieldBox } from './SyncedColumns';

const SyncFieldContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1.25rem 1fr 5rem;
  align-items: center;
  gap: 1rem;
  width: 42rem;
  max-width: 85vw;
  margin: 0.5rem 0;
`;

const ContentContainer = styled('div')`
  padding: 0 2.5rem 2rem;
`;

interface IConfirmDisconnectProps {
  open: boolean;
  onClose: () => void;
  columns: ISyncedColumnDataModel[];
  onDisconnect: () => Promise<void>;
  fdFieldsMap: Map<string | number, IForesightFieldDataModel>;
}

export const ConfirmDisconnect: FC<IConfirmDisconnectProps> = ({
  open,
  onClose,
  columns,
  onDisconnect,
  fdFieldsMap,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDisconnect = useCallback(async () => {
    setLoading(true);
    await onDisconnect();
    setLoading(false);
  }, [onDisconnect]);

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      titleComponent={
        <Typography component={'h4'} fontWeight={500}>
          Disconnect sync?
        </Typography>
      }
    >
      <ContentContainer>
        <Typography variant='body2' component='div'>
          Established Sync which will be disconnected:
        </Typography>
        {columns.map((column) => (
          <SyncFieldContainer key={column.id}>
            <SyncFieldBox>
              {fdFieldsMap.get(column.entityColumn)?.displayName || column.entityColumn}
            </SyncFieldBox>
            <ConnectIcon />
            <SyncFieldBox>{column.airtableColumnName}</SyncFieldBox>
            <Typography variant='body2'>{`(${
              column.syncType === AirtableSyncType.editable ? 'Editable' : 'Read Only'
            })`}</Typography>
          </SyncFieldContainer>
        ))}
        <Divider style={{ marginTop: '1.25rem' }} />
        <StickyFormButtons
          submitLabel='Disconnect'
          onSubmit={handleDisconnect}
          onCancel={onClose}
          loading={loading}
          style={{ padding: '2rem 0 0' }}
        />
      </ContentContainer>
    </BasicDialog>
  );
};
