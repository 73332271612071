import { useCallback } from 'react';
import { FieldPath, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { TransactionCategory } from '../../../data-models/transaction-type.data-model';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { TRANSACTION_SUB_TYPES } from '../../../types';
import { IFormField } from '../../../view-models/form.view-model';
import { eqBuyFields } from '../../Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';
import { transactionDateSchema } from './transactionFieldSchemas';

export function getCommonExtendedSchemas() {
  return {
    transactionDate: getExtendedSchema(transactionDateSchema()),
    fundId: getExtendedSchema(eqBuyFields().fundId),
    currencyid: getExtendedSchema(eqBuyFields().currencyid),
  };
}

export function getDisabledFieldsForConfirmed(
  transactionTypeAndSubtype: string
): FieldPath<ITransactionDataModel>[] {
  const result: FieldPath<ITransactionDataModel>[] = ['fundId', 'transactionDate', 'currencyid'];
  switch (transactionTypeAndSubtype) {
    case `${TransactionCategory.Escrow}_${TRANSACTION_SUB_TYPES.ESCROW_RECEIVED}`: {
      result.push('escrowAmount');
      break;
    }
    case `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.BUY}`:
    case `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SECONDARY_PURCHASE}`: {
      result.push('security', 'investmentAmount', 'noOfShares');
      break;
    }
    case `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SELL}`: {
      result.push('noOfShares', 'receivedAmount');
      break;
    }
    case `${TransactionCategory.Equity}_${TRANSACTION_SUB_TYPES.SHARE_EXCHANGE}`: {
      result.push('receivedSharesNo', 'receivedPricePerShare');
      break;
    }
    case `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_CONVERSION}`: {
      result.push('security', 'convertedPrincipalAmount', 'convertedInterestAmount', 'convertedSharesNo');
      break;
    }
    case `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_CONVERSION}`: {
      result.push('security', 'convertedPrincipalAmount', 'convertedSharesNo');
      break;
    }
    case `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.NOTE_ISSUANCE}`:
    case `${TransactionCategory.Note}_${TRANSACTION_SUB_TYPES.SAFE_ISSUANCE}`: {
      result.push('principalAmount');
      break;
    }
  }
  return result;
}

export function getExtendedSchema(schema: yup.Schema) {
  return schema
    .clone()
    .when('status', {
      is: 'Confirmed',
      then: (schema) => schema.optional().nullable(),
    })
    .transform((value) => {
      if (value != null) return value;
      return null;
    })
    .default(null);
}

export function setExtendedRules(
  fieldGetter: () => Partial<Record<FieldPath<ITransactionDataModel>, yup.AnySchema>>,
  paths: FieldPath<ITransactionDataModel>[]
) {
  return paths.reduce(
    (acc, path) => {
      acc[path] = getExtendedSchema(fieldGetter()[path] as yup.Schema);

      return acc;
    },
    {} as Partial<Record<FieldPath<ITransactionDataModel>, yup.Schema>>
  );
}

export interface IFieldModifierParams {
  category: TransactionCategory;
  subtype: TRANSACTION_SUB_TYPES;
  fields: IFormField<unknown>[];
}
export function modifyFieldsForConfirmed({ category, subtype, fields }: IFieldModifierParams) {
  const optional = new Set([...getDisabledFieldsForConfirmed(`${category}_${subtype}`)]);
  return fields.map((field) => {
    if (optional.has(field.key as FieldPath<ITransactionDataModel>)) {
      field.required = false;
      field.disabled = true;
    }
    return field;
  });
}

export function useExtendedFieldRules() {
  const status = useWatch<ITransactionDataModel>({ name: 'status' });

  return useCallback(
    ({ fields, category, subtype }: IFieldModifierParams) => {
      if (status !== 'Confirmed') return fields;
      return modifyFieldsForConfirmed({
        fields,
        category,
        subtype,
      });
    },
    [status]
  );
}
