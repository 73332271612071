import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import { IconButton, Stack, StackProps, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ValidScenarioModel } from '../../utils/scenarioUtils';
import { useAllScenariosLocation } from '../../hooks/useAllScenariosLocation';
import { companyState } from '../../../../../services/state/CompanyState';
import { ChipStack } from '../../components/commonStyledComponents';
import { TypeChip } from '../../AllScenarios/ScenarioTypes';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { useCanEditScenario } from '../../hooks/useCanEditScenario';
import { useViewMode } from '../../hooks/useViewMode';
import { ScenarioNameField } from '../form/ScenarioNameField';
import { RelevanceTag } from '../../AllScenarios/RelevanceTag';
import { ScenarioTransactionsChips } from './ScenarioTransactionsChips';

export interface IVisualizationHeadingProps {
  scenario: ValidScenarioModel;
}
export const HeaderStack: FC<StackProps> = ({ children, ...rest }) => {
  return (
    <Stack direction='row' justifyContent={'start'} gap='0.5rem' alignItems={'center'} {...rest}>
      {children}
    </Stack>
  );
};

export const ScenarioTitle: FC<IVisualizationHeadingProps> = ({ scenario }) => {
  const allScenariosPath = useAllScenariosLocation();
  const company = useRecoilValue(companyState(scenario.companyId));

  const canEdit = useCanEditScenario(scenario);
  const isViewMode = useViewMode();
  const disabled = !canEdit || isViewMode;

  if (!company) return null;

  return (
    <HeaderStack>
      <IconButton component={NavLink} to={allScenariosPath ?? ''} title='Back to all scenarios'>
        <ChevronLeftIcon color='secondary' />
      </IconButton>
      {disabled ? (
        <Typography variant='h4' color={'text.primary'}>
          {scenario.name}
        </Typography>
      ) : (
        <ScenarioNameField style={{ width: '15rem', marginBottom: '-1rem' }} />
      )}
      <CompanyLogoAndName
        name={company.name}
        logoUrl={company.logoUrl ?? ''}
        typographyProps={{ variant: 'body1' }}
        style={{ columnGap: '0.25rem', marginInline: '0.5rem' }}
      />
      <ScenarioTransactionsChips transactions={scenario.scenarioTransactions ?? []} />
      <ChipStack style={{ marginLeft: '0.5rem' }}>
        {scenario.tags?.map((tag) => (
          <TypeChip key={tag} type={tag!} />
        ))}
      </ChipStack>
      {scenario.outdated && <RelevanceTag outdated={true} />}
    </HeaderStack>
  );
};
