import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { Divider, Stack } from '@mui/material';
import { motion } from 'framer-motion';
import { selectedCompanyIdProfile } from '../state/UIState';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { CPWrapper } from '../components/CompanyProfilesWrapper';
import { CaptableSummary } from './SummaryGrid/CaptableSummary';
import { captablesByCompanyState, selectedCaptableState } from './CapTableDataState';
import { NoCapTable } from './NoCapTable';
import { CaptableSelector } from './SummaryGrid/CaptableSelector';
import { AllCaptablesMenu } from './AllCaptableActions/AllCaptablesMenu';

export const Wrapper = styled('div')`
  margin-top: 24px;
`;

export const CompanyProfileCapTable = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const captables = useRecoilValue(captablesByCompanyState(companyId ?? -1));

  return (
    <CPWrapper>
      <Wrapper>{captables.length === 0 ? <NoCapTable /> : <CaptableMainSection />}</Wrapper>
    </CPWrapper>
  );
};

export function CaptableMainSection() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const selectedCaptable = useRecoilValue(selectedCaptableState(companyId ?? -1));
  const canEditCaptable = PermissionService.get().hasPermission(PermissionKey.canEditTransaction);

  return (
    <motion.div animate={{ opacity: [0.6, 1] }} transition={{ duration: 0.5 }} key={selectedCaptable?.id}>
      <Stack
        display={'grid'}
        gridTemplateColumns={'1fr auto'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <CaptableSelector />
        <Stack display={'grid'} gridAutoFlow={'column'} gap='1rem' alignItems={'center'}>
          {canEditCaptable && <AllCaptablesMenu />}
        </Stack>
      </Stack>
      <Divider style={{ marginBlock: '0.75rem' }} />
      {selectedCaptable && <CaptableSummary data={selectedCaptable} />}
    </motion.div>
  );
}
