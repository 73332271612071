import { FC, useCallback, useState } from 'react';
import { Alert, Typography, useTheme } from '@mui/material';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { useInvestmentActions } from '../../hooks/useInvestmentActions';
import { useDryRunDeleteInvestment } from '../../hooks/useDryRunDeleteInvestment';
import { pluralizeWord } from '../../../../util/stringUtils';
import { Loader } from '../../../../components/Loader/Loader';
import { IInvestmentFormModalProps } from './EditInvestmentModal';

export const DeleteInvestmentModal: FC<IInvestmentFormModalProps> = ({ open, onClose, investment }) => {
  const { handleDeleteInvestment } = useInvestmentActions();
  const { nAttachedTransactions, loading: awaitingDryRunResponse } = useDryRunDeleteInvestment(investment.id);
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await handleDeleteInvestment(investment);
    setLoading(false);
    onClose();
  }, [onClose, handleDeleteInvestment, investment]);

  return open ? (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
      title='Are you sure you want to delete this round?'
      loading={loading}
    >
      {awaitingDryRunResponse ? (
        <Loader />
      ) : (
        <>
          <Typography variant='body2' color={colors.neutral[60]}>
            {`The round ${investment.name ?? ''} will be deleted permanently`}
          </Typography>
          {nAttachedTransactions ? (
            <>
              <Alert severity='warning' sx={{ mb: '0.5rem' }} role='alert'>
                {`This round has ${nAttachedTransactions} ${pluralizeWord('transaction')} attached, are you sure you would like to delete it?`}
                <br />
                You can reassign the transactions to another existing round.
              </Alert>
            </>
          ) : (
            <div />
          )}
        </>
      )}
    </ConfirmDeletionDialog>
  ) : (
    <div />
  );
};
