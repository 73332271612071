import { useRecoilCallback } from 'recoil';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { companyState } from '../state/CompanyState';

export function useSaveCompanyData(overwrite = true) {
  const batchSaveCompanyData = useRecoilCallback(({ set, snapshot }) => (companies: ICompanyDataModel[]) => {
    companies.forEach((company) => {
      if (overwrite || !snapshot.getInfo_UNSTABLE(companyState(company.id)).loadable?.valueMaybe()) {
        set(companyState(company.id), company);
      }
      // else do nothing, don't overwrite state
    });
  });

  return { batchSaveCompanyData };
}
