import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAllCompaniesRowSelection } from '../hooks/useAllCompaniesRowSelection';
import { allCompaniesActionState, selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { BulkActionButtons } from '../../KPIRequests/BulkAcceptResponse';

type IBulkAssignButtonsProps = Pick<
  ReturnType<typeof useAllCompaniesRowSelection>,
  'selected' | 'deselectAll'
>;

export function BulkAssignButtons({ selected, deselectAll }: IBulkAssignButtonsProps) {
  const setSelectedCompanies = useSetRecoilState(selectedKpiCompaniesStateDC);
  const setSelectedAction = useSetRecoilState(allCompaniesActionState);

  const handleAssign = useCallback(() => {
    setSelectedCompanies(selected);
    setSelectedAction('assign');
    deselectAll();
  }, [setSelectedCompanies, selected, setSelectedAction, deselectAll]);

  const handleCancel = useCallback(() => {
    deselectAll();
  }, [deselectAll]);

  return <BulkActionButtons onAction={handleAssign} onCancel={handleCancel} actionLabel='Assign' />;
}
