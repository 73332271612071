import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFormFromFieldsArray, IForm } from '../../../view-models/form.view-model';
import { FormFactoryWithStandardLayout } from '../../../components/Form/FormFactory';
import { StepperFormFieldsContainer } from '../../../components/Form/FormComponents';
import { useInvestmentFields } from '../../../data-fields/InvestmentFields';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { useInvestmentActions } from '../hooks/useInvestmentActions';
import { assignToInvestmentSchema } from '../Transactions/AssignToRound/investmentSchemas';
import {
  createFinanceRoundFormViewModel,
  fromFinanceRoundDataModel,
  IFinanceRoundFormViewModel,
  isNewFinanceRound,
  isNewRoundId,
  toFinanceRoundDataModel,
} from '../../../view-models/finance-round-form.view-model';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { companyRoundsByIdState } from '../state/FinanceState';
import { formatISODateOnly } from '../../../util/formatters/DateFormatters';
import { currentStepState, investmentDataState, selectedCompanyIdState } from './TransactionFormUIState';
import { StepperFormButtons } from './StepperFormButtons';
import { InvestmentSelectForm } from './InvestmentSelectForm';

export interface IInvestmentFormProps {
  companyId: number;
  formConfig?: Omit<IForm, 'fields'>;
  removeConfig?: {
    transaction: ITransactionDataModel;
    onRemove: () => void;
  };
}

export function InvestmentCreateForm(props: Omit<IInvestmentFormProps, 'companyId'>) {
  const { formConfig } = props;
  const { title = 'Enter New Round Information', variant = 'form' } = formConfig ?? {};
  const investmentFields = useInvestmentFields();

  const form = createFormFromFieldsArray(investmentFields, {
    title,
    variant,
  });

  return <FormFactoryWithStandardLayout form={form} />;
}

export function InvestmentSelectOrCreateForm(props: IInvestmentFormProps) {
  const { setValue } = useFormContext<Partial<IFinanceRoundFormViewModel>>();
  const { companyId, removeConfig } = props;
  const [showNewInvestmentForm, setShowNewInvestmentForm] = useState(false);

  const onAddNewInvestment = useCallback(() => {
    setValue('id', undefined);
    setShowNewInvestmentForm(true);
  }, [setValue]);

  return (
    <Stack display={'grid'} gridTemplateRows={'min-content 1fr'} gap='1rem'>
      {showNewInvestmentForm ? (
        <InvestmentCreateForm />
      ) : (
        <InvestmentSelectForm
          companyId={companyId}
          onAddNewInvestment={onAddNewInvestment}
          removeConfig={removeConfig}
        />
      )}
    </Stack>
  );
}

export function InvestmentFormStep() {
  const companyId = useRecoilValue(selectedCompanyIdState);
  const [investmentData, setInvestmentData] = useRecoilState(investmentDataState);
  const investmentsForCompany = useRecoilValue(companyRoundsByIdState(companyId ?? -1));
  const { handleCreateInvestment } = useInvestmentActions();
  const setCurrentStep = useSetRecoilState(currentStepState);

  const defaultValues = useMemo(() => {
    return investmentData
      ? fromFinanceRoundDataModel(investmentData as IFinanceRoundDataModel)
      : getInitialData(companyId!);
  }, [companyId, investmentData]);

  const methods = useForm<IFinanceRoundFormViewModel>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(assignToInvestmentSchema),
  });

  const { getValues, trigger, reset, control } = methods;
  const [showNewInvestmentForm, setShowNewInvestmentForm] = useState(
    () => investmentData !== null && isNewFinanceRound(investmentData)
  );

  const investmentRoundId = useWatch({ name: 'id', control });
  const isSkippingRound = !showNewInvestmentForm && isNewRoundId(investmentRoundId);
  const nextButtonLabel = isSkippingRound ? 'Skip' : 'Next';

  const onAddNewInvestment = useCallback(
    (name?: string) => {
      reset({ ...getInitialData(companyId!), name });
      setShowNewInvestmentForm(true);
    },
    [companyId, reset]
  );

  const handleNextStep = useCallback(async () => {
    const data = getValues();
    const financeRoundDataModel = toFinanceRoundDataModel(data);

    const isNew = !isSkippingRound && isNewFinanceRound(data);

    if (isSkippingRound) {
      setInvestmentData(null);
      return true;
    }

    if (!isNew) {
      setInvestmentData(investmentsForCompany.get(data.id ?? -1) ?? financeRoundDataModel);
      return true;
    }

    const isNewInvestmentValid = await trigger();
    if (isNewInvestmentValid) {
      const res = await handleCreateInvestment(financeRoundDataModel);

      if (res) {
        setInvestmentData(res);
        return true;
      }
    }

    return false;
  }, [getValues, handleCreateInvestment, investmentsForCompany, isSkippingRound, setInvestmentData, trigger]);

  const handleGoBack = useCallback(() => {
    if (showNewInvestmentForm) {
      reset(getInitialData(companyId!));
      setShowNewInvestmentForm(false);
      return;
    }

    const formData = getValues();

    if (isSkippingRound) {
      setInvestmentData(null);
    } else {
      setInvestmentData(formData);
    }

    setCurrentStep((prev) => prev - 1);
  }, [
    companyId,
    getValues,
    isSkippingRound,
    reset,
    setCurrentStep,
    setInvestmentData,
    showNewInvestmentForm,
  ]);

  if (companyId === null) {
    return <div>No company selected</div>;
  }

  return (
    <FormProvider {...methods}>
      <StepperFormFieldsContainer style={{ alignContent: 'start' }}>
        {showNewInvestmentForm ? (
          <InvestmentCreateForm />
        ) : (
          <>
            <Typography variant='body1'>Select a Round</Typography>
            <InvestmentSelectForm companyId={companyId} onAddNewInvestment={onAddNewInvestment} />
          </>
        )}
      </StepperFormFieldsContainer>
      <StepperFormButtons
        stepIsValid={handleNextStep}
        nextButtonLabel={nextButtonLabel}
        handleGoBack={handleGoBack}
        backButtonLabel={showNewInvestmentForm ? 'Cancel' : undefined}
      />
    </FormProvider>
  );
}

function getInitialData(companyId: number) {
  return createFinanceRoundFormViewModel({
    companyId,
    roundDate: formatISODateOnly(new Date()),
  });
}
