import { SelectItem } from '../../../types';

export function sortGroupableOptions(options: SelectItem[]) {
  return [...options]
    .sort((a, b) => a.value.localeCompare(b.value))
    .sort((a, b) => {
      if (a.group && b.group) {
        return a.group.localeCompare(b.group);
      } else if (a.group) {
        return -1;
      } else if (b.group) {
        return 1;
      } else {
        return 0;
      }
    });
}
