import { ColGroupDef } from 'ag-grid-community';
import { FieldDataModel } from '../../../../../data-models/field.data-model';
import { groupComparator } from '../../../util/groupComparator';
import { getTypeBasedDefs } from './typeBasedColDefs';

export const mapFieldsToColDefs = (
  fields: FieldDataModel[],
  groupOrderMap: Map<string, { groupPriority: number | null; packagePriority: number | null }>,
  loading?: boolean
) => {
  const fieldGroupsMap: Record<string, FieldDataModel[]> = fields.reduce((map, field) => {
    const currFieldGroupName = field.groupName || 'ungrouped';
    if (map[currFieldGroupName]) {
      map[currFieldGroupName].push(field);
    } else {
      map[currFieldGroupName] = [field];
    }
    return map;
  }, {} as Record<string, FieldDataModel[]>);

  // Chandra says "Field will belong to some group no matter what"
  const getColGroupDefs = (): ColGroupDef[] => {
    const result: ColGroupDef[] = [];

    for (const entry of Object.entries(fieldGroupsMap)) {
      const [groupName, fields] = entry;

      // sort children within each group
      fields.sort((a, b) => {
        return (a.groupOrder ?? -1) - (b.groupOrder ?? -1);
      });

      result.push({
        headerName: groupName,
        headerClass: 'color-header-group',
        groupId: groupName,

        children: fields.map((field, i) => ({
          headerName: field.displayName,
          field: field.name,
          columnGroupShow: i === 0 ? undefined : 'open',

          ...getTypeBasedDefs(field, loading),
        })),
      });
    }

    return result;
  };

  const colGroupDefs = getColGroupDefs();

  // sort groups by groupPriority
  return colGroupDefs.sort((colGroupDefA, colGroupDefB) => {
    const nameA = colGroupDefA.groupId ?? null;
    const nameB = colGroupDefB.groupId ?? null;
    return groupComparator(nameA, nameB, groupOrderMap);
  });
};
