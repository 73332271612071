import { useRecoilValue } from 'recoil';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { kpiGroupsState } from '../../../services/state/KPI/KPITemplatesState';
import { SortKPIGroups } from '../KPI/KPIManageGroups';
import { getKPIGroupFields } from './KPIGroupFields';

export const KPIGroupConfigBaseURL = '/kpiGroupConfig';

export function ConfigKPIGroups() {
  const kpiGroups = useRecoilValue(kpiGroupsState);

  return (
    <GenericConfigPage
      baseTitle={'KPI Group'}
      customToolbarElem={<SortKPIGroups />}
      data={kpiGroups}
      fields={getKPIGroupFields()}
      pageTitle={'KPI Groups'}
      queryURL={KPIGroupConfigBaseURL}
    />
  );
}
