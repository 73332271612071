import { FC, useCallback, useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, IconButton, Link, Paper, Popover, Stack, Typography } from '@mui/material';
import Mail from '../../../../../assets/icons/mail.svg?react';
import { usePersonEmails } from '../../../../../services/hooks/usePersonEmails';
import { Loader } from '../../../../../components/Loader/Loader';
import { ZINDEX } from '../../../../../constants/styles';
import { useUpdatePersonEmail } from '../../hooks/useUpdatePersonEmail';

const IconButtonStyles = {
  padding: '0.1rem',
};

const AllEmailsContainer = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary[60]};
`;

const EmailCard = styled(Paper)`
  padding: 1rem;
  border-radius: 0.25rem;
  background: var(--white, #fff);
  box-shadow: 0px 24px 48px 0px rgba(75, 82, 93, 0.04), 0px 4px 24px 0px rgba(16, 37, 62, 0.06),
    0px -4px 40px 0px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
`;

interface IEmailButtonProps {
  companyId?: number;
  personId: number;
  primaryEmail?: string | null;
  secondaryEmails?: string[] | null;
}

/** if !primaryEmail, data will be fetched from secondary source */
export const EmailButton: FC<IEmailButtonProps> = ({
  primaryEmail,
  secondaryEmails,
  personId,
  companyId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { getAndSetPersonEmails, loading } = usePersonEmails(companyId);
  const { handleUpdateEmail } = useUpdatePersonEmail(personId);
  const [primaryEmailState, setPrimaryEmailState] = useState(primaryEmail);

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (primaryEmail && primaryEmail !== 'email_not_unlocked@domain.com') return;
      const newPrimaryEmail = await handleUpdateEmail();
      setPrimaryEmailState(newPrimaryEmail);
      getAndSetPersonEmails(personId);
    },
    [anchorEl, getAndSetPersonEmails, handleUpdateEmail, personId, primaryEmail]
  );

  return (
    <>
      <IconButton style={IconButtonStyles} onClick={handleClick}>
        <Mail />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        style={{ zIndex: ZINDEX.POPPER_TOOLTIP }}
        onClose={() => setAnchorEl(null)}
      >
        <div>
          <Emails primaryEmail={primaryEmailState} secondaryEmails={secondaryEmails} loading={loading} />
        </div>
      </Popover>
    </>
  );
};

interface IEmailProps {
  primaryEmail?: string | null;
  secondaryEmails?: string[] | null;
  loading?: boolean;
}
export const Emails: FC<IEmailProps> = ({ primaryEmail, secondaryEmails, loading }) => {
  return (
    <EmailCard>
      <Typography variant='body1'>Contact Details</Typography>
      <Divider style={{ margin: '0.75rem 0' }} />
      {loading ? (
        <Loader />
      ) : !primaryEmail && !secondaryEmails?.length ? (
        <Typography variant='body2' justifyContent={'center'} color='text.secondary'>
          No data
        </Typography>
      ) : (
        <AllEmailsContainer>
          {Boolean(primaryEmail) && (
            <>
              <Typography variant='body2'>Work</Typography>
              <Typography
                component={StyledLink}
                variant='body2'
                href={`mailto:${primaryEmail}`}
                underline='none'
              >
                {primaryEmail}
              </Typography>
            </>
          )}
          {Boolean(secondaryEmails?.length) && (
            <>
              <Typography variant='body2'>Personal</Typography>
              <Stack gap='0.5rem'>
                {secondaryEmails!.map((mail) => (
                  <Typography
                    key={mail}
                    component={StyledLink}
                    variant='body2'
                    href={`mailto:${mail}`}
                    underline='none'
                  >
                    {mail}
                  </Typography>
                ))}
              </Stack>
            </>
          )}
        </AllEmailsContainer>
      )}
    </EmailCard>
  );
};
