import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { getRubricConfig, getRubrics } from '../queries/MaggieRubricQueries';
import { Rubric, RubricConfig } from '../../schemas/Rubric.schema';
import { EntityType } from '../../data-models/field2.data-model';

export const rubricConfigState = atom<RubricConfig[]>({
  key: 'rubricConfigState',
  default: selector({
    key: 'rubricConfigState/default',
    get: async () => {
      return getRubricConfig();
    },
  }),
});

export const rubricConfigByIdState = selector({
  key: 'rubricConfigByIdState',
  get: ({ get }) => {
    const rubricConfig = get(rubricConfigState);
    return rubricConfig.reduce((acc, config) => {
      return acc.set(config.id, config);
    }, new Map<number, RubricConfig>());
  },
});

type IRubricsByCompanyIdKey = {
  companyId: number;
  date: string;
};

export const forceRubricRefreshState = atom<number>({
  key: 'forceRubricRefreshState',
  default: 0,
});

export const mostRecentRubricsByCompanyIdState = atomFamily<Rubric[], IRubricsByCompanyIdKey>({
  key: 'mostRecentRubricsByCompanyIdState',
  default: selectorFamily<Rubric[], IRubricsByCompanyIdKey>({
    key: 'rubricsByCompanyIdState/default',
    get:
      ({ companyId, date }) =>
      ({ get }) => {
        get(forceRubricRefreshState);
        return getRubrics({ entityId: companyId, entity: EntityType.company, date });
      },
  }),
});

export const mostRecentCompanyRubricsByConfigIdState = selectorFamily<
  Map<number, Rubric>,
  IRubricsByCompanyIdKey
>({
  key: 'companyRubricsByConfigIdState',
  get:
    ({ companyId, date }) =>
    ({ get }) => {
      const rubrics = get(mostRecentRubricsByCompanyIdState({ companyId, date }));
      return rubrics.reduce(
        (map, rubric) => map.set(rubric.rubricConfigId, rubric),
        new Map<number, Rubric>()
      );
    },
});
