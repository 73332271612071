import { useMemo } from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGetTransactionTypesSelectItems } from '../../../data-fields/TransactionFields';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { ISimpleChoice } from '../../../data-models/field2.data-model';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const AllTransactionsItem: ISimpleChoice<number> = {
  displayName: 'All Transactions',
  value: -1,
};

export type AvailableTransactionTypesPillsProps = {
  selectedItemId: number;
  transactions: ITransactionDataModel[];
  onChange: (selectedItem: ISimpleChoice<number>) => void;
};

export function AvailableTransactionTypePills(props: AvailableTransactionTypesPillsProps) {
  const { onChange, selectedItemId, transactions } = props;
  const getTransactionTypes = useGetTransactionTypesSelectItems();

  const availableTransactionTypesSelectItems = useMemo(() => {
    return getAvailableTransactionTypesItems(transactions, getTransactionTypes());
  }, [getTransactionTypes, transactions]);

  const items = [AllTransactionsItem, ...availableTransactionTypesSelectItems.values()]
    .sort(sortTransactionTypeSelectItems)
    .map((availableTransType) => {
      const { displayName, value } = availableTransType;
      const isSelected = selectedItemId === value;

      return isSelected ? (
        <Chip
          key={displayName}
          label={displayName}
          onClick={() => onChange(availableTransType)}
          variant={'filled'}
          color={'primary'}
        />
      ) : (
        <Chip
          key={displayName}
          label={displayName}
          onClick={() => onChange(availableTransType)}
          variant={'outlined'}
        />
      );
    });
  return <Wrapper>{items}</Wrapper>;
}

function sortTransactionTypeSelectItems(t1: ISimpleChoice<number>, t2: ISimpleChoice<number>) {
  if (t1.value === AllTransactionsItem.value) {
    return 1;
  }
  if (t2.value === AllTransactionsItem.value) {
    return 1;
  }
  return t1.displayName?.localeCompare(t2.displayName!) ?? 0;
}

function getAvailableTransactionTypesItems(
  transactions: ITransactionDataModel[],
  allTransactionTypeItems: ISimpleChoice<number>[]
) {
  const transactionTypesAsMap = allTransactionTypeItems.reduce((res, transactionType) => {
    return res.set(transactionType.value, transactionType);
  }, new Map<number, ISimpleChoice<number>>());

  return transactions.reduce((res, transaction) => {
    return res.set(transaction.transactionTypeId, transactionTypesAsMap.get(transaction.transactionTypeId)!);
  }, new Map<number, ISimpleChoice<number>>());
}
