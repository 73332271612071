import { useCallback } from 'react';
import { atom, selector, useResetRecoilState } from 'recoil';
import { RubricConfig } from '../../../schemas/Rubric.schema';
import { rubricConfigByIdState } from '../../../services/state/RubricsState';

export type RubricConfigActions = 'create' | 'edit' | 'delete';
export const selectedRubricConfigActionState = atom<RubricConfigActions | null>({
  key: 'selectedRubricConfigActionState',
  default: null,
});

export const selectedRubricConfigIdState = atom<number | null>({
  key: 'selectedRubricConfigIdState',
  default: null,
});

export const selectedRubricConfigState = selector<RubricConfig | null>({
  key: 'selectedRubricConfigState',
  get: ({ get }) => {
    const configId = get(selectedRubricConfigIdState);
    if (configId === null) return null;
    return get(rubricConfigByIdState).get(configId) ?? null;
  },
});

export function useResetRubricConfigFormState() {
  const resetAction = useResetRecoilState(selectedRubricConfigActionState);
  const resetSelectedConfigId = useResetRecoilState(selectedRubricConfigIdState);

  return useCallback(() => {
    resetAction();
    resetSelectedConfigId();
  }, [resetAction, resetSelectedConfigId]);
}
