export enum SocketStatus {
  closed,
  connecting,
  error,
  initial,
  ready,
}
export interface ISocketErrorData {
  code: string;
  message: string;
}

export interface ISocketResponseBaseDataModel {
  type: string;
}

export interface IGenericSocketUpdate<T> extends ISocketResponseBaseDataModel {
  data: T;
}

export const enum ResponseStatus {
  aborted = 'aborted',
  complete = 'complete',
  error = 'error',
  pending = 'pending',
}

export function createBaseSocketResponse(
  overrides: Partial<ISocketResponseBaseDataModel> = {}
): ISocketResponseBaseDataModel {
  return {
    type: '',
    ...overrides,
  };
}
