import {
  createFormatterDataModel,
  IBooleanFormatConfig,
  IFormatterDataModel,
} from '../../data-models/formatter.data-model';
import { FMT } from '../formatter-service';

export enum BooleanFormatterId {
  yesNo = 'yesNo',
  trueFalse = 'trueFalse',
}

export const BooleanFormattersConfig: Record<
  BooleanFormatterId,
  IFormatterDataModel<IBooleanFormatConfig>
> = {
  yesNo: createFormatterDataModel({
    id: 'yesNo',
    config: { falseValue: 'No', trueValue: 'Yes', noValue: 'N/A' },
    type: 'boolean',
  }),
  trueFalse: createFormatterDataModel({
    id: 'trueFalse',
    config: {
      falseValue: 'false',
      trueValue: 'true',
      noValue: '',
    },
    type: 'boolean',
  }),
};

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatBooleanYesNo(value: boolean): string {
  return FMT.format('yesNo', value);
}

/** @deprecated use {@link FormatterService.format} directly instead. */
export function formatBooleanTrueFalse(value: boolean): string {
  return FMT.format('trueFalse', value);
}
