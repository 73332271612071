import { ButtonBase, Typography, styled, useTheme } from '@mui/material';
import { ROUTES } from '../../../../../../constants/RouteNameMapping';
import { NavItemType } from '../../SubSectionNavItems';
import { useSubNavItem } from './hooks/useSubNavItem';

export const SubNavItemWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 13px 0 18px;
  justify-content: space-between;
  ${({ theme, isSelected }) => (isSelected ? `background-color: ${theme.colors.primary[70]};` : '')}
  border-radius: 4px;
`;

export const SubNavItemContainer = styled(SubNavItemWrapper)<{ isSelected: boolean }>`
  justify-content: flex-start;
`;

interface ISubNavItem {
  section: NavItemType;
  parentRoute?: ROUTES | string;
  BadgeSlot?: React.ReactNode;
}

export const SubNavItem = ({ section, parentRoute, BadgeSlot }: ISubNavItem) => {
  const {
    colors: { secondary },
  } = useTheme();
  const { handleOnClick, isActive } = useSubNavItem({
    section,
    parentRoute,
  });

  return (
    <ButtonBase
      key={section.id}
      onClick={handleOnClick}
      TouchRippleProps={{ color: 'white' }}
      style={{ width: '100%', display: 'block' }}
      aria-label={section.value}
    >
      <SubNavItemContainer isSelected={isActive} sx={{ px: '.75rem', pt: 1 }}>
        <Typography variant='caption' color={secondary[10]}>
          {section.value}
        </Typography>
        {BadgeSlot ?? null}
      </SubNavItemContainer>
    </ButtonBase>
  );
};
