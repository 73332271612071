// FIXME: Use this component instead of the other custom ones MAGGIE-3441.
import { AvatarProps } from '@mui/material/Avatar/Avatar';
import { Avatar, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { getInitials } from '../../util/stringUtils';

export type AvatarSize = 'gridCell' | 'small' | 'medium' | 'large' | 'mediumLarge';

export type MUIAvatarSettings = Pick<
  AvatarProps<React.ElementType, unknown>,
  'fontSize' | 'height' | 'width'
>;

export interface IMUIAvatarProps {
  nameParts: string[];
  src: string | null;
  size?: AvatarSize;
  style?: CSSProperties | undefined;
  title?: string;
}

export const avatarSizeMapping: Record<AvatarSize, MUIAvatarSettings> = {
  gridCell: {
    fontSize: 14,
    height: 24,
    width: 24,
  },
  small: {
    fontSize: 10,
    height: 24,
    width: 24,
  },
  medium: {
    fontSize: 14,
    height: 30,
    width: 30,
  },
  large: {
    fontSize: 20,
    height: 56,
    width: 56,
  },
  mediumLarge: {
    fontSize: 20,
    height: 48,
    width: 48,
  },
};

export function MUIAvatar({ nameParts, src, style, size = 'small', title }: IMUIAvatarProps) {
  const theme = useTheme();
  const [namePart1, namePart2] = nameParts;
  const fullName = `${namePart1 ?? ''} ${namePart2 ?? ''}`;
  const initials = getInitials(nameParts);
  const sizeDetails = avatarSizeMapping[size];

  return (
    <Avatar
      alt={fullName}
      src={src ?? ''}
      style={style}
      sx={{
        bgcolor: theme.colors.primary[10],
        color: 'inherit',
        ...sizeDetails,
      }}
      title={title ?? fullName}
    >
      {initials}
    </Avatar>
  );
}
