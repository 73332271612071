import * as yup from 'yup';
import { requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { usdField } from '../../../../../../../schemas/common-schema-defs';

export function escrowFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema().label('Payment Date'),
    escrowAmount: usdField()
      .nullable()
      .min(0, 'Cash Received must be positive')
      .required('Cash Received is a required field'),
    currencyid: transactionCurrencyIdField(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
  };
}

export function escrowFormSchema() {
  return yup.object().shape(escrowFields());
}

export function escrowGridSchema() {
  return yup.object().shape(escrowFields());
}
