import * as yup from 'yup';
import { greaterThenMsg, minZeroMsg, requiredMsg, typeMsg } from '../../utils/validationMessages';
import { RendererType } from '../../../../../../../data-models/field.data-model';
import { BooleanFormatterId } from '../../../../../../../util/formatters/BooleanFormatters';
import {
  investmentRoundId,
  requiredCurrencyIdField,
} from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import {
  conversionRatioField,
  pricePerShareField,
  usdField,
} from '../../../../../../../schemas/common-schema-defs';

export function eqBuyFields() {
  return {
    fundId: yup.number().nullable().required(requiredMsg('Fund')),
    transactionDate: transactionDateSchema(),

    noOfShares: yup.number().nullable().label('Shares Purchased').required().min(0),
    currencyid: requiredCurrencyIdField(),
    fullyDilutedShares: yup.number().nullable().optional(),
    valuationImpact: yup
      .boolean()
      .required(requiredMsg('Valuation Impact'))
      .transform((_, val) => (val ? val : false)),
    postMoneyValuation: yup
      .number()
      .min(0, greaterThenMsg('Post Money Valuation', 0))
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    security: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    investmentAmount: usdField()
      .required(requiredMsg('Investment Amount'))
      .min(0, minZeroMsg('Investment Amount'))
      .nullable()
      .typeError(typeMsg('Investment Amount', 'number')),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    raiseAmount: yup
      .number()
      .label('Raise Amount')
      .min(10, greaterThenMsg('Raise Amount', 0))
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null))
      .meta({
        key: 'raiseAmount',
        renderer: RendererType.currency,
      }),
    leadInvestor: yup.string().optional().nullable(),
    coInvestors: yup.array().optional().nullable(),
    conversionRatio: conversionRatioField(),
  };
}

export function eqBuyFormSchema() {
  return yup.object().shape(eqBuyFields());
}

export function eqBuyGridSchema() {
  const base = eqBuyFields();
  return yup
    .object()
    .shape({
      ...base,
      currencyid: base.currencyid.gridMeta({
        renderer: RendererType.currencyId,
      }),
      fundId: base.fundId.gridMeta({
        renderer: RendererType.id,
        formatter: 'fund',
      }),

      noOfShares: base.noOfShares.gridMeta({
        label: 'Shares Purchased',
        renderer: RendererType.number,
      }),
      valuationImpact: base.valuationImpact.gridMeta({
        renderer: RendererType.boolean,
        formatter: BooleanFormatterId.yesNo,
      }),
      transactionDate: base.transactionDate.gridMeta({
        renderer: RendererType.date,
      }),
      investmentRoundId: investmentRoundId().label('Round'),
      investmentType: yup.string(),
      pricePerShare: pricePerShareField(),
      status: yup.string(),
      investmentAmount: base.investmentAmount.gridMeta({
        renderer: RendererType.currency,
      }),
    })
    .omit(['coInvestors', 'investmentType', 'leadInvestor', 'postMoneyValuation', 'raiseAmount']);
}
