import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { DealComment } from '../data-models/comment.data-model';
import { allDealsState } from '../state/DealboardDataState';
import { selectedDealState } from '../state/DealboardUIState';
import { getErrorMessage } from '../../../services/queryHelpers';

export interface ICommentCreationPayload {
  dealId: number;
  comment: string;
}

const createComment = (commentPayload: ICommentCreationPayload) => {
  return MaggieHttp.maggie.post<ICommentCreationPayload, DealComment>('/dealComment', commentPayload);
};

interface ICreateCommentParams extends ICommentCreationPayload {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreateComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [isLoading, setIsLoading] = useState(false);

  const createDealComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ comment, dealId, onSuccess, onError }: ICreateCommentParams) => {
        setIsLoading(true);
        const selectedDeal = snapshot.getLoadable(selectedDealState).valueMaybe();
        try {
          const newComment = await createComment({ comment, dealId });

          if (!newComment?.data) return;

          const updatedSnapshot = snapshot.map((mutable) => {
            mutable.set(allDealsState, (current) => {
              if (!current) return current;
              return current.map((d) => {
                if (d.id === dealId) {
                  return { ...d, comments: [...d.comments, newComment.data] };
                } else {
                  return d;
                }
              });
            });

            if (selectedDeal?.id === dealId) {
              mutable.set(selectedDealState, (current) => {
                if (!current) return current;
                return { ...current, comments: [...current.comments, newComment.data] };
              });
            }
          });

          gotoSnapshot(updatedSnapshot);

          pushSuccessToast({ message: 'Comment created' });
          onSuccess?.();
        } catch (err) {
          console.error(err);
          gotoSnapshot(snapshot);
          const message = getErrorMessage(err, 'Error creating comment');
          pushErrorToast({ message });
          onError?.();
        } finally {
          setIsLoading(false);
        }
      },
    []
  );

  return { createDealComment, isLoading };
};
