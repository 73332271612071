import { useTheme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

interface IProps {
  breadcrumbs: { url?: string; label: string | JSX.Element }[];
}

export function Breadcrumb({ breadcrumbs }: IProps) {
  const { secondary } = useTheme().colors;
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      sx={{
        fontSize: '0.875rem',
        maxWidth: 'fit-content',
      }}
    >
      {breadcrumbs.map(({ url, label }, i) => (
        <Link
          color={secondary['60']}
          underline={'none'}
          href={url}
          key={i}
          onClick={(e) => {
            e.preventDefault();
            if (url) navigate(url);
          }}
        >
          {label}
        </Link>
      ))}
    </Breadcrumbs>
  );
}
