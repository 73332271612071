import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import {
  Autocomplete,
  FilterOptionsState,
  InputAdornment,
  TextField,
  createFilterOptions,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import omit from 'lodash-es/omit';
import { colors } from '../../../theme/colors';
import {
  portCosByIdMapState,
  portCosState,
  selectedCompanyIdStateFinance,
} from '../../../services/state/PortCosState';
import { companyToSearchResult } from '../../../util/companyUtils';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { NoBorderAutocompleteSx } from '../../../constants/styles';
import { CompanyOption } from '../../../components/Form/Display/FieldCompanySearch';
import { useHandleFilterChange } from './useHandleFilterChange';

const StyledSearchBox = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.25rem;
  align-items: center;
  border-radius: 4px;
  background: ${colors.primary[0]};
  box-shadow: 0px 4px 6px 0px rgba(16, 37, 62, 0.06);
  padding: 0.5rem 1.5rem;
  margin: 1rem 0;
`;

const OptionsLimit = 30;

export const PortCoFilter: FC = () => {
  const portCos = useRecoilValue(portCosState);
  const portCoMap = useRecoilValue(portCosByIdMapState);
  const { handleFilterChange } = useHandleFilterChange();
  const defaultFilterOptions = createFilterOptions<number>({
    stringify: (option) => `${portCoMap.get(option)?.name} ${portCoMap.get(option)?.website}`,
  });

  const filterOptions = (options: number[], state: FilterOptionsState<number>) => {
    return defaultFilterOptions(options as number[], state).slice(0, OptionsLimit);
  };

  const selectedTransactionCompanyId = useRecoilValue(selectedCompanyIdStateFinance);

  const options = portCos?.map((c) => c.id) ?? [];

  if (!portCos) return null;

  return (
    <StyledSearchBox>
      <SearchIcon htmlColor={colors.neutral[30]} />
      <Autocomplete
        sx={NoBorderAutocompleteSx}
        autoHighlight
        isOptionEqualToValue={(option, newValue) => {
          return option === newValue;
        }}
        filterOptions={filterOptions}
        onChange={handleFilterChange}
        options={options}
        popupIcon={null}
        renderInput={(params) => {
          const { inputProps, InputProps, ...rest } = params;
          return (
            <TextField
              inputProps={inputProps}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <LogoAdornment company={portCoMap.get(selectedTransactionCompanyId ?? -1)} />
                  </InputAdornment>
                ),
              }}
              {...rest}
              autoFocus
              placeholder={'Search for a specific company finance information'}
            />
          );
        }}
        renderOption={(props, option: number) => {
          const otherProps = omit(props, 'key');

          return (
            <CompanyOption
              company={companyToSearchResult(portCoMap.get(option) ?? null)}
              key={option}
              otherProps={otherProps}
            />
          );
        }}
        getOptionLabel={(option) => {
          return portCoMap.get(option)?.name ?? '';
        }}
        value={selectedTransactionCompanyId}
      />
    </StyledSearchBox>
  );
};

function LogoAdornment({ company }: { company?: ICompanyDataModel }) {
  if (!company) return null;
  const { name, logoUrl } = company;
  return (
    <MUIAvatar
      size='small'
      src={logoUrl ?? ''}
      nameParts={name?.split(' ') ?? []}
      style={{ marginRight: '-0.5rem' }}
    />
  );
}
