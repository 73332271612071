import { useEffect, useMemo, useState } from 'react';

export function useIsInViewport(
  observeOptions: IntersectionObserverInit,
  mutableRef: React.MutableRefObject<Element | null>,
  watchOnce = true
) {
  const [isVisible, setIsVisible] = useState(false);
  const options: IntersectionObserverInit = useMemo(() => {
    return {
      threshold: 0.75,
      ...observeOptions,
    };
  }, [observeOptions]);

  useEffect(() => {
    const target = mutableRef.current;
    if (target === null) {
      return;
    }

    const intersectionCallback: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);

      if (
        typeof options.threshold === 'number' &&
        watchOnce &&
        entry.intersectionRatio >= options.threshold!
      ) {
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(intersectionCallback, options);
    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [observeOptions, mutableRef, options, watchOnce]);

  return isVisible;
}
