import { ICompanySearchResponseDataModel } from '../data-models/company-search-result.data-model';
import { ICompanyDataModel } from '../data-models/company.data-model';
import { CompanySearchResponseWithRealId } from '../schemas/CompanySearchResponse.schema';

export function companyDataModelToSearchResultDataModel(
  company: ICompanyDataModel | undefined
): ICompanySearchResponseDataModel {
  if (company) {
    return {
      id: company.id.toString(),
      fields: {
        website: company.website ?? '',
        logo: company.logoUrl ?? '',
        name: company.name,
        description: '',
        type: '',
      },
    };
  }
  return {
    id: '-1',
    fields: {
      website: '',
      description: '',
      name: '',
      logo: '',
      type: '',
    },
  };
}

export function companyToSearchResultWithRealId(
  company: ICompanyDataModel | null
): CompanySearchResponseWithRealId {
  if (company) {
    return {
      id: company.id,
      fields: {
        website: company.website ?? '',
        logo: company.logoUrl ?? '',
        name: company.name,
        description: '',
        type: '',
      },
    };
  }
  return {
    id: -1,
    fields: {
      website: '',
      description: '',
      name: '',
      logo: '',
      type: '',
    },
  };
}

export function companyToSearchResult(company: ICompanyDataModel | null): ICompanySearchResponseDataModel {
  if (company) {
    return {
      id: company.id.toString(),
      fields: {
        website: company.website ?? '',
        logo: company.logoUrl ?? '',
        name: company.name,
        description: '',
        type: '',
      },
    };
  }
  return {
    id: '-1',
    fields: {
      website: '',
      description: '',
      name: '',
      logo: '',
      type: '',
    },
  };
}

export function getCompanyDescription(company: ICompanyDataModel | undefined | null) {
  if (!company) {
    return '';
  }

  return company.description || company.shortDescription || '';
}
