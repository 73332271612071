import { styled } from '@mui/material/styles';

export const TableContainer = styled('div')`
  overflow: auto;
  margin: 1rem 0 2rem;
  width: fit-content;
  max-width: 100%;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  border-radius: 4px;
`;

export const StyledTable = styled('table')`
  border: 1px solid transparent;
  border-collapse: collapse;
`;

type StyledThProps = {
  highlighted?: boolean;
};
export const StyledTh = styled('th')<StyledThProps>`
  border-bottom: 3px solid ${({ theme }) => theme.colors.neutral[20]};
  &:not(:first-of-type) {
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  }
  text-align: left;
  padding: 0 1.2rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: normal;
  background: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.primary[10] : theme.colors.neutral[5]};
  ${({ theme, highlighted }) => highlighted && `color: ${theme.colors.primary[60]};`}
  height: 3.3rem;
`;

type StyledTdProps = {
  diff?: 'previous' | 'current' | 'noDiff'; // mark previous / current value in red/green, similar to git
  strikethrough?: boolean;
};
export const StyledTd = styled('td')<StyledTdProps>`
  padding: 0 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
  ${({ strikethrough }) => strikethrough && `text-decoration: line-through; text-decoration-color: inherit;`}
  font-size: .9rem;

  background: ${({ theme, diff }) =>
    !diff || diff === 'noDiff'
      ? theme.colors.primary[0]
      : diff === 'previous'
      ? theme.colors.critical[10]
      : theme.colors.success[10]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
  min-height: 2.8rem;
  color: ${({ diff, theme }) =>
    diff === undefined
      ? theme.colors.primary[100]
      : diff === 'noDiff'
      ? theme.colors.neutral[50]
      : diff === 'previous'
      ? theme.colors.critical[50]
      : theme.colors.success[60]};
`;
