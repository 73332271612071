import { CustomHeaderProps } from 'ag-grid-react';
import { useCallback, useMemo } from 'react';
import { ButtonProps, IconButton, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { PopupMenu } from '../../../../../components/PopupMenu/PopupMenu';
import { SelectItem } from '../../../../../types';
import { getPinnedYearColumnDates } from './usePinnedYearColDefs';
import { useVisibleColumns } from './useVisibleColumns';

export interface IAddPinnedYearsMenuProps extends CustomHeaderProps {
  fye: number;
}

export function AddPinnedYearsMenu(params: IAddPinnedYearsMenuProps) {
  const years = useMemo(() => {
    return getPinnedYearColumnDates(params.fye - 1).map((year, i) => ({
      id: i,
      value: year.formattedDate,
    }));
  }, [params.fye]);

  const { selectedYears, onChangeSelectedYears } = useVisibleColumns();
  const selected = useMemo(() => {
    return years.filter((yr) => selectedYears.has(yr.id));
  }, [selectedYears, years]);

  const onChange = useCallback(
    (newValue: SelectItem[]) => {
      onChangeSelectedYears(newValue.map((item) => item.id as number));
    },
    [onChangeSelectedYears]
  );

  return (
    <Stack width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <PopupMenu
        ActionComponent={ActionComponent}
        items={years}
        selectedItems={selected}
        onChange={onChange}
      />
    </Stack>
  );
}

function ActionComponent({ onClick }: Pick<ButtonProps, 'onClick' | 'children'>) {
  return (
    <IconButton onClick={onClick} title={'set pinned columns'}>
      <AddIcon color='secondary' />
    </IconButton>
  );
}
