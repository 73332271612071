import { Autocomplete, CircularProgress, TextField, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash-es';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import omit from 'lodash-es/omit';
import { getRealCompanyId, searchCompanies } from '../../services/queries/MaggieCompanyQueries';
import { ICompanySearchResponseDataModel } from '../../data-models/company-search-result.data-model';
import { CompanyOption } from '../Form/Display/FieldCompanySearch';
import { CompanySearchResponseWithRealId } from '../../schemas/CompanySearchResponse.schema';

export const SearchThrottleDelayMs = 150;

export interface ICompanySearchProps {
  autoFocus?: boolean;
  initialValues?: ICompanySearchResponseDataModel[] | undefined;
  limitTags?: number;
  maxSelectable?: number;
  onChange: (company: CompanySearchResponseWithRealId[]) => void;
  hideLoaderOnCompanyCreation?: boolean;
  placeholder?: string;
  loading?: boolean;
}

export function CompanySearchMultiSelect(props: ICompanySearchProps) {
  const { colors } = useTheme();
  const [companies, setCompanies] = useState<readonly ICompanySearchResponseDataModel[]>(
    props.initialValues ?? []
  );
  const [value, setValue] = React.useState<ICompanySearchResponseDataModel[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isGettingCompanyId, setIsGettingCompanyIds] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(props.initialValues ?? []);
    setCompanies(props.initialValues ? props.initialValues : []);
  }, [props.initialValues]);

  const doSearch = useMemo(
    () =>
      throttle(
        async (searchText: string) => {
          setIsSearching(true);
          const results = await searchCompanies(searchText);
          setCompanies(results);
          setIsSearching(false);
        },
        SearchThrottleDelayMs,
        {
          leading: false,
          trailing: true,
        }
      ),
    []
  );

  const placeholder = useMemo(() => {
    const placeholder = props.placeholder || 'Search for a company';
    return value.length === 0 ? placeholder : '';
  }, [value.length, props.placeholder]);

  const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input') {
      setOpen(value.length > 0);

      if (value === '') {
        setCompanies([]);
      } else {
        doSearch(value);
      }
    }
  };

  const onChange = async (event: React.SyntheticEvent, newValue: ICompanySearchResponseDataModel[]) => {
    setValue(newValue);

    setIsGettingCompanyIds(true);
    const companies: CompanySearchResponseWithRealId[] = await Promise.all(
      newValue.map(async (company) => {
        const { id } = await getRealCompanyId(company.id);
        return {
          ...company,
          id,
        };
      })
    );
    setValue(newValue);
    setIsGettingCompanyIds(false);
    props.onChange?.(companies);
  };

  const checkLimitReached = React.useCallback(() => {
    const reachedMaxSelected = props.maxSelectable && props.maxSelectable === value.length;
    if (!props.maxSelectable || !reachedMaxSelected) return false;

    return true;
  }, [props.maxSelectable, value.length]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      disablePortal
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.fields.name}
      id='companies-multi-select'
      limitTags={props.limitTags}
      multiple
      noOptionsText={null}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      open={open}
      options={companies}
      popupIcon={null}
      getOptionDisabled={checkLimitReached}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            autoFocus={props.autoFocus ?? false}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchOutlinedIcon htmlColor={colors.neutral[60]} fontSize='small' />
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <React.Fragment>
                  {isSearching ||
                  props.loading ||
                  (isGettingCompanyId && !props.hideLoaderOnCompanyCreation) ? (
                    <CircularProgress color='secondary' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
      renderOption={(props, option: ICompanySearchResponseDataModel) => {
        const otherProps = omit(props, 'key');

        return <CompanyOption company={option} key={option.id} otherProps={otherProps} />;
      }}
      value={value}
      ListboxProps={{
        // FIXME: Need to figure out zIndex issues resulting in list being
        // cropped in the "Create Deal" modal then this can be removed.
        style: { maxHeight: 190 },
      }}
    />
  );
}
