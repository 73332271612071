import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { Icon, IconTypes } from '../../../../components/Icon';
import { CollapsedMenu } from './CollapsedMenu';
import { ExpandedMenu } from './ExpandedMenu';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary[5]};
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  position: fixed;
  bottom: 40px;
  right: 100px;
  z-index: 10;
  display: flex;
`;

const IconWrapper = styled('div')`
  position: absolute;
  top: 9px;
  right: 9px;
`;

export const FloatingMenu = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { colors } = useTheme();

  return (
    <Wrapper>
      {isCollapsed ? <CollapsedMenu /> : <ExpandedMenu />}
      <IconWrapper onClick={() => setIsCollapsed((prev) => !prev)}>
        <Icon type={isCollapsed ? IconTypes.EXPAND : IconTypes.COLLAPSE} color={colors.neutral[60]} />
      </IconWrapper>
    </Wrapper>
  );
};

export const FloatingMenuSkeleton = () => {
  return <></>;
};
