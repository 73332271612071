import { Divider, Drawer, DrawerProps } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { ICompanyPersonDataModel, ICompanyDataModel } from '../../../../data-models/company.data-model';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { PersonDrawerHeader } from './PersonDrawerHeader';
import { PersonMainContent } from './PersonMainContent';

const DrawerContent = styled('section')`
  width: 56rem;
`;

interface IPersonDrawerProps extends DrawerProps {
  personData: ICompanyPersonDataModel | null;
  company: ICompanyDataModel;
  onClose: () => void;
}

export const PersonDrawer: FC<IPersonDrawerProps> = ({ personData, company, onClose, open, ...rest }) => {
  return (
    <GenericFallbacksWrapper>
      <Drawer onClose={onClose} {...rest} open={open}>
        <DrawerContent>
          {open && personData && (
            <>
              <PersonDrawerHeader personData={personData} company={company} onClose={onClose} />
              <Divider />
              <PersonMainContent personId={personData.id} />
            </>
          )}
        </DrawerContent>
      </Drawer>
    </GenericFallbacksWrapper>
  );
};
