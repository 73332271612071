import { styled } from '@mui/material/styles';

export interface FormikOption {
  id: number | string;
  value: string;
  label: string;
}

export const SubFormContainer = styled('div')`
  background: ${({ theme }) => theme.colors.primary[5]};
  padding: 1.5rem;
  border-radius: 4px;
`;

export const FormGrid = styled('div')`
  display: grid;
  grid-template-columns: 20% 80%;
  grid-row-gap: 1rem;
  justify-content: space-between;
  align-content: center;
  & > label {
    font-size: 0.8rem;
  }
`;

export const FieldContainer = styled('div')`
  margin-top: 1rem;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary[0]};
  border: thin solid ${({ theme }) => theme.colors.primary[20]};
  padding: 1.5rem;
`;

export const ClosedSubFormContainer = styled(SubFormContainer)`
  background: ${({ theme }) => theme.colors.neutral[2]};
`;

export const ClosedFieldContainer = styled(FieldContainer)`
  border: thin solid ${({ theme }) => theme.colors.neutral[20]};
`;

export const formSubtitleSx = {
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
};
