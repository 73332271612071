import { atom } from 'recoil';
import {
  IAirTableFieldDataModel,
  IAirTableTableDataModel,
} from '../../../data-models/airtable-base.data-model';
import { IAirTableEntityDataModel } from '../../../data-models/airtable-entity.data-model';

export const configurationStepsState = atom<number>({
  key: 'configurationStepsState',
  default: 0,
});

export const selectedTableState = atom<IAirTableTableDataModel | null>({
  key: 'selectedTableState',
  default: null,
});

export const selectedEntityState = atom<IAirTableEntityDataModel | null>({
  key: 'selectedEntityState',
  default: null,
});

// map foresight index column(s) to airtable index col(s)
export const indexColumnMatchesState = atom<Map<string, IAirTableFieldDataModel>>({
  key: 'airtableMatchesState',
  default: new Map(),
});
