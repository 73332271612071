import { Box, Button, Typography, useTheme } from '@mui/material';
import { CSSProperties, useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ObjectSchema } from 'yup';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { createForm } from '../../../view-models/form.view-model';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { ConfigItemRow, IConfigItemRowProps } from '../../Config/Common/ConfigItemRow';
import { FDMap } from '../../../util/data-structure/FDMap';
import { DefaultShareClassSchema, ViewModelInvestmentType } from '../../../schemas/common-schema-defs';
import { TypeToSchemaMap, ShareClass } from '../../../view-models/captable.view-model';
import { FormCard } from './commonStyledComponents';
import { currentStepState, shareClassesState, sortedShareClassesState } from './CapTableFormState';
import { AddShareClassForm } from './AddShareClassForm';
import { useCancelButtonProps } from './CapTableForm';
import { useCaptableInvestmentCustomFields } from './useShareClassFields';

export function AddShareClass() {
  const [openModal, setOpenModal] = useState(false);
  const [shareClasses, setShareClasses] = useRecoilState(shareClassesState);
  const sortedShareClasses = useRecoilValue(sortedShareClassesState);
  const [formMode, setFormMode] = useState<'create' | 'edit'>('create');
  const { colors } = useTheme();
  const cancelButtonProps = useCancelButtonProps();

  const [selectedShareClass, setSelectedShareClass] = useState<ShareClass>(() =>
    DefaultShareClassSchema.create().cast({})
  );

  const onCreate = useCallback(() => {
    setFormMode('create');
    setSelectedShareClass(DefaultShareClassSchema.create().cast({}));
    setOpenModal(true);
  }, []);

  const onDelete = useCallback(
    (item: ShareClass) => {
      setShareClasses((currVal) => {
        return currVal.filter((currItem) => currItem.name !== item.name);
      });
    },
    [setShareClasses]
  );

  const onEdit = useCallback((item: ShareClass) => {
    setFormMode('edit');
    setSelectedShareClass({ ...item });
    setOpenModal(true);
  }, []);

  return (
    <>
      <FormCard>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant='subtitle1'>Please add investment information</Typography>
          <Button variant='contained' color='secondary' onClick={onCreate}>
            Add Investment
          </Button>
        </Box>
        {sortedShareClasses.length > 0 && (
          <Box style={{ border: `thin solid ${colors.neutral[20]}`, borderRadius: '4px', padding: '1rem' }}>
            <ShareClassList onEdit={onEdit} onDelete={onDelete} shareClasses={sortedShareClasses} />
          </Box>
        )}
        <BasicDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          title='Add Investment'
          disableRestoreFocus
        >
          {openModal ? (
            <AddShareClassForm
              onClose={() => setOpenModal(false)}
              shareClass={selectedShareClass as ShareClass}
              mode={formMode}
            />
          ) : (
            <></>
          )}
        </BasicDialog>
        <StepperFormButtons
          stepIsValid={() => Promise.resolve(shareClasses.length > 0)}
          stepState={currentStepState}
          secondaryActionProps={cancelButtonProps}
        />
      </FormCard>
    </>
  );
}

type IShareClassListProps = {
  shareClasses: ShareClass[];
  style?: CSSProperties;
} & Pick<IConfigItemRowProps<ShareClass>, 'onEdit' | 'onDelete'>;

export function ShareClassList(props: IShareClassListProps) {
  const { onDelete, onEdit, shareClasses } = props;
  const schemaToFormField = useSchemaToFormFields();
  const customFields = useCaptableInvestmentCustomFields();

  const items = shareClasses.map((item) => {
    const schema = TypeToSchemaMap[item.type as ViewModelInvestmentType];
    const formFields = schemaToFormField(
      (schema.create() as ObjectSchema<ShareClass>).omit(['customData']) as ObjectSchema<ShareClass>
    );
    const form = createForm({
      data: item,
      fields: FDMap.fromArray(formFields.concat(customFields), 'key'),
    });

    return (
      <ConfigItemRow form={form} key={`${item.name}-${item.type}`} onDelete={onDelete} onEdit={onEdit} />
    );
  });

  return (
    <Box display={'grid'} gap={'1rem'} style={props.style}>
      {items}
    </Box>
  );
}
