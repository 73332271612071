import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { Icon, IconTypes } from '../../../../components/Icon';
import { useModalState } from '../../../../components/Modal/ModalContext';

interface Props {
  title: string;
  children: ReactNode;
  width?: string;
  contentPadding?: string;
}

const Wrapper = styled('div')<{ width?: string }>`
  width: ${({ width }) => (width ? width : '430px')};
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
`;

const Header = styled('div')`
  background-color: #2a309b;
  padding: 12px 15px 12px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled('div')<{ padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : '40px 40px 20px 40px')};
  max-height: 85vh;
  overflow-y: auto;
`;

const IconWrapper = styled('div')`
  margin-bottom: -3px;
`;

export const DealModal: FC<Props> = ({ children, title, width, contentPadding }) => {
  const { colors } = useTheme();
  const { onCloseModal } = useModalState();

  return (
    <Wrapper width={width}>
      <Header>
        <Typography color={colors.primary[0]} variant='subtitle1'>
          {title}
        </Typography>
        <IconWrapper onClick={onCloseModal}>
          <Icon type={IconTypes.CLOSE_MENU_2} color={colors.primary[0]} />
        </IconWrapper>
      </Header>
      <Content padding={contentPadding}>{children}</Content>
    </Wrapper>
  );
};
