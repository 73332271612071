import { IProviderFieldsDataModel } from '../../../data-models/provider-fields.data-model';

export enum MappingsEventType {
  editPrimary = 'editPrimary',
  editSecondary = 'editSecondary',
  editVendorIds = 'editVendorIds',
}

export interface IMappingsEvent {
  type: MappingsEventType;
  data?: IProviderFieldsDataModel<unknown>;
}
