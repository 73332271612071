import { Stack } from '@mui/material';
import { GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../components/grid-renderers/FadeInGridWrapper';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../data-models/kpi-requests.data-model';
import { filterKPIRequestsByStatuses } from '../../../../services/state/KPI/KPIRequestsState';
import { useExtendedExportSettings } from '../../../../components/AgTable/exportToExcelDefs';
import { ExportGridButton } from '../../../../components/AgTable/ExportGridButton';
import { BulkActionButtons } from './BulkAcceptResponse';
import { FilterSection } from './components/Filters/FilterSection';
import { KPIRequestsFrequenciesFilter } from './components/Filters/KPIRequestsFrequenciesFilter';
import { KPIRequestsTemplatesFilter } from './components/Filters/KPIRequestsTemplatesFilter';
import { useKPIRequestsFilters } from './hooks/useKPIRequestsFilters';
import useKPIResponsesColDefs from './hooks/useKPIRequestsResponsesColDefs';
import { useKPIRequestResponseActions } from './KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { RequestConfirmDialog } from './KPIRequestResponse/RequestConfirmDialog';
import { ActionContainer } from './components/KpiStyleDefs';

export function KPIResponsesGrid({ status }: { status: KPIRequestStatus }) {
  const responses: IKPIRequestDataModel[] | null =
    useRecoilValue(filterKPIRequestsByStatuses([status])) ?? null;
  const { columnDefs, defaultColDef } = useKPIResponsesColDefs(status);
  const {
    filteredRequests,
    selectedTemplates,
    selectedFrequencies,
    onSelectTemplateChange,
    onSelectFrequenciesChange,
  } = useKPIRequestsFilters(responses);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [selectedItems, setSelectedItems] = useState<IKPIRequestDataModel[]>([]);
  const { exportParams } = useExtendedExportSettings(new Set(['company']), `${status} kpi responses`);

  const onSelectionChanged = useCallback((e: SelectionChangedEvent) => {
    setSelectedItems(e.api.getSelectedRows());
  }, []);

  const { handleBulkAccept } = useKPIRequestResponseActions();
  const [loading, setLoading] = useState(false);

  const onBulkAccept = useCallback(async () => {
    setLoading(true);
    await handleBulkAccept(selectedItems.map((r) => r.id));
    setLoading(false);
    gridApi?.deselectAll();
    setShowConfirmDialog(false);
  }, [gridApi, handleBulkAccept, selectedItems]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const onSearchCompanyChange = (value: string) => {
    gridApi?.setGridOption('quickFilterText', value);
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);

  return (
    <Stack height={'100%'}>
      <div css={ActionContainer}>
        <FilterSection>
          <SearchInput onChange={onSearchCompanyChange} placeholder='Search for a company' />
          <KPIRequestsTemplatesFilter value={selectedTemplates} onChange={onSelectTemplateChange} />
          <KPIRequestsFrequenciesFilter value={selectedFrequencies} onChange={onSelectFrequenciesChange} />
        </FilterSection>
        <ExportGridButton onClick={handleExport} />
      </div>
      {selectedItems.length > 0 && (
        <BulkActionButtons
          onAction={() => setShowConfirmDialog(true)}
          onCancel={() => gridApi?.deselectAll()}
          actionLabel={`Accept Selected (${selectedItems.length})`}
        />
      )}
      {showConfirmDialog && (
        <RequestConfirmDialog
          open={showConfirmDialog}
          onClose={() => {
            setShowConfirmDialog(false);
            gridApi?.deselectAll();
          }}
          requests={selectedItems}
          onConfirm={onBulkAccept}
          loading={loading}
          title='Confirm Bulk Accept'
          message="Please confirm you want to accept all selected responses. Accepted data will reflect in the company's financials."
        />
      )}

      <FadeInGridWrapper key={status}>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          groupDefaultExpanded={-1}
          groupIncludeTotalFooter={false}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          rowData={filteredRequests}
          rowSelection={status === KPIRequestStatus.Submitted ? 'multiple' : undefined}
          suppressMovableColumns={true}
          defaultCsvExportParams={exportParams}
          defaultExcelExportParams={exportParams}
        />
      </FadeInGridWrapper>
    </Stack>
  );
}
