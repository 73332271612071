import { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { nameSearchQueryState } from '../state/PeopleByCompanyUIState';
import { SearchInput } from '../../../CoreComponents/base/SearchInput';

export const SearchByName: FC = () => {
  const setNameQuery = useSetRecoilState(nameSearchQueryState);

  useEffect(() => {
    return () => {
      setNameQuery('');
    };
  }, [setNameQuery]);

  return <SearchInput style={{ width: '14rem' }} onChange={setNameQuery} placeholder='Search by name' />;
};
