import * as yup from 'yup';
import { RendererType } from '../data-models/field.data-model';
import { eqBuyFields } from '../pages/Finance/components/TransactionModal/Forms/schemas/Equity/EquityBuySchema';

export function commonTransactionFields() {
  return {
    companyId: yup.number().label('Company').required(),
    fundId: eqBuyFields().fundId,
    id: yup.number(),
    investmentAmount: eqBuyFields().investmentAmount,
    roundId: yup.number().label('Stage'),
    investmentName: yup.string().label('Round'),
    transactionTypeId: yup.string().label('Transaction Type').required(),
  };
}

export function commonTransactionFormSchema() {
  return yup.object(commonTransactionFields());
}

export function commonTransactionGridSchema() {
  const fields = commonTransactionFields();

  return yup.object({
    ...fields,
    companyId: fields.companyId.gridMeta({
      renderer: RendererType.companyId,
      enableRowGroup: true,
    }),
    fundId: eqBuyFields().investmentAmount.label('Fund').gridMeta({
      renderer: RendererType.id,
      formatter: 'fund',
      enableRowGroup: true,
    }),
    investmentAmount: eqBuyFields().investmentAmount.label('Amount').gridMeta({
      renderer: RendererType.currency,
    }),
    investmentName: fields.investmentName.gridMeta({ enableRowGroup: true }),

    transactionTypeId: fields.transactionTypeId.gridMeta({
      renderer: RendererType.id,
      formatter: 'transactionType',
      enableRowGroup: true,
    }),
  });
}

export type AbstractTransaction = yup.InferType<ReturnType<typeof commonTransactionFormSchema>>;
