import { styled } from '@mui/material/styles';
import { Icon, useTheme, Button, ButtonGroup, Typography } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { LoadingButton } from '@mui/lab';
import { useDeal } from '../../../providers/DealProvider';
import { MenuItem } from '../../DealDrawer/SplitButton';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import { DealModal } from '../DealModal';
import { DealPassModalContent } from '../DealPassModalContent';
import { DealTrackModalContent } from '../DealTrackModalContent';
import { DealMissedModalContent } from '../DealMissedModalContent';
import { ICompanySearchResultDataModel } from '../../../../../data-models/company-search-result.data-model';
import { useGetCompanyData } from '../../../../CompanyProfiles/hooks/useGetCompanyData';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';
import { useModalState } from '../../../../../components/Modal/ModalContext';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { CompanySection } from './CompanySection';
import { DealSection } from './DealSection';

const Wrapper = styled('div')``;

const MenuCard = styled('ul')<{ width: number | undefined }>`
  all: unset;
  list-style: none;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06),
    0px 24px 48px rgba(75, 82, 93, 0.04);
  padding: 8px 12px;
  border-radius: 4px;
  display: block;
  position: absolute;
  background: #fff;
  z-index: 100;
  width: 227px;
  transform: translate(0, -12.2rem);
`;

const FormsWrapper = styled('div')<{ disableScroll: boolean }>`
  max-height: 65vh;
  min-height: 40vh;
  overflow-y: ${({ disableScroll }) => (disableScroll ? 'hidden' : 'auto')};
`;

const ActionButtonsWrapperWrapper = styled('div')`
  margin-top: 28px;
  display: flex;
  justify-content: start;
  gap: 12px;
`;

export interface IDealAddModalContentProps {
  companyData?: ICompanySearchResultDataModel;
}

export function DealAddModalContent(props: IDealAddModalContentProps) {
  const { companyData } = props;
  const { onCloseModal, onOpenModal } = useModalState();
  const containerRef = useRef<HTMLDivElement>(null);
  const { companyInfo, isDealFormValid, handleCreateDeal, isItemDropdownOpen, duplicateDeal } = useDeal();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { colors } = useTheme();
  useOutsideClick(containerRef, () => setOpen(false));
  const { pushErrorToast } = useToastMessageState();

  const getCompanyData = useGetCompanyData();

  const { state: loadingState } = useLoadingBarState();
  const creatingDeal = useMemo(() => loadingState.ids.includes(LoadingId.createDeal), [loadingState.ids]);

  const handleCreate = useCallback(async () => {
    const response = await handleCreateDeal(Boolean(duplicateDeal));
    onCloseModal();
    return response;
  }, [duplicateDeal, handleCreateDeal, onCloseModal]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectOption = useCallback(
    async (optionId: string) => {
      const deal = await handleCreate();
      if (!deal) return;

      const [company] = await getCompanyData([deal.companyId]);
      if (!company) {
        pushErrorToast({ message: 'Failed to load company data' });
        return;
      }
      const companyName = `“${company.name}”`;

      switch (optionId) {
        case 'PASS':
          onOpenModal(
            <DealModal title={`Move the Deal ${companyName} to Pass?`}>
              <DealPassModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'MISSED':
          onOpenModal(
            <DealModal title={`Mark the Deal ${companyName} as Missed?`}>
              <DealMissedModalContent deal={deal} />
            </DealModal>
          );
          return;
        case 'TRACK':
          onOpenModal(
            <DealModal title={`Move the Deal ${companyName} to Track Mode?`}>
              <DealTrackModalContent deal={deal} />
            </DealModal>
          );
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [getCompanyData, handleCreate, onOpenModal, pushErrorToast]
  );

  const scrollUp = () => {
    ref.current && ref.current.scrollTo(0, 0);
  };

  return (
    <Wrapper>
      <FormsWrapper disableScroll={isItemDropdownOpen} ref={ref}>
        <CompanySection scrollUp={scrollUp} companyData={companyData} />
        <DealSection key={duplicateDeal?.id ?? ''} />
      </FormsWrapper>
      <ActionButtonsWrapperWrapper>
        <div ref={containerRef}>
          <ButtonGroup
            variant='outlined'
            color={'secondary'}
            size={'large'}
            aria-label='split button'
            style={{ position: 'relative' }}
          >
            <LoadingButton
              onClick={handleCreate}
              color={'secondary'}
              disabled={!isDealFormValid || !companyInfo || creatingDeal}
              variant='outlined'
              size='large'
              loading={creatingDeal}
              endIcon={<Icon />}
              loadingPosition='end'
            >
              Add Deal
            </LoadingButton>

            <Button
              size='small'
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label='select deal action'
              aria-haspopup='menu'
              onClick={handleToggle}
              disabled={!isDealFormValid || !companyInfo || creatingDeal}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
          </ButtonGroup>
          {open && (
            <MenuCard width={300} role='listbox'>
              <MenuItem onClick={() => handleSelectOption('TRACK')} role='option'>
                <HourglassEmptyIcon />
                <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
                  Track
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption('PASS')} role='option'>
                <DangerousOutlinedIcon />
                <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
                  Pass
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption('MISSED')} role='option'>
                <CallMissedOutgoingIcon />
                <Typography component='span' variant={'body2'} color={colors.neutral[60]}>
                  Missed
                </Typography>
              </MenuItem>
            </MenuCard>
          )}
        </div>
        <Button variant='outlined' color={'secondary'} size={'large'} onClick={onCloseModal}>
          Cancel
        </Button>
      </ActionButtonsWrapperWrapper>
    </Wrapper>
  );
}
