import { useMemo } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ScenarioTransactionType } from '../../../../../data-models/scenario.data-model';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { shouldDisableExit, shouldDisablePartialExit } from './scenarioFormUtils';

export function TransactionTypeToggle({ index }: { index: number }) {
  const { control } = useFormContext();
  const transactionValues = useWatch({ name: 'scenarioTransactions', control });
  const disableExit = useMemo(() => shouldDisableExit(transactionValues, index), [index, transactionValues]);
  const disablePartialExit = useMemo(
    () => shouldDisablePartialExit(transactionValues, index),
    [index, transactionValues]
  );

  const { showPartialExit } = useFlags<MaggieFeatureFlags>();

  return (
    <Stack direction={'row'} gap='0.25rem'>
      <Controller
        control={control}
        name={`scenarioTransactions.${index}.type` as const}
        render={({ field: { onChange, value } }) => (
          <ToggleButtonGroup
            color='secondary'
            exclusive
            aria-label='transaction type'
            value={value}
            onChange={(event, value) => {
              if (value) onChange(value);
            }}
          >
            <ToggleButton value={ScenarioTransactionType.round}>Round</ToggleButton>
            {showPartialExit && (
              <ToggleButton value={ScenarioTransactionType.partialExit} disabled={disablePartialExit}>
                Partial Exit
              </ToggleButton>
            )}
            <ToggleButton value={ScenarioTransactionType.exit} disabled={disableExit}>
              Exit
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      />
      <Tooltip
        title={
          <Typography variant='caption' color='text.secondary'>
            Scenario can include only one exit transaction
          </Typography>
        }
        placement='bottom-start'
        aria-label={'Scenario can include only one exit transaction'}
        enterDelay={500}
      >
        <InfoOutlinedIcon fontSize='small' color='disabled' />
      </Tooltip>
    </Stack>
  );
}
