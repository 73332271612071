import { sub } from 'date-fns';
import { IDealDataModel } from '../../../data-models/deal.data-model';
import { fetchDealsByCompanyIds } from '../../../services/queries/MaggieDealQueries';

export function getLatestDuplicateDeal(deals: IDealDataModel[]) {
  const duplicates = deals.filter((d) => isDuplicateDeal(d));
  if (duplicates.length === 0) return null;
  duplicates.sort((a, b) => {
    const aDate = a.createdAt ? new Date(a.createdAt).getTime() : -1;
    const bDate = b.createdAt ? new Date(b.createdAt).getTime() : -1;
    return bDate - aDate;
  });
  return duplicates[0];
}

export function isDuplicateDeal(deal: IDealDataModel) {
  return deal.createdAt && sub(new Date(), { days: 30 }) < new Date(deal.createdAt);
}

export async function getDuplicateDeal(companyId: number) {
  try {
    const deals = await fetchDealsByCompanyIds([companyId]);
    if (deals?.length > 0) {
      return getLatestDuplicateDeal(deals);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
