import OneSchemaImporter from '@oneschema/react';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { styled } from '@mui/material/styles';
import { listsState } from '../../../../../../pages/CompanyProfiles/state/ListsState';
import { DealModal } from '../../../../../../pages/DealFlow2/components/DealModal/DealModal';
import { uploadList } from '../../../../../../services/queries/MaggieListQueries';
import { getEnvVariable, isDevelopment } from '../../../../../../util/environment';
import { useModalState } from '../../../../../Modal/ModalContext';
import { useToastMessageState } from '../../../../../ToastMessage/ToastMessageProvider';
import { IListDataModel } from '../../../../../../data-models/list.data-model';
import { CompanyListForm } from './CompanyListForm';
import { FormStep } from './FormStep';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const UploadCompanyListForm = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [jwt, setJwt] = useState('');
  const setCompanyLists = useSetRecoilState(listsState);
  const { onCloseModal } = useModalState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const oneSchemaClientId = getEnvVariable('VITE_ONESCHEMA_CLIENT_ID');
  const webhookKey = getEnvVariable('VITE_ONESCHEMA_WEBHOOK_KEY');

  const handleSubmit = async (values: Partial<IListDataModel>) => {
    const { token, list } = await uploadList(values as unknown as IListDataModel);
    setCompanyLists((prev) => [...(prev ?? []), list]);
    setJwt(token);
    setActiveStep(2);
    setIsOpen(true);
  };

  const handleSuccessUpload = () => {
    pushSuccessToast({ message: `Company list uploaded successfully` });
    onCloseModal();
  };

  const handleErrorUpload = () => {
    pushErrorToast({ message: 'An error occurred while uploading the file' });
  };

  return (
    <DealModal title={'Upload New Companies List'} width={'814px'}>
      <Wrapper>
        <FormStep
          activeStep={activeStep}
          stepNumber={1}
          heading={'Settings'}
          description={'Set list name and source. Also, share the list with colleagues'}
        >
          {activeStep === 1 && <CompanyListForm isEdit={false} onSubmit={handleSubmit} />}
        </FormStep>
        <FormStep
          activeStep={activeStep}
          stepNumber={2}
          heading={'Upload'}
          description={'Select XLSX or CSV file'}
        >
          <OneSchemaImporter
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            clientId={oneSchemaClientId}
            userJwt={jwt}
            templateKey='company_lists_upload'
            importConfig={{ type: 'webhook', key: webhookKey }}
            devMode={isDevelopment()}
            className='oneschema-importer'
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 2147483647,
            }}
            onSuccess={handleSuccessUpload}
            onError={handleErrorUpload}
          />
        </FormStep>
      </Wrapper>
    </DealModal>
  );
};
