import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class MoicKpiConfig implements KpiConfig {
  private id = KPI_COMPONENTS.MOIC as const;
  private title = 'MOIC';
  private type = 'multiple';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ kpis }: KpiCalculationParams): Kpi {
    const value =
      (kpis?.[KPI_COMPONENTS.TOTAL_VALUE]?.value || 0) /
      (kpis?.[KPI_COMPONENTS.TOTAL_INVESTMENT_AMOUNT]?.value || 1);

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
