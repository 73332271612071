import { Stack, StackProps } from '@mui/material';

interface IFilterSectionProps extends StackProps {
  nFilters?: number;
}
export function FilterSection({ children, nFilters = 3, ...rest }: IFilterSectionProps) {
  return (
    <Stack
      display='grid'
      gridTemplateColumns={`repeat(${nFilters}, minmax(10rem, 20rem))`}
      justifyContent={'start'}
      alignItems={'center'}
      gap='1rem'
      marginTop='1rem'
      {...rest}
    >
      {children}
    </Stack>
  );
}
