import React from 'react';
import { useInvestmentAmount } from '../../hooks/useInvestmentAmountByState';
import { CHART_COMPONENTS } from '../../../../types';
import { PieChart } from '../../../../components/Charts/Piechart/PieChart';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IPortfolioChartProps } from './ChartComponent';

export const InvestmentAmountByStateChart: React.FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const { data, total } = useInvestmentAmount(props.filteredMetrics);

  return (
    <PieChart
      data={data}
      title={'Investment By State'}
      id={CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_STATE}
      total={total}
    />
  );
};
