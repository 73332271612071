import { Column } from 'react-table';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { TransactionHistoryTableData } from '../../../types';
import { CurrencyRenderer } from '../components/CurrencyRenderer';
import { DateFormatter } from '../components/DateFormatter';
import { PercentageFormatter } from '../components/PercentageFormatter';
import { NumberRenderer } from '../components/NumberRenderer';
import { SymbolRenderer } from '../../../components/AgTable/cell-renderers/SymbolRenderer';
import { LongTextCellRenderer } from '../../../components/AgTable/cell-renderers/LongTextCellRenderer';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { transactionTypesMapState, usersByEmailMapState } from '../../../services/state/AppConfigState';
import { TransactionType } from '../../../data-models/transaction-type.data-model';
import { RoundRenderer } from '../../../components/AgTable/cell-renderers/RoundRenderer';
import { BooleanRenderer } from '../../../components/AgTable/cell-renderers/BooleanRenderer';

function isITransactionDataModel(
  transaction: TransactionHistoryTableData
): transaction is ITransactionDataModel {
  return (
    (transaction as ITransactionDataModel).amountNormalized !== undefined &&
    (transaction as ITransactionDataModel).noOfSharesNormalized !== undefined
  );
}

function dateAccessor(dateString: string | null | undefined) {
  return dateString ? new Date(dateString) : null;
}

const fundCol: Column<TransactionHistoryTableData> = {
  Header: 'Fund',
  accessor: (row) => row.fund?.name,
  id: 'fundName', // use id prop to access cell data when the column accessor is a fn instead of a string
};
const transactionDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Transaction Date',
  accessor: (row) => dateAccessor(row.transactionDate),
  Cell: DateFormatter,
  id: 'transactionDate',
};
const paymentDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Payment Date',
  accessor: (row) => dateAccessor(row.transactionDate),
  Cell: DateFormatter,
  id: 'transactionDate',
};
const roundCol: Column<TransactionHistoryTableData> = {
  Header: 'Stage',
  accessor: 'round',
  Cell: RoundRenderer,
  id: 'round',
};
const convertedRoundCol: Column<TransactionHistoryTableData> = {
  Header: 'Converted Stage',
  accessor: 'round',
  Cell: RoundRenderer,
  id: 'convertedRound',
};
const PPSCol: Column<TransactionHistoryTableData> = {
  Header: 'PPS',
  accessor: 'pricePerShare',
  Cell: CurrencyRenderer,
};

const newSharesPurchasedCol: Column<TransactionHistoryTableData> = {
  Header: 'New Shares Purchased',
  accessor: (row) => {
    if (isITransactionDataModel(row)) {
      return row.noOfSharesNormalized;
    } else {
      return row.noOfShares;
    }
  },
  Cell: NumberRenderer,
};
const newNoOfSharesCol: Column<TransactionHistoryTableData> = {
  Header: 'New Shares Issued',
  accessor: 'noOfShares',
  Cell: NumberRenderer,
};
const currencyCol: Column<TransactionHistoryTableData> = {
  Header: 'Currency',
  accessor: (row) => row.currency?.symbol,
  Cell: SymbolRenderer,
  id: 'currency',
};
const newlyDilutedSharesCol: Column<TransactionHistoryTableData> = {
  Header: 'New Fully Diluted Shares',
  accessor: 'fullyDilutedShares',
  Cell: NumberRenderer,
};
const postMoneyValuationCol: Column<TransactionHistoryTableData> = {
  Header: 'Post Money Valuation',
  accessor: 'postMoneyValuation',
  Cell: CurrencyRenderer,
};
const valuationDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Valuation Date',
  accessor: (row) => dateAccessor(row.valuationDate),
  Cell: DateFormatter,
  id: 'valuationDate',
};
const principalAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Principal',
  accessor: 'principalAmount',
  Cell: CurrencyRenderer,
};
const couponCol: Column<TransactionHistoryTableData> = {
  Header: 'Coupon',
  accessor: 'coupon',
  Cell: PercentageFormatter,
};

const maturityDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Maturity Date',
  accessor: (row) => dateAccessor(row.maturityDate),
  Cell: DateFormatter,
  id: 'maturityDate',
};

const interestTypeCol: Column<TransactionHistoryTableData> = {
  Header: 'Interest Type',
  accessor: 'couponType',
};
const compoundingFrequencyCol: Column<TransactionHistoryTableData> = {
  Header: 'Compounding period',
  accessor: 'compoundingFrequency',
};
const valuationCapCol: Column<TransactionHistoryTableData> = {
  Header: 'Valuation Cap',
  accessor: 'valuationCap',
  Cell: CurrencyRenderer,
};
const discountRateCol: Column<TransactionHistoryTableData> = {
  Header: 'Discount Rate',
  accessor: 'discountRate',
  Cell: PercentageFormatter,
};
const triggerPriceCol: Column<TransactionHistoryTableData> = {
  Header: 'Trigger Price',
  accessor: 'triggerPrice',
  Cell: CurrencyRenderer,
};
const pikStartDateCol: Column<TransactionHistoryTableData> = {
  Header: 'PIK Start Date',
  accessor: (row) => dateAccessor(row.pikStartDate),
  Cell: DateFormatter,
  id: 'pikStartDate',
};
const pikEndDateCol: Column<TransactionHistoryTableData> = {
  Header: 'PIK End Date',
  accessor: (row) => dateAccessor(row.pikEndDate),
  Cell: DateFormatter,
  id: 'pickEndDate',
};
const pikCadenceCol: Column<TransactionHistoryTableData> = {
  Header: 'PIK Cadence Date',
  accessor: (row) => dateAccessor(row.pikCadence),
  Cell: DateFormatter,
  id: 'pikCadenceDate',
};
const transactionStatusCol: Column<TransactionHistoryTableData> = {
  Header: 'Transaction Status',
  accessor: 'status',
};
const escrowAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Escrow Amount',
  accessor: 'escrowAmount',
  Cell: CurrencyRenderer,
};
const convertedSharesNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Number of Converted Shares',
  accessor: 'convertedSharesNo',
  Cell: NumberRenderer,
};
const convertedPrincipalAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Converted Principal Amount',
  accessor: 'convertedPrincipalAmount',
  Cell: CurrencyRenderer,
};
const convertedInterestAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Converted Interes',
  accessor: 'convertedInterestAmount',
  Cell: CurrencyRenderer,
};
const fullyDilutedSharesCol: Column<TransactionHistoryTableData> = {
  Header: 'New Fully Diluted Shares',
  accessor: 'fullyDilutedShares',
  Cell: NumberRenderer,
};
const miscPaymentAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Amount',
  accessor: 'miscPaymentAmount',
  Cell: CurrencyRenderer,
};
const miscPaymentTypeCol: Column<TransactionHistoryTableData> = {
  Header: 'Payment Type',
  accessor: 'miscPaymentType',
};
const exchangedRoundCol: Column<TransactionHistoryTableData> = {
  Header: 'Exchanged Stage',
  accessor: 'exchangedRound',
  Cell: RoundRenderer,
  id: 'exchangedRound',
};
const receivedRoundCol: Column<TransactionHistoryTableData> = {
  Header: 'Received Stage',
  accessor: 'receivedRound',
  Cell: RoundRenderer,
  id: 'receivedRound',
};
const exchangedSharesNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Exchanged Shares',
  accessor: 'exchangedSharesNo',
  Cell: NumberRenderer,
};
const receivedSharesNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Received Shares',
  accessor: 'receivedSharesNo',
  Cell: NumberRenderer,
};
const warrantsIssuedCol: Column<TransactionHistoryTableData> = {
  Header: 'Warrants Issued',
  accessor: 'warrantsIssued',
  Cell: NumberRenderer,
};
const warrantExpiryDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Expiration Date',
  accessor: (row) => dateAccessor(row.warrantExpiryDate),
  Cell: DateFormatter,
  id: 'warrantExpiryDate',
};
const exercisedWarrantsNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Warrants Exercised',
  accessor: 'exercisedWarrantsNo',
  Cell: NumberRenderer,
};
const warrantVestedCol: Column<TransactionHistoryTableData> = {
  Header: 'Vested',
  accessor: 'warrantVested',
  Cell: BooleanRenderer,
};
const linkedNoteIdCol: Column<TransactionHistoryTableData> = {
  Header: 'Note Name',
  accessor: 'linkedNoteId',
};
const interestPaymentAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Interest Payment Amount',
  accessor: 'interestPaymentAmount',
  Cell: CurrencyRenderer,
};
const optionsIssuedNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Options Issued',
  accessor: 'optionsIssuedNo',
  Cell: NumberRenderer,
};
const optionsStrikePriceCol: Column<TransactionHistoryTableData> = {
  Header: 'Strike Price',
  accessor: 'optionsStrikePrice',
  Cell: CurrencyRenderer,
};
const optionsExpiryDateCol: Column<TransactionHistoryTableData> = {
  Header: 'Expiraton Date',
  accessor: (row) => dateAccessor(row.optionsExpiryDate),
  Cell: DateFormatter,
  id: 'optionsExpiryDateCol',
};
const optionsExercisedNoCol: Column<TransactionHistoryTableData> = {
  Header: 'Options Exercised',
  accessor: 'optionsExercisedNo',
  Cell: NumberRenderer,
};
const valuationAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Valuation Amount',
  accessor: 'valuationAmount',
  Cell: CurrencyRenderer,
};
const investmentAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Amount',
  // accessor: 'investmentAmount',
  accessor: (row) => {
    if (isITransactionDataModel(row)) {
      return row.amountNormalized;
    } else {
      return row.investmentAmount;
    }
  },
  Cell: CurrencyRenderer,
};
const exchangedAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Exchanged Amount',
  accessor: 'exchangedAmount',
  Cell: CurrencyRenderer,
};
const receivedAmountCol: Column<TransactionHistoryTableData> = {
  Header: 'Received Amount',
  accessor: 'receivedAmount',
  Cell: CurrencyRenderer,
};

export function useTransactionHistoryColumns() {
  const usersByEmailMap = useRecoilValue(usersByEmailMapState);
  const transactionTypesMap = useRecoilValue(transactionTypesMapState);

  const editorCol: Column<TransactionHistoryTableData> = useMemo(
    () =>
      ({
        Header: 'Editor',
        accessor: (row) => {
          if (!row.createdBy) return '';
          return usersByEmailMap.get(row.createdBy)?.name ?? '';
        },
        Cell: LongTextCellRenderer,
        width: 300,
      } as Column<TransactionHistoryTableData>),
    [usersByEmailMap]
  );

  const equityBuyCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      roundCol,
      PPSCol,
      investmentAmountCol,
      newSharesPurchasedCol,
      currencyCol,
      newlyDilutedSharesCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const equitySellCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      escrowAmountCol,
      // soldAmount, // missing
      PPSCol,
      // sharesSold, // missing
      currencyCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const internalValuationCols = useMemo(
    () => [fundCol, valuationDateCol, PPSCol, valuationAmountCol, transactionStatusCol, editorCol],
    [editorCol]
  );

  const noteIssuanceCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      principalAmountCol,
      couponCol,
      maturityDateCol,
      currencyCol,
      interestTypeCol,
      compoundingFrequencyCol,
      valuationCapCol,
      discountRateCol,
      triggerPriceCol,
      pikStartDateCol,
      pikEndDateCol,
      pikCadenceCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const noteConversionCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      convertedRoundCol,
      convertedSharesNoCol,
      convertedPrincipalAmountCol,
      convertedInterestAmountCol,
      currencyCol,
      fullyDilutedSharesCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );
  // Named "SAFE" in the doc
  const safeIssuanceCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      principalAmountCol,
      maturityDateCol,
      currencyCol,
      triggerPriceCol,
      valuationCapCol,
      discountRateCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const safeConversionCols = useMemo(
    () => [
      transactionDateCol,
      fundCol,
      convertedRoundCol,
      convertedPrincipalAmountCol,
      convertedSharesNoCol,
      currencyCol,
      fullyDilutedSharesCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const miscPaymentCols = useMemo(
    () => [
      fundCol,
      paymentDateCol,
      miscPaymentAmountCol,
      currencyCol,
      miscPaymentTypeCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const shareExchangeCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      exchangedRoundCol,
      receivedRoundCol,
      exchangedSharesNoCol,
      receivedSharesNoCol,
      exchangedAmountCol,
      receivedAmountCol,
      currencyCol,
      fullyDilutedSharesCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const stockIssuanceCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      roundCol,
      currencyCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const warrantIssuanceCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      currencyCol,
      warrantsIssuedCol,
      warrantExpiryDateCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const warrantExerciseCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      currencyCol,
      exercisedWarrantsNoCol,
      warrantVestedCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const interestPaymentCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      currencyCol,
      linkedNoteIdCol,
      interestPaymentAmountCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );
  const secondaryPurchaseCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      roundCol,
      PPSCol,
      newNoOfSharesCol,
      currencyCol,
      fullyDilutedSharesCol,
      postMoneyValuationCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const noteExpirationCols = useMemo(
    () => [fundCol, transactionDateCol, transactionStatusCol, editorCol],
    [editorCol]
  );
  const safeExpirationCols = noteExpirationCols;
  const warrantExpirationCols = noteExpirationCols;
  const optionExpirationCols = noteExpirationCols;

  const optionIssuanceCols = useMemo(
    () => [
      fundCol,
      transactionDateCol,
      currencyCol,
      optionsIssuedNoCol,
      optionsStrikePriceCol,
      optionsExpiryDateCol,
      transactionStatusCol,
      editorCol,
    ],
    [editorCol]
  );

  const optionExerciseCols = useMemo(
    () => [fundCol, transactionDateCol, currencyCol, optionsExercisedNoCol, transactionStatusCol, editorCol],
    [editorCol]
  );

  const defaultCols: Column<TransactionHistoryTableData>[] = useMemo(
    () => [fundCol, transactionDateCol, transactionStatusCol, editorCol],
    [editorCol]
  );

  const columnsByTransactionType: Record<string, Column<TransactionHistoryTableData>[]> = useMemo(
    () => ({
      Buy: equityBuyCols,
      Sell: equitySellCols,
      'Internal Valuation': internalValuationCols,
      'Note Issuance': noteIssuanceCols,
      'Note Conversion': noteConversionCols,
      SAFE: safeIssuanceCols,
      'SAFE Conversion': safeConversionCols,
      'Misc Payment': miscPaymentCols,
      'Share Exchange': shareExchangeCols,
      'Stock Issuance': stockIssuanceCols,
      'Warrant Issuance': warrantIssuanceCols,
      'Warrant Exercise': warrantExerciseCols,
      'Interest Payment': interestPaymentCols,
      'Secondary Purchase': secondaryPurchaseCols,
      'Note Expiration': noteExpirationCols,
      'SAFE Expiration': safeExpirationCols,
      'Warrant Expiration': warrantExpirationCols,
      'Option Issuance': optionIssuanceCols,
      'Option Exercise': optionExerciseCols,
      'Option Expiration': optionExpirationCols,
      default: defaultCols,
    }),
    [
      defaultCols,
      equityBuyCols,
      equitySellCols,
      interestPaymentCols,
      internalValuationCols,
      miscPaymentCols,
      noteConversionCols,
      noteExpirationCols,
      noteIssuanceCols,
      optionExerciseCols,
      optionExpirationCols,
      optionIssuanceCols,
      safeConversionCols,
      safeExpirationCols,
      safeIssuanceCols,
      secondaryPurchaseCols,
      shareExchangeCols,
      stockIssuanceCols,
      warrantExerciseCols,
      warrantExpirationCols,
      warrantIssuanceCols,
    ]
  );

  const getTransactionHistoryColumns = useCallback(
    ({ transactionTypeId }: ITransactionDataModel) => {
      const transactionType = transactionTypesMap.get(transactionTypeId) as TransactionType;
      return columnsByTransactionType[transactionType.name] || columnsByTransactionType.default;
    },
    [columnsByTransactionType, transactionTypesMap]
  );

  return getTransactionHistoryColumns;
}
