import { Chip, Tooltip, useTheme, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Divider } from '../../../../components/Divider/Divider';
import { Icon, IconTypes } from '../../../../components/Icon';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { collapsedColWidth, expandedColWidth } from './columnSizes';

interface Props {
  title: string;
  deals: IDealDataModel[];
  isCollapsed: boolean;
  toggleCollapsed: () => void;
}

const Container = styled('div')<{ isCollapsed: boolean }>`
  padding-bottom: 16px;

  padding: 8px;
  padding-bottom: 0;
  border-radius: 4px;
  width: ${({ isCollapsed }) => (isCollapsed ? collapsedColWidth : expandedColWidth)};
  ${({ isCollapsed }) => isCollapsed && `display: flex; justify-content: center;`};
`;

const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 11px;
`;

const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TypographyWrapper = styled('div')`
  max-width: 165px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled('div')<{ isCollapsed: boolean }>`
  margin-bottom: -5px;
  ${({ isCollapsed }) => (isCollapsed ? 'margin-left: 2px;' : '')};
`;

export const ColumnHeader: FC<Props> = ({ title, deals, isCollapsed, toggleCollapsed }) => {
  const { colors } = useTheme();
  const valuationMaxSum = useMemo(() => {
    if (!deals.length) return '';
    const sum = deals.reduce((acc, current) => (current.gcAmountMax ? acc + current.gcAmountMax : acc), 0);
    return `(${formatUSDShort(sum)})`;
  }, [deals]);

  return (
    <Container isCollapsed={isCollapsed}>
      <HeaderWrapper>
        {!isCollapsed && (
          <InfoWrapper>
            <Tooltip
              title={
                <Typography variant='caption' color={colors.primary[100]}>
                  {title}
                </Typography>
              }
              placement='top'
              enterDelay={1000}
            >
              <TypographyWrapper>
                <Typography variant='subtitle1' color={colors.primary[100]}>
                  {title}
                </Typography>
              </TypographyWrapper>
            </Tooltip>
            <Chip label={`${deals.length} ${valuationMaxSum}`} />
          </InfoWrapper>
        )}
        <IconWrapper onClick={toggleCollapsed} isCollapsed={isCollapsed}>
          <Icon type={isCollapsed ? IconTypes.EXPAND : IconTypes.COLLAPSE} color={colors.neutral[60]} />
        </IconWrapper>
      </HeaderWrapper>
      {!isCollapsed && <Divider />}
    </Container>
  );
};
