import { styled } from '@mui/material/styles';
import { Typography, useTheme, Switch } from '@mui/material';
import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDashboardConfigCI } from '../../../../../pages/CompetitiveIntelligence/state/DashboardsState';
import { useUpdateDashboardConfig } from '../../../../../pages/CompetitiveIntelligence/hooks/useDashboardActions';

const NAVIGATION_SWITCH_STYLING = {
  '.MuiSwitch-switchBase.Mui-checked': {
    color: '#ECE5FF',
  },
  '.MuiSwitch-switchBase': {
    color: '#BDC0E0',
  },
};

interface IProps {
  id: string;
  name: string;
}

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 8px 0 8px 48px;
  align-items: center;
  & > * {
    &:last-child {
      margin-left: auto;
    }
  }
`;

export const Category: FC<IProps> = ({ id, name }) => {
  const { secondary } = useTheme().colors;
  const dashboardConfig = useRecoilValue(selectedDashboardConfigCI);
  const updateDashboardConfig = useUpdateDashboardConfig();
  const isChecked = useMemo(() => {
    return !!dashboardConfig?.selectedCategories.some((cId) => cId === id);
  }, [dashboardConfig?.selectedCategories, id]);

  const toggleCategory = () => {
    if (!dashboardConfig) return;
    const { selectedCategories } = dashboardConfig;
    const isSelected = selectedCategories.some((cId) => id === cId);
    const updatedSelectedCategories: string[] = isSelected
      ? selectedCategories.filter((cId) => id !== cId)
      : [...selectedCategories, id];

    updateDashboardConfig({ ...dashboardConfig, selectedCategories: updatedSelectedCategories });
  };

  return (
    <Wrapper>
      <Typography variant={'caption'} color={secondary[10]}>
        {name}
      </Typography>
      <Switch
        size='small'
        sx={NAVIGATION_SWITCH_STYLING}
        checked={isChecked}
        color={'default'}
        onChange={toggleCategory}
      />
    </Wrapper>
  );
};
