import { useRecoilCallback } from 'recoil';
import { useCallback } from 'react';
import {
  IAirtableSyncPayloadDataModel,
  IEstablishedSyncDataModel,
  ISyncedColumnDataModel,
} from '../../../../data-models/airtable-sync.data-model';
import {
  createAirtableSync,
  deleteEstablishedColumnSync,
  deleteEstablishedSync,
  fetchAirtableEntities,
  fetchConnectedBases,
  fetchEstablishedSyncs,
  fetchTablesForBase,
} from '../../../../services/queries/MaggieIntegrationsQueries';
import { getErrorMessage } from '../../../../services/queryHelpers';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { selectedTableState } from '../../state/ConfigurationState';
import {
  airTableBasesState,
  airTableTablesState,
  airtableEntitiesState,
  establishedSyncsState,
} from '../../state/IntegrationsState';

export function useAirtableActions() {
  const { pushErrorToast, pushInfoToast } = useToastMessageState();

  const refetchSyncData = useRecoilCallback(
    ({ set }) =>
      async () => {
        const syncs = await fetchEstablishedSyncs();
        set(establishedSyncsState, syncs);
      },
    []
  );

  const refetchConfigData = useRecoilCallback(
    ({ set, snapshot }) =>
      async (baseId?: string) => {
        const selectedTable = snapshot.getLoadable(selectedTableState).valueMaybe();
        if (!selectedTable && !baseId) return;
        const selectedBaseId = baseId ?? selectedTable!.baseId;

        Promise.all([
          fetchAirtableEntities(),
          fetchConnectedBases(),
          fetchTablesForBase(selectedBaseId),
        ]).then(([entities, bases, tables]) => {
          set(airtableEntitiesState, entities);
          set(airTableBasesState, bases);
          set(airTableTablesState(selectedBaseId), tables);
        });
      },
    []
  );

  const disconnectSync = useRecoilCallback(
    ({ snapshot }) =>
      async (sync: IEstablishedSyncDataModel) => {
        try {
          await deleteEstablishedSync(sync.id);

          refetchSyncData();
          if (snapshot.getInfo_UNSTABLE(airTableBasesState).isSet) {
            refetchConfigData(sync.airtableBaseId);
          }

          pushInfoToast({
            message: `Synchronization with Airtable table "${sync.airtableTableName}" has been disconnected.}`,
          });
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Failed to disconnect sync');
          pushErrorToast({ message });
        }
      },
    [refetchConfigData, refetchSyncData]
  );

  const disconnectSyncColumn = useRecoilCallback(
    ({ snapshot }) =>
      async (column: ISyncedColumnDataModel, sync: IEstablishedSyncDataModel) => {
        try {
          const disconnected = await deleteEstablishedColumnSync(column.id);
          refetchSyncData();
          if (snapshot.getInfo_UNSTABLE(airTableBasesState).isSet) {
            refetchConfigData(sync.airtableBaseId);
          }

          pushInfoToast({
            message: `Synchronization with Airtable column "${column.airtableColumnName}" has been disconnected.}`,
          });
          return disconnected;
        } catch (err) {
          console.error(err);
          const message = getErrorMessage(err, 'Failed to disconnect sync column');
          pushErrorToast({ message });
        }
      },
    [refetchSyncData]
  );

  const createSync = useCallback(
    async (payload: IAirtableSyncPayloadDataModel) => {
      try {
        await createAirtableSync(payload);
        refetchConfigData();
        refetchSyncData();
      } catch (e) {
        console.error(e);
        const message = getErrorMessage(e, 'Failed to match columns');
        pushErrorToast({ message });
      }
    },
    [pushErrorToast, refetchConfigData, refetchSyncData]
  );

  return { createSync, disconnectSync, disconnectSyncColumn };
}
