import {
  ColDef,
  IRowNode,
  ITooltipParams,
  KeyCreatorParams,
  SetFilterValuesFuncParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { UNKNOWN_GROUP_NAME } from '../../../../../components/AgTable/tableConstants';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { IUserDataModel } from '../../../../../data-models/user.data-model';
import {
  fundsByIdMapState,
  roundsMapState,
  sectorsMapState,
  usersByEmailMapState,
  usersByIdMapState,
} from '../../../../../services/state/AppConfigState';
import { GRID_ROW_HEIGHT } from '../../../../../theme/gridStyle';
import { DealBoardDealCategory } from '../../../../../types';
import { genericComparator } from '../../../../../util/comparators';
import { TooltipWrapperComponent } from '../../../../../util/TooltipWrapperComponent';
import { CurrencyRenderer } from '../../../../FinanceHistory/components/CurrencyRenderer';
import { DateFormatter } from '../../../../FinanceHistory/components/DateFormatter';
import { GridCompanyLinkRenderer } from '../../../../PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/ GridCompanyLinkRenderer';
import { DealComment } from '../../../data-models/comment.data-model';
import { getLatestCommentString } from '../../../helpers/getLatestCommentString';
import { dealStagesByIdMapState } from '../../../state/DealboardDataState';
import { selectedDealCategoryState, showCommentsState } from '../../../state/DealboardUIState';
import { CommentsRenderer } from '../CellRenderers/CommentsRenderer';
import { CompanyCellRenderer } from '../CellRenderers/CompanyCellRenderer';
import { LabelsRenderer } from '../CellRenderers/LabelsRenderer';
import { PriorityCellRenderer } from '../CellRenderers/PriorityCellRenderer';
import { IDealGridData } from '../DealGrid';
import { RoundRenderer } from '../../../../../components/AgTable/cell-renderers/RoundRenderer';
import { formatRoundName } from './fundingRoundUtils';
import { stageComparator } from './stageComparator';

const currencyTypeColDefs: ColDef<IDealGridData> = {
  cellRenderer: CurrencyRenderer,
  filter: 'agNumberColumnFilter',
  cellClass: ['monospace', 'text-align-right', 'currencyUSD'],
};

const dateTypeColDefs: ColDef<IDealGridData> = {
  cellRenderer: DateFormatter,
  filter: 'agDateColumnFilter',
  cellClass: ['monospace', 'text-align-right', 'dateISO'],
};

const longTextColDefs: ColDef<IDealGridData> = {
  cellStyle: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '0',
    maxWidth: '100%',
    lineHeight: `${GRID_ROW_HEIGHT}px`,
    verticalAlign: 'middle',
  },
};

export function useDealGridColDefs(): ColDef<IDealGridData>[] {
  const roundsMap = useRecoilValue(roundsMapState);
  const fundsMap = useRecoilValue(fundsByIdMapState);
  const usersByIdMap = useRecoilValue(usersByIdMapState);
  const usersByEmailMap = useRecoilValue(usersByEmailMapState);
  const stages = useRecoilValue(dealStagesByIdMapState);
  const selectedDealCategory = useRecoilValue(selectedDealCategoryState);
  const sectorMap = useRecoilValue(sectorsMapState);
  const showComments = useRecoilValue(showCommentsState);

  return useMemo(() => {
    const allColDefs: ColDef<IDealGridData>[] = [
      {
        headerName: 'Company',
        cellRenderer: CompanyCellRenderer,
        cellRendererParams: {
          showLogos: true,
        },
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          return params.data?.company?.name ?? '';
        },

        pinned: 'left',
        keyCreator: (params: KeyCreatorParams<IDealGridData>) => {
          return params.data?.company.name ?? UNKNOWN_GROUP_NAME;
        },
      },
      {
        headerName: 'Sector',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const sectorId = params.data?.company?.sectorId;
          if (typeof sectorId !== 'number') return '';
          return sectorMap.get(sectorId)?.displayName ?? '';
        },
      },
      {
        headerName: 'Fund',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const fundId = params.data?.fundId;
          const fundIds = params.data?.fundIds;
          if (typeof fundId !== 'number' && !fundIds?.length) return '';
          if (fundIds?.length) {
            return fundIds
              .map((fundId) => fundsMap.get(fundId)?.name)
              .filter((fundName) => Boolean(fundName))
              .join(', ');
          } else if (typeof fundId === 'number') {
            return fundsMap.get(fundId)?.name;
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Deal Lead',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const dealLeadId = params.data?.dealLeadId;
          return dealLeadId ? usersByIdMap.get(dealLeadId)?.name : '';
        },
      },
      {
        headerName: 'Deal Team',
        filter: 'agTextColumnFilter',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const dealTeamIds = params.data?.dealTeamIds ?? [];
          return dealTeamIds.map((id) => usersByIdMap.get(id)?.name).join(', ');
        },
        cellRenderer: TooltipWrapperComponent,

        ...longTextColDefs,
      },
      {
        headerName: 'Description',
        field: 'company.shortDescription',
        filter: 'agTextColumnFilter',
        cellRenderer: TooltipWrapperComponent,
        ...longTextColDefs,
      },

      {
        headerName: 'Website',
        field: 'company',
        colId: 'website',
        cellRenderer: GridCompanyLinkRenderer,
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          return params.data?.company;
        },
        keyCreator: (params: KeyCreatorParams<IDealGridData>) => {
          return params.data?.company.website ?? '';
        },
        filterParams: {
          filterValueGetter: (params: ValueGetterParams) => {
            return params.data?.company.website;
          },
        },
      },
      {
        headerName: 'CEO Name',
        field: 'company.ceoName',
      },
      {
        headerName: 'City',
        field: 'company.city',
      },
      {
        headerName: 'State',
        field: 'company.state',
      },
      {
        headerName: 'Country',
        field: 'company.country',
      },
      {
        headerName: 'Source',
        field: 'company.source',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const source = params.data?.company.source;
          const sourceType = params.data?.company.sourceType;
          if (source && sourceType) return `${source} (${sourceType})`;
          if (source) return source;
          return '';
        },
      },
      {
        headerName: 'GC Amount (Min)',
        field: 'gcAmountMin',
        ...currencyTypeColDefs,
      },
      {
        headerName: 'GC Amount (Max)',
        field: 'gcAmountMax',
        ...currencyTypeColDefs,
      },
      {
        headerName: 'Total PIC',
        field: 'totalPic',
        ...currencyTypeColDefs,
      },
      {
        headerName: 'Date Added',
        field: 'createdAt',
        valueGetter: (params: ValueGetterParams<IDealGridData>) =>
          params.data?.createdAt ? new Date(params.data?.createdAt) : '',
        ...dateTypeColDefs,
      },
      {
        headerName: 'Round',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          if (params.node?.group) return undefined;
          const roundId = params.data?.roundId;

          return roundId ? roundsMap.get(roundId) : null;
        },
        cellRenderer: RoundRenderer,
        cellRendererParams: { style: { border: 'thin solid #fff' }, showTBD: true },
        keyCreator: (params: KeyCreatorParams) => params.value?.displayName ?? UNKNOWN_GROUP_NAME,
        valueFormatter: (params: ValueFormatterParams) => params.value?.displayName ?? UNKNOWN_GROUP_NAME,
      },
      {
        headerName: 'Priority',
        field: 'priority',
        cellRenderer: PriorityCellRenderer,
        width: 120,
      },
      {
        headerName: 'Stage Comments',
        field: 'passComments',
        ...longTextColDefs,
      },
      {
        headerName: 'Labels',
        field: 'labels',
        filter: 'agSetColumnFilter',
        cellRenderer: LabelsRenderer,
        ...longTextColDefs,
        filterParams: {
          values: (params: SetFilterValuesFuncParams) => {
            const res = new Set<string>();
            params.api.forEachLeafNode((node: IRowNode<IDealDataModel>) => {
              const labels: string[] = node.data?.labels ?? [];
              labels.forEach((value) => res.add(value));
            });

            params.success(Array.from(res));
          },
        },
      },
      {
        headerName: 'Latest Raise Date',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const announcedOn = params.data?.company.latestFundingDate;
          return announcedOn && new Date(announcedOn);
        },
        ...dateTypeColDefs,
      },
      {
        headerName: 'Latest Funding Round',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const { name = '', latestFundingRound } = params.data?.company ?? {};
          return formatRoundName(name, latestFundingRound);
        },
      },
      {
        headerName: 'Latest Raise Amount',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const { latestFundingAmount } = params.data?.company ?? {};
          return latestFundingAmount;
        },
        ...currencyTypeColDefs,
      },
      {
        headerName: 'Latest Public Post Money Valuation',
        valueGetter: (params: ValueGetterParams<IDealGridData>) => {
          const { lastPostMoney } = params.data?.company ?? {};
          return lastPostMoney;
        },
        ...currencyTypeColDefs,
      },
    ];

    if (selectedDealCategory.id === DealBoardDealCategory.CURRENT) {
      allColDefs.push({
        headerName: 'Stage',
        valueGetter: (params: ValueGetterParams) => {
          const dealStage = stages.get(params.data.stageId);
          return dealStage?.displayName ?? 'Unknown';
        },
        sortable: true,
        rowGroup: true,
        comparator: stageComparator,
        hide: true,
      });
    }

    if (selectedDealCategory.id === DealBoardDealCategory.PASS) {
      allColDefs.push({
        headerName: 'Reason',
        field: 'stageComments',
        ...longTextColDefs,
      });
    }

    if (selectedDealCategory.id === DealBoardDealCategory.TRACK) {
      allColDefs.push({
        headerName: 'Reason',
        field: 'trackComments',
        ...longTextColDefs,
      });
    }

    if (showComments) {
      allColDefs.push({
        headerName: 'Comments',
        cellRenderer: CommentsRenderer,
        valueGetter: (params: ValueGetterParams<IDealGridData>) => params.data?.comments,
        filterValueGetter: (params: ValueGetterParams<IDealGridData>) =>
          getLatestCommentAsString(params, usersByEmailMap),
        valueFormatter: (params: ValueFormatterParams) => getLatestCommentAsString(params, usersByEmailMap),
        filter: 'agTextColumnFilter',
      });
    }

    return allColDefs;
  }, [
    fundsMap,
    roundsMap,
    sectorMap,
    selectedDealCategory.id,
    showComments,
    stages,
    usersByEmailMap,
    usersByIdMap,
  ]);
}

export function getLatestCommentAsString(
  params: ValueFormatterParams | ITooltipParams | ValueGetterParams,
  usersByEmailMap: Map<string, IUserDataModel>
): string {
  const comments: DealComment[] = params.data?.comments;

  return getLatestCommentString(comments, usersByEmailMap);
}

export const defaultColDef: ColDef = {
  comparator: genericComparator,
  suppressMovable: true,
  width: 200,
};
