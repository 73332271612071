import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const SkeletonWrapper = styled('div')`
  display: grid;
  grid-template-rows: 500px 500px;
  row-gap: 2rem;
`;
export function DealAnalyticsChartsSkeletonLoader() {
  return (
    <SkeletonWrapper>
      <Skeleton height={'100%'} variant='rectangular' width={'100%'} />
      <Skeleton height={'100%'} variant='rectangular' width={'100%'} />
    </SkeletonWrapper>
  );
}
