import { ICompanyMilestoneDataMode } from '../../data-models/company-milestone.data-model';
import { MaggieHttp } from '../MaggieHttp';

export async function fetchCompanyMilestones(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<ICompanyMilestoneDataMode[]>(
    `milestone?companyId=${companyId}`
  );

  return data;
}

export async function createCompanyMilestone(
  payload: Partial<ICompanyMilestoneDataMode>
): Promise<ICompanyMilestoneDataMode> {
  const { data } = await MaggieHttp.maggie.post<
    Partial<ICompanyMilestoneDataMode>,
    ICompanyMilestoneDataMode
  >(`milestone`, payload);

  return data;
}

export async function editMilestone(
  id: number,
  payload: Partial<ICompanyMilestoneDataMode>
): Promise<ICompanyMilestoneDataMode> {
  const { data } = await MaggieHttp.maggie.put<Partial<ICompanyMilestoneDataMode>, ICompanyMilestoneDataMode>(
    `milestone/${id}`,
    payload
  );

  return data;
}

export async function deleteMilestone(id: number): Promise<void> {
  await MaggieHttp.maggie.delete(`milestone/${id}`);
}
