import ContentLoader from 'react-content-loader';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  margin-top: -1rem;
  margin-left: 3rem;
`;

export function DealBoardSkeletonLoader(): ReactElement {
  return (
    <SkeletonWrapper>
      <ContentLoader height={2000} width={'100%'}>
        <rect width='245' height='36' y='80' x='0' fill='#EEEFF1' />

        <rect width='80' height='28' y='160' x='0' fill='#EEEFF1' />
        <rect width='80' height='28' y='160' x='95' fill='#EEEFF1' />
        <rect width='80' height='28' y='160' x='190' fill='#EEEFF1' />
        <rect width='80' height='28' y='160' x='285' fill='#EEEFF1' />
        <rect width='80' height='28' y='160' x='380' fill='#EEEFF1' />

        <rect width='160' height='38' y='230' x='0' fill='#EEEFF1' />
        <rect width='100' height='38' y='230' x='190' fill='#EEEFF1' />
        <rect width='80' height='38' y='230' x='305' fill='#EEEFF1' />
        <rect width='80' height='38' y='230' x='400' fill='#EEEFF1' />
        <rect width='80' height='38' y='230' x='495' fill='#EEEFF1' />
        <rect width='100' height='38' y='230' x='590' fill='#EEEFF1' />
        <rect width='160' height='38' y='230' x='720' fill='#EEEFF1' />

        <rect width='80' height='28' y='340' x='0' fill='#EEEFF1' />
        <rect width='80' height='28' y='340' x='95' fill='#EEEFF1' />
        <rect width='30' height='28' y='340' x='250' fill='#EEEFF1' />
        <rect width='280' height='70' y='388' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='464' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='542' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='620' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='696' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='774' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='852' x='0' fill='#EEEFF1' />
        <rect width='280' height='70' y='930' x='0' fill='#EEEFF1' />

        <rect width='80' height='28' y='340' x='312' fill='#EEEFF1' />
        <rect width='80' height='28' y='340' x='407' fill='#EEEFF1' />
        <rect width='30' height='28' y='340' x='562' fill='#EEEFF1' />
        <rect width='280' height='70' y='388' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='464' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='542' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='620' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='696' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='774' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='852' x='312' fill='#EEEFF1' />
        <rect width='280' height='70' y='930' x='312' fill='#EEEFF1' />

        <rect width='80' height='28' y='340' x='624' fill='#EEEFF1' />
        <rect width='80' height='28' y='340' x='719' fill='#EEEFF1' />
        <rect width='30' height='28' y='340' x='874' fill='#EEEFF1' />
        <rect width='280' height='70' y='388' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='464' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='542' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='620' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='696' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='774' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='852' x='624' fill='#EEEFF1' />
        <rect width='280' height='70' y='930' x='624' fill='#EEEFF1' />

        <rect width='80' height='28' y='340' x='936' fill='#EEEFF1' />
        <rect width='80' height='28' y='340' x='1031' fill='#EEEFF1' />
        <rect width='30' height='28' y='340' x='1186' fill='#EEEFF1' />
        <rect width='280' height='70' y='388' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='464' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='542' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='620' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='696' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='774' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='852' x='936' fill='#EEEFF1' />
        <rect width='280' height='70' y='930' x='936' fill='#EEEFF1' />

        <rect width='80' height='28' y='340' x='1248' fill='#EEEFF1' />
        <rect width='80' height='28' y='340' x='1343' fill='#EEEFF1' />
        <rect width='30' height='28' y='340' x='1498' fill='#EEEFF1' />
        <rect width='280' height='70' y='388' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='464' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='542' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='620' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='696' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='774' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='852' x='1248' fill='#EEEFF1' />
        <rect width='280' height='70' y='930' x='1248' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
