import { useEffect, useState } from 'react';
import { useToken } from '../services/hooks/useToken';
import { WebSocketService } from '../services/WebSocketService';
import { LoadingStatus } from '../types';

export function useInitSocket() {
  const getToken = useToken();

  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.loading);

  useEffect(() => {
    WebSocketService.initService(getToken).then(() => {
      setStatus(LoadingStatus.success);
    });
  }, [getToken]);

  return status;
}
