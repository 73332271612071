import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { metricsFundDataByFundIdState } from '../../../../../services/state/CompanyMetricsByIdState';
import { IFundData } from '../../../../../data-models/scenario.data-model';
import { RoundParticipationType } from './RoundParticipationToggle';

export function useRoundParticipation() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const fundDataByFundId = useRecoilValue(metricsFundDataByFundIdState(companyId));
  const allFunds = Array.from(fundDataByFundId.values());

  const getNoneParticipationValues = useCallback(() => {
    return allFunds.map((fund) => {
      return {
        fundId: fund.fundId,
        investedAmount: 0,
      };
    });
  }, [allFunds]);

  const isNoneParticipation = useCallback(
    (fundData: Partial<IFundData>[]) => {
      return (
        fundData.length == fundDataByFundId.size &&
        fundData.every((formDataFund) => {
          const fund = fundDataByFundId.get(formDataFund?.fundId ?? -1);
          if (!fund || typeof formDataFund.investedAmount !== 'number') return false;
          return formDataFund.investedAmount === 0;
        })
      );
    },
    [fundDataByFundId]
  );

  const getProRataValues = useCallback(
    (roundSize: number): IFundData[] => {
      return allFunds.map((fund) => ({
        fundId: fund.fundId,
        investedAmount: (roundSize * (fund.ownerShipPercentage ?? 0)) / 100,
      }));
    },
    [allFunds]
  );

  const isProRata = useCallback(
    (roundSize: number, fundData: Partial<IFundData>[]) => {
      return (
        fundData.length == fundDataByFundId.size &&
        fundData.every((formDataFund) => {
          const fund = fundDataByFundId.get(formDataFund?.fundId ?? -1);
          if (!fund || typeof formDataFund.investedAmount !== 'number') return false;
          return (
            Math.round(formDataFund.investedAmount * 100) ===
            Math.round((fund?.ownerShipPercentage ?? 0) * roundSize)
          );
        })
      );
    },
    [fundDataByFundId]
  );

  const getInitialParticipation = useCallback(
    (roundSize: number, fundData: Partial<IFundData>[]): RoundParticipationType => {
      if (fundData.length === 0) return RoundParticipationType.none;
      if (isProRata(roundSize, fundData)) {
        return RoundParticipationType.proRata;
      } else if (isNoneParticipation(fundData)) {
        return RoundParticipationType.none;
      } else {
        return RoundParticipationType.other;
      }
    },
    [isNoneParticipation, isProRata]
  );

  return {
    getProRataValues,
    getNoneParticipationValues,
    isProRata,
    isNoneParticipation,
    getInitialParticipation,
  };
}
