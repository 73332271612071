import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { fetchCompanyPeople, hidePerson, unHidePerson } from '../../../services/queries/MaggieCompanyQueries';
import { peopleReportingState } from '../state/PeopleReportingState';
import { getErrorMessage } from '../../../services/queryHelpers';
import { useInvalidateData } from '../../../hooks/useInvalidateData';
import { peopleByCompanyState } from '../../CompanyProfiles/state/PeopleByCompanyState';

interface IHideParams {
  companyId: number;
  personId: number;
  action: 'hide' | 'unhide';
}

export function useHidePortfolioPerson() {
  const { pushErrorToast } = useToastMessageState();
  const invalidatePeople = useInvalidateData();

  return useRecoilCallback(
    ({ set }) =>
      async ({ companyId, personId, action }: IHideParams) => {
        try {
          if (action === 'hide') {
            await hidePerson({ companyId, personId });
          } else {
            await unHidePerson({ companyId, personId });
          }

          set(peopleReportingState, (people) =>
            people.map((person) => {
              if (person.id === personId) {
                return {
                  ...person,
                  hidden: action === 'hide',
                };
              }
              return person;
            })
          );
          invalidatePeople({
            state: peopleByCompanyState(companyId),
            query: () => fetchCompanyPeople(companyId),
          });
        } catch (err) {
          const message = getErrorMessage(err, 'Failed to hide person');
          pushErrorToast({ message });
        }
      },
    []
  );
}
