import { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';

const StyledFieldLoadingIndicator = styled('div')<{ style?: CSSProperties }>`
  width: 4rem;
  height: 1.25rem;
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  border-radius: 4px;
  ${({ style }) => style && { ...style }};
`;

interface FieldLoadingIndicatorProps {
  style?: CSSProperties;
}

export const FieldLoadingIndicator: FC<FieldLoadingIndicatorProps> = ({ style }) => {
  return <StyledFieldLoadingIndicator style={style} />;
};
