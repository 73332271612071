import { FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ListWithMoreChip } from '../../../pages/CompanyProfiles/Companies/CompanyCard/ListWithMoreChip';

interface IStringListProps extends ICellRendererParams<unknown, string[]> {
  title: string;
}

export const StringListCellRenderer: FC<IStringListProps> = ({ value: list, title }) => {
  if (!list?.length) return null;
  return (
    <>
      <ListWithMoreChip
        items={list}
        title={title}
        style={{
          height: '100%',
          lineHeight: '1.5rem',
        }}
        firstItemStyle={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      />
    </>
  );
};
