import { EntityType } from '../../data-models/field2.data-model';
import { Commentary } from '../../schemas/Commentary.schema';
import { MaggieHttp } from '../MaggieHttp';
import { BaseModelWithID } from '../../schemas/BaseModel.schema';

interface ICommentaryQueryParams {
  date: string;
  entity: EntityType;
  entityId: number;
}
export async function getCommentaryForEntityAndDate(params: ICommentaryQueryParams) {
  const { data } = await MaggieHttp.maggie.get<Commentary>('/commentary', {
    params,
  });
  return data;
}

export async function getCommentariesForEntity(params: Omit<ICommentaryQueryParams, 'date'>) {
  const { data } = await MaggieHttp.maggie.get<Commentary[]>('/commentary', {
    params,
  });
  return data;
}

export interface ICreateOrUpdateCommentaryParams extends Omit<Commentary, keyof BaseModelWithID> {}
export async function createOrUpdateCommentary(payload: ICreateOrUpdateCommentaryParams) {
  const { data } = await MaggieHttp.maggie.post<ICreateOrUpdateCommentaryParams, Commentary>(
    '/commentary',
    payload
  );
  return data;
}

export async function deleteCommentaryById(id: number) {
  return await MaggieHttp.maggie.delete(`/commentary/${id}`);
}

export async function deleteCommentaryByEntityAndDate({ date, entity, entityId }: ICommentaryQueryParams) {
  return await MaggieHttp.maggie.delete(`/commentary`, {
    params: {
      date,
      entity,
      entityId,
    },
  });
}
