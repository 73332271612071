import * as yup from 'yup';
import { greaterThenMsg, minZeroMsg, requiredMsg } from '../../utils/validationMessages';
import { transactionCurrencyIdField } from '../../../../../../../schemas/transactions/TransactionsCommon.schema';
import { transactionDateSchema } from '../../../../../../Finance2/Forms/transactionFieldSchemas';
import { integerField, percentField, usdField } from '../../../../../../../schemas/common-schema-defs';

export function noteSafeIssuanceFields() {
  return {
    coInvestors: yup.array().of(yup.string()).optional().nullable(),
    currencyid: transactionCurrencyIdField(),
    discountRate: percentField().optional().min(0, minZeroMsg('Discount rate')).nullable(),
    fullyDilutedShares: integerField().nullable().optional(),
    fundId: yup.number().optional().nullable(),
    leadInvestor: yup.string().optional().nullable(),
    principalAmount: usdField().nullable().required(requiredMsg('Principal')).min(0, minZeroMsg('Principal')),
    maturityDate: yup.date().optional().nullable(),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    valuationCap: usdField().optional().min(0, minZeroMsg('Valuation Cap')).nullable(),
    raiseAmount: usdField()
      .min(0, greaterThenMsg('Raise Amount', 0))
      .optional()
      .nullable()
      .transform((_, val) => (val === val ? val : null)),
    transactionDate: transactionDateSchema().required(requiredMsg('Transaction Date')),
    triggerPrice: usdField().min(0, greaterThenMsg('Trigger Price', 0)).nullable(),
  };
}

export function noteSafeIssuanceFormSchema() {
  return yup.object().shape(noteSafeIssuanceFields());
}

export function noteSafeIssuanceGridSchema() {
  return yup
    .object()
    .shape(noteSafeIssuanceFields())
    .omit(['coInvestors', 'leadInvestor', 'raiseAmount', 'fullyDilutedShares']);
}
