import { Alert, AlertProps } from '@mui/material';

interface IHelpMessageProps {
  message: string;
  severity?: AlertProps['severity'];
}

export function HelpMessage({ message, severity }: IHelpMessageProps) {
  return <Alert severity={severity ?? 'error'}>{message}</Alert>;
}

export function ErrorOccurredMessage() {
  return <HelpMessage severity={'error'} message={'An error has occurred'} />;
}

export function NoDataMessage2() {
  return <HelpMessage severity='info' message={'No data available'} />;
}
