import { useState } from 'react';
import * as yup from 'yup';
import { Button, Stack, Typography } from '@mui/material';
import { ControllerRenderProps, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createForm, createFormField } from '../../view-models/form.view-model';
import { FDMap } from '../../util/data-structure/FDMap';
import { FormFactoryWithStandardLayout } from '../../components/Form/FormFactory';
import { colorStatusField } from '../../data-fields/CommonFields';
import { FieldFile } from '../../components/Form/Display/FieldFile';
import { RendererType } from '../../data-models/field.data-model';
import { IFileMeta } from '../../data-models/field3.data-model';

export function UI() {
  const testSchema = yup.object().shape({
    rubric1: yup.string().required().default(''),
  });
  const colorField = {
    ...colorStatusField({
      required: true,
      key: 'rubric1',
      label: 'Rubric 1',
      inlineLabel: true,
    }),
    variant: 'form-inline' as const,
  };
  const form = createForm({ fields: FDMap.fromArray([colorField], 'key') });

  const methods = useForm({
    defaultValues: testSchema.getDefault(),
    resolver: yupResolver(testSchema),
    mode: 'all',
  });

  const [file, setFile] = useState<File | null>(null);
  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  const fileFormField = createFormField<IFileMeta>({
    key: 'file',
    renderer: RendererType.fileUpload,
    disabled: false,
    rendererMeta: {
      acceptTypes: [
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ],
    },
  });

  return (
    <Stack>
      <Stack flexGrow={'1'} p='1rem'>
        <FieldFile
          formProps={{ onChange: handleFileChange, value: file } as ControllerRenderProps}
          formField={fileFormField}
        />
        <form>
          <FormProvider {...methods}>
            <button
              type='button'
              onClick={() =>
                console.log(methods.watch(), {
                  errors: methods.formState.errors,
                  formState: methods.formState,
                })
              }
            >
              Values & Errors & formState
            </button>
            <FormFactoryWithStandardLayout form={form} />
            <Button onClick={() => methods.trigger()}>Submit</Button>
          </FormProvider>
        </form>
      </Stack>
      <Stack
        display='grid'
        padding='1rem 2rem'
        gridTemplateColumns='1fr'
        gridTemplateRows={'auto'}
        alignItems={'baseline'}
        alignContent={'start'}
        gap='2rem'
        sx={{
          '& .MuiTypography-root': {
            justifySelf: 'end',
          },
        }}
      >
        <Typography variant='h1'>Typography h1</Typography>
        <Typography variant='h2'>Typography h2</Typography>
        <Typography variant='h3'>Typography h3</Typography>
        <Typography variant='h4'>Typography h4</Typography>
        <Typography variant='h5'>Typography h5</Typography>
        <Typography variant='h6'>Typography h6</Typography>
        <Typography variant='subtitle1'>Typography subtitle1</Typography>
        <Typography variant='subtitle2'>Typography subtitle2</Typography>
        <Typography variant='body1'>Typography body1</Typography>
        <Typography variant='body2'>Typography body2</Typography>
        <Typography variant='caption'>Typography caption</Typography>
        <Typography variant='caption2'>New Typography caption2</Typography>
        <Typography variant='overline'>Typography overline</Typography>
        <Typography variant='monotype'>Typography monotype 123</Typography>
      </Stack>
    </Stack>
  );
}
