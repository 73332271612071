import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { FieldsPerPage } from '../../CompanyProfiles2/Summary/CompanyDetailsPanel';
import { InvestmentMetricsPanel } from '../../CompanyProfiles2/Summary/InvestmentMetricsPanel';
import { useAllInvestmentMetricsFields } from '../../CompanyProfiles2/Summary/useInvestmentMetricsFields';
import { useLoadSelectedMetricsFields } from './data-display-utils';
import { selectedMetricsFieldsOrgState } from './DataDisplayState';
import { DisplayConfig, IDummySectionProps } from './DisplayConfig';
import { DummyCompanyId } from './DummyData';
import { useMetricsConfigActions } from './useDataDisplayConfigActions';

export function InvestmentMetricsConfig() {
  const { loaded } = useLoadSelectedMetricsFields();
  const allFields = useAllInvestmentMetricsFields();
  const [selectedFields, setSelectedFields] = useAtom(selectedMetricsFieldsOrgState);
  const { handleSave, handleReset } = useMetricsConfigActions();

  const renderDummySection = useCallback(
    ({ defaultPage, key }: IDummySectionProps) => {
      return (
        <InvestmentMetricsPanel
          companyId={DummyCompanyId}
          editable={false}
          fieldsToShow={selectedFields}
          key={key}
          defaultPage={defaultPage}
        />
      );
    },
    [selectedFields]
  );

  if (!loaded) return null;
  return (
    <DisplayConfig
      title='Investment Metrics Section'
      allFields={allFields}
      selectedFields={selectedFields}
      setSelectedFields={setSelectedFields}
      handleResetConfig={handleReset}
      handleSaveChanges={handleSave}
      fieldsPerPage={FieldsPerPage}
      renderDummySection={renderDummySection}
    />
  );
}
