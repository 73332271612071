import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Loader } from '../../../../../components/Loader/Loader';

const LoaderContainer = styled('div')`
  position: absolute;
  padding: 0.2rem;
  top: 2%;
  right: 50%;
  transform: translate(50%, 0%);
`;
export const OverlayComponent: FC = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};
