import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const LabelAndValueContainer = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 9rem 1fr;
  gap: 1rem;
  min-height: 3rem;
`;

interface LabelAndValueProps {
  label: string;
  value: ReactNode;
}

export const LabelAndValue: FC<LabelAndValueProps> = ({ label, value }) => {
  return (
    <LabelAndValueContainer>
      <Typography variant='body2' aria-label='label'>
        {label}
      </Typography>
      {value}
    </LabelAndValueContainer>
  );
};
