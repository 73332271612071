import { LocationSearch } from '../../GooglePlacesAutocomplete/LocationSearch';
import { formSelectInlineClassNames } from '../../../theme/component-styles';
import { ISelectMeta } from '../../../data-models/field3.data-model';
import { IBaseFieldProps } from './types';

export function FieldLocation(props: IBaseFieldProps<ISelectMeta<unknown>>) {
  const { formField, formProps } = props;
  const { ref, onChange, value } = formProps;
  const { multi } = formField.rendererMeta ?? {};
  const { disabled = false, variant } = formField ?? {};
  const className = variant === 'form-inline' ? formSelectInlineClassNames : '';

  return (
    <LocationSearch
      className={className}
      disabled={disabled}
      initialValue={value}
      multiple={multi}
      onChange={onChange}
      ref={ref}
    />
  );
}
