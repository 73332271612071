import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { SubSection } from '../../../Summary/components/SubSection';
import { SelectItem } from '../../../../../types';
import { PopupMenu } from '../../../../../components/PopupMenu/PopupMenu';
import { PerfBarChart2, PerfBarChartTitle } from './PerfBarChart';
import { useKpiChartsMenu } from './useKpiChartsMenu';

const Wrapper = styled('div')`
  display: grid;
  padding-right: 3rem;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(4, 25%);
`;

export const PerformanceCharts = () => {
  const { chartOptions, selectedChartOptions, handleChange, selectedKpis } = useKpiChartsMenu();

  const _onChange = (newValue: SelectItem[]) => {
    handleChange(newValue);
  };

  const charts = useMemo(() => {
    return selectedKpis.map((kpi) => {
      const { displayName, id } = kpi;

      return (
        <PerfBarChart2
          field={kpi!}
          key={id}
          title={displayName}
          titleComponent={<PerfBarChartTitle field={kpi} />}
        />
      );
    });
  }, [selectedKpis]);

  return (
    <>
      <SubSection
        title={'Performance Overview'}
        noCard
        actionComponent={
          <PopupMenu
            items={chartOptions}
            onChange={_onChange}
            selectedItems={selectedChartOptions}
            label={'Add Charts'}
            groupable
            searchable
            searchPlaceholder='Search for KPI name'
            style={{ marginLeft: 'auto' }}
          />
        }
      >
        <Wrapper>{charts}</Wrapper>
      </SubSection>
    </>
  );
};
