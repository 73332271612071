export function convertToCSV(jsonData: Record<string, unknown>[], fileName: string, columns?: string[]) {
  // Create an array of rows
  const rows = [];
  for (const i in jsonData) {
    const row = [];
    for (const key in jsonData[i]) {
      row.push(jsonData[i][key]);
    }
    rows.push(row);
  }

  // Create a CSV string from the array of rows and columns
  const csv = columns ? columns.join(',') + '\n' + rows.join('\n') : rows.join('\n');

  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  return csv;
}
