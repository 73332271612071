import { RendererType } from '../../../data-models/field.data-model';
import { ITextMeta } from '../../../data-models/field3.data-model';
import { createFormField, IFormField } from '../../../view-models/form.view-model';
import { useDealTodoField } from '../../../data-fields/DealFields';

export function useGetDealStageFields(): IFormField<unknown>[] {
  return [
    createFormField<ITextMeta>({
      dataType: 'string',
      key: 'name',
      label: 'Name',
      renderer: RendererType.text,
      rendererMeta: {
        maxLength: -1,
      },
    }),
    useDealTodoField({
      key: 'meta.required',
      label: 'To Do Items',
    }),
  ];
}
