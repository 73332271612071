import { useMemo } from 'react';
import { styled } from '@mui/material/styles';

const RightAligned = styled('span')`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

type CurrencyRendererProps = {
  value: number | null | undefined;
  currencyCode?: string | null;
};

export const CurrencyRenderer = ({ value, currencyCode }: CurrencyRendererProps) => {
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode || 'USD', // TODO: check if we should default to USD
        maximumFractionDigits: 2,
      }),
    [currencyCode]
  );
  return value === 0 || value ? (
    <RightAligned className='monospace'>{formatter.format(value)}</RightAligned>
  ) : null;
};
