import { FC, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { getClientRunData, getRunDataByFund } from '../../utils/runUtils';
import { IScenarioRunDataModel } from '../../../../../data-models/scenario-run.data-model';
import { ChipSelector } from '../../../../../components/ChipSelector';
import { RendererType } from '../../../../../data-models/field.data-model';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { IScenarioRunGridProps, ScenarioRunGrid } from './ScenarioRunGrid';
import { ScenarioTypeByTransactions } from './ScenarioVisualization';

interface IScenarioRunByFundProps extends IScenarioRunGridProps {
  initialClientFilterState: boolean;
  scenarioType: ScenarioTypeByTransactions;
}

export const ScenarioRunByFund: FC<IScenarioRunByFundProps> = ({
  colDefs,
  scenarioRunData,
  initialClientFilterState,
  scenarioType,
}) => {
  const dataByFund = useMemo(() => {
    return getRunDataByFund(scenarioRunData);
  }, [scenarioRunData]);

  const fundOptions = useMemo(
    () => ['All Funds', ...Array.from(dataByFund.keys()).sort((a, b) => a.localeCompare(b))],
    [dataByFund]
  );
  const [selectedFund, setSelectedFund] = useState('All Funds');
  const [clientOnly, setClientOnly] = useState(initialClientFilterState);

  const gridData = useMemo(() => {
    const data = selectedFund === 'All Funds' ? scenarioRunData : dataByFund.get(selectedFund);
    if (!clientOnly) {
      return data;
    } else {
      return getClientRunData(data ?? [], scenarioType);
    }
  }, [clientOnly, dataByFund, scenarioRunData, scenarioType, selectedFund]);

  return (
    <>
      <Stack direction={'row'} mr='3rem' alignItems={'center'}>
        <ScenarioRunFundSelector
          value={selectedFund}
          handleChange={(newValue: string) => {
            setSelectedFund(newValue);
          }}
          options={fundOptions}
        />
        <FieldFactory
          formProps={{ value: clientOnly, onChange: setClientOnly } as ControllerRenderProps}
          formField={{ key: 'clientOnly', renderer: RendererType.boolean }}
        />
        <Typography variant='body2' style={{ minWidth: '10rem' }}>
          My Participation Only
        </Typography>
      </Stack>
      <ScenarioRunGrid
        key={selectedFund}
        colDefs={colDefs}
        scenarioRunData={(gridData as IScenarioRunDataModel[]) ?? []}
        scenarioType={scenarioType}
      />
    </>
  );
};

export const ScenarioRunFundSelector: FC<{
  options: string[];
  value: string;
  handleChange: (val: string) => void;
}> = ({ value, handleChange, options }) => {
  const fundOptions = useMemo(() => options.map((opt) => ({ value: opt, displayName: opt })), [options]);
  return <ChipSelector options={fundOptions} value={value} onChange={handleChange} />;
};
