import { FC, MouseEvent, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRecoilValue } from 'recoil';
import { ViewAction, ViewActionName } from '../../../../../types';
import {
  useDeleteView,
  useDuplicateView,
  useResetView,
} from '../../../../../pages/PortfolioOverview/hooks/useViewActions';
import { selectedViewPF } from '../../../../../pages/PortfolioOverview/state/ViewState';
import { ConfirmDeletionDialog } from '../../../../Dialog/ConfirmDialog/ConfirmDeletionDialog';

interface Props {
  options: ViewAction[];
  openShareDialog: () => void;
  onRename: () => void;
}

const ItemWrapper = styled('div')``;
const Wrapper = styled('div')`
  margin-top: -3px;
`;

export const ViewMenu: FC<Props> = ({ options, openShareDialog, onRename }) => {
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleDuplicate = useDuplicateView();
  const handleReset = useResetView();
  const handleDelete = useDeleteView();
  const selectedView = useRecoilValue(selectedViewPF);

  const onDelete = useCallback(() => {
    if (!selectedView) return;
    handleDelete(selectedView);
  }, [handleDelete, selectedView]);

  const onConfirmDelete = useCallback(() => {
    setShowConfirmDelete(false);
    onDelete();
  }, [onDelete]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onDuplicate = useCallback(() => {
    if (!selectedView) return;
    handleDuplicate(selectedView);
  }, [handleDuplicate, selectedView]);

  const onReset = useCallback(() => {
    if (!selectedView) return;
    handleReset(selectedView);
  }, [handleReset, selectedView]);

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLElement>, id: ViewActionName) => {
      e.stopPropagation();
      handleCloseMenu();
      switch (id) {
        case ViewActionName.RENAME:
          onRename();
          return;
        case ViewActionName.DUPLICATE:
          onDuplicate();
          return;
        case ViewActionName.RESET:
          onReset();
          return;
        case ViewActionName.SHARE:
          openShareDialog();
          return;
        case ViewActionName.DELETE:
          setShowConfirmDelete(true);
          return;
        default:
          throw new Error('Unknown option');
      }
    },
    [onDuplicate, onRename, onReset, openShareDialog]
  );

  return (
    <Wrapper>
      <IconButton
        data-testid='menu-btn'
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon style={{ color: 'white' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
        disablePortal
        PaperProps={{
          style: {
            width: '180px',
            transform: 'translateX(-95px)',
          },
        }}
      >
        {options.map(({ id, hasSeparator, icon, value }) => (
          <ItemWrapper key={id}>
            {hasSeparator && <Divider />}
            <MenuItem onClick={(e) => handleSelectOption(e, id)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <Typography variant='body2' color={colors.primary[100]}>
                {value}
              </Typography>
            </MenuItem>
          </ItemWrapper>
        ))}
      </Menu>
      <ConfirmDeletionDialog
        open={showConfirmDelete}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={onConfirmDelete}
        title={`Delete View "${selectedView.name}"?`}
      >
        <Typography variant='body2' color={colors.neutral[60]}>
          The view will be deleted permanently
        </Typography>
      </ConfirmDeletionDialog>
    </Wrapper>
  );
};
