import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';

export function ExportGridButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick} title='Export to excel' color='secondary' size='large'>
      <DownloadIcon />
    </IconButton>
  );
}
