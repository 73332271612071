export class FDMap<Key, Value> extends Map<Key, Value> {
  getOrDefault(key: Key, defaultValue: Value) {
    if (this.has(key)) {
      return this.get(key)!;
    }
    this.set(key, defaultValue);

    return defaultValue;
  }

  getOrDynamicDefault(key: Key, getDefaultFn: () => Value) {
    if (this.has(key)) {
      return this.get(key)!;
    }

    const defaultValues = getDefaultFn();
    this.set(key, defaultValues);

    return defaultValues;
  }

  reduce<T>(mappingFunction: (accumulator: T, value: Value, key: Key) => T, initialValue: T) {
    let res = initialValue;

    this.forEach((value, key) => {
      res = mappingFunction(res, value, key);
    });

    return res;
  }

  toArray<T>(mappingFunction: (value: Value, key: Key) => T) {
    const res: T[] = [];
    this.forEach((value, key) => {
      res.push(mappingFunction(value, key));
    });

    return res;
  }

  setOrUpdate(key: Key, initialValue: Value, updater: (currentValue: Value) => Value) {
    if (this.has(key)) {
      this.update(key, updater as (currentValue: Value | undefined) => Value);
    } else {
      this.set(key, initialValue);
    }
    return this;
  }

  update(key: Key, updater: (currentValue: Value | undefined) => Value) {
    const newValue = updater(this.get(key));
    return this.set(key, newValue);
  }

  static fromArray<K, T extends object>(values: T[], keyField: keyof T) {
    return values.reduce((res, value) => res.set(value[keyField] as K, value), new FDMap<K, T>());
  }

  static fromObject<T>(object: Record<string, T>) {
    return Object.keys(object).reduce((res, key) => {
      return res.set(key, object[key]);
    }, new FDMap<string, T>());
  }
}
