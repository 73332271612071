import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { Modal } from './Modal';

interface Props {
  children: ReactNode;
}

interface Store {
  modalContent: JSX.Element | null;
  onOpenModal: (modalContent: JSX.Element) => void;
  onCloseModal: () => void;
}

const ModalContext = createContext<Store | undefined>(undefined);

export const useModalState = (): Store => {
  const context = useContext(ModalContext);

  if (typeof context === 'undefined') {
    throw new Error('useModalState cannot be used outside ModalProvider');
  }

  return context;
};

export const ModalProvider = ({ children }: Props): JSX.Element => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  const onOpenModal = (modalContent: JSX.Element) => {
    setModalContent(modalContent);
  };

  const onCloseModal = () => {
    setModalContent(null);
  };

  const value = useMemo(() => {
    return {
      modalContent,
      onOpenModal,
      onCloseModal,
    };
  }, [modalContent]);

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};
