import { FC, useCallback, useMemo } from 'react';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useRequestActions } from '../KPIRequestResponse/hooks/useKPIRequestResponseActions';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { KpiAction } from '../../../../../services/state/KPI/KPIRequestsState';
import { MenuButton } from '../../../../Finance2/common-grid-defs/MenuButton';

export const SendReminderTitle = 'Send Reminder';
export const MoreRequestActionsTitle = 'More Options';

export const SentRequestActions: FC<ICellRendererParams<IKPIRequestDataModel>> = ({ data, node }) => {
  const { onSelectAction } = useRequestActions();

  const handleSelectAction = useCallback(
    (action: KpiAction) => {
      onSelectAction(data!, action);
    },
    [data, onSelectAction]
  );

  const moreOptions = useMemo(() => {
    return [
      {
        name: 'Delete',
        icon: <DeleteOutlineOutlinedIcon />,
        onClick: () => handleSelectAction(KpiAction.archive),
      },
    ];
  }, [handleSelectAction]);

  if (!data || !data.respondent?.length || node?.group) return null;

  return (
    <Stack display={'grid'} gridAutoFlow={'column'} alignItems={'center'}>
      <IconButton
        color='secondary'
        onClick={() => handleSelectAction(KpiAction.sendReminder)}
        title={SendReminderTitle}
      >
        <NotificationAddOutlinedIcon />
      </IconButton>
      <MenuButton menuItems={moreOptions} label={MoreRequestActionsTitle} />
    </Stack>
  );
};
