import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { assignmentErrorsState } from '../AllCompaniesState';

export function useTemplateAssignmentErrors() {
  const [errors, setErrors] = useRecoilState(assignmentErrorsState);

  const onClose = useCallback(() => {
    setErrors(null);
  }, [setErrors]);

  return { errors, onClose };
}
