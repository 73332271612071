import { IConversionRateDataModel } from '../../../data-models/deal-conversion.data-model';

interface SankeySeriesData {
  data: { name: string; value?: number; depth?: number; previousPeriodDifference?: number | null }[];
  links: { source: string; target: string; value: number }[];
}

export function useSankeyData(conversionRates: IConversionRateDataModel[]) {
  const result: SankeySeriesData = {
    data: [],
    links: [],
  };

  const nonZeroStages = [...filterNonZeroStages(conversionRates)].sort(
    (a, b) => (a?.stageSortOrder ?? Number.MAX_VALUE) - (b?.stageSortOrder ?? Number.MAX_VALUE)
  );

  nonZeroStages.reduce((acc, curr, i) => {
    const {
      stage,
      track,
      pass,
      missed,
      numOfDeals,
      previousPeriodDifference,
      missedPreviousPeriodDifference,
      trackPreviousPeriodDifference,
      passPreviousPeriodDifference,
    } = curr;

    acc.data.push({ name: stage, value: numOfDeals, depth: i, previousPeriodDifference });

    // we need unique names, so use "#" + depth - will get rid of it in the label formatter
    if (pass > 0)
      acc.data.push({
        name: `Passed#${i}`,
        value: pass,
        depth: i,
        previousPeriodDifference: passPreviousPeriodDifference,
      });
    if (track > 0)
      acc.data.push({
        name: `Tracked#${i}`,
        value: track,
        depth: i,
        previousPeriodDifference: trackPreviousPeriodDifference,
      });
    if (missed > 0)
      acc.data.push({
        name: `Missed#${i}`,
        value: missed,
        depth: i,
        previousPeriodDifference: missedPreviousPeriodDifference,
      });

    if (i > 0) {
      const source = nonZeroStages[i - 1].stage;

      acc.links.push({ source, target: stage, value: numOfDeals });
      if (pass > 0) acc.links.push({ source, target: `Passed#${i}`, value: pass });
      if (track > 0) acc.links.push({ source, target: `Tracked#${i}`, value: track });
      if (missed > 0) acc.links.push({ source, target: `Missed#${i}`, value: missed });
    }

    return acc;
  }, result);

  return result;
}

export function filterNonZeroStages(conversionRates: IConversionRateDataModel[]) {
  return conversionRates.filter(
    (rate) => rate.numOfDeals > 0 || rate.pass > 0 || rate.missed > 0 || rate.track > 0
  );
}
