import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const Centered = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

type PriorityCellRendererProps = {
  value: number | null | undefined;
};

export const PriorityCellRenderer = ({ value }: PriorityCellRendererProps) => {
  const { colors } = useTheme();

  const starProps =
    value === 1
      ? { stroke: colors.warning[50], fill: true, testId: 'yellow-star' }
      : { stroke: colors.neutral[30], fill: false, testId: 'neutral-star' };

  if (value === undefined) return null;
  return (
    <Centered>
      {starProps.fill ? (
        <StarRoundedIcon htmlColor={starProps.stroke} data-testid={starProps.testId} />
      ) : (
        <StarBorderRoundedIcon htmlColor={starProps.stroke} data-testid={starProps.testId} />
      )}
    </Centered>
  );
};
