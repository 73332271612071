import {
  UniqueIdentifier,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash-es';
import { useCallback, useState } from 'react';
import { IItemWithId } from '../SortableList/SortableList';

export interface IMultiSortableProps<T extends IItemWithId> {
  items: Record<string, T[]>;
  onChange: (items: Record<string, T[]>) => void;
  groupSize?: number;
}

export function useMultiSortableList<T extends IItemWithId>(props: IMultiSortableProps<T>) {
  const { items, onChange, groupSize } = props;
  const [, setActiveId] = useState<UniqueIdentifier>(-1);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const findContainer = useCallback(
    (id: UniqueIdentifier) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find((groupName) => items[groupName].some((item) => item.id === id));
    },
    [items]
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;

    setActiveId(active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const { id } = active;
      const overId = over?.id ?? -1;

      const activeContainer = findContainer(id);
      const overContainer = findContainer(overId);

      if (!activeContainer || !overContainer || activeContainer !== overContainer) {
        return;
      }

      const activeIndex = items[activeContainer].findIndex((item) => item.id === active.id);
      const overIndex = items[overContainer].findIndex((item) => item.id === overId);

      if (activeIndex !== overIndex) {
        const newItems: Record<string, T[]> = cloneDeep({
          ...items,
          [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
        });

        onChange(newItems);
      }

      setActiveId(-1);
    },
    [findContainer, items, onChange]
  );
  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const { id } = active;
      const overId = over?.id ?? -1;

      // Find the containers
      const activeContainer = findContainer(id);
      const overContainer = findContainer(overId);

      if (!activeContainer || !overContainer || activeContainer === overContainer) {
        return;
      }

      const prev = items;
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((item) => item.id === id);
      const overIndex = overItems.findIndex((item) => item.id === overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = over && overIndex === overItems.length - 1;
        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      props.onChange({
        ...prev,
        [activeContainer]: [...prev[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      });
    },
    [findContainer, items, props]
  );

  const handleDragOverFixedSizeGroup = useCallback(
    (event: DragOverEvent) => {
      if (typeof groupSize !== 'number') {
        throw new Error('groupSize must be defined');
      }
      const { active, over } = event;
      const { id } = active;
      const overId = over?.id ?? -1;

      const activeContainer = findContainer(id);
      const overContainer = findContainer(overId);

      if (!activeContainer || !overContainer || activeContainer === overContainer) {
        return;
      }

      if (over && active.id !== over?.id) {
        const groups = Object.keys(items);
        let values = cloneDeep(Object.values(items).flat());
        const activeIndex = values.findIndex(({ id }) => id === active.id);
        const overIndex = values.findIndex(({ id }) => id === over.id);
        values = arrayMove(values, activeIndex, overIndex);
        const newGroups: Record<string, T[]> = {};
        groups.forEach((group) => {
          newGroups[group as keyof typeof newGroups] = values.splice(0, groupSize);
        });
        onChange(newGroups);
      }
    },
    [findContainer, groupSize, items, onChange]
  );

  return {
    sensors,
    handleDragStart,
    handleDragEnd,
    setActiveId,
    handleDragOver,
    handleDragOverFixedSizeGroup,
  };
}
