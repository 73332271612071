import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class NumberOfMarkupsConfig implements KpiConfig {
  private id = KPI_COMPONENTS.NUMBER_OF_MARKUPS as const;
  private title = 'Markups';
  private type = 'integer';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value = data?.rawCompanies.filter(({ fmv, amountInvested }) => fmv > amountInvested).length || 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
