import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { ROUTES } from '../../../../constants/RouteNameMapping';
import { reconnectAirtable } from '../../../../services/queries/MaggieIntegrationsQueries';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../../services/queryHelpers';

export function connectToAirTable(oauthUrl: string, callbackUrl?: string) {
  if (!oauthUrl) return;
  window.location.assign(
    `${oauthUrl}&callback=${callbackUrl ?? `${ROUTES.INTEGRATIONS}/${ROUTES.INTEGRATIONS_APPS}`}`
  );
}

export function useReconnectToAirtable() {
  const { pushErrorToast } = useToastMessageState();

  return useCallback(async () => {
    try {
      const { data } = await reconnectAirtable(window.location.href);
      const { oauthUrl } = data;
      connectToAirTable(oauthUrl, `${ROUTES.INTEGRATIONS}/${ROUTES.INTEGRATIONS_APPS}`);
    } catch (err) {
      console.error(err);

      const message = getErrorMessage(err, 'Failed to reconnect to Airtable');
      pushErrorToast({ message });
      Sentry.captureException(err);
    }
  }, [pushErrorToast]);
}
