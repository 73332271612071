import * as yup from 'yup';
import { capitalize } from 'lodash-es';
import { RendererType } from '../data-models/field.data-model';
import { futureDateSchema } from '../pages/Finance2/Forms/transactionFieldSchemas';
import { CompoundingPeriod, InterestType } from './CaptableInvestment.schema';
import {
  investmentDateField,
  investmentTypeField,
  shareClassNameField,
  usdField,
  ViewModelInvestmentType,
} from './common-schema-defs';
import { abstractShareClassSchema } from './AbstractShareClass.schema';

export const NotesSchema = {
  create() {
    return abstractShareClassSchema().shape({
      type: investmentTypeField().default(ViewModelInvestmentType.notes),
      name: shareClassNameField().default('Notes'),
      discountRate: yup.number().nullable().optional().label('Discount Rate').default(10).meta({
        renderer: RendererType.percent,
      }),
      interestRate: yup.number().nullable().optional().label('Interest Rate').default(0).meta({
        renderer: RendererType.percent,
      }),
      valuationCap: usdField().nullable().optional().label('Valuation Cap').default(null),
      interestType: yup
        .string()
        .oneOf(Object.values(InterestType))
        .nullable()
        .optional()
        .label('Interest Type')
        .default(InterestType.none)
        .meta({
          renderer: RendererType.singleSelect,
          rendererMeta: {
            values: Object.values(InterestType).map((interestType) => ({
              value: interestType,
              displayName: capitalize(interestType),
            })),
          },
        }),
      compoundingPeriod: yup
        .string()
        .oneOf(Object.values(CompoundingPeriod))
        .nullable()
        .optional()
        .label('Compounding Period')
        .default(CompoundingPeriod.none)
        .meta({
          renderer: RendererType.singleSelect,
          rendererMeta: {
            values: Object.values(CompoundingPeriod).map((compoundingPeriod) => ({
              value: compoundingPeriod,
              displayName: capitalize(compoundingPeriod),
            })),
          },
        }),
      maturityDate: futureDateSchema().label('Maturity Date').optional().default(null).meta({
        renderer: RendererType.date,
      }),
      investmentDate: investmentDateField(),
      postMoneyValuation: usdField().nullable().label('Post-Money Valuation').default(null),
    });
  },
};

export type Note = yup.InferType<ReturnType<typeof NotesSchema.create>>;

export function createNote(overrides: Partial<Note> = {}): Note {
  return NotesSchema.create().cast({ ...overrides });
}

export const NotesFormSchema = NotesSchema.create();
