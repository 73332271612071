import { useViewNavigation } from '../../../../../pages/PortfolioOverview/components/ViewNavigation/ViewNavigationProvider';

import { SelectItem } from '../../../../../types';
import { ViewOptions } from './ViewOptions';

export const ViewNavigation = () => {
  const { items, onReorder, onSelect } = useViewNavigation();

  const onReorderHandler = (items: SelectItem[]) => {
    onReorder(items);
  };

  const onSelectHandler = (item: SelectItem) => {
    if (onSelect) onSelect(item);
  };

  return <ViewOptions items={items} onReorder={onReorderHandler} onItemSelect={onSelectHandler} />;
};
