import { ColDef } from 'ag-grid-community';
import { BulkUploadReportResponse, RecordStatus } from '../../../schemas/BulkUploadReport.schema';
import { UploadStatusRenderer } from './UploadStatusRenderer';

export interface IBulkUploadReportGridData extends RecordStatus {}

export function getBulkUploadReportGridData(data: BulkUploadReportResponse) {
  const fileName = data.uploadItem.payload.sheet_metadata.original_file_name;
  const fieldToHeaderLabel = data.uploadItem.payload.columns.reduce((map, column) => {
    return map.set(column.template_column_key, column.sheet_column_name);
  }, new Map<string, string>());
  const idxToRecordStatus = (data.records ?? []).reduce((map, record) => {
    return map.set(record.recordIndex, record);
  }, new Map<number, RecordStatus>());
  const rowData = (data.uploadItem?.payload?.data?.records ?? []).map((record, idx) => {
    const recordStatus = idxToRecordStatus.get(idx);
    return {
      ...record,
      recordIndex: idx,
      status: recordStatus?.status ?? '',
      errorMessage: recordStatus?.errorMessage ?? '',
    };
  }, [] as IBulkUploadReportGridData[]);

  const colDefs: ColDef[] = (data?.uploadItem?.payload?.columns ?? []).map((column) => {
    return {
      field: column.template_column_key,
      headerName: fieldToHeaderLabel.get(column.template_column_key) ?? column.template_column_key,
    };
  });
  colDefs.push(
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: UploadStatusRenderer,
      sortable: true,
      initialSort: 'asc',
    },
    {
      field: 'errorMessage',
      headerName: 'Error Message',
      tooltipField: 'errorMessage',
      minWidth: 300,
      filter: 'agTextColumnFilter',
    }
  );

  return {
    fileName,
    rowData,
    colDefs,
  };
}

export const BulkUploadReportDefaultColDef = {
  flex: 1,
  minWidth: 150,
};
