import { ColumnState } from 'ag-grid-community';
import { IFundDataModel } from './fund.data-model';

type UUID = string;
export type ViewSectionUuid = UUID;
export type ViewComponentUuid = UUID;
export type ViewComponent = {
  sort: Record<string, string[]>;
  state: ColumnState[];
};
export type ViewSections = Record<ViewSectionUuid, ViewSection>;
export type ViewComponents = Record<ViewComponentUuid, ViewComponent>;
export type RowGroupModel = Record<string, RowGroupModelEntry>;

export enum VIEW_SECTION_TYPE {
  KPI = 'kpi',
  CHART = 'chart',
  TABLE = 'table',
}

// overlaps with COMPANY_VIEW_TYPE
export enum ViewTypeByData {
  company = 'company',
  fund = 'fund',
  people = 'people',
  security = 'security',
  transaction = 'transaction',
}

export enum VIEW_TYPE {
  COMPANY = 'company', // default view
  PERSONAL = 'personal',
  SHARED = 'shared',
}

export enum COMPANY_VIEW_TYPE {
  PORTFOLIO_OVERVIEW = 'PORTFOLIO_OVERVIEW',
  RETURN_FORECAST = 'RETURN_FORECAST',
  ROUND_TRACKER = 'ROUND_TRACKER',
}

export interface ViewSection {
  id: UUID;
  type: VIEW_SECTION_TYPE;
  title: string;
}

export interface ViewOrder {
  sections: ViewSectionUuid[];
  components: Record<ViewSectionUuid, ViewComponentUuid[]>;
}

export type OriginViewDataModel = {
  companyViewType: COMPANY_VIEW_TYPE;
  components: ViewComponents;
  createdBy: string;
  filteredFunds: IFundDataModel[] | null;
  id: number;
  isDefault: boolean;
  viewType: ViewTypeByData;
  label: string;
  name: string;
  order: ViewOrder;
  origin: null | OriginViewDataModel; // null for VIEW_TYPE.COMPANY, View for personal/custom views  used for resetting
  rowGroupModel?: RowGroupModel | null;
  sections: ViewSections;
  sharing?: string[];
  tableFilters: Record<string, unknown> | null;
  type: VIEW_TYPE;
  viewId: string;
};

export interface RowGroupModelEntry {
  expanded: boolean;
  children: Record<string, RowGroupModelEntry>;
}

export interface ViewConfigDataModel {
  asOfDate?: null;
  companyViewType: COMPANY_VIEW_TYPE;
  components: ViewComponents;
  filteredFunds: IFundDataModel[] | null;
  columnsToShow?: string[];
  isDefault: boolean;
  order: ViewOrder;
  origin: OriginViewDataModel | null;
  rowGroupModel?: RowGroupModel | null;
  sections: ViewSections;
  tableFilters: Record<string, unknown> | null;
}

export function createViewConfigDataModel(overrides: Partial<ViewConfigDataModel> = {}): ViewConfigDataModel {
  return {
    companyViewType: COMPANY_VIEW_TYPE.PORTFOLIO_OVERVIEW,
    components: {},
    filteredFunds: [],
    isDefault: true,
    order: {
      sections: [],
      components: {},
    },
    origin: null,
    rowGroupModel: undefined,
    sections: {},
    tableFilters: {},
    ...overrides,
  };
}
