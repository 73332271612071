import { styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';

const CELL_HEIGHT = 46;

const SMALLER_CELL_WIDTH_IN_REMS = 200;
const LARGER_CELL_WIDTH_IN_REMS = 700;

const SkeletonLoaderWrapper = styled('div')`
  margin-top: 2rem;
  width: 100%;
  height: 60vh;
`;

export function GridSkeletonLoader() {
  const cellRects = [];
  const startX = 0;
  const startY = 0;
  const gap = 16;
  for (let row = 0; row < 10; row++) {
    for (let col = 0; col < 3; col++) {
      const cellWidth = col < 2 ? SMALLER_CELL_WIDTH_IN_REMS : LARGER_CELL_WIDTH_IN_REMS;
      cellRects.push(
        <rect
          key={`${row}-${col}`}
          x={(startX + gap + SMALLER_CELL_WIDTH_IN_REMS) * col}
          y={(startY + gap + CELL_HEIGHT) * row}
          rx='0'
          ry='0'
          width={cellWidth}
          height={CELL_HEIGHT}
          fill='#EEEFF1'
        />
      );
    }
  }

  return (
    <SkeletonLoaderWrapper>
      <ContentLoader speed={2} width={'100%'} height={'100%'}>
        <>{cellRects}</>
      </ContentLoader>
    </SkeletonLoaderWrapper>
  );
}
