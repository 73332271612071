import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { companyState } from '../../../services/state/CompanyState';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../constants/RouteNameMapping';

export interface ICompanyProfileTabsProps {
  companyId: number;
}

export function CompanyProfileTabs(props: ICompanyProfileTabsProps) {
  const { companyId } = props;
  const { pathname } = useLocation();
  const company = useRecoilValue(companyState(companyId));
  const { competitiveIntelligenceModule, showScenarioModelling, showCapTable } =
    useFlags<MaggieFeatureFlags>();
  const canViewCapTable = PermissionService.get().hasPermission(PermissionKey.canViewTransaction);
  const navigate = useNavigate();

  const items = useMemo(() => {
    const sections = [
      {
        title: 'Summary',
        path: ROUTES.COMPANY_PROFILE_SUMMARY,
      },
    ];

    if (company?.companyType === 'portfolio') {
      sections.push({
        title: 'Performance',
        path: ROUTES.COMPANY_PROFILE_PERFORMANCE,
      });
    }

    sections.push({
      title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_FINANCIALS],
      path: ROUTES.COMPANY_PROFILE_FINANCIALS,
    });

    sections.push({ title: 'People', path: ROUTES.COMPANY_PROFILE_PEOPLE });

    if (competitiveIntelligenceModule) {
      sections.push({ title: 'Competitive Intelligence', path: ROUTES.COMPANY_PROFILE_CI });
    }

    if (showScenarioModelling) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_SCENARIOS],
        path: ROUTES.COMPANY_PROFILE_SCENARIOS,
      });
    }

    if (showCapTable && canViewCapTable) {
      sections.push({
        title: ROUTE_TITLE_MAPPING[ROUTES.COMPANY_PROFILE_CAP_TABLE],
        path: ROUTES.COMPANY_PROFILE_CAP_TABLE,
      });
    }
    return sections;
  }, [
    canViewCapTable,
    company?.companyType,
    competitiveIntelligenceModule,
    showCapTable,
    showScenarioModelling,
  ]);
  const [selectedTab, setSelectedTab] = useState(() => {
    const urlSegments = pathname.split('/');
    return urlSegments[3] as ROUTES;
  });

  useEffect(() => {
    const urlSegments = pathname.split('/');
    setSelectedTab(urlSegments[3] as ROUTES);
  }, [pathname]);

  const onChangeTab = useCallback(
    (path: string) => {
      setSelectedTab(path as ROUTES);
      navigate(`./${path}`);
    },
    [navigate]
  );

  return (
    <Tabs
      variant='scrollable'
      scrollButtons='auto'
      value={selectedTab}
      onChange={(e, item) => onChangeTab(item)}
    >
      {items.map((item) => (
        <Tab
          key={item.path}
          value={item.path}
          label={item.title}
          className={`mui-custom-tab`}
          onClick={() => {
            if (item.path === ROUTES.COMPANY_PROFILE_CAP_TABLE) onChangeTab(item.path);
          }}
        />
      ))}
    </Tabs>
  );
}
