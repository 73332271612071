import { IScenarioDataModel } from '../../../../data-models/scenario.data-model';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../services/PermissionService';
import { useCurrentUser } from '../../../../services/hooks/useCurrentUser';

export function useCanEditScenario(scenario: Partial<IScenarioDataModel>) {
  const currentUser = useCurrentUser();

  return (
    PermissionService.get().hasPermission(PermissionKey.canEditTransaction) ||
    !scenario.createdBy ||
    scenario.createdBy === currentUser?.email
  );
}
