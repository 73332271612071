import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

interface Props {
  isSubmitDisabled: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  submitLabel: string;
}

const Wrapper = styled('div')`
  margin-top: 28px;
  display: flex;
  gap: 32px;
  justify-content: start;
`;

export const ModalActionButtons: FC<Props> = ({ isSubmitDisabled, submitLabel, onSubmit, onCancel }) => {
  return (
    <Wrapper>
      <Button color='secondary' variant={'contained'} disabled={isSubmitDisabled} onClick={onSubmit}>
        {submitLabel}
      </Button>
      <Button color='secondary' onClick={onCancel}>
        Cancel
      </Button>
    </Wrapper>
  );
};
