import { useCallback, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useAtomValue } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectedFrequencyFinancialsState } from '../../FinancialsDatePickers/useFinancialsDateSettings';
import { KpiPeriod } from '../../../../../data-models/company-financials.data-model';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';

export interface IFinancialsDisplayOptions {
  showBudget?: boolean;
  showLTM?: boolean;
  showYTD?: boolean;
  showGrowth?: boolean;
}
export type FinancialsSettingsKey = keyof IFinancialsDisplayOptions | 'reportedOnly' | 'showPinnedYears';

export const settingsKeyToLabelMap: Record<FinancialsSettingsKey, string> = {
  reportedOnly: 'Only Reported KPIs',
  showBudget: 'Show Budget',
  showLTM: 'Show LTM',
  showYTD: 'Show YTD',
  showGrowth: 'Show Delta Trend',
  showPinnedYears: 'Show Years',
};

export const showReportedOnlyState = atom<boolean>({
  key: 'showReportedOnlyState',
  default: true,
});

export const showBudgetState = atom<boolean>({
  key: 'showBudgetState',
  default: true,
});

export const showLTMState = atom<boolean>({
  key: 'showLTMState',
  default: false,
});

export const showYTDState = atom<boolean>({
  key: 'showYTDState',
  default: false,
});

export const showGrowthState = atom<boolean>({
  key: 'showGrowthState',
  default: false,
});

export type FinancialsDisplaySettings = Record<FinancialsSettingsKey, boolean>;

export function useFinancialsGridSettings() {
  const [showReportedOnly, setShowReportedOnly] = useRecoilState(showReportedOnlyState);
  const [showBudget, setShowBudget] = useRecoilState(showBudgetState);
  const [showLTM, setShowLTM] = useRecoilState(showLTMState);
  const [showYTD, setShowYTD] = useRecoilState(showYTDState);
  const [showGrowth, setShowGrowth] = useRecoilState(showGrowthState);
  const selectedPeriod = useAtomValue(selectedFrequencyFinancialsState);
  const { showFinancialsPinnedYears } = useFlags<MaggieFeatureFlags>();

  const handleSettingsChange = useCallback(
    (key: FinancialsSettingsKey) => {
      switch (key) {
        case 'reportedOnly':
          setShowReportedOnly((prev) => !prev);
          break;
        case 'showBudget':
          setShowBudget((prev) => !prev);
          break;
        case 'showLTM':
          setShowLTM((prev) => !prev);
          break;
        case 'showYTD':
          setShowYTD((prev) => !prev);
          break;
        case 'showGrowth':
          setShowGrowth((prev) => !prev);
          break;
      }
    },
    [setShowBudget, setShowGrowth, setShowLTM, setShowReportedOnly, setShowYTD]
  );

  const showPinnedYears = useMemo(() => {
    return showFinancialsPinnedYears && selectedPeriod !== KpiPeriod.year;
  }, [selectedPeriod, showFinancialsPinnedYears]);

  const displaySettings: FinancialsDisplaySettings = useMemo(() => {
    return {
      reportedOnly: showReportedOnly,
      showBudget,
      showLTM,
      showYTD,
      showGrowth: showGrowth,
      showPinnedYears,
    };
  }, [showReportedOnly, showBudget, showLTM, showYTD, showGrowth, showPinnedYears]);

  return {
    displaySettings,
    handleSettingsChange,
  };
}
