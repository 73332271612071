import { Alert, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { DealConversionState } from '../../../state/DealConversionState';

import { useFiltersParams } from '../Details/hooks/useFiltersParams';
import { BreakdownChart } from './BreakdownChart';

import { ConversionBarChart } from './ConversionBarChart';
import { DealAnalyticsChartsSkeletonLoader } from './DAOverviewSkeletonLoader';
import { OverviewChart } from './OverviewChart';

const ChartWrapper = styled('div')`
  display: grid;
  gap: 2rem;
`;

export const DAOverview: FC = () => {
  const { queryParamsWithGroupBy } = useFiltersParams();

  const { groupBy: groupByKey, ...conversionParams } = queryParamsWithGroupBy;
  const key = JSON.stringify(conversionParams);

  const dealConversionLoadable = useRecoilValueLoadable(DealConversionState(conversionParams));

  const dealGroupedLoadable = useRecoilValueLoadable(DealConversionState(queryParamsWithGroupBy));

  const dealsGroupedData = dealGroupedLoadable.valueMaybe()?.dealsGrouped;

  const dealConversionData = dealConversionLoadable.valueMaybe()?.conversionRates;

  return (
    <GenericFallbacksWrapper suspenseFallback={<DealAnalyticsChartsSkeletonLoader />}>
      <ChartWrapper>
        {dealConversionData ? (
          <>
            <OverviewChart dealConversion={dealConversionData} key={`overview-${key}`} />
            <ConversionBarChart dealConversion={dealConversionData} key={`conversion-${key}`} />
          </>
        ) : dealConversionLoadable.state === 'hasError' ? (
          <Alert severity='error'>Error loading data</Alert>
        ) : (
          <DealAnalyticsChartsSkeletonLoader />
        )}
        {dealsGroupedData ? (
          <BreakdownChart dealsGrouped={dealsGroupedData} key={`breakdown-${key}-${groupByKey}`} />
        ) : dealGroupedLoadable.state === 'hasError' ? (
          <Alert severity='error'>Error loading data</Alert>
        ) : (
          <Skeleton height={'500px'} variant='rectangular' width={'100%'} />
        )}
      </ChartWrapper>
    </GenericFallbacksWrapper>
  );
};
