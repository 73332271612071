import { Stack, Typography, useTheme } from '@mui/material';

interface ISubSectionNavigation {
  title: string;
  RightSlot?: React.ReactNode;
  children?: React.ReactNode;
}

export const SubSectionNavigation = ({
  title,
  RightSlot: RightSlot = null,
  children = null,
}: ISubSectionNavigation) => {
  const { secondary } = useTheme().colors;

  return (
    <>
      <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
        <Typography variant='subtitle2' color={secondary[20]}>
          {title.toLocaleUpperCase()}
        </Typography>
        {RightSlot}
      </Stack>
      {children}
    </>
  );
};
