import { cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import { Fund } from '../../schemas/Fund.schema';
import { toFundViewModel } from '../../schemas/FundViewModel.schema';
import { FundFormModal } from './FundForms/FundFormModal';
import { useUpdateFund } from './state/useUpdateFund';

export function FundSettings({ fund }: { fund: Fund }) {
  const updateFund = useUpdateFund();
  const defaultValues = useMemo(() => toFundViewModel(cloneDeep(fund)), [fund]);
  return (
    <>
      <FundFormModal
        onSubmit={updateFund as (fund: Partial<Fund>) => Promise<Fund>}
        defaultValues={defaultValues}
      />
    </>
  );
}
