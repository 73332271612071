import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CoInvestorChartItem } from '../../../types';
import { selectedViewPF } from '../state/ViewState';
import { roundTrackerMetricsFilteredByViewState } from '../state/RoundTrackerMetricsState';

export const useMostCommonCoInvestors = (): CoInvestorChartItem[] => {
  const view = useRecoilValue(selectedViewPF);
  const filteredData = useRecoilValue(roundTrackerMetricsFilteredByViewState(view.id));

  return useMemo(() => {
    if (!filteredData) return [];

    const coInvestorsFrequency: Record<string, { count: number; companies: Record<string, string> }> = {};

    filteredData.forEach((d) => {
      for (let i = 0; i < d.coInvestors.length; i++) {
        const ci = d.coInvestors[i];
        if (!coInvestorsFrequency[ci]) {
          coInvestorsFrequency[ci] = {
            count: 1,
            companies: { [d.company]: d.company },
          };
          continue;
        }
        if (coInvestorsFrequency[ci].companies[d.company]) continue;
        coInvestorsFrequency[ci].count++;
        coInvestorsFrequency[ci].companies[d.company] = d.company;
      }
    });

    const coInvestorsFrequencyArray: CoInvestorChartItem[] = [];

    for (const property in coInvestorsFrequency) {
      if (['Other common holders', 'Other investors'].includes(property)) continue;
      coInvestorsFrequencyArray.push({ name: property, deals: coInvestorsFrequency[property].count });
    }
    coInvestorsFrequencyArray.sort((a, b) => b.deals - a.deals);

    return coInvestorsFrequencyArray.slice(0, 10);
  }, [filteredData]);
};
