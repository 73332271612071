import { FC } from 'react';
import { FormikHelpers } from 'formik';
import { ModalWrapper } from '../ModalWrapper';
import { ObjectiveForm, ObjectiveFormValues } from './ObjectiveForm';

interface ObjectiveModalProps {
  open: boolean;
  onClose: () => void;
  initialValues: ObjectiveFormValues;
  onSubmit: (
    values: ObjectiveFormValues,
    formikHelpers: FormikHelpers<ObjectiveFormValues>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => void | Promise<any>;
}

export const ObjectiveModal: FC<ObjectiveModalProps> = ({ open, onClose, initialValues, onSubmit }) => {
  return (
    <ModalWrapper title='Edit Objective' open={open} onClose={onClose}>
      <ObjectiveForm onClose={onClose} initialValues={initialValues} onSubmit={onSubmit} />
    </ModalWrapper>
  );
};
