import { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CHART_COMPONENTS } from '../../../../types';
import { GroupExtractorFn, useIrrByGroup } from '../../hooks/useIrrByGroup';
import { LineChart } from '../../../../components/Charts/LineChart/LineChart';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { fundsByIdMapState } from '../../../../services/state/AppConfigState';
import { IPortfolioChartProps } from './ChartComponent';

export const IrrByFundChart: FC<IPortfolioChartProps<IMetricsDataModel>> = (props) => {
  const fundsMap = useRecoilValue(fundsByIdMapState);
  const transactionFundExtractor = useCallback<GroupExtractorFn>(
    ({ transaction }) => fundsMap.get(transaction.fundId ?? -1)?.name ?? 'Unknown',
    [fundsMap]
  );
  const { data } = useIrrByGroup(props.filteredMetrics, transactionFundExtractor);
  const yFormatter = useCallback((tick: string | number) => `${Number(tick).toFixed(1)}%`, []);

  return (
    <LineChart
      yAxisLabelFormatter={yFormatter}
      data={data}
      id={CHART_COMPONENTS.IRR_BY_FUND}
      title={'IRR by Fund'}
      width={640}
    />
  );
};
