import { Paper, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { getRowsWithErrors } from './mappingDataUtils';

interface IErrorInfoProps {
  rowData: { hasError: boolean }[];
}
export const ErrorInfo: FC<IErrorInfoProps> = ({ rowData }) => {
  const nErrors = getRowsWithErrors(rowData).length;
  const { colors } = useTheme();

  if (rowData.length === 0) return null;

  return (
    <Paper
      sx={{
        margin: '0.5rem auto 1rem',
        height: '3rem',
        borderLeft: `0.25rem solid ${nErrors === 0 ? colors.success[60] : colors.critical[50]}`,
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
      }}
    >
      {nErrors === 0 ? (
        <Typography sx={{ paddingLeft: '1rem' }} variant={'body2'}>
          All data vendors have been mapped
        </Typography>
      ) : (
        <Typography sx={{ paddingLeft: '1rem' }} variant={'body2'}>
          {`There ${nErrors === 1 ? 'is' : 'are'}`}
          <span style={{ color: colors.critical[50], fontWeight: 'bold' }}>
            &nbsp;
            {nErrors}
            &nbsp;
          </span>
          {`${nErrors === 1 ? 'vendor' : 'vendors'} with errors.`}
        </Typography>
      )}
    </Paper>
  );
};
