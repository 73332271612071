import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { IField } from '../../../../../data-models/field2.data-model';
import { kpiConfigByIdMapAtom } from '../../../../../services/state/KPIConfigState';
import { IKpiSelectItem, selectedKPIMenuItemsAtom } from '../../../state/ProfileViewSettings';
import { selectedCompanyIdProfileAtom } from '../../../state/UIStateJ';

export function useKPISelectMenu() {
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const [selectedStatistics, setSelectedStatistics] = useAtom(selectedKPIMenuItemsAtom(companyId));
  const kpiMap = useAtomValue(kpiConfigByIdMapAtom);
  const handleDelete = useCallback(
    (id: string) => {
      setSelectedStatistics(selectedStatistics.filter((item) => String(item.id) !== id));
    },
    [selectedStatistics, setSelectedStatistics]
  );

  const handleReorder = useCallback(
    (ids: string[]) => {
      setSelectedStatistics(
        ids.map((id) => {
          const kpi = kpiMap.get(Number(id))!;
          return mapFieldToSelectItem(kpi);
        })
      );
    },
    [kpiMap, setSelectedStatistics]
  );

  return { handleDelete, handleReorder, selectedStatistics };
}

export function mapFieldToSelectItem(field: IField<unknown>): IKpiSelectItem {
  return {
    id: field.id,
    value: field.displayName,
    group: field.gridMeta?.group,
  };
}
