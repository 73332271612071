import * as yup from 'yup';
import { merge } from 'lodash-es';
import { EntityType } from '../data-models/field2.data-model';
import { BaseModelWithID, baseModelWithIdDataModel } from './BaseModel.schema';

export const NewCommentaryId = -123;

export function commentaryFields() {
  return {
    date: yup.date().nullable().required().default(null),
    entity: yup.string().oneOf(Object.keys(EntityType)).nullable().required().default(''),
    entityId: yup.number().nullable().required().default(null),
    text: yup.string().nullable().required().default('').default(undefined),
    type: yup.string(),
    createdAt: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    updatedAt: yup.string().nullable(),
  };
}

export function commentaryFormSchema() {
  return yup.object().shape(commentaryFields());
}

export function commentaryDataModel() {
  return yup.object().shape({
    ...commentaryFields(),
    date: yup.string().nullable().required().default(null),
  });
}

export type Commentary = yup.InferType<ReturnType<typeof commentaryDataModel>> & BaseModelWithID;

export function createEmptyCommentary(overrides: Partial<Commentary> = {}): Commentary {
  return merge(
    commentaryDataModel().getDefault(),
    baseModelWithIdDataModel().getDefault(),
    {
      id: NewCommentaryId,
    },
    overrides
  ) as Commentary;
}

export function commentaryAuditFields() {
  const { date, text } = commentaryFields();
  return {
    commentaryId: yup.number().nullable().required().default(null),
    date,
    text,
  };
}

export function commentaryAuditDataModel() {
  return yup.object().shape({
    ...commentaryAuditFields(),
    date: yup.string().nullable().required().default(null),
  });
}

export type CommentaryAudit = yup.InferType<ReturnType<typeof commentaryAuditDataModel>> & BaseModelWithID;

export function createEmptyCommentaryAudit(overrides: Partial<CommentaryAudit> = {}): CommentaryAudit {
  return merge(commentaryAuditDataModel().getDefault(), baseModelWithIdDataModel().getDefault(), overrides);
}
