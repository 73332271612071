import { styled } from '@mui/material/styles';
import { useAtom, useAtomValue } from 'jotai';
import { useRecoilValue } from 'recoil';
import { PopupMenu } from '../../../../../components/PopupMenu/PopupMenu';
import { kpiConfigAtom, kpiGroupsByIdAtom } from '../../../../../services/state/KPIConfigState';
import { SelectItem } from '../../../../../types';
import { IKpiSelectItem, selectedKPIMenuItemsAtom } from '../../../state/ProfileViewSettings';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { sortGroupableOptions } from '../../../utils/sortGroupableOptions';

const Wrapper2 = styled('div')`
  display: flex;
  position: relative;
  margin-left: auto;
`;

export const AddStatistic = () => {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedStatistics, setSelectedStatistics] = useAtom(selectedKPIMenuItemsAtom(companyId));
  const KPOptions = useKPIOptions();

  const handleChange = (selected: SelectItem[]) => {
    setSelectedStatistics(selected as IKpiSelectItem[]);
  };

  return (
    <Wrapper2>
      <PopupMenu
        items={KPOptions}
        onChange={handleChange}
        selectedItems={selectedStatistics}
        label={'Select KPIs'}
        groupable
        searchable
        searchPlaceholder='Search for KPI name'
      />
    </Wrapper2>
  );
};

function useKPIOptions() {
  const config = useAtomValue(kpiConfigAtom);
  const kpiGroupsById = useAtomValue(kpiGroupsByIdAtom);

  return sortGroupableOptions(
    config.map((ifield) => {
      return {
        id: ifield.id,
        value: ifield.displayName,
        // FIXME MAGGIE-6435: Remove once migration to groupId complete.
        group: kpiGroupsById.get(ifield?.kpiGroupId ?? -1)?.name ?? ifield.gridMeta?.group,
      };
    })
  ) as IKpiSelectItem[];
}
