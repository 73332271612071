import { CSSProperties, FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { stringToDate } from '../../../Finance/utils/dateParser';

import { ITransactionDataModel } from '../../../../data-models/transaction.data-model';
import { TransactionCard } from './TransactionCard';

export const PANEL_WIDTH_IN_PX = 300;

const Container = styled('div')`
  height: calc(100vh - 6rem); // 4rem is the height of the new sticky header
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-y: overlay;
  width: ${PANEL_WIDTH_IN_PX}px;
  background-color: ${({ theme }) => theme.colors.primary[0]};
  padding: 1.5rem 1rem 1.25rem 1rem;
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
`;

const TransactionsList = styled('div')`
  margin-top: 24px;
`;

const TRANSACTION = 'transaction';

interface ICompanyTransactionsPanelProps {
  transactions: ITransactionDataModel[] | undefined;
  style?: CSSProperties;
}

export const CompanyTransactionsPanel: FC<ICompanyTransactionsPanelProps> = ({ transactions, style }) => {
  const { primary } = useTheme().colors;

  const [search, setSearch] = useSearchParams();
  const transactionIdQuery = search.get(TRANSACTION);

  const transactionsSortedByDateDesc = useMemo(
    () =>
      !transactions
        ? null
        : [...transactions].sort(
            (t1, t2) =>
              stringToDate(t2.transactionDate).getTime() - stringToDate(t1.transactionDate).getTime()
          ),
    [transactions]
  );

  const transactionIdsToOrderMap: Record<number, number> | null = useMemo(
    () =>
      !transactionsSortedByDateDesc
        ? null
        : transactionsSortedByDateDesc.reduce(
            (map, transaction, index) => ({
              ...map,
              [transaction.id]: transactionsSortedByDateDesc.length - index,
            }),
            {} as Record<number, number>
          ),
    [transactionsSortedByDateDesc]
  );

  const onSelectTransaction = (transactionId: number) => {
    search.set(TRANSACTION, transactionId.toString());
    setSearch(search);
  };

  const firstItemIdInList = useMemo(() => {
    return transactionsSortedByDateDesc?.[0]?.id;
  }, [transactionsSortedByDateDesc]);

  const activeTransactionId = useMemo(() => {
    return transactionIdQuery ? transactionIdQuery : firstItemIdInList;
  }, [firstItemIdInList, transactionIdQuery]);

  useEffect(() => {
    const transactionId = firstItemIdInList?.toString();
    if (!transactionIdQuery && transactionId) {
      setSearch({ [TRANSACTION]: transactionId });
    }
  }, [setSearch, transactionIdQuery, firstItemIdInList]);

  return (
    <Container style={style}>
      <Typography variant='subtitle1' color={primary[100]}>
        Transaction List
      </Typography>
      <TransactionsList>
        {transactionsSortedByDateDesc?.map((transaction) => {
          return (
            <TransactionCard
              key={transaction.id}
              transaction={transaction}
              order={transactionIdsToOrderMap?.[transaction?.id]}
              onSelectTransaction={onSelectTransaction}
              isActive={String(activeTransactionId) === String(transaction.id)}
            />
          );
        })}
      </TransactionsList>
    </Container>
  );
};
