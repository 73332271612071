import { useState } from 'react';
import { Stack } from '@mui/material';
import { SearchInput } from '../../../../CoreComponents/base/SearchInput';
import { ICaptableDataModel } from '../../../../data-models/captable2.data-model';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { CTInvestmentDetails } from '../InvestmentDetails/CTInvestmentDetails';
import { CaptableSummaryGrid, StakeholderType } from './CaptableSummaryGrid';
import { CaptableActionsMenu } from './CaptableActionsMenu';
import { InvestmentsFilter } from './InvestmentsFilter';

export function CaptableSummary({ data }: { data: ICaptableDataModel }) {
  const [quickFilterText, setQuickFilterText] = useState('');
  const canEditCaptable = PermissionService.get().hasPermission(PermissionKey.canEditTransaction);
  const [investmentFilter, setInvestmentFilter] = useState<StakeholderType>();

  return (
    <>
      <Stack
        display={'grid'}
        gridTemplateColumns={'1fr auto'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <StakeholderFilter onChange={setQuickFilterText} />
        <Stack direction={'row'} gap={'0.5rem'} alignItems={'center'}>
          <InvestmentsFilter value={investmentFilter} onChange={setInvestmentFilter} />
          {canEditCaptable && <CaptableActionsMenu />}
        </Stack>
      </Stack>
      <CaptableSummaryGrid
        data={data}
        quickFilterText={quickFilterText}
        investmentFilter={investmentFilter}
      />
      <CTInvestmentDetails data={data} />
    </>
  );
}

export function StakeholderFilter({ onChange }: { onChange: (value: string) => void }) {
  return (
    <SearchInput
      onChange={onChange}
      style={{ maxWidth: '18rem' }}
      placeholder='Search for a stakeholder name'
    />
  );
}
