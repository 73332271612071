import { memo, useCallback, useMemo } from 'react';
import { useFormContext, FieldPath } from 'react-hook-form';
import { get } from 'lodash-es';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { ICaptableDataModel } from '../../../../../data-models/captable2.data-model';
import { partialSalesFields } from '../../forms/scenarioFields';
import { useCalculatedField } from '../../../../../components/Form/rhf-utils/useCalculatedField';
import { PartialSaleType } from './PartialSaleToggle';
import { getScenarioCaptableData } from './scenario-schema-utils';
import { IPartialScenario } from './PartialSaleFields';
import { useClearValueOnChange } from './partialBreakdownUtils';

interface IPartialBreakdownElemProps {
  captable: ICaptableDataModel;
  transactionIndex: number;
  breakdownIndex: number;
  type: PartialSaleType;
}
export const PartialBreakdownElem = memo(function PartialBreakdownElem({
  captable,
  transactionIndex,
  breakdownIndex,
  type,
}: IPartialBreakdownElemProps) {
  const { fundOptionsByShareClassId, shareClassIdToClientInvestmentsByFund, shareClassesById } =
    getScenarioCaptableData(captable);

  const methods = useFormContext<IPartialScenario>();
  const { watch } = methods;

  const fieldPath =
    `scenarioTransactions.${transactionIndex}.partialExitBreakdown.${breakdownIndex}` as const;

  const maxShares: number | null = useMemo(() => {
    const captableInvestmentId = watch(fieldPath)?.captableInvestmentId;
    const fundId = watch(fieldPath)?.fundId;

    if (!captableInvestmentId) {
      return null;
    }

    if (type === PartialSaleType.aggregated || !fundId) {
      return shareClassesById.get(captableInvestmentId ?? -1)?.clientFullyDilutedShares ?? null;
    } else {
      return (
        shareClassIdToClientInvestmentsByFund.get(captableInvestmentId)?.get(fundId!)?.fullyDilutedShares ??
        null
      );
    }
  }, [fieldPath, shareClassIdToClientInvestmentsByFund, shareClassesById, type, watch]);

  const calculatePercentage = useCallback(
    (vals?: Partial<IPartialScenario>) => {
      const noOfShares = get(vals, `${fieldPath}.numberOfShares`) ?? 0;
      if (typeof noOfShares !== 'number' || !maxShares) {
        return null;
      }
      return (noOfShares / maxShares) * 100;
    },
    [fieldPath, maxShares]
  );
  const calculateShares = useCallback(
    (vals?: Partial<IPartialScenario>) => {
      const percentage = get(vals, `${fieldPath}.numberOfSharesPercentage`) ?? 0;
      if (typeof percentage !== 'number' || !maxShares) {
        return null;
      }
      return (percentage / 100) * maxShares;
    },
    [fieldPath, maxShares]
  );

  const resetCount = useClearValueOnChange({
    triggerFieldPaths: new Set<FieldPath<IPartialScenario>>([
      `${fieldPath}.captableInvestmentId` as const,
      `${fieldPath}.fundId` as const,
    ]),
    subscriptionFieldPaths: new Set<FieldPath<IPartialScenario>>([
      `${fieldPath}.numberOfShares`,
      `${fieldPath}.numberOfSharesPercentage`,
    ]),
    methods,
  });

  const rerender1 = useCalculatedField({
    methods,
    fieldPath: `${fieldPath}.numberOfShares`,
    calcFunc: calculateShares,
    deps: new Set([`${fieldPath}.numberOfSharesPercentage`]),
  });

  const rerender2 = useCalculatedField({
    methods,
    fieldPath: `${fieldPath}.numberOfSharesPercentage`,
    calcFunc: calculatePercentage,
    deps: new Set([`${fieldPath}.numberOfShares`]),
  });

  const { captableInvestmentId, numberOfShares, numberOfSharesPercentage, fundId } = partialSalesFields({
    captable,
    transactionIndex,
    breakdownIndex,
    fundOptionsByShareClassId,
    captableInvestmentId: watch(fieldPath)?.captableInvestmentId,
  });

  return (
    <>
      <FormFieldWithLabelFactory formField={captableInvestmentId} />
      {type === PartialSaleType.byFund && <FormFieldWithLabelFactory formField={fundId} />}
      <FormFieldWithLabelFactory
        formField={numberOfShares}
        key={`numberOfShares-${resetCount}-${rerender1}`}
      />
      <FormFieldWithLabelFactory
        formField={numberOfSharesPercentage}
        key={`numberOfSharesPercentage-${resetCount}-${rerender2}`}
      />
    </>
  );
});
