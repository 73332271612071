import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { css } from '@emotion/react';
import { gradients } from '../../theme/gradients';
import { authenticatedUserState } from '../../services/state/AuthState';

const bg = css`
  width: 100%;
  height: 100%;
  background: ${gradients.primary};
  background-size: cover;
  display: grid;
  justify-content: center;
  padding-top: 5rem;
`;

export function LoginSuccess() {
  const user = useRecoilValue(authenticatedUserState);

  useEffect(() => {
    if (!user) return;

    let excelRedirectURL = `https://excel.${window.location.host}/authenticate/authenticate.html?fdtkn=${user.accessToken}`;

    if (process.env.NODE_ENV === 'development') {
      excelRedirectURL = `https://localhost:3000/authenticate/authenticate.html?fdtkn=${user.accessToken}`;
    }

    window.location.href = excelRedirectURL;
  }, [user]);

  return (
    <Typography component='section' css={bg} color='text.primary'>
      {user && (
        <div>
          <Typography variant='h1'>Login Successful</Typography>
        </div>
      )}
    </Typography>
  );
}
