import { User } from '@frontegg/redux-store/auth/interfaces';
import { PermissionKey, RoleKey } from './PermissionAndRolesKeys';

export class PermissionService {
  static #instance: PermissionService | undefined;

  readonly #permissions = new Set<string>();
  readonly #roles = new Set<string>();
  #user!: User;

  #init(user: User) {
    this.#user = user;

    this.#permissions.clear();
    user.permissions.forEach((permission) => {
      this.#permissions.add(permission.key);
    });

    this.#roles.clear();
    user.roles.forEach((role) => {
      this.#roles.add(role.key);
    });
  }

  hasPermission(permissionKey: PermissionKey | string) {
    return this.#permissions.has(permissionKey) || this.#hasTopPermission(permissionKey);
  }

  hasRole(roleKey: RoleKey | string) {
    return this.#roles.has(roleKey);
  }

  #hasTopPermission(permissionKey: string): boolean {
    const permParts = permissionKey.split('.');
    let current = '';

    return permParts.some((perm, index) => {
      current = index === 0 ? perm : `${current}.${perm}`;
      return this.#permissions.has(`${current}.*`);
    });
  }

  static initService(user: User) {
    if (!PermissionService.#instance) {
      PermissionService.#instance = new PermissionService();
    }
    PermissionService.#instance!.#init(user);
  }

  static isInitialized() {
    return !!PermissionService.#instance;
  }

  static get() {
    if (!PermissionService.#instance) {
      throw new Error(`Service has not been initialized`);
    }
    return PermissionService.#instance;
  }

  static destroyService() {
    PermissionService.#instance = undefined;
  }
}
