import { FC } from 'react';
import { Stack, StackProps, SxProps, Typography, TypographyProps } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { IFinancialsRowData2 } from '../hooks/useFinancialsGridData';

import { IValuesBySection } from '../hooks/useFinancialsColumnDefs';
import { FormatterService } from '../../../../../util/formatter-service';
import { Indicator } from '../../../../../components/Indicator/Indicator';
import { colors } from '../../../../../theme/colors';
import { getRowCount } from '../hooks/useFinancialsGridRowHeight';
import { KpiConfigPeriod } from '../../../../../data-models/company-financials.data-model';
import { PinnedYearColumnTag } from '../pinnedYears/usePinnedYearColDefs';
import { IFinancialsDisplayOptions } from './FinancialsSettingsState';

function InnerRow({ children, sx, ...rest }: TypographyProps) {
  return (
    <Typography {...rest} px={'1rem'} height='100%' marginBlock={'auto'} sx={{ ...sx, lineHeight: 'unset' }}>
      {children}
    </Typography>
  );
}
const highlightedRow: SxProps = {
  background: `${colors.neutral[10]}55`,
};
export interface IKpiValueRendererParams<TData, TValue>
  extends CustomCellRendererProps<TData, TValue>,
    IFinancialsDisplayOptions {}

export const KpiValueRenderer: FC<IKpiValueRendererParams<IFinancialsRowData2, IValuesBySection<unknown>>> = (
  params
) => {
  const { value: valuesBySection, showBudget, showGrowth, showLTM, showYTD } = params;
  if (!valuesBySection) return <></>;

  const nRows = getRowCount({ showBudget, showLTM, showYTD });

  const formatter = params.data ? FormatterService.get().getFormatterForField(params.data) : null;

  const formattedActual =
    formatter && valuesBySection.actual != null ? formatter(valuesBySection.actual) : '';

  const formattedBudget =
    formatter && valuesBySection.budget != null ? formatter(valuesBySection.budget) : '';

  const growth = valuesBySection.growth;
  const isPinnedYearColumn = params?.column?.getColId()?.includes(PinnedYearColumnTag);
  const formattedLtm =
    !isPinnedYearColumn && formatter && valuesBySection[KpiConfigPeriod.LTM] !== null
      ? formatter(valuesBySection[KpiConfigPeriod.LTM])
      : '';
  const formattedYtd =
    formatter && valuesBySection[KpiConfigPeriod.CYTD] !== null
      ? formatter(valuesBySection[KpiConfigPeriod.CYTD])
      : '';

  return (
    <ValuesBySectionContainer nRows={nRows}>
      <InnerRow
        display={'grid'}
        gridTemplateColumns={'repeat(2, minMax(0, 1fr))'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {showGrowth && typeof growth === 'number' ? (
          <Indicator indicator={growth ?? 1} style={{ justifySelf: 'start', lineHeight: '1.5rem' }} />
        ) : (
          <span />
        )}
        <Typography variant='monotype'>{formattedActual}</Typography>
      </InnerRow>

      {showBudget && (
        <InnerRow variant='monotype' color='text.secondary'>
          {formattedBudget}
        </InnerRow>
      )}

      {showLTM && (
        <InnerRow variant='monotype' color='text.secondary' sx={highlightedRow} className='highlightedRow'>
          {formattedLtm}
        </InnerRow>
      )}

      {showYTD && (
        <InnerRow variant='monotype' color='text.secondary' sx={highlightedRow}>
          {formattedYtd}
        </InnerRow>
      )}
    </ValuesBySectionContainer>
  );
};

export const KpiNameCellRenderer: FC<IKpiValueRendererParams<IFinancialsRowData2, string>> = (params) => {
  const { value, showBudget, showLTM, showYTD } = params;
  const nRows = getRowCount({ showBudget, showLTM, showYTD });

  if (!value) return <></>;
  return (
    <ValuesBySectionContainer
      justifyContent={'start'}
      justifyItems={'start'}
      alignItems={'center'}
      nRows={nRows}
      lineHeight={'100%'}
      height={'100%'}
    >
      <InnerRow variant='body2' noWrap>
        {value}
      </InnerRow>
      {showBudget && (
        <InnerRow variant='body2' color={'text.secondary'}>
          Budget
        </InnerRow>
      )}
      {showLTM && (
        <InnerRow variant='body2' color={'text.secondary'} sx={highlightedRow}>
          LTM
        </InnerRow>
      )}
      {showYTD && (
        <InnerRow variant='body2' color={'text.secondary'} sx={highlightedRow}>
          YTD
        </InnerRow>
      )}
    </ValuesBySectionContainer>
  );
};

interface IValuesBySectionContainerProps extends StackProps {
  nRows: number;
}
export const ValuesBySectionContainer: FC<IValuesBySectionContainerProps> = ({ children, nRows }) => {
  return (
    <Stack
      display='grid'
      gridTemplateRows={`repeat(${nRows}, minmax(0, 1fr))`}
      justifyContent={'stretch'}
      alignItems={'center'}
      alignContent={'center'}
      height='100%'
      minWidth={'100%'}
    >
      {children}
    </Stack>
  );
};
