import { IFormatterDataModel } from '../../../data-models/formatter.data-model';
import { CalculationType, Fund, fundFormSchema } from '../../../schemas/Fund.schema';
import { FundMetrics, FundMetricsByFund, fundMetricsCalcsSchema } from '../../../schemas/FundMetrics.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { WaterfallGridData, waterfallGridDataSchema } from './FPWaterfallData';

type UiFieldKey =
  `${Tier}-${keyof Pick<WaterfallGridData, 'distributableProceeds' | 'lpDistributed' | 'gpDistributed' | 'totalDistributed'>}`;
type Tier = 'initial' | '0' | '1' | '2' | '3';
export type WfKey = keyof Fund | keyof FundMetrics | keyof FundMetricsByFund | UiFieldKey;
interface CalcData {
  inputs: Set<WfKey>; // for cell highlighting
  formula: string;
}
export interface WaterfallField extends Partial<CalcData> {
  entity: 'fund' | 'fundMetrics' | 'wfGridData';
  formatter?: string | IFormatterDataModel<unknown>;
  key: WfKey;
  label: string;
  value: unknown;
}

class WFG {
  #fund: Fund;
  #fundMetrics: FundMetrics;
  #wfGridData: Map<Tier, WaterfallGridData>;
  static #keyToFormatter = new Map<
    | Omit<WaterfallField['key'], UiFieldKey>
    | keyof Pick<WaterfallGridData, 'distributableProceeds' | 'gpDistributed' | 'lpDistributed'>,
    string | IFormatterDataModel<unknown>
  >([]);
  static #keyToLabel = new Map<
    | Omit<WaterfallField['key'], UiFieldKey>
    | keyof Pick<WaterfallGridData, 'distributableProceeds' | 'gpDistributed' | 'lpDistributed'>,
    string
  >([]);

  static fundInputs = new Set<keyof Fund>([
    'commitments',
    'enableGPCatchup',
    'enableSuperReturn',
    'gpCatchUpPercentage',
    'isProceedsPercentAdjusted',
    'lpCommitmentSplit',
    'lpGpSplit',
    'lpGpSplitThreshold',
    'superReturnSplit',
  ]);

  static fundMetricsInputs = new Set<keyof FundMetrics>([
    'contributions',
    'distributions',
    'escrowReceivable',
    'fmv',
    'gpContributions',
    'gpDistributions',
    'lpContributions',
    'lpDistributions',
    'lpNavFromLpGpSplit',
    'mostRecentContributedSecurities',
    'netAssets',
  ]);

  static fundMetricsCalcFields = new Set<keyof FundMetrics>([
    'amountAvailableForLpGpSplit',
    'lpGpSlitAmount',
    'maxGpCatchup',
  ]);

  static tier1Fields = new Set<WfKey>([
    '1-distributableProceeds',
    'amountAvailableForLpGpSplit',
    'lpGpSlitAmount',
    '1-lpDistributed',
    '1-gpDistributed',
    '1-totalDistributed',
  ]);

  static tier2Fields = new Set<WfKey>([
    '2-distributableProceeds',
    'maxGpCatchup',
    '2-gpDistributed',
    '2-totalDistributed',
  ]);

  static tier3Fields = new Set<WfKey>([
    '3-distributableProceeds',
    '3-lpDistributed',
    '3-gpDistributed',
    '3-totalDistributed',
  ]);

  #fields: Map<WfKey, WaterfallField>;
  constructor(fund: Fund, fundMetrics: FundMetrics, wfGridData: WaterfallGridData[]) {
    this.#fund = { ...fund };
    this.#fundMetrics = { ...fundMetrics };
    this.#wfGridData = new Map<Tier, WaterfallGridData>();
    wfGridData.forEach((tierData) => {
      this.#wfGridData.set(WFG.#phaseToTier[tierData.phase!], { ...tierData });
    });

    [
      ...schemaToFormFields(fundFormSchema()),
      ...schemaToFormFields(fundMetricsCalcsSchema()),
      ...schemaToFormFields(waterfallGridDataSchema()),
    ].forEach((field) => {
      WFG.#keyToLabel.set(field.key as WaterfallField['key'], field.label!);
      WFG.#keyToFormatter.set(field.key as WaterfallField['key'], field.formatter ?? 'string');
    });

    this.#fields = new Map<WfKey, WaterfallField>();
    WFG.fundInputs.forEach((key) => {
      this.#fields.set(key, this.#keyToWaterfallField('fund', key));
    });
    [...WFG.fundMetricsInputs, ...WFG.fundMetricsCalcFields].forEach((key) => {
      this.#fields.set(key, this.#keyToWaterfallField('fundMetrics', key));
    });
    (['initial', '0', '1', '2', '3'] as Tier[]).forEach((tier) => {
      (['distributableProceeds', 'lpDistributed', 'gpDistributed', 'totalDistributed'] as const).forEach(
        (key) => {
          this.#fields.set(
            `${tier}-${key}` as WfKey,
            this.#keyToWaterfallField('wfGridData', `${tier}-${key}`)
          );
        }
      );
    });

    this.#setInputCalcs();
    this.#setInitialTierCalcs();
    this.#setTier0Calcs();
    this.#setTier1Calcs();
    this.#setTier2Calcs();
    this.#setTier3Calcs();
  }

  getFundInputs() {
    return Array.from(this.#fields.values()).filter((field) => WFG.fundInputs.has(field.key as keyof Fund));
  }
  getFundMetricsInputs() {
    return Array.from(this.#fields.values()).filter((field) =>
      WFG.fundMetricsInputs.has(field.key as keyof FundMetrics)
    );
  }
  getInitialTierFields() {
    return [this.#fields.get('initial-distributableProceeds')!];
  }
  getTier0Fields() {
    return Array.from(this.#fields.values()).filter((field) => field.key === '0-lpDistributed');
  }
  getTier1Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier1Fields.has(field.key as WfKey));
  }
  getTier2Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier2Fields.has(field.key as WfKey));
  }
  getTier3Fields() {
    return Array.from(this.#fields.values()).filter((field) => WFG.tier3Fields.has(field.key as WfKey));
  }

  #setInputCalcs() {
    this.#fields.set('contributions', {
      ...this.#fields.get('contributions')!,
      inputs: new Set(['lpContributions', 'gpContributions']),
      formula: `${WFG.#keyToLabel.get('lpContributions')} + ${WFG.#keyToLabel.get('gpContributions')}`,
    });
    this.#fields.set('distributions', {
      ...this.#fields.get('distributions')!,
      inputs: new Set(['lpDistributions', 'gpDistributions']),
      formula: `${WFG.#keyToLabel.get('lpDistributions')} + ${WFG.#keyToLabel.get('gpDistributions')}`,
    });
  }
  #setInitialTierCalcs() {
    const distributionsField =
      this.#fund.calculationType === CalculationType.lpOnly ? 'lpDistributions' : 'distributions';
    const inputs = new Set<WfKey>([
      'calculationType',
      'escrowReceivable',
      'fmv',
      'mostRecentContributedSecurities',
      'netAssets',
      distributionsField,
    ]);
    if (this.#fund.isProceedsPercentAdjusted) inputs.add('lpCommitmentSplit');

    const nonAdjusted = `${WFG.#keyToLabel.get('fmv')} + ${WFG.#keyToLabel.get(distributionsField)} + ${WFG.#keyToLabel.get('netAssets')} + ${WFG.#keyToLabel.get('escrowReceivable')} - ${WFG.#keyToLabel.get('mostRecentContributedSecurities')}`;
    const adjusted = `(${nonAdjusted}) * ${WFG.#keyToLabel.get('lpCommitmentSplit')}`;

    this.#fields.set('initial-distributableProceeds', {
      ...this.#fields.get('initial-distributableProceeds')!,
      inputs,
      formula: this.#fund.isProceedsPercentAdjusted ? adjusted : nonAdjusted,
    });
  }
  #setTier0Calcs() {
    const contributionsField =
      this.#fund.calculationType === CalculationType.combined ? 'contributions' : 'lpContributions';
    this.#fields.set('0-lpDistributed', {
      ...this.#fields.get('0-lpDistributed')!,
      inputs: new Set<WfKey>([contributionsField]),
      formula: `${WFG.#keyToLabel.get(contributionsField)}`,
    });
    this.#fields.set('0-distributableProceeds', {
      ...this.#fields.get('0-distributableProceeds')!,
      inputs: new Set<WfKey>(['initial-distributableProceeds']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} (initial)`,
    });
  }
  #setTier1Calcs() {
    this.#fields.set('1-distributableProceeds', {
      ...this.#fields.get('1-distributableProceeds')!,
      inputs: new Set(['initial-distributableProceeds', '0-lpDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('lpDistributed')}`,
    });
    this.#fields.set('amountAvailableForLpGpSplit', {
      ...this.#fields.get('amountAvailableForLpGpSplit')!,
      inputs: new Set([
        'commitments',
        'lpGpSplitThreshold',
        'lpContributions',
        'lpCommitmentSplit',
        'lpGpSplit',
      ]),
      formula: `((${WFG.#keyToLabel.get('commitments')} * ${WFG.#keyToLabel.get('lpGpSplitThreshold')}) - ${WFG.#keyToLabel.get('lpContributions')}) / (${WFG.#keyToLabel.get('lpGpSplit')} *  ${WFG.#keyToLabel.get('lpCommitmentSplit')})`,
    });
    this.#fields.set('lpGpSlitAmount', {
      ...this.#fields.get('lpGpSlitAmount')!,
      inputs: new Set(['amountAvailableForLpGpSplit', '1-distributableProceeds']),
      formula: `MIN(${WFG.#keyToLabel.get('amountAvailableForLpGpSplit')}, ${WFG.#keyToLabel.get('distributableProceeds')})`,
    });
    this.#fields.set('1-lpDistributed', {
      ...this.#fields.get('1-lpDistributed')!,
      inputs: new Set(['lpGpSlitAmount', 'lpGpSplit']),
      formula: `${WFG.#keyToLabel.get('lpGpSlitAmount')} * ${WFG.#keyToLabel.get('lpGpSplit')}}`,
    });
    this.#fields.set('1-gpDistributed', {
      ...this.#fields.get('1-gpDistributed')!,
      inputs: new Set(['lpGpSlitAmount', 'lpGpSplit']),
      formula: `${WFG.#keyToLabel.get('lpGpSlitAmount')} * (1 - ${WFG.#keyToLabel.get('lpGpSplit')})`,
    });
    this.#fields.set('1-totalDistributed', {
      ...this.#fields.get('1-totalDistributed')!,
      inputs: new Set(['1-lpDistributed', '1-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('lpDistributed')} + ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }
  #setTier2Calcs() {
    this.#fields.set('2-distributableProceeds', {
      ...this.#fields.get('2-distributableProceeds')!,
      inputs: new Set(['1-distributableProceeds', '1-totalDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('totalDistributed')}`,
    });
    this.#fields.set('maxGpCatchup', {
      ...this.#fields.get('maxGpCatchup')!,
      inputs: new Set(['lpNavFromLpGpSplit', 'gpCatchUpPercentage', '1-gpDistributed']),
      formula: `(${WFG.#keyToLabel.get('lpNavFromLpGpSplit')} * ${WFG.#keyToLabel.get('gpCatchUpPercentage')}) / (1 - ${WFG.#keyToLabel.get('gpCatchUpPercentage')}) - ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
    this.#fields.set('2-gpDistributed', {
      ...this.#fields.get('2-gpDistributed')!,
      inputs: new Set(['maxGpCatchup', '2-distributableProceeds']),
      formula: `MIN(${WFG.#keyToLabel.get('maxGpCatchup')}, ${WFG.#keyToLabel.get('distributableProceeds')})`,
    });
    this.#fields.set('2-totalDistributed', {
      ...this.#fields.get('2-totalDistributed')!,
      inputs: new Set(['2-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }
  #setTier3Calcs() {
    this.#fields.set('3-distributableProceeds', {
      ...this.#fields.get('3-distributableProceeds')!,
      inputs: new Set(['2-distributableProceeds', '2-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} - ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
    this.#fields.set('3-lpDistributed', {
      ...this.#fields.get('3-lpDistributed')!,
      inputs: new Set(['3-distributableProceeds', 'superReturnSplit']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} * ${WFG.#keyToLabel.get('superReturnSplit')}`,
    });
    this.#fields.set('3-gpDistributed', {
      ...this.#fields.get('3-gpDistributed')!,
      inputs: new Set(['3-distributableProceeds', 'superReturnSplit']),
      formula: `${WFG.#keyToLabel.get('distributableProceeds')} * (1 - ${WFG.#keyToLabel.get('superReturnSplit')})`,
    });
    this.#fields.set('3-totalDistributed', {
      ...this.#fields.get('3-totalDistributed')!,
      inputs: new Set(['3-lpDistributed', '3-gpDistributed']),
      formula: `${WFG.#keyToLabel.get('lpDistributed')} + ${WFG.#keyToLabel.get('gpDistributed')}`,
    });
  }

  #keyToWaterfallField(
    entity: 'fund' | 'fundMetrics' | 'wfGridData',
    key: keyof Fund | keyof FundMetrics | keyof FundMetricsByFund | WfKey
  ): WaterfallField {
    const tier = entity === 'wfGridData' ? (key.split('-').at(0) as Tier) : undefined;
    const _key = entity === 'wfGridData' ? key.split('-').at(1)! : key;
    let value;
    switch (entity) {
      case 'fund':
        value = this.#fund[key as keyof Fund];
        break;
      case 'fundMetrics':
        value = this.#fundMetrics[key as keyof FundMetrics];
        break;
      case 'wfGridData':
        value = this.#wfGridData.get(tier!)![_key as keyof WaterfallGridData];
        break;
    }
    return {
      entity,
      formatter: WFG.#keyToFormatter.get(_key) ?? 'string',
      key,
      label: WFG.#keyToLabel.get(_key)!,
      value,
    };
  }
  static #phaseToTier: Record<string, Tier> = {
    ['Initial']: 'initial',
    ['Tier 0']: '0',
    ['Tier 1']: '1',
    ['Tier 2']: '2',
    ['Tier 3']: '3',
  };
}
export const WaterfallFieldsGenerator = WFG;
