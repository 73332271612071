import * as htmlToImage from 'html-to-image';

export class ScreenshotGenerator {
  static async capture(element?: HTMLElement | null, name?: string): Promise<void> {
    if (!element) return;
    element.style.background = '#FBFBFD';

    const dataUrl = await htmlToImage.toPng(element, {
      cacheBust: true,
    });

    try {
      const link = document.createElement('a');
      link.download = `${name ?? self.crypto.randomUUID()}.png`;
      link.href = dataUrl;
      link.click();
    } catch (e) {
      throw new Error('Failed to generate image!');
    }
  }
}
