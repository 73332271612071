import { atom, atomFamily, selector } from 'recoil';
import { IIntegrationsAppDataModel } from '../../../data-models/integrations-app.data-model';
import {
  fetchAirtableEntities,
  fetchAllFDFields,
  fetchConnectedBases,
  fetchEstablishedSyncs,
  fetchIntegrations,
  fetchSyncColumns,
  fetchTablesForBase,
} from '../../../services/queries/MaggieIntegrationsQueries';
import {
  IAirTableBaseDataModel,
  IAirTableBasesResponseDataModel,
  IAirTableTablesResponseDataModel,
} from '../../../data-models/airtable-base.data-model';
import {
  IAirTableEntityDataModel,
  IForesightFieldDataModel,
} from '../../../data-models/airtable-entity.data-model';
import { getAllIndexColumnsForEntity } from '../Airtable/utils/syncUtils';
import {
  IBaseColumnDataModel,
  IEstablishedSyncDataModel,
  ISyncColumnsDataModel,
} from '../../../data-models/airtable-sync.data-model';
import { selectedEntityState, selectedTableState } from './ConfigurationState';

export const integrationsState = atom<IIntegrationsAppDataModel[] | null>({
  key: 'integrationsState',
  default: null,
  effects: [
    ({ trigger, setSelf }) => {
      if (trigger === 'get') {
        const integrations = fetchIntegrations();
        setSelf(integrations);
      }
    },
  ],
});

export const airTableBasesState = atom<IAirTableBasesResponseDataModel | null>({
  key: 'airTableBasesState',
  default: null,
  effects: [
    ({ trigger, setSelf }) => {
      if (trigger === 'get') {
        const bases = fetchConnectedBases();
        setSelf(bases);
      }
    },
  ],
});

export const airTableBasesMapState = selector<Map<string, IAirTableBaseDataModel> | null>({
  key: 'airTableBasesMapState',
  get: ({ get }) => {
    const bases = get(airTableBasesState)?.bases;
    const map = new Map<string, IAirTableBaseDataModel>();
    if (bases) {
      bases.forEach((base) => {
        map.set(base.id, base);
      });
    }
    return map;
  },
});

export const airTableTablesState = atomFamily<IAirTableTablesResponseDataModel | null, string>({
  key: 'airTableTablesState',
  default: null,
  effects: (baseId) => [
    ({ trigger, setSelf }) => {
      if (trigger === 'get') {
        setSelf(fetchTablesForBase(baseId));
      }
    },
  ],
});

export const airtableEntitiesState = atom<IAirTableEntityDataModel[] | null>({
  key: 'airtableEntitiesState',
  default: null,
  effects: [
    ({ trigger, setSelf }) => {
      if (trigger === 'get') {
        const entities = fetchAirtableEntities();
        setSelf(entities);
      }
    },
  ],
});

export const entityMapState = selector<Map<string, IAirTableEntityDataModel>>({
  key: 'entityMapState',
  get: ({ get }) => {
    const entities = get(airtableEntitiesState);
    const map = new Map<string, IAirTableEntityDataModel>();
    if (entities) {
      entities.forEach((entity) => {
        map.set(entity.entityValue, entity);
      });
    }
    return map;
  },
});

// each string element has entityValue,columnValue format
export const identifierFDColumnsState = selector<Set<string>>({
  key: 'identifierFDColumnsState',
  get: ({ get }) => {
    const identifierColsSet = new Set<string>();
    const entities = get(airtableEntitiesState);
    if (entities) {
      entities.forEach((entity) => {
        entity.uniqueColumns.forEach((uniqueColumn) => {
          if (uniqueColumn.isSynced) {
            uniqueColumn.columns.forEach((column) => {
              identifierColsSet.add(`${entity.entityValue},${column.value}`);
            });
          }
        });
      });
    }
    return identifierColsSet;
  },
});

export const identifierFDColumnsForSelectedEntityState = selector<Set<string>>({
  key: 'indetifierFDColumnsForSelectedEntityState',
  get: ({ get }) => {
    const identifierColsSet = get(identifierFDColumnsState);
    const selectedEntity = get(selectedEntityState)?.entityValue;
    if (!identifierColsSet || !selectedEntity) return new Set();
    return new Set(getAllIndexColumnsForEntity(identifierColsSet, selectedEntity));
  },
});

/** columns for selected entity, base and table */
export const syncColumnsState = selector<ISyncColumnsDataModel | null>({
  key: 'syncColumnsState',
  get: async ({ get }) => {
    const baseId = get(selectedTableState)?.baseId;
    const tableId = get(selectedTableState)?.id;
    const entity = get(selectedEntityState)?.entityValue;
    if (!baseId || !tableId || !entity) {
      return null;
    }

    const syncedColumns = await fetchSyncColumns({ baseId, tableId, entity });
    return syncedColumns;
  },
});

/** columns map for selected entity, base and table */
export const foresightColumnsMapState = selector<Map<string, IBaseColumnDataModel>>({
  key: 'foresightColumnsMapState',
  get: ({ get }) => {
    const foresightColumns = get(syncColumnsState)?.foresightColumns;
    const map = new Map<string, IBaseColumnDataModel>();
    if (foresightColumns) {
      foresightColumns.forEach((column) => {
        map.set(column.value, column);
      });
    }
    return map;
  },
});

export const airtableColumnsMapState = selector<Map<string, IBaseColumnDataModel>>({
  key: 'airtableColumnsMapState',
  get: ({ get }) => {
    const airtableColumns = get(syncColumnsState)?.airtableColumns;
    const map = new Map<string, IBaseColumnDataModel>();
    if (airtableColumns) {
      airtableColumns.forEach((column) => {
        map.set(column.value, column);
      });
    }
    return map;
  },
});

export const establishedSyncsState = atom<IEstablishedSyncDataModel[]>({
  key: 'establishedSyncsState',
  default: selector({
    key: 'establishedSyncsStateDefault',
    get: () => {
      return fetchEstablishedSyncs();
    },
  }),
});

export const allForesightFieldsState = atom<IForesightFieldDataModel[]>({
  key: 'allForesightFieldsState',
  default: selector({
    key: 'allForesightFieldsStateDefault',
    get: () => {
      return fetchAllFDFields();
    },
  }),
});

export const allFDFieldsMapState = selector<Map<string | number, IForesightFieldDataModel>>({
  key: 'allFDFieldsMapState',
  get: ({ get }) => {
    const allSyncColumns = get(allForesightFieldsState);
    const map = new Map<string, IForesightFieldDataModel>();
    if (allSyncColumns) {
      allSyncColumns.forEach((column) => {
        // for custom fields, the id is used as the entityColumn value for sync
        // for other fields, the entityField is used as the entityColumn value for sync
        if (column.id) map.set(String(column.id), column);
        else map.set(column.entityField, column);
      });
    }
    return map;
  },
});
