import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import { FieldEntity, GridRef } from '../../../data-models/field2.data-model';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';
import { RendererType } from '../../../data-models/field.data-model';
import { currencyMapByCodeState } from '../../../services/state/AppConfigState';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { CustomFieldFormType, CustomFieldSchema } from './CustomField.schema';

export function useCustomFieldsStep1Fields() {
  const currencyMapByCode = useRecoilValue(currencyMapByCodeState);
  const { control } = useFormContext<CustomFieldFormType>();
  const rendererType = useWatch({ name: '_viewModel.rendererType', control });
  const id = useWatch({ name: 'id', control });
  const editMode = isEditMode({ id });
  const { showSecurityView } = useFlags<MaggieFeatureFlags>();

  const schemaToFormFields = useSchemaToFormFields();
  const baseFields = useMemo(() => {
    const fields = schemaToFormFields(CustomFieldSchema.formSchema(showSecurityView), [
      'displayName',
      'description',
      '_viewModel.rendererType',
      'entity',
    ]);
    return fields;
  }, [schemaToFormFields, showSecurityView]);

  const currencyCodeField = useMemo(() => {
    const baseField = schemaToFormFields(CustomFieldSchema.formSchema(), ['_viewModel.currencyConfig']).at(
      0
    )!;

    return {
      ...baseField,
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: [...currencyMapByCode.keys()].map((code) => ({
          displayName: code,
          value: code,
        })),
      },
      required: true,
    };
  }, [currencyMapByCode, schemaToFormFields]);

  const conditionalFields = useMemo(() => {
    if (rendererType === RendererType.multiSelect || rendererType === RendererType.singleSelect) {
      return baseFields.toSpliced(3, 0, {
        ...schemaToFormFields(CustomFieldSchema.formSchema(), ['_viewModel.selectConfigValues']).at(0)!,
        required: true,
      });
    } else if (rendererType === RendererType.currency) {
      return baseFields.toSpliced(3, 0, currencyCodeField);
    } else {
      return baseFields;
    }
  }, [baseFields, currencyCodeField, rendererType, schemaToFormFields]);

  return useMemo(
    () =>
      conditionalFields.map((field) => {
        if (editMode && disabledForEdit.has(field.key as FieldPath<CustomFieldFormType>)) {
          return { ...field, disabled: true };
        } else {
          return field;
        }
      }),
    [conditionalFields, editMode]
  );
}

export function useCustomFieldsStep2Fields(entity: FieldEntity) {
  const getConditionalFields = useDisplayFields();
  const schemaToFormField = useSchemaToFormFields();

  return useMemo(() => {
    return schemaToFormField(CustomFieldSchema.formSchema(), getConditionalFields(entity as FieldEntity));
  }, [entity, getConditionalFields, schemaToFormField]);
}

export function useCustomFieldFormDynamicValues() {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name === '_viewModel.rendererType') {
        setValue('_viewModel.selectConfigValues', undefined);
        setValue('_viewModel.currencyConfig', undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);
}

function useDisplayFields() {
  const canViewDeals = PermissionService.get().hasPermission(PermissionKey.canViewDeal);

  return useCallback(
    (entity: FieldEntity) => {
      let result: FieldPath<CustomFieldFormType>[] = [];
      if (entity === FieldEntity.company) {
        result = [
          `_viewModel.addingTransaction`,
          `_viewModel.companyProfile`,

          `_viewModel.${GridRef.portfolioReporting}`,
          `_viewModel.${GridRef.roundTracker}`,
        ];
      } else if (entity === FieldEntity.fundCompany) {
        result = [`_viewModel.companyProfile`, `_viewModel.${GridRef.portfolioReporting}`];
      } else if (entity === FieldEntity.round) {
        result = [
          `_viewModel.addingRound`,
          `_viewModel.${GridRef.portfolioReporting}`,
          `_viewModel.${GridRef.roundTracker}`,
        ];
      } else if (entity === FieldEntity.captableInvestment) {
        result = [`_viewModel.addingCaptableSecurity`, `_viewModel.${GridRef.securityOverview}`];
      }

      if (canViewDeals && (entity === FieldEntity.company || entity === FieldEntity.deal)) {
        result.unshift(`_viewModel.addingDeal`);
      }
      return result;
    },
    [canViewDeals]
  );
}

export const disabledForEdit = new Set<FieldPath<CustomFieldFormType>>([`_viewModel.rendererType`, `entity`]);

export function isEditMode({ id }: { id?: number | null | undefined }) {
  return id != null;
}
