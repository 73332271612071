import { atom, selector } from 'recoil';
import { ICompanySearchResponseDataModel } from '../../../data-models/company-search-result.data-model';
import { IProviderConfigDataModel } from '../../../data-models/provider-config.data-model';
import { getProviderConfig } from '../../../services/queries/MappingsQueries';
import { companyState } from '../../../services/state/CompanyState';
import { companyToSearchResult } from '../../../util/companyUtils';
import { companySearchResultCache } from '../../CompanyComparison/state/CompanyComparisonUIState';

export const selectedMappingsCompaniesIds = atom<number[]>({
  key: 'SelectedCompaniesIdsStateMappings',
  default: [],
});

export const selectedMappingsCompanies = selector<ICompanySearchResponseDataModel[]>({
  key: 'SelectedCompaniesMappings',
  get: async ({ get }) => {
    const companyIds = get(selectedMappingsCompaniesIds);
    const allInCache = companyIds.every((companyId) => companySearchResultCache.has(companyId));

    if (allInCache) {
      return companyIds.map((companyId) => companySearchResultCache.get(companyId)!);
    }

    const selectedCompanies = companyIds.map((companyId) => get(companyState(companyId)));
    return selectedCompanies.map((company) => companyToSearchResult(company));
  },
});

export const providersConfigState = atom<IProviderConfigDataModel<unknown>[]>({
  key: 'providersState',
  default: [],
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const config = getProviderConfig();
        setSelf(config);
      }
    },
  ],
});

export const providerConfigByNameMapState = selector<Map<string, IProviderConfigDataModel<unknown>> | null>({
  key: 'providerConfigByNameMapState',
  get: ({ get }) => {
    const providersConfig = get(providersConfigState);
    return (
      providersConfig?.reduce((res, config) => {
        return res.set(config.provider, config);
      }, new Map<string, IProviderConfigDataModel<unknown>>()) ?? null
    );
  },
});

export const lastFieldUpdateDateState = atom<string | null>({
  key: 'lastFieldUpdateDateState',
  default: null,
});
