import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { FieldValues } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';
import { IForm } from '../../../view-models/form.view-model';
import { uuid } from '../../../util/uuid';
import { IItemWithId, SortableList } from '../../../components/SortableList/SortableList';
import { BasicDragHandle, SortableItem } from '../../../components/SortableList/SortableItem';
import { ConfigItemRow } from './ConfigItemRow';

const MainWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  padding: 1rem;
  width: 100%;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;

const Header = styled('div')`
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-bottom: 1rem;
`;

const Rows = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export interface IConfigPageProps<T extends FieldValues> {
  createBtnLabel: string;
  customToolbarElem?: React.JSX.Element;
  onCreate: () => Promise<void> | void;
  onDelete: (item: T) => Promise<void> | void;
  onEdit: (item: T) => Promise<void> | void;
  onReorder?: (items: T[]) => Promise<void> | void;
  forms: IForm<T>[];
  title: string;
}
export function ConfigPageContents<T extends FieldValues>(props: IConfigPageProps<T>) {
  const { createBtnLabel, onCreate, title } = props;

  return (
    <MainWrapper>
      <Header>
        <Typography variant={'h4'}>{title}</Typography>
        <ButtonsWrapper>
          {props.customToolbarElem}
          <Button color={'secondary'} onClick={onCreate} startIcon={<AddIcon />} variant={'contained'}>
            {createBtnLabel}
          </Button>
        </ButtonsWrapper>
      </Header>
      <ItemRows {...props} />
    </MainWrapper>
  );
}

function ItemRows<T extends FieldValues>(props: IConfigPageProps<T>) {
  const { forms, onDelete, onEdit, onReorder } = props;

  if (props.onReorder) {
    const formsWithId = forms.map((form) => {
      return {
        ...form,
        id: uuid(),
      };
    }) as (IForm<T> & IItemWithId)[];
    return (
      <SortableList
        items={formsWithId}
        onChange={(items) => {
          const reOrderedItems = items.map((formItem, index) => {
            const data = cloneDeep(formItem.data);
            return {
              ...data,
              sortOrder: index,
            };
          });

          onReorder?.(reOrderedItems as unknown as T[]);
        }}
        renderItem={(item) => (
          <SortableItem id={item.id}>
            <ConfigItemRow key={item.id} form={item} onDelete={onDelete} onEdit={onEdit} />
            <BasicDragHandle />
          </SortableItem>
        )}
      />
    );
  } else {
    return (
      <Rows>
        {forms.map((form, index) => {
          return <ConfigItemRow key={index} form={form} onDelete={onDelete} onEdit={onEdit} />;
        })}
      </Rows>
    );
  }
}
