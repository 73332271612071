import { styled } from '@mui/material/styles';
import { Button, useTheme } from '@mui/material';
import { useModalState } from '../../../../../Modal/ModalContext';
import { UploadCompanyListForm } from './UploadCompanyListForm';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const AddCompanyList = () => {
  const { secondary } = useTheme().colors;
  const { onOpenModal } = useModalState();

  const handleClick = () => onOpenModal(<UploadCompanyListForm />);

  return (
    <Wrapper>
      <Button variant='text' sx={{ color: secondary['20'] }} onClick={handleClick}>
        Add
      </Button>
    </Wrapper>
  );
};
