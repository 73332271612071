import { Fragment, useCallback, useMemo } from 'react';
import { useSetAtom } from 'jotai';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { merge } from 'lodash-es';
import { Collapse, Stack } from '@mui/material';
import { ObjectSchema } from 'yup';
import { fromFundViewModel, FundViewModel, fundViewModelSchema } from '../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { FormContainer } from '../../../components/Form/FormComponents';
import { fundFormCurrentStepState, fundFormState, showFundFormsState } from '../state/FPState';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { IFundFormProps } from './FundForm';

interface IFundFormStep2Props extends Pick<IFundFormProps, 'onSubmit'> {}

export function FundFormStep2({ onSubmit }: IFundFormStep2Props) {
  const [formData, setFormData] = useRecoilState(fundFormState);
  const setCurrentStep = useSetRecoilState(fundFormCurrentStepState);
  const setShowForm = useSetAtom(showFundFormsState);

  const methods = useForm<FundViewModel>({
    defaultValues: formData as FundViewModel,
    resolver: yupResolver(fundViewModelSchema() as ObjectSchema<FundViewModel>),
    mode: 'all',
  });

  const handleBack = useCallback(() => {
    setFormData((curr) => ({ ...curr, ...methods.getValues() }));
    setCurrentStep((curr) => curr - 1);
  }, [methods, setCurrentStep, setFormData]);

  const _onSubmit = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const payload = fromFundViewModel(merge({}, formData, methods.getValues()));
      await onSubmit(payload);
      setShowForm(null);
      return true;
    } else {
      return false;
    }
  }, [methods, formData, onSubmit, setShowForm]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <FundFormStep2Fields />
      </FormContainer>
      <StepperFormButtons
        stepIsValid={_onSubmit}
        stepState={fundFormCurrentStepState}
        handleGoBack={handleBack}
        incrementOnNext={false}
      />
    </FormProvider>
  );
}

function FundFormStep2Fields() {
  const tierToggles = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), [
      '_viewModel.tier1',
      '_viewModel.tier2',
      '_viewModel.tier3',
    ]);
  }, []);
  const tier1Fields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['lpGpSplit', 'lpGpSplitThreshold']);
  }, []);
  const [tier2Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['gpCatchUpPercentage']);
  }, []);
  const [tier3Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['superReturnSplit']);
  }, []);

  const { control } = useFormContext<FundViewModel>();
  const [tier1, tier2, tier3] = useWatch({
    name: ['_viewModel.tier1', '_viewModel.tier2', '_viewModel.tier3'],
    control,
  });

  return (
    <>
      {tierToggles.map((field) => {
        const open =
          field.key === '_viewModel.tier1' ? !!tier1 : field.key === '_viewModel.tier2' ? !!tier2 : !!tier3;
        return (
          <Fragment key={field.key}>
            <FormFieldWithLabelFactory key={field.key} formField={field} />
            <Collapse in={open}>
              <Stack gap='0.5rem' mt='-0.75rem'>
                {field.key === '_viewModel.tier1' &&
                  tier1Fields.map((field) => {
                    return <FormFieldWithLabelFactory key={field.key} formField={field} />;
                  })}
                {field.key === '_viewModel.tier2' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier2Field} />
                )}
                {field.key === '_viewModel.tier3' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier3Field} />
                )}
              </Stack>
            </Collapse>
          </Fragment>
        );
      })}
    </>
  );
}
