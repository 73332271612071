import { merge } from 'lodash-es';
import {
  ICaptableDataModel,
  ICaptableInvestmentDataModel,
  IStakeholderInvestmentDataModel,
  createCaptableDataModel,
  createCaptableInvestment,
} from '../data-models/captable2.data-model';

import {
  IInvestmentGridRow,
  IOtherInvestmentsGridRow,
} from '../pages/CompanyProfiles/CapTable/InvestmentGridDefs';
import { CommonShareClass, CommonShareClassSchema } from '../schemas/CommonShareClass.schema';
import { Note, NotesSchema } from '../schemas/Notes.schema';
import { Option, OptionsSchema } from '../schemas/Options.schema';
import { PreferredShareClass, PreferredShareClassSchema } from '../schemas/PreferredShareClass.schema';
import { Warrant, WarrantsSchema } from '../schemas/Warrants.schema';
import { ParticipationType } from '../data-models/scenario.data-model';
import { ViewModelInvestmentType } from '../schemas/common-schema-defs';
import { FDMap } from '../util/data-structure/FDMap';
import { CapTableInvestmentType, ShareClassType } from '../schemas/CaptableInvestment.schema';
import { SecurityDataModel } from '../schemas/Security.schema';

export const TypeToSchemaMap = {
  [ViewModelInvestmentType.common]: CommonShareClassSchema,
  [ViewModelInvestmentType.notes]: NotesSchema,
  [ViewModelInvestmentType.options]: OptionsSchema,
  [ViewModelInvestmentType.preferred]: PreferredShareClassSchema,
  [ViewModelInvestmentType.warrants]: WarrantsSchema,
};

export const InvestmentTypeMap: {
  [key in ViewModelInvestmentType]: CapTableInvestmentType;
} = {
  [ViewModelInvestmentType.common]: 'share-class',
  [ViewModelInvestmentType.notes]: 'note-block',
  [ViewModelInvestmentType.options]: 'option-pool',
  [ViewModelInvestmentType.preferred]: 'share-class',
  [ViewModelInvestmentType.warrants]: 'warrant-block',
};

export const InvestmentTypeToName: Record<CapTableInvestmentType, string> = {
  'share-class': 'Share Classes',
  'note-block': 'Notes',
  'option-pool': 'Options',
  'warrant-block': 'Warrants',
};

export interface ICaptableViewModel extends ICaptableDataModel {
  _viewModel: {
    shareClasses: ShareClass[];
    clientInvestments: IInvestmentGridRow[];
    otherInvestments: IOtherInvestmentsGridRow[];
  };
}
type CustomData = SecurityDataModel['customData'];
// TODO: rename to Investment or sth to make it less confusing
export type ShareClass = (CommonShareClass | PreferredShareClass | Note | Option | Warrant) & CustomData;

export function createCaptableViewModel(overrides: Partial<ICaptableViewModel> = {}): ICaptableViewModel {
  return merge(
    createCaptableDataModel(),
    {
      _viewModel: { shareClasses: [], clientInvestments: [], otherInvestments: [] },
    },
    { ...overrides }
  );
}

export function toCaptableDataModel(viewModel: ICaptableViewModel): ICaptableDataModel {
  const { _viewModel, ...dataModel } = viewModel;
  const captableInvestments = getCaptableInvestments(
    _viewModel.shareClasses,
    _viewModel.clientInvestments,
    _viewModel.otherInvestments,
    dataModel.currencyId
  );
  return {
    ...dataModel,
    captableInvestments,
  };
}

export function isShareClass(type: ViewModelInvestmentType): boolean {
  return type === ViewModelInvestmentType.common || type === ViewModelInvestmentType.preferred;
}

function getCaptableInvestments(
  shareClasses: ShareClass[],
  clientInvestments: IInvestmentGridRow[],
  otherInvestments: IOtherInvestmentsGridRow[],
  currencyId: number
): ICaptableInvestmentDataModel[] {
  return shareClasses.map((investment) => {
    const { type, ...rest } = investment;
    return merge(createCaptableInvestment(), {
      ...getCommonInvestmentFields(type),
      ...rest,
      ...getTypeSpecificFields(investment, clientInvestments, otherInvestments, currencyId),
    });
  });
}

function getCommonInvestmentFields(type: ViewModelInvestmentType): Partial<ICaptableInvestmentDataModel> {
  return {
    investmentType: InvestmentTypeMap[type],
    shareClassType: isShareClass(type) ? (type as ShareClassType) : undefined,
  };
}

export function getTypeSpecificFields(
  investment: ShareClass,
  clientInvestments: IInvestmentGridRow[],
  otherInvestments: IOtherInvestmentsGridRow[],
  currencyId: number
): Partial<ICaptableInvestmentDataModel> {
  const { type } = investment;

  const result: Partial<ICaptableInvestmentDataModel> = {};

  switch (type) {
    case ViewModelInvestmentType.common:
    case ViewModelInvestmentType.warrants: {
      result.stakeholderInvestments = getStakeholderData(
        investment,
        'fullyDilutedShares',
        clientInvestments,
        otherInvestments,
        currencyId
      );
      break;
    }
    case ViewModelInvestmentType.preferred: {
      const inv = investment as PreferredShareClass;
      result.participating = inv.participationType !== ParticipationType.none;
      result.stakeholderInvestments = getStakeholderData(
        investment,
        'fullyDilutedShares',
        clientInvestments,
        otherInvestments,
        currencyId
      );
      break;
    }

    case ViewModelInvestmentType.notes: {
      result.stakeholderInvestments = getStakeholderData(
        investment,
        'principal',
        clientInvestments,
        otherInvestments,
        currencyId
      );
      break;
    }

    case ViewModelInvestmentType.options: {
      result.stakeholderInvestments = getStakeholderData(
        investment,
        'outstandingEquityAwardDerivatives',
        clientInvestments,
        otherInvestments,
        currencyId
      ).map((stakeholderInvestment) => {
        return {
          ...stakeholderInvestment,
        };
      });
      break;
    }
  }

  return result;
}

export function getSumForInvestment(
  investment: ShareClass,
  clientInvestments: IInvestmentGridRow[],
  otherInvestments: IOtherInvestmentsGridRow[]
) {
  return [...clientInvestments, ...otherInvestments].reduce((sum, curr) => {
    const value = curr[getInvestmentKey(investment)];
    if (typeof value !== 'number') return sum;
    return sum + ((value as number) ?? 0);
  }, 0);
}

function getStakeholderData(
  investment: ShareClass,
  field: keyof IStakeholderInvestmentDataModel,
  clientInvestments: IInvestmentGridRow[],
  otherInvestments: IOtherInvestmentsGridRow[],
  currencyId: number
): IStakeholderInvestmentDataModel[] {
  return [...clientInvestments, ...otherInvestments]
    .filter((inv) => inv[getInvestmentKey(investment)] != null)
    .map((inv) => {
      return {
        [field]: inv[getInvestmentKey(investment)],
        fundId: (inv.fundId as number) ?? undefined,
        stakeholderName: (inv.investor as string) ?? undefined,
        name: investment.name,
        currencyId,
      };
    });
}

export function fromCaptableDataModel(dataModel: ICaptableDataModel): ICaptableViewModel {
  const { captableInvestments, ...rest } = dataModel;
  const { clientInvestments, otherInvestments } = getStakeholderInvestments(captableInvestments ?? []);
  return createCaptableViewModel({
    _viewModel: {
      shareClasses: getShareClassesFromInvestments(captableInvestments ?? []),
      clientInvestments,
      otherInvestments,
    },
    captableInvestments,
    ...rest,
  });
}

export function getShareClassesFromInvestments(investments: ICaptableInvestmentDataModel[]): ShareClass[] {
  return investments
    .filter((i) => Boolean(getShareClassType(i.investmentType, i.shareClassType)))
    .map(getShareClassFromInvestment);
}

export function getShareClassFromInvestment(investment: ICaptableInvestmentDataModel): ShareClass {
  const viewModelFields = getViewModelInvestmentFields(investment);
  const { type } = viewModelFields;

  // schema.cast will throw if any required field is missing
  try {
    return {
      ...(TypeToSchemaMap[type!].create().cast({ ...viewModelFields }) as ShareClass),
    };
  } catch (e) {
    console.error(e);
    return merge(
      {
        ...(TypeToSchemaMap[type!]?.create().getDefault() as ShareClass),
      },
      { ...viewModelFields }
    ) as ShareClass;
  }
}

export function getShareClassType(
  investmentType: ICaptableInvestmentDataModel['investmentType'],
  shareClassType?: ICaptableInvestmentDataModel['shareClassType']
): ViewModelInvestmentType | null {
  switch (investmentType) {
    case 'share-class':
      return shareClassType as ViewModelInvestmentType;
    case 'note-block':
      return ViewModelInvestmentType.notes;
    case 'option-pool':
      return ViewModelInvestmentType.options;
    case 'warrant-block':
      return ViewModelInvestmentType.warrants;
  }
  return null;
}

export function getStakeholderInvestments(investments: ICaptableInvestmentDataModel[]): {
  clientInvestments: IInvestmentGridRow[];
  otherInvestments: IOtherInvestmentsGridRow[];
} {
  const clientInvestmentsMap = new FDMap<number, IInvestmentGridRow>();
  const otherInvestmentsMap = new FDMap<string, IOtherInvestmentsGridRow>();

  investments.forEach((investment) => {
    investment.stakeholderInvestments?.forEach((inv) => {
      const type = getShareClassType(investment.investmentType, investment.shareClassType);
      if (!type) return;
      const invKey = getInvestmentKey({ name: investment.name, type } as ShareClass);

      let value = inv.fullyDilutedShares;
      if (investment.investmentType === InvestmentTypeMap.notes) {
        value = inv.principal;
      } else if (investment.investmentType === InvestmentTypeMap.options) {
        value = inv.outstandingEquityAwardDerivatives;
      }
      if (typeof inv.fundId === 'number' && invKey) {
        const initialValue = {
          fundId: inv.fundId,
          [invKey]: value,
        };

        clientInvestmentsMap.setOrUpdate(inv.fundId, initialValue, (prev) => {
          return {
            ...prev,
            [invKey!]: value,
          };
        });
      } else if (invKey && inv.stakeholderName) {
        const initialValue = {
          investor: inv.stakeholderName,
          [invKey]: value,
        };
        otherInvestmentsMap.setOrUpdate(inv.stakeholderName, initialValue, (prev) => {
          return {
            ...prev,
            [invKey!]: value,
          };
        });
      }
    });
  });

  return {
    clientInvestments: Array.from(clientInvestmentsMap.values()),
    otherInvestments: Array.from(otherInvestmentsMap.values()),
  };
}

export function getViewModelInvestmentFields(investment: ICaptableInvestmentDataModel): Partial<ShareClass> {
  const { investmentType, shareClassType, ...rest } = investment;
  const type = getShareClassType(investmentType, shareClassType)!;

  return { ...rest, type } as Partial<ShareClass>;
}

const ShareClassDelimiter = '___';
export function getInvestmentKey({ name, type }: Pick<ShareClass, 'name' | 'type'>) {
  return `${name}${ShareClassDelimiter}${type}`;
}

export function getInvestmentNameAndType(key: string): Pick<ShareClass, 'name' | 'type'> {
  const [name, type] = key.split(ShareClassDelimiter);
  return { name, type: type as ViewModelInvestmentType };
}
