import { FC } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { MilestoneForm, MilestoneInitialValues } from './MilestoneForm';

interface MilestoneModalProps {
  open: boolean;
  onClose: () => void;
  initialValues?: MilestoneInitialValues;
}
export const MilestoneModal: FC<MilestoneModalProps> = ({ onClose, open, initialValues }) => {
  return (
    <ModalWrapper title='Add Milestone' open={open} onClose={onClose}>
      <MilestoneForm onClose={onClose} initialValues={initialValues} />
    </ModalWrapper>
  );
};
