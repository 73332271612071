import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useState } from 'react';
import { useToastMessageState } from '../../ToastMessage/ToastMessageProvider';
import {
  createNoteComment,
  deleteNoteComment,
  updateNoteComment,
} from '../../../services/queries/MaggieNoteCommentsQueries';
import { NoteComment } from '../../../data-models/note.data-model';
import { noteCommentsState } from '../../../pages/CompanyProfiles/state/NoteCommentsState';
import { getErrorMessage } from '../../../services/queryHelpers';
import { ICommentUpdate } from '../../../pages/DealFlow2/data-models/comment.data-model';

export type INoteCommentUpdateParams = ICommentUpdate;

export function useNoteCommentActions(noteId: number) {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const currentNoteComments = useRecoilValue(noteCommentsState(noteId)) ?? [];
  const [isLoading, setIsLoading] = useState(false);
  const [ongoinActionId, setOngoinActionId] = useState<number | null>(null);

  const useCreateNoteComment = useRecoilCallback(({ set }) => async (newNoteData: NoteComment) => {
    setIsLoading(true);

    try {
      const response = await createNoteComment(newNoteData);
      const updatedNotes = [response, ...currentNoteComments];
      set(noteCommentsState(noteId), updatedNotes);
      return response;
    } catch (err) {
      set(noteCommentsState(noteId), currentNoteComments);
      pushErrorToast({ message: 'Failed to create comment' });
    } finally {
      setIsLoading(false);
    }
  });

  const useUpdateNoteComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ comment, commentId }: INoteCommentUpdateParams) => {
        setIsLoading(true);
        setOngoinActionId(commentId);

        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(noteCommentsState(noteId), (current) => {
            if (!current) return current;
            return current.reduce((prevValue, currentComment) => {
              if (currentComment.id === commentId) {
                return [...prevValue, { ...currentComment, comment }];
              } else {
                return [...prevValue, currentComment];
              }
            }, [] as NoteComment[]);
          });
        });

        gotoSnapshot(updatedSnapshot);
        const release = snapshot.retain();

        try {
          const updatedComment = await updateNoteComment({ noteId, comment, commentId });

          pushSuccessToast({ message: 'Comment successfully updated' });

          return updatedComment;
        } catch (err) {
          const message = getErrorMessage(err, 'An error occurred while trying to update comment');
          pushErrorToast({ message });
          gotoSnapshot(snapshot);
        } finally {
          release();
          setIsLoading(false);
          setOngoinActionId(null);
        }
      },
    [pushErrorToast, pushSuccessToast]
  );

  const useDeleteNoteComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (commentId: number) => {
        setIsLoading(true);
        setOngoinActionId(commentId);

        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(noteCommentsState(noteId), (current) => {
            if (!current) return current;
            return current.filter((comment) => comment.id !== commentId);
          });
        });

        gotoSnapshot(updatedSnapshot);
        const release = snapshot.retain();

        try {
          const updatedComment = await deleteNoteComment(commentId);

          pushSuccessToast({ message: 'Comment was deleted successfully' });

          return updatedComment;
        } catch (err) {
          const message = getErrorMessage(err, 'An error occurred while trying to delete comment');
          pushErrorToast({ message });
          gotoSnapshot(snapshot);
        } finally {
          release();
          setIsLoading(false);
          setOngoinActionId(null);
        }
      },
    [pushErrorToast, pushSuccessToast]
  );

  return {
    isLoading,
    ongoinActionId,
    useDeleteNoteComment,
    useCreateNoteComment,
    useUpdateNoteComment,
  };
}
