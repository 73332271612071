import { ColumnState, SortChangedEvent } from 'ag-grid-community';
import { useCallback, useEffect } from 'react';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
import { useKpiColumnDefs } from '../hooks/useFinancialsColumnDefs';

export interface IFinancialsSortState extends Pick<ColumnState, 'sort'> {
  colId: 'kpiName' | 'kpiGroupId';
}

export const financialsSortState = atom<IFinancialsSortState[]>({
  key: 'financialsGridColumnState',
  default: [],
});

export function useFinancialsSortState() {
  const { kpiGroupDef, kpiNameDef } = useKpiColumnDefs();
  const [sortState, setSortState] = useRecoilState(financialsSortState);
  const resetSort = useResetRecoilState(financialsSortState);

  useEffect(() => {
    setSortState([
      { colId: 'kpiGroupId', sort: kpiGroupDef.initialSort },
      { colId: 'kpiName', sort: kpiNameDef.initialSort },
    ]);
  }, [kpiGroupDef.initialSort, kpiNameDef.initialSort, setSortState]);

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      if (e.columns?.some((col) => col.getColId() === 'kpiName' || col.getColId() === 'kpiGroupId')) {
        setSortState(
          e.columns
            .filter((c) => c.getColId() === 'kpiName' || c.getColId() === 'kpiGroupId')
            .map((c) => ({ sort: c.getSort(), colId: c.getColId() as 'kpiName' | 'kpiGroupId' }))
        );
      }
    },
    [setSortState]
  );

  return {
    onSortChanged,
    resetSort,
    sortState,
  };
}
