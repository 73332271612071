import { SerializedEditorState } from 'lexical';
import {
  createNoteDataModel,
  createDraftNoteModel,
  INoteDataModel,
} from '../../../data-models/note.data-model';

export function createLexicalNoteTemplate(companyId: number): INoteDataModel<SerializedEditorState> {
  const draftNote = createDraftNoteModel();

  return createNoteDataModel({
    body: {
      data: draftNote,
      type: 'lexicaljs-json',
    },
    companyIds: [companyId],
    title: '',
    version: 0,
  });
}
