import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { companyState } from '../../../services/state/CompanyState';
import { IPersonDataModel, ScoreToNumber } from '../../../data-models/person.data-model';
import {
  peopleByCompanyState,
  showHiddenPeopleState,
} from '../../CompanyProfiles/state/PeopleByCompanyState';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';

export function usePeopleBreakdown() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const people = useRecoilValue(peopleByCompanyState(companyId));
  const showHidden = useRecoilValue(showHiddenPeopleState);
  const visiblePeople = useMemo(
    () => people?.filter((person) => !person.hidden || showHidden),
    [people, showHidden]
  );

  const leadershipIds = useMemo(
    () =>
      new Set(
        (company?.ceoIds ?? [])
          .concat(company?.founderIds ?? [])
          .filter(Boolean)
          .map((leader) => leader.id)
      ),
    [company?.ceoIds, company?.founderIds]
  );

  const getLeaders = useCallback(() => {
    return visiblePeople?.filter((person) => leadershipIds.has(person.id));
  }, [leadershipIds, visiblePeople]);

  const getRelationships = useCallback(() => {
    if (!visiblePeople) return [];
    return [...visiblePeople]
      .filter((p) => p.relationshipStrengthScoreNormalized != null && !leadershipIds.has(p.id))
      .sort(sortByRelationshipScore);
  }, [leadershipIds, visiblePeople]);

  return { visiblePeople, getLeaders, getRelationships };
}

type Relation = Pick<IPersonDataModel, 'relationshipStrengthScoreNormalized'>;
export function sortByRelationshipScore(a: Relation, b: Relation) {
  const aValue = a.relationshipStrengthScoreNormalized
    ? ScoreToNumber[a.relationshipStrengthScoreNormalized]
    : Number.MIN_SAFE_INTEGER;
  const bValue = b.relationshipStrengthScoreNormalized
    ? ScoreToNumber[b.relationshipStrengthScoreNormalized]
    : Number.MIN_SAFE_INTEGER;

  return bValue - aValue;
}
