import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  IScenarioDataModel,
  ParticipationType,
  IScenarioTransactionDataModel,
} from '../../../../../data-models/scenario.data-model';
import { InputContainer } from '../../components/commonStyledComponents';

import {
  capField,
  conversionRatioField,
  investmentDateField,
  multipleField,
  optionPoolField,
  optionPoolRefreshField,
  pariPassuField,
  participationField,
  preMoneyValuationField,
  roundSizeField,
  seniorityOptionField,
} from '../../forms/scenarioFields';
import { useRoundIdField } from '../../../../../data-fields/CommonFields';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { ITransactionFieldsProps } from './TransactionFields';
import { RoundParticipationSection } from './RoundParticipationSection';
import { AdvancedSettings } from './AdvancedSettings';

const advancedFields = new Set<keyof IScenarioTransactionDataModel>([
  'conversionRatio',
  'multiple',
  'participation',
  'participationCap',
  'pariPassu',
  'seniorityOption',
]);

export const RoundFields: FC<ITransactionFieldsProps> = ({ index }) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<Partial<IScenarioDataModel>>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `scenarioTransactions.${index}.multiple`) {
        if (watch(`scenarioTransactions.${index}.multiple`) === 0) {
          setValue(`scenarioTransactions.${index}.participation`, null);
          setValue(`scenarioTransactions.${index}.participationCap`, null);
        }
      } else if (name === `scenarioTransactions.${index}.participation`) {
        if (watch(`scenarioTransactions.${index}.participation`) !== ParticipationType.capped) {
          setValue(`scenarioTransactions.${index}.participationCap`, null);
        }
      } else if (name == `scenarioTransactions.${index}.optionPoolRefresh`) {
        if (watch(`scenarioTransactions.${index}.optionPoolRefresh`) === false) {
          setValue(`scenarioTransactions.${index}.optionPoolPercentage`, null);
        }
      }
    });

    return () => subscription.unsubscribe();
  });

  const advancedSettingsErrors = !!Object.keys(errors.scenarioTransactions?.[index] ?? {})?.find((key) =>
    advancedFields.has(key as keyof IScenarioTransactionDataModel)
  );

  return (
    <>
      <InputContainer>
        <FormFieldWithLabelFactory
          formField={useRoundIdField({
            label: 'Stage',
            key: `scenarioTransactions.${index}.roundId`,
            required: true,
          })}
        />
        <FormFieldWithLabelFactory formField={investmentDateField(index)} />
        <FormFieldWithLabelFactory formField={preMoneyValuationField(index)} />
        <FormFieldWithLabelFactory formField={roundSizeField(index)} />

        <FormFieldWithLabelFactory formField={optionPoolRefreshField(index)} />
        {watch(`scenarioTransactions.${index}.optionPoolRefresh`) === true && (
          <FormFieldWithLabelFactory formField={optionPoolField(index)} />
        )}

        <AdvancedSettings hasErrors={advancedSettingsErrors}>
          <InputContainer>
            <FormFieldWithLabelFactory formField={multipleField(index)} />
            <FormFieldWithLabelFactory formField={conversionRatioField(index)} />
            <FormFieldWithLabelFactory formField={seniorityOptionField(index)} />

            <FormFieldWithLabelFactory
              formField={participationField({
                key: `scenarioTransactions.${index}.participation`,
                required: watch(`scenarioTransactions.${index}.multiple`) !== 0,
              })}
            />

            {watch(`scenarioTransactions.${index}.participation`) === ParticipationType.capped && (
              <FormFieldWithLabelFactory formField={capField(index)} />
            )}

            {/* <FormItemWithLabelFactory formField={newSharesReservedField(index)} /> */}

            <FormFieldWithLabelFactory formField={pariPassuField(index)} />
          </InputContainer>
        </AdvancedSettings>
        <RoundParticipationSection transactionIndex={index} />
      </InputContainer>
    </>
  );
};
