import { useMemo } from 'react';
import { IMetricsDataModel } from '../../../data-models/metrics.data-model';
import { useGetCompanyIfSet } from '../../CompanyProfiles/hooks/useGetCompanyData';

export type InvestmentAmount = {
  data: Investment[];
  total: number;
};

export type Investment = {
  name: string;
  value: number;
  id: string;
};

export function useInvestmentAmount(filteredData: IMetricsDataModel[]): InvestmentAmount {
  let total = 0;
  const getCompany = useGetCompanyIfSet();

  const groupedData = filteredData?.reduce((a, c) => {
    // const group = c?.company?.crunchbase?.countryCode === 'USA' ? c?.company?.crunchbase?.region : 'Other';

    const company = getCompany(c.companyId);
    const group = company?.state ?? 'Other';

    if (a[group]) a[group] += c.amountInvested;
    else a[group] = c.amountInvested;
    total += c.amountInvested;
    return a;
  }, {} as Record<string, number>);

  const groupedDataToArray = Object.entries(groupedData as Record<string, number>).map(([name, value]) => ({
    name,
    value: Number(value.toFixed(2)),
    id: name,
  }));

  groupedDataToArray.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));

  return useMemo(() => ({ data: groupedDataToArray.slice(0, 10), total }), [groupedDataToArray, total]);
}
