import { BaseLocationDataModel } from '../schemas/Location.schema';

export function formatLocation(data: BaseLocationDataModel) {
  const { city = '', state = '', country = '', countryCode = '', stateCode = '' } = data;

  if (country === 'United States' || country === 'USA') {
    return [city, stateCode || state, countryCode || country].filter((str) => str !== '' && str).join(', ');
  }

  return [city, country].filter((str) => str !== '' && str).join(', ');
}
