import { useState } from 'react';
import { Alert, CircularProgress, IconButton, Popover, Stack, Typography } from '@mui/material';
import { css } from '@emotion/react';
import HistoryIcon from '@mui/icons-material/History';
import { Rubric } from '../../../../../schemas/Rubric.schema';
import {
  ColorStatusBox,
  StatusColorName,
  StatusToColorMapping,
} from '../../../../../components/Form/ColorStatus/ColorStatus';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';
import { FMT } from '../../../../../util/formatter-service';
import { useRubricHistory } from './useRubricHistory';

const HistoryContainer = css`
  min-height: 4rem;
  max-height: 30rem;
  padding: 1.5rem 1rem;
  overflow-y: auto;
  min-width: 24rem;
  max-width: 30rem;
  transition: all 0.3s;
`;

const RubricHistoryTableStyles = css`
  display: grid;
  grid-template-columns: 5rem 1fr auto;
  row-gap: 0.5rem;
`;
const RubricHistoryRowStyles = css`
  min-width: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
`;

export function RubricHistory({ rubric }: { rubric: Rubric }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton onClick={handleClick} style={{ justifySelf: 'center', alignSelf: 'center' }}>
        <HistoryIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {open ? <RubricHistoryData rubric={rubric} /> : null}
      </Popover>
    </>
  );
}

export function RubricHistoryData({ rubric }: { rubric: Rubric }) {
  const { history, loading, error } = useRubricHistory({ rubric });

  return (
    <div css={HistoryContainer}>
      {loading && (
        <Stack width='100%' justifyContent={'center'} alignItems={'center'}>
          <CircularProgress size={20} />
        </Stack>
      )}
      {error && <Alert severity='error'>Failed to load data</Alert>}
      {history && (
        <div css={RubricHistoryTableStyles} role='table'>
          {history.map((rubric) => {
            const author = FMT.format('userByEmail', rubric.updatedBy ?? rubric.createdBy ?? '');
            const date = FMT.format('date', rubric.date);
            return (
              <div css={RubricHistoryRowStyles} key={rubric.id} role='row'>
                <ColorStatusBox
                  statusColor={StatusToColorMapping[rubric.value as StatusColorName]}
                  style={{ transform: 'scale(0.6, 0.8)' }}
                  role='cell'
                  title={rubric.value}
                />
                <Stack direction={'row'} alignItems={'center'} gap={'0.5rem'} mr='2rem' role='cell'>
                  <MUIAvatar nameParts={author.split(/\s/)} src={''} />
                  <Typography variant={'caption'} noWrap>
                    {author}
                  </Typography>
                </Stack>
                <Typography variant={'caption'} color='text.secondary' role='cell'>
                  {date}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
