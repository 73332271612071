import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { TransactionAudit, TransactionHistoryTableData } from '../../../types';
import { IUserDataModel } from '../../../data-models/user.data-model';
import { DateRangeSelector } from '../../Finance/form-components/DateRangeSelector';
import { UserSelector } from '../../Finance/form-components/UserSelector';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { activeUsersState, usersByIdMapState } from '../../../services/state/AppConfigState';
import { UpdatesHistoryTable } from './UpdatesHistoryTable';
import { UpdatesHistoryTableHeading } from './UpdatesHistoryTableHeading';

const StyledSection = styled('section')`
  margin: 4rem 0 0;
`;
const NoDataContainer = styled('div')`
  margin: 1.6rem 1.2rem;
`;

const FilterContainer = styled('div')`
  margin: 1.6rem 0 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

type Props = {
  tableColumns: Column<TransactionHistoryTableData>[];
  activeTransaction: ITransactionDataModel;
  transactionHistory: TransactionAudit[];
};

export const HistoryOfUpdatesSection = ({ tableColumns, activeTransaction, transactionHistory }: Props) => {
  const { colors } = useTheme();
  const users = useRecoilValue(activeUsersState);
  const userMap = useRecoilValue(usersByIdMapState);

  const [selectedEditor, setSelectedEditor] = useState<IUserDataModel | null>(null);
  const [selectedStartingDate, setSelectedStartingDate] = useState<Date | null>(null);
  const [selectedEndingDate, setSelectedEndingDate] = useState<Date | null>(null);

  // TODO: check if only one editor can be selected, here not using multiselect, so id should never be an array
  const handleSelectEditor = useCallback(
    (id: number | number[] | null) => {
      if (!users || id === null || Array.isArray(id)) {
        setSelectedEditor(null);
        return;
      }
      setSelectedEditor(userMap.get(id) || null);
    },
    [userMap, users]
  );

  // data for all History of Updates tables
  // for maggie transactions transaction.linkedTransactionId === transaction.id; external transactions should only link to maggie transactions, so display history for the linked transaction, if available
  const activeTransactionHistory = useMemo(() => {
    if (!transactionHistory || !activeTransaction) {
      return [];
    } else {
      // get all audits for activeTransaction
      const result = transactionHistory.filter(
        (tAudit) => tAudit.transactionId === activeTransaction.linkedTransactionId
      );
      // sort in desc order, by createdAt date
      result.sort((thisT, thatT) =>
        thatT.createdAt && thisT.createdAt
          ? new Date(thatT?.createdAt).getTime() - new Date(thisT?.createdAt).getTime()
          : 0
      );
      return result;
    }
  }, [activeTransaction, transactionHistory]);

  /**
  - 1st Table: 
  1st row is coming from Transaction - `Transaction` object
  2nd row -- latest available history object transactionHistory[0]
  - 2nd Table:
  1st row -transactionHistory[0] 
  2nd  -transactionHistory[1]
  - 3rd Table:
  1st row -transactionHistory[1]
  2nd  -transactionHistory[2]
  - 4th Table:
  1st row -transactionHistory[2]
  2nd  -transactionHistory[3]
 */
  const allTableData: TransactionHistoryTableData[][] = useMemo(() => {
    if (activeTransactionHistory.length) {
      const data = [[activeTransaction, activeTransactionHistory[0]]];
      for (let i = 1; i < activeTransactionHistory.length; i++) {
        data.push([activeTransactionHistory[i - 1], activeTransactionHistory[i]]);
      }
      return data;
    } else {
      return [];
    }
  }, [activeTransaction, activeTransactionHistory]);

  const filteredTableData: TransactionHistoryTableData[][] = useMemo(() => {
    if (!selectedEditor && !selectedStartingDate && !selectedEndingDate) {
      return allTableData;
    } else {
      /** For the [editor &] date range filter, use the [createdBy and] createdAt of the secondRow, ie the transactionAudit entry of the previous state */
      const filtered = [];
      for (const tHistoryData of allTableData) {
        if (!selectedEditor || tHistoryData[1].createdBy === selectedEditor.email) {
          if (
            !selectedStartingDate ||
            !selectedEndingDate ||
            !tHistoryData[1].createdAt ||
            (new Date(new Date(tHistoryData[1].createdAt).setHours(0, 0, 0, 0)) >= selectedStartingDate &&
              new Date(new Date(tHistoryData[1].createdAt).setHours(0, 0, 0, 0)) <= selectedEndingDate)
          ) {
            filtered.push(tHistoryData);
          }
        }
      }
      return filtered;
    }
  }, [allTableData, selectedStartingDate, selectedEndingDate, selectedEditor]);

  const selectedEditorId = useMemo(() => (selectedEditor ? selectedEditor.id : undefined), [selectedEditor]);
  const placeholder = useMemo(() => selectedEditor?.name || 'Select Editor', [selectedEditor]);

  if (!users) return null;

  return (
    <StyledSection>
      <Typography variant='h4' color={colors.primary[90]}>
        History of Updates
      </Typography>
      {activeTransactionHistory.length ? (
        <>
          <FilterContainer>
            <Typography variant='subtitle2' color={colors.primary[90]}>
              Date Range{' '}
            </Typography>
            <DateRangeSelector
              setSelectedStartingDate={setSelectedStartingDate}
              setSelectedEndingDate={setSelectedEndingDate}
              textContent={
                selectedStartingDate && selectedEndingDate
                  ? `${selectedStartingDate.toLocaleDateString('en-US', {
                      dateStyle: 'medium',
                    })} - ${selectedEndingDate.toLocaleDateString('en-US', { dateStyle: 'medium' })}`
                  : 'Select Date Range'
              }
            />
            <Typography variant='subtitle2' color={colors.primary[90]}>
              Editor{' '}
            </Typography>
            <UserSelector
              value={selectedEditorId}
              onChange={handleSelectEditor}
              name='EditorSelector'
              placeholder={placeholder}
              style={{ width: '15rem' }}
            />
          </FilterContainer>
          {filteredTableData.length ? (
            filteredTableData.map((tdata) => (
              <div key={tdata[1].id}>
                <UpdatesHistoryTableHeading createdAt={tdata[1].createdAt} createdBy={tdata[1].createdBy} />
                <UpdatesHistoryTable columns={tableColumns} data={tdata} />
              </div>
            ))
          ) : (
            <NoDataContainer>
              <Typography variant='subtitle1' color={colors.neutral[50]}>
                No results.
              </Typography>
            </NoDataContainer>
          )}
        </>
      ) : (
        <NoDataContainer>
          <Typography variant='subtitle1' color={colors.neutral[50]}>
            No data.
          </Typography>
        </NoDataContainer>
      )}
    </StyledSection>
  );
};
