import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { baseFormInlineClassNames } from '../../../theme/component-styles';
import { IFormFieldSelectMeta } from '../../../view-models/form.view-model';
import { MoreChipWithTooltip } from '../../../pages/CompanyProfiles/Summary/components/MoreChipWithTooltip/MoreChipWithTooltip';
import { SelectType, useFieldSelect } from './FieldSelect';
import { IBaseFieldProps } from './types';

export function FieldMultiSelect(props: IBaseFieldProps<IFormFieldSelectMeta<SelectType>>) {
  const { formField, formProps } = props;
  const { ref, onChange, onBlur, value } = formProps;
  const { disabled = false, label, variant, placeholder } = formField ?? {};
  const { allowCustomAdd } = formField.rendererMeta ?? {};
  const className = variant === 'form-inline' ? `${baseFormInlineClassNames} form-inline-multiselect` : '';
  const { getOptionsLabel, options, valuesAsMap, getOptionDisabled } = useFieldSelect(formField);

  return (
    <Autocomplete
      style={{ pointerEvents: 'auto' }} // otherwise hovering over the more chip won't show other values
      disabled={disabled}
      aria-label={label}
      className={className}
      disableCloseOnSelect
      getOptionLabel={getOptionsLabel}
      freeSolo={allowCustomAdd}
      multiple
      onBlur={onBlur}
      onChange={(event, values) => {
        onChange(values);
      }}
      openOnFocus
      options={options}
      renderOption={(props, option, renderOptionState: AutocompleteRenderOptionState) => {
        const { selected } = renderOptionState;
        const fallbackOption = allowCustomAdd
          ? { displayName: option, value: option, disabled: false }
          : undefined;
        const { disabled, displayName, value } = valuesAsMap.get(option) ?? fallbackOption!;

        return (
          <MenuItem {...props} key={option} disabled={disabled}>
            <Checkbox checked={selected} />
            <ListItemText primary={displayName ?? value} />
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          ref={ref}
          onKeyDown={(event) => {
            // Prevent the backspace deletes tags behaviour.
            const currentText = (event.target as HTMLInputElement).value;
            if (currentText.length === 0 && event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
        />
      )}
      renderTags={(options) => {
        if (!options?.length) {
          return <></>;
        }

        const [first, ...others] = options.map((option) => {
          const fallbackOption = allowCustomAdd
            ? { displayName: String(option), value: option, secondaryLabel: undefined }
            : undefined;
          const item = valuesAsMap.get(option) ?? fallbackOption;

          return item ? item.displayName ?? item.secondaryLabel ?? item.value.toString() : '';
        });

        if (options.length === 1) {
          return <Chip size='small' label={first} />;
        }
        return (
          <>
            <Chip size='small' label={first} />
            <MoreChipWithTooltip
              title={`Other (${others.length})`}
              moreChipTotal={others.length}
              otherItems={others}
            ></MoreChipWithTooltip>
          </>
        );
      }}
      getOptionDisabled={getOptionDisabled}
      value={value ?? []}
    />
  );
}
