import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { Button, Chip, ListItem, Typography } from '@mui/material';
import { IAirTableTableDataModel } from '../../../../data-models/airtable-base.data-model';
import { airTableTablesState } from '../../state/IntegrationsState';
import { configurationStepsState, selectedTableState } from '../../state/ConfigurationState';
import { pluralizeWord } from '../../../../util/stringUtils';

const TableList = styled('ul')`
  all: unset;
  padding: 0.5rem 1rem 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, auto));
  justify-content: start;
  gap: 1rem;
`;

const TableButton = styled(Button)`
  all: unset;
  padding: 0 0.5rem;
  min-height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border: 1px solid #ffffff;
  box-shadow: 0px -4px 4px rgba(255, 255, 255, 0.06), 0px 4px 4px rgba(16, 37, 62, 0.04),
    0px 4px 4px rgba(75, 82, 93, 0.02);
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ffffff;
  &:hover {
    background: ${({ theme }) => theme.colors.secondary[10]};
  }
`;

interface IAirTableTablesProps {
  baseId: string;
}
export const AirTableTables: FC<IAirTableTablesProps> = ({ baseId }) => {
  const tablesLoadable = useRecoilValueLoadable(airTableTablesState(baseId));
  const tables = tablesLoadable.valueMaybe();
  if (!tables) return null;

  return (
    <TableList>
      {tables.tables.map((t) => (
        <AirTableTable key={t.id} table={t} />
      ))}
    </TableList>
  );
};

interface IAirTableTableProps {
  table: IAirTableTableDataModel;
}
const AirTableTable: FC<IAirTableTableProps> = ({ table }) => {
  const setSelectedTable = useSetRecoilState(selectedTableState);
  const setSelectedStep = useSetRecoilState(configurationStepsState);

  const { name, numOfColumnsSynced } = table;

  const theme = useTheme();
  return (
    <ListItem sx={{ padding: 0, overflowX: 'hidden' }}>
      <TableButton
        onClick={() => {
          setSelectedTable(table);
          setSelectedStep((prev) => {
            return prev + 1;
          });
        }}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <TableChartOutlinedIcon htmlColor={theme.colors.tertiary[60]} />
        <Typography
          variant='h6'
          sx={{ fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {name}
        </Typography>
        {numOfColumnsSynced > 0 && (
          <Chip
            label={`${numOfColumnsSynced} ${pluralizeWord('column', numOfColumnsSynced)} connected`}
            variant='outlined'
            color='success'
            size='medium'
            style={{
              marginLeft: 'auto',
              cursor: 'unset',
            }}
          />
        )}
      </TableButton>
    </ListItem>
  );
};
