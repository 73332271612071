import { FC } from 'react';
import { BasicDialog } from '../../../../components/Dialog/BasicDialog';
import { useFinanceActions } from '../../hooks/useFinanceActions';
import { EditTransactionForm } from '../../Forms/EditTransactionForm';
import { ITransactionFormModalProps } from '../AssignToRound/AssignToInvestmentModal';
import { useTransactionTitle } from '../../hooks/useTransactionTitle';

export const EditTransactionModal: FC<ITransactionFormModalProps> = ({ open, onClose, transaction }) => {
  const { handleUpdateTransaction } = useFinanceActions(onClose);
  const transactionTitle = useTransactionTitle(transaction);
  const title = `Edit the ${transactionTitle}`;

  return (
    <BasicDialog open={open} onClose={onClose} title={title}>
      {open ? (
        <EditTransactionForm
          onCancel={onClose}
          transaction={transaction}
          onSubmit={handleUpdateTransaction}
        />
      ) : (
        <div />
      )}
    </BasicDialog>
  );
};
