export function isLocal() {
  return window.location.hostname === 'localhost';
}

export function isDevelopment() {
  return window.location.hostname.startsWith('dev');
}

export function isProduction() {
  return !isLocal() && !isDevelopment();
}

export function isNotProduction() {
  return isLocal() || isDevelopment();
}

export function getEnvironment() {
  if (isLocal()) {
    return 'local';
  }

  return window.location.host.split('.')[0];
}

export const getEnvVariable = (envKey: keyof ImportMetaEnv): string => {
  return import.meta.env[envKey] ?? '';
};
