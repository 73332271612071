import { useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { css } from '@emotion/react';
import { IBaseFieldProps } from '../../../components/Form/Display/types';
import { FieldFactory } from '../../../components/Form/FieldFactory';
import { RendererType } from '../../../data-models/field.data-model';
import { createFormField } from '../../../view-models/form.view-model';
import { ISimpleChoice } from '../../../data-models/field2.data-model';

const fieldContainer = css`
  padding: 0 1rem 0.5rem;
`;

interface IDetailsFieldSelectProps {
  onChange: (value: string) => void;
  options: ISimpleChoice<string>[];
}
export function DetailsFieldSelect({ onChange, options }: IDetailsFieldSelectProps) {
  const selectorProps = useMemo(() => {
    const formField = createFormField({
      key: 'field',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: options,
      },
      placeholder: 'Add Field',
    });

    return {
      formProps: { value: null, onChange } as ControllerRenderProps,
      formField,
    } as IBaseFieldProps<unknown>;
  }, [options, onChange]);

  if (!options.length) return null;

  return (
    <div css={fieldContainer}>
      <FieldFactory {...selectorProps} key={Math.ceil(Math.random() * 1000)} />
    </div>
  );
}
