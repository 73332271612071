import { CSSProperties, useMemo } from 'react';
import { IField } from '../../../../../data-models/field2.data-model';
import {
  IKPIRequestDataModel,
  IKPIRequestResponse,
  IKPIResponseFormSectionData,
} from '../../../../../data-models/kpi-requests.data-model';
import { IKPITemplate } from '../../../../../data-models/kpi-template.data-model';
import { SubSection } from '../../../../CompanyProfiles/Summary/components/SubSection';
import { KPIFieldFactory, KPIFieldFactoryProps } from '../../Sections/FormItem/KPIFieldFactory';
import { IKPIGridSectionProps, KPIAgGrid } from './KPIAgGrid';

const kpiWrapperStyle: CSSProperties = {
  margin: '0 0 2rem 0',
};

interface IKPIRenderedTemplateProps {
  onKPIGridDataUpdated: IKPIGridSectionProps['onKPIGridDataUpdated'];
  onSectionDataUpdated: KPIFieldFactoryProps['onChange'];
  request: IKPIRequestDataModel;
  response: IKPIRequestResponse;
  template: IKPITemplate;
}

export function KPIRenderedTemplate(props: IKPIRenderedTemplateProps) {
  const { onKPIGridDataUpdated, onSectionDataUpdated, request, response, template } = props;

  const sections = useMemo(() => {
    if (template) {
      return (
        template.sections
          ?.toSorted((section1, section2) => {
            return (section1.meta.sortOrder ?? -1) - (section2.meta.sortOrder ?? -1);
          })
          .map((section, index) => {
            if (section.type === 'grid') {
              const { frequency, fye, granularity, period } = request;

              return (
                <SubSection
                  key={index}
                  noCard
                  title={`${index + 1}. ${section.meta.displayName}`}
                  style={kpiWrapperStyle}
                >
                  <KPIAgGrid
                    companyFYEDateString={fye}
                    kpiRequestFrequency={frequency}
                    kpiRequestGranularity={granularity}
                    kpiRequestPeriod={period}
                    kpiResponseData={response.kpiData.values}
                    onKPIGridDataUpdated={onKPIGridDataUpdated}
                    section={section}
                  />
                </SubSection>
              );
            } else {
              const sectionData: IKPIResponseFormSectionData = response.sectionData.find(
                (data) => data.sectionId === section.id
              ) ?? {
                sectionId: section.id ?? -1,
                value: null,
              };

              return (
                <SubSection
                  key={index}
                  noCard
                  title={`${index + 1}. ${(section.meta as IField<unknown>).entityField}`}
                  style={kpiWrapperStyle}
                >
                  <KPIFieldFactory
                    companyId={request.companyId}
                    onChange={onSectionDataUpdated}
                    section={section}
                    sectionData={sectionData}
                  />
                </SubSection>
              );
            }
          }) ?? []
      );
    }
    return [];
  }, [
    template,
    request,
    response.kpiData.values,
    response.sectionData,
    onKPIGridDataUpdated,
    onSectionDataUpdated,
  ]);

  return <>{sections}</>;
}
