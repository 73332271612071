import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { cloneDeep } from 'lodash-es';
import { fetchPersonEmails } from '../queries/MaggieCompanyQueries';
import { peopleByCompanyState } from '../../pages/CompanyProfiles/state/PeopleByCompanyState';
import { personState } from '../state/PersonState';

const fetchedPersonEmails = new Set<number>();

/**
 * Fetches person emails from secondary source and merges them into person state
 */
export function usePersonEmails(companyId?: number) {
  const [loading, setLoading] = useState(false);

  const getAndSetPersonEmails = useRecoilCallback(
    ({ set, snapshot }) =>
      async (personId: number) => {
        if (fetchedPersonEmails.has(personId)) return;
        setLoading(true);
        try {
          const personEmails = await fetchPersonEmails(personId);
          if (companyId && snapshot.getInfo_UNSTABLE(peopleByCompanyState(companyId)).isSet) {
            set(peopleByCompanyState(companyId), (people) => {
              if (!people) return people;

              return people.map((person) => {
                if (person.id !== personId) return person;
                return cloneDeep({ ...person, ...personEmails });
              });
            });
          }

          if (snapshot.getInfo_UNSTABLE(personState(personId)).isSet) {
            set(personState(personId), (person) => {
              if (!person) return person;
              return cloneDeep({ ...person, ...personEmails });
            });
          }
        } catch (err) {
          console.error(err);
          // no need to display error, the email data will just be empty
        } finally {
          setLoading(false);
          fetchedPersonEmails.add(personId);
        }
      },
    []
  );

  return { getAndSetPersonEmails, loading };
}
