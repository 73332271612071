import { css } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Stack, styled, Tooltip, Typography } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ICellRendererParams } from 'ag-grid-community';
import { IMetricsDataModel } from '../../../../../../../data-models/metrics.data-model';
import { MaggieFeatureFlags } from '../../../../../../../util/feature-flags';
import { MUIAvatar } from '../../../../../../../components/Avatar/MUIAvatar';
import { ICompanyDataModel } from '../../../../../../../data-models/company.data-model';
import { BorderlessTooltipProps } from '../../../../../../../constants/styles';
import { FMT } from '../../../../../../../util/formatter-service';
import { CompanyLogoAndName } from '../../../../../../../components/grid-renderers/CompanyCellRenderer';
import { cardStyles } from '../../../../../../../theme/component-styles';
import { colors } from '../../../../../../../theme/colors';
import { ITransactionDataModel } from '../../../../../../../data-models/transaction.data-model';

const card = css({
  ...cardStyles,
  padding: '0.2rem',
  marginBlock: '0.5rem',
});
const numeric = css`
  justify-self: right;
`;
const shadow = css`
  box-shadow: 2px 1px 0.25rem ${colors.neutral[20]}66;
`;
const styledAvatar = css`
  & .MuiAvatar-root {
    ${shadow};
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.1rem;
  }
`;

const AcquirerContents = styled('div')`
  display: grid;
  align-items: center;
  position: relative;
  height: min-content;
`;

const CompareIcon = styled(CompareArrowsIcon)`
  font-size: 0.8rem;
  background-color: ${colors.primary[0]};
  color: ${colors.secondary[40]};
  border: 0.1rem solid ${colors.secondary[40]};
  border-radius: 50%;
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
`;

const AcquirerAsCell = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  column-gap: 0.5rem;
`;

type IAcquirerRendererProps = Pick<
  IMetricsDataModel,
  'acquisitionShares' | 'exitDate' | 'fmv' | 'exitValuation'
> &
  Pick<ICompanyDataModel, 'acquirer'>;
export function AcquirerRenderer(props: IAcquirerRendererProps) {
  const { showAcquirerData } = useFlags<MaggieFeatureFlags>();
  if (!showAcquirerData || !props.acquirer) return null;

  return (
    <Tooltip {...BorderlessTooltipProps} title={<AcquirerDetails {...props} />} placement='top'>
      <AcquirerContents>
        <MUIAvatar
          nameParts={props.acquirer.fields?.name?.split(' ') ?? []}
          src={props.acquirer.fields?.logo ?? ''}
          size='gridCell'
        />
        <CompareIcon />
      </AcquirerContents>
    </Tooltip>
  );
}

export function AcquisitionCellRenderer(params: ICellRendererParams<ITransactionDataModel>) {
  if (params.data && params.data.acquirer) {
    return (
      <AcquirerAsCell>
        <AcquirerRenderer
          acquirer={params.data.acquirer}
          acquisitionShares={params.data.noOfShares ?? Number.NaN}
          exitDate={params.data.transactionDate}
          fmv={params.data.fmvBasis ?? Number.NaN}
          exitValuation={params.data.exitValuation}
        />
        <div>{params.data.acquirer.fields.name}</div>
      </AcquirerAsCell>
    );
  }

  return null;
}

export function AcquirerDetails({
  acquirer,
  acquisitionShares,
  exitDate,
  fmv,
  exitValuation,
}: IAcquirerRendererProps) {
  return (
    <Typography variant='caption' my='1rem' px='1rem' component={'div'} minWidth={'16rem'}>
      <Stack
        display={'grid'}
        gridTemplateColumns={'1fr 1fr'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
      >
        <Stack gridColumn={'1 / -1'} css={styledAvatar}>
          <Typography variant='body2'>Acquired by:</Typography>
          <div css={card}>
            <CompanyLogoAndName
              logoUrl={acquirer?.fields?.logo ?? ''}
              name={acquirer?.fields?.name ?? ''}
              avatarProps={{ size: 'small' }}
              typographyProps={{ variant: 'caption' }}
              style={{ gap: '0.5rem' }}
            />
          </div>
        </Stack>
        <span>Exit Date:</span> <span css={numeric}>{FMT.format('date', exitDate)}</span>
        <span>FMV:</span> <span css={numeric}>{FMT.format('usdRound', fmv)}</span>
        <span>Exit Value:</span> <span css={numeric}>{FMT.format('usd', exitValuation)}</span>
        <span>Shares Held:</span> <span css={numeric}>{FMT.format('numeric2DP', acquisitionShares)}</span>
      </Stack>
    </Typography>
  );
}
