import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authenticatedUserState, sessionExpiredState } from '../state/AuthState';

export function useToken() {
  const user = useRecoilValue(authenticatedUserState);
  const setSessionExpired = useSetRecoilState(sessionExpiredState);

  return useCallback(async (): Promise<string> => {
    try {
      return user?.accessToken ?? '';
    } catch (err) {
      Sentry.captureException(err);
      setSessionExpired(true);

      return '';
    }
  }, [setSessionExpired, user?.accessToken]);
}
