import { intervalToDuration } from 'date-fns';
import { IWorkExperienceDataModel } from '../../../data-models/person.data-model';
import { formatMonthYear } from '../../../util/formatters/DateFormatters';

export function getPeriod(workHistory: IWorkExperienceDataModel) {
  const startDate = workHistory.startDate ? formatMonthYear(workHistory.startDate) : 'unknown';
  // endDate === null always means Present
  let endDate = workHistory.endDate;
  if (endDate) {
    endDate = formatMonthYear(endDate);
  } else if (endDate === null) {
    endDate = 'Present';
  } else {
    endDate = 'unknown';
  }

  if (isUnknownDate(startDate) && isUnknownDate(endDate)) return '';

  return `${startDate} - ${endDate}`;
}

export function getFormattedDuration(startDate: Date, endDate: Date) {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  const years = duration.years;
  const months = duration.months;

  let formattedDuration = '';
  if (years) formattedDuration += `${years}y`;
  if (months) formattedDuration += `${years ? ' ' : ''}${months}m`;

  return formattedDuration;
}

export function isUnknownDate(date: string) {
  return !date || date === 'unknown';
}
