import * as yup from 'yup';
import { KPIRequestFrequency, KPIRequestStatus } from '../data-models/kpi-requests.data-model';
import { RendererType } from '../data-models/field.data-model';
import { StringFormattersConfig } from '../util/formatters/StringFormatters';
import { ISelectMeta } from '../data-models/field3.data-model';

export const KPIRequestSchema = {
  fields() {
    return {
      companyId: yup.number(),
      frequency: yup.string().oneOf(Object.values(KPIRequestFrequency)),
      id: yup.number(),
      period: yup.string(),
      respondent: yup.array(),
      sentAt: yup.string(),
      status: yup.string().oneOf(Object.values(KPIRequestStatus)),
      templateId: yup.number(),
      templateUuid: yup.string(),
    };
  },
  recipientGridSchema() {
    const { frequency, period, sentAt, status, templateUuid } = this.fields();
    return yup.object().shape({
      templateUuid: templateUuid.gridMeta({
        formatter: 'kpiTemplateName',
        label: 'Name',
      }),
      frequency: frequency.gridMeta({
        formatter: StringFormattersConfig.capitalizedString,
      }),
      period,
      sentAt: sentAt.gridMeta({
        renderer: RendererType.date,
      }),
      status: status.gridMeta<ISelectMeta<unknown>>({
        key: 'recipientKPIStatus',
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: [
            {
              value: KPIRequestStatus.Sent,
              displayName: 'Pending',
            },
            {
              value: KPIRequestStatus.Accepted,
              displayName: 'Complete',
            },
            {
              value: KPIRequestStatus.Rejected,
              displayName: 'Rejected',
            },
            {
              value: KPIRequestStatus.Submitted,
              displayName: 'Submitted',
            },
          ],
        },
      }),
    });
  },
};

export type IKPIRequest = yup.InferType<ReturnType<typeof KPIRequestSchema.recipientGridSchema>>;
