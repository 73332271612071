import { ColDef } from 'ag-grid-community';
import { genericComparator } from '../../../util/comparators';

export const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 200,
  suppressMovable: true,
  filterParams: {
    buttons: ['reset'],
    newRowsAction: 'keep',
  },
  comparator: genericComparator,
};
