import { useRecoilValue } from 'recoil';
import { useCompanyType } from '../hooks/useCompanyType';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { CompanyType } from '../../../data-models/company.data-model';
import { CompanySummaryPortfolio } from './CompanySummaryPortfolio';
import { CompanySummaryDiscovery } from './CompanySummaryDiscovery';

export function CompanySummary2() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const companyType = useCompanyType(companyId);

  switch (companyType) {
    case undefined:
      return null;
    case CompanyType.portfolio:
      return <CompanySummaryPortfolio />;
    case CompanyType.discovery:
    case CompanyType.passed:
      return <CompanySummaryDiscovery />;
    default:
      return <CompanySummaryDiscovery />;
  }
}
