import { styled } from '@mui/material/styles';
import { CSSProperties, FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { INDICATOR_TYPES } from '../../types';
import { theme } from '../../theme';
import { FMT } from '../../util/formatter-service';

interface Props {
  indicator: number | null;
  style?: CSSProperties;
}

const { neutral, critical, tertiary } = theme.colors;

const IndicatorWrapper = styled('span')<{ indicatorBackground: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ indicatorBackground }) => indicatorBackground};
  border-radius: 4px;
  padding: 0 0.4rem 0 0;
  min-width: fit-content;
`;

const Spacer = styled('span')`
  display: block;
  padding: 0.25rem;
  height: 1.5rem;
`;

export const Indicator: FC<Props> = ({ indicator, style }) => {
  const indicatorType = useMemo(() => {
    if (indicator === null) return INDICATOR_TYPES.NEUTRAL;
    if (indicator > 0) return INDICATOR_TYPES.POSITIVE;
    if (indicator < 0) return INDICATOR_TYPES.NEGATIVE;
    return INDICATOR_TYPES.NEUTRAL;
  }, [indicator]);

  const getFormattedValue = () => {
    if (indicator === null || indicator === Infinity || Number.isNaN(indicator)) return 'NA';
    return FMT.format('percent2dpAsIsShort', Math.abs(indicator));
  };

  const getIndicatorBackground = () => {
    switch (indicatorType) {
      case INDICATOR_TYPES.NEGATIVE:
        return critical[10];
      case INDICATOR_TYPES.POSITIVE:
        return tertiary[10];
      default:
        return neutral[10];
    }
  };

  const getFontColor = () => {
    switch (indicatorType) {
      case INDICATOR_TYPES.NEGATIVE:
        return critical[50];
      case INDICATOR_TYPES.POSITIVE:
        return tertiary[70];
      default:
        return neutral[70];
    }
  };

  const icon = getIcon(indicatorType, getFontColor());

  if (indicator === null) return null;

  return (
    <IndicatorWrapper indicatorBackground={getIndicatorBackground()} data-testid={'indicator'} style={style}>
      {icon}
      <Typography variant={'caption'} color={getFontColor()} lineHeight={'inherit'}>
        {getFormattedValue()}
      </Typography>
    </IndicatorWrapper>
  );
};

function getIcon(indicatorType: INDICATOR_TYPES, color: string) {
  if (indicatorType === INDICATOR_TYPES.NEGATIVE) {
    return <ArrowDropDownIcon fontSize={'small'} sx={{ color }} />;
  }
  if (indicatorType === INDICATOR_TYPES.POSITIVE) {
    return <ArrowDropUpIcon fontSize={'small'} sx={{ color }} />;
  }
  return <Spacer />;
}
