import { ICellRendererParams } from 'ag-grid-community';
import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { MUIAvatar } from '../../../../../components/Avatar/MUIAvatar';

const NameCell = styled('span')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[60]};
`;

export const CompanyCellRenderer: React.FC<ICellRendererParams> = memo(function CompanyCellRenderer({
  node,
  value,
}: ICellRendererParams) {
  const logoUrl = node.data?.company?.logoUrl;
  const companyName = node.data?.company?.name;

  return (
    <NameCell data-testid='company-cell-renderer'>
      <MUIAvatar
        src={logoUrl ?? null}
        nameParts={(companyName ?? value)?.split(' ') ?? []}
        style={!companyName && !value ? { background: 'transparent' } : {}}
      />
      <span>{node.data?.company?.name || value}</span>
    </NameCell>
  );
});
