import * as yup from 'yup';
import {
  investmentDateField,
  investmentTypeField,
  shareClassNameField,
  ppsField10dp,
  ViewModelInvestmentType,
} from './common-schema-defs';
import { abstractShareClassSchema } from './AbstractShareClass.schema';

export const WarrantsSchema = {
  create() {
    return abstractShareClassSchema().shape({
      type: investmentTypeField().default(ViewModelInvestmentType.warrants),
      name: shareClassNameField().default('Warrants'),
      strikePrice: ppsField10dp().required().label('Strike Price').default(0.01),
      investmentDate: investmentDateField(),
    });
  },
};

export type Warrant = yup.InferType<ReturnType<typeof WarrantsSchema.create>>;
export function createWarrant(overrides: Partial<Warrant> = {}): Warrant {
  return WarrantsSchema.create().cast(overrides);
}
