import { IDealReasonDataModel } from '../../../data-models/deal-reason.data-model';

export function transformDataModelBeforeSubmit(data: IDealReasonDataModel): IDealReasonDataModel {
  return {
    ...data,
    name: data.reason,
    displayName: data.reason,
    sortOrder: 0,
  };
}
