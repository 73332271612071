import { CSSProperties } from 'react';
import { Stack, Typography } from '@mui/material';
import Archive from '../assets/icons/archive.svg?react';
import { cardStyles } from '../theme/component-styles';

interface IEmptySectionProps {
  actionComponent?: JSX.Element;
  title: string;
  message?: string;
  style?: CSSProperties;
}
export function EmptySection({ style, actionComponent, title, message }: IEmptySectionProps) {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      alignSelf={'stretch'}
      style={{ ...cardStyles, minHeight: '70vh', ...style }}
    >
      <Archive />
      <Typography variant='h4' mt='1rem' mb={actionComponent ? '2rem' : undefined}>
        {title}
      </Typography>
      {actionComponent && actionComponent}
      <Typography
        variant='body2'
        mt='1rem'
        color='text.secondary'
        style={{ whiteSpace: 'pre-line', textAlign: 'center' }}
      >
        {message}
      </Typography>
    </Stack>
  );
}
