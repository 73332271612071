import { styled } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { selectedCompanyIdProfile } from '../../pages/CompanyProfiles/state/UIState';
import { companyState } from '../../services/state/CompanyState';
import { FormFactoryNoLayout } from '../Form/FormFactory';
import { FDMap } from '../../util/data-structure/FDMap';
import { schemaToFormFields } from '../../util/schema-utils';
import { useUpdateGoogleDriveFolder } from '../../pages/CompanyProfiles/hooks/useUpdateGoogleDriveFolder';

const GoogleDriveEmbedWrapper = styled('div')`
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-rows: min-content 1fr;
  row-gap: 1rem;
`;

const GoogleDriveIFrame = styled('iframe')`
  height: 100%;
  width: 100%;
  border: none;
`;

const ErrorMsg = styled('div')`
  display: flex;
  flex-direction: column;
  column-gap: 0.5rem;
`;

export function GoogleDriveEmbed() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const googleDriveFolderId = useRecoilValue(companyState(companyId))?.googleDriveFolder ?? undefined;

  return (
    <GoogleDriveEmbedWrapper>
      <GoogleDriveLinkInput companyId={companyId} googleDriveFolderId={googleDriveFolderId} />
      {googleDriveFolderId ? (
        <GoogleDriveIFrame
          src={`https://drive.google.com/embeddedfolderview?id=${googleDriveFolderId}#list`}
        ></GoogleDriveIFrame>
      ) : (
        <Alert sx={{ height: 'min-content' }} severity={'info'}>
          Please provide the Google Folder link
        </Alert>
      )}
    </GoogleDriveEmbedWrapper>
  );
}

function GoogleDriveLinkInput(props: { companyId: number; googleDriveFolderId?: string }) {
  const { companyId, googleDriveFolderId } = props;
  const updateGoogleDriveFolder = useUpdateGoogleDriveFolder();
  const schema = getGoogleDriveFolderSchema();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      folder: googleDriveFolderId
        ? `https://drive.google.com/drive/folders/${googleDriveFolderId}?usp=sharing`
        : '',
    },
  });

  const formFields = schemaToFormFields(schema);
  const updateFolder = useCallback(
    (data: Partial<{ folder: string }>) => {
      const [, folderId] = data.folder?.match(getGoogleDriveFolderIdRegex()) ?? [];
      if (folderId && !methods.formState.errors.folder) {
        updateGoogleDriveFolder(companyId, folderId);
      }
    },
    [companyId, methods.formState.errors, updateGoogleDriveFolder]
  );

  const hasError = useMemo(() => methods.formState.errors.folder, [methods.formState.errors.folder]);

  return (
    <div>
      <FormProvider {...methods}>
        <FormFactoryNoLayout
          onChange={updateFolder}
          form={{
            variant: 'form',
            fields: FDMap.fromArray(formFields, 'key'),
          }}
        ></FormFactoryNoLayout>
      </FormProvider>
      {hasError ? (
        <ErrorMsg>
          <Typography variant='caption' color='textSecondary'>
            {'Please ensure the link is generated from the Share > Copy Link option:'}
          </Typography>
        </ErrorMsg>
      ) : (
        ''
      )}
    </div>
  );
}

function getGoogleDriveFolderIdRegex() {
  return /https:\/\/drive\.google\.com\/drive\/folders\/([a-zA-Z0-9_-]*)\??.*/;
}

function getGoogleDriveFolderSchema() {
  return yup.object({
    folder: yup
      .string()
      .label('Google Drive Folder')
      .matches(getGoogleDriveFolderIdRegex(), {
        message:
          'The Google Drive share link format should resemble: https://drive.google.com/drive/folders/1ABcjsdlkfjsld_Kl7maq',
      })
      .nullable(),
  });
}
