import { atom } from 'jotai';
import { atomWithLazy, unwrap } from 'jotai/utils';
import { IField, isUniversalKPIField } from '../../data-models/field2.data-model';
import { KPIGroup } from '../../schemas/kpi-group.schema';
import { sortFields } from '../../util/field-utils';
import { toCamelCase } from '../../util/stringUtils';
import { getAllKPIs, getKPIGroupsSettings } from '../queries/KPIConfigQueries';

export const kpiConfigAtom = atomWithLazy(async () => {
  const res = await getAllKPIs();
  return res.sort(sortFields);
});

export const kpiConfigByIdMapAtom = atom(async (get) => {
  const map = new Map<number, IField<unknown>>();
  const kpiConfig = await get(kpiConfigAtom);

  if (!kpiConfig) return map;

  return kpiConfig.reduce((res, kpi) => {
    res.set(kpi.id, kpi);
    return res;
  }, map);
});

export const kpiConfigByKeyMapAtom = atom(async (get) => {
  const map = new Map<string, IField<unknown>>();
  const kpiConfig = await get(kpiConfigAtom);
  if (!kpiConfig) return map;
  return kpiConfig.reduce((res, kpi) => {
    res.set(kpi.entityField ?? `__${toCamelCase(kpi.entity)}`, kpi);
    return res;
  }, map);
});

export const kpiGroupsAtom = atom(async () => {
  const res = await getKPIGroupsSettings();

  return res.sort((s1, s2) => s1.sortOrder - s2.sortOrder);
});

export const kpiGroupsByIdAtom = atom(async (get) => {
  const groups = await get(kpiGroupsAtom);

  return groups.reduce((res, group) => {
    res.set(group.id, group);
    return res;
  }, new Map<number, KPIGroup>());
});

export const universalAndSystemKPIAtom = atom((get) => {
  const kpis = get(unwrap(kpiConfigAtom));

  return kpis?.filter(isUniversalKPIField) ?? [];
});
