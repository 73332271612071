import { useRecoilValue } from 'recoil';
import { useCallback, useState } from 'react';
import { Button, styled, Typography } from '@mui/material';
import { set } from 'lodash-es';
import {
  kpiConfigState,
  kpiGroupsByIdState,
  kpiGroupsState,
} from '../../../services/state/KPI/KPITemplatesState';
import { IField } from '../../../data-models/field2.data-model';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { BasicDragHandle, SortableItem } from '../../../components/SortableList/SortableItem';
import { MultiSortableList } from '../../../components/SortableMultiList/MultiSortableList';
import { Card } from '../../../components/Card/Card';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { useConfigActions } from '../Common/UseConfigActions';
import { sortGenericSortable } from '../../../data-models/app-config.data-model';
import { KPIConfigBaseURL, sortKpisByType } from './ConfigKPI';
import { getKPIsByGroupSorted, KPINoGroupName } from './KPIFields';

const CardWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled('div')`
  padding: 2rem;
`;

export interface IKPIGroupSortProps {
  closeModal: () => void;
}

export function KPISort(props: IKPIGroupSortProps) {
  const { bulkUpdateItems } = useConfigActions<IField<unknown>>(KPIConfigBaseURL);
  const universalKPIsFields = [...(useRecoilValue(kpiConfigState) ?? [])].sort(sortKpisByType);
  const kpiGroups = useRecoilValue(kpiGroupsState);
  const kpiGroupsById = useRecoilValue(kpiGroupsByIdState);
  const kpisByGroup = getKPIsByGroupSorted(universalKPIsFields, kpiGroupsById);
  const [updatedItems, setUpdatedItems] = useState(kpisByGroup);

  function onOrderChange(items: Record<string, IField<unknown>[]>) {
    setUpdatedItems(items);
  }

  function onSaveUpdatedOrder() {
    const payload: IField<unknown>[] = [];
    let index = 0;

    Object.keys(updatedItems).forEach((groupName) => {
      const kpiGroupNameToId = kpiGroups.reduce((res, group) => {
        res[group.name] = group.id;
        return res;
      }, {} as Record<string, number>);

      const kpisForGroup = updatedItems[groupName];
      kpisForGroup.forEach((kpi) => {
        const updatedKpi = { ...kpi };
        set(updatedKpi, 'gridMeta.group', groupName === KPINoGroupName ? '' : groupName);
        updatedKpi.kpiGroupId = groupName === KPINoGroupName ? undefined : kpiGroupNameToId[groupName];
        updatedKpi.sortOrder = index;
        payload.push(updatedKpi);
        index++;
      });
    });

    bulkUpdateItems(payload.sort(sortGenericSortable));
  }

  return (
    <ContentWrapper>
      <MultiSortableList
        items={updatedItems}
        onChange={onOrderChange}
        renderItem={(item) => {
          return (
            <SortableItem id={item.id}>
              <Card>
                <CardWrapper>
                  <Typography>{item.displayName}</Typography>
                  <BasicDragHandle />
                </CardWrapper>
              </Card>
            </SortableItem>
          );
        }}
      />
      <StickyFormButtons onCancel={props.closeModal} onSubmit={onSaveUpdatedOrder} submitLabel={'Save'} />
    </ContentWrapper>
  );
}

export function SortKPIs() {
  const [open, setIsOpen] = useState(false);
  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Button color={'secondary'} onClick={openModal} variant={'contained'}>
        Sort KPIs
      </Button>
      {open && (
        <BasicDialog open={open} onClose={closeModal} title={'Sort KPIs'}>
          <KPISort closeModal={closeModal} />
        </BasicDialog>
      )}
    </>
  );
}
