import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import {
  Circle,
  StepNumber,
} from '../../../../../../pages/DealFlow2/components/DealModal/DealAddModalContent/Circle';
import { Icon, IconTypes } from '../../../../../Icon';

const Wrapper = styled('div')<{ isActive: boolean }>`
  margin-bottom: 28px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary[5] : theme.colors.neutral[2]};
  padding: 24px;
`;

const Heading = styled('div')`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  cursor: pointer;
`;

const CaptionWrapper = styled('div')`
  margin: 13px 0;
`;

interface IProps {
  activeStep: number;
  stepNumber: number;
  heading: string;
  description: string;
  children: ReactNode;
}

export const FormStep: FC<IProps> = ({ activeStep, stepNumber, heading, description, children }) => {
  const { primary, success, neutral } = useTheme().colors;

  return (
    <Wrapper isActive={activeStep === stepNumber}>
      <Heading>
        <Circle isOpen={activeStep === stepNumber} isChecked={activeStep > stepNumber}>
          {(activeStep === stepNumber || activeStep < stepNumber) && (
            <StepNumber isOpen={activeStep === stepNumber}>{stepNumber}</StepNumber>
          )}
          {activeStep > stepNumber && <Icon type={IconTypes.CHECK} color={success[60]} />}
        </Circle>
        <Typography variant={'subtitle1'} color={primary[90]}>
          {heading}
        </Typography>
      </Heading>
      <CaptionWrapper>
        <Typography variant='caption' color={neutral[50]}>
          {description}
        </Typography>
      </CaptionWrapper>
      {children}
    </Wrapper>
  );
};
