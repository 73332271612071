import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { IRowNode } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { SecurityReport } from '../../../schemas/SecurityReport.schema';
import { securitiesFundFilterState, securitiesRealizedFilterState } from './AllSecuritiesUIState';

export function useAllSecuritiesFilterState(gridRef: RefObject<AgGridReact> | null) {
  const fundFilter = useRecoilValue(securitiesFundFilterState);
  const realizedFilter = useRecoilValue(securitiesRealizedFilterState);
  const selectedFunds = useMemo(() => new Set(fundFilter?.map((f) => f.id)), [fundFilter]);

  const isExternalFilterPresent = useCallback(() => {
    return fundFilter !== null || realizedFilter !== null;
  }, [fundFilter, realizedFilter]);

  const doesExternalFilterPass = useCallback(
    (node: IRowNode<SecurityReport>) => {
      let passes = !fundFilter?.length || selectedFunds.has(node.data?.fundId ?? -1);
      // the filter has only two options, realized and unrealized
      if (realizedFilter?.length == 1) {
        const fmv = node.data?.fmv;
        const isRealized = fmv != null && fmv <= 0;
        passes = realizedFilter.at(0)!.id === 'realized' ? isRealized : !isRealized;
      }
      return passes;
    },
    [fundFilter, selectedFunds, realizedFilter]
  );

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [fundFilter, gridRef, realizedFilter]);

  return { isExternalFilterPresent, doesExternalFilterPass };
}
