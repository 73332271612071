import { useRecoilValue } from 'recoil';
import { endOfDay } from 'date-fns';
import { trackingByCompanyIdState } from '../state/ListsState';

export function useTrackingData(companyId: number) {
  const trackingByCompanyId = useRecoilValue(trackingByCompanyIdState);
  const trackingData = trackingByCompanyId.get(companyId);
  const hasExpired = trackingData?.snoozeDate
    ? endOfDay(new Date(trackingData.snoozeDate)) < new Date()
    : false;

  return { hasExpired, snoozeDate: trackingData?.snoozeDate };
}
