import { useRecoilValue } from 'recoil';
import { styled, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { usersByIdMapState } from '../../../../services/state/AppConfigState';
import { MUIAvatar } from '../../../../components/Avatar/MUIAvatar';
import { RelationshipScore } from '../../../../components/RelationshipScore/RelationshipScore';
import { IUserRelationDataModel } from '../../../../data-models/user-relation.data-model';

const StrongestConnectionRow = styled('div')`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  padding: 0.25rem;
`;

const Container = styled('div')`
  width: 420px;
`;

const PeopleInfo = styled('div')`
  display: flex;
  gap: 1rem;
`;

export interface IStrongestConnectionsProps {
  relationships: IUserRelationDataModel[];
  titleStyle?: CSSProperties;
}

export function StrongestConnections(props: IStrongestConnectionsProps) {
  const { relationships } = props;
  const users = useRecoilValue(usersByIdMapState);

  const relationElems = relationships
    .slice(0, 3)
    .map((relation) => {
      const { userId, relationshipStrengthScoreNormalized } = relation;
      const user = users.get(userId);
      const fullName = user ? `${user.firstName} ${user.lastName}` : '';

      return user !== undefined ? (
        <StrongestConnectionRow key={relation.userId}>
          <PeopleInfo>
            <MUIAvatar nameParts={[user.firstName, user.lastName]} src={null} />
            <Typography
              variant={'body2'}
              whiteSpace={'nowrap'}
              display={'inline'}
              style={{ maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {fullName}
            </Typography>
          </PeopleInfo>

          <RelationshipScore
            relationshipStrengthScoreNormalized={relationshipStrengthScoreNormalized ?? null}
          />
        </StrongestConnectionRow>
      ) : undefined;
    })
    .filter((elem) => elem !== undefined);

  return (
    <Container aria-label={'strongest connections'}>
      <Typography paddingBottom={'.5rem'} variant={'body1'} style={props.titleStyle}>
        Strongest Connections
      </Typography>
      {relationElems}
    </Container>
  );
}
