import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilState } from 'recoil';
import {
  AssignTemplateFieldTypes,
  useAssignTemplateFormsSchema,
} from '../../../../../schemas/template-assignment-schema';
import { StepperFormButtons } from '../../../../Finance2/Forms/StepperFormButtons';
import { FormContainer } from '../../../../../components/Form/FormComponents';
import { currentStepStateTA, templateAssignmentFormState } from './AssignTemplateFormState';
import { AssignTemplateFields } from './AssignTemplateForm';

export function AssignTemplateStep1() {
  const [templateAssignmentDataState, setTemplateAssignmentDataState] =
    useRecoilState(templateAssignmentFormState);
  const getAssignSchema = useAssignTemplateFormsSchema();
  const assignSchema = getAssignSchema(templateAssignmentDataState?.frequency);
  const methods = useForm<AssignTemplateFieldTypes>({
    defaultValues: templateAssignmentDataState ?? assignSchema.getDefault(),
    mode: 'all',
    resolver: yupResolver(assignSchema),
  });
  const handleSubmit = async () => {
    const isValid = await methods.trigger();
    setTemplateAssignmentDataState(methods.getValues());
    return isValid;
  };

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <AssignTemplateFields />
      </FormContainer>
      <StepperFormButtons stepIsValid={handleSubmit} stepState={currentStepStateTA} />
    </FormProvider>
  );
}
