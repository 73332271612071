/* eslint-disable @typescript-eslint/ban-ts-comment */

import { FunctionComponent, ReactElement, SVGProps } from 'react';
import { styled } from '@mui/material/styles';
// Icons
import BellNotification from '../../assets/icons/bell-notification.svg?react';
import AlarmDefault from '../../assets/icons/alarm-default.svg?react';
import AlarmPressedWithDot from '../../assets/icons/alarm-pressed-with-dot.svg?react';
import AlarmPressed from '../../assets/icons/alarm-pressed.svg?react';
import AlarmActive from '../../assets/icons/alarm-active.svg?react';
import ArrowDown from '../../assets/icons/arrow-down.svg?react';
import AnalysisColored from '../../assets/icons/analysis-colored.svg?react';
import ArrowUp from '../../assets/icons/arrow-up.svg?react';
import Calendar from '../../assets/icons/calendar.svg?react';
import CalendarBig from '../../assets/icons/calendar-big.svg?react';
import CalendarForDropdowns from '../../assets/icons/calendar-for-dropdowns.svg?react';
import CancelChips from '../../assets/icons/cancel-chips.svg?react';
import Check from '../../assets/icons/check.svg?react';
import ChevronDown from '../../assets/icons/chevron-down.svg?react';
import ChevronLeft from '../../assets/icons/chevron-left.svg?react';
import ChevronNext from '../../assets/icons/chevron-next.svg?react';
import ChevronRight from '../../assets/icons/chevron-right.svg?react';
import CloseMenu1 from '../../assets/icons/close-menu-1.svg?react';
import CloseMenu2 from '../../assets/icons/close-menu-2.svg?react';
import Comments2 from '../../assets/icons/comments-2.svg?react';
import Companies from '../../assets/icons/companies.svg?react';
import CompaniesColored from '../../assets/icons/companies-colored.svg?react';
import Compare from '../../assets/icons/compare.svg?react';
import Csv from '../../assets/icons/csv.svg?react';
import DealBoard from '../../assets/icons/deal-board.svg?react';
import DealBoardColored from '../../assets/icons/deal-board-colored.svg?react';
import Delete1 from '../../assets/icons/delete-1.svg?react';
import Delete2 from '../../assets/icons/delete-2.svg?react';
import Document from '../../assets/icons/document.svg?react';
import Doner from '../../assets/icons/doner.svg?react';
import Download from '../../assets/icons/download.svg?react';
import Drag from '../../assets/icons/drag.svg?react';
import Duplicate from '../../assets/icons/duplicate.svg?react';
import Edit from '../../assets/icons/edit.svg?react';
import Export from '../../assets/icons/export.svg?react';
import Filter from '../../assets/icons/filter.svg?react';
import Finance from '../../assets/icons/finance.svg?react';
import FinanceColored from '../../assets/icons/finance-colored.svg?react';
import HalfScreen from '../../assets/icons/half-screen.svg?react';
import FourthScreen from '../../assets/icons/fourth-screen.svg?react';
import Follow from '../../assets/icons/follow.svg?react';

import Fullscreen from '../../assets/icons/fullscreen.svg?react';
import Grid from '../../assets/icons/grid.svg?react';
import History from '../../assets/icons/history.svg?react';
import Info from '../../assets/icons/info.svg?react';
import Kanban from '../../assets/icons/kanban.svg?react';
import Lightning from '../../assets/icons/lightning.svg?react';
import List from '../../assets/icons/list.svg?react';
import ListView from '../../assets/icons/list-view.svg?react';
import Logout from '../../assets/icons/logout.svg?react';
import Lock from '../../assets/icons/lock.svg?react';
import MenuTab from '../../assets/icons/menu-tab.svg?react';
import Menu from '../../assets/icons/menu.svg?react';
import Note from '../../assets/icons/note.svg?react';
import Minus from '../../assets/icons/minus.svg?react';
import NotificationColored from '../../assets/icons/notifications-colored.svg?react';
import MoreVertical from '../../assets/icons/more-vertical.svg?react';
import Pdf from '../../assets/icons/pdf.svg?react';
import People from '../../assets/icons/people.svg?react';
import PeopleColored from '../../assets/icons/people-colored.svg?react';
import PlusSmall from '../../assets/icons/plus-small.svg?react';
import PlusBig from '../../assets/icons/plus-big.svg?react';
import Png from '../../assets/icons/png.svg?react';
import Portfolio from '../../assets/icons/portfolio.svg?react';
import PortfolioColored from '../../assets/icons/portfolio-colored.svg?react';
import PresentMode from '../../assets/icons/present-mode.svg?react';
import PresentModeOff from '../../assets/icons/present-mode-off.svg?react';
import Preferences from '../../assets/icons/preferences.svg?react';
import ReplaceForButton from '../../assets/icons/replace-for-button.svg?react';
import Replace from '../../assets/icons/replace.svg?react';
import Report from '../../assets/icons/report.svg?react';
import ReportColored from '../../assets/icons/reports-colored.svg?react';
import Reset from '../../assets/icons/reset.svg?react';
import Resize from '../../assets/icons/resize.svg?react';
import Search from '../../assets/icons/search.svg?react';
import Settings from '../../assets/icons/settings.svg?react';
import SettingsColored from '../../assets/icons/settings-colored.svg?react';
import Settings1 from '../../assets/icons/settings-1.svg?react';
import Settings2 from '../../assets/icons/settings-2.svg?react';
import Share1 from '../../assets/icons/share-1.svg?react';
import Share2 from '../../assets/icons/share-2.svg?react';
import Sort from '../../assets/icons/sort.svg?react';
import Star from '../../assets/icons/star.svg?react';
import StarFilled from '../../assets/icons/star-filled.svg?react';
import Statistics1 from '../../assets/icons/statistics-1.svg?react';
import Statistics2 from '../../assets/icons/statistics-2.svg?react';
import Statistics3 from '../../assets/icons/statistics-3.svg?react';
import Statistics4 from '../../assets/icons/statistics-4.svg?react';
import Success from '../../assets/icons/success.svg?react';
import TemperatureOff from '../../assets/icons/temperature-off.svg?react';
import TemperatureOn from '../../assets/icons/temperature-on.svg?react';
import Track from '../../assets/icons/track.svg?react';
import Transaction from '../../assets/icons/transaction.svg?react';
import TriangleDown from '../../assets/icons/triangle-down.svg?react';
import TriangleDownRed from '../../assets/icons/triangle-down-red.svg?react';
import TriangleUpGreen from '../../assets/icons/triangle-up-green.svg?react';
import TriangleUp from '../../assets/icons/triangle-up.svg?react';
import Upload from '../../assets/icons/upload.svg?react';
import UserHeart from '../../assets/icons/user-heart.svg?react';
import UserSharedByMe from '../../assets/icons/user-shared-by-me.svg?react';
import UserSharedWithMe from '../../assets/icons/user-shared-with-me.svg?react';
import Warning from '../../assets/icons/warning.svg?react';
import Xlsx from '../../assets/icons/xlsx.svg?react';
import ClockRotateLeft from '../../assets/icons/clock-rotate-left.svg?react';
import Collapse from '../../assets/icons/collapse.svg?react';
import Expand from '../../assets/icons/expand.svg?react';
import Zz from '../../assets/icons/zz.svg?react';
import Undo from '../../assets/icons/undo.svg?react';
import Linkedin from '../../assets/icons/ln.svg?react';
import Facebook from '../../assets/icons/fb.svg?react';
import Twitter from '../../assets/icons/tw.svg?react';
import Instagram from '../../assets/icons/ig.svg?react';
import Pitchbook from '../../assets/icons/pitchbook.svg?react';
import Cb from '../../assets/icons/CB-icon.svg?react';
import CbInsights from '../../assets/icons/cb-insights.svg?react';
import Mappings from '../../assets/icons/mappings.svg?react';
import MappingsColored from '../../assets/icons/mappings-colored.svg?react';
import AdminPanel from '../../assets/icons/admin-panel.svg?react';
import AdminPanelColored from '../../assets/icons/admin-panel-colored.svg?react';
import KPIPlain from '../../assets/icons/kpis.svg?react';
import KPIColored from '../../assets/icons/kpis-colored.svg?react';

import {
  ADMIN_PANEL,
  ADMIN_PANEL_COLORED,
  ALARM_ACTIVE,
  ALARM_DEFAULT,
  ALARM_PRESSED,
  ALARM_PRESSED_WITH_DOT,
  ARROW_DOWN,
  ARROW_UP,
  CALENDAR,
  CALENDAR_BIG,
  CALENDAR_FOR_DROPDOWNS,
  CANCEL_CHIPS,
  CB_INSIGHTS,
  CHECK,
  CHEVRON_DOWN,
  CHEVRON_LEFT,
  CHEVRON_NEXT,
  CHEVRON_RIGHT,
  CLOSE_MENU_1,
  CLOSE_MENU_2,
  COMMENT,
  COMPANIES,
  COMPANIES_COLORED,
  COMPARE,
  CSV,
  DELETE_1,
  DELETE_2,
  DOCUMENT,
  DONER,
  DRAG,
  DUPLICATE,
  EDIT,
  EXPORT,
  FILTER,
  FINANCE,
  FOLLOW,
  FULLSCREEN,
  GRID,
  HISTORY,
  INFO,
  KANBAN,
  LIGHTNING,
  LIST,
  LIST_VIEW,
  LOCK,
  MAPPINGS,
  MAPPINGS_COLORED,
  MENU_TAB,
  MENU,
  NOTE,
  MINUS,
  MORE_VERTICAL,
  PDF,
  PEOPLE,
  PLUS_BIG,
  PLUS_SMALL,
  PNG,
  PORTFOLIO,
  PRESENT_MODE,
  PRESENT_MODE_OFF,
  REPLACE_FOR_BUTTON,
  REPLACE,
  REPORT,
  RESET,
  RESIZE,
  SEARCH,
  SETTINGS,
  SETTINGS_1,
  SETTINGS_2,
  SHARE_1,
  SHARE_2,
  SORT,
  STATISTICS_1,
  STATISTICS_2,
  STATISTICS_3,
  STATISTICS_4,
  SUCCESS,
  TEMPERATURE_OFF,
  TEMPERATURE_ON,
  TRACK,
  TRANSACTION,
  TRIANGLE_DOWN,
  TRIANGLE_DOWN_RED,
  TRIANGLE_UP,
  UPLOAD,
  USER_HEART,
  USER_SHARED_BY_ME,
  USER_SHARED_WITH_ME,
  WARNING,
  XLSX,
  TRIANGLE_UP_GREEN,
  FOURTH_SCREEN,
  HALF_SCREEN,
  BELL_NOTIFICATION,
  DOWNLOAD,
  CLOCK_ROTATE_LEFT,
  COLLAPSE,
  DEAL_BOARD,
  EXPAND,
  STAR,
  STAR_FILLED,
  ZZ,
  UNDO,
  PREFERENCES,
  LOGOUT,
  ANALYSIS_COLORED,
  DEALBOARD_COLORED,
  FINANCE_COLORED,
  PEOPLE_COLORED,
  PORTFOLIO_COLORED,
  REPORT_COLORED,
  NOTIFICATIONS_COLORED,
  SETTINGS_COLORED,
  LN,
  FB,
  IG,
  TW,
  CB,
  PITCHBOOK,
  KPI,
  KPI_COLORED,
} from './icons';

interface IconProps {
  type: string;
  color?: string;
  hidden?: boolean;
  disablePointer?: boolean;
  onClick?: () => void;
}

interface StyledIconProps {
  color?: string;
  isHidden?: boolean;
}

const icons: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  [ADMIN_PANEL]: AdminPanel,
  [ADMIN_PANEL_COLORED]: AdminPanelColored,
  [ALARM_ACTIVE]: AlarmActive,
  [ALARM_DEFAULT]: AlarmDefault,
  [ALARM_PRESSED]: AlarmPressed,
  [ALARM_PRESSED_WITH_DOT]: AlarmPressedWithDot,
  [ARROW_DOWN]: ArrowDown,
  [ARROW_UP]: ArrowUp,
  [ANALYSIS_COLORED]: AnalysisColored,
  [BELL_NOTIFICATION]: BellNotification,
  [CB_INSIGHTS]: CbInsights,
  [CALENDAR]: Calendar,
  [CALENDAR_BIG]: CalendarBig,
  [CALENDAR_FOR_DROPDOWNS]: CalendarForDropdowns,
  [CANCEL_CHIPS]: CancelChips,
  [CHECK]: Check,
  [CHEVRON_DOWN]: ChevronDown,
  [CHEVRON_LEFT]: ChevronLeft,
  [CHEVRON_NEXT]: ChevronNext,
  [CHEVRON_RIGHT]: ChevronRight,
  [CLOSE_MENU_1]: CloseMenu1,
  [CLOSE_MENU_2]: CloseMenu2,
  [COMMENT]: Comments2,
  [COMPANIES]: Companies,
  [COMPANIES_COLORED]: CompaniesColored,
  [COMPARE]: Compare,
  [CSV]: Csv,
  [DEAL_BOARD]: DealBoard,
  [DELETE_1]: Delete1,
  [DELETE_2]: Delete2,
  [DOCUMENT]: Document,
  [DONER]: Doner,
  [DOWNLOAD]: Download,
  [DRAG]: Drag,
  [DUPLICATE]: Duplicate,
  [DEALBOARD_COLORED]: DealBoardColored,
  [EDIT]: Edit,
  [EXPORT]: Export,
  [FILTER]: Filter,
  [FINANCE]: Finance,
  [FINANCE_COLORED]: FinanceColored,

  [FOLLOW]: Follow,
  [FULLSCREEN]: Fullscreen,
  [HALF_SCREEN]: HalfScreen,
  [FOURTH_SCREEN]: FourthScreen,
  [GRID]: Grid,
  [HISTORY]: History,
  [INFO]: Info,
  [KANBAN]: Kanban,
  [LIGHTNING]: Lightning,
  [LIST]: List,
  [LIST_VIEW]: ListView,
  [LOCK]: Lock,
  [LOGOUT]: Logout,
  [NOTIFICATIONS_COLORED]: NotificationColored,
  [MENU_TAB]: MenuTab,
  [MENU]: Menu,
  [MAPPINGS]: Mappings,
  [MAPPINGS_COLORED]: MappingsColored,
  [KPI]: KPIPlain,
  [KPI_COLORED]: KPIColored,
  [NOTE]: Note,
  [MINUS]: Minus,
  [MORE_VERTICAL]: MoreVertical,
  [PDF]: Pdf,
  [PEOPLE]: People,
  [PEOPLE_COLORED]: PeopleColored,
  [PORTFOLIO_COLORED]: PortfolioColored,
  [PLUS_SMALL]: PlusSmall,
  [PLUS_BIG]: PlusBig,
  [PNG]: Png,
  [PORTFOLIO]: Portfolio,
  [PREFERENCES]: Preferences,
  [PRESENT_MODE]: PresentMode,
  [PRESENT_MODE_OFF]: PresentModeOff,
  [REPLACE_FOR_BUTTON]: ReplaceForButton,
  [REPLACE]: Replace,
  [REPORT]: Report,
  [REPORT_COLORED]: ReportColored,
  [RESET]: Reset,
  [RESIZE]: Resize,
  [SEARCH]: Search,
  [SETTINGS]: Settings,
  [SETTINGS_COLORED]: SettingsColored,
  [SETTINGS_1]: Settings1,
  [SETTINGS_2]: Settings2,
  [SHARE_1]: Share1,
  [SHARE_2]: Share2,
  [SORT]: Sort,
  [STAR]: Star,
  [STAR_FILLED]: StarFilled,
  [STATISTICS_1]: Statistics1,
  [STATISTICS_2]: Statistics2,
  [STATISTICS_3]: Statistics3,
  [STATISTICS_4]: Statistics4,
  [SUCCESS]: Success,
  [TEMPERATURE_OFF]: TemperatureOff,
  [TEMPERATURE_ON]: TemperatureOn,
  [TRACK]: Track,
  [TRANSACTION]: Transaction,
  [TRIANGLE_DOWN]: TriangleDown,
  [TRIANGLE_DOWN_RED]: TriangleDownRed,
  [TRIANGLE_UP_GREEN]: TriangleUpGreen,
  [TRIANGLE_UP]: TriangleUp,
  [UPLOAD]: Upload,
  [USER_HEART]: UserHeart,
  [USER_SHARED_BY_ME]: UserSharedByMe,
  [USER_SHARED_WITH_ME]: UserSharedWithMe,
  [WARNING]: Warning,
  [XLSX]: Xlsx,
  [CLOCK_ROTATE_LEFT]: ClockRotateLeft,
  [COLLAPSE]: Collapse,
  [EXPAND]: Expand,
  [UNDO]: Undo,
  [ZZ]: Zz,
  [LN]: Linkedin,
  [FB]: Facebook,
  [TW]: Twitter,
  [IG]: Instagram,
  [CB]: Cb,
  [PITCHBOOK]: Pitchbook,
};

const StyledIcon = styled('span')<StyledIconProps>`
  ${({ color }) => `
		color: ${color};
		${color ? `color: ${color};` : ''}
    svg * { stroke: currentColor; }
  `}
  visibility: ${({ isHidden: hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const IconWrapper = styled('span')<{ disablePointer: boolean }>`
  cursor: ${({ disablePointer }) => (disablePointer ? 'initial' : 'pointer')};
`;

export const Icon = ({ type, color, hidden, onClick, disablePointer = false }: IconProps): ReactElement => {
  const IconType = icons[type];

  return (
    <IconWrapper disablePointer={disablePointer} onClick={onClick}>
      <StyledIcon color={color} data-icon={type} isHidden={hidden} data-testid='icon'>
        <IconType />
      </StyledIcon>
    </IconWrapper>
  );
};
