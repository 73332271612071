import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type WithOpen = {
  isOpen?: boolean;
};

type WithChecked = {
  isChecked?: boolean;
};

const getCircleStyles = (theme: Theme, isOpen?: boolean, isChecked?: boolean) => {
  switch (true) {
    case isOpen:
      return {
        backgroundColor: theme.colors.primary[60],
      };
    case isChecked:
      return {
        backgroundColor: theme.colors.success[20],
      };
    default:
      return {
        border: `1px solid ${theme.colors.neutral[60]}`,
        backgroundColor: theme.colors.primary[0],
      };
  }
};

export const Circle = styled('div')<WithOpen & WithChecked>(({ theme, isOpen, isChecked }) => ({
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  marginRight: '9px',
  transition: 'all ease-in-out 0.2s',
  ...getCircleStyles(theme, isOpen, isChecked),
  '& span': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const StepNumber = styled('div')<WithOpen>`
  font-size: 14px;
  color: ${({ isOpen, theme }) => (isOpen ? theme.colors.primary[0] : theme.colors.neutral[60])};
`;
