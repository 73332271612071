import { IGenericSortable } from '../../data-models/app-config.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class SectorFormatter
  implements IInitializedFormatter<Map<number, IGenericSortable>, number | number[]>
{
  #sectors: Map<number, IGenericSortable> = new Map();

  init(sectors: Map<number, IGenericSortable>) {
    this.#sectors = sectors;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map(this.#format).join(', ');
    }
    return this.#format(value);
  };

  #format = (value: number) => {
    return this.#sectors.get(value)?.name ?? value.toString();
  };
}
