import { TextField } from '@mui/material';
import { FC, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSetRecoilState } from 'recoil';
import { useModalState } from '../../../../components/Modal/ModalContext';
import { MuiMultiselect } from '../../../../components/MuiMultiselect/MuiMultiselect';
import { Option } from '../../../../components/MuiSingleSelect/MuiSingleSelect';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import { useDealBoardConfig } from '../../../../services/queries-hooks/MaggieDealQueriesHooks';
import { useDealActions2 } from '../../hooks/useDealActions';
import { isOpenDrawerState } from '../../state/DealboardUIState';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { ModalActionButtons } from './ModalActionButtons';

const Wrapper = styled('div')``;

interface MissedModalProps {
  deal: IDealDataModel;
  handler?: (deal: IDealDataModel, missedReasonsString: string, missedComments: string | null) => void;
}

export const DealMissedModalContent: FC<MissedModalProps> = ({ deal, handler }) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);
  const [missedComments, setMissedComments] = useState<string | null>('');
  const { handleMissed } = useDealActions2();
  const { data } = useDealBoardConfig();
  const { onCloseModal } = useModalState();
  const setIsOpenDrawer = useSetRecoilState(isOpenDrawerState);
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);
  const selectRef = useRef<HTMLDivElement>(null);
  useOutsideClick(selectRef, closePopper);

  const onSubmit = useCallback(async () => {
    onCloseModal();
    const missedReasonsString = selectedItems.map((i) => i.value).join(',');
    if (handler) {
      handler(deal, missedReasonsString, missedComments);
    } else {
      setIsOpenDrawer(false);
      await handleMissed(deal, missedReasonsString, missedComments);
    }
  }, [onCloseModal, selectedItems, handler, deal, missedComments, setIsOpenDrawer, handleMissed]);

  const onChange = (e: SyntheticEvent, newValues: Option[] | undefined) => {
    setSelectedItems(newValues ?? []);
  };

  const items = useMemo(() => {
    if (!data || !data?.missedReasons) return [];
    return data.missedReasons.map(({ id, reason: value }) => ({ id, value }));
  }, [data]);

  return (
    <Wrapper ref={selectRef}>
      <MuiMultiselect
        fieldPlaceholder='Select the Reason'
        options={items}
        value={selectedItems}
        onChange={onChange}
        open={open}
        onOpen={openPopper}
        onClose={closePopper}
        onFocus={(e) => e.stopPropagation()}
      />
      <TextField
        value={missedComments}
        onChange={(e) => setMissedComments(e.target.value)}
        variant='outlined'
        fullWidth
        placeholder='Add a comment'
        style={{ marginTop: '16px' }}
        onFocus={(e) => e.stopPropagation()}
      />
      <ModalActionButtons
        isSubmitDisabled={!selectedItems.length}
        onSubmit={onSubmit}
        onCancel={() => onCloseModal()}
        submitLabel='Mark as Missed'
      />
    </Wrapper>
  );
};
