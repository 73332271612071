import { atomFamily, selectorFamily } from 'recoil';
import { IMetricFundDataModel, IMetricsResponseDataModel } from '../../data-models/metrics.data-model';
import { fetchCompanyMetrics } from '../queries/MaggieMetricsQueries';

export const companyMetricsByIdState = atomFamily<IMetricsResponseDataModel | null, number>({
  key: 'companyMetricsRawById',
  default: (companyId) => {
    return fetchCompanyMetrics(companyId);
  },
});

export const restructureIdToPositionMapState = selectorFamily<Map<string, string>, number>({
  key: 'restructureIdToPositionMapState',
  get:
    (companyId) =>
    ({ get }) => {
      const companyMetrics = get(companyMetricsByIdState(companyId));
      const result = new Map<string, string>();
      companyMetrics?.metrics?.at(0)?.transactions?.reduce((map, transaction) => {
        if (transaction.restructureId && transaction.position) {
          map.set(transaction.restructureId, transaction.position);
        }
        return map;
      }, result);
      return result;
    },
});

export const metricsFundDataByFundIdState = selectorFamily<Map<number, IMetricFundDataModel>, number>({
  key: 'metricsFundDataByFundIdState',
  get:
    (companyId) =>
    ({ get }) => {
      const fundData = get(companyMetricsByIdState(companyId))?.metrics?.at(0)?.fundData ?? [];
      return fundData.reduce((map, fundData) => {
        map.set(fundData.fundId, fundData);
        return map;
      }, new Map<number, IMetricFundDataModel>());
    },
});
