export interface IDealTypeDataModel {
  id: number;
  name: string;
  displayName: string;
  sortOrder: number;
  meta: Record<string, unknown>;
  dealStageIds: number[];
  default: boolean;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

export function createDealTypeDataModel(overrides: Partial<IDealTypeDataModel> = {}): IDealTypeDataModel {
  return {
    id: 0,
    name: '',
    displayName: '',
    sortOrder: 0,
    meta: {},
    dealStageIds: [],
    default: false,
    createdBy: null,
    updatedBy: null,
    createdAt: '',
    updatedAt: '',
    ...overrides,
  };
}
