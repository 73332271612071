import { css } from '@emotion/react';
import { styled, Typography } from '@mui/material';
import { atom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../../../../components/Loader/Loader';
import { colors } from '../../../../../theme/colors';
import { LoadingStatus } from '../../../../../types';
import { useAsync } from '../../../../../util/hook-utils';
import {
  KPIReviewState,
  loadReviewerViewInitialData,
  ReviewerViewData,
  useKPIReviewerActions,
} from './hooks/useKPIRequestReviewActions';
import { KPIRenderedTemplate } from './KPIRenderedTemplate';
import { KPIReviewHeader } from './KPIReviewHeader';

const Background = styled('div')`
  background: ${({ theme }) => theme.gradients.primary};
`;

const headerWrapperCSS = css`
  background: white;
  margin-bottom: 2rem;
  border-bottom: 2px ${colors.neutral[40]} solid;
  padding: 1rem 5rem;
  position: sticky;
  top: 0;
  z-index: 5;
`;

const contentWrapperCss = css`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  padding: 0 5rem;
`;

export function KPIReviewerView() {
  const { requestId } = useParams<{ requestId: string }>();
  const { error, loading, data } = useAsync(
    useCallback(() => loadReviewerViewInitialData(Number(requestId)), [requestId])
  );

  switch (loading) {
    case LoadingStatus.loading:
      return <Loader />;

    case LoadingStatus.error:
      return <Typography>{error}</Typography>;

    case LoadingStatus.success:
    default:
      return <Layout initialData={data} />;
  }
}

type LayoutProps = {
  initialData: ReviewerViewData;
};

function Layout(props: LayoutProps) {
  const componentState = useMemo(
    () =>
      atom<KPIReviewState>({
        ...props.initialData,
        isModified: false,
        isSubmitting: false,
        submissionError: null,
      }),
    [props.initialData]
  );
  const { currentRequest, currentResponse, currentTemplate, isModified, isSubmitting } =
    useAtomValue(componentState);

  const { onSectionDataUpdated, onKPIGridDataUpdated, rejectResponse, acceptResponse } =
    useKPIReviewerActions(componentState);

  return (
    <Background>
      <div css={headerWrapperCSS}>
        <KPIReviewHeader
          company={props.initialData.company}
          isSubmitting={isSubmitting}
          kpiRequest={currentRequest}
          onAcceptResponse={acceptResponse}
          onRejectResponse={rejectResponse}
          isModified={isModified}
        />
      </div>
      {!isSubmitting ? (
        <div css={contentWrapperCss}>
          <GenericFallbacksWrapper>
            {currentTemplate ? (
              <KPIRenderedTemplate
                onKPIGridDataUpdated={onKPIGridDataUpdated}
                onSectionDataUpdated={onSectionDataUpdated}
                request={currentRequest}
                response={currentResponse}
                template={currentTemplate}
              />
            ) : (
              <Typography>Invalid Template</Typography>
            )}
          </GenericFallbacksWrapper>
        </div>
      ) : (
        <>
          <Typography>You are finished!</Typography>
          <div />
        </>
      )}
    </Background>
  );
}
