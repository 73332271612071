import { useRecoilCallback } from 'recoil';
import { fieldsMappingByCompanyState } from '../../../services/state/CompanyProvidersState';
import { IProviderFieldsDataModel } from '../../../data-models/provider-fields.data-model';

export function useGetProviderData() {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (companyIds: number[]) => {
        const providers = new Map<number, IProviderFieldsDataModel<unknown>[]>();
        const providerPromises = [] as Promise<IProviderFieldsDataModel<unknown>[] | null>[];

        companyIds.forEach((id) => {
          if (
            snapshot.getInfo_UNSTABLE(fieldsMappingByCompanyState(id)).isSet &&
            snapshot.getInfo_UNSTABLE(fieldsMappingByCompanyState(id)).loadable?.state === 'hasValue'
          ) {
            const providersForCompany = snapshot.getLoadable(fieldsMappingByCompanyState(id)).valueMaybe();
            if (providersForCompany) providers.set(id, providersForCompany);
          } else {
            const providersForCompanyPromise = snapshot.getPromise(fieldsMappingByCompanyState(id));
            providerPromises.push(providersForCompanyPromise);
          }
        });

        await Promise.all(providerPromises).then((providerData) => {
          providerData.reduce((acc, providersForCompany) => {
            // NB: if there are no providers for a company, it won't be in the map
            if (providersForCompany?.length) {
              const companyId = providersForCompany[0].companyId;
              acc.set(companyId, providersForCompany);
              set(fieldsMappingByCompanyState(companyId), providersForCompany);
            }
            return acc;
          }, providers);
        });

        return providers;
      },
    []
  );
}
