import { useCallback } from 'react';
import { ICompanyDataModel } from '../data-models/company.data-model';
import { useGetCompanyData } from '../pages/CompanyProfiles/hooks/useGetCompanyData';

export function useCombineCompaniesWithData() {
  const getCompanyData = useGetCompanyData();

 const mergeData = useCallback(
    async (data: { companyId: number }[]) => {
      const companies = await getCompanyData(data.map((d) => d.companyId));
      const result = data.map((dataItem) => {
        const company = companies.find((c) => c.id === dataItem.companyId) as ICompanyDataModel;
        return {
          ...dataItem,
          company,
        };
      });
      return result;
    },
    [getCompanyData]
  );

  return mergeData;
}
