import { subMonths } from 'date-fns';
import { KpiPeriod } from '../../../../data-models/company-financials.data-model';
import { monthsInPeriod } from '../../Performance/components/DateSettings/DateSettings';
import { endOfFiscalPeriod } from '../../utils/financialUtils';

export const PastPeriodRangeOptions: Record<KpiPeriod, number[]> = {
  [KpiPeriod.month]: [6, 12, 24],
  [KpiPeriod.quarter]: [4, 8, 12],
  [KpiPeriod.year]: [2, 3, 5],
};
export const FuturePeriodRangeOptions: Record<KpiPeriod, number[]> = {
  [KpiPeriod.month]: [12, 24],
  [KpiPeriod.quarter]: [4, 8],
  [KpiPeriod.year]: [2],
};
export function periodRangeToLabel(frequency: KpiPeriod, value: number, future: boolean = false) {
  return `${future ? 'Next' : 'Last'} ${value} ${frequency}s`;
}

export function rangeOptions(frequency: KpiPeriod, future: boolean = false) {
  const options = future ? FuturePeriodRangeOptions : PastPeriodRangeOptions;
  return Array.from(options[frequency]).map((value) => {
    return {
      value,
      displayName: periodRangeToLabel(frequency, value, future),
    };
  });
}

export function defaultRangeForFrequency(frequency: KpiPeriod, future: boolean = false) {
  const options = future ? FuturePeriodRangeOptions : PastPeriodRangeOptions;
  return options[frequency][0];
}

/** @param fye [0-11]  */
export function endOfPreviousFiscalPeriod(fye: number, frequency: KpiPeriod, currentDate: Date = new Date()) {
  const prevPeriodDate = subMonths(currentDate, monthsInPeriod[frequency]);
  return endOfFiscalPeriod(prevPeriodDate, frequency, fye);
}

export function financialsDateRangeLabel(
  frequency: KpiPeriod,
  pastRange: number | null,
  futureRange: number | null
) {
  return `${pastRange ? periodRangeToLabel(frequency, pastRange) : ''}${
    pastRange && futureRange ? ' / ' : ''
  }${futureRange ? periodRangeToLabel(frequency, futureRange, true) : ''}`;
}
