import { atom } from 'recoil';
import { User as FrontEggUser } from '@frontegg/redux-store/auth/interfaces';

// Prefer using useCurrentUser hook, this hook should only be used when auth
// information is required.
export const authenticatedUserState = atom<FrontEggUser | undefined>({
  key: 'authenticatedUserState',
  default: undefined,
});

export const sessionExpiredState = atom<boolean>({
  key: 'sessionExpiredState',
  default: false,
});
