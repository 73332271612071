import { CompetitiveIntelligenceSeriesItem } from '../data-models/competitive-intelligence.data-model';

export const tempTimeseries: CompetitiveIntelligenceSeriesItem[] = [
  {
    fieldId: 9,
    companyId: 4,
    value: [
      ['2023-03-31', 184164],
      ['2023-02-28', 141252],
      ['2023-01-31', 182829],
      ['2022-12-31', 140561],
      ['2022-11-30', 149234],
      ['2022-10-31', 191366],
      ['2022-09-30', 190979],
      ['2022-08-31', 180014],
      ['2022-07-31', 200844],
      ['2022-06-30', 185962],
      ['2022-05-31', 168641],
      ['2022-04-30', 159403],
      ['2022-03-31', 168847],
      ['2022-02-28', 181771],
      ['2022-01-31', 281079],
      ['2021-12-31', 340024],
      ['2021-11-30', 348561],
      ['2021-10-31', 335326],
      ['2021-09-30', 254970],
      ['2021-08-31', 328803],
      ['2021-07-31', 342306],
      ['2021-06-30', 296514],
      ['2021-05-31', 446481],
      ['2021-04-30', 354544],
    ],
  },
  {
    fieldId: 350,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 2],
      ['2023-02-01T00:00:00.000Z', 1],
      ['2023-01-01T00:00:00.000Z', 3],
      ['2022-12-01T00:00:00.000Z', 3],
      ['2022-11-01T00:00:00.000Z', 2],
      ['2022-10-01T00:00:00.000Z', 6],
      ['2022-09-01T00:00:00.000Z', 3],
      ['2022-08-01T00:00:00.000Z', 2],
      ['2022-07-01T00:00:00.000Z', 1],
      ['2022-06-01T00:00:00.000Z', 2],
      ['2022-05-01T00:00:00.000Z', 8],
      ['2022-04-01T00:00:00.000Z', 8],
      ['2022-03-01T00:00:00.000Z', 40],
      ['2022-02-01T00:00:00.000Z', 5],
      ['2022-01-01T00:00:00.000Z', 2],
      ['2021-12-01T00:00:00.000Z', 3],
      ['2021-11-01T00:00:00.000Z', 1],
      ['2021-10-01T00:00:00.000Z', 1],
      ['2021-09-01T00:00:00.000Z', 3],
      ['2021-08-01T00:00:00.000Z', 4],
      ['2021-07-01T00:00:00.000Z', 3],
      ['2021-06-01T00:00:00.000Z', 12],
      ['2021-05-01T00:00:00.000Z', 4],
    ],
  },
  {
    fieldId: 324,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 6],
      ['2023-02-01T00:00:00.000Z', 5],
      ['2023-01-01T00:00:00.000Z', 6],
      ['2022-12-01T00:00:00.000Z', 8],
      ['2022-11-01T00:00:00.000Z', 9],
      ['2022-10-01T00:00:00.000Z', 14],
      ['2022-09-01T00:00:00.000Z', 12],
      ['2022-08-01T00:00:00.000Z', 7],
      ['2022-07-01T00:00:00.000Z', 6],
      ['2022-06-01T00:00:00.000Z', 6],
      ['2022-05-01T00:00:00.000Z', 10],
      ['2022-04-01T00:00:00.000Z', 16],
      ['2022-03-01T00:00:00.000Z', 54],
      ['2022-02-01T00:00:00.000Z', 7],
      ['2022-01-01T00:00:00.000Z', 6],
      ['2021-12-01T00:00:00.000Z', 5],
      ['2021-11-01T00:00:00.000Z', 5],
      ['2021-10-01T00:00:00.000Z', 6],
      ['2021-09-01T00:00:00.000Z', 9],
      ['2021-08-01T00:00:00.000Z', 11],
      ['2021-07-01T00:00:00.000Z', 5],
      ['2021-06-01T00:00:00.000Z', 14],
      ['2021-05-01T00:00:00.000Z', 16],
    ],
  },
  {
    fieldId: 71,
    companyId: 4,
    value: [
      ['2023-02-21T05:50:49Z', 77000],
      ['2023-02-07T06:18:44Z', 77100],
      ['2023-01-24T05:26:12Z', 77200],
      ['2023-01-10T07:39:30Z', 77200],
      ['2022-12-19T06:24:53Z', 77300],
      ['2022-12-05T05:50:42Z', 77400],
      ['2022-10-28T06:05:52Z', 78400],
      ['2022-06-15T07:05:52Z', 78600],
      ['2022-05-30T08:40:23Z', 78600],
      ['2022-04-27T08:48:27Z', 78600],
      ['2022-04-26T18:48:55Z', 78600],
      ['2022-03-30T13:34:41Z', 78600],
      ['2022-03-15T12:56:25Z', 78600],
      ['2022-03-02T08:01:22Z', 78600],
      ['2022-03-01T13:44:25Z', 78600],
      ['2021-12-13T18:15:22Z', 78400],
      ['2021-12-12T06:22:27Z', 78400],
      ['2021-10-28T05:41:21Z', 77600],
      ['2021-10-24T16:31:18Z', 77600],
      ['2021-07-22T14:11:39Z', 76500],
      ['2021-07-02T18:31:12Z', 76200],
      ['2021-07-01T03:52:56Z', 76200],
    ],
  },
  {
    fieldId: 64,
    companyId: 4,
    value: [
      ['2023-02-21T05:50:49Z', 177662],
      ['2023-02-07T06:18:44Z', 177662],
      ['2023-01-24T05:26:12Z', 177662],
      ['2023-01-10T07:39:30Z', 177662],
      ['2022-12-19T06:24:53Z', 177662],
      ['2022-12-05T05:50:42Z', 177662],
      ['2022-10-28T06:05:52Z', 177662],
      ['2022-06-15T07:05:52Z', 177662],
      ['2022-05-30T08:40:23Z', 177662],
      ['2022-04-27T08:48:27Z', 177662],
      ['2022-04-26T18:48:55Z', 177662],
      ['2022-03-30T13:34:41Z', 177662],
      ['2022-03-15T12:56:25Z', 176980],
      ['2022-03-02T08:01:22Z', 175887],
      ['2022-03-01T13:44:25Z', 175887],
      ['2021-12-13T18:15:22Z', 165833],
      ['2021-12-12T06:22:27Z', 165833],
      ['2021-10-28T05:41:21Z', 158456],
      ['2021-10-24T16:31:18Z', 158456],
      ['2021-07-22T14:11:39Z', 155792],
    ],
  },
  { fieldId: 441, companyId: 4, value: null },
  { fieldId: 192, companyId: 4, value: [] },
  { fieldId: 196, companyId: 4, value: [] },
  { fieldId: 440, companyId: 4, value: null },
  {
    fieldId: 105,
    companyId: 4,
    value: [
      ['2023-03-31', 2351513.14444386],
      ['2023-02-28', 2385947.19959142],
      ['2023-01-31', 2521875.41480497],
      ['2022-12-31', 2639349.77420783],
      ['2022-11-30', 2659055.77865105],
      ['2022-10-31', 3007720.53872293],
      ['2022-09-30', 3020051.75482438],
      ['2022-08-31', 3080506.0982465],
      ['2022-07-31', 3042402.0399268],
      ['2022-06-30', 3008750.06925669],
      ['2022-05-31', 3122808.28536742],
      ['2022-04-30', 3355060.42259924],
      ['2022-03-31', 3237502.84688637],
      ['2022-02-28', 3721218.57790833],
      ['2022-01-31', 3853110.59334158],
      ['2021-12-31', 3793832.63373799],
      ['2021-11-30', 3674777.57816527],
      ['2021-10-31', 3867817.35573696],
      ['2021-09-30', 3687601.96503168],
      ['2021-08-31', 4497450.83130061],
      ['2021-07-31', 4384846.52871736],
      ['2021-06-30', 4130037.14314594],
      ['2021-05-31', 4702458.68621898],
      ['2021-04-30', 4677574.5973023],
    ],
  },
  { fieldId: 442, companyId: 4, value: null },
  {
    fieldId: 323,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 512],
      ['2023-02-01T00:00:00.000Z', 513],
      ['2023-01-01T00:00:00.000Z', 514],
      ['2022-12-01T00:00:00.000Z', 514],
      ['2022-11-01T00:00:00.000Z', 514],
      ['2022-10-01T00:00:00.000Z', 541],
      ['2022-09-01T00:00:00.000Z', 518],
      ['2022-08-01T00:00:00.000Z', 520],
      ['2022-07-01T00:00:00.000Z', 517],
      ['2022-06-01T00:00:00.000Z', 513],
      ['2022-05-01T00:00:00.000Z', 505],
      ['2022-04-01T00:00:00.000Z', 503],
      ['2022-03-01T00:00:00.000Z', 493],
      ['2022-02-01T00:00:00.000Z', 488],
      ['2022-01-01T00:00:00.000Z', 480],
      ['2021-12-01T00:00:00.000Z', 469],
      ['2021-11-01T00:00:00.000Z', 466],
      ['2021-10-01T00:00:00.000Z', 488],
      ['2021-09-01T00:00:00.000Z', 453],
      ['2021-08-01T00:00:00.000Z', 455],
      ['2021-07-01T00:00:00.000Z', 442],
      ['2021-06-01T00:00:00.000Z', 436],
      ['2021-05-01T00:00:00.000Z', 428],
    ],
  },
  {
    fieldId: 412,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 2],
      ['2023-02-01T00:00:00.000Z', 3],
      ['2023-01-01T00:00:00.000Z', 4],
      ['2022-12-01T00:00:00.000Z', 2],
      ['2022-11-01T00:00:00.000Z', 3],
      ['2022-10-01T00:00:00.000Z', 34],
      ['2022-09-01T00:00:00.000Z', 11],
      ['2022-08-01T00:00:00.000Z', 10],
      ['2022-07-01T00:00:00.000Z', 10],
      ['2022-06-01T00:00:00.000Z', 19],
      ['2022-05-01T00:00:00.000Z', 13],
      ['2022-04-01T00:00:00.000Z', 19],
      ['2022-03-01T00:00:00.000Z', 7],
      ['2022-02-01T00:00:00.000Z', 15],
      ['2022-01-01T00:00:00.000Z', 23],
      ['2021-12-01T00:00:00.000Z', 11],
      ['2021-11-01T00:00:00.000Z', 13],
      ['2021-10-01T00:00:00.000Z', 45],
      ['2021-09-01T00:00:00.000Z', 7],
      ['2021-08-01T00:00:00.000Z', 20],
      ['2021-07-01T00:00:00.000Z', 14],
      ['2021-06-01T00:00:00.000Z', 15],
      ['2021-05-01T00:00:00.000Z', 8],
    ],
  },
  { fieldId: 188, companyId: 4, value: [] },
  {
    fieldId: 338,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 2],
      ['2023-02-01T00:00:00.000Z', 2],
      ['2023-01-01T00:00:00.000Z', 1],
      ['2022-12-01T00:00:00.000Z', 1],
      ['2022-11-01T00:00:00.000Z', 1],
      ['2022-10-01T00:00:00.000Z', 5],
      ['2022-09-01T00:00:00.000Z', 7],
      ['2022-08-01T00:00:00.000Z', 2],
      ['2022-07-01T00:00:00.000Z', 2],
      ['2022-06-01T00:00:00.000Z', 4],
      ['2022-05-01T00:00:00.000Z', 2],
      ['2022-04-01T00:00:00.000Z', 2],
      ['2022-03-01T00:00:00.000Z', 52],
      ['2022-02-01T00:00:00.000Z', 3],
      ['2022-01-01T00:00:00.000Z', 4],
      ['2021-12-01T00:00:00.000Z', 1],
      ['2021-09-01T00:00:00.000Z', 2],
      ['2021-08-01T00:00:00.000Z', 9],
      ['2021-07-01T00:00:00.000Z', 3],
      ['2021-06-01T00:00:00.000Z', 2],
    ],
  },
  {
    fieldId: 416,
    companyId: 4,
    value: [
      ['2023-03-01T00:00:00.000Z', 1],
      ['2023-02-01T00:00:00.000Z', 3],
      ['2023-01-01T00:00:00.000Z', 4],
      ['2022-12-01T00:00:00.000Z', 4],
      ['2022-11-01T00:00:00.000Z', 2],
      ['2022-10-01T00:00:00.000Z', 30],
      ['2022-09-01T00:00:00.000Z', 11],
      ['2022-08-01T00:00:00.000Z', 13],
      ['2022-07-01T00:00:00.000Z', 7],
      ['2022-06-01T00:00:00.000Z', 6],
      ['2022-05-01T00:00:00.000Z', 11],
      ['2022-04-01T00:00:00.000Z', 11],
      ['2022-03-01T00:00:00.000Z', 9],
      ['2022-02-01T00:00:00.000Z', 2],
      ['2022-01-01T00:00:00.000Z', 7],
      ['2021-12-01T00:00:00.000Z', 12],
      ['2021-11-01T00:00:00.000Z', 8],
      ['2021-10-01T00:00:00.000Z', 35],
      ['2021-09-01T00:00:00.000Z', 10],
      ['2021-08-01T00:00:00.000Z', 9],
      ['2021-07-01T00:00:00.000Z', 7],
      ['2021-06-01T00:00:00.000Z', 8],
      ['2021-05-01T00:00:00.000Z', 7],
    ],
  },
  {
    fieldId: 59,
    companyId: 4,
    value: [
      ['2023-02-21T05:50:49Z', 167143],
      ['2023-02-07T06:18:44Z', 167136],
      ['2023-01-24T05:26:12Z', 167114],
      ['2023-01-10T07:39:30Z', 167125],
      ['2022-12-19T06:24:53Z', 167108],
      ['2022-12-05T05:50:42Z', 167115],
      ['2022-10-28T06:05:52Z', 167041],
      ['2022-06-15T07:05:52Z', 166918],
      ['2022-05-30T08:40:23Z', 166918],
      ['2022-04-27T08:48:27Z', 166918],
      ['2022-04-26T18:48:55Z', 166918],
      ['2022-03-30T13:34:41Z', 166918],
      ['2022-03-15T12:56:25Z', 166918],
      ['2022-03-02T08:01:22Z', 166918],
      ['2022-03-01T13:44:25Z', 166918],
      ['2021-12-13T18:15:22Z', 166918],
      ['2021-12-12T06:22:27Z', 166918],
      ['2021-10-28T05:41:21Z', 166143],
      ['2021-10-24T16:31:18Z', 166143],
      ['2021-07-22T14:11:39Z', 166143],
      ['2021-07-02T18:31:12Z', 166087],
      ['2021-07-01T03:52:56Z', 166087],
    ],
  },
  {
    fieldId: 79,
    companyId: 4,
    value: [
      ['2023-03-31', 3.410098103],
      ['2023-02-28', 3.079088598],
      ['2023-01-31', 3.40574679],
      ['2022-12-31', 3.215683908],
      ['2022-11-30', 3.61685843],
      ['2022-10-31', 3.943322289],
      ['2022-09-30', 3.975836948],
      ['2022-08-31', 4.121110542],
      ['2022-07-31', 3.891240729],
      ['2022-06-30', 4.242051842],
      ['2022-05-31', 4.265864004],
      ['2022-04-30', 3.955608325],
      ['2022-03-31', 3.738746669],
      ['2022-02-28', 3.382377777],
      ['2022-01-31', 4.117530151],
      ['2021-12-31', 3.698353692],
      ['2021-11-30', 3.961538916],
      ['2021-10-31', 3.967673147],
      ['2021-09-30', 4.1667934],
      ['2021-08-31', 3.860185779],
      ['2021-07-31', 3.465630096],
      ['2021-06-30', 3.438045123],
      ['2021-05-31', 3.17790533],
      ['2021-04-30', 3.705424132],
    ],
  },
  {
    fieldId: 69,
    companyId: 4,
    value: [
      ['2023-02-21T05:50:49Z', 39970],
      ['2023-02-07T06:18:44Z', 39887],
      ['2023-01-24T05:26:12Z', 39686],
      ['2023-01-10T07:39:30Z', 39561],
      ['2022-12-19T06:24:53Z', 39471],
      ['2022-12-05T05:50:42Z', 39361],
      ['2022-10-28T06:05:52Z', 39049],
      ['2022-06-15T07:05:52Z', 37825],
      ['2022-05-30T08:40:23Z', 37608],
      ['2022-04-27T08:48:27Z', 36791],
      ['2022-04-26T18:48:55Z', 36791],
      ['2022-03-30T13:34:41Z', 36791],
      ['2022-03-15T12:56:25Z', 36433],
      ['2022-03-02T08:01:22Z', 36057],
      ['2022-03-01T13:44:25Z', 36057],
      ['2021-12-13T18:15:22Z', 34157],
      ['2021-12-12T06:22:27Z', 34157],
      ['2021-10-28T05:41:21Z', 32903],
      ['2021-10-24T16:31:18Z', 32903],
      ['2021-07-22T14:11:39Z', 30886],
      ['2021-07-02T18:31:12Z', 30124],
      ['2021-07-01T03:52:56Z', 30124],
    ],
  },
  {
    fieldId: 9,
    companyId: 353,
    value: [
      ['2023-03-31', 7692],
      ['2023-02-28', 9868],
      ['2023-01-31', 11540],
      ['2022-12-31', 10638],
      ['2022-11-30', 13317],
      ['2022-10-31', 13610],
      ['2022-09-30', 22440],
      ['2022-08-31', 29098],
      ['2022-07-31', 48748],
      ['2022-06-30', 50730],
      ['2022-05-31', 37135],
      ['2022-04-30', 31464],
      ['2022-03-31', 46458],
      ['2022-02-28', 67970],
      ['2022-01-31', 101820],
      ['2021-12-31', 110572],
      ['2021-11-30', 129504],
      ['2021-10-31', 106410],
      ['2021-09-30', 118737],
      ['2021-08-31', 126534],
      ['2021-07-31', 138052],
      ['2021-06-30', 98552],
      ['2021-05-31', 74934],
      ['2021-04-30', 69974],
    ],
  },
  { fieldId: 350, companyId: 353, value: [] },
  { fieldId: 324, companyId: 353, value: [] },
  {
    fieldId: 71,
    companyId: 353,
    value: [
      ['2023-04-07T05:45:49Z', 38700],
      ['2023-03-17T08:27:27Z', 38700],
      ['2023-03-03T08:02:57Z', 38700],
      ['2023-02-17T07:13:53Z', 38700],
      ['2023-02-03T05:25:06Z', 38700],
      ['2023-01-20T07:43:38Z', 38700],
      ['2023-01-06T06:41:47Z', 38700],
      ['2022-12-19T06:57:30Z', 38600],
      ['2022-12-05T05:34:26Z', 38700],
      ['2022-11-21T08:07:57Z', 38800],
      ['2022-11-07T05:27:24Z', 38900],
      ['2022-10-24T21:49:25Z', 39100],
      ['2022-10-14T07:14:43Z', 39100],
      ['2022-10-03T05:30:47Z', 39100],
      ['2022-09-17T11:22:44Z', 39000],
      ['2022-08-25T08:18:26Z', 38900],
      ['2022-08-11T08:36:10Z', 38800],
      ['2022-07-28T06:00:02Z', 38800],
      ['2022-07-13T05:50:02Z', 38800],
      ['2022-06-29T05:55:35Z', 38800],
      ['2022-06-15T05:46:56Z', 38800],
      ['2022-05-18T06:45:17Z', 38800],
      ['2022-05-04T13:11:27Z', 38800],
      ['2022-04-27T08:35:31Z', 38800],
      ['2022-04-26T19:40:12Z', 38800],
      ['2022-03-20T13:35:45Z', 38500],
      ['2022-03-04T16:44:40Z', 38200],
      ['2022-02-15T06:35:26Z', 38200],
      ['2022-01-21T09:23:55Z', 38100],
      ['2021-12-25T14:26:39Z', 37600],
      ['2021-12-09T06:31:16Z', 37500],
      ['2021-11-24T19:02:01Z', 37100],
      ['2021-11-13T23:45:20Z', 36500],
      ['2021-11-11T06:32:07Z', 36500],
      ['2021-10-30T08:55:22Z', 35800],
      ['2021-10-28T04:34:38Z', 35000],
      ['2021-10-25T19:11:10Z', 35000],
      ['2021-10-23T21:13:54Z', 33700],
      ['2021-10-21T15:36:40Z', 33700],
      ['2021-10-13T23:53:27Z', 32299],
      ['2021-10-07T03:20:08Z', 32100],
      ['2021-09-30T00:29:35Z', 32100],
      ['2021-09-26T19:04:06Z', 32100],
      ['2021-09-26T15:45:47Z', 32100],
      ['2021-09-23T13:51:19Z', 32000],
      ['2021-09-17T20:29:48Z', 32000],
      ['2021-09-05T00:00:00Z', 31500],
      ['2021-08-20T00:00:00Z', 31500],
      ['2021-08-04T14:08:45Z', 31500],
      ['2021-07-30T15:51:35Z', 31000],
      ['2021-07-14T14:43:09Z', 30800],
      ['2021-06-30T17:37:55Z', 30700],
      ['2021-06-13T09:44:32Z', 30300],
      ['2021-06-10T23:27:15Z', 30300],
      ['2021-06-07T16:59:35Z', 30300],
      ['2021-06-01T19:17:15Z', 30300],
      ['2021-05-31T17:19:57Z', 30300],
      ['2021-04-30T00:20:54Z', 29100],
      ['2021-04-15T01:02:09Z', 29100],
    ],
  },
  {
    fieldId: 64,
    companyId: 353,
    value: [
      ['2023-04-07T05:45:49Z', 41631],
      ['2023-03-17T08:27:27Z', 41631],
      ['2023-03-03T08:02:57Z', 41631],
      ['2023-02-17T07:13:53Z', 41631],
      ['2023-02-03T05:25:06Z', 41631],
      ['2023-01-20T07:43:38Z', 41631],
      ['2023-01-06T06:41:47Z', 41631],
      ['2022-12-19T06:57:30Z', 41631],
      ['2022-12-05T05:34:26Z', 41631],
      ['2022-11-21T08:07:57Z', 41631],
      ['2022-11-07T05:27:24Z', 41631],
      ['2022-10-24T21:49:25Z', 41631],
      ['2022-10-14T07:14:43Z', 41631],
      ['2022-10-03T05:30:47Z', 41631],
      ['2022-09-17T11:22:44Z', 41631],
      ['2022-08-25T08:18:26Z', 41631],
      ['2022-08-11T08:36:10Z', 41631],
      ['2022-07-28T06:00:02Z', 41631],
      ['2022-07-13T05:50:02Z', 41631],
      ['2022-06-29T05:55:35Z', 41877],
      ['2022-06-15T05:46:56Z', 41877],
      ['2022-05-18T06:45:17Z', 41877],
      ['2022-05-04T13:11:27Z', 41677],
      ['2022-04-27T08:35:31Z', 41677],
      ['2022-04-26T19:40:12Z', 41677],
      ['2022-03-20T13:35:45Z', 41064],
      ['2022-03-04T16:44:40Z', 40388],
      ['2022-02-15T06:35:26Z', 39528],
      ['2022-01-21T09:23:55Z', 37811],
      ['2021-12-30T17:23:07Z', 37215],
      ['2021-12-25T14:26:39Z', 37070],
      ['2021-12-09T06:31:16Z', 36506],
      ['2021-11-24T19:02:01Z', 35070],
      ['2021-11-13T23:45:20Z', 34154],
      ['2021-11-11T06:32:07Z', 34154],
      ['2021-10-30T08:55:22Z', 32968],
      ['2021-10-28T04:34:38Z', 32762],
      ['2021-10-25T19:11:10Z', 32762],
      ['2021-10-23T21:13:54Z', 32499],
      ['2021-10-21T15:36:40Z', 32499],
      ['2021-10-13T23:53:27Z', 32304],
      ['2021-10-07T03:20:08Z', 31936],
      ['2021-09-30T00:29:35Z', 31709],
      ['2021-09-26T19:04:06Z', 31619],
      ['2021-09-26T15:45:47Z', 31619],
      ['2021-09-23T13:51:19Z', 31512],
      ['2021-09-17T20:29:48Z', 31174],
      ['2021-09-05T00:00:00Z', 29131],
      ['2021-08-20T00:00:00Z', 29131],
      ['2021-08-04T14:08:45Z', 29131],
      ['2021-07-30T15:51:35Z', 29131],
      ['2021-07-14T14:43:09Z', 27911],
      ['2021-06-30T17:37:55Z', 22687],
      ['2021-06-13T09:44:32Z', 22687],
      ['2021-06-10T23:27:15Z', 22687],
      ['2021-06-07T16:59:35Z', 22687],
      ['2021-06-01T19:17:15Z', 22687],
      ['2021-05-31T17:19:57Z', 22687],
      ['2021-04-30T00:20:54Z', 22687],
      ['2021-04-15T01:02:09Z', 22687],
    ],
  },
  { fieldId: 441, companyId: 353, value: null },
  { fieldId: 192, companyId: 353, value: [] },
  { fieldId: 196, companyId: 353, value: [] },
  { fieldId: 440, companyId: 353, value: null },
  {
    fieldId: 105,
    companyId: 353,
    value: [
      ['2023-03-31', 278728],
      ['2023-02-28', 270505],
      ['2023-01-31', 263882],
      ['2022-12-31', 268367],
      ['2022-11-30', 318777],
      ['2022-10-31', 370009],
      ['2022-09-30', 389986],
      ['2022-08-31', 406457],
      ['2022-07-31', 413534],
      ['2022-06-30', 422763],
      ['2022-05-31', 443245],
      ['2022-04-30', 486304],
      ['2022-03-31', 543310],
      ['2022-02-28', 619551],
      ['2022-01-31', 645502],
      ['2021-12-31', 668554],
      ['2021-11-30', 629952],
      ['2021-10-31', 580801],
      ['2021-09-30', 544171],
      ['2021-08-31', 577869],
      ['2021-07-31', 514156],
      ['2021-06-30', 472415],
      ['2021-05-31', 406156],
      ['2021-04-30', 398324],
    ],
  },
  { fieldId: 442, companyId: 353, value: null },
  {
    fieldId: 323,
    companyId: 353,
    value: [
      ['2023-03-01T00:00:00.000Z', 410],
      ['2023-02-01T00:00:00.000Z', 412],
      ['2023-01-01T00:00:00.000Z', 415],
      ['2022-12-01T00:00:00.000Z', 417],
      ['2022-11-01T00:00:00.000Z', 428],
      ['2022-10-01T00:00:00.000Z', 421],
      ['2022-09-01T00:00:00.000Z', 415],
      ['2022-08-01T00:00:00.000Z', 418],
      ['2022-07-01T00:00:00.000Z', 421],
      ['2022-06-01T00:00:00.000Z', 420],
      ['2022-05-01T00:00:00.000Z', 421],
      ['2022-04-01T00:00:00.000Z', 409],
      ['2022-03-01T00:00:00.000Z', 414],
      ['2022-02-01T00:00:00.000Z', 408],
      ['2022-01-01T00:00:00.000Z', 403],
      ['2021-12-01T00:00:00.000Z', 391],
      ['2021-11-01T00:00:00.000Z', 393],
      ['2021-10-01T00:00:00.000Z', 387],
      ['2021-09-01T00:00:00.000Z', 374],
      ['2021-08-01T00:00:00.000Z', 368],
      ['2021-07-01T00:00:00.000Z', 359],
      ['2021-06-01T00:00:00.000Z', 355],
      ['2021-05-01T00:00:00.000Z', 355],
    ],
  },
  {
    fieldId: 412,
    companyId: 353,
    value: [
      ['2023-02-01T00:00:00.000Z', 3],
      ['2023-01-01T00:00:00.000Z', 2],
      ['2022-12-01T00:00:00.000Z', 3],
      ['2022-11-01T00:00:00.000Z', 12],
      ['2022-10-01T00:00:00.000Z', 9],
      ['2022-09-01T00:00:00.000Z', 5],
      ['2022-08-01T00:00:00.000Z', 5],
      ['2022-07-01T00:00:00.000Z', 6],
      ['2022-06-01T00:00:00.000Z', 8],
      ['2022-05-01T00:00:00.000Z', 18],
      ['2022-04-01T00:00:00.000Z', 5],
      ['2022-03-01T00:00:00.000Z', 11],
      ['2022-02-01T00:00:00.000Z', 13],
      ['2022-01-01T00:00:00.000Z', 16],
      ['2021-12-01T00:00:00.000Z', 2],
      ['2021-11-01T00:00:00.000Z', 11],
      ['2021-10-01T00:00:00.000Z', 15],
      ['2021-09-01T00:00:00.000Z', 16],
      ['2021-08-01T00:00:00.000Z', 12],
      ['2021-07-01T00:00:00.000Z', 12],
      ['2021-06-01T00:00:00.000Z', 13],
      ['2021-05-01T00:00:00.000Z', 8],
    ],
  },
  { fieldId: 188, companyId: 353, value: [] },
  { fieldId: 338, companyId: 353, value: [] },
  {
    fieldId: 416,
    companyId: 353,
    value: [
      ['2023-03-01T00:00:00.000Z', 1],
      ['2023-02-01T00:00:00.000Z', 2],
      ['2023-01-01T00:00:00.000Z', 6],
      ['2022-12-01T00:00:00.000Z', 4],
      ['2022-11-01T00:00:00.000Z', 14],
      ['2022-10-01T00:00:00.000Z', 5],
      ['2022-09-01T00:00:00.000Z', 3],
      ['2022-08-01T00:00:00.000Z', 8],
      ['2022-07-01T00:00:00.000Z', 8],
      ['2022-06-01T00:00:00.000Z', 5],
      ['2022-05-01T00:00:00.000Z', 9],
      ['2022-04-01T00:00:00.000Z', 6],
      ['2022-03-01T00:00:00.000Z', 10],
      ['2022-02-01T00:00:00.000Z', 5],
      ['2022-01-01T00:00:00.000Z', 8],
      ['2021-12-01T00:00:00.000Z', 4],
      ['2021-11-01T00:00:00.000Z', 4],
      ['2021-10-01T00:00:00.000Z', 5],
      ['2021-09-01T00:00:00.000Z', 2],
      ['2021-08-01T00:00:00.000Z', 10],
      ['2021-07-01T00:00:00.000Z', 3],
      ['2021-06-01T00:00:00.000Z', 8],
      ['2021-05-01T00:00:00.000Z', 13],
    ],
  },
  {
    fieldId: 59,
    companyId: 353,
    value: [
      ['2023-04-07T05:45:49Z', 4309],
      ['2023-03-17T08:27:27Z', 4302],
      ['2023-03-03T08:02:57Z', 4297],
      ['2023-02-17T07:13:53Z', 4284],
      ['2023-02-03T05:25:06Z', 4281],
      ['2023-01-20T07:43:38Z', 4271],
      ['2023-01-06T06:41:47Z', 4271],
      ['2022-12-19T06:57:30Z', 4266],
      ['2022-12-05T05:34:26Z', 4260],
      ['2022-11-21T08:07:57Z', 4252],
      ['2022-11-07T05:27:24Z', 4233],
      ['2022-10-24T21:49:25Z', 4234],
      ['2022-10-14T07:14:43Z', 4234],
      ['2022-10-03T05:30:47Z', 4222],
      ['2022-09-17T11:22:44Z', 4212],
      ['2022-08-25T08:18:26Z', 4206],
      ['2022-08-11T08:36:10Z', 4202],
      ['2022-07-28T06:00:02Z', 3772],
      ['2022-07-13T05:50:02Z', 3772],
      ['2022-06-29T05:55:35Z', 3772],
      ['2022-06-15T05:46:56Z', 3772],
      ['2022-05-18T06:45:17Z', 3772],
      ['2022-05-04T13:11:27Z', 3772],
      ['2022-04-27T08:35:31Z', 3772],
      ['2022-04-26T19:40:12Z', 3772],
      ['2022-03-20T13:35:45Z', 3772],
      ['2022-03-04T16:44:40Z', 3772],
      ['2022-02-15T06:35:26Z', 3772],
      ['2022-01-21T09:23:55Z', 3772],
      ['2021-12-30T17:23:07Z', 3758],
      ['2021-12-25T14:26:39Z', 3750],
      ['2021-12-09T06:31:16Z', 3729],
      ['2021-11-24T19:02:01Z', 3634],
      ['2021-11-13T23:45:20Z', 3634],
      ['2021-11-11T06:32:07Z', 3634],
      ['2021-10-30T08:55:22Z', 3554],
      ['2021-10-28T04:34:38Z', 3522],
      ['2021-10-25T19:11:10Z', 3522],
      ['2021-10-23T21:13:54Z', 3501],
      ['2021-10-21T15:36:40Z', 3501],
      ['2021-10-13T23:53:27Z', 3482],
      ['2021-10-07T03:20:08Z', 3464],
      ['2021-09-30T00:29:35Z', 3438],
      ['2021-09-26T19:04:06Z', 3438],
      ['2021-09-26T15:45:47Z', 3438],
      ['2021-09-23T13:51:19Z', 3435],
      ['2021-09-17T20:29:48Z', 3423],
      ['2021-09-05T00:00:00Z', 3302],
      ['2021-08-20T00:00:00Z', 3302],
      ['2021-08-04T14:08:45Z', 3302],
      ['2021-07-30T15:51:35Z', 3302],
      ['2021-07-14T14:43:09Z', 3242],
      ['2021-06-30T17:37:55Z', 3242],
      ['2021-06-13T09:44:32Z', 3209],
      ['2021-06-10T23:27:15Z', 3202],
      ['2021-06-07T16:59:35Z', 3202],
      ['2021-06-01T19:17:15Z', 3180],
      ['2021-05-31T17:19:57Z', 3180],
      ['2021-04-30T00:20:54Z', 2970],
      ['2021-04-15T01:02:09Z', 2970],
    ],
  },
  {
    fieldId: 79,
    companyId: 353,
    value: [
      ['2023-03-31', 31.089377833],
      ['2023-02-28', 66.259249232],
      ['2023-01-31', 63.333569656],
      ['2022-12-31', 44.047639644],
      ['2022-11-30', 68.823269093],
      ['2022-10-31', 66.632915937],
      ['2022-09-30', 74.286207323],
      ['2022-08-31', 64.22067405],
      ['2022-07-31', 55.402797811],
      ['2022-06-30', 65.413906936],
      ['2022-05-31', 54.583511208],
      ['2022-04-30', 67.088838251],
      ['2022-03-31', 77.069163271],
      ['2022-02-28', 67.230153493],
      ['2022-01-31', 60.024370561],
      ['2021-12-31', 69.510957597],
      ['2021-11-30', 69.008997915],
      ['2021-10-31', 64.348519229],
      ['2021-09-30', 56.712813857],
      ['2021-08-31', 37.593217664],
      ['2021-07-31', 47.517761772],
      ['2021-06-30', 43.903966969],
      ['2021-05-31', 40.712196618],
      ['2021-04-30', 43.095997483],
    ],
  },
  {
    fieldId: 69,
    companyId: 353,
    value: [
      ['2023-04-07T05:45:49Z', 9162],
      ['2023-03-17T08:27:27Z', 9067],
      ['2023-03-03T08:02:57Z', 8978],
      ['2023-02-17T07:13:53Z', 8857],
      ['2023-02-03T05:25:06Z', 8741],
      ['2023-01-20T07:43:38Z', 8691],
      ['2023-01-06T06:41:47Z', 8604],
      ['2022-12-19T06:57:30Z', 8583],
      ['2022-12-05T05:34:26Z', 8524],
      ['2022-11-21T08:07:57Z', 8486],
      ['2022-11-07T05:27:24Z', 8382],
      ['2022-10-24T21:49:25Z', 8277],
      ['2022-10-14T07:14:43Z', 8277],
      ['2022-10-03T05:30:47Z', 8160],
      ['2022-09-17T11:22:44Z', 8019],
      ['2022-08-25T08:18:26Z', 7932],
      ['2022-08-11T08:36:10Z', 7884],
      ['2022-07-28T06:00:02Z', 7828],
      ['2022-07-13T05:50:02Z', 7775],
      ['2022-06-29T05:55:35Z', 7715],
      ['2022-06-15T05:46:56Z', 7648],
      ['2022-05-18T06:45:17Z', 7460],
      ['2022-05-04T13:11:27Z', 7275],
      ['2022-04-27T08:35:31Z', 7275],
      ['2022-04-26T19:40:12Z', 7275],
      ['2022-03-20T13:35:45Z', 7054],
      ['2022-03-04T16:44:40Z', 6912],
      ['2022-02-15T06:35:26Z', 6772],
      ['2022-01-21T09:23:55Z', 6604],
      ['2021-12-30T17:23:07Z', 6388],
      ['2021-12-25T14:26:39Z', 6348],
      ['2021-12-09T06:31:16Z', 6230],
      ['2021-11-24T19:02:01Z', 6092],
      ['2021-11-13T23:45:20Z', 5968],
      ['2021-11-11T06:32:07Z', 5968],
      ['2021-10-30T08:55:22Z', 5863],
      ['2021-10-28T04:34:38Z', 5793],
      ['2021-10-25T19:11:10Z', 5793],
      ['2021-10-23T21:13:54Z', 5793],
      ['2021-10-21T15:36:40Z', 5793],
      ['2021-10-13T23:53:27Z', 5745],
      ['2021-10-07T03:20:08Z', 5626],
      ['2021-09-30T00:29:35Z', 5565],
      ['2021-09-26T19:04:06Z', 5543],
      ['2021-09-26T15:45:47Z', 5543],
      ['2021-09-23T13:51:19Z', 5511],
      ['2021-09-17T20:29:48Z', 5452],
      ['2021-09-05T00:00:00Z', 5083],
      ['2021-08-20T00:00:00Z', 5083],
      ['2021-08-04T14:08:45Z', 5083],
      ['2021-07-30T15:51:35Z', 5010],
      ['2021-07-14T14:43:09Z', 4901],
      ['2021-06-30T17:37:55Z', 4819],
      ['2021-06-13T09:44:32Z', 4702],
      ['2021-06-10T23:27:15Z', 4702],
      ['2021-06-07T16:59:35Z', 4622],
      ['2021-06-01T19:17:15Z', 4572],
      ['2021-05-31T17:19:57Z', 4572],
      ['2021-04-30T00:20:54Z', 4121],
      ['2021-04-15T01:02:09Z', 4121],
    ],
  },
  { fieldId: 9, companyId: 5780, value: null },
  { fieldId: 350, companyId: 5780, value: [] },
  { fieldId: 324, companyId: 5780, value: [] },
  {
    fieldId: 71,
    companyId: 5780,
    value: [
      ['2023-03-20T06:22:19Z', 24800],
      ['2023-03-06T09:01:03Z', 24800],
      ['2023-02-20T07:22:32Z', 24800],
      ['2023-02-06T07:39:16Z', 24800],
      ['2023-01-23T06:01:02Z', 24800],
      ['2023-01-09T06:37:18Z', 24800],
      ['2022-12-22T06:32:42Z', 24800],
      ['2022-12-08T06:40:36Z', 24900],
      ['2022-11-24T07:31:52Z', 24900],
      ['2022-11-10T06:21:06Z', 25200],
      ['2022-10-27T06:35:31Z', 25600],
      ['2022-10-14T09:00:41Z', 25600],
      ['2022-10-05T06:17:01Z', 25500],
      ['2022-09-19T08:05:12Z', 25500],
      ['2022-08-27T08:32:46Z', 25500],
      ['2022-07-17T06:17:49Z', 25400],
      ['2022-07-03T11:00:09Z', 25300],
      ['2022-07-03T07:27:55Z', 25300],
      ['2022-06-15T06:29:16Z', 25300],
      ['2022-05-21T10:07:59Z', 25300],
      ['2022-05-06T15:24:45Z', 25300],
      ['2022-04-27T09:30:32Z', 25200],
      ['2022-04-26T19:32:06Z', 25200],
      ['2022-03-22T19:31:46Z', 25000],
      ['2022-03-09T07:22:40Z', 24936],
      ['2022-02-18T23:42:10Z', 24900],
      ['2022-02-18T06:40:30Z', 24900],
      ['2022-02-09T03:03:30Z', 24800],
      ['2022-01-29T22:34:44Z', 24800],
      ['2022-01-28T00:25:53Z', 24800],
      ['2021-12-21T22:11:06Z', 24700],
      ['2021-12-20T11:52:36Z', 24700],
      ['2021-11-18T15:19:35Z', 24600],
      ['2021-10-29T08:15:47Z', 24500],
      ['2021-10-28T04:52:47Z', 24400],
      ['2021-10-25T15:13:43Z', 24400],
      ['2021-10-24T01:55:37Z', 24400],
      ['2021-10-22T23:16:46Z', 24400],
      ['2021-10-19T22:18:12Z', 24400],
      ['2021-10-01T11:38:52Z', 24400],
      ['2021-09-19T19:17:01Z', 24300],
      ['2021-09-17T21:05:40Z', 24300],
      ['2021-09-05T00:00:00Z', 24200],
      ['2021-08-20T00:00:00Z', 24200],
      ['2021-08-04T14:40:55Z', 24200],
      ['2021-07-21T14:24:07Z', 24100],
      ['2021-07-02T12:19:54Z', 24000],
      ['2021-06-13T06:54:39Z', 23900],
      ['2021-06-11T12:49:36Z', 23900],
      ['2021-06-11T07:17:33Z', 23900],
      ['2021-06-09T20:51:05Z', 23900],
      ['2021-06-09T18:13:40Z', 23900],
      ['2021-06-07T17:16:57Z', 23900],
      ['2021-04-30T00:21:15Z', 23600],
      ['2021-04-15T01:03:18Z', 23600],
    ],
  },
  {
    fieldId: 64,
    companyId: 5780,
    value: [
      ['2023-03-20T06:22:19Z', 350147],
      ['2023-03-06T09:01:03Z', 350147],
      ['2023-02-20T07:22:32Z', 350147],
      ['2023-02-06T07:39:16Z', 350147],
      ['2023-01-23T06:01:02Z', 350147],
      ['2023-01-09T06:37:18Z', 350147],
      ['2022-12-22T06:32:42Z', 350147],
      ['2022-12-08T06:40:36Z', 350147],
      ['2022-11-24T07:31:52Z', 350147],
      ['2022-11-10T06:21:06Z', 350147],
      ['2022-10-27T06:35:31Z', 350147],
      ['2022-10-14T09:00:41Z', 350147],
      ['2022-10-05T06:17:01Z', 350147],
      ['2022-09-19T08:05:12Z', 350147],
      ['2022-08-27T08:32:46Z', 350147],
      ['2022-07-17T06:17:49Z', 350147],
      ['2022-07-03T11:00:09Z', 347220],
      ['2022-07-03T07:27:55Z', 347220],
      ['2022-06-15T06:29:16Z', 347220],
      ['2022-05-21T10:07:59Z', 347220],
      ['2022-05-06T15:24:45Z', 347220],
      ['2022-04-27T09:30:32Z', 347220],
      ['2022-04-26T19:32:06Z', 347220],
      ['2022-03-22T19:31:46Z', 342644],
      ['2022-03-09T07:22:40Z', 340327],
      ['2022-02-18T23:42:10Z', 337360],
      ['2022-02-18T06:40:30Z', 337360],
      ['2022-02-09T03:03:30Z', 335594],
      ['2022-01-29T22:34:44Z', 331289],
      ['2022-01-28T00:25:53Z', 331289],
      ['2022-01-02T01:51:14Z', 318177],
      ['2021-12-21T22:11:06Z', 314228],
      ['2021-12-20T11:52:36Z', 314228],
      ['2021-12-15T19:29:49Z', 313171],
      ['2021-11-18T15:19:35Z', 307274],
      ['2021-10-29T08:15:47Z', 300060],
      ['2021-10-28T04:52:47Z', 298871],
      ['2021-10-25T15:13:43Z', 298871],
      ['2021-10-24T01:55:37Z', 298871],
      ['2021-10-22T23:16:46Z', 298871],
      ['2021-10-19T22:18:12Z', 298166],
      ['2021-10-01T11:38:52Z', 295407],
      ['2021-09-19T19:17:01Z', 292452],
      ['2021-09-17T21:05:40Z', 292452],
      ['2021-09-05T00:00:00Z', 276030],
      ['2021-08-20T00:00:00Z', 276030],
      ['2021-08-04T14:40:55Z', 276030],
      ['2021-07-21T14:24:07Z', 276030],
      ['2021-07-02T12:19:54Z', 243288],
      ['2021-06-13T06:54:39Z', 243288],
      ['2021-06-11T12:49:36Z', 243288],
      ['2021-06-11T07:17:33Z', 243288],
      ['2021-06-09T20:51:05Z', 243288],
      ['2021-06-09T18:13:40Z', 243288],
      ['2021-06-07T17:16:57Z', 243288],
      ['2021-04-30T00:21:15Z', 243288],
      ['2021-04-15T01:03:18Z', 243288],
    ],
  },
  { fieldId: 441, companyId: 5780, value: null },
  { fieldId: 192, companyId: 5780, value: [] },
  { fieldId: 196, companyId: 5780, value: [] },
  { fieldId: 440, companyId: 5780, value: null },
  { fieldId: 105, companyId: 5780, value: null },
  { fieldId: 442, companyId: 5780, value: null },
  {
    fieldId: 323,
    companyId: 5780,
    value: [
      ['2023-03-01T00:00:00.000Z', 150],
      ['2023-02-01T00:00:00.000Z', 153],
      ['2023-01-01T00:00:00.000Z', 150],
      ['2022-12-01T00:00:00.000Z', 154],
      ['2022-11-01T00:00:00.000Z', 158],
      ['2022-10-01T00:00:00.000Z', 168],
      ['2022-09-01T00:00:00.000Z', 168],
      ['2022-08-01T00:00:00.000Z', 166],
      ['2022-07-01T00:00:00.000Z', 166],
      ['2022-06-01T00:00:00.000Z', 170],
      ['2022-05-01T00:00:00.000Z', 167],
      ['2022-04-01T00:00:00.000Z', 168],
      ['2022-03-01T00:00:00.000Z', 157],
      ['2022-02-01T00:00:00.000Z', 152],
      ['2022-01-01T00:00:00.000Z', 150],
      ['2021-12-01T00:00:00.000Z', 139],
      ['2021-11-01T00:00:00.000Z', 140],
      ['2021-10-01T00:00:00.000Z', 140],
      ['2021-09-01T00:00:00.000Z', 138],
      ['2021-08-01T00:00:00.000Z', 133],
      ['2021-07-01T00:00:00.000Z', 133],
      ['2021-06-01T00:00:00.000Z', 132],
      ['2021-05-01T00:00:00.000Z', 127],
    ],
  },
  {
    fieldId: 412,
    companyId: 5780,
    value: [
      ['2023-02-01T00:00:00.000Z', 4],
      ['2022-12-01T00:00:00.000Z', 3],
      ['2022-11-01T00:00:00.000Z', 1],
      ['2022-10-01T00:00:00.000Z', 3],
      ['2022-09-01T00:00:00.000Z', 2],
      ['2022-08-01T00:00:00.000Z', 4],
      ['2022-07-01T00:00:00.000Z', 11],
      ['2022-06-01T00:00:00.000Z', 9],
      ['2022-05-01T00:00:00.000Z', 7],
      ['2022-04-01T00:00:00.000Z', 18],
      ['2022-03-01T00:00:00.000Z', 10],
      ['2022-02-01T00:00:00.000Z', 3],
      ['2022-01-01T00:00:00.000Z', 13],
      ['2021-12-01T00:00:00.000Z', 2],
      ['2021-11-01T00:00:00.000Z', 2],
      ['2021-10-01T00:00:00.000Z', 5],
      ['2021-09-01T00:00:00.000Z', 7],
      ['2021-08-01T00:00:00.000Z', 7],
      ['2021-07-01T00:00:00.000Z', 7],
      ['2021-06-01T00:00:00.000Z', 7],
      ['2021-05-01T00:00:00.000Z', 3],
    ],
  },
  { fieldId: 188, companyId: 5780, value: [] },
  { fieldId: 338, companyId: 5780, value: [] },
  {
    fieldId: 416,
    companyId: 5780,
    value: [
      ['2023-03-01T00:00:00.000Z', 1],
      ['2023-02-01T00:00:00.000Z', 3],
      ['2023-01-01T00:00:00.000Z', 1],
      ['2022-12-01T00:00:00.000Z', 4],
      ['2022-11-01T00:00:00.000Z', 7],
      ['2022-10-01T00:00:00.000Z', 11],
      ['2022-09-01T00:00:00.000Z', 3],
      ['2022-07-01T00:00:00.000Z', 4],
      ['2022-06-01T00:00:00.000Z', 15],
      ['2022-05-01T00:00:00.000Z', 6],
      ['2022-04-01T00:00:00.000Z', 8],
      ['2022-03-01T00:00:00.000Z', 7],
      ['2022-02-01T00:00:00.000Z', 5],
      ['2022-01-01T00:00:00.000Z', 1],
      ['2021-12-01T00:00:00.000Z', 2],
      ['2021-11-01T00:00:00.000Z', 3],
      ['2021-10-01T00:00:00.000Z', 2],
      ['2021-09-01T00:00:00.000Z', 3],
      ['2021-08-01T00:00:00.000Z', 2],
      ['2021-07-01T00:00:00.000Z', 7],
      ['2021-06-01T00:00:00.000Z', 6],
      ['2021-05-01T00:00:00.000Z', 2],
    ],
  },
  {
    fieldId: 59,
    companyId: 5780,
    value: [
      ['2023-03-20T06:22:19Z', 85523],
      ['2023-03-06T09:01:03Z', 85507],
      ['2023-02-20T07:22:32Z', 85476],
      ['2023-02-06T07:39:16Z', 85447],
      ['2023-01-23T06:01:02Z', 85400],
      ['2023-01-09T06:37:18Z', 85391],
      ['2022-12-22T06:32:42Z', 85400],
      ['2022-12-08T06:40:36Z', 85410],
      ['2022-11-24T07:31:52Z', 85424],
      ['2022-11-10T06:21:06Z', 85440],
      ['2022-10-27T06:35:31Z', 85452],
      ['2022-10-14T09:00:41Z', 85452],
      ['2022-10-05T06:17:01Z', 85486],
      ['2022-09-19T08:05:12Z', 85486],
      ['2022-08-27T08:32:46Z', 85455],
      ['2022-07-17T06:17:49Z', 85464],
      ['2022-07-03T11:00:09Z', 85464],
      ['2022-07-03T07:27:55Z', 85464],
      ['2022-06-15T06:29:16Z', 85464],
      ['2022-05-21T10:07:59Z', 85464],
      ['2022-05-06T15:24:45Z', 85464],
      ['2022-04-27T09:30:32Z', 85464],
      ['2022-04-26T19:32:06Z', 85464],
      ['2022-03-22T19:31:46Z', 85464],
      ['2022-03-09T07:22:40Z', 85464],
      ['2022-02-18T23:42:10Z', 85464],
      ['2022-02-18T06:40:30Z', 85464],
      ['2022-02-09T03:03:30Z', 85464],
      ['2022-01-29T22:34:44Z', 85464],
      ['2022-01-28T00:25:53Z', 85464],
      ['2022-01-02T01:51:14Z', 85464],
      ['2021-12-21T22:11:06Z', 85456],
      ['2021-12-20T11:52:36Z', 85456],
      ['2021-12-15T19:29:49Z', 85474],
      ['2021-11-18T15:19:35Z', 85514],
      ['2021-10-29T08:15:47Z', 85494],
      ['2021-10-28T04:52:47Z', 85466],
      ['2021-10-25T15:13:43Z', 85466],
      ['2021-10-24T01:55:37Z', 85466],
      ['2021-10-22T23:16:46Z', 85466],
      ['2021-10-19T22:18:12Z', 85472],
      ['2021-10-01T11:38:52Z', 85460],
      ['2021-09-19T19:17:01Z', 85417],
      ['2021-09-17T21:05:40Z', 85417],
      ['2021-09-05T00:00:00Z', 85143],
      ['2021-08-20T00:00:00Z', 85143],
      ['2021-08-04T14:40:55Z', 85143],
      ['2021-07-21T14:24:07Z', 85143],
      ['2021-07-02T12:19:54Z', 85055],
      ['2021-06-13T06:54:39Z', 84962],
      ['2021-06-11T12:49:36Z', 84962],
      ['2021-06-11T07:17:33Z', 84962],
      ['2021-06-09T20:51:05Z', 84957],
      ['2021-06-09T18:13:40Z', 84957],
      ['2021-06-07T17:16:57Z', 84957],
      ['2021-04-30T00:21:15Z', 84731],
      ['2021-04-15T01:03:18Z', 84731],
    ],
  },
  { fieldId: 79, companyId: 5780, value: null },
  {
    fieldId: 69,
    companyId: 5780,
    value: [
      ['2023-03-20T06:22:19Z', 65124],
      ['2023-03-06T09:01:03Z', 64822],
      ['2023-02-20T07:22:32Z', 64375],
      ['2023-02-06T07:39:16Z', 64211],
      ['2023-01-23T06:01:02Z', 63673],
      ['2023-01-09T06:37:18Z', 63448],
      ['2022-12-22T06:32:42Z', 63309],
      ['2022-12-08T06:40:36Z', 63141],
      ['2022-11-24T07:31:52Z', 62930],
      ['2022-11-10T06:21:06Z', 62674],
      ['2022-10-27T06:35:31Z', 62390],
      ['2022-10-14T09:00:41Z', 62390],
      ['2022-10-05T06:17:01Z', 61901],
      ['2022-09-19T08:05:12Z', 61521],
      ['2022-08-27T08:32:46Z', 60452],
      ['2022-07-17T06:17:49Z', 59277],
      ['2022-07-03T11:00:09Z', 59277],
      ['2022-07-03T07:27:55Z', 59277],
      ['2022-06-15T06:29:16Z', 59277],
      ['2022-05-21T10:07:59Z', 58006],
      ['2022-05-06T15:24:45Z', 58006],
      ['2022-04-27T09:30:32Z', 57099],
      ['2022-04-26T19:32:06Z', 57099],
      ['2022-03-22T19:31:46Z', 54967],
      ['2022-03-09T07:22:40Z', 54551],
      ['2022-02-18T23:42:10Z', 54230],
      ['2022-02-18T06:40:30Z', 54230],
      ['2022-02-09T03:03:30Z', 54084],
      ['2022-01-29T22:34:44Z', 53800],
      ['2022-01-28T00:25:53Z', 53800],
      ['2022-01-02T01:51:14Z', 53084],
      ['2021-12-21T22:11:06Z', 52934],
      ['2021-12-20T11:52:36Z', 52934],
      ['2021-12-15T19:29:49Z', 52858],
      ['2021-11-18T15:19:35Z', 52459],
      ['2021-10-29T08:15:47Z', 52092],
      ['2021-10-28T04:52:47Z', 51992],
      ['2021-10-25T15:13:43Z', 51992],
      ['2021-10-24T01:55:37Z', 51992],
      ['2021-10-22T23:16:46Z', 51992],
      ['2021-10-19T22:18:12Z', 51930],
      ['2021-10-01T11:38:52Z', 51623],
      ['2021-09-19T19:17:01Z', 51408],
      ['2021-09-17T21:05:40Z', 51408],
      ['2021-09-05T00:00:00Z', 50588],
      ['2021-08-20T00:00:00Z', 50588],
      ['2021-08-04T14:40:55Z', 50588],
      ['2021-07-21T14:24:07Z', 50101],
      ['2021-07-02T12:19:54Z', 49581],
      ['2021-06-13T06:54:39Z', 49220],
      ['2021-06-11T12:49:36Z', 49220],
      ['2021-06-11T07:17:33Z', 49220],
      ['2021-06-09T20:51:05Z', 49141],
      ['2021-06-09T18:13:40Z', 49141],
      ['2021-06-07T17:16:57Z', 49141],
      ['2021-04-30T00:21:15Z', 48425],
      ['2021-04-15T01:03:18Z', 47938],
    ],
  },
  {
    fieldId: 9,
    companyId: 4607,
    value: [
      ['2023-03-31', 75061],
      ['2023-02-28', 72301],
      ['2023-01-31', 107962],
      ['2022-12-31', 117757],
      ['2022-11-30', 108363],
      ['2022-10-31', 53055],
      ['2022-09-30', 85997],
      ['2022-08-31', 195236],
      ['2022-07-31', 191817],
      ['2022-06-30', 162547],
      ['2022-05-31', 303252],
      ['2022-04-30', 368518],
      ['2022-03-31', 421678],
      ['2022-02-28', 428512],
      ['2022-01-31', 544138],
      ['2021-12-31', 600273],
      ['2021-11-30', 418076],
      ['2021-10-31', 349538],
      ['2021-09-30', 364732],
      ['2021-08-31', 332195],
      ['2021-07-31', 377252],
      ['2021-06-30', 348411],
      ['2021-05-31', 361172],
      ['2021-04-30', 321652],
    ],
  },
  {
    fieldId: 350,
    companyId: 4607,
    value: [
      ['2023-03-01T00:00:00.000Z', 4],
      ['2023-02-01T00:00:00.000Z', 1],
      ['2023-01-01T00:00:00.000Z', 2],
      ['2022-12-01T00:00:00.000Z', 5],
      ['2022-11-01T00:00:00.000Z', 11],
      ['2022-10-01T00:00:00.000Z', 6],
      ['2022-09-01T00:00:00.000Z', 7],
      ['2022-08-01T00:00:00.000Z', 18],
      ['2022-07-01T00:00:00.000Z', 17],
      ['2022-06-01T00:00:00.000Z', 21],
      ['2022-05-01T00:00:00.000Z', 62],
      ['2022-04-01T00:00:00.000Z', 44],
      ['2022-03-01T00:00:00.000Z', 42],
      ['2022-02-01T00:00:00.000Z', 22],
      ['2022-01-01T00:00:00.000Z', 30],
      ['2021-12-01T00:00:00.000Z', 16],
      ['2021-11-01T00:00:00.000Z', 59],
      ['2021-10-01T00:00:00.000Z', 33],
      ['2021-09-01T00:00:00.000Z', 21],
      ['2021-08-01T00:00:00.000Z', 18],
      ['2021-07-01T00:00:00.000Z', 16],
      ['2021-06-01T00:00:00.000Z', 8],
      ['2021-05-01T00:00:00.000Z', 4],
    ],
  },
  {
    fieldId: 324,
    companyId: 4607,
    value: [
      ['2023-03-01T00:00:00.000Z', 14],
      ['2023-02-01T00:00:00.000Z', 8],
      ['2023-01-01T00:00:00.000Z', 9],
      ['2022-12-01T00:00:00.000Z', 13],
      ['2022-11-01T00:00:00.000Z', 22],
      ['2022-10-01T00:00:00.000Z', 17],
      ['2022-09-01T00:00:00.000Z', 17],
      ['2022-08-01T00:00:00.000Z', 26],
      ['2022-07-01T00:00:00.000Z', 29],
      ['2022-06-01T00:00:00.000Z', 33],
      ['2022-05-01T00:00:00.000Z', 87],
      ['2022-04-01T00:00:00.000Z', 91],
      ['2022-03-01T00:00:00.000Z', 92],
      ['2022-02-01T00:00:00.000Z', 66],
      ['2022-01-01T00:00:00.000Z', 80],
      ['2021-12-01T00:00:00.000Z', 80],
      ['2021-11-01T00:00:00.000Z', 123],
      ['2021-10-01T00:00:00.000Z', 126],
      ['2021-09-01T00:00:00.000Z', 86],
      ['2021-08-01T00:00:00.000Z', 80],
      ['2021-07-01T00:00:00.000Z', 47],
      ['2021-06-01T00:00:00.000Z', 22],
      ['2021-05-01T00:00:00.000Z', 11],
    ],
  },
  {
    fieldId: 71,
    companyId: 4607,
    value: [
      ['2023-03-20T08:07:39Z', 126000],
      ['2023-03-06T06:33:29Z', 126000],
      ['2023-02-20T07:57:51Z', 126000],
      ['2023-02-06T06:07:55Z', 126200],
      ['2023-01-23T06:11:03Z', 126500],
      ['2023-01-09T06:21:29Z', 126500],
      ['2022-12-22T06:35:09Z', 126300],
      ['2022-12-08T06:18:52Z', 126400],
      ['2022-11-24T07:14:11Z', 126000],
      ['2022-11-10T07:58:25Z', 126400],
      ['2022-10-27T07:42:06Z', 127100],
      ['2022-10-14T09:15:53Z', 127100],
      ['2022-10-05T09:44:21Z', 126900],
      ['2022-09-21T09:52:41Z', 127000],
      ['2022-08-29T06:41:31Z', 127000],
      ['2022-08-15T06:32:27Z', 127000],
      ['2022-07-16T06:35:25Z', 126900],
      ['2022-07-02T08:51:21Z', 127000],
      ['2022-06-15T06:00:07Z', 127000],
      ['2022-05-30T10:28:04Z', 127000],
      ['2022-05-15T07:30:01Z', 127000],
      ['2022-05-01T15:27:04Z', 127000],
      ['2022-04-30T13:16:24Z', 127000],
      ['2022-04-29T13:10:15Z', 127000],
      ['2022-04-28T14:18:32Z', 127000],
      ['2022-04-27T13:21:49Z', 127000],
      ['2022-04-27T08:41:11Z', 127000],
      ['2022-04-26T19:00:19Z', 127000],
      ['2022-03-27T15:07:38Z', 126000],
      ['2022-03-11T23:02:25Z', 126000],
      ['2022-02-26T06:48:46Z', 125000],
      ['2022-02-13T00:40:17Z', 125000],
      ['2022-02-12T04:00:55Z', 125000],
      ['2022-02-08T10:30:57Z', 125000],
      ['2022-02-05T20:18:27Z', 125000],
      ['2022-02-02T19:14:26Z', 125000],
      ['2022-02-01T07:17:56Z', 125000],
      ['2021-11-25T13:01:18Z', 124000],
      ['2021-11-20T01:10:37Z', 124000],
      ['2021-11-04T05:11:07Z', 123000],
      ['2021-11-04T00:01:10Z', 123000],
      ['2021-10-29T15:42:16Z', 123000],
      ['2021-10-28T04:57:16Z', 123000],
      ['2021-10-26T01:42:19Z', 123000],
      ['2021-10-25T11:12:11Z', 123000],
      ['2021-10-25T09:05:06Z', 123000],
      ['2021-10-17T20:09:25Z', 122000],
      ['2021-09-30T20:04:35Z', 122000],
      ['2021-09-22T02:29:10Z', 121000],
      ['2021-09-20T03:33:44Z', 121000],
      ['2021-09-17T22:36:41Z', 121000],
      ['2021-09-05T00:00:00Z', 118000],
      ['2021-08-20T00:00:00Z', 118000],
      ['2021-08-07T17:26:30Z', 118000],
      ['2021-08-07T05:13:23Z', 118000],
      ['2021-07-23T19:23:00Z', 117000],
      ['2021-07-21T15:30:31Z', 117000],
      ['2021-07-03T14:42:51Z', 115000],
      ['2021-07-02T13:45:14Z', 115000],
      ['2021-06-13T09:31:47Z', 112000],
      ['2021-06-11T13:16:00Z', 112000],
      ['2021-06-08T04:15:14Z', 112000],
      ['2021-05-16T16:41:15Z', 111000],
      ['2021-04-30T00:21:45Z', 108000],
      ['2021-04-15T01:04:58Z', 108000],
    ],
  },
  {
    fieldId: 64,
    companyId: 4607,
    value: [
      ['2023-03-20T08:07:39Z', 110910],
      ['2023-03-06T06:33:29Z', 110910],
      ['2023-02-20T07:57:51Z', 110910],
      ['2023-02-06T06:07:55Z', 110910],
      ['2023-01-23T06:11:03Z', 110910],
      ['2023-01-09T06:21:29Z', 110910],
      ['2022-12-22T06:35:09Z', 110910],
      ['2022-12-08T06:18:52Z', 110910],
      ['2022-11-24T07:14:11Z', 110910],
      ['2022-11-10T07:58:25Z', 110910],
      ['2022-10-27T07:42:06Z', 110910],
      ['2022-10-14T09:15:53Z', 110910],
      ['2022-10-05T09:44:21Z', 110910],
      ['2022-09-21T09:52:41Z', 110910],
      ['2022-08-29T06:41:31Z', 110910],
      ['2022-08-15T06:32:27Z', 110910],
      ['2022-07-16T06:35:25Z', 110910],
      ['2022-07-02T08:51:21Z', 110910],
      ['2022-06-15T06:00:07Z', 110910],
      ['2022-05-30T10:28:04Z', 110910],
      ['2022-05-15T07:30:01Z', 110910],
      ['2022-05-01T15:27:04Z', 110878],
      ['2022-04-30T13:16:24Z', 110878],
      ['2022-04-29T13:10:15Z', 110878],
      ['2022-04-28T14:18:32Z', 110878],
      ['2022-04-27T13:21:49Z', 110876],
      ['2022-04-27T08:41:11Z', 110876],
      ['2022-04-26T19:00:19Z', 110876],
      ['2022-03-27T15:07:38Z', 107909],
      ['2022-03-11T23:02:25Z', 107567],
      ['2022-02-26T06:48:46Z', 107342],
      ['2022-02-13T00:40:17Z', 107056],
      ['2022-02-12T04:00:55Z', 107056],
      ['2022-02-08T10:30:57Z', 107049],
      ['2022-02-05T20:18:27Z', 107049],
      ['2022-02-02T19:14:26Z', 107008],
      ['2022-02-01T07:17:56Z', 106919],
      ['2022-01-24T16:57:00Z', 106809],
      ['2022-01-23T22:05:11Z', 106809],
      ['2022-01-19T07:30:56Z', 106648],
      ['2022-01-17T10:42:20Z', 106648],
      ['2022-01-12T02:29:08Z', 106450],
      ['2022-01-10T08:59:31Z', 106450],
      ['2022-01-02T10:29:44Z', 106240],
      ['2021-12-22T18:01:33Z', 106078],
      ['2021-11-25T13:01:18Z', 105898],
      ['2021-11-20T01:10:37Z', 105885],
      ['2021-11-04T05:11:07Z', 105740],
      ['2021-11-04T00:01:10Z', 105740],
      ['2021-10-29T15:42:16Z', 105614],
      ['2021-10-28T04:57:16Z', 105620],
      ['2021-10-26T01:42:19Z', 105620],
      ['2021-10-25T11:12:11Z', 105620],
      ['2021-10-25T09:05:06Z', 105620],
      ['2021-10-17T20:09:25Z', 105516],
      ['2021-09-30T20:04:35Z', 105341],
      ['2021-09-22T02:29:10Z', 105328],
      ['2021-09-20T03:33:44Z', 105290],
      ['2021-09-17T22:36:41Z', 105290],
      ['2021-09-05T00:00:00Z', 104637],
      ['2021-08-20T00:00:00Z', 104637],
      ['2021-08-07T17:26:30Z', 104637],
      ['2021-08-07T05:13:23Z', 104637],
      ['2021-07-23T19:23:00Z', 104505],
      ['2021-07-21T15:30:31Z', 104505],
      ['2021-07-03T14:42:51Z', 104188],
      ['2021-07-02T13:45:14Z', 102642],
      ['2021-06-13T09:31:47Z', 102642],
      ['2021-06-11T13:16:00Z', 102642],
      ['2021-06-08T04:15:14Z', 102642],
      ['2021-05-16T16:41:15Z', 102642],
      ['2021-04-30T00:21:45Z', 99013],
      ['2021-04-15T01:04:58Z', 99013],
    ],
  },
  { fieldId: 441, companyId: 4607, value: null },
  { fieldId: 192, companyId: 4607, value: [] },
  { fieldId: 196, companyId: 4607, value: [] },
  { fieldId: 440, companyId: 4607, value: null },
  {
    fieldId: 105,
    companyId: 4607,
    value: [
      ['2023-03-31', 2198555.57040021],
      ['2023-02-28', 2539469.87799447],
      ['2023-01-31', 2773889.60651297],
      ['2022-12-31', 2750326.44090043],
      ['2022-11-30', 2341677.3197453],
      ['2022-10-31', 2302375.04571744],
      ['2022-09-30', 2381973.74647509],
      ['2022-08-31', 2529370.51980272],
      ['2022-07-31', 2922341.56108867],
      ['2022-06-30', 2839786.21173685],
      ['2022-05-31', 2801469.3032589],
      ['2022-04-30', 2797970.94580442],
      ['2022-03-31', 2889013.06734377],
      ['2022-02-28', 3058253.28543189],
      ['2022-01-31', 3222212.25990157],
      ['2021-12-31', 3159192.55153299],
      ['2021-11-30', 2981475.06355603],
      ['2021-10-31', 2949015.05948341],
      ['2021-09-30', 2946691.07638276],
      ['2021-08-31', 2970024.18857386],
      ['2021-07-31', 3216848.71184627],
      ['2021-06-30', 3218213.15982173],
      ['2021-05-31', 3420296.87179436],
      ['2021-04-30', 3299219.7278465],
    ],
  },
  { fieldId: 442, companyId: 4607, value: null },
  {
    fieldId: 323,
    companyId: 4607,
    value: [
      ['2023-03-01T00:00:00.000Z', 494],
      ['2023-02-01T00:00:00.000Z', 500],
      ['2023-01-01T00:00:00.000Z', 499],
      ['2022-12-01T00:00:00.000Z', 517],
      ['2022-11-01T00:00:00.000Z', 520],
      ['2022-10-01T00:00:00.000Z', 554],
      ['2022-09-01T00:00:00.000Z', 535],
      ['2022-08-01T00:00:00.000Z', 525],
      ['2022-07-01T00:00:00.000Z', 545],
      ['2022-06-01T00:00:00.000Z', 545],
      ['2022-05-01T00:00:00.000Z', 524],
      ['2022-04-01T00:00:00.000Z', 523],
      ['2022-03-01T00:00:00.000Z', 549],
      ['2022-02-01T00:00:00.000Z', 506],
      ['2022-01-01T00:00:00.000Z', 494],
      ['2021-12-01T00:00:00.000Z', 471],
      ['2021-11-01T00:00:00.000Z', 462],
      ['2021-10-01T00:00:00.000Z', 442],
      ['2021-09-01T00:00:00.000Z', 444],
      ['2021-08-01T00:00:00.000Z', 422],
      ['2021-07-01T00:00:00.000Z', 417],
      ['2021-06-01T00:00:00.000Z', 402],
      ['2021-05-01T00:00:00.000Z', 395],
    ],
  },
  {
    fieldId: 412,
    companyId: 4607,
    value: [
      ['2023-03-01T00:00:00.000Z', 2],
      ['2023-02-01T00:00:00.000Z', 5],
      ['2023-01-01T00:00:00.000Z', 3],
      ['2022-12-01T00:00:00.000Z', 1],
      ['2022-11-01T00:00:00.000Z', 3],
      ['2022-10-01T00:00:00.000Z', 30],
      ['2022-09-01T00:00:00.000Z', 23],
      ['2022-08-01T00:00:00.000Z', 4],
      ['2022-07-01T00:00:00.000Z', 9],
      ['2022-06-01T00:00:00.000Z', 30],
      ['2022-05-01T00:00:00.000Z', 14],
      ['2022-04-01T00:00:00.000Z', 15],
      ['2022-03-01T00:00:00.000Z', 51],
      ['2022-02-01T00:00:00.000Z', 23],
      ['2022-01-01T00:00:00.000Z', 30],
      ['2021-12-01T00:00:00.000Z', 14],
      ['2021-11-01T00:00:00.000Z', 25],
      ['2021-10-01T00:00:00.000Z', 17],
      ['2021-09-01T00:00:00.000Z', 33],
      ['2021-08-01T00:00:00.000Z', 16],
      ['2021-07-01T00:00:00.000Z', 19],
      ['2021-06-01T00:00:00.000Z', 15],
      ['2021-05-01T00:00:00.000Z', 10],
    ],
  },
  { fieldId: 188, companyId: 4607, value: [] },
  {
    fieldId: 338,
    companyId: 4607,
    value: [
      ['2023-03-01T00:00:00.000Z', 7],
      ['2023-02-01T00:00:00.000Z', 1],
      ['2023-01-01T00:00:00.000Z', 1],
      ['2022-12-01T00:00:00.000Z', 2],
      ['2022-11-01T00:00:00.000Z', 11],
      ['2022-10-01T00:00:00.000Z', 7],
      ['2022-09-01T00:00:00.000Z', 9],
      ['2022-08-01T00:00:00.000Z', 14],
      ['2022-07-01T00:00:00.000Z', 17],
      ['2022-06-01T00:00:00.000Z', 8],
      ['2022-05-01T00:00:00.000Z', 40],
      ['2022-04-01T00:00:00.000Z', 41],
      ['2022-03-01T00:00:00.000Z', 48],
      ['2022-02-01T00:00:00.000Z', 16],
      ['2022-01-01T00:00:00.000Z', 16],
      ['2021-12-01T00:00:00.000Z', 16],
      ['2021-11-01T00:00:00.000Z', 30],
      ['2021-10-01T00:00:00.000Z', 61],
      ['2021-09-01T00:00:00.000Z', 24],
      ['2021-08-01T00:00:00.000Z', 49],
      ['2021-07-01T00:00:00.000Z', 33],
      ['2021-06-01T00:00:00.000Z', 15],
      ['2021-05-01T00:00:00.000Z', 7],
    ],
  },
  {
    fieldId: 416,
    companyId: 4607,
    value: [
      ['2023-02-01T00:00:00.000Z', 8],
      ['2023-01-01T00:00:00.000Z', 4],
      ['2022-12-01T00:00:00.000Z', 21],
      ['2022-11-01T00:00:00.000Z', 4],
      ['2022-10-01T00:00:00.000Z', 37],
      ['2022-09-01T00:00:00.000Z', 11],
      ['2022-08-01T00:00:00.000Z', 13],
      ['2022-07-01T00:00:00.000Z', 24],
      ['2022-06-01T00:00:00.000Z', 9],
      ['2022-05-01T00:00:00.000Z', 9],
      ['2022-04-01T00:00:00.000Z', 13],
      ['2022-03-01T00:00:00.000Z', 41],
      ['2022-02-01T00:00:00.000Z', 8],
      ['2022-01-01T00:00:00.000Z', 11],
      ['2021-12-01T00:00:00.000Z', 7],
      ['2021-11-01T00:00:00.000Z', 5],
      ['2021-10-01T00:00:00.000Z', 5],
      ['2021-09-01T00:00:00.000Z', 19],
      ['2021-08-01T00:00:00.000Z', 11],
      ['2021-07-01T00:00:00.000Z', 11],
      ['2021-06-01T00:00:00.000Z', 4],
      ['2021-05-01T00:00:00.000Z', 8],
    ],
  },
  {
    fieldId: 59,
    companyId: 4607,
    value: [
      ['2023-03-20T08:07:39Z', 175098],
      ['2023-03-06T06:33:29Z', 175132],
      ['2023-02-20T07:57:51Z', 175128],
      ['2023-02-06T06:07:55Z', 175075],
      ['2023-01-23T06:11:03Z', 175001],
      ['2023-01-09T06:21:29Z', 174909],
      ['2022-12-22T06:35:09Z', 174794],
      ['2022-12-08T06:18:52Z', 174755],
      ['2022-11-24T07:14:11Z', 174640],
      ['2022-11-10T07:58:25Z', 174607],
      ['2022-10-27T07:42:06Z', 174476],
      ['2022-10-14T09:15:53Z', 174476],
      ['2022-10-05T09:44:21Z', 174408],
      ['2022-09-21T09:52:41Z', 174424],
      ['2022-08-29T06:41:31Z', 174496],
      ['2022-08-15T06:32:27Z', 174529],
      ['2022-07-16T06:35:25Z', 171173],
      ['2022-07-02T08:51:21Z', 171173],
      ['2022-06-15T06:00:07Z', 171173],
      ['2022-05-30T10:28:04Z', 171173],
      ['2022-05-15T07:30:01Z', 171173],
      ['2022-05-01T15:27:04Z', 171173],
      ['2022-04-30T13:16:24Z', 171173],
      ['2022-04-29T13:10:15Z', 171173],
      ['2022-04-28T14:18:32Z', 171173],
      ['2022-04-27T13:21:49Z', 171173],
      ['2022-04-27T08:41:11Z', 171173],
      ['2022-04-26T19:00:19Z', 171173],
      ['2022-03-27T15:07:38Z', 171173],
      ['2022-03-11T23:02:25Z', 171173],
      ['2022-02-26T06:48:46Z', 171173],
      ['2022-02-13T00:40:17Z', 171173],
      ['2022-02-12T04:00:55Z', 171173],
      ['2022-02-08T10:30:57Z', 171173],
      ['2022-02-05T20:18:27Z', 171173],
      ['2022-02-02T19:14:26Z', 171173],
      ['2022-02-01T07:17:56Z', 171173],
      ['2022-01-24T16:57:00Z', 171173],
      ['2022-01-23T22:05:11Z', 171173],
      ['2022-01-19T07:30:56Z', 171173],
      ['2022-01-17T10:42:20Z', 171173],
      ['2022-01-12T02:29:08Z', 171173],
      ['2022-01-10T08:59:31Z', 171173],
      ['2022-01-02T10:29:44Z', 171049],
      ['2021-12-22T18:01:33Z', 170964],
      ['2021-11-25T13:01:18Z', 170832],
      ['2021-11-20T01:10:37Z', 170771],
      ['2021-11-04T05:11:07Z', 170509],
      ['2021-11-04T00:01:10Z', 170509],
      ['2021-10-29T15:42:16Z', 170414],
      ['2021-10-28T04:57:16Z', 170330],
      ['2021-10-26T01:42:19Z', 170330],
      ['2021-10-25T11:12:11Z', 170330],
      ['2021-10-25T09:05:06Z', 170330],
      ['2021-10-17T20:09:25Z', 170164],
      ['2021-09-30T20:04:35Z', 170013],
      ['2021-09-22T02:29:10Z', 169858],
      ['2021-09-20T03:33:44Z', 169800],
      ['2021-09-17T22:36:41Z', 169800],
      ['2021-09-05T00:00:00Z', 169018],
      ['2021-08-20T00:00:00Z', 169018],
      ['2021-08-07T17:26:30Z', 169018],
      ['2021-08-07T05:13:23Z', 169018],
      ['2021-07-23T19:23:00Z', 168839],
      ['2021-07-21T15:30:31Z', 168839],
      ['2021-07-03T14:42:51Z', 168451],
      ['2021-07-02T13:45:14Z', 168451],
      ['2021-06-13T09:31:47Z', 167938],
      ['2021-06-11T13:16:00Z', 167719],
      ['2021-06-08T04:15:14Z', 167719],
      ['2021-05-16T16:41:15Z', 166401],
      ['2021-04-30T00:21:45Z', 164914],
      ['2021-04-15T01:04:58Z', 164914],
    ],
  },
  {
    fieldId: 79,
    companyId: 4607,
    value: [
      ['2023-03-31', 6.449498725],
      ['2023-02-28', 5.554771127],
      ['2023-01-31', 7.57954375],
      ['2022-12-31', 5.711796665],
      ['2022-11-30', 5.372614715],
      ['2022-10-31', 5.538162185],
      ['2022-09-30', 5.461060109],
      ['2022-08-31', 5.091998309],
      ['2022-07-31', 5.493797092],
      ['2022-06-30', 6.368767668],
      ['2022-05-31', 5.348089035],
      ['2022-04-30', 5.356701075],
      ['2022-03-31', 5.699553477],
      ['2022-02-28', 4.913015612],
      ['2022-01-31', 6.549150553],
      ['2021-12-31', 9.057358842],
      ['2021-11-30', 7.328643601],
      ['2021-10-31', 6.893707607],
      ['2021-09-30', 6.349986095],
      ['2021-08-31', 7.144517813],
      ['2021-07-31', 7.518826413],
      ['2021-06-30', 8.995004902],
      ['2021-05-31', 7.749362682],
      ['2021-04-30', 8.520927926],
    ],
  },
  {
    fieldId: 69,
    companyId: 4607,
    value: [
      ['2023-03-20T08:07:39Z', 29918],
      ['2023-03-06T06:33:29Z', 29760],
      ['2023-02-20T07:57:51Z', 29578],
      ['2023-02-06T06:07:55Z', 29465],
      ['2023-01-23T06:11:03Z', 29163],
      ['2023-01-09T06:21:29Z', 29004],
      ['2022-12-22T06:35:09Z', 28928],
      ['2022-12-08T06:18:52Z', 28817],
      ['2022-11-24T07:14:11Z', 28668],
      ['2022-11-10T07:58:25Z', 28522],
      ['2022-10-27T07:42:06Z', 28399],
      ['2022-10-14T09:15:53Z', 28399],
      ['2022-10-05T09:44:21Z', 28118],
      ['2022-09-21T09:52:41Z', 27857],
      ['2022-08-29T06:41:31Z', 27513],
      ['2022-08-15T06:32:27Z', 27337],
    ],
  },
];
