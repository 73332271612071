import { isEqual } from 'lodash-es';

export function partialEquals<T>(partialOfObj: Partial<T>, obj: T) {
  if (Object.is(partialOfObj, obj)) return true;
  if (!partialOfObj || !obj) return false;

  for (const key of Object.keys(partialOfObj)) {
    if (!isEqual(partialOfObj[key as keyof Partial<T>], obj[key as keyof T])) {
      return false;
    }
  }
  return true;
}

/**
 * @returns true if all fields of partialOfObj are equal to the corresponding fields of obj, ignoring empty arrays and all falsy fields except 0
 */
export function partialEqualsIgnoreUnsetValues<T>(partialOfObj: Partial<T>, obj: T) {
  if (Object.is(partialOfObj, obj)) return true;

  if (!partialOfObj || !obj) return false;

  const partialOfObjWithoutFalsyFields = { ...partialOfObj };

  for (const key of Object.keys(partialOfObj)) {
    const field1 = partialOfObj[key as keyof Partial<T>];
    const field2 = obj[key as keyof T];

    if (
      (isFalsyExcept0(field1) && isFalsyExcept0(field2)) ||
      (isEmptyArrayOrUnset(field1) && isEmptyArrayOrUnset(field2))
    ) {
      delete partialOfObjWithoutFalsyFields[key as keyof Partial<T>];
    }
  }
  return partialEquals(partialOfObjWithoutFalsyFields, obj);
}

function isFalsyExcept0(value: unknown) {
  return !value && value !== 0;
}

function isEmptyArrayOrUnset(value: unknown) {
  return value == null || (Array.isArray(value) && value.length === 0);
}
