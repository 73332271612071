import { FC, MouseEvent } from 'react';
import { Typography, useTheme } from '@mui/material';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { ConfirmDeletionDialog } from '../../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import { formatDate } from '../../../../util/formatters/DateFormatters';

interface IConfirmDeleteDealProps {
  deal: IDealDataModel;
  company: ICompanyDataModel;
  open: boolean;
  onClose: (e?: MouseEvent<HTMLElement>) => void;
  onConfirm: (e?: MouseEvent<HTMLElement>) => void;
}
export const ConfirmDeleteDeal: FC<IConfirmDeleteDealProps> = ({
  deal,
  company,
  open,
  onClose,
  onConfirm,
}) => {
  const { colors } = useTheme();
  return (
    <ConfirmDeletionDialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={`Delete Deal for "${company.name}"?`}
    >
      <Typography variant='body2' color={colors.neutral[60]}>{`The deal added ${
        deal.createdAt ? formatDate(deal.createdAt) : ''
      } will be deleted permanently`}</Typography>
    </ConfirmDeletionDialog>
  );
};
