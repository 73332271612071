import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IAssignTemplateParams } from '../hooks/useTemplateAssignmentActions';
import { BulkAssignTemplatePayload } from '../../../../../services/queries/KPITemplatesQueries';

export function validateCompanyData(company: ICompanyDataModel) {
  const { fye, respondents } = company;
  return fye != null && respondents && respondents.length > 0;
}

export function prepareBulkAssignPayload(payload: IAssignTemplateParams): BulkAssignTemplatePayload[] {
  const { companyIds, ...templateData } = payload;
  return companyIds.map((companyId) => {
    return { ...templateData, companyId } as BulkAssignTemplatePayload;
  });
}
