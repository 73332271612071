import { FC } from 'react';
import { IPersonDataModel } from '../../../../data-models/person.data-model';
import { WorkHistoryTable } from '../../People/CellRenderers/WorkHistoryTable';
import { getAllExperience } from '../../utils/peopleHelpers';
import { NoDataMessage2 } from '../../components/Messages/HelpMessage';

interface IPersonWorkHistoryProps {
  person: IPersonDataModel;
}
export const PersonWorkHistory: FC<IPersonWorkHistoryProps> = ({ person }) => {
  const experience = getAllExperience(person);
  if (!experience || experience.length === 0)
    return (
      <div style={{ marginTop: '1.5rem' }}>
        <NoDataMessage2 />
      </div>
    );
  return (
    <WorkHistoryTable
      workHistory={experience}
      style={{ width: '100%' }}
      showTitle={false}
      tableBodyStyle={{ maxHeight: '100%' }}
    />
  );
};
