import { FC, useMemo, memo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IFinanceRoundDataModel } from '../../../data-models/finance-round.data-model';
import { useInvestmentModalActions } from '../hooks/useFinanceModalActions';
import { MenuButton } from './MenuButton';

export const InvestmentMenuCell: FC<ICellRendererParams<IFinanceRoundDataModel>> = memo(
  function InvestmentMenuCell({ data: selectedInvestment }: ICellRendererParams<IFinanceRoundDataModel>) {
    const { handleClickAction } = useInvestmentModalActions();
    const menuItems = useMemo(() => {
      if (!selectedInvestment) return [];
      return [
        {
          name: 'Edit Round',
          icon: <EditOutlinedIcon aria-label='edit investment' />,
          onClick: () => {
            handleClickAction(selectedInvestment, 'editInvestment');
          },
        },
        {
          name: 'Delete',
          icon: <DeleteOutlinedIcon aria-label='delete investment' />,
          onClick: () => {
            handleClickAction(selectedInvestment, 'deleteInvestment');
          },
        },
      ];
    }, [handleClickAction, selectedInvestment]);

    if (!selectedInvestment) return null;

    return <MenuButton menuItems={menuItems} label='investment actions' />;
  }
);
