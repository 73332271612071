import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { IconButton, Paper, Popper, Slide, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilValue } from 'recoil';
import { NavbarWidth, SideBarWidth } from '../constants/styles';
import { useHasAdditionalNav } from '../hooks/useHasAdditionalNav';
import { isNavigationBarStateOpen } from './Layout/state/NavigationState';

const Anchor = styled('div')`
  position: fixed;
  height: 1rem;
  bottom: 0;
  left: calc(50% + ${NavbarWidth / 2}px);
  transform: translate(-50%, 0);
`;

const StyledPaper = styled(Paper)`
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
`;

interface SlideUpPopperProps {
  message: string | ReactNode;
  onClose?: () => void;
}

export const SlideUpPopper: FC<SlideUpPopperProps> = ({ message, onClose }) => {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const hasAdditionalNav = useHasAdditionalNav();
  const isSidebarOpen = useRecoilValue(isNavigationBarStateOpen) && hasAdditionalNav;

  useEffect(() => {
    if (!anchorRef.current) return;
    setAnchorEl(anchorRef.current);
  }, []);

  return (
    <>
      <Anchor
        ref={anchorRef}
        style={{ left: `calc(50% + ${(NavbarWidth + (isSidebarOpen ? SideBarWidth : 0)) / 2}px)` }}
      />
      {anchorEl && (
        <Popper
          open={open}
          transition
          anchorEl={anchorEl}
          disablePortal
          placement='top'
          style={{ width: `calc(95% - ${NavbarWidth + (isSidebarOpen ? SideBarWidth : 0)}px)` }}
        >
          {({ TransitionProps }) => (
            <Slide direction='up' {...TransitionProps} mountOnEnter unmountOnExit>
              <StyledPaper elevation={5}>
                {typeof message === 'string' ? <Typography variant='caption'>{message}</Typography> : message}
                <IconButton
                  onClick={() => {
                    setOpen(false);
                    onClose?.();
                  }}
                  aria-label='close-tip'
                >
                  <CloseIcon color='action' />
                </IconButton>
              </StyledPaper>
            </Slide>
          )}
        </Popper>
      )}
    </>
  );
};
