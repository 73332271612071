import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { getGroupByOptionName } from '../../../../helpers/dealBreakdownUtils';
import { GroupByFilterState, GroupByOptions } from '../../../../state/DealConversionFilterState';

interface Option {
  id: GroupByOptions;
  value: GroupByOptions;
  name: string;
}

export const GroupByFilter: FC = () => {
  const [selectedGroupByParam, setSelectedGroupByParam] = useRecoilState(GroupByFilterState);

  const groupByOptions: Option[] = useMemo(() => {
    return Object.values(GroupByOptions)
      .map((opt) => ({
        id: opt,
        value: opt,
        name: getGroupByOptionName(opt),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const handleDealTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      if (newValue != null) {
        setSelectedGroupByParam(newValue as GroupByOptions);
      }
    },
    [setSelectedGroupByParam]
  );

  return (
    <Select
      value={selectedGroupByParam}
      onChange={handleDealTypeChange}
      sx={{ minWidth: '10rem' }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {groupByOptions.map((groupBy) => (
        <MenuItem key={groupBy.id} value={groupBy.id}>
          {groupBy.name}
        </MenuItem>
      ))}
    </Select>
  );
};
