import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';
import { useUpdateCompanyAndState } from '../../../services/hooks/useUpdateCompanyAndState';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { usePreviousValue } from '../../../hooks/usePreviousValue';

export function useDebounceCompanyUpdates(companyId: number) {
  const updateCompany = useUpdateCompanyAndState();

  const [payload, setPayload] = useState<Partial<ICompanyDataModel>>();
  const previousPayload = usePreviousValue(payload);

  const savePayload = useMemo(() => {
    return debounce((data) => {
      setPayload(data);
    }, 500);
  }, []);

  useEffect(() => {
    if (payload && previousPayload !== payload) {
      updateCompany(companyId, payload);
    }
  }, [companyId, payload, previousPayload, updateCompany]);

  return useCallback(
    (data: Partial<ICompanyDataModel>) => {
      savePayload(data);
    },
    [savePayload]
  );
}
