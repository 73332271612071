export enum RelationshipStrengthNormalized {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface IUserRelationDataModel {
  relationshipCapitalScore: number;
  relationshipStrengthIndicator: string;
  relationshipStrengthScore: number;
  relationshipStrengthScoreNormalized?: RelationshipStrengthNormalized | null;
  userId: number;
}

export function createUserRelationDataModel(
  overrides: Partial<IUserRelationDataModel> = {}
): IUserRelationDataModel {
  return {
    relationshipCapitalScore: 0,
    userId: 0,
    relationshipStrengthIndicator: 'high',
    relationshipStrengthScore: 0,
    relationshipStrengthScoreNormalized: RelationshipStrengthNormalized.Low,
    ...overrides,
  };
}
