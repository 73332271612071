import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ColDef, GetRowIdParams, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { LinearProgress } from '@mui/material';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { competitiveIntelligenceMetrics } from '../../hooks/useConnectCompetitiveIntelligenceMetrics';
import { currentCompaniesCI, selectedCompaniesCI } from '../../state/CompaniesState';
import { groupOrderMapCI, selectedFieldsCI } from '../../state/DashboardsState';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { LoadingStatus } from '../../../../types';
import { useColumnDefinitions } from './Columns/useColumnDefinitions';
import { DefaultCellRenderer } from './CellRenderers/DefaultCellRenderer';
import { prepRowData } from './RowData/prepRowData';
import { TableStyleWrapper, gridStyleOptions } from './TableStyleWrapper';

export const DefaultTable: FC = () => {
  const companies = useRecoilValue(currentCompaniesCI);
  const activeCompanies = useRecoilValue(selectedCompaniesCI);
  const selectedFields = useRecoilValue(selectedFieldsCI);
  const groupOrderMap = useRecoilValue(groupOrderMapCI);
  const { data, status } = useRecoilValue(competitiveIntelligenceMetrics);
  const loading = useMemo(() => status === LoadingStatus.loading, [status]);
  const { colDefs } = useColumnDefinitions(selectedFields, groupOrderMap, loading);

  const [rowData, setRowData] = useState<Record<string, unknown>[]>();

  useEffect(() => {
    if (!selectedFields?.length || !companies.length || !data?.length) return;
    setRowData(prepRowData(companies, selectedFields, data));
  }, [companies, data, selectedFields]);

  const [gridApi, setGridApi] = useState<GridApi>();

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      width: 200,
      minWidth: 200,
      resizable: true,
      sortable: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      // enableCellChangeFlash: true,
      cellRenderer: DefaultCellRenderer,
    }),
    []
  );

  const getRowId = useCallback((params: GetRowIdParams) => params.data?.id, []);

  const isExternalFilterPresent = useCallback(
    () => activeCompanies.length !== companies.length,
    [activeCompanies, companies.length]
  );

  const doesExternalFilterPass = useCallback(
    (node: IRowNode) => {
      if (activeCompanies.length === companies.length) return true;
      const companyId = node.data?.id;
      return companyId && activeCompanies.map((c) => c.id).includes(companyId);
    },
    [activeCompanies, companies]
  );

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [activeCompanies, gridApi]);

  const key = useMemo(() => activeCompanies.map((c) => c.id).join('-'), [activeCompanies]);

  if (!colDefs) return null;

  return (
    <GenericFallbacksWrapper>
      <TableStyleWrapper>
        {status === LoadingStatus.loading ? (
          <LinearProgress variant='indeterminate' color='primary' />
        ) : (
          <div style={{ height: '4px' }}></div> // height of progress bar
        )}
        <AgTable
          key={key}
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          gridOptions={gridStyleOptions}
          onGridReady={onGridReady}
          getRowId={getRowId}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          domLayout='autoHeight'
          groupIncludeTotalFooter={false}
        />
      </TableStyleWrapper>
    </GenericFallbacksWrapper>
  );
};
