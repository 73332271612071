import { SyntheticEvent, useRef, DragEvent } from 'react';
import { IconButton, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { css } from '@emotion/react';
import { colors } from '../../../theme/colors';
import { IFileMeta } from '../../../data-models/field3.data-model';
import { convertFileSize } from '../../../pages/KPI/utils';
import { UnstyledFieldset } from '../../../pages/CompanyProfiles/Scenarios/components/commonStyledComponents';
import { IBaseFieldProps } from './types';

const FileInputBox = css`
  width: 100%;
  height: 3rem;
  border-radius: 4px;
  display: grid;
  align-items: center;
  padding-inline: 1rem;
  grid-template-columns: 1fr;
  transition: all 0.5s;
`;

const EmptyFileInputBox = css`
  ${FileInputBox};
  border: thin dashed ${colors.neutral[30]};
  &:disabled {
    pointer-events: none;
  }
`;

const UploadedFileInputBox = css`
  ${FileInputBox};
  border: thin solid ${colors.neutral[30]};
  grid-template-columns: 1fr auto;
`;

const FileInputButton = css`
  all: unset;
  position: relative;
`;

const InputDescContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;

  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  & input {
    all: unset;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
`;

export const FieldFile = (props: IBaseFieldProps<IFileMeta>) => {
  const { formField, formProps } = props;
  const { value, onChange } = formProps;
  const { acceptTypes } = formField.rendererMeta ?? {};
  const { disabled = false } = formField ?? {};
  const fileInputRef = useRef<HTMLInputElement>(null);

  const _onChange = (e: SyntheticEvent) => {
    e.preventDefault();
    if (fileInputRef?.current?.files) onChange(fileInputRef.current.files[0]);
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    const selected = e.dataTransfer.files[0];
    if (selected && (!acceptTypes || acceptTypes.includes(selected.type))) onChange(selected);
  };

  if (value) {
    return (
      <div css={UploadedFileInputBox}>
        <Typography variant='body2' color='text.primary' noWrap>
          {`${value.name} ${convertFileSize(value.size)}`}
        </Typography>
        {!disabled && (
          <IconButton onClick={() => onChange(null)} title='remove'>
            <ClearIcon htmlColor={colors.neutral[50]} fontSize='small' />
          </IconButton>
        )}
      </div>
    );
  }

  return (
    <UnstyledFieldset disabled={disabled}>
      <div css={EmptyFileInputBox} onDragOver={handleDrag} onDragEnter={handleDrag} onDrop={handleDrop}>
        <div css={InputDescContainer}>
          <CloudUploadOutlinedIcon htmlColor={colors.neutral[50]} fontSize='small' />
          <Typography variant='body2' color='text.secondary'>
            &nbsp;Drag and drop or&nbsp;
          </Typography>
          <button css={FileInputButton} type='button'>
            <Typography variant='body2' color='secondary'>
              Browse your files
            </Typography>
            <input
              type='file'
              ref={fileInputRef}
              onChange={_onChange}
              multiple={false}
              accept={acceptTypes?.join(', ')}
            />
          </button>
        </div>
      </div>
    </UnstyledFieldset>
  );
};
