import { styled } from '@mui/material/styles';
import { useTheme, Typography } from '@mui/material';
import { NotificationHeader } from './NotificationHeader';
import { NotificationHeader as NotificationEvents } from './NotificationEvents';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  box-shadow: 0px -4px 40px rgba(255, 255, 255, 0.06), 0px 4px 24px rgba(16, 37, 62, 0.04),
    0px 24px 48px rgba(75, 82, 93, 0.02);
  border-radius: 8px;
  gap: 1rem;
  padding: 1.5rem;
`;

export function NotificationPreferences() {
  const { primary, neutral } = useTheme().colors;

  return (
    <Wrapper data-testid={'notification-config'}>
      <Typography variant={'subtitle1'} color={primary['100']}>
        Deal Board E-mail Notifications
      </Typography>
      <Typography variant={'body2'} color={neutral['60']}>
        Notify me where I am
      </Typography>
      <NotificationHeader />
      <NotificationEvents />
    </Wrapper>
  );
}
