import { ReactNode } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import { css } from '@emotion/react';
import { IconButton } from '@mui/material';

const HeaderWrapper = css`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
`;

interface IActivityHeaderProps {
  onBackClick: () => void;
  onCloseClick: () => void;
  title: ReactNode;
}
export function ActivityHeader({ onBackClick, onCloseClick, title }: IActivityHeaderProps) {
  return (
    <div css={HeaderWrapper}>
      <IconButton color={'secondary'} onClick={onBackClick} title='go back'>
        <ChevronLeftIcon />
      </IconButton>
      {title}
      <IconButton color={'secondary'} onClick={onCloseClick} title='close drawer'>
        <CloseIcon />
      </IconButton>
    </div>
  );
}
