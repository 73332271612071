import { IListDataModel, ListSortType } from '../../../data-models/list.data-model';

export function discoveryListSort(a: IListDataModel, b: IListDataModel) {
  let order = listSort(a, b);
  if (order === 0) {
    order =
      (a.createdAt ? new Date(a.createdAt).getTime() : Number.MAX_VALUE) -
      (b.createdAt ? new Date(b.createdAt).getTime() : Number.MAX_VALUE);
  }
  return order;
}

export function archivedListSort(a: IListDataModel, b: IListDataModel) {
  let order = listSort(a, b);
  if (order === 0) {
    order =
      (b.archiveDate ? new Date(b.archiveDate).getTime() : -1) -
      (a.archiveDate ? new Date(a.archiveDate).getTime() : -1);
  }
  return order;
}

export function listSort(a: IListDataModel, b: IListDataModel) {
  if (a.sortType === b.sortType && a.sortType === ListSortType.desc) {
    return (b.sortOrder ?? Number.MAX_VALUE) - (a.sortOrder ?? Number.MAX_VALUE);
  }
  return (a.sortOrder ?? Number.MAX_VALUE) - (b.sortOrder ?? Number.MAX_VALUE);
}
