import { IFormField } from '../../../view-models/form.view-model';

export function filterAndSortByPreferences(formFields: IFormField<unknown>[], preferences: string[]) {
  const formFieldsByKey = formFields.reduce((acc, field) => {
    if (!field.key) return acc;
    return acc.set(field.key, field);
  }, new Map<string, IFormField<unknown>>());

  return preferences.reduce((acc, key) => {
    const field = formFieldsByKey.get(key);
    if (field) acc.push(field);
    return acc;
  }, [] as IFormField<unknown>[]);
}
