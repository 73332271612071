import { CompetitiveIntelligenceItem } from '../data-models/competitive-intelligence.data-model';

export const tempFieldValues: CompetitiveIntelligenceItem[] = [
  { fieldId: 3, companyId: 4, value: null },
  { fieldId: 37, companyId: 4, value: 'acorns.com' },
  { fieldId: 4, companyId: 4, value: null },
  { fieldId: 8, companyId: 4, value: 'Mobile Software & Services' },
  { fieldId: 9, companyId: 4, value: 184164 },
  { fieldId: 320, companyId: 4, value: 3.9 },
  {
    fieldId: 299,
    companyId: 4,
    value: ['Jennifer Barrett', 'Jeffrey Cruttenden', 'Mark Dru'],
  },
  { fieldId: 378, companyId: 4, value: null },
  { fieldId: 50, companyId: 4, value: 57 },
  { fieldId: 286, companyId: 4, value: '2022-03-09' },
  { fieldId: 350, companyId: 4, value: 2 },
  { fieldId: 11, companyId: 4, value: 111377 },
  { fieldId: 287, companyId: 4, value: 'Series F' },
  { fieldId: 47, companyId: 4, value: 300000000 },
  { fieldId: 422, companyId: 4, value: 0.87 },
  {
    fieldId: 89,
    companyId: 4,
    value: [
      'Joe Armao,Blackstone,linkedin.com/in/joe-armao-07b32166',
      'Shlomo Benartzi,University of Southern California,linkedin.com/in/shlomobenartz',
      'James A. Caccavo,Steelpoint Capital Partners,linkedin.com/in/jim-caccavo-4422a175',
      'Patrick Carey,,',
      'Walter Cruttenden,Cruttenden Partners,linkedin.com/in/walter-cruttenden-58097b34',
      'Mark Hoffman,,',
      'Noah Kerner,Viacom,linkedin.com/in/noahkerner',
      'Adam Nash,NASA,linkedin.com/in/adamnash',
      'Mathias Schilling,Headline,linkedin.com/in/maschilling',
      'Dana Settle,Lehman Brothers,linkedin.com/in/dana-settle-8b18',
      'Gabrielle Sulzberger,,linkedin.com/in/gabrielle-sulzberger-5aa7a614',
      'Mark Terbeek,McKinsey & Company,linkedin.com/in/markterbeek',
    ],
  },
  { fieldId: 359, companyId: 4, value: -33.33333333333333 },
  { fieldId: 408, companyId: 4, value: 'California' },
  { fieldId: 267, companyId: 4, value: [] },
  { fieldId: 83, companyId: 4, value: 72787 },
  { fieldId: 477, companyId: 4, value: null },
  { fieldId: 324, companyId: 4, value: 6 },
  { fieldId: 51, companyId: 4, value: 402000000 },
  { fieldId: 71, companyId: 4, value: 77000 },
  { fieldId: 64, companyId: 4, value: 177662 },
  { fieldId: 87, companyId: 4, value: 8018890.51207024 },
  { fieldId: 97, companyId: 4, value: null },
  {
    fieldId: 103,
    companyId: 4,
    value: [
      'Partnership - 14',
      'Investment - 12',
      'New Offering - 8',
      'Acquisition - 7',
      'Leadership - 5',
      'Expansion - 1',
    ],
  },
  {
    fieldId: 104,
    companyId: 4,
    value: [
      'Partnership - 14',
      'Investment - 12',
      'New Offering - 8',
      'Acquisition - 7',
      'Leadership - 5',
      'Expansion - 1',
    ],
  },
  { fieldId: 99, companyId: 4, value: null },
  { fieldId: 100, companyId: 4, value: null },
  {
    fieldId: 101,
    companyId: 4,
    value: [
      'Partnership - 14',
      'Investment - 12',
      'New Offering - 9',
      'Acquisition - 8',
      'Leadership - 5',
      'Expansion - 1',
    ],
  },
  { fieldId: 353, companyId: 4, value: 100 },
  { fieldId: 428, companyId: 4, value: null },
  { fieldId: 426, companyId: 4, value: null },
  { fieldId: 347, companyId: 4, value: -71.42857142857143 },
  { fieldId: 429, companyId: 4, value: null },
  { fieldId: 427, companyId: 4, value: null },
  { fieldId: 425, companyId: 4, value: null },
  { fieldId: 327, companyId: 4, value: 20 },
  { fieldId: 161, companyId: 4, value: 2.358575123562703 },
  { fieldId: 163, companyId: 4, value: 8.640700171237532 },
  { fieldId: 434, companyId: 4, value: null },
  { fieldId: 344, companyId: 4, value: 100 },
  { fieldId: 362, companyId: 4, value: -95 },
  { fieldId: 395, companyId: 4, value: ['Say'] },
  { fieldId: 356, companyId: 4, value: -33.33333333333333 },
  { fieldId: 337, companyId: 4, value: -88.88888888888889 },
  { fieldId: 430, companyId: 4, value: null },
  { fieldId: 432, companyId: 4, value: null },
  { fieldId: 162, companyId: 4, value: 0.8744810529105203 },
  { fieldId: 150, companyId: 4, value: 0 },
  { fieldId: 148, companyId: 4, value: 0 },
  { fieldId: 151, companyId: 4, value: null },
  { fieldId: 149, companyId: 4, value: 0 },
  { fieldId: 147, companyId: 4, value: 0.1001057936228059 },
  { fieldId: 145, companyId: 4, value: null },
  { fieldId: 146, companyId: 4, value: 0.02017567041479473 },
  { fieldId: 144, companyId: 4, value: null },
  { fieldId: 441, companyId: 4, value: null },
  { fieldId: 164, companyId: 4, value: null },
  { fieldId: 304, companyId: 4, value: null },
  {
    fieldId: 306,
    companyId: 4,
    value:
      'Acorn Investment Group is a sports company based out of 19900 Macarthur Blvd, Irvine, CA, United States.',
  },
  { fieldId: 437, companyId: 4, value: null },
  { fieldId: 411, companyId: 4, value: 'support@acorns.com' },
  { fieldId: 300, companyId: 4, value: true },
  { fieldId: 431, companyId: 4, value: null },
  { fieldId: 433, companyId: 4, value: null },
  { fieldId: 435, companyId: 4, value: null },
  { fieldId: 439, companyId: 4, value: null },
  { fieldId: 190, companyId: 4, value: null },
  { fieldId: 191, companyId: 4, value: null },
  { fieldId: 174, companyId: 4, value: -1.785714285714286 },
  { fieldId: 177, companyId: 4, value: null },
  { fieldId: 175, companyId: 4, value: -0.2590673575129534 },
  { fieldId: 176, companyId: 4, value: -2.035623409669211 },
  { fieldId: 192, companyId: 4, value: null },
  { fieldId: 194, companyId: 4, value: null },
  { fieldId: 195, companyId: 4, value: null },
  { fieldId: 196, companyId: 4, value: null },
  { fieldId: 198, companyId: 4, value: null },
  { fieldId: 199, companyId: 4, value: null },
  { fieldId: 331, companyId: 4, value: -25 },
  { fieldId: 438, companyId: 4, value: null },
  {
    fieldId: 200,
    companyId: 4,
    value: [
      'Undisclosed Investors',
      'JAM Equity Partners',
      'Garland Capital Group',
      'Great Oaks Venture Capital',
      'Headline',
      'MATH Venture Partners',
      'Sound Ventures',
      'PayPal Ventures',
      'Rakuten Capital',
      'Thirty Five Ventures',
      'Point72 Ventures',
      'The Rise Fund',
      'Bain Capital Ventures',
      'Capital Group Companies',
      'Nyca Partners',
      'BlackRock',
      'Comcast Ventures',
      'DST Global',
      'MSD Capital',
      'NBC Universal',
      'TPG Capital',
      'A-Rod',
      'Jennifer Lopez',
      'Bertelsmann Digital Media Investments',
      'Dany Garcia',
      'Dwayne Johnson',
      'CE Innovation Capital',
      'Galaxy Digital',
      'Industry Ventures',
      'Owl Rock Capital Partners',
      'Senator Investment Group',
      'Torch Capital',
    ],
  },
  { fieldId: 398, companyId: 4, value: 'Irvine' },
  { fieldId: 440, companyId: 4, value: null },
  { fieldId: 365, companyId: 4, value: null },
  { fieldId: 418, companyId: 4, value: 4 },
  { fieldId: 415, companyId: 4, value: 11 },
  { fieldId: 414, companyId: 4, value: 2 },
  { fieldId: 105, companyId: 4, value: 2351513.14444386 },
  { fieldId: 334, companyId: 4, value: -50 },
  { fieldId: 442, companyId: 4, value: null },
  { fieldId: 453, companyId: 4, value: -0.1949317738791423 },
  { fieldId: 459, companyId: 4, value: -1.1583011583011582 },
  { fieldId: 323, companyId: 4, value: 512 },
  { fieldId: 372, companyId: 4, value: null },
  { fieldId: 341, companyId: 4, value: 0 },
  { fieldId: 412, companyId: 4, value: 2 },
  { fieldId: 375, companyId: 4, value: null },
  { fieldId: 188, companyId: 4, value: null },
  { fieldId: 321, companyId: 4, value: 4.7 },
  { fieldId: 318, companyId: 4, value: 4.4 },
  { fieldId: 455, companyId: 4, value: 3.8539553752535496 },
  { fieldId: 457, companyId: 4, value: -0.38910505836575876 },
  { fieldId: 10, companyId: 4, value: 9832982 },
  { fieldId: 338, companyId: 4, value: 2 },
  { fieldId: 316, companyId: 4, value: 3.9 },
  { fieldId: 401, companyId: 4, value: 'United States' },
  { fieldId: 406, companyId: 4, value: '2008-01-01T00:00:00Z' },
  { fieldId: 314, companyId: 4, value: 4.3 },
  { fieldId: 366, companyId: 4, value: null },
  { fieldId: 241, companyId: 4, value: ['Cornell University'] },
  { fieldId: 371, companyId: 4, value: null },
  { fieldId: 247, companyId: 4, value: 15 },
  { fieldId: 416, companyId: 4, value: 1 },
  { fieldId: 315, companyId: 4, value: 0.76 },
  {
    fieldId: 251,
    companyId: 4,
    value: 'Asset & Financial Management & Trading',
  },
  { fieldId: 272, companyId: 4, value: ['linkedin.com/in/noahkerner'] },
  { fieldId: 319, companyId: 4, value: 4.3 },
  { fieldId: 262, companyId: 4, value: ['Noah Kerner'] },
  { fieldId: 317, companyId: 4, value: 4.1 },
  { fieldId: 59, companyId: 4, value: 175990 },
  { fieldId: 79, companyId: 4, value: 3.410098103 },
  { fieldId: 69, companyId: 4, value: 39970 },
  { fieldId: 290, companyId: 4, value: true },
  { fieldId: 349, companyId: 4, value: -96.15384615384616 },
  { fieldId: 322, companyId: 4, value: 0.87 },
  { fieldId: 419, companyId: 4, value: 11 },
  { fieldId: 3, companyId: 353, value: null },
  { fieldId: 37, companyId: 353, value: 'public.com' },
  { fieldId: 4, companyId: 353, value: null },
  { fieldId: 8, companyId: 353, value: 'Mobile Software & Services' },
  { fieldId: 9, companyId: 353, value: 7692 },
  { fieldId: 320, companyId: 353, value: null },
  {
    fieldId: 299,
    companyId: 353,
    value: ['Sean Hendelman', 'Jannick Malling', 'Peter Quinn'],
  },
  { fieldId: 378, companyId: 353, value: null },
  { fieldId: 50, companyId: 353, value: 49 },
  { fieldId: 286, companyId: 353, value: '2021-12-06' },
  { fieldId: 350, companyId: 353, value: null },
  { fieldId: 11, companyId: 353, value: 3277 },
  { fieldId: 287, companyId: 353, value: 'Angel' },
  { fieldId: 47, companyId: 353, value: 220000000 },
  { fieldId: 422, companyId: 353, value: null },
  {
    fieldId: 89,
    companyId: 353,
    value: [
      'Peter Dartley,Bixby,linkedin.com/in/peter-dartley-82a2b716',
      'Alison K. Engel,EXE Technologies,linkedin.com/in/alisonengel',
      'Alison Lange Engel,CBS,linkedin.com/in/alisonlangeengel',
      'Sameer K. Gandhi,Oracle,linkedin.com/in/skgandhi',
      'Oliver Heimes,Harvard Business School,linkedin.com/in/oahzrh',
      'Sean Hendelman,T3 Trading Group,linkedin.com/in/seanhendelman',
      'Jessica Neal,Netflix,linkedin.com/in/jeneal',
      'Ian Sigalow,Boston Millennia Partners,linkedin.com/in/iansigalow',
    ],
  },
  { fieldId: 359, companyId: 353, value: null },
  { fieldId: 408, companyId: 353, value: 'New York' },
  { fieldId: 267, companyId: 353, value: [] },
  { fieldId: 83, companyId: 353, value: 4415 },
  { fieldId: 477, companyId: 353, value: null },
  { fieldId: 324, companyId: 353, value: null },
  { fieldId: 51, companyId: 353, value: 220000000 },
  { fieldId: 71, companyId: 353, value: 38700 },
  { fieldId: 64, companyId: 353, value: 41631 },
  { fieldId: 87, companyId: 353, value: 8665480.10455003 },
  { fieldId: 97, companyId: 353, value: null },
  {
    fieldId: 103,
    companyId: 353,
    value: [
      'Investment - 15',
      'New Offering - 9',
      'Partnership - 7',
      'Acquisition - 3',
      'Recognition - 1',
      'Expansion - 1',
    ],
  },
  {
    fieldId: 104,
    companyId: 353,
    value: [
      'Investment - 15',
      'New Offering - 9',
      'Partnership - 7',
      'Acquisition - 3',
      'Recognition - 1',
      'Expansion - 1',
    ],
  },
  { fieldId: 99, companyId: 353, value: null },
  { fieldId: 100, companyId: 353, value: null },
  {
    fieldId: 101,
    companyId: 353,
    value: [
      'Investment - 15',
      'New Offering - 10',
      'Partnership - 9',
      'Acquisition - 3',
      'Recognition - 1',
      'Expansion - 1',
    ],
  },
  { fieldId: 353, companyId: 353, value: null },
  { fieldId: 428, companyId: 353, value: null },
  { fieldId: 426, companyId: 353, value: null },
  { fieldId: 347, companyId: 353, value: null },
  { fieldId: 429, companyId: 353, value: null },
  { fieldId: 427, companyId: 353, value: null },
  { fieldId: 425, companyId: 353, value: null },
  { fieldId: 327, companyId: 353, value: null },
  { fieldId: 161, companyId: 353, value: 10.69227981152591 },
  { fieldId: 163, companyId: 353, value: 25.93814432989691 },
  { fieldId: 434, companyId: 353, value: null },
  { fieldId: 344, companyId: 353, value: null },
  { fieldId: 362, companyId: 353, value: null },
  { fieldId: 395, companyId: 353, value: ['And Co', 'Tradable'] },
  { fieldId: 356, companyId: 353, value: null },
  { fieldId: 337, companyId: 353, value: null },
  { fieldId: 430, companyId: 353, value: null },
  { fieldId: 432, companyId: 353, value: null },
  { fieldId: 162, companyId: 353, value: 5.949696444058977 },
  { fieldId: 150, companyId: 353, value: -0.1103726275883581 },
  { fieldId: 148, companyId: 353, value: 0 },
  { fieldId: 151, companyId: 353, value: 0 },
  { fieldId: 149, companyId: 353, value: 0 },
  { fieldId: 147, companyId: 353, value: 1.859039836567927 },
  { fieldId: 145, companyId: 353, value: null },
  { fieldId: 146, companyId: 353, value: 0.8087343307723414 },
  { fieldId: 144, companyId: 353, value: 0.211034066927947 },
  { fieldId: 441, companyId: 353, value: null },
  { fieldId: 164, companyId: 353, value: 1.546134663341646 },
  { fieldId: 304, companyId: 353, value: 'Invest in Culture' },
  {
    fieldId: 306,
    companyId: 353,
    value:
      'Otis is an alternative investment platform that makes it possible for almost anyone to invest in shares of cultural assets -- from contemporary art to rare comics to sneaker grails, sports memorabilia, and more. Every week we drop new cultural assets on our app and members can trade with one another 24/7. Our long-term ambitions are to create a NASDAQ of culture, usher in a new asset class for our generation, and fundamentally change the way art and culture are financed and created around the world.',
  },
  { fieldId: 437, companyId: 353, value: null },
  { fieldId: 411, companyId: 353, value: 'press@public.com' },
  { fieldId: 300, companyId: 353, value: true },
  { fieldId: 431, companyId: 353, value: null },
  { fieldId: 433, companyId: 353, value: null },
  { fieldId: 435, companyId: 353, value: null },
  { fieldId: 439, companyId: 353, value: null },
  { fieldId: 190, companyId: 353, value: null },
  { fieldId: 191, companyId: 353, value: null },
  { fieldId: 174, companyId: 353, value: -1.023017902813299 },
  { fieldId: 177, companyId: 353, value: 0 },
  { fieldId: 175, companyId: 353, value: 0 },
  { fieldId: 176, companyId: 353, value: -0.2577319587628866 },
  { fieldId: 192, companyId: 353, value: null },
  { fieldId: 194, companyId: 353, value: null },
  { fieldId: 195, companyId: 353, value: null },
  { fieldId: 196, companyId: 353, value: null },
  { fieldId: 198, companyId: 353, value: null },
  { fieldId: 199, companyId: 353, value: null },
  { fieldId: 331, companyId: 353, value: null },
  { fieldId: 438, companyId: 353, value: null },
  {
    fieldId: 200,
    companyId: 353,
    value: [
      'Peter Dartley',
      'Sean Hendelman',
      'Accel',
      'Advancit Capital',
      'Casey Neistat',
      'Dreamers Fund',
      'Justin James Watt',
      'Morgan DeBaun',
      'Scott Belsky',
      'Sophia Amoruso',
      'Paycheck Protection Program',
      'Mohammed Alabsi',
      'Scott Galloway',
      'Dick Parsons',
      'Lakestar',
      'MANTIS Venture Capital',
      'Tony Hawk',
      'Aglae Ventures',
      'Inspired Capital',
      'Intuition Capital',
      'Philip DeFranco',
      'Tiger Global Management',
      'VIne Capital',
      'Bobby Wagner',
      'Maria Sharapova',
      'West Quad',
    ],
  },
  { fieldId: 398, companyId: 353, value: 'New York' },
  { fieldId: 440, companyId: 353, value: null },
  { fieldId: 365, companyId: 353, value: null },
  { fieldId: 418, companyId: 353, value: 4 },
  { fieldId: 415, companyId: 353, value: 5 },
  { fieldId: 414, companyId: 353, value: 3 },
  { fieldId: 105, companyId: 353, value: 278728 },
  { fieldId: 334, companyId: 353, value: null },
  { fieldId: 442, companyId: 353, value: null },
  { fieldId: 453, companyId: 353, value: -0.48543689320388345 },
  { fieldId: 459, companyId: 353, value: -1.2048192771084338 },
  { fieldId: 323, companyId: 353, value: 410 },
  { fieldId: 372, companyId: 353, value: null },
  { fieldId: 341, companyId: 353, value: null },
  { fieldId: 412, companyId: 353, value: 0 },
  { fieldId: 375, companyId: 353, value: null },
  { fieldId: 188, companyId: 353, value: null },
  { fieldId: 321, companyId: 353, value: null },
  { fieldId: 318, companyId: 353, value: null },
  { fieldId: 455, companyId: 353, value: -0.966183574879227 },
  { fieldId: 457, companyId: 353, value: -1.6786570743405276 },
  { fieldId: 10, companyId: 353, value: 1956557 },
  { fieldId: 338, companyId: 353, value: null },
  { fieldId: 316, companyId: 353, value: null },
  { fieldId: 401, companyId: 353, value: 'United States' },
  { fieldId: 406, companyId: 353, value: '2015-01-01T00:00:00Z' },
  { fieldId: 314, companyId: 353, value: null },
  { fieldId: 366, companyId: 353, value: null },
  { fieldId: 241, companyId: 353, value: ['University of Copenhagen'] },
  { fieldId: 371, companyId: 353, value: null },
  { fieldId: 247, companyId: 353, value: 12 },
  { fieldId: 416, companyId: 353, value: 1 },
  { fieldId: 315, companyId: 353, value: null },
  {
    fieldId: 251,
    companyId: 353,
    value: 'Asset & Financial Management & Trading',
  },
  {
    fieldId: 272,
    companyId: 353,
    value: ['linkedin.com/in/leifabraham', 'linkedin.com/in/jmalling'],
  },
  { fieldId: 319, companyId: 353, value: null },
  {
    fieldId: 262,
    companyId: 353,
    value: ['Leif Abraham', 'Jannick Malling'],
  },
  { fieldId: 317, companyId: 353, value: null },
  { fieldId: 59, companyId: 353, value: 4986 },
  { fieldId: 79, companyId: 353, value: 31.089377833 },
  { fieldId: 69, companyId: 353, value: 9162 },
  { fieldId: 290, companyId: 353, value: true },
  { fieldId: 349, companyId: 353, value: null },
  { fieldId: 322, companyId: 353, value: null },
  { fieldId: 419, companyId: 353, value: 3 },
  { fieldId: 3, companyId: 5780, value: null },
  { fieldId: 37, companyId: 5780, value: 'ellevest.com' },
  { fieldId: 4, companyId: 5780, value: null },
  { fieldId: 8, companyId: 5780, value: 'Mobile Software & Services' },
  { fieldId: 9, companyId: 5780, value: null },
  { fieldId: 320, companyId: 5780, value: 3.4 },
  {
    fieldId: 299,
    companyId: 5780,
    value: ['Sallie Krawcheck', 'Charlie Kroll'],
  },
  { fieldId: 378, companyId: 5780, value: null },
  { fieldId: 50, companyId: 5780, value: 57 },
  { fieldId: 286, companyId: 5780, value: '2022-04-06' },
  { fieldId: 350, companyId: 5780, value: 0 },
  { fieldId: 11, companyId: 5780, value: null },
  { fieldId: 287, companyId: 5780, value: 'Series B' },
  { fieldId: 47, companyId: 5780, value: 53000000 },
  { fieldId: 422, companyId: 5780, value: 0.68 },
  {
    fieldId: 89,
    companyId: 5780,
    value: [
      'Jenny Abramson,The Boston Consulting Group,linkedin.com/in/jenny-abramson-bb75297',
      'Momo Bi,King & Wood Mallesons,linkedin.com/in/momobi',
      'Rochelle King,Silman,linkedin.com/in/rochelleking',
      'Kinjil Mathur,Protiviti,linkedin.com/in/kinjil',
      'Catherine Odelbo,Morningstar,linkedin.com/in/catherineodelbo',
    ],
  },
  { fieldId: 359, companyId: 5780, value: null },
  { fieldId: 408, companyId: 5780, value: 'New York' },
  { fieldId: 267, companyId: 5780, value: [] },
  { fieldId: 83, companyId: 5780, value: null },
  { fieldId: 477, companyId: 5780, value: null },
  { fieldId: 324, companyId: 5780, value: 0 },
  { fieldId: 51, companyId: 5780, value: 153400000 },
  { fieldId: 71, companyId: 5780, value: 24800 },
  { fieldId: 64, companyId: 5780, value: 350147 },
  { fieldId: 87, companyId: 5780, value: null },
  { fieldId: 97, companyId: 5780, value: ['Leadership - 1'] },
  {
    fieldId: 103,
    companyId: 5780,
    value: ['Investment - 18', 'New Offering - 11', 'Recognition - 4', 'Partnership - 2'],
  },
  {
    fieldId: 104,
    companyId: 5780,
    value: ['Investment - 18', 'New Offering - 11', 'Recognition - 4', 'Partnership - 2'],
  },
  { fieldId: 99, companyId: 5780, value: ['Leadership - 1'] },
  { fieldId: 100, companyId: 5780, value: ['Leadership - 1'] },
  {
    fieldId: 101,
    companyId: 5780,
    value: ['Investment - 18', 'New Offering - 11', 'Recognition - 4', 'Partnership - 2'],
  },
  { fieldId: 353, companyId: 5780, value: null },
  { fieldId: 428, companyId: 5780, value: null },
  { fieldId: 426, companyId: 5780, value: null },
  { fieldId: 347, companyId: 5780, value: null },
  { fieldId: 429, companyId: 5780, value: null },
  { fieldId: 427, companyId: 5780, value: null },
  { fieldId: 425, companyId: 5780, value: null },
  { fieldId: 327, companyId: 5780, value: null },
  { fieldId: 161, companyId: 5780, value: 5.064914249078378 },
  { fieldId: 163, companyId: 5780, value: 14.80060946776651 },
  { fieldId: 434, companyId: 5780, value: null },
  { fieldId: 344, companyId: 5780, value: null },
  { fieldId: 362, companyId: 5780, value: null },
  { fieldId: 395, companyId: 5780, value: null },
  { fieldId: 356, companyId: 5780, value: null },
  { fieldId: 337, companyId: 5780, value: null },
  { fieldId: 430, companyId: 5780, value: null },
  { fieldId: 432, companyId: 5780, value: null },
  { fieldId: 162, companyId: 5780, value: 3.130088655690248 },
  { fieldId: 150, companyId: 5780, value: 0.8429813950809285 },
  { fieldId: 148, companyId: 5780, value: 0 },
  { fieldId: 151, companyId: 5780, value: 0 },
  { fieldId: 149, companyId: 5780, value: 0 },
  { fieldId: 147, companyId: 5780, value: 0.2435110477708079 },
  { fieldId: 145, companyId: 5780, value: null },
  { fieldId: 146, companyId: 5780, value: 0.2108994637768953 },
  { fieldId: 144, companyId: 5780, value: 0.07337743447843208 },
  { fieldId: 441, companyId: 5780, value: null },
  { fieldId: 164, companyId: 5780, value: 0.8880611946500854 },
  {
    fieldId: 304,
    companyId: 5780,
    value:
      'The only financial company built for women, by women. Get a personalized investment plan at www.ellevest.com',
  },
  {
    fieldId: 306,
    companyId: 5780,
    value:
      'The only investing platform built for women, by women. Make your money work harder for you at ellevest.com.',
  },
  { fieldId: 437, companyId: 5780, value: null },
  { fieldId: 411, companyId: 5780, value: 'support@ellevest.com' },
  { fieldId: 300, companyId: 5780, value: true },
  { fieldId: 431, companyId: 5780, value: null },
  { fieldId: 433, companyId: 5780, value: null },
  { fieldId: 435, companyId: 5780, value: null },
  { fieldId: 439, companyId: 5780, value: null },
  { fieldId: 190, companyId: 5780, value: null },
  { fieldId: 191, companyId: 5780, value: null },
  { fieldId: 174, companyId: 5780, value: -2.745098039215686 },
  { fieldId: 177, companyId: 5780, value: 0 },
  { fieldId: 175, companyId: 5780, value: 0 },
  { fieldId: 176, companyId: 5780, value: -1.587301587301587 },
  { fieldId: 192, companyId: 5780, value: null },
  { fieldId: 194, companyId: 5780, value: null },
  { fieldId: 195, companyId: 5780, value: null },
  { fieldId: 196, companyId: 5780, value: null },
  { fieldId: 198, companyId: 5780, value: null },
  { fieldId: 199, companyId: 5780, value: null },
  { fieldId: 331, companyId: 5780, value: null },
  { fieldId: 438, companyId: 5780, value: null },
  {
    fieldId: 200,
    companyId: 5780,
    value: [
      'Undisclosed Investors',
      'Ajay Banga',
      'Andrea Jung',
      'Brian Finn',
      'Contour Venture Partners',
      'Karen Finerman',
      'Mohamed El-Erian',
      'Morningstar',
      'Robert Druskin',
      'Aspect Ventures',
      'Astia Angels',
      'Broadway Angels',
      'Karen Boezi',
      'Khosla Ventures',
      'Max Levchin',
      'Mellody Hobson',
      'Nellie Levchin',
      'The Perkins Fund',
      'Ulu Ventures',
      'Venus Williams',
      'CE Innovation Capital',
      'LH Holdings',
      'PSP Capital Partners',
      'Rethink Impact',
      'Salesforce Ventures',
      'Elaine Wynn',
      'Eric Schmidt',
      'Gingerbread Capital',
      'Mastercard',
      'PSP Growth',
      'PayPal Ventures',
      'Pivotal Ventures',
      'Valerie Jarrett',
      'AME Cloud Ventures',
      'Allianz Life',
      'Astia',
      'Bank of Montreal',
      'Cleo Capital',
      'Envestnet',
      'Gaingels',
      'Halogen Ventures',
      'New York Ventures',
      'Stardust Equity',
      'The Venture Collective',
    ],
  },
  { fieldId: 398, companyId: 5780, value: 'New York' },
  { fieldId: 440, companyId: 5780, value: null },
  { fieldId: 365, companyId: 5780, value: null },
  { fieldId: 418, companyId: 5780, value: 4 },
  { fieldId: 415, companyId: 5780, value: 2 },
  { fieldId: 414, companyId: 5780, value: 3 },
  { fieldId: 105, companyId: 5780, value: null },
  { fieldId: 334, companyId: 5780, value: null },
  { fieldId: 442, companyId: 5780, value: null },
  { fieldId: 453, companyId: 5780, value: -1.9607843137254901 },
  { fieldId: 459, companyId: 5780, value: -10.714285714285714 },
  { fieldId: 323, companyId: 5780, value: 150 },
  { fieldId: 372, companyId: 5780, value: null },
  { fieldId: 341, companyId: 5780, value: null },
  { fieldId: 412, companyId: 5780, value: 0 },
  { fieldId: 375, companyId: 5780, value: null },
  { fieldId: 188, companyId: 5780, value: null },
  { fieldId: 321, companyId: 5780, value: 3.8 },
  { fieldId: 318, companyId: 5780, value: 3.6 },
  { fieldId: 455, companyId: 5780, value: -4.45859872611465 },
  { fieldId: 457, companyId: 5780, value: -2.5974025974025974 },
  { fieldId: 10, companyId: 5780, value: null },
  { fieldId: 338, companyId: 5780, value: 0 },
  { fieldId: 316, companyId: 5780, value: 3.2 },
  { fieldId: 401, companyId: 5780, value: 'United States' },
  { fieldId: 406, companyId: 5780, value: '2014-01-01T00:00:00Z' },
  { fieldId: 314, companyId: 5780, value: 3.4 },
  { fieldId: 366, companyId: 5780, value: null },
  {
    fieldId: 241,
    companyId: 5780,
    value: ['Columbia University in the City of New York'],
  },
  { fieldId: 371, companyId: 5780, value: null },
  { fieldId: 247, companyId: 5780, value: 7 },
  { fieldId: 416, companyId: 5780, value: 1 },
  { fieldId: 315, companyId: 5780, value: 0.51 },
  {
    fieldId: 251,
    companyId: 5780,
    value: 'Asset & Financial Management & Trading',
  },
  {
    fieldId: 272,
    companyId: 5780,
    value: ['linkedin.com/in/salliekrawcheck'],
  },
  { fieldId: 319, companyId: 5780, value: 3.8 },
  { fieldId: 262, companyId: 5780, value: ['Sallie Krawcheck'] },
  { fieldId: 317, companyId: 5780, value: 3.4 },
  { fieldId: 59, companyId: 5780, value: 89270 },
  { fieldId: 79, companyId: 5780, value: null },
  { fieldId: 69, companyId: 5780, value: 65124 },
  { fieldId: 290, companyId: 5780, value: true },
  { fieldId: 349, companyId: 5780, value: null },
  { fieldId: 322, companyId: 5780, value: 0.49 },
  { fieldId: 419, companyId: 5780, value: 3 },
  { fieldId: 3, companyId: 4607, value: null },
  { fieldId: 37, companyId: 4607, value: 'stash.com' },
  { fieldId: 4, companyId: 4607, value: null },
  { fieldId: 8, companyId: 4607, value: 'Mobile Software & Services' },
  { fieldId: 9, companyId: 4607, value: 75061 },
  { fieldId: 320, companyId: 4607, value: 3.5 },
  {
    fieldId: 299,
    companyId: 4607,
    value: ['Brandon Krieg', 'Edward Robinson', 'David Ronick'],
  },
  { fieldId: 378, companyId: 4607, value: null },
  { fieldId: 50, companyId: 4607, value: 14 },
  { fieldId: 286, companyId: 4607, value: '2022-10-14' },
  { fieldId: 350, companyId: 4607, value: 4 },
  { fieldId: 11, companyId: 4607, value: 53651 },
  { fieldId: 287, companyId: 4607, value: 'Debt' },
  { fieldId: 47, companyId: 4607, value: 52600000 },
  { fieldId: 422, companyId: 4607, value: 0.71 },
  {
    fieldId: 89,
    companyId: 4607,
    value: [
      'Chi-Hua Chien,Coremetrics,linkedin.com/in/chchien',
      'Seyonne Kang,J.P. Morgan,linkedin.com/in/seyonne-kang-b283021',
      'Andrew McCormack,Ecount,linkedin.com/in/drewmccormack',
      'Jonathan McNeill,Bain,linkedin.com/in/jonmcneill1',
      'J. D. Moriarty,Bank of America Merrill Lynch,linkedin.com/in/jdmoriarty',
      'Edward Suh,Plaxo,linkedin.com/in/edsuh',
    ],
  },
  { fieldId: 359, companyId: 4607, value: -42.857142857142854 },
  { fieldId: 408, companyId: 4607, value: 'New York' },
  { fieldId: 267, companyId: 4607, value: [] },
  { fieldId: 83, companyId: 4607, value: 21410 },
  { fieldId: 477, companyId: 4607, value: null },
  { fieldId: 324, companyId: 4607, value: 14 },
  { fieldId: 51, companyId: 4607, value: 480000000 },
  { fieldId: 71, companyId: 4607, value: 126000 },
  { fieldId: 64, companyId: 4607, value: 110910 },
  { fieldId: 87, companyId: 4607, value: 14179581.3484857 },
  { fieldId: 97, companyId: 4607, value: ['Leadership - 1'] },
  {
    fieldId: 103,
    companyId: 4607,
    value: ['Investment - 6', 'New Offering - 5', 'Partnership - 2'],
  },
  {
    fieldId: 104,
    companyId: 4607,
    value: ['New Offering - 5', 'Investment - 4', 'Partnership - 1'],
  },
  { fieldId: 99, companyId: 4607, value: ['Leadership - 1'] },
  { fieldId: 100, companyId: 4607, value: ['Leadership - 1'] },
  {
    fieldId: 101,
    companyId: 4607,
    value: ['New Offering - 6', 'Investment - 6', 'Partnership - 3', 'Recognition - 1'],
  },
  { fieldId: 353, companyId: 4607, value: 300 },
  { fieldId: 428, companyId: 4607, value: null },
  { fieldId: 426, companyId: 4607, value: null },
  { fieldId: 347, companyId: 4607, value: -22.22222222222222 },
  { fieldId: 429, companyId: 4607, value: null },
  { fieldId: 427, companyId: 4607, value: null },
  { fieldId: 425, companyId: 4607, value: null },
  { fieldId: 327, companyId: 4607, value: 75 },
  { fieldId: 161, companyId: 4607, value: 6.299517588647488 },
  { fieldId: 163, companyId: 4607, value: 3018800 },
  { fieldId: 434, companyId: 4607, value: null },
  { fieldId: 344, companyId: 4607, value: 250 },
  { fieldId: 362, companyId: 4607, value: -90.47619047619048 },
  { fieldId: 395, companyId: 4607, value: null },
  { fieldId: 356, companyId: 4607, value: -20 },
  { fieldId: 337, companyId: 4607, value: -84.78260869565217 },
  { fieldId: 430, companyId: 4607, value: null },
  { fieldId: 432, companyId: 4607, value: null },
  { fieldId: 162, companyId: 4607, value: 3.797685973146286 },
  { fieldId: 150, companyId: 4607, value: 0.03066488690068184 },
  { fieldId: 148, companyId: 4607, value: 0 },
  { fieldId: 151, companyId: 4607, value: 0 },
  { fieldId: 149, companyId: 4607, value: 0 },
  { fieldId: 147, companyId: 4607, value: 1.274158318203183 },
  { fieldId: 145, companyId: 4607, value: null },
  { fieldId: 146, companyId: 4607, value: 0.4761589086062922 },
  { fieldId: 144, companyId: 4607, value: 0.1250589417143326 },
  { fieldId: 441, companyId: 4607, value: null },
  { fieldId: 164, companyId: 4607, value: 1.169610241630082 },
  {
    fieldId: 304,
    companyId: 4607,
    value: 'We’re helping everyday Americans invest and build wealth. (stsh.app/disclosures)',
  },
  {
    fieldId: 306,
    companyId: 4607,
    value:
      'Stash is a personal finance app that can make investing easy and affordable for millions of Americans. From budgeting paychecks to saving for retirement, we help Stashers create more financial freedom.\n\nSince launching in 2015, over 5 million people have joined Stash to increase their financial freedom. Our 250+ employees are on a mission to help you invest in yourself and build wealth.\n\n(Disclosures: stsh.app/disclosures)',
  },
  { fieldId: 437, companyId: 4607, value: null },
  { fieldId: 411, companyId: 4607, value: 'support@stashinvest.com' },
  { fieldId: 300, companyId: 4607, value: true },
  { fieldId: 431, companyId: 4607, value: null },
  { fieldId: 433, companyId: 4607, value: null },
  { fieldId: 435, companyId: 4607, value: null },
  { fieldId: 439, companyId: 4607, value: null },
  { fieldId: 190, companyId: 4607, value: null },
  { fieldId: 191, companyId: 4607, value: null },
  { fieldId: 174, companyId: 4607, value: -1.496062992125984 },
  { fieldId: 177, companyId: 4607, value: -0.7142857142857143 },
  { fieldId: 175, companyId: 4607, value: -1.028481012658228 },
  { fieldId: 176, companyId: 4607, value: -1.496062992125984 },
  { fieldId: 192, companyId: 4607, value: null },
  { fieldId: 194, companyId: 4607, value: null },
  { fieldId: 195, companyId: 4607, value: null },
  { fieldId: 196, companyId: 4607, value: null },
  { fieldId: 198, companyId: 4607, value: null },
  { fieldId: 199, companyId: 4607, value: null },
  { fieldId: 331, companyId: 4607, value: 7.6923076923076925 },
  { fieldId: 438, companyId: 4607, value: null },
  {
    fieldId: 200,
    companyId: 4607,
    value: [
      'Undisclosed Investors',
      'Entree Capital',
      'Goodwater Capital',
      'Peter Thiel',
      'Valar Ventures',
      'Breyer Capital',
      'Coatue Management',
      'Union Square Ventures',
      'Greenspring Associates',
      'LendingTree',
      'T. Rowe Price',
      'Eldridge',
      'Owl Ventures',
      'NAB Ventures',
      'Pacific Community Ventures',
    ],
  },
  { fieldId: 398, companyId: 4607, value: 'New York' },
  { fieldId: 440, companyId: 4607, value: null },
  { fieldId: 365, companyId: 4607, value: null },
  { fieldId: 418, companyId: 4607, value: 21 },
  { fieldId: 415, companyId: 4607, value: 23 },
  { fieldId: 414, companyId: 4607, value: 1 },
  { fieldId: 105, companyId: 4607, value: 2198555.57040021 },
  { fieldId: 334, companyId: 4607, value: -17.647058823529413 },
  { fieldId: 442, companyId: 4607, value: null },
  { fieldId: 453, companyId: 4607, value: -1.2 },
  { fieldId: 459, companyId: 4607, value: -7.663551401869159 },
  { fieldId: 323, companyId: 4607, value: 494 },
  { fieldId: 372, companyId: 4607, value: null },
  { fieldId: 341, companyId: 4607, value: 600 },
  { fieldId: 412, companyId: 4607, value: 2 },
  { fieldId: 375, companyId: 4607, value: null },
  { fieldId: 188, companyId: 4607, value: null },
  { fieldId: 321, companyId: 4607, value: 3.9 },
  { fieldId: 318, companyId: 4607, value: 3.9 },
  { fieldId: 455, companyId: 4607, value: -10.018214936247723 },
  { fieldId: 457, companyId: 4607, value: -4.448742746615087 },
  { fieldId: 10, companyId: 4607, value: 12604673 },
  { fieldId: 338, companyId: 4607, value: 7 },
  { fieldId: 316, companyId: 4607, value: 3.4 },
  { fieldId: 401, companyId: 4607, value: 'United States' },
  { fieldId: 406, companyId: 4607, value: '2015-01-01T00:00:00Z' },
  { fieldId: 314, companyId: 4607, value: 3.8 },
  { fieldId: 366, companyId: 4607, value: null },
  { fieldId: 241, companyId: 4607, value: null },
  { fieldId: 371, companyId: 4607, value: null },
  { fieldId: 247, companyId: 4607, value: 12 },
  { fieldId: 416, companyId: 4607, value: 0 },
  { fieldId: 315, companyId: 4607, value: 0.56 },
  {
    fieldId: 251,
    companyId: 4607,
    value: 'Asset & Financial Management & Trading',
  },
  {
    fieldId: 272,
    companyId: 4607,
    value: ['linkedin.com/in/brandonkrieg1'],
  },
  { fieldId: 319, companyId: 4607, value: 4 },
  { fieldId: 262, companyId: 4607, value: ['Brandon Krieg'] },
  { fieldId: 317, companyId: 4607, value: 3.5 },
  { fieldId: 59, companyId: 4607, value: 219539 },
  { fieldId: 79, companyId: 4607, value: 6.449498725 },
  { fieldId: 69, companyId: 4607, value: 29918 },
  { fieldId: 290, companyId: 4607, value: false },
  { fieldId: 349, companyId: 4607, value: -85.41666666666666 },
  { fieldId: 322, companyId: 4607, value: 0.72 },
  { fieldId: 419, companyId: 4607, value: 11 },
];
