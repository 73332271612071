import { useAtom, useAtomValue } from 'jotai';
import { useRecoilValue } from 'recoil';
import { kpiConfigByIdMapState } from '../../../../../services/state/KPI/KPITemplatesState';
import { SelectItem } from '../../../../../types';
import { availableKPIChartsAtom, selectedKPIChartsAtom } from '../../../state/ProfileViewSettings';
import { selectedCompanyIdProfile } from '../../../state/UIState';
import { sortGroupableOptions } from '../../../utils/sortGroupableOptions';
import { mapFieldToSelectItem } from '../Statistics/useKPISelectMenu';

export function useKpiChartsMenu() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const [selectedKpis, setSelectedKPIs] = useAtom(selectedKPIChartsAtom(companyId));
  const allChartKpis = useAtomValue(availableKPIChartsAtom);
  const kpisById = useRecoilValue(kpiConfigByIdMapState);
  const chartOptions = sortGroupableOptions(allChartKpis.map((field) => mapFieldToSelectItem(field)));
  const selectedChartOptions = selectedKpis.map(mapFieldToSelectItem);

  const handleChange = (newValue: SelectItem[]) => {
    setSelectedKPIs(newValue.map((item) => kpisById.get(item.id as number)!));
  };

  return { chartOptions, selectedChartOptions, handleChange, selectedKpis };
}
