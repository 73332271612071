import { IconButton, Paper, Stack, styled, TextField, useTheme } from '@mui/material';
import SegmentOutlinedIcon from '@mui/icons-material/SegmentOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { useFormContext, useWatch } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { IField, RendererTypeOfComponent } from '../../../../../data-models/field2.data-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import { SelectConfig } from '../../../../../components/Form/Configuration/SelectConfig';
import { IKPITemplateSectionDataModel } from '../../../../../data-models/kpi-template.data-model';
import { ParagraphConfig } from './ParagraphConfig';
import { KPITableConfig } from './KPITableConfig';
import { FileUploadConfig } from './FileUploadConfig';

const BorderlessTextField = styled(TextField)(({ theme }) => ({
  ...theme.typography.body2,
  background: 'transparent',
  width: '100%',
  padding: 0,
  '& .MuiOutlinedInput-input': {
    background: 'transparent',
    padding: 0,
    borderColor: theme.colors.secondary[60],
    color: theme.colors.neutral[70],
    '&::placeholder': {
      color: theme.colors.primary[80],
    },
  },
}));

const SectionTextWrapper = styled(Paper)(({ theme }) => ({
  height: 'fit-content',
  ...theme.typography.body2,
  color: 'white',
  padding: '1rem',
  width: '100%',
}));

function IconBySectionType(type: string) {
  switch (type) {
    case RendererType.text:
      return <SegmentOutlinedIcon color='primary' />;
    case RendererType.fileUpload:
      return <InsertDriveFileOutlinedIcon color='primary' />;
    default:
      return <TimelineOutlinedIcon color='primary' />;
  }
}

interface ISectorFieldsByTypeProps {
  onRemoveSection: () => void;
  sectionRef: string;
  attributes: DraggableAttributes;
  listeners?: SyntheticListenerMap;
}

export function KPIBuildItemFactory(props: ISectorFieldsByTypeProps) {
  const { onRemoveSection, sectionRef, attributes, listeners } = props;
  const { colors } = useTheme();
  const { register, watch } = useFormContext();
  const { type } = watch(sectionRef) as IKPITemplateSectionDataModel;
  const sectionMetaRef = `${sectionRef}.meta`;
  const titlePath =
    type === RendererTypeOfComponent.grid ? `${sectionMetaRef}.displayName` : `${sectionMetaRef}.entityField`;
  const descriptionPath = `${sectionMetaRef}.description`;

  const field = useWatch({ name: sectionMetaRef });
  const rendererType = (field as IField<unknown>).formMeta?.renderer?.type ?? '';

  return (
    <SectionTextWrapper elevation={0}>
      <Stack gap='1rem'>
        <Stack
          direction='row'
          gap='.5rem'
          sx={{
            '&:hover': {
              '& div#section-icon': {
                display: 'none',
              },
              '& div#section-drag-action-btn': {
                display: 'inline-flex !important',
              },
            },
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              height: '44px',
              width: '44px',
              borderRadius: '4px',
              background: colors.secondary[10],
            }}
          >
            <Stack id='section-icon' justifyContent={'center'} alignItems={'center'}>
              {IconBySectionType(rendererType)}
            </Stack>
            <div
              {...attributes}
              {...listeners}
              id='section-drag-action-btn'
              style={{ cursor: 'move', display: 'none' }}
            >
              <DragIndicatorOutlinedIcon color='primary' />
            </div>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              padding: 0,
            }}
          >
            <BorderlessTextField
              placeholder='Section title'
              {...register(titlePath)}
              inputProps={{
                sx: {
                  fontSize: '.875rem',
                },
              }}
              sx={{
                margin: 0,
                padding: 0,
                '& fieldset': { border: 'none' },
                '& .MuiInputBase-root': {
                  minHeight: '0px',
                },
              }}
            />
            <BorderlessTextField
              placeholder='Description'
              inputProps={{
                sx: {
                  fontSize: '.725rem',
                },
              }}
              {...register(descriptionPath)}
              sx={{
                margin: 0,
                marginTop: '.25rem',
                '& .MuiInputBase-root': {
                  minHeight: '0px',
                },
                '& fieldset': { border: 'none' },
              }}
            />
          </Stack>
          <Stack>
            <IconButton onClick={onRemoveSection}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </Stack>
        <KPIBuildItem sectionMetaRef={sectionMetaRef} />
      </Stack>
    </SectionTextWrapper>
  );
}

interface KPIPreviewItemProps {
  sectionMetaRef: string;
}

export function KPIBuildItem(props: KPIPreviewItemProps) {
  const { sectionMetaRef } = props;
  const field = useWatch({ name: sectionMetaRef });

  if (field.metrics) {
    return <KPITableConfig metricsRef={`${sectionMetaRef}.metrics`} />;
  } else {
    const type = (field as IField<unknown>).formMeta?.renderer?.type ?? '';

    return <FieldPreviewItem type={type} sectionRef={sectionMetaRef} />;
  }
}

interface IFieldPreviewItemProps {
  type: RendererType | string;
  sectionRef: string;
}

function FieldPreviewItem(props: IFieldPreviewItemProps) {
  const { type, sectionRef } = props;

  switch (type) {
    case RendererType.multiSelect:
      return <SelectConfig valuesPath={`${sectionRef}.formMeta.renderer.config.values`} />;

    case RendererType.text:
      return <ParagraphConfig sectionRef={props.sectionRef} />;

    case RendererType.fileUpload:
      return <FileUploadConfig />;

    default:
      return null;
  }
}
