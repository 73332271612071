export const KpiPeriod = {
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
} as const;

export type KpiPeriod = Capitalize<Extract<KpiConfigPeriod, 'month' | 'quarter' | 'year'>>;

export type KpiPeriodExtended =
  | Extract<KpiConfigPeriod, 'CYTD' | 'LTM'>
  | Capitalize<Extract<KpiConfigPeriod, 'growthMonth' | 'growthYear' | 'growthQuarter'>>;

export enum KpiSection {
  actual = 'actual',
  budget = 'budget',
}

export interface ICompanyFinancialsDataModel {
  calculated?: boolean;
  companyId: number;
  createdAt: string;
  currencyId: number | null;
  date: string;
  iLevelId?: number;
  id: number;
  // kpiId should correspond to IField.id
  kpiId: number;
  period?: KpiPeriod | KpiPeriodExtended;
  section?: KpiSection;
  updatedAt: string;
  value: string;
}

export function createCompanyFinancialsDataModel(
  overrides: Partial<ICompanyFinancialsDataModel> = {}
): ICompanyFinancialsDataModel {
  return {
    calculated: false,
    companyId: 0,
    createdAt: '',
    currencyId: null,
    date: '',
    iLevelId: 0,
    id: 0,
    kpiId: 0,
    section: KpiSection.actual,
    updatedAt: '',
    value: '',
    ...overrides,
  };
}
export const KpiConfigPeriod = {
  GrowthMonth: 'growthMonth',
  GrowthQuarter: 'growthQuarter',
  GrowthYear: 'growthYear',
  Month: 'month',
  Quarter: 'quarter',
  Year: 'year',
  CYTD: 'CYTD',
  LTM: 'LTM',
} as const;

export type KpiConfigPeriod = (typeof KpiConfigPeriod)[keyof typeof KpiConfigPeriod];

export const KpiConfigPeriodToName: Record<KpiConfigPeriod, string> = {
  [KpiConfigPeriod.GrowthMonth]: 'MoM Change',
  [KpiConfigPeriod.GrowthQuarter]: 'QoQ Change',
  [KpiConfigPeriod.GrowthYear]: 'YoY Change',
  [KpiConfigPeriod.Month]: 'Month',
  [KpiConfigPeriod.Quarter]: 'Quarter',
  [KpiConfigPeriod.Year]: 'Year',
  [KpiConfigPeriod.CYTD]: 'CYTD',
  [KpiConfigPeriod.LTM]: 'LTM',
};
