import { IconButton, Typography, useTheme } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ErrorMessagesWrapper } from '../KPIItemFactory';

export type KPITableErrorsValueType = {
  numberFormatValue: {
    ref: HTMLInputElement;
  };
};

export interface IKPITableEditableMetricsErrors {
  onNextErrorMetric: () => void;
  onPrevErrorMetric: () => void;
  isValidFocusedMetricCeil: boolean;
  totalErrors: number;
}

export const KPITableEditableMetricsErrors = ({
  onNextErrorMetric,
  onPrevErrorMetric,
  isValidFocusedMetricCeil,
  totalErrors,
}: IKPITableEditableMetricsErrors) => {
  const { colors } = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        minWidth: '250px',
        marginBottom: '1.25rem',
      }}
    >
      {totalErrors ? (
        <ErrorMessagesWrapper
          sx={{
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            marginLeft: 0,
            paddingLeft: '.25rem',
            gap: '.25rem',
          }}
        >
          <IconButton onClick={onPrevErrorMetric}>
            <KeyboardArrowLeftIcon color='error' />
          </IconButton>
          <Typography
            variant='caption'
            color={colors.critical[50]}
            sx={{ display: 'flex', direction: 'row', alignItems: 'center', gap: '.25rem' }}
          >
            {isValidFocusedMetricCeil ? (
              <CheckCircleIcon color='success' />
            ) : (
              <ErrorOutlineOutlinedIcon
                sx={{
                  fontSize: '1.25rem',
                }}
              />
            )}
            /{totalErrors ?? null}
          </Typography>
          <IconButton onClick={onNextErrorMetric}>
            <KeyboardArrowRightIcon color='error' />
          </IconButton>
          <Typography
            variant='caption'
            color={colors.critical[50]}
            mr={'1rem'}
            sx={{ display: 'flex', direction: 'row', alignItems: 'center', gap: '.25rem' }}
          >
            Please enter the values
          </Typography>
        </ErrorMessagesWrapper>
      ) : null}
    </div>
  );
};
