import { updatePersonEmail } from '../../../../services/queries/MaggieCompanyQueries';

export const useUpdatePersonEmail = (personId: number) => {
  const handleUpdateEmail = async () => {
    const { email } = await updatePersonEmail(personId);
    return email;
  };

  return { handleUpdateEmail };
};
