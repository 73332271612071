import { atomFamily, selectorFamily } from 'recoil';
import { IDealDataModel } from '../../data-models/deal.data-model';
import { allDealsState } from '../../pages/DealFlow2/state/DealboardDataState';

export const DealsByCompanyIdState = selectorFamily<IDealDataModel[] | null, number>({
  key: 'dealsByCompanyIdState',
  get:
    (companyId) =>
    ({ get }) => {
      const allDeals = get(allDealsState);

      return allDeals.filter((deal) => deal.companyId === companyId);
    },
});

/**
 * In order to get the loading status of DealsByCompanyIdState, use this atomFamily.
 * Because of the way we are storing DealsByCompanyIdState as a selectorFamily, its loadable state is always 'hasValue', even if the data is null or being loaded. This has the effect of briefly showing wrong data when navigating between companies, for example in the StatusChip.
 * This should solve that problem.
 */

export const DealsForCompanyLoadedState = atomFamily<boolean, number>({
  key: 'DealsForCompanyLoadedState',
  default: false,
});
