import * as yup from 'yup';
import { merge } from 'lodash-es';
import { EntityType } from '../data-models/field2.data-model';
import { RendererType } from '../data-models/field.data-model';
import { BaseModelWithID, baseModelWithIdDataModel } from './BaseModel.schema';

export function rubricFields() {
  return {
    date: yup.date().nullable().required().default(null),
    entity: yup.string().oneOf(Object.keys(EntityType)).nullable().required().default(''),
    entityId: yup.number().nullable().required().default(0),
    rubricConfigId: yup.number().nullable().required().default(0),
    value: yup.string().nullable().required().default(''),
  };
}

export function rubricFormSchema() {
  return yup.object().shape(rubricFields());
}

export function rubricDataModel() {
  return yup.object().shape({
    ...rubricFields(),
    date: yup.string().nullable().required().default(null),
  });
}

export type Rubric = yup.InferType<ReturnType<typeof rubricDataModel>> & BaseModelWithID;

export function createRubric(overrides: Partial<Rubric> = {}): Rubric {
  return merge({}, rubricDataModel().getDefault(), baseModelWithIdDataModel().getDefault(), overrides);
}

// TODO: should match field base - create transform function?
export function rubricConfigFields() {
  return {
    description: yup.string().default(''),
    format: yup.string().default(RendererType.colorStatus),
    displayName: yup.string().required().default(''),
    type: yup.string().default('string'),
  };
}

export function rubricConfigSchema() {
  return yup.object().shape(rubricConfigFields());
}

export type RubricConfig = yup.InferType<ReturnType<typeof rubricConfigSchema>> & BaseModelWithID;

export function rubricConfigGridSchema() {
  const { displayName, description } = rubricConfigFields();
  return yup.object().shape({
    displayName,
    description,
    createdBy: yup.string().nullable().gridMeta({
      formatter: 'userByEmail',
    }),
    createdAt: yup.date().nullable().label('Created Date').gridMeta({
      renderer: RendererType.date,
    }),
  });
}

export function createRubricConfig(overrides: Partial<RubricConfig> = {}): RubricConfig {
  return merge({}, rubricConfigSchema().getDefault(), baseModelWithIdDataModel().getDefault(), overrides);
}

export function rubricAuditFields() {
  return {
    date: yup.date().nullable().required().default(null),
    rubricId: yup.number().nullable().required().default(0),
    value: yup.string().nullable().required().default(''),
  };
}

export function rubricAuditSchema() {
  return yup.object().shape(rubricAuditFields());
}

export function rubricAuditModel() {
  return yup.object().shape({
    ...rubricAuditFields(),
    date: yup.string().nullable().required().default(null),
  });
}

export type RubricAudit = yup.InferType<ReturnType<typeof rubricAuditModel>> & BaseModelWithID;

export function createRubricAudit(overrides: Partial<RubricAudit> = {}): RubricAudit {
  return merge({}, rubricAuditModel().getDefault(), baseModelWithIdDataModel().getDefault(), overrides);
}
