import { IListItemCommentDataModel } from '../pages/DealFlow2/data-models/comment.data-model';
import { ICompanyDataModel } from './company.data-model';

export type ListActionType = 'hidden' | 'pipeline' | null;

export interface ListItemsResponse {
  versions: IVersionDataModel[];
  items: IListItemDataModel[];
  companies: ICompanyDataModel[];
  token?: string;
}

export interface IListItemDataModel {
  id: number;
  listId: number;
  companyId: number;
  score: string;
  versionId: number;
  action: boolean | null;
  actionType: ListActionType;
  previousDeal?: boolean;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
  comments?: IListItemCommentDataModel[];
}

export interface IVersionDataModel {
  id: number;
  listId: number;
  version: string;
  createdBy: string | null;
  updatedBy: string | null;
  createdAt: string;
  updatedAt: string;
}

export function createVersionDataModel(overrides?: Partial<IVersionDataModel>) {
  return {
    id: -1,
    listId: -1,
    version: '',
    createdBy: null,
    updatedBy: null,
    createdAt: '',
    updatedAt: '',
    ...overrides,
  };
}

export function createListItemDataModel(overrides?: Partial<IListItemDataModel>) {
  return {
    id: 0,
    listId: 0,
    companyId: 0,
    score: '',
    versionId: 0,
    action: null,
    actionType: null,
    createdBy: null,
    updatedBy: null,
    createdAt: '',
    updatedAt: '',
    ...overrides,
  };
}

export interface ICreateListItemForm {
  name: string;
  website?: string;
  sectorId: number | null;
  assignee: number | null;
}

export const initCreateListItemForm = (
  overrides: Partial<ICreateListItemForm> = {}
): ICreateListItemForm => ({
  name: '',
  website: '',
  sectorId: null,
  assignee: null,
  ...overrides,
});
