import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, useTheme } from '@mui/material';
import MapsUgcRoundedIcon from '@mui/icons-material/MapsUgcRounded';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UserAvatarList } from '../../../../components/AvatarListTooltip/UserAvatarList';
import { IUserDataModel } from '../../../../data-models/user.data-model';
import { formatUSDShort } from '../../../../util/formatters/NumericFormatters';
import { DealCardComments } from '../Comment/DealCardComments';
import { getWarningMessage } from '../../helpers/checklistHelpers';
import { PriorityStar } from '../DealDrawer/PriorityStar';
import { roundsState, usersState } from '../../../../services/state/AppConfigState';
import { dealBoardConfigState } from '../../state/DealboardDataState';
import { isOpenDrawerState, selectedDealState, showCommentsState } from '../../state/DealboardUIState';
import { LazyLoad } from '../../../../components/LazyLoad/LazyLoad';
import { companyState } from '../../../../services/state/CompanyState';
import { IDealDataModel } from '../../../../data-models/deal.data-model';
import { PermissionService } from '../../../../services/PermissionService';
import { PermissionKey } from '../../../../services/PermissionAndRolesKeys';
import { mapSectorToColor } from './mapSectorToColor';
import { CompanyNameAndLogo } from './CompanyNameAndLogo';
import { CardMenu } from './CardMenu';

interface DealCardProps {
  deal: IDealDataModel;
}

interface CardProps {
  isStale?: boolean;
}

interface CardAndSectorBorderProps {
  sectorId: number | null;
}

const CardWrapper = styled('div')`
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: rgb(255 255 255 / 10%) 0px -4px 40px, rgb(16 37 62 / 6%) 0px 4px 24px,
    rgb(75 82 93 / 4%) 0px 24px 48px;
  width: 280px;
  min-height: 75px;
`;

const CardAndSectorBorder = styled('div')<CardAndSectorBorderProps>`
  border-left: 4px solid ${({ sectorId }) => mapSectorToColor(sectorId)};
  border-radius: 4px;
`;

const Card = styled('div')<CardProps>`
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* filter: ${({ isStale }) => (isStale ? 'grayscale(1)' : '')}; */
  background: ${({ isStale }) =>
    isStale
      ? 'radial-gradient(100% 204.54% at 0% 0%, rgba(210, 213, 219, 0.44) 0%, rgba(210, 213, 219, 0.176) 100%)'
      : 'radial-gradient(100% 204.54% at 0% 0%,rgba(249, 250, 250, 0.8) 0%, rgba(255, 255, 255, 0.32) 100%  )'};
`;

const CardHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.8rem;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const PriorityWrapper = styled('div')`
  margin-bottom: -2px;
`;

const DetailsAndCommentsWrapper = styled('div')<CardProps>`
  filter: ${({ isStale }) => (isStale ? 'grayscale(1)' : '')};
`;

const DetailsWrapper = styled('div')`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0;
`;

const InnerWrapper = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledRange = styled('span')`
  font-size: 0.9em;
`;

export const DealCard = ({ deal }: DealCardProps) => {
  const { colors } = useTheme();
  const users = useRecoilValue(usersState);
  const rounds = useRecoilValue(roundsState);
  const dealBoardConfig = useRecoilValue(dealBoardConfigState);
  const setIsOpenDrawer = useSetRecoilState(isOpenDrawerState);
  const setSelectedDeal = useSetRecoilState(selectedDealState);
  const displayComments = useRecoilValue(showCommentsState);
  const [showMenu, setShowMenu] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const ref = useRef(null);
  const company = useRecoilValue(companyState(deal.companyId));

  const canEditDeal = PermissionService.get().hasPermission(PermissionKey.canEditDeal);

  const { roundId, comments, dealTeamIds, dealLeadId, priority } = deal;
  const valuationMin = deal.gcAmountMin;
  const valuationMax = deal.gcAmountMax;

  const onClick = useCallback(() => {
    setSelectedDeal(deal);
    setIsOpenDrawer(true);
  }, [deal, setIsOpenDrawer, setSelectedDeal]);

  const dealMembers = useMemo(() => {
    if (!users) return [];
    const dealTeam = dealTeamIds
      ? dealTeamIds.map((id) => users.find((u) => u.id === id) as IUserDataModel)
      : [];
    const dealLead = users.find((u) => u.id === dealLeadId);

    return dealLead ? [dealLead, ...dealTeam.filter((u) => u.id !== dealLead.id)] : [...dealTeam];
  }, [dealLeadId, dealTeamIds, users]);

  const round = useMemo(() => {
    if (!roundId || !rounds) return null;
    return rounds.find((r) => r.id === roundId);
  }, [roundId, rounds]);

  const getFormatedRange = useCallback(() => {
    if (!valuationMax || !valuationMin) return '';
    if (valuationMax === valuationMin) return formatUSDShort(valuationMax);
    return `${formatUSDShort(valuationMin)} - ${formatUSDShort(valuationMax)}`;
  }, [valuationMax, valuationMin]);

  const handleMouseEnter = useCallback(() => {
    if (canEditDeal) setShowMenu(true);
  }, [canEditDeal]);

  const handleMouseLeave = useCallback(() => {
    setShowMenu(false);
  }, []);

  const isStale = useMemo(
    () => Date.now() - new Date(deal.stageUpdateDate).getTime() > 1000 * 60 * 60 * 24 * 15,
    [deal]
  );

  const warning = useMemo(() => {
    if (!dealBoardConfig) return false;
    const dealStage = dealBoardConfig.dealStages.find((s) => s.id === deal.stageId);
    if (!dealStage || !dealStage.meta.required?.length) return false;

    const required = dealStage.meta.required;

    // return true if todoList does not contain all required items
    return !deal.todoList || required.some((r) => !deal.todoList!.includes(r));
  }, [deal.stageId, deal.todoList, dealBoardConfig]);

  const warningMessage = useMemo(() => {
    if (!deal || !dealBoardConfig || !warning) return null;
    return getWarningMessage({ deal, dealBoardConfig });
  }, [deal, dealBoardConfig, warning]);

  useEffect(() => {
    setShowComments(displayComments);
  }, [displayComments]);

  if (!company) return null;

  return (
    <CardWrapper
      data-testid='deal-card'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      ref={ref}
    >
      <LazyLoad>
        <CardAndSectorBorder sectorId={company.sectorId || null}>
          <Card isStale={isStale}>
            <CardHeader>
              <CompanyNameAndLogo
                companyName={company.name}
                companyLogo={company.logoUrl || ''}
                warning={warning}
                warningMessage={warningMessage}
                isStale={isStale}
              />
              <IconWrapper>
                {(showMenu || priority === 1) && (
                  <PriorityWrapper>
                    <PriorityStar deal={deal} />
                  </PriorityWrapper>
                )}
                {canEditDeal && (
                  <PriorityWrapper>
                    <CardMenu
                      deal={deal}
                      company={company}
                      style={{ visibility: showMenu ? 'visible' : 'hidden' }}
                    />
                  </PriorityWrapper>
                )}
              </IconWrapper>
            </CardHeader>
            <DetailsAndCommentsWrapper isStale={isStale}>
              <DetailsWrapper>
                <Typography component='div' variant='body2' color={colors.neutral[60]}>
                  <InnerWrapper>
                    <span>{round?.displayName}</span>
                    <StyledRange>{getFormatedRange()}</StyledRange>
                  </InnerWrapper>
                </Typography>
                <InnerWrapper>
                  <UserAvatarList users={dealMembers} />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedDeal(deal);
                      setShowComments((prevState) => !prevState);
                    }}
                  >
                    <Button
                      startIcon={<MapsUgcRoundedIcon sx={{ marginRight: '-6px' }} />}
                      color={'secondary'}
                      sx={{ minHeight: 24, minWidth: 32 }}
                    >
                      {comments.length}
                    </Button>
                  </span>
                </InnerWrapper>
              </DetailsWrapper>
              {showComments && <DealCardComments comments={comments} deal={deal} />}
            </DetailsAndCommentsWrapper>
          </Card>
        </CardAndSectorBorder>
      </LazyLoad>
    </CardWrapper>
  );
};

export const DealCardMemo = memo(DealCard, (prevProps, nextProps) => {
  return prevProps.deal === nextProps.deal;
});
