import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColDef, GridApi, GridReadyEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { commonProcessCb } from '../../../../../components/AgTable/exportToExcelDefs';
import { roundsMapState } from '../../../../../services/state/AppConfigState';
import { IDealStageDataModel } from '../../../data-models/dealStage.data-model';
import { dealStagesByIdMapState, dealTypesByIdMapState } from '../../../state/DealboardDataState';
import { dealsStatsHistory } from '../../../state/DealConversionState';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import {
  IDealAnalyticsGriData,
  dealStatsToRowData,
  getColDefs,
  getDefaultColDef,
} from './DealAnalyticsGridUtils';
import { useFiltersParams } from './hooks/useFiltersParams';

const DealAnalyticsWrapper = styled('div')`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  row-gap: 1rem;
`;

const InfoMessage = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 0.25rem;
  width: 100%;
`;

export const DealAnalyticsDuration: FC = () => {
  const defaultColDef: ColDef = getDefaultColDef();
  const gridApi = useRef<GridApi>();
  const dealTypesById = useRecoilValue(dealTypesByIdMapState);
  const dealStagesById = useRecoilValue(dealStagesByIdMapState);
  const roundsById = useRecoilValue(roundsMapState);
  const { queryParams, selectedDealType } = useFiltersParams();

  const data = useRecoilValueLoadable(dealsStatsHistory(queryParams));

  const columnDefs: ColDef[] = useMemo(() => {
    const stageIdsForType = dealTypesById.get(selectedDealType)?.dealStageIds ?? [];
    const stagesForType = stageIdsForType
      .map((stageId) => dealStagesById.get(stageId) ?? null)
      .filter((stage) => stage !== null) as IDealStageDataModel[];
    return getColDefs(stagesForType);
  }, [dealStagesById, dealTypesById, selectedDealType]);

  const rowData = useMemo(() => {
    if (data.state === 'hasValue') {
      return dealStatsToRowData(data.getValue(), roundsById);
    }
    return [];
  }, [data, roundsById]);

  const onGridReady = useCallback(
    (event: GridReadyEvent<unknown>) => {
      gridApi.current = event.api;
      if (data.state === 'loading') {
        gridApi.current?.showLoadingOverlay();
      }
    },
    [data.state]
  );

  const processCellCallback = useCallback(
    (params: ProcessCellForExportParams<IDealAnalyticsGriData>) => {
      if (params.column.getColId() === 'company') {
        return params.node?.data?.company?.name ?? '';
      }
      if (params.column.getColId() === 'round') {
        const roundId = params.node?.data?.roundId;
        if (typeof roundId !== 'number') return '';
        return roundsById.get(roundId)?.displayName ?? '';
      }
      return commonProcessCb(params);
    },
    [roundsById]
  );

  return (
    <DealAnalyticsWrapper>
      <InfoMessage>
        <InfoOutlinedIcon fontSize={'small'} />
        <Typography variant={'body2'}>All duration are displayed in days</Typography>
      </InfoMessage>
      <FadeInGridWrapper key={`${queryParams.from}-${selectedDealType}`}>
        <AgTable
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          defaultCsvExportParams={{ processCellCallback }}
          groupIncludeTotalFooter={false}
          onGridReady={onGridReady}
          rowData={rowData}
          rowGroupPanelShow='never'
        />
      </FadeInGridWrapper>
    </DealAnalyticsWrapper>
  );
};
