import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { requiredMsg } from '../../../Finance/components/TransactionModal/Forms/utils/validationMessages';
import { IField } from '../../../../data-models/field2.data-model';
import { RendererType } from '../../../../data-models/field.data-model';

export const createCustomFieldSchema = yup.object({
  displayName: yup.string().required(requiredMsg('Name')),
  description: yup.string().required(requiredMsg('Description')).nullable(),
  entity: yup.string().required(requiredMsg('Entity')),
  formMeta: yup
    .object({
      renderer: yup.object({
        id: yup.string(),
        type: yup.string().required(requiredMsg('Type')).nullable(),
        config: yup.object({
          values: yup
            .array()
            .of(
              yup.object({
                displayName: yup.string(),
                value: yup.string(),
              })
            )
            .nullable()
            .optional()
            .test('required', requiredMsg('Options'), (value, context) => {
              const fieldType = context?.from?.[2]?.value?.renderer?.type;
              if (fieldType === RendererType.singleSelect || fieldType === RendererType.multiSelect) {
                return value != undefined && value.length > 0;
              }
              return true;
            }),
        }),
      }),
    })
    .nullable(),
}) as ObjectSchema<IField<unknown>>;
