import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { selectedViewPF, viewsPF } from '../state/ViewState';
import { OriginViewDataModel, VIEW_TYPE, ViewOrder } from '../../../data-models/view-config.data-model';
import {
  deleteView,
  duplicateView,
  shareView,
  unshareView,
  updateView,
} from '../../../services/queries/MaggieViewQueries';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { isFirstRenderDonePF } from '../state/UIState';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../types';
import { buildUniqueName, nameDuplicate } from '../../../util/buildUniqueName';
import { serializeView, serializeViewForCreation, ViewModel } from '../../../models/view.model';
import { pageConfigPF } from '../state/PageState';
import { IViewDataModel } from '../../../data-models/view.data-model';
import { ROUTES } from '../../../constants/RouteNameMapping';

const defaultUpdateWarningShown = new Set<number>();

export function useDeleteView() {
  const navigate = useNavigate();
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [pageConfig, setPageConfig] = useRecoilState(pageConfigPF);
  const [views, setViews] = useRecoilState(viewsPF);

  return useCallback(
    async (viewToDelete: OriginViewDataModel) => {
      actions.startLoading(LoadingId.deleteView);

      try {
        const viewsAfterDelete = views.filter((view) => view.id !== viewToDelete.id);
        const newOrder = views.map((v) => v.viewId);

        await deleteView(viewToDelete);

        pushSuccessToast({ message: 'View deleted successfully.' });
        setViews(viewsAfterDelete);
        setPageConfig({ ...pageConfig, order: newOrder });
        navigate(`/${ROUTES.PORTFOLIO}/${viewsAfterDelete[0].id}`);
      } catch (err) {
        pushErrorToast({ message: 'Failed to delete view.' });
      }

      actions.stopLoading(LoadingId.deleteView);
    },
    [actions, navigate, pageConfig, pushErrorToast, pushSuccessToast, setPageConfig, setViews, views]
  );
}

export function useDuplicateView() {
  const navigate = useNavigate();
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [pageConfig, setPageConfig] = useRecoilState(pageConfigPF);
  const [views, setViews] = useRecoilState(viewsPF);

  return useCallback(
    async (viewToDuplicate: OriginViewDataModel) => {
      actions.startLoading(LoadingId.duplicateView);

      try {
        const id = self.crypto.randomUUID();
        const currentViewNames = views.map((view) => view.label);
        const label = nameDuplicate(viewToDuplicate.label, currentViewNames);
        const newView = {
          ...viewToDuplicate,
          origin: { ...viewToDuplicate },
          viewType: viewToDuplicate.viewType,
          type: VIEW_TYPE.PERSONAL,
          viewId: `${VIEW_TYPE.PERSONAL}_${id}`,
          label,
          name: label,
        };
        const payload = serializeViewForCreation(newView);

        const duplicate = await duplicateView(payload);

        const newViews = [...views, duplicate];
        const newOrder = [...pageConfig.order, duplicate.viewId];

        pushSuccessToast({ message: 'New view created.' });
        setViews(newViews);
        setPageConfig({ ...pageConfig, order: newOrder });
        navigate(`/${ROUTES.PORTFOLIO}/${duplicate.id}`);
      } catch (err) {
        pushErrorToast({ message: 'Failed to duplicate view.' });
      }

      actions.stopLoading(LoadingId.duplicateView);
    },
    [actions, navigate, pageConfig, pushErrorToast, pushSuccessToast, setPageConfig, setViews, views]
  );
}

export function useRenameView() {
  const updateView = useUpdateView();
  const views = useRecoilValue(viewsPF);

  return useCallback(
    (view: ViewModel, newName: string) => {
      const currentViewNames = views.map((view) => view.label);
      const newUniqueName = buildUniqueName(
        newName,
        currentViewNames.filter((name) => name !== newName)
      );

      return updateView(
        { ...view, label: newUniqueName, name: newUniqueName },
        'View renamed to ' + newUniqueName,
        'Failed to rename view!'
      );
    },
    [updateView, views]
  );
}

export function useShareView() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [views, setViews] = useRecoilState(viewsPF);

  return useCallback(
    async (viewToUpdate: ViewModel, emails: string[]) => {
      actions.startLoading(LoadingId.updateView);

      let success = true;

      try {
        await shareView(viewToUpdate.id, emails);

        const updateViewModel: ViewModel = {
          ...viewToUpdate,
          sharing: [...(viewToUpdate.sharing ?? []), ...emails],
        };
        const updatedViews = views.map((view) => (view.id === updateViewModel.id ? updateViewModel : view));

        pushSuccessToast({ message: 'Successfully shared view!' });
        setViews(updatedViews);
      } catch (err) {
        pushErrorToast({ message: 'Failed to share view!' });
        success = false;
      }

      actions.stopLoading(LoadingId.updateView);

      return success;
    },
    [actions, pushErrorToast, pushSuccessToast, setViews, views]
  );
}

export function useUnShareView() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [views, setViews] = useRecoilState(viewsPF);

  return useCallback(
    async (viewToUpdate: ViewModel, emailsToRemove: string[]) => {
      actions.startLoading(LoadingId.updateView);

      const updateViewModel: ViewModel = {
        ...viewToUpdate,
        sharing: viewToUpdate.sharing?.filter((sharedUser) => emailsToRemove.indexOf(sharedUser) < 0),
      };
      const updatedViews = views.map((view) => (view.id === updateViewModel.id ? updateViewModel : view));
      let success = true;

      try {
        await unshareView(viewToUpdate.id, emailsToRemove);
        pushSuccessToast({ message: 'Successfully un-shared view!' });
        setViews(updatedViews);
      } catch (err) {
        pushErrorToast({ message: 'Failed to un-share view!' });
        success = false;
      }

      actions.stopLoading(LoadingId.updateView);

      return success;
    },
    [actions, pushErrorToast, pushSuccessToast, setViews, views]
  );
}

export function useResetView() {
  const updateView = useUpdateView();

  return useCallback(
    (view: ViewModel) => {
      const viewReset: ViewModel = {
        ...view,
        origin: view.origin,
        sections: view.origin?.sections ?? {},
        components: view.origin?.components ?? {},
        order: view.origin?.order ?? ({} as ViewOrder),
        filteredFunds: view.origin?.filteredFunds ?? null,
      };

      return updateView(viewReset, 'Successfully reset view!', 'Failed to reset view!');
    },
    [updateView]
  );
}

export function useUpdateSelectedView() {
  const isFirstRenderDone = useRecoilValue(isFirstRenderDonePF);
  const { pushWarningToast } = useToastMessageState();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (updates: Partial<ViewModel>) => {
        const selectedView = snapshot.getLoadable(selectedViewPF).contents;
        const updatedView = {
          ...selectedView,
          ...updates,
        };
        const viewType = updatedView.type;

        const currentViews: ViewModel[] = snapshot.getLoadable(viewsPF).valueMaybe() ?? [];

        const newViews = currentViews.map((view) => {
          if (view.id === updatedView.id) {
            return updatedView;
          }
          return view;
        });

        const oldViews = snapshot.getLoadable(viewsPF).valueMaybe() ?? [];
        set(viewsPF, newViews);

        if (viewType === VIEW_TYPE.PERSONAL) {
          try {
            await updateView(serializeView(updatedView));
          } catch (err) {
            set(viewsPF, oldViews);
          }
        } else if (!defaultUpdateWarningShown.has(updatedView.id) && isFirstRenderDone) {
          defaultUpdateWarningShown.add(updatedView.id);

          const companyViewTitle = 'COMPANY VIEW WAS CHANGED';
          const sharedViewTitle = 'SHARED VIEW WAS CHANGED';
          const companyViewMessage =
            'You can reset it to Default settings or Save as a New View. If you leave this page, the data will be automatically reset to the default view.';
          const sharedViewMessage =
            'You can save it as a New View. If you leave this page all the changes will be lost.';
          pushWarningToast({
            title: viewType === VIEW_TYPE.COMPANY ? companyViewTitle : sharedViewTitle,
            message: viewType === VIEW_TYPE.COMPANY ? companyViewMessage : sharedViewMessage,
          });
        }
      },
    [isFirstRenderDone, pushWarningToast]
  );
}

export function useUpdateView() {
  const { actions } = useLoadingBarState();
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        updatedView: ViewModel,
        successMessage = 'View updated!',
        errorMessage = 'Failed to update view'
      ) => {
        actions.startLoading(LoadingId.updateView);

        const updateViewDataModel: IViewDataModel = serializeView(updatedView);

        const views = snapshot.getLoadable(viewsPF).contents;
        const oldViews = snapshot.getLoadable(viewsPF).contents;

        try {
          const updatedView = await updateView(updateViewDataModel);
          const newViews = views.map((view: ViewModel) => (view.id === updatedView.id ? updatedView : view));
          pushSuccessToast({ message: successMessage as string });
          set(viewsPF, newViews);
        } catch (err) {
          pushErrorToast({ message: errorMessage as string });
          set(viewsPF, oldViews);
        }

        actions.stopLoading(LoadingId.updateView);
      },
    [actions, pushErrorToast, pushSuccessToast]
  );
}
