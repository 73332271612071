import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { theme } from '../../../../../theme';

const { neutral } = theme.colors;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: neutral['60'],
    fontSize: theme.typography.pxToRem(12),
    border: '0.5px solid #D2D5DB',
    borderRadius: '4px',
    boxShadow:
      '0px -4px 40px rgba(255, 255, 255, 0.1), 0px 4px 24px rgba(16, 37, 62, 0.06), 0px 24px 48px rgba(75, 82, 93, 0.04)',
  },
}));
