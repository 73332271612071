import { Column, useFlexLayout, useTable } from 'react-table';
import { isEqual } from 'lodash-es';
import { TransactionHistoryTableData } from '../../../types';
import { StyledTable, StyledTd, StyledTh, TableContainer } from './styledTableComponents';

type Props = {
  columns: Column<TransactionHistoryTableData>[];
  data: TransactionHistoryTableData[];
};

export const UpdatesHistoryTable = ({ columns, data }: Props) => {
  const tableInstance = useTable(
    { columns, data, defaultColumn: { width: 180, minWidth: 150, maxWidth: 400 } },
    useFlexLayout
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <TableContainer>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
              {headerGroup.headers.map((column, columnIndex) => {
                /* do not diff 0 & null/undefined */
                const diff =
                  Boolean(data[0][column.id as keyof TransactionHistoryTableData]) &&
                  Boolean(data[1][column.id as keyof TransactionHistoryTableData]) &&
                  !isEqual(
                    data[0][column.id as keyof TransactionHistoryTableData],
                    data[1][column.id as keyof TransactionHistoryTableData]
                  );
                return (
                  <StyledTh {...column.getHeaderProps()} key={columnIndex} highlighted={diff}>
                    {column.render('Header')}
                  </StyledTh>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => {
                  const currencyCode = data[rowIndex].currency?.code;
                  const currentVal = data[rowIndex][cell.column.id as keyof TransactionHistoryTableData];
                  const otherVal =
                    data[(rowIndex + 1) % 2][cell.column.id as keyof TransactionHistoryTableData];
                  const diff =
                    typeof currentVal !== 'number' ||
                    typeof otherVal !== 'number' ||
                    isEqual(currentVal, otherVal)
                      ? false
                      : true;

                  return (
                    <StyledTd
                      {...cell.getCellProps()}
                      key={cellIndex}
                      strikethrough={!isEqual(currentVal, otherVal) && rowIndex > 0}
                      diff={diff && rowIndex === 0 ? 'current' : diff ? 'previous' : 'noDiff'}
                    >
                      {cell.render('Cell', {
                        className: isEqual(currentVal, otherVal) ? 'muted' : '', // for the RoundRenderer
                        currencyCode: currencyCode,
                      })}
                    </StyledTd>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};
