import { Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';
import { CommentCreation } from '../../Comment/CommentCreation';
import { CommentList } from '../../Comment/CommentsList';
import { createNoteCommentDataModel } from '../../../data-models/note-comment.data-model';
import { useNoteCommentActions } from '../hooks/useNoteCommentActions';
import { useLexicalContext } from '../provider/LexicalProvider';
import { noteCommentsState } from '../../../pages/CompanyProfiles/state/NoteCommentsState';

const NEW_NOTE = createNoteCommentDataModel({ noteId: 1, comment: '' });

export function LexicalEditorCommentsTab() {
  const { noteData } = useLexicalContext();
  const noteId = noteData?.id ?? 0;
  const { isLoading, useCreateNoteComment, useUpdateNoteComment, useDeleteNoteComment, ongoinActionId } =
    useNoteCommentActions(noteId);
  const comments = useRecoilValue(noteCommentsState(noteId));

  const createNoteComment = useCreateNoteComment;
  const updateNoteComment = useUpdateNoteComment;
  const deleteNoteComment = useDeleteNoteComment;

  const commentsWithLoading = useMemo(
    () =>
      (comments ?? []).map((comment) => {
        const isLoading = comment.id === ongoinActionId;
        if (comment.id) {
          return { ...comment, isLoading };
        } else {
          return comment;
        }
      }),
    [comments, ongoinActionId]
  );

  const handleCreate = async (comment: string) => {
    const newNote = {
      ...NEW_NOTE,
      noteId,
      comment,
    };

    const response = await createNoteComment(newNote);

    if (response) {
      return response;
    }
  };

  const handleDelete = useCallback(
    async (commentId: number) => {
      await deleteNoteComment(commentId);
    },
    [deleteNoteComment]
  );

  const handleUpdate = useCallback(
    async (comment: string, commentId: number) => {
      updateNoteComment({
        comment,
        commentId,
      });
    },
    [updateNoteComment]
  );

  return (
    <Stack justifyContent='space-between' gap='1rem'>
      <CommentCreation onCreate={handleCreate} isLoading={isLoading} />
      <CommentList comments={commentsWithLoading} handleDelete={handleDelete} handleUpdate={handleUpdate} />
    </Stack>
  );
}
