import { styled } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import { theme } from '../../../../theme';
import { timeRange } from '../../state/DashboardsState';

const { primary, neutral } = theme.colors;

const Wrapper = styled('div')<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? primary['0'] : neutral['10'])};
  border: 1px solid ${({ isSelected }) => (isSelected ? primary['60'] : neutral['10'])};
  border-radius: 4px;
  cursor: pointer;
  padding: 0.4rem;
`;

interface IProps {
  data: { value: number; label: string };
}

export function TimeRange({ data }: IProps) {
  const [selectedTimeRange, setSelectedTimeRange] = useRecoilState(timeRange);

  const handleClick = () => {
    setSelectedTimeRange(data.value);
  };

  return (
    <Wrapper onClick={handleClick} isSelected={data.value === selectedTimeRange} data-testid={'time-range'}>
      {data.label}
    </Wrapper>
  );
}
