import { useFlags } from 'launchdarkly-react-client-sdk';
import { MaggieFeatureFlags } from '../../util/feature-flags';

export function useRouteBasedFinanceTitles() {
  const { showAllTransactions } = useFlags<MaggieFeatureFlags>();

  return {
    default: 'Finance',
    details: 'Finance Data Sources',
    main: showAllTransactions ? 'All Transactions' : 'Dashboard',
    recon: 'Reconciliation',
    securities: 'All Securities',
  };
}
