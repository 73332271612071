import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { usersState } from '../state/AppConfigState';
import { authenticatedUserState } from '../state/AuthState';
import { createUserDataModel } from '../../data-models/user.data-model';

export function useCurrentUser() {
  const authenticatedUser = useRecoilValue(authenticatedUserState);
  const users = useRecoilValue(usersState);

  return useMemo(() => {
    if (!authenticatedUser) {
      Sentry.captureMessage('No authenticated user retrieved');
      return createUserDataModel({
        name: 'Unknown',
        id: -1,
      });
    }

    const authenticatedUserEmail = authenticatedUser.email.toLocaleLowerCase();
    const user = users.find((user) => user.email === authenticatedUserEmail);

    if (!user) {
      return createUserDataModel({
        name: authenticatedUser.name,
        id: -1,
      });
    }

    return user;
  }, [authenticatedUser, users]);
}
