import { IDealStageDataModel } from '../../pages/DealFlow2/data-models/dealStage.data-model';
import { IInitializedFormatter } from './IInitializedFormatter';

export class DealStageFormatter
  implements IInitializedFormatter<Map<number, IDealStageDataModel>, number | number[]>
{
  #dealStage: Map<number, IDealStageDataModel> = new Map();

  init(dealStages: Map<number, IDealStageDataModel>) {
    this.#dealStage = dealStages;
    return this;
  }

  format = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return value.map((val) => this.#format(val)).join(', ');
    }
    return this.#format(value);
  };

  #format = (dealTypeId: number) => {
    return this.#dealStage.get(dealTypeId)?.displayName ?? dealTypeId.toString();
  };
}
