import { CalendarShortcut } from '../components/DatePicker/Calendar';
import { ISelectMeta, ITextMeta } from '../data-models/field3.data-model';
import { FDMap } from '../util/data-structure/FDMap';
import { IDisplayField } from './display-field.view-model';

export type FormVariant = 'form' | 'form-inline' | 'legacy-form';
const DefaultFormVariant: FormVariant = 'form';

export interface IFormField<T> extends IDisplayField<T> {
  autoFocus?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  required?: boolean;
  variant?: FormVariant;
}

export interface IForm<T = unknown> {
  data?: T;
  disabled?: boolean;
  fields: FDMap<string, IFormField<unknown>>;
  title?: string;
  variant?: FormVariant;
}

export interface IFormFieldTextMeta extends ITextMeta {
  rows?: number;
  maxRows?: number;
  multiline?: boolean;
  placeholder?: string;
}

export interface IFormFieldUploadMeta {
  accept?: string;
  companyId: number;
  maxFileSizeMb?: number;
  multiple?: boolean;
}

export interface IDateFieldFormSettings {
  min: string | null; // ISO date
  max: string | null; // ISO date
  presets: CalendarShortcut[] | null;
}

export interface IFormFieldSelectMeta<T> extends ISelectMeta<T> {
  allowCustomAdd?: boolean;
  actionMeta?: {
    actionLabel: string;
    helperText?: string;
    onAction: (inputValue?: string) => void;
    onDelete?: (value: T) => void;
  };
}

export interface IFormFieldFreeLabelsMeta extends ISelectMeta<string> {
  maxTags?: number;
}

export function createFormField<T>(data: IFormField<T>): IFormField<T> {
  const { key, label, renderer, ...rest } = data;
  return {
    key,
    renderer,
    label: label ?? key ?? 'label',
    variant: 'form',
    ...rest,
  };
}

export function createFormFromFieldsArray<T>(
  fields: IFormField<unknown>[],
  formConfig: Omit<IForm<T>, 'fields'> = {}
): IForm<T> {
  return {
    variant: DefaultFormVariant,
    fields: FDMap.fromArray(fields, 'key'),
    ...formConfig,
  };
}

export function createForm<T>(overrides?: Partial<IForm<T>>): IForm<T> {
  return {
    data: {} as T,
    fields: new FDMap<string, IFormField<unknown>>(),
    title: '',
    ...overrides,
  };
}
