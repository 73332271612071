import { ExpandMore } from '@mui/icons-material';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Divider,
  SxProps,
  TextField,
  useTheme,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { MultiselectProps } from '../MuiMultiselect/MuiMultiselect';
import { Option } from '../MuiSingleSelect/MuiSingleSelect';

interface LabelInputProps extends Omit<MultiselectProps, 'value' | 'onChange'> {
  value: string[];
  onChange: (value: string[]) => void;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  onCreateLabel: (value: string) => void | Promise<void>;
  loading?: boolean;
  textFieldSx?: SxProps;
  multiple?: boolean;
  required?: boolean;
  className?: string;
  createPrompt?: string;
  errorText?: string;
  disableCloseOnSelect?: boolean;
  popupIcon?: JSX.Element;
  showMenuHelperText?: boolean;
}

const LABEL_OPTION_ID = 0;
const CREATE_OPTION_ID = 9999999;

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 999999 !important;
  }

  &&& .MuiAutocomplete-popper .MuiPaper-root .MuiAutocomplete-listbox .Mui-focused {
    background-color: ${({ theme }) => theme.colors.primary[5]};
  }

  &&& .MuiInputBase-root.Mui-focused {
    z-index: 1000;
  }
`;
const OptionWrapper = styled('li')<{ selectable: boolean }>`
  font-family: Wotfard-Regular;
  font-size: 0.875rem;
  height: 32px;
  padding: 0 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'auto')};
  &:hover {
    background-color: ${({ theme, selectable }) => (selectable ? theme.colors.primary[5] : 'transparent')};
  }
`;
const CreateOptionWrapper = styled('div')``;

export const LabelInput: FC<LabelInputProps> = (props) => {
  const {
    options,
    onCreateLabel,
    onChange,
    value,
    fieldPlaceholder,
    alwaysShowPlaceholder: showPlaceholder,
    fieldName,
    sx,
    textFieldSx,
    createPrompt,
    errorText,
    autofocus = false,
    loading = false,
    multiple = true,
    disableCloseOnSelect = true,
    popupIcon = <ExpandMore />,
    showMenuHelperText = true,
    ...rest
  } = props;
  const { colors } = useTheme();
  const [createOption, setCreateOption] = useState<Option | null>(null);
  const [searchText, setSearchText] = useState('');

  const selectedOptions = useMemo(() => {
    return options.filter((l) => value.includes(String(l.id))) ?? [];
  }, [options, value]);

  const updatedOptions = useMemo(() => {
    const labelOption = {
      id: 0,
      value: searchText,
      label: 'Select an option or create a new one',
    };

    const result = [...options].sort((a, b) =>
      a.value.localeCompare(b.value, undefined, { sensitivity: 'base' })
    );
    if (showMenuHelperText) result.unshift(labelOption);

    return createOption ? [...result, createOption] : result;
  }, [createOption, showMenuHelperText, options, searchText]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchText(e.target.value);
      const searchResult = updatedOptions.find(
        (o) => o.id !== CREATE_OPTION_ID && o.id !== LABEL_OPTION_ID && o.value === e.target.value
      );
      if (searchResult || !e.target.value) {
        setCreateOption(null);
      } else {
        setCreateOption({ id: CREATE_OPTION_ID, value: e.target.value });
      }
    },
    [updatedOptions]
  );

  const onBlur = useCallback(() => {
    setCreateOption(null);
    setSearchText('');
  }, []);

  const onChangeHandler = useCallback(
    async (selectedOptions: Option[], option: Option | null) => {
      setCreateOption(null);
      setSearchText('');
      if (option && option.id === CREATE_OPTION_ID) {
        await onCreateLabel(option.value);
        return;
      }
      if (!selectedOptions) {
        onChange([]);
        return;
      }
      onChange(selectedOptions.map((o) => String(o.id)));
    },
    [onCreateLabel, onChange]
  );

  return (
    <StyleContainer>
      <Autocomplete
        onChange={(_e, options, _r, details) => onChangeHandler(options as Option[], details?.option ?? null)}
        options={updatedOptions}
        value={selectedOptions}
        autoHighlight
        onBlur={onBlur}
        getOptionLabel={function (option): string {
          return option?.value ?? '';
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        disablePortal
        renderInput={(params) => (
          <TextField
            onChange={onInputChange}
            autoFocus={autofocus}
            {...params}
            fullWidth
            name={fieldName}
            placeholder={props.value?.length && !showPlaceholder ? '' : fieldPlaceholder}
            onKeyDown={props.onKeyDown}
            error={Boolean(errorText)}
            helperText={errorText ?? ' '}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='secondary' size={16} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={textFieldSx}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const propsWithouthStyle = { ...props, className: '' };
          if (option.id === LABEL_OPTION_ID) {
            return (
              <OptionWrapper selectable={false} key={option.id}>
                <Typography variant='caption' color={colors.neutral[50]}>
                  {option.label}
                </Typography>
              </OptionWrapper>
            );
          }
          if (option.id === CREATE_OPTION_ID) {
            return (
              <CreateOptionWrapper key={option.id}>
                {showMenuHelperText && <Divider style={{ margin: '0 10px' }} />}
                <OptionWrapper {...propsWithouthStyle} selectable={true}>
                  <Typography variant='subtitle2' color={colors.primary[60]}>
                    {createPrompt ?? 'Create'}
                  </Typography>
                  <Chip
                    label={option?.value ?? ''}
                    sx={{
                      maxHeight: '21px',
                      cursor: 'pointer',
                      backgroundColor: colors.tertiary[10],
                    }}
                  />
                </OptionWrapper>
              </CreateOptionWrapper>
            );
          }
          return (
            <OptionWrapper {...propsWithouthStyle} selectable={true} key={option.id}>
              <Chip
                label={option?.value ?? ''}
                sx={{
                  maxHeight: '21px',
                  cursor: 'pointer',
                  backgroundColor: selected ? colors.neutral[10] : colors.tertiary[10],
                }}
              />
            </OptionWrapper>
          );
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        popupIcon={popupIcon}
        multiple={multiple}
        ChipProps={props.ChipProps ?? { size: 'small', style: { backgroundColor: colors.tertiary[10] } }}
        sx={{
          "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
            color: colors.neutral[40],
          },
          '& .MuiAutocomplete-tag': {
            maxHeight: '21px',
            backgroundColor: colors.tertiary[10],
          },
          '& .MuiFormHelperText-root.Mui-error': {
            marginLeft: '0px',
          },
          ...sx,
        }}
        {...rest}
      />
    </StyleContainer>
  );
};
