import { useRecoilValue } from 'recoil';
import { ControllerRenderProps } from 'react-hook-form';
import { FieldEntity, IField } from '../../../data-models/field2.data-model';
import { useKPIFieldActions } from '../hooks/useKPIFieldActions';
import { kpiConfigByIdMapState, kpiConfigState } from '../../../services/state/KPI/KPITemplatesState';
import { FieldSelect, SelectType } from '../../../components/Form/Display/FieldSelect';
import { IFormField, IFormFieldSelectMeta, createFormField } from '../../../view-models/form.view-model';
import { RendererType } from '../../../data-models/field.data-model';

export interface IKPISelectorProps {
  createMetric?: (metricName: string) => void;
  onMetricSelect: (metric: IField<unknown>) => void;
  selectedMetrics: IField<unknown>[];
}

export function KPISelector(props: IKPISelectorProps) {
  const { createMetric, onMetricSelect, selectedMetrics } = props;
  const { deleteKPI } = useKPIFieldActions();
  const kpiMetrics = useRecoilValue(kpiConfigState);
  const kpisById = useRecoilValue(kpiConfigByIdMapState);
  const selectedFieldIds = selectedMetrics.reduce((res, metric) => {
    return res.add(metric.displayName);
  }, new Set<string>());

  const onCreate = (inputValue?: string) => {
    createMetric?.(inputValue ?? '');
  };

  const onChange = (newValue: number) => {
    if (newValue === null) {
      return;
    }
    return onMetricSelect(kpisById.get(newValue)!);
  };

  const formField: IFormField<IFormFieldSelectMeta<SelectType>> = createFormField({
    key: 'kpi',
    label: 'KPI',
    renderer: RendererType.singleSelect,
    rendererMeta: {
      actionMeta: {
        onAction: onCreate,
        actionLabel: 'Create new KPI',
        onDelete: deleteKPI as (value: SelectType) => void,
      },
      values: kpiMetrics
        .filter((option) => !selectedFieldIds.has(option.displayName))
        .map((kpi) => {
          return {
            value: kpi.id,
            displayName: kpi.displayName,
            secondaryLabel: kpi.entity === FieldEntity.userKPI ? 'custom' : 'universal',
          };
        }),
    },
    placeholder: 'Add KPI',
    disableClearable: false,
  });

  const formProps = {
    onChange,
    value: null,
  };

  return (
    <FieldSelect
      formField={formField}
      formProps={formProps as ControllerRenderProps}
      key={Math.random()} // Force a re-render with value emptied each time
    />
  );
}
