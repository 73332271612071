import { Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface IRelevanceProps {
  outdated?: boolean | null;
}

export function RelevanceTag({ outdated }: IRelevanceProps) {
  return (
    <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
      {outdated ? (
        <>
          <ErrorOutlineOutlinedIcon color={'disabled'} />
          <Typography variant='subtitle2'>Outdated</Typography>
        </>
      ) : (
        <>
          <CheckCircleOutlineIcon color='success' />
          <Typography variant='subtitle2'>Actual</Typography>
        </>
      )}
    </Stack>
  );
}
