import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { Button, ButtonProps, SvgIcon, useTheme } from '@mui/material';
import { FC } from 'react';

interface IUndoButtonProps extends ButtonProps {
  hasUndo: boolean;
}

export const UndoButton: FC<IUndoButtonProps> = ({ hasUndo, children, ...rest }) => {
  const { colors } = useTheme();
  return (
    <Button
      variant='outlined'
      color='secondary'
      endIcon={
        hasUndo ? (
          <SvgIcon
            color='secondary'
            fontSize='large'
            titleAccess='undo'
            sx={{ width: '1.35rem', height: '1.35rem', transform: 'scaleX(-1)' }}
          >
            <ReplayOutlinedIcon />
          </SvgIcon>
        ) : undefined
      }
      style={{
        borderRadius: '60px',
        borderColor: colors.neutral[30],
        padding: '0.5rem 1rem',
        color: colors.neutral[60],
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
