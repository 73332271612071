import { KPI_COMPONENTS, Kpi, KpiCalculationParams, KpiConfig, KpiConfigMeta } from '../../../../types';
import { format } from './kpi-formatter';

export class PotentialUpside implements KpiConfig {
  private id = KPI_COMPONENTS.POTENTIAL_UPSIDE as const;
  private title = 'Potential Upside';
  private type = 'currency';

  getMeta(): KpiConfigMeta {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
    };
  }

  calculate({ data }: KpiCalculationParams): Kpi {
    const value =
      data?.rawCompanies?.reduce((sum, rawCompany) => {
        const proceeds = Number(rawCompany.projectedProceeds);
        const fmv = Number(rawCompany.fmv);

        return Number.isNaN(proceeds) || proceeds < fmv ? sum : sum + proceeds - fmv;
      }, 0) ?? 0;

    return {
      ...this.getMeta(),
      value,
      formatted: format(value, this.type),
    };
  }
}
