import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { useInvalidateData } from '../../../hooks/useInvalidateData';
import { RubricConfig, rubricConfigSchema } from '../../../schemas/Rubric.schema';
import {
  createRubricConfig as createRubricConfigQuery,
  getRubricConfig,
  updateRubricConfig as updateRubricConfigQuery,
  deleteRubricConfig as deleteRubricConfigQuery,
} from '../../../services/queries/MaggieRubricQueries';
import { rubricConfigState } from '../../../services/state/RubricsState';
import { getErrorMessage } from '../../../services/queryHelpers';

export function useRubricConfigActions() {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();

  const invalidateRubricConfig = useInvalidateData();

  const createNewRubricConfig = useCallback(
    async (rubricConfig: Pick<RubricConfig, 'displayName' | 'description'>) => {
      const { type, format } = rubricConfigSchema().getDefault();
      const payload = { ...rubricConfig, type, format };
      try {
        const data = await createRubricConfigQuery(payload);
        pushSuccessToast({ message: 'Rubric config created successfully' });
        invalidateRubricConfig({ state: rubricConfigState, query: getRubricConfig });
        return data;
      } catch (err) {
        pushErrorToast({ message: getErrorMessage(err, 'Failed to create rubric config') });
      }
    },
    [invalidateRubricConfig, pushErrorToast, pushSuccessToast]
  );

  const updateRubricConfig = useCallback(
    async (id: number, payload: Partial<RubricConfig>) => {
      try {
        const data = await updateRubricConfigQuery(id, payload);
        pushSuccessToast({ message: 'Rubric config updated successfully' });
        invalidateRubricConfig({ state: rubricConfigState, query: getRubricConfig });
        return data;
      } catch (err) {
        pushErrorToast({ message: getErrorMessage(err, 'Failed to update rubric config') });
      }
    },
    [invalidateRubricConfig, pushErrorToast, pushSuccessToast]
  );

  const deleteRubricConfig = useCallback(
    async (id: number, force = false) => {
      try {
        await deleteRubricConfigQuery(id, { force });
        pushSuccessToast({ message: 'Rubric config deleted successfully' });
        invalidateRubricConfig({ state: rubricConfigState, query: getRubricConfig });
      } catch (err) {
        pushErrorToast({ message: getErrorMessage(err, 'Failed to delete rubric config') });
      }
    },
    [invalidateRubricConfig, pushErrorToast, pushSuccessToast]
  );

  return { createNewRubricConfig, updateRubricConfig, deleteRubricConfig };
}
