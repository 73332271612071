import { styled } from '@mui/material/styles';
import { FC, HTMLAttributes } from 'react';

const Centered = styled('span')`
  display: flex;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const NoDataCell: FC<HTMLAttributes<HTMLSpanElement>> = () => {
  return <Centered>-</Centered>;
};
