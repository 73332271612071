import { LoadingButton } from '@mui/lab';
import { styled, Icon, Button, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CSSProperties, FC } from 'react';
import { FormBottomPadding } from '../../constants/styles';
import { FormXPaddingInRems } from './FormComponents';

const ButtonContainer = styled('div')`
  position: sticky;
  bottom: 0;
  padding: 2rem ${FormXPaddingInRems}rem ${FormBottomPadding};
  display: grid;
  // add extra column to fit error message and prevent buttons from stretching
  grid-template-columns: repeat(3, auto);
  justify-content: start;
  column-gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary[0]};

  & .error {
    grid-column: 1 / span 3;
    grid-row: 1;
    transform: translateY(-60%);
  }
`;

interface StickyFormButtonsProps {
  cancelLabel?: string;
  disabled?: boolean;
  errorMessage?: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  style?: CSSProperties;
  submitLabel?: string;
  secondaryActionProps?: {
    label: string;
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
  };
}

export const StickyFormButtons: FC<StickyFormButtonsProps> = ({
  onSubmit,
  onCancel,
  loading,
  disabled,
  style,
  errorMessage,
  secondaryActionProps,
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
}) => {
  return (
    <ButtonContainer style={style}>
      <LoadingButton
        type='button'
        disabled={disabled}
        onClick={onSubmit}
        size='medium'
        variant='contained'
        color='secondary'
        loadingPosition='start'
        loading={loading}
        startIcon={loading ? <Icon /> : <></>}
        style={{ gridColumn: '1', gridRow: '1' }}
      >
        {submitLabel}
      </LoadingButton>
      {secondaryActionProps && (
        <LoadingButton
          type='button'
          disabled={secondaryActionProps.disabled}
          onClick={secondaryActionProps.onClick}
          size='medium'
          variant='contained'
          color='secondary'
          loadingPosition='start'
          loading={secondaryActionProps.loading}
          startIcon={secondaryActionProps.loading ? <Icon /> : <></>}
          style={{ gridColumn: '2', gridRow: '1' }}
        >
          {secondaryActionProps.label}
        </LoadingButton>
      )}

      <Button
        type='button'
        onClick={onCancel}
        size='medium'
        variant='outlined'
        color='secondary'
        style={secondaryActionProps ? { gridColumn: '3', gridRow: '1' } : { gridColumn: '2', gridRow: '1' }}
      >
        {cancelLabel}
      </Button>
      {errorMessage && (
        <Typography display={'flex'} variant='caption' color='error' className='error'>
          <ErrorOutlineIcon style={{ marginRight: '.05rem', height: '1rem' }} /> {errorMessage}
        </Typography>
      )}
    </ButtonContainer>
  );
};
