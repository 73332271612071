import { CHART_COMPONENTS } from '../../../../types';
import { MostCommonCoInvestorsChart } from '../../../../components/Charts/Leaderboard/MostCommonCoInvestors/MostCommonCoInvestorsCharts';
import { useMostCommonCoInvestors } from '../../hooks/useMostCommonCoInvestors';

export function MostCommonCoInvestors() {
  const data = useMostCommonCoInvestors();

  return (
    <MostCommonCoInvestorsChart id={CHART_COMPONENTS.MOST_COMMON_CO_INVESTORS} width={640} data={data} />
  );
}
