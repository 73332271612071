import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { MaggieHttp } from '../../../services/MaggieHttp';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { selectedDealState } from '../state/DealboardUIState';
import { allDealsState } from '../state/DealboardDataState';
import { DealComment, ICommentUpdate, ICommentUpdatePayload } from '../data-models/comment.data-model';
import { getErrorMessage } from '../../../services/queryHelpers';

export interface IDealCommentUpdate extends ICommentUpdate {
  dealId: number;
}

export interface IUpdateDealCommentParams extends IDealCommentUpdate {
  onSuccess?: () => void;
  onError?: () => void;
}

export interface ICommentDealUpdatePayload extends ICommentUpdatePayload {
  dealId: number;
}

const updateComment = ({ dealId, comment, commentId }: IDealCommentUpdate) => {
  return MaggieHttp.maggie.put<ICommentDealUpdatePayload, DealComment>(`/dealComment/${commentId}`, {
    dealId,
    comment,
  });
};

export const useUpdateComment = () => {
  const { pushErrorToast, pushSuccessToast } = useToastMessageState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingId, setLoadingId] = useState<number | null>(null);

  const updateDealComment = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ dealId, comment, commentId, onSuccess, onError }: IUpdateDealCommentParams) => {
        setIsLoading(true);
        setLoadingId(commentId);
        const updatedSnapshot = snapshot.map((mutable) => {
          mutable.set(allDealsState, (current) => {
            if (!current) return current;
            return current.map((d) => {
              if (d.id === dealId) {
                const updatedComments = d.comments.map((c) => {
                  if (c.id === commentId) {
                    return { ...c, comment: comment };
                  } else {
                    return c;
                  }
                });
                return { ...d, comments: updatedComments };
              } else {
                return d;
              }
            });
          });

          const selectedDeal = mutable.getLoadable(selectedDealState).valueMaybe();

          if (selectedDeal && selectedDeal.id === dealId) {
            mutable.set(selectedDealState, (current) => {
              if (!current) return current;
              const updatedComments = current.comments.map((c) => {
                if (c.id === commentId) {
                  return { ...c, comment: comment };
                } else {
                  return c;
                }
              });
              return { ...current, comments: updatedComments };
            });
          }
        });
        gotoSnapshot(updatedSnapshot);
        const release = snapshot.retain();

        try {
          const updatedComment = await updateComment({ dealId, comment, commentId });
          pushSuccessToast({ message: 'Comment successfully updated' });
          onSuccess?.();
          return updatedComment;
        } catch (err) {
          const message = getErrorMessage(err, 'An error occurred while trying to update comment');
          pushErrorToast({ message });
          gotoSnapshot(snapshot);
          onError?.();
        } finally {
          release();
          setIsLoading(false);
          setLoadingId(null);
        }
      },
    [pushErrorToast, pushSuccessToast]
  );

  return { updateDealComment, isLoading, loadingId };
};
