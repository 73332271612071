import { ScenarioTransactionType } from '../../../data-models/scenario.data-model';

// scenario search params
export const ActiveScenarioIdParamName = 'scenarioId';
export const ViewModeParamName = 'viewMode';
export const DuplicateParam = 'dup';

export const transactionNameMap = {
  [ScenarioTransactionType.round]: 'Round',
  [ScenarioTransactionType.exit]: 'Exit Modelling',
  [ScenarioTransactionType.partialExit]: 'Partial Exit',
};
