import { styled } from '@mui/material';
import { colors } from '../../theme/colors';
import { RelationshipStrengthNormalized } from '../../data-models/user-relation.data-model';

const BAR_COLOURS = [colors.primary['20'], colors.primary['30'], colors.primary['50']];

const RelationshipBars = styled('div')`
  align-items: center;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(3, minmax(4px, 44px));
  height: 100%;
  padding: 0.25rem;
  width: 100%;
`;

const RelationshipBar = styled('div')<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 2px;
  height: 4px;
`;

const Bars = BAR_COLOURS.map((colour, index) => <RelationshipBar key={index} backgroundColor={colour} />);

export interface IRelationshipScoreProps {
  relationshipStrengthScoreNormalized: RelationshipStrengthNormalized | null;
}

export function RelationshipScore(props: IRelationshipScoreProps) {
  const score = props.relationshipStrengthScoreNormalized;

  if (score === null) {
    return null;
  }

  const barCount = getBarCount(score);

  return (
    <RelationshipBars aria-label={`relationship score ${score}`}>{Bars.slice(0, barCount)}</RelationshipBars>
  );
}

function getBarCount(score: RelationshipStrengthNormalized) {
  if (score === RelationshipStrengthNormalized.Low) {
    return 1;
  }
  if (score === RelationshipStrengthNormalized.Medium) {
    return 2;
  }
  return 3;
}
