import { $getRoot, SerializedEditorState } from 'lexical';
import { createHeadlessEditor } from '@lexical/headless';
import { Stack, Typography } from '@mui/material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { styled } from '@mui/material/styles';
import { noop } from 'lodash-es';
import { INoteDataModel } from '../../../../data-models/note.data-model';
import { FMT } from '../../../../util/formatter-service';
import { UserAvatarList } from '../../../../components/AvatarListTooltip/UserAvatarList';

const SUMMARY_MAX_CHAR = 400;

const NoteSummaryCardWrapper = styled('div')`
  display: grid;
  align-content: start;
  height: 100%;
  row-gap: 0.5rem;
  width: 100%;
`;

const NoteTextWrapper = styled('div')`
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.75rem;
`;

export interface INoteSummaryCardProps {
  note: INoteDataModel<SerializedEditorState>;
}

export function NoteSummaryCard(props: INoteSummaryCardProps) {
  const { title, body, updatedAt, comments = [] } = props.note;
  const { attendees, data } = body;
  const attendeeIds = attendees?.map((attendee) => attendee.userId) ?? [];

  const editor = createHeadlessEditor({
    nodes: [],
    onError: noop,
  });
  const parsedEditorState = editor.parseEditorState(data);
  const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());
  const textMaybeEllipsized =
    editorStateTextString.length > SUMMARY_MAX_CHAR
      ? `${editorStateTextString.slice(0, SUMMARY_MAX_CHAR)}...`
      : editorStateTextString;

  return (
    <NoteSummaryCardWrapper>
      <Stack>
        <Typography>{title}</Typography>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'} alignItems={'center'} gap={'0.5rem'}>
            <UserAvatarList users={attendeeIds} />
            <Typography variant={'caption'} color={'text.secondary'}>{`${FMT.format(
              'date',
              updatedAt
            )} at ${FMT.format('time12hr', updatedAt)}`}</Typography>
          </Stack>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
            display={'flex'}
            alignItems={'center'}
            gap='0.25rem'
          >
            <AddCommentOutlinedIcon fontSize={'small'} />
            {comments.length}
          </Typography>
        </Stack>
      </Stack>

      <NoteTextWrapper>
        <Typography variant={'body2'}>{textMaybeEllipsized}</Typography>
      </NoteTextWrapper>
    </NoteSummaryCardWrapper>
  );
}
