import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { shareClassesState } from './CapTableFormState';

import {
  IInvestmentGridRow,
  IOtherInvestmentsGridRow,
  getClientInvestmentsShareClasses as getClientInvestmentsShareClasses,
} from './InvestmentGridDefs';
import { getShareClassesForOtherInvestments } from './OtherInvestmentsDefs';
import { updateInvestmentRows } from './investment-utils';

// share class state might change as users goes back and forth through the steps;
// this removes any share classes that are not in the current state, adds default values for any newly created share classes
// and deletes rows that are missing fundId
// Note that we would reset the values for any renamed shares

export function useSyncClientInvestmentDataWithSharesState() {
  const shareClasses = useRecoilValue(shareClassesState);

  return useCallback(
    (investmentRows: IInvestmentGridRow[]) => {
      return updateInvestmentRows(investmentRows, getClientInvestmentsShareClasses(shareClasses), 'fundId');
    },
    [shareClasses]
  );
}

export function useSyncOtherInvestmentDataWithSharesState() {
  const shareClasses = useRecoilValue(shareClassesState);

  return useCallback(
    (rows: IOtherInvestmentsGridRow[]) => {
      return updateInvestmentRows(rows, getShareClassesForOtherInvestments(shareClasses), 'investor');
    },
    [shareClasses]
  );
}
